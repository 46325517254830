<div *ngIf="vehicle" class="border-dark border-left border-bottom border-right bg-light">
    <ul class="nav nav-tabs border-dark border-left">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./strikingvehicle"]'
               [ngClass]="{'bg-light': linkVehVehicle.isActive !== true }"
               [routerLinkActive]="['active']" #linkVehVehicle="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehVehicle.isActive === true, 'fw-bold' : linkVehVehicle.isActive === true}">
                        <!--StrikingVehicle_V{{vehicleid}}-->
                        Striking_Vehicle_V{{vehicle.Veh_SS.SSVNumber}}
                    </span>
                </span>
            </a>
        </li>

        <!--<li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingdriver"]'
               [ngClass]="{'bg-light': linkstrikingdriver.isActive !== true }"
               [routerLinkActive]="['active']" #linkstrikingdriver="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkstrikingdriver.isActive === true, 'fw-bold' : linkstrikingdriver.isActive === true}">
                        Striking_Driver_V{{vehicle.Veh_SS.SSVNumber}}
                    </span>
                </span>
            </a>
        </li>-->

        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingprecrash"]'
               [ngClass]="{'bg-light': linkstrikingprecrash.isActive !== true }"
               [routerLinkActive]="['active']" #linkstrikingprecrash="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkstrikingprecrash.isActive === true, 'fw-bold' : linkstrikingprecrash.isActive === true}">
                        <!--Striking_Precrash_V{{vehicleid}}-->
                        Striking_Precrash_V{{vehicle.Veh_SS.SSVNumber}}
                    </span>
                </span>
            </a>
        </li>

        <li class="nav-item mt-1" *ngFor="let link of vehicle.Occupants">
            <span *ngIf="link">
                <a class="nav-link" [routerLink]='["./strikingperson", link.PNumber]'
                   [ngClass]="{'bg-light': strikinglinkperson.isActive !== true }"
                   [routerLinkActive]="['active']" #strikinglinkperson="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': strikinglinkperson.isActive === true, 'fw-bold' : strikinglinkperson.isActive === true}">
                            <!--Striking_Person_{{ link.PNumber }}_V{{ link.VNumber}}-->
                            Striking_Occupant_{{ link.PNumber }}_V{{ vehicle.Veh_SS.SSVNumber }}
                            <i class="fas fa-asterisk pl-2" *ngIf="link.PolInjSevr === 5"></i><span class="text-decoration-none" *ngIf="link.CreatedRecord > 0">_CR</span>
                        </span>
                    </span>
                </a>
            </span>
        </li>

    </ul>
    <!--here we generate and show Vehicle Vehicle's-children html's components-->
    <div class="tab-content">
        <router-outlet></router-outlet>
    </div>
</div>
