import { Component, OnInit, Input, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { EventEmitter, Output }     from '@angular/core';
import { Acc }      from 'src/app/models/acc';
import { TreeNode, TreeNodeSelectability } from './tree-node';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { KeyCode } from 'src/app/models/enums/app.enums';
import { EarlyNotify } from 'src/app/models/early-notify';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent implements OnInit {
    private strId: string; //The HTML element ID for the current tree node and the ID prefix for child nodes.
    public _this: TreeViewComponent = this;

    @ViewChild('lblNode') lblNode: ElementRef;
    @ViewChildren('treeViewChild') arrChildren: QueryList<TreeViewComponent>;

    @Output() public selectionChanged: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();

    /**
     * TreeViewComponent.objRootComponentElement should not be confused with TreeNode.objRoot. This input property is a
     * component with a recursive template and its parent container can only bind to the top-level TreeViewComponent.selectionChanged.
     * We need to pass down either a reference to the root or the container's handler bound to the root's selectionChanged
     * event.
     **/
    @Input() objRootComponentElement: TreeViewComponent; 
    @Input() objModel: EarlyNotify;
    @Input() treeData: TreeNode;
    @Input() blnBorder: boolean;
    @Input() blnEnabled: boolean = false;
    @Input() blnEnableNav: boolean = false; //Is this a navigation tree where each node represents navigation URL?
    @Input() blnVerbose: boolean = false;
    @Input() strIdPrefix: string;
    @Input() tabindex: number = -1;
    
    constructor(private _valueToDescriptionPipe: ValueToDescriptionPipe) { }   

    async ngOnInit() {
        if (this.blnBorder === undefined)
            this.blnBorder = true;

        if (this.objModel && !this.treeData) {
            //TreeNode.resetMossTreeCaption();
            this.treeData = await TreeNode.CreateTree(this.objModel, this.blnVerbose, this.blnEnabled, this._valueToDescriptionPipe);
        }

        this.strId = this.strIdPrefix + '_' + this.treeData.strId;
        this.treeData.strId = this.strId;

        if(!this.objRootComponentElement)
            this.objRootComponentElement = this;
    }

    public toggleExpand(objNode: TreeViewComponent): void {
        objNode.treeData.blnExpanded = !objNode.treeData.blnExpanded;
    }

    public toggleSelect(objNode: TreeViewComponent): void {
        let nodeNewSelection: TreeNode = objNode.treeData.Select(); //Toggles selection if selecting already selected node

        let nodeTreeModelRoot: TreeNode = this.treeData.objRoot || this.treeData; //For root TreeNode, TreeNode.objRoot is null and it can just refer to itself.

        if (nodeTreeModelRoot.objSelected != null && nodeTreeModelRoot.objSelected != nodeNewSelection)
            nodeTreeModelRoot.objSelected.Select(false); //Clear previous selection

        nodeTreeModelRoot.objSelected = nodeNewSelection;

        if(nodeNewSelection != null)
            this.lblNode.nativeElement.focus(); //Focus may be changed again by whoever is listening to selectionChanged event
        
        this.objRootComponentElement.selectionChanged.emit(nodeNewSelection); //Raise root's event as that is the only one that the parent container can bind a handler to
    }

    public handleKeydown(objEvent: KeyboardEvent): void {
        if (objEvent.key == KeyCode.Tab) {
            let objRoot: TreeViewComponent = this.objRootComponentElement ? this.objRootComponentElement : this;
            let arrDepthFirstFlat: Array<TreeViewComponent> = objRoot.FlattenDepthFirst();
            let intIndexCurrent: number = arrDepthFirstFlat.indexOf(this);
            let intIndexNext: number = intIndexCurrent + (objEvent.shiftKey ? -1 : 1);

            if (intIndexNext >= 0 && intIndexNext < arrDepthFirstFlat.length) {
                objEvent.preventDefault(); //Do not let key press bubble up to document
                let objNodeNext: TreeViewComponent = arrDepthFirstFlat[intIndexNext];
                objNodeNext.toggleSelect(objNodeNext);
            }
        }
    }

    public FlattenDepthFirst(arrFlat: Array<TreeViewComponent> = null): Array<TreeViewComponent> {
        if (arrFlat == null)
            arrFlat = new Array<TreeViewComponent>();

        if (this.treeData.eSelectability == TreeNodeSelectability.Enabled)
            arrFlat.push(this); //Push self on

        for (let objChild of this.arrChildren.toArray()) {
            if (objChild.arrChildren.length == 0) {
                if (objChild.treeData.eSelectability == TreeNodeSelectability.Enabled)
                    arrFlat.push(objChild);
            }
            else
                objChild.FlattenDepthFirst(arrFlat); //Recursive call will push child on if there are grandchildren
        }

        return arrFlat;
    }
}
