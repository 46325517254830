import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError, BehaviorSubject, Subscription, interval, from } from 'rxjs';
import { GenericService } from './generic.service'
import { DrpDownOptions } from '../models/drp-down-options';
import { TableFieldElements } from '../models/table-field-elements';
import { LookupTable } from '../models/enums/Generated/LookupTable';
import { AttributeMatchLevel } from '../models/attr-match-level';
import { Element_Specify } from '../models/element-specify';
import { CaseStats } from '../models/case-stats';
import { share, shareReplay, tap, map, concat, retry } from 'rxjs/operators';
import { GetSeqEvents_Result } from '../models/GetSeqEvents_Result';
import { GetCrashAvoidCriteria_Result } from '../models/GetCrashAvoidCriteria_Result';
import { Acc } from '../models/acc';
import { EncryptedData } from '../models/EncryptedData';
import { GetStylesByStateNum_Result } from '../models/GetStylesByStateNum_Result';
import { usp_EDT_GetStylesByStateNum_Result } from '../models/usp_EDT_GetStylesByStateNum_Result';
import { Coordinates_DMS } from '../models/Coordinates_DMS';
import { vpicGVWRMapping } from '../models/vpicGVWRMapping';
import { usp_EDT_GetAttributeMatchLevels_Result } from '../models/usp_EDT_GetAttributeMatchLevels_Result';
import { CustomNvpFilter, DriverSubTab, CrashSubTab, VehicleSubTab } from '../models/enums/app.enums';
import { promise } from 'protractor';
import { ParErrorCodeList } from '../models/ParErrorCodeList';
import { RbisUser } from '../models/rbis-user';
import { VehicleService } from './vehicle.service';
import { UserClient } from '../models/user-client';
import { AuthService } from './auth.service';
import { PerBikeWizard } from '../models/pedbikewizard';
//import { SharedDataService } from './shared-data.service';

//import { GetCalendarDetailsResult } from './../models/get-calendar-details-result';
//import { FarsMetricsDetails } from './../models/fars-metrics-details';
//import { CaseSummaryData } from './../models/case-summary-data';
//import { QcBenchmarkComparison } from './../models/get-qc-benchmark-comparison';
const CACHE_SIZE = 1;


@Injectable({
    providedIn: 'root'
})
export class UtilService {

    objDrpDownOptions: DrpDownOptions[];

    utilSubscription: Subscription = new Subscription();

    metaDataToShare: BehaviorSubject<TableFieldElements[]> = new BehaviorSubject(null);
    metaDataSet = this.metaDataToShare.asObservable();
    private _arrMetadata: TableFieldElements[];
    private prmMetadata: Promise<TableFieldElements[]>

    formDrpDownOptionShare: BehaviorSubject<DrpDownOptions[]> = new BehaviorSubject(null);

    attributeLevelToShare: BehaviorSubject<usp_EDT_GetAttributeMatchLevels_Result[]> = new BehaviorSubject(null);
    attributeLevelDataSet = this.attributeLevelToShare.asObservable();

    private _arrAttributeLeveltoShare: usp_EDT_GetAttributeMatchLevels_Result[];
    private prmAttributeLevel: Promise<usp_EDT_GetAttributeMatchLevels_Result[]>

    stateElementOtherSpecifyToShare: BehaviorSubject<Element_Specify[]> = new BehaviorSubject(null);
    objStateElementOtherSpecify = this.stateElementOtherSpecifyToShare.asObservable();

    stateEDTStyleToShare: BehaviorSubject<usp_EDT_GetStylesByStateNum_Result[]> = new BehaviorSubject(null);
    objstateEDTStyle = this.stateEDTStyleToShare.asObservable();

    private $cacheEDTStyle: Observable<usp_EDT_GetStylesByStateNum_Result[]>;

    private wizardOptions: BehaviorSubject<any[]>;
    private crashTypeWizard: BehaviorSubject<any[]>;
    private encryptedValue: BehaviorSubject<any>;
    private decryptedValue: BehaviorSubject<any>;
    public dmsConverted: Array<any> = [];
    public ddmConveted: Array<any> = [];
    private _userClient: UserClient[];
    public get userClient() { return this._userClient; }
    public set userClient(value: UserClient[]) { this._userClient = value; }
    userClientPms: Promise<UserClient[]>;

    private harmfulEventsList: BehaviorSubject<GetSeqEvents_Result[]> = new BehaviorSubject<any[]>(null);
    private nonHarmfulEventsList: BehaviorSubject<GetSeqEvents_Result[]> = new BehaviorSubject<any[]>(null);
    private crashAvoidCriteriaList: BehaviorSubject<GetCrashAvoidCriteria_Result[]> = new BehaviorSubject<any[]>(null);
    sign = 1.;
    lonsign = 1.;
    latAbs = 0;
    lonAbs = 0;

    objDrpDownPSUList: DrpDownOptions[];
    continuesSlownessCounter: number = 0;

    //userToShare: BehaviorSubject<RbisUser[]> = new BehaviorSubject(null);
    //objUsers = this.userToShare.asObservable();

    //private userCache$: BehaviorSubject<UserClient[]> = new BehaviorSubject(null);

    private userListSubject = new BehaviorSubject<UserClient[]>(null);
    userListByPriv$ = this.userListSubject.asObservable();

    constructor(private _baseService: GenericService<any>, private _authService: AuthService) {
        this.wizardOptions = new BehaviorSubject<any[]>([]);
        this.crashTypeWizard = new BehaviorSubject<any[]>([]);
        this.encryptedValue = new BehaviorSubject<any>('');
        this.decryptedValue = new BehaviorSubject<any>('');

    }

    public get getAvailableWizardOptions(): Observable<any> {
        return this.wizardOptions.asObservable();
    }

    public async getWizardOptions(type: string): Promise<PerBikeWizard[]> {
        this._baseService.actionURL = "api/util/wizard/" + type;
        return await this._baseService.getListPromise();
    }

    //section is for crash type wizard
    public get getListOfCrashWizard(): Observable<any> {
        return this.crashTypeWizard.asObservable();
    }

    public getCrashTypeWizard() {
        this._baseService.actionURL = "api/util/crashtypewizard";
        let crashWizartList = this._baseService.getData();
        crashWizartList.subscribe(options => {
            this.crashTypeWizard.next(options);
        })
    }
    //end of crash type

    public GetDrpDownListOptions(strTableName: string, strFilterConditions: string): Observable<DrpDownOptions[]> {
       //Object.values(LookupTable).includes(strTableName) was replace with 

        if (Object.values(LookupTable).includes(strTableName as LookupTable)) {
            return this._baseService.GetDrpDownListOptions(strTableName, strFilterConditions);
        }
        else {
            let errorMessage = "Table : " + strTableName + " not exist enum class LookupTable.ts";
            window.alert(errorMessage);
            return throwError(errorMessage);
        }
    }

    public GetFormDrpDownListOptions(formName: string): Observable<DrpDownOptions[]> {

        let objDrpDwnOptions = this._baseService.GetFormDrpDownListOptions(formName).pipe(
            share() //Multiple subscribers to HTTP request observable will dispatch only a single HTTP request and share the result
        );
        objDrpDwnOptions.subscribe((result) => {
            this.formDrpDownOptionShare.next(result);
        });

        return objDrpDwnOptions;
    }

    public GetStateAttributeMatchLevelsByFormName(stateNum: number, strSubFormName: string, strByPassCaching: string = 'false', strForeCaching: string = 'false'): Observable<AttributeMatchLevel[]> {

        let objAttrMatchData = this._baseService.GetAttributeMatchLevelsByForm(stateNum, strSubFormName, strByPassCaching, strForeCaching).pipe(
            share()
        );
        objAttrMatchData.subscribe((result) => {
            this.attributeLevelToShare.next(result);
        });
        this.attributeLevelDataSet = objAttrMatchData;
        return objAttrMatchData;
    }


    public get _arrAttributeMatchLevels() {
        return this._arrAttributeLeveltoShare;
    }

    public GetStateAttributeMatchLevelsByFormNamePromise(stateNum: number, strSubFormName: string, strByPassCaching: string = 'false', strForeCaching: string = 'false'): Promise<AttributeMatchLevel[]> {
        if (strSubFormName == DriverSubTab.Counters || strSubFormName == CrashSubTab.Special || strSubFormName == VehicleSubTab.DamagedAreas)
            return Promise.resolve(this._arrAttributeLeveltoShare);
        if ((this._arrAttributeLeveltoShare && this._arrAttributeLeveltoShare.some(i => i.StateNum == stateNum && i.SubFormName == strSubFormName))) {
            return Promise.resolve(this._arrAttributeLeveltoShare);
        }
        else {
            this.prmAttributeLevel = this._baseService.GetAttributeMatchLevelsByForm(stateNum, strSubFormName, strByPassCaching, strForeCaching).toPromise();

            this.prmAttributeLevel.then((arrAttributeLeveltoShare) => {
                if (arrAttributeLeveltoShare)
                    this._arrAttributeLeveltoShare = arrAttributeLeveltoShare;
                this.prmAttributeLevel = null;
            });
            return this.prmAttributeLevel;
        }
    }

    private RequestStateEDTStyle(stateNum: number): Observable<GetStylesByStateNum_Result[]> {
        return this._baseService.GetStateEDTStyle(stateNum).pipe(
            share()
        );
    }

    public GetStateEDTStyle(stateNum: number): Observable<GetStylesByStateNum_Result[]> {

        if (!this.$cacheEDTStyle) {
            this.$cacheEDTStyle = this.RequestStateEDTStyle(stateNum).pipe(
                shareReplay(CACHE_SIZE)
            );
        }
        return this.$cacheEDTStyle;
    }



    public GetStateAllElementOtherSpecifyValues(stateNum: number): Observable<Element_Specify[]> {
        this._baseService.actionURL = "api/util/GetStateAllElementOtherSpecifyValues/" + stateNum;
        let list = this._baseService.getData().pipe(
            share()
        );
        list.subscribe(result => {
            this.stateElementOtherSpecifyToShare.next(result)
        })
        this.objStateElementOtherSpecify = list;
        return list;
    }

    public GetTableFieldElements(tableId: string, form: string): Observable<TableFieldElements[]> {

        let objMetaData = this._baseService.GetTableFieldElements(tableId, form).pipe(
            share()
        );
        this.utilSubscription.add(objMetaData.subscribe((result) => {
            this.metaDataToShare.next(result);
        }));
        this.metaDataSet = objMetaData;
        return objMetaData;
    }

    public GetMetadataPromise(): Promise<TableFieldElements[]> {
        //We are intentionally using an explicitly instantiated Promise and not using the async await syntax here to ensure
        //that all components requesting access to the data model (the Acc object) share the same promise, the same server
        //trip and the same instance of the data model.
        if ((this._arrMetadata)) {
            return Promise.resolve(this._arrMetadata);
        }
        else if (this.prmMetadata) {
            return this.prmMetadata;
        }
        else {
            this.prmMetadata = this._baseService.GetTableFieldElements('', '').toPromise();

            this.prmMetadata.then((arrMetadata) => {
                this._arrMetadata = arrMetadata;
                this.prmMetadata = null;
            });
            return this.prmMetadata;
        }
    }


    public GetVpicGVWRMappings(): Observable<vpicGVWRMapping[]> {

        this._baseService.actionURL = "api/util/GetVpicGVWRMappings";
        let obsVpicMapping = this._baseService.getData();
        return obsVpicMapping;
    }


    //map cordinate related

    public decryptUrl(url: any) {

        this._baseService.actionURL = "api/util/UrlDecrypt/" + url;
        return this._baseService.getData().toPromise();

    }

    urlToJson(result: any) {
        let decodedUrl = decodeURI(result)
        let queryString = decodedUrl.split('?').pop();;

        //parsing query string to Json
        let jsonQuery = queryString.split('&').reduce(function (obj, item, index) {
            if (item) {
                let items = item.split('=');
                obj[items[0]] = items[1];
                return obj;
            }
        }, {})
        return jsonQuery

    }


    compareNumber(a, b) {

        if (a < b) return '-';
        else if (a === b) return '=';
        else if (a > b) return '+';
        else return 'z';
    }

    public convertDmstoLatLng(degrees, minutes, seconds, direction): string {
        let latOrLng = (Number(degrees) + (Number(minutes) / 60) + (Number(seconds) / (60 * 60)));

        if (direction == 'S' || direction == 'W') {
            latOrLng = latOrLng * -1;
        }
        return latOrLng.toString();
    }

    private truncate(n) {
        return n > 0 ? Math.floor(n) : Math.ceil(n);
    }


    //new conversation function

    public dmsTodd(degrees, minutes, seconds, type) {
        let value: any;
        if (this.compareNumber(degrees, 0) == '-') { this.sign = -1.; } else { this.sign = 1.; }

        const absDeg = Math.abs(Math.round(degrees * 1000000.))
        const tmpMin = Math.abs(Math.round(minutes * 1000000.) / 1000000)
        const tmpSec = Math.abs(Math.round(seconds * 1000000.) / 1000000)

        const absMin = Math.abs(Math.round(tmpMin * 1000000.))
        const absSec = Math.abs(Math.round(tmpSec * 1000000.))

        value = ((Math.round(absDeg + (absMin / 60.) + (absSec / 3600.)) / 1000000)) * this.sign;
        if (this.compareNumber(absDeg, 0) == '-') value = 0.0 - value;
        // We have to do it this way because IE11 doesn't handle negative numbers correctly

        return value;
    }

    public dmsToDdm(degrees, minutes, seconds) {
        let value: any;
        if (this.compareNumber(degrees, 0) == '-') { this.sign = -1.; } else { this.sign = 1.; }

        const absDeg = Math.abs(Math.round(degrees * 1000000.))
        const tmpMin = Math.abs(Math.round(minutes * 1000000.) / 1000000)
        const tmpSec = Math.abs(Math.round(seconds * 1000000.) / 1000000)

        const absMin = Math.abs(Math.round(tmpMin * 1000000.))
        const absSec = Math.abs(Math.round(tmpSec * 1000000.))

        value = ((Math.round(absDeg + (absMin / 60.) + (absSec / 3600.)) / 1000000)) * this.sign;
        if (this.compareNumber(absDeg, 0) == '-') value = 0.0 - value;

        return this.ddToDms(value, 'noArray').decimalMinutes;
    }

    public ddmToDms(deg: string, min: string, type: string) {

        if (this.compareNumber(deg, 0) == '-') { this.sign = -1.; } else { this.sign = 1; }

        let latOrLngcoordinate = this.sign * (Number(deg) + (Number(min) / 60));

        return this.ddToDms(latOrLngcoordinate, type);

    }

    public ddmTodd(deg: string, min: string) {

        if (this.compareNumber(deg, 0) == '-') { this.sign = -1.; } else { this.sign = 1; }

        let latOrLngcoordinate = this.sign * (Number(deg) + (Number(min) / 60.0));

        return latOrLngcoordinate.toFixed(8);

    }
    public ddToDms(cordinate, type: string) {

        if (this.compareNumber(cordinate, 0) == '-') { this.sign = -1.; } else { this.sign = 1; }

        let absolute = Math.abs(Math.round(cordinate * 1000000.));

        let degree: number = (Math.floor(absolute / 1000000) * this.sign);

        let minutes = Math.floor(((absolute / 1000000) - Math.floor(absolute / 1000000)) * 60)

        let seconds: number = (Math.floor(((((absolute / 1000000) - Math.floor(absolute / 1000000)) * 60) - Math.floor(((absolute / 1000000) - Math.floor(absolute / 1000000)) * 60)) * 100000) * 60 / 100000);

        seconds = Math.floor(seconds * 100) / 100;

        /* if (seconds >= 60) {
             seconds = 59.99;
         }*/
        let dMinutes = Math.abs(parseFloat('.' + cordinate.toString().split('.')[1]) * 60.0);

        if (type != 'noArray') this.dmsConverted.push({ degree: degree, minutes: minutes, seconds: seconds, dMinutes: dMinutes, type: type });
        return {
            degrees: degree,
            minutes: minutes,
            seconds: seconds,
            decimalMinutes: dMinutes,
            type: type
        };

    }

    //end of new
    /**
     * Convert Decimal Degrees (DD) to [Whole] Degrees, [Whole] Minutes, [Decimal] Seconds (DMS)
     * TODO: make data type of "cordinate" parameter consistent
     **/
    public convertLatLngToDms(cordinate, type: string): Coordinates_DMS {

        let absolute: number = Math.abs(cordinate);

        let degree: number = this.truncate(absolute);

        // let degree: number = Math.floor(absolute);

        const minutes = this.truncate((absolute - degree) * 60);

        let seconds: number = ((absolute - degree - minutes / 60) * Math.pow(60, 2));

        seconds = Math.floor(seconds * 100) / 100;

        // let tmpMinutes: number = ((absolute - degree) * 60);
        // let minutes: number = Math.floor(tmpMinutes);
        let dMinutes: number = parseFloat(((absolute - degree) * 60).toFixed(4));
        //let seconds: number = parseFloat(((tmpMinutes - minutes) * 60).toFixed(2));

        this.dmsConverted.push({ degree: degree, minutes: minutes, seconds: seconds, dMinutes: dMinutes, type: type });
        return {
            degrees: degree,
            minutes: minutes,
            seconds: seconds,
            decimalMinutes: dMinutes,
            type: type
        };
    }

    public convertLatLngToDdm(cordinate, type: string): void {
        let absolute = Math.abs(cordinate);
        let degree = Math.floor(absolute);
        let tmpMinutes = ((absolute - degree) * 60);
        let minutes = Math.floor(tmpMinutes);
        let dMinutes = ((absolute - degree) * 60).toFixed(4);
        let seconds = ((tmpMinutes - minutes) * 60);
        seconds = Math.floor(seconds * 100) / 100;

        this.dmsConverted.push({ degree: degree, minutes: minutes, seconds: seconds, dMinutes: dMinutes, type: type });
    }

    public convertDDMToLatLng(deg: string, min: string, type: string): string {
        let latOrLngcoordinate = (Number(deg) + (Number(min) / 60));

        let absolute = Math.abs(latOrLngcoordinate);
        let degree = Math.floor(absolute);
        let tmpMinutes = ((absolute - degree) * 60);
        let minutes = Math.floor(tmpMinutes);
        //let seconds = Math.floor((tmpMinutes - minutes) * 60);

        //let seconds = ((tmpMinutes - minutes) * 60).toFixed(2);
        let dMinutes = ((absolute - degree) * 60).toFixed(4);
        let seconds = ((tmpMinutes - minutes) * 60);
        seconds = Math.floor(seconds * 100) / 100;

        this.dmsConverted.push({ degree: degree, minutes: minutes, seconds: seconds, dMinutes: dMinutes, type: type });

        return latOrLngcoordinate.toString();
    }
    ///

    //end of map related.


    //encryption and decrypted:

    public encrypt(plainText: string): Observable<EncryptedData> {
        this._baseService.actionURL = "api/util/encrypt?url=" + plainText;
        let objEncryptResult = this._baseService.getData();

        objEncryptResult.subscribe(result => {
            this.encryptedValue.next(result)
        });

        return objEncryptResult;
    }

    public get getEncryptedValues(): Observable<EncryptedData> {
        return this.encryptedValue.asObservable();
    }

    public decrypt(encryptedCode: string): Observable<EncryptedData> {
        this._baseService.actionURL = "api/util/decrypt?url=" + encryptedCode;
        let obsDecryptResult = this._baseService.getData();

        obsDecryptResult.subscribe(result => {
            this.decryptedValue.next(result);
        });

        return obsDecryptResult;
    }

    public get getDecryptedValue(): Observable<EncryptedData> {
        return this.decryptedValue.asObservable();
    }

    //end of Decrypted


    //Get Seq Events:

    public setSeqEventsList() {
        this._baseService.actionURL = "api/util/GetSeqEvents";
        let seqEventList = this._baseService.getData();
        seqEventList.subscribe(seqEvents => {
            if (seqEvents) {
                let harmfulEvents = seqEvents.filter(s => s.Harmful == 1);

                this.harmfulEventsList.next(harmfulEvents);

                let nonHarmfulEvents = seqEvents.filter(s => s.Harmful == 0);

                this.nonHarmfulEventsList.next(nonHarmfulEvents);
            }

        })
    }

    public get harmfulEvents() {
        return this.harmfulEventsList.asObservable();
    }

    public get nonHarmfulEvents() {
        return this.nonHarmfulEventsList.asObservable();
    }
    //end of Seq Events:

    //Get GetCrashAvoidCriteria:

    public setCrashAvoidCriteria() {
        this._baseService.actionURL = "api/util/GetCrashAvoidCriteria";
        let crashAvoidCriteriaList = this._baseService.getData();
        crashAvoidCriteriaList.subscribe(result => {
            if (result) {
                this.crashAvoidCriteriaList.next(result)
            }
        })
    }

    public get getCrashAvoidCriteria() {
        return this.crashAvoidCriteriaList.asObservable();
    }

    //end of GetCrashAvoidCriteria

    /**
     * Starting from the passed in active route, traverses up the active route hierarchy, until an active route with the sought parameter is found.
     * Returns the value of the sought parameter. Throws an error if the sought parameter does not exist up the active route hierarchy.
     * @param objActivatedRouteSnapShot
     * @param strParamName
     */
    public GetParameterFromRouteHierarchy(objActivatedRouteSnapShot: ActivatedRouteSnapshot, strParamName: string): string {
        let objCursor: ActivatedRouteSnapshot = objActivatedRouteSnapShot;

        while (objCursor != null) {
            if (objCursor.paramMap.get(strParamName))
                return objCursor.paramMap.get(strParamName);
            else
                objCursor = objCursor.parent;
        }

        throw new Error('Parameter ' + strParamName + ' not found in active route hirarchy. Check that it exists and is spelled correctly.');
    }

    /**
     * Saves the last user activity and renews the session cookie.
     **/
    public UserLastActiveSave(): Promise<EncryptedData> {
        this._baseService.actionURL = "api/util/SaveUserLastActive";
        return this._baseService.getData().toPromise();
    }

    public setPSUList(psu_list) {
        if (psu_list != null) {
            psu_list.forEach(item => {
                this.objDrpDownPSUList.push(item)
            })

        }
    }
    public setEmptyPSUList() {
        this.objDrpDownPSUList = [];
    }

    public findPSU(psu_num) {
        const psuItem = this.objDrpDownPSUList.find(x => x.intValue == psu_num);
        if (psuItem != null) {
            return psuItem.displayText
        }
        return '';
    }
    public getPsuList() {
        this.setEmptyPSUList();
        this.GetDrpDownListOptions(LookupTable.VR_PSU, CustomNvpFilter.ByPermissions)
            .subscribe((result: DrpDownOptions[]) => {
                this.setPSUList(result);
            });
    }

    /* Crss Section */
    public UpdateCrssCase(accId: number, statusId: number, isUndelete: boolean, intParId_Duplicate: number): Observable<Acc> {
        if (intParId_Duplicate == undefined || intParId_Duplicate == null)
            intParId_Duplicate = -1;

        this._baseService.actionURL = "api/case/UpdateCrssCase/" + accId + '/' + statusId + '/' + isUndelete + '/' + intParId_Duplicate;
        return this._baseService.updateEntity(null);
    }

    public getParErrorCodeLists(): Promise<ParErrorCodeList[]> {
        this._baseService.actionURL = "api/case/GetParErrorCodeLists/";
        return this._baseService.getData().toPromise();
    }


    public sizeOfNumber(v, left, right) {
        return Math.abs(v).toFixed(right).padStart(left + right + 1, '0');
    }

    //uses for rounding lower/upper roundUp(10.25, -1) result 10.20 position will 


    /* Cache userlist
    Changed Observable aproche to promise
*/

    public GetUserList(): Promise<UserClient[]> {

        if (this._userClient) {
            return new Promise((resolve) => {
                resolve(this._userClient);
            });
        }
        else if (this.userClientPms) {
            return this.userClientPms;
        }
        else {
            this._baseService.actionURL = "api/util/GetUserList";
            this.userClientPms = this._baseService.getDataPromise();
            
            return this.userClientPms;
        }
        //let objUser = this._baseService.GetUserList().pipe(
        //    share()
        //);
        //objUser.subscribe((result) => {
        //    this.userCache$.next(result);
        //});
        //return objUser;
    }

    public GetUserListByPriv(priv: string): Promise<UserClient[]> {

        let objUserList = this._baseService.GetUserListByPriv(priv).pipe(
            share()
        );

        objUserList.subscribe((result) => {
            this.userListSubject.next(result);    
        });        

        this.userListByPriv$ = objUserList;
        return objUserList.toPromise();
    }

    // send slow internet report to heldesk
    public sendSlowInternetStatics(): Promise<boolean> {
        this._baseService.actionURL = "api/util/SendSpeedStatics";
        return this._baseService.getData().toPromise();
    }

    GetUserPromise() {
        return this._authService.GetUserPromise();
    }

    public errorTest(): Promise<boolean> {
        this._baseService.actionURL = "api/util/SpeedErrorStatics";
        return this._baseService.getData().toPromise();
    }
    //end send slow internet report to heldesk
}

