<form novalidate #fnonOccsupplemental="ngForm">
    <div class="pt-2" *ngIf="nonOccupant && intMode">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div [ngClass]="{'pl-2' : printOnly !== true}" class="">
                    <div class="form-group">
                        <app-typeahead id="DiedScene" name="diedScene" typeAheadPosition="right-top"
                                       [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="DiedScene"
                                       [printOnly]="printOnly"
                                       [clearFieldData]="clearFieldData">
                        </app-typeahead>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="pt-1 pb-1">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto' : printOnly !== true }">
                                <div [ngClass]="{'pl-2' : printOnly !== true, 'pl-3' : printOnly === true }">
                                    <app-label inputName="DeathDate" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }">
                                <div class="input-group">
                                    <div class="input-group-append" [ngClass]="{'pl-2' : printOnly !== true, 'pl-3' : printOnly === true }">
                                        <div [ngClass]="{'p-0 mr-1' : printOnly !== true}">
                                            <app-typeahead id="NonOccupantDthMon" typeAheadPosition="right-top" [disableLabel]="true"
                                                           [(objCurrentValue)]="nonOccupant.DthMon" [blnHideSelectedOptionText]="true"
                                                           [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="DthMon"
                                                           [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           (keyup)="onModalChange($event)"
                                                           strRightArrowFocusFieldName="DthDay" [blnFocusOnNextEmptyControl]="false">
                                            </app-typeahead>
                                        </div>
                                    </div>
                                    <p class="px-2">/</p>
                                    <div class="input-group-append">
                                        <app-typeahead id="NonOccupantDthDay" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="nonOccupant.DthDay" [blnHideSelectedOptionText]="true"
                                                       [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="DthDay"
                                                       [printOnly]="printOnly"
                                                       [showAllDataListForPrintOnly]="false"
                                                       [clearFieldData]="clearFieldData" strLeftArrowFocusFieldName="DthMon" strRightArrowFocusFieldName="DthYr"
                                                       [blnCheckStrengthCode]="false" [blnFocusOnNextEmptyControl]="false">
                                        </app-typeahead>
                                    </div>
                                    <p class="px-2">/</p>
                                    <div class="input-group-append" *ngIf="intMode != 256">
                                        <app-typeahead id="NonOccupantDthYr" typeAheadPosition="right-top"
                                                       [disableLabel]="true" [(objCurrentValue)]="nonOccupant.DthYr"
                                                       [blnHideSelectedOptionText]="true"
                                                       [objModel]="nonOccupant" strTableName="Non_Occupants"
                                                       strFieldName="DthYr"
                                                       [printOnly]="printOnly"
                                                       [showAllDataListForPrintOnly]="false"
                                                       [clearFieldData]="clearFieldData" strLeftArrowFocusFieldName="DthDay"
                                                       strComplexFieldName="DeathDate">
                                        </app-typeahead>
                                    </div>
                                    <div class="input-group-append" *ngIf="intMode == 256">
                                        <app-typeahead id="NonOccupantDthYr" typeAheadPosition="right-top"
                                                       [disableLabel]="true" [(objCurrentValue)]="nonOccupant.DthYr"
                                                       [blnHideSelectedOptionText]="true"
                                                       [objModel]="nonOccupant" strTableName="Non_Occupants"
                                                       strFieldName="DthYr"
                                                       [printOnly]="printOnly"
                                                       [showAllDataListForPrintOnly]="false"
                                                       [clearFieldData]="clearFieldData" strLeftArrowFocusFieldName="DthDay"
                                                       strFilterCondition=" AND ID IN ({{arrPossiableYears}})"
                                                       strComplexFieldName="DeathDate"
                                                       [blnEvaluateConditionValue]="false">
                                        </app-typeahead>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div  *ngIf="intMode == 64 || intMode == 128"  [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }" class="">
                                <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                    <app-label TableID="Non_Occupants" inputName="DeathTime" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }" class="">
                                <div class="input-group">
                                    <div class="input-group" [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                        <div #typAhdDthHr class="input-group-append">
                                            <app-typeahead id="NonOccupantDthHr" typeAheadPosition="right-top" [disableLabel]="true"
                                                           [(objCurrentValue)]="nonOccupant.DthHr" [blnHideSelectedOptionText]="true"
                                                           [objModel]="nonOccupant"
                                                           strTableName="Non_Occupants"
                                                           strFieldName="DthHr"
                                                           [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           strRightArrowFocusFieldName="DthMin"
                                                           [blnCheckStrengthCode]="false" [blnFocusOnNextEmptyControl]="false">
                                            </app-typeahead>
                                        </div>
                                        <p class="px-2" [ngClass]="{'mb-0' : printOnly === true }">:</p>
                                        <div #typAhdDthMin class="input-group-append px-1">
                                            <app-typeahead id="NonOccupantDthMin" typeAheadPosition="right-top" [disableLabel]="true"
                                                           [(objCurrentValue)]="nonOccupant.DthMin"
                                                           [blnHideSelectedOptionText]="true"
                                                           [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="DthMin"
                                                           [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData" strLeftArrowFocusFieldName="DthHr"
                                                           strComplexFieldName="DeathTime">
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!printOnly && intMode == 64">
                <div class="form-group">
                    <div class="row" [ngClass]="{'pl-3 my-auto' : printOnly === true }">
                        <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : printOnly !== true }">
                            <div [ngClass]="{'pl-2' : printOnly !== true }" class="">
                                <app-label inputName="DeathCer" [printOnly]="printOnly"></app-label>
                            </div>
                        </div>
                        <div [ngClass]="{'col my-auto' : printOnly !== true }" class="">
                            <div class="input-group">
                                <div class="input-group-append" [ngClass]="{'pl-2' : printOnly !== true }">
                                    <div #typAhdDeathCity [ngClass]="{'p-0 mr-3' : printOnly !== true }" class="">
                                        <!--<app-textfield id="DeathCity" cssFieldCLass="w74" numType="number"
                                               [disableLabel]="true"
                                               [maxlength]="4" intMaxDecimal="0"
                                               [blnIsNumber]="true"
                                               [objModel]="nonOccupant" [(objCurrentValue)]="nonOccupant.DeathCity"
                                               strTableName="Non_Occupants" strFieldName="DeathCity"
                                               #txtNonOccupantDeathCity>
                                </app-textfield>-->
                                        <!--Please see Ticket# 6835 We are not using strDependentFieldName="DeathState" because DeathState is nullable and does not have default value -1 and we need the default options in DeathCity to be not empty when DeathState is not yet specified -->
                                        <app-typeahead #txtDeathCity id="DeathCity" name="DeathCity" typeAheadPosition="right-top"
                                                       [objModel]="nonOccupant"
                                                       [(objCurrentValue)]="nonOccupant.DeathCity"
                                                       [disableLabel]="true"
                                                       strTableName="Non_Occupants"
                                                       strFieldName="DeathCity"
                                                       strFilterCondition=" AND StateID = {{ nonOccupant.DeathState != null && nonOccupant.DeathState != -1 ? nonOccupant.DeathState : nonOccupant.Acc.StateNum }}"
                                                       [blnHideSelectedOptionText]="true"
                                                       [blnAllowFreeText]="true"
                                                       [printOnly]="printOnly"
                                                       [maxlength]="4"
                                                       strComputedFieldName="DeathCer"
                                                       [showAllDataListForPrintOnly]="false"
                                                       strRightArrowFocusFieldName="DeathState"
                                                       [blnCheckStrengthCode]="false" [blnFocusOnNextEmptyControl]="false">
                                        </app-typeahead>
                                    </div>
                                    <div class="p-0 ml-1 mr-3">
                                        <app-textfield id="DeathState" cssFieldCLass="w74" numType="number"
                                                       [disableLabel]="true"
                                                       [maxlength]="2" intMaxDecimal="0"
                                                       [blnIsNumber]="true"
                                                       [objModel]="nonOccupant" [(objCurrentValue)]="nonOccupant.DeathState"
                                                       (bindModel)="OnDeathStateChange($event)"
                                                       strTableName="Non_Occupants" strFieldName="DeathState"
                                                       #txtNonOccupantDeathState
                                                       [printOnly]="printOnly"
                                                       [showAllDataListForPrintOnly]="false"
                                                       strComputedFieldName="DeathCer"
                                                       [clearFieldData]="clearFieldData"
                                                       strLeftArrowFocusFieldName="DeathCity"
                                                       strRightArrowFocusFieldName="DeathCertNumber"
                                                       [blnCheckStrengthCode]="false" [blnFocusOnNextEmptyControl]="false">
                                        </app-textfield>
                                    </div>
                                    <div class="p-0 ml-1">
                                        <app-textfield id="DeathCertNumber" cssFieldCLass="w86" numType="number"
                                                       [disableLabel]="true"
                                                       [maxlength]="6" intMaxDecimal="0"
                                                       [blnIsNumber]="true"
                                                       [objModel]="nonOccupant"
                                                       [blnAllowLeftMostZero]="true"
                                                       strTableName="Non_Occupants" strFieldName="DeathCertNumber"
                                                       #txtNonOccupantDeathCertNumber
                                                       [printOnly]="printOnly"
                                                       [showAllDataListForPrintOnly]="false"
                                                       strComputedFieldName="DeathCer"
                                                       [clearFieldData]="clearFieldData"
                                                       strLeftArrowFocusFieldName="DeathState" strComplexFieldName="DeathCer">
                                        </app-textfield>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div #typAhdInjurWrk *ngIf="!printOnly && intMode == 64">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="injurWrk" name="injurWrk" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="InjurWrk"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdSidewalk [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 256">
                <div [ngClass]="{'pl-2' : printOnly !== true}" class="">
                    <div class="form-group">
                        <app-typeahead id="Sidewalk" name="Sidewalk" typeAheadPosition="right-top"
                                       [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="Sidewalk"
                                       [printOnly]="printOnly"
                                       [clearFieldData]="clearFieldData">
                        </app-typeahead>
                    </div>
                </div>
            </div>

            <div #typAhdSchoolZone [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 256">
                <div [ngClass]="{'pl-2' : printOnly !== true}" class="">
                    <div class="form-group">
                        <app-typeahead id="SchoolZone" name="SchoolZone" typeAheadPosition="right-top"
                                       [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="SchoolZone"
                                       [printOnly]="printOnly"
                                       [clearFieldData]="clearFieldData">
                        </app-typeahead>
                    </div>
                </div>
            </div>

        </div>
    </div>
</form>
