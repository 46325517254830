import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, Directive, Input, ElementRef, OnDestroy, Renderer2, OnChanges, DoCheck, PipeTransform, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DecimalPipe } from '@angular/common';

import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';

import { Acc } from 'src/app/models/acc';
import { Acc_AtmCond } from 'src/app/models/acc-atm-cond';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { KeyCode, Preference, DBMode, CrashSubTab, CustomNvpFilter, MossCISite } from 'src/app/models/enums/app.enums';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { MultiselectComponent } from 'src/app/components/multiselect/multiselect.component';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { LookupTable, FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { LeadingZeroPipe } from 'src/app/pipes/leading-zero.pipe'
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { NonOcc_Race } from 'src/app/models/non-occ-race';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { Subscription } from 'rxjs';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { AutofillService } from 'src/app/services/autofill.service';
import { AccRF } from 'src/app/models/acc-rf';
import { CrashTrafficwayComponent } from '../crash-trafficway/crash-trafficway.component';
import { EdtConfidenceColorComponent } from 'src/app/components/edt-confidence-color/edt-confidence-color.component';
import { Acc_RelRdwy } from 'src/app/models/Acc_RelRdwy';
import { Element_Specify } from 'src/app/models/element-specify';
import { AuthService } from 'src/app/services/auth.service';


@Component({
    selector: 'app-crash-crash',
    templateUrl: './crash-crash.component.html',
    styleUrls: ['./crash-crash.component.css'],
    providers: [LeadingZeroPipe, DecimalPipe]
})


export class CrashCrashComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_CrashCrashComponent: string = null;
    private intYear: number;
    private toYear: number;

    accid: number;
    stateNum: number;
    countyId: number;

    acc: Acc; //Do not initialize to empty object, view child components will wait on this object before they try to initialize, i.e. child components will wait until the data model is available 

    objAtmCondTemplate: Acc_AtmCond;
    objAccRFTemplate: AccRF;
    objRelRdwyTemplate: Acc_RelRdwy;
    countyStrCond: string;
    fieldMetaData: TableFieldElements[];
    @Input() caseId: number;

    @ViewChild('drpCounty',{ static: false }) drpCounty: TypeaheadComponent;
    @ViewChildren('EDTConfidenceColor') edtConfidenceColor: QueryList<EdtConfidenceColorComponent>;

    @ViewChildren('appTrafficway') componentTrafficway: QueryList<CrashTrafficwayComponent>;

    @ViewChild('MossCaseNo', { static: false }) txtMossCaseNo: TypeaheadComponent;

    preferenceCityCountyCheck: boolean = true;
    private objAcc_Original: Acc;

    userList: DrpDownOptions[] = [] as DrpDownOptions[];
    BlankOption: DrpDownOptions = {} as DrpDownOptions;
    selectedMode: number;
    selectedState: number;
    strPriv: string;
    mossSCISiteList: any[];
    sciSiteID: any;

    arrStates: DrpDownOptions[];

    strMossCaseNumOrginal: string = '';
    strErrMessageMossCaseNum: string = '';
    strMossCaseNumNew: string = '';
    objInstanceElementSpecify: Element_Specify;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _vehService: VehicleService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private renderer: Renderer2,
        private leadingZeroPipe: LeadingZeroPipe,
        private pipe: DecimalPipe,
        private _secUserPerferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _changeDetectorRef: ChangeDetectorRef,
        private _authService: AuthService,
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
        this.blnFocusOnFirstField = false;
    }

    public async onBeforeSave() {
       // this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
        if (this.intMode == DBMode.FARS) {
            // we only need this to perform for non FARS mode in the CRSS there is no County Field Enabled. 
            if (this.objAcc_Original != null && this.objAcc_Original.County != -1) {
                if (this.acc.County != this.objAcc_Original.County) {
                    if (this.acc.LongLatInfo) {
                        this.acc.LongLatInfo.Verified = false;
                        this._caseService.UpdateLongLatStatus(this.acc.LongLatInfo);
                    }
                }
            }
        }
        let err:string = "";
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);

        err = this._caseService.dateTimeValidations(this.acc, "crash", 2020);
        if (err != "") {
            this._modalService.setMessage(err, 'error');
            this.blnAlloweSave = false;
            return;
        }

        if (this.blnAlloweSave) {
            if (this.strMossCaseNumOrginal != this.acc.Acc_SS.MOSSCasenum.toString()) {
                let numberPrefix = [];
                for (let startYear = this.intYear; startYear <= this.toYear; startYear++) {
                    numberPrefix.push(startYear.toString().substr(2, 4));
                }
                err = await this._caseService.IsValidateMOSSCaseNumber(this.acc.Acc_SS.MOSSCasenum.toString(), true, this.sciSiteID, numberPrefix.join('|'));
                if (err != "") {
                    this._modalService.setMessage(err, 'error');
                    this.blnAlloweSave = false;
                    return;
                       // this._caseService.ValidateOtherSpecifyObject(this.acc);
                }
            }
            this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);

            //if (this.strErrMessageMossCaseNum != '') {
            //    this._modalService.setMessage(this.strErrMessageMossCaseNum, 'error');
            //    this.blnAlloweSave = false;
            //    this.acc.Acc_SS.MOSSCasenum = this.strMossCaseNumOrginal; 
            //}
        }

    }

    async onMossCaseNoChange($event) {
        this.acc.Acc_SS.MOSSCasenum = $event;
    }

    MOSSCasenumPress(event) {
        // Only AlphaNumeric
        var inp = String.fromCharCode(event.keyCode);
        if (/[a-zA-Z0-9]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    
    public async onBlur(strNewValue: string) {
        this.strMossCaseNumNew = strNewValue

        //TODO: Has some issue when implement onBlur, need to discuss with Sergei
        //if (this.strMossCaseNumOrginal != strNewValue) {
        //    this.strErrMessageMossCaseNum = await this._caseService.IsValidateMOSSCaseNumber(strNewValue);
        //    if (this.strErrMessageMossCaseNum != "") {
        //        this._modalService.setMessage(this.strErrMessageMossCaseNum, 'error');
        //    }
        //} else {
        //    this.strErrMessageMossCaseNum = '';
        //}
    }

    onSelectedUserList($event) {
        this.acc.CaseStats.CrUserID = $event;
        this.focusNextInput();
    }

    onSelectState(event) {
        if (event !== "0") {
            this.selectedState = event;

            if (this.selectedMode == DBMode.MOSS) {
                this.acc.StateNum = this.selectedState;;

            }
        }
    }

    async ngOnInit() {
        //Route params should use subscribe syntax (vs await) to react to URL parameters changing while staying on the same page (ex. user manipulating URL to change case ID)
        super.ngOnInit();

        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intYear = appSettings.intYear;
        this.selectedMode = appSettings.intMode;
        this.strPriv = appSettings.strPrivilege;
        this.toYear = appSettings.toYear;
        console.log(this.strPriv);

        if (this.acc) {
            this.accid = this.acc.AccID;
            this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, 0, 0);
            console.log(this.objInstanceElementSpecify);
        }
        // we may not this below section
        if (this.caseId == 0) {
            this._route.parent.parent.params.subscribe(async params => {
                this.accid = + params['caseid'];
            });
        } else {
            this.accid = this.acc.AccID;
            this.initData();
        }
        this.objAcc_Original = ObjectUtil.CloneModelObject(this.acc);              

    }

    getCaseRecursive(parent) {
        let acc: Acc;
        if (parent.snapshot.data['case']) {
            acc = parent.snapshot.data['case']
            return acc = acc;
        } else {
            let parentRoute = parent.parent;
            acc = this.getCaseRecursive(parentRoute);
        }
        return acc;
    }

    async initData() {
        /*if (this.caseId == undefined) { // if not undefined means calling from print case. 
            this.acc = this._route.parent.parent.snapshot.data['case']
        } else {
            this.acc = getAccFromRoute.snapshot.data['case']
        }
        */

        if (this.acc.AccMon == 99) {
            let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                subInit.unsubscribe();
                let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == FieldName.AccMon) as TypeaheadComponent;
                uiControl.blnAllowFreeText = true;
                uiControl.objTextbox.nativeElement.value = "99";
                uiControl.bindItemAndFocusNextElement(null, false);
            }).bind(this));
        }

        if (this.acc.AccDay == 99) {
            let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                subInit.unsubscribe();
                let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == FieldName.AccDay) as TypeaheadComponent;
                uiControl.blnAllowFreeText = true;
                uiControl.objTextbox.nativeElement.value = "99";
                uiControl.bindItemAndFocusNextElement(null, false);
            }).bind(this));
        }

        //console.log("Crash data->", this.acc);
        this.objAtmCondTemplate = ObjectUtil.InstantiateAtmCond(this.acc);
        this.objAccRFTemplate = ObjectUtil.InstantiateAccRF(this.acc);
        this.objRelRdwyTemplate = ObjectUtil.InstantiateAccRelRdwy(this.acc);

        this.stateNum = this.acc.StateNum;
        if (!this._sharedDataService.selectedState) {
            this._sharedDataService.setSelectedState(this.stateNum);
        }
        this.countyStrCond = " AND StateID=" + this.stateNum;
        this._secUserPerferenceService.isThisPreferenceOn(Preference.CountyCityCheck).then(((blnPreferenceEnabled: boolean) => {
            this.preferenceCityCountyCheck = blnPreferenceEnabled;
        }).bind(this));


        try {
            if (this.acc) {
                if (this.acc.Veh) {
                    console.log('Acc object->', this.acc);
                    this._vehService.visibilityFinalStageBodyClass(this.acc.Veh).then((fsbcVisibilityArr) => {
                        this._vehService.vehicleInfoFilledCheck(this.acc.Veh, fsbcVisibilityArr);
                    });
                }

            }
        }
        catch (ex) {
            console.error(ex);
        }


        if (this.selectedMode == DBMode.MOSS) {
            this.sciSiteID = this.acc.EarlyNotify[0].SCISiteID;
            var sciSite = MossCISite[this.sciSiteID];
            let userDropDown = await this._utilService.GetUserListByPriv(sciSite);

            this.BlankOption.intValue = -1;
            this.BlankOption.strText = '-Please select-';
            this.BlankOption.displayText = '-Please select-';

            if (userDropDown) {
                this.userList.splice(0, 0, this.BlankOption);
                userDropDown.forEach(item => {
                    let option = {} as DrpDownOptions;
                    option.intValue = item.SECUSERID;
                    option.strText = item.FIRSTNAME;
                    option.displayText = item.LASTNAME + ", " + item.FIRSTNAME;

                    this.userList.push(option);
                });
            }

            this._utilService.GetDrpDownListOptions(LookupTable.StateNum, CustomNvpFilter.ByPermissions)
                .subscribe((result: DrpDownOptions[]) => {
                    this.arrStates = result.filter(x => x.intValue != 98 && x.intValue != 99);

                    if (this.arrStates.length == 1) {
                        this.onSelectState(this.arrStates[0].intValue);
                    }
                });

            this.strMossCaseNumOrginal = this.acc.Acc_SS.MOSSCasenum;
        }

    }

    OnAccYearModelChange($event) {
        if ($event) {
            this.acc.EarlyNotify[0].CaseYear = $event.intValue == -1 ? null : $event.intValue;
        }
    }

    OnAccMonthModelChange($event) {
        if ($event) {
            this.acc.EarlyNotify[0].AccMonth = $event.intValue == -1 ? null : $event.intValue;
        }
    }

    OnAccDayModelChange($event) {
        if ($event) {
            this.acc.EarlyNotify[0].AccDay = $event.intValue == -1 ? null : $event.intValue;
        }
    }

    OnAccHrModelChange($event) {
        if ($event) {
            this.acc.EarlyNotify[0].AccHr = $event.intValue == -1 ? null : $event.intValue;
        }
    }

    OnAccMinModelChange($event) {
        if ($event) {
            this.acc.EarlyNotify[0].AccMin = $event.intValue == -1 ? null : $event.intValue;
        }
    }

    ngAfterViewInit(): void {
        if (this.acc.Mode != DBMode.CRSS) {
            super.ngAfterViewInit();
        }
        else {
            let subinit: Subscription = this.componentTrafficway.changes
                .subscribe(((querylist: QueryList<CrashTrafficwayComponent>) => {
                    subinit.unsubscribe();
                    console.log('Trafficway component', this.componentTrafficway);
                    this._sharedService.subReEvalCaseActionButtons.next({ objComponent: [this, querylist.last], objCase: null });

                    let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                        subInit.unsubscribe();
                        this.FindBlankOrViolatedUIElement();
                    }).bind(this));
                }).bind(this));
        }

        let subConfidence: Subscription = this.edtConfidenceColor.changes
            .subscribe(((querylist: QueryList<EdtConfidenceColorComponent>) => {
                subConfidence.unsubscribe();
                if (this._sharedDataService.IsEDTCase(this.acc))
                    querylist.first.SetStandAloneElementEDTColor(this.intStateNum, FieldName.AccDate, CrashSubTab.Crash);
            }).bind(this));
    }
}
