import { Component, OnInit, QueryList, ViewChildren, OnDestroy, Output, EventEmitter, PipeTransform, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { DecimalPipe } from '@angular/common';

//rxjs && rxjs/operators
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, switchMap, tap, finalize } from 'rxjs/operators';

//services
import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { ViolatedRulesService } from 'src/app/services/violated-rules.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { OverriddenRulesService } from 'src/app/services/overridden-rules.service';

//models
import { ViolatedRules } from 'src/app/models/violated-rules';
import { ViolatedRulesSearchResult } from 'src/app/models/violated-rules-search-result';
import { ViolatedRulesState } from 'src/app/models/violated-rules-state';

import { RuleFieldMetaData } from 'src/app/models/rulefield-metadata';
import { TableId, DBMode, CrashSubTab } from 'src/app/models/enums/app.enums';

//components
import { BaseComponent } from 'src/app/helper/basecomponent';

//directives
import { NgbdSortableHeader, SortEvent, SortDirection } from '../../../directives/sortable-header.directive';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
//import { CaseStats } from 'src/app/models/case-stats';

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(violatedRules: ViolatedRules[], column: string, direction: string): ViolatedRules[] {
    if (direction === '') {
        return violatedRules;
    } else {
        return [...violatedRules].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}
function matcheRules(violatedRule: ViolatedRules, searchTerm: string) {
    return violatedRule.RuleID.toLowerCase().includes(searchTerm.toLowerCase());
}

function matchesDefinition(violatedRule: ViolatedRules, searchTerm: string) {
    return violatedRule.Definition.toLowerCase().includes(searchTerm.toLowerCase());
}

function matchesVehicle(violatedRule: ViolatedRules, pipe: PipeTransform, searchTerm: string) {
    return pipe.transform(violatedRule.VNumber).includes(searchTerm);
}

function matchesPerson(violatedRule: ViolatedRules, pipe: PipeTransform, searchTerm: string) {
    return pipe.transform(violatedRule.PNumber).includes(searchTerm);
}

function matchesSeverity(violatedRule: ViolatedRules, pipe: PipeTransform, searchTerm: string) {
    return pipe.transform(violatedRule.Severity).includes(searchTerm);
}

function matchesOverride(violatedRule: ViolatedRules, searchTerm: string) {
    //if (violatedRule.ORideFlag === "T") {
    //    violatedRule.ORideFlag = "Override";
    //}
    return violatedRule.ORideFlag.toLowerCase().includes(searchTerm.toLowerCase());
}

@Component({
    selector: 'app-violated-rules',
    templateUrl: './violated-rules.component.html',
    styleUrls: ['./violated-rules.component.css'],
    providers: [ViolatedRulesService, DecimalPipe]
})
export class ViolatedRulesComponent extends BaseComponent implements OnInit, OnDestroy {
    private _TypeScript_TypeGuard_ViolatedRulesComponent: string = null;
    caseNumber: number;
    stateNum: number;
    accid: number;
    vehicleid: number;
    personid: number;
    nonOccupantid: number;

    fatalNonOverridableCount: number;
    fatalOverridableCount: number;

    severeNonOverridableCount: number;
    severeOverridableCount: number;

    pendingNonOverridableCount: number;
    pendingOverridableCount: number;

    totalNonOverridableCount: number;
    totalOverridableCount: number;

    VIOLATEDRULES: ViolatedRules[];

    //region for en grid variables
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _violatedRules$ = new BehaviorSubject<ViolatedRules[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    public ColummForSorting: string = '';
    public DirectionForSorting: string = '';

    private _state: ViolatedRulesState = {
        page: 1,
        pageSize: 10,

        searchRuleTerm: '',
        searchDefinitionTerm: '',
        searchVehicleTerm: '',
        searchPersonTerm: '',
        searchSeverityTerm: '',
        searchOverrideTerm: '',

        sortColumn: '',
        sortDirection: ''
    };
    //end of region

    ruleFieldMetadata: RuleFieldMetaData[];

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    violatedRulesSubscription: Subscription;

    strModeName: string;
    intYear: number;
    intMode: number; 

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private sharedDataService: SharedDataService,
        protected _caseService: CaseService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        public violatedRulesService: ViolatedRulesService,
        private pipe: DecimalPipe,
        private _overriddenRulesService: OverriddenRulesService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {// we are not using this method at this page, it is only because of BaseComponent extension
        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        let appSettings = await this.sharedDataService.GetAppSettings();        
        if (DBMode[appSettings.intMode]) { 
            this.strModeName = DBMode[appSettings.intMode];
        }
        this.intYear = appSettings.intYear;
        this.intMode = appSettings.intMode;

        this._route.parent.params.subscribe(params => {

            

            this.stateNum = + params['stateNum'];
            this.accid = + params['caseid'];
            this.getViolatedRules();
        });
    }
    //Fetching data from the service(Data Base)
    getViolatedRules() {
        this.violatedRulesSubscription = this.violatedRulesService.GetViolatedRules(this.accid).subscribe(async result => {
            this.VIOLATEDRULES = result;
            //console.log(this.VIOLATEDRULES);

            //console.log("Acc: ", this.acc);

            //if (this.acc.CaseStats) {
            //    let item: CaseStats = await this._caseService.GetAccCaseStats(this.accid);
            //    this.acc.CaseStats = item;

            //    this.pendingNonOverridableCount = this.acc.CaseStats.Pending - this.acc.CaseStats.ORidePending;
            //    this.severeNonOverridableCount = this.acc.CaseStats.Severe - this.acc.CaseStats.ORideSevere;
            //    this.fatalNonOverridableCount = this.acc.CaseStats.Fatal - this.acc.CaseStats.ORideFatal;
            //    this.totalNonOverridableCount = (this.acc.CaseStats.Pending - this.acc.CaseStats.ORidePending) + (this.acc.CaseStats.Severe - this.acc.CaseStats.ORideSevere) + (this.acc.CaseStats.Fatal - this.acc.CaseStats.ORideFatal);

            //    this.pendingOverridableCount = this.acc.CaseStats.ORidePending;
            //    this.severeOverridableCount = this.acc.CaseStats.ORideSevere;
            //    this.fatalOverridableCount = this.acc.CaseStats.ORideFatal;
            //    this.totalOverridableCount = (this.acc.CaseStats.ORidePending) + (this.acc.CaseStats.ORideSevere) + (this.acc.CaseStats.ORideFatal);

            //}

            //if (this.acc.CaseStats) {
            //    this.pendingNonOverridableCount = this.acc.CaseStats.Pending - this.acc.CaseStats.ORidePending;
            //    this.severeNonOverridableCount = this.acc.CaseStats.Severe - this.acc.CaseStats.ORideSevere;
            //    this.fatalNonOverridableCount = this.acc.CaseStats.Fatal - this.acc.CaseStats.ORideFatal;
            //    this.totalNonOverridableCount = (this.acc.CaseStats.Pending - this.acc.CaseStats.ORidePending) + (this.acc.CaseStats.Severe - this.acc.CaseStats.ORideSevere) + (this.acc.CaseStats.Fatal - this.acc.CaseStats.ORideFatal);

            //    this.pendingOverridableCount = this.acc.CaseStats.ORidePending;
            //    this.severeOverridableCount = this.acc.CaseStats.ORideSevere;
            //    this.fatalOverridableCount = this.acc.CaseStats.ORideFatal;
            //    this.totalOverridableCount = (this.acc.CaseStats.ORidePending) + (this.acc.CaseStats.ORideSevere) + (this.acc.CaseStats.ORideFatal);
            //}

            //Non-Overridabl
            this.pendingNonOverridableCount = result.filter(s => s.Severity >= 1 && s.Severity <= 3 && s.ORideFlag === "F").length;
            this.severeNonOverridableCount = result.filter(s => s.Severity >= 4 && s.Severity <= 6 && s.ORideFlag === "F").length;
            this.fatalNonOverridableCount = result.filter(s => s.Severity >= 7 && s.Severity <= 10 && s.ORideFlag === "F").length;
            this.totalNonOverridableCount = result.filter(t => t.ORideFlag === "F").length;

            //Overridable
            this.pendingOverridableCount = result.filter(s => s.Severity >= 1 && s.Severity <= 3 && s.ORideFlag === "T").length;
            this.severeOverridableCount = result.filter(s => s.Severity >= 4 && s.Severity <= 6 && s.ORideFlag === "T").length;
            this.fatalOverridableCount = result.filter(s => s.Severity >= 7 && s.Severity <= 10 && s.ORideFlag === "T").length;
            this.totalOverridableCount = result.filter(t => t.ORideFlag === "T").length;
            
            // hook up search after grid data is avaiable becouse it will filter as part of the config. 
            this._search$.pipe(
                tap(() => this._loading$.next(true))
                , debounceTime(200)
                , switchMap(() => this._search())
                , delay(200)
                , finalize(() => this._loading$.next(false))
                , tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._violatedRules$.next(result.violatedRules);
                this._total$.next(result.total);
            });

            this._search$.next();
        });
    }

    //region for ViolatedRules grid
    get violatedRules$() { return this._violatedRules$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }

    get searchRuleTerm() { return this._state.searchRuleTerm; }
    get searchDefinitionTerm() { return this._state.searchDefinitionTerm; }
    get searchVehicleTerm() { return this._state.searchVehicleTerm; }
    get searchPersonTerm() { return this._state.searchPersonTerm; }
    get searchSeverityTerm() { return this._state.searchSeverityTerm; }
    get searchOverrideTerm() { return this._state.searchOverrideTerm; }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }

    set searchRuleTerm(searchRuleTerm: string) { this._set({ searchRuleTerm }); }
    set searchDefinitionTerm(searchDefinitionTerm: string) { this._set({ searchDefinitionTerm }); }
    set searchVehicleTerm(searchVehicleTerm: string) { this._set({ searchVehicleTerm }); }
    set searchPersonTerm(searchPersonTerm: string) { this._set({ searchPersonTerm }); }
    set searchSeverityTerm(searchSeverityTerm: string) { this._set({ searchSeverityTerm }); }
    set searchOverrideTerm(searchOverrideTerm: string) { this._set({ searchOverrideTerm }); }

    set sortColumn(sortColumn: string) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    private _set(patch: Partial<ViolatedRulesState>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<ViolatedRulesSearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchRuleTerm, searchDefinitionTerm, searchVehicleTerm, searchPersonTerm, searchSeverityTerm, searchOverrideTerm } = this._state;

        let violatedRules = sort(this.VIOLATEDRULES, sortColumn, sortDirection);

        this.ColummForSorting = sortColumn;
        this.DirectionForSorting = sortDirection;

        if (searchRuleTerm !== '') {
            violatedRules = violatedRules.filter(violatedRule => matcheRules(violatedRule, searchRuleTerm));
        }
        if (searchDefinitionTerm !== '') {
            violatedRules = violatedRules.filter(violatedRule => matchesDefinition(violatedRule, searchDefinitionTerm));
        }
        if (searchVehicleTerm !== '') {
            violatedRules = violatedRules.filter(violatedRule => matchesVehicle(violatedRule, this.pipe, searchVehicleTerm));
        }
        if (searchPersonTerm !== '') {
            violatedRules = violatedRules.filter(violatedRule => matchesPerson(violatedRule, this.pipe, searchPersonTerm));
        }
        if (searchSeverityTerm !== '') {
            violatedRules = violatedRules.filter(violatedRule => matchesSeverity(violatedRule, this.pipe, searchSeverityTerm));
        }
        if (searchOverrideTerm !== '') {
            violatedRules = violatedRules.filter(violatedRule => matchesOverride(violatedRule, searchOverrideTerm));
        }
        const total = violatedRules.length;

        violatedRules = violatedRules.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

        return of({ violatedRules, total });
    }

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });
        this.sortColumn = column;
        this.sortDirection = direction;

        this.ColummForSorting = column;
        this.DirectionForSorting = direction;
    }

    onGoTo(violatedRule: ViolatedRules) {

        let subTab: string = 'crash';
        let blnPersonTab: boolean = true;
        let blnNonOccupantTab: boolean = true;

        if (violatedRule.ruleFields) {
            if (violatedRule.ruleFields.find(x => x.TableID == TableId.Non_Occupants) && violatedRule.ruleFields.find(x => x.TableID == TableId.Occupants)) {

                if (violatedRule.VNumber > 0 && violatedRule.PNumber > 0) {
                    blnNonOccupantTab = false;
                    subTab = violatedRule.ruleFields.find(x => x.TableID == TableId.Occupants).SubFormName;
                }
                else if (violatedRule.VNumber == 0 && violatedRule.PNumber > 0) {
                    blnPersonTab = false;
                    subTab = violatedRule.ruleFields.find(x => x.TableID == TableId.Non_Occupants).SubFormName;
                }
            }
            else if (violatedRule.ruleFields.find(x => x.TableID == TableId.NonOccDrug) && violatedRule.ruleFields.find(x => x.TableID == TableId.OccDrug)) {
                if (violatedRule.VNumber > 0 && violatedRule.PNumber > 0) {
                    blnNonOccupantTab = false;
                    subTab = violatedRule.ruleFields.find(x => x.TableID == TableId.OccDrug).SubFormName;
                }
                else if (violatedRule.VNumber == 0 && violatedRule.PNumber > 0) {
                    blnPersonTab = false;
                    subTab = violatedRule.ruleFields.find(x => x.TableID == TableId.NonOccDrug).SubFormName;
                }
            }
            else if (violatedRule.ruleFields.find(x => x.TableID == TableId.NonOcc_Race) && violatedRule.ruleFields.find(x => x.TableID == TableId.Occ_Race)) {
                if (violatedRule.VNumber > 0 && violatedRule.PNumber > 0) {
                    blnNonOccupantTab = false;
                    subTab = violatedRule.ruleFields.find(x => x.TableID == TableId.Occ_Race).SubFormName;
                }
                else if (violatedRule.VNumber == 0 && violatedRule.PNumber > 0) {
                    blnPersonTab = false;
                    subTab = violatedRule.ruleFields.find(x => x.TableID == TableId.NonOcc_Race).SubFormName;
                }
            }
            else if (violatedRule.ruleFields.find(x => x.TableID == TableId.NonOccRF) && violatedRule.ruleFields.find(x => x.TableID == TableId.OccRF)) {
                if (violatedRule.VNumber > 0 && violatedRule.PNumber > 0) {
                    blnNonOccupantTab = false;
                    subTab = violatedRule.ruleFields.find(x => x.TableID == TableId.OccRF).SubFormName;
                }
                else if (violatedRule.VNumber == 0 && violatedRule.PNumber > 0) {
                    blnPersonTab = false;
                    subTab = violatedRule.ruleFields.find(x => x.TableID == TableId.NonOccRF).SubFormName;
                }
            }
            else {
                if (violatedRule.ruleFields && violatedRule.ruleFields.length > 0)
                    subTab = violatedRule.ruleFields[0].SubFormName;
                else
                    this._modalService.setMessage('Rule ' + violatedRule.RuleID + ' is missing tab navigation metadata. This is either because the rule is not applicable to the current year and mode, or a missing entry in the data dictionary table meta.TableFieldElements. Please contact CDAN Help Desk.', 'danger');
            }
        }

        let intPNumber: number = violatedRule.PNumber == 0 ? 1 : violatedRule.PNumber;
        let intVNumber: number = violatedRule.VNumber == 0 ? 1 : violatedRule.VNumber;

        try {
            if (UrlTreeHelper.CrashTabs.includes(subTab) || violatedRule.ruleFields.length == 0) {
                this.sharedDataService.setViolatedRule(violatedRule);
                this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid, subTab]);
            }
            else if (UrlTreeHelper.NonOccupantTabs.includes(subTab) && blnNonOccupantTab) {
                this.ruleFieldMetadata = violatedRule.ruleFields.filter(i => i.TableID != TableId.Occupants && i.TableID != TableId.Occ_Race && i.TableID != TableId.OccRF && i.TableID != TableId.OccDrug);
                violatedRule.ruleFields = this.ruleFieldMetadata;
                this.sharedDataService.setViolatedRule(violatedRule);
                this._router.navigate([this.stateNum, 'case', this.accid, 'nonOccupant', intPNumber, subTab])
            }
            else if (UrlTreeHelper.VehicleTabs.includes(subTab)) {
                this.sharedDataService.setViolatedRule(violatedRule);
                this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', intVNumber, 'vehicle', subTab]);
            }
            else if (UrlTreeHelper.DriverTabs.includes(subTab)) {
                this.sharedDataService.setViolatedRule(violatedRule);
                this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', intVNumber, 'driver', subTab]);  // 1/case/7/vehicle/1/driver/driver
            }
            else if (UrlTreeHelper.PrecrashTabs.includes(subTab) && blnPersonTab) {
                this.sharedDataService.setViolatedRule(violatedRule);
                this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', intVNumber, 'precrash', subTab]);  // 1/case/7/vehicle/1/precrash/roadway
            }
            else if (UrlTreeHelper.PersonTabs.includes(subTab)) {
                this.ruleFieldMetadata = violatedRule.ruleFields.filter(i => i.TableID != TableId.Non_Occupants && i.TableID != TableId.NonOcc_Race && i.TableID != TableId.NonOccRF && i.TableID != TableId.NonOccDrug);
                violatedRule.ruleFields = this.ruleFieldMetadata;
                this.sharedDataService.setViolatedRule(violatedRule);
                this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', intVNumber, 'person', intPNumber, subTab]);   // 1/case/7/vehicle/1/person/1/person
            }
            else {
                this._modalService.setMessage('The tab specified for Rule ' + violatedRule.RuleID + ' is not found in UrlTreeHelper. Please contact CDAN Help Desk.', 'danger');
            }
        }
        catch (ex) {
            this._modalService.setMessage('Could not navigate to tab for rule ' + violatedRule.RuleID + '. Please contact CDAN Help Desk. ' + ex.toString(), 'danger');
        }

        //KM
        this.sharedDataService.setViolatedRuleOR(null);
        if (violatedRule.ORideFlag == "T")
            this.sharedDataService.setViolatedRuleOR(violatedRule);
    }

    OnOverride(violatedRule: ViolatedRules) {
        //this._router.navigate([this.stateNum, 'checkCase', this.accid, 'overrideRule', violatedRule.RuleID], { fragment: "override" });
        this._router.navigate([this.stateNum, 'checkCase', this.accid, 'overrideRule', violatedRule.RuleID], { queryParams: { From: 'override', VNumber: violatedRule.VNumber, PNumber: violatedRule.PNumber } });
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.violatedRulesSubscription) {
            this.violatedRulesSubscription.unsubscribe();
        }
    }
}





