import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, Subscription } from 'rxjs';

import { GenericService }            from './generic.service'
import { GetCalendarDetails_Result } from '../models/get-calendar-details-result';
import { FarsMetricsDetails }        from '../models/fars-metrics-details';
import { CaseSummaryData }           from '../models/case-summary-data';
import { QcBenchmarkComparison }     from '../models/get-qc-benchmark-comparison';
import { GESSummaryData_Result } from '../models/GESSummaryData_Result';


@Injectable({
    providedIn: 'root'
})
export class HomeService {

    hasAdminRigths: BehaviorSubject<any> = new BehaviorSubject(false);
    constructor(
        private _baseService: GenericService<any>
    ) { }

    public GetCalenderReminder(): Observable<GetCalendarDetails_Result[]> {
        let calenderReminder: Observable<GetCalendarDetails_Result[]> = this._baseService.GetCalenderReminder();
        return calenderReminder;
    }

    public GetCaseSummaryData(caseYear: number, mdMode: number): Observable<CaseSummaryData> {

        let summaryData = this._baseService.GetCaseSummaryData(caseYear, mdMode);
        return summaryData;
    }

    public GetFarsMetricsDetails(stateNum: number, caseYear: number, monthlyCountTypeID: number): Observable<FarsMetricsDetails> {

        let metricsDetails = this._baseService.GetFarsMetricsDetails(stateNum, caseYear, monthlyCountTypeID);
        return metricsDetails;
    }

    public GetQcBenchmarkComparison(stateNum: number): Observable<QcBenchmarkComparison> {

        let qcBenchmarkComparison = this._baseService.GetQcBenchmarkComparison(stateNum);
        return qcBenchmarkComparison;
    }

    public get getAdminRights(): Observable<boolean> {
        return this.hasAdminRigths.asObservable()
    }

    public setAdminRights() {
        this._baseService.actionURL = "api/case/AdminRights/";
        let lockedStates = this._baseService.getData();
        lockedStates.subscribe(result => {
            this.hasAdminRigths.next(result);
        })
    }

    // GES Related BELOW

    public GetGESSummaryData(stateNum: number, caseYear: number, mdMode: number): Observable<GESSummaryData_Result> {

        let summaryData = this._baseService.GetGESSummaryData(stateNum, caseYear, mdMode);
        return summaryData;
    }


    // END OF GES

}
