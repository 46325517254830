import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { Veh } from 'src/app/models/veh';
import { Acc } from 'src/app/models/acc';
import { Subscription } from 'rxjs';
import { VehType, UnitType, RBISDataValue } from 'src/app/models/enums/app.enums';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { Element_Specify } from 'src/app/models/element-specify';

@Component({
  selector: 'app-sfr-vehicle-vehicle-notes',
  templateUrl: './sfr-vehicle-vehicle-notes.component.html',
  styleUrls: ['./sfr-vehicle-vehicle-notes.component.css']
})
export class SfrVehicleVehicleNotesComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    public veh: Veh;
    acc: Acc;
    accid: number;
    vehicleid: number;
    private sbsVehicleId: Subscription;
    public VehClone: Veh;


    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        public _autofillService: AutofillService,
        private _secUserPerferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper

    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.accid = this.acc.AccID;
        // console.log(this.acc);
        if (this.sbsVehicleId) {
            this.sbsVehicleId.unsubscribe();
        }
        else {
            this.vehicleid = this._sharedDataService.getVehicleId();
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.SFRVehicle);
            this.VehClone = ObjectUtil.CloneModelObject(this.veh);
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        //if (this.sbsClearForm) {
        //    this.sbsClearForm.unsubscribe();
        //}
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

}
