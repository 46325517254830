<form novalidate #faco="ngForm">
    <div id="crash-other" class="pt-2" *ngIf="acc && intMode">
        <div [ngClass]="{'row' : printOnly === true}">
            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                   
                    <app-typeahead id="schlBus" name="schlBus" typeAheadPosition="right-top"
                                   [objModel]="Acc" strTableName="Acc" strFieldName="SchlBus"
                                   [showAllDataListForPrintOnly]="false" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->
            <div  *ngIf="intMode == 1" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label inputName="RXing" [printOnly]="printOnly"></app-label>
                            </div>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">

                                <app-typeahead id="RXing" name="RXing" typeAheadPosition="right-top" typeAheadClass="form-control w200" [searchAll]="false"
                                               [blnAllowFreeText]="true" [maxlength]="7" [blnAllowFocusNext]="true"
                                               disableLabel="true"
                                               [objModel]="acc" strTableName="Acc" strFieldName="RXing"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData" [showAllDataListForPrintOnly]="false" [intDisabledMode]="4"
                                               [blnFormatUpperCase]="true">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="intMode == 1" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="pt-1 pb-1">
                        <div class="row">
                            <div [ngClass]="[!printOnly ? 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="pl-2">
                                    <app-label inputName="NotTime" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="input-group">
                                    <div class="input-group-append pl-2">
                                        <div class="p-0 mr-1">
                                            <app-typeahead id="NotHr" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="acc.NotHr"
                                                           [blnHideSelectedOptionText]="true"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="NotHr"
                                                           (bindModel)="bindModelValue($event,'NotHr')"
                                                           [showAllDataListForPrintOnly]="false" [printOnly]="printOnly" [clearFieldData]="clearFieldData" strRightArrowFocusFieldName="NotMin"
                                                           [intDisabledMode]="4" [blnCheckStrengthCode]="false" [blnFocusOnNextEmptyControl]="false">
                                            </app-typeahead>
                                        </div>
                                        <p class="pt-1 mr-2 ml-1" *ngIf="acc.Mode == 1">:</p>
                                        <div class="p-0 mr-1">
                                            <app-typeahead id="NotMin" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="acc.NotMin" [blnHideSelectedOptionText]="true"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="NotMin"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [printOnly]="printOnly" [clearFieldData]="clearFieldData"
                                                           strLeftArrowFocusFieldName="NotHr" [intDisabledMode]="4"
                                                           strComplexFieldName="NotTime">
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="intMode == 1" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="pt-1 pb-1">
                        <div class="row">
                            <div [ngClass]="[!printOnly ? 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="pl-2">
                                    <app-label inputName="ArrTime" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="input-group">
                                    <div class="input-group-append pl-2">
                                        <div class="p-0 mr-1">
                                            <app-typeahead id="ArrHr" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="acc.ArrHr" [blnHideSelectedOptionText]="true"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="ArrHr" (bindModel)="bindModelValue($event,'ArrHr')"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [printOnly]="printOnly" [clearFieldData]="clearFieldData"
                                                           strRightArrowFocusFieldName="ArrMin"
                                                           [intDisabledMode]="4"
                                                           [blnCheckStrengthCode]="false" [blnFocusOnNextEmptyControl]="false">
                                            </app-typeahead>
                                        </div>
                                        <p class="pt-1 mr-2 ml-1" *ngIf="acc.Mode == 1">:</p>
                                        <div class="p-0 mr-1">
                                            <app-typeahead id="ArrMin" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="acc.ArrMin" [blnHideSelectedOptionText]="true"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="ArrMin"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [printOnly]="printOnly" [clearFieldData]="clearFieldData"
                                                           strLeftArrowFocusFieldName="ArrHr"
                                                           [intDisabledMode]="4"
                                                           strComplexFieldName="ArrTime">
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="intMode == 1" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="pt-1 pb-1">
                        <div class="row">
                            <div [ngClass]="[!printOnly ? 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="pl-2">
                                    <app-label inputName="HospTime" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="input-group">
                                    <div class="input-group-append pl-2">
                                        <div class="p-0 mr-1">
                                            <app-typeahead id="HospHr" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="acc.HospHr" [blnHideSelectedOptionText]="true"
                                                           [objModel]="acc" strTableName="Acc"
                                                           strFieldName="HospHr"
                                                           (bindModel)="bindModelValue($event,'HospHr')"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [printOnly]="printOnly" [clearFieldData]="clearFieldData"
                                                           strRightArrowFocusFieldName="HospMin"
                                                           [intDisabledMode]="4"
                                                           [blnCheckStrengthCode]="false" [blnFocusOnNextEmptyControl]="false">
                                            </app-typeahead>
                                        </div>
                                        <p class="pt-1 mr-2 ml-1" *ngIf="acc.Mode == 1">:</p>
                                        <div class="p-0 mr-1">
                                            <app-typeahead id="HospMin" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="acc.HospMin" [blnHideSelectedOptionText]="true"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="HospMin"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [printOnly]="printOnly" [clearFieldData]="clearFieldData"
                                                           strLeftArrowFocusFieldName="HospHr"
                                                           [intDisabledMode]="4"
                                                           strComplexFieldName="HospTime">
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="intMode == 1" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="arf"
                                     name="arf"
                                     strTableName="AccRF"
                                     strFieldName="ARf"
                                     [objModel]="acc.AccRF"
                                     [objTemplateModel]="objAccRFTemplate"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div *ngIf="intMode == 1" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="row  pl-2">
                        <div [ngClass]="[!printOnly ? 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <app-label inputName="AddInfo" [printOnly]="printOnly"></app-label>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <!--<app-textfield id="AccAddInfo" cssFieldCLass="w300"
                                           [disableLabel]="true" [blnFormatWithLeading]="false"
                                           [maxlength]="20"
                                           [blnIsNumber]="false"
                                           [objModel]="acc" [(objCurrentValue)]="acc.AddInfo"
                                           strTableName="Acc" strFieldName="AddInfo"
                                           [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="4"
                                           #txtAddInfo
                                           [blnFormatUpperCase]="true">
                            </app-textfield>-->
                            <app-typeahead #txtAddInfo id="AccAddInfo" name="AccAddInfo" typeAheadClass="form-control w300"
                                           [objModel]="acc"
                                           [(objCurrentValue)]="acc.AddInfo"
                                           [disableLabel]="true"
                                           strTableName="Acc"
                                           strFieldName="AddInfo"
                                           [blnHideSelectedOptionText]="true"
                                           [blnAllowFreeText]="true"
                                           [printOnly]="printOnly"
                                           [maxlength]="20"
                                           [clearFieldData]="clearFieldData"
                                           [blnFormatUpperCase]="true"
                                           [blnAllowFocusNext]="true"
                                           [intDisabledMode]="4"
                                           [blnIncludeBlank]="false">
                            </app-typeahead>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="intMode == 1" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group mb-0">
                    <div class="row  pl-2">
                        <div [ngClass]="[!printOnly ? 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <app-label inputName="AddInfo2" [printOnly]="printOnly"></app-label>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <!--<app-textfield id="AccAddInfo1" cssFieldCLass="w300"
                                           [disableLabel]="true" [blnFormatWithLeading]="false"
                                           [maxlength]="20"
                                           [blnIsNumber]="false"
                                           [objModel]="acc" [(objCurrentValue)]="acc.AddInfo2"
                                           strTableName="Acc" strFieldName="AddInfo2"
                                           [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="4"
                                           #txtAddInfo2
                                           [blnFormatUpperCase]="true">
                            </app-textfield>-->
                            <app-typeahead #txtAddInfo2 id="AccAddInfo1" name="AccAddInfo1" typeAheadClass="form-control w300"
                                           [objModel]="acc"
                                           [(objCurrentValue)]="acc.AddInfo2"
                                           [disableLabel]="true"
                                           strTableName="Acc"
                                           strFieldName="AddInfo2"
                                           [blnHideSelectedOptionText]="true"
                                           [blnAllowFreeText]="true"
                                           [printOnly]="printOnly"
                                           [maxlength]="20"
                                           [clearFieldData]="clearFieldData"
                                           [blnFormatUpperCase]="true"
                                           [blnIncludeBlank]="false"
                                           [intDisabledMode]="4">
                            </app-typeahead>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
