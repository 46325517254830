<form novalidate #fnonOccNotes="ngForm">
    <div id="crash-notes" class="pt-2" *ngIf="acc && intMode">
        <div [ngClass]="{'col-12' : printOnly === true}" *ngIf="intMode == 128 || intMode == 256">
            <div class="form-group mb-0">
                <div class="px-2">
                    <app-textfield id="NonOccNotes" [disableLabel]="false"
                                   [blnIsNumber]="false" [intTextAreaRows]="10"
                                   [objModel]="nonOccupant" [(objCurrentValue)]="nonOccupant.Notes"
                                   [blnFormatWithLeading]="false"
                                   strTableName="Non_Occupants" strFieldName="Notes"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-textfield>
                </div>
            </div>
        </div>
    </div>
</form>
