declare global {
    interface String {
        isNullOrEmpty(): boolean;
    }
}

String.prototype.isNullOrEmpty = function (): boolean {
    return (!this || this == null || this == undefined || this == "" || this.length == 0);
}

export { };
