import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceTextPipe'
})
export class ReplaceTextPipe implements PipeTransform {
   transform(strValue: string, strPattern: string, strReplaceWith: string = ''): string {
        if (strValue != null) {
            let regex = new RegExp(strPattern, 'g');
            return strValue.replace(regex, strReplaceWith);
        }
        else
            return strValue;
    }
}
