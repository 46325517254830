<span class="pl-2">
    <button id="btnAgeCalculator_Open" class="btn btn-sm btn-light border-secondary" (click)="open(content, null)" #btnOpen>Age Calculator</button>
</span>


<ng-template #content let-modal>

    <div class="modal-header">
        <!--<h4 class="modal-title" id="modal-basic-title">Age Calculator</h4>-->
        <h4 class="modal-title" id="modal-basic-title">Age Calculator</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form #macontrol="ngForm">
            <div class="form-group">
                <p class="border-bottom border-light aliceblue">This window allows you to enter date of birth and returns the corresponding age</p>
            </div>

            <div class="d-flex justify-content-center">
                <label class="align-self-center" for="dateOfBirth">Enter the Date Of Birth<br />(mm/dd/yyyy)</label>
            </div>

            <div class="form-group">
                <div *ngIf="blnShowValidationMessage==true">
                    <div class="alert alert-danger mt-1" *ngIf="blnRequired" role="alert">
                        Date of Birth is required.
                    </div>
                    <div class="alert alert-danger mt-1" *ngIf="blnInvalid" role="alert">
                        Date of Birth must be a valid date (Ex: mm/dd/yyyy).
                    </div>
                    <div class="alert alert-danger mt-1" *ngIf="blnIsDateInRange" role="alert">                      
                        Date of Birth  must be between {{strMinValidation}} and {{streMaxValidation}}.
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center">
                <div class="form-inline">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="mm/dd/yyyy" id="AgeCalculatorDateOfBirth" name="datePicked" 
                                   [ngModel]="datePicked" (ngModelChange)="onDateSelect(dplvar, $event)" (blur)="onBlur()" (keyup.enter)="onSubmit(dplvar)"
                                   [minDate]="getMinDate" [maxDate]="getMaxDate" required maxlength="10" [autoClose]="inside" (dateSelect)="dage.close()"
                                   ngbDatepicker
                                   #dplvar="ngModel" #dage="ngbDatepicker" ngbAutoFocus>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="dage.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer d-flex justify-content-end">
        <!--<div>Age: {{ calculatedAgeint }}</div>-->
        <button type="button" class="btn btn-primary" (click)="onSubmit(dplvar)" >Submit</button>
        <button type="button" class="btn btn-light border-secondary" (click)="modal.close('Cancel click');onCancel()">Cancel</button>
    </div>

</ng-template>
