<form novalidate #fvpdrug="ngForm">
    <div class="pt-2" *ngIf="person">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="drugInv" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="DrugInv"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="drugSts" name="drugSts" typeAheadPosition="right-top"
                                   [objModel]="person" (bindModel)="OnModelChange($event)"
                                   strTableName="Occupants" strFieldName="DrugInv" [showAllDataListForPrintOnly]="true"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="drugSts" name="drugSts" typeAheadPosition="right-top"
                                   [objModel]="person" (bindModel)="OnModelChange($event)"
                                   strTableName="Occupants" strFieldName="DrugSts" [showAllDataListForPrintOnly]="true"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-12 border border-dark mt-2' : printOnly === true}">
                <div [ngClass]="{'border-top border-dark' : printOnly === true}">

                    <div class="d-flex justify-content-between bg-light">
                        <div class="my-auto pl-2">
                            <label class="col-form-label fw-bold">Drug Toxicology Results</label>
                        </div>
                        <div class="pr-2">
                            <div class="input-group pt-2 pb-2" *ngIf="!printOnly">
                                <div class="my-auto">
                                    <span class="pr-1">Add</span>
                                </div>
                                <span class="w62">
                                    <input type="number" id="inputPerson_onAddResults" name="addResult" [(ngModel)]="addResult" class="form-control form-control-sm for-focus" #addResults (keyup.enter)="onAddResults(addResults.value)" numeric numericType="number" [disabled]="blnDisabled || blnReadOnly">
                                </span>
                                <div class="my-auto">
                                    <span class="px-1">Row(s)</span>
                                </div>
                                <div class="">
                                    <button id="btnPerson_onAddResults" class="btn btn-sm btn-light border-secondary" type="button" (click)="onAddResults(addResults.value)" [disabled]="blnDisabled || blnReadOnly">Go</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="row mt-2">
                            <div class="col-4">
                                <div class="pl-2 my-auto">
                                    <app-label inputName="DrugTst" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>

                            <div class="col-5">
                                <div class="my-auto">
                                    <app-label inputName="DrugRes" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>

                            <div class="col my-auto" *ngIf="!printOnly">
                                <div class="d-flex justify-content-center">
                                    <label class="fw-bold">Action</label>
                                </div>
                            </div>
                        </div>

                        <div class="px-2 py-1" *ngFor="let item of person.OccDrug; let i = index;">
                            <div class="row">
                                <div class="col-4 my-auto">
                                    <app-typeahead [id]="'DrugTst_' + i" typeAheadPosition="right-top" [disabled]="blnDisabled"
                                                   [objModel]="item" strTableName="OccDrug" strFieldName="DrugTst" [intSeqNum]="item.SeqNum" maxlength="2"
                                                   (bindModel)="OnOccDrugModelChange(item.SeqNum, 'DrugTst')"
                                                   disableLabel="true"
                                                   [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData">
                                    </app-typeahead>
                                </div>
                                <div class="col-5 my-auto py-1">
                                    <app-typeahead [id]="'DrugRes_' + i" typeAheadPosition="right-top" [disabled]="blnDisabled"
                                                   [objModel]="item" strTableName="OccDrug" strFieldName="DrugRes" [intSeqNum]="item.SeqNum" maxlength="3" (bindModel)="OnOccDrugModelChange(item.SeqNum, 'DrugRes')"
                                                   disableLabel="true" [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData">
                                    </app-typeahead>
                                </div>
                                <div class="col my-auto" *ngIf="!printOnly">
                                    <div class="d-flex justify-content-center">
                                        <span class="pl-2">
                                            <button type="button" id="inputPerson_onDeleteRow_{{item.SeqNum}}" class="btn btn-sm btn-light border-secondary my-auto" (click)="onDeleteRow(item.SeqNum)" [disabled]="blnDisabled || blnReadOnly"><span class="fw-bold">Delete</span></button>
                                        </span>
                                        <span class="pl-2">
                                            <button type="button" id="inputPerson_onAddRow_{{item.SeqNum}}" class="btn btn-sm btn-light border-secondary my-auto" (click)="onAddRow(i + 1)" [disabled]="blnDisabled || blnReadOnly"><span class="fw-bold">Add</span></button>
                                        </span>
                                        <span class="pl-2">
                                            <button type="button" id="inputPerson_onClearRow_{{item.SeqNum}}" class="btn btn-sm btn-light border-secondary my-auto" (click)="onClearRow(i)" [disabled]="blnDisabled || blnReadOnly"><span class="fw-bold">Clear</span></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="px-2 py-1" *ngIf="printOnly">
                            <div class="row">
                                <div class="col-sm6">
                                    <app-typeahead [id]="taDrugSpecimen" typeAheadPosition="right-top" [disabled]="blnDisabled"
                                                   [objModel]="item" strTableName="OccDrug" strFieldName="DrugTst" maxlength="2"
                                                   disableLabel="true"
                                                   [printOnly]="printOnly"
                                                   [otherOption]="true"
                                                   [clearFieldData]="clearFieldData">
                                    </app-typeahead>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
