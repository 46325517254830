<div class="">
    <!-- This is a helper to manage the dirty/pristine state of ngForm programmatically: ngForm does not react to markAsDirty()
        unless one of it's child controls is dirty via an ngModel manipulation. -->
    <input #txtHelper name="txtHelper" type="text" [(ngModel)]="strHelper" style="display:none;" />
    <div class="row  {{printOnly ? 'd-none' : ''}}" #divContainer>
        <ng-template #rt let-r="result" let-t="term">
            <ngb-highlight [result]="r.displayText" [term]="t"></ngb-highlight>
        </ng-template>
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto" *ngIf="!disableLabel && objMetaData">
            <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo()" style="cursor:pointer;display:inline;"></i>&nbsp;
            <app-label [inputName]="inputName" [inputMetaData]="objMetaData" #currentLabel></app-label>

        </div>
        <!--We are intentionally not binding NGMODEL directly to objModel[strFieldName], objModel[objMetadata.FieldID], instead, we are processing the click and keyup events separately,
            however, we do want NGMODEL to receive model updates as ngModel makes the field participate in NGFORM pristine/dirty infrastructure.-->
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl p-0">
            <div class="container-fluid">
                <div class="row m-0">

                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-1 p-0">
                        <p #lblCodes class="mb-0" for="selectedCodes"></p>
                        <input #txt type="text"
                               [id]="inputName" [name]="inputName"
                               [ngModel]
                               [ngModelOptions]="objNgModelOptions"
                               class="form-control w74"
                               [resultTemplate]="rt"
                               [inputFormatter]="formatter"
                               [ngbTypeahead]="search"
                               (selectItem)="selected($event)"
                               [placement]="multiSelectPosition"
                               (blur)="onBlur($event.target.value)"
                               (click)="click$.next($event.target.value)"
                               (focus)="handleFocus($event)"
                               (keyup)="handleKeyup($event)"
                               (keydown)="handleKeydown($event)"
                               #instance="ngbTypeahead" />
                    </div>

                    <div class="col-sm-10 col-md-10 col-lg-10 col-xl p-0 m-3">
                        <div class="container-fluid p-0">
                            <div class="d-inline-flex w-100">
                                <div class="flex-column w-100">
                                    <div class="d-flex flex-wrap flex-row" *ngIf="objModel">
                                        <input #txtHelper name="txtHelper" type="text" [(ngModel)]="strHelper" style="display:none;" />
                                        <div class="flex-column border bg-dark-grey border-dark rounded mx-1 mb-1 {{obj?.IsSpecify == true ? 'w-100' : ''}}" *ngFor="let obj of objModel">
                                            <div class="d-flex flex-row rounded {{obj?.IsSpecify == true ? 'w-100' : ''}}">
                                                <div class="flex-column {{obj?.IsSpecify == true ? 'w-100' : ''}}">
                                                    <div class="d-flex flex-row" *ngIf="blnEnableManualSort">
                                                        <span class="flex-column ml-1 mb-1 mr-1">
                                                            <span class="align-middle">
                                                                Order#
                                                            </span>
                                                        </span>
                                                        <app-typeahead id="txtSeqNum{{obj.SeqNum}}"
                                                                       [objModel]="obj"
                                                                       strTableName="NonOcc_Race"
                                                                       strFieldName="SeqNum"
                                                                       [blnAllowFreeText]="true"
                                                                       typeAheadClass="form-control-sm flex-column w50"
                                                                       [blnFreeTextWithoutOption]="true"
                                                                       [disableLabel]="true"
                                                                       [maxlength]="2"
                                                                       [(objCurrentValue)]="obj.SeqNum">
                                                        </app-typeahead>

                                                    </div>
                                                    <div class="d-flex flex-row {{ blnEnableManualSort ? 'border-top border-dark': 'align-items-center' }}{{obj?.IsSpecify == true ? 'w-100' : ''}}" *ngIf="objMetaData">
                                                        <span class="flex-column ml-1 mt-1 mr-1" style="max-width: 400px !important;">
                                                            <span class="">
                                                                {{ obj[strFieldName] | valueToDescription :  strDefsLookupTableName  : strFilterCondition : '' : !blnHideNumericalCodePrefix | async}}
                                                            </span>
                                                        </span>
                                                        <span class="flex-column my-auto {{obj?.IsSpecify == true ? 'w-100' : ''}}">
                                                            <app-other-specify #otherSpecify
                                                                               [strTableName]="strTableName"
                                                                               [strFieldName]="strFieldName"
                                                                               strCssClass=""
                                                                               [objOtherSpecifyTemplate]="objOtherSpecifyTemplate"
                                                                               [intElementValue]="obj[strFieldName]"
                                                                               [blnIsEnabled]="obj.IsSpecify">
                                                            </app-other-specify>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="flex-column">
                                                    <span *ngIf="!disabled" (click)="deleteObjectItem(obj)" class="btn btn-sm btn-light border-secondary">
                                                        <i class="fas fa-times"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-container *ngIf="printOnly && intMode != intDisabledMode">
    <div class="print-only">
        <app-label [inputName]="inputName" [printOnly]="true" [inputMetaData]="objMetaData" [disableLabel]="disableLabel" *ngIf="!disableLabel && objMetaData">
        </app-label>
        <div *ngIf="clearFieldData">
            <textarea style="font-size:14px" type="text"
                      class="form-control form-control-sm bg-white print-only-input"
                      readonly disabled="disabled"
                      #printTextArea>
            </textarea>
        </div>
        <ng-container *ngIf="!clearFieldData">
            <div *ngFor="let item of arrSelectedItems">
                <textarea style="font-size:14px"
                          type="text" class="form-control form-control-sm bg-white print-only-input"
                          [ngModel]="item.displayText" [ngModelOptions]="{standalone: true}"
                          readonly disabled="disabled"
                          #printTextArea>
                </textarea>
            </div>
            <div *ngIf="arrSelectedItems.length == 0">
                <textarea style="font-size:14px" type="text"
                          class="form-control form-control-sm bg-white print-only-input"
                          readonly disabled="disabled"
                          #printTextArea>
            </textarea>
            </div>

        </ng-container>
        <ng-container *ngIf="showAllDataListForPrintOnly">
            <div class="row">
                <div class="col-sm-12">
                    <ng-container class="container-fluid" *ngFor="let item of options">
                        <div class="row border-light border-bottom p-1">
                            <div class="col">
                                <ul class="list-group list-group-flush" *ngIf="item.intValue!=-1">
                                    <li class="list-group-item p-0" style="font-size:14px">
                                        <span>{{ item.strValue }}</span><span> - </span><span>{{item.strText}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
