import { ViolatedRules } from '../models/violated-rules';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { SharedDataService, AppSettings } from '../services/shared-data.service';
import { ModalService } from '../services/modal.service';
import { UtilService } from '../services/util.service';
import { UrlTreeHelper } from './UrlTreeHelper';
import { UIElementBase } from './UIElementBase';
import { AutofillService } from '../services/autofill.service';
import { RuleFieldMetaData } from '../models/rulefield-metadata';
import { Subscription } from 'rxjs';
import { GetCaseBlanks_Result } from '../models/GetCaseBlanks_Result';
import { CaseService } from '../services/case.service';
import { DBMode, CrashSubTab } from '../models/enums/app.enums';

export class EditCheckHelper {

    arrUrlSegments: UrlSegment[];
    public intAccid: number;
    public intNonOccupantid: number;
    public intVehicleid: number;
    public intPersonid: number;

    objViolatedRule: ViolatedRules;
    //currentTab: string;
    blnViolatedRuleForDisabled: boolean = true;

    constructor(
        protected _sharedService: SharedDataService,
        protected _modalBaseService: ModalService,
        protected _urlTreeHelper: UrlTreeHelper

    ) {
        this.arrUrlSegments = _urlTreeHelper.arrUrlSegment;
        this._sharedService.getViolatedRule().then(objRule => {
            this.objViolatedRule = objRule;

        })
        this.setURLPathIds();
    }


    public setURLPathIds(): void {
        this.intAccid = UrlTreeHelper.FindAndSetPathId("case", this.arrUrlSegments);//parseInt(urlSegments[urlSegments.findIndex(x => x.path === "case") + 1].path);
        this.intNonOccupantid = UrlTreeHelper.FindAndSetPathId("nonOccupant", this.arrUrlSegments);  //parseInt(urlSegments[urlSegments.findIndex(x => x.path === "nonOccupant") + 1].path);
        this.intVehicleid = UrlTreeHelper.FindAndSetPathId("vehicle", this.arrUrlSegments); //parseInt(urlSegments[urlSegments.findIndex(x => x.path === "vehicle") + 1].path);
        this.intPersonid = UrlTreeHelper.FindAndSetPathId("person", this.arrUrlSegments);  //parseInt(urlSegments[urlSegments.findIndex(x => x.path === "person") + 1].path);
    }

    /**
  * It finds the violated controls and highlight it.
  **/
    private HighlightViolatedElement(objRuleMetadata: RuleFieldMetaData, blnSetClassValid: boolean = true): void {
        //this.SetViolatedRuleMessage(this.objViolatedRule);
        let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            subInit.unsubscribe();
            let arrUiControl: UIElementBase[] = AutofillService.arrControls.filter(i => i.objMetaData && i.objMetaData.FormElementID == objRuleMetadata.ID);
            console.log('UI COntrol->', arrUiControl);
            if (arrUiControl) {
                arrUiControl.forEach((objControl: UIElementBase) => {
                    objControl.setIsInvalidClass(blnSetClassValid);
                    objControl.blnClearHighlighting = false;

                });
            }

            this.blnViolatedRuleForDisabled = this.objViolatedRule.ORideFlag != "T" ? true : false;
            if (!this.blnViolatedRuleForDisabled) {
                this._sharedService.observeblnViolatedRule.next(true);
            }


        }).bind(this));

    }

    /**
     * Finds the control corresponding to the passed-in Check Blanks element and highlights it.
     */
    public static FocusBlankElement(objBlank: GetCaseBlanks_Result): void {
        if (objBlank) {
            let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                subInit.unsubscribe();

                let arrUiControl: UIElementBase[] = AutofillService.arrControls.filter(i => i.objMetaData && i.objMetaData.Field == objBlank.FldName);

                if (arrUiControl) {
                    arrUiControl.forEach((objControl: UIElementBase, index: number) => {
                        objControl.blnClearHighlighting = false;

                        if (arrUiControl.length > 1) {
                            if (index == 0) //For multi-part fields, give focus to the first part.
                                objControl.focusAndHighlightUIElement();

                            objControl.setIsInvalidClass(true);
                        }
                        else {
                            objControl.focusAndHighlightUIElement();
                        }
                    });
                }
                else
                    console.warn(`EditCheckHelper.FocusBlankElement() could not resolve Check Blanks element ${objBlank.FldName} to an onscreen control.`);
            }).bind(this));
        }
        else
            console.warn('EditCheckHelper.FocusBlankElement() was called with null argument.');
    }

    /**
    * It clears the violation message on top of the form and clear the highligting on the controls.
    **/
    public ClearViolatedElement(): void {
        this._modalBaseService.resetEditCheckMessage();
        let arrUiControl: UIElementBase[] = AutofillService.arrControls;
        if (arrUiControl) {
            arrUiControl.forEach((objControl: UIElementBase) => {
                objControl.blnClearHighlighting = true;
                objControl.setIsInvalidClass(false);

            });
        }
        this.objViolatedRule = null;
        this.blnViolatedRuleForDisabled = true;
        this._sharedService.setViolatedRule(null);
    }


    private SetViolatedRuleMessage(violatedRule: ViolatedRules): void {

        this._modalBaseService.setEditCheckMessage("Rule# " + violatedRule.RuleID + " - " + violatedRule.Definition);
    }

    /**
    * It triggers in baseComponent to find and highlight the violated ui controls in tabs.
    **/
    public async FindAndHighlightEditCheckElement(violatedRule: ViolatedRules) {
        this._modalBaseService.resetEditCheckMessage();
        this.blnViolatedRuleForDisabled = true;
        if (violatedRule && violatedRule.ruleFields.length > 0) {
            violatedRule.ruleFields.forEach(async field => {
                let strCurrentForm: string = UrlTreeHelper.FindAndSetCurrentTab(this.arrUrlSegments);
                let strFieldSubForm: string = field.SubFormName;
                let blnSubFormMatch: boolean = strCurrentForm.toLowerCase() == strFieldSubForm.toLowerCase();
                if (blnSubFormMatch) {

                    if (UrlTreeHelper.NonOccupantTabs.includes(strFieldSubForm)) {
                        if ((violatedRule.PNumber == this.intNonOccupantid)) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                        else if (violatedRule.PNumber == 0) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                    }
                    else if (UrlTreeHelper.DriverTabs.includes(strFieldSubForm)) {
                        if ((violatedRule.VNumber == this.intVehicleid)) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                        if ((violatedRule.VNumber == 0)) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                    }
                    else if (UrlTreeHelper.VehicleTabs.includes(strFieldSubForm)) {
                        if ((violatedRule.VNumber == this.intVehicleid)) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                        else if (violatedRule.VNumber == 0) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                    }
                    else if (UrlTreeHelper.PersonTabs.includes(strFieldSubForm)) {
                        if ((violatedRule.VNumber == this.intVehicleid)) {
                            if ((violatedRule.PNumber == this.intPersonid)) {
                                this.SetViolatedRuleMessage(this.objViolatedRule);
                                this.HighlightViolatedElement(field);
                            }
                            else if (violatedRule.PNumber == 0) {
                                this.SetViolatedRuleMessage(this.objViolatedRule);
                                this.HighlightViolatedElement(field);
                            }
                        }
                        else if (violatedRule.VNumber == 0) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                    }
                    else if (UrlTreeHelper.PrecrashTabs.includes(strFieldSubForm)) {
                        if (violatedRule.VNumber == this.intVehicleid) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                        else if (violatedRule.VNumber == 0) {
                            this.SetViolatedRuleMessage(this.objViolatedRule);
                            this.HighlightViolatedElement(field);
                        }
                    }
                    else if (UrlTreeHelper.CrashTabs.includes(strFieldSubForm)) {
                        this.SetViolatedRuleMessage(this.objViolatedRule);
                        this.HighlightViolatedElement(field);
                    }
                    //else {
                    //    this.blnViolatedRuleForDisabled = true;
                    //}
                }

            });
        }
    }

}
