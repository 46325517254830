<!-- ngIf ensures view child components do not try to initialize until the data model is available, alternatively use a data model resolver at the routing level to delay route activation until model is available -->
<form #facc="ngForm" novalidate name="crashForm">
    <div *ngIf="acc && intMode" id="crash-crash" class="mt-2 mb-2">

        <div [ngClass]="{'row' : printOnly === true}">

            <div *ngIf="intMode == 256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                                <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                    <app-label class="my-auto" inputName="MOSSCasenum" [isBold]="true" ></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }">
                                <div class="input-group">
                                    <div class="input-group-append" [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                        <div class="p-0 mr-1">
                                            <input id="MossCaseNo" type="text" [ngClass]="{'form-control' : printOnly != true}" name="MossCaseNo" [ngModel]="acc.Acc_SS.MOSSCasenum" [disabled]="blnReadOnly || printOnly"
                                                   maxlength="8" (ngModelChange)="acc.Acc_SS.MOSSCasenum = $event; onMossCaseNoChange($event)"
                                                   (keypress)="MOSSCasenumPress($event)"
                                                   (blur)="onBlur($event.target.value)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="intMode == 256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                                <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                    <app-label inputName="CrUserID"  [isBold]="true"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }">
                                <div class="input-group">
                                    <div class="input-group-append" [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                        <div class="p-0 mr-1">
                                            <select id="UserList" name="UserList" [ngClass]="{'form-control for-focus w475' : printOnly != true}" [disabled]="blnReadOnly || printOnly"
                                                    [ngModel]="acc.CaseStats.CrUserID" (ngModelChange)="acc.CaseStats.CrUserID = $event; onSelectedUserList($event)"
                                                    #user="ngModel">
                                                <option *ngFor="let user of userList" [ngValue]="user.intValue">
                                                    <!--{{user.intValue}} : {{user.displayText}}-->
                                                    {{user.displayText}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="intMode == 256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                                <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                    <app-label inputName="ENPARNumber1"  [isBold]="true"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }">
                                <div class="input-group">
                                    <div class="input-group-append" [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                        <div class="p-0 mr-1">
                                            <!--The flag ---[blnFormatWithLeading]="false"--- has been added to omit formatting for StateCaseNum.-->
                                            <app-textfield id="PARNumber" name="PARNumber" class="" cssFieldCLass="w475 for-focus"
                                                           [disableLabel]="true"
                                                           [maxlength]="20"
                                                           [blnFormatWithLeading]="false"
                                                           [blnIsNumber]="false"
                                                           [objModel]="acc.EarlyNotify[0]" [(objCurrentValue)]="acc.EarlyNotify[0].PARNumber"
                                                           strTableName="EarlyNotify" strFieldName="PARNumber" [printOnly]="printOnly"
                                                           #txtPARNumber>
                                            </app-textfield>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="intMode == 256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                                <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                    <app-label inputName="ENState1"  [isBold]="true"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }">
                                <div class="input-group">
                                    <div class="input-group-append" [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                        <div class="p-0 mr-1">
                                            <select id="State" name="State" class="form-control w100" [disabled]="blnReadOnly || printOnly" [ngModel]="acc.StateNum" (ngModelChange)="acc.StateNum = $event;onSelectState($event)"
                                                    #state="ngModel">
                                                <option *ngFor="let state of arrStates" [ngValue]="state.intValue">
                                                    {{state.intValue | enumAsString : 'States' }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                                <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                    <app-label inputName="AccDate" [printOnly]="printOnly"  [isBold]="true"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }">
                                <div class="input-group">
                                        <div class="" [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                        <div class="input-group-append">
                                            <app-typeahead id="AccDateMonth" typeAheadPosition="right-top" [disableLabel]="true"
                                                           [(objCurrentValue)]="acc.AccMon" [blnHideSelectedOptionText]="true" [ngClass]="test"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="AccMon"
                                                           (bindModel)="OnAccMonthModelChange($event)"
                                                           [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [showTextboxInSamePositionForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           [blnCheckStrengthCode]="false" [blnFocusOnNextEmptyControl]="false">
                                            </app-typeahead>
                                        </div>
                                       </div>
                                        <p class="px-2" [ngClass]="{'mb-0' : printOnly === true }">/</p>
                                        <div class="input-group-append p-0 mr-1 px-1">
                                            <app-typeahead id="AccDateDay" typeAheadPosition="right-top" [disableLabel]="true"
                                                           [(objCurrentValue)]="acc.AccDay" [blnHideSelectedOptionText]="true"
                                                           (bindModel)="OnAccDayModelChange($event)"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="AccDay" [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [showTextboxInSamePositionForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           strComplexFieldName="AccDate" [blnCheckStrengthCode]="false">
                                            </app-typeahead>
                                        </div>
                                        <p class="px-2" [ngClass]="{'mb-0' : printOnly === true }">/</p>
                                        <div class="input-group-append p-0 mr-1" *ngIf="intMode != 256">
                                            <div class="form-control w74 disabled {{ printOnly === true ? 'form-control-sm': ''}}" style="background-color:#e9ecef;">{{intYear}}</div>
                                        </div>
                                        <div #typAhdCaseYear class="input-group-append" *ngIf="intMode == 256">
                                            <app-typeahead id="AccDateYear" typeAheadPosition="right-top" [disableLabel]="true"
                                                           [(objCurrentValue)]="acc.CaseYear" [blnHideSelectedOptionText]="true"
                                                           (bindModel)="OnAccYearModelChange($event)"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="CaseYear" [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [showTextboxInSamePositionForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           strComplexFieldName="AccDate" [blnCheckStrengthCode]="false">
                                            </app-typeahead>
                                        </div>
                                        <app-edt-confidence-color #EDTConfidenceColor
                                                                  strTableName="Acc"
                                                                  strFieldName="AccDay"
                                                                  strFieldParam="AccDate"
                                                                  [objModel]="acc"
                                                                  [selectedValue]="-1">
                                        </app-edt-confidence-color>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }" class="">
                                <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                    <app-label inputName="AccTime" [printOnly]="printOnly"  [isBold]="true"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }" class="">
                                <div class="input-group">
                                    <div class="input-group" [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                        <div class="input-group-append">
                                            <app-typeahead id="AccTimeHr" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="acc.AccHr" [blnHideSelectedOptionText]="true"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="AccHr" [printOnly]="printOnly"
                                                           (bindModel)="OnAccHrModelChange($event)"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           [showTextboxInSamePositionForPrintOnly]="false"
                                                           [blnFocusOnNextEmptyControl]="false"
                                                           [blnCheckStrengthCode]="false">
                                            </app-typeahead>
                                        </div>
                                        <p class="px-2" [ngClass]="{'mb-0' : printOnly === true }">:</p>
                                        <div class="input-group-append px-1">
                                            <app-typeahead id="AccTimeMin" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="acc.AccMin" [blnHideSelectedOptionText]="true"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="AccMin"
                                                           (bindModel)="OnAccMinModelChange($event)"
                                                           [printOnly]="printOnly" [showAllDataListForPrintOnly]="false"
                                                           [showTextboxInSamePositionForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           strComplexFieldName="AccTime">
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div #typAhdRelJuncInter *ngIf="intMode==64 && !printOnly" class="card border border-secondary bg-light m-2">
                <div class="card-body border border-secondary bg-light p-1 margin-border">
                    <div class="card-title fw-bold bg-light">Relation To Junction</div>

                    <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                        <div class="form-group pl-2">
                            <app-typeahead id="relJuncInter" name="relJuncInter"
                                           [objModel]="acc" strTableName="Acc" strFieldName="RelJuncInter"
                                           [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                            </app-typeahead>
                        </div>
                    </div>

                    <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                        <div class="form-group pl-2">
                            <app-typeahead id="drpJunc" name="drpJunc"
                                           [objModel]="acc" strTableName="Acc" strFieldName="RelJunc" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                            </app-typeahead>
                        </div>
                    </div>

                </div>
            </div>

            <div *ngIf="intMode==64 && printOnly" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="relJuncInter" name="relJuncInter"
                                   [objModel]="acc" strTableName="Acc" strFieldName="RelJuncInter" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div *ngIf="intMode==256 && !printOnly" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="relJuncInter" name="relJuncInter" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [objModel]="acc" strTableName="Acc" strFieldName="RelJunc" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div *ngIf="intMode==64 && printOnly" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="drpJunc" name="drpJunc"
                                   [objModel]="acc" strTableName="Acc" strFieldName="RelJunc" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdIntersectionType *ngIf="intMode==64 || intMode==128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="drpIntersectionType" name="drpIntersectionType"
                                   [objModel]="acc" strTableName="Acc" strFieldName="IntersectionType" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdTrafFlow *ngIf="intMode == 256" [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="trafflow" name="trafflow" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="TrafFlow"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhddrpRelatedRoadway *ngIf="intMode==64 || intMode==128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="drpRelatedRoadway" name="drpRelatedRoadway"
                                   [objModel]="acc" strTableName="Acc" strFieldName="RelRdwy" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div *ngIf="intMode==256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-multiselect id="RelRdwy"
                                     strTableName="Acc_RelRdwy"
                                     strFieldName="RelRdwy"
                                     [objTemplateModel]="objRelRdwyTemplate"
                                     [objModel]="acc.Acc_RelRdwy" [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="true"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="drpConstructionZone" name="drpConstructionZone"
                                   [objModel]="acc" strTableName="Acc" strFieldName="ConsZone" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="drpLightConditions" name="drpLightConditions"
                                   [objModel]="acc" strTableName="Acc" strFieldName="LightCond" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdAtmCond1 *ngIf="intMode==64 || intMode==128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-multiselect id="slctAtmCond"
                                     strTableName="Acc_AtmCond"
                                     strFieldName="ElementValue"
                                     strDefsLookupTableName="AtmCond"
                                     [objTemplateModel]="objAtmCondTemplate"
                                     [objModel]="acc.Acc_AtmCond" [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="true"
                                     [clearFieldData]="clearFieldData"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-multiselect>
                </div>
            </div>

            <div #typAhdAtmCond2 *ngIf="intMode==256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-multiselect id="slctAtmCond"
                                     multiSelectPosition="right-top"
                                     strTableName="Acc_AtmCond"
                                     strFieldName="ElementValue"
                                     [objTemplateModel]="objAtmCondTemplate"
                                     [objModel]="acc.Acc_AtmCond" [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-multiselect>
                </div>
            </div>

            <!--<div *ngIf="intMode==256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
        <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
            <app-typeahead id="AtmCond" name="AtmCond" typeAheadPosition="right-top"
                           [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="AtmCond"
                           [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                           [printOnly]="printOnly" [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->

            <div #typAhdARf *ngIf="intMode==64 || intMode==128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="arf"
                                     name="arf"
                                     strTableName="AccRF"
                                     strFieldName="ARf"
                                     [objModel]="acc.AccRF"
                                     [objTemplateModel]="objAccRFTemplate"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="1">
                    </app-multiselect>
                </div>
            </div>

            <div *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="schlBus" name="schlBus" typeAheadPosition="right-top"
                                   [objModel]="acc" strTableName="Acc" strFieldName="SchlBus"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

        </div>
    </div>
</form>
