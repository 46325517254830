<div class="modal-header">
    <h4 class="modal-title">
        Pedestrian Data
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-warning text-center m-2" role="alert" style="height:6rem;font-size:medium;" *ngIf="noCopyAvailable">
        There are no other Pedestrian to copy data. <br /> Click Close to return to the main form. <br />
        <!--Why we are using inputs type="button"???
            Can we change it to Button type=buton???
            Can we also add id to every elements on the forms!!!???-->
        <button type="button" value="Close" (click)="modal.close(null)">Close </button>
    </div>
    <div class="border rounded p-2 text-center" *ngIf="!noCopyAvailable">
        <p>This window allows you to copy data from previously entered Pedestrians.</p>

        <div class="p-3 mb-2 bg-secondary text-white"><strong>Select the appropriate Pedestrian </strong></div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col"> </th>
                    <th scope="col">Pedestrian #</th>
                    <th scope="col">Crash Location</th>
                    <th scope="col">Pedestrian Position</th>
                    <th scope="col">Motorist Direction</th>
                    <th scope="col">Motorist Maneuver</th>
                    <th scope="col">Leg Intersection</th>
                    <th scope="col">Scenario</th>
                    <th scope="col">Pedestrian Direction</th>
                    <th scope="col">Crash Type</th>
                    <th scope="col">School Zone</th>
                    <th scope="col">Marked Crosswalk</th>
                    <th scope="col">Sidewalk Presence</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of tmpGrid; index as i">
                    <td>
                        <!--Why we are using inputs type="button"???
                        Can we change it to Button type=buton???
                        Can we also add id to every elements on the forms!!!???-->
                        <button type="button" value="Select" (click)="modal.close(item.RowItem)">Select </button>
                    </td>
                    <th scope="row">{{ item.PNumber }}</th>
                    <td>
                        {{ item.CrashLocation }}
                    </td>
                    <td>
                        {{  item.PedPosition }}
                    </td>
                    <td>
                        {{item.MotDirection}}
                    </td>
                    <td>
                        {{item.MotManeuver }}
                    </td>
                    <td>
                        {{  item.LegIntersection }}
                    </td>
                    <td>
                        {{  item.Scenario }}
                    </td>
                    <td>
                        {{ item.PedDirection}}
                    </td>
                    <td>
                        {{  item.CrashType}}
                    </td>
                    <td>
                        {{ item.SchoolZone}}
                    </td>
                    <td>
                        {{  item.Crosswalk}}
                    </td>
                    <td>
                        {{ item.Sidewalk}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light" *ngIf="!noCopyAvailable" (click)="modal.close(null)">Close</button>
</div>
