import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DBMode } from 'src/app/models/enums/app.enums';
import { Acc } from 'src/app/models/acc';

@Component({
    selector: 'app-avoindace-notification',
    templateUrl: './avoindace-notification.component.html',
    styleUrls: ['./avoindace-notification.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AvoindaceNotificationComponent implements OnInit {

    isFirstPopup: false;
    caseNumber: number = 0;
    intMode: number;
    strAppName: string;
    ParId: string;
    acc: Acc;
    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
        switch (this.intMode) {
            case DBMode.FARS:
                this.strAppName = 'FARS';
                break;
            case DBMode.CRSS:
                this.strAppName = 'CRSS';
                this.ParId = this.acc.EarlyNotify[0].PARID.toString().slice(4);
                break;
            default:
        }
    }

}
