<form novalidate #fvdl="ngForm">
    <!--<pre>{{fvdl.value | json}}</pre>-->
    <div class="pt-2" *ngIf="dri">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="nonCDLType" typeAheadPosition="right-top" class="pt-0"
                                   [objModel]="dri" strTableName="Dri" strFieldName="NonCDLType"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="nonCDL" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="NonCDL" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div *ngIf="printOnly && intMode == 1" class="col-md-12 " style="margin-top:120px!important"></div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="drivPres" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="CommLic" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="licEndor" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="LicEndor" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="licComp" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="LicComp" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="licRestrict" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="LicRestrict" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="feet" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="Feet" [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="Inches" [printOnly]="printOnly" inputName="Inches"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-height-calculator *ngIf="!blnReadOnly"
                                                       [objDri]="dri"
                                                       (setFeetInchesEvent)="setFeetAndInches($event)">
                                </app-height-calculator>
                            </span>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="inches" typeAheadPosition="right-top"
                                               [disableLabel]="true"
                                               [objModel]="dri" strTableName="Dri" strFieldName="Inches"
                                               [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
        <div class="form-group pl-2">
            <app-typeahead id="inches" typeAheadPosition="right-top"
                           [objModel]="dri" strTableName="Dri" strFieldName="Inches" [printOnly]="printOnly"
                           [showAllDataListForPrintOnly]="false"
                           [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="weight" typeAheadPosition="right-top" [blnShowSpecialValuesOnly]="true"
                                   [objModel]="dri" strTableName="Dri" strFieldName="Weight" [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
        </div>
</form>
