import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LoadingFormService } from '../services/loading-form.service';
import { finalize } from 'rxjs/operators';
import { GenericService } from '../services/generic.service';
import { LogEntry } from '../models/LogEntry';
import { AppSettings } from '../services/shared-data.service';

@Injectable()
export class SlowRequestLoggingInterceptor implements HttpInterceptor {

    /**
     * Requests taking longer than this threshold should be logged to identify bottlenecks including end-user Internet connection quality and DOT network responsiveness.
     **/
    public intClientSideSlowRequestThresholdMillisecond: number = 10000;

    constructor(
        private _baseService: GenericService<LogEntry>
        ) {
        //Delay app settings retrieval until after user authentication has had a chance to occur
        setTimeout((async () => {
            try {
                this._baseService.actionURL = "api/util/getappsettings?slowrequestlogginginterceptor";
                let objAppSettings: AppSettings = await this._baseService.getData().toPromise();
                this.intClientSideSlowRequestThresholdMillisecond = objAppSettings.intClientSideSlowRequestThresholdMillisecond
            }
            catch (objError) {
                console.warn('Failed to get app settings.', objError);
            }
        }).bind(this), 10000);
    }

    /**
     * Measures request flight time and dispatches a log entry if it exceeded threshold.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let strUrl: string = request.urlWithParams.toLowerCase();

        if(strUrl.endsWith('/'))
            strUrl = strUrl.substr(0, strUrl.length - 1); //Trailing slash is legal 

        if (this.intClientSideSlowRequestThresholdMillisecond > 0 &&
            !strUrl.endsWith('api/util/clientlog') && //Avoid possibility of recursive infinite loop
            !strUrl.endsWith('api/util/getappsettings?slowrequestlogginginterceptor') //Do not monitor own app settings call, but log all others
            ) {
            let intRequestStartMs: number = Date.now();

            //Continue to next interceptor/handler. HttpRequests are daisy chained together, so no need to worry about multiple observers of request observable
            return next.handle(request).pipe(finalize( //finalize pipe means that following logic is called on both success and failure
                (async () => { 
                    try {
                        let intElapsedTime: number = Date.now() - intRequestStartMs;

                        if (intElapsedTime > this.intClientSideSlowRequestThresholdMillisecond) {
                            let objLogEntry: LogEntry = {
                                _TypeScript_TypeGuard_LogEntry: null,
                                Level: 'Warning',
                                Logger: 'SlowRequestLoggingInterceptor',
                                Message: 'Request to ' + request.url + ' took ' + intElapsedTime + 'ms.',
                                Exception: request.urlWithParams + '\r\n' + JSON.stringify(request.body)
                            } as LogEntry;

                            this._baseService.actionURL = 'api/util/ClientLog';
                            await this._baseService.updateEntity(objLogEntry).toPromise(); //Converting to promise so that observer is not needed
                        }
                    }
                    catch (ex) {
                        console.error('SlowRequestLoggingInterceptor.intercept(): ', ex);
                    }
                }).bind(this)));
        }
        else
            return next.handle(request);
    }
}
