
<div *ngIf="intMode" id="printDiv" #contentBody>

    <ng-container *ngIf="printCase || printCrash">
        <div class="page">

            <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
            <h6 class="text-center">CRASH LEVEL</h6>
            <ng-container>
                <div class="row">
                    <div class="col-sm-2" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                    <div class="col-sm-2" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                    <div class="col-sm-2" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                    <div class="col-sm-2" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                    <ng-container *ngIf="intMode != 4">
                        <div class="col-sm-5"> <span class="print-font-size-label">**Number of Forms Submitted for Persons Not in Motor Vehicles</span> <input type="text" disabled="disabled" [value]="acc.Non_Occupants.length" class="form-control form-control-sm bg-white" /> </div>
                        <div class="col-sm-3"> <span class="print-font-size-label">**Number of Vehicle Submitted</span> <input type="text" disabled="disabled" [value]="acc.Veh.length" class="form-control form-control-sm bg-white" /> </div>
                    </ng-container>
                    <ng-container *ngIf="intMode == 4">
                        <div class="col-sm-4"> <span class="print-font-size-label">PAR #</span> <input type="text" disabled="disabled" [value]="parNumber" class="form-control form-control-sm bg-white" /> </div>
                        <div class="col-sm-4"> <span class="print-font-size-label">**Number of Vehicle Submitted</span> <input type="text" disabled="disabled" [value]="acc.Veh.length" class="form-control form-control-sm bg-white" /> </div>
                    </ng-container>
                </div>
                <div class="row">
                    <ng-container *ngIf="intMode != 4">
                        <div class="col-sm-5"> <span class="print-font-size-label">**Number of Motor Vehicle Occupant Forms Submitted</span> <input type="text" disabled="disabled" [value]="numberMvOccupant" class="form-control form-control-sm bg-white" /> </div>
                        <div class="col-sm-4"> <span class="print-font-size-label">State Case #</span> <input type="text" disabled="disabled" [value]="acc.StateCaseID" class="form-control form-control-sm bg-white" /> </div>
                    </ng-container>
                    <ng-container *ngIf="intMode == 4">
                        <div class="col-sm-5"> <span class="print-font-size-label">**Number of Forms Submitted for Persons Not in Motor Vehicles</span> <input type="text" disabled="disabled" [value]="acc.Non_Occupants.length" class="form-control form-control-sm bg-white" /> </div>
                        <div class="col-sm-5"> <span class="print-font-size-label">**Number of Motor Vehicle Occupant Forms Submitted</span> <input type="text" disabled="disabled" [value]="numberMvOccupant" class="form-control form-control-sm bg-white" /> </div>
                    </ng-container>
                </div>
            </ng-container>
            <app-crash-crash [caseId]="acc.AccID" [printOnly]="true" [clearFieldData]="clearFieldData"></app-crash-crash>
        </div>
        <div class="page">
            <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
            <h6 class="text-center">CRASH LEVEL - CRASH EVENTS</h6>
            <app-crasheventlist [printOnly]="true" [clearFieldData]="clearFieldData"> </app-crasheventlist>
        </div>
        <div class="page" *ngIf="intMode == 64 || intMode == 128 || intMode == 256">
            <p class="h6 text-center mt-2">CRASH LEVEL - TRAFFICWAY</p>
            <app-crash-traffic [printOnly]="true" [clearFieldData]="clearFieldData"></app-crash-traffic>
        </div>
        <!--<div class="page" *ngIf="intMode == 128">
            <p class="h6 text-center mt-2">CRASH LEVEL - OTHER</p>
            <app-crash-other [printOnly]="true" [clearFieldData]="clearFieldData"></app-crash-other>
        </div>-->
        <div class="page" *ngIf="intMode == 64 || intMode == 128 || intMode == 256" >
            <p class="h6 text-center mt-2">CRASH LEVEL - CRASH NOTES</p>
            <app-crash-notes [printOnly]="true" [clearFieldData]="clearFieldData"></app-crash-notes>
        </div>
        <div class="page" *ngIf="intMode == 128">
            <p class="h6 text-center mt-2">CRASH LEVEL - NON-MOTORIST</p>
            <app-non-motorist [printOnly]="true" [clearFieldData]="clearFieldData"></app-non-motorist>
        </div>
    </ng-container>

    <ng-container *ngIf="printCase || printNonOccupant">
        <ng-container *ngFor="let NonOccupantItem of acc.Non_Occupants">
            <ng-container *ngIf="pOrVNumber == 0 || NonOccupantItem.PNumber == pOrVNumber ">
                <div class="page">

                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center">PERSON LEVEL (NOT A MV OCCUPANT)</h6>
                    <ng-container>
                        <div class="row">
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>
                            <div class="col-sm-4"> <span class="print-font-size-label">**Person Number</span> <input type="text" disabled="disabled" [value]="NonOccupantItem.PNumber" class="form-control form-control-sm bg-white" /> </div>
                        </div>

                        <app-non-occupant-non-occupant [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-non-occupant>
                    </ng-container>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">OTHER INFORMATION (NOT A MV OCCUPANT) </h6>
                    <app-non-occupant-other [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-other>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">PEDESTRIAN INFORMATION (NOT A MV OCCUPANT) </h6>
                    <app-non-occupant-pedestrian [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-pedestrian>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">NON-MOTORIST ACTION INFORMATION (NOT A MV OCCUPANT) </h6>
                    <app-non-occupant-non-motorist-actions [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-non-motorist-actions>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">ALCOHOL INFORMATION (NOT A MV OCCUPANT) </h6>
                    <app-non-occupant-alcohol [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-alcohol>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">DRUG INFORMATION (NOT A MV OCCUPANT) </h6>
                    <app-non-occupant-drug [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-drug>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">SUPPLEMENTAL INFORMATION (NOT A MV OCCUPANT) </h6>
                    <app-non-occupant-supplemental [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-supplemental>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">INJURY INFORMATION (NOT A MV OCCUPANT) </h6>
                    <app-non-occupant-injury [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-injury>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">NOTES INFORMATION (NOT A MV OCCUPANT) </h6>
                    <app-non-occupant-notes [printOnly]="true" [clearFieldData]="clearFieldData" [printNonOccupantNum]="NonOccupantItem.PNumber"></app-non-occupant-notes>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <!--
    print case
    -->
    <ng-container *ngIf="printCase && intMode != 256">
        <ng-container *ngFor="let vehItem of acc.Veh;">
            <ng-container *ngIf="pOrVNumber == 0 || vehItem.VNumber == pOrVNumber ">
                <div class="page">

                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center">VEHICLE LEVEL # {{vehItem.VNumber}}</h6>
                    <ng-container>
                        <div class="row">
                            <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-3"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                            <div class="col-sm-3"> <span class="print-font-size-label">**Number Of Occupants </span> <input type="text" disabled="disabled" [value]="vehItem.Occupants.length" class="form-control form-control-sm bg-white input-sm" /> </div>
                        </div>

                        <app-vehicle-vehicle-vehicle [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-vehicle>
                    </ng-container>
                </div>
                <div class="page" *ngIf="intMode == 128">
                    <h6 class="text-center mt-2">DAMAGED AREAS FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-damaged-areas [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-damaged-areas>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">VIN INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-vin [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-vin>
                </div>
                <div class="page" *ngIf="intMode == 64">
                    <h6 class="text-center mt-2">DETAILS FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-details [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-details>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">OTHER INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-other [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-other>
                </div>
                <div class="page" *ngIf="intMode == 64">
                    <h6 class="text-center mt-2">TRAILER INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-trailer [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-trailer>
                </div>
                <div class="page" *ngIf="intMode == 64">
                    <h6 class="text-center mt-2">ROLLOVER INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-rollover [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-rollover>
                </div>
                <div class="page" *ngIf="intMode == 64">
                    <h6 class="text-center mt-2">VIEW INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-view [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-view>
                </div>
                <div class="page" *ngIf="intMode == 128">
                    <h6 class="text-center mt-2">NON MOTORIST FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-non-motorist [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-non-motorist>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">NOTES INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-notes [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-notes>
                </div>
                <!--<div class="page">
                    <h6 class="text-center mt-2">TRUCK INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-truck [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-truck>
                </div>-->
                <!--<div class="page">
                    <h6 class="text-center mt-2">NON-TRUCK INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-nontruck [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-nontruck>
                </div>-->

                <div class="page">
                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center">DRIVER LEVEL FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <ng-container>
                        <div class="row">
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-4"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                        </div>
                    </ng-container>
                    <app-vehicle-driver-driver [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-driver-driver>

                    <h6 class="text-center mt-2">DRIVER LICENSE FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-driver-license [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-driver-license>

                    <h6 class="text-center mt-2">DRIVER COUNTERS FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-driver-counters [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-driver-counters>

                    <h6 class="text-center mt-2">DRIVER NOTES FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-driver-notes [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-driver-notes>
                </div>
                <div class="page">
                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center">PRECRASH LEVEL (VEHICLE/DRIVER) FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <h6 class="text-center" *ngIf="!vehItem.PreCrash">NO PRE-CRASH CODED FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <ng-container>
                        <div class="row">
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-4"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                        </div>
                    </ng-container>

                    <h6 class="text-center">ROADWAY FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-precrash-roadway [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-precrash-roadway>

                    <h6 class="text-center">PRECRASH FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-precrash-precrash [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-precrash-precrash>

                    <h6 class="text-center">OTHER FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-precrash-other [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-precrash-other>
                </div>

                <ng-container *ngFor="let person of vehItem.Occupants">
                    <div class="page">
                        <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                        <h6 class="text-center">PERSON LEVEL (MV OCCUPANT) FOR VEHICLE# {{vehItem.VNumber}}</h6>
                        <ng-container>
                            <div class="row">
                                <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                                <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                                <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                                <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                                <div class="col-sm-3"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                                <div class="col-sm-3"> <span class="print-font-size-label">**Person Number</span> <input type="text" disabled="disabled" [value]="person.PNumber" class="form-control form-control-sm bg-white" /> </div>
                            </div>
                        </ng-container>
                        <app-vehicle-person-person [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-person>
                        <h6 class="text-center mt-2">SAFETY DEVICES FOR VEHICLE# {{vehItem.VNumber}} AND PERSON# {{person.PNumber}} </h6>
                        <app-vehicle-person-safety-devices [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-safety-devices>
                    </div>
                    <div class="page">
                        <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>

                        <h6 class="text-center mt-2">ALCOHOL FOR VEHICLE# {{vehItem.VNumber}} AND PERSON# {{person.PNumber}} </h6>
                        <app-vehicle-person-alcohol [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-alcohol>
                    </div>
                    <div class="page">
                        <h6 class="text-center">Drug Toxicology Results (MV OCCUPANT) FOR VEHICLE# {{vehItem.VNumber}}</h6>
                        <app-vehicle-person-drug [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-drug>
                    </div>
                    <div class="page">
                        <h6 class="text-center">PERSON NOTES FOR VEHICLE# {{vehItem.VNumber}} AND PERSON# {{person.PNumber}} </h6>
                        <app-vehicle-person-notes [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-notes>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>


    <!--
    a. Vehicle
    b.	VIN
    c.	Details
    d.	Other
    e.	Trailer
    f.	Rollover
    g.  View
    h.  Notes
    i.  Truck
    j.  Non-Truck

        -->
    <ng-container *ngIf="printVehicle">
        <ng-container *ngFor="let vehItem of acc.Veh;">
            <ng-container *ngIf="pOrVNumber == 0 || vehItem.VNumber == pOrVNumber ">
                <div class="page">

                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center" *ngIf="intMode != 256">VEHICLE LEVEL FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <h6 class="text-center" *ngIf="intMode == 256">OTHER VEHICLE LEVEL FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <ng-container>
                        <div class="row">
                            <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-3"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                            <div class="col-sm-3"> <span class="print-font-size-label">**Number Of Occupants </span> <input type="text" disabled="disabled" [value]="vehItem.Occupants.length" class="form-control form-control-sm bg-white input-sm" /> </div>
                        </div>
                    </ng-container>
                    <app-vehicle-vehicle-vehicle [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-vehicle>
                </div>
                <div class="page" *ngIf="intMode == 128">
                    <h6 class="text-center mt-2">DAMAGED AREAS FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-damaged-areas [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-damaged-areas>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2" *ngIf="intMode != 256">VIN INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <h6 class="text-center mt-2" *ngIf="intMode == 256">VIN INFORMATION FOR OTHER VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-vin [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-vin>
                </div>
                <div class="page" *ngIf="intMode == 64">
                    <h6 class="text-center mt-2">DETAILS FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-details [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-details>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2" *ngIf="intMode != 256">VIN INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <h6 class="text-center mt-2" *ngIf="intMode == 256">OTHER INFORMATION FOR OTHER VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-other [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-other>
                </div>
                <div class="page" *ngIf="intMode == 64">
                    <h6 class="text-center mt-2">TRAILER INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-trailer [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-trailer>
                </div>
                <div class="page" *ngIf="intMode == 64">
                    <h6 class="text-center mt-2">ROLLOVER INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-rollover [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-rollover>
                </div>
                <div class="page" *ngIf="intMode == 64">
                    <h6 class="text-center mt-2">VIEW INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-view [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-view>
                </div>
                <div class="page" *ngIf="intMode == 128">
                    <h6 class="text-center mt-2">NON MOTORIST FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-non-motorist [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-non-motorist>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2" *ngIf="intMode != 256">NOTES INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <h6 class="text-center mt-2" *ngIf="intMode == 256">NOTES INFORMATION FOR OTHER VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-notes [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-notes>
                </div>
                <!--<div class="page">
                    <h6 class="text-center mt-2">TRUCK INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-truck [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-truck>
                </div>-->
                <!--<div class="page">
                    <h6 class="text-center mt-2">NON-TRUCK INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
                    <app-vehicle-vehicle-nontruck [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-nontruck>
                </div>-->
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="printSFRVehicle">
        <ng-container *ngFor="let vehItem of acc.Veh;">
            <ng-container *ngIf="pOrVNumber == 0 || vehItem.VNumber == pOrVNumber ">
                <div class="page">

                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center" *ngIf="intMode == 256">SFR VEHICLE LEVEL FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <ng-container>
                        <div class="row">
                            <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-3"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                            <div class="col-sm-3"> <span class="print-font-size-label">**Number Of Occupants </span> <input type="text" disabled="disabled" [value]="vehItem.Occupants.length" class="form-control form-control-sm bg-white input-sm" /> </div>
                        </div>
                    </ng-container>
                    <app-sfr-vehicle-vehicle-vehicle [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="SSVNumber"></app-sfr-vehicle-vehicle-vehicle>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">VIN INFORMATION FOR SFR VEHICLE# {{SSVNumber}} </h6>
                    <app-sfr-vehicle-vehicle-vin [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="SSVNumber"></app-sfr-vehicle-vehicle-vin>
                </div>
                <!--<div class="page">
        <h6 class="text-center mt-2">CONTROL INFORMATION FOR SFR VEHICLE# {{SSVNumber}} </h6>
        <app-sfr-vehicle-vehicle-control [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-sfr-vehicle-vehicle-control>
    </div>-->

                <div class="page">
                    <h6 class="text-center mt-2">OTHER INFORMATION FOR SFR VEHICLE# {{SSVNumber}} </h6>
                    <app-sfr-vehicle-vehicle-other [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="SSVNumber"></app-sfr-vehicle-vehicle-other>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">NOTES INFORMATION FOR SFR VEHICLE# {{SSVNumber}} </h6>
                    <app-sfr-vehicle-vehicle-notes [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="SSVNumber"></app-sfr-vehicle-vehicle-notes>
                </div>
                <!--<div class="page">
        <h6 class="text-center mt-2">TRUCK INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
        <app-vehicle-vehicle-truck [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-truck>
    </div>-->
                <!--<div class="page">
        <h6 class="text-center mt-2">NON-TRUCK INFORMATION FOR VEHICLE# {{vehItem.VNumber}} </h6>
        <app-vehicle-vehicle-nontruck [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-vehicle-nontruck>
    </div>-->
            </ng-container>
        </ng-container>
    </ng-container>

    <!-- Pre Crash -->
    <ng-container *ngIf="printPrecrash">
        <ng-container *ngFor="let vehItem of acc.Veh;">
            <ng-container *ngIf="pOrVNumber == 0 || vehItem.VNumber == pOrVNumber ">
                <div class="page">

                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center">PRECRASH LEVEL (VEHICLE/DRIVER) FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <ng-container>
                        <div class="row">
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-4"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                        </div>
                    </ng-container>

                    <h6 class="text-center">ROADWAY FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-precrash-roadway [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-precrash-roadway>

                    <h6 class="text-center">PRECRASH FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-precrash-precrash [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-precrash-precrash>

                    <h6 class="text-center">OTHER FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-precrash-other [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-precrash-other>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <!--
        a.	Driver
    b.	License
    c.	Counters
     -->

    <ng-container *ngIf="printDriver">
        <ng-container *ngFor="let vehItem of acc.Veh;">
            <ng-container *ngIf="pOrVNumber == 0 || vehItem.VNumber == pOrVNumber ">
                <div class="page">

                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center">DRIVER LEVEL FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <ng-container>
                        <div class="row">
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                            <div class="col-sm-4" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>
                            <div class="col-sm-4"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                        </div>
                    </ng-container>
                    <app-vehicle-driver-driver [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-driver-driver>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">DRIVER LICENSE FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-driver-license [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-driver-license>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">DRIVER COUNTERS FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-driver-counters [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-driver-counters>
                </div>
                <div class="page">
                    <h6 class="text-center mt-2">DRIVER NOTES FOR VEHICLE# {{vehItem.VNumber}}</h6>
                    <app-vehicle-driver-notes [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-vehicle-driver-notes>
                </div>

            </ng-container>
        </ng-container>
    </ng-container>

    <!--
        a.	Person
        b.	Safety Devices
        c.	Alcohol
        d.	Drug
        e.	Race
        f.	Supplemental
         -->

    <ng-container *ngIf="printPerson">
        <ng-container *ngFor="let vehItem of acc.Veh;">
            <ng-container *ngIf="vehItem.VNumber == pOrVNumber ">
                <ng-container *ngFor="let person of vehItem.Occupants">
                    <ng-container *ngIf="person.PNumber == vehPersonNumber ">
                        <div class="page">

                            <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                            <h6 class="text-center">PERSON LEVEL (MV OCCUPANT) FOR VEHICLE# {{vehItem.VNumber}}</h6>
                            <ng-container>
                                <div class="row">
                                    <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                                    <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                                    <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                                    <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                                    <div class="col-sm-3"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                                    <div class="col-sm-3"> <span class="print-font-size-label">**Person Number</span> <input type="text" disabled="disabled" [value]="person.PNumber" class="form-control form-control-sm bg-white" /> </div>
                                </div>
                            </ng-container>
                            <app-vehicle-person-person [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-person>
                            <h6 class="text-center mt-2">SAFETY DEVICES FOR VEHICLE# {{vehItem.VNumber}} AND PERSON# {{person.PNumber}} </h6>
                            <app-vehicle-person-safety-devices [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-safety-devices>
                        </div>
                        <div class="page">
                            <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>

                            <h6 class="text-center mt-2">ALCOHOL FOR VEHICLE# {{vehItem.VNumber}} AND PERSON# {{person.PNumber}} </h6>
                            <app-vehicle-person-alcohol [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-alcohol>
                        </div>

                        <div class="page" *ngIf="intMode == 64 || intMode == 128">
                            <h6 class="text-center">Drug Toxicology Results (MV OCCUPANT) FOR VEHICLE# {{vehItem.VNumber}}</h6>
                            <app-vehicle-person-drug [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-drug>
                        </div>
                        <div class="page" *ngIf="intMode == 64 || intMode == 128">
                            <h6 class="text-center">PERSON NOTES FOR VEHICLE# {{vehItem.VNumber}} AND PERSON# {{person.PNumber}} </h6>
                            <app-vehicle-person-notes [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-notes>
                        </div>
                        <!--<div *ngIf="intMode == 1 || intMode == 64" class="page">
                            <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                            <h6 class="text-center mt-2">RACE FOR VEHICLE# {{vehItem.VNumber}} AND PERSON# {{person.PNumber}} </h6>
                            <app-vehicle-person-race [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber" [blnMultipleRacePrintForm]="person.IsMultiRace"></app-vehicle-person-race>
                        </div>-->
                        <!--<div *ngIf="intMode == 1 || intMode == 64" class="page">
                            <h6 class="text-center mt-2">SUPLEMENTAL FOR VEHICLE# {{vehItem.VNumber}} AND PERSON# {{person.PNumber}} </h6>
                            <app-vehicle-person-suplemental [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber" [personNum]="person.PNumber"></app-vehicle-person-suplemental>
                        </div>-->
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <!--printAvoidanceEquipment-->
    <ng-container *ngIf="printCase || printAvoidanceEquipment">
        <ng-container *ngFor="let vehItem of acc.Veh;">
            <ng-container *ngIf="pOrVNumber == 0 || vehItem.VNumber == pOrVNumber ">
                <div class="page">

                    <h6 class="text-center">{{ appYear}} {{strFullApplicationName}}</h6>
                    <h6 class="text-center">AVOIDANCE EQUIPMENT LEVEL # {{vehItem.VNumber}}</h6>
                    <div class="row">
                        <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PSU #</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                        <div class="col-sm-3" *ngIf="intMode == 4"> <span class="print-font-size-label">PAR ID</span> <input type="text" disabled="disabled" [value]="parId" class="form-control form-control-sm bg-white input-sm" /> </div>

                        <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">State Number</span> <input type="text" disabled="disabled" [value]="acc.StateNum" class="form-control form-control-sm bg-white" /> </div>
                        <div class="col-sm-3" *ngIf="intMode != 4"> <span class="print-font-size-label">Consecutive Number</span> <input type="text" disabled="disabled" [value]="acc.Casenum" class="form-control form-control-sm bg-white input-sm" /> </div>

                        <div class="col-sm-3"> <span class="print-font-size-label">**Vehicle Number </span> <input type="text" disabled="disabled" [value]="vehItem.VNumber" class="form-control form-control-sm bg-white input-sm" /> </div>
                        <div class="col-sm-3"> <span class="print-font-size-label">**Number Of Occupants </span> <input type="text" disabled="disabled" [value]="vehItem.Occupants.length" class="form-control form-control-sm bg-white input-sm" /> </div>
                    </div>
                    <app-avoidance-equipment [printOnly]="true" [clearFieldData]="clearFieldData" [printVehNum]="vehItem.VNumber"></app-avoidance-equipment>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

</div>


