import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Acc } from '../../../../models/acc';
import { EarlyNotify } from '../../../../models/early-notify';
import { EDT_GetCaseLastUpdateDates_Result } from 'src/app/models/EDT_GetCaseLastUpdateDates_Result';

import { SharedDataService } from 'src/app/services/shared-data.service';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { States } from 'src/app/models/enums/app.enums';
import { UtilService } from 'src/app/services/util.service';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { ModalService } from 'src/app/services/modal.service';


@Component({
    selector: 'app-case-header-crss',
    templateUrl: './case-header-crss.component.html',
    styleUrls: ['./case-header-crss.component.scss']
})

export class CaseHeaderCrssComponent implements OnInit, OnDestroy {

    @Input() isPrintMode: boolean = false;
    @Input() stateString: string;
    @Input() acc: Acc = null;
    @Input() invisibleENCaseNum: boolean = false;
    @Input() hideNavForCloseCase: boolean = false;
    @Input() stateNum: number;
    @Input() accid: number;
    @Input() edtLastUpdateDates: EDT_GetCaseLastUpdateDates_Result;
    @Input() blnIsEDTcase: boolean = false;
    @Input() caseStatusReviewFlag: Boolean = false;

    selecting: boolean = false;
    CRSSstateString: string;

    @Output() selectcase: EventEmitter<any> = new EventEmitter();

    //this.formDrpDownOptionShare.next(result);

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _sharedDataService: SharedDataService,
        private _enService: EarlyNotificationService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _utilService: UtilService,
        private _modalService: ModalService
    ) {

    }

    async ngOnInit() {
        //console.log('acc :', this.acc);
        //this.CRSSstateString = States[this.acc.EarlyNotify[0].StateNum];

        this._utilService.formDrpDownOptionShare.subscribe(async objResult => {
            if (objResult) {
                let options = objResult.filter(x => x.tblName == "VR_PSU");
                if (options) {
                    this.CRSSstateString = options.find(y => y.intValue == this.stateNum).PSUStateAbbr;
                }
            }

        })

    }

    public onStateCasePDFTest() {
        this.selectcase.emit(this.acc)
    }

    async onEarlyNotifyEdit(acc: Acc) {
        this._sharedDataService.setLastCaseUrl(this._router.url);
        try {
            this._enService.earlyNotification = null;  // Cleaning EarlyNotify this._enService.earlyNotification so that when we land to EN page we get fresh EarlyNotify object from the database;   
            this._router.navigate(['earlyNotification', this.stateNum, 'newFatalCase', acc.EarlyNotify[0].ENID]);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    public async onStateCasePDF(EarlyNotify: EarlyNotify): Promise<void> {
        this.selecting = !this.selecting;

        try {
            let item: string = await this._enService.GetCRSSFromStateCaseViewer(EarlyNotify);
            if (!item || item.length == 0) {
                this._modalService.setMessage('File not found', 'danger');
            }
            else {
                let strBufferStateCaseViewer: string = atob(item);
                let arrBytes: Uint8Array = new Uint8Array(strBufferStateCaseViewer.length);

                for (var i = 0; i < strBufferStateCaseViewer.length; i++) {
                    arrBytes[i] = strBufferStateCaseViewer.charCodeAt(i);
                }

                let objBlob: Blob = new Blob([arrBytes], { type: 'application/pdf' });
                let strUrl = URL.createObjectURL(objBlob);
                window.open(strUrl);
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }

    changeCaseStatus() {}

    RedirectViewCaseStatusUpdates(AccId) {
        this._sharedDataService.setLastCaseUrl(this._router.url);
        this._router.navigate([this.stateNum, "EDTUpdate", this.accid]);
    }

    ngOnDestroy() {
        this._modalService.resetMessage();
    }
}
