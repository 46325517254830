import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'maxLengthPipe'

})
export class MaxLengthPipe implements PipeTransform {
   transform(strValue: string, intMaxLength: number, strIgnore: string = ''): string {
        if (strValue != null) {
            if(strValue.replace(strIgnore, '').length > intMaxLength)
                strValue = strValue.substr(0, intMaxLength + strIgnore.length); //We're assuming that the ignore character is encountered only once. Otherwise, need to count how many occurrences there are.
        }

        return strValue;
    }
}
