import { Input, Output, OnDestroy, EventEmitter, Component } from '@angular/core';
import { OnInit, AfterViewInit } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { LabelComponent } from 'src/app/components/label/label.component';
import { AutofillService } from 'src/app/services/autofill.service';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { Rule } from 'src/app/models/Rule';
import { RuleCondition } from 'src/app/models/RuleCondition';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { intRBISDataValue, strRBISDataValue, KeyCode, PropertyType, RecodeSpecialCodes } from '../models/enums/app.enums';
import { Element_Specify } from '../models/element-specify';
import { DecimalPipe } from '@angular/common';
import { ReplaceTextPipe } from 'src/app/pipes/replaceText.pipe';
import { AttributeMatchLevel } from '../models/attr-match-level';
import { usp_EDT_GetStylesByStateNum_Result } from '../models/usp_EDT_GetStylesByStateNum_Result';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../services/util.service';
import { UrlTreeHelper } from './UrlTreeHelper';
import { UrlSegment } from '@angular/router';
import { usp_EDT_GetAttributeMatchLevels_Result } from '../models/usp_EDT_GetAttributeMatchLevels_Result';
import { IUIElementBase } from '../interface/IUIElementBase';

@Component({
    selector: 'UIElement-Base',
    template: '<div></div>'
})

export abstract class UIElementBase implements IUIElementBase, OnDestroy, OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_UIElementBase: string = null;

    /**
     * This is to interact with the parent component and to mark the form is dirty.
    **/
    public static evtMarkFormDirty: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Set based on lack of edit rights, case deleted status or data year freeze status.
     * Overrides "disabled" input property and any enable/disable rules that manipulate that property.
     **/
    public static blnReadOnly: boolean = false;
    public get GetReadOnly() { return UIElementBase.blnReadOnly; }
    /**
     * Raised when all visible UIElementBase controls have finished initializing.
     * Relies on the following lifecycle:
     *  Parent constructor(), followed by parent Init(), followed by parent AfterViewInit() followed by
     *  all child constructors, followed by all child Init(), followed by all child AfterViewInit().
     **/

    public static evtSiblingsInitialized: EventEmitter<void> = new EventEmitter<void>();
    private static intSiblingsInitializing: number = 0;
    private static blnTrackingShift: boolean = false;
    /**
     * Helper to track Shift keyDown/keyUp events occurring on different UIElementBase instances.
     **/
    protected static blnShiftPressed: boolean = false;
    /**
     * Applicable to fields that are stored as numbers in the database, intMaxLengthCode is maximum digit length of the numerical code stored in the database.
     * Whereas maxlength may be set dynamically based on whether searching by text is allowed (see searchAll), this length is constant
     **/
    public intMaxLengthCode: number = 999;
    protected strDbDataType: string;
    public get GetDbDataType(): string { return this.strDbDataType; }
    /**
     * The initial value of the "display" CSS attribute when the UI element is visible, ex "inline" or empty string (meaning "inherited")
     **/
    protected strVisibleDisplayStyle: string = '';
    protected objBlankDrpDown: DrpDownOptions = ObjectUtil.InstantiateBlankDrpDownOption();

    /**
     * Autofill Rules that are rooted at the model object that this field has as objModel and that reference this field's
     * strFieldName binding in at least one action. When this field comes on screen, used to check if other data model fields
     * result in an operation on this field.
     **/
    public arrAutofillRules: Rule[] = null;
    /**
     * Flattened out collection of rule conditions for Autofill Rules applicable to this field
     **/
    public arrAutofillConditions: RuleCondition[] = null;

    strPlaceholder: string = '';


    public options: DrpDownOptions[]; //TODO: make protected and add public get accessor?
    public blnVisible: boolean = true;
    public blnEnableOtherSpecify: boolean = false;

    public intMode: number;
    /**
     * EDT Color Attributes
     **/
    public matchLevel: string;
    public blnHideLight: boolean = false;
    public blnHideDanger: boolean = false;
    public blnHideWarning: boolean = false;
    public blnHidePrimary: boolean = false;
    public blnHideInfo: boolean = false;
    public blnHideSuccess: boolean = false;
    public _arrAttributeLevel: AttributeMatchLevel[];
    public arrStateStyle: Array<usp_EDT_GetStylesByStateNum_Result> = [];
    arrUrlSegments: UrlSegment[];

    /**
     * Indicates if autofill rules for the field have been looked up. Set to true after awaited actions in UIElementBase.ngOnInit(),
     * the last of which are autofill lookups, are completed.
     **/
    public blnInitialized: boolean = false;
    public objMetaData: TableFieldElements;
    /**
     *If property set false for control, invalid class not removed from dom-element. (Requirement : Edit check control should be highligted even value has been changed)
     * */
    public blnClearHighlighting: boolean = true;


    public inputName: string; //The HTML id/name attributes for the <input> tag wrapped inside this component
    /**
     * Similar to the HTML focus event, but with the value of the control being emitted rather than an event object with a reference to the UI element.
     **/
    @Output() public focus$ = new Subject<string>(); //DO NOT CHANGE type of focus subject (event): it must be the value of the control for the Bootstrap Typeahead to work. If there is a need to pass in a reference to the control, create a separate EventEmitter.
    public click$ = new Subject<string>();

    @ViewChild(LabelComponent, { static: false }) labelComp: ElementRef;

    //Public "input" properties to pass data from parent pages to child UIElement instances
    @Input() disabled: boolean = false;

    public get blnDisabled() { return this.disabled; }
    //public set blnDisabled(value: boolean) { this.disabled = value; this.disableOrEnableComponent(value); }

    /**
     * DEPRECATED?
     **/
    public blnIsMultiSelect: boolean = false;
    public blnIsTextField: boolean = false;

    @Input() intDisabledMode: number = 0;
    @Input() blnAllowFreeText: boolean = false;
    @Input() blnCheckStrengthCode: boolean = true;

    /**
     * Flag created to override default inputformatter(ngbtyphead binds value of strText) and resultformatter(the template items show only strText without code )
     **/
    @Input() blnFormatListOnlyShowText: boolean = false;
    /**
     * Skips execution of business rules during UI initialization
     **/
    @Input() blnExcludeFromBusinessRules: boolean = false;
    @Input() blnIncludeBlank: boolean = true;
    /**
     * Determines if the control reacts to and potentially cancels further processing of the TAB key press.
     * Can be used to prevent default form field tabbing behavior (ex. on case structure screen, we don't want Injury Severity field to interfere with tabbing through case structure tree)
     * Not to be confused with TypeaheadComponent.blnAllowFocusNext
     **/
    @Input() blnListenToTabKey: boolean = true;

    /**
     * Flag that controls whether TypeaheadComponent will allow filtering and selection by text substring matching and by numerical codes, or only by numerical codes
     **/
    @Input() searchAll: boolean = true;
    @Input() disableLabel: boolean = false;
    @Input() id: string;
    /**
     * Setting intMaxDecimal to a non-zero value increments maxlength by 1 because the decimal point takes up one position from the perspective of string length.
     **/
    @Input() intMaxDecimal: number = 0;
    @Input() intSeqNum: number = 0;     //Applicable to one-to-many fields only. For simple columns, this is always 0.
    /**
     * Drives the maximum length and, combined with intMaxDecimal, the number of leading and trailing-decimal zeros for numerical fields.
     * For TypeaheadComponent, maxlength is applicable only when blnAllowFreeText is false, otherwise, maxlength is determined by the 
     * range of values in DEFS.
     **/
    @Input() maxlength: number = 999;
    @Input() strTableName: string = ''; //Default value is important as we don't check for undefined below
    @Input() strFieldName: string = '';
    @Input() strComplexFieldName: string = '';
    @Input() strComputedFieldName: string = '';// This parameter has been added to override the objMetadata.Field(computed field value) for highlight logic. if the field is computed such as MilePnt field is calculated field 'MilePointDecimal' and MilePointWhole.  
    /**
     * DEFS lookup table name
     * REMINDER: Required if a DEFS lookup table is used by more than one field as the cache will contain the lookup table only under one (TableID, FieldID) lookup key.
     **/
    @Input() strDefsLookupTableName: string = '';
    @Input() strFilterCondition: string = ""; //DEFS lookup table filter condition
    @Input() strDependentFieldName: string = ""; //Parent field (if any) that drives the range of values in this field
    @Input() objModel: any = null;
    @Input() objCurrentValue: any;
    @Input() objOtherSpecifyTemplate: Element_Specify;
    @Input() objNgModelOptions: { standalone: boolean } = { standalone: false };
    /**
     * The flag has been added to omit formatting for VIN control in VIN Decode tab.
     **/
    @Input() blnFormatWithLeading: boolean = true;

    //direct access the DOM elements to manupulate them
    @ViewChild('divContainer', { static: false }) divContainer: ElementRef; //DIV tag that contains 
    @ViewChild('txt', { static: false }) public objTextbox: ElementRef; //The text INPUT element encapsulated in this component
    @ViewChild('lbl', { static: false }) labelElem: ElementRef;
    @ViewChild('instance', { static: false }) instance: NgbTypeahead;
    /**
     * Called after parent component's Init and AfterViewInit have fired
     */

    /* for print related only */
    @Input() printOnly: boolean = false;
    @Input() showAllDataListForPrintOnly: boolean = true;
    @Input() showTextboxInSamePositionForPrintOnly: boolean = true;
    @Input() showDescriptionForPrintOnly: boolean = true;
    @Input() clearFieldData: boolean = false;
    @Input() customCss: string = '';
    @Input() otherOption: boolean = false;

    //The flag is to give decision to evaluate condition value from model for dependent fields dynamically in typeahead component or not. 
    @Input() blnEvaluateConditionValue: boolean = true;


    @Input() blnRemoveDecimalFraction: boolean = false;


    //This flag allow user to select empty and focus to next element
    // https://dot-cdan.visualstudio.com/NHTSA.CDAN.MDE/_workitems/edit/11300/
    @Input() allowFocusNextOnEmptySelection: boolean = false;

    /*end of printer related */

    /**
     * strLeftArrowFocusFieldName -> Indicates the fieldname which is on the left side of the control and which fields is being focused by pressing left arrow on the keyboard.
     * strRightArrowFocusFieldName -> Indicates the fieldname which is on the right side of the control and which fields is being focused by pressing right arrow on the keyboard.  
     */
    @Input() strLeftArrowFocusFieldName: string = "";
    @Input() strRightArrowFocusFieldName: string = "";

    /**
    *Default behavioir of UI field is to move the cursor next empty control after field is coded.
    *Passing the flag false in html for a control, moves cursor to next adjacent field instead of empty one.
    */
    @Input() blnFocusOnNextEmptyControl: boolean = true;

    @Input() disabledLeftAndRightArow: boolean = false; //this will prevent from moving left or right arrow in case of if there is any value exists

    @Input() isFromBatchProcessing: boolean = false; //this will prevent from moving left or right arrow in case of if there is any value exists

    @Input() blnMossReverseFilter: boolean = false; // Use for MOSSS reverse filter on Precrash tab Crash Category, Crash Configuration and Crash Type

    constructor(
        protected _autofillService: AutofillService,
        protected renderer: Renderer2,
        protected decimalPipe: DecimalPipe,
        protected replaceTextPipe: ReplaceTextPipe,
        protected _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        if (!UIElementBase.blnTrackingShift) {
            document.addEventListener('keydown', this.trackShiftDown.bind(this));
            document.addEventListener('keyup', this.trackShiftUp.bind(this));
            UIElementBase.blnTrackingShift = true;
        }

        UIElementBase.intSiblingsInitializing++;
    }

    public abstract setCurrentValue(objNewValue: any): boolean;
    public abstract disableOrEnableComponent(isDisable: boolean): void;
    public abstract bindFieldMetadata(): Promise<void>;
    public abstract FilterFieldOptions(): Promise<DrpDownOptions[]>;
    public abstract BindElementEDTColor(intStateNum: number, arrStateStyles: usp_EDT_GetStylesByStateNum_Result[], arrAttrMatchLevel: usp_EDT_GetAttributeMatchLevels_Result[]);

    public clearComponent(blnSkipAutoFillOnChange: boolean = true): void {
        if (this.objModel && this.objModel.hasOwnProperty(this.strFieldName)) {
            let blnDefaultSet: boolean = AutofillService.SetDefaultValue(this.objModel, this.strTableName, this.strFieldName);

            if (!blnDefaultSet) {
                let strFieldType: string = typeof (this.objModel[this.strFieldName]);

                if (strFieldType == 'number') {
                    this.objModel[this.strFieldName] = intRBISDataValue.Blank;
                    this.objTextbox.nativeElement.value = ''
                }
                else if (strFieldType == 'string') {
                    this.objModel[this.strFieldName] = '';
                    this.objTextbox.nativeElement.value = ''
                }
                else //Boolean or Date
                    this.objModel[this.strFieldName] = null;
            }
        }
    }

    public InsertBlankToOptions(): void {

        if (this.blnIncludeBlank) {
            if (this.options !== undefined && !this.options.includes(this.objBlankDrpDown)) {
                this.options.unshift(this.objBlankDrpDown);
            }
        }
    }

    public handleFocus(objEvent: FocusEvent) {
        if (!UIElementBase.blnShiftPressed) //Bootstrap typeahead is listening to focus event, among others, to determine when to display the popup - we don't want to show the popup while the Shift key remains depressed
            this.focus$.next((<any>(objEvent.target)).value); //Broadcast focus event to anybody who's listening beside Bootstrap Typeahead
    }

    public handleKeydown(event: KeyboardEvent): void {

        if (event.code == KeyCode.Tab && this.blnListenToTabKey) {
            let blnFocusMoved: boolean = false;

            if (!UIElementBase.blnShiftPressed) {
                if (document.activeElement == this.objTextbox.nativeElement) {
                    blnFocusMoved = this.focusNextInput(false);
                }
                else {
                    blnFocusMoved = true; //TypeaheadComponent.bindItemAndFocusNextElement() already advanced the focus and there is no need to process the Tab key press any further
                }
            }
            else
                blnFocusMoved = this.focusPrevInput();

            if (blnFocusMoved)
                event.preventDefault();
            //else let browser default behavior for Tab key proceed (i.e. select next focusable HTML DOM element that is after UIElementBase)
        }
    }

    public handleKeyup(event: KeyboardEvent): void {
        let blnFocusMoved: boolean = false;

        if (event.key == KeyCode.PageUp || event.key == KeyCode.PageDown) {// We should be aware that any keys that causes navigation thay can realise before navigation is finished and we do not want a control interpret that keyup has a data entre action; 
            return;
        }

        if (event.key == KeyCode.Shift) {
            this.objTextbox.nativeElement.click(); //Shift key down dismisses the ngbTypeahead pop-up in preparation for Shift-Tab sequence, but if user just releases the Shift key without completing a Shift-Tab sequence, we want the pop-up to come back, which the click() handler facilitates
        }
        else if (event.key == KeyCode.ArrowRight) {
            if ((this.disabledLeftAndRightArow && this.objTextbox.nativeElement.value != '') || this.blnIsTextField) {
                blnFocusMoved = false;
            }
            else if (document.activeElement == this.objTextbox.nativeElement) {
                blnFocusMoved = this.focusNextInput(false);
            }
        }
        else if (event.key == KeyCode.ArrowLeft) {
            if ((this.disabledLeftAndRightArow && this.objTextbox.nativeElement.value != '') || this.blnIsTextField) {
                blnFocusMoved = false;
            } else {
                blnFocusMoved = this.focusPrevInput();
            }
        }
        if (blnFocusMoved)
            event.preventDefault();
    }

    /**
     * We need to track the Shift key at the document level because not all focusable elements are UIElementBase, but UIElementBase needs to be aware of Shift key even if
     * currently a non-UIElementBase has focus (as UIElementBase may be about to receive focus)
     */
    private trackShiftDown(event: KeyboardEvent): void {
        if (event.key == KeyCode.Shift || event.key == KeyCode.ShiftLeft) {
            UIElementBase.blnShiftPressed = true;
        }
    }

    private trackShiftUp(event: KeyboardEvent): void {
        if (event.key == KeyCode.Shift || event.key == KeyCode.ShiftLeft) {
            UIElementBase.blnShiftPressed = false;
        }
    }

    /**
     * Hides or shows the UI element by setting its "display" CSS style attribute either to "none" or back to its initial value (which may the empty string, i.e. inherited)
     * @param blnNewVisibleValue When "true", then show UI element, otherwise hide the UI element.
     */
    public hideOrShowComponent(blnNewVisibleValue: boolean): void { //TODO: consider renaming from "hideOrShowComponent" to "showOrHideComponent"
        if (blnNewVisibleValue === undefined || blnNewVisibleValue === null) {
            console.warn('UIElementBase.hideOrShowComponent(): blnVisible is a required parameter, falling back to "false"');
            blnNewVisibleValue = false;
        }

        if (this.blnVisible && !blnNewVisibleValue && //If about to change from visible to invisible, remember CSS display attribute value.
            (this.strVisibleDisplayStyle === undefined || this.strVisibleDisplayStyle === null) && //Reminder: empty string is falsy, but for CSS "display" attribute, empty string means "inherit", so we need a boolean expression that treats the empty string appropriately
            this.divContainer.nativeElement.style.display != 'none'
        ) {
            this.strVisibleDisplayStyle = this.divContainer.nativeElement.style.display;
        }

        this.blnVisible = blnNewVisibleValue;
        this.divContainer.nativeElement.style.display = this.blnVisible ? this.strVisibleDisplayStyle : 'none';
    }

    /**
     * Called after parent component's Init and AfterViewInit events.
     * All siblings fire their Init event before the first sibling's AfterViewInit fires, however, CAUTION:
     * the logic following the first await in an async ngOnInit() will likely execute after ngAfterViewInit has fired.
     **/
    public async ngOnInit(): Promise<void> {
        try {
            //console.debug("UIElementBase.ngOnInit(): Begin ", this.id);

            let strStopAtControlId: string = '';
            //if (strStopAtControlId.split(',').findIndex(x => x == this.id) != -1)
            //    console.log(''); //Put break point here to stop

            AutofillService.RegisterControl(this);   //This call should occur before any awaits or subscribes to preserve the order of appearance in the HTML template
            this.inputName = 'txt' + this.id;

            let subsSiblingsInitiated = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                //This event is fired only after all sibling UIElementBase instances have finished initializing
                subsSiblingsInitiated.unsubscribe();

                if (!this.blnExcludeFromBusinessRules && !UIElementBase.blnReadOnly) {
                    //AutofillService.EvalRulesAffectingField(this, true, false); //TEMPORARY for 01/16/2020 DEMO: execute model-affecting actions on page init
                    //AutofillService.OnChange(this, this.objModel, this.strFieldName, true, false);

                    AutofillService.EvalRulesAffectingField(this, false, false); //Executing only non-model-affecting actions like enable/disable and show/hide.
                    AutofillService.OnChange(this, this.objModel, this.strFieldName, false, false);
                }
            }).bind(this));

            if (this.objModel && this.strFieldName) {
                this.objCurrentValue = this.objModel[this.strFieldName];
                //this.intValue = this.objModel[this.strFieldName];
            }

            //if (this.blnAllowFreeText) {
            //    this.objTextbox.nativeElement.value = this.objModel[this.strFieldName];
            //    this.labelElem.nativeElement.innerText = this.objModel[this.strFieldName];
            //}

            //TO-DO: Related to Check if we should assign value to objModel[this.strFieldName] as -1 or null
            //We need to handle -1 values when the this.objModel[this.strFieldName] comes as -1 
            //this.objCurrentValue = this.objModel[this.strFieldName] === -1 ? null : this.objModel[this.strFieldName];

            if (this.objModel && !this.strTableName)
                this.strTableName = ObjectUtil.GetTypeName(this.objModel);

            await this.bindFieldMetadata();

            //let intStateNum: number = UrlTreeHelper.FindAndSetStateNum('case', this._urlTreeHelper.arrUrlSegment);
            //if (intStateNum != null)
            //    this.arrStateStyle = await this._utilService.GetStateEDTStyle(intStateNum).toPromise();

            if (strStopAtControlId.split(',').findIndex(x => x == this.id) != -1)
                console.log(''); //Put break point here to stop

            if (this.disabled || UIElementBase.blnReadOnly) //If data model (and initialization of UIElementBase.blnReadOnly) is not guaranteed available by the time this line is reached, consider moving into evtSiblingsInitialized handler
                this.disableOrEnableComponent(this.disabled);

            //this.hideOrShowTypeaheadComponent(this.blnVisible);
            if (this.objMetaData) {
                if (!this.strDefsLookupTableName || this.strDefsLookupTableName == '')
                    this.strDefsLookupTableName = this.objMetaData.LookupTblID;
                if (!this.strTableName || this.strTableName == '')
                    this.strTableName = this.objMetaData.TableID;
                if (!this.strFieldName || this.strFieldName == '')
                    this.strFieldName = this.objMetaData.FieldID;
                if (this.intSeqNum == 0 && this.objMetaData.SeqNum)
                    this.intSeqNum = this.objMetaData.SeqNum;
                if (this.objModel && this.strFieldName)
                    this.strDbDataType = AutofillService.GetDataType(this.objModel, this.strFieldName, this.strTableName);

                if (this.strComputedFieldName != '')
                    this.objMetaData.Field = this.strComputedFieldName;

                await this.FilterFieldOptions();
            }

            //Due to await, siblings initialized event will not fire until all typeaheads have resolved their options

            if (strStopAtControlId.split(',').findIndex(x => x == this.id) != -1)
                console.log('');

            //await this.BindElementEDTColor();

            AutofillService.InitializeAutofillForControl(this); //We want this logic to occur after the set of dropdown options for this field has been retrieved (since ngInit is async, this line cannot be simply placed in ngAfterViewInit)

            if (UIElementBase.intSiblingsInitializing > 0)
                UIElementBase.intSiblingsInitializing--;
            else
                console.error('UIElementBase.ngOnInit(): UIElementBase.intSiblingsInitializing increments and decrements are unbalanced, check for error readouts.');

            if (UIElementBase.intSiblingsInitializing == 0)
                UIElementBase.evtSiblingsInitialized.emit();

            //console.debug("UIElementBase.ngOnInit(): End ", this.id);
        }
        catch (ex) {
            console.error(ex);
        }
    }

    //AfterViewInit is called after Angular has fully initialized this component's view (but not necessarily the parent component's view),
    //but CAUTION: If this class' ngOnInit() is async, then after first await in ngOnInit(), ngAfterViewInit() can be called already.
    public ngAfterViewInit(): void {
        this.objTextbox.nativeElement.name = this.id;
    }

    //OnDestroy called when a directive, pipe, or service is destroyed, for a visual component this is when navigation away from the component has occurred.
    //Returning to a previous URL will create a new instance of the visual component.
    public ngOnDestroy(): void {
        AutofillService.UnregisterControl(this);
    }

    /**
     * As the user types, filters the options that are currently shown on screen by NgbTypeahead encapsulated in this TypeaheadComponent.
     * Not to be confused with FilterFieldOptions(), which applies business rule filtering logic to determine the set of options available for this field.
     **/
    protected FilterOptions(strFilterTerm: string): DrpDownOptions[] {
        //If we're dealing with a typeahead that allows the entry of free text, as the user types free text, all DrpDownOptions will be filtered out
        if (!strFilterTerm || strFilterTerm === '') {
            if (this.options && this.options.length > 100) //Typescript && operator is short circuit
                return this.options.slice(0, 100); //Rendering and tearing down a lot of options in the DOM takes time. 100 options is more than enough until the user starts filtering. For example avoids the 1+ second delay when opening the unfiltered vehicle make dropdown.
            else
                return this.options;
        }
        else if (this.searchAll) {
            let intCode: number = parseInt(strFilterTerm); //Warning: parseInt() will return successfully if the input string leads with a digit but also contains text - it will just stop parsing on encountering text

            if (isNaN(intCode))
                this.setMaxLengthOfCode(true); //Allow text search term to exceed max numeric code length
            else
                this.setMaxLengthOfCode();

            return this.options.filter(v => v.displayText.toLowerCase().indexOf(strFilterTerm.toLowerCase()) > -1);
        }
        else
            return this.options.filter(v => v.strValue.indexOf(strFilterTerm.split('-')[0]) > -1);
    }

    public GetArrFocusCandidates(): any[] {
        let inputElements = window.document.querySelectorAll('input, textarea, select, .for-focus');
        let arrFocusCandidates: Array<any> = Array.prototype.filter.call(inputElements,
            function (element) {
                return (element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement) //Why do we care about offset here?
                    && (element.hidden == false && element.disabled == false)
                    && (element.id != 'SearchBox');
            });

        return arrFocusCandidates;
    }

    /**
     * Returns true if focus was advanced
     * @param blnFocusOnNextEmptyControl Indicates that focus should go to next empty control (vs next adjacent control)
     **/
    public focusNextInput(blnFocusOnNextEmptyControl: boolean = false): boolean {
        try {
            //Do not put time delays here. If necessary have the parent page listen to the UIElementBase.evtSiblingsInitialized event and give focus to new controls as they come on screen
            //.for-focus selector helps to handle <select> HTML element, but can be applied to any element

            let arrFocusCandidates = this.GetArrFocusCandidates();
            let index = arrFocusCandidates.indexOf(document.activeElement); //-1 if no element is active

            if (index < arrFocusCandidates.length - 1) {
                let intNextIndex = blnFocusOnNextEmptyControl ? this.FindNextEmptyControlIndex(arrFocusCandidates, index) : index + 1;

                if (!intNextIndex)
                    intNextIndex = index + 1;

                let nextElement = arrFocusCandidates[intNextIndex];
                console.debug('UIElementBase.focusNextInput(): moving from ' + this.id + ' to ' + nextElement.id);

                //added this condition since <select> HTML element does not support .select(); so this way we are skipping selects
                // found out at  early-notification-create.component
                if (nextElement.value.length > 0 && nextElement.localName != "select") {
                    nextElement.select();
                }

                nextElement.focus();
                return true;
            }
            else {
                // the only logic that I could think of to handle (??what was the issue??) issue with the last input on the page... we may need to revist it later;
                //arrFocusCandidates[index].blur();
            }
        }
        catch (ex) {
            console.error(ex);
        }

        return false;
    }

    /**
     * The method returns the index number of next empty element on UI.
     * @param arrFocusCandidates
     * @param intCurrentIndex
     */
    public FindNextEmptyControlIndex(arrFocusCandidates: any[], intCurrentIndex: number): number {
        if (arrFocusCandidates) {
            for (let i = intCurrentIndex + 1; i < arrFocusCandidates.length; i++) {
                let elemNextControl: any = arrFocusCandidates[i];

                if (elemNextControl) {

                    //For multiselect component
                    if (elemNextControl.attributes) {
                        //For MultiSelectComponent: attrblank is set by MultiSelectComponent
                        //TODO: Rework this: base class should not have a dependency on deriving class attribute (attrblank). For example, declare an IsBlank() abstract method and have each deriving class implement it
                        if ((elemNextControl.attributes.attrblank) && (elemNextControl.value == "" && elemNextControl.attributes.attrblank.value == 'true'))
                            return i;
                        else if ((!elemNextControl.attributes.attrblank) && (elemNextControl.value == "" || elemNextControl.value == strRBISDataValue.Minus1))
                            return i;
                    }
                    else if (elemNextControl.blnIsMultiSelect && elemNextControl.objModel.length == 0) {
                        return i;
                    }
                    //For TypeaheadComponent
                    else if (UIElementBase.IsUiElementBase(elemNextControl) && !Array.isArray(elemNextControl.objModel)) {
                        if (elemNextControl.strDbDataType == PropertyType.String && elemNextControl.objModel[elemNextControl.strFieldName] == strRBISDataValue.Empty)
                            return i;
                        else if (elemNextControl.objModel[elemNextControl.strFieldName] == intRBISDataValue.Blank || elemNextControl.objModel[elemNextControl.strFieldName] == strRBISDataValue.Minus1)
                            return i;
                    }

                }
            }
        }
        else
            console.error('UIElementBase.FindNextEmptyControlIndex(): ArgumentNullException: arrFocusCandidates is required to determine next control to receive focus');

        return null;
    }

    /**
     * Returns true if focus was advanced
     **/
    public focusPrevInput(): boolean {
        let inputElements = window.document.querySelectorAll('input, textarea, select, .for-focus');
        let focusInputElements = Array.prototype.filter.call(inputElements,
            function (element) {
                return (element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement)
                    && (element.hidden == false && element.disabled == false);
            });

        let index = focusInputElements.indexOf(document.activeElement);
        let prevElement;

        if (index == 0) {
            prevElement = focusInputElements[index];
        }

        if (index > 0) {
            prevElement = focusInputElements[index - 1];
        }

        if (index < focusInputElements.length) {
            if (prevElement.select && prevElement.value.length > 0) {
                prevElement.select();
            }

            prevElement.focus();
            return true;
        }

        return false;
    }

    /**
     * Applies leading-zero and trailing-decimal-zero formatting on the UI DOM element, if it is applicable.
     * When intMaxDecimal is zero, the maximum number of leading zeros is based on maxlength, when intMaxDecimal is non-zero, the maximum number of leading zeros is reduced
     */
    public FormatWithLeadingZeros(strValue: string): string {
        let strFormatted: string = null;

        if (strValue != undefined && strValue != null && strValue != '') { //Leading zeros are not applicable if field is blank
            if (!this.blnFormatWithLeading) { //The blnFormatWithLeading flag has been added to omit formatting for VIN control in VIN Decode tab.
                if (strValue != undefined && this.objTextbox.nativeElement.value != strValue)
                    this.objTextbox.nativeElement.value = strValue;

                strFormatted = strValue;
                return strFormatted;
            }

            if (AutofillService.GetDataType(this.objModel, this.strFieldName) == 'number') {
                if (this.maxlength) {

                    let intWholeNumActual: number;
                    let strPipeFormatString: string;
                    let intDecimalsActual: number;

                    if (this.intMaxDecimal == 0) {
                        intWholeNumActual = this.maxlength;
                        strPipeFormatString = `${intWholeNumActual}.0-0`;
                    }
                    else {
                        let intIndexDecimal: number = strValue.indexOf('.');

                        intWholeNumActual = intIndexDecimal != -1 ? intIndexDecimal : Math.max(this.maxlength - this.intMaxDecimal, strValue.length); //If number of digits in whole portion of number exceeds the minimum number of digits required, then we start taking away decimal positions;
                        intDecimalsActual = this.maxlength - intWholeNumActual >= 0 ? Math.min(this.maxlength - intWholeNumActual, this.intMaxDecimal) : this.intMaxDecimal; //Presence of decimal point increases effective max length by 1, but for the purpose of countin remaining places for decimal digits, that increment does not apply
                        strPipeFormatString = `${intWholeNumActual}.${intDecimalsActual}-${intDecimalsActual}`;
                    }

                    strFormatted = this.decimalPipe.transform(strValue, strPipeFormatString);
                    strFormatted = this.replaceTextPipe.transform(strFormatted, ',', '');
                }
            }
            else if (strValue.length < this.maxlength) {
                strFormatted = '0'.repeat(this.maxlength - strValue.length) + strValue;
            }

            if (strFormatted != undefined &&
                (strFormatted != strValue || strFormatted != this.objTextbox.nativeElement.value))
                this.objTextbox.nativeElement.value = strFormatted;
        }

        return strFormatted;
    }

    public RerenderModelValue(blnDisabled: boolean = null): void {
        if (this.objTextbox && this.objModel) {
            if (blnDisabled === true || blnDisabled === false)
                this.disableOrEnableComponent(blnDisabled);

            if (this.objTextbox.nativeElement.value == null || this.objTextbox.nativeElement.value == '')
                if (this.objModel[this.strFieldName] && this.objModel[this.strFieldName] != intRBISDataValue.Blank)
                    this.objTextbox.nativeElement.value = this.objModel[this.strFieldName];

            if (blnDisabled === true || blnDisabled === false) {
                this.disableOrEnableComponent(blnDisabled); //TODO: Why is disableOrEnableComponent() called a second time. For that matter, why is it called the first time?
            }
        }
    }

    public SetPlaceHolder(strValue: string): void {
        this.strPlaceholder = strValue;
    }
    /**
     * 
     * @param enable
     * It adds/removed class is-invalid to the input element based on the passing parameter. If blnClearHighlighting is false, it always keep the control is highligted. 
     */
    public setIsInvalidClass(enable: boolean): void {
        if (this.blnClearHighlighting) {
            //this.renderer.setElementClass(this.objTextbox.nativeElement, "is-invalid", enable);
            enable ? this.renderer.addClass(this.objTextbox.nativeElement, "is-invalid") : this.renderer.removeClass(this.objTextbox.nativeElement, "is-invalid");
        }
        else {
            //this.renderer.setElementClass(this.objTextbox.nativeElement, "is-invalid", true);
            this.renderer.addClass(this.objTextbox.nativeElement, "is-invalid");
        }
    }

    public focusAndHighlightUIElement(): void {
        this.setIsInvalidClass(true);
        this.focusUIElement();
    }

    /**
    * Focus UI control
    **/
    public focusUIElement(blnSelectControl: boolean = false): void {
        //this.renderer.invokeElementMethod(this.objTextbox.nativeElement, 'focus');
        //this.renderer['focus'].apply(this.objTextbox.nativeElement);
        this.objTextbox.nativeElement.focus();
        if (blnSelectControl)
            this.objTextbox.nativeElement.select();
    }

    public focus(): void {
        this.focusUIElement(true);
    }

    /**     * Find the DrpDownOption with the longest strValue and remember the length.
     **/
    public setMaxLengthOfCode(blnByDisplayText: boolean = false): void {
        if (!this.blnAllowFreeText) {
            let arrOptionsFiltered: DrpDownOptions[] = this.options.filter(x => x.strValue != null && x.intValue > intRBISDataValue.Blank);
            let objLongestOption: DrpDownOptions = null;

            if (arrOptionsFiltered.length > 0) {
                if (blnByDisplayText)
                    objLongestOption = arrOptionsFiltered.reduce(function (a, b) { return a.displayText.length > b.displayText.length ? a : b; });
                else
                    objLongestOption = arrOptionsFiltered.reduce(function (a, b) { return a.strValue.length > b.strValue.length ? a : b; });
            }

            if (objLongestOption) {
                this.maxlength = blnByDisplayText ? objLongestOption.displayText.length : objLongestOption.strValue.length;
            }
            else
                this.maxlength = 999;

            this.objTextbox.nativeElement.maxLength = this.maxlength;
        }
        else if (this.strDbDataType == PropertyType.Number) {
            this.maxlength = this.intMaxLengthCode;
            this.objTextbox.nativeElement.maxLength = this.intMaxLengthCode + (this.intMaxDecimal > 0 ? 1 : 0); //Re-eval max length: if UIElementBase.searchAll is true, maxlength may have been increased to allow text search
        }
        //else if (this.strDbDataType == PropertyType.String && this.blnAllowFreeText) {
        //    this.maxlength = this.intMaxLengthCode;
        //    this.objTextbox.nativeElement.maxLength = this.intMaxLengthCode;
        //}
    }

    protected SetModelValue(strNewValue: string, blnBypassLeadingZeroFormattingDuringInput: boolean = false): void {
        if (AutofillService.GetDataType(this.objModel, this.strFieldName) == 'number') { //Format freetext number fields with a maxlength specified with leading zeros 

            if (strNewValue != null && strNewValue != undefined && strNewValue != '')
                this.objCurrentValue = parseFloat(strNewValue);
            else
                this.objCurrentValue = intRBISDataValue.Blank;

            if (this.objModel[this.strFieldName] != this.objCurrentValue)
                this.objModel[this.strFieldName] = this.objCurrentValue;

            if (this.objCurrentValue != intRBISDataValue.Blank) {
                if (!blnBypassLeadingZeroFormattingDuringInput || !this.blnFormatListOnlyShowText)
                    this.FormatWithLeadingZeros(strNewValue);
            }
            else
                this.objTextbox.nativeElement.value = '';

            if (this.blnFormatListOnlyShowText) {
                let option: DrpDownOptions = this.options.find(i => i.intValue == this.objModel[this.strFieldName]);
                if (option)
                    this.objTextbox.nativeElement.value = option.strText;
            }
        }
        else {
            if (this.objModel[this.strFieldName] != strNewValue)
                this.objModel[this.strFieldName] = strNewValue;

            if (this.objTextbox.nativeElement.value != strNewValue)
                this.objTextbox.nativeElement.value = strNewValue;
        }
    }

    public static IsUiElementBase(objCandidate: any): objCandidate is UIElementBase {
        if ('_TypeScript_TypeGuard_UIElementBase' in objCandidate)
            return true;

        return false;
    }
}
