<div class="form-group alert-fixed card-body">
    <p><ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert></p>
</div>
<div class="card border-0" (window:beforeunload)="checkUnsavedChanges($event)">
    <div class="card-body py-0 px-1 bg-light margin-border">
        <div class="form-group mt-2 mb-3">
            <div class="d-flex justify-content-center rounded" [ngClass]="[strCaseStatus === 'Saved' ? 'bg-color-msg-success' : 'bgc-color-msg-modify' ]">
                <span class="fw-bold text-dark"> {{ strCaseStatus }} </span>
            </div>
        </div>
        <div >
            <div class="form-group">
                <button type="submit" id="btnRightToolbar_CheckCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="onSave($event,'checkCase')"
                        [disabled]="disabledSaveAndCheckCase">
                    <span class="fw-bold">Check Case</span>
                </button>
            </div>
            <div class="form-group">
                <button type="submit" id="btnRightToolbar_SaveCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="onSave($event,'save')"
                        [disabled]="disabledSaveCase">
                    <span class="text-dark fw-bold">Save Case</span>
                </button>
            </div>
            <div class="form-group">
                <button type="submit" id="btnRightToolbar_CloseCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="closeCase()"
                        [disabled]="disabledCloseCase">
                    <span class="text-dark fw-bold">Close Case</span>
                </button>
            </div>
            <div class="form-group">
                <button type="button" id="btnRightToolbar_RestructureCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="restructureCase($event)"
                        [disabled]="disabledRestructureCase">
                    <span class="text-dark fw-bold">Restructure Case</span>
                </button>
            </div>
            <div class="form-group" *ngIf="mode == 256 && adminRights">
                <button type="submit" id="btnRightToolbar_DeleteCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="deleteCase()"
                        [disabled]="disabledDeleteCase">
                    <span class="text-dark fw-bold">Delete Case</span>
                </button>
            </div>
            <div class="form-group" *ngIf="mode != 256">
                <button type="submit" id="btnRightToolbar_DeleteCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="deleteCase()"
                        [disabled]="disabledDeleteCase">
                    <span class="text-dark fw-bold">Delete Case</span>
                </button>
            </div>
            <div class="form-group">
                <button type="submit" id="btnRightToolbar_OverrideEditCheck" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="onOverride()"
                        [disabled]="disabledOverrideEditCheck">
                    <span class="text-dark fw-bold">Override Edit Check</span>
                </button>
            </div>
            <div class="form-group">
                <button type="button" id="btnRightToolbar_CrashEvents" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="OnCrashEvents($event)"
                        [disabled]="disabledCrashEvents">
                    <span class="text-dark fw-bold">Crash Events</span>
                </button>
            </div>
            <div class="form-group" *ngIf="mode == 64 || mode == 128">
                <button type="button" id="btnRightToolbar_CrashType" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="crashTypeWizard($event)"
                        [disabled]="disabledCrashTypeWizard">
                    <span class="text-dark fw-bold">Crash Type Wizard</span>
                </button>
            </div>
            <div class="form-group">
                <button type="submit" id="btnRightToolbar_ClearForm" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="onClearForm($event)"
                        [disabled]="disabledClearForm">
                    <span class="text-dark fw-bold">Clear Form</span>
                </button>
            </div>
            <div class="form-group" *ngIf="mode != 256">
                <button type="submit" id="btnCodingComplete" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="codingComplete(4)"
                        [disabled]="disabledCodingComplete">
                    <span class="text-dark fw-bold">Coding Complete</span>
                </button>
            </div>
            <div class="form-group" *ngIf="mode == 256"> 
                <button type="submit" id="btnCodingComplete" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="codingComplete()"
                        [disabled]="disabledCodingComplete">
                    <span class="text-dark fw-bold">{{ strCodingStatus }}</span>
                </button>
            </div>

            <div class="form-group" *ngIf="isHQStaff==true && mode == 256  && codingStatus != 5"> <!-- 5=HQ Review -->
                <button type="submit" id="btnHQReview" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="hqReview()"
                        [disabled]="disabledHQReview">
                    <span class="text-dark fw-bold">{{ strHQReviewStatus }}</span>
                </button>
            </div>

            <div class="form-group" *ngIf="isHQStaff==true && mode == 256 && (codingStatus == 5)"> <!-- 6=HQ Review Complete-->
                <button type="submit" id="btnHQReviewComplete" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="hqReviewComplete()"
                        [disabled]="disabledHQReviewComplete">
                    <span class="text-dark fw-bold">{{ strHQReviewCompleteStatus }}</span>
                </button>
            </div>
        </div>
    </div>
</div>






