import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../services/generic.service';
import { LogEntry } from '../models/LogEntry';
import { tap } from 'rxjs/operators';

/**
 * Requests involding data model objects are automatically validated and may be rejected before flow of control reaches API controller logic.
 * For example, if a non-nullable field is NULL, the response will be code 400 (Bad Request). This interceptor logs such bad requests.
 **/
@Injectable()
export class BadRequestLoggingInterceptor implements HttpInterceptor {
    constructor(private _baseService: GenericService<LogEntry>) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //Continue to next interceptor/handler. HttpRequests are daisy chained together, so no need to worry about multiple observers of request observable
        return next.handle(request).pipe(tap( //Using tap() instead of catchError() because tap() does not modify the response, whereas catchError() will interrupt the interceptor error-handling chain, even if it rethrows the error response
            null, //No action on success, request passes through to next interceptor
            (async (objResponse: HttpErrorResponse) => { 
                try {
                    if (objResponse instanceof HttpErrorResponse && objResponse.status == 400){
                        let objLogEntry: LogEntry = {
                            _TypeScript_TypeGuard_LogEntry: null,
                            Level: 'Warning',
                            Logger: 'BadRequestLoggingInterceptor',
                            Message: 'Request to ' + request.url + ' failed with error: ' + JSON.stringify(objResponse.error) + '.',
                            Exception: request.urlWithParams + '\r\n' + JSON.stringify(request.body)
                        } as LogEntry;

                        this._baseService.actionURL = 'api/util/ClientLog';
                        await this._baseService.updateEntity(objLogEntry).toPromise(); //Converting to promise so that observer is not needed
                    }
                }
                catch (ex) {
                    console.error('BadRequestLoggingInterceptor.intercept(): ', ex);
                }
            }).bind(this)));
    }
}
