<!-- boxType: C = Confirm | M = Message | A = Alert | W = Warning | YNC = YesNoCancel -->

<ng-container *ngIf="!isAlert">


    <div class="modal-header bg-light" *ngIf="boxType !='M'">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-center">
        <p [innerHTML]="body"></p>
    </div>

    <div class="modal-footer">
        <button ngbAutoFocus *ngIf="isRelaseLock && blnShowRelaseCaseBtn" type="button"
                class="btn btn-sm btn-outline-dark"
                (click)="gotoRelease()">
            Release Records
        </button>
        <ng-container *ngIf="focusTo=='yes'">
            <!--In case of focus Yes we are setting up focus to Yes button this will be the default parameter -->
            <button ngbAutoFocus *ngIf="!isRelaseLock" type="button" class="btn btn-sm btn-primary border-secondary" (click)="activeModal.close(true)">
                {{strYesText}}
            </button>
        </ng-container>
        <ng-container *ngIf="focusTo!='yes'">
            <button *ngIf="!isRelaseLock" type="button" class="btn btn-sm btn-light border-secondary " (click)="activeModal.close(true)">
                {{strYesText}}
            </button>
        </ng-container>

        <ng-container *ngIf="focusTo == 'no'">
            <!--In case of focus no we are setting up focus to no button -->
            <button ngbAutoFocus *ngIf="boxType !='M' && boxType !='W'" type="button" class="btn btn-sm btn-primary border-secondary" (click)="activeModal.close(false)">
                {{strNoText}}
            </button>
        </ng-container>

        <ng-container *ngIf="focusTo != 'no'">
            <button *ngIf="boxType !='M' && boxType !='W'" type="button" class="btn btn-sm btn-light border-secondary" (click)="activeModal.close(false)">
                {{strNoText}}
            </button>
        </ng-container>

        <button *ngIf="boxType == 'YesNoCancel'" type="button" class="btn btn-sm btn-light border-secondary" (click)="activeModal.dismiss('Canceled')">
            {{strDismissText}}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="isAlert">

    <div class="modal-body text-center">
        <p [innerHTML]="body"></p>
    </div>
    <div class="modal-footer">
        <button ngbAutoFocus type="button" class="btn btn-sm btn-primary border-secondary" (click)="activeModal.close(true)">
            Ok
        </button>
    </div>
</ng-container>
