import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { HelpDeskTicket } from 'src/app/models/HelpDeskTicket';
//services
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { HelpDeskTicketService } from 'src/app/services/helpdeckticket.service';
import { UtilService } from 'src/app/services/util.service';
import { CaseService } from 'src/app/services/case.service';
import { DBMode } from 'src/app/models/enums/app.enums';

@Component({
    selector: 'app-ask-coding-question',
    templateUrl: './ask-coding-question.component.html',
    styleUrls: ['./ask-coding-question.component.css']
})
export class AskCodingQuestionComponent implements OnInit {

    private sbsCaseNum: Subscription;
    caseNum: number;
    emailAddress: string='';
    caseString: string;
    issueDesc: string;
    //stateID: number;
    stateNum: number;
    noAttachment: boolean = true;
        
    attachedFileNames: string='';
    fileExtensionTypeInput = [];
    fileExtensionTypes = ["bmp","gif","png","jpg","tiff","doc","docx","xls","xlsx","pdf","txt","csv","PNG"];
    formData = new FormData();

    constructor(private _route: ActivatedRoute,
        public _sharedDataService: SharedDataService,
        private _modalService: ModalService,
        private _helpDeskTicketService: HelpDeskTicketService,
        private _utilService: UtilService,
        private _caseService: CaseService
    ) { }

    ngOnInit() {
        this.sbsCaseNum = this._route.params.subscribe((objParams: Params) => { //If the parameter in the route changes, we load the case for the new parameter value
            this.caseNum = +objParams.casenum;
            this.stateNum = +objParams.stateNum;

            if (!this._sharedDataService.selectedState) {
                this._sharedDataService.setSelectedState(this.stateNum);
            }
            this._utilService.GetDrpDownListOptions("StateNum", '').subscribe(objResult => {
                if (objResult) {
                    let arrStates: Array<number> = [];
                    if (objResult) {
                        objResult.forEach(item => {
                            arrStates.push(item.intValue);
                        });
                        this._sharedDataService.setListofValidStates(arrStates);
                    }
                    let ddoSelectedState = objResult.find(x => x.intValue === this.stateNum);
                    if (ddoSelectedState) {
                        this._sharedDataService.setDDOSelectedState(ddoSelectedState);
                        this._sharedDataService.subjectSelectedState.next(ddoSelectedState);
                    }
                }
            });

            if (this._caseService.acc != null && this._caseService.acc.Mode == 4) {
                this.issueDesc = this.caseString = 'PAR ID ' + this._caseService.acc.EarlyNotify[0].PARID.toString().slice(4,12)+ ': '
            } else if (this._caseService.acc != null && this._caseService.acc.Mode == DBMode.MOSS) {
                this.issueDesc = this.caseString = 'CASE # ' + this._caseService.acc.Acc_SS.MOSSCasenum + ': '
            }
            else {
                this.issueDesc = this.caseString = 'CASE #' + this.caseNum.toString() + ': '
            }
           
            //this.stateID = this._sharedDataService.getSelectedState();
        });
    }

    validateMultipleEmails(emails: string): boolean {
        if (emails.trim().length == 0) return true;
        if (this.emailAddress.search(';') >= 0) {
            this._modalService.dialog('Invalid email addresses, entered, Please replace ";" with "," before submitting.', 'Warning', 'M');
            return false;
        }
        let validEmails = false;
        var regex = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        validEmails = regex.test(emails);
        if (!validEmails) {           
            this._modalService.dialog('Invalid email address entered, Please correct it before submitting.', 'Warning', 'M');            
        }
        return validEmails;
    }

    emailCC(event) {        
        this.emailAddress = event.target.value.trim();
        this.validateMultipleEmails(this.emailAddress);
    }

    askQuestion(event) {
        this.issueDesc = event.target.value.trim();
    }       

    moveCursorToEnd(event) {
        var keyCode = event.keyCode || event.which;
        if (keyCode == 9) {
            event.selectionStart = event.selectionEnd = event.value.length;
        }
    }   
       
    public dropped(files: NgxFileDropEntry[]) {
        for (const droppedFile of files)  {
            if (droppedFile.fileEntry.isFile) {

                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

                fileEntry.file((file => {
                    // Made changes below to keep code consistance between DEV and MTSPC same;
                    //this.fileExtensionTypeInput = file.name.split(".");
                    let fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1) // file.name.split(".");

                    //if (this.fileExtensionTypes.indexOf(this.fileExtensionTypeInput[1]) >= 0) {
                    if (this.fileExtensionTypes.indexOf(fileExtension.toLowerCase()) >= 0) {
                        if (this.attachedFileNames.search(droppedFile.relativePath) == -1) {
                            this.formData.append('file', file, file.filename);
                            if (this.attachedFileNames.trim().length == 0) {
                                this.attachedFileNames = droppedFile.relativePath;
                            }
                            else {
                                this.attachedFileNames = this.attachedFileNames + "; " + droppedFile.relativePath;
                            }
                            this.noAttachment = false;
                        }
                        else {
                            this._modalService.dialog('File ' + droppedFile.relativePath+ ' has already been attached.', 'Warning', 'M');
                        }
                    }
                    else {
                        this._modalService.dialog('Invalid file extension for ' + droppedFile.relativePath, 'Warning','M');
                    }
                }).bind(this));
            }            
        }        
    }

    clearFiles() {
        this.attachedFileNames = '';
        this.formData = new FormData();
        this.noAttachment = true;
    }

    isEmptySubmission(): boolean {
        return ((this.issueDesc.trim().length == this.caseString.trim().length) && (this.attachedFileNames.trim().length == 0))
    }

    submit() {
        if (this.isEmptySubmission()) {
            this._modalService.dialog('Unable to submit empty Help Desk ticket!', 'Warning', 'M');
        }
        else {
            if (this.validateMultipleEmails(this.emailAddress)) {    
                //if (this.stateID == undefined) this.stateID = 1;
                this.formData.append('UnitId', this.stateNum.toString());
                this.formData.append('EmailCC', this.emailAddress);
                this.formData.append('Issue_Description', this.issueDesc);
                try {
                    this._helpDeskTicketService.CreateTicket(this.formData).then(result => {
                        this._modalService.dialog('Help desk ticket #' + result.TicketID + ' has been submitted', 'Warning', 'M').pipe(take(1)).subscribe();
                    });
                } catch (error) {
                    this._modalService.dialog('Enable to create Help Desk Ticket at this time.  Please contact Help Desk Service for assistance', 'Warning', 'M').pipe(take(1)).subscribe();
                }
                window.history.back();
            }
        }
    }
        
    cancel() {
        if (this.isEmptySubmission()) {
            window.history.back();           
        }
        else {
            this._modalService.dialog('The question has not been submitted.  Do you want to cancel?', 'Warning').pipe(take(1)).subscribe(okCliked => {
                if (okCliked) {
                    window.history.back();
                }
            })
        }
    }
}
