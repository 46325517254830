<div class="container-fluid">
    <div class="row">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="alert-info">
                        &nbsp;Case Entry Preferences
                    </h5>
                </div>
                <div class="col-md-12">
                    <table class="table table-sm">
                        <thead class="thead-light ">
                            <ng-container *ngFor="let prefRow of userPreferences, index as index">
                                <tr>
                                    <th class="text-sm-left" width="90%">
                                        <label class="col-form-label">
                                            {{prefRow.Preference}}:
                                            <br />
                                            <small>{{prefRow.Instruction}}</small>
                                        </label>
                                    </th>
                                    <th class="align-middle" width="20%">
                                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10">
                                            <div class="form-inline">
                                                <div class="form-group">
                                                    <label class="fw-bold text-dark mr-2">Off</label>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox"
                                                               id="OnOff_{{prefRow.PreferenceID}}"
                                                               name="{{prefRow.PreferenceID}}"
                                                               class="custom-control-input for-focus"
                                                               (ngModelChange)="togglePrefOnOff(index)"
                                                               checked="{{prefRow.PreferenceValue ? 'checked':''}}"
                                                               [(ngModel)]="userPreferences[index].PreferenceValue" />
                                                        <label class="custom-control-label bg-primary" for="OnOff_{{prefRow.PreferenceID}}"></label>
                                                    </div>
                                                    <label class="fw-bold text-dark ml-2">On</label>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </ng-container>
                        </thead>
                    </table>
                </div>
                <div class="col-md-12" *ngIf="isEDTstate && userEDTPreferences.length > 0">
                    <h5 class="alert-info">
                        &nbsp;State Level Preferences
                    </h5>
                </div>
                <div class="col-md-12" *ngIf="isEDTstate && userEDTPreferences.length > 0">
                    <table class="table table-sm">
                        <thead class="thead-light ">
                            <ng-container *ngFor="let prefRow of userEDTPreferences, index as index">
                                <tr>
                                    <th class="text-sm-left" width="90%">
                                        <label class="col-form-label">
                                            {{prefRow.Preference}}:
                                            <br />
                                            <small>{{prefRow.Instruction}}</small>
                                        </label>
                                    </th>
                                    <th class="align-middle" width="20%">
                                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10">
                                            <div class="form-inline">
                                                <div class="form-group">
                                                    <label class="fw-bold text-dark mr-2">Off</label>
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox"
                                                               id="OnOff_{{prefRow.PreferenceID}}"
                                                               name="{{prefRow.PreferenceID}}"
                                                               class="custom-control-input for-focus"
                                                               (ngModelChange)="toggleEDTPrefOnOff(index)"
                                                               checked="{{prefRow.PreferenceValue ? 'checked':''}}"
                                                               [(ngModel)]="userEDTPreferences[index].PreferenceValue" />
                                                        <label class="custom-control-label bg-primary" for="OnOff_{{prefRow.PreferenceID}}"></label>
                                                    </div>
                                                    <label class="fw-bold text-dark ml-2">On</label>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </ng-container>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="col-sm-1">
                <p>&nbsp;&nbsp;</p>
            </div>
            <div class="col-sm-11">
                <button class="btn btn-primary btn-block active" id="save" (click)="onSave()">
                    Save
                </button>
            </div>
            &nbsp;
            <div class="col-sm-11">
                <button class="btn btn-block btn-md btn-secondary" id="backToCase" (click)="onBack()">
                    <span class="glyphicon glyphicon-print"></span>Back
                </button>
            </div>
        </div>
    </div>
</div>
