<form novalidate #fvpperson="ngForm">
    <div class="pt-2" *ngIf="person">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="Age" [printOnly]="printOnly" inputName="Age"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-modals-age-calculator #ageCalculator *ngIf="!blnReadOnly"
                                                           name="personAge"
                                                           [personObj]="person"
                                                           [accDate]="accDate"
                                                           [occupantDateOfBirth]="personDateOfBirth"
                                                           (newDOBEvent)="setDateOfBirth($event)">
                                </app-modals-age-calculator>
                            </span>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="Age" name="Age" typeAheadPosition="right-top"
                                               [disableLabel]="true" [blnShowSpecialValuesOnly]="true"
                                               [objModel]="person" #ageField (keyup)="handleKeyup($event)" strTableName="Occupants" strFieldName="Age"
                                               [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="sex" name="sex" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="Sex" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="pType" name="pType" typeAheadPosition="right-top" strFilterCondition=" AND Type = 1" strDefsLookupTableName="PType"
                                   [objModel]="person" (bindModel)="OnModelPTypeChange($event)"
                                   strTableName="Occupants" strFieldName="PType" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="injury" name="injury" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="PolInjSevr"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="seatPos" name="seatPos" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="SeatPos" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="AirBagDeplmnt" name="AirBagDeplmnt" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="AirBagDeplmnt" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->
            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
        <div class="form-group pl-2">
            <app-typeahead id="takeHosp" name="takeHosp" typeAheadPosition="right-top"
                           [objModel]="person" strTableName="Occupants" strFieldName="TakeHosp" [printOnly]="printOnly"
                           [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->
            <!--<div *ngIf="printOnly && intMode == 1" class="col-md-12 " style="margin-top:35px!important"></div>-->


        </div>
    </div>
</form>
