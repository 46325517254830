
    <div class="modal-header">
        <h4 class="modal-title-geo">Geolocator</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(null)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body-geo text-center">
        <iframe id="geoLocationContainer" #iFrame (load)="onLoadIframe(iFrame)" class="iframe-withandheight" [src]="iframeSrc" frameborder="0" scrolling="no" ></iframe>
    </div>


