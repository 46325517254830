import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from '../../../services/case.service';
import { UtilService } from '../../../services/util.service';
import { Acc } from '../../../models/acc';
import { Non_Occupants } from '../../../models/non-occupants';
import { Element_Specify } from '../../../models/element-specify';
import { TableFieldElements } from '../../../models/table-field-elements';
import { DrpDownOptions } from '../../../models/drp-down-options';
import { FormName, TableId, KeyCode, DBMode, Preference, RBISDataValue } from '../../../models/enums/app.enums';
import { TypeaheadComponent } from '../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';

import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service'
import { ModalsAlcoholCalculatorComponent } from 'src/app/components/modals/modals-alcohol-calculator/modals-alcohol-calculator.component';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Subscription } from 'rxjs';
import { NonOcc_Other } from 'src/app/models/non-occ-other';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { NgForm } from '@angular/forms';
import { AutofillService } from 'src/app/services/autofill.service';
import { UIElementBase } from 'src/app/helper/UIElementBase';

@Component({
    selector: 'app-non-occupant-other',
    templateUrl: './non-occupant-other.component.html',
    styleUrls: ['./non-occupant-other.component.scss']
})
/** NonOccupantOther component*/
export class NonOccupantOtherComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    /** NonOccupantOther ctor */
    private sbsNonOccId: Subscription;

    accid: number;
    nonOccid: number;
    intMode: number;

    acc: Acc;
    nonOccupant: Non_Occupants;
   // nonOcc_Other: NonOcc_Other[];
    data: Acc;
    objInstanceElementSpecify: Element_Specify;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('fvno') objForm: NgForm;
    
    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _preferencesService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _autofillService: AutofillService,
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);

    }

    public async onBeforeSave() {
        console.log(this.acc);
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    //async ngOnInit() {
    //    super.ngOnInit();
       
    //    if (this.printNonOccupantNum == 0) {
    //        this._route.parent.parent.params.subscribe(params => {
    //            this.accid = + params['caseid'];
    //        });
    //    }
    //    else {
    //        this.accid = this.acc.AccID;
    //    }

    //    if (this.sbsNonOccId) {
    //        this.sbsNonOccId.unsubscribe();
    //    }

    //    if (this.printNonOccupantNum == 0) {
    //        this.sbsNonOccId = this._route.parent.params.subscribe(params => {
    //            this.nonOccid = + params['nonOccid'];
    //            this.FormLoad();
    //        });
    //    } else {
    //        this.nonOccid = this.printNonOccupantNum;
    //        this.FormLoad();
    //        //this.getCrashGroupType();

    //    }
       
    //  //  this.hideDrugInvolvement = ((this.mode == DBMode.FARS || this.mode == DBMode.RECODE));
    //}

    ngOnInit() {
        this._sharedService.GetAppSettings().then((appSetting: AppSettings) => {
            this.intMode = appSetting.intMode;
        });

        this._route.parent.params.subscribe((params => {
            this.accid = + params['crashid'];
        }).bind(this));

        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
        }
    }

    FormLoad() {
   
        this.data = this.acc;
        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);
        //console.log('NonOccupant from BicyclistComponent', this.nonOccupant);
        // if(this.printNonOccupantNum ==0) this.getCrashGroupType();

        // this.nonOcc_Other = this.nonOccupant.NonOcc_Other;

       // if ((!this.nonOcc_Other) || (this.nonOcc_Other.length == 0)) {
        if (this.nonOccupant.NonOcc_Other == null) {
            this._autofillService.AddNonOccOther(this.nonOccupant);
        }
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, 0, this.nonOccupant.PNumber);

        //Sort Non-OCC Heading, Striking Vehicle. Display special options at first
        let sbsSubscription: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            sbsSubscription.unsubscribe();
            let NonHeadingTyhpeAhead: TypeaheadComponent = this._autofillService.arrControls
                .find(i =>
                    (i.strTableName == "NonOcc_Other" && i.strFieldName == "PedHeading")) as TypeaheadComponent;

            NonHeadingTyhpeAhead.options.sort((a, b) => {
                if (a.intValue >= 359) {
                    return -1;
                } else if (b.intValue < 359) {
                    return 1;
                }
                return 0;
            })

            let StrikeHeadingTyhpeAhead: TypeaheadComponent = this._autofillService.arrControls
                .find(i =>
                    (i.strTableName == "NonOcc_Other" && i.strFieldName == "StrkVehHeading")) as TypeaheadComponent;

            StrikeHeadingTyhpeAhead.options.sort((a, b) => {
                if (a.intValue >= 359) {
                    return -1;
                } else if (b.intValue < 359) {
                    return 1;
                }
                return 0;
            })
        }).bind(this));
    }

    setWeightValue(event) {
        let result = event;
        result = String(result);
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.PedWeight);
        if (component) {
            component.setCurrentValue(result);
            component.onBlur(result);
            this.objForm.form.markAsDirty();
        }
    }


    setHeightValue(event) {
        let result = event;       
        result = String(result);
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.PedHeight);
       if (component) {
            component.setCurrentValue(result);
            component.onBlur(result);
            this.objForm.form.markAsDirty();
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
