import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CaseService } from 'src/app/services/case.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UtilService } from 'src/app/services/util.service';

import { Veh } from './../../../../models/veh';
import { Trailer } from './../../../../models/trailer';
import { TrailerVINDecode } from './../../../../models/trailer-vindecode';
import { vinDecode } from './../../../../models/vin-decode';

import { TableFieldElements } from './../../../../models/table-field-elements';
import { DrpDownOptions } from './../../../../models/drp-down-options';
import { FormName, RBISDataValue } from './../../../../models/enums/app.enums';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { map } from 'rxjs/operators';
import { Acc } from 'src/app/models/acc';
import { AutofillService } from 'src/app/services/autofill.service';
import { NgForm } from '@angular/forms';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';


import { ObjectUtil } from 'src/app/helper/objectUtil';
import { Subscription } from 'rxjs';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';


@Component({
    selector: 'app-vehicle-vehicle-trailer',
    templateUrl: './vehicle-vehicle-trailer.component.html',
    styleUrls: ['./vehicle-vehicle-trailer.component.css']
})

export class VehicleVehicleTrailerComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehicleVehicleTrailerComponent: string = null;
    sbsClearForm: Subscription;

    stateNum: number;
    accid: number;
    vehicleid: number;

    public acc: Acc;
    veh: Veh;
    objVehClone: Veh;
    trailers: Trailer[];
    TrailerVINDecode: TrailerVINDecode;

    vinNumber: string;
    vinData: any;

    URLfragment: string;
    inputElements: NodeListOf<any>;

    seqNumber: number;
    targetId: string;

    vinMetaData: TableFieldElements;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;


    arrFragments = ['trailerVIN0', 'trailerVIN1', 'trailerVIN2'];

    //isTrailerOneVisiable: boolean = true; //Decoded VIN attributes visible and VIN decode button enabled (VIN field is always visible)
    //isTrailerTwoVisiable: boolean = true;
    //isTrailerThreeVisiable: boolean = true;

    blnTrailerOneDisabled: boolean = false;
    blnTrailerTwoDisabled: boolean = false;
    blnTrailerThreeDisabled: boolean = false;

    @ViewChild('fvvtrailer') objForm: NgForm;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _sharedDataService: SharedDataService,
        private _autoFillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _vehicleService: VehicleService
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngOnInit() {

        this.accid = this.acc.AccID;

        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe((params => {
                this.stateNum = + params['stateNum'];
                this.accid = + params['caseid'];
            }).bind(this));

            this._route.fragment.subscribe(((fragment: string) => {

                this.URLfragment = fragment;

                if (this.arrFragments.includes(fragment)) {
                }
                else {
                    super.ngOnInit();
                }

            }).bind(this));

            this._route.parent.parent.params.subscribe((params => {
                this.vehicleid = + params['vehicleid'];
                this.initDataInSuscribe();
                
            }).bind(this));
            this.initData();
        } else {
            this.vehicleid = this.printVehNum;
            this.initDataInSuscribe();
            this.initData();
        }
       
    }

    initDataInSuscribe() {

        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.objVehClone = ObjectUtil.CloneModelObject(this.veh);

        if (this.veh) {//set btn's disabled based on this.veh.Trailer;            
            this.setDisabledTrailers();
        }

        this.trailers = this.acc.Veh.find(v => v.VNumber == this.vehicleid).Trailer1;

        if (this.trailers.length < 1) {
            for (let i = 1; i <= 3; i++) {
                let item = this.setDefaultTrailer(this.veh, i);
                this.veh.Trailer1.push(item);
            }
        }

        let subRowsVisible = UIElementBase.evtSiblingsInitialized.subscribe((() => { //Grid row has rendered
            subRowsVisible.unsubscribe();

            setTimeout(() => {
                //A trivial time delay is needed for call to click() to be able to spawn ngbTypeahead popup
                switch (this.URLfragment) {
                    case this.arrFragments[0]: {
                        let typeahead: TypeaheadComponent = this._autoFillService.arrControls.find(i => i.strFieldName === 'TrailerVIN' && i.intSeqNum === 1) as TypeaheadComponent;
                        typeahead.objTextbox.nativeElement.click();
                        typeahead.objTextbox.nativeElement.focus();
                        break;
                    }
                    case this.arrFragments[1]: {
                        let typeahead: TypeaheadComponent = this._autoFillService.arrControls.find(i => i.strFieldName === 'TrailerVIN' && i.intSeqNum === 2) as TypeaheadComponent;
                        typeahead.objTextbox.nativeElement.click();
                        typeahead.objTextbox.nativeElement.focus();
                        break;
                    }
                    case this.arrFragments[2]: {
                        let typeahead: TypeaheadComponent = this._autoFillService.arrControls.find(i => i.strFieldName === 'TrailerVIN' && i.intSeqNum === 3) as TypeaheadComponent;
                        typeahead.objTextbox.nativeElement.click();
                        typeahead.objTextbox.nativeElement.focus();
                        break;
                    }
                    default: {
                        if (this.URLfragment == 'undefined') {
                            let typeahead: TypeaheadComponent = this._autoFillService.arrControls.find(i => i.strFieldName === 'TrailerVIN' && i.intSeqNum === 1) as TypeaheadComponent;
                            typeahead.objTextbox.nativeElement.click();
                            typeahead.objTextbox.nativeElement.focus();
                        }
                        break;
                    }
                }
            }, 1);
        }).bind(this));
    }

    initData() {
        this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe(async item => {
            let param = this.veh.Trailer;
            switch (param) {
                case 1: {
                    setTimeout(() => {
                        this.veh.Trailer1.forEach(item => {
                            if (item) {
                                if (item.SeqNum === 1) {
                                    if (item.TrailerVPICDECODE) {
                                        //item.TrailerVPICDECODE.Make = '';
                                        //item.TrailerVPICDECODE.BodyClass = '';
                                        //item.TrailerVPICDECODE.TrailerBodyType = '';
                                        //item.TrailerVPICDECODE.Model = '';
                                        //item.TrailerVPICDECODE.ModelYear = -1;
                                        item.TrailerVPICDECODE = null;
                                    }
                                }
                            }
                        });
                        let uiControls = this._autoFillService.arrControls.filter(i => i.id === 'Trailer1Vin1');
                        uiControls.forEach(x => x.clearComponent());

                    }, 5);

                    break;
                }
                case 2: {
                    setTimeout(() => {
                        this.veh.Trailer1.forEach(item => {
                            if (item) {
                                if (item.SeqNum === 1 || item.SeqNum === 2) {
                                    if (item.TrailerVPICDECODE) {
                                        //item.TrailerVPICDECODE.Make = '';
                                        //item.TrailerVPICDECODE.BodyClass = '';
                                        //item.TrailerVPICDECODE.TrailerBodyType = '';
                                        //item.TrailerVPICDECODE.Model = '';
                                        //item.TrailerVPICDECODE.ModelYear = -1;
                                        item.TrailerVPICDECODE = null;
                                    }
                                }
                            }
                        });
                        let uiControls = this._autoFillService.arrControls.filter(i => i.id === 'Trailer1Vin1' || i.id === 'Trailer1Vin2');
                        uiControls.forEach(x => x.clearComponent());
                    }, 5);
                    break;
                }
                case 3: {
                    setTimeout(() => {
                        this.veh.Trailer1.forEach(item => {
                            if (item) {
                                if (item.SeqNum === 1 || item.SeqNum === 2 || item.SeqNum === 3) {
                                    if (item.TrailerVPICDECODE) {
                                        //item.TrailerVPICDECODE.Make = '';
                                        //item.TrailerVPICDECODE.BodyClass = '';
                                        //item.TrailerVPICDECODE.TrailerBodyType = '';
                                        //item.TrailerVPICDECODE.Model = '';
                                        //item.TrailerVPICDECODE.ModelYear = -1;
                                        item.TrailerVPICDECODE = null;
                                    }
                                }
                            }
                        });
                        let uiControls = this._autoFillService.arrControls.filter(i => i.id === 'Trailer1Vin1' || i.id === 'Trailer1Vin2' || i.id === 'Trailer1Vin3');
                        uiControls.forEach(x => x.clearComponent());
                    }, 5);
                    break;
                }
                case 4: {
                    setTimeout(() => {
                        this.veh.Trailer1.forEach(item => {
                            if (item) {
                                if (item.SeqNum === 1 || item.SeqNum === 2 || item.SeqNum === 3) {
                                    if (item.TrailerVPICDECODE) {
                                        //item.TrailerVPICDECODE.Make = '';
                                        //item.TrailerVPICDECODE.BodyClass = '';
                                        //item.TrailerVPICDECODE.TrailerBodyType = '';
                                        //item.TrailerVPICDECODE.Model = '';
                                        //item.TrailerVPICDECODE.ModelYear = -1;
                                        item.TrailerVPICDECODE = null;
                                    }
                                }
                            }
                        });
                        let uiControls = this._autoFillService.arrControls.filter(i => i.id === 'Trailer1Vin1' || i.id === 'Trailer1Vin2' || i.id === 'Trailer1Vin3');
                        uiControls.forEach(x => x.clearComponent());
                    }, 5);
                    break;
                }
                default: {
                    break;
                }
            }
        });
        this._utilService.metaDataToShare.subscribe(result => {
            this.vinMetaData = result.find(x => x.Form == FormName.Vehicle && x.Field == "VIN");
        });

    }

    setDisabledTrailers() {
        let param = this.veh.Trailer;
        switch (param) {
            case 1: {
                this.blnTrailerOneDisabled = true;
                break;
            }
            case 2: {
                this.blnTrailerOneDisabled = true;
                this.blnTrailerTwoDisabled = true;
                break;
            }
            case 3: {
                this.blnTrailerOneDisabled = true;
                this.blnTrailerTwoDisabled = true;
                this.blnTrailerThreeDisabled = true;
                break;
            }
            case 4: {
                this.blnTrailerOneDisabled = true;
                this.blnTrailerTwoDisabled = true;
                this.blnTrailerThreeDisabled = true;
                break;
            }
            default: {
                break;
            }
        }
    }

    setDefaultTrailer(objVeh: Veh, intSeqNum: number): Trailer {
        let objTrailer = {} as Trailer;
        let clearDefaultValue = false;
        if (intSeqNum <= this.veh.Trailer) clearDefaultValue = true;
        objTrailer._TypeScript_TypeGuard_Trailer = null;
        objTrailer.Veh = objVeh;
        objTrailer.AccID = this.veh.AccID;
        objTrailer.VNumber = this.veh.VNumber;
        objTrailer.SeqNum = intSeqNum;
        objTrailer.TrailerVIN = (clearDefaultValue) ? '' : "77777777777777777";
        objTrailer.TrailerEmptyWeight = -1;
        objTrailer.Deleted = false;

        //Betul : This section is removed becuase spTrailerVINReDecodeAll sp creates the record when the trailer VIN is decoded.Otherwise initially it should be null if vin has not decoded. 

        //objTrailer.TrailerVPICDECODE = AutofillService.AddModelObject(objTrailer, 'Trailer', 'TrailerVPICDECODE');
        //objTrailer.TrailerVPICDECODE.VIN = " ";
        //objTrailer.TrailerVPICDECODE.vPICMakeID = -1;
        //objTrailer.TrailerVPICDECODE.vPICBodyClassID = -1;
        //objTrailer.TrailerVPICDECODE.vPICTrailerBodyTypeID = -1;
        //objTrailer.TrailerVPICDECODE.vPICGVWRID = -1;
        //objTrailer.TrailerVPICDECODE.vPICModelID = -1;
        //objTrailer.TrailerVPICDECODE.ModelYear = -1;

        return objTrailer;
    }

    public OnPasteTrailerVIN($event: ClipboardEvent, n: number) {
        this._modalService.resetMessage();
        let strVIN: string = $event.clipboardData.getData('Text').trim();

        //let newValue = strVIN.replace(/\s/g, '*');
        let newValue = strVIN.replace(/\s/g, '');
        this.veh.Trailer1[n].TrailerVIN = newValue;       

        setTimeout((() => {
            let uiControl: TypeaheadComponent = this._autoFillService.arrControls.find(x => x.strFieldName == "TrailerVIN" && x.intSeqNum == n + 1) as TypeaheadComponent;
            uiControl.setCurrentValue(newValue);
            uiControl.RerenderModelValue();

            if (this.veh.Trailer1[n].TrailerVIN.length > 17) {
                this._modalService.setMessage("Pasted Trailer VIN number has more then 17 charaters, please correct it and try again", "warning");
                return;
            }
           

            this.onTrailerVINModelChange(this.veh.Trailer1[n]);

        }).bind(this), 1);

    }

    public onTrailerVINModelChange($event: Trailer) {

        //$event.TrailerVIN.toUpperCase();

        this._modalService.resetMessage();

        if (this.objVehClone.Trailer1.find(x => x.SeqNum == $event.SeqNum).TrailerVIN !== "" && $event.TrailerVIN === "") {
            this._modalService.dialogPromise("By Clearing Trailer VIN number, all existing decoded information will be cleared as well. Are you Sure you want to proceed with this?", "Are you sure?").then((confirm) => {
                if (confirm) {
                    if ($event.TrailerVPICDECODE) {
                        //$event.TrailerVPICDECODE.Make = "";
                        //$event.TrailerVPICDECODE.BodyClass = "";
                        //$event.TrailerVPICDECODE.TrailerBodyType = "";
                        //$event.TrailerVPICDECODE.Model = "";
                        //$event.TrailerVPICDECODE.ModelYear = -1;
                        //$event.TrailerVPICDECODE.VIN
                        $event.TrailerVPICDECODE = null;
                        this.objVehClone.Trailer1.find(x => x.SeqNum == $event.SeqNum).TrailerVIN = $event.TrailerVIN;
                    }
                }
                else {
                    this.veh.Trailer1.find(x => x.SeqNum == $event.SeqNum).TrailerVIN = this.objVehClone.Trailer1.find(x => x.SeqNum == $event.SeqNum).TrailerVIN;

                    let uiControl: TypeaheadComponent = this._autoFillService.arrControls.find(x => x.strFieldName == "TrailerVIN" && x.intSeqNum == $event.SeqNum) as TypeaheadComponent;
                    uiControl.RerenderModelValue();
                }
            });
        }

        if ($event.TrailerVIN.length === 17) {
            let URLfragment: string;

            if ($event.SeqNum === 1) {
                URLfragment = this.arrFragments[0];
            }
            else if ($event.SeqNum === 2) {
                URLfragment = this.arrFragments[1];
            }
            else {
                URLfragment = this.arrFragments[2];
            }
            this.decodeVin(URLfragment, $event);
        }
    }

    decodeVin(urlFragment, objTrailer: Trailer) {
        this._modalService.resetMessage();

        let trailerVIN = objTrailer.TrailerVIN;

        //Cheking if VIN # has WhiteSpace
        let blnWhiteSpace = ObjectUtil.HasWhiteSpaces(trailerVIN);

        //Cheking if VIN # has 17, 0's|7's|8's|9's
        const noCheckVin: boolean = ObjectUtil.VINHasLookupsValues(trailerVIN);

        //If we need to repalce if VIN number has empty spaces with the * we have functionality in objectUtil.ts;

        if (objTrailer.TrailerVIN === "" || noCheckVin) {
            this._modalService.setMessage("No VIN Checking is performed if VIN is blank, all 0's or 7's or 8s or 9's.", "info");
            return;
        }

        if (blnWhiteSpace) {
            this._modalService.setMessage("Trailer VIN number has empty space/s, please replace with * and try again", "warning");
            return;
        }

        else {
            this.objForm.form.markAsPristine();
            this._sharedDataService.setVinNumber(objTrailer.TrailerVIN);
            this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', this.vehicleid, 'vehicle', 'vehicleVINDecode'], { fragment: urlFragment });
        }
    }

    setWeightValue(event, id) {

       // this.veh.Veh_MTSS.CargoWeight = event;
        let result = event;
        result = String(result);
        // this.focuseOut = true;
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.CURBWTTRLR && x.id == id);
      
        if (component) {
            component.setCurrentValue(result);
            component.onBlur(result);
            this.MarkFormAsDirty();
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsClearForm) {
            this.sbsClearForm.unsubscribe();
        }
    }

    //public openVinRequest(trailer: Trailer) {
    //    if (this.objForm.dirty) {
    //        this._vehicleService.SaveVeh(this.veh)
    //            .then((result => {
    //                this.objForm.form.markAsPristine();
    //                this._sharedDataService.subReEvalCaseActionButtons.next({ objComponent: null, objCase: this.acc });
    //                this._modalService_CannedPopups.vinRequest(this.acc.AccID, this.veh.VNumber, trailer.SeqNum, this.acc.Casenum, false, trailer.TrailerVIN).toPromise();
    //            }).bind(this));
    //    } else {
    //        this._modalService_CannedPopups.vinRequest(this.acc.AccID, this.veh.VNumber, trailer.SeqNum, this.acc.Casenum, false, trailer.TrailerVIN).toPromise();
    //    }
    //}

}
