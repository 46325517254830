import { Pipe, PipeTransform } from '@angular/core';
import { States, EDTStatus, Months } from 'src/app/models/enums/app.enums';


@Pipe({
    name: 'enumAsString'
})
export class EnumAsStringPipe implements PipeTransform {

    transform(value: any, enumType?: any): any {
        if (value) {
            if (enumType === 'States') {
                return States[value];
            }
            if (enumType === 'Months') {
                return Months[value];
            }
            if (enumType === 'EDTStatus') {
                if (value === -1) {
                    return "";
                } else { 
                    return EDTStatus[value];
                }
            }            
        }        
    }
}
