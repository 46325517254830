<div class="row">
    <div class="col-xl">
        <div class="card border-dark">

            <div class="card-header text-dark">
                <div class="my-auto">
                    <div class="card-title my-auto pl-2">
                        <h3 class="showhinline" *ngIf="selectedMode !=4 ">Release Records</h3>                                             
                    </div>
                </div>
            </div>
            
            <div class="card-body">              
                <div class="row">
                    <div class="col-10">
                        <div class="card">
                            <div class="card-body">

                                <h5 class="card-title" *ngIf="selectedMode == 64">Release MTSS Records</h5>
                                <h5 class="card-title" *ngIf="selectedMode == 128">Release PPSS Records</h5>
                                <h5 class="card-title" *ngIf="selectedMode == 256">Release MOSS Records</h5>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" class=" text-center">Release Record</th>
                                            <th scope="col" class=" text-center" *ngIf="selectedMode == 64">MTSS Case Number</th>
                                            <th scope="col" class=" text-center" *ngIf="selectedMode == 128">PPSS Case Number</th>
                                            <th scope="col" class=" text-center" *ngIf="selectedMode == 256">MOSS Case Number</th>

                                            <th scope="col" class=" text-center">Who Has It</th>
                                            <th scope="col" class=" text-center">Date/Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="locked_case.length == 0">
                                            <td scope="col" colspan="5" class=" text-center">There is no locked accounts.</td>
                                        </tr>
                                        <tr *ngFor="let item of locked_case; index as i">
                                            <td id="btnReleaseRecordAndFars_releaseRecord_{{i}}" scope="col" class="text-center">
                                                <button type="button" (click)="releaseRecord(item,i,selectedMode)" class="btn btn-secondary" data-toggle="tooltip" data-placement="bottom" title="Release Record">
                                                    Release Record
                                                </button>
                                            </td>
                                            <td scope="col" class="text-center" *ngIf="item.CaseNum !=-1 && (selectedMode == 1 || selectedMode == 64 || selectedMode == 128)">{{item.CaseNum | number : '4.0-0' | replaceTextPipe : ',' : ''}}</td>
                                            <td scope="col" class="text-center" *ngIf="item.MossCaseNum !=-1 && (selectedMode == 256)">{{item.MossCaseNum | replaceTextPipe : ' ' : '' | maxLengthPipe : 12 : ''}}</td>
                                            <td scope="col" class="text-center">{{item.UserID | username | async }}</td>
                                            <td scope="col" class="text-center">{{item.LockedDate | date:'M/d/yyyy, h:mm:ss a'}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div class="card mt-3" style="display:none">
                            <div class="card-body">
                                <h5 class="card-title">Release Early Notification Records</h5>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" class=" text-center">Release Record</th>
                                            <th scope="col" class=" text-center">Case Number</th>
                                            <th scope="col" class=" text-center">Who Has It</th>
                                            <th scope="col" class=" text-center">Date/Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="locked_case.length == 0">
                                            <td scope="col" colspan="4" class=" text-center">There is no locked accounts.</td>
                                        </tr>
                                        <tr *ngFor="let item of locked_case; index as i">
                                            <td scope="col" class="text-center">
                                                <button id="btnReleaseRecord_releaseRecord_{{i}}" type="button" (click)="releaseRecord(item,i)" class="btn btn-secondary" data-toggle="tooltip" data-placement="bottom" title="Release Record">
                                                    Release Record
                                                </button>
                                            </td>
                                            <td scope="col" class=" text-center">{{item.AccID}}</td>
                                            <td scope="col" class=" text-center">{{item.UserID | username | async }}</td>
                                            <td scope="col" class=" text-center">{{item.LockedDate | date:'short'}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div class="col-2">
                        <div class="border-left border-secondary pl-2">
                            <div class="form-group">
                                <button id="btnReleaseRecord_BackToHome" type="button" class="btn btn-light border-secondary btn-block" [routerLink]='["/"]'>Back to Home</button>
                            </div>
                            <div class="form-group">
                                <button id="btnReleaseRecord_releaseAllRecord" type="button" class="btn  btn-primary btn-block" *ngIf="hasAdminRights" (click)="releaseAllRecord()">Release All</button>
                                <button id="btnReleaseRecord_releaseOwnRecord" type="button" class="btn  btn-primary btn-block" *ngIf="hasReleaseRights" (click)="releaseOwnRecord()">Release Own Record</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
