import { Component, OnInit } from '@angular/core';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { VinrequestService } from 'src/app/services/vinrequest.service';
import { VinRequestSearch_Result } from 'src/app/models/VinRequestSearch_Result';
import { HomeService } from 'src/app/services/home.service';
import { ReqStatus } from 'src/app/models/VinRequestModel';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { CustomNvpFilter, DBMode } from 'src/app/models/enums/app.enums';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';

@Component({
    selector: 'app-vin-requests-details',
    templateUrl: './vin-requests-details.component.html',
    styleUrls: ['./vin-requests-details.component.css']
})
export class VINRequestsDetailsComponent implements OnInit {

    arrStates: DrpDownOptions[];
    currentPage: number = 1;
    totalPages: number = 1;
    pageSize: number = 2;
    seletedState: number = -1;
    vinRequestSearchResult: VinRequestSearch_Result[];
    vinCurrentRequests: VinRequestSearch_Result[];
    tmpResult: VinRequestSearch_Result[];
    seletedStatus: number = 2;
    caseNumber: number = null;
    createdBy: string = null;
    hasAdminRights: false;
    pageNumberList: Array<number> = [];
    statusList: ReqStatus[];
    hasNoRows: boolean = false;

    stateNum: number;
    selectedYear: number;
    selectedMode: number;

    arrPSU: DrpDownOptions[];
    allOption: DrpDownOptions = { intValue: -1, strText: 'All',displayText:'All' } as DrpDownOptions;
    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilService: UtilService,
        public sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _vinrequestService: VinrequestService,
        private _homeService: HomeService,
        protected _urlTreeHelper: UrlTreeHelper,
    ) {}

    public async ngOnInit() {
        let appSettings: AppSettings = await this.sharedDataService.GetAppSettings();
        this._homeService.setAdminRights();
        this.selectedMode = appSettings.intMode;
        this.selectedYear = appSettings.intYear;
        this._homeService.hasAdminRigths.subscribe(result => {
            if (result) {
                this.hasAdminRights = result;
                this.seletedStatus = 1;
            } else {
                this.hasAdminRights = false;
            }
        });
        this._route.params.subscribe((params => {

            if (this.selectedMode == 1) {
                this.stateNum = +params['stateNum'];
                if (!this.sharedDataService.selectedState) {
                    this.sharedDataService.setSelectedState(this.stateNum);
                }


                this._utilService.GetDrpDownListOptions("StateNum", '').subscribe(objResult => {
                    if (objResult) {
                        let arrStates: Array<number> = [];
                        if (objResult) {
                            objResult.forEach(item => {
                                arrStates.push(item.intValue);
                            });
                            this.sharedDataService.setListofValidStates(arrStates);
                        }
                        let ddoSelectedState = objResult.find(x => x.intValue === this.stateNum);
                        if (ddoSelectedState) {
                            this.sharedDataService.setDDOSelectedState(ddoSelectedState);
                            this.sharedDataService.subjectSelectedState.next(ddoSelectedState);
                        }
                    }
                });
            } else {
                this.arrPSU = [];
               
                this._utilService.GetDrpDownListOptions("VR_PSU", CustomNvpFilter.ByPermissions).subscribe(result => {
                    if (this.hasAdminRights)  this.arrPSU.push(this.allOption);
                    result.forEach(item => {
                        this.arrPSU.push(item);
                    });
                  //  this.arrPSU =  result;
                   //
                });
                }
            }).bind(this));
   

        this._utilService.GetDrpDownListOptions(LookupTable.StateNum, '').subscribe(result => {
            this.arrStates = result;
        });

       

        this._vinrequestService.getVinStatus().subscribe(result => {
            this.statusList = result;
        })

        if (this.selectedMode == 4) {
            let tmpSelectedState = this.sharedDataService.getSelectedState();
            if (tmpSelectedState > 0) {
                this.seletedState = tmpSelectedState;
            } else {
                this.seletedState = -1;
            }
        } else {
            this.seletedState = this.sharedDataService.getSelectedState();
        }

        if (!this.hasAdminRights && this.selectedMode == 4 && this.seletedState == -1) {

        } else {
            this.searchVinRequest();
        }
       
        this._modalService.setEditCheckMessage('');
    }

    pageBuilder() {
        this.hasNoRows = this.vinRequestSearchResult.length == 0;
        this.pageNumberList = [];
        this.totalPages = parseInt((this.vinRequestSearchResult.length / this.pageSize).toFixed(0));
        for (let pageNum = 1; pageNum <= this.totalPages; pageNum++) {
            this.pageNumberList.push(pageNum)
        }
        this.currentPage = 1;
    }

    navigateVehicleTab(accid, vehicleid, tabToGo, stateNum) {
        if (this.selectedMode == DBMode.CRSS) this.stateNum = stateNum;
        this._router.navigate([this.stateNum, 'case', accid, 'vehicle', vehicleid, 'vehicle', tabToGo]);
    }

    pageSetBuilder(pageNum: number = 1) {
        /* this.tmpResult = [];
         this.vinRequestSearchResult.forEach(item => {
             this.tmpResult.push(item);
         })*/
        let starting = 0
        let ending = this.pageSize;
        if (pageNum > 1) {
            starting = (pageNum * this.pageSize) - 2;
            ending = (pageNum * this.pageSize);
        }
        let innerResult = this.vinRequestSearchResult.slice(starting, ending);

        this.currentPage = pageNum;
        this.vinCurrentRequests = innerResult;
    }

    openVinRequestInfo(accid: number, vehNumber: number, trailerNumber: number, caseNumber: number) {
        this._modalService_CannedPopups.vinRequest(accid, vehNumber, trailerNumber, caseNumber, true).toPromise();
    }

    fixVin(accid: number, vehNumber: number, trailerNumber: number, caseNumber: number) {
        this._modalService_CannedPopups.vinRequest(accid, vehNumber, trailerNumber, caseNumber, false).toPromise();
 
    }
    searchVinRequest() {

        let caseNum = -1;

        if (!isNaN(this.caseNumber) && this.caseNumber != null && this.caseNumber > 0) {
            caseNum = this.caseNumber;
        }
        if (!this.hasAdminRights && this.seletedState == -1 && this.selectedMode == DBMode.CRSS) {
            this._modalService.dialog('Please select PSU', 'Message', 'M').toPromise();
            return;
        }
        if (this.createdBy != null && this.createdBy.trim() == '') this.createdBy = null;
        this._vinrequestService.searchVinRequest(caseNum, this.seletedStatus, this.seletedState, this.createdBy).subscribe(result => {
            this.vinRequestSearchResult = result;
            this.pageBuilder();
            this.pageSetBuilder();
        })
    }

    gotoVinDecodePage(seqNum, vehicleid, accid, fixedVin, stateNum) {
        let urlFragment = 'vehicle';
        if (seqNum > 0) {
            urlFragment = 'trailerVIN' + (seqNum - 1);
        }
        this.sharedDataService.setVinNumber(fixedVin);
        if (this.selectedMode == DBMode.CRSS) this.stateNum = stateNum;
       this._router.navigate([this.stateNum, 'case', accid, 'vehicle', vehicleid, 'vehicle', 'vehicleVINDecode'], { fragment: urlFragment });
      
    }

    onChange(status: number) {
        this.seletedState = status;
    }
}
