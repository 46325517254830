<ng-container #divContainer *ngIf="inputName || label">
    <!-- this change was because this div was taking space in so many places-->
    <label class="{{printOnly ? 'print-label' : 'col-form-label' }}" *ngIf="link != null || link; else justLabel">
        <span class="labelLink" (click)="onPDFOpen()">
            {{label}}
        </span>
    </label>

    <ng-template #justLabel>
        <label class="col-form-label {{isBold ? 'fw-bolder': ''}}" *ngIf="!printOnly">
            {{label}}
        </label>
        <label class="print-label" *ngIf="printOnly">
            {{label}}
        </label>
    </ng-template>
</ng-container>


