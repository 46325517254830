<div class="row">
    <div class="col-xl">
        <div class="card border-dark">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="caseNumber" class="col-sm-1 col-form-label" *ngIf="selectedMode == 1 || selectedMode == 64">Case Number:</label>
                                    <label for="caseNumber" class="col-sm-1 col-form-label" *ngIf="selectedMode == 4">PAR ID:</label>
                                    <div class="col-sm-2" *ngIf="selectedMode == 1 || selectedMode == 64">
                                        <input type="text" [(ngModel)]="caseNumber" class="form-control" id="caseNumber" placeholder="Case Number">
                                    </div>
                                    <div class="col-sm-2" *ngIf="selectedMode == 4">
                                        <input type="text" [(ngModel)]="caseNumber" class="form-control" id="caseNumber" placeholder="PAR ID">
                                    </div>
                                    <label for="createdBy" class="col-sm-1 col-form-label">Created By:</label>
                                    <div class="col-sm-2">
                                        <input type="text" [(ngModel)]="createdBy" class="form-control" id="createdBy" placeholder="Created By">
                                    </div>
                                    <label for="status" class="col-sm-1 col-form-label">Status:</label>
                                    <div class="col-sm-2">
                                        <select id="stateId" class="form-control" [(ngModel)]="seletedStatus">
                                            <option value="-1">All</option>
                                            <option *ngFor="let status of statusList" [ngValue]="status.Id">
                                                {{status.Def}}
                                            </option>
                                        </select>
                                    </div>
                                    <ng-container *ngIf="hasAdminRights && selectedMode == 1 || selectedMode == 64">
                                        <label for="stateId" class="col-sm-1 col-form-label">State:</label>
                                        <div class="col-sm-2" *ngIf="selectedMode == 1 || selectedMode == 64">
                                            <select id="stateId" class="form-control" [(ngModel)]="seletedState">
                                                <option value="-1">All</option>
                                                <option *ngFor="let state of arrStates" [ngValue]="state.intValue">
                                                    {{state.strText}}
                                                </option>
                                            </select>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="form-group row" *ngIf="selectedMode == 4">
                                        <label for="stateId" class="col-sm-1 col-form-label">PSU:</label>
                                        <div class="col-sm-7">
                                            <select id="stateId" class="form-control" name="stateId" [(ngModel)]="seletedState">
                                                <option *ngFor="let psu of arrPSU"  [value]="psu.intValue">
                                                    {{ psu?.intValue != -1 ? psu?.intValue +' - ': '' }} {{ psu?.strText }}
                                                </option>
                                            </select>
                                        </div>
                                            <div class="col-sm-2">
                                                &nbsp;
                                            </div>
                                            <div class="col-sm-1">
                                                <button type="button" class="btn btn-primary btn-block" (click)="searchVinRequest()">Search</button>
                                            </div>
                                            <div class="col-sm-1">
                                                <!--<button type="button" class="btn btn-primary btn-block">Export</button>-->
                                            </div>
                                </div>
                                    <div class="form-group row" *ngIf="selectedMode == 1 || selectedMode == 64">
                                        <div class="col-sm-10">
                                            &nbsp;
                                        </div>
                                        <div class="col-sm-1">
                                            <button type="button" class="btn btn-primary btn-block" (click)="searchVinRequest()">Search</button>
                                        </div>
                                        <div class="col-sm-1">
                                            <!--<button type="button" class="btn btn-primary btn-block">Export</button>-->
                                        </div>

                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

                <div class="row" *ngIf="hasNoRows">
                    <div class="col-12">
                        <div class="card m-2">
                            <div class="card-body">
                                <p>No Vin request are there.</p>
                            </div>
                    </div>
                    </div>
                </div>
                <ng-container *ngIf="!hasNoRows">
                    <div class="row">
                        <div class="col-12">
                            <nav aria-label="Page navigation">
                                <ul class="pagination float-right mr-4">
                                    <li class="page-item">
                                        <button type="button" class="btn btn-link page-link" [disabled]="currentPage == 1" (click)="pageSetBuilder(currentPage-1)"><<</button>
                                    </li>
                                    <li *ngFor="let pageNumber of pageNumberList" class="page-item {{pageNumber == currentPage ? 'active':''}}">
                                        <a class="page-link " (click)="pageSetBuilder(pageNumber)" href="javascript:void(0)">{{pageNumber}}</a>
                                    </li>
                                    <li class="page-item">
                                        <button type="button" (click)="pageSetBuilder(currentPage+1)" class="btn btn-link page-link" [disabled]="currentPage == pageNumberList.length">>></button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="row" *ngFor="let item of vinCurrentRequests, index as index">
                        <div class="col-12">
                            <div class="card m-2">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            &nbsp;
                                        </div>
                                        <div class="col-sm-2">
                                            &nbsp;
                                        </div>
                                        <div class="col-sm-4">
                                            <button type="button" (click)="fixVin(item.AccID,item.VNumber,item.SeqNum,item.casenum)" class="btn btn-link" *ngIf="hasAdminRights">Fix VIN</button>
                                            <button type="button" (click)="openVinRequestInfo(item.AccID,item.VNumber,item.SeqNum,item.casenum)" class="btn btn-link">Info</button>
                                            <button type="button" *ngIf="item.SeqNum==0" (click)="navigateVehicleTab(item.AccID,item.VNumber,'vin', item.statenum)" class="btn btn-link">Go to Vehicle</button>
                                            <button type="button" *ngIf="item.SeqNum!=0" (click)="navigateVehicleTab(item.AccID,item.VNumber,'vehicleTrailer', item.statenum)" class="btn btn-link">Go to Trailer</button>
                                            <button type="button" [disabled]="item.Status!='Fixed'" (click)="gotoVinDecodePage(item.SeqNum, item.VNumber, item.AccID, item.FixedVIN, item.statenum)" class="btn btn-link">Decode & Accept VIN</button>
                                        </div>

                                    </div>
                                    <div class="row mt-2">
                                        <ng-container *ngIf="selectedMode == 1 || selectedMode == 64">
                                            <div class="col-sm-2">
                                                <strong>State: {{item.StateInitial}}</strong>
                                            </div>
                                            <div class="col-sm-3">
                                                <strong>Case #: {{item.casenum | number : '4.0-0' | replaceTextPipe : ',' : ''}}</strong>
                                            </div>
                                            <div class="col-sm-2">
                                                <strong>Vehicle #: {{item.VNumber}}</strong>
                                            </div>
                                            <div class="col-sm-3">
                                                <ng-container *ngIf="item.SeqNum!=0">
                                                    <strong> Trailer #: {{item.SeqNum}}</strong>
                                                </ng-container>
                                            </div>
                                            <div class="col-sm-2">
                                                <strong>Status:  {{item.Status}}</strong>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="selectedMode == 4">
                                            <div class="col-sm-5">
                                                <strong>PSU: {{item.statenum | valueToDescription: 'VR_PSU' | async}}</strong>
                                            </div>
                                            <div class="col-sm-2">
                                                <strong>PAR ID: {{item.PARID | number: '8.0-0' | replaceTextPipe : ',' : '' | maxLengthPipe : 12 : '' | slice: 4:12}}</strong>
                                            </div>
                                            <div class="col-sm-1">
                                                <strong>Vehicle #: {{item.VNumber}}</strong>
                                            </div>
                                            <div class="col-sm-2">
                                                <ng-container *ngIf="item.SeqNum!=0">
                                                    <strong> Trailer #: {{item.SeqNum}}</strong>
                                                </ng-container>
                                            </div>
                                            <div class="col-sm-2">
                                                <strong>Status:  {{item.Status}}</strong>
                                            </div>
                                        </ng-container>
                                          
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-sm-6">
                                            <table class="table">
                                                <thead class="thead-light">
                                                    <tr>
                                                        <th colspan="2" scope="col" class="text-center">Original Submission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="row">VIN:</td>
                                                        <td scope="row">{{item.NotFixedVIN}}</td>
                                                    </tr>
                                                    <tr *ngIf="item.Status!='Done'">
                                                        <td scope="row">vPIC:</td>
                                                        <td scope="row" *ngIf="item.SeqNum==0">{{item.VPICMakeDEF + "/" + item.VPICModelDEF + "/" + item.VPICClassDEF + "/" }} {{ (item.ModelYr == null || item.ModelYr == -1) ? 'blank' : item.ModelYr+ "/" }} {{ item.vPICGVWRValue}}</td>
                                                        <td scope="row" *ngIf="item.SeqNum!=0">{{item.TVINMakeName + "/" + item.TVINModelName + "/" + item.TVINBodyName + "/"}} {{ (item.TVINYear == null || item.TVINYear == -1) ? 'blank' : item.TVINYear }}</td>
                                                    </tr>
                                                    <tr *ngIf="item.Status!='Done'">
                                                        <td scope="row" >NCSA:</td>
                                                        <td scope="row" *ngIf="item.SeqNum!=0">&nbsp;</td>
                                                        <td scope="row" *ngIf="item.SeqNum==0">{{item.MakeDef + "/" + item.ModelDef + "/" + item.BodyDef + "/"}} {{ (item.ModelYr == null || item.ModelYr == -1) ? 'blank' : item.ModelYr }}</td>
                                                    </tr>
                                                    <tr *ngIf="item.Status!='Done'">
                                                        <td scope="row">Error Code:</td>
                                                        <td scope="row">{{item.ErrorCode}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Submitted On:</td>
                                                        <td scope="row">{{item.CreatedDate | date:'M/d/yyyy, h:mm:ss a'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Submitted By:</td>
                                                        <td scope="row">{{item.CreatedUserFullName}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-sm-6">
                                            <table class="table">
                                                <thead class="thead-light">
                                                    <tr>
                                                        <th colspan="2" scope="col" class="text-center">Result</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="row">Fixed VIN:</td>
                                                        <td scope="row" *ngIf="item.Status=='Active'">&nbsp;</td>
                                                        <td scope="row" *ngIf="item.Status=='Done' || item.Status=='Fixed'">{{item.FixedVIN}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">vPIC:</td>
                                                        <td scope="row" *ngIf="item.Status!='Done'">&nbsp;</td>
                                                        <td scope="row" *ngIf="item.SeqNum==0  && item.Status=='Done'">{{item.VPICMakeDEF + "/" + item.VPICModelDEF + "/" + item.VPICClassDEF + "/" }} {{ (item.ModelYr == null || item.ModelYr == -1) ? 'blank' : item.ModelYr }}</td>
                                                        <td scope="row" *ngIf="item.SeqNum!=0 && item.Status=='Done'">{{item.TVINMakeName + "/" + item.TVINModelName + "/" + item.TVINBodyName + "/"}} {{ (item.TVINYear == null || item.TVINYear == -1) ? 'blank' : item.TVINYear }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">NCSA:</td>
                                                        <td scope="row" *ngIf="item.Status!='Done'">&nbsp;</td>
                                                        <td scope="row" *ngIf="item.SeqNum!=0 && item.Status=='Done'">&nbsp;</td>
                                                        <td scope="row" *ngIf="item.SeqNum==0 && item.Status=='Done'">{{item.MakeDef + "/" + item.ModelDef + "/" + item.BodyDef + "/"}} {{ (item.ModelYr == null || item.ModelYr == -1) ? 'blank' : item.ModelYr }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Error Code:</td>
                                                        <td scope="row" *ngIf="item.Status!='Done'">&nbsp;</td>
                                                        <td scope="row" *ngIf="item.Status=='Done'">{{item.ErrorCode}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Fixed On:</td>
                                                        <td scope="row" *ngIf="item.Status=='Active'">&nbsp;</td>
                                                        <td scope="row" *ngIf="item.Status=='Done' || item.Status=='Fixed'">{{item.FixedDate | date:'M/d/yyyy, h:mm:ss a'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Fixed By:</td>
                                                        <td scope="row" *ngIf="item.Status=='Active'">&nbsp;</td>
                                                        <td scope="row" *ngIf="item.Status=='Done' || item.Status=='Fixed'">{{item.FixedUserFullName}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-sm-12 border-top my-2 ml-2 p-3">
                                            <strong>Last Comment: {{item.LastComment}}</strong>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </ng-container>
                        </div>
                    </div>
                </div>
