import { Injectable } from '@angular/core';
import { GenericService } from 'src/app/services/generic.service';

@Injectable({
    providedIn: 'root'
})
export class CrasheventServices {

    constructor(private _baseService: GenericService<any>) {
    }

    public SeqeventList() {
        this._baseService.actionURL = "api/util/GetSeqEvents";
        return this._baseService.getData().toPromise();
    }

    public ObjContactEventList() {
        this._baseService.actionURL = "api/util/GetObjContactEvent";
        return this._baseService.getData().toPromise();
    }

    public VehicleClassList() {
        this._baseService.actionURL = "api/util/GetVehicleClass";
        return this._baseService.getData().toPromise();
    }
}
