import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, AfterViewChecked, AfterContentInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Acc } from 'src/app/models/acc';
import { AppSettings, SharedDataService } from 'src/app/services/shared-data.service';
import { DBMode } from 'src/app/models/enums/app.enums';


@Component({
    selector: 'app-printcase',
    templateUrl: './printcase.component.html',
    styleUrls: ['./printcase.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PrintcaseComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentInit {
    public title: string;
    formName: string = '';
    clearFieldData: boolean = false;
    printCase: boolean = true;
    printNonOccupant: boolean = false;
    printVehicle: boolean = false;
    printSFRVehicle: boolean = false;
    printStrikeVehicle: boolean = false;
    printCrash: boolean = false;
    printDriver: boolean = false;
    printPrecrash: boolean = false;
    printPerson: boolean = false;
    printAvoidanceEquipment: boolean = false;
    vehPersonNumber = 0;
    acc: Acc
    pOrVNumber = 0;
    numberMvOccupant = 0;
    appYear = 2020
    intMode: number;

    parId: string = "";
    parNumber: string = "";
    zoneCenter: number = 0;
    strFullApplicationName: string = "";

    //public arrVehTrailing: Array<number>;

    @ViewChild('contentBody') contentBody: HTMLElement;
    SSVNumber: number;
    constructor(private _route: ActivatedRoute) {
        this.acc = this._route.parent.snapshot.data['case'];
    }

    async ngOnInit() {
        console.log(this._route)
        this._route.params.subscribe((async params => {
            this.formName = await params['type'];            
            if (params['id']) this.pOrVNumber = await params['id'];
            if (params['personId']) this.vehPersonNumber = await params['personId'];
            if (this.formName == 'blank') this.clearFieldData = true;
            if (this.formName == 'case') this.printCase = true;
            if (this.formName != 'case' && this.formName != 'blank') {
                console.log(this.formName);
                this.printingType();
            }
            this.acc.Veh.forEach(item => {
                this.numberMvOccupant += item.Occupants.length
                this.SSVNumber = item.Veh_SS.SSVNumber;
                
            })

            //this.arrVehTrailing = [1, 2, 3];
        }).bind(this))       
        this.intMode = this.acc.Mode;
        if (this.intMode == DBMode.CRSS) {
            this.zoneCenter = this.acc.EarlyNotify[0].ZoneCenter;
            this.parId = this.acc.EarlyNotify[0].PARID.toString().slice(4);
            this.parNumber = this.acc.EarlyNotify[0].PARNumber;
            this.strFullApplicationName = "Crash Report Sampling System";
        }
        else if (this.intMode == DBMode.FARS)
            this.strFullApplicationName = "Fatality Analysis Reporting System";
        else if (this.intMode == DBMode.MTSS)
            this.strFullApplicationName = "Medium Truck Study";
        else if (this.intMode == DBMode.PPSS)
            this.strFullApplicationName = "Pedestrians-Pedalcyclists Speacial Study";
        else if (this.intMode == DBMode.MOSS)
            this.strFullApplicationName = "Move Over Laws";
    }

    ngAfterViewInit() {
        //window.print()
    }

    ngAfterViewChecked() {
        // window.print()
    }

    ngAfterContentInit() {
        // window.print()
    }

    printingType() {
        switch (this.formName) {
            //switch (searchStr2) {
            //Saving Crash tabs
            case "crash":            
            case "events":
            case "trafficway":
            case "crashnotes":           
                this.printCrash = true;
                this.printCase = false;
                break;
            case "nonmotorist":
                this.printCrash = true;
                this.printCase = false;
                break;
            case "nonOccupant":            
                this.printNonOccupant = true;
                this.printCase = false;
                break;
            case "nonOther":
                this.printNonOccupant = true;
                this.printCase = false;
                break;
            case "bicyclist":
                this.printNonOccupant= true;
                this.printCase = false;
                break;
            case "pedestrian":
                this.printNonOccupant = true;
                this.printCase = false;
                break;
            case "nonMotoristActions":
                this.printNonOccupant = true;
                this.printCase = false;
                break;
            case "alcohol":
                this.printNonOccupant = true;
                this.printCase = false;
                break;
            case "drug":
                this.printNonOccupant = true;
                this.printCase = false;
                break;
            case "injury":
                this.printNonOccupant = true;
                this.printCase = false;
                break;
            case "nonOccNotes":
                this.printNonOccupant = true;
                this.printCase = false;
                break;
            case "vehicle":
            case "damagedAreas":
            case "vin":
            case "vehicleDetails":
            case "vehicleOther":
            case "vehnonmotorist":
            case "vehicleTrailer":
            case "rollover":
            case "view":
            case "vehicleNotes":            
            case "vehicleVINDecode":            
                this.printVehicle = true;
                this.printCase = false;
                break;
            case "driver":
            case "license":
            case "counters":
            case "driverNotes":
                this.printDriver = true;
                this.printCase = false;
                break;
            case "precrash":
            case "roadway":
            case "precrashOther":
                this.printPrecrash = true;
                this.printCase = false;
                break;
            case "person":
            case "safetyDevices":
            case "personAlcohol":
            case "personDrug":           
            case "personNotes":
                this.printPerson = true;
                this.printCase = false;
                break;
            case "avoidanceEquipment":
                this.printAvoidanceEquipment = true;
                this.printCase = false;
                break;
            case "sfrvehicle":
                this.printSFRVehicle = true;
                this.printCase = false;
            case "strikingvehicle":
                this.printStrikeVehicle = true;
                this.printCase = false;
            default:
                this.printCase = true;
        }
    }

    printDiv() {

        // window.print();
        let contentBody = document.getElementById('printDiv').innerHTML;
        let printWindow = window.open('', '', 'height=500, width=500');
        printWindow.document.write(contentBody)
        printWindow.document.close();
        printWindow.print();
        printWindow.close();

    }

}
