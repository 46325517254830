import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, ApplicationModule } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, retryWhen, concatMap, delay } from 'rxjs/operators';
import { LoadingFormService } from '../services/loading-form.service';
import { finalize } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { ModalService } from '../services/modal.service';
import { UtilService } from '../services/util.service';

enum HttpStatus {
    None = 0,
    OK = 200,
    Redirect = 302,
    Unauthorized = 401,
    Forbidden = 403
}

//A singleton instance of this class is created, no need to declare internal state variables as static.
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    activeRequests: number = 0;

    /**
     * URLs for which the loading screen should not be enabled
     */
    skippUrls = [
        '/ClientLog',         //This request is made in the background
        '/GetAutofills',      //This request is made in the background, user does not wait on it to log into state or look at state home page
        '/GetColumnDefaults', //This request is made in the background, user does not wait on it to log into state or look at state home page
        '/GetCalenderReminder',
        '/GetFarsMetricsDetails',
        //'/GetFormDrpDownListOptions',
        '/GetCaseSummaryData',
        '/GetQcBenchmarkComparison',
        //'/GetCaseBlanks',
        //'/GetRulesErrorList',
        '/GetStateAllElementOtherSpecifyValues',
        '/GetCaseStatisticDetails',
        '/GetLongLatValidatorInfo',
        '/GetStateEDTStyle',
        //'/GetDropDownOptions',
        '/GetAttributeMatchLevelsByForm',
        '/SaveListElementSpecify',
        '/RunChecksGetStats', //This request is made in the background, user does not wait on it
        '/SaveUserLastActive', //This request is made in the background, user does not wait on it
        '/DownloadAllByAccId',
        //'/InsertFileToObjectStore',
        //'/CaseFile_Insert',
        '/GetCaseFileLock'
    ];

    constructor(private _loadingService: LoadingFormService,
        private modalService: ModalService,
        private utilService: UtilService) {
    }

    /**
     * Clones the HttpRequest and adds a .AspNetCore.Cookies header to it
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let arrCookies: string[] = document.cookie.split('; ');
        let strCookie: string = arrCookies.find(x => x.startsWith('.AspNetCore.Cookies')); //Expired cookies and HTTP-only cookies are not visible in document.cookie
        let objHeaders = new HttpHeaders();

        if (!strCookie && AppComponent.blnLoggedIn) { //This is just a visually evident sanity check. Http Request error handler below already redirects to "/login" URL.
            if (window.prompt('Warning: User session not found. Go to Login?')) {
                window.location.href = '/login';
                return throwError(new Error('ApiInterceptor.intercept(): Before Dispatch: Request not authenticated. Redirecting to login. Flow of control stopped.'));
            }
        }

        //.AspNetCore.Cookies is already included in async request header by default //if (strCookie) objHeaders.append('Cookie', strCookie); 

        const objRequestClone = request.clone({ //HttpRequest is immutable, must clone to add headers, however, NOTICE: if debugging, will not see objRequestClone.headers being modified
            headers: objHeaders
        });

        let blnDisplayLoadingScreen = true;

        for (const skippUrl of this.skippUrls) {
            if (new RegExp(skippUrl).test(request.url)) {
                blnDisplayLoadingScreen = false;
                break;
            }
        }

        if (blnDisplayLoadingScreen) {
            if (this.activeRequests === 0) {
                this._loadingService.startLoading();
                //console.log("loading service START");
            }

            this.activeRequests++;

            return next.handle(objRequestClone) //Continue to next interceptor/handler
                .pipe(
                    finalize((() => {
                        if (blnDisplayLoadingScreen) { //Local variable accessed in callback via closure
                            this.activeRequests--;

                            if (this.activeRequests === 0) {
                                this._loadingService.stopLoading();
                                //console.log("loading service STOP");
                            }
                        }
                    }).bind(this)),
                    retryWhen(errors => errors.pipe( //retryWhen resubscribes to HTTP request (and thus dispatches it again) if the passed in observable does not throw an error.
                        concatMap((errorname, count) => {
                            if (errorname.error.includes) { //A blocked request that was blocked by HTTPS consistency or CORS policy will not be a true HttpErrorResponse object (ex. blocked redirect to MAX.GOV when ASP.NET Core seesion cookie is expired)
                                if (errorname.error.includes('arriving too slowly') && count == 0) {
                                    this.utilService.continuesSlownessCounter = this.utilService.continuesSlownessCounter + 1;
                                    this.modalService.setMessage('Application has detected a slow or intermittent Internet connection. The slowness may be on the side of your Internet provider or on the side of DOT\'s network.', 'W');
                                    return of(errorname.status);
                                }
                                else {
                                    if (errorname.error.includes('arriving too slowly') && count == 1) {
                                        this.modalService.setMessage('Application has detected a slow or intermittent Internet connection. The slowness may be on the side of your Internet provider or on the side of DOT\'s network.', 'W');
                                        this.utilService.sendSlowInternetStatics();
                                    }
                                }
                            }

                            return throwError(errorname);
                        }),
                        delay(1000)
                    )),
                    //retry(1), //DO NOT BLINDLY RETRY
                    catchError((error: HttpErrorResponse) => {
                        console.log('error', error);

                        if (error.status == HttpStatus.None || //CAS challenge through reverse proxy manifests as canceled request with status 0
                            error.status == HttpStatus.Redirect || //CAS challenge manifests as 302 redirect to MAX.GOV
                            error.status == HttpStatus.Unauthorized ||
                            error.status == HttpStatus.Forbidden ||
                            !error.error.includes //Either (1) bad JSON or (2) A blocked request that was blocked by HTTPS consistency or CORS policy will not be a true HttpErrorResponse object (ex. blocked redirect to MAX.GOV when ASP.NET Core seesion cookie is expired)
                        ) {
                            console.log('ApiInterceptor.intercept(): After Dispatch Attempt: Session missing or expired. Redirecting to login.');
                            window.location.href = '/login';
                            return throwError(new Error('ApiInterceptor.intercept(): After Dispatch Attempt: Request not authenticated. Redirecting to login. Flow of control stopped.'));
                        }

                        let blnShowAlert: boolean = true;
                        let errorMessage: string = 'Unknown error';

                        if (error.error) {
                            if (typeof (error.error) == 'string') {
                                errorMessage = error.error;

                                if (error.error.charAt(0) == '<') { //<!DOCTYPE html>
                                    if (error.error.includes('already locked')) {
                                        //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}\nisCaseLocked: true`;
                                        //blnShowAlert = false; //Do nothing in interceptor, let original caller react to error 
                                        throwError(error);
                                        blnShowAlert = false;
                                    }

                                    else if (error.error.includes('already entered in the system')) { // Bypass opening the 2nd window with error information if EN State Case Number IS NOT Unique;
                                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}\nIsStateCaseNumberUnique: true`;
                                        blnShowAlert = false; //Do nothing in interceptor, let original caller react to error
                                    }
                                    else {
                                        let wndErrorReadout = window.open('', 'ErrorReadout');
                                        if (wndErrorReadout) { //Unless explicitly permitted, pop-up blocker will suppress new window as this is an async callback outside the context of a user-initiated event
                                            wndErrorReadout.document.write(error.error);
                                            blnShowAlert = false;
                                        }
                                    }
                                }
                                else if (error.error.includes('already locked') || error.error.includes('There is no case with Case Number')) {
                                    blnShowAlert = false;
                                }
                                else if (error.error.includes('PAR ID')) {
                                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}\n isParNotFound: true`;
                                    blnShowAlert = false; //Do nothing in interceptor, let original caller react to error 
                                }
                                else if (error.error.includes('cases')) {
                                    errorMessage = error.error.substring(error.error.indexOf(':') + 1, error.error.length);
                                    blnShowAlert = false; //Do nothing in interceptor, let original caller react to error 
                                }
                                else if (error.error.includes('There is no PAR with PARID')) {
                                    errorMessage = error.error.substring(error.error.indexOf('There is no PAR with PARID'), error.error.indexOf('The UPDATE statement'));
                                    blnShowAlert = false; //Do nothing in interceptor, let original caller react to error 
                                }
                                else {
                                    alert(errorMessage);
                                    blnShowAlert = false;
                                }
                            }
                        }

                        if (blnShowAlert) {
                            let strAlert: string;
                            if (error.error instanceof ErrorEvent) { // client-side error
                                strAlert = `Error: ${error.error.message}`;
                            }
                            else if (error.message) { // server-side error
                                strAlert = `Error Code: ${error.status}\nMessage: ${error.message}`;
                            }

                            //Suppression of startup error message from components that try to initialize without checking if user authentication has had a chance to occur. Angular misinterprets the 401 error (possibly because ContentType header of 401 response does not match request)
                            if (!((error.status == HttpStatus.OK && error.message.startsWith('Http failure during parsing for')) ||
                                (error.status == HttpStatus.None && error.message.startsWith('Http failure response for'))))
                                window.alert(strAlert);
                        }

                        return throwError(errorMessage); //Rethrow just the string error message
                    })
                );
        }
        else {
            return next.handle(request);
        }
    }
}
