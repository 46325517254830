<!-- this section is only shows if this is normal dialog-->
<div *ngIf="module!='W'">
    <div class="modal-header" *ngIf="boxType !='M'">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{body}}</p>
    </div>
    <div class="modal-footer">
        <button ngbAutofocus type="button" *ngIf="boxType !='M'" id="DialogCancel"
                class="btn btn-light border-secondary"
                (click)="activeModal.close(false)">
            Cancel
        </button>
        <button type="button" id="dialogOK"
                class="btn btn-light border-secondary"
                (click)="activeModal.close(true)">
            OK
        </button>
    </div>
</div>
<!-- this section is only shows if this is this is used for wizard-->
<div *ngIf="module=='W'">
    <div class="modal-header">
        <h6 class="modal-title">{{title}}</h6>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-dark" role="alert" style="font-size:large;margin-top:-12px">
            {{shortDesc}}
        </div>
        <div class="alert alert-warning text-justify" role="alert" style="height:10rem;font-size:medium;margin-top:-20px" *ngIf="showHintArea">
            {{ hint }}
        </div>
        <div class="border rounded p-2 {{showHintArea ? 'content-size':'content-size-nohint'}}">
            <ng-template [ngIf]="!showFinalSelection && templateType=='image'">
                <div class="container" style="overflow: hidden; width:100%">
                    <ng-container *ngIf="showExtraStripInTop">
                        <div class="row bg-secondary">
                            <div class="col-sm-4 border-right border-white">&nbsp;</div>
                            <div class="col-sm-8">&nbsp;</div>
                        </div>
                    </ng-container>

                    <div *ngIf="showDivider" class="vhr"></div>
                    <div *ngIf="showDividerWithoutText" class="vhr-1"></div>
                    <ng-container *ngIf="showDivider">
                        <div class="row bg-secondary">
                            <div class="col-sm-4 border-right border-white text-center text-white">Motorist Failed to Yield</div>
                            <div class="col-sm-8 text-center text-white">Bicyclist Failed to Yield</div>
                        </div>
                    </ng-container>
                    <div class="row">

                        <ng-container *ngFor="let item of currentOptions; index as i">
                            <div class="text-center {{isFirst ? 'col-sm-6': columnSize>6  ? 'col-sm-3': (title.toLowerCase().includes('typical pedestrian') && (optionCount-1==i)) && getFirstId!=4 ? 'col-sm-6': 'col-sm-4'}} {{(optionCount-1==i) && isFirst ? 'last-column-margin' : item.OptionType =='button' ? 'd-flex justify-content-between align-items-center':''}} {{(optionCount-1==i) && item.OptionType =='button' ? customCss :''}} {{title.toLowerCase().includes('non-trafficway crash') ? 'col-sm-6':''}} ">
                                <ng-container *ngIf="item.OptionType !='radio'">
                                    <p *ngIf="item.OptionType !='button'" class="label-size {{item.Value=='00' ? 'disabled-mode':''}}">
                                        {{item.Value!=null && item.Value!='' && item.Value!='00' && !title.toLowerCase().includes('motorist turning') ?  (item.Value!=item.Label ? item.Value + ' ('+item.Label+')':item.Label):item.Label}} {{item.Label=='' ? '&nbsp;':''}}
                                    </p>
                                    <img *ngIf="item.OptionType =='image'" class="image-size {{(backSelected!='' && backSelected == item.Id) ? 'border-selected':''}} {{item.Value=='00' ? 'disabled-mode':''}}" src="../../assets/images/wizard/pedwizard/{{item.ImageUrl}}" (click)="onOptionSelect(item)" (mouseout)="onMouseOut($event)" (mouseover)="onMouseHover(item, $event)" />
                                </ng-container>
                                <button class="btn btn-light border-secondary {{backSelected == item.Id ? 'border-selected':''}} {{buttonSize}} {{item.Value=='disabled'? 'disabled':''}}" *ngIf="item.OptionType =='button'" disabled="{{item.Value=='disabled'? 'disabled':''}}" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)"> {{item.Value!=null ? item.Value + ' '+ item.Label : item.Label}}</button>

                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
            <ng-template #radioTemplate [ngIf]="!showFinalSelection && templateType=='radio'">
                <div class="border-0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let item of currentOptions; index as i">
                            <p *ngIf="(item.Condition == 'group_a' || item.Condition =='group_b')" class="{{item.Condition =='group_b'? 'group-margin-groupb':'group-margin-groupa'}}">
                                {{item.SectionTitle}}
                            </p>
                            <input *ngIf="item.OptionType =='radio'" checked="{{backSelected == item.Id ? 'checked':''}}" type="radio" (mouseover)="onMouseHover(item)" name="option" (click)="onOptionSelect(item)" /><a style="cursor: pointer;" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)"> {{item.Value}} {{item.Label}}</a>
                        </li>
                    </ul>
                </div>
            </ng-template>
            <ng-template #directional [ngIf]="!showFinalSelection && templateType=='direction'">
                <pre *ngIf="currentOptions.length==3">
                    Hover on the buttons to see the complete description in the box above
                </pre>
                <div class="text-center ">

                    <ng-container *ngFor="let item of currentOptions; index as i">
                        <img *ngIf="i==0 && item.ImageUrl!=null" class="card-img-bottom {{showHintArea ? currentOptions.length==3? 'image-size-withoutTopMarign': 'image-size-withHint' : 'image-size-withoutHint'}}" style="" src="../../assets/images/wizard/pedwizard/{{item.ImageUrl}}" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" />
                        <button *ngIf="i<(optionCount-1) && item.DirectionPosition =='bottom'" class="btn btn-light border-secondary btn-lg {{backSelected == item.Id ? 'border-selected':''}}" style="margin-left:415px;display:flex;margin-top:-32px;position:absolute" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}}  {{item.Label}}"> {{item.Value}}  {{item.Label}} </button>
                        <button *ngIf="i<(optionCount-1) && item.DirectionPosition =='left'" class="btn btn-light border-secondary btn-lg {{backSelected == item.Id ? 'border-selected':''}}" style="margin-left:217px;display:flex;margin-top:-209px;position:absolute;" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}}  {{item.Label}}"> {{item.Value}}  {{item.Label}} </button>
                        <button *ngIf="i<(optionCount-1) && item.DirectionPosition =='right'" class="btn btn-light border-secondary btn-lg {{backSelected == item.Id ? 'border-selected':''}}" style="margin-left:612px;display:flex;margin-top:-209px;position:absolute;" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}}  {{item.Label}}"> {{item.Value}}  {{item.Label}} </button>
                        <button *ngIf="i<(optionCount-1) && item.DirectionPosition =='top'" class="btn btn-light border-secondary btn-lg {{backSelected == item.Id ? 'border-selected':''}}" style="margin-left:415px;display:flex;margin-top:-346px;position:absolute;" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}}  {{item.Label}}"> {{item.Value}}  {{item.Label}} </button>
                        <button *ngIf="i<(optionCount-1) && item.DirectionPosition =='out'" class="btn btn-light border-secondary btn-lg w-50 text-center {{backSelected == item.Id ? 'border-selected':''}}" style="margin-top:25px;margin-bottom:20px;margin-left:7%;" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}}  {{item.Label}}">{{item.Value}}  {{item.Label}} </button>
                        <button *ngIf="(optionCount-1)==i" class="btn btn-light border-secondary btn-lg text-center w-50 {{backSelected == item.Id ? 'border-selected':''}}" type="button" style="margin-top:25px;margin-bottom:20px;margin-left:7%;" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}}  {{item.Label}}"> {{item.Value}}  {{item.Label}} </button>
                    </ng-container>
                </div>
            </ng-template>

            <ng-template [ngIf]="!showFinalSelection && templateType=='imageButton'">
                <div class="row text-center">
                    <ng-container *ngFor="let item of currentOptions; index as i">
                        <div *ngIf="item.OptionType =='imageButton'" class="col-sm-6 mt-1 text-center">
                            <img class="{{backSelected == item.Id ? 'border-selected':''}}" src="../../assets/images/wizard/pedwizard/{{item.ImageUrl}}" (mouseout)="onMouseOut($event)" (mouseover)="onMouseHover(item, $event)" (click)="onOptionSelect(item)" style="width: 200px;margin-top:20px" /> <br />
                            <button class="btn btn-light border-secondary btn-lg clearfix m-1  {{backSelected == item.Id ? 'border-selected':''}}" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Label}}" style="margin-left:80px"> {{item.Label}} </button>
                        </div>
                        <div class="col-sm-6 mt-2  d-flex justify-content-center align-items-center " *ngIf="item.OptionType =='button'">
                            <button class="btn btn-light border-secondary btn-lg m-1  {{backSelected == item.Id ? 'border-selected':''}}" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}} {{item.Label}}"> {{item.Value}} {{item.Label}}</button>
                        </div>
                    </ng-container>
                </div>
            </ng-template>

            <ng-template #radioTemplate [ngIf]="!showFinalSelection && templateType=='common'">
                <div>
                    <div class="row" *ngFor="let item of currentOptions; index as i">
                        <div class="col-sm-5" style="font-size:medium"><button class="btn btn-light border-secondary btn-lg m-1 {{item.Parent=='0'? 'disabled':''}} {{backSelected == item.Id ? 'border-selected':''}}" disabled="{{item.Parent=='0'? 'disabled':''}}" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}} {{item.Label}}"> {{item.Value}} {{item.Label}} </button></div>
                        <div class="col-sm-7" style="font-size:medium">{{item.ShortDesc}}   </div>
                        <div class="w-100"><hr></div>
                    </div>
                </div>
            </ng-template>

            <ng-container #buttonTemplate *ngIf="!showFinalSelection && templateType=='button'">
                <div>
                    <div class="row" *ngFor="let item of currentOptions; index as i">
                        <div class="col-sm-12 mt-2 text-center" *ngIf="item.OptionType =='button'">
                            <button class="btn btn-light border-secondary btn-lg m-1  {{backSelected == item.Id ? 'border-selected':''}}" type="button" (mouseover)="onMouseHover(item)" (click)="onOptionSelect(item)" value="{{item.Value}} {{item.Label}}"> {{item.Value}} {{item.Label}} </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- this section is for to show the final selections-->

            <ng-template [ngIf]="showFinalSelection">

                <ul class="list-group">

                    <li class="list-group-item d-flex justify-content-between align-items-center mb-1">
                        <span class="text-dark fw-bold"> Description</span>
                        <span class="text-dark fw-bold"> Screen Name</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let finalItem of selectedOptions; index as y">
                        <ng-container *ngIf="finalItem.Label==''">
                            <div style="text-transform:capitalize">{{getScreenName(y+1).replace('CRASH','')}}</div>
                        </ng-container>
                        <ng-container *ngIf="finalItem.Label!=''">
                            {{ (finalItem.Value == finalItem.Label) ? finalItem.Value :(finalItem.Value==null) ? finalItem.Label : finalItem.Value+' '+ finalItem.Label   }}
                        </ng-container>

                        <span class="text-dark fw-bold"> {{getScreenName(y)}}</span>
                    </li>
                </ul>
            </ng-template>

        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col-sm">
                    <button type="button"
                            class="btn btn-light border-secondary float-left"
                            (click)="onGoBack(parentItem)" *ngIf="showBack">
                        Back
                    </button>
                </div>
                <div class="col-sm text-center">
                    <div class="row">
                        <div class="col-sm-7">
                            <button type="button"
                                    class="btn btn-primary float-left"
                                    (click)="activeModal.close(selectedOptions)" *ngIf="showFinalSelection" [disabled]="isReadOnly">
                                Submit
                            </button>
                            <button ngbAutofocus type="button"
                                    class="btn btn-light border-secondary float-right"
                                    (click)="activeModal.close(null)">
                                Cancel
                            </button>
                        </div>
                        <div class="col-sm-5">
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <button type="button"
                            class="btn btn-light border-secondary float-right"
                            (click)="onGoNext(parentItem)" *ngIf="showNext">
                        Next
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>
