<span class="pl-2">
    <button id="btnHeightCalculator_Open" class="btn btn-sm btn-light border-secondary" (click)="open(content, null)" #btnHeightCalcOpen>Height Calculator</button>
</span>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Height Calculator</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');onCancel()">
            <span aria-hidden="true"> X </span>
        </button>
    </div>

    <div class="modal-body">

        <div class="form-group">
            <p class="border-bottom border-light" *ngIf="!blnIsPedastrian">This window allows you to enter {{ strUnitType }} and returns Feet and Inches</p>
            <p class="border-bottom border-light" *ngIf="blnIsPedastrian">This window allows you to enter {{ strUnitType }} and returns in Centimeters</p>

        </div>

        <div class="form-group">
            <div *ngIf="blnRequired == true">
                <div class="alert alert-danger mt-1" role="alert">
                    Height is required
                </div>
            </div>

            <div *ngIf="blnMetersLessThen == true">
                <div class="alert alert-danger mt-1" role="alert">
                    Height can't be less then 0.61 meters.
                </div>
            </div>
            <div *ngIf="blnMetersGreaterThen == true">
                <div class="alert alert-danger mt-1" role="alert">
                    Height can't be greater then or equal 2.72 meters.
                </div>
            </div>

            <div *ngIf="blnInchesLessThen == true">
                <div class="alert alert-danger mt-1" role="alert">
                    Height can't be less then 24 inches.
                </div>
            </div>
            <div *ngIf="blnInchesGreaterThen == true">
                <div class="alert alert-danger mt-1" role="alert">
                    Height can't be greater then 107 inches.
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-9">
                    <div class="row">
                        <div class="input-group mb-3">
                            <input #inputHeight type="text" class="form-control w74" id="inputHeightCalculator" name="inputHeightCalculator"
                                   [ngModel]="intHeight" (ngModelChange)="OnHeightChange($event)"
                                   (blur)="onBlur()" (keyup.enter)="Convert(inputHeight)"
                                   [numeric] numericType="decimal" maxlength="4">
                            <div class="input-group-append">
                                <select class="form-control" id="StateOfficalCrash" name="StateOfficalCrash"
                                        [ngModel]="intUnitType" (ngModelChange)="OnUnitTypeChange($event, inputHeight)">
                                    <option *ngFor="let unitType of arrUnitTypes" [ngValue]="unitType.value">
                                        {{unitType.text}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-6 col-sm-6">
                            <label *ngIf="!blnIsPedastrian">Driver Height:</label>
                            <label *ngIf="blnIsPedastrian">Pedastrian Height:</label>
                            <input class="form-control" type="text" disabled [value]="intFeet" placeholder="Feet" />
                        </div>
                        <div class="col-6 col-sm-6">
                            <label *ngIf="!blnIsPedastrian">Driver Height:</label>
                            <label *ngIf="blnIsPedastrian">Pedastrian Height:</label>
                            <input class="form-control" type="text" disabled [value]="intInches" placeholder="Inches" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-primary" (click)="OnSubmit(inputHeight)">Submit</button>
        <button type="button" class="btn btn-light border-secondary" (click)="modal.close('Cancel click');onCancel()">Cancel</button>
    </div>

</ng-template>
