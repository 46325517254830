<form novalidate #fvdc="ngForm">

    <div class="pt-2" *ngIf="dri">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="prevAcc" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="PrevAcc"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData"
                                   [blnShowSpecialValuesOnly]="true"
                                   (keyup)="eventHandler($event)">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div [ngClass]="{'card border border-secondary bg-light m-2' : !printOnly }">
                    <div class="{{ !printOnly  ? 'card-body border border-secondary bg-light p-1 margin-border' : 'text-center' }}">
                        <div [ngClass]="{'card-title fw-bold bg-light' : !printOnly }"><span [ngClass]="{'print-font-size-small' : printOnly }">Previous Rec. Susp.,Revoc. and Withdrawals</span></div>

                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="driPrevSus1" name="driPrevSus1" typeAheadPosition="right-top"
                                               [objModel]="dri.DriPrevSus[0]" strTableName="DriPrevSus" strFieldName="PrevSusRevoc" [intSeqNum]="1" [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="true">
                                </app-typeahead>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="driPrevSus2" name="driPrevSus2" typeAheadPosition="right-top"
                                               [objModel]="dri.DriPrevSus[1]" strTableName="DriPrevSus" strFieldName="PrevSusRevoc" [intSeqNum]="2" [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="true">
                                </app-typeahead>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="driPrevSus3" name="driPrevSus3" typeAheadPosition="right-top"
                                               [objModel]="dri.DriPrevSus[2]" strTableName="DriPrevSus" strFieldName="PrevSusRevoc" [intSeqNum]="3" [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="true">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="prevDwi" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="PrevDwi"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="true">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="prevSpd" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="PrevSpd" [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="true">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="prevOth" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="PrevOth" [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="true">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="pt-1 pb-1">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : !printOnly }">
                                <!--TO DO make it as app - label-->
                                <!--<label for="FAccMon" class="col-form-label labelLink pl-2">Date - OLDEST Crash/Suspension/Conviction</label>-->
                                <div class="pl-2">
                                    <app-label inputName="FAccDate" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : !printOnly }">
                                <div class="input-group">
                                    <div class="input-group-append">
                                            <app-typeahead id="FAccMon"
                                                           typeAheadPosition="right-top"
                                                           [disableLabel]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [objModel]="dri"
                                                           strTableName="Dri"
                                                           strFieldName="FAccMon"
                                                           (bindModel)="OnModelFAccMonChange($event)"
                                                           [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData">
                                            </app-typeahead>
                                    </div>
                                        <p class="px-2">/</p>
                                      <div class="input-group-append">
                                            <app-typeahead id="FAccYr"
                                                           typeAheadPosition="right-top"
                                                           [disableLabel]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [objModel]="dri"
                                                           strTableName="Dri"
                                                           strFieldName="FAccYr"
                                                           (bindModel)="OnModelFAccYrChange($event)"
                                                           [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="true">
                                            </app-typeahead>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="pt-1 pb-1">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : !printOnly }">
                                <!--TO DO make it as app - label-->
                                <!--<label for="LAccMon" class="col-form-label labelLink pl-2">Date - MOST RECENT Crash/Suspension/Conviction</label>-->
                                <div class="pl-2">
                                    <app-label inputName="LAccDate" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : !printOnly }">
                                <div class="input-group">
                                    <div class="input-group-append">
                                            <app-typeahead id="LAccMon"
                                                           typeAheadPosition="right-top"
                                                           [disableLabel]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [objModel]="dri"
                                                           strTableName="Dri"
                                                           strFieldName="LAccMon"
                                                           (bindModel)="OnModelLAccMonChange($event)"
                                                           [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData">
                                            </app-typeahead>
                                    </div>
                                        <p class="px-2">/</p>
                                        <div class="input-group-append">
                                            <app-typeahead id="LAccYr"
                                                           typeAheadPosition="right-top"
                                                           [disableLabel]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [objModel]="dri"
                                                           strTableName="Dri"
                                                           strFieldName="LAccYr"
                                                           (bindModel)="OnModelLAccYrChange($event)"
                                                           [printOnly]="printOnly"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="true">
                                            </app-typeahead>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
