<div class="row pt-4">
    <div class="col-xl">
        <div class="row">
            <div class="col-12 col-sm-12 col-lg-12 col-xl-8">
                <div class="">
                    <div class="table-responsive">
                        <table class="table table-hover table-sm mb-0">
                            <thead class="bg-tb-header">
                                <tr>
                                    <th class="border-bottom border-top border-dark">Mandatory</th>
                                    <th class="border-bottom border-top border-dark">Key</th>
                                    <th class="border-bottom border-top border-dark">Common</th>
                                    <th class="border-bottom border-top border-dark">Supplemental</th>
                                    <th class="border-bottom border-top border-dark">Total</th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom border-dark">
                                <tr>
                                    <td class="fw-bold">{{mandatoryBlanksCount}}</td>
                                    <td class="fw-bold">{{keyBlanksCount}}</td>
                                    <td class="fw-bold">{{commonBlanksCount}}</td>
                                    <td class="fw-bold">{{supplementalBlanksCount}}</td>
                                    <td class="fw-bold">{{totalBlanksCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <div class="row pt-4">
            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 pt-2 pt-sm-2 pt-lg-2 pt-xl-2">

                <div class="pb-2 form-inline">
                    <h5 *ngIf="intMode == 1 || intMode == 64">Blanks for Case - {{ acc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : '' }}</h5>
                    <h5 *ngIf="intMode == 4">Blanks for PAR ID - {{ acc.EarlyNotify[0]?.PARID | number : '8.0-0' | replaceTextPipe : ',' : '' | slice : 4 : 12 }}</h5>
                    <h5 *ngIf="intMode == 256">Blanks for Case - {{ acc.Acc_SS.MOSSCasenum }}</h5>
                    <span class="ml-3" *ngIf="loading$ | async"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></span>
                </div>

                <div class="">
                    <div class="table-responsive">
                        <table class="table table-hover table-sm mb-0">
                            <thead class="bg-tb-header pl-2">
                                <tr>
                                    <th scope="col" sortable="FldDef" (sort)="onSort($event)" class="border-bottom border-top border-dark text-nowrap text-center">
                                        <span>Blank Element</span>
                                        <span *ngIf="ColummForSorting == 'FldDef'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="VehNum" *ngIf="intMode != 256" (sort)="onSort($event)" class="border-bottom border-top border-dark text-nowrap text-center">
                                        <span>Vehicle #</span>
                                        <span *ngIf="ColummForSorting == 'VehNum'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="VehNum" *ngIf="intMode == 256" (sort)="onSort($event)" class="border-bottom border-top border-dark text-nowrap text-center">
                                        <span>Vehicle #</span>
                                        <span *ngIf="ColummForSorting == 'SSVNumber'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="PerNum" (sort)="onSort($event)" class="border-bottom border-top border-dark text-nowrap text-center">
                                        <span>Person #</span>
                                        <span *ngIf="ColummForSorting == 'PerNum'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="Rank" (sort)="onSort($event)" class="border-bottom border-top border-dark text-nowrap text-center">
                                        <span>Severity</span>
                                        <span *ngIf="ColummForSorting == 'Rank'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="FldName" (sort)="onSort($event)" class="border-bottom border-top border-dark text-nowrap text-center">
                                        <span>Field</span>
                                        <span *ngIf="ColummForSorting == 'FldName'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="SubFormName" (sort)="onSort($event)" class="border-bottom border-top border-dark text-nowrap text-center">
                                        <span>Data File</span>
                                        <span *ngIf="ColummForSorting == 'SubFormName'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom border-top border-dark">
                                <tr class="bg-white">
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchBlankElementTerm" placeholder="Search" [(ngModel)]="searchBlankElementTerm" />
                                    </td>
                                    <td class=" form-group border-bottom border-dark" *ngIf="intMode != 256">
                                        <input class="form-control form-control-sm" type="text" name="searchVehicleTerm" placeholder="Search" [(ngModel)]="searchVehicleTerm" />
                                    </td>
                                    <td class=" form-group border-bottom border-dark" *ngIf="intMode == 256">
                                        <input class="form-control form-control-sm" type="text" name="searchSSVVehicleTerm" placeholder="Search" [(ngModel)]="searchSSVVehicleTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchPersonTerm" placeholder="Search" [(ngModel)]="searchPersonTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchLevelTerm" placeholder="Search" [(ngModel)]="searchLevelTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchFieldTerm" placeholder="Search" [(ngModel)]="searchFieldTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchDataFileTerm" placeholder="Search" [(ngModel)]="searchDataFileTerm" />
                                    </td>
                                </tr>
                                <tr *ngFor="let blank of blanks$ | async">
                                    <td class="my-auto p-0">
                                        <button type="button" class="btn btn-link text-truncate" (click)="onGoTo(blank)" style="text-decoration: none">
                                            <ngb-highlight [result]="blank?.FldDef" [term]="searchBlankElementTerm"></ngb-highlight>
                                        </button>
                                    </td>
                                    <td *ngIf="intMode != 256">
                                        <ngb-highlight [result]="blank?.VehNum"></ngb-highlight>
                                    </td>
                                    <td *ngIf="intMode == 256">
                                        <ngb-highlight [result]="blank?.SSVNumber"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="blank?.PerNum"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="blank?.Rank"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="blank?.FldName" [term]="searchFieldTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="blank?.SubFormName" [term]="searchDataFileTerm"></ngb-highlight>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="total$ | async" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">

                            </ngb-pagination>
                            <div class="custom-control-inline">
                                <p class="pr-2 my-auto">Total: {{total$ | async }}</p>
                                <select class="custom-select pl-2 my-auto w-auto" name="pageSize" [(ngModel)]="pageSize">
                                    <option [ngValue]="5">5 items per page</option>
                                    <option [ngValue]="10">10 items per page</option>
                                    <option [ngValue]="25">25 items per page</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
