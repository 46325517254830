import { Injectable }     from '@angular/core';
import { GenericService } from 'src/app/services/generic.service';
import { RbisUser }       from 'src/app/models/rbis-user';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _objUser: RbisUser;
    private _prmUser: Promise<RbisUser>;
    public _isAuthorized: Promise<boolean>;
    public isAuth: boolean;
    public mossSCIsites: Promise<any[]>;
    mossSCISiteList: Promise<number[]>;

    constructor(
        private _baseService: GenericService<any>
    ) {

    }

    public GetUserPromise(): Promise<RbisUser> {
        //We are intentionally using an explicitly instantiated Promise and not using the async await syntax here to ensure
        //that all components requesting access to the data model (the User object) share the same promise, the same server
        //trip and the same instance of the data model.

        if (this._objUser) {
            return new Promise((resolve) => {
                resolve(this._objUser);
            });
        }
        else if (this._prmUser) {
            return this._prmUser;
        }
        else {
            this._baseService.actionURL = "api/util/GetUser";
            this._prmUser = this._baseService.getDataPromise();

            this._prmUser.then((objUser: RbisUser) => {
                this._objUser = objUser;
                this._prmUser = null;
            });

            return this._prmUser;
        }
    }

    public IsUserAuthorized(): Promise<boolean> {
        this._baseService.actionURL = "api/util/IsUserAuthorized";
        this._isAuthorized = this._baseService.getDataPromise();
        return this._isAuthorized;
    }

    public GetMossSciSites(): Promise<number[]> {

        this._baseService.actionURL = "api/util/GetMossSciSites/";
        this.mossSCIsites = this._baseService.getDataPromise();

        return this.mossSCIsites;
    }

}
