import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { DecimalPipe } from '@angular/common';

import { AutofillService } from 'src/app/services/autofill.service';
//import { ModalService } from 'src/app/services/modal.service';

import { NumericDirective } from 'src/app/directives/numeric.directive';

import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';

@Component({
    selector: 'app-speed-calculator',
    templateUrl: './speed-calculator.component.html',
    styleUrls: ['./speed-calculator.component.css']
})

export class SpeedCalculatorComponent implements OnInit {
    closeResult: string;

    private modal: NgbModalRef;
    strModelName: string;

    @Input() public objVeh: Veh;
    @Input() public objToUpdate: string;
    @Input() public destUnits: string;

    @Output() setSpeedEvent = new EventEmitter<string>();

    public intSpeed: number;
    public intKmPerHr: number;
    public intmPerHr: number;

    warning: string = '';
    speed: any;
    speedUnit: number = 1;
    resultText: string = '';
    result: any = null;

    fontColor: string = 'red';
    mPerHr: string;

    blnShowValidationMessage: boolean;
    blnRequired: boolean;

    constructor(
        private _ngModalService: NgbModal,
        private _autofillService: AutofillService,
        public pipe: DecimalPipe,
    ) { }

    ngOnInit() {

    }

    onSubmit(efSpeed) {

        this.intSpeed = null;
        let strValue = this.pipe.transform(this.result, '3.0-0');
        if (this.destUnits == this.mPerHr)
            this.setSpeedEvent.emit(strValue);
        this.modal.close();
    }

    onCancel() {
        this.blnShowValidationMessage = false;
        this.blnRequired = false;
        this.intSpeed = null;
    }

    onClear() {
        this.warning = '';
        this.speed = '';
        this.speedUnit = 1;
        this.resultText = '';
        this.result = '';
        this.mPerHr = '';
    }

    open(content, options: NgbModalOptions) {
        this.modal = this._ngModalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'light-blue-backdrop', backdrop: 'static', keyboard: false });

    }

    unitChange() {
        this.warning = '';
        this.resultText = '';
        this.result = 0;
        this.SpeedConversion();
    }

    SpeedConversion() {
        if (isNaN(this.speed)) {
            this.warning = 'Please enter a valid value';
            return;
        }

        let distanceValue: number;
        let speeds: number = +this.speed;

        if (this.speedUnit == 1) {

            distanceValue = Number(Math.round(speeds * 0.621371));
            this.mPerHr = 'Mph'
        }
        else if (this.speedUnit == 2) {
            distanceValue = Number(Math.round(speeds * 1.609344));
            this.mPerHr = 'Kmph'
        }

        this.result = distanceValue.toString();
        this.warning = '';
    }

}
