import { Injectable } from '@angular/core';

//rxjs && rxjs/operators
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

//services
import { GenericService } from './generic.service';
import { SharedDataService } from './shared-data.service';

//models
import { CheckBlanks } from './../models/check-blanks';

@Injectable({
    providedIn: 'root'
})

export class BlanksService {

    constructor(        
        private _baseService: GenericService<any>,
        public _sharedDataService: SharedDataService
    ) {        
    }

    public GetCaseBlanks(accID: number): Observable<CheckBlanks[]> {
        this._baseService.actionURL = "api/case/GetCaseBlanks/" + accID;
        let objBlanks = this._baseService.getList().pipe(
            share()
        );

        //this.caseBlanksSubscription.add(objBlanks.subscribe((item) => {
        //    //this._BLANK = item;
        //    this._caseBlanksToShare$.next(item);
        //}));
        return objBlanks;
    }    
}
