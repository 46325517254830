import { Injectable, PipeTransform } from '@angular/core';

//rxjs && rxjs/operators
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

//services
import { GenericService } from './generic.service';
import { SharedDataService } from './shared-data.service';

//models
import { CaseOverRiddenRules } from './../models/case-over-ridden-rules';
import { CaseOverRiddenRulesReasons } from './../models/case-overridden-rules-reasons';
import { RulesErrorList } from './../models/Rules_Error_List';
import { OrideCase } from '../models/oride-case';
import { Acc } from '../models/Acc';


@Injectable({
    providedIn: 'root'
})

export class OverriddenRulesService {

    constructor(
        private _baseService: GenericService<any>,
        public _sharedDataService: SharedDataService
    ) {
    }

    public GetCaseOverRiddenRules(stateNum: number, accId: number, year: number, ruleId: string): Observable<CaseOverRiddenRules[]> {

        let caseOverRiddenRules = this._baseService.GetCaseOverRiddenRules(stateNum, accId, year, ruleId);
        return caseOverRiddenRules;
    }

    public GetCaseOverRiddenRulesReasons(stateNum: number, year: number, ruleId: string): Observable<CaseOverRiddenRulesReasons[]> {

        let caseOverRiddenRulesReasons = this._baseService.GetCaseOverRiddenRulesReasons(stateNum, year, ruleId);
        return caseOverRiddenRulesReasons;
    }

    public SaveRulesErrorList(rulesErrorList: RulesErrorList[]): Promise<void> {
        this._baseService.actionURL = "api/case/SaveRulesErrorList/";
        let prmResult: Promise<void> = this._baseService.updateEntity(rulesErrorList).toPromise();
        console.log(prmResult);
        return prmResult;

    }

    public SaveOrideCase(OrideCase: OrideCase): Promise<any> {
        this._baseService.actionURL = 'api/case/SaveOrideCase';
         //Warning: using the observable pattern here is not appropriate: the internal subscription here and the 2nd subscription of the caller of this method will cause Http to issue the web twice, once for each subscriber.
        let obsSaveOride = this._baseService.saveEntity(OrideCase).toPromise();
        return obsSaveOride;
    }

    public SaveOrideCaseIsHideFlag(CaseOverRiddenRulesReasons: CaseOverRiddenRulesReasons): Promise<void> {
        //this._baseService.actionURL = "api/case/SaveOrideCaseIsHideFlag/";
        let prmResult: Promise<void> = this._baseService.SaveOrideCaseIsHideFlag(CaseOverRiddenRulesReasons);
        console.log(prmResult);
        return prmResult;

    }

    public DeleteOrideCase(AccID: number, RuleID: string, VNumber: number, PNumber: number): Promise<any> {
        this._baseService.actionURL = 'api/case/DeleteOrideCase/' + AccID + "/" + RuleID + "/" + VNumber + "/" + PNumber;
        return this._baseService.getData().toPromise();
    }

}
