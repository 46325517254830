import { Injectable, Output, EventEmitter } from '@angular/core';
//rxjs && rxjs/operators
import { Observable, BehaviorSubject, Subscription, Subject, from, of } from 'rxjs';

//services
import { GenericService } from './generic.service';
import { ModalService } from './modal.service';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';

//models
import { Acc } from './../models/acc';
import { Occupants } from './../models/occupants';
import { CaseOverRiddenRules } from './../models/case-over-ridden-rules';
import { Veh } from '../models/veh';
import { Non_Occupants } from '../models/non-occupants';
import { NonOccDrug } from '../models/non-occ-drug';
import { OccDrug } from './../models/occ-drug';
import { DeletedCases } from './../models/deleted-cases';

import { CrashEvents } from './../models/crash-events';
import { DamagedAreas } from './../models/damaged-areas';

//helpers
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { Cases_Locked } from '../models/cases-locked';
import { LongLatInfo } from '../models/longlatinfo';
import { CaseLockedViewModel } from '../interface/CaseLockedViewModel';
import { GetCrashAvoidCriteria_Result } from '../models/GetCrashAvoidCriteria_Result';
import { UtilService } from './util.service';
import { Element_Specify } from '../models/element-specify';
import { share, catchError } from 'rxjs/operators';
import { Preference, EDTStatus, DBMode, Injury, PropertyType, } from 'src/app/models/enums/app.enums';
import { CaseStats } from '../models/case-stats';
import { UrlSegment } from '@angular/router';
import { UrlTreeHelper } from '../helper/UrlTreeHelper';
import { BaseComponent } from '../helper/basecomponent';
import { EditCheckHelper } from '../helper/EditCheckHelper';
import { EarlyNotify } from '../models/early-notify';
import { MessageBoxComponent } from '../components/message-box/message-box.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericMessage } from '../models/GenericMessage';
import { AutofillService } from './autofill.service';
import { RbisUser } from '../models/rbis-user';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CaseService {
    private intObsCaseNumber: BehaviorSubject<number> = new BehaviorSubject(null);
    private strObsCaseNumber: BehaviorSubject<string> = new BehaviorSubject(null);

    public subCase: BehaviorSubject<Acc> = new BehaviorSubject(null);
    /**
     * Summary of failing Edit Checks that is refreshed after every save, after edit checks have finished running
     **/
    public subEditCheckStats = new Subject<CaseStats>();
    private _acc: Acc;
    private _objUser: RbisUser;
    public get acc() { return this._acc; }
    public set acc(value: Acc) { this._acc = value; }
    accPms: Promise<Acc>;
    person: Occupants;
    hideNavForCloseCase: BehaviorSubject<boolean> = new BehaviorSubject(false);
    hideENNum = new Subject<boolean>();
    caseLocked: BehaviorSubject<any> = new BehaviorSubject(null);
    longLatStatus: BehaviorSubject<LongLatInfo>;
    refreshPreCrash: BehaviorSubject<boolean> = new BehaviorSubject(false);
    tmpAccID: number;
    tmpcasenum: number;
    tmpParID: string = '';
    tmpMossNo: string = '';
    validationRules: Array<GetCrashAvoidCriteria_Result> = [];
    public intCasenum: number;
    public mossCasenum: string = '';

    @Output() emsSaved = new EventEmitter<Acc>();

    //public trafficwayInfoFilledStatusInitial = new BehaviorSubject(false);
    //public trafficwayInfoFilledStatusAfterSave = new BehaviorSubject(false);
    @Output() trafficwaySaved = new EventEmitter<Acc>();

    //public deathRecordsFilledStatusInitial = new BehaviorSubject(false);
    //public deathRecordsFilledStatusAfterSave = new BehaviorSubject(false);
    @Output() deathRecordsSaved = new EventEmitter<Acc>();

    //public alcDrugFilledStatusInitial = new BehaviorSubject(false);
    //public alcDrugFilledStatusAfterSave = new BehaviorSubject(false);
    @Output() alcDrugSaved = new EventEmitter<Acc>();
    caseReadOnlyStatus: BehaviorSubject<boolean> = new BehaviorSubject(null);

    private _isShowTabSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isShowTab$: Observable<boolean> = this._isShowTabSubject.asObservable();

    public sbjUploadActive = new BehaviorSubject<boolean>(false);


    constructor(
        private _baseService: GenericService<any>,
        private _modalService: ModalService,
        private ngModal: NgbModal,
        private _utilService: UtilService,
        private _userPreferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _authService: AuthService
    ) {
        this.longLatStatus = new BehaviorSubject<LongLatInfo>(null);
        this.validationSeedData();
        this.subEditCheckStats.subscribe(((objEditCheckStats: CaseStats) => {
            //objEditCheckStats.Acc = null; //If CaseStats was wrapped in a temporary Acc container, unlink from that temporary container so that it does not pollute the data model.

            if (this.acc && this.acc.AccID == objEditCheckStats.AccID) { //If user is jumping quickly between cases, the Edit Check results from the last save may come back after the user has already switched to a different case, in which case we simply ignore them.
                if (this.acc.CaseStats) {
                    for (let strPropertyName in objEditCheckStats) { //Our goal here is to copy over primitive values, but not object references. CAUTION: The dbo.CaseStats table has business rules requiring certain columns to be populated as a pair (ex. CrUserID and CrDateTime), which is important for downstream Intranet Reports.
                        if (objEditCheckStats[strPropertyName] &&    //Value is not null
                            ((objEditCheckStats[strPropertyName] instanceof Date) //Dates may come down in JSON as strings
                                || (typeof (objEditCheckStats[strPropertyName]) == PropertyType.String)
                                || (typeof (objEditCheckStats[strPropertyName]) == PropertyType.Number)
                            )
                        ) {
                            this.acc.CaseStats[strPropertyName] = objEditCheckStats[strPropertyName];
                        }
                    }
                }
                else {
                    this.acc.CaseStats = objEditCheckStats;
                    objEditCheckStats.Acc = this.acc;
                }
            }
        }).bind(this));
        this.getUSer();
    }

    //public GetCasePromise(intAccID: number, blnBypassReleaseLocks: boolean = false, blnReadOnlyNoLock: boolean = false): Promise<Acc> {
    //    this.tmpAccID = intAccID;
    //    console.log(intAccID);
    //    //We are intentionally using an explicitly instantiated Promise and not using the async await syntax here to ensure
    //    //that all components requesting access to the data model (the Acc object) share the same promise, the same server
    //    //trip and the same instance of the data model.
    //    if ((this._acc) && (this._acc.AccID == intAccID)) {
    //        return new Promise((resolve) => {
    //            resolve(this._acc);
    //        });
    //    }
    //    else if (this.accPms) {
    //        return this.accPms;
    //    }
    //    else {
    //        this._baseService.actionURL = "api/case/GetCase/" + intAccID + '/' + blnBypassReleaseLocks + '/' + blnReadOnlyNoLock;
    //        this.accPms = this._baseService.getDataPromise();
    //        this.accPms.then(this.PreprocessCase.bind(this), this.OnGetCaseError.bind(this));
    //        return this.accPms;
    //    }
    //}

    public GetCasePromise(intAccID: number, blnBypassReleaseLocks: boolean = false, blnReadOnlyNoLock: boolean = false, tmpCaseNumber: number = 0): Promise<Acc> {
        this.tmpAccID = tmpCaseNumber;
        //We are intentionally using an explicitly instantiated Promise and not using the async await syntax here to ensure
        //that all components requesting access to the data model (the Acc object) share the same promise, the same server
        //trip and the same instance of the data model.
        if ((this._acc) && (this._acc.AccID == intAccID)) {
            return new Promise((resolve) => {
                resolve(this._acc);
            });
        }
        else if (this.accPms) {
            return this.accPms;
        }
        else {
            this._baseService.actionURL = "api/case/GetCase/" + intAccID + '/' + blnBypassReleaseLocks + '/' + blnReadOnlyNoLock;
            this.accPms = this._baseService.getDataPromise();
            this.accPms.then(this.PreprocessCase.bind(this), this.OnGetCaseError.bind(this));
            return this.accPms;
        }
    }

    public GetCaseByMossCaseNumberPromise(mossCaseNumber: string, year: number = 0): Promise<Acc> {
        this.tmpMossNo = mossCaseNumber;

        //We are intentionally using an explicitly instantiated Promise and not using the async await syntax here to ensure
        //that all components requesting access to the data model (the Acc object) share the same promise, the same server
        //trip and the same instance of the data model.
        if ((this._acc) && (this._acc.Acc_SS.MOSSCasenum == mossCaseNumber) && (this._acc.CaseYear == year || year == 0)) {
            return new Promise((resolve) => {
                resolve(this._acc);
            });
        }
        else if (this.accPms) {
            return this.accPms;
        }
        else {
            this._baseService.actionURL = "api/case/GetCaseByMossCaseNum/" + mossCaseNumber + "/" + year + "/" + false;
            this.accPms = this._baseService.getDataPromise();
            this.accPms.then(this.PreprocessCase.bind(this), this.OnGetCaseError.bind(this));

            this.strObsCaseNumber.next(mossCaseNumber);
            return this.accPms;
        }
    }

    public IsMossMumExists: Promise<boolean>;
    public ChkMossCaseNumberPromise(mossCaseNumber: string, year: number = 0, isCreatCaseMode: boolean = false): Promise<boolean> {
        this._baseService.actionURL = "api/case/GetCaseByMossCaseNum/" + mossCaseNumber + "/" + year + "/" + isCreatCaseMode;
        this.IsMossMumExists = this._baseService.getDataPromise();
        return this.IsMossMumExists;
    }


    public GetCaseByCaseNumberPromise(caseNumber: number, stateNum: number, year: number): Promise<Acc> {

        this.tmpAccID = caseNumber;

        //We are intentionally using an explicitly instantiated Promise and not using the async await syntax here to ensure
        //that all components requesting access to the data model (the Acc object) share the same promise, the same server
        //trip and the same instance of the data model.
        if ((this._acc) && (this._acc.Casenum == caseNumber && this._acc.CaseYear == year)) {
            return new Promise((resolve) => {
                resolve(this._acc);
            });
        }
        else if (this.accPms) {
            return this.accPms;
        }
        else {
            this._baseService.actionURL = "api/case/GetCaseByCaseNum/" + caseNumber + "/" + year;
            this.accPms = this._baseService.getDataPromise();
            this.accPms.then(this.PreprocessCase.bind(this), this.OnGetCaseError.bind(this));

            this.intObsCaseNumber.next(caseNumber);
            return this.accPms;
        }
    }

    //added this method related to task 5772 item #2 button arrow up - Get first avaiable case with greater casenumber then current one
    public GetCaseByCaseIncreasedNumberPromise(caseNumber: number, stateNum: number, year: number): Promise<Acc> {
        this.tmpAccID = caseNumber;
        //We are intentionally using an explicitly instantiated Promise and not using the async await syntax here to ensure
        //that all components requesting access to the data model (the Acc object) share the same promise, the same server
        //trip and the same instance of the data model.
        if ((this._acc) && (this._acc.Casenum == caseNumber && this._acc.StateNum == stateNum && this._acc.CaseYear == year)) {
            return new Promise((resolve) => {
                resolve(this._acc);
            });
        }
        else if (this.accPms) {
            return this.accPms;
        }
        else {
            this._baseService.actionURL = "api/case/GetCaseByCaseNumIncreased/" + caseNumber + "/" + stateNum + "/" + year;
            this.accPms = this._baseService.getDataPromise();
            this.accPms.then(this.PreprocessCase.bind(this), this.OnGetCaseError.bind(this));
            this.intObsCaseNumber.next(caseNumber);
            return this.accPms;
        }
    }
    //added this method related to task 5772 item #2 button arrow down - Get first avaiable case with lower casenumber then current one
    public GetCaseByCaseDecreasedNumberPromise(caseNumber: number, stateNum: number, year: number): Promise<Acc> {
        this.tmpAccID = caseNumber;
        //We are intentionally using an explicitly instantiated Promise and not using the async await syntax here to ensure
        //that all components requesting access to the data model (the Acc object) share the same promise, the same server
        //trip and the same instance of the data model.
        if ((this._acc) && (this._acc.Casenum == caseNumber && this._acc.StateNum == stateNum && this._acc.CaseYear == year)) {
            return new Promise((resolve) => {
                resolve(this._acc);
            });
        }
        else if (this.accPms) {
            return this.accPms;
        }
        else {
            this._baseService.actionURL = "api/case/GetCaseByCaseNumDecreased/" + caseNumber + "/" + stateNum + "/" + year;
            this.accPms = this._baseService.getDataPromise();
            this.accPms.then(this.PreprocessCase.bind(this), this.OnGetCaseError.bind(this));
            return this.accPms;
        }
    }

    private PreprocessCase(objAcc: Acc): void {

        this._acc = objAcc;
        this.accPms = null;
        if (objAcc != null && objAcc.EarlyNotify && objAcc.EarlyNotify.length > 0) {
            for (let objEarlyNotify of objAcc.EarlyNotify)
                objEarlyNotify.Acc = objAcc; //EarlyNotify is the data model root, but when retrieving Acc, EarlyNotify is comes as a child of Acc, therefore, restore link from EarlyNotify to Acc

            ObjectUtil.RestoreBidirectionalLinks(objAcc.EarlyNotify[0]);
        }
        else
            ObjectUtil.RestoreBidirectionalLinks(objAcc);

        //loop through all veh and atach default below Veh_MTSS and PreCrash_MTSS
        //objAcc.Veh.forEach((objVehicle: Veh) => {
        //    if (!objVehicle.Veh_MTSS) {
        //        AutofillService.AddModelObject(objVehicle, 'Veh', 'Veh_MTSS');
        //    }
        //    if (!objVehicle.PreCrash) {
        //        AutofillService.AddModelObject(objVehicle, 'Veh', 'PreCrash');
        //    }
        //    if (!objVehicle.PreCrash.PreCrash_MTSS) {
        //        AutofillService.AddModelObject(objVehicle.PreCrash, 'PreCrash', 'PreCrash_MTSS');
        //    }

        //})

        if (objAcc != null && !objAcc.Acc_SS) {
            AutofillService.AddModelObject(objAcc, 'Acc', 'Acc_SS');
        }
        if (this._acc != null) {
            if (this._acc.Mode == DBMode.MOSS) {
                this.strObsCaseNumber.next(this._acc.Acc_SS.MOSSCasenum);
            }
            else if (this._acc.Mode == DBMode.CRSS) {
                this.intObsCaseNumber.next(this._acc.EarlyNotify[0].PARID);
            }
            else {
                this.intObsCaseNumber.next(this._acc.Casenum);
            }

            this.subCase.next(objAcc);
        }
    }

    //private OnGetCaseError(objError: any): void {
    //    if (objError.includes && objError.includes('isCaseLocked')) {
    //        this.lockedCase(this.tmpAccID).toPromise(); //Convert to promise to generate observer for observable and force it to evaluate. TODO: move this inside lockedCase()?
    //    }

    //    this.accPms = null;
    //    console.log(objError);
    //}

    private OnGetCaseError(objError: any): void {
        console.log(objError);

        if (objError && objError.includes('already locked')) {
            let strObjError = objError.substring(objError.indexOf(":") + 1).trimLeft();
            this.lockedCase(this.tmpAccID, strObjError).toPromise();
        }
        else if (objError && objError.includes('There is no case with Case Number')) {
            let strObjError = objError.substring(objError.indexOf("There is no case with Case Number")).trimLeft();
            this._modalService.setMessage(strObjError, 'warning');
        }

        this.accPms = null;
    }

    public async getUSer() {
        this._objUser = await this._authService.GetUserPromise();
    }

    private lockedCase(caseNum, objError): Observable<boolean> {
        const modal = this.ngModal.open(MessageBoxComponent, { backdrop: 'static', size: 'lg', centered: true });

        modal.componentInstance.body = objError;
        modal.componentInstance.title = "Locked Case";
        modal.componentInstance.isRelaseLock = true;
        modal.componentInstance.blnShowRelaseCaseBtn = this._objUser.hasAdminRights;
        modal.componentInstance.module = 'D';
        modal.componentInstance.boxType = 'R';
        modal.componentInstance.caseNum = caseNum
        modal.componentInstance.strYesText = "Ok";
        modal.componentInstance.strNoText = "Close";
        return from(modal.result).pipe((error) => {
            console.warn(error);
            return of(undefined);
        });
    }

    //private lockedCase(caseNum): Observable<boolean> {

    //    const modal = this.ngModal.open(MessageBoxComponent, { backdrop: 'static', size: 'lg', centered: true });

    //    if (this.tmpParID != '') {
    //        caseNum = this._utilService.sizeOfNumber(this.tmpParID, 7, 0);
    //        modal.componentInstance.body = 'PAR ID ' + this.intCasenum + ' is in use. Please go to a different case or click the Release Records button below to see if you can unlock the case.';
    //        modal.componentInstance.title = 'PAR ID ' + this.intCasenum + ' is in use.';
    //    } else if (this.mossCasenum != null || this.mossCasenum != '') {
    //        modal.componentInstance.body = 'Case Number ' + this.mossCasenum + ' is in use. Please go to a different case or click the Release Records button below to see if you can unlock the case.';
    //        modal.componentInstance.title = 'Case Number ' + this.mossCasenum + ' is in use.';
    //    }
    //    else {
    //        modal.componentInstance.body = 'Case Number ' + this.intCasenum + ' is in use. Please go to a different case or click the Release Records button below to see if you can unlock the case.';
    //        modal.componentInstance.title = 'Case Number ' + this.intCasenum + ' is in use.';

    //    }
    //    modal.componentInstance.isRelaseLock = true;
    //    modal.componentInstance.module = 'D';
    //    modal.componentInstance.boxType = 'R'
    //    modal.componentInstance.caseNum = caseNum
    //    modal.componentInstance.strYesText = "Ok";
    //    modal.componentInstance.strNoText = "Close";
    //    return from(modal.result).pipe(
    //        catchError(error => {
    //            console.warn(error);
    //            return of(undefined);
    //        })
    //    )
    //}

    public CheckUsedCase(caseid: number): Observable<any> {
        this.caseLocked.next(null);
        this._baseService.actionURL = "api/case/TestAction/" + caseid;
        let caseStatus = this._baseService.getData();
        caseStatus.subscribe(item => {
            this.caseLocked.next(item);
        }, (error) => {
            alert(error.message);
            console.log(error);
        })
        return caseStatus;
    }

    public GetCases(): Observable<Acc[]> {
        this._baseService.actionURL = "api/case/GetCases/";
        return this._baseService.getList();
    }

    public SaveCase(objAcc: Acc = null, blnRestructureCase: boolean = false, blnRunEditChecksSynchronously: boolean = false): Promise<Acc> {
        if (!objAcc)
            objAcc = this.acc;

        this.CheckAndSetEDTCaseStatus(objAcc);

        objAcc.Non_Occupants.forEach(objNonOccupant => {
            objNonOccupant.PII = null;
        });

        objAcc.Veh.forEach(vehicle => {
            vehicle.Occupants.forEach(person => {
                person.PII = null;  // PII will be saved at Acc level and not NON Occupant and Occupant level
            })
        });

        this._baseService.actionURL = "api/case/SaveCase/" + blnRestructureCase + "/" + blnRunEditChecksSynchronously.toString();
        let prmSave: Promise<Acc> = this._baseService.updateEntity(objAcc).toPromise();

        //WARNING: using the observable pattern here is NOT appropriate: the internal subscription here and the 2nd subscription of the caller of this method will cause Http to issue the web twice, once for each subscriber.
        prmSave.then(((objSavedAcc: Acc) => {
            ObjectUtil.RestoreBidirectionalLinks(objSavedAcc); //Restore bidirectional links in case there is a subscriber waiting for resulting Acc object

            if (!this._acc) //If case is not cached client-side yet, then cache it, otherwise, just read back the CaseNum into cached copy
                this._acc = objSavedAcc;
            else if (this._acc.Casenum != objSavedAcc.Casenum) {
                this._acc.AccID = objSavedAcc.AccID;
                this._acc.Casenum = objSavedAcc.Casenum;
            }

            if (!blnRunEditChecksSynchronously)
                this.RunChecksGetStats(objSavedAcc.AccID, blnRestructureCase ? 'AllChecks' : 'Acc');
            else if (objSavedAcc.CaseStats) {
                this.subEditCheckStats.next(objSavedAcc.CaseStats);
            }

            //Fire event to update intObsCaseNumber: BehaviorSubject for search box component;
            if (objSavedAcc.Mode == DBMode.CRSS) {
                if (this.intObsCaseNumber.getValue() != objSavedAcc.EarlyNotify[0].PARID) {
                    this.intObsCaseNumber.next(objSavedAcc.EarlyNotify[0].PARID);
                }
            } else {
                if (this.intObsCaseNumber.getValue() != objSavedAcc.Casenum)
                    this.intObsCaseNumber.next(objSavedAcc.Casenum);
            }
        }).bind(this));

        return prmSave;
    }


    //public SaveCaseWithoutAnimation(objAcc: Acc = null): Promise<Acc> {
    //   return SaveCase(objAcc, false, false, "api/case/SaveCaseWithoutAnimation/")
    //}

    public SaveCrashEventsPromise(intAccID: number, arrCrashEvents: CrashEvents[]): Promise<void> {
        this._baseService.actionURL = "api/case/SaveCrashEvents/" + intAccID;
        let prmResult: Promise<void> = this._baseService.updateEntity(arrCrashEvents).toPromise();
        return prmResult;
    }

    // added the vehicle number to the "savedamagedareas"
    public SaveDamagedAreasPromise(intAccID: number, intVehID: number, arrDamagedAreas: DamagedAreas[]): Promise<void> {
        this._baseService.actionURL = "api/case/SaveDamagedAreas/" + intAccID + "/" + intVehID;
        let prmResult: Promise<void> = this._baseService.updateEntity(arrDamagedAreas).toPromise();
        return prmResult;
    }

    public SaveListElementSpecify(intAccID: number, objElementSpecify: Element_Specify[]): Promise<any> {
        this._baseService.actionURL = "api/case/SaveListElementSpecify/" + intAccID;
        let prmResult: Promise<any> = this._baseService.updateEntity(objElementSpecify).toPromise();
        return prmResult;
    }

    public DeleteElementSpecify(objElementSpecify: Element_Specify): Promise<any> {
        //this._baseService.actionURL = "api/case/DeleteElementSpecify/";
        let prmResult: Promise<void> = this._baseService.DeleteElementSpecify(objElementSpecify.AccID,
            objElementSpecify.VNumber,
            objElementSpecify.PNumber,
            objElementSpecify.TableID,
            objElementSpecify.FieldID,
            objElementSpecify.ElementValue).toPromise();
        return prmResult;
    }

    /**
     * Reruns Edit Checks at the specified level, recomputes Edit Check stats for case and returns Edit Check stats.
     * Fires CaseService.subCaseStatDetails subject, but also returns the Edit Check stats as a promise.
     */
    public RunChecksGetStats(intAccID: number, strLevel: string, intUserID: number = -1): Promise<CaseStats> {
        this._baseService.actionURL = 'api/case/RunChecksGetStats/' + intAccID.toString() + '/' + strLevel + '/' + intUserID.toString();
        let pmsStats: Promise<CaseStats> = this._baseService.getDataPromise();

        pmsStats.then(
            ((objStats: CaseStats) => {
                this.subEditCheckStats.next(objStats);
            }).bind(this),
            ((objError: HttpErrorResponse) => {
                this._modalService.setMessage('Edit Checks could not be refreshed. Another attempt to refresh Edit Checks will be made when the case is saved again.\r\n' + objError.error, 'warning');
                console.error(objError);
            }).bind(this));

        return pmsStats;
    }

    public GetAccCaseStats(intAccID: number,): Promise<CaseStats> {
        this._baseService.actionURL = 'api/case/GetAccCaseStats/' + intAccID.toString();
        let pmsStats: Promise<CaseStats> = this._baseService.getDataPromise();

        //pmsStats.then(
        //    ((objStats: CaseStats) => {
        //        this.subEditCheckStats.next(objStats);
        //    }).bind(this),
        //    ((objError: HttpErrorResponse) => {
        //        this._modalService.setMessage('Edit Checks could not be refreshed. Another attempt to refresh Edit Checks will be made when the case is saved again.\r\n' + objError.error, 'warning');
        //        console.error(objError);
        //    }).bind(this));

        return pmsStats;
    }

    public GetAllLockedCase(stateNum): Observable<CaseLockedViewModel[]> {
        this._baseService.actionURL = "api/case/GetLockedCases/" + stateNum;
        return this._baseService.getList();
    }

    public ReleaseLockedCases(cases: CaseLockedViewModel[]): Promise<GenericMessage> {
        this._baseService.actionURL = "api/case/ReleaseCases/";
        let releaseStatus = this._baseService.updateEntity(cases).toPromise();
        return releaseStatus;
    }

    public get getlongLatStatus(): Observable<LongLatInfo> {
        return this.longLatStatus.asObservable();
    }

    /**
     * Broadcasts the new Lat/Long validation status to whoever is listening
     */
    public UpdateLongLatStatus(longLat: LongLatInfo) {
        console.log('longLat', longLat);
        this.longLatStatus.next(longLat);
    }

    public GetLongLatValidationStatus(intAccID: number) {
        this._baseService.actionURL = "api/case/GetLongLatValidatorInfo/" + intAccID;
        let statusResult = this._baseService.getData();
        statusResult.subscribe(result => {
            this.longLatStatus.next(result);
        })
    }

    setRefreshPreCrash(status: boolean = false) {
        this.refreshPreCrash.next(null);
        this.refreshPreCrash.next(status);
    }

    get getRefreshPreCrash(): Observable<boolean> {
        return this.refreshPreCrash.asObservable();
    }

    public sendAvoidanceEmail(objVeh: Veh): Promise<any> {
        console.log('objAcc kkk', objVeh);
        const sendModel = {
            AccID: objVeh.AccID,
            VNumber: objVeh.VNumber
        }
        this._baseService.actionURL = "api/util/SendCrashAvoidanceEmail/" + sendModel.AccID + "/" + sendModel.VNumber;
        return this._baseService.updateEntity(sendModel).toPromise();
    }

    public sendAirbagFatalityEmail(objVeh: Veh): Promise<any> {
        const sendModel = {
            AccID: objVeh.AccID,
            VNumber: objVeh.VNumber
        }
        this._baseService.actionURL = "api/util/SendAirbagFatalityEmailFromVech/" + sendModel.AccID + "/" + sendModel.VNumber;;
        return this._baseService.updateEntity(sendModel).toPromise();
    }

    public sendCrashSpecialEmail(objAcc: Acc, emailBody: string, countyName: string): Promise<any> {
        //TODO: consider passing email body as PUT/POST parameter
        this._baseService.actionURL = 'api/util/SendCrashSpecialEmail?strEmailBody=' + encodeURIComponent(emailBody) + '&strCountyName=' + encodeURIComponent(countyName);
        return this._baseService.updateEntity(objAcc).toPromise();
    }

    public emsInfoFilledCheck(accObj: Acc) {
        let emsInfoFilled = true;
        if (accObj.ArrHr < 0 ||
            (accObj.ArrHr > 23 && accObj.ArrHr != 88 && accObj.ArrHr != 99) ||
            accObj.ArrMin < 0 ||
            (accObj.ArrMin > 59 && accObj.ArrMin != 88 && !(accObj.ArrMin > 96 && accObj.ArrMin < 100)) ||
            accObj.HospHr < 0 ||
            (accObj.HospHr > 23 && accObj.HospHr != 88 && accObj.HospHr != 99) ||
            accObj.HospMin < 0 ||
            (accObj.HospMin > 59 && accObj.HospMin != 88 && !(accObj.HospMin > 95 && accObj.HospMin < 100)) ||
            accObj.NotHr < 0 ||
            (accObj.NotHr > 23 && accObj.NotHr != 88 && accObj.NotHr != 99) ||
            accObj.NotMin < 0 ||
            (accObj.NotMin > 59 && accObj.NotMin != 88 && accObj.NotMin != 98 && accObj.NotMin != 99)
        ) {
            emsInfoFilled = false;
        }

        return emsInfoFilled;
    }

    public emsSavedNotify(accObj) {
        this.emsSaved.emit(accObj);
    }

    public trafficwayInfoFilledCheck(accObj) {
        let trafficwayInfoFilled = true;

        try {
            if ((accObj.MilePointWhole < 0 && accObj.MilePointDecimal < 0) ||
                accObj.NHS < 0 ||
                accObj.RTESign < 1 ||
                //accObj.AccTrafID[0].TrafID.trim() == "" ||
                accObj.LandUse < 1 ||
                accObj.FuncSystem < 1 ||
                accObj.Ownership < 1 ||
                accObj.SpeJur < 0
            ) {
                trafficwayInfoFilled = false;
            }

            if (accObj.AccTrafID) {
                if (accObj.AccTrafID.length > 0) {
                    if (accObj.AccTrafID[0].TrafID) {
                        if (accObj.AccTrafID[0].TrafID.trim() == "") {
                            trafficwayInfoFilled = false;
                        }
                    }
                    else {
                        trafficwayInfoFilled = false;
                    }
                }
                else
                    trafficwayInfoFilled = false;
            }
            else
                trafficwayInfoFilled = false;
        }
        catch (ex) {
            trafficwayInfoFilled = false;
            console.error(ex);
        }

        return trafficwayInfoFilled;
    }

    public deathRecordsSavedNotify(accObj) {
        this.deathRecordsSaved.emit(accObj);
    }

    public deathRecordsFilledCheck(accObj) {
        let deathRecordsFilled = true;

        try {
            //Non-occupants check
            accObj.Non_Occupants.forEach((nonOcc) => {
                if (nonOcc.DeathCity < 0) { deathRecordsFilled = false; }
                if (nonOcc.DeathState < 0) { deathRecordsFilled = false; }
                if (nonOcc.DeathCertNumber < 0) { deathRecordsFilled = false; }
                if (nonOcc.DiedScene < 0) { deathRecordsFilled = false; }
                if (nonOcc.DthDay < 1) { deathRecordsFilled = false; }
                if (nonOcc.DthHr < 0) { deathRecordsFilled = false; }
                if (nonOcc.DthMin < 0) { deathRecordsFilled = false; }
                if (nonOcc.DthMon < 1) { deathRecordsFilled = false; }
                if (nonOcc.DthYr < 1) { deathRecordsFilled = false; }
                if (nonOcc.InjurWrk < 0) { deathRecordsFilled = false; }
            });

            //Occupants check
            accObj.Veh.forEach((vehicle) => {
                vehicle.Occupants.forEach((occupant) => {
                    if (occupant.DeathCity < 0) { deathRecordsFilled = false; }
                    if (occupant.DeathState < 0) { deathRecordsFilled = false; }
                    if (occupant.DeathCertNumber < 0) { deathRecordsFilled = false; }
                    if (occupant.DiedScene < 0) { deathRecordsFilled = false; }
                    if (occupant.DthDay < 1) { deathRecordsFilled = false; }
                    if (occupant.DthHr < 0) { deathRecordsFilled = false; }
                    if (occupant.DthMin < 0) { deathRecordsFilled = false; }
                    if (occupant.DthMon < 1) { deathRecordsFilled = false; }
                    if (occupant.DthYr < 1) { deathRecordsFilled = false; }
                    if (occupant.InjurWrk < 0) { deathRecordsFilled = false; }
                });
            });
        }
        catch (ex) {
            deathRecordsFilled = false;
            console.error(ex);
        }

        return deathRecordsFilled;
    }

    public alcDrugFilledCheck(accObj) {
        let alcDrugFilled = true;

        try {
            //Non_Occupants & NonOccDrug
            if (accObj.Non_Occupants) {
                accObj.Non_Occupants.forEach((nonOcc) => {
                    if (nonOcc.AlcRes < 0) { alcDrugFilled = false; }
                    if (nonOcc.AlcSts < 0) { alcDrugFilled = false; }
                    if (nonOcc.AlcTst < 0) { alcDrugFilled = false; }
                    if (nonOcc.DrugSts < 0) { alcDrugFilled = false; }

                    if (nonOcc.NonOccDrug) {
                        nonOcc.NonOccDrug.forEach((nonOccD) => {
                            if (nonOccD.DrugRes < 0) { alcDrugFilled = false; }
                            if (nonOccD.DrugTst < 0) { alcDrugFilled = false; }
                        });
                    }
                });
            }

            //Occupants & OccDrug
            if (accObj.Veh) { //An accident should always involve at least one vehicle, but just in case, we should handle the case when there is no vehicle involved
                accObj.Veh.forEach((vehicle) => {
                    vehicle.Occupants.forEach((occupant) => {
                        if (occupant.AlcRes < 0) { alcDrugFilled = false; }
                        if (occupant.AlcSts < 0) { alcDrugFilled = false; }
                        if (occupant.AlcTst < 0) { alcDrugFilled = false; }
                        if (occupant.DrugSts < 0) { alcDrugFilled = false; }

                        if (occupant.OccDrug) {
                            occupant.OccDrug.forEach((occD) => {
                                if (occD.DrugRes < 0) { alcDrugFilled = false; }
                                if (occD.DrugTst < 0) { alcDrugFilled = false; }
                            });
                        }
                    });
                });
            }
        }
        catch (ex) {
            alcDrugFilled = false;
            console.error(ex);
        }

        return alcDrugFilled;
    }

    public alcDrugSavedNotify(accObj) {
        this.alcDrugSaved.emit(accObj);
    }

    //added by khem for make this accessable to all case level
    validationSeedData() {
        this._utilService.getCrashAvoidCriteria.subscribe(result => {
            this.validationRules = result;
        })
    }

    validateRules(acc: Acc, veh: Veh) {
        let make = veh.Make;
        let model = veh.Model;
        let modelYear = veh.ModelYr;
        let bodyType = veh.Body;
        let isFoundMatch: boolean = false;
        if (this.validationRules == null) {
            this._utilService.setCrashAvoidCriteria();
            this.validationSeedData();
        }
        if (make == 54 && model == 40 && bodyType == 5 && modelYear == 2013) {
            isFoundMatch = true;
        } else {
            if (veh) {

                if (this.validationRules) {
                    let matchValidations = this.validationRules.filter(x => x.ModelYear == modelYear && x.MakeId == make && x.ModelID == model);

                    if (matchValidations != undefined && matchValidations != null) {
                        if (matchValidations.length == 1 && matchValidations[0].Vin == 'all') {
                            isFoundMatch = true;
                        } else {

                            if (veh.VIN) {
                                const vehicleVin = veh.VIN.toUpperCase().substring(3, 8);
                                const matchTheVin = matchValidations.find(x => x.Vin == vehicleVin);
                                if (matchTheVin) {
                                    isFoundMatch = true;
                                }
                            }

                        }
                    }
                }
            }
        }
        return isFoundMatch;
    }

    sendMessage(veh: Veh) {
        this.sendAvoidanceEmail(veh).then(result => {
            console.log('result', result);
        })
    }

    //airbag fatality
    fatalityCounter(acc: Acc): number {
        let i = 0;
        /*if (this.acc.Non_Occupants) {
            let countLength = this.acc.Non_Occupants.filter(x => x.Injury == 4).length;
            i = i + countLength;
        }*/
        if (acc.Non_Occupants.length > 0) {
            acc.Non_Occupants.forEach((item) => {
                if (item && item.Injury == 4) {
                    i = i + 1;
                }
            });
        }
        acc.Veh.forEach((item) => {
            if (item && item.Occupants) {
                item.Occupants.forEach((person) => {
                    if (person.Injury == 4) {
                        i = i + 1
                    }
                })
            }
        });
        return i;
    }

    sendAirbagFatalityMessage(veh: Veh) {
        this.sendAirbagFatalityEmail(veh).then(result => {
            if (result) {
                //  this._success.next("Airbag Fatality Notification Email has been sent successfully.");
            } else {
                // this._success.next("Failed to send Airbag Fatality Notification Email.");
            }
        })
    }

    validateAirbagFatality(acc: Acc, veh: Veh) {
        if (!veh.Occupants) return false;
        let body = veh.Body;
        if (body == 0 || body == -1) return false;
        let hasFatality = false;
        if (this.fatalityCounter(acc) == 1) {

            console.log('I am here')
            veh.Occupants.forEach((item) => {
                if (!hasFatality) {
                    if (body < 50 && item.Injury == 4 && item.PType == 1 && item.SeatPos == 11 && (item.Age >= 1 && item.Age < 13)) {
                        hasFatality = true;
                    }
                }
            })
        }
        return hasFatality

    }
    //end of khem added 10/21/2019


    public isLongLatStatusVerified(objLongLat: LongLatInfo): boolean {
        if (!objLongLat.Verified) {
            alert("Long Lat isn\'t verified please verify, Long Lat before procced.");
        }
        return objLongLat.Verified;
    }

    public get getIntObsCaseNumber(): Observable<number> {
        return this.intObsCaseNumber.asObservable();
    }

    public get getStrObsCaseNumber(): Observable<string> {
        return this.strObsCaseNumber.asObservable();
    }

    public SaveDeletedCases(item: DeletedCases): Observable<DeletedCases> {
        this._baseService.actionURL = "api/case/SaveDeletedCases/";
        return this._baseService.updateEntity(item);
    }

    //#region Date Validations

    //KM: Validations for Date and Time
    public dateTimeValidations(acc: Acc, tab: string, intYear: number) {
        let error = "";
        let isFuture: boolean = false;
        let isValid: boolean = false;

        let cDate = acc.AccMon.toString().padStart(2, '0') + "/" + acc.AccDay.toString().padStart(2, '0') + "/" + acc.CaseYear.toString();
        let cTime = acc.AccHr.toString().padStart(2, '0') + "" + acc.AccMin.toString().padStart(2, '0');
        let nTime = acc.NotHr.toString().padStart(2, '0') + "" + acc.NotMin.toString().padStart(2, '0');//Notification
        let aTime = acc.ArrHr.toString().padStart(2, '0') + "" + acc.ArrMin.toString().padStart(2, '0');//Arrival
        let hTime = acc.HospHr.toString().padStart(2, '0') + "" + acc.HospMin.toString().padStart(2, '0');//Hospital

        if (!ObjectUtil.usDate(cDate))//Check for valid date eg: 11/30/2019 and not 11/31/2019
            isValid = true;

        if (isValid)
            //isFuture = ObjectUtil.blnIsDateTimeInTheFuture(acc.CaseYear, acc.AccMon, acc.AccDay, acc.AccHr, acc.AccMin, intYear, acc.Mode); //Check if Crash Date is in the future
            isFuture = ObjectUtil.blnIsDateTimeInTheFuture(acc.CaseYear, acc.AccMon, acc.AccDay, acc.AccHr, acc.AccMin, intYear); //Check if Crash Date is in the future               

        //Validate Date   -- enable this in Jan 2020   
        if (isFuture && tab == "crash")
            error = "Crash Date cannot be in the future.";
        else
            //Crash Date/Time
            if (tab == "crash") {
                if (acc.AccMon < 0 || acc.AccDay < 0)
                    error = "Crash Date is a required field";
                else if (acc.AccMon < 0 || acc.AccDay < 0)
                    error = "Crash Date must have valid values.";
                else if (acc.AccMon > 0 && acc.AccDay > 0 && (!isValid))
                    error = "Crash Date is not valid.";
                else if (acc.AccHr < 0 || acc.AccMin < 0)
                    error = "Crash Time is a required field";
                else if ((acc.AccHr < 0 && acc.AccMin > 0) || (acc.AccHr > 0 && acc.AccMin < 0))
                    error = "Crash Time must have valid values.";
                else if (cTime == "9999")
                    error = "";
            }
        if (tab == "other") {
            //Notification Time
            if ((acc.NotHr < 0 && acc.NotMin > 0) || (acc.NotHr > 0 && acc.NotMin < 0))
                error = "Notification Time EMS must have valid values.";
            else if ((nTime == "8888" || nTime == "9998" || nTime == "9999" || nTime == "-1-1" || nTime == "0000"))
                error = "";
            else if ((acc.NotHr < 0 || acc.NotHr > 24) && (acc.NotHr != 88) && (acc.NotHr != 99))
                return "Notification Hour should be between 0 and 24";
            else if ((acc.NotMin < 0 || acc.NotMin > 59) && (acc.NotMin != 88) && (acc.NotMin != 98) && (acc.NotMin != 99))
                return "Notification Minutes should be between 0 and 60 or 99 for unknown";

            //Arrival Time
            if ((acc.ArrHr < 0 && acc.ArrMin > 0) || (acc.ArrHr > 0 && acc.ArrMin < 0))//Either Arrival Time EMS Hour or Minute is blank
                error += " Arrival Time EMS must have valid values.";
            else if (aTime == "8888" || aTime == "9997" || aTime == "9998" || aTime == "9999" || aTime == "-1-1" || aTime == "0000")
                error += "";
            else if ((acc.ArrHr < 0 || acc.ArrHr > 24) && (acc.ArrHr != 88) && (acc.ArrHr != 99))
                return "Arrival Hour should be between 0 and 24";
            else if ((acc.ArrMin < 0 || acc.ArrMin > 59) && (acc.ArrMin != 88) && !(acc.ArrMin > 96 && acc.ArrMin < 100))
                return "Arrival Minutes should be between 0 and 60 or 99 for unknown";

            //Hospital Time
            if ((acc.HospHr < 0 && acc.HospMin > 0) || (acc.HospHr > 0 && acc.HospMin < 0))// Either EMS Time at Hospital Hour or Minute is blank
                error += " EMS Time at Hospital must have valid values.";
            else if (hTime == "8888" || hTime == "9996" || hTime == "9997" || hTime == "9998" || hTime == "9999" || hTime == "-1-1" || hTime == "0000")
                error += "";
            else if ((acc.HospHr < 0 || acc.HospHr > 24) && (acc.HospHr != 88) && (acc.HospHr != 99))
                return "EMS Time at Hospital Hour should be between 0 and 24";
            else if ((acc.HospMin < 0 || acc.HospMin > 59) && (acc.HospMin != 88) && !(acc.HospMin > 95 && acc.HospMin < 100))
                return "EMS Time at Hospital Minutes should be between 0 and 60 or 99 for unknown";

        }
        return error;
    }

    /**
     * Once the user releases their record lock, the cached data model is stale.
     * If user chooses to immediately return to case they just closed, we want 
     * to guarantee that there is no cached hit by clearing the cache, in other
     * words we want user to re-obtain the case lock and re-read the case.
     **/
    clearAccMemoryForCloseCase() {
        this._acc = null;
        this.hideNavForCloseCase.next(false);
    }
    //#endregion

    public ValidateOtherSpecifyObject(objAcc: Acc): boolean {
        if (objAcc && objAcc.Element_Specify.some(i => i.SpecifyValue == "" || i.SpecifyValue == null)) {
            alert('Other specify should be filled out');
            return false;
        }
        return true;
    }

    async IsValidateMOSSCaseNumber(mossNum: string, isCreatCaseMode: boolean = false, sciSiteID: number, allowedNumber) {
        let errMsg = "";
        let dmymossNum = mossNum.toUpperCase();
        let blnIsValidMossNum: boolean = true;

        let numberList = allowedNumber.split('|')
        let message = 'MCR23001, MCR22001, MCR21001, MCR20001, MCR19001';

        if (sciSiteID == 1) {
            let str = `^(MCR)(${allowedNumber})[0-9]{3}`;
            numberList.forEach((item,index) => {
                numberList[index] = 'MCR' + item + '001';
            })
            message = numberList.join(',');
            blnIsValidMossNum = new RegExp(str).test(dmymossNum);
            errMsg = 'Please enter valid MOSS Case Number. Ex: ' + message;
        }
        if (sciSiteID == 2) {
            let str = `^(MCR)(${allowedNumber})[0-9]{3}`;
            numberList.forEach((item, index) => {
                numberList[index] = 'MCR' + item + '001';
            })
            blnIsValidMossNum = new RegExp(str).test(dmymossNum);
            errMsg = 'Please enter valid MOSS Case Number. Ex: ' + numberList.join(',');
        }
        if (sciSiteID == 3) {
            let str = `^(MDS)(${allowedNumber})[0-9]{3}`;
            numberList.forEach((item, index) => {
                numberList[index] = 'MDS' + item + '001';
            })
            blnIsValidMossNum = new RegExp(str).test(dmymossNum);
            errMsg = 'Please enter valid MOSS Case Number. Ex: ' + numberList.join(',');
        }
        if (!blnIsValidMossNum) {
            return errMsg;
        }

        errMsg = await this.IsMossNumberAlreadyExist(mossNum, isCreatCaseMode);
        return errMsg;
    }

    public async IsMossNumberAlreadyExist(mossNum: string, isCreatCaseMode: boolean = false): Promise<string> {
        let errMsg = "";
        let dmymossNum = mossNum.toUpperCase();
        return new Promise<string>(((resolve, reject) => {
            try {
                this.ChkMossCaseNumberPromise(dmymossNum, 0, isCreatCaseMode).then(
                    ((result: boolean) => {
                        if (result) {
                            errMsg = 'The Moss case number ' + dmymossNum.toString() + ' already exists';
                        }
                        resolve(errMsg);
                    }).bind(this),
                    ((objError: any) => {
                        console.log(objError);
                        reject(objError);
                    }).bind(this)
                );
            } catch (ex) {
                reject(ex);
            }
        }).bind(this));
    }

    //public async IsMossNumberAlreadyExist(mossNum: string, frmCrashTab:boolean): Promise<string> {
    //    let errMsg = "";
    //    let dmymossNum = mossNum.toUpperCase();
    //    return new Promise<string>(((resolve, reject) => {
    //        try {
    //            this.GetCaseByMossCaseNumberPromise(dmymossNum, 0).then(
    //                ((objAcc: Acc) => {
    //                    if (objAcc) {
    //                        errMsg = 'The Moss case number ' + dmymossNum.toString() + ' already exists';
    //                    }
    //                    resolve(errMsg);
    //                }).bind(this),
    //                ((objError: any) => {
    //                    console.log(objError);
    //                    reject(objError);
    //                }).bind(this)
    //            );
    //        } catch (ex) {
    //            reject(ex);
    //        }
    //    }).bind(this));
    //}


    // Moh : 4-17-2020 : 8
    // Moh : 4-18-2020 : 8

    // fGetStratum:  Calculate CRSS Strata Final
    public CalculateFinalStramtum(objAcc: Acc): number {
        let a: number = null;
        let x: number = (a || 5) + 1;
        let strata: number = -1;

        let caseYear: number = objAcc.CaseYear;

        const arrNonMotoristPTypes = [4, 5, 6, 7, 8, 10, 19];
        const injuries = [1, 2, 3, 4, 5]; //At least some kind of injury present

        //console.log(objAcc);

        // -- NiTS crashes: Motor vehicle crashes occurring off trafficway involving at least one person who was injured or killed.
        if (objAcc.Mode == DBMode.NITS) {
            if (objAcc.Veh.find(v => v.Occupants.find(o => injuries.includes(o.Injury)) !== undefined)
            ) {
                return strata = 1;
            }
        }
        // -- Go on with CRSS Stratification
        else if (objAcc.Mode == DBMode.CRSS) {
            //NOTICE: Do not reorder the IF statements as that would change the precedence of Strata in case multiple IF statements are satisfied.

            //-- Stratum 2 - Not a MV Occupant & any Injury
            if (objAcc.Non_Occupants.find(x => arrNonMotoristPTypes.includes(x.PType) && injuries.includes(x.Injury))) {
                return strata = 2;
            }

            //--Stratum 3 - Motor Cycle & any injury            
            if (objAcc.Veh.find(v =>
                v.UnitType == 1 &&
                v.Body >= 80 && v.Body <= 89 &&
                v.Occupants.find(o => (o.PType == 1 || o.PType == 2 || o.PType == 9) && injuries.includes(o.Injury)) !== undefined)) {
                return strata = 3;
            }

            //--Stratum 4 - Late Model Year (LMY) Passenger Vehicle in Transport  with killed or seriously injured occupant
            if (objAcc.Veh.find(v =>
                v.UnitType == 1 &&
                v.Body >= 1 && v.Body <= 49 &&
                v.ModelYr != -1 && v.ModelYr != 9999 && caseYear - v.ModelYr <= 4 && //To qualify for LMY, model year cannot be unknown. Veh.ModelYr is not nullable.
                v.Occupants.find(o => (o.Injury == 3 || o.Injury == 4)) != undefined)) {
                return strata = 4;
            }

            //--Stratum 5 - Non-Late Model Year (NLMY) passenger vehicle in Transport with killed or seriously injured occupant
            if (objAcc.Veh.find(v =>
                v.UnitType == 1 &&
                v.Body >= 1 && v.Body <= 49 &&
                v.ModelYr != -1 && (caseYear - v.ModelYr >= 5 || v.ModelYr == 9999) && //To qualify for NLMY, model year may be unknown. Veh.ModelYr is not nullable.
                v.Occupants.find(o => (o.Injury == 3 || o.Injury == 4)) !== undefined)) {
                return strata = 5;
            }

            //--Stratum 6 - LMY Passenger Vehicle in Transport with injured occupant
            if (objAcc.Veh.find(v =>
                v.UnitType == 1 &&
                v.Body >= 1 && v.Body <= 49 &&
                v.ModelYr != -1 && v.ModelYr != 9999 && caseYear - v.ModelYr <= 4 && //To qualify for LMY, model year cannot be unknown. Veh.ModelYr is not nullable.
                v.Occupants.find(o => (o.Injury == 1 || o.Injury == 2 || o.Injury == 5)) !== undefined)) {
                return strata = 6;
            }

            //--Stratum 7 - Medium or heavy truck or bus in Transport  with GVWR equal or greater than 10,001 pounds is involved, is it a contract Vehicle
            if (objAcc.Veh.find(v => v.UnitType == 1 && v.Body >= 50 && v.Body <= 79)) { //Medium or heavy bus or truck body type implies GVWR greater than 10,000 pounds - we are no longer checking Veh.GVWR which has been replaced by Veh.VPICGVWR
                return strata = 7;
            }

            //-- Stratum 8 - Non-Late Model Year (NLMY) passenger vehicle in Transport with injured occupant
            if (objAcc.Veh.find(v =>
                v.UnitType == 1 &&
                v.Body >= 1 && v.Body <= 49 &&
                v.ModelYr != -1 && (caseYear - v.ModelYr >= 5 || v.ModelYr == 9999) && //To qualify for NLMY, model year may be unknown. Veh.ModelYr is not nullable.
                v.Occupants.find(o => (o.Injury == 1 || o.Injury == 2 || o.Injury == 5)) !== undefined)) {
                return strata = 8;
            }

            //--Stratum 9 - Late Model Year (LMY) passenger vehicle in Transport AND no occupant in the crash (in any in-traffic vehicle) is killed or injured. Reminder: non-occupant injuries are covered by strata 2.
            if (objAcc.Veh.find(v =>
                v.UnitType == 1 &&
                v.Body >= 1 && v.Body <= 49 &&
                v.ModelYr != -1 && v.ModelYr != 9999 && caseYear - v.ModelYr <= 4) && //To qualify for LMY, model year cannot be unknown. Veh.ModelYr is not nullable.
                !objAcc.Veh.find(v =>
                    v.UnitType == 1 &&
                    v.Occupants.find(o => injuries.includes(o.Injury) || o.Injury == -1) !== undefined)) { //Occupant with blank (-1) Injury Severity means case is still being coded and we cannot say with certainty there are no occupant injuries, in other words, assume that an occupant is injured
                return strata = 9;
            }


            //--Check if any of the required fields are blanks - before making the strata to 10
            if (objAcc.Veh.find(v => v.UnitType == -1 || v.Body == -1 || v.ModelYr == -1)) {
                return strata = -1;
            }
            if (objAcc.Veh.find(v => v.Occupants.find(o => o.Injury == -1 || o.PType == -1) !== undefined)) {
                return strata = -1;
            }
            if (objAcc.Non_Occupants.find(x => x.Injury == -1 || x.PType == -1)) {
                return strata = -1;
            }

            return strata = 10;
        }

        return strata;
    }

    //Moh: 11-27-2020
    public CheckMOSSCaseNum(objAcc: Acc, blnSave: boolean = false): boolean {
        if (objAcc.EarlyNotify != null && objAcc.EarlyNotify.length > 0) {
            if (objAcc.Mode == DBMode.MOSS) {
                if (objAcc.EarlyNotify[0].MOSSCasenum != objAcc.Acc_SS.MOSSCasenum) {
                    objAcc.EarlyNotify[0].MOSSCasenum = objAcc.Acc_SS.MOSSCasenum;
                    return true;
                }
            }
        }
    }

    //Moh: 04-20-2020
    public CheckFinalStramtum(objAcc: Acc, blnSave: boolean = false): boolean {
        if (objAcc.EarlyNotify != null && objAcc.EarlyNotify.length > 0) {
            if (objAcc.Mode == DBMode.CRSS) {
                let finalStrata: number = this.CalculateFinalStramtum(objAcc);

                if (finalStrata == -1) { //TODO: Where is the requirement to not apply Final Strata -1 when case qualifies for no strata (Bugs 8917 and 9293 do not specify this behavior, so where is this from)?
                    // Do nothing
                }
                else {
                    if (objAcc.EarlyNotify[0].CategoryFinal != finalStrata) {
                        objAcc.EarlyNotify[0].CategoryFinal = finalStrata;
                        return true;
                    }
                }
            }
        }
        return false
    }

    // Moh: 06-08-2020
    public GetTabDisplayStatus(objAcc: Veh = null) {
        const bodyTypes = [60, 61, 62, 64, 65, 66, 67];
        let displayStatus: boolean = false;

        if (bodyTypes.indexOf(objAcc.Body) > -1) {
            displayStatus = true
        }
        this._isShowTabSubject.next(displayStatus)
    }

    //// Moh : 6-08-2020
    //@Output() showTab: EventEmitter<any> = new EventEmitter();

    //getCheckTabEmittedValue() {
    //    return this.showTab;
    //}

    //// Moh : 5-21-2020
    //CheckTabDisplay(objAcc: Veh = null) {
    //    const bodyTypes = [60, 61, 62, 64, 65, 66, 67];

    //    if (bodyTypes.indexOf(objAcc.Body) > -1) {
    //        this.showTab.emit(false);
    //    }
    //    //for (var i = 0; i < bodyTypes.length; i++) {
    //    //    if (bodyTypes[i] == this.veh.Body)  return false; 
    //    //}
    //    this.showTab.emit(true);
    //}

    public CheckAndSetEDTCaseStatus(objAcc: Acc = null) {
        //Acc.StateCaseId is populated exclusively by EDT logic and indicates whether case is EDT or not EDT
        if ((objAcc.StateCaseID != null) && (objAcc.Mode == DBMode.FARS) &&
            ((objAcc.Status == EDTStatus.New) || (objAcc.Status == EDTStatus.Reviewed) || (objAcc.Status == EDTStatus.Updated))) {
            this._userPreferenceService.isThisPreferenceOn(Preference.EDTStatusLocked).then(item => {
                if (item) {
                    objAcc.Status = EDTStatus.Locked
                };
            });
        }
    }

    //save Early Notification 
    public SaveEarlyNotification(item: EarlyNotify): Observable<EarlyNotify> {
        this._baseService.actionURL = "api/earlyNotification/SaveEarlyNotification/";
        return this._baseService.updateEntity(item);
    }

    /* Starting of CRSS */
    public CheckCrssCaseStates(parId: number, stateNum: number, year: number, searchType = 'regular'): Promise<EarlyNotify> {
        const tmpParId = parId;
        stateNum = -1;
        this._baseService.actionURL = "api/case/CheckCrssCaseStates/" + parId + "/" + year + "/" + stateNum + "/" + searchType;
        return this._baseService.getDataPromise();
    }


    public setIntObsCaseNumber(caseNumber) {
        this.intObsCaseNumber.next(caseNumber);
    }


    /* End Of CRSS */
}
