<span>
    <button id="btnSpeedCalculator_Open" class="btn btn-sm btn-light border-secondary" (click)="open(content, null)" #btnOpen>Speed Calculator</button>
</span>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Speed Calculator</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');onCancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <p class="border-bottom border-light aliceblue">This window allows you to enter Kilometers per hour and returns the corresponding speed in miles per hour.</p>
        </div>
        <div class="col-12">
            <p *ngIf="warning!=''" class="alert-danger">
                {{warning}}
            </p>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-9">
                    <!--<label class="align-self-center">Please enter value</label>-->
                    <div class="row">
                        <div class="col-4 col-sm-4">
                            <input type="text" [(ngModel)]="speed"
                                   class="form-control"
                                   id="convertorValue"
                                   name="SpeedCalculator"
                                   placeholder="Km/Hr" />
                        </div>
                        <div class="col-4 col-sm-4">
                            <button type="button" class="btn btn-light border-secondary" (click)="unitChange()">Convert</button>
                        </div>
                        <div class="col-4 col-sm-4">
                            <input class="form-control" type="text" name="result"
                                   disabled [value]="result" placeholder="Mile/Hr" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-primary" (click)="onSubmit(efSpeed)">Ok</button>
        <button type="button" class="btn btn-light border-secondary" (click)="modal.close('Cancel click');onCancel()">Cancel</button>
        <button type="button" class="btn btn-light border-secondary" (click)="onClear()">Clear</button>
    </div>

</ng-template>
