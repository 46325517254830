<div *ngIf="vehicle" class="border-left border-bottom border-right bg-light">
    <ul class="nav nav-tabs border-dark border-left">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./sfrvehicle"]'
               [ngClass]="{'bg-light': linkSFRVehicle.isActive !== true }"
               [routerLinkActive]="['active']" #linkSFRVehicle="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkSFRVehicle.isActive === true, 'fw-bold' : linkSFRVehicle.isActive === true}">
                        <!--SFRVehicle_V{{vehicleid}}-->
                        Struck_Subject_Vehicle_V{{vehicle.Veh_SS.SSVNumber}}
                    </span>
                </span>
            </a>
        </li>

        <li class="nav-item mt-1" *ngFor="let link of vehicle.Occupants">
            <span *ngIf="link">
                <a class="nav-link" [routerLink]='["./sfrperson", link.PNumber]'
                   [ngClass]="{'bg-light': sfrlinkperson.isActive !== true }"
                   [routerLinkActive]="['active']" #sfrlinkperson="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': sfrlinkperson.isActive === true, 'fw-bold' : sfrlinkperson.isActive === true}">
                            <!--SFRPerson_{{ link.PNumber }}_V{{ link.VNumber}}-->
                            Struck_Subject_Occupant_{{ link.PNumber }}_V{{ vehicle.Veh_SS.SSVNumber}}
                            <i class="fas fa-asterisk pl-2" *ngIf="link.PolInjSevr === 5"></i><span class="text-decoration-none" *ngIf="link.CreatedRecord > 0">_CR</span>
                        </span>
                    </span>
                </a>
            </span>
        </li>
    </ul>
    <!--here we generate and show Vehicle Vehicle's-children html's components-->
    <div class="tab-content">
        <router-outlet></router-outlet>
    </div>
</div>
