<form novalidate #fvpalcohol="ngForm">
    <div class="pt-2" *ngIf="person && intMode">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PlcRprtdAlcPrnce" name="PlcRprtdAlcPrnce" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="PlcRprtdAlcPrnce" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <!--<div *ngIf="intMode == 1" [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="methAlc" name="methAlc" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="MethAlc" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>-->
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="alcTst" name="alcTst" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="AlcTst" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="alcRes" name="alcRes" typeAheadPosition="right-top"
                                   [objModel]="person" #alcTestResult (keyup)="handleKeyup($event)" strTableName="Occupants" strFieldName="AlcRes" [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData"
                                   [blnFormatListOnlyShowText]="true">
                    </app-typeahead>
                </div>
                
            </div>
            <div class="row" *ngIf="!printOnly">
                <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                    <app-modals-alcohol-calculator #alcElement strForm="PersonAlcohol" (submitAlcoholValue)="setAlcoholValue($event)" *ngIf="!blnReadOnly" [hideResult]="true"></app-modals-alcohol-calculator>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PlcRprtdAlcPrnce" name="PlcRprtdDrgPrnce" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="PlcRprtdDrgPrnce" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="DrgTstRslt" name="DrgTstRslt" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="DrgTstRslt" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"  [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-typeahead>
                </div>
            </div>
            <!--<div *ngIf="!hideDrugInvolvement" [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
        <div class="form-group pl-2">
            <app-typeahead id="drugInv"
                           name="drugInv"
                           typeAheadPosition="right-top"
                           [objModel]="person"
                           strTableName="Occupants"
                           strFieldName="DrugInv"
                           [printOnly]="printOnly"
                           [showAllDataListForPrintOnly]="false"
                           [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->
        </div>
    </div>
</form>
