import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ActivatedRoute } from '@angular/router';
import { Acc } from 'src/app/models/acc';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { AutofillService } from 'src/app/services/autofill.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-non-occupant-notes',
    templateUrl: './non-occupant-notes.component.html',
    styleUrls: ['./non-occupant-notes.component.scss']
})

export class NonOccupantNotesComponent extends BaseComponent implements OnInit, AfterViewInit {
    acc: Acc;
    nonOccupant: Non_Occupants;

    accid: number;
    nonOccid: number;
    intMode: number;

    private sbsNonOccId: Subscription;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);

    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngOnInit() {
        super.ngOnInit();

        this._sharedService.GetAppSettings().then((appSetting: AppSettings) => {
            this.intMode = appSetting.intMode;
        })
        
        if (this.printNonOccupantNum == 0) {
            this._route.parent.parent.params.subscribe((params => {
                this.accid = + params['caseid'];
            }).bind(this));
        }
        else {
            this.accid = this.acc.AccID;
        }

        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
        }

    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    FormLoad() {
        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);
    }
}
