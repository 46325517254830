<form novalidate #fsfrvv="ngForm">
    <div id="sfrvehicle-vehicle" class="pt-2">

        <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
            <div class="form-group pl-2">
                <app-typeahead id="NumOccs" name="NumOccs"
                               typeAheadPosition="right-top" (bindModel)="_autofillService.OnCreatedRecordPrerequisitesChange(veh, 'NumOccs', $event, VehClone)"
                               [objModel]="veh" strTableName="Veh" strFieldName="NumOccs"
                               [printOnly]="printOnly"
                               [showAllDataListForPrintOnly]="false"
                               [clearFieldData]="clearFieldData"
                               disabled="true">

                </app-typeahead>
            </div>
        </div>


        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="spUse" name="spUse" typeAheadPosition="right-top"
                                   [objModel]="veh" strTableName="Veh" strFieldName="SpUse"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-typeahead>
                </div>
            </div>
        </div>
               
        <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
            <div class="form-group pl-2">
                <app-typeahead id="ConsMaintVeh" name="ConsMaintVeh" typeAheadPosition="right-top" searchAll="true"
                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="ConsMaintVeh" [printOnly]="printOnly"
                               [showAllDataListForPrintOnly]="false"
                               [clearFieldData]="clearFieldData">
                </app-typeahead>
            </div>
        </div>
        <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
            <div class="form-group pl-2">
                <app-typeahead id="TowRcvryVeh" name="TowRcvryVeh" typeAheadPosition="right-top" searchAll="true"
                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="TowRcvryVeh" [printOnly]="printOnly"
                               [showAllDataListForPrintOnly]="false"
                               [clearFieldData]="clearFieldData">
                </app-typeahead>
            </div>
        </div>
        
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RespdAgencyType" name="RespdAgencyType" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="RespdAgencyType"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RespdEmerVehType" name="RespdEmerVehType" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="RespdEmerVehType"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>        

        <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
            <div class="form-group pl-2">
                <app-typeahead id="TowRcvryVeh" name="MrkdLawEnfVeh" typeAheadPosition="right-top" searchAll="true"
                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="MrkdLawEnfVeh" [printOnly]="printOnly"
                               [showAllDataListForPrintOnly]="false"
                               [clearFieldData]="clearFieldData">
                </app-typeahead>
            </div>
        </div>
        <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
            <div class="form-group pl-2">
                <app-typeahead id="MrkdFireEmsVeh" name="MrkdFireEmsVeh" typeAheadPosition="right-top" searchAll="true"
                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="MrkdFireEmsVeh" [printOnly]="printOnly"
                               [showAllDataListForPrintOnly]="false"
                               [clearFieldData]="clearFieldData">
                </app-typeahead>
            </div>
        </div>
        <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
        <div class="form-group pl-2">
            <app-typeahead id="unitType" name="unitType"
                           [objModel]="veh" strTableName="Veh" strFieldName="UnitType" [printOnly]="printOnly"
                           [clearFieldData]="clearFieldData" (bindModel)="onUnitTypeChange($event)">
            </app-typeahead>
        </div>
    </div>-->
        <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
        <div class="form-group">
            <div class="row">
                <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                    <div class="pl-2">
                        <app-label label="TravSpd" [printOnly]="printOnly" inputName="TravSpd"></app-label>
                    </div>
                    <span class="my-auto" *ngIf="!printOnly">
                        <app-speed-calculator *ngIf="!blnReadOnly"
                                              [objVeh]="veh"
                                              objToUpdate="TravSpd"
                                              destUnits="Mph"
                                              (setSpeedEvent)="setTravelSpeed($event)">
                        </app-speed-calculator>
                    </span>
                </div>
                <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                    <div class="pl-2">
                        <app-typeahead id="travelspeed" name="travelspeed" typeAheadPosition="right-top"
                                       [objModel]="veh" strTableName="Veh" strFieldName="TravSpd"
                                       [printOnly]="printOnly" [disableLabel]="true" [blnAllowFreeText]="true"
                                       [clearFieldData]="clearFieldData" [showAllDataListForPrintOnly]="false">
                        </app-typeahead>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
        <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
        <div class="form-group pl-2">
            <app-typeahead id="underrideoverride" name="underrideoverride"
                           [objModel]="veh" strTableName="Veh" strFieldName="UdOvr" [printOnly]="printOnly"
                           [clearFieldData]="clearFieldData"
                           [intDisabledMode]="4">
            </app-typeahead>
        </div>
    </div>-->
        <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
        <div class="form-group pl-2">
            <app-typeahead id="leave" name="leave"
                           [objModel]="veh" strTableName="Veh" strFieldName="Leave" [printOnly]="printOnly"
                           [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->
        <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
        <div class="form-group pl-2">
            <app-typeahead id="mhEvent" name="mhEvent" typeAheadPosition="right"
                           [objModel]="veh" strTableName="Veh" strFieldName="MhEvent"
                           [printOnly]="printOnly"
                           [showAllDataListForPrintOnly]="false"
                           [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->
    </div>
</form>
