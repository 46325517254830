

//Vpic Vehicle Variable and VariableId
export enum VehicleElement {
    BatteryInfo = 1,
    BatteryType = 2,
    BedType = 3,
    BodyCabType = 4,
    BodyClass = 5,
    Country = 8,
    EngineCylinders = 9,
    DestinationMarket = 10,
    DisplacementCC = 11,
    DisplacementCI = 12,
    DisplacementL = 13,
    Doors = 14,
    DriveType = 15,
    DriverAssist = 16,
    EngineCycles = 17,
    EngineModel = 18,
    EngineKW = 21,
    EntertainmentSystem = 23,
    FuelTypePrimary = 24,
    GVWR = 25,
    Make = 26,
    Manufacturer = 27,
    Model = 28,
    ModelYear = 29,
    PlantCity = 31,
    Seats = 33,
    Series = 34,
    SteeringLocation = 36,
    TransmissionStyle = 37,
    Trim = 38,
    VehicleType = 39,
    Windows = 40,
    Axles = 41,
    BrakeSystemType = 42,
    NCICCode = 45,
    BatteryCells = 48,
    BedLengthIN = 49,
    BrakeSystemDesc = 52,
    CurbWeightLB = 54,
    AirBagLocCurtain = 55,
    AirBagLocSeatCushion = 56,
    BatteryA = 57,
    BatteryV = 58,
    BatteryKWh = 59,
    WheelBaseType = 60,
    SeatRows = 61,
    ValveTrainDesign = 62,
    TransmissionSpeeds = 63,
    EngineConfiguration = 64,
    AirBagLocFront = 65,
    FuelTypeSecondary = 66,
    FuelInjectionType = 67,
    AirBagLocKnee = 69,
    EngineHP = 71,
    EVDriveUnit = 72,
    PlantCountry = 75,
    PlantCompanyName = 76,
    PlantState = 77,
    Pretensioner = 78,
    SeatBeltsAll = 79,
    AdaptiveCruiseControl = 81,
    AdaptiveHeadlights = 82,
    ABS = 86,
    CIB = 87,
    BlindSpotMon = 88,
    CAFEBodyType = 89,
    CAFEMake = 90,
    CAFEModel = 91,
    NCAPBodyType = 92,
    NCAPMake = 93,
    NCAPModel = 94,
    NCSABodyType = 96,
    NCSAMake = 97,
    NCSAModel = 98,
    ESC = 99,
    TractionControl = 100,
    ForwardCollisionWarning = 101,
    LaneDepartureWarning = 102,
    LaneKeepSystem = 103,
    RearVisibilitySystem = 104,
    ParkAssist = 105,
    AirBagLocSide = 107,
    Artemis = 108,
    Trim2 = 109,
    Series2 = 110,
    WheelBaseShort = 111,
    WheelBaseLong = 112,
    Note = 114,
    Wheels = 115,
    TrailerType = 116,
    TrailerBodyType = 117,
    TrailerLength = 118,
    WheelSizeFront = 119,
    WheelSizeRear = 120,
    OtherRestraintSystemInfo = 121,
    CoolingType = 122,
    EngineHP_to = 125,
    ElectrificationLevel = 126,
    ChargerLevel = 127,
    ChargerPowerKW = 128,
    OtherEngineInfo = 129,
    BatteryA_to = 132,
    BatteryV_to = 133,
    BatteryKWh_to = 134,
    Turbo = 135,
    BasePrice = 136,
    BatteryModules = 137,
    BatteryPacks = 138,
    TopSpeedMPH = 139,
    EquipmentType = 140,
    ManufacturerType = 141,
    SuggestedVIN = 142,
    ErrorCode = 143,
    PossibleValues = 144,
    AxleConfiguration = 145,
    EngineManufacturer = 146,
    BusLength = 147,
    BusFloorConfigType = 148,
    BusType = 149,
    OtherBusInfo = 150,
    CustomMotorcycleType = 151,
    MotorcycleSuspensionType = 152,
    MotorcycleChassisType = 153,
    OtherMotorcycleInfo = 154,
    OtherTrailerInfo = 155,
    AdditionalErrorText = 156,
    ManufacturerId = 157,
    CashForClunkers = 158,
    TrackWidth = 159,
    TPMS = 168,
    ActiveSafetySysNote = 169,
    DynamicBrakeSupport = 170,
    PedestrianAutomaticEmergencyBraking = 171,
    AutoReverseSystem = 172,
    AutomaticPedestrianAlertingSound = 173,
    CAN_AACN = 174,
    EDR = 175,
    KeylessIgnition = 176,
    DaytimeRunningLight = 177,
    LowerBeamHeadlampLightSource = 178,
    SemiautomaticHeadlampBeamSwitching = 179,
    AdaptiveDrivingBeam = 180,
    SAEAutomationLevel = 181,
    SAEAutomationLevel_to = 182,
    RearCrossTrafficAlert = 183,
    GCWR = 184,
    GCWR_to = 185,
    NCSANote = 186,
    NCSAMappingException = 187,
    NCSAMapExcApprovedOn = 188,
    NCSAMapExcApprovedBy = 189,
    GVWR_to = 190,
    ErrorText = 191

}

export enum DamagedAreasOptions {
    NoDamage = 15,
    UnknownDamage = 99
}

export enum FormName {

    Crash = "Crash",
    Vehicle = "Vehicle",
    Driver = "Driver",
    PreCrash = "PreCrash",
    Occupant = "Occupant",
    NonOccupant = "NonOccupant",
    Ped = "Ped",
    Bike = "Bike",
    PII = "PII",
    EarlyNotify = "EarlyNotify"
}

export enum FormID {

    Crash = 1,
    Vehicle = 2,
    Driver = 3,
    PreCrash = 4,
    Occupant = 5,
    NonOccupant = 6,
    Ped = 11,
    Bike = 12,
    PII = 14,
    EarlyNotify = 15
}

export enum URLstrings {
    Case = 'case',
    Crash = 'crash',
    NonOccupant = 'nonOccupant',
    Vehicle = 'vehicle',
    Driver = 'driver',
    Precrash = 'precrash',
    Person = 'person',
    VehicleAvoidEquip = 'vehicleAvoidEquip',
    Ae = 'ae'
}

export enum CrashSubTab {

    Crash = 'crash',
    Events = 'events',
    Trafficway = 'trafficway',
    Other = 'other',
    Special = 'special',
    Crashnotes = 'crashnotes',
    NonMotorist = 'nonmotorist'
}

export enum NonOccupantSubTab {
    NonOccupant = 'nonOccupant',
    NonOther = 'nonOther',
    Bicyclist = 'bicyclist',
    Pedestrian = 'pedestrian',
    NonMotoristActions = 'nonMotoristActions',
    Alcohol = 'alcohol',
    Drug = 'drug',
    Race = 'race',
    Supplemental = 'supplemental',
    Injury = 'injury',
    NonOccNotes='nonOccNotes'
}

export enum VehicleSubTab {

    Vehicle = 'vehicle',
    DamagedAreas = 'damagedAreas',
    VIN = 'vin',
    VehicleDetails = 'vehicleDetails',
    VehicleOther = 'vehicleOther',
    VehicleTrailer = 'vehicleTrailer',
    Rollover = 'rollover',
    View = 'view',
    VehicleNotes = 'vehicleNotes',   
    VehicleVINDecode = 'vehicleVINDecode',
    VehNonMotorist = 'vehnonmotorist'
}

export enum SFRVehicleSubTab {

    Vehicle = 'sfrvehicle',
    VIN = 'sfrvin',
    Notes = 'sfrnotes',
    Other = 'sfrother',
    Control = 'sfrcontrol',
    Person = 'sfrperson',
    VehicleVINDecode = 'sfrvehicleVINDecode',
}

export enum StrikingVehicleSubTab {

    Vehicle = 'strikingvehicle',
    VIN = 'strikingvin',
    Notes = 'strikingnotes',
    VehicleVINDecode = 'strikingvehicleVINDecode',
}

export enum DriverSubTab {

    Driver = 'driver',
    License = 'license',
    Counters = 'counters',
    DriverNotes = 'driverNotes'
}

export enum PrecrashSubTab {

    Roadway = 'roadway',
    Precrash = 'precrash',
    PrecrashOther = 'precrashOther'    
}

export enum StrikingPrecrashSubTab {

    Roadway = 'strikingroadway',
    Precrash = 'strikingprecrash',
    PrecrashOther = 'strikingprecrashother'
}

export enum PersonSubTab {

    Person = 'person',
    SafetyDevices = 'safetyDevices',
    PersonAlcohol = 'personAlcohol',
    PersonDrug = 'personDrug',
    PersonRace = 'personRace',
    PersonSupplemental = 'personSupplemental',
    PersonNotes = 'personNotes'
}

export enum SFRPersonSubTab {

    Person = 'sfrperson',
    Safety = 'sfrsafety',
    PersonAlcohol = 'sfrpersonalcohol',   
    PersonNotes = 'sfrpersonnotes'
}
export enum StrikingPersonSubTab {

    Person = 'strikingperson',
    Safety = 'strikingsafety',
    PersonAlcohol = 'strikingpersonalcohol',
    Driver = 'strikingdriver',
    DriverNotes = 'strikingdrivernotes',
    PersonNotes = 'strikingpersonnotes'
}
//export enum StrikingDriverSubTab {

//    Driver = 'strikingdriver',
//    Person = 'strikingdriverperson',
//    Safety = 'strikingdriversafety',
//    PersonAlcohol = 'strikingdriveralcohol',
//    PersonNotes = 'strikingdrivernotes'
//}
export enum AvoidanceEquipmentSubTab {

    AvoidanceEquipment = 'avoidanceEquipment',
   
}

export enum ActionCommand {

    Save = "save",
    CheckCase = "checkCase"
}

export enum MessageType {

    Success = "success"
}

export enum Months {

    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12
}



export enum TableId {

    Acc = "Acc",
    AccRF = "AccRF",
    AccTrafID = "AccTrafID",
    CrashEvents = "CrashEvents",
    DamagedAreas = "DamagedAreas",
    Dri = "Dri",
    DriCondAtCrashTime = "DriCondAtCrashTime",
    DriPrevSus = "DriPrevSus",
    DriRF = "DriRF",
    Driver = "Driver",
    DriVIOL = "DriVIOL",
    Non_Occupants = "Non_Occupants",
    NonOcc_ActAtCrash = "NonOcc_ActAtCrash",
    NonOcc_ActPriorCrash = "NonOcc_ActPriorCrash",
    NonOcc_Bike = "NonOcc_Bike",
    NonOcc_CondAtCrashTime = "NonOcc_CondAtCrashTime",
    NonOcc_Distract = "NonOcc_Distract",
    NonOcc_Ped = "NonOcc_Ped",
    NonOcc_Race = "NonOcc_Race",
    NonOcc_Safety = "NonOcc_Safety",
    NonOccDrug = "NonOccDrug",
    NonOcc_Other = "NonOcc_Other",
    NonOccInjury = "NonOccInjury",
    NonOccRF = "NonOccRF",
    Occ_Race = "Occ_Race",
    OccDrug = "OccDrug",
    OccRF = "OccRF",
    Occupants = "Occupants",
    PII = "PII",
    PreCrash = "PreCrash",
    PreCrash_ContribCirc = "PreCrash_ContribCirc",
    PreCrash_DriAvoidManvr = "PreCrash_DriAvoidManvr",
    PreCrash_DriDistract = "PreCrash_DriDistract",
    PreCrash_DriVisObs = "PreCrash_DriVisObs",
    Trailer = "Trailer",
    Veh = "Veh",
    VehRF = "VehRF",
    VehSeqEvent = "VehSeqEvent",
    Veh_SSAvoidEquipAvail = "Veh_SSAvoidEquipAvail"
}

export enum KeyCode {
    ArrowDown = "ArrowDown",
    ArrowUp = "ArrowUp",
    ArrowLeft = "ArrowLeft",
    ArrowRight = "ArrowRight",
    Backspace = "Backspace",
    Delete = "Delete",
    Digit = "Digit",
    /**
     * This is a key code prefix, ex: Numpad1
     **/
    Numpad = "Numpad",
    NumpadAdd = "NumpadAdd",
    NumpadDecimal = "NumpadDecimal",
    NumpadDivide = "NumpadDivide",
    NumpadMultiply = "NumpadMultiply",
    Tab = "Tab",
    Shift = "Shift",
    Slash = "Slash",
    Enter = "Enter",
    NumpadEnter = "NumpadEnter",
    PageUp = "PageUp",
    PageDown = "PageDown",
    NumpadSubtract = "NumpadSubtract",
    Minus = 'Minus',
    F9 = 'F9',
    ShiftLeft = 'ShiftLeft',
    F5 = 'F5'
}

export enum DBMode {
    All = -1,
    FARS = 1,
    CRSS = 4,
    RECODE = 16,
    NITS = 32,
    MTSS = 64,
    PPSS = 128,
    MOSS = 256
}

export enum MossCISite {
    MOSS_SCI_Site_1 = 1,
    MOSS_SCI_Site_2 = 2,
    MOSS_SCI_Site_3 = 3
}

export enum RecodeSpecialCodes {
    R = -4,
    T = -2,
    X = -3,
    TT = 'T',
    RT = 'R',
    XT = 'X'
}

export enum States {
    select = 0,
    AL = 1,
    AK = 2,
    AZ = 4,
    AR = 5,
    CA = 6,
    CO = 8,
    CT = 9,
    DE = 10,
    DC = 11,
    FL = 12,
    GA = 13,
    HI = 15,
    ID = 16,
    IL = 17,
    IN = 18,
    IA = 19,
    KS = 20,
    KY = 21,
    LA = 22,
    ME = 23,
    MD = 24,
    MA = 25,
    MI = 26,
    MN = 27,
    MS = 28,
    MO = 29,
    MT = 30,
    NE = 31,
    NV = 32,
    NH = 33,
    NJ = 34,
    NM = 35,
    NY = 36,
    NC = 37,
    ND = 38,
    OH = 39,
    OK = 40,
    OR = 41,
    PA = 42,
    PR = 43,
    RI = 44,
    SC = 45,
    SD = 46,
    TN = 47,
    TX = 48,
    UT = 49,
    VT = 50,
    VA = 51,
    VI = 52,
    WA = 53,
    WV = 54,
    WI = 55,
    WY = 56,

}

export enum EDTStatus {
    Blank = -1,
    New = 1,
    Reviewed = 2,
    Conflict = 3,
    Updated = 4,
    Locked = 5,
    Final = 6,
    StructuralConflict = 7
}

export enum AreaOfImpact {
    Blank = -1,
    NonCollision = 0
}

export enum DriverPresence {
    NoDriver = 0,
    DriverPresent = 1,
    Unknown = 9
}

export enum EarlyNotifyType {
    FARS = 1
}

export enum Injury {
    NotSpecified = -1,
    SuspectedMinorInjury = 2,
    Fatal = 4,
    MOSSFatal = 5
}

export enum NonOccupantType {
    NotSpecified = -1,
    Pedestrian = 5,
    Bike = 6,
}

export enum OccupantType {
    NotSpecified = -1,
    Driver = 1,
}

export enum MannerOfCollision {
    NotCollisionWithVehicleInTransport = 0,
    FrontToRear = 1,
    FrontToFront = 2,
    Angle = 6,
    SideswipeSameDirection = 7,
    SideswipeOppositeDirection = 8,
    RearToSide = 9,
    RearToRear = 10,
    Other = 11,
    NotReported = 98,
    Unknown = 99
}

/**
 * Crash Events - Sequence of Events
 **/
export enum SequenceOfEvents {
    RailwayVehicle = 10,
    MotorVehicleInTransport = 12,
    ParkedVehicle = 14,
    InTransportContactNonVehicle = 54,
    InMotionOutsideTrafficway = 55,
    WorkVehicle = 45
}

/**
 * Vehicle Unit Type
 **/
export enum UnitType {
    Blank = -1,
    InTransport = 1,
    NotInTransport_InTrafficway = 2,
    NotInTransport_OffTrafficway = 3,
    WorkingMotorVehicle = 4
}
export enum DrugTestStatus {
    Blank = -1,
    TestNotGiven = 0,
    TestGiven = 2,
    NotReported = 8,
    ReportedAsUnknownIfTested = 9
}

export enum MailRequestType {
    VehicleRequest = 1,
    DriverRequest = 2,
    VehicleDriverRequest = 3,
    EMSDeathRequest = 4
}

export enum MailRequestGroup {
    Request = 1,
    Response = 2,
}

/**
 *Preference enum list has values of PreferenceKey on RBSDB.dbo.Preferences table.
 * */
export enum Preference {
    AutoFill = 'AutoFill',
    VehicleTableChecks = 'VehicleTableChecks',
    AutomaticOutOfStateMessageRequests = 'AutomaticOutOfStateMessageRequests',
    CountyCityCheck = 'CountyCityCheck',
    PromptForRecCreateDelete = 'PromptForRecCreateDelete',
    EditNumberofOccupants = 'EditNumberofOccupants',
    AgeCalculaterPopUp = 'AgeCalculaterPopUp',
    GeoLocatorPopUp = 'GeoLocatorPopUp',
    AlcoholConvPopup = 'AlcoholConvPopup',
    AllowprefillforPrevRecAdminPerSefields = 'AllowprefillforPrevRecAdminPerSefields',
    EDTStatusLocked = 'EDTStatusLocked',
    LatLongDMSFormat = 'LatLongDMSFormat'
}

export enum RBISDataValue {
    Blank = -1,
    Minus1 = '-1', // this is for the string  -1
    Empty = ''
}


export enum ExcludeMonthValue {
    NoRecord = 0,
    Unknown = 99
}

export enum ExcludeYearValue {
    NoRecord = 0,
    Unknown = 9999
}

export enum VehicleTrailing {
    Blank = -1,
    NoTrailingUnits = 0,
    OneTrailingUnit = 1,
    TwoTrailingUnits = 2,
    ThreeOrMoreTrailingUnits = 3,
    YesNumberOfTrailingUnitsUnknown = 4,
    VehicleTowingAnotherMotorVehicleFixedLinkage = 5,
    VehicleTowingAnotherMotorVehicleNonFixedLinkage = 6,
    Unknown = 9
}

export enum ExcludeFormClearFields {
    //crash
    AccDateMonth = 'AccDateMonth',
    AccDateDay = 'AccDateDay',
    AccTimeHr = 'AccTimeHr',
    AccTimeMin = 'AccTimeMin',
    AccDateYear = 'AccDateYear',
    //bike
    crashType = 'crashType',
    crashLocation = 'crashLocation',
    bikePosition = 'bikePosition',
    bikeDirection = 'bikeDirection',
    crashGroupBike = 'crashGroupBike',

    //pedestrian
    crashTypePed = 'crashTypePed',
    crashLocationPed = 'crashLocationPed',
    pedestrianPosition = 'pedestrianPosition',
    pedestrianDirection = 'pedestrianDirection',
    pedestrianMotDirection = 'pedestrianMotDirection',
    pedestrianMotManeuver = 'pedestrianMotManeuver',
    pedestrianLegIntersection = 'pedestrianLegIntersection',
    pedestrianScenario = 'pedestrianScenario',
    crashGroupPed = 'crashGroupPed',
    crashGroupExpended = 'crashGroupExpended',
    crashTypeExpended = 'crashTypeExpended',

    //veh
    NumOccs = 'NumOccs',
    InImpact = 'InImpact',
    //preCrash
    //Field has sae ID!?
    //crashType = 'crashType',
    //Trailer
    Trailer1Vin1 = 'Trailer1Vin1',
    Trailer1Vin2 = 'Trailer1Vin2',
    Trailer1Vin3 = 'Trailer1Vin3',
    Trailer1GVWRating = 'Trailer1GVWRating',
    Trailer2GVWRating = 'Trailer2GVWRating',
    Trailer3GVWRating = 'Trailer3GVWRating'
}

export enum AutoFillOperator {
    And = "and",
    Or = "or",
    Set = "set",
    Show = "show",
    Insert = "insert",
    Disable = "disable",
    Enable = "enable",
    Clear = "clear",
    Hide = "hide",
    Delete = "delete",

}

export enum PropertyType {
    String = "string",
    Number = "number",
    Boolean = "boolean",
    Object = "object"
}

export enum ColumnDataType {
    Array = "array",
    BigInt = "bigint",
    Integer = "int",
    SmallInt = "smallint",
    TinyInt = "tinyint",
    Decimal = "decimal",
    Float = "float",
    Date = "date",
    Bit = "bit",
    Varchar = "varchar",
    Char = "char"
}

export class MilePointWhole {
    public static readonly None = '0000.0';
    public static readonly NotReported = '9999.8';
    public static readonly Unknown = '9999.9';
}

export enum CheckCaseFragment {
    EditCheck = 'EditCheck'
}


export enum NumberOfOccupants {
    NotReported = 98,
    Unknown = 99
}

export enum CreatedRecord_Occupant_Type {
    NotCreatedRecord = 0,
    ReadOnlyNonHitRun = 1,
    EditableHitRun = 2
}

export enum HitAndRun {
    No = 0,
    Yes = 1
}

/**
 * Vehicles that are sold as incomplete frames will have a "final" body class.
 **/
export enum BodyClass_VPIC {
    Blank = -1,
    NotApplicable = 0,
    Bus = 16,
    SchoolBus = 73,
    Otherspecify = 997,
    NotReported = 998,
    Unknown = 999
    //,IncompleteVehicleBus
}

export enum PersonRace {
    NotApplicable = 0
}

export enum PersonType {
    Driver = 1,
    Passenger = 2
}

export enum PersonRelatedFactors {
    None = 0,
    Unknown = 999
}

export enum EjectionPath {
    NotApplicable = 0,
    Unknown = 9
}

export enum AlcoholTestResult {
    TestNotGiven = 996
}

export enum DrugTestSubstance {
    TestNotGiven = 0
}
export enum DrugTestResult {
    TestNotGiven = 0
}

export enum ExcludeTimeValues {
    Hour = 99,
    Minute = 99
}

export enum CustomNvpFilter {
    ByPermissions = 'ByPermissions'
}

export enum CrssCaseStatus {
    ToBeCoded = 0,
    Open = 1
}

export enum CaseStatus {
    Open = 1,
    TobeCoded = 2,
    Coding_Complete = 4,
    Deleted = 3,
    HQ_Review = 5,
    HQ_Review_Complete = 6
}

export enum RBISUser {
    EDT = 4444
}

export enum MeassureUnitType {
    Meter = 1,
    Inches = 2,
    MeterMin = 0.61,
    MeterMax = 2.72,
    InchesMin = 24,
    InchesMax = 107,
}

export enum VehType {
    SFRVehicle = 'F',
    StrikingVehicle = 'S',
    OtherVehicle = 'O'
}
export enum intRBISDataValue {
    Blank = -1,
    Zero = 0
}
export enum strRBISDataValue {
    Minus1 = '-1',
    Empty = ''
}
