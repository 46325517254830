import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modals-distance-calculator',
    templateUrl: './modals-distance-calculator.component.html',
    styleUrls: ['./modals-distance-calculator.component.scss']
})

export class ModalsDistanceCalculatorComponent implements OnInit{        
    public strForm: string;
    warning: string = '';
    distance: number;
    distanceUnit: number = -1;
    resultText: string = '';
    result: any = 0;
    closeResult: string;
    fontColor: string = 'red';    

    @Input() objName = "";
    @Input() hideSubmitButton: boolean;
    @Output() submitDistanceValue:EventEmitter<any> = new EventEmitter<any>();

    constructor(private _modalService: NgbModal) {

    }

    ngOnInit() {

    }

    public open(content, options: NgbModalOptions) {

        this._modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title', size: 'lg', backdropClass: 'light-blue-backdrop', backdrop: 'static', keyboard: false
        }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    onSubmit() {
        //this.submitDistanceValue.emit(Math.round(this.result));
        this.submitDistanceValue.emit(this.result);
       // this.submitDistanceValue.emit({ result:this.result, objName: this.objName });
    }

    onClear() {
        this.warning = '';
        this.distance = 0;
        this.distanceUnit = -1;
        this.resultText = '';        
        this.result = 0;
    }

    unitChange() {
        this.warning = '';
        this.resultText = '';
        this.result = 0;
        this.DistanceConversion();
    }  

    DistanceConversion() {
        if (isNaN(this.distance)) {
            this.warning = 'Please enter a valid value';
            return;
        }

        if (this.distanceUnit == -1) {
            this.warning = 'Please select an unit';
            return;
        }

        let distanceMeter: number;
        let distanceValue: number;

        if (this.distanceUnit == 1) {
            distanceMeter = (this.distance * 0.0254)
            distanceValue = Number((distanceMeter / 10).toFixed(1));
        }
        else if (this.distanceUnit == 2) {
            distanceMeter = (this.distance * 0.3048000)
            distanceValue = Number((distanceMeter / 10).toFixed(1));
        }

        this.resultText = `${distanceValue}m is a 10th of ${distanceMeter.toFixed(1)}m`;
        //this.result = distanceValue.toString();
        this.result = distanceMeter.toFixed(1);

        this.warning = '';
    }
}
