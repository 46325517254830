<form novalidate #fvvc="ngForm">
    <div *ngIf="veh && veh.Veh_MTSS" id="vehicle-details" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div [ngClass]="{'card border border-secondary bg-light m-2' : !printOnly}">
                    <div [ngClass]="{'card-body border border-secondary bg-light p-1 margin-border' : !printOnly}">

                        <div [ngClass]="{'card-title fw-bold bg-light' : !printOnly}" *ngIf="!printOnly"> <span [ngClass]="{'print-font-size-small' : printOnly }"> Motor Carrier Identification </span></div>

                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="mcInAuth" name="mcInAuth" typeAheadPosition="right-top"
                                               [objModel]="veh" strTableName="Veh" strFieldName="McInAuth" [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="McInnNum" name="McInnNum" typeAheadPosition="right-top" blnAllowFreeText="true" [maxlength]="9"
                                               [objModel]="veh" strTableName="Veh" strFieldName="McInnNum"
                                               [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-3">
                    <app-typeahead id="vConfig" name="vConfig"
                                   [objModel]="veh" strTableName="Veh" strFieldName="VConfig" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-3">
                    <app-typeahead id="cargo" name="cargo"
                                   [objModel]="veh" strTableName="Veh" strFieldName="Cargo" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-3">
                    <app-typeahead id="CargoType"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="CargoType"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="row pl-3">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div>
                                <app-label label="Cargo Weight(kgs)" [printOnly]="printOnly" inputName="CargoWeight"></app-label>
                            </div>
                            <div class="float-sm-left" *ngIf="!printOnly">
                                <app-modals-weight-calculator *ngIf="!blnReadOnly" #weightCalculator
                                                              (submitWeightValue)="setWeightValue($event)" name="weight">
                                </app-modals-weight-calculator>
                            </div>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div>
                                <app-typeahead id="CargoWeight" disableLabel="true"
                                               [objModel]="veh.Veh_MTSS"
                                               strTableName="Veh_MTSS" strFieldName="CargoWeight"
                                               [printOnly]="printOnly"
                                               [blnAllowFreeText]="true"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>             
                    <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                        <div class="form-group pl-3">
                            <app-typeahead id="CargoSpillage"
                                           [objModel]="veh.Veh_MTSS"
                                           strTableName="Veh_MTSS" strFieldName="CargoSpillage"
                                           [printOnly]="printOnly"
                                           [clearFieldData]="clearFieldData">
                            </app-typeahead>
                        </div>
                    </div>

                    <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                        <div [ngClass]="{'card border border-secondary bg-light m-2' : !printOnly }">
                            <div [ngClass]="{'card-body border border-secondary bg-light p-1 margin-border' : !printOnly }">

                                <div [ngClass]="{'card-title fw-bold bg-light' : !printOnly }" *ngIf="!printOnly">
                                    <span [ngClass]="{'print-font-size-small' : printOnly }">Hazardous Material Involvement/Placard</span>
                                </div>

                                <div class="form-group">
                                    <div class="pl-2">
                                        <app-typeahead id="hazCargo" name="hazCargo"
                                                       [objModel]="veh" strTableName="Veh" strFieldName="HazCargo" [printOnly]="printOnly"
                                                       [clearFieldData]="clearFieldData">
                                        </app-typeahead>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="pl-2">
                                        <app-typeahead id="hazMat2" name="hazMat2"
                                                       [objModel]="veh" strTableName="Veh" strFieldName="HazMat2"
                                                       [printOnly]="printOnly"
                                                       [clearFieldData]="clearFieldData">
                                        </app-typeahead>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="pl-2">
                                        <app-typeahead #txtHazMat3 id="HazMat3" name="HazMat3" typeAheadPosition="right-top" [blnAllowFreeText]="true" (bindModel)="ValidateHazMat3Range($event)"
                                                       [objModel]="veh" strTableName="Veh" strFieldName="HazMat3" [maxlength]="4" [printOnly]="printOnly"
                                                       [clearFieldData]="clearFieldData">
                                        </app-typeahead>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="pl-2">
                                        <app-typeahead id="hazMat4" name="hazMat4"
                                                       [objModel]="veh" strTableName="Veh" strFieldName="HazMat4" [printOnly]="printOnly"
                                                       [clearFieldData]="clearFieldData">
                                        </app-typeahead>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <div class="pl-2">
                                        <app-typeahead id="hazMat5" name="hazMat5"
                                                       [objModel]="veh" strTableName="Veh" strFieldName="HazMat5"
                                                       [printOnly]="printOnly"
                                                       [clearFieldData]="clearFieldData">
                                        </app-typeahead>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
</form>
