<form novalidate #fvno="ngForm">
    <div class="pt-2" *ngIf="nonOccupant && intMode">
        <div [ngClass]="{'row' : printOnly === true}">

            <div #typAhdPedHeight *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedHeight" name="PedHeight" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedHeight"
                                   [printOnly]="printOnly" [blnAllowFreeText]="true"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <span class="my-auto" *ngIf="!printOnly && intMode == 128">
                <app-height-calculator *ngIf="!blnReadOnly"
                                        [blnIsPedastrian]="true" (submitHeightInCms)="setHeightValue($event)" name="Height">
                </app-height-calculator>
            </span>

            <div #typAhdPedWeight *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedWeight" name="PedWeight" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedWeight"
                                   [printOnly]="printOnly" [blnAllowFreeText]="true"
                                   [clearFieldData]="clearFieldData" [blnShowSpecialValuesOnly]="false">
                    </app-typeahead>
                </div>
            </div>
            <span class="my-auto" *ngIf="!printOnly && intMode == 128" >
                <app-modals-weight-calculator *ngIf="!blnReadOnly" #weightCalculator
                                              (submitWeightValue)="setWeightValue($event)" name="weight">
                </app-modals-weight-calculator>
            </span>
            <div #typAhdPedZipCode  *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="dZip" name="dZip" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedZipCode"
                                   [blnAllowFreeText]="true"
                                   [maxlength]="5"
                                   [(objCurrentValue)]="nonOccupant.NonOcc_Other.PedZipCode" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdPedUseDevice *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedUseDevice" name="PedUseDevice" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedUseDevice"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"                                   
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div  [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedAttitude" name="PedAttitude" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedAttitude"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData" [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedMotion" name="PedMotion" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedMotion"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedApprRelaRearVeh" name="PedApprRelaRearVeh" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedApprRelaRearVeh"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedPriFocusAtten" name="PedPriFocusAtten" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedPriFocusAtten"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedHeading" name="PedHeading" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedHeading"
                                   [printOnly]="printOnly" maxlength="3"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div  [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="StrkVehHeading" name="StrkVehHeading" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="StrkVehHeading"
                                   [printOnly]="printOnly" maxlength="3"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedActnReltiveRdway" name="PedActnReltiveRdway" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedActnReltiveRdway"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedOrientnReltiveToVeh" name="PedOrientnReltiveToVeh" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedOrientnReltiveToVeh"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedFirstAvoidActn" name="PedFirstAvoidActn" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedFirstAvoidActn"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="VehPedInteraction" name="VehPedInteraction" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="VehPedInteraction"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="PedVisObs" name="PedVisObs" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="PedVisObs"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div  #typAhdRdDistnCrByPedPriorImp *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="RdDistnCrByPedPriorImp" name="RdDistnCrByPedPriorImp" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="RdDistnCrByPedPriorImp"
                                   [printOnly]="printOnly" [blnAllowFreeText]="true"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdRdRdDistnCrbyPedPrImpMsrmnt *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="RdDistnCrbyPedPrImpMsrmnt" name="RdDistnCrbyPedPrImpMsrmnt" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="RdDistnCrbyPedPrImpMsrmnt"
                                   [printOnly]="printOnly" [blnAllowFreeText]="true"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="WereOthPedPrsnt" name="WereOthPedPrsnt" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="WereOthPedPrsnt"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdRdDestnTripPurpose *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="DestnTripPurpose" name="DestnTripPurpose" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="DestnTripPurpose"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdRdNMCritRsnCritEvnt *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="NMCritRsnCritEvnt" name="NMCritRsnCritEvnt" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Other" strTableName="NonOcc_Other" strFieldName="NMCritRsnCritEvnt"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

        </div>
        </div>
</form>
