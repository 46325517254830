<div *ngIf="intMode" class="border-dark border-start border-bottom border-end px-2">
    <ul class="nav nav-tabs border-dark bg-white">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./person"]'
               [ngClass]="{'bg-light': linkperson.isActive === true }"
               [routerLinkActive]="['active']" #linkperson="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkperson.isActive === true, 'fw-bold' : linkperson.isActive === true}">
                        Person
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./safetyDevices"]'
               [ngClass]="{'bg-light': linksafetyDevices.isActive === true }"
               [routerLinkActive]="['active']" #linksafetyDevices="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksafetyDevices.isActive === true, 'fw-bold' : linksafetyDevices.isActive === true}">
                        Safety Devices
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./personAlcohol"]'
               [ngClass]="{'bg-light': linkpersonAlcohol.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonAlcohol="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonAlcohol.isActive === true, 'fw-bold' : linkpersonAlcohol.isActive === true}">
                        Alcohol
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./personDrug"]'
               [ngClass]="{'bg-light': linkpersonDrug.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonDrug="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonDrug.isActive === true, 'fw-bold' : linkpersonDrug.isActive === true}">
                        Drug
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./personNotes"]'
               [ngClass]="{'bg-light': linkpersonNotes.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonNotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonNotes.isActive === true, 'fw-bold' : linkpersonNotes.isActive === true}">
                        Notes
                    </span>
                </span>
            </a>
        </li>
        <!--<li class="nav-item mt-1" *ngIf="intMode == 1">
            <a class="nav-link" [routerLink]='["./personRace"]'
               [ngClass]="{'bg-light': linkpersonRace.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonRace="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonRace.isActive === true, 'fw-bold' : linkpersonRace.isActive === true}">Race</span>
                </span>
            </a>
        </li>-->
        <!--<li class="nav-item mt-1" *ngIf="intMode == 1">
            <a class="nav-link" [routerLink]='["./personSupplemental"]'
               [ngClass]="{'bg-light': linkpersonSupplemental.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonSupplemental="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonSupplemental.isActive === true, 'fw-bold' : linkpersonSupplemental.isActive === true}">Supplemental</span>
                </span>
            </a>
        </li>-->
    </ul>
    <!-- Router outlet hosts Vehicle Occupant child route components' HTML output -->
    <router-outlet></router-outlet>
</div>
