<form novalidate #fsfrvv="ngForm">
    <div *ngIf="veh && intMode" id="strikevehicle-vehicle" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                    <div class="form-group pl-2">
                        <app-textfield id="VehNotes" [disableLabel]="false"
                                       [blnIsNumber]="false" [intTextAreaRows]="10"
                                       [objModel]="veh.Veh_SS" [(objCurrentValue)]="veh.Veh_SS.StrikeNotes"
                                       [blnFormatWithLeading]="false"
                                       strTableName="Veh_SS" strFieldName="StrikeNotes"
                                       [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                        </app-textfield>
                    </div>
                </div>
            </div>

        </div>
    </div>
</form>
