
<div class="row">
    <div class="col">
        <p *ngIf="!isAuthorized">
            "You are not authorized to use the application. If you think this message should not have been displayed, Please contact CDAN Helpdesk at cdan.Helpdesk@dot.gov."
        </p>
        <p *ngIf="isAuthorized && multipleSiteAccess">
            "Your permissions are not setup properly, please check your configuration settings. Further questions, please contact CDAN Helpdesk at cdan.Helpdesk@dot.gov."
        </p>
    </div>
</div>
