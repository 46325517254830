import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AutofillService } from '../../../../services/autofill.service';
import { CaseService } from '../../../../services/case.service';
import { UtilService } from '../../../../services/util.service';

import { Acc } from 'src/app/models/acc';
import { Veh } from '../../../../models/veh';
import { VehRF } from '../../../../models/veh-rf';

import { VehicleTrailing, VehType } from '../../../../models/enums/app.enums';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';

@Component({
    selector: 'app-vehicle-vehicle-other',
    templateUrl: './vehicle-vehicle-other.component.html',
    styleUrls: ['./vehicle-vehicle-other.component.css']
})

export class VehicleVehicleOtherComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehicleVehicleOtherComponent: string = null;
    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;

    objVehClone: Veh;

    public objVehRFTemplate: VehRF;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _autofillService: AutofillService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) {
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
    }

    initData() {
        if (this.intMode == 256)
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.OtherVehicle);
        else
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        this.objVehClone = ObjectUtil.CloneModelObject(this.veh);
        this.objVehRFTemplate = ObjectUtil.InstantiateVehRF(this.veh);
    }

    async OnModelChange($event) { // TO DO - Check with Sergei! Code below messing up with autofils --- Something is wrong in my code! :(
        if (this.veh.Trailer != this.objVehClone.Trailer) {
            let allowSaveFrom: boolean;

            if ([VehicleTrailing.NoTrailingUnits, VehicleTrailing.VehicleTowingAnotherMotorVehicleFixedLinkage, VehicleTrailing.VehicleTowingAnotherMotorVehicleNonFixedLinkage, VehicleTrailing.Unknown].includes(this.veh.Trailer)) {
                if (![VehicleTrailing.Blank, VehicleTrailing.NoTrailingUnits, VehicleTrailing.VehicleTowingAnotherMotorVehicleFixedLinkage, VehicleTrailing.VehicleTowingAnotherMotorVehicleNonFixedLinkage, VehicleTrailing.Unknown].includes(this.objVehClone.Trailer)) {
                    allowSaveFrom = await this._modalService.dialogPromise("Changing the Vehicle Trailing to " + this.veh.Trailer + ' will clear Trailer(s) Information for all 3 Trailers for this Vehicle and create all trailers as "7s - No Trailing Units". Are you Sure you want to change the Vehicle Trailing?', "Are you sure");
                }
                else {
                    allowSaveFrom = true;
                }
            }

            if (this.veh.Trailer === VehicleTrailing.OneTrailingUnit) {
                if (this.objVehClone.Trailer !== VehicleTrailing.Blank) {
                    allowSaveFrom = await this._modalService.dialogPromise("Changing the Vehicle Trailing to " + this.veh.Trailer + ' will clear Trailer(s) Information for Trailer #2 and Trailer #3 for this Vehicle and create Trailer(s) Information for Trailer #2 and Trailer #3 as "7s - No Trailing Units". Are you Sure you want to change the Vehicle Trailing?', "Are you sure");
                }
                else {
                    allowSaveFrom = true;
                }
            }

            if (this.veh.Trailer === VehicleTrailing.TwoTrailingUnits) {
                if (this.objVehClone.Trailer !== VehicleTrailing.Blank) {
                    allowSaveFrom = await this._modalService.dialogPromise("Changing the Vehicle Trailing to " + this.veh.Trailer + ' will clear Trailer(s) Information for Trailer #3 for this Vehicle and create Trailer(s) Information for Trailer #3 as "7s - No Trailing Units". Are you Sure you want to change the Vehicle Trailing?', "Are you sure");
                }
                else {
                    allowSaveFrom = true;
                }
            }

            if (this.veh.Trailer === VehicleTrailing.ThreeOrMoreTrailingUnits) {
                if (this.objVehClone.Trailer !== VehicleTrailing.Blank) {
                    allowSaveFrom = await this._modalService.dialogPromise("Changing the Vehicle Trailing to " + this.veh.Trailer + ' will clear Trailer(s) Information for all 3 Trailers for this Vehicle . Are you Sure you want to change the Vehicle Trailing?', "Are you sure");
                }
                else {
                    allowSaveFrom = true;
                }
            }

            if (this.veh.Trailer === VehicleTrailing.YesNumberOfTrailingUnitsUnknown) {
                if (this.objVehClone.Trailer !== VehicleTrailing.Blank) {
                    allowSaveFrom = await this._modalService.dialogPromise("Changing the Vehicle Trailing to " + this.veh.Trailer + ' will clear Trailer(s) Information for all 3 Trailers for this Vehicle and create all trailers as "8s - Not Reported. Are you Sure you want to change the Vehicle Trailing?', "Are you sure");
                }
                else {
                    allowSaveFrom = true;
                }
            }

            if (allowSaveFrom === false) { // dialogPromise No
                this.veh.Trailer1 = ObjectUtil.CloneModelObject(this.objVehClone.Trailer1, 'Veh');
                this.veh.Trailer1.forEach(x => x.Veh = this.veh); //Clone operation does not know destination of cloned object, so upward pointing half of bidirectional links must be restored manually
                this.veh.Trailer = this.objVehClone.Trailer;

                let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "Trailer") as TypeaheadComponent;
                let item = typeahead.options.find(x => x.intValue == this.veh.Trailer);
                typeahead.bindItemAndFocusNextElement(item, false);
            }

            if (allowSaveFrom === true) {// dialogPromise Yes             
                this.objVehClone.Trailer1 = ObjectUtil.CloneModelObject(this.veh.Trailer1);
                this.objVehClone.Trailer1.forEach(x => x.Veh = this.objVehClone); //Clone operation does not know destination of cloned object, so upward pointing half of bidirectional links must be restored manually
                this.objVehClone.Trailer = this.veh.Trailer;

                let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "Trailer") as TypeaheadComponent;
                let item = typeahead.options.find(x => x.intValue == this.veh.Trailer);
                typeahead.bindItemAndFocusNextElement(item, false);
            }
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
