import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ModalsAlcoholCalculatorComponent } from './modals-alcohol-calculator/modals-alcohol-calculator.component';
import { ModalsAgeCalculatorComponent } from './modals-age-calculator/modals-age-calculator.component';
import { ModalsWeightCalculatorComponent } from './modals-weight-calculator/modals-weight-calculator.component';
import { ModalsDistanceCalculatorComponent } from './modals-distance-calculator/modals-distance-calculator.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        NgbModule
    ],
    declarations: [
        ModalsAlcoholCalculatorComponent,
        ModalsAgeCalculatorComponent,
        ModalsWeightCalculatorComponent,
        ModalsDistanceCalculatorComponent
    ],
    exports: [
        ModalsAlcoholCalculatorComponent,
        ModalsAgeCalculatorComponent,
        ModalsWeightCalculatorComponent,
        ModalsDistanceCalculatorComponent       
    ],
    bootstrap: [
        ModalsAlcoholCalculatorComponent,
        ModalsAgeCalculatorComponent,
        ModalsWeightCalculatorComponent,
        ModalsDistanceCalculatorComponent
    ]
})
export class ModalsModule { }
