import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//services
import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';

//helper
import { BaseComponent } from 'src/app/helper/basecomponent';

//pipes
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';

//models
import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { Occupants } from 'src/app/models/occupants';

//basecomponents
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

@Component({
    selector: 'app-vehicle-person-notes',
    templateUrl: './vehicle-person-notes.component.html',
    styleUrls: ['./vehicle-person-notes.component.css']
})

export class VehiclePersonNotesComponent extends BaseComponent implements OnInit, AfterViewInit {

    stateNum: number;
    accid: number;
    vehicleid: number;
    personid: number;

    acc: Acc;
    veh: Veh;
    person: Occupants;    

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    )
    {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngOnInit() {
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
                this._route.parent.parent.params.subscribe(params => {
                    this.vehicleid = + params['vehicleid'];
                    this._route.parent.params.subscribe(params => {
                        this.personid = + params['personid'];
                        this.initData();
                    });
                });
            });
        }
        else {
            this.vehicleid = this.printVehNum;
            this.personid = this.personNum;
            this.accid = this.acc.AccID;
            this.initData();
        }
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.person = this.veh.Occupants.find(o => o.VNumber == this.vehicleid && o.PNumber == this.personid);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
}
