
<div class="card-body pt-0">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
            <div class="row">
                <div class="card w-100 mb-3 border-dark">
                    <div class="card-header text-dark"><h3>Case Navigator</h3></div>
                    <div class="card-body text-dark bg-light" style="margin: 0.9px">
                        <ul class="nav flex-column mb-0">
                            <li class="nav-item" *ngIf="selectedMode == 256">
                                <button id="lnkCaseHeaderNew_MOSS" class="btn btn-link px-0 text-left" (click)="onCreateMoSS()" [disabled]="isReadOnly">Create New case</button>
                            </li>
                            <li class="nav-item">
                                <button id="lnkCaseHeaderSearch_FARS" class="btn btn-link px-0 text-left" (click)="onGoNotificationScreener()">
                                    <div *ngIf="selectedMode == 256">Search Cases</div>
                                    <div *ngIf="selectedMode != 256">Case Screener</div>
                                </button>
                            </li>
                        </ul>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend pr-1">
                                <label class="my-auto">{{modeString}} Case #</label>
                            </div>
                            <input type="text"
                                   id="txtSearchByCaseNum" class="border-primary for-focus px-2" style="max-width: 74px;"
                                   [ngModel]="strSeachText" (ngModelChange)="OnModelChange($event)"
                                   (keyup.enter)="onSearch(searchCase.value)"
                                   (paste)="onPaste($event)"
                                   (keydown)="handleKeydown($event)"
                                   maxlength="4"
                                   [numeric] numericType="number"
                                   #searchCase *ngIf="selectedMode == 64 || selectedMode == 128" />
                            <input type="text" id="searchMossCase" class="border-primary for-focus px-2" style="max-width:115px;" maxlength="8"
                                   [ngModel]="strMossCaseNumber | replaceTextPipe : ' ' : '' | maxLengthPipe : 12 : ''" (ngModelChange)="OnModelChange($event)"
                                   (keydown)="handleKeydown($event)"
                                   (keyup.enter)="onSearchMossNum(searchMossCase.value)"
                                   (paste)="onPaste($event)"
                                   #searchMossCase *ngIf="selectedMode == 256" />
                            <div class="input-group-append" *ngIf="selectedMode != 256">
                                <button class="btn btn-primary" id="btnHome_SearchByCaseNum" type="button" (click)="onSearch()">Go</button>
                            </div>
                            <div class="input-group-append" *ngIf="selectedMode == 256">
                                <button class="btn btn-primary" id="btnHome_SearchByMossCaseNum" type="button" (click)="onSearchMossNum()">Go</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card w-100 mb-3 border-dark">
                    <div class="card-header text-dark"><h3>Admin Menu</h3></div>
                    <div class="card-body text-dark bg-light" style="margin: 0.9px">
                        <ul class="nav flex-column mb-0">
                            <li class="nav-item">
                                <button id="btnHome_OnGoToReleaseRecords" class="btn btn-link" (click)="OnGoToReleaseRecords()">Release Records</button>
                            </li>                          
                        </ul>
                    </div>
                </div>


                <div class="card w-100 border-dark mb-3">
                    <div class="card-header text-dark"><h3>Tools</h3></div>
                    <div class="card-body text-dark bg-light" style="margin: 0.9px">
                        <app-modals-alcohol-calculator strForm="Home"></app-modals-alcohol-calculator>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 ">

            <div class="card border-dark mb-3"  *ngIf="selectedMode != 256">
                <div class="card-header text-dark"><h3>Calendar Reminder</h3></div>
                <div class="card-body text-dark h78 bg-light" style="margin: 0.9px" *ngIf="calenderReminder && calenderReminder.length > 0; else loadingCalenderReminder">
                    <p class="card-text">
                        {{calenderReminder[0]?.Special}} - {{calenderReminder[0]?.EventDate}} - {{calenderReminder[0]?.Title}}
                    </p>
                </div>
                <ng-template #loadingCalenderReminder>
                    <div class="pl-3 py-3">
                    <i *ngIf="calenderReminder == undefined" class="fa fa-spinner fa-spin" style="font-size:24px"></i>
                    <p *ngIf="calenderReminder?.length == 0">No Events Exists.</p>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="selectedMode == 256">
                <div class="row" *ngFor="let s of mossSCISiteList">

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6" *ngIf="s == 1">
                        <div>
                            <div class="card border-dark mb-3">
                                <div class="card-header text-dark">
                                    <h3>{{modeString}} Case Status of SCI Site {{s}} </h3>
                                </div>
                                <div class="card-body text-dark bg-light" style="margin: 0.9px" *ngIf="caseSummaryData; else loadingCaseStatus">
                                    <ul class="list-group list-group-flush">
                                        <!--All -->
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Total Cases                                                      <span><b>{{caseSummaryData?.TotalCasesSite1}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light"><br /></li>
                                        <!--NON EDT -->
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light" *ngIf="selectedMode == 256">Coding Complete                       <span><b>{{caseSummaryData?.TotalCasesCodingCompletedSite1}}</b></span></li>

                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Open                                                        <span><b>{{caseSummaryData?.TotalCasesToBeCreatedSite1}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Deleted                                                     <span><b>{{caseSummaryData?.TotalCasesDeletedSite1}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light"><br /></li>
                                    </ul>
                                </div>
                                <ng-template #loadingCaseStatus>
                                    <div class="pl-3 py-3"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6" *ngIf="s == 2">
                        <div>
                            <div class="card border-dark mb-3">
                                <div class="card-header text-dark">
                                    <h3>{{modeString}} Case Status of SCI Site {{s}} </h3>
                                </div>
                                <div class="card-body text-dark bg-light" style="margin: 0.9px" *ngIf="caseSummaryData; else loadingCaseStatus">
                                    <ul class="list-group list-group-flush">
                                        <!--All -->
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Total Cases                                                       <span><b>{{caseSummaryData?.TotalCasesSite2}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light"><br /></li>
                                        <!--NON EDT -->
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light" *ngIf="selectedMode == 256">Coding Complete                       <span><b>{{caseSummaryData?.TotalCasesCodingCompletedSite2}}</b></span></li>

                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Open                                                        <span><b>{{caseSummaryData?.TotalCasesToBeCreatedSite2}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Deleted                                                     <span><b>{{caseSummaryData?.TotalCasesDeletedSite2}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light"><br /></li>
                                    </ul>
                                </div>
                                <ng-template #loadingCaseStatus>
                                    <div class="pl-3 py-3"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6" *ngIf="s == 3">
                        <div>
                            <div class="card border-dark mb-3">
                                <div class="card-header text-dark">
                                    <h3>{{modeString}} Case Status of SCI Site {{s}} </h3>
                                </div>
                                <div class="card-body text-dark bg-light" style="margin: 0.9px" *ngIf="caseSummaryData; else loadingCaseStatus">
                                    <ul class="list-group list-group-flush">
                                        <!--All -->
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Total Cases                                                       <span><b>{{caseSummaryData?.TotalCasesSite3}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light"><br /></li>
                                        <!--NON EDT -->
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light" *ngIf="selectedMode == 256">Coding Complete                       <span><b>{{caseSummaryData?.TotalCasesCodingCompletedSite3}}</b></span></li>

                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Open                                                        <span><b>{{caseSummaryData?.TotalCasesToBeCreatedSite3}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Deleted                                                     <span><b>{{caseSummaryData?.TotalCasesDeletedSite3}}</b></span></li>
                                        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light"><br /></li>
                                    </ul>
                                </div>
                                <ng-template #loadingCaseStatus>
                                    <div class="pl-3 py-3"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="selectedMode != 256"> 
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div>
                        <div class="card border-dark mb-3">
                            <div class="card-header text-dark">
                                <h3>{{modeString}} Case Status</h3>
                            </div>
                            <div class="card-body text-dark bg-light" style="margin: 0.9px" *ngIf="caseSummaryData; else loadingCaseStatus">
                                <ul class="list-group list-group-flush">
                                    <!--All -->
                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Total Cases                                                             <span><b>{{caseSummaryData?.TotalCases}}</b></span></li>
                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light" *ngIf="selectedMode != 256">Fatalities                            <span><b>{{caseSummaryData?.TotalFatalities}}</b></span></li>
                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light"><br /></li>
                                    <!--NON EDT -->
                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light" *ngIf="selectedMode != 256">Cases To be Coded                     <span><b>{{caseSummaryData?.TotalCasesToBeCreated}}</b></span></li>
                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light" *ngIf="selectedMode == 256">Coding Complete                       <span><b>{{caseSummaryData?.TotalCasesCodingCompleted}}</b></span></li>

                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Open                                                        <span><b>{{caseSummaryData?.TotalCasesCreated}}</b></span></li>
                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Deleted                                                     <span><b>{{caseSummaryData?.TotalCasesDeleted}}</b></span></li>
                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light"><br /></li>
                                    <!--EDT -->
                                    <li *ngIf="blnIsEDTstate === true" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases New                          <span><b>{{caseSummaryData?.TotalCasesNewOpened}}</b></span></li>
                                    <li *ngIf="blnIsEDTstate === true" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Updated                      <span><b>{{caseSummaryData?.TotalCasesMarkedForReviewUpdated}}</b></span></li>
                                    <li *ngIf="blnIsEDTstate === true" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Reviewed                     <span><b>{{caseSummaryData?.TotalCasesReviewed}}</b></span></li>
                                    <li *ngIf="blnIsEDTstate === true" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases In Conflict                  <span><b>{{caseSummaryData?.TotalCasesInConflict}}</b></span></li>
                                    <li *ngIf="blnIsEDTstate === true" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0 bg-light">Cases Locked/Final                 <span><b>{{caseSummaryData?.TotalCasesLockedFinalClosed}}</b></span></li>
                                </ul>
                            </div>
                            <ng-template #loadingCaseStatus>
                                <div class="pl-3 py-3"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></div>
                            </ng-template>
                        </div>
                    </div>
                </div>              

            </div>
            </div>
    </div>
</div>

