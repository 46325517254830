<form novalidate #fvvv="ngForm">
    <div class="pt-2" *ngIf="preCrash">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="TrafLane" name="traflane" typeAheadPosition="right-top"
                                   [objModel]="preCrash" strTableName="PreCrash" strFieldName="TrafLane" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData" #traflane>
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="InitTravLane"
                                   [objModel]="preCrash.PreCrash_MTSS" strTableName="PreCrash_MTSS" strFieldName="InitTravLane" [printOnly]="printOnly"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData" #devfunc>
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="SpdLim" name="SpdLim" typeAheadPosition="right-top"
                                   [objModel]="preCrash" strTableName="PreCrash" strFieldName="SpdLim" [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData"
                                   [blnShowSpecialValuesOnly]="true"                                   
                                   [blnAllowFreeText]="true"
                                   #spdlim>
                    </app-typeahead>
                </div>
            </div>

            <!--[showAllDataListForPrintOnly]="false" [blnHideSelectedOptionText]="true"-->

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RoadAlgn"
                                   [objModel]="preCrash" strTableName="PreCrash" strFieldName="RoadAlgn" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData" #roadalgn>
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RoadProf"
                                   [objModel]="preCrash" strTableName="PreCrash" strFieldName="RoadProf" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData" #roadprof>
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="SurfType"
                                   [objModel]="preCrash" strTableName="PreCrash" strFieldName="SurfType" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify" [intDisabledMode]="4" #surftype>
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="SurfCond"
                                   [objModel]="preCrash" strTableName="PreCrash" strFieldName="SurfCond"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData" #surfcond>
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="ContDev"
                                   [objModel]="preCrash" strTableName="PreCrash" strFieldName="ContDev" [printOnly]="printOnly"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData" #contdev>
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="DevFunc"
                                   [objModel]="preCrash" strTableName="PreCrash" strFieldName="DevFunc" [printOnly]="printOnly"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData" #devfunc>
                    </app-typeahead>
                </div>
            </div>

        </div>
    </div>
</form>

<ng-template *ngIf="intMode" #copyModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Roadway Characteristics</h4>
        <button id="btnRoadway_onRoadwayCharacteristicsDismiss" class="btn btn-sm btn-light border-secondary close" aria-label="Close" (click)="modal.dismiss(null)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-warning text-center m-2" role="alert" style="height:6rem;font-size:medium;" *ngIf="noCopyAvailable">
            There are no other Vehicles to copy Precrash Data. <br /> Click Close to return to the main form. <br />
            <button type="button" id="btnRoadwayCharacteristics_OnClose" class="btn btn-sm btn-light border-secondary" value="Close" (click)="modal.close(null)">Close</button>
        </div>
        <div class="border rounded p-2 text-center" *ngIf="!noCopyAvailable">
            <p>
                This window allows you to copy Roadway Characteristics from previously entered Vehicles.
            </p>

            <div class="p-3 mb-2 bg-secondary text-white"><strong>Select the appropriate Vehicle</strong></div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col"> </th>
                        <th scope="col">Vehicle #</th>
                        <th scope="col">Trafficway Description</th>
                        <th scope="col">Total Lanes</th>
                        <th scope="col">Speed Limit</th>
                        <th scope="col">Roadway Alignment</th>
                        <th scope="col">Roadway Grade</th>
                        <th *ngIf="intMode==1" scope="col">Surface Type</th>
                        <th scope="col">Surface Conditions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of allExisitingPrecrashList; index as i">
                        <td>
                            <button id="btnRoadwayCharacteristics_OnSelect" class="btn btn-sm btn-light border-secondary" type="button" value="select" (click)="modal.close(item)">Select</button>
                        </td>
                        <th scope="row">
                            {{item.VNumber }}
                        </th>
                        <td>
                            {{ (item.TrafFlow=='-1') ? ' ' : item.TrafFlow }}
                        </td>
                        <td>
                            {{ (item.TrafLane=='-1') ? ' ' : item.TrafLane}}
                        </td>
                        <td>
                            {{ (item.SpdLim=='-1') ? ' ' : item.SpdLim}}
                        </td>
                        <td>
                            {{ (item.RoadAlgn=='-1') ? ' ' : item.RoadAlgn}}
                        </td>
                        <td>
                            {{ (item.RoadProf=='-1') ? ' ' : item.RoadProf}}
                        </td>
                        <td *ngIf="intMode==1">
                            {{ (item.SurfType=='-1') ? ' ' : item.SurfType}}
                        </td>
                        <td>
                            {{ (item.SurfCond=='-1') ? ' ' : item.SurfCond}}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="btnRoadwayCharacteristics_OnFooterClose" class="btn btn-sm btn-light border-secondary" *ngIf="allExisitingPrecrashList.length>0" (click)="modal.close(null)">Close</button>
    </div>
</ng-template>

