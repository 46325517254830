import { Component, OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Params } from '@angular/router';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

import { Acc } from 'src/app/models/acc';
import { Dri } from 'src/app/models/dri';
import { Veh } from 'src/app/models/veh';
import { DriRF } from 'src/app/models/dri-rf';
import { Element_Specify } from 'src/app/models/element-specify';
import { DriViol } from 'src/app/models/dri-viol';
import { DriCondAtCrashTime } from 'src/app/models/dri-cond-at-crash-time';
import { AutofillService } from 'src/app/services/autofill.service';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { DriPrevSus } from 'src/app/models/dri-prev-sus';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Preference, DriverSubTab } from 'src/app/models/enums/app.enums';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { Subscription } from 'rxjs';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { Dri_Illness } from 'src/app/models/dri-illness';
import { Dri_DriInattention } from 'src/app/models/dri-driinattention';
import { Dri_DriDistract } from 'src/app/models/dri-dridistract';

@Component({
  selector: 'app-striking-vehicle-driver-driver',
  templateUrl: './striking-vehicle-driver-driver.component.html',
  styleUrls: ['./striking-vehicle-driver-driver.component.css']
})
export class StrikingVehicleDriverDriverComponent extends BaseComponent implements OnInit, OnDestroy {
    private _TypeScript_TypeGuard_VehicleDriverDriverComponent: string = null;
    sbsClear: Subscription;
    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    dri: Dri;
    driClone: Dri;
    blnOOSPreference: boolean = false;

    initSelectedOptionsDriViol: Array<number> = [];
    initSelectedOptionsAddDriCondAtCrashTimeItem: Array<number> = [];
    initSelectedOptionsdriRF: Array<number> = [];
    objInstanceElementSpecify: Element_Specify;

    inputToFocus: HTMLElement;
    objDriCondCrashTimeTemplate: DriCondAtCrashTime;
    objDriVioTemplate: DriViol;
    objDriRF: DriRF;

    objDriInattentionTemplate: Dri_DriInattention;
    objDriDistractTemplate: Dri_DriDistract;
    objDriIllnessTemplate: Dri_Illness;


    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _autoFillService: AutofillService,
        private _secUserPerferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }



    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    async ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) {
            let objParams: Params = await this._route.parent.parent.parent.params;
            let objParams2: Params = await this._route.parent.parent.params;
            let strCaseId = objParams.value['caseid'];
            this.accid = +strCaseId;
            this.vehicleid = +objParams2.value['vehicleid'];
            this.initData();
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
        this._secUserPerferenceService.isThisPreferenceOn(Preference.AutomaticOutOfStateMessageRequests)
            .then((blnIsON: boolean) => {
                this.blnOOSPreference = blnIsON;
            });


    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        console.log(this.veh);

        if (!this.veh.Dri) {
            this.dri = <Dri>AutofillService.AddModelObject(this.veh, 'Veh', 'Dri');
            this.dri.AccID = this.acc.AccID;
            this.dri.VNumber = this.veh.VNumber;

        }
        else 
            this.dri = this.veh.Dri;
        this.driClone = Object.assign({}, this.dri);

        if (!this.dri.DriCondAtCrashTime)
            this.dri.DriCondAtCrashTime = new Array<DriCondAtCrashTime>();

        if (!this.dri.DriPrevSus)
            this.dri.DriPrevSus = new Array<DriPrevSus>();

        if (!this.dri.DriViol)
            this.dri.DriViol = new Array<DriViol>();

        if (this.dri) {
            console.log('dri');
            this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.dri.VNumber, this.dri.PNumber);
            this.objDriCondCrashTimeTemplate = ObjectUtil.InstantiateDriCondAtCrashTime(this.dri);
            this.objDriVioTemplate = ObjectUtil.InstantiateDriViol(this.dri);
            this.objDriRF = ObjectUtil.InstantiateDriRF(this.dri);
            this.objDriIllnessTemplate = ObjectUtil.InstantiateDriIllness(this.dri);
            this.objDriInattentionTemplate = ObjectUtil.InstantiateDriInattention(this.dri);
            this.objDriDistractTemplate = ObjectUtil.InstantiateDriDistract(this.dri);

            if (!this.veh.DriRF)
                this.veh.DriRF = new Array<DriRF>();

        }
        //Commented out becuase to override the autofill rule
        this.sbsClear = this._sharedDataService.subjectClearForm.subscribe(async () => {

            const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment;
            if (UrlTreeHelper.FindAndSetCurrentTab(urlSegments) === DriverSubTab.Driver) {
                if (this.veh.Occupants.length > 0) {
                    let uiControls: UIElementBase[] = this._autoFillService.arrControls;
                    if (uiControls) {
                        uiControls.forEach(uiControl => {
                            uiControl.disableOrEnableComponent(false);
                        });
                    }
                }
            }
        });
    }

    objectOnChange(objModel: Dri) {
        this._sharedDataService.setBeforeSaveState(objModel.LicState);
    }


    ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.sbsClear) {
            this.sbsClear.unsubscribe();
        }
    }
}
