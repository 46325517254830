import { Injectable }             from '@angular/core';
import { CanActivate }            from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterStateSnapshot }    from '@angular/router';
import { Observable }             from 'rxjs';
import { AutofillService }        from 'src/app/services/autofill.service';

/**
 * This routeguard ensures that all initialization logic that is a prerequisite for components deriving from BaseComponent to begin initializing themselves has finished.
 **/
@Injectable({
    providedIn: 'root',
})
export class InitRouteGuard implements CanActivate {
    constructor(
        private _autofillService: AutofillService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return new Observable<boolean>(objGuardObserver => {
            this._autofillService.subInitialized.subscribe(blnInitialized => {
                if(blnInitialized)
                    objGuardObserver.next(true);
            });
        });
    }
}
