<form novalidate #fvvv="ngForm">
    <div *ngIf="veh && intMode" id="vehicle-vehicle" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode != 256">
                <div class="form-group pl-2">
                    <app-typeahead id="NumOccs" name="NumOccs"
                                   typeAheadPosition="right-top" (bindModel)="_autofillService.OnCreatedRecordPrerequisitesChange(veh, 'NumOccs', $event, VehClone)"
                                   [objModel]="veh" strTableName="Veh" strFieldName="NumOccs"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData"
                                   [blnShowSpecialValuesOnly]="true">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdNumOccs [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode == 256">
                <div class="form-group pl-2">
                    <app-typeahead id="NumOccs" name="NumOccs"
                                   typeAheadPosition="right-top" (bindModel)="_autofillService.OnCreatedRecordPrerequisitesChange(veh, 'NumOccs', $event, VehClone)"
                                   [objModel]="veh" strTableName="Veh" strFieldName="NumOccs"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData"
                                   [maxlength]="2"
                                   [blnAllowFreeText]="true"
                                   [blnFreeTextWithoutOption]="true">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode != 256">
                <div class="form-group pl-2">
                    <app-typeahead id="unitType" name="unitType"
                                   [objModel]="veh" strTableName="Veh" strFieldName="UnitType" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData" (bindModel)="onUnitTypeChange($event)">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="TravSpd" [printOnly]="printOnly" inputName="TravSpd"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly && !calc">
                                <app-speed-calculator *ngIf="!blnReadOnly"
                                                      [objVeh]="veh"
                                                      objToUpdate="TravSpd"
                                                      destUnits="Mph"
                                                      (setSpeedEvent)="setTravelSpeed($event)">
                                </app-speed-calculator>
                            </span>
                        </div>
                        <div #typAhdTravSpd [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="travelspeed" name="travelspeed" typeAheadPosition="right-top"
                                               [objModel]="veh" strTableName="Veh" strFieldName="TravSpd"
                                               [printOnly]="printOnly" [disableLabel]="true" [blnAllowFreeText]="true"
                                               [clearFieldData]="clearFieldData" [showAllDataListForPrintOnly]="false"
                                               [blnShowSpecialValuesOnly]="true">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #typAhdunderrideoverride [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode==64">
                <div class="form-group pl-2">
                    <app-typeahead id="underrideoverride" name="underrideoverride"
                                   [objModel]="veh" strTableName="Veh" strFieldName="UdOvr" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdLeave [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode!=128">
                <div class="form-group pl-2">
                    <app-typeahead id="leave" name="leave"
                                   [objModel]="veh" strTableName="Veh" strFieldName="Leave" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode != 256">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-label inputName="SeqEvent" [printOnly]="printOnly"></app-label>
                            </div>
                        </div>

                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']" *ngIf="dropDownOption && crashEvents && arrCrashEventsForList">
                            <div class="pl-2" *ngFor="let item of arrCrashEventsForList; index as i">
                                <span [ngClass]="{'print-font-size field-size-seq-event': printOnly }" *ngIf="!clearFieldData">
                                    {{item.desc}}
                                </span>
                                <textarea *ngIf="clearFieldData" style="font-size:7px"
                                          class="form-control form-control-sm bg-white print-only-input" readonly disabled="disabled"></textarea>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode != 256">
                <div class="form-group pl-2">
                    <app-typeahead id="mhEvent" name="mhEvent" typeAheadPosition="right"
                                   [objModel]="veh" strTableName="Veh" strFieldName="MhEvent"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdRollOver [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="rollover" name="rollover"
                                   [objModel]="veh" strTableName="Veh" strFieldName="RollOver" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div *ngIf="printOnly && intMode == 128" class="col-md-12 " style="margin-top:190px!important"></div>

            <div #typAhdRollOverLoc [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="rolloverloc" name="rolloverloc"
                                   [objModel]="veh" strTableName="Veh" strFieldName="RollOverLoc" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdInImpact [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="InImpact" name="InImpact"
                                   [objModel]="veh" strTableName="Veh" strFieldName="InImpact" disabled="true" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdDeform [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="deForm" name="deForm"
                                   [objModel]="veh" strTableName="Veh" strFieldName="Deform" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdLeave [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="leave" name="leave"
                                   [objModel]="veh" strTableName="Veh" strFieldName="Leave" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode == 256">
        <div class="form-group pl-2">
            <app-typeahead id="spUse" name="spUse" typeAheadPosition="right-top"
                           [objModel]="veh" strTableName="Veh" strFieldName="SpUse"
                           [printOnly]="printOnly"
                           [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->
            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode == 256">
        <div class="form-group pl-2">
            <app-typeahead id="EmergMotorVehUse" name="EmergMotorVehUse"
                           typeAheadPosition="right-top"
                           [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="EmergMotorVehUse"
                           [printOnly]="printOnly"
                           [showAllDataListForPrintOnly]="false"
                           [clearFieldData]="clearFieldData"
                          >
            </app-typeahead>
        </div>
    </div>-->
        </div>
        </div>
</form>

