import { Component, OnInit, OnDestroy, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { CaseService } from 'src/app/services/case.service';
import { Acc } from 'src/app/models/acc';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';

@Component({
    selector: 'app-crash',
    templateUrl: './crash.component.html',
    styleUrls: ['./crash.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CrashComponent implements OnInit, OnDestroy, AfterViewInit {


    accid: number;
    stateNum: number;
    acc: Acc;
    intMode: number;

    constructor(
        private _route: ActivatedRoute,
        private _caseService: CaseService,
        private _sharedDataService: SharedDataService
    ) { }

    ngOnInit() {
        this._route.parent.params.subscribe(params => {
            this.accid = +params['caseid'];
            this.stateNum = + params['stateNum'];
            if (!this._sharedDataService.selectedState) {
                this._sharedDataService.setSelectedState(this.stateNum);
            }
        });

        this._caseService.GetCasePromise(this.accid).then(data => {
            this.acc = data;
        });
    }

    async ngAfterViewInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = appSettings.intMode;
    }

    ngOnDestroy() {

    }
}

