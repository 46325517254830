import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, ViewChild, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
//rxjs
import { Subscription } from 'rxjs';
//services
import { CaseService } from 'src/app/services/case.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
//models
import { Acc } from '../../../../models/acc';
import { Veh } from '../../../../models/veh';
import { Occupants } from '../../../../models/occupants';
import { CrashEvents } from 'src/app/models/crash-events';
import { TableFieldElements } from '../../../../models/table-field-elements';
import { DrpDownOptions } from '../../../../models/drp-down-options';
import { GetSecUserPreferencesResult } from 'src/app/models/GetSecUserPreferencesResult';
//components
import { TypeaheadComponent } from '../../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
//helpers
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { PreCrash } from 'src/app/models/pre-crash';
import { async } from 'q';
import { Preference, HitAndRun, CreatedRecord_Occupant_Type, RBISDataValue, MailRequestType, UnitType, VehType, DBMode } from 'src/app/models/enums/app.enums';
import { LookupTable, FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { OOSRequest } from 'src/app/models/oos-request';
import { ActionButtonService } from 'src/app/services/action-button.service';
import { NgForm } from '@angular/forms';


export interface CrashEventsList {
    intValue: number;
    strValue: string;
    desc: string;
}

@Component({
    selector: 'app-vehicle-vehicle-vehicle',
    templateUrl: './vehicle-vehicle-vehicle.component.html',
    styleUrls: ['./vehicle-vehicle-vehicle.component.css']
})

export class VehicleVehicleVehicleComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    private _TypeScript_TypeGuard_VehicleVehicleVehicleComponent: string = null;
    private sbsVehicleId: Subscription;
    sbsClearForm: Subscription;
    accid: number;
    vehicleid: number;

    public veh: Veh;
    acc: Acc;

    public VehClone: Veh;
    PreCrashClone: PreCrash;

    crashEvent: CrashEvents;
    crashEvents: CrashEvents[];

    crashEventForList: CrashEventsList;
    arrCrashEventsForList: CrashEventsList[];

    inputElements: NodeListOf<any>;
    fieldMetaData: TableFieldElements[];

    objMetaData: TableFieldElements;
    dropDownOption: DrpDownOptions[];

    dropDownOptionHarmfulEvents: DrpDownOptions[];
    arrIntDropDownHarmEvent: number[];

    public secUserPerferenceResult: GetSecUserPreferencesResult;
    public strTypeAheadClass: string;

    subsriptionSeqEvent = Subscription;
    blnOOSPreference: boolean = false;
    @ViewChild('fvvv') objForm: NgForm;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    calc: boolean;


    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        public _autofillService: AutofillService,
        private _secUserPerferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper

    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }




    ngOnInit() {
        super.ngOnInit();

        this.accid = this.acc.AccID;
        this._secUserPerferenceService.isThisPreferenceOn(Preference.AutomaticOutOfStateMessageRequests).then((blnIsON: boolean) => {
            this.blnOOSPreference = blnIsON;
        });
        if (this.sbsVehicleId)
            this.sbsVehicleId.unsubscribe();
        if (this.printVehNum == 0) {
            this.sbsVehicleId = this._route.parent.parent.params.subscribe(async params => {
                this.vehicleid = + params['vehicleid'];
                this.formLoad();
            });
        } else {
            this.formLoad();           
        }
    }

    formLoad() {
        if (this.printVehNum != 0) this.vehicleid = this.printVehNum;

        this._sharedDataService.setVehicleID(this.vehicleid);

        this._secUserPerferenceService.isThisPreferenceOn(Preference.EditNumberofOccupants).then((blnIsOn: boolean) => {
            if (!blnIsOn) {
                let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                    subInit.unsubscribe();
                    let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == FieldName.NumOccs) as TypeaheadComponent;
                    if (uiControl)
                        uiControl.focusNextInput();

                }).bind(this));
            }
        });
       
        if (this.intMode == 256)
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.OtherVehicle);
        else
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        if (this.veh.UnitType == 1)
            this.calc = false;
        else
            this.calc = true;

        this.VehClone = ObjectUtil.CloneModelObject(this.veh);
        //this.PreCrashClone = ObjectUtil.CloneModelObject(this.veh.PreCrash);

        this._utilService.formDrpDownOptionShare.subscribe(async result => {

            this.dropDownOption = await result.filter(i => i.tblName == LookupTable.SeqEvent);

            console.log("this.dropDownOption", this.dropDownOption);
            this.dropDownOptionHarmfulEvents = this.dropDownOption.filter(x => x.HARMFUL === true);
            this.crashEvents = this.acc.CrashEvents.filter(v => v.AccID == this.accid && (v.VNumber1 == this.vehicleid || v.VNumber2 == this.vehicleid));
            this.arrIntDropDownHarmEvent = [];
            this.dropDownOptionHarmfulEvents.forEach(item => {
                if (item.HARMFUL === true) {
                    this.arrIntDropDownHarmEvent.push(item.intValue);
                }
            });

            this.arrCrashEventsForList = [];

            if (this.crashEvents && (this.veh.UnitType == UnitType.Blank || this.veh.UnitType == UnitType.InTransport)) {
                this.crashEvents.forEach(item => {
                    if (item.SOE != RBISDataValue.Blank) {
                        let option = this.dropDownOption.find(x => x.intValue == item.SOE);
                        if (option) {
                            let itemFOrList = {} as CrashEventsList;
                            itemFOrList.intValue = option.intValue;
                            itemFOrList.strValue = option.strValue;
                            itemFOrList.desc = option.displayText;
                            this.addCrashEventTheList(itemFOrList);
                        }
                    }
                });
            }

            this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe((() => {
                const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment;
                if (UrlTreeHelper.FindAndSetCurrentTab(urlSegments) === "vehicle") {
                    if (this.acc) {
                        this.veh.UnitType = -1;
                        let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == "UnitType") as TypeaheadComponent;
                        let item: DrpDownOptions = uiControl.options.find(x => x.intValue == this.veh.UnitType);
                        uiControl.bindItemAndFocusNextElement(item);
                    }
                }

            }).bind(this));

            //Commentted out becuase the logic moved to autofill. 
            // AS per Business rule from the Task #5791 item 3.
            // We are intentionly looking for the Most Harmful event only if unit type field is coded with 1 and there is only 1 harmful crash event then we assign value to MhEvent = this.crashEvents[0].SOE
            //if (this.crashEvents.length == 1 && this.veh.UnitType == 1 && this.arrCrashEventsForList.length == 1) {
            //    if (this.arrIntDropDownHarmEvent.includes(this.arrCrashEventsForList[0].intValue)) {
            //        if (this.veh.MhEvent === -1 || this.veh.MhEvent === null || this.veh.MhEvent === undefined) {
            //            this.veh.MhEvent = this.crashEvents[0].SOE;
            //        }
            //        let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            //            subInit.unsubscribe();
            //            let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == "MhEvent") as TypeaheadComponent;
            //            let item: DrpDownOptions = uiControl.options.find(x => x.intValue == this.veh.MhEvent);
            //            uiControl.bindItemAndFocusNextElement(item);
            //        }).bind(this));
            //    }
            //}
        });
    }

   // onUnitTypeChange($event) {
        // AS per Business rule from the Task #5791 item 3.
        // We are intentionly looking for the Most Harmful event only if unit type field is coded with 1 and there is only 1 harmful crash event then we assign value to MhEvent = this.crashEvents[0].SOE
        //Commentted out becuase the logic moved to autofill. 
        //if (this.crashEvents.length == 1 && this.veh.UnitType == 1 && this.arrCrashEventsForList.length == 1) {
        //    if (this.arrIntDropDownHarmEvent.includes(this.arrCrashEventsForList[0].intValue)) {
        //        if (this.veh.MhEvent === -1 || this.veh.MhEvent === null || this.veh.MhEvent === undefined) {
        //            this.veh.MhEvent = this.crashEvents[0].SOE;
        //        }

        //        let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
        //            subInit.unsubscribe();
        //            let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == "MhEvent") as TypeaheadComponent;
        //            let item: DrpDownOptions = uiControl.options.find(x => x.intValue == this.veh.MhEvent);
        //            uiControl.bindItemAndFocusNextElement(item);

        //        }).bind(this));

        //    }
        //}
   // }


    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    addCrashEventTheList(itemFOrList) {
        this.arrCrashEventsForList.push(itemFOrList);
    }

    objectOnChange(objModel: Veh) {
        //console.log("Veh objectOnChange ->", objModel);
        this._sharedDataService.setBeforeSaveState(objModel.RegState);
    }

    public async onBeforeSave() {
        if (this.intMode != DBMode.MOSS) {
            if (this.VehClone.UnitType != this.veh.UnitType) {
                if (this.VehClone.UnitType === UnitType.InTransport && this.veh.UnitType !== UnitType.InTransport) {

                    let allowSaveFrom1 = await this._modalService.dialogPromise("You have changed the Unit Type of the Vehicle from " + this.VehClone.UnitType + " to " + (this.veh.UnitType !== -1 ? this.veh.UnitType : ' ') + ". This would delete the Precrash form and all Data in it. Are you sure you want to proceed with this?", "Unit Type has been Changed");

                    if (!allowSaveFrom1) { // No
                        this.veh.UnitType = UnitType.InTransport;
                        this.veh.PreCrash = ObjectUtil.CloneModelObject(this.VehClone.PreCrash, "Veh");
                        this.veh.PreCrash.Veh = this.veh;

                        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == FieldName.UnitType) as TypeaheadComponent;
                        let item = typeahead.options.find(x => x.intValue == 1);
                        typeahead.bindItemAndFocusNextElement(item);
                    }
                    else { // Yes
                        this.VehClone = ObjectUtil.CloneModelObject(this.veh);
                    }
                    this.blnAlloweSave = await allowSaveFrom1;
                }
                else if (this.VehClone.UnitType !== UnitType.InTransport && this.veh.UnitType === UnitType.InTransport) {
                    let alloweSaveTo1;

                    if (this.VehClone.UnitType == UnitType.Blank) {
                        alloweSaveTo1 = true;
                    }
                    else {
                        alloweSaveTo1 = await this._modalService.dialogPromise("You have changed the Unit Type of the Vehicle from " + this.VehClone.UnitType + " to " + this.veh.UnitType + ". This would create the Precrash form. Are you sure you want to proceed with this?", "Unit Type has been Changed");
                    }
                    if (!alloweSaveTo1) { // No
                        this.veh.UnitType = this.VehClone.UnitType;
                        this.veh.PreCrash = ObjectUtil.CloneModelObject(this.VehClone.PreCrash, "Veh");
                        if (this.veh.PreCrash) {
                            this.veh.PreCrash.Veh = this.veh;
                        }
                        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == FieldName.UnitType) as TypeaheadComponent;
                        let item = typeahead.options.find(x => x.intValue == this.VehClone.UnitType);
                        typeahead.bindItemAndFocusNextElement(item);
                    }
                    else if (alloweSaveTo1) { //Yes
                        if (this.veh.PreCrash == null) {
                            this._autofillService.AddPreCrash(this.veh);
                        }
                        if (this.veh.PreCrash.PreCrash_MTSS == null) {
                            this._autofillService.AddPreCrashMTSS(this.veh);
                        }
                    }
                    this.blnAlloweSave = await alloweSaveTo1;
                }
            }
            else {
                this.blnAlloweSave = true;
            }
        }
        else {
            this.blnAlloweSave = true;
        }
        let sbsOnAfterSave: Subscription = this._sharedDataService.observeSave.subscribe(item => {
            sbsOnAfterSave.unsubscribe();
            if (item) {
                this.VehClone = ObjectUtil.CloneModelObject(this.veh);
            }
        })
    }

    ngAfterViewChecked(): void {

    }

    public OnCreatedRecordPrerequisitesChange(objModel: Veh, objNewSelection: DrpDownOptions, objModelOriginal: Veh) {
        if (objNewSelection.intValue != objModelOriginal.NumOccs) {
            this._autofillService.OnCreatedRecordPrerequisitesChange(objModel, 'NumOccs', objNewSelection, objModelOriginal); //Updates objModelOriginal.NumOccs if confirm prompt was not rejected
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsClearForm) {
            this.sbsClearForm.unsubscribe();
        }
    }

    setTravelSpeed($event) {
        let result = $event;
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.TravSpd);
       
        if (component) {
            component.setCurrentValue(result);
            component.onBlur(result);
            this.MarkFormAsDirty();  
        }
    }

    onUnitTypeChange(value) {
        if (value.intValue == 1)
            this.calc = false;
            
        else 
            this.calc = true;
        
    }

}
