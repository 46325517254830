import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

/**
 * The purpose of this class is to override Angular's default route reuse strategy, which reuses a route when only the parameter portions of the route changed.
 * We do NOT want to reuse route to force the component mapped to the route to be recreated and go through the full lifecycle including retrieving the data model,
 * rendering the HTML view (and evaluating autofill business logic attached to Component lifecycle events).
 **/
export class NoReuseRouteReuseStrategy implements RouteReuseStrategy {
    /**
     * Store snapshot before deactivating route?
     */
    public shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false; //Do not fire store()
    }

    /**
     * Nothing is stored in cache
     */
    public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        return;
    }

    /**
     * Retrieve from cached snapshots?
     */
    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false; //Do not fire retrieve()
    }

    /**
     * Nothing is cached, so always return null.
     */
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    /**
     * Reuse current route?
     */
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return false; //Do not reuse current route
    }
}
