import { Component, OnInit } from '@angular/core';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LabelComponent } from '../label/label.component';

@Component({
  selector: 'app-attribute-info-modal',
  templateUrl: './attribute-info-modal.component.html',
  styleUrls: ['./attribute-info-modal.component.css']
})
export class AttributeInfoModalComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal) { }
    options: DrpDownOptions[];
    optionsBackup: DrpDownOptions[];
    currentLabel: LabelComponent
    searchTextValue = '';
    displayText = '';
    ngOnInit() {
        this.optionsBackup = this.options;
    }

    searchText(event: any): void {
        console.log(this.searchTextValue)
        this.options = this.optionsBackup.filter(x => x.displayText.toLowerCase().includes(this.searchTextValue.toLowerCase()));
        console.log(this.optionsBackup)
    }
}
