<form novalidate #fvdn="ngForm">
    <div id="dri-notes" class="pt-2" *ngIf="acc && dri">
        <div [ngClass]="{'col-12' : printOnly === true}">
            <div class="form-group mb-0">
                <div class="px-2">
                    <app-textfield id="DriNotes" [disableLabel]="false"
                                   [blnIsNumber]="false" [intTextAreaRows]="10"
                                   [objModel]="dri" [(objCurrentValue)]="dri.Notes"
                                   [blnFormatWithLeading]="false"
                                   strTableName="Dri" strFieldName="Notes"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-textfield>
                </div>
            </div>
        </div>
    </div>
</form>
