import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from '../../../../services/case.service';
import { VehicleService } from '../../../../services/vehicle.service';
import { UtilService } from '../../../../services/util.service';
import { Acc } from '../../../../models/acc';
import { Veh } from '../../../../models/veh';
import { PreCrash } from '../../../../models/pre-crash';
import { PreCrash_DriVisObs } from '../../../../models/pre-crash-dri-vis-obs';
import { PreCrash_DriAvoidManvr } from '../../../../models/pre-crash-dri-avoid-manvr';
import { PreCrash_DriDistract } from '../../../../models/pre-crash-dri-distract';
import { Element_Specify } from '../../../../models/element-specify';
import { TableFieldElements } from '../../../../models/table-field-elements';
import { DrpDownOptions } from '../../../../models/drp-down-options';
import { FormName, TableId } from '../../../../models/enums/app.enums';
import { TypeaheadComponent } from '../../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { PreCrash_ContribCirc } from 'src/app/models/pre-crash-contrib-circ';

import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

@Component({
    selector: 'app-vehicle-precrash-precrash',
    templateUrl: './vehicle-precrash-precrash.component.html',
    styleUrls: ['./vehicle-precrash-precrash.component.css']
})

export class VehiclePrecrashPrecrashComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehiclePrecrashPrecrashComponent: string = null;
    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    preCrash: PreCrash;
    objElementSpecify: Element_Specify[];

    fieldMetaData: TableFieldElements[];

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    objPreCrashDriVisObsTemplate: PreCrash_DriVisObs;
    objPreCrashDriAvoidManvrTemplate: PreCrash_DriAvoidManvr;
    objPreCrashDriDistractTemplate: PreCrash_DriDistract;
    objInstanceElementSpecify: Element_Specify;
    intMode: number;
    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _vehService: VehicleService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {

        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngOnInit() {
        super.ngOnInit();
        
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });

            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.vehicleid = this.printVehNum;
            this.accid = this.acc.AccID;
            this.initData();
        }

        this.refreshCrashType();
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid)

        if (this.printVehNum > 0 && this.veh.PreCrash == undefined || this.veh.PreCrash == null) {
            this.veh.PreCrash = {} as PreCrash;
        }
        if (this.veh && this.veh.PreCrash) {
            this.preCrash = this.veh.PreCrash;
        }
        //console.log('PreCrash from app-vehicle-precrash-precrash', this.objElementSpecify);

        if (this.preCrash) {
            this.objPreCrashDriVisObsTemplate = ObjectUtil.InstantiatePreCrashDriVisObs(this.preCrash);
            this.objPreCrashDriAvoidManvrTemplate = ObjectUtil.InstantiatePreCrashDriAvoidManvr(this.preCrash);
            this.objPreCrashDriDistractTemplate = ObjectUtil.InstantiatePreCrashDriDistract(this.preCrash);
            this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.preCrash.VNumber, 0);
        }
        this.intMode = this.acc.Mode;
    }

    checkField() {
        console.log('event', this.typeaheads.find(c => c.strFieldName == 'CriticalEvent'));
        console.log('category', this.typeaheads.find(c => c.strFieldName == 'CriticalEventCat'));
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    refreshCrashType() {
        this._caseService.getRefreshPreCrash.subscribe(result => {
            if (result) {
                let field: TypeaheadComponent;
                field = this.typeaheads.find(x => x.strFieldName == 'CrashType');
                if (field != null) {
                    field.FilterFieldOptions();
                    this._caseService.setRefreshPreCrash(false);
                }
            }
        });
    }

    setObjectValue(event) {
        if (event.intValue != -1) {
            let criticalEvent = this.typeaheads.find(c => c.strFieldName == 'CriticalEvent')
            if (criticalEvent) {
                criticalEvent.strDependentFieldName = "CriticalEventCat";
                criticalEvent.strFilterCondition = " AND Category=" + event.intValue;
                criticalEvent.FilterFieldOptions();
            }
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
