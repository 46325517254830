<form novalidate #fvdd="ngForm">
    <div class="pt-2" *ngIf="dri && veh && intMode">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <!-- We want UI fields to be added to HTML DOM as one unit to preserve order of appearance in registration order.
                So, do not put ngIf with different conditions on individual fields.-->
                <div class="form-group pl-2">
                    <app-typeahead id="drivPres" name="drivPres" typeAheadPosition="right-top"
                                   [objModel]="dri.Veh" strTableName="Veh" strFieldName="DrivPres"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>

                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="dZip" name="dZip" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="DZip"
                                   [blnAllowFreeText]="true"
                                   [maxlength]="5"
                                   [(objCurrentValue)]="dri.DZip" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="driViol"
                                     multiSelectPosition="right-top"
                                     [objModel]="dri.DriViol"
                                     strTableName="DriViol"
                                     strFieldName="ElementValue"
                                     [objTemplateModel]="objDriVioTemplate" [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="spdRel" name="spdRel" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="SpdRel" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="driCondAtCrashTime"
                                     name="driCondAtCrashTime"
                                     multiSelectPosition="right-top"
                                     strTableName="DriCondAtCrashTime"
                                     strFieldName="CondAtCrashTime"
                                     [objModel]="dri.DriCondAtCrashTime"
                                     [objTemplateModel]="objDriCondCrashTimeTemplate" [printOnly]="printOnly"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div [ngClass]="{'col-12 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="driRF"
                                     name="driRF"
                                     multiSelectPosition="right-top"
                                     strTableName="DriRF"
                                     strFieldName="DRF"
                                     intSeqNum="1"
                                     [objModel]="dri.Veh.DriRF"
                                     [objTemplateModel]="objDriRF"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify" [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
        </div>
    </div>
</form>
