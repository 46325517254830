import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-vehicle-precrash',
    templateUrl: './vehicle-precrash.component.html',
    styleUrls: ['./vehicle-precrash.component.css']
})

export class VehiclePrecrashComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
