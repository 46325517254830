import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription, Observable } from 'rxjs';

import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { DamagedAreas } from 'src/app/models/damaged-areas';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { DamagedAreasOptions } from 'src/app/models/enums/app.enums';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';

import { VehicleVehicleDamagedAreasService } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-damaged-areas/service/vehicle-vehicle-damaged-areas.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

@Component({
    selector: 'app-vehicle-vehicle-damaged-areas',
    templateUrl: './vehicle-vehicle-damaged-areas.component.html',
    styleUrls: ['./vehicle-vehicle-damaged-areas.component.css']
})


export class VehicleVehicleDamagedAreasComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_VehicleVehicleDamagedAreasComponent: string = null;
    @ViewChild('f') public objForm: NgForm;

    accid: number;
    vehicleid: number;

    private sbsSubscription: Subscription;

    acc: Acc;
    veh: Veh;
    arrDamageAreasOptions: DrpDownOptions[];
    buttonColor: string = "#fff";
    damagedAreasBackup: DamagedAreas[]; //Clone of original data model to allow revert to original

    checkboxStyle = {
        width: "15px",
        height: "15px"
    };
    labelStyle = {
        "padding-left": "15px"
    };

    /**
     * Button style
     **/
    public comboStyle3Box = {
        "background-color": this.buttonColor,
        height: "60px",
        width: "85px",
        border: "solid 1px",
        color: "#000",
        "font-size": "16px",
        "font-weight": "bold"
    };

    /**
     * Button style
     **/
    public comboStyle1Box = {
        "background-color": this.buttonColor,
        border: "solid 1px",
        height: "60px",
        width: "85px",
        color: "#000",
        "font-size": "20px",
        "font-weight": "bold"
    };

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private messageService: VehicleVehicleDamagedAreasService,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
        this.blnFocusOnFirstField = false; //This page has no UIElementBase controls
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        super.ngOnInit();
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe((params => {
                this.accid = + params['caseid'];
                this._route.parent.parent.params.subscribe((params => {
                    this.vehicleid = + params['vehicleid'];
                    this.initData();
                }).bind(this));
            }).bind(this));
            this.initSubscribe()
        } else {

            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum
            this.initData();
            this.initSubscribe()
        }

    }

    initSubscribe() {
        this.sbsSubscription = this.messageService.getMessage().subscribe((message => {
            if (message) {
                if (message.text == 'damagedAreasSave')
                    this.onAfterSaveData();
                if (message.text == 'DamagedAreas') //Clear form
                    this.resetCheckBox();
            }
        }).bind(this));
    }
    async initData() {
        this.arrDamageAreasOptions = await this._utilService.GetDrpDownListOptions('PrImpact', '').toPromise(); // values from the DEFS table
        console.log('this.acc', this.acc);
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.damagedAreasBackup = ObjectUtil.CloneModelObject(this.veh.DamagedAreas);
        this.updateCurrentValues();

        setTimeout(() => { //Time delay needed to let ngFor render checkboxes in HTML DOM
            let btn: HTMLElement = document.getElementById('selectAllCheckBoxes');

            if (btn)
                btn.focus();
        }, 1);

        if (this.printOnly && this.clearFieldData) {
            this.resetCheckBox()
        }

    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    /**
     * Update UI model that CheckBoxes are bound to from data model (vehicle damaged areas values)
     **/
    updateCurrentValues() {
        for (let objOption of this.arrDamageAreasOptions) {
            if (this.veh.DamagedAreas.find(x => objOption.intValue == x.ElementValue) != null)
                objOption.isSpecify = true;
            else
                objOption.isSpecify = false;
        }

        this.ReEvalButtonBackgroundColors();
    }

    onAfterSaveData() {
        this.damagedAreasBackup = ObjectUtil.CloneModelObject(this.veh.DamagedAreas);
    }

    // recall and update the form based on the current values stored in the system
    recallCheckBox() {
        if (this.damagedAreasBackup != null) {
            this.veh.DamagedAreas = ObjectUtil.CloneModelObject(this.damagedAreasBackup);
            this.updateCurrentValues();
        }
    }

    // Select one cell from the image
    selectOneCheckBox(buttonNo: number) {
        let objOption: DrpDownOptions = this.arrDamageAreasOptions.find(x => x.intValue == buttonNo);
        this.isChecked(objOption, !objOption.isSpecify);
        this.objForm.form.markAsDirty();
    }

    // Select one column from the image (Left or Right)
    selectCheckBoxRange(strIds: any) {

        let arrOptionIds: string[] = strIds.split(",");
        for (let strOptionId of arrOptionIds)
            this.isChecked(this.arrDamageAreasOptions.find(x => x.intValue.toString() == strOptionId), true);
        this.objForm.form.markAsDirty();
    }

    //Remove all the selected CheckBoxes
    resetCheckBox() {
        this.arrDamageAreasOptions.forEach(objOption => this.isChecked(objOption, false));
        this.objForm.form.markAsDirty();
    }

    //Select all the CheckBoxes
    selectAllCheckBoxes() {
        this.veh.DamagedAreas = [];
        this.selectCheckBoxRange('1,2,3,4,5,6,7,8,9,10,11,12,13,14');
    }

    /**
     * Update data model based on checked/unchecked checkbox
     */
    public isChecked(objOption: DrpDownOptions, blnSelected: boolean = null): void {
        if (this.blnReadOnly)
            return;

        if (blnSelected == null)
            blnSelected = objOption.isSpecify;
        else if (objOption.isSpecify != blnSelected)
            objOption.isSpecify = blnSelected;

        if (blnSelected == null || blnSelected == false) {
            const getIndex = this.veh.DamagedAreas.findIndex(x => x.ElementValue == objOption.intValue);
            this.veh.DamagedAreas.splice(getIndex, 1);
        }
        else {
            if (objOption.intValue == DamagedAreasOptions.NoDamage || objOption.intValue == DamagedAreasOptions.UnknownDamage) {
                this.arrDamageAreasOptions.forEach(x => { if (x != objOption) x.isSpecify = false; }); //Uncheck other options
                this.veh.DamagedAreas.length = 0; //Clear data model
            }
            else {
                let objOption_NoDamage = this.arrDamageAreasOptions.find(x => x.intValue == DamagedAreasOptions.NoDamage);
                if (objOption_NoDamage.isSpecify) this.isChecked(objOption_NoDamage, false);

                let objOption_Unknown = this.arrDamageAreasOptions.find(x => x.intValue == DamagedAreasOptions.UnknownDamage);
                if (objOption_Unknown.isSpecify) this.isChecked(objOption_Unknown, false);
            }

            this._autofillService.AddDamagedArea(this.veh, objOption.intValue)
            // this.veh.DamagedAreas.push();
        }

        this.ReEvalButtonBackgroundColors();
        this.objForm.form.markAsDirty(); //NgForm not reacting to NgModel update on named checkbox for some reason, possibly because CSS hides (and restyles) actual checkbox input.
    }

    /**
     * Color button backgrounds to match checkbox selections
     **/
    private ReEvalButtonBackgroundColors(): void {
        let objButton: any;

        for (var i = 0; i < this.arrDamageAreasOptions.length; i++) {
            const buttonId = 'button' + (i + 1) + '' + this.vehicleid;
            objButton = document.getElementById(buttonId);

            if (objButton) {
                if (this.arrDamageAreasOptions[i].isSpecify)
                    objButton.style.backgroundColor = "#ecf76c"
                else
                    objButton.style.backgroundColor = "#fff"
            }
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsSubscription) {
            this.sbsSubscription.unsubscribe();
        }
    }
}
