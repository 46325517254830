<div *ngIf="intMode" class="border-dark border-start border-bottom border-end px-2">
    <ul class="nav nav-tabs border-dark bg-white">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./strikingperson"]'
               [ngClass]="{'bg-light': strikinglinkperson.isActive === true }"
               [routerLinkActive]="['active']" #strikinglinkperson="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': strikinglinkperson.isActive === true, 'fw-bold' : strikinglinkperson.isActive === true}">
                        Person
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingsafety"]'
               [ngClass]="{'bg-light': linksafetyDevices.isActive === true }"
               [routerLinkActive]="['active']" #linksafetyDevices="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksafetyDevices.isActive === true, 'fw-bold' : linksafetyDevices.isActive === true}">
                        Safety
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingpersonalcohol"]'
               [ngClass]="{'bg-light': linkpersonAlcohol.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonAlcohol="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonAlcohol.isActive === true, 'fw-bold' : linkpersonAlcohol.isActive === true}">
                        Contributory Factors
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item ml-1 mt-1" *ngIf="intOccId == 1">
            <a class="nav-link" [routerLink]='["./strikingdriver"]'
               [ngClass]="{'bg-light': linkstrikingdriver.isActive === true }"
               [routerLinkActive]="['active']" #linkstrikingdriver="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': strikinglinkperson.isActive === true, 'fw-bold' : linkstrikingdriver.isActive === true}">
                        Driver
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1"  *ngIf="intOccId == 1">
            <a class="nav-link" [routerLink]='["./strikingdrivernotes"]'
               [ngClass]="{'bg-light': strikingdrivernotes.isActive === true }"
               [routerLinkActive]="['active']" #strikingdrivernotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': strikingdrivernotes.isActive === true, 'fw-bold' : strikingdrivernotes.isActive === true}">
                        Driver_Notes
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingpersonnotes"]'
               [ngClass]="{'bg-light': linkpersonNotes.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonNotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonNotes.isActive === true, 'fw-bold' : linkpersonNotes.isActive === true}">
                        Notes
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <!-- Router outlet hosts Vehicle Occupant child route components' HTML output -->
    <router-outlet></router-outlet>
</div>
