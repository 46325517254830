<!-- CSS class "card" is the Bootstrap 4 implementation of a panel in Bootstrap 3 -->
<!-- CSS class "my-1" means margin vertically (y-axis), ml-1 means margin left 1 -->
<!-- CSS class "fas" stands for Font Awesome -->
<!-- CSS class "nav-pills" is required for nav-link.active to work -->
<div class="nav-pills mt-3" [ngClass]="{ 'card border-dark p-3' : blnBorder }" *ngIf="treeData">
    <div>
        <span *ngIf="treeData.arrChildren.length != 0" (click)="toggleExpand(this)">
            <span class="p-1" *ngIf="!treeData.blnExpanded"><i class="fas fa-plus"></i></span>
            <span class="p-1" *ngIf="treeData.blnExpanded"><i class="fas fa-minus"></i></span>
        </span>
        <!-- CSS class "nav-link" applies style "display:block", which we override -->
        <a *ngIf="blnEnableNav"
            id="{{strId}}" #lblNode [tabindex]="tabindex"
            class="nav-link" style="display:inline !important;"
            (keydown)="handleKeydown($event)"
            [routerLink]="[treeData.strUrl]"
            [routerLinkActive]="['active']" 
            [routerLinkActiveOptions]="{exact: true}">
            {{ treeData.strTitle }}
        </a>
        <!-- tabindex attribute is necessary for SPAN element to be able to receive focus (even programmatically) -->
        <span *ngIf="!blnEnableNav"
              id="{{strId}}" #lblNode [tabindex]="tabindex"
              class="nav-link" [ngClass]="{ 'active' : treeData.blnSelected, 'font-italic': treeData.eSelectability != 1, 'table-dark': objRootComponentElement == _this }"
              style="display:inline !important;"
              (click)="toggleSelect(this)"
              (keydown)="handleKeydown($event)">
            {{ treeData.strTitle }}
        </span>
        <ul *ngIf="treeData.blnExpanded" class="nav nav-pills flex-column">
            <li *ngFor="let objChild of treeData.arrChildren" class="nav-item">
                <div *ngIf="objChild.blnVisible" class="my-2 mx-4">
                    <!-- Although a one-time binding for "blnBorder" suffices, Angular will change the data type from boolean to string unless we use the braketed one-way binding -->
                    <app-tree-view #treeViewChild [treeData]="objChild" [blnBorder]="false" strIdPrefix="{{strId}}"
                                   [objRootComponentElement]="objRootComponentElement" [tabindex]="tabindex"></app-tree-view>
                </div>
            </li>
        </ul>
    </div>
</div>
