import { Component, OnInit } from '@angular/core';
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-editcheck-message',
    templateUrl: './editcheck-message.component.html',
    styleUrls: ['./editcheck-message.component.css']
})
export class EditcheckMessageComponent implements OnInit {
    public messageItem: any

    constructor(private messageService: ModalService) {

    }

    reset() {
        this.messageService.setEditCheckMessage('');

    }

    ngOnInit() {
        this.messageService.getCurrentEditCheckMessage.subscribe((y => {
            this.messageItem = y;
        }).bind(this));
    }

}
