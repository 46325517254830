import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, OnDestroy, PipeTransform, ViewEncapsulation, ViewChild, ElementRef, NgModule } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { DecimalPipe, DatePipe} from '@angular/common';

import { ReplaceTextPipe } from 'src/app/pipes/replaceText.pipe';
import { EnumAsStringPipe } from 'src/app/pipes/enum-as-string.pipe'
import { UsernamePipe } from 'src/app/pipes/username.pipe';

import { Observable, BehaviorSubject, Subscription, of, Subject, empty } from 'rxjs';
import { debounceTime, delay, switchMap, tap, finalize, share, map, catchError, delayWhen } from 'rxjs/operators';

import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { UtilService } from 'src/app/services/util.service';
import { MonthlyCountService } from 'src/app/services/monthly-count.service';

import { Acc } from 'src/app/models/acc';
import { EarlyNotify } from 'src/app/models/early-notify';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { EarlyNotifyType, DBMode, EDTStatus, States, KeyCode } from 'src/app/models/enums/app.enums'

import { EarlyNotificationsSearchResult } from './../../../models/early-notifications-search-result';
import { EarlyNotificationState } from './../../../models/early-notification-state';
import { EarlyNotificationFilters } from './../../../models/early-notification-filters';

import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable-header.directive';
import { SortDirection } from './../../../directives/sortable-header.directive';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { AuthService } from 'src/app/services/auth.service';
import { UserClient } from 'src/app/models/user-client';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';


export interface ENMonthlyCount {
    MonthNum: number;
    ReportValue: number;
    OfficialCount: number;
    NotOfficialCount: number;
}

export interface ENMonthlyCountFilters {
    blnIsOfficialreconcileable: boolean
    intMonth: number;
    intMonthlyCountTypeID: number;
}

export const ISSTATEOFFICIALCRASH = [
    { value: -1, text: ' ' },
    { value: 1, text: 'Official' },
    { value: 0, text: 'Non Official' }
];

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(earlyNotifications: EarlyNotify[], column: string, direction: string): EarlyNotify[] {
    if (direction === '') {
        if (column === 'MOSSCasenum') {
            {
                return [...earlyNotifications].sort((a, b) => {
                    if (a.Acc && b.Acc) {
                        if (a.Acc.Acc_SS != null && b.Acc.Acc_SS) {
                            if (a.Acc.Acc_SS.MOSSCasenum && b.Acc.Acc_SS.MOSSCasenum) {

                                const res = compare(Number(a.Acc.Acc_SS.MOSSCasenum.substring(3)), Number(b.Acc.Acc_SS.MOSSCasenum.substring(3)));
                                return res;
                            }
                        }
                    }
                });
            }
        }
        return earlyNotifications;
    } else {
        if (column === 'MathAnalyCaseNum' || column === 'Casenum') {
            return [...earlyNotifications].sort((a, b) => {
                const res = compare(a.Acc[column], b.Acc[column]);
                return direction === 'asc' ? res : -res;
            });
        }
        if (column === 'MOSSCasenum') {
            return [...earlyNotifications].sort((a, b) => {
                if (a.Acc && b.Acc) {
                    if (a.Acc.Acc_SS != null && b.Acc.Acc_SS) {
                        if (a.Acc.Acc_SS.MOSSCasenum && b.Acc.Acc_SS.MOSSCasenum) {
                           
                            const res = compare(Number(a.Acc.Acc_SS.MOSSCasenum.substring(3)), Number(b.Acc.Acc_SS.MOSSCasenum.substring(3)));
                            return direction === 'asc' ? res : -res;
                        }
                    }
                }
            });
        }
        else{
            return [...earlyNotifications].sort((a, b) => {
                const res = compare(a[column], b[column]);
                 return direction === 'asc' ? res : -res;
            });
        }
    }
}

function sortByMossCaseNum(earlyNotifications: EarlyNotify[], direction: string): EarlyNotify[] {
    if (direction === '') {
        return earlyNotifications;
    }
    else {
        return [...earlyNotifications].sort((a, b) => {
            if (a.Acc && b.Acc) {
                if (a.Acc.Acc_SS != null && b.Acc.Acc_SS) {
                    if (a.Acc.Acc_SS.MOSSCasenum && b.Acc.Acc_SS.MOSSCasenum) {
                        const res = compare(Number(a.Acc.Acc_SS.MOSSCasenum.substring(3)), Number(b.Acc.Acc_SS.MOSSCasenum.substring(3)));
                        return direction === 'asc' ? res : -res;
                    }
                }
            }
        });
    }
}
//function matcheENCaseNum(earlyNotifications: EarlyNotify, replaceTextPipe: ReplaceTextPipe, pipe: PipeTransform, searchTerm: string) {
//    if (earlyNotifications.ENCaseNum) {
//        return replaceTextPipe.transform(pipe.transform(earlyNotifications.ENCaseNum, '4.0-0').toString(), ',', '').includes(searchTerm);
//    }
//}
//function matcheMathAnalysisCase(earlyNotifications: EarlyNotify, replaceTextPipe: ReplaceTextPipe, pipe: PipeTransform, searchTerm: string) {
//    if (earlyNotifications.Acc) {
//        if (earlyNotifications.Acc.MathAnalyCaseNum) {
//            return replaceTextPipe.transform(pipe.transform(earlyNotifications.Acc.MathAnalyCaseNum, '3.0-0').toString(), ',', '').includes(searchTerm);
//        }
//    }
//}

//function matchesMDECaseNum(earlyNotifications: EarlyNotify, replaceTextPipe: ReplaceTextPipe, pipe: PipeTransform, searchTerm: string) {
//    if (earlyNotifications.MDECaseNum) {
//        return replaceTextPipe.transform(pipe.transform(earlyNotifications.MDECaseNum, '4.0-0').toString(), ',', '').includes(searchTerm);
//    }
//}

function matchesCaseNum(earlyNotifications: EarlyNotify, replaceTextPipe: ReplaceTextPipe, pipe: PipeTransform, searchTerm: string) {
    if (earlyNotifications.Acc) {
        if (earlyNotifications.Acc.Casenum) {
            return replaceTextPipe.transform(pipe.transform(earlyNotifications.Acc.Casenum, '3.0-0').toString(), ',', '').includes(searchTerm);
        }
    }
}

function matchesMOSSCaseNum(earlyNotifications: EarlyNotify, searchTerm: string) {
    if (earlyNotifications.Acc) {
        if (earlyNotifications.Acc.Acc_SS != null) {
            if (earlyNotifications.Acc.Acc_SS.MOSSCasenum) {
                return earlyNotifications.Acc.Acc_SS.MOSSCasenum.toString().toLowerCase().includes(searchTerm.toLowerCase());
            }
        }
    }
}

function matcheStateCaseNum(earlyNotifications: EarlyNotify, searchTerm: string) {
    if (earlyNotifications.StateCaseNum) {

        return earlyNotifications.StateCaseNum.toString().toLowerCase().includes(searchTerm.toLowerCase());
    }
}

//function matcheTechnician(earlyNotifications: EarlyNotify, username: UsernamePipe, pipe: PipeTransform, searchTerm: string) {
//    if (earlyNotifications.Acc.CaseStats.CrUserID) {
//        return username.transform(pipe.transform(earlyNotifications.Acc.CaseStats.CrUserID, '3.0-0').toString(), ',', '').includes(searchTerm);

//        return earlyNotifications.StateCaseNum.toString().toLowerCase().includes(searchTerm.toLowerCase());
//    }
//}

function matchesCrashDate(earlyNotifications: EarlyNotify, pipe: PipeTransform, searchTerm: string) {
    if (earlyNotifications.AccDate) {

        let strAccDate: string;

        strAccDate = pipe.transform(earlyNotifications.AccMonth, '2.0-0');
        strAccDate = strAccDate + '/';
        strAccDate = strAccDate + pipe.transform(earlyNotifications.AccDay, '2.0-0');
        strAccDate = strAccDate + ' ';

        if (earlyNotifications.AccHr == -1) {
            strAccDate = strAccDate + '--';
        }
        else {
            strAccDate = strAccDate + pipe.transform(earlyNotifications.AccHr, '2.0-0');
        }

        strAccDate = strAccDate + ':';

        if (earlyNotifications.AccMin == -1) {
            strAccDate = strAccDate + '--';
        }
        else {
            strAccDate = strAccDate + pipe.transform(earlyNotifications.AccMin, '2.0-0');
        }

        return strAccDate.toString().includes(searchTerm.toLowerCase());
    }
}

function matchesJurisdiction(earlyNotifications: EarlyNotify, searchTerm: string) {
    if (earlyNotifications.Jurisdiction) {
        return earlyNotifications.Jurisdiction.toString().toLowerCase().includes(searchTerm.toLowerCase());
    }
}

//function matchesENType(earlyNotifications: EarlyNotify, searchTerm: string) {
//    if (earlyNotifications.CrashType) {
//        return earlyNotifications.ENType.toString().toLowerCase().includes(searchTerm.toLowerCase());
//    }
//}

function matchesFatals(earlyNotifications: EarlyNotify, pipe: PipeTransform, searchTerm: string) {
    if (earlyNotifications.NoOfFatals || earlyNotifications.NoOfFatals == 0) {
        return pipe.transform(earlyNotifications.NoOfFatals).includes(searchTerm);
    }
}

function matchesComments(earlyNotifications: EarlyNotify, searchTerm: string) {
    if (earlyNotifications.Remarks) {
        return earlyNotifications.Remarks.toLowerCase().includes(searchTerm.toLowerCase());
    }
}

function matchesStatus(earlyNotifications: EarlyNotify, enumAsStringPipe: EnumAsStringPipe, searchTerm: string) {
    if (earlyNotifications.Acc) {
        if (earlyNotifications.Acc.Status && earlyNotifications.Acc.Status != null) {
            return enumAsStringPipe.transform(earlyNotifications.Acc.Status, 'EDTStatus').toLowerCase().includes(searchTerm.toLowerCase());
        }
    }
}

function matchesCaseStatus(earlyNotifications: EarlyNotify, searchTerm: string) {
    if (earlyNotifications.Acc) {
        if (earlyNotifications.strCaseStatus && earlyNotifications.strCaseStatus != null) {
            return( earlyNotifications.strCaseStatus).toLowerCase().includes(searchTerm.toLowerCase());
        }
    }
}

function matchesPARNum(earlyNotifications: EarlyNotify, searchTerm: string) {
    if (earlyNotifications.PARNumber) {
        return earlyNotifications.PARNumber.toString().toLowerCase().includes(searchTerm.toLowerCase());
    }
}


function matchesTechnician(earlyNotifications: EarlyNotify, searchTerm: string, userList: any) {
    if (earlyNotifications.Acc.CaseStats.CrUserID) {
        //get userlist and match with search term
        let users = userList.filter(o => o.FULLNAME.toLowerCase().includes(searchTerm.toLowerCase()) && o.SECUSERID === earlyNotifications.Acc.CaseStats.CrUserID);      
        if (users.length > 0)
            return earlyNotifications.Acc.CaseStats.CrUserID.toString().toLowerCase().includes(users[0].SECUSERID.toString().toLowerCase());
        else
            return null;
       
    }
}

@Component({
    selector: 'app-early-notification-details',
    templateUrl: './early-notification-details.component.html',
    styleUrls: ['./early-notification-details.component.css'],
    providers: [
        EarlyNotificationService,
        ReplaceTextPipe, EnumAsStringPipe, DecimalPipe, DatePipe, UsernamePipe, NgForm, ValueToDescriptionPipe,
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
    ]

})

export class EarlyNotificationDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    public strModeName: string = 'FARS';
    selectedYear: number;
    selectedMode: number;

    arrStates: DrpDownOptions[];
    ddoSelectedState: DrpDownOptions;

    blnIsEDTstate: boolean;
    blnIsCRSSEDTstate: boolean;

    enid: number;

    acc: Acc;
    EARLYNOTIFICATIONS: EarlyNotify[];

    isENScreenerCollapsed: boolean;

    @ViewChild('dpcdf') dateFrom: ElementRef;
    @ViewChild('dpcdt') dateTo: ElementRef;
    @ViewChild('crashTypeForSearchCriteria') public strCrashType: ElementRef;

    strMinValidation: string;
    streMaxValidation: string;

    blnShowValidationMessageDateFrom: boolean = false;
    blnShowValidationMessageDateTo: boolean = false;

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    arrStatus: any;
    arrLevelRanking: any;
    arrStateOfficalCrash: any;

    edtStatusList: any;
    EDTStatus: EDTStatus;

    //crashTypeList: DrpDownOptions[];
    //jurisdictionList: DrpDownOptions[];

    blankOption: DrpDownOptions = {} as DrpDownOptions;
    AllOption: DrpDownOptions = {} as DrpDownOptions;

    //region for en filters
    earlyNotificationFilters: EarlyNotificationFilters;
    //end of region

    //region for en filter criteria
    criCrashDateFrom: Date;
    criCrashDateTo: Date;
    criCaseStatus: number;
    criCaseLevelRankings: number;
    criEdtStatus: number;
    //criCrashType: number;
    //criJurisdiction: number;
    criPARNumber: string;
    caseStatus: string;

    getMinDate: NgbDate;
    getMaxDate: NgbDate;
    //end of region    

    public blnAllowSearch: boolean = true;

    //public blnIsOfficialReconcile: boolean = false;

    ENMonthlyCount: ENMonthlyCount;

    public blnIsOfficialreconcileable: boolean;
    public intMonth: number;
    public intMonthlyCountTypeID: number;

    earlyNotificationSubscription: Subscription;

    //region for en grid variables
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _earlyNotifications$ = new BehaviorSubject<EarlyNotify[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    public intTotal: number;

    public _state: EarlyNotificationState; //Must be public to be accessible in HTML template in a release build

    public cookieENGridState: EarlyNotificationState;
    public cookieENGridFilters: EarlyNotificationFilters;

    public ColummForSorting: string = '';
    public DirectionForSorting: string = '';
    isAuthorized: boolean;
    userList: UserClient[];

    //end of region

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _autofillService: AutofillService,
        private _caseService: CaseService,
        private _modalService: ModalService,
        private _utilService: UtilService,
        private _sharedDataService: SharedDataService,
        public earlyNotificationService: EarlyNotificationService,
        private _monthlyCountService: MonthlyCountService,
        private replaceTextPipe: ReplaceTextPipe,
        private enumAsStringPipe: EnumAsStringPipe,
        public pipe: DecimalPipe,
        public datePipe: DatePipe,
        public username: UsernamePipe,
        private calendar: NgbCalendar,
        private authService: AuthService
    ) {
       
    }

    async ngOnInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.selectedMode = appSettings.intMode;

        this.userList = await this._utilService.GetUserList();

        if (DBMode[appSettings.intMode])
            this.strModeName = DBMode[appSettings.intMode];

        this.isENScreenerCollapsed = false;

        this.selectedYear = appSettings.intYear;
        
        this.selectedMode = appSettings.intMode;

        let todaysDate: Date = new Date(new Date().setFullYear(this.selectedYear));

        this.getMinDate = new NgbDate(todaysDate.getFullYear() - 120, todaysDate.getMonth() + 1, todaysDate.getDate());
        this.getMaxDate = new NgbDate(todaysDate.getFullYear(), todaysDate.getMonth() + 1, todaysDate.getDate());

        if (this.selectedMode == DBMode.MTSS || this.selectedMode == DBMode.PPSS) {
            this.getMinDate = new NgbDate(2018, 1, 1);
            this.getMaxDate = new NgbDate(2018, 12, 31);
        }
        let currentYear = new Date().getFullYear();
        let dateTodays: Date = new Date(new Date().setFullYear(currentYear));
        if (this.selectedMode == DBMode.MOSS)
            this.getMaxDate = new NgbDate(dateTodays.getFullYear(), dateTodays.getMonth() + 1, dateTodays.getDate());

        this.strMinValidation = (this.getMinDate.month).toString() + "/" + this.getMinDate.day.toString() + "/" + this.getMinDate.year.toString();
        this.streMaxValidation = (this.getMaxDate.month).toString() + "/" + this.getMaxDate.day.toString() + "/" + this.getMaxDate.year.toString();

        this.blankOption.intValue = -1;
        this.blankOption.strText = ' ';

        this.AllOption.intValue = 99999;
        this.AllOption.strText = "All";
        this.AllOption.displayText = "All";
        this.caseStatus = "All";


        this._route.params.subscribe(params => {
            this._utilService.GetDrpDownListOptions("StateNum", '').subscribe(result => {
                if (result) {
                    let arrStates: Array<number> = [];
                    result = result.filter(i => i.tblName == LookupTable.StateNum && i.intValue != 98 && i.intValue != 99);
                    if (result) {
                        result.forEach(item => {
                            arrStates.push(item.intValue);
                        });
                        this._sharedDataService.setListofValidStates(arrStates);
                    }
                }
            });

            if (this._sharedDataService.getCookie('cookieENFilters')) {
                let cookieENFilters = this._sharedDataService.getCookie('cookieENFilters');
                this.earlyNotificationFilters = JSON.parse(cookieENFilters);

                if (this.earlyNotificationFilters.CrashDateFrom) {
                    this.earlyNotificationFilters.CrashDateFrom = new Date(this.earlyNotificationFilters.CrashDateFrom);
                }
                if (this.earlyNotificationFilters.CrashDateTo) {
                    this.earlyNotificationFilters.CrashDateTo = new Date(this.earlyNotificationFilters.CrashDateTo);
                }
            }

            else {
               
                if (this.selectedMode == 256) 
                    this.earlyNotificationFilters = this.setMossDefaultFilters();
              
                else
                    this.earlyNotificationFilters = this.setDefaultFilters();
            }

            if (this._sharedDataService.getCookie('cookieENPageState')) {
                let cookieENPageState = this._sharedDataService.getCookie('cookieENPageState');
                this._state = JSON.parse(cookieENPageState);
            }

            else {
                if (this.selectedMode == 256)
                    this._state = this.setMossDefaultPageState();

                else
                this._state = this.setDefaultPageState();
            }

            this.arrStateOfficalCrash = ISSTATEOFFICIALCRASH;

            this.getEarlyNotifications(this.earlyNotificationFilters);
            //sortByMossCaseNum(this.EARLYNOTIFICATIONS, 'asc');
        });

        //this.crashTypeList = await this._utilService.GetDrpDownListOptions("ENType", '').toPromise();      
        //this.crashTypeList.splice(0, 0, this.AllOption);
        //this.crashTypeList.splice(0, 0, this.blankOption);

        this.arrStatus = await this._utilService.GetDrpDownListOptions("CaseStatus", '').toPromise();
        this.arrStatus.splice(0, 0, this.AllOption);
        //this.arrStatus.splice(0, 0, this.blankOption);

        //this.arrLevelRanking = await this._utilService.GetDrpDownListOptions("CaseLevels", '').toPromise();
        //this.arrLevelRanking.splice(0, 0, this.blankOption);

        this.edtStatusList = await this._utilService.GetDrpDownListOptions("EDT_CaseStatus", '').toPromise();
        this.edtStatusList.splice(0, 0, this.AllOption);
        this.edtStatusList.splice(0, 0, this.blankOption);

        this._modalService.setEditCheckMessage('');
    }

    setDefaultFilters(): EarlyNotificationFilters {
        let dateNow: Date = new Date();

        let ENDefaultFilters = {
            Mode: this.selectedMode,
            //StateNum: this.stateNum,
            blnIsEDTstate: this.blnIsEDTstate,
            CrashDateFrom: new Date(2018, 0, 1),
            CrashDateTo: new Date(2018, 11, 31),
          
            //CrashType: 1,
            //Jurisdiction: null,
            CaseStatus: 99999,
            //CaseLevelRankings: null,
            EdtStatus: null,
            //PARNumber: null,
            //StateOfficalCrash: null

        } as EarlyNotificationFilters;

        return ENDefaultFilters;
    }

    setMossDefaultFilters(): EarlyNotificationFilters {
        let dateNow: Date = new Date();

        let ENDefaultFilters = {
            Mode: this.selectedMode,
            //StateNum: this.stateNum,
            blnIsEDTstate: this.blnIsEDTstate,
            CrashDateFrom: new Date(2019, 0, 1),
            CrashDateTo: new Date(),

            //CrashType: 1,
            //Jurisdiction: null,
            CaseStatus: 99999,
            //CaseLevelRankings: null,
            EdtStatus: null,
            //PARNumber: null,
            //StateOfficalCrash: null

        } as EarlyNotificationFilters;

        return ENDefaultFilters;
    }

    setDefaultPageState(): EarlyNotificationState {
        let _state: EarlyNotificationState = {
            page: 1,
            pageSize: 100,
            
            searchCaseNumTerm: '',
            searchMOSSCaseNumTerm: '',
            searchStateCaseNumTerm: '',
            searchCrashDateTerm: '',
          
            searchFatalsTerm: '',
            searchCommentsTerm: '',
            searchStatusTerm: '',
            searchPARNumTerm: '',
            searchTechnicianTerm: '',
            searchCaseStatTerm: '',
            sortColumn:'Casenum',
            sortDirection: 'desc'
        };
        return _state;
    }
    setMossDefaultPageState(): EarlyNotificationState {
        let _state: EarlyNotificationState = {
            page: 1,
            pageSize: 100,

            searchCaseNumTerm: '',
            searchMOSSCaseNumTerm: '',
            searchStateCaseNumTerm: '',
            searchCrashDateTerm: '',

            searchFatalsTerm: '',
            searchCommentsTerm: '',
            searchStatusTerm: '',
            searchPARNumTerm: '',
            searchTechnicianTerm: '',
            searchCaseStatTerm: '',
            sortColumn: 'MOSSCasenum',
            sortDirection: 'asc'
        };
        return _state;
    }

    onDateFromSelect(dpcdf) {
        this._modalService.resetMessage();
        ObjectUtil.DateMask(event);
        this.blnShowValidationMessageDateFrom = false;
        if (!dpcdf.invalid) {
            this.earlyNotificationFilters.CrashDateFrom = dpcdf.value;
        }
    }

    onDateToSelect(dpcdt) {
        this._modalService.resetMessage();
        ObjectUtil.DateMask(event);
        this.blnShowValidationMessageDateTo = false;
        if (!dpcdt.invalid) {

            this.earlyNotificationFilters.CrashDateTo = dpcdt.value;

        }
    }

    //region for en grid
    get earlyNotifications$() { return this._earlyNotifications$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }

    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    //get searchENCaseNumTerm() { return this._state.searchENCaseNumTerm; }
    //get searchMathAnalysisCaseNumberTerm() { return this._state.searchMathAnalysisCaseNumberTerm; }

    //get searchMDECaseTerm() { return this._state.searchMDECaseTerm; }
    get searchCaseNumTerm() { return this._state.searchCaseNumTerm; }
    get searchMOSSCaseNumTerm() { return this._state.searchMOSSCaseNumTerm; }
    get searchStateCaseNumTerm() { return this._state.searchStateCaseNumTerm; }
    get searchCrashDateTerm() { return this._state.searchCrashDateTerm; }
    //get searchJurisdictionTerm() { return this._state.searchJurisdictionTerm; }
    //get searchENTypeTerm() { return this._state.searchENTypeTerm; }
    get searchFatalsTerm() { return this._state.searchFatalsTerm; }
    get searchCommentsTerm() { return this._state.searchCommentsTerm; }
    get searchStatusTerm() { return this._state.searchStatusTerm; }
    get searchTechnicianTerm() { return this._state.searchTechnicianTerm; }
    get searchCaseStatTerm() { return this._state.searchCaseStatTerm; }
    get searchPARNumTerm() { return this._state.searchPARNumTerm; }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }

    //set searchENCaseNumTerm(searchENCaseNumTerm: string) { this._set({ searchENCaseNumTerm }); }
    //set searchMathAnalysisCaseNumberTerm(searchMathAnalysisCaseNumberTerm: string) { this._set({ searchMathAnalysisCaseNumberTerm }); }

    //set searchMDECaseTerm(searchMDECaseTerm: string) { this._set({ searchMDECaseTerm }); }
    set searchCaseNumTerm(searchCaseNumTerm: string) { this._set({ searchCaseNumTerm }); }
    set searchMOSSCaseNumTerm(searchMOSSCaseNumTerm: string) { this._set({ searchMOSSCaseNumTerm }); }
    set searchStateCaseNumTerm(searchStateCaseNumTerm: string) { this._set({ searchStateCaseNumTerm }); }
    set searchCrashDateTerm(searchCrashDateTerm: string) { this._set({ searchCrashDateTerm }); }
    //set searchJurisdictionTerm(searchJurisdictionTerm: string) { this._set({ searchJurisdictionTerm }); }
    //set searchENTypeTerm(searchENTypeTerm: string) { this._set({ searchENTypeTerm }); }
    set searchFatalsTerm(searchFatalsTerm: string) { this._set({ searchFatalsTerm }); }
    set searchCommentsTerm(searchCommentsTerm: string) { this._set({ searchCommentsTerm }); }
    set searchStatusTerm(searchStatusTerm: string) { this._set({ searchStatusTerm }); }
    set searchPARNumTerm(searchPARNumTerm: string) { this._set({ searchPARNumTerm }); }
    set searchTechnicianTerm(searchTechnicianTerm: string) { this._set({ searchTechnicianTerm }); }
    set searchCaseStatTerm(searchCaseStatTerm: string) { this._set({ searchCaseStatTerm }); }

    set sortColumn(sortColumn: string) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    private _set(patch: Partial<EarlyNotificationState>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<EarlyNotificationsSearchResult> {
        //searchENTypeTerm, searchJurisdictionTerm
        const { sortColumn, sortDirection, pageSize, page, searchCaseNumTerm, searchMOSSCaseNumTerm, searchStateCaseNumTerm, searchCrashDateTerm, searchFatalsTerm, searchCommentsTerm, searchStatusTerm, searchPARNumTerm, searchTechnicianTerm, searchCaseStatTerm } = this._state;

        let earlyNotifications = [] as EarlyNotify[];

        if (this.blnAllowSearch) {
            earlyNotifications = sort(this.EARLYNOTIFICATIONS, sortColumn, sortDirection);
        }

        this.ColummForSorting = sortColumn;
        this.DirectionForSorting = sortDirection;

        //if (searchMathAnalysisCaseNumberTerm !== '') {
        //    earlyNotifications = earlyNotifications.filter(earlyNotification => matcheMathAnalysisCase(earlyNotification, this.replaceTextPipe, this.pipe, searchMathAnalysisCaseNumberTerm));
        //}
        //if (searchMDECaseTerm !== '') {
        //    earlyNotifications = earlyNotifications.filter(earlyNotification => matchesMDECaseNum(earlyNotification, this.replaceTextPipe, this.pipe, searchMDECaseTerm));
        //}
        if (searchCaseNumTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesCaseNum(earlyNotification, this.replaceTextPipe, this.pipe, searchCaseNumTerm));
        }
        if (searchMOSSCaseNumTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesMOSSCaseNum(earlyNotification, searchMOSSCaseNumTerm));
        }
        if (searchStateCaseNumTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matcheStateCaseNum(earlyNotification, searchStateCaseNumTerm));
        }
        if (searchCrashDateTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesCrashDate(earlyNotification, this.pipe, searchCrashDateTerm));
        }
        //if (searchJurisdictionTerm !== '') {
        //    earlyNotifications = earlyNotifications.filter(earlyNotification => matchesJurisdiction(earlyNotification, searchJurisdictionTerm));
        //}
        //if (searchENTypeTerm !== '') {
        //    earlyNotifications = earlyNotifications.filter(earlyNotification => matchesENType(earlyNotification, searchENTypeTerm));
        //}
        if (searchFatalsTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesFatals(earlyNotification, this.pipe, searchFatalsTerm));
        }
        if (searchCommentsTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesComments(earlyNotification, searchCommentsTerm));
        }
        if (searchStatusTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesStatus(earlyNotification, this.enumAsStringPipe, searchStatusTerm));
        }
        if (searchPARNumTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesPARNum(earlyNotification, searchPARNumTerm));
        }
        if (searchTechnicianTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesTechnician(earlyNotification, searchTechnicianTerm, this.userList));
        }
        if (searchCaseStatTerm !== '') {
            earlyNotifications = earlyNotifications.filter(earlyNotification => matchesCaseStatus(earlyNotification, searchCaseStatTerm));
        }

        const total = earlyNotifications.length;
        earlyNotifications = earlyNotifications.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

        return of({ earlyNotifications, total });
    }
    //end of region

    



    public async getEarlyNotifications(filters: EarlyNotificationFilters) {
        this.earlyNotificationSubscription = this.earlyNotificationService.GetEarlyNotifications(filters)
            
            .subscribe(result => {
            this.EARLYNOTIFICATIONS = result;
            console.log('EARLYNOTIFICATIONS: ', this.EARLYNOTIFICATIONS);
             // hook up search after grid data is avaiable becouse it will filter as part of the config. 
            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(300),
                switchMap(() => this._search()),
                delay(200),
                finalize(() => this._loading$.next(false)),
                tap(() => this._loading$.next(false)),

            ).subscribe(result => {
                this._earlyNotifications$.next(result.earlyNotifications);
                this._total$.next(result.total);
                this.intTotal = result.total;
                this.criCrashDateFrom = this.earlyNotificationFilters.CrashDateFrom;
                this.criCrashDateTo = this.earlyNotificationFilters.CrashDateTo;
                this.criCaseStatus = this.earlyNotificationFilters.CaseStatus;
                //this.criCaseLevelRankings = this.earlyNotificationFilters.CaseLevelRankings;
                this.criEdtStatus = this.earlyNotificationFilters.EdtStatus;
                //this.criCrashType = this.earlyNotificationFilters.CrashType;
                //this.criJurisdiction = this.earlyNotificationFilters.Jurisdiction;
                //this.criPARNumber = this.earlyNotificationFilters.PARNumber;

            });

            this._search$.next();
        });
    }

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });
        this.sortColumn = column;
        this.sortDirection = direction;

        this.ColummForSorting = column;
        this.DirectionForSorting = direction;
       
    }

    onMossSort({ column, direction }: SortEvent) {

        this.sortColumn = column;
        this.sortDirection = direction;
        sortByMossCaseNum(this.EARLYNOTIFICATIONS, direction);

        this.ColummForSorting = column;
        this.DirectionForSorting = direction;
    }
    

    validateDateFrom(dateFrom): boolean {
        this.blnShowValidationMessageDateFrom = false;

        if (!dateFrom.value) {
            this._modalService.setMessage('Please enter Crash Date (From)', 'danger');
            this.blnShowValidationMessageDateFrom = true;
            return false;
        }

        if (dateFrom.value) {
            if (dateFrom.invalid) {
                this.blnShowValidationMessageDateFrom = true;

                if (dateFrom.errors.ngbDate.invalid) {
                    this._modalService.setMessage('Crash Date (From) must be a valid date (Ex: mm/dd/yyyy).', 'danger');
                    return false;
                }
                if (dateFrom.errors.ngbDate.requiredBefore || dateFrom.errors.ngbDate.requiredAfter) {
                    this._modalService.setMessage("Crash Date (From) must be between " + this.strMinValidation + " and " + this.streMaxValidation + ".", 'danger');
                    return false;
                }

            }
        }
        return true;
    }

    validateDateTo(dateTo): boolean {
        this.blnShowValidationMessageDateTo = false;

        if (!dateTo.value) {
            this._modalService.setMessage('Please enter Crash Date (To)', 'danger');
            this.blnShowValidationMessageDateTo = true;
            return false;
        }

        if (dateTo.value) {
            if (dateTo.invalid) {
                this.blnShowValidationMessageDateTo = true;

                if (dateTo.errors.required) {
                    this._modalService.setMessage('Crash Date (To) must', 'danger');
                    return false;
                }

                if (dateTo.errors.ngbDate.invalid) {
                    this._modalService.setMessage('Crash Date (To) must be a valid date (Ex: mm/dd/yyyy).', 'danger');
                    return false;
                }
                if (dateTo.errors.ngbDate.requiredBefore || dateTo.errors.ngbDate.requiredAfter) {
                    this._modalService.setMessage("Crash Date (To)  must be between " + this.strMinValidation + " and " + this.streMaxValidation + ".", 'danger');
                    return false;
                }
            }
        }

        return true;
    }

    public searchEN(event: KeyboardEvent) {
        if (event.code == KeyCode.Enter || event.code == KeyCode.NumpadEnter)
            this.onSearch();
    }

    onSearch() {
        this.blnAllowSearch = true;

        if (!this.validateDateFrom(this.dateFrom)) {
            this._earlyNotifications$.next(null);
            this._total$.next(null);
            this.intTotal = null;
            return;
        }

        if (!this.validateDateTo(this.dateTo)) {
            this._earlyNotifications$.next(null);
            this._total$.next(null);
            this.intTotal = null;
            return;
        }

        this._sharedDataService.setNewCookie("cookieENFilters", JSON.stringify(this.earlyNotificationFilters), null);
        this._sharedDataService.setNewCookie("cookieENPageState", JSON.stringify(this._state), null);

        this.getEarlyNotifications(this.earlyNotificationFilters);

        this.criCrashDateFrom = this.earlyNotificationFilters.CrashDateFrom;
        this.criCrashDateTo = this.earlyNotificationFilters.CrashDateTo;
        this.criCaseStatus = this.earlyNotificationFilters.CaseStatus;
        let caseStatus = this.arrStatus.find(x => x.intValue == this.criCaseStatus);
        this.caseStatus = caseStatus.strText;
        //this.criCaseLevelRankings = this.earlyNotificationFilters.CaseLevelRankings;
        this.criEdtStatus = this.earlyNotificationFilters.EdtStatus;
        //this.criCrashType = this.earlyNotificationFilters.CrashType;
        //this.criJurisdiction = this.earlyNotificationFilters.Jurisdiction;
        //this.criPARNumber = this.earlyNotificationFilters.PARNumber;
    }

    onClearSearch() {
        //this.searchMathAnalysisCaseNumberTerm = '';
        //this.searchMDECaseTerm = '';
        this.searchStateCaseNumTerm = '';
        this.searchCaseNumTerm = '';
        this.searchCrashDateTerm = '';
        //this.searchJurisdictionTerm = '';
        //this.searchENTypeTerm = '';
        this.searchFatalsTerm = '';
        this.searchCommentsTerm = '';
        this.searchStatusTerm = '';
        this.searchPARNumTerm = '';
        this.searchTechnicianTerm = '';
        this.searchCaseStatTerm = '';
        this.searchMOSSCaseNumTerm = '';

        this.page = 1;
        
        this.earlyNotificationFilters.CrashDateFrom = new Date(2018, 0, 1);
        this.earlyNotificationFilters.CrashDateTo = new Date(2018, 12, 31);
        if (this.selectedMode == DBMode.MOSS) {
            this.earlyNotificationFilters.CrashDateFrom = null;
            this.earlyNotificationFilters.CrashDateTo = null;
        }
        this.earlyNotificationFilters.CaseStatus = null;
        //this.earlyNotificationFilters.CaseLevelRankings = null;
        this.earlyNotificationFilters.EdtStatus = null;
        //this.earlyNotificationFilters.CrashType = 99999;
        //this.earlyNotificationFilters.Jurisdiction = null;
        //this.earlyNotificationFilters.PARNumber = null;
        //this.earlyNotificationFilters.StateOfficalCrash = null;

        this.criCrashDateFrom = null;
        this.criCrashDateTo = null;
        this.criCaseStatus = null;
        this.criCaseLevelRankings = null;
        this.criEdtStatus = null;
        //this.criCrashType = null;
        //this.criJurisdiction = null;
        this.criPARNumber = null;

        this.blnAllowSearch = false;
    }

    public async onMakeMDECase(objEN: EarlyNotify): Promise<void> {
        if (!objEN.Acc) {
            if (objEN.CrashType == EarlyNotifyType.FARS || objEN.Mode != DBMode.FARS) {
                this.earlyNotificationService.earlyNotification = null; //Clear cache to ensure that server trip is made to aquire lock on EN
                let objEarlyNotify = await this.earlyNotificationService.GetEarlyNotificationPromise(objEN.ENID); //Acquire exclusive lock on EN

                let objAcc = this._autofillService.AddAcc(objEarlyNotify);
                this._caseService.acc = objAcc; //Acc row not committed in DB yet. Cache Acc in service for Case Structure page to pick up.
                this._router.navigate(['cases', objEarlyNotify.StateNum, 'caseEdit', objAcc.AccID]);
            }
            else
                this._modalService.setMessage('Cannot create FARS case for Early Notification with non-FARS Crash Type.', 'error');
        }
        else
            this._modalService.setMessage('Early Notify already has a case.', 'error');
    }

    onGoToTheCase(objEN: EarlyNotify): void {
        if (objEN.Acc) {
            this._caseService.mossCasenum = objEN.MOSSCasenum;
            this._caseService.intCasenum = objEN.Acc.Casenum;
            this._caseService.clearAccMemoryForCloseCase();
            this._caseService.GetCasePromise(objEN.Acc.AccID).then(data => {

                this.acc = data;
                if (this.acc) {
                    this._router.navigate([this.acc.StateNum, 'case', this.acc.AccID, 'crash', this.acc.AccID]);
                }
            });
        }
    }

    public async onEdit(enid): Promise<void> {
        try {
            this.earlyNotificationService.earlyNotification = null; //Clear cache to ensure that server trip is made to aquire lock on EN
            let objEarlyNotify = await this.earlyNotificationService.GetEarlyNotificationPromise(enid); //Acquire exclusive lock on EN

            if (objEarlyNotify) {
                this._sharedDataService.setEarlyNotify(objEarlyNotify);
                this._sharedDataService.setLastCaseUrl(this._router.url);
                this._router.navigate(['earlyNotification', objEarlyNotify.StateNum, 'newFatalCase', objEarlyNotify.ENID]);
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    public async onDelete(objEN: EarlyNotify): Promise<void> {
        this.earlyNotificationService.earlyNotification = null; //Clear cache to ensure that server trip is made to aquire lock on EN
        let objEarlyNotify = await this.earlyNotificationService.GetEarlyNotificationPromise(objEN.ENID); //Acquire exclusive lock on EN

        //We are not setting objEN.Deleted to true yet. Must first specify reason and click "Delete" button on EN edit screen.
        objEarlyNotify.CrashType = null;

        this._sharedDataService.setEarlyNotify(objEarlyNotify);
        this._sharedDataService.setLastCaseUrl(this._router.url);

        this._router.navigate(['earlyNotification', objEarlyNotify.StateNum, 'newFatalCase', objEarlyNotify.ENID], { fragment: "Delete" });
    }

    ngAfterViewInit() {

    }

    onClickIsStateOfficialCrash(earlyNotification: EarlyNotify) {

        this.earlyNotificationService.SaveEarlyNotification(earlyNotification).subscribe(objEarlyNotify => {
            this._modalService.setMessage('State Official Crash for Early notification with EN #' + this.replaceTextPipe.transform(this.pipe.transform(objEarlyNotify.ENCaseNum, '4.0-0').toString(), ',', '') + ' has been changed successfully.', 'success');
            this._monthlyCountService.getMonthlyCount(earlyNotification.StateNum, this.selectedYear, this.intMonthlyCountTypeID).subscribe(item => {
                this.ENMonthlyCount = item.find(m => m.MonthNum == this.intMonth);
                this.onSearch();
            });
        },
            objError => console.log(objError)
        );
    }

    //onBackToMontlyCounts() {
    //    this._router.navigate([this.stateNum, 'monthlyCounts']);
    //}

    ngOnDestroy() {
        this._sharedDataService.setNewCookie("cookieENPageState", JSON.stringify(this._state), null);
        if (this.earlyNotificationSubscription) {
            this.earlyNotificationSubscription.unsubscribe();
        }

        this._modalService.resetMessage();
    }

    public async btnExportToExcel($event: UIEvent): Promise<void> {
        let strBufferBase64: string = await this.earlyNotificationService.GetEarlyNotificationsExcel(this.earlyNotificationFilters);
        let strBuffer: string = atob(strBufferBase64);
        let arrBytes: Uint8Array = new Uint8Array(strBuffer.length);

        for (var i = 0; i < strBuffer.length; i++)
            arrBytes[i] = strBuffer.charCodeAt(i);

        let objBlob: Blob = new Blob([arrBytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let strUrl = URL.createObjectURL(objBlob);
        window.open(strUrl);
    }

    public async onPDF(EarlyNotify: EarlyNotify): Promise<void> {
        try {
            let itemStateViewer: string = await this.earlyNotificationService.GetCRSSFromStateCaseViewer(EarlyNotify);

            if (!itemStateViewer || itemStateViewer.length == 0) {
                this._modalService.setMessage('File not found', 'danger');

            }
            else {
                let strBufferStateCaseViewer: string = atob(itemStateViewer);
                let arrBytes: Uint8Array = new Uint8Array(strBufferStateCaseViewer.length);

                for (var i = 0; i < strBufferStateCaseViewer.length; i++) {
                    arrBytes[i] = strBufferStateCaseViewer.charCodeAt(i);
                }

                let objBlob: Blob = new Blob([arrBytes], { type: 'application/pdf' });
                let strUrl = URL.createObjectURL(objBlob);
                window.open(strUrl);
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }


}
