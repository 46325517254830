<div *ngIf="vehicle" class="border-left border-bottom border-right bg-light">
    <ul class="nav nav-tabs border-dark border-left">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./vehicle"]'
               [ngClass]="{'bg-light': linkvehicle.isActive !== true }"
               [routerLinkActive]="['active']" #linkvehicle="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkvehicle.isActive === true, 'fw-bold' : linkvehicle.isActive === true}">
                        Vehicle_V{{vehid_SS}}
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="intMode != 256">
            <a class="nav-link" [routerLink]='["./driver"]'
               [ngClass]="{'bg-light': linkdriver.isActive !== true }"
               [routerLinkActive]="['active']" #linkdriver="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkdriver.isActive === true, 'fw-bold' : linkdriver.isActive === true}">
                        Driver_V{{vehid_SS}}
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="vehicle.PreCrash && intMode != 256">
            <a class="nav-link" [routerLink]='["./precrash"]'
               [ngClass]="{'bg-light': linkprecrash.isActive !== true }"
               [routerLinkActive]="['active']" #linkprecrash="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkprecrash.isActive === true, 'fw-bold' : linkprecrash.isActive === true}">
                        Precrash_V{{vehid_SS}}
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngFor="let link of vehicle.Occupants">
            <span *ngIf="link && intMode != 256">
                <a class="nav-link" [routerLink]='["./person", link.PNumber]'
                   [ngClass]="{'bg-light': linkperson.isActive !== true }"
                   [routerLinkActive]="['active']" #linkperson="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': linkperson.isActive === true, 'fw-bold' : linkperson.isActive === true}">
                            Person_{{ link.PNumber }}_V{{ link.VNumber}}
                            <i class="fas fa-asterisk pl-2" *ngIf="link.Injury === 4"></i><span class="text-decoration-none" *ngIf="link.CreatedRecord > 0">_CR</span>
                        </span>
                    </span>
                </a>
            </span>
        </li>
    </ul>
    <!--here we generate and show Vehicle-children html's components-->
    <div class="tab-content">
        <router-outlet></router-outlet>
    </div>
</div>
