<form novalidate #fvpsafetyd="ngForm">
    <div class="pt-2" *ngIf="person && intMode">
        <div [ngClass]="{'row' : printOnly === true}">


            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="BeltUsed"
                                     multiSelectPosition="right-top"
                                     strTableName="Occ_BeltUsed"
                                     strFieldName="BeltUsed"
                                     [objModel]="person.Occ_BeltUsed"
                                     [objTemplateModel]="objBeltUsedTemplate"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>-->
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="BeltUsed" name="BeltUsed" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="BeltUsed" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"  [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="AirBagDeplmnt" name="AirBagDeplmnt" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="AirBagDeplmnt" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>
