<form novalidate #fvvo="ngForm">
    <div *ngIf="veh && intMode" id="vehicle-other" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">
            <div #typAhdTrailer [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-typeahead id="Trailer" name="Trailer" typeAheadPosition="right-top"
                                    [objModel]="veh" strTableName="Veh" strFieldName="Trailer"
                                    (bindModel)="OnModelChange($event)"
                                    [printOnly]="printOnly"
                                    [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdJacknife [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-typeahead id="Jacknife" name="Jacknife" typeAheadPosition="right-top"
                                    [objModel]="veh" strTableName="Veh" strFieldName="Jacknife"
                                    [printOnly]="printOnly"
                                    [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode != 256">
                <div class="form-group pl-2">
                    <app-typeahead id="busUse" name="busUse" typeAheadPosition="right-top"
                                    [objModel]="veh" strTableName="Veh" strFieldName="BusUse"
                                    [printOnly]="printOnly"
                                    [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode != 256">
                <div class="form-group pl-2">
                    <app-typeahead id="spUse" name="spUse" typeAheadPosition="right-top"
                                    [objModel]="veh" strTableName="Veh" strFieldName="SpUse"
                                    [printOnly]="printOnly"
                                    [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode != 256">
                <div class="form-group pl-2">
                    <app-typeahead id="emerg" name="emerg"
                                    [objModel]="veh" strTableName="Veh" strFieldName="Emerg"
                                    [printOnly]="printOnly"
                                    [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode != 256">
                <div class="form-group pl-2">
                    <app-multiselect id="vehRF"
                                        name="vehRF"
                                        strTableName="VehRF"
                                        strFieldName="VRF"
                                        intSeqNum="1"
                                        multiSelectPosition="right-top"
                                        [objTemplateModel]="objVehRFTemplate"
                                        [objModel]="veh.VehRF" [printOnly]="printOnly"
                                        [showAllDataListForPrintOnly]="false"
                                        [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-12 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="fire" name="fire" typeAheadPosition="right-top"
                                    [objModel]="veh" strTableName="Veh" strFieldName="Fire" [printOnly]="printOnly"
                                    [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
               
        </div>
    </div>
</form>
