import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, OnDestroy, PipeTransform, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { DecimalPipe } from '@angular/common';

//rxjs && rxjs/operators
import { Observable, BehaviorSubject, Subscription, of, Subject, empty } from 'rxjs';
import { debounceTime, delay, switchMap, tap, finalize } from 'rxjs/operators';

//services
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { OverriddenRulesService } from 'src/app/services/overridden-rules.service';
import { ViolatedRulesService } from 'src/app/services/violated-rules.service';

//models
import { Acc } from 'src/app/models/acc';
import { CaseOverRiddenRules } from 'src/app/models/case-over-ridden-rules';
import { OverriddenRulesState } from 'src/app/models/overridden-rules-state';
import { SearchOverriddenRulesResult } from 'src/app/models/search-overridden-rules-result';
import { ViolatedRules } from 'src/app/models/violated-rules';
//import { RulesErrorList } from 'src/app/models/Rules_Error_List';

//directives
import { NgbdSortableHeader, SortEvent, SortDirection } from 'src/app/directives/sortable-header.directive';
//import { RulesErrorList } from 'src/app/models/Rules_Error_List';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { DBMode } from 'src/app/models/enums/app.enums';

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(overriddenRules: CaseOverRiddenRules[], column: string, direction: string): CaseOverRiddenRules[] {
    if (direction === '') {
        return overriddenRules;
    } else {
        return [...overriddenRules].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matcheRules(overriddenRules: CaseOverRiddenRules, searchTerm: string) {
    if (overriddenRules.RULEID) {
        return overriddenRules.RULEID.toLowerCase().includes(searchTerm.toLowerCase());
    }
}

function matchesReasons(overriddenRules: CaseOverRiddenRules, searchTerm: string) {
    if (overriddenRules.REASON) {
        return overriddenRules.REASON.toLowerCase().includes(searchTerm.toLowerCase());
    }
}

function matchesVehicle(overriddenRules: CaseOverRiddenRules, pipe: PipeTransform, searchTerm: string) {
    if (overriddenRules.VNumber || overriddenRules.VNumber == 0) {
        return pipe.transform(overriddenRules.VNumber).includes(searchTerm);
    }
}

function matchesPerson(overriddenRules: CaseOverRiddenRules, pipe: PipeTransform, searchTerm: string) {
    if (overriddenRules.PNumber || overriddenRules.PNumber == 0) {
        return pipe.transform(overriddenRules.PNumber).includes(searchTerm);
    }
}

@Component({
    selector: 'app-overridden-rules',
    templateUrl: './overridden-rules.component.html',
    styleUrls: ['./overridden-rules.component.css'],
    providers: [
        OverriddenRulesService,
        DecimalPipe
    ]
})

export class OverriddenRulesComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_OverriddenRulesComponent: string = null;
    selectedYear: number;
    selectedMode: number;

    stateNum: number;
    accid: number;
    acc: Acc;

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    overriddenRulesSubscription: Subscription;

    OVERRIDDENRULES: CaseOverRiddenRules[];
    caseNumber: number;

    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _overriddenRules$ = new BehaviorSubject<CaseOverRiddenRules[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    VIOLATEDRULESOR: ViolatedRules[];
    VIOLATEDRULESEDITOR: ViolatedRules[];
    VIOLATEDRULES: ViolatedRules[];
    ruleViolated: ViolatedRules;

    violatedRulesSubscription: Subscription;

    public ColummForSorting: string = '';
    public DirectionForSorting: string = '';

    private _state: OverriddenRulesState = {
        page: 1,
        pageSize: 10,

        searchRuleTerm: '',
        searchReasonTerm: '',
        searchVehicleTerm: '',
        searchPersonTerm: '',

        sortColumn: '',
        sortDirection: ''
    };

    strModeName: string;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private sharedDataService: SharedDataService,
        protected _caseService: CaseService,
        protected _modalService: ModalService,
        private _overriddenRulesService: OverriddenRulesService,
        private pipe: DecimalPipe,
        private _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _violatedRulesService: ViolatedRulesService
    ) {
        super(_route, sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {// we are not using this method at this page, it is only because of BaseComponent extension
        this.blnAlloweSave = true;
    }

    async ngOnInit() {

        let appSettings = await this.sharedDataService.GetAppSettings();
        if (DBMode[appSettings.intMode]) {
            this.strModeName = DBMode[appSettings.intMode];
        }
        this.selectedYear = appSettings.intYear;
        this.selectedMode = appSettings.intMode;

        this._route.parent.params.subscribe(params => {
            this.stateNum = + params['stateNum'];
            if (!this.sharedDataService.selectedState) {
                this.sharedDataService.setSelectedState(this.stateNum);
            }
            this.accid = + params['caseid'];           
            this.getOverRiddenRules();

            //console.log("Acc from OverriddenRulesComponent: ", this.acc.Casenum);
        });
    }

    //Fetching data from the service(database)
    getOverRiddenRules() {
        this.overriddenRulesSubscription = this._overriddenRulesService.GetCaseOverRiddenRules(this.stateNum, this.accid, this.selectedYear, '').subscribe(result => {

            this.OVERRIDDENRULES = result;
            console.log(this.OVERRIDDENRULES);

            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                finalize(() => this._loading$.next(false)),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._overriddenRules$.next(result.overriddenRules);
                this._total$.next(result.total);
            });

            this._search$.next();
        });
    }

    get overriddenRules$() { return this._overriddenRules$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }

    get searchRuleTerm() { return this._state.searchRuleTerm; }
    get searchReasonTerm() { return this._state.searchReasonTerm; }
    get searchVehicleTerm() { return this._state.searchVehicleTerm; }
    get searchPersonTerm() { return this._state.searchPersonTerm; }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set searchRuleTerm(searchRuleTerm: string) { this._set({ searchRuleTerm }); }
    set searchReasonTerm(searchReasonTerm: string) { this._set({ searchReasonTerm }); }
    set searchVehicleTerm(searchVehicleTerm: string) { this._set({ searchVehicleTerm }); }
    set searchPersonTerm(searchPersonTerm: string) { this._set({ searchPersonTerm }); }

    set sortColumn(sortColumn: string) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    private _set(patch: Partial<OverriddenRulesState>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchOverriddenRulesResult> {
        const { sortColumn, sortDirection, pageSize, page, searchRuleTerm, searchReasonTerm, searchVehicleTerm, searchPersonTerm } = this._state;

        let overriddenRules = sort(this.OVERRIDDENRULES, sortColumn, sortDirection);

        this.ColummForSorting = sortColumn;
        this.DirectionForSorting = sortDirection;

        if (searchRuleTerm !== '') {
            overriddenRules = overriddenRules.filter(overriddenRule => matcheRules(overriddenRule, searchRuleTerm));
        }

        if (searchReasonTerm !== '') {
            overriddenRules = overriddenRules.filter(overriddenRule => matchesReasons(overriddenRule, searchReasonTerm));
        }

        if (searchVehicleTerm !== '') {
            overriddenRules = overriddenRules.filter(overriddenRule => matchesVehicle(overriddenRule, this.pipe, searchVehicleTerm));
        }

        if (searchPersonTerm !== '') {
            overriddenRules = overriddenRules.filter(overriddenRule => matchesPerson(overriddenRule, this.pipe, searchPersonTerm));
        }

        const total = overriddenRules.length;

        overriddenRules = overriddenRules.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

        return of({ overriddenRules, total });
    }

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.sortColumn = column;
        this.sortDirection = direction;

        this.ColummForSorting = column;
        this.DirectionForSorting = direction;
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.OVERRIDDENRULES) {
            this.OVERRIDDENRULES = null;
        }

        if (this.overriddenRulesSubscription)
            this.overriddenRulesSubscription.unsubscribe();
    }


    getViolatedRules() {
        this.violatedRulesSubscription = this._violatedRulesService.GetViolatedRules(this.accid).subscribe(result => {
            this.VIOLATEDRULESOR = result;
            console.log(this.VIOLATEDRULESOR);

        });
    }


    onEdit(violatedRuleOREdit: CaseOverRiddenRules) {
        if (violatedRuleOREdit != null) {
            this._router.navigate([this.stateNum, 'checkCase', this.accid, 'overrideRule', violatedRuleOREdit.RULEID], { queryParams: { From: 'edit', VNumber: violatedRuleOREdit.VNumber, PNumber: violatedRuleOREdit.PNumber } });
        }
    }

    onCancel(overriddenRule: CaseOverRiddenRules) {
        if (overriddenRule != null) {
            this._overriddenRulesService.DeleteOrideCase(this.accid, overriddenRule.RULEID, overriddenRule.VNumber, overriddenRule.PNumber).then(((item) => {               
                // We have to remove Overridden Rule from the this.acc.OrideCase(from the client) so that next timewe save the entier case the rule will not be saved in OrideCase
                var index = this.acc.OrideCase.findIndex(x => x.ACCID == this.accid && x.RULEID == overriddenRule.RULEID && x.VNumber == overriddenRule.VNumber && x.PNumber == overriddenRule.PNumber);
                this.acc.OrideCase.splice(index, 1);

                this._router.navigate([this.stateNum, 'checkCase', this.accid, 'violatedRules']);
            }).bind(this));
        }
    }
}
