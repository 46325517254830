import { Directive, Input, HostListener, ElementRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, FormsModule, NgForm, AbstractControl, ValidatorFn } from '@angular/forms';

import { UtilService } from './../services/util.service'
import { TableFieldElements } from '../models/table-field-elements';

@Directive({
    selector: '[customMax][formControlName],[customMax][formControl],[customMax][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true }]
})
export class CustomMaxDirective implements OnInit, Validator {
    
    @Input() customMax: number;
    @Input() strTableName: string;
    @Input() strFieldName: string;

    fieldMetaData: TableFieldElements;
    fieldMetaDatas: TableFieldElements[];

    constructor(
        private el: ElementRef,
        private _utilService: UtilService
    ) {      
    }

    async ngOnInit() {
        await this._utilService.metaDataToShare.subscribe(arrMetadata => {
            if (this.strTableName && this.strFieldName)
                this.fieldMetaData = arrMetadata.find(x => x.TableID === this.strTableName && x.FieldID === this.strFieldName);
            else
                this.fieldMetaData = arrMetadata.find(x => x.FieldID === this.el.nativeElement.name);
        });
    }

    validate(control: AbstractControl): { [key: string]: any } {
        if (control.value) {
            
            if (this.fieldMetaData) {
                let value: number = typeof (control.value) === "number" ? control.value : parseInt(control.value);

                if (value === null || isNaN(value))
                    return null;

                if (value === parseInt(this.fieldMetaData.NotRepoValue) ||
                    value === parseInt(this.fieldMetaData.UnknownValue) ||
                    value === parseInt(this.fieldMetaData.NotApplicable) ||
                    value == -1) { //-1 is a reserved value that by convention indicates "no value", similar to NULL
                    return null;
                }

                else {
                    return (value > this.customMax) ? { "customMax": true } : null;
                }
            }
            else {
                window.alert('This element ' + this.el.nativeElement.name + ' has no fieldMetaData');
            }
        }
        else {
            return null;
        }
    }
}   
