import {
    Component, Input, Renderer2,
    OnInit, OnDestroy, AfterViewInit,
    ViewChild, ElementRef, Output, EventEmitter,
    OnChanges, SimpleChanges,
    SimpleChange, ViewEncapsulation, AfterViewChecked
} from '@angular/core';

import { ControlContainer, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { Observable, Subject, merge, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';


import { AutofillService } from 'src/app/services/autofill.service';
import { UtilService } from 'src/app/services/util.service';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { KeyCode, intRBISDataValue, PropertyType } from 'src/app/models/enums/app.enums';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { AttributeMatchLevel } from 'src/app/models/attr-match-level';
import { Element_Specify } from 'src/app/models/element-specify';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { ReplaceTextPipe } from 'src/app/pipes/replaceText.pipe';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { usp_EDT_GetStylesByStateNum_Result } from 'src/app/models/usp_EDT_GetStylesByStateNum_Result';
import { EdtConfidenceColorComponent } from '../edt-confidence-color/edt-confidence-color.component';

@Component({
    selector: 'app-textfield',
    templateUrl: './textfield.component.html',
    styleUrls: ['./textfield.component.css'],
    providers: [DecimalPipe, ReplaceTextPipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    encapsulation: ViewEncapsulation.None
})
export class TextFieldComponent extends UIElementBase implements OnDestroy, OnInit, AfterViewInit, AfterViewChecked {
    focusOtherSpecify$ = new Subject<string>(); //TODO: TextFieldComponent has no "other specify" functionality, what is the purpose of this?
    clickOtherSpecify$ = new Subject<string>();

    /**
     * We track the number of typed characters to distinguish user-input from formatting pipe inputs, like DecimalPipe
     */
    public intTypedCharacters: number = 0;

    @Input() blnIsNumber: boolean = false;
    @Input() blnAllowLeftMostZero = false;
    @Input() intTextAreaRows: number = 1;
    @Input() intCustomMin: number;
    @Input() intCustomMax: number;
    @Input() blnIsRequired: boolean = null;
    @Input() objCurrentValue: any;
    @Input() cssFieldCLass: string;
    @Input() numType: string = 'number';
    @Input() fieldName: string;
    @Input() blnFormatUpperCase: boolean = null;

    @ViewChild('printTextArea', { static: false }) printTextArea: ElementRef;
    @ViewChild('EDTConfidenceColor', { static: false }) edtConfidenceColor: EdtConfidenceColorComponent;

    //to access the event object with the "$event" argument through the child
    @Output() bindModel = new EventEmitter<any>();

    constructor(
        protected _autofillService: AutofillService,
        protected renderer: Renderer2,
        protected decimalPipe: DecimalPipe,
        protected replaceTextPipe: ReplaceTextPipe,
        protected _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _sharedService: SharedDataService
    ) {
        super(_autofillService, renderer, decimalPipe, replaceTextPipe, _utilService, _urlTreeHelper);
    }

    //Raised during initial data binding and on changes to component properties.
    ngOnChanges(arrChanges: SimpleChange[]) {

        for (let strPropName in arrChanges) {
            let objChange: SimpleChange = arrChanges[strPropName];

            if (strPropName == 'blnVisible')
                this.hideOrShowComponent(this.blnVisible);

            if (strPropName == 'objModel')
                this.intTypedCharacters = 0;

            if (this.objModel &&
                this.objMetaData &&
                strPropName == 'objCurrentValue' &&
                objChange.previousValue != objChange.currentValue) {
                this.objModel[this.objMetaData.FieldID] = this.objCurrentValue;
                AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, false, false);
                this.bindModel.emit(this.objCurrentValue);

            }
        }
    }

    //#region Helper methods
    public setCurrentValue(objNewValue: string): boolean {

        this.bindItemAndFocusNextElement(objNewValue, false);
        AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, true, false); //User initiated flag is set to false as we don't want focus to field focus to auto-advance
        return true;
    }

    public bindItemAndFocusNextElement(objNewValue: any, focusNext: boolean = true): void {
        this.objCurrentValue = objNewValue;

        if (AutofillService.GetDataType(this.objModel, this.strFieldName) == 'number') {
            this.objCurrentValue = parseFloat(objNewValue);

            if (this.objCurrentValue === intRBISDataValue.Blank) {
                this.objTextbox.nativeElement.value = "";
            }

            if (this.objModel[this.strFieldName] != this.objCurrentValue)
                this.objModel[this.strFieldName] = this.objCurrentValue;

            if (this.blnAllowLeftMostZero) {
                this.objCurrentValue = parseFloat(objNewValue);
                if (this.objModel[this.strFieldName] != this.objCurrentValue)
                    this.objModel[this.strFieldName] = this.objCurrentValue;

                this.bindModel.emit(this.objCurrentValue);
                this.objTextbox.nativeElement.value = objNewValue;
            }
        }
        else {
            if (objNewValue === "-1") {
                this.objTextbox.nativeElement.value = "";
            }

            if (this.objModel[this.strFieldName] != objNewValue)
                this.objModel[this.strFieldName] = objNewValue;
        }

        this.bindModel.emit(objNewValue);

        if (focusNext) {
            this.focusNextInput();
        }
    }

    async findElementMetaData() {
        let arrMetadata: TableFieldElements[] = await this._utilService.GetMetadataPromise();

        //if (this.strTableName != '' && this.strFieldName != '') {
        //    this.objMetaData = arrMetadata.find(x =>
        //        x.TableID == this.strTableName &&
        //        x.FieldID == this.strFieldName);
        //}

        //TO DO : we have to use this logic for fields accTrafID1.TrafID AND accTrafID2.TrafID at crash-traficway
        if (this.strTableName != '' && this.strFieldName != '' && this.intSeqNum) {
            this.objMetaData = arrMetadata.find(x =>
                x.TableID == this.strTableName &&
                x.FieldID == this.strFieldName &&
                x.SeqNum == this.intSeqNum);
            //If no record found filtering by SeqNum find the metadata with TableId and FieldId parameters
            if (!this.objMetaData)
                this.objMetaData = arrMetadata.find(x =>
                    x.TableID == this.strTableName &&
                    x.FieldID == this.strFieldName);
        }

        else if (this.strTableName != '' && this.strFieldName != '') {
            this.objMetaData = arrMetadata.find(x =>
                x.TableID == this.strTableName &&
                x.FieldID == this.strFieldName);
        }

    }

    public async bindFieldMetadata() {
        await this.findElementMetaData();

        let appSetting: AppSettings = await this._sharedService.GetAppSettings();
        this.intMode = appSetting ? appSetting.intMode : -1;

        if (this.objMetaData) {
            if (!this.inputName) //All controls need an HTML ID attribute for test scripts, if an ID was not provided, fall back on field name in meta data
                this.inputName = this.intSeqNum > 0 ? this.objMetaData.FieldID + this.intSeqNum.toString() : this.objMetaData.FieldID
        }
        else if ((this.intMode & this.intDisabledMode) == this.intDisabledMode) {
            this.hideOrShowComponent(false);
        }
        else {
            //this.labelElem.nativeElement.innerText = " Warning!!!Metadata missing and control not initialize";
        }
    }

    public BindElementEDTColor(intStateNum: number, arrStateStyles: usp_EDT_GetStylesByStateNum_Result[] = null) {
        if (this.printOnly || !this.objModel)
            return;
        let strFieldParam: string = this.objMetaData.SeqNum == 0 ? this.strFieldName : this.objMetaData.Field;
        if (this.strComplexFieldName != '')
            strFieldParam = this.strComplexFieldName;
        this.edtConfidenceColor.BindTextFieldEDTColor(intStateNum, strFieldParam, arrStateStyles);
    }

    public FilterFieldOptions(): Promise<DrpDownOptions[]> { return new Promise((resolve, reject) => { resolve(this.options); }); }

    public disableOrEnableComponent(isDisable: boolean): void {
        this.disabled = isDisable || UIElementBase.blnReadOnly;

        if (this.disabled) {
            this.edtConfidenceColor.HideEDTConfidenceColor();
            this.renderer.setAttribute(this.objTextbox.nativeElement, 'disabled', 'disabled');
        }
        else {
            //"disabled" HTML5 attribute is presence-based, not value-based. Setting it to false does not actually remove it.
            //"disabled" HTML5 attribute should not be conused with the shadow-DOM "disabled" attribute, which can be set to false.
            this.renderer.removeAttribute(this.objTextbox.nativeElement, 'disabled');
        }
    }

    //#end region

    //#region Angular LifeCycle Hooks
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    //OnInit is called after Angular has initialized all data-bound properties of a directive
    public async ngOnInit(): Promise<void> {
        await super.ngOnInit(); //After this point metadata is available

        if (this.objModel[this.strFieldName] === -1) //-1 Is a reserved value analogous to NULL in most cases, we don't want to format it with leading zeros
            this.objTextbox.nativeElement.value = '';

        let strDebugHelper: string = 'Acclatitude';
        if (this.id == strDebugHelper)
            console.debug('Put breakpoint here to debug TextfieldComponent ngOnInit()');

        let strModelFieldType: string = AutofillService.GetDataType(this.objModel, this.strFieldName);
        let intMaxLengthFromReflection: number = AutofillService.GetMaxLength(this.strTableName, this.strFieldName);

        if (intMaxLengthFromReflection && strModelFieldType == 'string')
            this.maxlength = intMaxLengthFromReflection;

        if (this.maxlength > 0 && (strModelFieldType == 'number' || strModelFieldType == 'string')) {
            this.objTextbox.nativeElement.maxLength = this.maxlength + (this.intMaxDecimal > 0 ? 1 : 0);
        }

        if (this.objModel[this.strFieldName] !== undefined && this.objModel[this.strFieldName] !== null)
            this.SetModelValue(this.objModel[this.strFieldName].toString()); //If a number format with leading zeros

        this.blnIsTextField = true;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    //#end region

    public clearComponent(blnSkipAutoFillOnChange: boolean = true): void {
        super.clearComponent();
        this.edtConfidenceColor.HideEDTConfidenceColor();
        if (!blnSkipAutoFillOnChange)
            AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, true, false, false);
    }

    //#region Event Handlers
    public handleKeydown(event: KeyboardEvent): void {
        super.handleKeydown(event);

        if (!this.blnIsNumber && (event.code == KeyCode.Enter || event.code == KeyCode.NumpadEnter))
            event.cancelBubble = true; //Mapping of Enter key to Case Save should not apply while a multiline field has focus
    }

    public handleKeyup(event: KeyboardEvent): void {
        super.handleKeyup(event);

        if (event.key == KeyCode.PageUp || event.key == KeyCode.PageDown) {// we should be aware that any keys tat causes navigation thay can realise before navigation is finished and we do not want a control interpret that keyup has a data entre action; 
            return;
        }

        this.setIsInvalidClass(false);

        if (this.blnFormatUpperCase) {
            let strValue: string = (<any>event.target).value.toString();
            this.objTextbox.nativeElement.value = strValue ? strValue.toUpperCase() : '';
        }

        let blnSkipAction: boolean = event.code != KeyCode.Tab
            && event.code != KeyCode.Shift
            && event.code != KeyCode.ShiftLeft
            && event.code != KeyCode.ArrowLeft
            && event.code != KeyCode.ArrowRight;

        let hasNotApplicable: boolean = this.objMetaData && this.objMetaData.NotApplicable != null && this.objMetaData.NotApplicable != "";
        let hasNotRepoValue: boolean = this.objMetaData && this.objMetaData.NotRepoValue != null && this.objMetaData.NotRepoValue != "";
        let hasUnknownValue: boolean = this.objMetaData && this.objMetaData.UnknownValue != null && this.objMetaData.UnknownValue != "";

        if (event.code == KeyCode.NumpadDivide || event.code == KeyCode.Slash) {
            if (hasNotApplicable)
                this.bindItemAndFocusNextElement(this.objMetaData.NotApplicable);
            //else
            //    this.setIsInvalidClass(true);
        }
        else if (event.code == KeyCode.NumpadAdd) {
            if (hasNotRepoValue)
                this.bindItemAndFocusNextElement(this.objMetaData.NotRepoValue);
            //else
            //    this.setIsInvalidClass(true);
        }
        //Bind the Unknown value from metadata field.
        else if (event.code == KeyCode.NumpadMultiply) {
            if (hasUnknownValue)
                this.bindItemAndFocusNextElement(this.objMetaData.UnknownValue);
            //else
            //    this.setIsInvalidClass(true);
        }

        //Ticket# 8081 - blnAllowLeftMostZero flag is added to create the new text field component in view.
        //Person/Non-Occupant Supplemental tab - DeathCert field has numeric datatype,
        //intentially remove two - way binding to update the objmodel field with numeric value and manipulate DOM element with string value.
        else if (this.blnAllowLeftMostZero && blnSkipAction) {
            let strValue: string = (<any>event.target).value;

            this.objCurrentValue = parseFloat(strValue);
            if (this.objModel[this.strFieldName] != this.objCurrentValue)
                this.objModel[this.strFieldName] = this.objCurrentValue;

            this.bindModel.emit(this.objCurrentValue);
            AutofillService.OnChange(this, this.objModel, this.strFieldName, true, true);
            let intMaxLengthActual: number = this.maxlength + (strValue.indexOf('.') != -1 ? 1 : 0); //Presence of decimal point increases effective max length by 1
            this.objTextbox.nativeElement.value = strValue;

            if (this.objModel[this.strFieldName] !== undefined && this.objModel[this.strFieldName] !== null) {
                if (strValue.length >= intMaxLengthActual) {
                    this.focusNextInput();
                }
            }
        }
    }

    public handleFocus(objEvent: FocusEvent): void {
        super.handleFocus(objEvent);
        let inputElements = window.document.querySelectorAll('input');
        let focusInputElements = Array.prototype.filter.call(inputElements,
            function (element) {
                return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
            });
        let index = focusInputElements.indexOf(document.activeElement);

        if (index != -1) {
            let inputElement = focusInputElements[index]

            if (inputElement.value) {
                if (inputElement.value.length > 0) {
                    inputElement.select();
                }
            }
        }
    }

    public onBlur(strNewValue: string): void {
        // 1) Special Typeaheads mapped to text DB columns, with ability to pick a canned value or enter free text.
        if ((this.objCurrentValue) && (this.objCurrentValue.toString() != strNewValue)) { //intValue is null when none of the DrpDownOptions in this.options matched the abandoned term
            this.SetModelValue(strNewValue);
        }

        this.FormatWithLeadingZeros(strNewValue);
    }
    //#end region

    //#region Dom Manupulation Helper Methods
    //setIsInvalidClass(enable: boolean) {
    //    this.renderer.setElementClass(this.objTextbox.nativeElement, "is-invalid", enable);
    //}

    /**
     * Attached to ngModelChange
     * Raised after KeyDown and before KeyUp events
     **/
    public OnModelChange(strNewValue: string): void { //Incoming value is always a string regardless of data type of data model field
        if (strNewValue === "" || strNewValue === null) {
            this.objModel[this.strFieldName] = null;
            return;
        }
        if (this.blnAllowLeftMostZero)
            return;

        if (AutofillService.GetDataType(this.objModel, this.strFieldName) == 'number') {
            this.objCurrentValue = parseFloat(strNewValue);
            if (this.objCurrentValue === intRBISDataValue.Blank) {
                this.objTextbox.nativeElement.value = "";
            }
            if (this.objModel[this.strFieldName] != this.objCurrentValue)
                this.objModel[this.strFieldName] = this.objCurrentValue;
        }
        else {
            if (strNewValue === "-1") {
                this.objTextbox.nativeElement.value = "";
            }

            if (this.objModel[this.strFieldName] != strNewValue)
                this.objModel[this.strFieldName] = strNewValue;
        }

        this.bindModel.emit(this.objCurrentValue);
        AutofillService.OnChange(this, this.objModel, this.strFieldName, true, true);

        let intMaxLengthActual: number = this.maxlength + (strNewValue.indexOf('.') != -1 ? 1 : 0); //Presence of decimal point increases effective max length by 1

        if (this.objModel[this.strFieldName] !== undefined && this.objModel[this.strFieldName] !== null) {
            if (strNewValue.length >= intMaxLengthActual) {
                this.focusNextInput();
            }
        }
    }
    //#end region

    ngAfterViewChecked(): void {
        if (this.objCurrentValue == "-1" || this.objCurrentValue == intRBISDataValue.Blank) {
            this.objTextbox.nativeElement.value = "";
        }

        if (this.printTextArea) {
            let el = this.printTextArea.nativeElement;
            this.printTextArea.nativeElement.style.resize = 'none';
            this.printTextArea.nativeElement.style.overflow = 'hidden';
            this.printTextArea.nativeElement.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight) + "px" : "30px";
        }
    }
}
