<header>
    <nav class='navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-light border-bottom box-shadow mb-1'>
        <div class="container-fluid">
            <a class="navbar-brand" [routerLink]='["/"]'>Records Based Information Solution (RBIS)</a>
            <!--<a class="navbar-brand" *ngIf="selectedMode !=4" [routerLink]='["/"]'>Records Based Information Solution (RBIS)</a>-->
            <!--<a class="navbar-brand" *ngIf="selectedMode ==4" [routerLink]='["/"]'>CRSS</a>-->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation"
                    [attr.aria-expanded]="isExpanded" (click)="toggle()">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]='{"show": isExpanded}'>
                <ul class="navbar-nav flex-grow">
                    <li *ngIf="blnNavbarItemsVisibleState || selectedMode == 64 || selectedMode == 128 || selectedMode == 256" class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
                        <a id="lnkHome" class="nav-link text-dark pointer" [routerLink]='["/"]'>Home</a>
                    </li>
                    <li *ngIf="blnNavbarItemsVisibleState || selectedMode == 64 || selectedMode == 128 || selectedMode == 256" class="nav-item dropdown pointer" ngbDropdown>
                        <!--<a class="nav-link text-dark dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle>Cases</a>-->
                        <a id="lnkMonthlyCounts" class="nav-link text-dark pointer" (click)="onClickCases()">Cases</a>
                        <!--<div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <a *ngIf="selectedMode === 1" class="dropdown-item pointer" (click)="onEarlyNotification()">Early Notification</a>
                            <a *ngIf="blnIsEDTstate && selectedMode === 1" class="dropdown-item pointer" (click)="OnSearchEDT()">Search EDT</a>
                            <a *ngIf="selectedMode === 4" class="dropdown-item pointer" (click)="OnGoToCaseSearchScreener()">Cases</a>
                        </div>-->
                    </li>
                    <!-- Commented out since we did not implemented logic for "Batch Processing" as per Lavanay request on the meeting 2/26/2020-->
                    <!--<li *ngIf="blnNavbarItemsVisibleState && selectedMode !=4" class="nav-item" [routerLinkActive]='["link-active"]'>
                        <a id="lnkBatchProcessing" class="nav-link text-dark pointer" (click)="OnGoToBatchProcessing()">Batch Processing</a>
                    </li>-->
                    <!--<li *ngIf="blnNavbarItemsVisibleState || selectedMode == 4" class="nav-item" [routerLinkActive]='["link-active"]'>
                        <a id="lnkVINRequests" class="nav-link text-dark pointer" (click)="OnGoToVINRequests()">VIN Requests</a>
                    </li>-->
                    <!--<li *ngIf="blnNavbarItemsVisibleState && selectedMode != 4" class="nav-item" [routerLinkActive]='["link-active"]'>
                        <a id="lnkMail" *ngIf="mailPendingRequests != 0" class="nav-link text-dark dropdown-item pointer" (click)="OnGoToMail()">Mail({{mailPendingRequests}})</a>
                        <a id="lnkMail" *ngIf="mailPendingRequests == 0" class="nav-link text-dark dropdown-item pointer" (click)="OnGoToMail()">Mail</a>
                    </li>-->
                    <!-- Commented out since we did not implemented logic for "Reports" as per Lavanay request on the meeting 2/26/2020 -->
                    <!--<li *ngIf="blnNavbarItemsVisibleState" class="nav-item" [routerLinkActive]='["link-active"]'>
                        <a id="lnkReports" class="nav-link text-dark pointer" (click)="OnGoToReports()">Reports</a>
                    </li>-->
                    <!--<li *ngIf="blnNavbarItemsVisibleState && selectedMode != 4" class="nav-item" [routerLinkActive]='["link-active"]'>
                        <a id="lnkMonthlyCounts" class="nav-link text-dark pointer" (click)="OnGoTomonthlyCounts()">Monthly Counts</a>
                    </li>-->
                    <!-- Commented out since we did not implemented logic for "PAR Requests" as per Lavanay request on the meeting 2/26/2020 -->
                    <!--<li *ngIf="blnNavbarItemsVisibleState && selectedMode !=4" class="nav-item" [routerLinkActive]='["link-active"]'>
                        <a id="lnkPARRequests" class="nav-link text-dark pointer" (click)="OnGoToPARRequests()">PAR Requests</a>
                    </li>-->
                    <!-- Commented out since we did not implemented logic for "Help" as per Lavanay request on the meeting 2/26/2020 -->
                    <!--<li class="nav-item" [routerLinkActive]='["link-active"]'>
                        <a id="lnkHelp" class="nav-link text-dark pointer" (click)="OnGoToHelp()">Help</a>
                    </li>-->
                    <!--<li *ngIf="blnNavbarItemsVisibleState || selectedMode == 4" class="nav-item" [routerLinkActive]='["link-active"]'>
                        <a id="lnkPreferencesFARS" class="nav-link text-dark pointer" (click)="OnGoToPreferences()">Preferences</a>
                    </li>-->

                </ul>
            </div>
        </div>
    </nav>
    <app-genericmessage></app-genericmessage>
    <app-editcheck-message></app-editcheck-message>
</header>

