<div class="container-fluid px-0">
    <div class="row" *ngIf="(CaseOverRiddenRule || ViolatedRule) && acc">
        <div class="col-12 pr-2">
            <div class="row">
                <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
                    <div class="">
                        <div class="table-responsive border-left border-right border-dark">
                            <table class="table table-hover table-sm mb-0" >
                                <thead class="bg-tb-header">
                                    <tr>
                                        <th class="border-bottom border-dark" colspan="4">Rule Information</th>
                                    </tr>
                                </thead>
                                <tbody class="border-bottom border-dark">
                                    <tr>
                                        <!--<td><span class="">Case Number:                 </span><span class="fw-bold">{{acc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : ''}}</span></td>-->
                                        <td *ngIf="selectedMode == 1 || selectedMode == 1"><span class="">Case Number:                   </span><span class="fw-bold">{{acc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : ''}}</span></td>
                                        <td *ngIf="selectedMode == 4"><span class="">PAR ID:                        </span><span class="fw-bold">{{ acc.EarlyNotify[0]?.PARID | number : '8.0-0' | replaceTextPipe : ',' : '' | slice : 4 : 12  }}</span></td>
                                        <td><span class="">Vehicle Number:              </span><span class="fw-bold">{{OrideCase.VNumber}}                        </span></td>
                                        <td><span class="">Person Number:               </span><span class="fw-bold">{{OrideCase.PNumber}}                        </span></td>
                                        <td><span class="">Rule Number:                 </span><span class="fw-bold">{{OrideCase.RULEID}}                         </span></td>
                                    </tr>
                                    <tr class="bg-light">
                                        <td class="border-bottom border-dark" colspan="4"><span class="">Rule Definition:  </span><span class="fw-bold">{{definition}}    </span></td>
                                    </tr>                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-lg-12 col-xl-12 mt-3">
                    <div class="">
                        <div class="table-responsive border-left border-right border-dark">
                            <table class="table table-hover table-sm mb-0">
                                <thead class="bg-tb-header">
                                    <tr>
                                        <th colspan="4" class="border-bottom border-dark">Override Reason</th>
                                    </tr>
                                </thead>
                                <tbody class="border-bottom border-dark ">
                                    <tr>
                                        <td colspan="4" class="bg-light">
                                            <textarea id="OverrideReason" name="OverrideReason" class="form-control bg-white"
                                                      placeholder="Please enter an override reason" rows="4" maxlength="255"
                                                      [ngModel]="OrideCase.REASON" (ngModelChange)="OrideCase.REASON = $event"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="bg-light border-0" colspan="4">Previously Overridden Reason(s):</th>
                                    </tr>
                                    <tr>
                                        <td class="bg-light border-0" colspan="4" style="font-size: 12px;">Click "Select" link below to use a previously overridden reason to override this rule.</td>
                                    </tr>
                                    <tr class="bg-light">
                                        <th class=" pl-3">  SELECT      </th>
                                        <th class="">       REASON      </th>
                                        <th class="">       ACTION #    </th>
                                    </tr>
                                    <tr class="" *ngFor="let overriddenRule of CaseOverRiddenRulesReasons">
                                        <td class="">
                                            <button class="btn btn-link" (click)="onSelect(overriddenRule.REASON)"><u>Select</u></button>
                                        </td>
                                        <td class="">
                                            <label>{{overriddenRule?.REASON}}</label>
                                        </td>
                                        <td class="">
                                            <button class="btn btn-sm btn-light border-secondary" (click)="onHide(overriddenRule)" [disabled]="blnPvtReadOnly">Hide</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





