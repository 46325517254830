<div class="row" (window:keydown)="tabNavigation($event)">
    <div class="col-xl" *ngIf="acc">
        <div class="card border-dark">
            <div class="card-body pt-0">
                <div class="row">
                    <div class="col-xl">
                        <div class="border-bottom border-secondary">
                            <ul class="nav nav-fill">
                                <li class="nav-item my-auto no-pointer" *ngIf="intMode == 256">
                                    <span class="align-middle">State: </span>
                                    <span class="align-middle fw-bold">{{stateString}}</span>
                                </li>
                                <li class="nav-item my-auto" *ngIf="intMode == 1 || intMode == 64 || intMode == 128 || intMode == 256">
                                    <span class="align-middle">Case #</span>
                                    <button *ngIf="intMode == 64 || intMode == 128" class="btn btn-link" (click)="onGoToTheCase()">{{ acc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : '' }}</button>
                                    <button *ngIf="intMode == 256" class="btn btn-link" (click)="onGoToTheCase()">{{ acc.Acc_SS.MOSSCasenum }}</button>
                                </li>
                                <li class="nav-item my-auto no-pointer" *ngIf="intMode == 256">
                                    <span class="align-middle">Status: </span>
                                    <span class="align-middle fw-bold">{{caseStatus}}</span>
                                </li>
                                <li class="nav-item my-auto" *ngIf="intMode == 4">
                                    <span class="align-middle">PAR ID</span>
                                    <button class="btn btn-link" (click)="onGoToTheCase()">{{ acc.EarlyNotify[0]?.PARID | number : '8.0-0' | replaceTextPipe : ',' : '' | slice : 4 : 12 }}</button>
                                </li>
                                <li class="nav-item my-auto" *ngIf="intMode == 1">
                                    <span class="align-middle">EN Case #</span>
                                    <button class="btn btn-link" (click)="onGoToTheEN()">{{acc.EarlyNotify[0].ENCaseNum | number : '4.0-0' | replaceTextPipe : ',' : '' }}</button>
                                </li>
                                <li class="nav-item my-auto no-pointer" *ngIf="intMode == 64 || intMode == 128">
                                    <span class="align-middle">Non-Occ Forms: </span>
                                    <span class="align-middle fw-bold" *ngIf="acc.NMForms !== -1; else ZeroNMForms">{{acc?.NMForms}}</span>
                                    <ng-template #ZeroNMForms>
                                        <span class="align-middle fw-bold">0</span>
                                    </ng-template>
                                </li>
                                <li class="nav-item my-auto no-pointer" *ngIf="intMode == 64 || intMode == 128">
                                    <span class="align-middle">Veh Forms: </span>
                                    <span class="align-middle fw-bold" *ngIf="acc.VForms !== -1; else ZeroVForms">{{acc?.VForms}}</span>
                                    <ng-template #ZeroVForms>
                                        <span class="align-middle fw-bold">0</span>
                                    </ng-template>
                                </li>
                                <li class="nav-item my-auto no-pointer" *ngIf="intMode == 64 || intMode == 128">
                                    <span class="align-middle">Occ Forms: </span>
                                    <span class="align-middle fw-bold" *ngIf="acc.PForms !== -1; else ZeroVForms">{{acc?.PForms}}</span>
                                    <ng-template #ZeroPForms>
                                        <span class="align-middle fw-bold">0</span>
                                    </ng-template>
                                </li>
                                <li class="nav-item my-auto no-pointer" *ngIf="intMode == 64 || intMode == 128 || intMode == 256">
                                    <span class="align-middle">Last Updated By: </span>
                                    <span class="align-middle fw-bold">{{acc.strLastUpdatedBy}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
 
                <div class="row pt-2">
                    <div class="col-xl">
                        <!-- hide Nav Tabs if we are at Override Rule page with *ngIf="!blnIsoverrideRule"-->
                        <ul class="nav nav-tabs" *ngIf="!blnIsoverrideRule">
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]='["./violatedRules"]'
                                   [ngClass]="{'bg-light': linkViolatedRules.isActive !== true, 'border-bottom' : linkViolatedRules.isActive !== true}"
                                   [routerLinkActive]="['active']" #linkViolatedRules="routerLinkActive">
                                    <span class="text-dark" [ngClass]="{'text-decoration-none': linkViolatedRules.isActive === true, 'fw-bold' : linkViolatedRules.isActive === true}">Violated Rules</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]='["./blanks"]'
                                   [ngClass]="{'bg-light': linkBlanks.isActive !== true, 'border-bottom' : linkBlanks.isActive !== true}"
                                   [routerLinkActive]="['active']" #linkBlanks="routerLinkActive">
                                    <span class="text-dark" [ngClass]="{'text-decoration-none': linkBlanks.isActive === true, 'fw-bold' : linkBlanks.isActive === true}">Blanks</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]='["./overriddenRules"]'
                                   [ngClass]="{'bg-light': linkOverriddenRules.isActive !== true, 'border-bottom' : linkOverriddenRules.isActive !== true}"
                                   [routerLinkActive]="['active']" #linkOverriddenRules="routerLinkActive">
                                    <span class="text-dark" [ngClass]="{'text-decoration-none': linkOverriddenRules.isActive === true, 'fw-bold' : linkOverriddenRules.isActive === true}">Overridden Rules</span>
                                </a>
                            </li>
                        </ul>

                        <div class="row">
                            <div class="col-10">
                                <!--here we generate and show Check Case components-->
                                <router-outlet></router-outlet>
                            </div>
                            <!--<div class="col-2 {{ blnIsoverrideRule === 'false' ? 'pt-2' : ''}}">-->
                            <div class="col-2 pt-2">
                                <div class="border-left border-secondary pl-2">
                                    <div class="form-group">
                                        <button id="btnBackToCaseFromCheckCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="onBackToCase($event, accid)">
                                            <span class="fw-bold">Back to the Case</span>
                                        </button>
                                    </div>
                                    <div class="form-group" *ngIf="!blnIsoverrideRule">
                                        <button id="btnCloseCaseFromCheckCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="OnCloseCase()">
                                            <span class="fw-bold">Close Case</span>
                                        </button>
                                    </div>
                                    <div class="form-group" *ngIf="blnIsoverrideRule">
                                        <button id="btnCheckCaseFromCheckCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="onBackToCheckCase()">
                                            <span class="fw-bold">Check Case</span>
                                        </button>
                                    </div>
                                    <div class="form-group" *ngIf="blnIsoverrideRule">
                                        <button id="btnSubmitFromCheckCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="saveOverrideReason()">
                                            <span class="fw-bold">Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
