import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Acc } from 'src/app/models/acc';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DBMode } from 'src/app/models/enums/app.enums';

@Component({
  selector: 'app-airbagchildsafety',
  templateUrl: './airbagchildsafety.component.html',
  styleUrls: ['./airbagchildsafety.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AirbagchildsafetyComponent implements OnInit {

    caseNumber: number = 0;
    intMode: number = 1;
    parId: string = "";
    acc: Acc;
    constructor(public activeModal: NgbActiveModal) { }
   

    ngOnInit() {
        this.caseNumber = this.acc.Casenum;
        this.intMode = this.acc.Mode;
        if(this.intMode == DBMode.CRSS) this.parId = this.acc.EarlyNotify[0].PARID.toString().slice(4);
  }

}
