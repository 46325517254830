import { Component, OnInit, Input } from '@angular/core';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { AttributeMatchLevel } from 'src/app/models/attr-match-level';
import { usp_EDT_GetStylesByStateNum_Result } from 'src/app/models/usp_EDT_GetStylesByStateNum_Result';
import { UtilService } from 'src/app/services/util.service';
import { RBISDataValue, PropertyType } from 'src/app/models/enums/app.enums';
import { AutofillService } from 'src/app/services/autofill.service';
import { usp_EDT_GetAttributeMatchLevels_Result } from 'src/app/models/usp_EDT_GetAttributeMatchLevels_Result';

@Component({
    selector: 'app-edt-confidence-color',
    templateUrl: './edt-confidence-color.component.html',
    styleUrls: ['./edt-confidence-color.component.css']
})
export class EdtConfidenceColorComponent implements OnInit {

    @Input() strTableName: string;
    @Input() strFieldName: string;
    @Input() strFieldParam: string;
    @Input() intStateNum: number;
    @Input() selectedValue: any;
    @Input() objModel: any;
    @Input() blnEnableEDTColor: boolean = true;
    @Input() blnStandAloneEDTColor: boolean = false;
    @Input() arrOptions: DrpDownOptions[];


    matchLevel: string;
    blnHideLight: boolean = false;
    blnHideDanger: boolean = false;
    blnHideWarning: boolean = false;
    blnHidePrimary: boolean = false;
    blnHideInfo: boolean = false;
    blnHideSuccess: boolean = false;

    _arrAttributeLevel: AttributeMatchLevel[];
    arrStateStyle: Array<usp_EDT_GetStylesByStateNum_Result> = [];
    arrStateAttributeLevel: AttributeMatchLevel[];

    constructor(
        private _utilService: UtilService
    ) { }

    ngOnInit(): void {

    }

    public HideEDTConfidenceColor(): void {
        this.blnHideLight = false;
        this.blnHideDanger = false;
        this.blnHideWarning = false;
        this.blnHidePrimary = false;
        this.blnHideInfo = false;
        this.blnHideSuccess = false;
    }

    public SetConfidenceColor(strMatchLevel: string): void {
        this.HideEDTConfidenceColor();
        if (this.arrStateStyle && this.arrStateStyle.length > 0) {
            //console.log('EDT Match Level->', strMatchLevel);
            if (strMatchLevel == '0')
                this.blnHideLight = true;
            else if (strMatchLevel == this.arrStateStyle[1].StyleName) {
                this.blnHideDanger = true;
            }
            else if (strMatchLevel == this.arrStateStyle[2].StyleName) {
                this.blnHideWarning = true;
            }
            else if (strMatchLevel == this.arrStateStyle[3].StyleName) {
                this.blnHidePrimary = true;
            }
            else if (strMatchLevel == this.arrStateStyle[4].StyleName) {
                this.blnHideInfo = true;
            }
            else if (strMatchLevel == '100') {
                this.blnHideSuccess = true;
            }
            else {
                this.HideEDTConfidenceColor();
            }
            this.matchLevel = strMatchLevel;
        }
    }

    public BindTypeAheadElementEDTColor(intStateNum: number, strFieldParam: string, arrStateStyles: usp_EDT_GetStylesByStateNum_Result[] = null, arrAttrMatchLevel: AttributeMatchLevel[]) {
        if (!this.selectedValue)
            this.selectedValue = this.objModel[this.strFieldName];
        if (this.objModel && this.blnEnableEDTColor) {
            this.arrStateStyle = arrStateStyles;
            this.arrStateAttributeLevel = arrAttrMatchLevel.filter(i => i.StateNum == intStateNum);

            let arrAttributeLevel: AttributeMatchLevel[] = arrAttrMatchLevel.filter(i => i.StateNum == intStateNum);
            if (arrAttributeLevel) {
                arrAttributeLevel = arrAttributeLevel.filter(i => i.FormName == this.strTableName && i.FieldName == strFieldParam);
                if (arrAttributeLevel) {
                    let attrLevel: AttributeMatchLevel;
                    if ((arrAttributeLevel.length == 1 || strFieldParam.includes('Date') || strFieldParam.includes('Time'))
                        && (arrAttributeLevel.find(i => i.Value == RBISDataValue.Minus1))) {
                        attrLevel = arrAttributeLevel.find(i => i.Value == RBISDataValue.Minus1);
                    }
                    else {
                        if (this.arrOptions) {
                            let option: DrpDownOptions = AutofillService.GetDataType(this.objModel, this.strFieldName) == PropertyType.String
                                ? this.arrOptions.find(i => i.strValue == this.objModel[this.strFieldName])
                                : this.arrOptions.find(i => i.intValue == this.objModel[this.strFieldName]);
                            if (option)
                                attrLevel = arrAttributeLevel.find(i => parseInt(i.Value) == option.intValue);
                            else
                                if (arrAttributeLevel.find(i => i.Value == '-2'))
                                    attrLevel = arrAttributeLevel.find(i => i.Value == '-2');
                        }
                        else if (arrAttributeLevel.find(i => i.Value == '-2'))
                            attrLevel = arrAttributeLevel.find(i => i.Value == '-2');
                    }
                    if (attrLevel) {
                        if ((this.selectedValue === RBISDataValue.Blank || this.selectedValue === RBISDataValue.Minus1 || this.selectedValue === RBISDataValue.Empty) && (strFieldParam !== 'AccDate')) return;
                        this.SetConfidenceColor(attrLevel.MatchLevel);
                    }
                }
            }
        }
    }

    public SetSelectedItemEDTColor(objItem: DrpDownOptions, strValue: any, strFieldParam: string): void {

        if (this.arrStateAttributeLevel != null) {

            let arrAttributeLevel = this.arrStateAttributeLevel.filter(i => i.FormName == this.strTableName && i.FieldName == strFieldParam);
            if (arrAttributeLevel) {
                let attrLevel: AttributeMatchLevel;
                if ((arrAttributeLevel.length == 1 || strFieldParam.includes('Date') || strFieldParam.includes('Time'))
                    && (arrAttributeLevel.find(i => i.Value == RBISDataValue.Minus1))) {
                    attrLevel = arrAttributeLevel.find(i => i.Value == RBISDataValue.Minus1);
                }
                else {
                    if (objItem)
                        attrLevel = AutofillService.GetDataType(this.objModel, this.strFieldName) == PropertyType.Number
                            ? arrAttributeLevel.find(i => parseInt(i.Value) == objItem.intValue)
                            : arrAttributeLevel.find(i => i.Value == objItem.strValue);
                    else if (strValue || arrAttributeLevel.find(i => i.Value == '-2'))
                        attrLevel = arrAttributeLevel.find(i => i.Value == '-2');
                }
                if (attrLevel) {
                    if ((objItem.intValue === RBISDataValue.Blank || strValue === RBISDataValue.Blank || strValue === RBISDataValue.Minus1 || strValue === RBISDataValue.Empty) && (strFieldParam !== 'AccDate')) return;
                    this.SetConfidenceColor(attrLevel.MatchLevel);
                }
            }
        }

    }

    public BindTextFieldEDTColor(intStateNum: number, strFieldParam: string, arrStateStyles: usp_EDT_GetStylesByStateNum_Result[] = null) {

        let arrCachedEDTAttrMatchLevels = this._utilService._arrAttributeMatchLevels;
        this.arrStateStyle = arrStateStyles;
        this._arrAttributeLevel = arrCachedEDTAttrMatchLevels.filter(i => i.StateNum == intStateNum);
        if (this._arrAttributeLevel) {

            this._arrAttributeLevel = this._arrAttributeLevel.filter(i => i.FormName == this.strTableName && i.FieldName == strFieldParam);
            if (this._arrAttributeLevel) {
                let attrLevel: AttributeMatchLevel = this._arrAttributeLevel.find(i => i.Value == RBISDataValue.Minus1);
                if (attrLevel) {
                    this.SetConfidenceColor(attrLevel.MatchLevel);
                }
            }
        }
    }

    public SetStandAloneElementEDTColor(intStateNum: number, strFieldParam: string, strCurrentTab: string): void {
        this._utilService.GetStateEDTStyle(intStateNum).toPromise()
            .then((arrStyle: usp_EDT_GetStylesByStateNum_Result[]) => {
                this._utilService.GetStateAttributeMatchLevelsByFormNamePromise(intStateNum, strCurrentTab).then(arrAttributeLevels => {
                    this.BindTypeAheadElementEDTColor(intStateNum, strFieldParam, arrStyle, arrAttributeLevels);
                })
            });
    }

    ngAfterViewInit(): void {

    }

}
