import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { Router } from '@angular/router';

/**
 * Wraps an ng Bootstrap NgbActiveModal observer that implements close(result:any) and dismiss(dismissalReason: any)
 * methods that will be called when the user prompt is interacted with.
 **/
@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MessageBoxComponent implements OnInit {

    public body: string;
    public title: string = '';
    public boxType: string = 'M';
    public module: string = 'D';
    public isRelaseLock = false;
    public blnShowRelaseCaseBtn = false;

    public caseNum: any;
    public strYesText: string;
    public strNoText: string;
    public strDismissText: string;
    public stateNum: any;
    selectedMode: number = 1;
    public focusTo: string = 'yes';
    isAlert: boolean = false;

    constructor(public activeModal: NgbActiveModal,
        private sharedDataService: SharedDataService,
        private _router: Router, ) { }

    async ngOnInit() {

        this.stateNum = this.sharedDataService.getSelectedState();
        let appSettings = await this.sharedDataService.GetAppSettings();
        this.selectedMode = appSettings.intMode
    }

    gotoRelease() {
        if (this.selectedMode == 4 || this.selectedMode == 64 || this.selectedMode == 128 || this.selectedMode == 256) {
            this._router.navigate(['releaserecords'])
        } else {
            this._router.navigate([this.stateNum, 'releaserecords'])
        }
        this.activeModal.dismiss('out');
    }
}
