
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Airbag Child Safety</h5>
    </div>
    <div class="modal-body">
        <div class="row col-sm-12 text-center">
            <div class="col-sm-12">
                <h6 *ngIf="intMode != 4"><strong>Case Number: {{caseNumber | number : '4.0-0' | replaceTextPipe : ',' : ''}} </strong> </h6>
                <h6 *ngIf="intMode == 4"><strong>PAR ID: {{parId}} </strong> </h6>

                <h6><strong>AIRBAG FATALITY NOTIFICATION</strong></h6>
            </div>
        </div>
      
        <div class="row col-sm-12">
            <h6><strong>Please submit PAR to:</strong></h6>
            <div class="col-sm-12">
                <pre>
John Brophy
U.S. Dept. of Transportation/NHTSA
Rm W53-301
1200 New Jersey Ave SE
Washington, DC 20590
Tel: (202) 366-0328
Fax: (202) 366-3189
Email: john.brophy@dot.gov
</pre>
            </div>
        </div>
        <div class="row col-sm-12 text-center">
            <div class="col-sm-12">
                <h6><strong>Thank You!</strong></h6>
                <button type="button" class="btn btn-outline-primary" ngbAutoFocus (click)="activeModal.close(true)">OK</button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
    </div>

