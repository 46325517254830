<div >
    <div class="modal-header" >
        <h4 class="modal-title">
            {{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">PAR ID:
                <span class="fw-bold">
                {{ acc.EarlyNotify[0].PARID | number: '8.0-0' | replaceTextPipe : ',' : '' | maxLengthPipe : 12 : '' | slice: 4:12 }}
                </span>
            </div>
            <div class="col-sm-6">PSU:  <span class="fw-bold">{{ acc.EarlyNotify[0]?.StateNum | valueToDescription: 'VR_PSU' | async }}</span></div>
            <div class="col-sm-6">PJ: <span class="fw-bold">{{ jurisdiction }}</span></div>
            <div class="col-sm-6">PAR#: <span class="fw-bold">{{ acc.EarlyNotify[0].PARNumber }} </span></div>
            <div class="col-sm-3">
                PAR Error:
            </div>
            <div class="col-sm-3">
                <select  [(ngModel)]="errorId" class="form-control">
                    <option *ngFor="let item of parErrorCodeList" [value]="item.ID">{{item.DEF}}</option>
                </select>
            </div>
            <div class="col-sm-6 m-0 p-0" *ngIf="errorId == 8">
                <div class="col-sm-6">Duplicate PAR ID:</div>
                <div class="col-sm-6">
                    <input class="form-control" [(ngModel)]="intParId_Duplicate" />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="dialogOK"
                class="btn btn-light border-secondary"
                (click)="setParError()">
            Set PAR Error
        </button>

        <button type="button" *ngIf="!isUnDeleteMode"
                class="btn btn-light border-secondary"
                (click)="deleteCase();">
            Delete
        </button>
        <button type="button" *ngIf="isUnDeleteMode"
                class="btn btn-light border-secondary"
                (click)="undeleteCase();">
            Undelete
        </button>
        <button ngbAutofocus type="button" id="DialogCancel"
                class="btn btn-light border-secondary"
                (click)="activeModal.close(false)">
            Back to Case
        </button>

    </div>
</div>


<ng-template #alertMessage let-modal>
    <div class="modal-body text-center">
        <p>{{message}}</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="innerPopup.close('ok')">
            Ok
        </button>
    </div>
</ng-template>
