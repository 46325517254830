import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[dragDrop]'
})

export class DragDropDirective {

    @Output() onFileDropped = new EventEmitter<any>();

    //@HostBinding('style.background-color') private background = '#f5fcff'
    @HostBinding('style.opacity') private opacity = '1'

    constructor() { }

    //Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        if (!evt.dataTransfer || !evt.dataTransfer.items || evt.dataTransfer.items[0].kind !== 'file') {
            return;
        }
        evt.preventDefault();
        evt.stopPropagation();
        //this.background = '#9ecbec';
        this.opacity = '0.8'
    }
    //Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        //this.background = '#f5fcff'
        this.opacity = '1'
    }
    //Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        //this.background = '#f5fcff'
        this.opacity = '1'
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files)
        }

    }

}
