<form novalidate #f="ngForm">
    <div [ngClass]="{'container pt-2' : !printOnly}">
        <div *ngIf="!printOnly">
            <p style="font-size:24px">
                Select all the values that are appropriate.
            </p>
        </div>

        <div class="form-group aliceblue" *ngIf="!printOnly">
            <h3>Areas of Impact - Damaged Areas</h3>
        </div>
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-12 border border-dark ' : printOnly === true}">
                <table style="width:100%">
                    <tr *ngIf="!printOnly">
                        <td>
                            <table style="width:100%">
                                <tr>
                                    <td>
                                        <button type="button" id="selectAllCheckBoxes" name="selectAllCheckBoxes" class="btn btn-light border-secondary custom5" [disabled]="blnReadOnly"
                                                (click)="selectAllCheckBoxes()">
                                            <span class="fw-bold">Select All</span>
                                        </button>
                                        <button type="button" Id="resetCheckBox" name="resetCheckBox" class="btn btn-light border-secondary custom5" [disabled]="blnReadOnly"
                                                (click)="resetCheckBox()">
                                            <span class="fw-bold">Clear All</span>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table>
                                <tr>
                                    <td>
                                        <table>
                                            <tr *ngFor="let objOption of arrDamageAreasOptions; let i=index;">
                                                <td class="col-md-6 col-sm-2">
                                                    <input type="checkbox" [name]="'impactResultSet' + i.toString()" [id]="'impactResultSet' + i.toString()" [disabled]="blnReadOnly || printOnly"
                                                           [(ngModel)]="objOption.isSpecify" (change)="isChecked(objOption)" [ngStyle]="checkboxStyle" />
                                                    <label class="form-check-label selectcursor " (click)="isChecked(objOption, !objOption.isSpecify)" for="'impactResultSet' + i.toString()" *ngIf="!printOnly">
                                                        {{objOption.displayText}}
                                                    </label>
                                                    <label class="form-check-label selectcursor" for="'impactResultSet' + i.toString()" *ngIf="printOnly">
                                                        {{objOption.displayText}}
                                                    </label>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="width:25%;" *ngIf="!printOnly">&nbsp;</td>
                                    <td style="width:10%;" *ngIf="printOnly">&nbsp;</td>
                                    <td>
                                        <table>
                                            <tr>
                                                <td colspan="3" class="red-text text-center">
                                                    <label type="label" class="labelformat" *ngIf="!printOnly">Click on the image to select areas,</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="red-text text-center">
                                                    <label type="label" class="labelformat" *ngIf="!printOnly">or use check boxes on the left</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="black-text text-center bg-light">
                                                    <label type="label" class="labelformat">Damaged Areas</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectCheckBoxRange('7,8,9,10,11')" [ngStyle]="comboStyle3Box">
                                                        Left<br><img src=" ../../../../../assets/images/damagedArea/arrow-down-solid.png" />
                                                    </button>
                                                </td>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells " [id]="['button12'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(12)" [ngStyle]="comboStyle1Box">
                                                        12
                                                    </button>

                                                </td>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button13'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(13)" [ngStyle]="comboStyle3Box">
                                                        Top<br>13
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button11'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(11)" [ngStyle]="comboStyle1Box">
                                                        11
                                                    </button>
                                                </td>
                                                <td rowspan="5" class="col-md-3 col-sm-2">
                                                    <img style="width:75px;height:300px" src="../../../../../assets/images/damagedArea/AOIDamaged.png" />
                                                </td>

                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary  tablecells" [id]="['button1'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(1)" [ngStyle]="comboStyle1Box">
                                                        1
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary  tablecells" [id]="['button10'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(10)" [ngStyle]="comboStyle1Box">
                                                        10
                                                    </button>
                                                </td>

                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary  tablecells" [id]="['button2'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(2)" [ngStyle]="comboStyle1Box">
                                                        2
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button9'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(9)" [ngStyle]="comboStyle1Box">
                                                        9
                                                    </button>
                                                </td>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button3'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(3)" [ngStyle]="comboStyle1Box">
                                                        3
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button8'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(8)" [ngStyle]="comboStyle1Box">
                                                        8
                                                    </button>
                                                </td>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button4'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(4)" [ngStyle]="comboStyle1Box">
                                                        4
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button7'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(7)" [ngStyle]="comboStyle1Box">
                                                        7
                                                    </button>
                                                </td>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button5'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(5)" [ngStyle]="comboStyle1Box">
                                                        5
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button14'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(14)" [ngStyle]="comboStyle3Box">
                                                        14<br>Under
                                                    </button>
                                                </td>
                                                <td class="col-md-3 col-sm-2">
                                                    <button type="button" class="btn btn-outline-secondary tablecells" [id]="['button6'+vehicleid]" [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectOneCheckBox(6)" [ngStyle]="comboStyle1Box">
                                                        6
                                                    </button>
                                                </td>
                                                <td class="col-md-3 col-sm-2">

                                                    <button type="button" class="btn btn-outline-secondary tablecells"
                                                            [disabled]="blnReadOnly || printOnly"
                                                            (click)="selectCheckBoxRange('1,2,3,4,5')" [ngStyle]="comboStyle3Box">
                                                        <img src=" ../../../../../assets/images/damagedArea/arrow-up-solid.png" />
                                                        <br>Right
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        </div>
</form>
