<form novalidate #fnonOccinjury="ngForm">
    <div *ngIf="nonOccupant">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="medRptedCauseOfDth" name="medRptedCauseOfDth" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Injury" strTableName="NonOcc_Injury" strFieldName="MedRptedCauseOfDth"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="evdncHdBrnInj" name="evdncHdBrnInj" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Injury" strTableName="NonOcc_Injury" strFieldName="EvdncHdBrnInj"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="evdncLowExtrmtyInj" name="evdncLowExtrmtyInj" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Injury" strTableName="NonOcc_Injury" strFieldName="EvdncLowExtrmtyInj"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="evdncUprExtrmtyInj" name="evdncUprExtrmtyInj" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Injury" strTableName="NonOcc_Injury" strFieldName="EvdncUprExtrmtyInj"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="evdncThorcicInj" name="evdncThorcicInj" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Injury" strTableName="NonOcc_Injury" strFieldName="EvdncThorcicInj"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>
