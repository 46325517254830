import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';

import { Acc } from 'src/app/models/acc';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { KeyCode, DBMode } from 'src/app/models/enums/app.enums';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { AutofillService } from 'src/app/services/autofill.service';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { NonOcc_Ped } from 'src/app/models/non-occ-ped';


@Component({
    selector: 'app-non-occupant-supplemental',
    templateUrl: './non-occupant-supplemental.component.html',
    styleUrls: ['./non-occupant-supplemental.component.css']
})

export class NonOccupantSupplementalComponent extends BaseComponent implements OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_NonOccupantSupplementalComponent: string = null;
    private sbsNonOccId: Subscription;

    accid: number;
    nonOccid: number;
    intYear: number;
    intMode: number;

    acc: Acc;
    nonOccupant: Non_Occupants;
    //pedestrian: NonOcc_Ped = { _TypeScript_TypeGuard_NonOcc_Ped: null } as NonOcc_Ped;
    arrPossiableYears: number[] = [8888, 9999];

    @ViewChild('txtDeathCity') txtDeathCity: TypeaheadComponent;
    @ViewChild('typAhdSchoolZone') typAhdSchoolZone: TypeaheadComponent;
    @ViewChild('typAhdSidewalk') typAhdSidewalk: TypeaheadComponent;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {

        let dateTimeError = "";
        dateTimeError = ObjectUtil.dateTimeValidations(this.nonOccupant.DthYr, this.nonOccupant.DthMon, this.nonOccupant.DthDay, this.nonOccupant.DthHr, this.nonOccupant.DthMin, this.intYear, this.intMode);

        if (dateTimeError != "") {
            this._modalService.setMessage(dateTimeError, 'error');
            this.blnAlloweSave = false;
            return;
        }

        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        super.ngOnInit();

        let appSettings: AppSettings = await this._sharedService.GetAppSettings()
        this.intMode = appSettings.intMode;
        this.intYear = appSettings.intYear;
        let currentYear: number = new Date().getFullYear();         

        if (this.printNonOccupantNum == 0) {
            this._route.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];

                let startYear: number = this.intYear || 2019;
                while (startYear <= currentYear) {
                    this.arrPossiableYears.push(startYear++);
                }
            });
            
        }
        else {
            this.accid = this.acc.AccID;
        }

        if (this.sbsNonOccId) {
            this.sbsNonOccId.unsubscribe();
        }

        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
        }
    }

    FormLoad() {
        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);

        //if (this.nonOccupant.NonOcc_Ped == null) {
        //    this.nonOccupant.NonOcc_Ped = this.pedestrian;
        //}

        if (this.nonOccupant.NonOcc_Ped == null) {
            this._autofillService.AddPedestrian(this.nonOccupant);
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {

    }

    onModalChange($event: KeyboardEvent) {
        if ($event.code == KeyCode.NumpadSubtract || $event.code == KeyCode.Minus) {
            let uiControl: UIElementBase = AutofillService.arrControls.find(i => i.strFieldName == FieldName.DiedScene);
            if (uiControl) {
                let intBeforeChange: number = this.nonOccupant.DiedScene;
                uiControl.objModel['DiedScene'] = 7;
                //let cloneObject: Non_Occupants = this.nonOccupant;
                AutofillService.OnChange(uiControl, this.nonOccupant, 'DiedScene', true, true, false);
                this.nonOccupant.DiedScene = intBeforeChange;
            }
        }
    }

    public OnDeathStateChange(intNewValue: number): void {
        this.txtDeathCity.FilterFieldOptions();
    }
}
