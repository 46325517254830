

import { Acc } from 'src/app/models/acc';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { NonOcc_Race } from 'src/app/models/non-occ-race';
import { Veh } from 'src/app/models/veh';
import { Dri } from 'src/app/models/dri';
import { PreCrash } from 'src/app/models/pre-crash';
import { Occupants } from 'src/app/models/occupants';
import { Occ_Race } from 'src/app/models/occ-race';
import { Acc_AtmCond } from 'src/app/models/acc-atm-cond';
import { VehRF } from 'src/app/models/veh-rf';
import { NonOcc_CondAtCrashTime } from 'src/app/models/non-occ-cond-at-crash-time';
import { NonOcc_ActPriorCrash } from 'src/app/models/non-occ-act-prior-crash';
import { NonOcc_ActAtCrash } from 'src/app/models/non-occ-act-at-crash';
import { NonOcc_Distract } from 'src/app/models/non-occ-distract';
import { NonOccRF } from 'src/app/models/non-occ-rf';
import { DriCondAtCrashTime } from 'src/app/models/dri-cond-at-crash-time';
import { DriViol } from 'src/app/models/dri-viol';
import { DriRF } from 'src/app/models/dri-rf';
import { AccRF } from 'src/app/models/acc-rf';
import { OccRF } from 'src/app/models/occ-rf';
import { PreCrash_DriVisObs } from 'src/app/models/pre-crash-dri-vis-obs';
import { PreCrash_DriAvoidManvr } from 'src/app/models/pre-crash-dri-avoid-manvr';
import { PreCrash_DriDistract } from 'src/app/models/pre-crash-dri-distract';
import { PreCrash_ContribCirc } from 'src/app/models/pre-crash-contrib-circ';
import { EarlyNotify } from '../models/early-notify';
import { formatDate } from '@angular/common';
import { DrpDownOptions } from '../models/drp-down-options';
import { DriPrevSus } from '../models/dri-prev-sus';
import { Element_Specify } from '../models/element-specify';
import { RBISDataValue, PersonRace, DBMode } from '../models/enums/app.enums';
import { NonOcc_Safety } from '../models/non-occ-safety';
import { OrideCase } from 'src/app/models/oride-case';
import { RRF_MTSS } from '../models/rrf-mtss';
import { OEF_MTSS } from '../models/oef-mtss';
import { TFIF_MTSS } from '../models/tfif-mtss';
import { WRF_MTSS } from '../models/wrf-mtss';
import { VCF_MTSS } from '../models/vcf-mtss';
import { Veh_SSAvoidEquipAvail } from '../models/Veh-SSAvoidEquipAvail';
import { PreFHEMnvr_SS } from '../models/PreFHEMnvr_SS';
import { LightingClr_SS } from '../models/LightingClr_SS';
import { SuplTrfcCtrl_SS } from '../models/SuplTrfcCtrl_SS';
import { Occ_BeltUsed } from '../models/occ-beltused';
import { Acc_RelRdwy } from '../models/Acc_RelRdwy';
import { PreCrash_DriInattention } from '../models/pre-crash-dri-inattention';
import { NonOcc_Bike } from '../models/non-occ-bike';
import { Dri_Illness } from '../models/dri-illness';
import { Dri_DriInattention } from '../models/dri-driinattention';
import { Dri_DriDistract } from '../models/dri-dridistract';

export class ObjectUtil {

    /**
     * Returns the class name of the passed in data model entity. Relies on the Entity Framework model including a "type guard" member to allow reflection
     * on the type name at runtime.
     **/
    public static GetTypeName(objModel: any): string {
        if (!objModel) return null;

        if (Array.isArray(objModel)) {
            if (objModel.length > 0)
                objModel = objModel[0];
            //else may still have type guard info on empty array
        }

        for (let strKey in objModel) {
            if (strKey.startsWith('_TypeScript_TypeGuard_')) //TypeGuard property is added by our TypeLite output processing logic.
                return strKey.substr('_TypeScript_TypeGuard_'.length);
        }

        if (!Array.isArray(objModel))
            console.log('Warning: ObjectUtil.GetType() was given a non-data-model object.');

        return null;
    }

    /**
     * Traverses the data model until an instance of EarlyNotify is found. May return null.
     **/
    public static FindRoot(objNode: any, strRootName: string = 'EarlyNotify', arrVisited: Array<any> = null): any {
        let objChild: any;
        let objRoot: any = null;

        if (arrVisited == null)
            arrVisited = new Array<any>();

        if (arrVisited.indexOf(objNode) != -1)
            return null;
        else
            arrVisited.push(objNode);

        if (!Array.isArray(objNode) && ObjectUtil.GetTypeName(objNode) == strRootName)
            return objNode; //Recursion stop condition

        for (let strChild in objNode) { //If objNode is an array for(in) loops over the array elements.
            objChild = objNode[strChild];

            if (!Array.isArray(objChild) && strChild == strRootName)
                return objChild; //Recursion stop condition

            if (objChild != null) {
                if (!Array.isArray(objChild)) {
                    if (!this.IsValueType(objChild)) {
                        if (arrVisited.indexOf(objChild) == -1) {
                            objRoot = this.FindRoot(objChild, strRootName, arrVisited);

                            if (objRoot)
                                return objRoot; //Return result to previous recursion frame
                        }
                    }
                }
                else {
                    for (let objElement of objChild) {
                        if (!this.IsValueType(objElement)) {
                            objRoot = this.FindRoot(objElement, strRootName, arrVisited);

                            if (objRoot)
                                return objRoot; //Return result to previous recursion frame
                        }
                    }
                }
            }
        }

        return objRoot;
    }

    /**
     * Traverses the passed in entity in breadth first order and calls the passed in function for each node.
     **/
    public static TraverseEntity(objEntity: any, fptrProcess: Function): void {
        let queTraversal: Array<any> = new Array<any>();
        let lVisited: Array<any> = new Array<any>();
        let objNode: any;

        queTraversal.push(objEntity);

        while (queTraversal.length > 0) {
            objNode = queTraversal.shift();

            ObjectUtil.TraverseEntityProcessNode(objNode, queTraversal, lVisited);

            fptrProcess(objNode);
        }
    }

    private static TraverseEntityProcessNode(objNode: any, queTraversal: Array<any>, lVisited: Array<any>): void {
        if (lVisited.indexOf(objNode) != -1)
            return;

        if (!ObjectUtil.IsValueType(objNode) && Array.isArray(objNode)) {
            for (let objElement of objNode) {
                if (ObjectUtil.IsValueType(objElement)) //Collection of primitives like byte[]
                    break;

                queTraversal.push(objElement);
            }
        }
        else {
            lVisited.push(objNode);

            for (let objValue of objNode) {
                if (objValue && !ObjectUtil.IsValueType(objValue))
                    if (Array.isArray(objValue))
                        queTraversal.push(objValue);
            }
        }
    }

    /**
     * The out-of-the-box JSON serializer has no way to encode reference loops, so they must be removed from objects that are about to be serialized. Alternatively use util.inspect()
     *
     * WARNING: This function is meant to be used on the root of the data model (i.e. EarlyNotify), DO NOT hand it a pointer into the middle of a data model tree.
     * It can handle a Veh object when it is the root, but cannot handle a Veh attached to an Acc root.
     * (Ex: If processing a Veh attached to an Acc, then in order to remove cycles, we'd need to clear an element in Acc.Veh[])
     **/
    public static RemoveBidirectionalLinks(objNode: any, arrVisited: Array<any> = null): boolean {
        let objChild: any;

        if (arrVisited == null)
            arrVisited = new Array<any>();

        if (arrVisited.indexOf(objNode) != -1)
            return true; //Object already encountered, i.e. this is an upward link
        else
            arrVisited.push(objNode);

        for (let strChild in objNode) {
            objChild = objNode[strChild];

            if (objChild != null) {
                if (!Array.isArray(objChild)) {
                    if (!this.IsValueType(objChild)) {
                        if (arrVisited.indexOf(objChild) != -1)
                            objNode[strChild] = null;
                        else
                            this.RemoveBidirectionalLinks(objChild, arrVisited);
                    }
                }
                else {
                    let arrChild: Array<any> = <Array<any>>objChild;

                    for (let i = arrChild.length - 1; i >= 0; i--) {
                        if (!this.IsValueType(arrChild[i])) {
                            if (this.RemoveBidirectionalLinks(arrChild[i], arrVisited)) {
                                let strTypeName = ObjectUtil.GetTypeName(arrChild[i]);

                                if (strTypeName != null)
                                    arrChild['_TypeScript_TypeGuard_' + strTypeName] = null; //Leave type information behind, otherwise we won't be able to derive type from empty array at runtime

                                arrChild.splice(i, 1);
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Checks for the presence of cycles in the data model
     **/
    public static FindFirstCycle(objNode: any): any {
        let arrVisited = new Array<any>();
        let objFound: any = null;

        ObjectUtil.TraverseEntity(objNode, (x: any) => {
            if(arrVisited.indexOf(x) != -1)
                objFound = x;
            else
                arrVisited.push(x);
        });

        return objFound;
    }

    /**
     * The out-of-the-box JSON serializer on the server side has no way to encode reference loops. So, only downward pointing Entity Framwork navigation properties are serialized.
     * Upward pointing navigation properties can be restored on the client side. Here we rely on a naming convention: the navigation property name
     * corresponds with the type name.
     * Reference loops should be removed again before attempting JSON re-serizliation [to send object back to server] (unless an extended JSON serializer is used, ex: circular-json npm package)
     **/
    public static RestoreBidirectionalLinks(objNode: any, arrVisited: Array<any> = null, objParent: any = null): void {
        let objChild: any;

        if (arrVisited == null) //Must track visited nodes as passed in object may initially have some bidirectional links already populated, which means cycles may already be present.
            arrVisited = new Array<any>();

        if (Array.isArray(objNode)) {
            for (let objElement of objNode)
                ObjectUtil.RestoreBidirectionalLinks(objElement, arrVisited);

            console.log('ObjectUtil.RestoreBidirectionalLinks() was given an array.');
            return;
        }

        let strKey: string = ObjectUtil.GetTypeName(objNode);

        if (arrVisited.indexOf(objNode) != -1)
            return;
        else
            arrVisited.push(objNode);

        for (let strChild in objNode) { //If objNode is an array for(in) loops over the array elements.
            objChild = objNode[strChild];

            if (objChild != null) {
                if (!Array.isArray(objChild)) {
                    if (!this.IsValueType(objChild)) {
                        this.RestoreBidirectionalLinks(objChild, arrVisited, objNode); //Drill down first to avoid following upward link that is about to be added.

                        if (!Array.isArray(objChild[strKey]))
                            objChild[strKey] = objNode; //Must assume the property is there: we can't check for the existence of the key using hasOwnProperty as type information was lost during deserialization and is not available at runtime
                        //TODO: Rely on serialization of empty array instead of presence of type guard on array?
                    }
                }
                else { //Is array
                    if (objChild.length > 0) {
                        for (let objElement of objChild) {
                            if (!this.IsValueType(objElement)) {
                                this.RestoreBidirectionalLinks(objElement, arrVisited, objNode);
                                objElement[strKey] = objNode;
                            }
                        }
                    }
                    else if (objParent) {
                        let objChildAsArray: Array<any> = <Array<any>>objChild;

                        if (ObjectUtil.GetTypeName(objChildAsArray) == ObjectUtil.GetTypeName(objParent)) //Empty array and type name match means this was an upward link as an array. Only works if RestoreBidirectionalLinks left behind type information  on empty array.
                            objChildAsArray.push(objParent);
                    }
                }
            }
        }
    }    

    public static spliceNonOccupantPII(objAcc: Acc, objNonOcc: Non_Occupants) {
        if (objAcc.PII) {
            let i = objAcc.PII.filter(p => p !== null).findIndex(v => v.AccID === objAcc.AccID && v.VNumber === 0 && v.PNumber === objNonOcc.PNumber);
            if (i > -1) {
                objAcc.PII.splice(i, 1);
            }
        }
    }

    public static splicePersonPII(objAcc: Acc, ojbOcc: Occupants) {
        if (objAcc.PII) {
            let i = objAcc.PII.filter(p => p !== null).findIndex(v => v.AccID === objAcc.AccID && v.VNumber === ojbOcc.VNumber && v.PNumber === ojbOcc.PNumber);
            if (i > -1) {
                objAcc.PII.splice(i, 1);
            }
        }
    }

    public static InstantiateBlankDrpDownOption(): DrpDownOptions {

        let objOption = ({
            displayText: '\r\n',
            intValue: RBISDataValue.Blank,
            strValue: '-1',
            strText: '\r\n',
            isSpecify: false
        } as DrpDownOptions);

        return objOption;
    }


    public static InstantiateElementSpecify(objAcc: Acc, intVNumber: number, intPNumber: number): Element_Specify {
        let item = {} as Element_Specify;
        item._TypeScript_TypeGuard_Element_Specify = null;
        item.Acc = objAcc;
        item.AccID = objAcc.AccID;
        item.FieldID = null;
        item.TableID = null;
        item.PNumber = intPNumber;
        item.VNumber = intVNumber;
        item.SpecifyValue = null;
        item.ElementValue = RBISDataValue.Blank;
        return item;
    }


    public static InstantiateDrpDownOption(): DrpDownOptions {

        let objDrpDownOption: DrpDownOptions = {} as DrpDownOptions;
        objDrpDownOption.AllowOneMultiple = null;
        objDrpDownOption.displayText = null;
        objDrpDownOption.ENABLE_CRSS_EDT = null;
        objDrpDownOption.ENABLE_EDT = null;
        objDrpDownOption.FormName = null;
        objDrpDownOption.intValue = null;
        objDrpDownOption.isSpecify = false;
        objDrpDownOption.SortOrder = null;
        objDrpDownOption.displayText = null;
        objDrpDownOption.strText = null;
        objDrpDownOption.strValue = null;
        objDrpDownOption.tblName = null;
        return objDrpDownOption;
    }

    public static InstantiateNonOccRace(objNonOcc: Non_Occupants): NonOcc_Race {
        let item = {} as NonOcc_Race;
        item._TypeScript_TypeGuard_NonOcc_Race = null;
        item.Non_Occupants = objNonOcc;
        item.AccID = objNonOcc.AccID;
        item.PNumber = objNonOcc.PNumber;
        item.Non_Occupants = objNonOcc;
        item.AllowOneMultiple = false;
        item.ElementValue = RBISDataValue.Blank;
        item.SeqNum = 1;
        return item;
    }

    public static InstantiateOccRace(objOcc: Occupants): Occ_Race {
        let item = {} as Occ_Race;
        item._TypeScript_TypeGuard_Occ_Race = null;
        item.AccID = objOcc.AccID;
        item.Occupants = objOcc;
        item.PNumber = objOcc.PNumber;
        item.VNumber = objOcc.VNumber;
        item.Occupants = objOcc;
        item.AllowOneMultiple = false;
        item.ElementValue = RBISDataValue.Blank;
        item.SeqNum = 1;
        return item;
    }

    public static InstantiateOrideCase(objAcc: Acc, RuleID: string, VNumber: number, PNumber: number): OrideCase {
        let item = {} as OrideCase;
        item._TypeScript_TypeGuard_OrideCase = null;
        item.ACCID = objAcc.AccID;
        item.RULEID = RuleID;
        item.VNumber = VNumber;
        item.PNumber = PNumber;
        item.REASON = "";
        item.Acc = objAcc;

        return item;
    }

    public static InstantiateAtmCond(objAcc: Acc): Acc_AtmCond {
        let item = {} as Acc_AtmCond;
        item._TypeScript_TypeGuard_Acc_AtmCond = null;
        item.Acc = objAcc;
        item.AccID = objAcc.AccID;
        item.ElementValue = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static InstantiateAccRF(objAcc: Acc): AccRF {
        let item = {} as AccRF;
        item._TypeScript_TypeGuard_AccRF = null;
        item.AccID = objAcc.AccID;
        item.Acc = objAcc;
        item.ARf = RBISDataValue.Blank;
        return item;
    }

    public static InstantiateAccRelRdwy(objAcc: Acc): Acc_RelRdwy {
        let item = {} as Acc_RelRdwy;
        item._TypeScript_TypeGuard_Acc_RelRdwy = null;
        item.AccID = objAcc.AccID;
        item.Acc = objAcc;
        item.RelRdwy = RBISDataValue.Blank;
        return item;
    }

    public static InstantiateVehRF(objVeh: Veh): VehRF {
        let item = {} as VehRF;
        item._TypeScript_TypeGuard_VehRF = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.VRF = RBISDataValue.Blank;
        return item;
    }

    public static InstantiateRoadwayRF(objVeh: Veh): RRF_MTSS {
        let item = {} as RRF_MTSS;
        item._TypeScript_TypeGuard_RRF_MTSS = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.RRF = RBISDataValue.Blank;
        item.IsSpecify = false;// setting to false so that IS NOT Undentified 
        return item;
    }

    public static InstantiateWeatherRF(objVeh: Veh): WRF_MTSS {
        let item = {} as WRF_MTSS;
        item._TypeScript_TypeGuard_WRF_MTSS = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.WRF = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static InstantiateOtherEF(objVeh: Veh): OEF_MTSS {
        let item = {} as OEF_MTSS;
        item._TypeScript_TypeGuard_OEF_MTSS = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.OEF = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static InstantiateTrafficFlowIF(objVeh: Veh): TFIF_MTSS {
        let item = {} as TFIF_MTSS;
        item._TypeScript_TypeGuard_TFIF_MTSS = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.TFIF = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }
    
    public static InstantiateVehicleCF(objVeh: Veh): VCF_MTSS {
        let item = {} as VCF_MTSS;
        item._TypeScript_TypeGuard_VCF_MTSS = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.VCF = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static InstantiateVeh_SSAvoidEquipAvail(objVeh: Veh): Veh_SSAvoidEquipAvail {
        let item = {} as Veh_SSAvoidEquipAvail;
        item._TypeScript_TypeGuard_Veh_SSAvoidEquipAvail = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.AvoidEquipAvail = RBISDataValue.Blank;
        return item;
    }

    public static InstantiateNonOccCondAtCrashTime(objNonOccupant: Non_Occupants): NonOcc_CondAtCrashTime {
        let item = {} as NonOcc_CondAtCrashTime;
        item._TypeScript_TypeGuard_NonOcc_CondAtCrashTime = null;
        item.AccID = objNonOccupant.AccID;
        item.PNumber = objNonOccupant.PNumber;
        item.Non_Occupants = objNonOccupant;
        item.ElementValue = RBISDataValue.Blank;
        return item;
    }

    public static InstantiateNonOccPrf(objNonOccupant: Non_Occupants): NonOccRF {
        let item = {} as NonOccRF;
        item._TypeScript_TypeGuard_NonOccRF = null;
        item.AccID = objNonOccupant.AccID;
        item.PNumber = objNonOccupant.PNumber;
        item.Non_Occupants = objNonOccupant;
        item.PRF = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static InstantiateNonOccActPriorCrash(objNonOccupant: Non_Occupants): NonOcc_ActPriorCrash {
        let item = {} as NonOcc_ActPriorCrash;
        item._TypeScript_TypeGuard_NonOcc_ActPriorCrash = null;
        item.AccID = objNonOccupant.AccID;
        item.PNumber = objNonOccupant.PNumber;
        item.ElementValue = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;

    }

    public static InstantiateNonOccActAtCrash(objNonOccupant: Non_Occupants): NonOcc_ActAtCrash {
        let item = {} as NonOcc_ActAtCrash;
        item._TypeScript_TypeGuard_NonOcc_ActAtCrash = null;
        item.AccID = objNonOccupant.AccID;
        item.PNumber = objNonOccupant.PNumber;
        item.Non_Occupants = objNonOccupant;
        item.ElementValue = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;

    }

    public static InstantiateNonOccBike(objNonOccupant: Non_Occupants): NonOcc_Bike {
        let item = {} as NonOcc_Bike;
        item._TypeScript_TypeGuard_NonOcc_Bike = null;
        item.AccID = objNonOccupant.AccID;
        item.PNumber = objNonOccupant.PNumber;
        item.Non_Occupants = objNonOccupant;
        item.SchoolZone = RBISDataValue.Blank;
        item.Crosswalk = RBISDataValue.Blank;
        item.Sidewalk = RBISDataValue.Blank;
        item.CrashType = RBISDataValue.Blank;
        item.CrashLocation = RBISDataValue.Blank;
        item.BikePosition = RBISDataValue.Blank;
        item.BikeDirection = RBISDataValue.Blank;
        item.CrashGroup = RBISDataValue.Blank;
        return item;
    }

    public static InstantiateNonOccDistract(objNonOccupant: Non_Occupants): NonOcc_Distract {
        let item = {} as NonOcc_Distract;
        item._TypeScript_TypeGuard_NonOcc_Distract = null;
        item.AccID = objNonOccupant.AccID;
        item.PNumber = objNonOccupant.PNumber;
        item.Non_Occupants = objNonOccupant;
        item.ElementValue = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;

    }

    public static InstantiateDriCondAtCrashTime(objdriver: Dri): DriCondAtCrashTime {
        let item = {} as DriCondAtCrashTime;
        item._TypeScript_TypeGuard_DriCondAtCrashTime = null;
        item.ACCID = objdriver.AccID;
        item.VNumber = objdriver.VNumber;
        item.Dri = objdriver;
        item.CondAtCrashTime = RBISDataValue.Blank;
        return item;

    }

    public static InstantiateDriIllness(objdriver: Dri): Dri_Illness {
        let item = {} as Dri_Illness;
        item._TypeScript_TypeGuard_Dri_Illness = null;
        item.ACCID = objdriver.AccID;
        item.VNumber = objdriver.VNumber;
        item.Dri = objdriver;
        item.Illness = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;

    }

    public static InstantiateDriViol(objdriver: Dri): DriViol {
        let item = {} as DriViol;
        item._TypeScript_TypeGuard_DriViol = null;
        item.AccID = objdriver.AccID;
        item.VNumber = objdriver.VNumber;
        item.Dri = objdriver;
        item.Viol = RBISDataValue.Blank;
        return item;

    }

    public static InstantiateDriRF(objdriver: Dri): DriRF {
        let item = {} as DriRF;
        item._TypeScript_TypeGuard_DriRF = null;
        item.AccID = objdriver.AccID;
        item.VNumber = objdriver.VNumber;
        item.Veh = objdriver.Veh;
        item.DRF = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;

    }

    public static InstantiateOccRF(objPerson: Occupants): OccRF {
        let item = {} as OccRF;
        item._TypeScript_TypeGuard_OccRF = null;
        item.AccID = objPerson.AccID;
        item.PNumber = objPerson.PNumber;
        item.VNumber = objPerson.VNumber;
        item.Occupants = objPerson;
        item.PRF = RBISDataValue.Blank;
        return item;

    }

    public static InstantiateOccBeltUsed(objPerson: Occupants): Occ_BeltUsed {
        let item = {} as Occ_BeltUsed;
        item._TypeScript_TypeGuard_Occ_BeltUsed = null;
        item.AccID = objPerson.AccID;
        item.PNumber = objPerson.PNumber;
        item.VNumber = objPerson.VNumber;
        item.Occupants = objPerson;
        item.BeltUsed = RBISDataValue.Blank;
        return item;

    }

    public static InstantiatePreCrashDriVisObs(objPreCrash: PreCrash): PreCrash_DriVisObs {
        let item = {} as PreCrash_DriVisObs;
        item._TypeScript_TypeGuard_PreCrash_DriVisObs = null;
        item.AccID = objPreCrash.AccID;
        item.VNumber = objPreCrash.VNumber;
        item.PreCrash = objPreCrash;
        item.ElementValue = RBISDataValue.Blank;
        return item;
    }

    public static InstantiatePreCrashDriAvoidManvr(objPreCrash: PreCrash): PreCrash_DriAvoidManvr {
        let item = {} as PreCrash_DriAvoidManvr;
        item._TypeScript_TypeGuard_PreCrash_DriAvoidManvr = null;
        item.AccID = objPreCrash.AccID;
        item.VNumber = objPreCrash.VNumber;
        item.PreCrash = objPreCrash;
        item.ElementValue = RBISDataValue.Blank;
        return item;
    }

    // We may not need this one -Moh
    //public static InstantiatePreCrashDriInattention(objVeh: Veh): PreCrash_DriInattention {
    //    let item = {} as PreCrash_DriInattention;
    //    item._TypeScript_TypeGuard_PreCrash_DriInattention = null;
    //    item.AccID = objVeh.AccID;
    //    item.VNumber = objVeh.VNumber;
    //    item.Veh = objVeh;
    //    item.ElementValue = RBISDataValue.Blank;
    //    return item;
    //}

    public static InstantiateDriInattention(objdriver: Dri): Dri_DriInattention {
        let item = {} as Dri_DriInattention;
        item._TypeScript_TypeGuard_Dri_DriInattention = null;
        item.ACCID = objdriver.AccID;
        item.VNumber = objdriver.VNumber;
        item.Veh = objdriver.Veh;
        item.ElementValue = RBISDataValue.Blank;
        return item;
    }

    public static InstantiateDriDistract(objdriver: Dri): Dri_DriDistract {
        let item = {} as Dri_DriDistract;
        item._TypeScript_TypeGuard_Dri_DriDistract = null;
        item.ACCID = objdriver.AccID;
        item.VNumber = objdriver.VNumber;
        item.Veh = objdriver.Veh;
        item.Dri_Distract = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static InstantiatePreCrashDriDistract(objPreCrash: PreCrash): PreCrash_DriDistract {
        let item = {} as PreCrash_DriDistract;
        item._TypeScript_TypeGuard_PreCrash_DriDistract = null;
        item.AccID = objPreCrash.AccID;
        item.VNumber = objPreCrash.VNumber;
        item.PreCrash = objPreCrash;
        item.ElementValue = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    //public static InstantiateDriInattention(objVeh: Veh): PreCrash_DriInattention {
    //    let item = {} as PreCrash_DriInattention;
    //    item._TypeScript_TypeGuard_PreCrash_DriInattention = null;
    //    item.AccID = objVeh.AccID;
    //    item.VNumber = objVeh.VNumber;
    //    item.Veh = objVeh;
    //    item.ElementValue = RBISDataValue.Blank;
    //    return item;
    //}


    //public static InstantiateDriDistract(objVeh: Veh): PreCrash_DriDistract {
    //    let item = {} as PreCrash_DriDistract;
    //    item._TypeScript_TypeGuard_PreCrash_DriDistract = null;
    //    item.AccID = objVeh.AccID;
    //    item.VNumber = objVeh.VNumber;
    //    item.Veh = objVeh;
    //    item.ElementValue = RBISDataValue.Blank;
    //    item.IsSpecify = false;
    //    return item;
    //}

    public static InstantiatePreCrashContribCirc(objPreCrash: PreCrash): PreCrash_ContribCirc {
        let item = {} as PreCrash_ContribCirc;
        item._TypeScript_TypeGuard_PreCrash_ContribCirc = null;
        item.AccID = objPreCrash.AccID;
        item.VNumber = objPreCrash.VNumber;
        item.PreCrash = objPreCrash;
        item.ElementValue = RBISDataValue.Blank;
        return item;
    }

    public static InstantiatePreFHEMnvr_SS(objVeh: Veh): PreFHEMnvr_SS {
        let item = {} as PreFHEMnvr_SS;
        item._TypeScript_TypeGuard_PreFHEMnvr_SS = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.PreFHarmEventManeuver = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static InstantiateLightingClr_SS(objVeh: Veh): LightingClr_SS {
        let item = {} as LightingClr_SS;
        item._TypeScript_TypeGuard_LightingClr_SS = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.LightingClr = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static InstantiateSuplTrfcCtrl_SS(objVeh: Veh): SuplTrfcCtrl_SS {
        let item = {} as SuplTrfcCtrl_SS;
        item._TypeScript_TypeGuard_SuplTrfcCtrl_SS = null;
        item.AccID = objVeh.AccID;
        item.VNumber = objVeh.VNumber;
        item.Veh = objVeh;
        item.SuplTrfcCtrl = RBISDataValue.Blank;
        item.IsSpecify = false;
        return item;
    }

    public static BindDriPrevSusToParent(objDri: Dri) {

        if (objDri.DriPrevSus) {

            if (!objDri.DriPrevSus[0]) {
                let item = {} as DriPrevSus;
                item.AccId = objDri.AccID;
                item.Dri = objDri;
                item.PrevSusRevoc = RBISDataValue.Blank;
                item.SeqNum = 1;
                item.VNumber = objDri.VNumber;
                item._TypeScript_TypeGuard_DriPrevSus = null;
                objDri.DriPrevSus.push(item);

            }
            if (!objDri.DriPrevSus[1]) {
                let item = {} as DriPrevSus;
                item.AccId = objDri.AccID;
                item.Dri = objDri;
                item.PrevSusRevoc = RBISDataValue.Blank;
                item.SeqNum = 2;
                item.VNumber = objDri.VNumber;
                item._TypeScript_TypeGuard_DriPrevSus = null;
                objDri.DriPrevSus.push(item);
            }
            if (!objDri.DriPrevSus[2]) {
                let item = {} as DriPrevSus;
                item.AccId = objDri.AccID;
                item.Dri = objDri;
                item.PrevSusRevoc = RBISDataValue.Blank;
                item.SeqNum = 3;
                item.VNumber = objDri.VNumber;
                item._TypeScript_TypeGuard_DriPrevSus = null;
                objDri.DriPrevSus.push(item);
            }

        }

    }

    public static BindNonOccupantSafetyToParent(objNonOcc: Non_Occupants) {

        if (objNonOcc.NonOcc_Safety) {

            if (!objNonOcc.NonOcc_Safety[0]) {
                let item = {} as NonOcc_Safety;
                item.AccID = objNonOcc.AccID;
                item.Non_Occupants = objNonOcc;
                item.ElementValue = RBISDataValue.Blank;
                item.SeqNum = 1;
                item.PNumber = objNonOcc.PNumber;
                item._TypeScript_TypeGuard_NonOcc_Safety = null;
                objNonOcc.NonOcc_Safety.push(item);

            }
            if (!objNonOcc.NonOcc_Safety[1]) {
                let item = {} as NonOcc_Safety;
                item.AccID = objNonOcc.AccID;
                item.Non_Occupants = objNonOcc;
                item.ElementValue = RBISDataValue.Blank;
                item.SeqNum = 2;
                item.PNumber = objNonOcc.PNumber;
                item._TypeScript_TypeGuard_NonOcc_Safety = null;
                objNonOcc.NonOcc_Safety.push(item);
            }
            if (!objNonOcc.NonOcc_Safety[2]) {
                let item = {} as NonOcc_Safety;
                item.AccID = objNonOcc.AccID;
                item.Non_Occupants = objNonOcc;
                item.ElementValue = RBISDataValue.Blank;
                item.SeqNum = 3;
                item.PNumber = objNonOcc.PNumber;
                item._TypeScript_TypeGuard_NonOcc_Safety = null;
                objNonOcc.NonOcc_Safety.push(item);
            }
            if (!objNonOcc.NonOcc_Safety[3]) {
                let item = {} as NonOcc_Safety;
                item.AccID = objNonOcc.AccID;
                item.Non_Occupants = objNonOcc;
                item.ElementValue = RBISDataValue.Blank;
                item.SeqNum = 4;
                item.PNumber = objNonOcc.PNumber;
                item._TypeScript_TypeGuard_NonOcc_Safety = null;
                objNonOcc.NonOcc_Safety.push(item);
            }
            if (!objNonOcc.NonOcc_Safety[4]) {
                let item = {} as NonOcc_Safety;
                item.AccID = objNonOcc.AccID;
                item.Non_Occupants = objNonOcc;
                item.ElementValue = RBISDataValue.Blank;
                item.SeqNum = 5;
                item.PNumber = objNonOcc.PNumber;
                item._TypeScript_TypeGuard_NonOcc_Safety = null;
                objNonOcc.NonOcc_Safety.push(item);
            }
            if (!objNonOcc.NonOcc_Safety[5]) {
                let item = {} as NonOcc_Safety;
                item.AccID = objNonOcc.AccID;
                item.Non_Occupants = objNonOcc;
                item.ElementValue = RBISDataValue.Blank;
                item.SeqNum = 6;
                item.PNumber = objNonOcc.PNumber;
                item._TypeScript_TypeGuard_NonOcc_Safety = null;
                objNonOcc.NonOcc_Safety.push(item);
            }
        }

    }


    ////DriRF is child of Veh and needs to be attached to Dri object to being saved.
    //public static AttachDriRFToDriObject(objVeh: Veh, objDri: Dri) {
    //    if (objVeh.DriRF) {
    //        objDri.DriRF = ObjectUtil.CloneModelObject(objVeh.DriRF);
    //        //objVeh.DriRF = null;
    //        objDri.DriRF.forEach(driRf => {
    //            if (driRf.hasOwnProperty('DriRF'))
    //                delete driRf['DriRF'];
    //            driRf.Veh = objVeh;
    //            //driRf.Dri = objDri;
    //        });

    //    }
    //}

    private static IsValueType(x: any): boolean {
        let strType: string = typeof (x);

        if (strType === 'number')
            return true;
        else if (strType === 'string')
            return true;
        else if (strType === 'boolean')
            return true;
        else if (x instanceof Date)
            return true;
        else
            return false;
    }

    //region date/time validations
    //KM: Data Validation for correct format and correct Month/Day and leap year checks
    public static usDate(sDate: string) {
        let ismatched: boolean;

        // let usDatePattern = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/; /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/
        let usDatePattern = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/;

        let match = sDate.match(usDatePattern);

        if (!match) {
            return { 'dateInvalid': true };
        }

        return null;
    }

    //KM: To check if Crash Date or Death Date are in the future
    public static blnIsDateTimeInTheFuture(year: number, month: number, day: number, hour: number, minute: number, intYear: number): boolean {
        if (hour == 88 || hour == 99) //JavaScript Date constructor will silently convert overflow to next biggest unit, ex: 88 hours is 3 days and 16 hours
            hour = 0;
        if (minute == 88 || minute == 99)
            minute = 0;

        //TODO: Why are we converting numbers to string only to parse as number again?
        //TODO: What is the difference between dateFuture and dateIsFutureTime (both include the time of day)
        let dateFuture = new Date(
            parseInt(year.toString()),
            parseInt(month.toString()) - 1,
            parseInt(day.toString()),
            hour.toString() ? parseInt(hour.toString()) : 0,
            minute.toString() ? parseInt(minute.toString()) : 0,
            0, 0);


        let dateIsFutureTime = new Date(
            parseInt(year.toString()),
            parseInt(month.toString()) - 1,
            parseInt(day.toString()),
            hour.toString() ? parseInt(hour.toString()) : 0,
            minute.toString() ? parseInt(minute.toString()) : 0,
            0, 0);

        //adjusting date to work with 2020 for testing purpose ONLY!
        //make todays date in the future +1 Year
        //let tDay = new Date();
        let tDay = new Date();
        tDay.setHours(0, 0, 0, 0);
        let tDayForTime = new Date();


        if (dateFuture >= tDay) {

            return dateIsFutureTime.getTime() >= tDayForTime.getTime() ? true : false;
        }
        else {
            return false;
        }
    }

    //#region: Kakuli M: Validations for Death Date and Time
    // Death Date must allow  88: Not Reported and 99: Unknown values in any combination of month/day/year
    public static dateTimeValidations(dYear: number, dMonth: number, dDay: number, dHour: number, dMinute: number, intYear: number, intMode: number = -1) {
        let error = "";
        let isFuture: boolean = false;
        let isValid: boolean = false;

        let sDate = dMonth.toString().padStart(2, '0') + "/" + dDay.toString().padStart(2, '0') + "/" + dYear.toString();
        let sTime = dHour.toString().padStart(2, '0') + "" + dMinute.toString().padStart(2, '0');

        if (!ObjectUtil.usDate(sDate))//Check for valid date eg: 11/30/2019 and not 11/31/2019
            isValid = true;

        if (isValid)
            isFuture = ObjectUtil.blnIsDateTimeInTheFuture(dYear, dMonth, dDay, dHour, dMinute, intYear);//Check if Death Date is in the future

        //Validate Date       
        if (isFuture)
            error = "Death Date cannot be in the future.";
        else if (sDate == "-1/-1/-1")
            error = "";
        else if ((dMonth > 0 && dDay < 0) || (dMonth < 0 && dDay > 0) || (dMonth > 0 && dDay > 0 && dYear < 0))
            error = "Please enter the complete Death Date.";
        else if (isValid && ((dMonth > 0 && dMonth <= 12) && ((dMonth != 88 && dMonth != 99) && (dDay < 0 && dDay > 31))))
            error = "Death Date must have valid values.";
        else if (isValid && ((dDay > 0 && dDay <= 31) && ((dDay != 88 && dDay != 99) && (dMonth < 0 && dMonth > 12))))
            error = "Death Date must have valid values.";
        else if (!sDate.includes("88") && !sDate.includes("99") && (!isValid))
            error = "Death Date must have valid values.";
        //Validate Time
        else if ((dHour >= 0 && dMinute < 0) || (dHour < 0 && dMinute > 0))
            error = "Death Time must have valid values.";
        else if (sTime == "8888" || sTime == "9999")
            error = "";
        else if ((dMinute > 59 && dMinute != 99))
            error = "Death Time must have valid values.";


        return error;
    }
    //#endregion

    //At runtime, model classes are all Objects. We can check types at compile time using instanceof, but runtime type checking is done via user-defined type guards.
    public static isAcc(objCandidate: any): objCandidate is Acc {
        if ('_TypeScript_TypeGuard_Acc' in objCandidate)
            return true;

        return false;
    }

    public static isVeh(objCandidate: any): objCandidate is Veh {
        if ('_TypeScript_TypeGuard_Veh' in objCandidate)
            return true;

        return false;
    }

    public static isOcc(objCandidate: any): objCandidate is Occupants {
        if ('_TypeScript_TypeGuard_Occupants' in objCandidate)
            return true;

        return false;
    }

    public static isNonOcc(objCandidate: any): objCandidate is Non_Occupants {
        if ('_TypeScript_TypeGuard_Non_Occupants' in objCandidate)
            return true;

        return false;
    }

    /**
     * Creates a clone of the data model from the given point down only.
     * WARNING: Will not clone upward link to parent object. Pass in the parent if you want a clone from the parent down.
     * WARNING: Do not give this method objects with more crosslinks than just simple bidirectional links, for example,
     *          DO NOT give it a PII object that is linked to both Acc and Non_Occupants.
     */
    public static CloneModelObject<T>(item: T, strRootName: string = null): any {
        if (item === null)
            return null;
        if(Array.isArray(item) && item.length == 0)
            return [];

        if (strRootName !=null && strRootName != 'EarlyNotify') {
            let objSanityCheck: EarlyNotify = ObjectUtil.FindRoot(item, 'EarlyNotify');

            if (objSanityCheck != null) {
                throw new Error('ObjectUtil.CloneModelObject(): Incorrect usage: if passing in full data model, rootName is EarlyNotify.');
            }
        }

        let objRoot: EarlyNotify = ObjectUtil.FindRoot(item, strRootName ? strRootName : 'EarlyNotify');

        if (!objRoot && !strRootName)
            throw new Error('ObjectUtil.CloneModelObject(): Incorrect usage: if passing in a model fragment, then specify the root of the fragment.');
        if (strRootName && !objRoot)
            throw new Error('ObjectUtil.CloneModelObject(): Incorrect usage: specified root is not found in passed in model fragment.');

        if (objRoot)
            ObjectUtil.RemoveBidirectionalLinks(objRoot); //Before the calls JSON.stringify(), must remove bidirectional links from object
        else
            ObjectUtil.RemoveBidirectionalLinks(item);

        let objClone: T = JSON.parse(JSON.stringify(item));

        if (objRoot)
            ObjectUtil.RestoreBidirectionalLinks(objRoot);
        else
            ObjectUtil.RestoreBidirectionalLinks(item);

        ObjectUtil.RestoreBidirectionalLinks(objClone);

        return objClone;
    }


    public static sortSelectedItem(strPropertyName: string) {
        let sortOrder = 1;
        if (strPropertyName[0] === "-") {
            sortOrder = -1;
            strPropertyName = strPropertyName.substr(1);
        }
        return function (a, b) {
            let intValue1 = Number(a[strPropertyName]);
            let intValue2 = Number(b[strPropertyName]);
            let result = (intValue1 < intValue2) ? -1 : (intValue1 > intValue2) ? 1 : 0;
            return result * sortOrder;
        }
    }
    /**
    * Checking if String has empty(white) spaces;
    * * Returing boolean true/false if value has empty space in provided string;
    */
    public static HasWhiteSpaces(strValue: string): boolean {
        let blnWhiteSpace: boolean = new RegExp(/\s/).test(strValue);
        return blnWhiteSpace;
    }
    /**
    * Cheking if VIN # has 17, 0's|7's|8's|9's;
    * Returing boolean true/false if value has all 0's|7's|8's or 9's;
    */
    public static VINHasLookupsValues(strValue: string): boolean {
        let noCheckVin: boolean = new RegExp("([0|7|8|9]){17}").test(strValue);
        return noCheckVin;
    }
    /**
   * Replacing white(empty) spaces with;
   * NOT SURE IF WE NEED TO USE THIS ONE;
   * Returing string with replaced empty spaces with Asterisks ("*");
   */
    public static StringRepalceEmptySpaceWithAsterisk(strValue: string): string {
        if (strValue) { 
        let reg = new RegExp(/\s+/g);
        let result = strValue.trimRight().replace(reg, "*");

            return result;
        }
    }

    /** 
    * Used for masking Dates by adding / between Month and Day and between Day and Year
    * Adding / after 2nd and 4th digint in Date;
    * Handle backspec condition when event.data is null
    */
    public static DateMask(event: any) {
        let v = event.target.value;
        if (event.data != null) {
            if (v) {
                if (v.match(/^\d{2}$/) !== null) {
                    event.target.value = v + '/';
                } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
                    event.target.value = v + '/';
                }
            }
        }
    }
}
