


import {
    Component, OnInit, Input,
    ViewChild, ElementRef,
    AfterViewInit, Renderer2, Output,
    EventEmitter, OnDestroy,
    SimpleChange, AfterContentChecked,

    ViewChildren,
    QueryList,
    ChangeDetectorRef
} from '@angular/core';

import { ControlContainer, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, merge, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { AutofillService } from 'src/app/services/autofill.service';
import { UtilService } from 'src/app/services/util.service';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { KeyCode, intRBISDataValue, PropertyType, UnitType, RecodeSpecialCodes, DBMode } from 'src/app/models/enums/app.enums';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { AttributeMatchLevel } from 'src/app/models/attr-match-level';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { OtherSpecifyComponent } from '../other-specify/other-specify.component';
import { usp_EDT_GetStylesByStateNum_Result } from 'src/app/models/usp_EDT_GetStylesByStateNum_Result';
import { DecimalPipe } from '@angular/common';
import { ReplaceTextPipe } from 'src/app/pipes/replaceText.pipe';
import { ModalService } from 'src/app/services/modal.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { EdtConfidenceColorComponent } from '../edt-confidence-color/edt-confidence-color.component';
import { LabelComponent } from '../label/label.component';

//Wraps the Angular Bootstrap (ngb) TypeAhead component (https://ng-bootstrap.github.io/#/components/typeahead/examples),
//not to be confused with this component, which we also call TypeAhead
@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.css'],
    providers: [DecimalPipe, ReplaceTextPipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TypeaheadComponent extends UIElementBase implements OnDestroy, OnInit, NgbTypeaheadSelectItemEvent, AfterViewInit, AfterContentChecked, ResultTemplateContext {
    result: any;
    term: string;
    /**
     * For unfiltered fields (i.e. non dependent fields), caches the original set of options
     **/
    public _arrOptionsPristine: DrpDownOptions[]; //In a release build, this property must be public as it is accessed from the HTML template.

    /**
     * When typing quickly, the handler for the first key press may already see the text of not yet processed key presses in event.target.value
     * and advance the focus to the next field such that when the keyboard key is released, it is seen by the UI element that focus moved to.
     * The way we deal with this is to delay focus advancement when keyboard event resulted in data model update and advancement to next field.
     * REMINDER: We cannot debounce keyboard events as we need to see all events - because some keyboard events need to be canceled.
     **/
    private _blnDelayedFocusNextScheduled: boolean = false;

    //Public "input" properties to pass data from parent pages to child TypeaheadComponent instances
    @Input() blnHideSelectedOptionText: boolean = false; //Useful for fields like Vehicle Model Year where the option numerical code is the same as the display text
    /**
     * Indicates whether only applicable special values like "Unknown", "Not Applicable", "Not Reported" should be displayed.
     * Used for fields like "Person Weight" that otherwise would show hundreds of consecutive numbers. The hidden values are
     * still used for range validation.
     **/
    @Input() blnShowSpecialValuesOnly: boolean = false;
    @Input() strRefreshedLookupTableName: string = "";
    /**
     * top, top-left, top-right, bottom, bottom-left, bottom-right, left, left-top, left-bottom, right, right-top, right-bottom
     **/
    @Input() typeAheadPosition: string = "right";
    @Input() typeAheadClass: string = "form-control w74";
    @Input() blnHideInputText: boolean = false;
    @Input() blnListenEmitter: boolean = false;
    /**
     * Focus on next control after a value is typed in
     **/
    @Input() blnAllowFocusNext: boolean = false;
    /**
     * Evaluates autofill rules before raising the bindModel (a.k.a. OnChange) event.
     * Useful when autofill rules update data model fields that OnChange logic is looking for. Ex: Created Records logic looking for "Shool Bus Chassis (Incomplete)" and Final Stage Body Class "School Bus"
     **/
    @Input() blnEvalAutofillBeforeOnChangeEvent: boolean = false;
    @Input() blnFormatUpperCase: boolean = false;
    @Input() blnFreeTextWithoutOption: boolean = false;
    /**
     * Flag that controls whether a selection is made automatically when typed-in value is as long as the maximum allowed length for values.
     * Used to force the user to Tab-select a typeahead dropdown option.
     **/
    @Input() blnAutoSelectOnMaxlength: boolean = true;
    /**
     * When maxlength has been reached, determines if the lookup of possible matching dropdown options is done by integer code or by
     * string text. Ex: Used by MilePoint to have "0000" and "0000.0" produce different behaviors.
     **/
    @Input() blnMatchDropDownOptionAsText: boolean = false;

    /**
     *Default is false. if an element has attribute type of decimal, control needs to be fed by passing true.(Ex : Trafficway form-> Latitude field(ddLatitude))
     */
    @Input() blnBindDecimalAttribute: boolean = false;

    @Input() blnNoRangeCheck: boolean = false;
    @Input() minRange: string = null;
    @Input() maxRange: string = null;

    //direct access the DOM elements to manupulate them
    //The selected option text, not to be confused with the field label (app-label component)
    @ViewChild('manualLink', { static: false }) manualLink: ElementRef;
    //@ViewChild('cfl') spanConfidence: ElementRef;
    @ViewChildren('otherSpecify') componentOtherSpecify: QueryList<OtherSpecifyComponent>;
    @ViewChild('otherSpecify', { static: false }) childOtherSpecify: OtherSpecifyComponent;
    @ViewChild('EDTConfidenceColor', { static: false }) edtConfidenceColor: EdtConfidenceColorComponent;
    @ViewChild('printTextArea', { static: false }) printTextArea: ElementRef;
    @ViewChild('currentLabel', { static: false }) currentLabel: LabelComponent;
    //to access the event object with the "$event" argument through the parent
    @Output() bindModel = new EventEmitter<DrpDownOptions>();
    /**
     * Emits the entire data model object when the object's property that this control is databound to is about modified.(Old Value)
     **/
    @Output() emitObjectOnChange = new EventEmitter<any>();

    /**
     * Emits the keyboard event of typeahead control
     **/
    @Output() evtListenKeyboard = new EventEmitter<KeyboardEvent>();

    //Property declaration
    item: any; //Inherited from NgbTypeaheadSelectItemEvent
    blnFocusOtherSpecify: boolean = true;

    selecteditem: DrpDownOptions;

    typeaheadSubscription: Subscription = new Subscription();

    arrStateAttributeLevel: AttributeMatchLevel[];
    strFieldParam: string;


    focusOtherSpecify$ = new Subject<string>();
    clickOtherSpecify$ = new Subject<string>();
    preventDefault: () => void;
    intYear = 2020;
    constructor(
        protected _autofillService: AutofillService,
        protected renderer: Renderer2,
        protected decimalPipe: DecimalPipe,
        protected replaceTextPipe: ReplaceTextPipe,
        protected _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _route: ActivatedRoute,
        private _sharedService: SharedDataService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _modalService: ModalService,
        private refMe: ElementRef

    ) {
        super(_autofillService, renderer, decimalPipe, replaceTextPipe, _utilService, _urlTreeHelper);
    }

    //Raised during initial data binding and on changes to component properties.
    ngOnChanges(arrChanges: SimpleChange[]) {

        for (let strPropName in arrChanges) {
            let objChange: SimpleChange = arrChanges[strPropName];

            if (strPropName == 'blnVisible')
                this.hideOrShowComponent(this.blnVisible);

            if (this.objModel &&
                this.objMetaData &&
                strPropName == 'objCurrentValue' &&
                objChange.previousValue != objChange.currentValue
            ) {
                //if (isNaN(this.intValue))
                //    this.intValue = RBISDataValue.Blank;
                this.objModel[this.objMetaData.FieldID] = this.objCurrentValue;
                AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, false, false);
            }



        }
    }

    //Format the Angular Bootstap (Ngb) Typeahead selected option
    public formatter(objSelectedOption: DrpDownOptions) {

        if (this.blnAllowFreeText)
            return objSelectedOption.strValue;
        if (objSelectedOption.intValue != intRBISDataValue.Blank)
            return objSelectedOption.strValue;
        else
            return '';
    };

    //#region Helper methods
    public setCurrentValue(strNewValue: string, blnUserInitiated: boolean = true): boolean {
        //DrpDownOptions.strValue is a formatted value that may have leading zeros, which need to be ignored by lookup logic.
        //We're assuming that DrpDownOptions.intValue is set to NULL when not applicable (as opposed to 0, which may be a valid value)

        //Nikola - added logic to check if it is number(NOT NaN) or string 
        let newIntValue = parseFloat(strNewValue); //this will return even if the string has letter after number, hence regex check next line.

        if (strNewValue == null) strNewValue = ''; //2/10/2020 added by khem in case of null value the match in regex was throwing the error.

        //let blnStrNewValueIsNumber: boolean = (isNumber(newIntValue) && !isNaN(newIntValue)) ? true : false;
        let blnStrNewValueIsNumber: boolean = false;
        if (strNewValue)
            blnStrNewValueIsNumber = (strNewValue.match('[a-zA-Z]') && AutofillService.GetDataType(this.objModel, this.strFieldName) == PropertyType.String) ? false : true;
        else
            blnStrNewValueIsNumber = AutofillService.GetDataType(this.objModel, this.strFieldName) != PropertyType.String;


        let arrOptions: DrpDownOptions[] = this.blnShowSpecialValuesOnly ? this._arrOptionsPristine : this.options;

        if (arrOptions) {
            let objSelection: DrpDownOptions;

            if (this.strDbDataType == PropertyType.String) {
                objSelection = arrOptions.find(v => v.strValue == strNewValue); //DrpDownOptions.strValue contains leading zeros
            }
            else if ((strNewValue && strNewValue.length >= this.maxlength)
                || (blnStrNewValueIsNumber))//Need to account for maxlength: for example, for a month field when the user types 1, we don't know if they intended to enter 01, 10, 11 or 12, we can't assume 01.//Or value is a number (passed in without leading zeros, making the maxlength check not applicable)
            {
                if (blnStrNewValueIsNumber && !this.blnMatchDropDownOptionAsText)
                    objSelection = arrOptions.find(v => v.intValue == newIntValue);
                else
                    objSelection = arrOptions.find(v => v.strValue == strNewValue);
            }

            if (objSelection) {
                this.blnEnableOtherSpecify = objSelection.isSpecify;

                if (this.blnEnableOtherSpecify)
                    this.blnFocusOtherSpecify = false;

                this.bindItemAndFocusNextElement(objSelection, false, false, false, blnUserInitiated);

                if (!blnStrNewValueIsNumber) { // in case of if the this isn't number then do not perform the entire task. //by khem 1/08/2020
                    this.objTextbox.nativeElement.value = objSelection.strValue.substr(0, this.maxlength); // setting the non text value in the field.
                    return true;
                }

                //TODO: Rework this logic: We can't assume that all Typeahead fields have a numeric ID
                if (isNaN(this.objModel[this.strFieldName]))
                    this.objModel[this.strFieldName] = intRBISDataValue.Blank;

                if (newIntValue == 0 && objSelection.strValue.length > this.maxlength && this.blnAllowFreeText) //Special case for MilePoint the canned placeholders are longer than maxlength, so need to trim
                    this.objTextbox.nativeElement.value = objSelection.strValue.substr(0, this.maxlength);

                return true;
            }
            else if (Number(strNewValue) == intRBISDataValue.Blank) {
                this.clearComponent();
                return true;
            }
            else if (this.blnAllowFreeText) { //For free text field, update model with every keystroke
                this.labelElem.nativeElement.innerText = null;
                this.SetModelValue(strNewValue);
                //    this.bindModel.emit({ intValue: parseFloat(strNewValue), strValue: strNewValue } as DrpDownOptions);
                return true;
            }
            else {
                console.log('Could not resolve value ' + strNewValue + ' to TypeaheadComponent selection option');

                if (newIntValue != intRBISDataValue.Blank) //We've already checked blnAllowFreeText
                    this.clearComponent(); //If filter operation removed the currently selected option, then clear the field.

                return false;
            }
        }
        else if (this.objModel.hasOwnProperty(this.strFieldName)) {
            console.log('TypeaheadComponent options not initialized: ' + this.id + ', updating data model instead');
            this.SetModelValue(strNewValue);
            return true;
        }

        //else
        return false;
    }

    /**
     * WARNING: DO NOT ADD ASYNC LOGIC THAT WILL MAKE INITIAL DATABINDING ASYNC: THIS FUNCTION IS ASYNC ONLY WHEN CLEARING OTHER SPECIFY, ALL OTHER CALLERS EXPECT SYNCHRONOUSO BEHAVIOR FROM THIS METHOD!
     * TODO: Remove async pathway from this function, we don't need to rebind the Other Specify value if caller of bindItemAndFocusNextElement() [i.e. selected() and keyup/down handler] does the confirm
     *       and omits calling bindItemAndFocusNextElement() when user does not confirm.
     * Applies drop-down option to data model and optionally advances focus to the next empty field.
     * @param focusNext Flag to indicate whether focus should be advanced to the next empty field.
     * @param blnDelayFocusNext When typing quickly, the handler for the first key press may already see the text of not yet processed key presses in event.target.value
     *                          and advance the focus to the next field such that when the keyboard key is released, it is seen by the UI element that focus moved to.
     *                          The way we deal with this is to delay focus advancement when keyboard event resulted in data model update and advancement to next field.
     **/
    public async bindItemAndFocusNextElement(objItem: DrpDownOptions, focusNext: boolean = true, blnDelayFocusNext: boolean = false, blnBypassLeadingZeroFormatting: boolean = false, blnUserInitiated: boolean = true): Promise<void> {
        let blnModelValueChanged: boolean = false;

        if (objItem) {
            let blnConfirm: boolean = true;

            if (this.blnEnableOtherSpecify) {
                if ((!objItem.isSpecify) || (this.objModel[this.strFieldName] != objItem.intValue)) {
                    blnConfirm = await this._modalService.dialogPromise("This action will clear the Other(specify) value", "Are you sure to delete the code?");

                    if (blnConfirm) {
                        if (!this.childOtherSpecify)
                            this._changeDetectorRef.detectChanges();

                        this.childOtherSpecify.DeleteOtherSpecify(this.objCurrentValue);
                    }
                    else //Retain Other Specify selection
                        objItem = this.options.find(i => i.intValue == this.objModel[this.strFieldName]);
                }
            }

            this.instance.writeValue(objItem);

            this.labelElem.nativeElement.innerText = objItem ? objItem.strText : "";
            this.blnEnableOtherSpecify = objItem.isSpecify;
            this.instance.dismissPopup();

            //let blnValueChanged: boolean = this.objModel && this.options.find(i => i.intValue == this.objModel[this.strFieldName])
            //    && this.options.find(i => i.intValue == this.objModel[this.strFieldName]).intValue != objItem.intValue;

            this.blnEnableOtherSpecify = objItem.isSpecify;

            if (objItem.isSpecify) {
                blnModelValueChanged = true;
                focusNext = false;
                let subinit: Subscription = this.componentOtherSpecify.changes
                    .subscribe(((querylist: QueryList<OtherSpecifyComponent>) => {
                        subinit.unsubscribe();
                        if (this.blnFocusOtherSpecify)
                            querylist.last.focusSpecifyText();
                    }).bind(this));
            }

            if (this.objModel[this.strFieldName] != (AutofillService.GetDataType(this.objModel, this.strFieldName) == PropertyType.String ? objItem.strValue : objItem.intValue)) {
                this.edtConfidenceColor.SetSelectedItemEDTColor(objItem, null, this.strFieldParam);
            }

            if (this.objModel && this.objModel.hasOwnProperty(this.strFieldName)) {
                if (this.blnBindDecimalAttribute)
                    this.objCurrentValue = parseFloat(objItem.strValue);
                else
                    this.objCurrentValue = AutofillService.GetDataType(this.objModel, this.strFieldName) == PropertyType.String ? objItem.strValue : objItem.intValue;

                if (this.selecteditem != objItem || this.objCurrentValue != this.objModel[this.strFieldName]) //In case of an autofill set action that triggers a cascade of filter operations, the data model may already be updated, but the filtering of options may still be cascading. In this case we still want to raise the bindModel event (ex. Make filters Model, filters Body)
                    blnModelValueChanged = true;

                this.objModel[this.strFieldName] = this.objCurrentValue;
            }

            if (this.strRefreshedLookupTableName) {
                let typeAhead: TypeaheadComponent = this._autofillService.arrControls.find(i => i.strDefsLookupTableName == this.strRefreshedLookupTableName) as TypeaheadComponent;

                if (typeAhead)
                    typeAhead.FilterFieldOptions();
            }

            this.selecteditem = objItem;
        }
        else if (this.blnAllowFreeText) {
            blnModelValueChanged = true;
            this.SetModelValue(this.objTextbox.nativeElement.value, blnBypassLeadingZeroFormatting);
            //this.edtConfidenceColor.SetSelectedItemEDTColor(null, this.objCurrentValue, strFieldParam);
            this.labelElem.nativeElement.innerText = null;
        }

        if (!this.blnListenEmitter)
            this.emitObjectOnChange.emit(this.objModel);

        if (blnModelValueChanged && !this.blnEvalAutofillBeforeOnChangeEvent)
            this.bindModel.emit(objItem); //Let page react to new value before autofill.

        AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, blnUserInitiated, blnUserInitiated, focusNext);

        if (blnModelValueChanged && this.blnEvalAutofillBeforeOnChangeEvent)
            this.bindModel.emit(objItem); //Let page react to new value after autofill.

        //Autofill rules may have enabled/disabled controls, which affects which control can receive next focus.
        //Autofill may have advanced next focus, so if current element is no longer the active element, don't try advancing focus from it.
        if (focusNext && document.activeElement == this.objTextbox.nativeElement) {
            if (!blnDelayFocusNext)
                this.focusNextInput(this.blnFocusOnNextEmptyControl);
            else if (!this._blnDelayedFocusNextScheduled) {
                this._blnDelayedFocusNextScheduled = true;

                setTimeout((() => {
                    this._blnDelayedFocusNextScheduled = false;

                    if (document.activeElement == this.objTextbox.nativeElement) //Recheck that focus has not already advanced. Alternatively, clear active element so that other logic does not advance the focus and use this.objTextbox.nativeElement instead of document.activeElement when timeout runs.
                        this.focusNextInput(this.blnFocusOnNextEmptyControl);
                }).bind(this), 1); //Delay until next digest
            }
        }
    }

    private setSelectionAndBindValue(): void {
        if (this.blnHideInputText) {
            this.labelElem.nativeElement.innerText = null;
            this.objTextbox.nativeElement.value = null;
            return;
        }

        if (this.blnListenEmitter)
            this.emitObjectOnChange.emit(this.objModel);

        if (this.objModel) { //Control may be databound late or not at all
            let objValue: any = this.objModel[this.strFieldName];

            if (objValue !== null && objValue !== undefined)
                this.setCurrentValue(objValue.toString(), false);
        }
    }

    private async findElementMetaData() {

        let arrMetadata: TableFieldElements[] = await this._utilService.GetMetadataPromise();

        if (this.strDefsLookupTableName && this.strDefsLookupTableName != '') {
            //DefsLookupTableName not always unique for every control and if there is more than one entry also filter with TableId and FieldId
            let metadata = arrMetadata.filter(x =>
                x.LookupTblID == this.strDefsLookupTableName);

            if (metadata && metadata.length > 1) {
                this.objMetaData = arrMetadata.find(x =>
                    x.TableID == this.strTableName &&
                    x.FieldID == this.strFieldName &&
                    x.LookupTblID == this.strDefsLookupTableName
                );
            }
            else {
                this.objMetaData = arrMetadata.find(x =>
                    x.LookupTblID == this.strDefsLookupTableName);
            }
        }
        else if (this.strTableName != '' && this.strFieldName != '' && this.intSeqNum) {
            this.objMetaData = arrMetadata.find(x =>
                x.TableID == this.strTableName &&
                x.FieldID == this.strFieldName &&
                x.SeqNum == this.intSeqNum);

            if (!this.objMetaData)//If no record found filtering by SeqNum find the metadata with TableId and FieldId parameters
                this.objMetaData = arrMetadata.find(x =>
                    x.TableID == this.strTableName &&
                    x.FieldID == this.strFieldName);
        }
        else if (this.strTableName != '' && this.strFieldName != '') {
            this.objMetaData = arrMetadata.find(x =>
                x.TableID == this.strTableName &&
                x.FieldID == this.strFieldName);
        }

        if (this.objMetaData) {
            this.blnNoRangeCheck = this.objMetaData.NoRangeCheck;
            this.minRange = this.objMetaData.MinRange;
            this.maxRange = this.objMetaData.MaxRange;
        }
    }

    public async bindFieldMetadata() {

        await this.findElementMetaData();

        let appSetting: AppSettings = await this._sharedService.GetAppSettings();
        this.intMode = appSetting ? appSetting.intMode : -1;

        if (this.objMetaData) {
            if (!this.strDefsLookupTableName || this.strDefsLookupTableName == '')
                this.strDefsLookupTableName = this.objMetaData.LookupTblID;
        }
        else if ((this.intMode & this.intDisabledMode) == this.intMode) {
            this.hideOrShowComponent(false);
        }
        else {
            this.hideOrShowComponent(false);
            //this.labelElem.nativeElement.innerText = " Warning!!!Metadata missing and control not initialize";
        }
    }

    /**
     * Disables or enables control
     * @param isDisable New value for disabled state of control.
     */
    public disableOrEnableComponent(isDisable: boolean): void {
        this.disabled = isDisable || UIElementBase.blnReadOnly;

        if (this.disabled) {
            if (this.instance.isPopupOpen())
                this.instance.dismissPopup();
            this.edtConfidenceColor.HideEDTConfidenceColor();
            this.renderer.setAttribute(this.objTextbox.nativeElement, 'disabled', 'disabled');
        }
        else {
            //"disabled" HTML5 attribute is presence-based, not value-based. Setting it to false does not actually remove it.
            //"disabled" HTML5 attribute should not be conused with the shadow-DOM "disabled" attribute, which can be set to false.
            this.renderer.removeAttribute(this.objTextbox.nativeElement, 'disabled');
        }
    }
    //#end region

    //#region Angular LifeCycle Hooks
    //OnInit is called after Angular has initialized all data-bound properties of a directive
    public async ngOnInit(): Promise<void> {
        await super.ngOnInit();

        if (this.blnFormatListOnlyShowText) {
            this.instance.inputFormatter = (x: DrpDownOptions) => x.strText;
            this.options.sort((x, y) => (x.strText > y.strText) ? 1 : -1);
        }

        if (this.objModel && this.objModel[this.strFieldName] !== undefined && this.objModel[this.strFieldName] !== null)
            this.SetModelValue(this.objModel[this.strFieldName].toString()); //If a number, format with leading zeros

        //getting the current data year
        const appSetting = await this._sharedService.GetAppSettings();
        this.intYear = appSetting.intYear;
    }

    private GetEDTFieldParam(): void {
        let strFieldParam: string = this.objMetaData?.SeqNum == 0 ? this.strFieldName : this.objMetaData?.Field;
        if (this.strComplexFieldName != '')
            strFieldParam = this.strComplexFieldName;
        if (!this.edtConfidenceColor.arrOptions)
            this.edtConfidenceColor.arrOptions = this._arrOptionsPristine;
        if (!this.edtConfidenceColor.selectedValue)
            this.edtConfidenceColor.selectedValue = this.objCurrentValue;
        this.strFieldParam = strFieldParam;
    }

    public BindElementEDTColor(intStateNum: number, arrStateStyles: usp_EDT_GetStylesByStateNum_Result[] = null, arrAttrMatchLevel: AttributeMatchLevel[]) {
        if (this.printOnly || !this.objModel)
            return;
        this.GetEDTFieldParam();
        this.edtConfidenceColor.BindTypeAheadElementEDTColor(intStateNum, this.strFieldParam, arrStateStyles, arrAttrMatchLevel);
    }

    /**
     * When the range of values for a field is driven by another field.
     * If this is not a dependent field, then just [re]sets this.options without any filtering
     * Although this function returns a promise, it is not async: it creates a promise, but has no awaits in it's own body
     */
    public FilterFieldOptions(): Promise<DrpDownOptions[]> {
        return new Promise<DrpDownOptions[]>(((resolve, reject) => {
            try {
                //Fields with options filtered by a dynamic WHERE filter condition driven by another field
                if ((this.strDependentFieldName && this.strFilterCondition) || !this.blnEvaluateConditionValue) { //&& this.strFilterCondition != null && this.strFilterCondition != ''
                    let conditionValue = this.objModel[this.strDependentFieldName];

                    if ((conditionValue !== null && conditionValue !== undefined && conditionValue !== '')
                        || (!this.blnEvaluateConditionValue)) { //0 is a valid field value (ex. Area Of Impact), so we don't want to bypass filtering for 0
                        // TODO: We will work on Dependent Field to so we don't have to accomondate spefecis HTML template and reusuable based component. (Crash Events) 
                        if (this.strDependentFieldName == 'AccID' || !this.blnEvaluateConditionValue) conditionValue = ''; // this because concatenate like after the strFilterCondition Like in Crashevents 'AND ID BETWEEN 1 AND 2' after this was adding accId which results different

                        let strCondition: string = '';

                        //Moh- For MOSS added the following logic for reverse filter on Precrash tab Crash Category, Crash Configuration and Crash Type                        
                        if (this.blnMossReverseFilter) {
                            strCondition = this.strFilterCondition;

                            if (!Number(conditionValue))
                                conditionValue = conditionValue.replace(/^\s+|\s+$/g, ""); // Remove leading and trailing white space

                            if (!Number(this.strFilterCondition))
                                this.strFilterCondition = this.strFilterCondition.replace(/^\s+|\s+$/g, ""); // Remove leading and trailing white space

                            if (strCondition.indexOf("#") > 0) //on DEV_MTSPC branch some place still use # character
                                strCondition = strCondition.replace("#" + this.strDependentFieldName, conditionValue);

                            if (conditionValue == '' || conditionValue == UnitType.Blank)
                                if ((this.strFilterCondition.slice(this.strFilterCondition.lastIndexOf('=') + 1)).trim() === '') {
                                    strCondition = ''
                                }
                                else {
                                    strCondition = strCondition != '' ? strCondition : '';
                                }
                            else {
                                if ((this.strFilterCondition != '') &&
                                    (this.strFilterCondition.slice(this.strFilterCondition.lastIndexOf('=') + 1)).trim() === '') { // Filter did not included condition value yet
                                    strCondition = this.strFilterCondition + conditionValue;
                                }
                            }
                        }
                        else {
                            strCondition = this.strFilterCondition + conditionValue;
                        }

                        if (this.objMetaData) {
                            this._utilService.GetDrpDownListOptions(this.objMetaData.LookupTblID, strCondition)
                                .subscribe(((dataset: DrpDownOptions[]) => {
                                    try {
                                        let blnBootstrapTypeaheadPopupOpen: boolean = this.instance.isPopupOpen();

                                        if (blnBootstrapTypeaheadPopupOpen) //If popup is already open, it will not reflect updated selection options until closed and reopened.
                                            this.instance.dismissPopup();

                                        this.options = dataset;

                                        this.ManullyAddTRX();

                                        if (this.options && this.options.length > 0) {
                                            let objLongestOption: DrpDownOptions = this.options.reduce(function (a, b) { return a.strValue.length > b.strValue.length ? a : b; });

                                            if (objLongestOption)
                                                //Remove decimal point character if exist to calculate max length of whole numeric portion. 
                                                this.intMaxLengthCode = this.blnRemoveDecimalFraction
                                                    ? objLongestOption.strValue.split('.')[0].length
                                                    : objLongestOption.strValue.replace('.', '').length;
                                        }
                                        else {
                                            this.intMaxLengthCode = this.maxlength;
                                        }

                                        this.setMaxLengthOfCode(); //Falls back on value in this.intMaxLengthCode
                                        this.setSelectionAndBindValue();

                                        if (!this.blnFreeTextWithoutOption)
                                            this.InsertBlankToOptions();
                                        else
                                            this.options = [];

                                        if (blnBootstrapTypeaheadPopupOpen || document.activeElement == this.objTextbox.nativeElement)
                                            this.objTextbox.nativeElement.click();

                                        resolve(this.options);
                                    }
                                    catch (ex) {
                                        reject(ex);
                                    }
                                }).bind(this));
                        }
                    }
                    else { //Parent field that drives filtering of this field is blank
                        this.options = [];
                        resolve(this.options);
                    }
                }
                else if ((this.objMetaData) && (this.objMetaData.ExcludeLookUpTblId != true && !this.strDependentFieldName)) {
                    if (this._arrOptionsPristine) { //Avoids async flow of control through BehaviorSubject.subscribe()
                        let blnBootstrapTypeaheadPopupOpen: boolean = this.instance.isPopupOpen();

                        if (blnBootstrapTypeaheadPopupOpen) //If popup is already open, it will not reflect updated selection options until closed and reopened.
                            this.instance.dismissPopup();

                        this.options = JSON.parse(JSON.stringify(this._arrOptionsPristine));

                        if (this.options && this.options.length > 0) {
                            let objLongestOption: DrpDownOptions = this.options.reduce(function (a, b) { return a.strValue.length > b.strValue.length ? a : b; });

                            if (objLongestOption)
                                //Remove decimal point character if exist to calculate max length of whole numeric portion. 
                                this.intMaxLengthCode = this.blnRemoveDecimalFraction
                                    ? objLongestOption.strValue.split('.')[0].length
                                    : objLongestOption.strValue.replace('.', '').length;
                        }
                        else {
                            this.searchAll = false;
                            this.intMaxLengthCode = this.maxlength;
                        }

                        this.setMaxLengthOfCode(); //Falls back on value in this.intMaxLengthCode
                        this.setSelectionAndBindValue();

                        if (!this.blnFreeTextWithoutOption)
                            this.InsertBlankToOptions();
                        else
                            this.options = [];

                        if (blnBootstrapTypeaheadPopupOpen)
                            this.objTextbox.nativeElement.click();

                        resolve(this.options);
                    }
                    else {
                        this._utilService.formDrpDownOptionShare.subscribe(((arrOptionsAllFields: DrpDownOptions[]) => {
                            try {
                                if (!arrOptionsAllFields)
                                    console.error('TypeaheadComponent.FilterFieldOptions(): Race condition: initialization was begun while dropdown options are not retrieved yet!');

                                let blnBootstrapTypeaheadPopupOpen: boolean = this.instance.isPopupOpen();

                                if (blnBootstrapTypeaheadPopupOpen) //If popup is already open, it will not reflect updated selection options until closed and reopened.
                                    this.instance.dismissPopup();

                                this.options = arrOptionsAllFields.filter(i => i.tblName == this.strDefsLookupTableName);
                                this.ManullyAddTRX();
                                this._arrOptionsPristine = JSON.parse(JSON.stringify(this.options));

                                //Check if NotRepoValue has multiple value - MOSS                               
                                let NotRepoValueArray = new Array();
                                if (this.objMetaData.NotRepoValue != null)
                                    NotRepoValueArray = this.objMetaData.NotRepoValue.split(',')//.map(Number);                             

                                // regarding th TRX not applying the mode condition because that already filter by the mode so we don't need that.
                                if (this.blnShowSpecialValuesOnly)
                                    this.options = this.options.filter(i =>
                                        //i.strValue == this.objMetaData.NotRepoValue ||
                                        NotRepoValueArray.includes(i.strValue) ||
                                        i.strValue == this.objMetaData.NotApplicable ||
                                        i.strValue == this.objMetaData.UnknownValue ||
                                        i.intValue == RecodeSpecialCodes.T ||
                                        i.intValue == RecodeSpecialCodes.R ||
                                        i.intValue == RecodeSpecialCodes.X
                                    );

                                if (this.options && this.options.length > 0) {
                                    let objLongestOption: DrpDownOptions = this.options.reduce(function (a, b) { return a.strValue.length > b.strValue.length ? a : b; });

                                    if (objLongestOption)
                                        //Remove decimal point character if exist to calculate max length of whole numeric portion. 
                                        this.intMaxLengthCode = this.blnRemoveDecimalFraction
                                            ? objLongestOption.strValue.split('.')[0].length
                                            : objLongestOption.strValue.replace('.', '').length;
                                }
                                else {
                                    this.searchAll = false;
                                    this.intMaxLengthCode = this.maxlength;
                                }

                                this.setMaxLengthOfCode(); //Falls back on value in this.intMaxLengthCode
                                this.setSelectionAndBindValue();

                                if (!this.blnFreeTextWithoutOption)
                                    this.InsertBlankToOptions();
                                else
                                    this.options = [];

                                if (blnBootstrapTypeaheadPopupOpen)
                                    this.objTextbox.nativeElement.click();

                                resolve(this.options);
                            }
                            catch (ex) {
                                reject(ex);
                            }
                        }).bind(this));
                    }
                }
                else { //Fields with options filtered by a constant WHERE filter condition, or a WHERE filter condition set programmatically at runtime (results should NOT be cached)
                    this._utilService.GetDrpDownListOptions(this.strDefsLookupTableName, this.strFilterCondition)
                        .subscribe(((dataset: DrpDownOptions[]) => {
                            try {
                                let blnBootstrapTypeaheadPopupOpen: boolean = this.instance.isPopupOpen();

                                if (blnBootstrapTypeaheadPopupOpen) //If popup is already open, it will not reflect updated selection options until closed and reopened.
                                    this.instance.dismissPopup();

                                this.options = dataset;
                                this.ManullyAddTRX();
                                this._arrOptionsPristine = JSON.parse(JSON.stringify(this.options));

                                if (this.blnShowSpecialValuesOnly)
                                    this.options = this.options.filter(i =>
                                        i.strValue == this.objMetaData.NotRepoValue ||
                                        i.strValue == this.objMetaData.NotApplicable ||
                                        i.strValue == this.objMetaData.UnknownValue ||
                                        i.intValue == RecodeSpecialCodes.T ||
                                        i.intValue == RecodeSpecialCodes.R ||
                                        i.intValue == RecodeSpecialCodes.X);

                                if (this.options && this.options.length > 0) {
                                    let objLongestOption: DrpDownOptions = this.options.reduce(function (a, b) { return a.strValue.length > b.strValue.length ? a : b; });

                                    if (objLongestOption)
                                        //Remove decimal point character if exist to calculate max length of whole numeric portion. 
                                        this.intMaxLengthCode = this.blnRemoveDecimalFraction
                                            ? objLongestOption.strValue.split('.')[0].length
                                            : objLongestOption.strValue.replace('.', '').length;
                                }
                                else {
                                    this.searchAll = false;
                                    this.intMaxLengthCode = this.maxlength;
                                }

                                this.setMaxLengthOfCode(); //Falls back on value in this.intMaxLengthCode
                                this.setSelectionAndBindValue();

                                if (!this.blnFreeTextWithoutOption)
                                    this.InsertBlankToOptions();
                                else
                                    this.options = [];

                                if (blnBootstrapTypeaheadPopupOpen)
                                    this.objTextbox.nativeElement.click();

                                resolve(this.options);
                            }
                            catch (ex) {
                                reject(ex);
                            }
                        }).bind(this));
                }

            }
            catch (ex) {
                reject(ex);
            }
        }).bind(this));
    }


    //saved freetext values only show when blnAllowFreeText is true
    //Betul : We dont need this function, it is already handled inside the method Line#218 : setSelectionAndBindValue 
    public HandleFreeTextValue(): void {
        if (this.blnAllowFreeText && this.objCurrentValue != -1 && this.objCurrentValue != null && this.options !== undefined) {
            let freeTextMatch = this.options.find(o => o.intValue == this.objCurrentValue);

            if (!freeTextMatch) {
                let newOption: DrpDownOptions = {
                    intValue: this.objCurrentValue,
                    strValue: this.objCurrentValue.toString(),
                    strText: '',
                    displayText: '',
                    isSpecify: false,
                    tblName: '',
                    specifyValue: '',
                    EnTypeGrpID: -1,
                    SortOrder: -1,
                    AllowOneMultiple: 0,
                    FormName: '',
                    ENABLE_EDT: false,
                    ENABLE_CRSS_EDT: false,
                    HARMFUL: false,
                    PSUCODE: '',
                    PSUStateAbbr: '',
                    VehType: '',
                    ISEQGRPID: null,
                    VehOthCollisionFlag: false,
                    CrashTypeCat: -1,
                    CrashTypeConf: '',
                } as DrpDownOptions;
                this.options.push(newOption);
                //this.objTextbox.nativeElement.value = this.intValue
                //this.blnHideSelectedOptionText = true;
            }
        }
    }

    //AfterViewInit is called after Angular has fully initialized this component's view (but not necessarily the parent component's view).
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngAfterContentChecked(): void {
        if (this.printTextArea) {
            let el = this.printTextArea.nativeElement;
            this.printTextArea.nativeElement.style.resize = 'none';
            this.printTextArea.nativeElement.style.overflow = 'hidden';
            this.printTextArea.nativeElement.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight) + "px" : "30px";

            if (!this.objMetaData && this.printOnly) {
                // this logic must be have to be without any dependents fields but for many fields get undefined so it was removing many fields.
                // these fields are removing in printing because no longer needed for from 2022
                if (this.strFieldName == 'MethAlc' || this.strFieldName == 'MethDrug' && this.intYear > 2021) {
                    this.refMe.nativeElement.parentElement.parentElement.remove(); // this will remove the div designated for print only and others div will arrang in the place of this

                }
            }
        }
    }

    //OnDestroy called when a directive, pipe, or service is destroyed, for a visual component this is when navigation away from the component has occurred. Returning to a previous URL will create a new instance of the visual component.
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
    //#end region

    //#region Event Handlers
    handleKeydown(event: KeyboardEvent) {
        super.handleKeydown(event);

        if (event.key == KeyCode.Shift || event.key == KeyCode.ShiftLeft) {
            if (this.instance.isPopupOpen())
                this.instance.dismissPopup(); //Dismiss ngbTypeahead pop-up in preparation for Shift-Tab sequence
        }
    }

    public async handleKeyup(event: KeyboardEvent) {
        try {

            super.handleKeyup(event);

            if (event.key == KeyCode.PageUp || event.key == KeyCode.PageDown) {// we should be aware that any keys tat causes navigation thay can realise before navigation is finished and we do not want a control interpret that keyup has a data entre action; 
                return;
            }

            if (event.defaultPrevented)
                return;

            this.setIsInvalidClass(false);
            this.blnFocusOtherSpecify = true;

            let term: string = (<any>event.target).value;

            if (this.blnFormatListOnlyShowText) {
                let objItem: DrpDownOptions = this.options.find(v => v.strText.toLowerCase() == term.toLowerCase());

                if (objItem) {
                    this.SetModelValue(objItem.intValue.toString());
                    this.focusNextInput();
                    return;
                }
            }

            let blnSkipAction: boolean = event.code != KeyCode.Tab
                && event.code != KeyCode.Shift
                && event.code != KeyCode.ShiftLeft
                && event.code != KeyCode.ArrowLeft
                && event.code != KeyCode.ArrowRight;

            let arrOptions: DrpDownOptions[] = this.blnShowSpecialValuesOnly ? this._arrOptionsPristine : this.options;

            let hasNotApplicable: boolean = this.objMetaData && this.objMetaData.NotApplicable != null && this.objMetaData.NotApplicable != "";
            let hasNotRepoValue: boolean = this.objMetaData && this.objMetaData.NotRepoValue != null && this.objMetaData.NotRepoValue != "";
            let hasUnknownValue: boolean = this.objMetaData && this.objMetaData.UnknownValue != null && this.objMetaData.UnknownValue != "";

            //Bind the NotApplicable value from metadata field.
            if (event.code == KeyCode.NumpadDivide || event.code == KeyCode.Slash) {

                if (hasNotApplicable) {

                    let objItem: DrpDownOptions = this.options.find(v => v.strValue == this.objMetaData.NotApplicable);
                    if (objItem) {
                        this.bindItemAndFocusNextElement(objItem);
                    }
                }

            }
            //Bind the NotReported value from metadata field.
            else if (event.code == KeyCode.NumpadAdd) {
                if (hasNotRepoValue) {
                    let objItem: DrpDownOptions = this.options.find(v => v.strValue == this.objMetaData.NotRepoValue);

                    if (objItem) {
                        this.bindItemAndFocusNextElement(objItem);
                    }
                }

            }
            //Bind the Unknown value from metadata field.
            else if (event.code == KeyCode.NumpadMultiply && term.length == 1) {
                if (hasUnknownValue) {
                    let objItem: DrpDownOptions = this.options.find(v => v.strValue == this.objMetaData.UnknownValue);

                    if (objItem) {
                        this.bindItemAndFocusNextElement(objItem);
                    }
                }

            }
            else if (event.code == KeyCode.Backspace || event.code == KeyCode.Delete || term == intRBISDataValue.Blank.toString() || term == '') {
                if (term.length == 0) {
                    let blnConfirm: boolean = true;
                    let objItem: DrpDownOptions;

                    if (this.blnEnableOtherSpecify) {
                        blnConfirm = await this._modalService.dialogPromise("This action will clear the Other(specify) value", "Are you sure to delete the code?");

                        if (blnConfirm) {
                            if (!this.childOtherSpecify)
                                this._changeDetectorRef.detectChanges();

                            this.childOtherSpecify.DeleteOtherSpecify(this.objCurrentValue);
                        }
                    }

                    if (!blnConfirm) {
                        objItem = await this.options.find(i => i.intValue == this.objModel[this.strFieldName]);
                        this.instance.writeValue(objItem);
                    }
                    else {
                        this.blnEnableOtherSpecify = false;
                        let item = ObjectUtil.InstantiateBlankDrpDownOption();

                        if (this.blnListenEmitter)
                            this.emitObjectOnChange.emit(this.objModel);

                        let uiControl: TypeaheadComponent = AutofillService.arrControls.find(i => i.strDependentFieldName == this.strFieldName) as TypeaheadComponent;

                        if (uiControl) {
                            uiControl.options = [];
                            uiControl.bindItemAndFocusNextElement(item, false);
                        }

                        this.clearComponent();
                        console.log('Emit trigger in handleKeyup');
                        this.bindModel.emit(item);

                        if (!this.blnListenEmitter)
                            this.emitObjectOnChange.emit(this.objModel);

                        AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, true, true, false);
                        this.labelElem.nativeElement.innerText = "";
                    }
                }
            }
            //TODO: The following branch is entered for numeric fields, even if the pressed key was not a number (i.e. when user is filtering options by text)
            //      Rework this so that numeric fields and text fields are handled separately. As it is now, when a text field receives a digit (which is pefectly legal) it goes into this branch, while a letter takes flow of control to a lower branch for that same text field
            else if (event.code.match(KeyCode.Numpad) || event.code.match(KeyCode.Digit) || //As an alterntive to string.match() we can use string.startsWith() to match 'NumpadX' or 'DigitX'
                (this.strDbDataType != PropertyType.String && blnSkipAction)
            ) {
                let objItem: DrpDownOptions = this.blnShowSpecialValuesOnly ? this._arrOptionsPristine.find(v => v.strValue == term) : this.options.find(v => v.strValue == term);

                let items: DrpDownOptions[] = this.searchAll
                    ? arrOptions.filter(v => v.displayText.toLowerCase().indexOf(term.toLowerCase()) > -1)
                    : arrOptions.filter(v => v.strValue == term);

                if (this.strDbDataType != PropertyType.String) {
                    let intTermLength: number = this.strDbDataType == PropertyType.Number ? term.replace(/[.]/, '').length : term.length;

                    if (this.blnAutoSelectOnMaxlength &&
                        (intTermLength == this.maxlength ||
                            (intTermLength == this.intMaxLengthCode && this.strDbDataType == PropertyType.Number))) {

                        if (this.blnAllowFreeText) {
                            this.objTextbox.nativeElement.maxLength = this.intMaxLengthCode + (this.intMaxDecimal > 0 ? 1 : 0); //Re-eval max length: if UIElementBase.searchAll is true, maxlength may have been increased to allow text search

                            if (intTermLength == this.intMaxLengthCode && this.strDbDataType == PropertyType.Number && term.match('^[0-9.]+$')) {
                                this.setCurrentValue(term);
                                this.focusNextInput(this.blnFocusOnNextEmptyControl);
                            }
                            else if (items.length == 0) {
                                this.setIsInvalidClass(true);
                                //this.setCurrentValue(term);
                                //this.focusNextInput(this.blnFocusOnNextEmptyControl);
                            }

                            this.bindModel.emit(null);
                            this.edtConfidenceColor.SetSelectedItemEDTColor(null, this.objCurrentValue, this.strFieldParam);
                        }
                        else if (objItem) {
                            if (this.blnListenEmitter)
                                this.emitObjectOnChange.emit(this.objModel);
                            //When typing quickly, the handler for the first key press may already see the text of not yet processed key presses in event.target.value
                            //and advance the focus to the next field such that when the keyboard key is released, it is seen by the UI element that focus moved to.
                            //The way we deal with this is to delay focus advancement when keyboard event resulted in data model update and advancement to next field.
                            this.bindItemAndFocusNextElement(objItem, true, true);
                        }

                        else if (term.match('^[0-9.]+$') && items.length == 0) {
                            if (this.blnEnableOtherSpecify)
                                this.childOtherSpecify.DeleteOtherSpecify(this.objCurrentValue);
                            this.blnEnableOtherSpecify = false;

                            this.clearComponent();
                            this.setIsInvalidClass(true);

                        }
                    }
                    else if (!this.blnAllowFreeText && items.length == 0) {
                        this.labelElem.nativeElement.innerText = null;
                        this.setIsInvalidClass(true);

                        if (this.blnEnableOtherSpecify)
                            this.childOtherSpecify.DeleteOtherSpecify(this.objCurrentValue);

                        this.blnEnableOtherSpecify = false;
                    }
                    else if (this.blnAllowFreeText && intTermLength > this.maxlength) {
                        if (items.length == 0) {
                            //this.clearComponent();
                            this.setIsInvalidClass(true);
                        }
                    }
                    else if (intTermLength > this.maxlength) {
                        this.clearComponent();
                        this.setIsInvalidClass(true);

                        if (this.blnEnableOtherSpecify)
                            this.childOtherSpecify.DeleteOtherSpecify(this.objCurrentValue);

                        this.blnEnableOtherSpecify = false;
                    }
                }
                else if (this.blnAllowFreeText && this.strDbDataType == PropertyType.String) {
                    this.objTextbox.nativeElement.maxLength = this.intMaxLengthCode + (this.intMaxDecimal > 0 ? 1 : 0); //Re-eval max length: if UIElementBase.searchAll is true, maxlength may have been increased to allow text search

                    if (this.options.find(i => i.displayText.toLowerCase().includes(term.toLowerCase())) == null)
                        this.setCurrentValue(term);

                    if (this.blnAutoSelectOnMaxlength && (term.length == this.maxlength || term.length == this.intMaxLengthCode)) {
                        this.focusNextInput(true);
                    }

                    if (term.length > this.maxlength) {
                        this.setIsInvalidClass(true);
                    }

                    this.edtConfidenceColor.SetSelectedItemEDTColor(null, this.objCurrentValue, this.strFieldParam);
                    this.bindModel.emit(null);
                }
                else { //this.strDbDataType == PropertyType.String
                    //if (this.blnFormatUpperCase) // Moved logic for blnFormatUpperCase at the begging of handleKeyup(event: KeyboardEvent)  so it will trigger at any key Keyup;
                    //    this.objTextbox.nativeElement.value = term.toUpperCase();

                    this.objTextbox.nativeElement.maxLength = this.intMaxLengthCode + (this.intMaxDecimal > 0 ? 1 : 0); //Re-eval max length: if UIElementBase.searchAll is true, maxlength may have been increased to allow text search

                    if (term.length < this.maxlength && this.blnAllowFreeText) {
                        this.bindItemAndFocusNextElement(null, false, false, true);
                    }
                    else if (term.length == this.maxlength && this.blnAutoSelectOnMaxlength) {
                        if (objItem) {
                            this.bindItemAndFocusNextElement(objItem);
                        }
                        else {
                            this.bindItemAndFocusNextElement(null, this.blnAllowFocusNext);
                        }
                    }
                    else if (term.length > this.maxlength) {
                        this.setIsInvalidClass(true);
                    }
                }
            }
            else if (event.code == KeyCode.ArrowDown || event.code == KeyCode.ArrowUp ||
                event.code == KeyCode.ArrowLeft || event.code == KeyCode.ArrowRight) {
                //Arrow up/down are used to select options. Arrow left/right are used to move the text insertion point or (with Shift) to select text
                this.setIsInvalidClass(false);
            }
            else if (!this.blnAllowFreeText && //Check that typed, non-numeric value is a filter that at least partially matches a Typeahead option
                event.key != KeyCode.Shift && event.code != KeyCode.Tab) {
                this.setIsInvalidClass(false);
                if (arrOptions) {
                    let items: DrpDownOptions[] = this.searchAll
                        ? arrOptions.filter(v => v.displayText.toLowerCase().indexOf(term.toLowerCase()) > -1)
                        : arrOptions.filter(v => v.strValue == term);
                    if (items.length == 0 && term != "") {
                        //this.clearComponent();
                        this.labelElem.nativeElement.innerText = null;
                        this.setIsInvalidClass(true);
                    }
                }
            }
            else if (this.blnAllowFreeText && this.strDbDataType == PropertyType.String && blnSkipAction) {
                this.objTextbox.nativeElement.maxLength = this.intMaxLengthCode + (this.intMaxDecimal > 0 ? 1 : 0); //Re-eval max length: if UIElementBase.searchAll is true, maxlength may have been increased to allow text search

                if (this.options.find(i => i.displayText.toLowerCase().includes(term.toLowerCase())) == null)
                    this.setCurrentValue(term);
                if (this.blnAutoSelectOnMaxlength && (term.length == this.maxlength || term.length == this.intMaxLengthCode)) {
                    this.focusNextInput(true);
                }
            }
            //Condition added to clear alpha characters(instant validation) if the field is free text without option list(has feature of app-textfield component) and numeric. (onBlur also handles formatting after loose the focus)
            else if (this.blnFreeTextWithoutOption && blnSkipAction) {
                if (term.length == this.maxlength) {
                    if (this.strDbDataType == PropertyType.Number && !term.match('^[0-9.]+$')) {
                        this.clearComponent();
                        this.setIsInvalidClass(true);
                    }
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    /**
     * This function has been added to click event to trigger ngbtypeahead dismisspopup function if the control is already coded.
     * @param objEvent
     */
    public handleClick(objEvent) {

        if (this.objTextbox.nativeElement.value.length == this.maxlength)
            this.instance.dismissPopup();
    }

    public handleFocus(objEvent: FocusEvent) {
        super.handleFocus(objEvent);

        let inputElements = window.document.querySelectorAll('input');
        let focusInputElements = Array.prototype.filter.call(inputElements,
            function (element) {
                return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
            });
        let index = focusInputElements.indexOf(document.activeElement);
        let inputElement = focusInputElements[index];

        if (inputElement.value.length > 0) {
            inputElement.select();
            if (this.objTextbox.nativeElement.value.length == this.maxlength) {
                this.instance.dismissPopup();
            }

        }
    }

    public selected(objNgbSelection: NgbTypeaheadSelectItemEvent): void {
        this.setIsInvalidClass(false);
        this.blnFocusOtherSpecify = true;
        let objSelection: DrpDownOptions = objNgbSelection.item;
        let strDataType: string = AutofillService.GetDataType(this.objModel, this.strFieldName, this.strTableName);

        if (this.options.includes(objSelection)) { //-1 is a valid selection, 0 is also a valid selection
            if (this.blnListenEmitter)
                this.emitObjectOnChange.emit(this.objModel);

            if ((strDataType == 'number' && this.objModel[this.strFieldName] != objSelection.intValue) ||
                ((strDataType != 'number' || this.blnMatchDropDownOptionAsText) && this.objModel[this.strFieldName] !== objSelection.strValue))
                this.bindItemAndFocusNextElement(objSelection);
            else
                if (this.allowFocusNextOnEmptySelection) this.bindItemAndFocusNextElement(objSelection); // specially this logics for crash events. Fields AOI1 and AIO2
                else objNgbSelection.preventDefault();
        }
    }
    //#end region

    //#region TypeAhead Instance Helper Methods

    /**
     * onBlur() deals with two configurations for numeric and non-numeric fields (4 permutations) with leading zeros potentially switched off (8 permutations):
     * 1) Special Typeaheads mapped to text DB columns, with ability to pick a canned value or enter free text.
     * 2) User abandons (blurs) [non-free-text] field with non-blank value not from the list of allowed options, then field will return to the previous value.
     *    We are using setCurrentValue() function to reset back to the original field value
     */
    public onBlur(strNewValue: string): void {
        this.setIsInvalidClass(false);
        if (this.objModel) {

            if (this.blnFormatUpperCase && strNewValue != '') {
                strNewValue = strNewValue ? strNewValue.toUpperCase() : '';
            }

            // 1) Special Typeaheads mapped to text DB columns, with ability to pick a canned value or enter free text.
            if (this.blnAllowFreeText) {
                if (this.strDbDataType != PropertyType.Number)
                    this.SetModelValue(strNewValue);
                else {
                    if (strNewValue.match('^[0-9.]+$')) {
                        this.SetModelValue(strNewValue); //Applies leading zero formatting (if leading zero formatting is not turned off for the field)

                        if ((this.minRange != null) && (this.maxRange != null)) {
                            if (this.options.find(v => v.strValue == strNewValue) || (Number(strNewValue) >= Number(this.minRange) && Number(strNewValue) <= Number(this.maxRange))) {

                            }
                            else {
                                this.clearComponent();
                                this.setIsInvalidClass(true);
                                this._modalService.setMessage('Invalid Range. Value must be between ' + this.minRange.toString() + ' and ' + this.maxRange.toString(), 'error')
                            }
                        }
                        this.bindModel.emit({ intValue: parseFloat(strNewValue), strValue: strNewValue } as DrpDownOptions);
                    }

                    else if (this.objModel[this.strFieldName] != intRBISDataValue.Blank) {
                        let strFormatted: string = this.FormatWithLeadingZeros(this.objModel[this.strFieldName]);
                        if (this.blnFormatListOnlyShowText) {
                            let option: DrpDownOptions = this.options.find(i => i.intValue == this.objModel[this.strFieldName]);
                            if (option)
                                this.objTextbox.nativeElement.value = option.strText;
                        }
                        else {
                            this.objTextbox.nativeElement.value = strFormatted;
                        }

                    }
                    else
                        this.objTextbox.nativeElement.value = '';
                }
            }
            else if (this.blnShowSpecialValuesOnly && this.objModel[this.strFieldName] != strNewValue) { //When leading zeros are not shown, it is easy to overlook them and have the value be rejected, so when blnShowSpecialValuesOnly is set, we try to interpret the value as a valid option
                let strFormatted: string = this.FormatWithLeadingZeros(strNewValue);
                this.setCurrentValue(strFormatted);
            }
            // 2) User abandons (blurs) [non-free-text] field with non-blank value not from the list of allowed options, then field will return to the previous value.
            else if (!this.blnAllowFreeText && this.objTextbox.nativeElement.value != '') { //Abandoned text is not blank
                let objCurrentModelValue: any, objValueInTextBox: any;

                if (AutofillService.GetDataType(this.objModel, this.strFieldName) == 'number') {
                    objCurrentModelValue = this.objCurrentValue;
                    objValueInTextBox = parseFloat(this.objTextbox.nativeElement.value);
                }
                else {
                    objCurrentModelValue = this.objCurrentValue != null && this.objCurrentValue !== undefined ? this.objCurrentValue.toString() : null;
                    objValueInTextBox = this.objTextbox.nativeElement.value;
                }

                if (objCurrentModelValue != objValueInTextBox) { //Abandoned text is not the current selection
                    this.setCurrentValue(this.objCurrentValue !== null && this.objCurrentValue !== undefined ? this.objCurrentValue.toString() : null);
                }
            }
            else if (this.objModel[this.strFieldName] != strNewValue) {
                if (this.objModel[this.strFieldName] != intRBISDataValue.Blank) {
                    let strFormatted: string = this.FormatWithLeadingZeros(this.objModel[this.strFieldName]);
                    this.objTextbox.nativeElement.value = strFormatted;
                }
                else
                    this.objTextbox.nativeElement.value = '';
            }

            if (this.instance.isPopupOpen())
                this.instance.dismissPopup();
        }
    }

    public ClearNgTypeaheadInstance(): void {

        this.setIsInvalidClass(false);
        this.instance.writeValue(null);
        this.labelElem.nativeElement.innerText = null;

    }

    public clearComponent(blnSkipAutoFillOnChange: boolean = true): void {
        super.clearComponent();

        if (this.blnEnableOtherSpecify) {
            this.childOtherSpecify.DeleteOtherSpecify(this.objCurrentValue);
            this.blnEnableOtherSpecify = false;
        }

        this.edtConfidenceColor.HideEDTConfidenceColor();
        this.ClearNgTypeaheadInstance();

        if (!blnSkipAutoFillOnChange)
            AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, true, false, false);

        //this.bindModel.emit({ intValue: RBISDataValue.Blank, strValue: RBISDataValue.Minus1 } as DrpDownOptions);
    }

    bindOptionsToTypeahead(ngbInstance: NgbTypeahead, optionSet: DrpDownOptions[]) {

        ngbInstance.ngbTypeahead = this.search;
    }

    search = (text$: Observable<string>): Observable<DrpDownOptions[]> => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        //Merge debounce, focus and click observables and handle all with the same callback lambda
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(this.FilterOptions.bind(this)) //bind() defines the meaning of the keyword "this" when function is called
        );
    }
    //#end region

    //#region Dom Manupulation Helper Methods


    //#end region

    //public AdjustTextArea($event) {
    //    console.log($event);
    //}
    public RemoveTRX(): void {
        let index: number = this.options.findIndex(x => x.intValue == RecodeSpecialCodes.T);
        if (index > -1)
            this.options.splice(index, 1);
        index = this.options.findIndex(x => x.intValue == RecodeSpecialCodes.R);
        if (index > -1)
            this.options.splice(index, 1);
        index = this.options.findIndex(x => x.intValue == RecodeSpecialCodes.X);
        if (index > -1)
            this.options.splice(index, 1);
    }

    public ManullyAddTRX(): void {
        if (this.intMode == DBMode.RECODE) {
            if (this.strFieldName == 'Make' || this.strFieldName == 'Model' || this.strFieldName == 'DrugRes' || this.strFieldName == 'CrashType' || this.strFieldName == 'Body') {
                // this will remove existing TRX because while doing sort this it was sorting -4 -3,-2
                // in most of the others there is - 2, -3, -4 need to unshift them correctly after removing.
                this.RemoveTRX();
            }
            // this will hack for all the filter with multiple condition  (ModelYr because there is no text field in the Model_year table.)
            if ((this.strFilterCondition != null && this.strFilterCondition != '')
                || (this.strFieldName == 'ModelYr')
                || (this.strFieldName == 'Make')
                || (this.strFieldName == 'Model')
                || (this.strFieldName == 'DrugRes') || (this.strFieldName == 'CrashType') || (this.strFieldName == 'Body')) {
                const hasTrx = this.options.filter(i => i.intValue == RecodeSpecialCodes.T ||
                    i.intValue == RecodeSpecialCodes.R || i.intValue == RecodeSpecialCodes.X)
                if (hasTrx.length == 0) {
                    let TRXItem = {} as DrpDownOptions;
                    TRXItem.intValue = RecodeSpecialCodes.R
                    TRXItem.strValue = RecodeSpecialCodes.R.toString();
                    TRXItem.strText = RecodeSpecialCodes.RT.toString();
                    TRXItem.displayText = RecodeSpecialCodes.R.toString() + '-' + RecodeSpecialCodes.RT.toString()
                    this.options.unshift(TRXItem);

                    TRXItem = {} as DrpDownOptions;
                    TRXItem.intValue = RecodeSpecialCodes.X
                    TRXItem.strValue = RecodeSpecialCodes.X.toString();
                    TRXItem.strText = RecodeSpecialCodes.XT.toString();
                    TRXItem.displayText = RecodeSpecialCodes.X.toString() + '-' + RecodeSpecialCodes.XT.toString()
                    this.options.unshift(TRXItem);

                    TRXItem = {} as DrpDownOptions;
                    TRXItem.intValue = RecodeSpecialCodes.T
                    TRXItem.strValue = RecodeSpecialCodes.T.toString();
                    TRXItem.strText = RecodeSpecialCodes.TT.toString();
                    TRXItem.displayText = RecodeSpecialCodes.T.toString() + '-' + RecodeSpecialCodes.TT.toString()
                    this.options.unshift(TRXItem);
                }
            }

        }
    }

    showAttributeInfo(): void {
        console.log(this.currentLabel.label);
        this._modalService.openAttributeInfoModal(this.options, this.currentLabel);
    }

    replaceInCondition(text: string) {
        return text.replace('-1.00', '');
    }

}
