import { Injectable } from '@angular/core';
import { CanDeactivate, UrlSegment } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { Acc } from 'src/app/models/acc';
import { ActionButtonService } from 'src/app/services/action-button.service';
import { ActionCommand, VehicleSubTab } from 'src/app/models/enums/app.enums';
import { Veh } from 'src/app/models/veh';
import { VehicleService } from 'src/app/services/vehicle.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

/**
 * This routeguard works with components deriving from BaseComponent (src/app/helper/basecomponent) that define at least one ngForm.
 * If an ngForm is dirty, prompts the user whether to commit unsaved changes, discard unsaved changes or stay on the page
 **/
@Injectable({
    providedIn: 'root',
})
export class UnsavedChangesRouteGuard implements CanDeactivate<any> {

    vehicleid: number;
    currentTab: string;

    constructor(
        private _caseService: CaseService,
        private _modalService: ModalService,
        private _sharedService: SharedDataService,
        private _actionButtonService: ActionButtonService,
        private _vehService: VehicleService,
        private _urlTreeHelper: UrlTreeHelper
    ) {


    }

    canDeactivate(component: BaseComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let blnModelDirty: boolean = false;


        let objLastEmittedValue: { objComponent: Array<BaseComponent>, objCase: Acc } = this._sharedService.subReEvalCaseActionButtons.getValue();
        if (objLastEmittedValue) {
            if (objLastEmittedValue.objComponent) {

                let component: BaseComponent = objLastEmittedValue.objComponent.find(objComp => objComp && objComp.arrInputForms.toArray().some(x => x.dirty == true));
                blnModelDirty = component != null || component != undefined ? true : false; 
            }
        }

        console.log('Last Emitted Value', objLastEmittedValue);

        if (!blnModelDirty) {

            return true;
        }
        else {
            let objEmitedComponent: BaseComponent = objLastEmittedValue.objComponent.find(i => i.arrInputForms && i.arrInputForms.toArray().some(x => x.dirty == true));
            if (objEmitedComponent)
                component = objEmitedComponent;

            //Router will immediately subscribe to observable returned by this route guard
            return new Observable<boolean>(objGuardObserver => {
                this._modalService.dialog('Please specify how to proceed.', 'Warning: Unsaved changes', 'YesNoCancel', 'lg', true, false, "Save Changes", "Discard Changes", "Stay On Page", false)
                    .subscribe(
                        async blnOK => {
                            if (blnOK) { //Do we want to dispatch save in the background and optimistically assume it succeeds?
                                await component.onBeforeSave(null); //this may show conform prompt with validation from different tabs.

                                if (component.blnAlloweSave) {
                                    this._actionButtonService.SaveCase(component, ActionCommand.Save).then(
                                        ((blnSaveSuccessful: boolean) => { //Save first
                                            if (blnSaveSuccessful) {
                                                this._modalService.resetMessage();

                                                if (this._caseService.acc.Element_Specify) //We may be adding or removing "other specify" entries
                                                    this._caseService.SaveListElementSpecify(this._caseService.acc.AccID, this._caseService.acc.Element_Specify);

                                                objLastEmittedValue.objComponent.forEach(objComponent => {
                                                    objComponent.arrInputForms.forEach(objForm => objForm.form.markAsPristine());
                                                })

                                                //An automatic modal popup might be opened immediately after save returns in which case navigation to next tab
                                                //SHOULD NOT proceed. So, we check for open modal popups. (Example: Out of State Request automatic popup)
                                                let sbsModalOpen: Subscription = this._vehService.sbjModalOpenAfterSave
                                                    .subscribe((blnModalOpen: boolean) => {
                                                        //Then, allow route to deactivate and navigation to proceed (BehaviorSubject is guaranteed to have a value)
                                                        objGuardObserver.next(!blnModalOpen);
                                                    });
                                                sbsModalOpen.unsubscribe();
                                            }
                                            else {

                                                objGuardObserver.next(false); //Do not let route deactivate, i.e. remain on current page without doing anything.
                                            }
                                        }).bind(this),
                                        ((objError) => {

                                            objGuardObserver.next(false); //Do not let route deactivate, i.e. remain on current page without doing anything.
                                        }).bind(this));
                                }
                            }
                            else {
                                if (this._caseService.acc) {
                                    let intAccID: number = this._caseService.acc.AccID;
                                    this._caseService.acc = null;                    //Discard uncommitted data model, no need to run edit checks
                                    this._caseService.GetCasePromise(intAccID).then( //Get fresh data model from DB
                                        objAcc => {
                                            objGuardObserver.next(true); //then, allow route to deactivate and navigation to proceed.
                                        }
                                    );
                                }
                                else {
                                    console.error('InvalidOperation: blnModelDirty is set, but no case is loaded. This route guard is incorrectly being applied to a route that is not capturing case data.');
                                    objGuardObserver.next(true);
                                }
                            }
                        },
                        objDismissReason => {

                            objGuardObserver.next(false); //Do not let route deactivate, i.e. remain on current page without doing anything.
                        });
            });
        }
    }

}
