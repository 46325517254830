import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
    selector: 'app-modals-alcohol-calculator',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './modals-alcohol-calculator.component.html',
    styleUrls: ['./modals-alcohol-calculator.component.css']
})
export class ModalsAlcoholCalculatorComponent implements OnInit {

    closeResult: string;
    bac: any;
    alcUnit: number = -1;
    result: string = '';
    warning: string = '';
    resultText: string = '';
    dummyText: string = 'This is {0} g/dL';
    fontColor: string = 'red';
    options1 = [
        { text: 'g/mL' },
        { text: 'mg/dL' },
        { text: 'g/100mL' },
        { text: 'g/dL' },
        { text: 'g/100cc' },
        { text: '%' },
    ];

    states = [
        { name: 'Arizona', abbrev: 'AZ' },
        { name: 'California', abbrev: 'CA' },
        { name: 'Colorado', abbrev: 'CO' },
        { name: 'New York', abbrev: 'NY' },
        { name: 'Pennsylvania', abbrev: 'PA' },
    ];

    @ViewChild('btnOpen') public btnOpen: ElementRef;
    @Input() strForm: string;
    @Output() submitAlcoholValue = new EventEmitter();
   // @Input() alcoholResult: string;
    @Input() hideResult: boolean;

    constructor(
        private _modalService: NgbModal
    ) { }

    ngOnInit() {

    }

    public open(content, options: NgbModalOptions) {
        
        this._modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title', size: 'alcohol' as 'lg', backdropClass: 'light-blue-backdrop' }).result.then((result) => {
            
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    convert() {
        console.log(this.bac, this.alcUnit);
        this.doAlcoholConversion();
    }


    doAlcoholConversion() {
        this.result = '';
        if (this.bac == '' || this.bac == '.') return;
        if (this.bac == '0') {
            this.warning = 'Please enter a valida BAC value';
            return;
        }
        if (this.alcUnit == -1) {
            this.warning = 'Please select an unit';
            return;
        }

        let alcoholResValue: any;
        let dec0: any;
        let dec1: any;
        let alcResVal: number = 0;
        console.log('this.alcUnit', this.alcUnit)
            if (this.alcUnit == 1) {
                if (this.bac != 0) {
                    alcoholResValue = (this.bac * 100);
                }
            }
            else if (this.alcUnit == 2) {
                console.log('hello world');
                if (this.bac != 0) {
                    console.log('i am here');
                    alcoholResValue = (this.bac / 1000);
                }
            }
            else if (this.alcUnit == 3 || this.alcUnit == 4 || this.alcUnit == 5) {
                alcoholResValue = this.bac;
            }
            else if (this.alcUnit == 6) {
                alcoholResValue = (this.bac / 100) * 100;
            }
 
        let s: string = alcoholResValue.toString();
        
        let index = s.indexOf('.');
        
        if (index > -1) {
            alcoholResValue = parseFloat(alcoholResValue).toFixed(3);
            dec0 = alcoholResValue.split('.')[0];
            dec1 = alcoholResValue.split('.')[1];
            console.log('dec0 value', dec0);
            if (dec0 != 'undefined' && dec0 < 1) {
                alcResVal = this.padNumRight(dec1);
                console.log('alcResVal', alcResVal)
            }
            else if (dec0 != 'undefined' && dec0 >= 1) {
                this.warning = 'Error! The calculated Alcohol Test Result is out of range. Please check the BAC entered.';
                this.resultText = this.dummyText.replace('{0}', alcoholResValue);
                return;
            }
        } else {
            if (alcoholResValue >= 1) {
                this.warning = 'Error! The calculated Alcohol Test Result is out of range. Please check the BAC entered.';
                this.resultText = this.dummyText.replace('{0}', alcoholResValue);
                return;
            } else {
                alcResVal = this.padNumRight(dec1);
            }

        }

        if (alcResVal > 994) {
            this.warning = 'Error! The calculated Alcohol Test Result is out of range. Please check the BAC entered.';
            return;
        }

        if (alcResVal >= 940 && alcResVal <= 994) {
            alcResVal = 940;
        }
        this.result = alcResVal.toString().substring(0, 3);

       // this.padNumRight(alcResVal);
        this.resultText = this.dummyText.replace('{0}', alcoholResValue)
        this.warning = '';

        if (alcResVal >= 340) {
            this.warning = 'Warning! The calculated Alcohol Test Result appears high. Please check the BAC entered.';
        } else {
            this.fontColor = 'black';
        }
    
    }
    padNumRight(str: any) {

        str = str.toString();
        while (str.length <3) {
            str = str + "0";
        }
        return str;
    }

    padNumLeft(str: any) {
        str = str.toString();
        while (str.length < 3) {
            str = "0" + str;
        }
        return str;
    }
    unitChange() {
        this.result = '';
        this.warning = '';
        this.resultText = '';
        this.doAlcoholConversion();
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    onSubmit() {
        console.log('resutl ', this.result)

        this.submitAlcoholValue.emit(this.result);
    }

    onClear() {
        this.alcUnit = -1;
        this.bac = '';
        this.resultText = '';
        this.warning = '';
        this.result = '';
    }
}
