import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { Veh } from 'src/app/models/veh';
import { Acc } from 'src/app/models/acc';
import { Subscription } from 'rxjs';
import { VehType, UnitType, RBISDataValue } from 'src/app/models/enums/app.enums';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { Element_Specify } from 'src/app/models/element-specify';
import { LightingType_SS } from 'src/app/models/LightingType_SS';

import { SuplTrfcCtrl_SS } from 'src/app/models/SuplTrfcCtrl_SS';
import { LightingClr_SS } from 'src/app/models/LightingClr_SS'; 
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { FieldName, LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { DrpDownOptions } from '../../../../models/drp-down-options';


@Component({
  selector: 'app-sfr-vehicle-vehicle-control',
  templateUrl: './sfr-vehicle-vehicle-control.component.html',
  styleUrls: ['./sfr-vehicle-vehicle-control.component.css']
})
export class SfrVehicleVehicleControlComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    public veh: Veh;
    acc: Acc;
    accid: number;
    vehicleid: number;
    private sbsVehicleId: Subscription;
    public VehClone: Veh;
    public LightingType_SS: LightingType_SS;
    public LightingClr: LightingClr_SS;

    objInstanceElementSpecify: Element_Specify;

    public objLightingClrTemplate: LightingClr_SS;
    public objSuplTrfcCtrlTemplate: SuplTrfcCtrl_SS;
    @ViewChild('addResults') txtAddRows: ElementRef;
    addResult: number;
    intRowsToAdd: number = 1;
    locationList: Array<DrpDownOptions> = [];
    typeList: Array<DrpDownOptions> = [];
    colorList: Array<DrpDownOptions> = [];
    statusList: Array<DrpDownOptions> = [];

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        public _autofillService: AutofillService,
        private _secUserPerferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper

    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) 
            this.vehicleid = this._sharedDataService.getVehicleId();
        else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
        }
        this.addResult = null;
     
        // this.vehicleid = this._sharedDataService.getVehicleId();
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.SFRVehicle);
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);

        this.objLightingClrTemplate = ObjectUtil.InstantiateLightingClr_SS(this.veh);
        if (!this.veh.LightingClr_SS)
            this.veh.LightingClr_SS = new Array<LightingClr_SS>();

        this.objSuplTrfcCtrlTemplate = ObjectUtil.InstantiateSuplTrfcCtrl_SS(this.veh);
        if (!this.veh.SuplTrfcCtrl_SS)
            this.veh.SuplTrfcCtrl_SS = new Array<SuplTrfcCtrl_SS>();

        console.log(this.veh.LightingType_SS.length);
        //if (this.veh.LightingType_SS.length == 0)
        //    this.onAddResults(1)

        this.formLoad();

    }

    formLoad() {
        this.VehClone = ObjectUtil.CloneModelObject(this.veh);

        this._utilService.formDrpDownOptionShare.subscribe(async result => {
            this.locationList = await result.filter(i => i.tblName == LookupTable.LightingType);
            this.typeList = await result.filter(i => i.tblName == LookupTable.LightingSrc);
            this.colorList = await result.filter(i => i.tblName == LookupTable.LightingClr);
            this.statusList = await result.filter(i => i.tblName == LookupTable.LightingSts);
        }).unsubscribe();

        
    }
    ngAfterViewInit() {
        super.ngAfterViewInit();

        //First field that should have focus is not an instance of UIElementBase.
        //Need a non-trivial delay before focus can be given - it seems AfterViewInit is too early to give focus. So, we wait for UIElementBase instances to finish initializing.
        let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            subInit.unsubscribe();
            if (this.txtAddRows) {
                this._activatedRoute.fragment.subscribe((fragment: string) => { //If page is being navigated to from Check Blanks or Edit Checks, default field focus logic is overridden.
                    if (!fragment) {
                        this.txtAddRows.nativeElement.focus();
                        this.txtAddRows.nativeElement.select();
                    }
                });
            }
        }).bind(this));
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        //if (this.sbsClearForm) {
        //    this.sbsClearForm.unsubscribe();
        //}
    }

    public async onBeforeSave() {
        this.removeEmptyLightingType();
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }


    public async onAddResults(objEvent: Event) {
        if (this.intRowsToAdd) {
            for (let i = 0; i < this.intRowsToAdd; i++) {
                let objLightingType_SS = {} as LightingType_SS;
                objLightingType_SS = {
                    AccID: this.veh.AccID,
                    VNumber: this.veh.VNumber,
                    LightingType: -1,
                    LightingSrc: -1,
                    LightingSts: -1,
                    LightingClr: -1,
                    SeqNum: this.veh.LightingType_SS.length + 1,
                } as LightingType_SS;
                this.veh.LightingType_SS.push(objLightingType_SS);
            }         
        }
        this.addResult = null;
        this.intRowsToAdd = null;
        // console.log(this.veh.LightingType_SS);
        this.MarkFormAsDirty();

        if (objEvent && objEvent instanceof KeyboardEvent && objEvent.key == 'Enter') {
            objEvent.preventDefault();
            objEvent.cancelBubble = true;
        }
    }
 
    onDeleteRow(SeqNum: number) {
        this.veh.LightingType_SS = this.veh.LightingType_SS.filter(x => x.SeqNum !== SeqNum);
        this.RenumberArrayObjects(); //Removal could be from the end of the collection or from the middle of the
        this.MarkFormAsDirty();
        //collection
    }

    private RenumberArrayObjects() {
        for (let i = 0; i < this.veh.LightingType_SS.length; i++) {
            this.veh.LightingType_SS[i].SeqNum = i + 1;
        }
    }

    removeEmptyLightingType() {
         let lightingType = this.acc.Veh.find(x => x.AccID === this.veh.AccID && x.VNumber === this.veh.VNumber).LightingType_SS;
        if (lightingType.length > 1) {
            lightingType.forEach(item => {
                if (item.LightingClr === -1 && item.LightingSrc === -1 && item.LightingSts == -1 && item.LightingType == -1) {
                    console.log('test');
                    this.onDeleteRow(item.SeqNum);
                }
            });
        }
    }
}
