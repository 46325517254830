
<div class="modal-header bg-light" ngbAutofocus>
    <h5 *ngIf="currentLabel != undefined && currentLabel.link != undefined"><a href="/assets/{{currentLabel.link}}" target="_blank" style="outline: none">{{currentLabel.label}}</a></h5>
    <h5 *ngIf="currentLabel != undefined && currentLabel.link == undefined">{{currentLabel.label}}</h5>
    <h5 *ngIf="currentLabel == undefined">{{displayText}}</h5>
    
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <div class="row mb-1 ml-1">
        <input type="text" class="form-control w-50" placeholder="find here" (keyup)="searchText($event)" [(ngModel)]="searchTextValue" />
    </div>
    
    <ul class="list-group list-group-flush" style="min-height:300px;max-height:300px; overflow-y: auto">
        <ng-container *ngFor="let item of options">
            <li class="list-group-item p-0" style="font-size:16px; font-weight:bold" *ngIf="item.intValue!=-1">
                   {{item.displayText}}
            </li>
        </ng-container>
       
    </ul>
</div>
    <div class="modal-footer">
    <button class="btn btn-primary float-right" (click)="activeModal.close('I am closed')">Close</button>
    </div>

