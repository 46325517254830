<div  class="border-left border-bottom border-right bg-light">
    <ul class="nav nav-tabs border-dark border-left">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./sfrvehicle"]'
               [ngClass]="{'bg-light': linksfrvehicle.isActive !== true }"
               [routerLinkActive]="['active']" #linksfrvehicle="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksfrvehicle.isActive === true, 'fw-bold' : linksfrvehicle.isActive === true}">
                        SS_Vehicle
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./sfrvin"]'
               [ngClass]="{'bg-light': linksfrVehVIN.isActive === true }"
               [routerLinkActive]="['active']" #linksfrVehVIN="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksfrVehVIN.isActive === true, 'fw-bold' : linksfrVehVIN.isActive === true}">
                        SS_VIN
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./sfrcontrol"]'
               [ngClass]="{'bg-light': linkVehControl.isActive === true }"
               [routerLinkActive]="['active']" #linkVehControl="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehControl.isActive === true, 'fw-bold' : linkVehControl.isActive === true}">
                        SS_Control
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./sfrother"]'
               [ngClass]="{'bg-light': linksfrVehOther.isActive === true }"
               [routerLinkActive]="['active']" #linksfrVehOther="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksfrVehOther.isActive === true, 'fw-bold' : linksfrVehOther.isActive === true}">
                        SS_Other
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./sfrnotes"]'
               [ngClass]="{'bg-light': linksfrVehNotes.isActive === true }"
               [routerLinkActive]="['active']" #linksfrVehNotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksfrVehNotes.isActive === true, 'fw-bold' : linksfrVehNotes.isActive === true}">
                        SS_Notes
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./sfrvehicleVINDecode"]'
               [ngClass]="{'bg-light': linkVehVINDecode.isActive === true }"
               [routerLinkActive]="['active']" #linkVehVINDecode="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehVINDecode.isActive === true, 'fw-bold' : linkVehVINDecode.isActive === true}">
                        SS_VIN Decode
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <router-outlet></router-outlet>
</div>
