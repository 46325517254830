import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { CaseService } from '../../../services/case.service';
import { Acc } from '../../../models/acc';
import { Dri } from '../../../models/dri';
import { Veh } from 'src/app/models/veh';
import { DBMode } from 'src/app/models/enums/app.enums';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { extend } from 'webdriver-js-extender';
import { BaseComponent } from 'src/app/helper/basecomponent';

@Component({
    selector: 'app-vehicle-driver',
    templateUrl: './vehicle-driver.component.html',
    styleUrls: ['./vehicle-driver.component.css']
})

export class VehicleDriverComponent implements OnInit, OnDestroy {

    accid: number;
    vehicleid: number;
    intMode: number;

    acc: Acc;
    veh: Veh;
    dri: Dri;


    constructor(
        private _route: ActivatedRoute,
        private _caseService: CaseService,
        private _sharedDataService: SharedDataService
    ) { }

    async ngOnInit() {
        let objParams: Params = await this._route.parent.parent.params;
        let objParams2: Params = await this._route.parent.params;
        let strCaseId = objParams.value['caseid'];
        this.accid = +strCaseId;
        this.vehicleid = +objParams2.value['vehicleid'];
        this.acc = await this._caseService.GetCasePromise(this.accid);
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        if (!this.veh.Dri) {
            this.dri = {} as Dri;
        }
        else {
            this.dri = this.acc.Veh.find(v => v.VNumber == this.vehicleid).Dri;
        }

    }

    async ngAfterViewInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = appSettings.intMode;
    }

    ngOnDestroy() {

    }


}
