<div class="border-dark border-start border-bottom border-end px-2">
    <ul class="nav nav-tabs border-dark bg-white">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./roadway"]'
               [ngClass]="{'bg-light': linkPrecrashRoadway.isActive !== true }"
               [routerLinkActive]="['active']" #linkPrecrashRoadway="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkPrecrashRoadway.isActive === true, 'fw-bold' : linkPrecrashRoadway.isActive === true}">
                        Roadway
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./precrash"]'
               [ngClass]="{'bg-light': linkPrecrashPrecrash.isActive !== true }"
               [routerLinkActive]="['active']" #linkPrecrashPrecrash="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkPrecrashPrecrash.isActive === true, 'fw-bold' : linkPrecrashPrecrash.isActive === true}">
                        Precrash
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./precrashOther"]'
               [ngClass]="{'bg-light': linkPrecrashOther.isActive !== true }"
               [routerLinkActive]="['active']" #linkPrecrashOther="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkPrecrashOther.isActive === true, 'fw-bold' : linkPrecrashOther.isActive === true}">
                        Other
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <!--here we generate and show Vehicle Precrash's-children html's components-->
    <div class="tab-content">
        <router-outlet></router-outlet>
    </div>
</div>
