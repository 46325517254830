<form novalidate #fvpperson="ngForm">
    <div class="pt-2" *ngIf="person">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="Age" [printOnly]="printOnly" inputName="Age"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-modals-age-calculator #ageCalculator *ngIf="!blnReadOnly"
                                                           name="personAge"
                                                           [personObj]="person"
                                                           [accDate]="accDate"
                                                           [occupantDateOfBirth]="personDateOfBirth"
                                                           (newDOBEvent)="setDateOfBirth($event)">
                                </app-modals-age-calculator>
                            </span>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="Age" name="Age" typeAheadPosition="right-top"
                                               [disableLabel]="true" [blnShowSpecialValuesOnly]="true"
                                               [objModel]="person" #ageField (keyup)="handleKeyup($event)" strTableName="Occupants" strFieldName="Age"
                                               [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="sex" name="sex" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="Sex" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="pType" name="pType" typeAheadPosition="right-top" strFilterCondition=" AND Type = 1" strDefsLookupTableName="PType"
                                   [objModel]="person" (bindModel)="OnModelPTypeChange($event)"
                                   strTableName="Occupants" strFieldName="PType" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="injury" name="injury" typeAheadPosition="right-top"
                                   [objModel]="person" (bindModel)="OnModelChange($event)"
                                   strTableName="Occupants" strFieldName="Injury"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="seatPos" name="seatPos" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="SeatPos" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="takeHosp" name="takeHosp" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="TakeHosp" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->
            <!--<div *ngIf="printOnly && intMode == 1" class="col-md-12 " style="margin-top:35px!important"></div>-->

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group my-auto pl-2">
                    <app-multiselect id="occRF" strFilterCondition=" AND PRFTYPE in (0,1)" strDefsLookupTableName="PRF"
                                     name="occRF"
                                     multiSelectPosition="right-top"
                                     [objModel]="person.OccRF"
                                     strTableName="OccRF"
                                     strFieldName="PRF"
                                     [objTemplateModel]="objOccRFTemplate" [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
           

            <ng-container *ngIf="!printOnly">
                <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                    <div *ngIf="blnPIIVisible && mode === 1">

                        <div class="border-top border-dark pt-2">

                            <div class="form-group ">
                                <div class="row pl-2 {{printOnly ? 'text-center': ''}}">
                                    <label [ngClass]="{'col-12 col-form-label fw-bold': !printOnly }"><span [ngClass]="{'print-font-size-small' : printOnly }">Person Identification Information</span></label>
                                </div>
                            </div>
                            <div>
                                <div class="form-group ">
                                    <div class="row pl-2">
                                        <label for="traficwayId1" class="{{ !printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-form-label fw-bold': 'col-sm-6' }}">
                                            <span [ngClass]="{'print-font-size-label' : printOnly }">Person Name </span>
                                        </label>
                                        <div class="col-xl-3" *ngIf="!printOnly">
                                            <input id="PName" type="text" class="form-control" name="PName" [ngModel]="pii?.PName" (ngModelChange)="pii.PName = $event; onPIINameChange($event)"
                                                   [disabled]="blnReadOnly" maxlength="50">
                                        </div>
                                        <div class="col-xl-3 print-font-size-small" *ngIf="printOnly">
                                            {{pii?.PName}}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <div class="row  pl-2">
                                        <div class="{{ !printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2': 'col-sm-6' }}">
                                            <label for="personDateOfBirth" [ngClass]="{'col-form-label fw-bold' : !printOnly }">
                                                <span [ngClass]="{'print-font-size-label' : printOnly }"> Date Of Birth </span>
                                            </label>
                                        </div>
                                        <div class="{{ !printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3' :'col-sm-6' }}">

                                            <div class="form-group" *ngIf="!printOnly">
                                                <div class="input-group">
                                                    <input class="form-control" placeholder="mm/dd/yyyy" id="personDateOfBirth" name="personDateOfBirth"
                                                           [ngClass]="{'is-invalid': blnShowValidationMessageOcc}"
                                                           [ngModel]="personDateOfBirth" (ngModelChange)="onDateSelect(dpDOB, $event)" (blur)="onBlur(dpDOB)"
                                                           [minDate]="getMinDate" [maxDate]="getMaxDate" maxlength="10"
                                                           [autoClose]="inside" (dateSelect)="page.close()"
                                                           ngbDatepicker
                                                           #dpDOB="ngModel" #page="ngbDatepicker"
                                                           [disabled]="blnReadOnly">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-light border-secondary" (click)="page.toggle()" type="button" [disabled]="blnReadOnly">
                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group print-font-size" *ngIf="printOnly">
                                                {{personDateOfBirth}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <div class="row pl-2">
                                        <label class="{{ !printOnly ? 'col-sm-2 col-form-label fw-bold' : 'col-sm-6' }}">  <span [ngClass]="{'print-font-size-label' : printOnly }"> Comment 1 </span></label>
                                        <div class="col-3" *ngIf="!printOnly">
                                            <textarea id="Comment1" type="text" rows="2" class="form-control" name="Comment1" [ngModel]="pii?.Comment1" (ngModelChange)="pii.Comment1 = $event; onPIIComment1Change($event)"
                                                      [disabled]="blnReadOnly" maxlength="50"></textarea>
                                        </div>
                                        <div class="col-3 print-font-size" *ngIf="printOnly">
                                            {{pii?.Comment1}}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <div class="row pl-2">
                                        <label class="{{ !printOnly ? 'col-sm-2 col-form-label fw-bold' : 'col-sm-6' }}"> <span [ngClass]="{'print-font-size-label' : printOnly }">Comment 2 </span></label>
                                        <div class="col-3" *ngIf="!printOnly">
                                            <textarea id="Comment2" type="text" rows="2" class="form-control" name="Comment2" [ngModel]="pii?.Comment2" (ngModelChange)="pii.Comment2 = $event; onPIIComment2Change($event)"
                                                      [disabled]="blnReadOnly" maxlength="50"></textarea>
                                        </div>
                                        <div class="col-3 print-font-size" *ngIf="printOnly">
                                            {{pii?.Comment2}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</form>
