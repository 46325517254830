<form novalidate #fsfrvv="ngForm">
    <div *ngIf="veh && intMode" id="strikevehicle-vehicle" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">                  
                    <div class="form-group pl-2">
                        <app-textfield id="DriNotes" [disableLabel]="false"
                                       [blnIsNumber]="false" [intTextAreaRows]="10"
                                       [objModel]="veh.Dri" [(objCurrentValue)]="veh.Dri.Notes"
                                       [blnFormatWithLeading]="false"
                                       strTableName="Dri" strFieldName="Notes"
                                       [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                        </app-textfield>
                    </div>
                </div>
            </div>

        </div>
    </div>
</form>
