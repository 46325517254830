import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Acc } from 'src/app/models/acc';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { AutofillService } from 'src/app/services/autofill.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { Subscription } from 'rxjs';
import { Element_Specify } from 'src/app/models/element-specify';
import { ObjectUtil } from 'src/app/helper/objectUtil';

@Component({
    selector: 'app-non-occupant-injury',
    templateUrl: './non-occupant-injury.component.html',
    styleUrls: ['./non-occupant-injury.component.scss']
})

export class NonOccupantInjuryComponent extends BaseComponent implements OnInit, AfterViewInit {
    accid: number;
    nonOccid: number;

    acc: Acc;
    nonOccupant: Non_Occupants;
    objInstanceElementSpecify: Element_Specify;
       
    private sbsNonOccId: Subscription;    

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    ngOnInit() {
        this._route.parent.params.subscribe((params => {
            this.accid = + params['crashid'];
        }).bind(this));

        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
        }
    }

    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    FormLoad() {
        this.acc = this.acc;
        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);

        if (this.nonOccupant.NonOcc_Injury == null)
        {
            this._autofillService.AddNonOccInjury(this.nonOccupant);
        }
       
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, 0, this.nonOccupant.PNumber);

    }

}
