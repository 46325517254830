import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Acc }         from 'src/app/models/acc';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';

/**
 * For a given active route, inspects the nesting hierarchy of all currently active routes to find route with parameter called "caseID".
 * Retrieves case (dbo.Acc) with given ID via CaseService (CaseService will ensure that multiple requestors (active on screen components)
 * of the same case share the same case instance).
 **/
@Injectable()
export class CaseResolver implements Resolve<Acc> {

    constructor(
        private _caseService: CaseService,
        private _utilService: UtilService,
        ) { }

    public resolve(route: ActivatedRouteSnapshot): Promise<Acc> {
        let strCaseId: string = this._utilService.GetParameterFromRouteHierarchy(route, 'caseid');
        return this._caseService.GetCasePromise(+strCaseId);
    }
}
