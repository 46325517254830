import { Component, Input, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ViewChild, Renderer2, ElementRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { OnInit, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { Observable, Subject, merge, Subscription } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';


import { KeyCode, RBISDataValue, DBMode } from 'src/app/models/enums/app.enums';
import { Element_Specify } from 'src/app/models/element-specify';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { AutofillService } from 'src/app/services/autofill.service';
import { UtilService } from 'src/app/services/util.service';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { OtherSpecifyComponent } from '../other-specify/other-specify.component';
import { DecimalPipe } from '@angular/common';
import { ReplaceTextPipe } from 'src/app/pipes/replaceText.pipe';
import { ModalService } from 'src/app/services/modal.service';
import { CaseService } from 'src/app/services/case.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { usp_EDT_GetStylesByStateNum_Result } from 'src/app/models/usp_EDT_GetStylesByStateNum_Result';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { LabelComponent } from '../label/label.component';


@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.css'],
    providers: [DecimalPipe, ReplaceTextPipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }] //Needed for input element in this component to participate in parent's ngForm
})
export class MultiselectComponent extends UIElementBase implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    public _TypeScript_TypeGuard_MultiselectComponent = null;
    //Public "input" properties to pass data from parent pages to child TypeaheadComponent instances
    @Input() blnHideNumericalCodePrefix: boolean = false;
    @Input() blnHideElemCodes: boolean = false;
    @Input() blnEnableManualSort: boolean = false;

    @Input() strFilterCondition: string = "";
    @Input() maxInputs: number;
    @Input() selectedOptions: Array<number> = [];
    @Input() multiSelectPosition: string = "right";
    @Input() objTemplateModel: any = {};

    /**
     * Flag that controls whether options will allow filtering and selection by text substring matching and by numerical codes (searchAll = true), or only by numerical codes (searchAll = false)
     **/
    @Input() searchAll: boolean = true;
    //to access the event object with the "$event" argument from the parent
    @Output() bindObjMultiSelect = new EventEmitter<any>();

    //direct access the DOM elements to manupulate them
    @ViewChild('lblCodes', { static: false }) lblCodesElem: ElementRef;
    @ViewChildren('otherSpecify') componentOtherSpecify: QueryList<OtherSpecifyComponent>;
    @ViewChild('otherSpecify', { static: false }) childOtherSpecify: OtherSpecifyComponent;
    @ViewChild('printTextArea', { static: false }) printTextArea: ElementRef;
    @ViewChild('txtHelper', { static: false }) txtHelper: ElementRef;
    @ViewChild('currentLabel', { static: false }) currentLabel: LabelComponent;
    //Property declaration
    static objectCodingManualWindow: Window;
    arrSelectedItems: Array<DrpDownOptions> = [];//It stores selected drpdownoptions to display sorted list of codes which is separated with(.)
    typeaheadSubscription: Subscription = new Subscription();
    strNotApplicable: string = "";
    strNotReported: string = "";
    strUnknown: string = "";
    public strHelper: string = "";


    constructor(
        protected _autofillService: AutofillService,
        protected renderer: Renderer2,
        protected decimalPipe: DecimalPipe,
        protected replaceTextPipe: ReplaceTextPipe,
        protected _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _changeDetectorRef: ChangeDetectorRef,
        private _modalService: ModalService,
        private _sharedService: SharedDataService,
    ) {
        super(_autofillService, renderer, decimalPipe, replaceTextPipe, _utilService, _urlTreeHelper);
    }

    //#region Angular LifeCycle Hooks

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.typeaheadSubscription.unsubscribe();
    }

    public async ngOnInit(): Promise<void> {
        try {
            //console.debug('MultiselectComponent.ngOnInit(): Begin', this.id);
            await super.ngOnInit(); //Base class will call bindFieldMetadata()
            //console.debug('MultiselectComponent.ngOnInit(): End', this.id);
            this.blnIsMultiSelect = true;
        }
        catch (ex) {
            console.error(ex);
        }
    }
    //#endregion

    public async findElementMetaData() {
        //console.debug('MultiselectComponent.findElementMetaData(): Begin', this.id);
        let arrMetadata: TableFieldElements[] = await this._utilService.GetMetadataPromise();

        if (this.strDefsLookupTableName && this.strDefsLookupTableName != '') {
            //DefsLookupTableName not always unique for every control and if there is more than one entry also filter with TableId and FieldId
            let metadata = arrMetadata.filter(x =>
                x.LookupTblID == this.strDefsLookupTableName);
            if (metadata && metadata.length > 0) {
                this.objMetaData = arrMetadata.find(x =>
                    x.TableID == this.strTableName &&
                    x.FieldID == this.strFieldName &&
                    x.LookupTblID == this.strDefsLookupTableName
                );
            }
            else {
                this.objMetaData = arrMetadata.find(x =>
                    x.LookupTblID == this.strDefsLookupTableName);
            }
        }
        else if (this.strTableName != '' && this.strFieldName != '' && this.intSeqNum) {
            this.objMetaData = arrMetadata.find(x =>
                x.TableID == this.strTableName &&
                x.FieldID == this.strFieldName &&
                x.SeqNum == this.intSeqNum);
            //If no record found filtering by SeqNum find the metadata with TableId and FieldId parameters
            if (!this.objMetaData)
                this.objMetaData = arrMetadata.find(x =>
                    x.TableID == this.strTableName &&
                    x.FieldID == this.strFieldName);
        }
        else if (this.strTableName != '' && this.strFieldName != '') {
            this.objMetaData = arrMetadata.find(x =>
                x.TableID == this.strTableName &&
                x.FieldID == this.strFieldName);
        }

        //console.debug('MultiselectComponent.findElementMetaData(): End', this.id);
    }

    public async bindFieldMetadata(): Promise<void> {
        //console.debug('MultiselectComponent.bindFieldMetadata(): Begin', this.id);
        await this.findElementMetaData();

        let appSetting: AppSettings = await this._sharedService.GetAppSettings();
        this.intMode = appSetting ? appSetting.intMode : -1;

        if (this.objMetaData) {
            if (!this.strDefsLookupTableName || this.strDefsLookupTableName == '')
                this.strDefsLookupTableName = this.objMetaData.LookupTblID;

            this.strNotApplicable = this.objMetaData.NotApplicable;
            this.strNotReported = this.objMetaData.NotRepoValue;
            this.strUnknown = this.objMetaData.UnknownValue;

            //Checking object has other-specify and enable the text box.
            if (this.objMetaData.IsSpecify) {
                if (this.objModel) {
                    this.objModel.forEach(((item: any) => {
                        //setTimeout((() => {
                            //if (this.objTemplateModel != undefined && //Moh
                            //    this.objTemplateModel.hasOwnProperty('IsSpecify')) {
                                    if (this.objMetaData.OtherSpecifyIntValues)
                                        if (this.objMetaData.OtherSpecifyIntValues.split(',').some(i => Number(i) == item[this.strFieldName]))
                                            item['IsSpecify'] = true;
                            //}
                            //else {
                            //    console.error(`MultiselectComponent.bindFieldMetadata(): objTemplateModel not specified by encapsulating parent.`);
                            //}
                        //}).bind(this), 1000); //FRINGE CASE: If this control is injected via a structural directive after the parent component has already finished initializing, then @Input properties are not available yet at the time bindFieldMetadata() is called and we need a non-trivial delay to let @Input properties trickle in (a trivial delay of 1 millisecond is not enough because outside the ngInit lifecycle there is no guaranteed about the order in which @Input properties will be initialized.).
                    }).bind(this));
                }
            }

            //ExcludeLookUpTblId is a flag to indicate that cached DEFS data should not be used and a fresh request should be made every time the field is initialized.
            //Generally used for fields where DEFS data is filtered based on business rule conditions.
            if ((this.objMetaData) && (this.objMetaData.ExcludeLookUpTblId != true)) {
                this._utilService.formDrpDownOptionShare.subscribe(result => {
                    if (result) {
                        let objOptions = result.filter(i => i.tblName == this.strDefsLookupTableName);

                        if (objOptions && objOptions.length > 0) {
                            this.options = objOptions;
                            this.setMaxLengthOfCode();
                            this.sortAndDisplaySelectedCodes();
                            this.InsertBlankToOptions();
                        }
                        else {
                            this._utilService.GetDrpDownListOptions(this.strDefsLookupTableName, '').subscribe(dataset => {
                                if (dataset) {
                                    this.options = dataset;
                                    this.setMaxLengthOfCode();
                                    this.sortAndDisplaySelectedCodes();
                                    this.InsertBlankToOptions();
                                }

                            });
                        }
                    }
                });
            }
            else {
                this._utilService.GetDrpDownListOptions(this.strDefsLookupTableName, this.strFilterCondition).subscribe(dataset => {
                    if (dataset) {
                        this.options = dataset;
                        this.setMaxLengthOfCode();
                        this.sortAndDisplaySelectedCodes();
                        this.InsertBlankToOptions();
                    }

                });
            }
        }
        else if ((this.intMode & this.intDisabledMode) == this.intDisabledMode) {
            this.hideOrShowComponent(false);
        }
        else {
            //this.labelElem.nativeElement.innerText = " Warning!!!Metadata missing and control not initialize";
        }
        return;
    }

    //#region Event Handlers

    search = (text$: Observable<string>): Observable<DrpDownOptions[]> => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(this.FilterOptions.bind(this)) //bind() defines the meaning of the keyword "this" when function is called
        );
    }

    /**
     * Formats selected option as empty string. In other words, upon selection, the 
     * MultiSelectComponent empties itself to prepare for the entry of the next selection.
     */
    public formatter(result: DrpDownOptions) {
        if (this.blnFormatListOnlyShowText)
            return result.strText;
        return '';
    }

    selected($event) {
        //We do not want to call preventDefault() as it will stop NGMODEL from notifying NGFORM that the field is dirty, instead formatter() formats the selected option as a blank.
        let item: DrpDownOptions = $event.item;
        this.selectObject(item);
    }

    public handleKeydown(event: KeyboardEvent): void {
        super.handleKeydown(event);
        let term: string = (<any>(event.target)).value;

        if (event.code == KeyCode.Enter || event.code == KeyCode.NumpadEnter) {
            //It is to prevent delete action when press enter key.
            event.preventDefault();
            return;
        }

        else if (event.code == KeyCode.Backspace) {
            if (this.objModel.length > 0 && term.length == 0) { //Need to check search term length as we may be editing it.
                this.deleteSelectedOption(true);

            }
        }
        else if (event.key == KeyCode.Shift) {
            if (this.instance.isPopupOpen())
                this.instance.dismissPopup(); //Dismiss ngbTypeahead pop-up in preparation for Shift-Tab sequence
        }
    }

    public handleKeyup(event: KeyboardEvent): void {
        super.handleKeyup(event);
        this.setIsInvalidClass(false);

        if (event.defaultPrevented)
            return;

        let strSearchTerm: string = (<any>(event.target)).value;

        if (event.code == KeyCode.Enter) {//We may be dealing with Tab key being released (key up) after a Shift-Tab sequence
            event.preventDefault();
            return;
        }


        let hasNotApplicable: boolean = this.objMetaData && this.objMetaData.NotApplicable != null && this.objMetaData.NotApplicable != "";
        let hasNotRepoValue: boolean = this.objMetaData && this.objMetaData.NotRepoValue != null && this.objMetaData.NotRepoValue != "";
        let hasUnknownValue: boolean = this.objMetaData && this.objMetaData.UnknownValue != null && this.objMetaData.UnknownValue != "";


        if (event.code == KeyCode.NumpadDivide || event.code == KeyCode.Slash) {

            if (hasNotApplicable) {

                let objItem: DrpDownOptions = this.options.find(v => v.strValue == this.objMetaData.NotApplicable);
                if (objItem) {
                    this.selectObject(objItem);
                }
            }

        }
        //Bind the NotReported value from metadata field.
        else if (event.code == KeyCode.NumpadAdd) {

            if (hasNotRepoValue) {
                let objItem: DrpDownOptions = this.options.find(v => v.strValue == this.objMetaData.NotRepoValue);

                if (objItem) {
                    this.selectObject(objItem);
                }
            }

        }
        //Bind the Unknown value from metadata field.
        else if (event.code == KeyCode.NumpadMultiply) {

            if (hasUnknownValue) {
                let objItem: DrpDownOptions = this.options.find(v => v.strValue == this.objMetaData.UnknownValue);

                if (objItem) {
                    this.selectObject(objItem);
                }
            }

        }

        if (event.code.match(KeyCode.Numpad) || event.code.match(KeyCode.Digit) || event.code.match(KeyCode.NumpadDecimal)) {
            //maxlength + 1 is to include dot(.) character for making selection
            if (strSearchTerm.length == this.maxlength) {
                let item: DrpDownOptions = this.options.find(v => v.strValue == strSearchTerm);
                if (!item) {
                    this.setIsInvalidClass(true);
                    this.objTextbox.nativeElement.value = null;
                    return;
                }
            }
            if (strSearchTerm.length == this.maxlength &&
                event.code.match(KeyCode.NumpadDecimal)) {
                //We are assuming that user input will have leading zeros, otherwise a simple string comparison is not sufficient
                //let item: DrpDownOptions = this.options.find(v => v.strValue == strSearchTerm);
                let item: DrpDownOptions = this.options.find(v => v.strValue == strSearchTerm);
                if (item) {
                    this.instance.dismissPopup();
                    this.selectObject(item);

                    if (this.maxInputs == this.objModel.length) {
                        this.instance.dismissPopup();
                        this.focusNextInput();
                    }
                }
                else {
                    this.objTextbox.nativeElement.value = null;
                    this.setIsInvalidClass(true);
                }
            }
            else if (strSearchTerm.length > this.maxlength) {
                this.objTextbox.nativeElement.value = null;
                this.setIsInvalidClass(true);
            }
        }
    }

    public onBlur(value: string): void {
        if (this.instance.isPopupOpen())
            this.instance.dismissPopup();
    }

    async deleteObjectItem(objItem: any) {
        this.onBlur(null);
        let blnConfirm: boolean = true;
        this.strHelper = objItem[this.strFieldName];
        UIElementBase.evtMarkFormDirty.emit();
        if (objItem.hasOwnProperty('IsSpecify')) {
            if (objItem.IsSpecify) {
                blnConfirm = await this._modalService.dialogPromise("This action will clear the Other(specify) value", "Are you sure to delete the code?");
                if (blnConfirm) {
                    if (!this.childOtherSpecify)
                        this._changeDetectorRef.detectChanges();

                    if (this.childOtherSpecify) {
                        this.childOtherSpecify.DeleteOtherSpecify(objItem[this.strFieldName] as number);
                    }
                }
            }
        }
        if (blnConfirm) {
            this.objModel.splice(this.objModel.indexOf(objItem), 1);
            this.bindObjMultiSelect.emit(this.objModel);
            AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, true, true);
            this.sortAndDisplaySelectedCodes();
        }
    }

    async deleteSelectedOption(blnFocus: boolean = false, blnSkipConfirmation: boolean = false) {

        this.onBlur(null);
        let blnConfirm: boolean = true;
        let objDeleted = this.objModel[this.objModel.length - 1]//this.objModel.pop();
        this.strHelper = objDeleted[this.strFieldName];
        if (objDeleted.hasOwnProperty('IsSpecify')) {
            if (objDeleted.IsSpecify) {
                if (blnSkipConfirmation) {
                    if (!this.childOtherSpecify)
                        this._changeDetectorRef.detectChanges();

                    if (this.childOtherSpecify) {
                        this.childOtherSpecify.DeleteOtherSpecify(objDeleted[this.strFieldName] as number);
                    }
                }
                else {
                    blnConfirm = await this._modalService.dialogPromise("This action will clear the Other(specify) value", "Are you sure to delete the code?");
                    if (blnConfirm) {
                        if (!this.childOtherSpecify)
                            this._changeDetectorRef.detectChanges();

                        if (this.childOtherSpecify) {
                            this.childOtherSpecify.DeleteOtherSpecify(objDeleted[this.strFieldName] as number);
                        }
                    }
                }
            }
        }
        if (blnConfirm) {
            this.objModel.pop();
            this.bindObjMultiSelect.emit(this.objModel);

            this.sortAndDisplaySelectedCodes();
        }

        if (blnFocus)
            this.objTextbox.nativeElement.focus();
    }

    //#endregion

    //#region Helper methods

    private setNextAvailableSeqNum(obj: any): number {

        let intSeqNum: number = 1;

        if (obj) {
            if (obj.hasOwnProperty('SeqNum')) {
                if (this.objModel.length === 0) {
                    obj['SeqNum'] = 1;
                }
                else {
                    let arrSeqNum: Array<number> = [];

                    this.objModel.forEach((item) => {
                        arrSeqNum.push(Number(item['SeqNum']));
                    });
                    let intMax = arrSeqNum.reduce(function (a, b) {
                        return Math.max(a, b);
                    });
                    if (intMax < 1)
                        obj['SeqNum'] = 1;
                    else
                        obj['SeqNum'] = Number(intMax) + 1;
                }
            }
        }

        return intSeqNum;
    }

    private selectObject(item: DrpDownOptions): void {
        this.strHelper = item.strText;
        if (this.objModel && Array.isArray(this.objModel)) {
            let cloneItem = Object.assign({}, this.objTemplateModel);
            cloneItem[this.strFieldName] = item.intValue;

            if (cloneItem.hasOwnProperty('AllowOneMultiple')) {
                cloneItem['AllowOneMultiple'] = item.AllowOneMultiple === null ? false : item.AllowOneMultiple;
            }

            if (cloneItem.hasOwnProperty('SeqNum')) {
                //We need to find last seqnum and assign the next number for the selected item.
                this.setNextAvailableSeqNum(cloneItem);
            }

            //Only for MOSS
            if (this.intMode == DBMode.MOSS &&
                this.maxInputs != undefined &&
                this.objModel.length == this.maxInputs &&
                ((this.strFieldName == 'RRF' && this.objMetaData.TableID == 'RRF_MTSS') ||
                (this.strFieldName == 'TFIF' && this.objMetaData.TableID == 'TFIF_MTSS') ||
                (this.objMetaData.TableID == 'Dri_Illness'))
            ){
                return
            }
            
            if (!this.objModel.find(i => i[this.strFieldName] == cloneItem[this.strFieldName]) && item.intValue > RBISDataValue.Blank) {

                if (cloneItem.hasOwnProperty('IsSpecify')) {
                    this.blnEnableOtherSpecify = item.isSpecify;
                    cloneItem['IsSpecify'] = item.isSpecify;
                }

                this.objModel.push(cloneItem);
                this.objTextbox.nativeElement.value = null;

                this.objCurrentValue = item.intValue.toString();

                if (item.isSpecify) {
                    this.onBlur(null);
                    //if (!this.componentOtherSpecify)
                    //    this._changeDetectorRef.detectChanges();
                    let subInit: Subscription = this.componentOtherSpecify.changes.subscribe(((queryList: QueryList<OtherSpecifyComponent>) => {
                        subInit.unsubscribe();
                        queryList.last.focusSpecifyText();
                    }).bind(this));
                }

                this.bindObjMultiSelect.emit(this.objModel);
                AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, true, true);

                let strValue: string = item.strValue;
                if (strValue == this.strUnknown || strValue == this.strNotReported || strValue == this.strNotApplicable) {
                    this.focusNextInput();
                }
                this.sortAndDisplaySelectedCodes();
            }

        }
    }

    /**
     *Ticket# 8117 - Helper method created to set custom attribute in multiselect input text to help to detect the field is coded or not.
     * Because multiselect input text is different than typeahead and not bind the value in html input. it has just functionality for searching the code.
     * This attribute is being used inside the FindNextEmptyControlIndex logic in base class to handle cursor move for multiselect component.
     * */
    private SetCustomDOMAttribute(): void {        
        if (this.objModel && this.objModel.length > 0)
            this.renderer.setProperty(this.objTextbox.nativeElement, 'attrblank', 'false');
        else
            this.renderer.setProperty(this.objTextbox.nativeElement, 'attrblank', 'true');
    }

    private sortAndDisplaySelectedCodes(): void {
        this.arrSelectedItems = [];
        this.lblCodesElem.nativeElement.innerText = "";

        this.SetCustomDOMAttribute();

        if (this.objModel && Array.isArray(this.objModel)) {

            if (this.blnEnableManualSort) {
                this.objModel.sort(ObjectUtil.sortSelectedItem('SeqNum'));
            }

            if (!this.blnHideNumericalCodePrefix && !this.blnHideElemCodes) {
                this.objModel.sort(ObjectUtil.sortSelectedItem(this.strFieldName));

                if (this.options) {
                    this.objModel.forEach(obj => {
                        let intValue: number = obj[this.strFieldName] as number;
                        let objSelection: DrpDownOptions = this.options.find(item => item.intValue == intValue);

                        if (objSelection)
                            this.arrSelectedItems.push(objSelection);
                        else
                            console.error('MultiselectComponent.sortAndDisplaySelectedCodes(): ' + this.strFieldName + ' ' + intValue.toString() + ' does not map to a DEFS option.');
                    });

                    if (this.arrSelectedItems) {
                        this.arrSelectedItems.sort(ObjectUtil.sortSelectedItem('intValue'));
                        this.lblCodesElem.nativeElement.innerText = this.arrSelectedItems.map(x => { return x.strValue }).join('. ');
                    }
                }
            }
        }
        else {

            this.objTextbox.nativeElement.placeholder = 'No Selected codes';
        }
    }

    //#endregion
    public BindElementEDTColor(intStateNum: number, arrStateStyles: usp_EDT_GetStylesByStateNum_Result[]): void { };

    public FilterFieldOptions(): Promise<DrpDownOptions[]> { return new Promise((resolve, reject) => { resolve(this.options); }); }

    public disableOrEnableComponent(isDisable: boolean): void {
        this.disabled = isDisable || UIElementBase.blnReadOnly;

        if (this.disabled) {
            this.renderer.setProperty(this.objTextbox.nativeElement, 'disabled', 'disabled');
        }
        else {
            //"disabled" HTML5 attribute is presence-based, not value-based. Setting it to false does not actually remove it, must set to null instead.
            //"disabled" HTML5 attribute should not be conused with the shadow-DOM "disabled" attribute, which can be set to false.
            this.renderer.setProperty(this.objTextbox.nativeElement, 'disabled', null);
        }
    }

    public setCurrentValue(strNewValue: string): boolean {
        //DrpDownOptions.strValue is a formatted value that may have leading zeros, which need to be ignored by lookup logic.
        //We're assuming that DrpDownOptions.intValue is set to NULL when not applicable (as opposed to 0, which may be a valid value)
        let objSelection: DrpDownOptions = this.options.find(x => x.intValue != null && x.intValue != undefined ? x.intValue.toString() == strNewValue : x.strValue == strNewValue);

        if (objSelection) {
            this.selectObject(objSelection);
            AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, true, false); //User initiated flag is set to false as we don't want focus to field focus to auto-advance
            this.sortAndDisplaySelectedCodes();
            return true;
        }
        else {
            console.log('Could not resolve value ' + strNewValue + ' to MultiselectComponent selection option')
            return false;
        }
    }

    public hideOrShowComponent(blnVisible: boolean): void {
        if (blnVisible !== undefined && blnVisible !== null)
            this.blnVisible = blnVisible;

        if (!this.blnVisible && !this.strVisibleDisplayStyle) //If about to change from visible to invisible, remember CSS display attribute value.
            this.strVisibleDisplayStyle = this.divContainer.nativeElement.style.display;

        this.divContainer.nativeElement.style.display = this.blnVisible ? this.strVisibleDisplayStyle : 'none';
    }

    ngAfterViewChecked(): void {
        if (this.printTextArea) {
            let el = this.printTextArea.nativeElement;
            this.printTextArea.nativeElement.style.resize = 'none';
            this.printTextArea.nativeElement.style.overflow = 'hidden';
            this.printTextArea.nativeElement.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight) + "px" : "30px";
        }
    }


    //Override inherited method
    public clearComponent(blnSkipAutoFillOnChange: boolean = true): void {
        if (this.objModel) {
            for (let i: number = this.objModel.length; i > 0; i--) {
                this.deleteSelectedOption(false, true);
                if (!blnSkipAutoFillOnChange)
                    AutofillService.OnChange(this, this.objModel, this.objMetaData.FieldID, true, true);
            }
        }
    }
    showAttributeInfo(): void {
        console.log(this.currentLabel.label);
        this._modalService.openAttributeInfoModal(this.options, this.currentLabel);
    }

}
