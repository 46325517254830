<form novalidate #fvpp="ngForm">
    <div class="pt-2" *ngIf="preCrash">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="InitTravLane"
                                   name="InitTravLane"
                                   typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS"
                                   strFieldName="InitTravLane"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="preCrash_DriVisObs"
                                     multiSelectPosition="right-top"
                                     strTableName="PreCrash_DriVisObs"
                                     strFieldName="ElementValue"
                                     [objTemplateModel]="objPreCrashDriVisObsTemplate"
                                     [objModel]="preCrash.PreCrash_DriVisObs"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="preCrash_DriAvoidManvr"
                                     multiSelectPosition="right-top"
                                     strTableName="PreCrash_DriAvoidManvr"
                                     strFieldName="ElementValue"
                                     [objTemplateModel]="objPreCrashDriAvoidManvrTemplate"
                                     [objModel]="preCrash.PreCrash_DriAvoidManvr"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>-->
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="preCrash_DriDistract"
                                     multiSelectPosition="right-top"
                                     strTableName="PreCrash_DriDistract"
                                     strFieldName="ElementValue"
                                     [objTemplateModel]="objPreCrashDriDistractTemplate"
                                     [objModel]="preCrash.PreCrash_DriDistract"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="vehManvr"
                                   name="vehManvr"
                                   typeAheadPosition="right-top"
                                   [objModel]="preCrash"
                                   strTableName="PreCrash"
                                   strFieldName="VehManvr"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="criticalEventCat"
                                   name="criticalEventCat"
                                   typeAheadPosition="right-top"
                                   [objModel]="preCrash"
                                   strTableName="PreCrash"
                                   strFieldName="CriticalEventCat"
                                   strRefreshedLookupTableName="CriticalEvent" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="criticalEvent"
                                   name="criticalEvent"
                                   typeAheadPosition="right-top"
                                   [objModel]="preCrash"
                                   strTableName="PreCrash"
                                   strFieldName="CriticalEvent"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   strDependentFieldName="CriticalEventCat" strFilterCondition=" AND Category= "
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="crshAvoid"
                                   name="crshAvoid"
                                   typeAheadPosition="right-top"
                                   [objModel]="preCrash"
                                   strTableName="PreCrash"
                                   strFieldName="CrshAvoid"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="preImpStab"
                                   name="preImpStab"
                                   typeAheadPosition="right-top"
                                   [objModel]="preCrash"
                                   strTableName="PreCrash"
                                   strFieldName="PreImpStab"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="preImpLoc"
                                   name="preImpLoc"
                                   typeAheadPosition="right-top"
                                   [objModel]="preCrash"
                                   strTableName="PreCrash"
                                   strFieldName="PreImpLoc" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="crashType"
                                   name="crashType"
                                   typeAheadPosition="right-top"
                                   [objModel]="preCrash"
                                   strTableName="PreCrash"
                                   strFieldName="CrashType" [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div  [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="CritReasonCritEvent" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="CritReasonCritEvent"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>

