<div  class="border-dark border-start border-bottom border-end px-2">
    <ul class="nav nav-tabs border-dark bg-white">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./strikingroadway"]'
               [ngClass]="{'bg-light': linkstrikingPrecrashRoadway.isActive !== true }"
               [routerLinkActive]="['active']" #linkstrikingPrecrashRoadway="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkstrikingPrecrashRoadway.isActive === true, 'fw-bold' : linkstrikingPrecrashRoadway.isActive === true}">
                    Roadway</span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingprecrash"]'
               [ngClass]="{'bg-light': linkstrikingPrecrashPrecrash.isActive !== true }"
               [routerLinkActive]="['active']" #linkstrikingPrecrashPrecrash="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkstrikingPrecrashPrecrash.isActive === true, 'fw-bold' : linkstrikingPrecrashPrecrash.isActive === true}">
                    Precrash</span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingprecrashother"]'
               [ngClass]="{'bg-light': linkstrikingPrecrashOther.isActive !== true }"
               [routerLinkActive]="['active']" #linkstrikingPrecrashOther="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkstrikingPrecrashOther.isActive === true, 'fw-bold' : linkstrikingPrecrashOther.isActive === true}">
                    Other</span>
                </span>
            </a>
        </li>
    </ul>    
    <!--here we generate and show Vehicle Precrash's-children html's components-->
    <router-outlet></router-outlet>
</div>
