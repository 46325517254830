import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';

import { BaseComponent } from 'src/app/helper/basecomponent';

import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { VCF_MTSS } from 'src/app/models/vcf-mtss';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { Element_Specify } from 'src/app/models/element-specify';

@Component({
    selector: 'app-vehicle-vehicle-view',
    templateUrl: './vehicle-vehicle-view.component.html',
    styleUrls: ['./vehicle-vehicle-view.component.css']
})
export class VehicleVehicleViewComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh; 

    objInstanceElementSpecify: Element_Specify;
    public objVCFTemplate: VCF_MTSS;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _autofillService: AutofillService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    )
    {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) {
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
        
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);
        this.objVCFTemplate = ObjectUtil.InstantiateVehicleCF(this.veh);
        if (!this.veh.VCF_MTSS)
            this.veh.VCF_MTSS = new Array<VCF_MTSS>();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
