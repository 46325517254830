import { Component, OnInit, Input, ViewChild, Output, ElementRef, Renderer2, OnChanges, SimpleChanges, ChangeDetectorRef, AfterViewInit, EventEmitter, AfterContentInit, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { Observable, Subject, merge, Subscription, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from 'src/app/services/util.service';
import { Element_Specify } from 'src/app/models/element-specify';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { Acc } from 'src/app/models/acc';
import { ModalService } from 'src/app/services/modal.service';
import { CaseService } from 'src/app/services/case.service';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { AutofillService } from 'src/app/services/autofill.service';
import { NgForm, ControlContainer } from '@angular/forms';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ActivatedRoute } from '@angular/router';
import { CaseStatus, KeyCode } from 'src/app/models/enums/app.enums';
import { RbisUser } from '../../models/rbis-user';

@Component({
    selector: 'app-other-specify',
    templateUrl: './other-specify.component.html',
    styleUrls: ['./other-specify.component.css'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]

})
export class OtherSpecifyComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {



    @Input() intElementValue: number;
    @Input() strTableName: string = "";
    @Input() strFieldName: string = "";
    @Input() intSeqNum: number = 0;     //Applicable to one-to-many fields only, ex: Safety Equipment. For simple columns, this is always 0.
    @Input() blnIsEnabled: boolean = false;
    @Input() objOtherSpecifyTemplate: Element_Specify;
    @Input() strCssClass: string = "";
    //@Input() blnIsMultiSelect: boolean = true;

    public objItem: Element_Specify;
    blnEmptyWarning: boolean = false;
    objStateOtherSpecify: Array<Element_Specify> = [];
    acc: Acc;

    focusOtherSpecify$ = new Subject<string>();
    clickOtherSpecify$ = new Subject<string>();

    sbsOtherSpecifyInitialize = new BehaviorSubject<boolean>(false);

    @ViewChild('txtOtherSpecify', { static: false }) txtOtherSpecify: ElementRef;
    @ViewChild('instanceOtherSpecify', { static: false }) instanceOtherSpecify: NgbTypeahead;
    @ViewChild('instanceToolTip', { static: false }) instanceToolTip: NgbTooltip;

    blnInitComponent: boolean = true;
    isReadOnly: boolean = false;

    constructor(private _utilService: UtilService,
        protected renderer: Renderer2,
        private _sharedService: SharedDataService,
        private _changeDetectorRef: ChangeDetectorRef,
        protected _caseService: CaseService,
        protected _autoFillService: AutofillService
    ) {
        this.acc = _caseService.acc;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.blnIsEnabled && this._caseService.acc) {
            this._changeDetectorRef.detectChanges();
            this.ngOnInit();
        }
    }

    ngAfterViewInit(): void {

    }

    ngAfterViewChecked(): void {

    }

    ngOnInit(): void {
        if (this.blnIsEnabled && this._caseService.acc) {

            this.blnEmptyWarning = false;
            this.setInvalidClass(false);

            this.RefreshAllStateElementSpecify();
            this.bindOtherSpecifyElement();
            this.setReadOnly();
        }
    }

    private RefreshAllStateElementSpecify(): void {
        if (this._caseService.acc) {
            this._utilService.GetStateAllElementOtherSpecifyValues(this._caseService.acc.StateNum).toPromise()
                .then((arrStateSpecify: Element_Specify[]) => {
                    this.objStateOtherSpecify = arrStateSpecify.filter(i => i.TableID == this.strTableName && i.FieldID == this.strFieldName);
                });
        }
    }

    private bindOtherSpecifyElement(): void {
        if (this.objOtherSpecifyTemplate != undefined) { //Moh
            this.objItem = this._caseService.acc.Element_Specify.find(i => i.TableID == this.strTableName
                && i.FieldID == this.strFieldName
                && i.ElementValue == this.intElementValue
                && i.PNumber == this.objOtherSpecifyTemplate.PNumber
                && i.VNumber == this.objOtherSpecifyTemplate.VNumber);
        }
    }

    formatterOtherSpecify(result: Element_Specify): string {
        return result.SpecifyValue;
    }

    searchOtherSpecify = (textOther$: Observable<string>) => {

        const debouncedText$ = textOther$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.clickOtherSpecify$.pipe(filter(() => !this.instanceOtherSpecify.isPopupOpen()));
        const inputFocus$ = this.focusOtherSpecify$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term => (term === '' ? this.objStateOtherSpecify
                : this.objStateOtherSpecify.filter(v => v.SpecifyValue.toLowerCase().indexOf(term.toLowerCase()) > -1)))
        );
    }

    protected setInvalidClass(enable: boolean): void {

        this.renderer.setProperty(this.txtOtherSpecify.nativeElement, "is-invalid", enable);
    }

    public DeleteOtherSpecify(intValue: number): void {

        if (this._caseService.acc.Element_Specify) {
            let objDeleted = this._caseService.acc.Element_Specify.find(i => i.TableID == this.strTableName
                && i.PNumber == this.objOtherSpecifyTemplate.PNumber
                && i.VNumber == this.objOtherSpecifyTemplate.VNumber
                && i.FieldID == this.strFieldName
                && i.ElementValue == intValue);
            if (objDeleted) {
                this._caseService.acc.Element_Specify.splice(this._caseService.acc.Element_Specify.indexOf(objDeleted), 1);
                this.RefreshAllStateElementSpecify();
            }
        }
    }

    public focusSpecifyText(blnCheckElem: boolean = false): void {
        if (!this.blnIsEnabled)
            return;

        if (this.txtOtherSpecify) {
            if (blnCheckElem) {
                if (this.txtOtherSpecify.nativeElement.value == "") {
                    this.setInvalidClass(true);
                    this.blnEmptyWarning = true;
                    this.renderer.selectRootElement(this.txtOtherSpecify.nativeElement).focus();
                    this.txtOtherSpecify.nativeElement.blur();
                    return;
                }
                return;
            }
            this.renderer.selectRootElement(this.txtOtherSpecify.nativeElement).focus();
        }
    }

    public OnModelChange($event: any): void {
        if (!this.objItem) {
            this.objItem = this.objOtherSpecifyTemplate;
            this.objItem.TableID = this.strTableName;
            this.objItem.FieldID = this.strFieldName;
            this.objItem.ElementValue = this.intElementValue;
        }
        if (this.instanceToolTip.isOpen()) {
            this.instanceToolTip.close();
        }

        if (typeof ($event) === 'string') {
            this.instanceToolTip.ngbTooltip = $event;
            this.objItem.SpecifyValue = $event;
            //this.instanceToolTip.open();
        }
        else if (typeof ($event) === 'object') {
            this.instanceToolTip.ngbTooltip = $event.SpecifyValue;
            this.objItem.SpecifyValue = $event.SpecifyValue;
            //this.instanceToolTip.open();
        }
        if (!this.instanceToolTip.isOpen()) {
            this.instanceToolTip.open();
        }
    }
    public blurOtherSpecify(event): void {

        if (!this.blnIsEnabled)
            return;

        let value: string = event.srcElement.value.trim();

        this.setInvalidClass(false);
        this.blnEmptyWarning = false;


        let item = this._caseService.acc.Element_Specify.find(i => i.TableID == this.strTableName
            && i.FieldID == this.strFieldName
            && i.PNumber == this.objOtherSpecifyTemplate.PNumber
            && i.VNumber == this.objOtherSpecifyTemplate.VNumber
            && i.ElementValue == this.intElementValue);

        if (item) {
            let intIndex: number = this._caseService.acc.Element_Specify.indexOf(item);
            this._caseService.acc.Element_Specify[intIndex].SpecifyValue = value;
            if (value == "") {
                this.setInvalidClass(true);
                this.blnEmptyWarning = true;
                return;
            }
            return;
        }
        else {

            let objElementSpecify: Element_Specify = {} as Element_Specify;
            objElementSpecify.Acc = this._caseService.acc;
            objElementSpecify.AccID = this.objOtherSpecifyTemplate.AccID;
            objElementSpecify.PNumber = this.objOtherSpecifyTemplate.PNumber;
            objElementSpecify.VNumber = this.objOtherSpecifyTemplate.VNumber;
            objElementSpecify.FieldID = this.strFieldName;
            objElementSpecify.TableID = this.strTableName;
            objElementSpecify.ElementValue = this.intElementValue;
            objElementSpecify.SpecifyValue = value;
            this._caseService.acc.Element_Specify.push(objElementSpecify);

            if (value == "") {
                this.setInvalidClass(true);
                this.blnEmptyWarning = true;
            }
        }

        this.RefreshAllStateElementSpecify();
        console.log("Acc Other Specify Object", this._caseService.acc.Element_Specify);

    }

    public handleKeydown(event: KeyboardEvent): void {

        if (event.key == KeyCode.PageDown || event.key == KeyCode.Enter) {
            this.txtOtherSpecify.nativeElement.blur();
        }
    }

    private async setReadOnly() {
        let objUser: RbisUser = await this._utilService.GetUserPromise();
        this.isReadOnly = objUser.blnReadOnly || this.acc.Deleted
            || (this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review || this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review_Complete)

        if (this.isReadOnly) {
            this.instanceOtherSpecify.dismissPopup();

            this.txtOtherSpecify.nativeElement.setAttribute('disabled', 'disabled');
            //this.renderer.setAttribute(this.txtOtherSpecify.nativeElement, 'disabled', 'disabled'); //TODO: may need to upgrade to use renderer2
        } else {
            this.txtOtherSpecify.nativeElement.removeAttribute('disabled');
            //this.renderer.removeAttribute(this.txtOtherSpecify.nativeElement, 'disabled');  //TODO: may need to upgrade to use renderer2
        }
    }

}
