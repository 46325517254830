import { Injectable } from '@angular/core';
import { GenericService } from './generic.service'
import { VinRequestModel } from '../models/VinRequestModel';

@Injectable({
    providedIn: 'root'
})

export class VinrequestService {

    constructor(private _baseService: GenericService<any>) { }


    //VIN Request Related

    saveVinRequest(model: VinRequestModel) {
        this._baseService.actionURL = "api/vehicle/SaveVINReqeust/";
        return this._baseService.updateEntity(model).toPromise();
    }

    updateDateVinRequest() {

    }

    searchVINRequest() {

    }

    getVINRequest(accid:number, vNmber:number, seqNumber: number) {
        this._baseService.actionURL = "api/vehicle/GetVINReqeust/" + accid + "/" + vNmber+ "/"+ seqNumber;
        return this._baseService.getData();
    }

    searchVinRequest(caseNum: number = -1, status: number = -1, state: number = -1, createdBy: string ="all") {
        this._baseService.actionURL = "api/vehicle/SearchVinRequest/" + caseNum + "/" + status + "/" + state + "/" + createdBy;
        return this._baseService.getData();
    }

    getVinStatus() {
        this._baseService.actionURL = "api/vehicle/GetVinStatus/";
        return this._baseService.getData();
    }
    
    //End of VIN Request Related
}
