import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modals-weight-calculator',
    templateUrl: './modals-weight-calculator.component.html',
    styleUrls: ['./modals-weight-calculator.component.css']
})

export class ModalsWeightCalculatorComponent implements OnInit {

    private modal: NgbModalRef;
    result: any;
    value: any;
    outputKilograms: any;
    blnShowValidationMessage: boolean = false;
    @Output() submitWeightValue = new EventEmitter();

    constructor(
        private _modalService: NgbModal
    ) { }

    ngOnInit() {
    }

    public open(content, options: NgbModalOptions) {

        this.modal = this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'light-blue-backdrop', backdrop: 'static', keyboard: false });       
        this.blnShowValidationMessage = false;
        this.result = null;
        this.value = null;
    }

    unitChange() {
       this.result = this.value / 2.2046;
        this.outputKilograms = this.result;
    }

    onSubmit() {
       if (this.value == 'undefined' || this.value == null) {
           this.blnShowValidationMessage = true;
           return;
        }
        else {
            this.submitWeightValue.emit(Math.round(this.result));
           this.modal.close();
        }
    }

    cancel() {
        this.blnShowValidationMessage = false;
        this.result = null;
        this.value = null;
    }
}
    
