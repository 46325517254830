<div class="container-fluid" *ngIf="acc">
    <div class="row">
        <div class="col-12">
            <div class="card border-dark">

                <div class="card-header">
                    <div class="row">
                        <div class="my-auto">
                            <div class="card-title my-auto pl-3">
                                <h3 *ngIf="intMode == 64 || intMode == 128">Uploaded files for Case #: {{ acc.Casenum  | number : '4.0-0' | replaceTextPipe : ',' : '' }}</h3>
                                <h3 *ngIf="intMode == 256">Uploaded files for Case #: {{ acc.Acc_SS.MOSSCasenum }}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-10 pr-2">
                            <div class="row mb-2">
                                <div class="my-auto">
                                    <div class="my-auto pl-3" *ngIf="CaseFiles?.length > 0 && (blnIsReadOnly === false && !uploadDocumentService.blnAllDownloadActive)">
                                        Upload Files by dragging them into the table
                                    </div>
                                </div>
                                <div class="ml-auto my-auto pr-3">
                                    <button type="button" class="btn btn-light border-secondary" (click)="fileInput.click()"
                                            [disabled]="blnIsReadOnly  || blnIsCaseDeleted || uploadDocumentService.blnAllDownloadActive || uploadDocumentService.blnUploadActive">
                                        Browse file
                                    </button>
                                </div>
                                <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" multiple>
                            </div>
                            <div *ngIf="uploadDocumentService.blnUploadActive == false && CaseFiles && CaseFiles?.length == 0"
                                 class="table-responsive border-dark mb-4">
                                <table class="table table-striped table-hover table-sm">
                                    <thead class="bg-tb-header border-dark border-left border-right">
                                        <tr>
                                            <th class="border-bottom border-top border-dark">File name</th>
                                            <th class="border-bottom border-top border-dark">Uploaded By</th>
                                            <th class="border-bottom border-top border-dark">Uploaded Date</th>
                                            <th class="border-bottom border-top border-dark">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class='border-bottom border-left border-right border-dark' dragDrop (onFileDropped)="uploadFile($event)">
                                        <tr *ngIf="blnIsReadOnly === false  && blnIsCaseDeleted === false" [ngClass]="{'h100' : CaseFiles.length == 0 }">
                                            <td colspan="4" class="align-middle" (click)="fileInput.click()">
                                                <div class="d-flex justify-content-center">Please click or drop file here to Upload</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="(blnIsReadOnly === true || blnIsCaseDeleted === true)" class="h-100">
                                            <td colspan="4" class="align-middle">
                                                <div class="d-flex justify-content-center" *ngIf="blnIsCaseDeleted">Case Deleted</div>
                                                <div class="d-flex justify-content-center" *ngIf="blnIsReadOnly">Read Only Mode</div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="uploadDocumentService.blnUploadActive == false && CaseFiles?.length > 0"
                                 class="table-responsive border-dark mb-4">
                                <table class="table table-striped table-hover table-sm">
                                    <thead class="bg-tb-header border-dark border-left border-right">
                                        <tr>
                                            <th class="border-bottom border-top border-dark">File name</th>
                                            <th class="border-bottom border-top border-dark">Uploaded By</th>
                                            <th class="border-bottom border-top border-dark">Uploaded Date</th>
                                            <th class="border-bottom border-top border-dark">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class='border-bottom border-left border-right border-dark' dragDrop (onFileDropped)="uploadFile($event)">
                                        <tr *ngFor="let file of CaseFiles; let i = index">
                                            <td class="text-wrap align-middle pointer">
                                                <button class="btn btn-link pl-2" (click)="DownloadCaseFile(file)" *ngIf="file?.ID && uploadDocumentService.blnAllDownloadActive == false">
                                                    {{ file.FileName }}
                                                </button>
                                                <span class="" *ngIf="file?.ID && uploadDocumentService.blnAllDownloadActive">{{ file.FileName }}</span>
                                            </td>
                                            <td class="text-wrap align-middle">
                                                <span class="">{{ file.UserID | username | async}}</span>
                                            </td>
                                            <td class="text-wrap align-middle">
                                                <span class="">{{ file.UploadDate | date: 'short'}}</span>
                                            </td>
                                            <td class="align-middle">
                                                <span *ngIf="!blnIsReadOnly && !blnIsCaseDeleted && !uploadDocumentService.blnAllDownloadActive">
                                                    <i class="far fa-trash-alt fa-lg text-danger pointer" (click)="DeleteCaseFile(file?.ID)" *ngIf="file?.ID"></i>
                                                </span>
                                                <button *ngIf="uploadDocumentService.blnAllDownloadActive" class="btn btn-primary" disabled>
                                                    <span class="spinner-grow spinner-grow-sm"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="uploadDocumentService.blnUploadActive == true && uploadDocumentService.arrUploadingCaseFiles?.length > 0"
                                 class="table-responsive border-dark mb-4">
                                <table class="table table-striped table-hover table-sm">
                                    <thead class="bg-tb-header border-dark border-left border-right">
                                        <tr>
                                            <th class="border-bottom border-top border-dark">File name</th>
                                            <th class="border-bottom border-top border-dark">Uploaded By</th>
                                            <th class="border-bottom border-top border-dark">Uploaded Date</th>
                                            <th class="border-bottom border-top border-dark">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class='border-bottom border-left border-right border-dark'>
                                        <tr *ngFor="let file of uploadDocumentService.arrUploadingCaseFiles; let i = index">
                                            <td class="text-wrap align-middle pointer">
                                                <span class="">{{ file.FileName }}</span>
                                            </td>
                                            <td class="text-wrap align-middle">
                                                <div class="spinner-grow text-success" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </td>
                                            <td class="text-wrap align-middle">
                                                <div class="spinner-grow text-success" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="spinner-grow text-success" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card" *ngIf="uploadDocumentService.blnUploadActive == true  && uploadDocumentService.arrUploadingCaseFiles?.length == 0">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                                         aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Preparing Files to Upload...</div>
                                </div>
                            </div>
                            <div class="card" *ngIf="acc.StateCaseID">
                                <div class="card-header">
                                    PAR data received through EDT
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div class="form-group">
                                                <label for="btnCaseFiles_OnGetFromStateCaseViewer">File Name</label>
                                            </div>
                                            <button id="btnCaseFiles_OnGetFromStateCaseViewer" type="button" class="btn btn-link text-left pl-0" (click)="OnGetFromStateCaseViewer(acc.EarlyNotify[0])" style="text-decoration: none" [disabled]="!acc.EarlyNotify[0].StateCaseNum">
                                                {{acc.StateCaseID}}
                                            </button>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div class="form-group">
                                                <label>Uploaded Date</label>
                                                <div>
                                                    {{acc.EarlyNotify[0]?.DateReported | date : 'short'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 pl-0">
                            <div class="border-left border-secondary pl-2">
                                <div class="form-group">
                                    <button id="btnCaseFiles_OnBackToCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="OnBackToCase($event, accid)">
                                        <span class="text-dark fw-bold">Back to the Case</span>
                                    </button>
                                </div>

                                <div class="form-group">
                                    <button id="btnCaseFiles_OnCloseCase" class="btn btn-sm btn-light border-secondary btn-block w-100" (click)="OnCloseCase()">
                                        <span class="text-dark fw-bold">Close Case</span>
                                    </button>
                                </div>

                                <div class="form-group">
                                    <button *ngIf="blnEnableDownloadAll" id="btnCaseFiles_OnDownloadAll" class="btn btn-sm btn-primary border-primary btn-block w-100" (click)="onDownloadAll()" [disabled]="uploadDocumentService.blnAllDownloadActive == true || uploadDocumentService.blnUploadActive == true">
                                        <span class="{{uploadDocumentService.blnAllDownloadActive == true ? 'spinner-border spinner-border-sm' : 'text-dark fw-bold'}}"></span>
                                        {{uploadDocumentService.blnAllDownloadActive == true ? 'Downloading All Files....' : 'Download All Files'}}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
