<form novalidate #fcnm="ngForm">
    <div *ngIf="acc" id="accicle-other" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">
            <!--<div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-textfield id="accAvoidEquipAssmntNotes" [disableLabel]="false"
                                   [blnIsNumber]="false" [intTextAreaRows]="10"
                                   [objModel]="acc.Acc_SS" [(objCurrentValue)]="AvoidEquipAssmntNotes"
                                   [blnFormatWithLeading]="false"
                                   strTableName="Acc_SS" strFieldName="AvoidEquipAssmntNotes"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-textfield>
                </div>
            </div>-->
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accSchlBusTrolyRel" name="accSchlBusTrolyRel" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="SchlBusTrolyRel"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accSideWlkPrctUse" name="accSideWlkPrctUse" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="SideWlkPrctUse"
                                   [printOnly]="printOnly"  [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accTurnTrafChnlPrscn" name="accTurnTrafChnlPrscn" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="TurnTrafChnlPrscn"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accPrscnOfMedium" name="accPrscnOfMedium" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="PrscnOfMedium"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accPedsignal" name="accPedsignal" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="Pedsignal"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accBikeLane" name="accBikeLane" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="BikeLane"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accPrkingLane" name="accPrkingLane" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="PrkingLane"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accPrkingLnVehPresnt" name="accPrkingLnVehPresnt" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="PrkingLnVehPresnt"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accSpdBmpTrafClmPrscn" name="accSpdBmpTrafClmPrscn" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="SpdBmpTrafClmPrscn"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accVisofTrafNMPtOfVw" name="accVisofTrafNMPtOfVw" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="VisofTrafNMPtOfVw"
                                   [printOnly]="printOnly"  [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accTrvLnLocOfImp" name="accTrvLnLocOfImp" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="TrvLnLocOfImp"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="accDistFromCurb" name="accDistFromCurb" typeAheadPosition="right-top"
                                   [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="DistFromCurb"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label class="my-auto" inputName="DistFromCurb" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-distance-calculator #distanceElement [objName]="'DistFromCurb'" (submitDistanceValue)="setDistanceValue($event)" *ngIf="!blnReadOnly"></app-modals-distance-calculator>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-typeahead id="accDistFromCurb" name="accDistFromCurb" typeAheadPosition="right-top"
                                               [objModel]="acc.Acc_SS" strTableName="Acc_SS" strFieldName="DistFromCurb"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData"
                                               disableLabel="true"
                                               [blnAllowFreeText]="true"                                               
                                               [maxlength]="5"
                                               [blnFormatWithLeading]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
