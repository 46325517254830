import { Pipe, PipeTransform } from '@angular/core';

import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { UtilService } from 'src/app/services/util.service';
import { TableFieldElements } from 'src/app/models/table-field-elements';

import { Observable, Subscription } from 'rxjs';


@Pipe({
    name: 'valueToDescription',
    //pure: false //TODO: instead of making this pipe impure, try using async pipe when translating dependent field that requires trip to server
})

export class ValueToDescriptionPipe implements PipeTransform {
    //perviousReturnValue: string = null; //What was the reasoning behind making this pipe maintain a state?

    constructor(private _utilService: UtilService) { }

    public async transform(value: any,
        strDefsLookupTblName: string,
        //hasCondition: boolean = false,
        conditionFilter: string = "",
        conditionParameter: string = "",
        hasCodePrefix: boolean = false): Promise<string> {

        let returnValue: string;
        let intValue: number = parseInt(value);
        let dropDownOptions: DrpDownOptions[];
        let option: DrpDownOptions;

        //if (value !== this.perviousReturnValue) {

        //this.perviousReturnValue = value;

        if (intValue !== null && intValue !== undefined && intValue > -1 && intValue !== NaN) {

            if (conditionFilter !== "") {
                dropDownOptions = await this._utilService.GetDrpDownListOptions(strDefsLookupTblName, conditionFilter + conditionParameter).toPromise();
            }
            else {
                dropDownOptions = await this._utilService.formDrpDownOptionShare.value;
                dropDownOptions = dropDownOptions.filter(i => i.tblName == strDefsLookupTblName);
            }

            option = dropDownOptions.find(x => x.intValue == intValue);
            returnValue = (option !== null && option !== undefined) ? (hasCodePrefix ? option.displayText : option.strText) : intValue.toString();
        }
        else
            returnValue = '';
        //}

        return returnValue;
    }
}
