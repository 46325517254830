import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CaseFilesLists } from 'src/app/models/case-files-lists.model';
import { GenericService } from 'src/app/services/generic.service';
import { ModalService } from './modal.service';
import { SharedDataService } from './shared-data.service';
import { JsonFileWrapper } from '../models/JsonFileWrapper';
import { DBMode } from '../models/enums/app.enums';
import { Acc } from '../models/acc';
import { CaseFile_Locked } from '../models/CaseFile_Locked';
import { ObjectCaseFile } from '../models/ObjectCaseFile';
import { CaseFileDTO } from '../models/CaseFileDTO';
import { CaseService } from './case.service';

@Injectable()
export class CaseFilesListsService {
    private subject = new Subject<any>();


    sendMessage(message: string) {
        this.subject.next({ text: message });
    }

    clearMessage() {
        this.subject.next(null);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    formCaseFileData: CaseFilesLists[];
    public blnAllDownloadActive: boolean = false;
    public blnUploadActive: boolean = false;
    arrUploadingCaseFiles: CaseFilesLists[] = [];
    blnPARorEDTFile: boolean = false;


    constructor(private http: HttpClient,
        private _baseService: GenericService<any>,
        private _modalService: ModalService,
        private _sharedService: SharedDataService,
        private _caseService: CaseService
    ) { }

    getCaseFilesById(intAccID: number) {
        this._baseService.actionURL = "api/CaseFile/GetCaseFileByID/" + intAccID;
        if (intAccID != undefined) {
            return this._baseService.getData().pipe().toPromise();
        }
    }

    getUserList() {
        this._baseService.actionURL = "api/util/GetUser"
        return this._baseService.getData().toPromise();
    }

    uploadCaseFile(intAccId: number, objFormData: FormData): Promise<CaseFileDTO> {
        try {
            this._baseService.actionURL = '';
            return this.http.post<CaseFileDTO>(this._baseService.actionURL + "/api/CaseFile/CaseFile_Insert/" + intAccId.toString(), objFormData).toPromise();
        } catch (ex) {
            this.blnUploadActive = false;
            console.error(ex);
            this._modalService.setMessage('Failed Upload Files.', 'error');
        }
    }

    uploadCaseFileToObjectStore(intAccId: number, objCaseFiles: ObjectCaseFile[]): Promise<CaseFilesLists[]> {
        try {
            this._baseService.actionURL = '';
            return this.http.post<CaseFilesLists[]>(this._baseService.actionURL + "/api/CaseFile/InsertFileToObjectStore/" + intAccId.toString(), objCaseFiles).toPromise();
        } catch (ex) {
            this.blnUploadActive = false;
            console.error(ex);
            this._modalService.setMessage('Failed Upload Files.', 'error');
        }
    }

    downloadCaseFile(recordId: number, intAccId: number, intYearUploaded) {
        this._baseService.actionURL = '';
        this._baseService.actionURL = 'api/CaseFile/DownloadDocumentFileByID/' + recordId + "/" + intYearUploaded;
        if (recordId != undefined) {
            return this._baseService.getData().toPromise();
        }
    }

    downloadDocument(fileId: number) {
        this._baseService.actionURL = 'api/CaseFile/DownloadDocumentByID/' + fileId;
        if (fileId != undefined) {
            return this._baseService.getData().toPromise();
        }
    }

    handlePromiseError(error: Response) {
        console.error('PromiseError==>', error);
        throw (error);
    }


    deleteCaseFile(recordId: number, intAccId: number): Promise<any> {
        this._baseService.actionURL = '';
        let strRecordId: string = recordId ? recordId.toString() : "";
        let strAccId: string = intAccId ? intAccId.toString() : "";
        return this.http.delete(this._baseService.actionURL + "/api/CaseFile/DeleteCaseFile/" + strRecordId + "/" + strAccId).toPromise();
    }

    async downloadAll(acc: Acc): Promise<void> {
        this.LockedCaseFile(acc.AccID);
        this._baseService.actionURL = 'api/CaseFile/DownloadAllByAccId/' + acc.AccID;
        const strBufferBase64: JsonFileWrapper = await this._baseService.getData().toPromise();
        if (strBufferBase64 == null) {
            this.blnAllDownloadActive = false;
            this._modalService.alertPromise('Buffer object(JsonFileWrapper) returns null')
            return;
        }
        if (strBufferBase64.fileSize > 0) { //0 is the empty zip file size

            const strBuffer: string = atob(strBufferBase64.file);
            const arrBytes: Uint8Array = new Uint8Array(strBuffer.length);

            for (let i = 0; i < strBuffer.length; i++) {
                arrBytes[i] = strBuffer.charCodeAt(i);
            }
            const objBlob: Blob = new Blob([arrBytes], { type: strBufferBase64.mime.trim() });
            const strUrl = URL.createObjectURL(objBlob);

            const anchor = document.createElement("a");
            anchor.download = strBufferBase64.fileName.trim();
            anchor.href = strUrl;
            anchor.click();
            this.UnlockCaseFile(acc.AccID);
            this._modalService.setMessage('Files downloaded successfully.', 'info');

        } else {
            this.blnAllDownloadActive = false;
            this._modalService.alertPromise('There are no files to download.')
        }
    }

    public async GetCaseFileLock(intAccID: number): Promise<void> {
        this._baseService.actionURL = 'api/CaseFile/GetCaseFileLock/' + intAccID.toString();
        this._baseService.getDataPromise().then((obj: CaseFile_Locked) => {
            if (obj) {
                if (obj.AccID == intAccID) {
                    this._caseService.sbjUploadActive.next(obj.IsUploadActive);
                    this.blnAllDownloadActive = obj.IsDownloadActive;
                    this.blnUploadActive = obj.IsUploadActive;
                }
                else {
                    this._caseService.sbjUploadActive.next(false);
                    this.blnAllDownloadActive = false;
                    this.blnUploadActive = false;
                }
            }
            else {
                this._caseService.sbjUploadActive.next(false);
                this.blnAllDownloadActive = false;
                this.blnUploadActive = false;
            }
        });
    }

    public LockedCaseFile(accId: number) {
        this.blnAllDownloadActive = true;
        this._baseService.actionURL = 'api/CaseFile/LockedCaseFile/' + accId;
        this._baseService.getData().toPromise();
    }

    public UnlockCaseFile(accId: number) {
        this.blnUploadActive = false;
        this.blnAllDownloadActive = false;
        this._baseService.actionURL = 'api/CaseFile/UnlockCaseFile/' + accId;
        this._baseService.getData().toPromise();

    }
}
