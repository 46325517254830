<div class="row" (window:keydown)="searchEN($event)">
    <div class="col-xl">
        <div class="card border-dark">

            <div class="card-header text-dark">
                <div class="row">
                    <div class="col">
                        <div class="d-flex">
                            <div class="my-auto">
                                <button class="btn btn-sm btn-secondary" *ngIf="!isENScreenerCollapsed" (click)="isENScreenerCollapsed = !isENScreenerCollapsed" [attr.aria-expanded]="!isENScreenerCollapsed" aria-controls="collapseENScreener">
                                    <i class="fas fa-minus"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-secondary" *ngIf="isENScreenerCollapsed" (click)="isENScreenerCollapsed = !isENScreenerCollapsed" [attr.aria-expanded]="!isENScreenerCollapsed" aria-controls="collapseENScreener">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                            <div class="my-auto">
                                <div class="card-title my-auto pl-2">
                                    Case Screener
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body py-0 bg-light margin-border">
                <div class="row pt-2 border-bottom border-dark" id="collapseENScreener" [ngbCollapse]="isENScreenerCollapsed">
                    <div class="col-xl-10 pr-0">
                        <div class="form-group border-right border-dark pr-2" *ngIf="earlyNotificationFilters">
                            <div class="row mb-2">

                                <div class="col-xl">
                                    <div class="form-group w200">
                                        <label for="crashDateFrom" class="text-nowrap">Crash Date (From)</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="mm/dd/yyyy" id="CrashDateFrom" name="CrashDateFrom"
                                                   [ngClass]="{ 'is-invalid': blnShowValidationMessageDateFrom }"
                                                   (ngModelChange)="onDateFromSelect(dpcdf)" [ngModel]="earlyNotificationFilters.CrashDateFrom"
                                                   ngbDatepicker [minDate]="getMinDate" [maxDate]="getMaxDate" maxlength="10"
                                                   #dpcdf="ngModel" #dpcf="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" (click)="dpcf.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl">
                                    <div class="form-group w200">
                                        <label for="crashDateTo" class="text-nowrap">Crash Date (To)</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="mm/dd/yyyy" id="CrashDateTo" name="CrashDateTo"
                                                   [ngClass]="{ 'is-invalid': blnShowValidationMessageDateTo }"
                                                   (ngModelChange)="onDateToSelect(dpcdt)" [ngModel]="earlyNotificationFilters.CrashDateTo"
                                                   ngbDatepicker [minDate]="getMinDate" [maxDate]="getMaxDate" maxlength="10"
                                                   #dpcdt="ngModel" #dpct="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" (click)="dpct.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl">
                                    <div class="form-group">
                                        <span class="w-auto">
                                            <label for="caseStatus" class="text-nowrap">Case Status</label>
                                            <select class="form-control w-auto" id="caseStatus" name="CaseStatus" [(ngModel)]="earlyNotificationFilters.CaseStatus">
                                                <option *ngFor="let caseStatus of arrStatus" [ngValue]="caseStatus.intValue">
                                                    {{caseStatus.strText}}
                                                </option>
                                            </select>
                                        </span>
                                    </div>
                                </div>                               

                                <div class="col-xl" *ngIf="blnIsEDTstate === true">
                                    <span class="w-auto">
                                        <label for="edtStatus" class="text-nowrap">EDT Status</label>
                                        <select class="form-control w-auto" id="edtStatus" name="EdtStatus" [(ngModel)]="earlyNotificationFilters.EdtStatus">
                                            <option *ngFor="let edtStatus of edtStatusList" [ngValue]="edtStatus.intValue">
                                                {{edtStatus.strText}}
                                            </option>
                                        </select>
                                    </span>
                                </div>                              
                              
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-2">
                        <div class="form-group">
                            <button class="btn btn-primary btn-block" (click)="onSearch()">Search</button>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-light border-secondary btn-block" (click)="onClearSearch()">Clear Search</button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card-body pt-1">
                <div class="row">
                    <div class="col-xl">
                        <div class="">
                            <div class="d-flex justify-content-between">
                                <span class="my-auto selectedFilterColor text-dark">
                                    <span class="mx-3" *ngIf="loading$ | async"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></span>

                                    <span class="my-auto" *ngIf="criCrashDateFrom != null">
                                        Crash Date From: <span class="fw-bold">{{criCrashDateFrom | date:'MM/dd/yyyy'}}</span>,
                                    </span>

                                    <span class="my-auto" *ngIf="criCrashDateTo != null">
                                        Crash Date To: <span class="fw-bold">{{criCrashDateTo | date:'MM/dd/yyyy'}}</span>,
                                    </span>
                                    <!--<span class="my-auto" *ngIf="criCaseStatus != null">
                                        Case Status: <span class="fw-bold">{{criCaseStatus | valueToDescription :  'CaseStatus'  : strFilterCondition : '' : false | async }}</span>,
                                    </span>-->
                                    <span class="my-auto" *ngIf="criCaseStatus != null">
                                        Case Status:  <span class="fw-bold"> {{this.caseStatus}} </span>
                                        </span>
                                        <!--<span class="my-auto" *ngIf="criCaseStatus != null">
        Case Status:
        <span *ngIf="criCaseStatus != 99999; else elseStatusAll" class="fw-bold">
            <span>{{criCaseStatus | valueToDescription :  'CaseStatus'  : strFilterCondition : '' : false | async }}</span>
        </span>
        <ng-template #elseStatusAll>
            <span class="fw-bold">All</span>
        </ng-template>,
    </span>-->

                                        <span class="my-auto" *ngIf="criEdtStatus != null blnIsEDTstate">
                                            EDT Status:
                                            <span *ngIf="criEdtStatus != 99999; else elseEdtStatusAll" class="fw-bold">{{criEdtStatus  | valueToDescription :  'EDT_CaseStatus'  : strFilterCondition : '' : false | async }}</span>
                                            <ng-template #elseEdtStatusAll>
                                                <span class="fw-bold">All</span>
                                            </ng-template>,
                                        </span>

                                        <span class="my-auto">
                                            Total: <span class="fw-bold">{{total$ | async }}</span>
                                        </span>
                                    </span>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-xl">

                        <div class="table-responsive">
                            <table class="table table-hover table-striped table-sm mb-0">
                                <thead class="">
                                    <tr class="">
                                        <th *ngIf="selectedMode == 256" scope="col" sortable="MOSSCasenum" (sort)="onMossSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            {{ strModeName }} #
                                            <span *ngIf="ColummForSorting === 'MOSSCasenum'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th *ngIf="selectedMode != 256" scope="col" sortable="Casenum" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            {{ strModeName }} #
                                            <span *ngIf="ColummForSorting === 'Casenum'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th *ngIf="selectedMode != 256" scope="col" sortable="StateCaseNum" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            State Case #
                                            <span *ngIf="ColummForSorting === 'StateCaseNum'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th *ngIf="selectedMode == 256" scope="col" sortable="AccDate" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            Crash Report #
                                            <span *ngIf="ColummForSorting === 'PARNumber'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th scope="col" sortable="AccDate" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            Crash Date
                                            <span *ngIf="ColummForSorting === 'AccDate'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th scope="col" *ngIf="selectedMode != 256" sortable="NoOfFatals" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            Fatals
                                            <span *ngIf="ColummForSorting === 'NoOfFatals'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th scope="col" *ngIf="selectedMode != 256" sortable="Remarks" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            Comments
                                            <span *ngIf="ColummForSorting === 'Remarks'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th *ngIf="blnIsEDTstate && selectedMode != 256" scope="col" sortable="strEDTStatus" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            EDT Status
                                            <span *ngIf="ColummForSorting === 'strEDTStatus'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th scope="col" *ngIf="selectedMode != 256" sortable="PARNumber" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            PAR Number
                                            <span *ngIf="ColummForSorting === 'PARNumber'">
                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up">  </i>
                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th *ngIf="selectedMode == 256" scope="col" sortable="AccDate" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            Technician
                                        </th>
                                        <th *ngIf="selectedMode == 256" scope="col" sortable="AccDate" (sort)="onSort($event)" class="bg-tb-header sticky-top border-bottom border-dark text-center">
                                            Case Status
                                        </th>
                                        <th colspan="2" class="bg-tb-header sticky-top border-bottom border-dark text-center">Actions</th>
                                    </tr>
                                </thead>

                                <tbody class="border-bottom border-dark">
                                    <tr class="bg-white" *ngIf="_state">
                                        <td class="border-bottom border-dark" *ngIf="selectedMode != 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchCaseNumTerm }">
                                                <input class="form-control form-control-sm " type="text" name="searchCaseNumTerm" placeholder="Search" [ngModel]="searchCaseNumTerm" (ngModelChange)="searchCaseNumTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark" *ngIf="selectedMode == 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchMOSSCaseNumTerm }">
                                                <input class="form-control form-control-sm " type="text" name="searchMOSSCaseNumTerm" placeholder="Search" [ngModel]="searchMOSSCaseNumTerm" (ngModelChange)="searchMOSSCaseNumTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark" *ngIf="selectedMode != 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchStateCaseNumTerm }">
                                                <input class="form-control form-control-sm " type="text" name="searchStateCaseNumTerm" placeholder="Search" [ngModel]="searchStateCaseNumTerm" (ngModelChange)="searchStateCaseNumTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark" *ngIf="selectedMode == 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchPARNumTerm }">
                                                <input class="form-control form-control-sm" type="text" name="searchPARNumTerm" placeholder="Search" [ngModel]="searchPARNumTerm" (ngModelChange)="searchPARNumTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchCrashDateTerm }">
                                                <input class="form-control form-control-sm " type="text" name="searchCrashDateTerm" placeholder="Search" [ngModel]="searchCrashDateTerm" (ngModelChange)="searchCrashDateTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark" *ngIf="selectedMode != 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchFatalsTerm }">
                                                <input class="form-control form-control-sm " type="text" name="searchFatalsTerm" placeholder="Search" [ngModel]="searchFatalsTerm" (ngModelChange)="searchFatalsTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark" *ngIf="selectedMode != 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchCommentsTerm }">
                                                <input class="form-control form-control-sm" type="text" name="searchCommentsTerm" placeholder="Search" [ngModel]="searchCommentsTerm" (ngModelChange)="searchCommentsTerm = $event" />
                                            </div>
                                        </td>
                                        <td *ngIf="blnIsEDTstate && selectedMode != 256" class="border-bottom border-dark">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchStatusTerm }">
                                                <input class="form-control form-control-sm" type="text" name="searchStatusTerm" placeholder="Search" [ngModel]="searchStatusTerm" (ngModelChange)="searchStatusTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark" *ngIf="selectedMode != 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchPARNumTerm }">
                                                <input class="form-control form-control-sm" type="text" name="searchPARNumTerm" placeholder="Search" [ngModel]="searchPARNumTerm" (ngModelChange)="searchPARNumTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark" *ngIf="selectedMode == 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchTechnicianTerm }">
                                                <input class="form-control form-control-sm " type="text" name="searchTechnicianTerm" placeholder="Search" [ngModel]="searchTechnicianTerm" (ngModelChange)="searchTechnicianTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark" *ngIf="selectedMode == 256">
                                            <div class="form-group pt-2 mb-2" [ngClass]="{'input-has-value' : searchCaseStatTerm }">
                                                <input class="form-control form-control-sm " type="text" name="searchCaseStatTerm" placeholder="Search" [ngModel]="searchCaseStatTerm" (ngModelChange)="searchCaseStatTerm = $event" />
                                            </div>
                                        </td>
                                        <td class="border-bottom border-dark"></td>
                                        <td class="border-bottom border-dark" colspan="2"></td>
                                    </tr>

                                    <tr *ngFor="let earlyNotification of earlyNotifications$ | async">
                                        <td class="text-center align-middle" *ngIf="selectedMode != 256">
                                            <ngb-highlight [result]="earlyNotification.Acc?.Casenum | number : '3.0-0' | replaceTextPipe : ',' : '' "
                                                           ngbTooltip="Case Num #" placement="top"></ngb-highlight>
                                        </td>
                                        <td class="text-center align-middle" *ngIf="selectedMode == 256">
                                            <ngb-highlight [result]="earlyNotification.Acc?.Acc_SS?.MOSSCasenum | replaceTextPipe : ' ' : '' | maxLengthPipe : 12 : ''"
                                                           ngbTooltip="Case Num #" placement="top"></ngb-highlight>
                                        </td>
                                        <td class="text-center text-break  align-middle" *ngIf="selectedMode != 256">
                                            <ngb-highlight [result]="earlyNotification.StateCaseNum" [term]="searchStateCaseNumTerm"
                                                           ngbTooltip="State Case #" placement="top"></ngb-highlight>
                                        </td>
                                        <td class="text-center text-break  align-middle" *ngIf="selectedMode == 256">
                                            <ngb-highlight [result]="earlyNotification.PARNumber"
                                                           ngbTooltip="PAR Number" placement="top"></ngb-highlight>
                                        </td>
                                        <td class="text-center text-break  align-middle">
                                            <span ngbTooltip="Crash Date" placement="top">
                                                {{ pipe.transform(earlyNotification.AccMonth, '2.0-0') }}/{{ pipe.transform(earlyNotification.AccDay, '2.0-0') }}
                                                {{ earlyNotification.AccHr === -1 ? "--" : pipe.transform(earlyNotification.AccHr, '2.0-0') }}:{{ earlyNotification.AccMin === -1 ? "--" : pipe.transform(earlyNotification.AccMin, '2.0-0') }}
                                            </span>
                                        </td>

                                        <td class="text-center text-break  align-middle" *ngIf="selectedMode != 256">
                                            <ngb-highlight [result]="earlyNotification.NoOfFatals"
                                                           ngbTooltip="Fatals" placement="top"></ngb-highlight>
                                        </td>
                                        <td class="text-center text-break align-middle" *ngIf="selectedMode != 256">
                                            <div tooltipClass="tooltip-class" placement="top">
                                                <ngb-highlight [result]="earlyNotification.Remarks" [term]="searchCommentsTerm"
                                                               [ngbTooltip]="'Comments: ' + earlyNotification.Remarks"></ngb-highlight>
                                            </div>
                                        </td>
                                        <td *ngIf="blnIsEDTstate && selectedMode != 256" class="text-center text-break align-middle">
                                            <ngb-highlight [result]="earlyNotification.strEDTStatus"
                                                           ngbTooltip="EDT Status" placement="top"></ngb-highlight>
                                        </td>
                                        <td class="text-center text-break  align-middle" *ngIf="selectedMode != 256">
                                            <ngb-highlight [result]="earlyNotification.PARNumber"
                                                           ngbTooltip="PAR Number" placement="top"></ngb-highlight>
                                        </td>
                                        <td class="text-center text-break  align-middle" *ngIf="selectedMode == 256">
                                            <!--<ngb-highlight [result]="earlyNotification.StateCaseNum" [term]="searchStateCaseNumTerm"
        ngbTooltip="State Case #" placement="top"></ngb-highlight>-->

                                            <ngb-highlight [result]="earlyNotification.Acc.CaseStats.CrUserID | username | async"
                                                           ngbTooltip="Technician" placement="top"></ngb-highlight>

                                        </td>
                                        <td class="text-center text-break  align-middle" *ngIf="selectedMode == 256">
                                            <ngb-highlight [result]="earlyNotification.strCaseStatus"
                                                           ngbTooltip="Status" placement="top"></ngb-highlight>
                                        </td>
                                        <td class="my-auto" *ngIf="selectedMode != 256">
                                            <button type="button" class="btn btn-link text-truncate" (click)="onGoToTheCase(earlyNotification)" style="text-decoration: none" *ngIf="earlyNotification.Acc && !earlyNotification.Acc.Deleted">
                                                Go to {{ strModeName }} Case
                                            </button>
                                            <!-- Deleted case remins linked to EarlyNotify only if Earlyotify is also deleted, oterwise delted case is unliked froom EarlyNotify even if EarlyNotify is deleted later -->
                                            <button type="button" class="btn btn-link text-truncate" (click)="onGoToTheCase(earlyNotification)" style="text-decoration: none" *ngIf="earlyNotification.Acc && earlyNotification.Acc.Deleted">
                                                Go to {{ strModeName }} Case({{ strModeName }} Deleted)
                                            </button>
                                            <button type="button" class="btn btn-link text-truncate" (click)="onMakeMDECase(earlyNotification)" style="text-decoration: none" *ngIf="!earlyNotification.Acc && earlyNotification.CrashType == 1">
                                                Make {{ strModeName }} Case
                                            </button>
                                        </td>
                                        <td class="my-auto" *ngIf="selectedMode == 256">
                                            <button type="button" class="btn btn-link text-truncate" (click)="onGoToTheCase(earlyNotification)" style="text-decoration: none" *ngIf="earlyNotification.Acc">
                                                Go to {{ strModeName }} Case
                                            </button>
                                        </td>
                                        <td class="my-auto" *ngIf="blnIsEDTstate === true">
                                            <button type="button" class="btn btn-link text-truncate" (click)="onPDF(earlyNotification)" style="text-decoration: none" [disabled]="!earlyNotification.StateCaseNum">
                                                View
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>

                        <div class="d-flex justify-content-between p-2" *ngIf="_state">
                            <div class="align-middle" *ngIf="total$ | async">
                                <ngb-pagination class="align-middle" [collectionSize]="total$ | async" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                            </div>
                            <div class="custom-control-inline">
                                <select class="custom-select pl-2 my-auto w-auto" name="pageSize" [(ngModel)]="pageSize">
                                    <option [ngValue]="5">5 items per page</option>
                                    <option [ngValue]="10">10 items per page</option>
                                    <option [ngValue]="25">25 items per page</option>
                                    <option [ngValue]="100">100 items per page</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
