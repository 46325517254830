<form novalidate #facn="ngForm">
    <div id="crash-notes" class="pt-2" *ngIf="acc">
        <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
            <div class="form-group pl-2">
                <app-typeahead id="schlBus" name="schlBus" typeAheadPosition="right-top"
                               [objModel]="acc" strTableName="Acc" strFieldName="SchlBus"
                               [showAllDataListForPrintOnly]="false" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                </app-typeahead>
            </div>
        </div>--> 
        <div [ngClass]="{'col-12' : printOnly === true}">
            <div class="form-group mb-0">
                <div class="px-2">
                    <app-textfield id="CrashNotes" [disableLabel]="false"
                                   [blnIsNumber]="false" [intTextAreaRows]="10"
                                   [objModel]="acc" [(objCurrentValue)]="acc.Notes"
                                   [blnFormatWithLeading]="false"
                                   strTableName="Acc" strFieldName="Notes"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-textfield>
                </div>
            </div>
        </div>
    </div>
</form>
