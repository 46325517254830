import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, Directive, Input, ElementRef, Renderer2, ViewEncapsulation, ViewContainerRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { CrasheventServices } from "../services/crashevents.service";

import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { Acc } from 'src/app/models/acc';
import { CrashEvents } from 'src/app/models/crash-events';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { Seqevent } from 'src/app/ui/crash/crash-events/services/seqevent.model'
import { SequenceOfEvents, MannerOfCollision, RBISDataValue, DBMode, VehType } from 'src/app/models/enums/app.enums';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { ActionButtonService } from 'src/app/services/action-button.service';
import { VehicleClass } from '../services/vehicleClass.model.';
import { Veh } from 'src/app/models/veh';
import { ObjContactGrpEvent } from '../services/ObjContactGrpEvent.model';
import { Element_Specify } from 'src/app/models/element-specify';
import { RbisUser } from '../../../../models/rbis-user';
import { LookupTable } from '../../../../models/enums/Generated/LookupTable';
import { async } from 'rxjs/internal/scheduler/async';

/**
 * This component relies on autofill rules to set/clear Acc.FHEvent and enable/disable CrashEvents.AOI2 and CrashEvents.VNumber2
 **/
@Component({
    selector: 'app-crasheventlist',
    templateUrl: './crasheventlist.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./crasheventlist.component.css']
})
export class CrasheventlistComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_CrasheventlistComponent: string = null;
    private readonly NON_SAVE_VALIDATION: string = 'nonsave';
    acc: Acc;
    accid: number;
    intMode: number;

    lblmessage: string = '';

    private sbsClearForm: Subscription;

    //Single vehicle non-harmful non-impact events (group 5, blank AOI1, no 2nd vehicle involvement)
    //60 - Cargo / Equipment Loss or Shift(non - harmful)
    //61 - Equipment Failure(blown tire, brake failure, etc)
    //62 - Separation of Units
    //63 - Ran Off Roadway - Right
    //64 - Ran Off Roadway - Left
    //65 - Cross Median
    //66 - Downhill Runaway
    //67 - Vehicle Went Airborne
    //68 - Cross Centerline
    //69 - Re - entering Roadway
    //70 - (Non - harmful, Swaying Trailer / Jackknife)
    //71 - End Departure
    //79 - Ran off Roadway - Direction Unknown
    arrSoeHarmfulNoncollision = []; // SOE Code - AOI1=00 and AOI2 and VNumber2 must be blank

    //Single vehicle harmful non-impact events (group 1, AOI1 is 0, no 2nd vehicle involvement)
    //01 - Rollover / Overturn
    //02 - Fire / Explosion
    //03 - Immersion or Partial Immersion
    //04 - Gas Inhalation
    //05 - Fell / Jumped from Vehicle
    //06 - Injured In Vehicle(Non - Collision)
    //07 - Other Non - Collision
    //16 - Thrown or Falling Object
    //44 - Pavement Surface Irregularity(Ruts, Potholes, Grates, etc.)
    //51 - Jackknife(harmful to this vehicle)
    //72 - Cargo / Equipment Loss, Shift, or Damage[harmful]
    arrSoeNonharmfulNoncollision = []; // SOE Code - AOI1, AOI2 and VNumber2 (all three) must be blank

    //Collision with another vehicle (must specify AOI1, 2nd vehicle involved and AOI2)
    //12 - Motor Vehicle In - Transport
    //14 - Parked Motor Vehicle
    //45 - Working Motor Vehicle
    //54 - Motor Vehicle In - Transport Strikes or is Struck by Cargo, Persons or Objects Set -in -Motion from / by Another Motor Vehicle In Transport
    //55 - Motor Vehicle in Motion Outside the Trafficway
    arrSoeCollisionWithAnotherVeh = [];  // SOE Code - AOI2 and VNumber2 cannot be blank - AOI1 can be any value

    itemFHECheck = [
        { id: RBISDataValue.Blank },
        { id: SequenceOfEvents.MotorVehicleInTransport },
        { id: SequenceOfEvents.InTransportContactNonVehicle },
        { id: SequenceOfEvents.InMotionOutsideTrafficway }
    ];
    intRowsToAdd: number = 1;

    /**
     * True after DEFS.dbo.SeqEvent has been looked up and Harmful flags have been assigned to Acc.CrashEvents
     * and First Harmful Event (FHE) and vehicles involved in FHE have been looked up. Before this flag is set
     * evaluating if the FHE or vehicles involved have changed is not possible.
     **/
    private blnInitialized: boolean = false;
    private objAcc_Original: Acc;
    private FHE_SOE_Original: number = RBISDataValue.Blank;
    private FHE_VNumber1_Original: number = RBISDataValue.Blank;
    private FHE_VNumber2_Original: number = RBISDataValue.Blank;
    private blnClearCrashTypeConfirmReentryGuard: boolean = false;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;

    @ViewChild('FHEvent', { static: false }) txtFHEvent: TypeaheadComponent;
    @ViewChild('crashEvent', { static: false }) objForm: NgForm;
    @ViewChild('addResults', { static: false}) txtAddRows: ElementRef;

    totalStrikingVehicleCount: number = 0;
    totalSFRVehicleCount: number = 0;
    totalOtherVehicleCount: number = 0;

    StrikingVehicle: string = VehType.StrikingVehicle;
    SFRVehicle: string = VehType.SFRVehicle;
    OtherVehicle: string = VehType.OtherVehicle;

    blnIsChangeMode: boolean = false

    objInstanceElementSpecify: Element_Specify;

    hasDeleteRow: boolean = false;

    blnBtnReadOnly: boolean = false;

    areaOfDamageStrikingVeh: Array<DrpDownOptions> = [];
    objectContactedClassList: Array<DrpDownOptions> = [];
    objContactGroupEventList: Array<DrpDownOptions> = [];
    constructor(
        public _route: ActivatedRoute,
        private _utilService: UtilService,             //For base class constructor
        private _sharedDataService: SharedDataService, //For base class constructor
        protected _modalService: ModalService,
        private service: CrasheventServices,
        private _autofillService: AutofillService,
        private modalService: NgbModal,
        protected _urlTreeHelper: UrlTreeHelper,
        protected _caseService: CaseService,
        private _actionButtonService: ActionButtonService
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
        this.blnFocusOnFirstField = false;
    }

    public async onBeforeSave(strParam: string) {
        this.blnAlloweSave = true;
        this.lblmessage = '';

        //TODO: REMOVE: THIS SHOULD NOT BE NEEDED IF MOVEUP AND MOVEDOWN ARE DOING THEIR JOB
        for (let i = 0; i < this.acc.CrashEvents.length; i++) {
            if (this.acc.CrashEvents[i].EventNum != i + 1) {
                console.error(`Crash Event at index ${i} should have EventNum ${i + 1}, but has EventNum ${this.acc.CrashEvents[i].EventNum} instead!`);
                this.acc.CrashEvents[i].EventNum = i + 1;
            }
        }

        //DEPRECATED??
        if (strParam != this.NON_SAVE_VALIDATION && //Is save
            this.acc.CrashEvents.length == 1 && this.acc.CrashEvents[0].VNumber1 == -1) {
            this.acc.CrashEvents.length = 0;
        }

        //// Do not Save Multi Blank Lines - Start
        if (this.acc.CrashEvents.length > 1) {
            let _flagCnt = 0;
            let i_end = this.acc.CrashEvents.length;

            for (var i = 0; i < i_end; i++) {
                let ce: CrashEvents = this.acc.CrashEvents[i];
                if (ce.VNumber1 == -1 && i > 0) {
                    _flagCnt += 1
                }
                if (strParam != this.NON_SAVE_VALIDATION) {
                    if (ce.VNumber1 == -1) {
                        let typheadVNumber1 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                            && i.strFieldName == "VNumber1" && i.intSeqNum == ce.EventNum);
                        typheadVNumber1.setIsInvalidClass(true);
                    }
                    if (ce.AOI1 == -1 && this.intMode != DBMode.MOSS) {
                        let typheadAOI1 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                            && i.strFieldName == "AOI1" && i.intSeqNum == ce.EventNum);
                        typheadAOI1.setIsInvalidClass(true);
                    }
                    if (this.intMode == DBMode.MOSS) {
                        if (ce.SOE == -1 && ce.OCC != 1) { //Autofill rules: Crash Event OCC disables Object Contacted If OCC is Vehicle
                            let typheadSOE = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                                && i.strFieldName == "SOE" && i.intSeqNum == ce.EventNum);
                            typheadSOE.setIsInvalidClass(true);
                        }
                    }
                    else {
                        if (ce.SOE == -1) {
                            let typheadSOE = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                                && i.strFieldName == "SOE" && i.intSeqNum == ce.EventNum);
                            typheadSOE.setIsInvalidClass(true);
                        }
                    }
                }

                if (this.intMode == DBMode.MOSS) {
                    this.clearVehTypeName()
                }
            }

            if (_flagCnt > 0 && strParam != this.NON_SAVE_VALIDATION) {
                this.blnAlloweSave = false;
                this.errordisplay(4);
            }
        }
               
        let hasOnlySingleEmptyRow: boolean = false;
        if (this.acc.CrashEvents.length == 1) {
            const ce = this.acc.CrashEvents[0];
            if ((ce.AOI1 == -1 || ce.AOD1 == '') && ce.VNumber1 == -1 && ce.VNumber2 == -1 && ce.SOE == -1 && (ce.AOI2 == -1 || ce.AOD2 == '')) {
                hasOnlySingleEmptyRow = true;
            }
        }
        //// Do not Save Multi Blank Lines - End

        if (this.acc.CrashEvents.length > 0) {
            if (this.blnAlloweSave && !hasOnlySingleEmptyRow) {
                for (var i = 0; i < this.acc.CrashEvents.length; i++) {
                    let objCrashEvent: CrashEvents = this.acc.CrashEvents[i];

                    let typheadVNumber1 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                        && i.strFieldName == "VNumber1" && i.intSeqNum == objCrashEvent.EventNum);

                    let typheadSOE
                    typheadSOE = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                        && i.strFieldName == "SOE" && i.intSeqNum == objCrashEvent.EventNum);

                    let typheadVNumber2 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                        && i.strFieldName == "VNumber2" && i.intSeqNum == objCrashEvent.EventNum);

                    let typhead_AOI_AOD_2 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                        && (i.strFieldName == "AOI2" || i.strFieldName == "AOD2") && i.intSeqNum == objCrashEvent.EventNum);

                    let typhead_AOI_AOD_1 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                        && (i.strFieldName == "AOI1" || i.strFieldName == "AOD1") && i.intSeqNum == objCrashEvent.EventNum);

                    typhead_AOI_AOD_2.setIsInvalidClass(false);
                    typhead_AOI_AOD_1.setIsInvalidClass(false);

                    //let typheadAOI2 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                    //    && i.strFieldName == "AOI2" && i.intSeqNum == objCrashEvent.EventNum);

                    //let typheadAOI1 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                    //    && i.strFieldName == "AOI1" && i.intSeqNum == objCrashEvent.EventNum);

                    typhead_AOI_AOD_1.setIsInvalidClass(false);
                    typheadVNumber1.setIsInvalidClass(false);
                    if (this.intMode != DBMode.MOSS) typheadSOE.setIsInvalidClass(false);
                    typheadVNumber2.setIsInvalidClass(false);
                    typhead_AOI_AOD_2.setIsInvalidClass(false);
                    typhead_AOI_AOD_1.setIsInvalidClass(false);

                    //// #11 start - based on validation document - VNumber1 is required
                    if (this.intMode != DBMode.MOSS) {
                        if ((this.acc.CrashEvents[i].VNumber1 == -1) || isNaN(Number(this.acc.CrashEvents[i].VNumber1))) {
                            if (strParam != this.NON_SAVE_VALIDATION)
                                typheadVNumber1.focusAndHighlightUIElement();
                            this.blnAlloweSave = false;
                            this.lblmessage = 'Range Error: Invalid Entry: Vehicle No. (this) is required';
                            break;
                        }
                    }

                    //// #6 start - based on validation document
                    if (this.intMode != DBMode.MOSS) {
                        if (this.acc.CrashEvents[i].AOI1 != -1 && this.acc.CrashEvents[i].AOI1 != 0) { //V1 collided with something
                            let blnCollidedWithOtherVeh: boolean = //Collision was with another vehicle
                                null != this.arrSoeCollisionWithAnotherVeh.find(x => x.id == this.acc.CrashEvents[i].SOE);

                            if (blnCollidedWithOtherVeh) {
                                if (this.acc.CrashEvents[i].AOI2 == -1) {
                                    if (strParam != this.NON_SAVE_VALIDATION)
                                        typhead_AOI_AOD_2.focusAndHighlightUIElement();
                                    this.blnAlloweSave = false;
                                    this.errordisplay(3);
                                    break;
                                }
                                else if (this.acc.CrashEvents[i].VNumber2 == -1) {
                                    if (strParam != this.NON_SAVE_VALIDATION)
                                        typheadVNumber2.focusAndHighlightUIElement();
                                    this.blnAlloweSave = false;
                                    this.errordisplay(3);
                                    break;
                                }
                            }
                            else {
                                //This is already handled by autofill logic, this is a redundant validation check just to be sure
                                if (this.acc.CrashEvents[i].AOI2 != -1) {
                                    if (strParam != this.NON_SAVE_VALIDATION)
                                        typhead_AOI_AOD_2.focusAndHighlightUIElement();
                                    this.blnAlloweSave = false;
                                    this.errordisplay(2);
                                    break;
                                }
                                else if (this.acc.CrashEvents[i].VNumber2 != -1) {
                                    if (strParam != this.NON_SAVE_VALIDATION)
                                        typheadVNumber2.focusAndHighlightUIElement();
                                    this.blnAlloweSave = false;
                                    this.errordisplay(2);
                                    break;
                                }
                            }
                        }
                    }
                    //// #6 end
                    if (this.intMode == DBMode.MOSS) {
                        if ((objCrashEvent.OCC != -1 && objCrashEvent.OCC != 1 && objCrashEvent.SOE == -1) && !this.hasDeleteRow) { //Object Contacted is required
                            if (strParam != this.NON_SAVE_VALIDATION)
                                typheadSOE.focusAndHighlightUIElement();
                            this.blnAlloweSave = false;
                            this.lblmessage = 'Range Error: Invalid Entry: Object Contacted is required'
                            break;
                        }
                    }
                    else {
                        if (objCrashEvent.SOE == -1) { //SOE is required
                            if (strParam != this.NON_SAVE_VALIDATION)
                                typheadSOE.focusAndHighlightUIElement();
                            this.blnAlloweSave = false;
                            this.lblmessage = 'Range Error: Invalid Entry: SOE is required'
                            break;
                        }
                    }

                    //// #11 end

                    //// #9 start - based on validation document -- VNumber1 cannot equal VNumber2: Vehicle cannot collide with itself
                    if (this.intMode != DBMode.MOSS) {
                        if (this.acc.CrashEvents[i].VNumber2 != -1 && (this.acc.CrashEvents[i].VNumber2 === this.acc.CrashEvents[i].VNumber1)) {
                            if (strParam != this.NON_SAVE_VALIDATION)
                                typheadVNumber2.focusAndHighlightUIElement();
                            this.blnAlloweSave = false;
                            this.lblmessage = 'Range Error: Invalid Entry: Vehicle No. (this) and Vehicle No. (Other) cannot be the same';
                            break;
                        }
                    }
                    else {
                        if (this.acc.CrashEvents[i].VNumber2 != -1 && (this.acc.CrashEvents[i].VNumber2 === this.acc.CrashEvents[i].VNumber1 &&
                            this.acc.CrashEvents[i].VehType1 === this.acc.CrashEvents[i].VehType2)) {
                            if (strParam != this.NON_SAVE_VALIDATION)
                                typheadVNumber2.focusAndHighlightUIElement();
                            this.blnAlloweSave = false;
                            this.lblmessage = 'Range Error: Invalid Entry: Vehicle No. (Striking Veh) and Vehicle No. (Contacted Veh) cannot be the same';
                            break;
                        }
                    }
                    //// #9 end

                    //// #5 start - based on validation document
                    if (this.intMode != DBMode.MOSS) {
                        if (this.acc.CrashEvents[i].AOI1 === 0) { //Not a collision, but harmful
                            let blnSoeHarmfulNoncollision: boolean = null != this.arrSoeHarmfulNoncollision.find(x => x.id == this.acc.CrashEvents[i].SOE);

                            if (!blnSoeHarmfulNoncollision) {
                                if (strParam != this.NON_SAVE_VALIDATION)
                                    typheadSOE.focusAndHighlightUIElement();
                                this.blnAlloweSave = false;
                                this.lblmessage = 'Range Error: Value coded  for AOI1 indicates that SOE should be a Harmful Non-Collision Event';
                                break;
                            } else {
                                //This is already handled by autofill logic, this is a redundant validation check just to be sure
                                if (this.acc.CrashEvents[i].AOI2 != -1) {
                                    if (strParam != this.NON_SAVE_VALIDATION)
                                        typhead_AOI_AOD_2.focusAndHighlightUIElement();
                                    this.blnAlloweSave = false;
                                    this.errordisplay(2);
                                    break;
                                }
                                else if (this.acc.CrashEvents[i].VNumber2 != -1) {
                                    if (strParam != this.NON_SAVE_VALIDATION)
                                        typheadVNumber2.focusAndHighlightUIElement();
                                    this.blnAlloweSave = false;
                                    this.errordisplay(2);
                                    break;
                                }
                            }
                        }
                    }
                    //// #5 end

                    //// #4 start - based on validation document
                    if (this.intMode != DBMode.MOSS) {
                        if (this.acc.CrashEvents[i].AOI1 == -1) {
                            let blnSoeNonharmfulNoncollision: boolean = null != this.arrSoeNonharmfulNoncollision.find(x => x.id == this.acc.CrashEvents[i].SOE);

                            if (!blnSoeNonharmfulNoncollision) {
                                if (strParam != this.NON_SAVE_VALIDATION)
                                    typheadSOE.focusAndHighlightUIElement();
                                this.blnAlloweSave = false;
                                this.lblmessage = 'Range Error: Value coded  for AOI1 indicates that SOE should be a Non-Harmful Non-Collision Event';
                                break;
                            } else {
                                //This is already handled by autofill logic, this is a redundant validation check just to be sure
                                if (this.acc.CrashEvents[i].AOI2 != -1) {
                                    if (strParam != this.NON_SAVE_VALIDATION)
                                        typhead_AOI_AOD_2.focusAndHighlightUIElement();
                                    this.blnAlloweSave = false;
                                    this.errordisplay(2);
                                    break;
                                }
                                else if (this.acc.CrashEvents[i].VNumber2 != -1) {
                                    if (strParam != this.NON_SAVE_VALIDATION)
                                        typheadVNumber2.focusAndHighlightUIElement();
                                    this.blnAlloweSave = false;
                                    this.errordisplay(2);
                                    break;
                                }
                            }
                        }
                    }
                    //// #4 end

                    //SOE 
                }  //for 
            } //OKSave
        } //CrashEvents not empty

        this.hasDeleteRow = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadList();
        this.blnBtnReadOnly = this.blnReadOnly;

        this.accid = this.acc.AccID;
        this.intMode = this.acc.Mode;

        this.totalStrikingVehicleCount = this.acc.Veh.filter(v => v.AccID == this.accid && v.VehType == VehType.StrikingVehicle).length;
        this.totalSFRVehicleCount = this.acc.Veh.filter(v => v.AccID == this.accid && v.VehType == VehType.SFRVehicle).length;
        this.totalOtherVehicleCount = this.acc.Veh.filter(v => v.AccID == this.accid && v.VehType == VehType.OtherVehicle).length;

        if (this.intMode != DBMode.MOSS) {
            this.service.SeqeventList().then(((arrSeqEvent: Seqevent[]) => {
                for (let i = 0; i < arrSeqEvent.length; i++) {
                    //    // SOE Code AOI1, AOI2 and VNumber2 (all three) must be blank
                    if (arrSeqEvent[i].ISEQGRPID == 5) {
                        this.arrSoeNonharmfulNoncollision.push({ id: arrSeqEvent[i].Id });
                    }

                    //    // SOE Code  - AOI1=00 and AOI2 and VNumber2 must be blank
                    if (arrSeqEvent[i].ISEQGRPID == 1) {
                        this.arrSoeHarmfulNoncollision.push({ id: arrSeqEvent[i].Id });
                    }

                    //    //SOE Code - AOI2 and VNumber2 cannot be blank - AOI1 can be any value
                    if (arrSeqEvent[i].ISEQGRPID == 2) {
                        this.arrSoeCollisionWithAnotherVeh.push({ id: arrSeqEvent[i].Id });
                    }
                }

                this.accid = this.acc.AccID;
                this.FHE_SOE_Original = this.acc.FHEvent;
                let foundFHE = false;
                for (let i = 0; i < this.acc.CrashEvents.length; i++) {
                    this.acc.CrashEvents[i].Harmful = arrSeqEvent.find(x => x.Id == this.acc.CrashEvents[i].SOE).Harmful;

                    if (this.acc.CrashEvents[i].AccID == this.acc.AccID &&
                        this.acc.CrashEvents[i].SOE == this.acc.FHEvent
                    ) {
                        if (!foundFHE) { // we only need first row information we don't care rest of row 05/13/2020 Khem
                            this.FHE_VNumber1_Original = this.acc.CrashEvents[i].VNumber1;
                            this.FHE_VNumber2_Original = this.acc.CrashEvents[i].VNumber2;
                            foundFHE = true;
                        }
                    }
                }

                this.objAcc_Original = ObjectUtil.CloneModelObject(this.acc);
                this.blnInitialized = true;

                let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                    subInit.unsubscribe();
                    this.RefilterSoeInAllRows();
                }).bind(this));
            }).bind(this));
        }
        else {
            if (this.acc) {
                this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, 0, 0);
                console.log(this.objInstanceElementSpecify);
            }

            this.service.ObjContactEventList().then(((arrSeqEvent: ObjContactGrpEvent[]) => {
                this.parseContactGroupEventList(arrSeqEvent);
                //TODO: The following code comment out for MOSS, logic need to check later

                //for (let i = 0; i < arrSeqEvent.length; i++) {
                //    //    // SOE Code AOI1, AOI2 and VNumber2 (all three) must be blank
                //    if (arrSeqEvent[i].ISOBJCONTGRPID == 5) {
                //        this.arrSoeNonharmfulNoncollision.push({ id: arrSeqEvent[i].Id });
                //    }

                //    //    // SOE Code  - AOI1=00 and AOI2 and VNumber2 must be blank
                //    if (arrSeqEvent[i].ISOBJCONTGRPID == 1) {
                //        this.arrSoeHarmfulNoncollision.push({ id: arrSeqEvent[i].Id });
                //    }

                //    //    //SOE Code - AOI2 and VNumber2 cannot be blank - AOI1 can be any value
                //    if (arrSeqEvent[i].ISOBJCONTGRPID == 2) {
                //        this.arrSoeCollisionWithAnotherVeh.push({ id: arrSeqEvent[i].Id });
                //    }
                //}

                this.FHE_SOE_Original = this.acc.FHEvent;

                let foundFHE = false;

                for (let i = 0; i < this.acc.CrashEvents.length; i++) {
                    if (this.acc.CrashEvents[i].AccID == this.acc.AccID) {

                        if (typeof (arrSeqEvent.find(x => x.Id === this.acc.CrashEvents[i].SOE)) != 'undefined') {
                            this.acc.CrashEvents[i].Harmful = arrSeqEvent.find(x => x.Id == this.acc.CrashEvents[i].SOE).Harmful;
                        }                        

                        if (this.acc.CrashEvents[i].AccID == this.acc.AccID &&
                            this.acc.CrashEvents[i].SOE == this.acc.FHEvent) {
                            if (!foundFHE) { // we only need first row information we don't care rest of row 05/13/2020 Khem
                                this.FHE_VNumber1_Original = this.acc.CrashEvents[i].VNumber1;
                                this.FHE_VNumber2_Original = this.acc.CrashEvents[i].VNumber2;
                                foundFHE = true;
                            }

                            //Moh: 09/24/2020                            
                            //this.loadVehClassLst(i);
                            //this.loadObjContactEventLst(i);
                        }

                        this.loadVehDes("",i); 
                    }
                }

                this.objAcc_Original = ObjectUtil.CloneModelObject(this.acc);
                this.blnInitialized = true;

                let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                    subInit.unsubscribe();
                    this.RefilterSoeInAllRows();
                }).bind(this));
            }).bind(this));
        }

        this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe(((item) => {
            if (item == 'events') {
                if (this.acc) {
                    this.clearVehTypeName();
                    this.ReevaluateFheAndVehInImpact();
                    this.RefilterSoeInAllRows();

                    let uiControls = this._autofillService.arrControls.filter(i => i.strFieldName == "SOE" || i.strFieldName == "VNumber2" || i.strFieldName == "AOD2");
                    if (uiControls) {
                        uiControls.forEach(control => {
                            control.clearComponent();
                            control.disableOrEnableComponent(false);
                        })
                    }
                }
            }
        }).bind(this));
    }

    onEventModelChange(event, fieldName: string, index: number) {
        //console.log(event.VNumber1)
        //console.log(event)

        if (this.intMode == DBMode.MOSS) {
            //if ((fieldName == "VNumber1" && (event.VehType1 == undefined || event.VNumber1 != -1))
            //    || (fieldName == "VNumber2" && (event.VehType2 == undefined || event.VNumber2 != -1)))
            //    this.blnIsChangeMode = true
            //else
            //    this.blnIsChangeMode = false

            if (fieldName == "VNumber1" || fieldName == "VNumber2") {
                switch (fieldName) {
                    case 'VNumber1':
                        if ((fieldName == "VNumber1" && event.VNumber1 == -1) && !this.objForm.dirty)
                                this.blnIsChangeMode = false                    
                            else
                                this.blnIsChangeMode = true
                        break;
                    case 'VNumber2':
                        if ((fieldName == "VNumber2" && event.VNumber2 == -1) && !this.objForm.dirty)
                                this.blnIsChangeMode = false                    
                            else
                                this.blnIsChangeMode = true
                        break;
                    default:
                        break;            
                }
            }

                
            this.loadVehDes(fieldName, index); 

            //if (fieldName === 'VNumber1' && event.VNumber1 != -1) {
            //    this.loadVehClassLst(event.VNumber1)
            //}
            //if (fieldName === 'VNumber2' && event.VNumber2 != -1) {
            //    this.loadVehClassLst(event.VNumber2)
            //}
            //if (fieldName === 'SOE') {
            //    //this.loadObjContactEventLst(event.VNumber2)
            //}
        }
    }

    private loadVehDes(fieldName: string = "", i: number) {
        if (fieldName == 'VNumber1' || fieldName =="") {
            if (this.acc.CrashEvents[i].VNumber1 == -1)
                this.acc.CrashEvents[i].VehTypeDes1 = ''        
            else if (this.acc.CrashEvents[i].VehType1 != '') {
                let vehType1 = this.acc.CrashEvents[i].VehType1
                switch (vehType1) {
                    case 'F':
                        this.acc.CrashEvents[i].VehTypeDes1 = "StruckSubjectVehicle _" + this.acc.CrashEvents[i].VNumber1
                        break;
                    case 'S':
                        this.acc.CrashEvents[i].VehTypeDes1 = "Striking_Vehicle_" + + this.acc.CrashEvents[i].VNumber1
                        break;
                    case 'O':
                        this.acc.CrashEvents[i].VehTypeDes1 = "Other_Vehicle_" + + this.acc.CrashEvents[i].VNumber1
                        break;
                    default:
                        this.acc.CrashEvents[i].VehTypeDes1= ''
                        break;
                }
            }
        }

        if (fieldName == 'VNumber2' || fieldName == "") {
            if (this.acc.CrashEvents[i].VNumber2 == -1)
                this.acc.CrashEvents[i].VehTypeDes2 = ''            
            else if (this.acc.CrashEvents[i].VehType2 != '') {
                let vehType2 = this.acc.CrashEvents[i].VehType2
                switch (vehType2) {
                    case 'F':
                        this.acc.CrashEvents[i].VehTypeDes2 = "StruckSubjectVehicle_" + this.acc.CrashEvents[i].VNumber2
                        break;
                    case 'S':
                        this.acc.CrashEvents[i].VehTypeDes2 = "Striking_Vehicle_" + this.acc.CrashEvents[i].VNumber2
                        break;
                    case 'O':
                        this.acc.CrashEvents[i].VehTypeDes2 = "Other_Vehicle_" + this.acc.CrashEvents[i].VNumber2
                        break;
                    default:
                        this.acc.CrashEvents[i].VehTypeDes2 = ''
                        break;
                }
            }
        }
    }

    private loadVehClassLst(i:number) {
        this.service.VehicleClassList().then((arrVehicleClass: VehicleClass[]) => {
            //console.log(JSON.stringify(arrVehicleClass))

            //this.acc.CrashEvents[i].VehicleClass1 = arrVehicleClass
            //    .find(x => x.Id == this.acc.Veh[i].Veh_SS.VehicleClass).Def

            let v1, v2

            if (this.FHE_VNumber1_Original != -1) {
                v1 = arrVehicleClass
                    .find(x => x.Id == this.acc.Veh
                        .find(v => v.Veh_SS.VNumber == this.FHE_VNumber1_Original).Veh_SS.VehicleClass)

                if (typeof (v1) != 'undefined') {
                    this.acc.CrashEvents[i].VehicleClass1 = v1.Def
                }

            }

            if (this.FHE_VNumber2_Original != -1) {
                v2 = arrVehicleClass
                    .find(x => x.Id == this.acc.Veh
                        .find(v => v.Veh_SS.VNumber == this.FHE_VNumber2_Original).Veh_SS.VehicleClass)
                if (typeof (v2) != 'undefined') {
                    this.acc.CrashEvents[i].VehicleClass2 = v2.Def
                }
            }

        })
    }

    private loadObjContactEventLst(i: number) {
        if (typeof (this.acc.CrashEvents[i]) != 'undefined') {
            if (typeof (this.acc.CrashEvents[i].SOE) != 'undefined') {
                this.service.ObjContactEventList().then((arrCrashEvents: ObjContactGrpEvent[]) => {

                    //console.log(arrCrashEvents)
                    //console.log(arrCrashEvents.find(x => x.ID === this.acc.CrashEvents[i].SOE))

                    if (typeof (arrCrashEvents.find(x => x.Id === this.acc.CrashEvents[i].SOE)) != 'undefined') {

                        //console.log(arrCrashEvents
                        //    .find(x => x.ID === this.acc.CrashEvents[i].SOE).SeqGrpDEF)

                        this.acc.CrashEvents[i].ObjectContactedClass = arrCrashEvents
                            .find(x => x.Id === this.acc.CrashEvents[i].SOE).ObjContactGrpDEF

                        //console.log(this.acc.CrashEvents[i].ObjectContactedClass)
                    }
                })
            }
        }
    }

    /**
     * Workaround for ngFor sometimes recreating but not rebinding rows adjacent to an insert
     **/
    private RefilterSoeInAllRows() {
        for (let objTypeahead of this._autofillService.arrControls) {
             if (objTypeahead.strFieldName === 'AOI1') {
                if (objTypeahead.options) {
                    let objSelection: DrpDownOptions = objTypeahead.options.find(x => x.intValue == objTypeahead.objModel[objTypeahead.strFieldName]);

                    if (objSelection != null)
                        this.setObjectValue(objSelection, objTypeahead.strFieldName, objTypeahead.intSeqNum - 1);
                }
            }
        }
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();

        //First field that should have focus is not an instance of UIElementBase.
        //Need a non-trivial delay before focus can be given - it seems AfterViewInit is too early to give focus. So, we wait for UIElementBase instances to finish initializing.
        let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            subInit.unsubscribe();
            if (this.txtAddRows) {
                
                this.txtAddRows.nativeElement.focus();
                this.txtAddRows.nativeElement.select();
            }
        }).bind(this));
    }

    public setObjectValue(objSelection: DrpDownOptions, fieldName: string, index: number): void {
        let typeaheads: UIElementBase[] = this._autofillService.arrControls;
        this.ClearValidation(index);
        if (fieldName === 'SOE') {
            this.acc.CrashEvents[index].Harmful = objSelection.HARMFUL === true ? true : false;
        }
        else if (fieldName == "VNumber1" || fieldName == "VNumber2") {
            if (this.intMode == DBMode.MOSS && this.blnIsChangeMode && this.objForm.dirty) {
                if (objSelection.VehType == undefined) {
                    if (fieldName == "VNumber1") this.acc.CrashEvents[index].VehType1 = null
                    if (fieldName == "VNumber2") this.acc.CrashEvents[index].VehType2 = null
                }
                else {
                    //console.log(this.blnInitialized + " " + this.blnIsChangeMode + " " + objSelection.VehType.toString());
                    if (fieldName == "VNumber1") this.acc.CrashEvents[index].VehType1 = objSelection.VehType.toString() 
                    if (fieldName == "VNumber2") this.acc.CrashEvents[index].VehType2 = objSelection.VehType.toString() 
                }                
                this.loadVehDes(fieldName, index);

                //Task:12095 If a striking  vehicle is selected as vehicle No. in first column in an event the Veh No. (contacted)must not show the same vehicle
                //later task: ask for implement on both SFR and other Veh

                //Strike
                if (fieldName == "VNumber1" &&
                    objSelection.VehType != undefined &&
                    objSelection.VehType == 'S' &&
                    objSelection.intValue != -1 &&
                    this.blnIsChangeMode) {

                    let condStr = ' AND ((v.ID BETWEEN 1 AND ' + this.totalSFRVehicleCount + ' AND v.VEHTYPE = ' + this.SFRVehicle + ') OR (v.ID BETWEEN 1 AND ' + this.totalOtherVehicleCount + ' AND v.VEHTYPE = ' + this.OtherVehicle + ') OR (v.ID BETWEEN 1 AND ' + this.totalStrikingVehicleCount + ' AND v.VEHTYPE = S AND v.ID <> ' + this.acc.CrashEvents[index].VNumber1 + '))'

                    let vNumber2Typeahead = typeaheads.find(i => i.strFieldName == 'VNumber2' && i.intSeqNum == index + 1);
                    vNumber2Typeahead.strFilterCondition = condStr
                    vNumber2Typeahead.FilterFieldOptions();
                }
                //SFR
                else if (fieldName == "VNumber1" &&
                    objSelection.VehType != undefined &&
                    objSelection.VehType == 'F' &&
                    objSelection.intValue != -1 &&
                    this.blnIsChangeMode) {

                    let condStr = ' AND ((v.ID BETWEEN 1 AND ' + this.totalStrikingVehicleCount + ' AND v.VEHTYPE = ' + this.StrikingVehicle + ') OR (v.ID BETWEEN 1 AND ' + this.totalOtherVehicleCount + ' AND v.VEHTYPE = ' + this.OtherVehicle + ') OR (v.ID BETWEEN 1 AND ' + this.totalSFRVehicleCount + ' AND v.VEHTYPE = F AND v.ID <> ' + this.acc.CrashEvents[index].VNumber1 + '))'

                    let vNumber2Typeahead = typeaheads.find(i => i.strFieldName == 'VNumber2' && i.intSeqNum == index + 1);
                    vNumber2Typeahead.strFilterCondition = condStr
                    vNumber2Typeahead.FilterFieldOptions();
                }
                //Other
                else if (fieldName == "VNumber1" &&
                    objSelection.VehType != undefined &&
                    objSelection.VehType == 'O' &&
                    objSelection.intValue != -1 &&
                    this.blnIsChangeMode) {

                    let condStr = ' AND ((v.ID BETWEEN 1 AND ' + this.totalStrikingVehicleCount + ' AND v.VEHTYPE = ' + this.StrikingVehicle + ') OR (v.ID BETWEEN 1 AND ' + this.totalSFRVehicleCount + ' AND v.VEHTYPE = ' + this.SFRVehicle + ') OR (v.ID BETWEEN 1 AND ' + this.totalOtherVehicleCount + ' AND v.VEHTYPE = O AND v.ID <> ' + this.acc.CrashEvents[index].VNumber1 + '))'

                    let vNumber2Typeahead = typeaheads.find(i => i.strFieldName == 'VNumber2' && i.intSeqNum == index + 1);
                    vNumber2Typeahead.strFilterCondition = condStr
                    vNumber2Typeahead.FilterFieldOptions();
                }
                else if (fieldName == "VNumber1" &&
                    this.blnIsChangeMode) {

                    let condStr = ' AND ((v.ID BETWEEN 1 AND ' + this.totalStrikingVehicleCount + ' AND v.VEHTYPE = ' + this.StrikingVehicle + ') OR (v.ID BETWEEN 1 AND ' + this.totalSFRVehicleCount + ' AND v.VEHTYPE = ' + this.SFRVehicle + ') OR (v.ID BETWEEN 1 AND ' + this.totalOtherVehicleCount + ' AND v.VEHTYPE = ' + this.OtherVehicle + '))'

                    let vNumber2Typeahead = typeaheads.find(i => i.strFieldName == 'VNumber2' && i.intSeqNum == index + 1);
                    vNumber2Typeahead.strFilterCondition = condStr
                    vNumber2Typeahead.FilterFieldOptions();
                }

                //Task12095: Show Area of damage attributes based on CDC / TDC applicable vehicles
                //CDC=2/TDC=3 field into lookup table

                if (fieldName == "VNumber1" &&
                    objSelection.VehType != undefined &&
                    objSelection.intValue != -1 &&
                    this.blnIsChangeMode) {

                    let dmyveh: Veh[];
                    let vehAtIndex: Veh;

                    dmyveh = this.acc.Veh.filter(x => x.VehType == objSelection.VehType)
                    vehAtIndex = dmyveh[objSelection.intValue - 1];                    
                    let veh1Body = vehAtIndex.Body

                    //let veh1Body = this.acc.Veh.find(x => x.VehType == objSelection.VehType).Body 

                    let condStr = ""
                    if (veh1Body == -1) {
                        //condStr = " AND ID IN(#0#, #N#, #9#)"
                        condStr = " AND DAMAGEGRPID IN(1,2)"
                    } else {
                        condStr = veh1Body > 49 ?
                            //" AND ID IN(#0#, #N#, #9#, #F#, #R#, #L#, #B#, #D#, #C#, #V#, #T#, #U#)" :
                            //" AND ID IN(#0#,#N#,#9#,#F#, #R#, #L#, #B#, #T#, #U#)"
                            " AND DAMAGEGRPID IN(1,3)" :
                            " AND DAMAGEGRPID IN(1,2)"
                    }                    

                    let aoc1Typeahead = typeaheads.find(i => i.strFieldName == 'AOD1' && i.intSeqNum == index + 1);
                    
                    aoc1Typeahead.strFilterCondition = condStr
                    aoc1Typeahead.FilterFieldOptions();
                }

                if (fieldName == "VNumber2" &&
                    objSelection.VehType != undefined &&
                    objSelection.intValue != -1 &&
                    this.blnIsChangeMode) {

                    let dmyveh: Veh[];
                    let vehAtIndex: Veh;

                    dmyveh = this.acc.Veh.filter(x => x.VehType == objSelection.VehType)
                    vehAtIndex = dmyveh[objSelection.intValue - 1];
                    let veh2Body = vehAtIndex.Body

                    //let veh2Body = this.acc.Veh.find(x => x.VehType == objSelection.VehType).Body

                    let condStr = ""
                    if (veh2Body == -1) {
                        //condStr = " AND ID IN(#0#, #N#, #9#)"
                        condStr = " AND DAMAGEGRPID IN(1,2)"
                    } else {
                        condStr = veh2Body > 49 ?
                            //" AND ID IN(#0#, #N#, #9#, #F#, #R#, #L#, #B#, #D#, #C#, #V#, #T#, #U#)" :
                            //" AND ID IN(#0#,#N#,#9#,#F#, #R#, #L#, #B#, #T#, #U#)"
                            " AND DAMAGEGRPID IN(1,3)" :
                            " AND DAMAGEGRPID IN(1,2)"
                    }

                    let aoc2Typeahead = typeaheads.find(i => i.strFieldName == 'AOD2' && i.intSeqNum == index + 1);
                    
                    aoc2Typeahead.strFilterCondition = condStr
                    aoc2Typeahead.FilterFieldOptions();
                }
            }

            this.ValidateVNumber(this.acc.CrashEvents[index].VNumber1, this.acc.CrashEvents[index].VNumber2);
        }
        else if (fieldName === 'AOI1') {
            //find the typeahead instance SOE in a row
            let soeTypeahead = typeaheads.find(i => i.strFieldName == 'SOE' && i.intSeqNum == index + 1);
            //focus is automatically advanced by AOI1 to next field (SOE) when AOI1 is filled in

            if (objSelection.intValue === -1) { //-1 which means empty area of impact
                if (soeTypeahead && soeTypeahead.strFilterCondition != ' iSeqGrpID = 5 ') {
                    soeTypeahead.strFilterCondition = ' iSeqGrpID = 5 ';
                    soeTypeahead.FilterFieldOptions(); //Refresh the typeahead for the new options
                    //[60,61,62,63,64,65,66,67,68,69,70,71,79]
                }
            }
            else if (objSelection.intValue === 0) {
                if (soeTypeahead && soeTypeahead.strFilterCondition != ' iSeqGrpID = 1 ') {
                    soeTypeahead.strFilterCondition = ' iSeqGrpID = 1 ';
                    soeTypeahead.FilterFieldOptions(); //Refresh the typeahead for the new options
                    //[01, 02, 03, 04, 05, 06, 07, 16, 44, 51, 72]
                }
            }
            else {
                if (soeTypeahead && soeTypeahead.strFilterCondition != 'iSeqGrpID NOT IN (1, 5)') {
                    soeTypeahead.strFilterCondition = 'iSeqGrpID NOT IN (1, 5)';
                    soeTypeahead.FilterFieldOptions(); //Refresh the typeahead for the new options
                }
            }
        }
        else if (fieldName === 'OCC') {
            let soeTypeahead = typeaheads.find(i => i.strFieldName == 'SOE' && i.intSeqNum == index + 1);
            //if (objSelection.intValue === -1) {
                soeTypeahead.strFilterCondition = ' AND ISOBJCONTGRPID= ';
                soeTypeahead.FilterFieldOptions(); 
            //}

        }

        if (this.blnInitialized)
            this.ReevaluateFheAndVehInImpact();
    }

    private ClearValidation(intIndex: number): void {
        let objCrashEvent: CrashEvents = this.acc.CrashEvents[intIndex];
        if (objCrashEvent) {
            let typheadVNumber1 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                && i.strFieldName == "VNumber1" && i.intSeqNum == objCrashEvent.EventNum);
            let typheadSOE = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                && i.strFieldName == "SOE" && i.intSeqNum == objCrashEvent.EventNum);
            let typheadVNumber2 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                && i.strFieldName == "VNumber2" && i.intSeqNum == objCrashEvent.EventNum);
            let typheadAOI2 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                && i.strFieldName == "AOI2" && i.intSeqNum == objCrashEvent.EventNum);

            let typheadAOI1 = this._autofillService.arrControls.find(i => i.strTableName == "CrashEvents"
                && i.strFieldName == "AOI1" && i.intSeqNum == objCrashEvent.EventNum);

            this._sharedService.getViolatedRule().then(objViolatedRule => {
                if (!objViolatedRule) {
                    typheadVNumber1.setIsInvalidClass(false);
                    typheadSOE.setIsInvalidClass(false);
                    typheadVNumber2.setIsInvalidClass(false);
                    if (this.intMode != DBMode.MOSS) typheadAOI2.setIsInvalidClass(false);
                    if (this.intMode != DBMode.MOSS) typheadAOI1.setIsInvalidClass(false);
                    this.lblmessage = '';
                }
            });
        }
    }

    public onAddNewRows(objEvent: Event): void {
        if (this.intRowsToAdd > 0) {
            let xintIndex: number = this.acc.CrashEvents.length;

            for (var j = 0; j < this.intRowsToAdd; j++) {
                this.addCrashItem(xintIndex + j, false);  //(xintIndex + j) is the index at which to insert
            }

            this.intRowsToAdd = null; //Force user to specify number of rows to add next time around (avoid unintentional double clicks producing more rows than desired)
            this.OnRowAdded(xintIndex);
        }

        if (objEvent && objEvent instanceof KeyboardEvent && objEvent.key == 'Enter') {
            objEvent.preventDefault();
            objEvent.cancelBubble = true;
        }
    }
    
    public async ReevaluateFheAndVehInImpact(): Promise<void> {
        if (!this.blnInitialized) {
            console.warn('CrasheventlistComponent.ReevaluateFheAndVehInImpact() called before initialization finished. Exiting early.');

            if (this.intMode == DBMode.MOSS) {
                this.clearVehTypeName()
            }

            return;
        }

        let objFHE: CrashEvents = this.acc.CrashEvents.find(x => x.Harmful);

        this.onBeforeSave(this.NON_SAVE_VALIDATION); //Re-eval validation (without silent discard of blank row)

        if (this.blnAlloweSave) {
            let blnVehicleImpacted: boolean;
            //First Harmful Event for accident should not be confused with First Harmful Event for Vehicle, each vehicle may have impacted
            //an area against other vehicle or object, so for each vehicle, we look for harmful impact events. The vehicle may be on the left
            //side or the right side of an impact event.
            for (let x = 0; x < this.acc.Veh.length; x++) {
                blnVehicleImpacted = false;

                for (var i = 0; i < this.acc.CrashEvents.length; i++) {
                    if (this.acc.CrashEvents[i].Harmful && this.acc.CrashEvents[i].VNumber1 === this.acc.Veh[x].VNumber && this.acc.CrashEvents[i].AOI1 != RBISDataValue.Blank) {
                        this.acc.Veh[x].InImpact = this.acc.CrashEvents[i].AOI1;
                        blnVehicleImpacted = true;
                        break;
                    }

                    if (this.acc.CrashEvents[i].Harmful && this.acc.CrashEvents[i].VNumber2 === this.acc.Veh[x].VNumber && this.acc.CrashEvents[i].AOI2 != RBISDataValue.Blank) {
                        this.acc.Veh[x].InImpact = this.acc.CrashEvents[i].AOI2;
                        blnVehicleImpacted = true;
                        break;
                    }
                }

                if (!blnVehicleImpacted)
                    this.acc.Veh[x].InImpact = RBISDataValue.Blank;
            }

            if (!this.blnClearCrashTypeConfirmReentryGuard &&
                this.FHE_SOE_Original != RBISDataValue.Blank && !this.printOnly &&
                this.acc.Veh.find(x => x.PreCrash && x.PreCrash.CrashType != RBISDataValue.Blank) != null &&
                (objFHE == null ||
                    objFHE.SOE != this.FHE_SOE_Original ||
                    objFHE.VNumber1 != this.FHE_VNumber1_Original || //Check if the vehicle numbers changed
                    objFHE.VNumber2 != this.FHE_VNumber2_Original)
            ) {
                this.blnClearCrashTypeConfirmReentryGuard = true;

                let blnClearCrashTypeConfirm: boolean 
                if (this.intMode != DBMode.MOSS )
                    blnClearCrashTypeConfirm = await this._modalService.dialogPromise("You have changed the First Harmful Event (FHE) or the vehicles involved in the First Harmful Event. This will clear the Crash Type for all vehicles. Do you want to proceed?", "Please Confirm");

                this.blnClearCrashTypeConfirmReentryGuard = false;

                if (blnClearCrashTypeConfirm) {
                    for (var i = 0; i < this.acc.Veh.length; i++)
                        if (this.acc.Veh[i].PreCrash)
                            this.acc.Veh[i].PreCrash.CrashType = -1;

                    this.objAcc_Original = ObjectUtil.CloneModelObject(this.acc);
                    this.FHE_SOE_Original = objFHE ? objFHE.SOE : RBISDataValue.Blank;
                    this.FHE_VNumber1_Original = objFHE ? objFHE.VNumber1 : RBISDataValue.Blank;
                    this.FHE_VNumber2_Original = objFHE ? objFHE.VNumber2 : RBISDataValue.Blank;
                }
                else {
                    if (this.intMode == DBMode.MOSS) {
                        //TODO: not sure yet
                    }
                    else {
                        this.acc = this.objAcc_Original;
                        this.acc = null; //Clear data model to force all controls, including FHE above grid to be recreated.
                        //setTimeout((() => { this.acc = ObjectUtil.CloneModelObject(this.objAcc_Original, 'events'); }).bind(this), 1);
                    }
                }
            }
        }
    }

    errordisplay(saveErrorCode: number) {
        switch (saveErrorCode) {
            case 1:
                this.lblmessage = 'Range Error: Invalid Entry';
                break;
            case 2:
                this.lblmessage = 'Range Error: based on the SOE and AOI(this) values coded this event is not a collision with another vehicle, therefore Veh No.(other) or AOI(other) must be blank'
                break;
            case 3:
                this.lblmessage = 'Range Error: based on the SOE and AOI(this) values coded, Veh No.(other) or AOI(other) must indicate a collision with another vehicle'
                break;
            case 4:
                this.lblmessage = 'Range Error: Please correct the data in red highlighted field'
                break;
            default:
                break;
        }

        if (this.intMode == DBMode.MOSS) {
            this.clearVehTypeName()
        }
    }

    public clearVehTypeName() {
        for (var i = 0; i < this.acc.CrashEvents.length; i++) {
            this.loadVehDes("", i);
        }
    }

    public onPreviewData(objPreviewTemplate: TemplateRef<any>): void {
        this._actionButtonService.SaveCase(this, 'Save').then(((blnSuccess: boolean) => {
            if (blnSuccess)
                this._modalService.openModal(objPreviewTemplate);
        }).bind(this));
    }

    openScrollableLongContent(longContent) {
        this.modalService.open(longContent, { centered: true, size: 'lg' });
    }

    addCrashItem(intIndexAtWhichToInsert: number, blnAddingOneRow: boolean = true): void {
        this.lblmessage = '';

        let objCrashEvent = {} as CrashEvents;
        objCrashEvent._TypeScript_TypeGuard_CrashEvents = null;
        objCrashEvent.Acc = this.acc;
        objCrashEvent.AccID = this.acc.AccID;
        objCrashEvent.EventNum = intIndexAtWhichToInsert + 1; //Array is 0-index, EventNum starts from 1
        objCrashEvent.VNumber1 = -1;
        objCrashEvent.VNumber2 = -1;
        objCrashEvent.AOI1 = -1;
        objCrashEvent.AOI2 = -1;
        objCrashEvent.SOE = -1;
        objCrashEvent.AOD1 = '';
        objCrashEvent.AOD2 = '';
        //objCrashEvent.VehType1 = ''; // cause problem comment out
        //objCrashEvent.VehType2 = '';
        objCrashEvent.OCC = -1;
        objCrashEvent.Harmful = false;

        objCrashEvent.Acc.Mode = this.acc.Mode;

        for (let i = intIndexAtWhichToInsert; i < this.acc.CrashEvents.length; i++) //Make a hole in the EventNum sequence for incoming row
            this.acc.CrashEvents[i].EventNum++;

        this.acc.CrashEvents.splice(intIndexAtWhichToInsert, 0, objCrashEvent); //Array is 0-index

        if (blnAddingOneRow)
            this.OnRowAdded(intIndexAtWhichToInsert);
    }

    /**
     * Post row addition logic and logic to focus on first field in added row.
     */
    private OnRowAdded(intIndex: number): void {
        let subRowRendered: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            subRowRendered.unsubscribe();
            this.RefilterSoeInAllRows();
            this._autofillService.RerenderModelValues();
            let objFocusTarget: UIElementBase = this._autofillService.arrControls.find(x => x.id == 'VNumber1_' + intIndex.toString());

            if (objFocusTarget)
                objFocusTarget.objTextbox.nativeElement.focus();
            else
                console.debug('CrashEventComponent.addCrashItem(): Failed to find control with ID VNumber1_' + intIndex.toString());
        }).bind(this));

        this.MarkFormAsDirty();
    }

    public deleteCrashRow(rowIndex: number): void {
        this.lblmessage = '';
        this.hasDeleteRow = true;

        if (this.acc.CrashEvents.length > 0) {
            this.acc.CrashEvents.splice(rowIndex, 1);
            this.RenumberCrashEvents(); //Removal could be from the end of the collection or from the middle of the collection

            let typheadSOE: UIElementBase = this._autofillService.arrControls.find(i =>
                i.strTableName == "CrashEvents" &&
                i.strFieldName == "SOE" &&
                i.intSeqNum == 1);
            AutofillService.OnChange(typheadSOE, typheadSOE.objModel, typheadSOE.strFieldName, true, false); //User initiated flag is set to false as we don't want focus to field focus to auto-advance
        }

        this.MarkFormAsDirty();
        this.ReevaluateFheAndVehInImpact();
        if (rowIndex > 0) rowIndex -= 1;
        let objFocusTarget: UIElementBase = this._autofillService.arrControls.find(x => x.id == 'VNumber1_' + rowIndex.toString());
        if (objFocusTarget)
            objFocusTarget.objTextbox.nativeElement.focus();
        //forcuse
    }

    clearCrashRow(intIndex: number) {
        this.lblmessage = '';

        let intEventNum = intIndex + 1;
        let arrControlsInRow: UIElementBase[] = this._autofillService.arrControls.filter(i => i.intSeqNum == intEventNum);

        if (arrControlsInRow) {
            arrControlsInRow.forEach(objControl => {
                objControl.clearComponent();

                if (objControl.strFieldName == 'AOI1')
                    objControl.setCurrentValue(RBISDataValue.Blank.toString()); //Trigger SOE filtering logic
                if (objControl.strFieldName == 'SOE') {
                    objControl.objModel.Harmful = false;
                    AutofillService.OnChange(objControl, objControl.objModel, objControl.strFieldName, true, false); //User initiated flag is set to false as we don't want focus to field focus to auto-advance
                }
            });
            this.MarkFormAsDirty();
        }

        this.loadVehDes("", intIndex);
    }

    public arrowdownCrashRow(intIndex: number): void {
        if (intIndex < (this.acc.CrashEvents.length - 1)) {
            this.MoveRow(intIndex, intIndex + 1);

            let typheadSOE: UIElementBase = this._autofillService.arrControls.find(i =>
                i.strTableName == "CrashEvents" &&
                i.strFieldName == "SOE" &&
                i.intSeqNum == intIndex + 1);
            AutofillService.OnChange(typheadSOE, typheadSOE.objModel, typheadSOE.strFieldName, true, false); //User initiated flag is set to false as we don't want focus to field focus to auto-advance
        }
    }

    public ValidateVNumber(vnumber1: number, vnumber2: number) {
        this.lblmessage = "";
        let blnFlag: boolean = false;
        let strMessage: string = 'Vehicle Numbers must either ';

        for (let i = 0; i < this.acc.Veh.length; i++) {
            strMessage = strMessage + ((i > 0) ? ' or ' : '') + this.acc.Veh[i].VNumber;

            if (this.acc.Veh[i].VNumber == vnumber1) {
                blnFlag = true;
                break;
            }
        }

        if (vnumber2 != undefined && vnumber1 == vnumber2) {
            this.lblmessage = "Vehicle numbers must be different";
            blnFlag = true;
        }

        if (!blnFlag) {
            this.lblmessage = strMessage;
            return false;
        }
    }

    public arrowupCrashRow(intIndex: number): void {
        //Verify that index-1 is a valid destination index
        //Either move values from this.acc.CrashEvents[index] to this.acc.CrashEvents[index - 1]
        if (intIndex - 1 >= 0) {
            //ngFor is listening for inserts and deletions, the default tracker will not see an inplace
            //index swap as two operations
            let objRowToMove: CrashEvents = this.acc.CrashEvents[intIndex];
            this.acc.CrashEvents.splice(intIndex, 1);
            this.acc.CrashEvents.splice(intIndex - 1, 0, objRowToMove);
            this.RenumberCrashEvents();
            this.MarkFormAsDirty();

            let typheadSOE: UIElementBase = this._autofillService.arrControls.find(i =>
                i.strTableName == "CrashEvents" &&
                i.strFieldName == "SOE" &&
                i.intSeqNum == intIndex + 1);
            AutofillService.OnChange(typheadSOE, typheadSOE.objModel, typheadSOE.strFieldName, true, false); //User initiated flag is set to false as we don't want focus to field focus to auto-advance
        }
    }

    private MoveRow(i: number, j: number): void {
        //ngFor is listening for inserts and deletions, the default tracker
        // will not see an inplace index swap as two operations
        let objRowToMove: CrashEvents = this.acc.CrashEvents[i];
        this.acc.CrashEvents.splice(i, 1);
        this.acc.CrashEvents.splice(j, 0, objRowToMove);
        this.RenumberCrashEvents();
        this.MarkFormAsDirty();
    }

    private RenumberCrashEvents() {
        for (let i = 0; i < this.acc.CrashEvents.length; i++) {
            //if (this.acc.CrashEvents[i].EventNum != i + 1)
            this.acc.CrashEvents[i].EventNum = i + 1;
        }
    }

    public GetCrashEventsInvolvingVehicle(intVNumber: number): CrashEvents[] {
        return this.acc.CrashEvents.filter(x => x.VNumber1 == intVNumber || x.VNumber2 == intVNumber);
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsClearForm) {
            this.sbsClearForm.unsubscribe();
        }
    }

    loadList() {
        this._utilService.formDrpDownOptionShare.subscribe(async result => {
            this.areaOfDamageStrikingVeh = await result.filter(i => i.tblName == LookupTable.AreaOfDamage);
        }).unsubscribe();

        this._utilService.GetDrpDownListOptions('SS.ObjContactGroup', '').subscribe(async result => {
            console.log('result', result);
            this.objectContactedClassList = result;
            //this.parseContactGroupClassList(arrVehicleClass);
        });
    }
    parseContactGroupClassList(contactLIst: VehicleClass[]): void {
        this.objContactGroupEventList = [];
        for (const item of contactLIst) {
            const drpItem = {} as DrpDownOptions;
            drpItem.displayText = item.Id.toString() + '-' + item.Def;
            drpItem.intValue = item.Id;
            this.objContactGroupEventList.push(drpItem)
        }
    }
    parseContactGroupEventList(contactLIst: ObjContactGrpEvent[]): void {
        this.objContactGroupEventList = [];
        for (const item of contactLIst) {
            const drpItem = {} as DrpDownOptions;
            drpItem.displayText = item.Id.toString() + '-' + item.Def;
            drpItem.intValue = item.Id;
            this.objContactGroupEventList.push(drpItem)
        }
    }
}
