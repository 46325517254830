import { Component, HostListener, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Event as NavigationEvent, NavigationEnd, UrlSegment } from '@angular/router';
import { UtilService } from './services/util.service';
import { DrpDownOptions } from './models/drp-down-options';
import { TableFieldElements } from './models/table-field-elements';

import { SharedDataService, AppSettings } from './services/shared-data.service';
import { LoadingFormService } from './services/loading-form.service';
import { EncryptedData } from './models/EncryptedData';
import { rbisStore } from './rbis-store';
import { AuthService } from './services/auth.service';
import { DBMode } from './models/enums/app.enums';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    title = 'Records Based System (RBS)';
    public isSearchVisible: boolean = false;
    public static blnLoggedIn: boolean = false;
    objDrpDwnOptions: DrpDownOptions[];
    metaDataSet: TableFieldElements[];
    isDrpDownSetLoaded: boolean = false;
    isPrintMode: boolean = false;
    authorized: boolean = true;
    multipleSiteAccess: boolean = false;
    selectedYear: number;
    selectedMode: number;
    mossSCISiteList: any[];
    /**
     * Must create snapshot of bound function pointer as bind() alters the type signature from the viewpoint of window.removeEventListener().
     * Periodically starts listening for user mouse activity and if user is active, then renews the session and restarts the idle user automatic
     * logout counter before going to sleep again.
     **/
    private fptrBoundResetSessionTimeoutWarning: any = this.ResetSessionTimeoutWarning.bind(this);
    strGeolocatorUrl: string;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilService: UtilService,
        private _sharedDataService: SharedDataService,
        private LoadingService: LoadingFormService,
        private rbisstore: rbisStore,
        private authService: AuthService
    ) {
        this.overrideDate();
    }

    public ngOnInit(): Promise<void> {
        this.LoadingService.startLoading();

        if (!this.CheckCookie())
            alert('This application requires browser cookies to be enabled in order to function');

        let arrCookies: string[] = document.cookie.split(';');
        //Every new cookie in cookie.split(';') array starts with leading space (" "). TODO: Consider using string.trimLeft()
        let strCookie: string = arrCookies.find(x => x.startsWith('.AspNetCore.Cookies') || x.startsWith(' .AspNetCore.Cookies')); //Expired cookies and HTTP-only cookies are not visible in document.cookie

        if (!strCookie || window.location.href.toLowerCase().includes('ssosessionid')) { //The presence of querystring parameters from SSO will make the 302 redirect to MAX.GOV a CORS request, which we don't want
            console.log('AppComponent.ngOnInit(): Redirecting to /login.');
            window.location.href = '/login'; //Redirect is not immediate and location.href does not reflect new value immediately. Some components will begin initialization.
            return;
        }
        else {
            //WARNING: Do not make any API calls that require authentication until GetAppSettings() comes back successfully: we could be trying to use an expired/invalidated session cookie and repeated failed API calls will stop flow of control instead of redirecting the user to authenticate.
            console.log('AppComponent.ngOnInit(): Did not need to redirect to /login.');
            this._route.params.subscribe((async params => {
                this.isPrintMode = location.href.includes('print');
            }));

            this._router.events.subscribe(
                (event: NavigationEvent): void => {
                    if (event instanceof NavigationEnd) {
                        this.isSearchVisible = event.url.includes('caseCreate') ? false : event.url.includes('case');
                        this.isPrintMode = event.url.includes('print');
                    }
                });

            this._sharedDataService.GetAppSettings().then(
                //We need to handle at least 2 scenarios cookies and 2 scenarios with the 302 redirect to MAX.GOV:
                //1. No user session cookie is present
                //2. User session cookie is present but is invalid (ex: application pool was restarted and cookie encryption key changed)
                //3. Successful simple 302 redirect to MAX.GOV
                //4. Blocked CORS 302 redirect to MAX.GOV
                (async (objSettings: AppSettings) => {
                    if (objSettings) {
                        this.selectedYear = objSettings.intYear;
                        this.selectedMode = objSettings.intMode;

                        let isAuthorized = await this.authService.IsUserAuthorized();

                        if (!isAuthorized) {
                            this.authorized = false;
                            this._router.navigate(['unauthorized']);
                        }
                        if (this.selectedMode == DBMode.MOSS) {
                            this.mossSCISiteList = await this.authService.GetMossSciSites();

                            this.multipleSiteAccess = this._sharedDataService.IsMultipleSiteAccess(this.mossSCISiteList);
                            if (this.multipleSiteAccess)
                                this._router.navigate(['unauthorized']);                            
                        }

                        AppComponent.blnLoggedIn = true;

                        this.GetDrpDownOptions();
                        this.GetTableFieldElements();                      

                        if (objSettings.intSessionIdleMaxMinutes > 0) {
                            this.SESSION_IDLE_LIMIT_MINUTE = objSettings.intSessionIdleMaxMinutes;
                            this.SESSION_RENEW_INTERVAL_MINUTE = this.SESSION_IDLE_LIMIT_MINUTE / 6;
                            this.SESSION_RENEW_INTERVAL_MILLIS = this.SESSION_RENEW_INTERVAL_MINUTE * 60 * 1000;
                        }

                        //Schedule session timeout warning timer based on [detectable] mouse movement.
                        //Note: Mouse move is not raised when mouse cursor is over PDF Coding Manual.
                        setTimeout(() => {
                            window.addEventListener('mousemove', this.fptrBoundResetSessionTimeoutWarning);
                        }, this.SESSION_RENEW_INTERVAL_MILLIS);
                    }
                    else {
                        console.error('AppComponent.ngOnInit(): SharedDataService.GetAppSettings() failed to retrieve app settings. Session probably expired. Clearing old session cookie and redirecting to /login.');
                        document.cookie = ".AspNetCore.Cookies=dummy; expires=Thu, 01-Jan-1970 00:00:01 GMT"; //Clear cookie
                        window.location.href = '/login';
                    }
                }).bind(this),
                ((objError: any) => {
                    console.error('AppComponent.ngOnInit(): Error when calling SharedDataService.GetAppSettings(). Clearing old session cookie and redirecting to /login.', objError);
                    document.cookie = ".AspNetCore.Cookies=dummy; expires=Thu, 01-Jan-1970 00:00:01 GMT"; //Clear cookie
                    window.location.href = '/login';
                }).bind(this));
        }
    }

    /**
     * TODO: DEPRECATED?
     * Override polyfill implementation of Date.toJSON() with custom implementation that returns local date and time serialized as a string.
     **/
    overrideDate() {
        Date.prototype.toJSON = function (key) {
            return this.toLocaleDateString() + ' ' + this.toLocaleTimeString();
        }
    }

    private intLastSessionTimeoutReset: number;
    private APP_SESSION_TIMEOUT_ID: NodeJS.Timer;
    private SESSION_IDLE_LIMIT_MINUTE: number = 120;
    private SESSION_RENEW_INTERVAL_MINUTE: number = 15;
    private SESSION_RENEW_INTERVAL_MILLIS: number = this.SESSION_RENEW_INTERVAL_MINUTE * 60 * 1000;
    private strSessionExpirationMessage: string;

    /**
     * Periodically starts listening for user mouse activity and if user is active, then renews the session and restarts the idle user automatic
     * logout counter before going to sleep again.
     **/
    private ResetSessionTimeoutWarning(): void {
        //No need to account for reentrancy here as JS is effectively single threaded.
        //Cannot use Date.UTC() as in IE it always returns -2208988800000, using getTime() instead.
        let dtNow: Date = new Date();
        let intNow: number = dtNow.getTime(); //Milliseconds
        let intMillisSinceLastReset: number = intNow - this.intLastSessionTimeoutReset;

        //If last mouse move was less than SESSION_RENEW_INTERVAL_MINUTE ago, detach mouse move handler, and schedule re-attach
        //in SESSION_RENEW_INTERVAL_MINUTE. Greater than 0 check accounts for overflow during initialization.
        if (intMillisSinceLastReset > 0 && intMillisSinceLastReset < this.SESSION_RENEW_INTERVAL_MILLIS) {
            console.log('Session cookie keep alive mouse move listener raised before renew interval exceeded. Reattaching mouse move listener in ' + this.SESSION_RENEW_INTERVAL_MILLIS.toString() + ' milliseconds.');
            window.removeEventListener('mousemove', this.fptrBoundResetSessionTimeoutWarning);
            setTimeout(() => {
                window.addEventListener('mousemove', this.fptrBoundResetSessionTimeoutWarning);
            }, this.SESSION_RENEW_INTERVAL_MILLIS);

            return; //Exit early
        }
        else {//Renew session cookie and restart automatic logout timer
            try {
                console.log('Session cookie keep alive mouse move listener attempting to renew session cookie.');
                this.intLastSessionTimeoutReset = intNow; //Next entry into ResetSessionTimeoutWarning() will remove mousemove handler

                this._utilService.UserLastActiveSave().then(
                    (objCookie: EncryptedData) => {
                        try {
                            console.log('Session cookie keep alive successfully renewed session cookie.');
                            document.cookie = '.AspNetCore.Cookies=' + objCookie.code; //Assignment to cookie is actually an append or replace

                            if (this.APP_SESSION_TIMEOUT_ID)
                                clearTimeout(this.APP_SESSION_TIMEOUT_ID);

                            this.APP_SESSION_TIMEOUT_ID = setTimeout(() => { //Restart automatic logout timer
                                document.cookie = ".AspNetCore.Cookies=dummy; expires=Thu, 01-Jan-1970 00:00:01 GMT"; //Clear cookie
                                window.alert('Session expired at ' + (new Date()).toLocaleTimeString() + ' due to ' + this.SESSION_IDLE_LIMIT_MINUTE.toString() + ' minutes of inactivity');
                                window.location.href = '/login';
                            }, this.SESSION_IDLE_LIMIT_MINUTE * 60 * 1000);

                            let dtExpireOn: Date = new Date(intNow + (this.SESSION_IDLE_LIMIT_MINUTE * 60 * 1000));
                            let strExpireOn: string =
                                (dtExpireOn.getHours().toString().length === 1 ? '0' : '') + dtExpireOn.getHours().toString() + ':' +
                                (dtExpireOn.getMinutes().toString().length === 1 ? '0' : '') + dtExpireOn.getMinutes().toString();

                            this.strSessionExpirationMessage = 'Session will expire at ' + strExpireOn;
                            console.log(this.strSessionExpirationMessage);
                        }
                        catch (ex) {
                            console.error('Error in session cookie keep alive logic when saving new cookie.', ex);
                        }
                    },
                    (objError: any) => {
                        //This handler is entered when session is already expired and HttpContext.SignInAsync() in UtilService.UserLastActiveSave() resulted in
                        //a 302 redirect to /login?returnUrl=util/SaveUserLastActive
                        console.error('AppComponent.ResetSessionTimeoutWarning(): Failed to renew session cookie. Clearing old session cookie.', objError);

                        if (this.APP_SESSION_TIMEOUT_ID)
                            clearTimeout(this.APP_SESSION_TIMEOUT_ID);

                        document.cookie = ".AspNetCore.Cookies=dummy; expires=Thu, 01-Jan-1970 00:00:01 GMT"; //Clear cookie
                        window.alert('Failed to renew session cookie. Session already expired (' + (new Date()).toLocaleTimeString() + ')');
                        window.location.href = '/login';
                    });
            }
            catch (ex) {
                console.error('Error in session cookie keep alive logic.', ex);
            }

            try {
                //REMINDER: This logic is currently ineffective on DEV workstations: MAX.GOV does not allow the domain name to be localhost and Geolocator is currently not registered on TEST.MAX.GOV
                if (this._sharedDataService.blnGeolocatorSessionExists) {
                    console.log('GeoLocator session cookie keep alive logic temporarily attaching IFRAME element.');
                    let objFrame: HTMLIFrameElement = <HTMLIFrameElement>document.createElement('IFRAME');
                    objFrame.src = this.strGeolocatorUrl;
                    objFrame.height = '1px';
                    document.body.appendChild(objFrame);

                    setTimeout(() => {
                        document.body.removeChild(objFrame);
                    }, 5000); //CORS security restrictions prevent interaction with the IFRAME, for example: we can't attach a handler to the load event to determine whether Geolocator finished loading. We can only do a blind wait. 5 seconds is enough to validate the Geolocator cookie and issue a new one if the sliding expiration interval is near expiration
                }
                else
                    console.log('GeoLocator session does not exist yet. GeoLocator session keep alive logic skipped.');
            }
            catch (ex) {
                console.error('Error in GeoLocator session cookie keep alive logic.', ex);
            }
        }
    };

    ngAfterViewInit(): void {
        //WARNING: Do not make any API calls that require authentication until GetAppSettings() async call comes back successfully: we could be trying to use an expired/invalidated session cookie and repeated failed API calls will stop flow of control instead of redirecting the user to authenticate.
        //REMINDER: ngAfterViewInit() is raised before async calls int ngOnInit() come back
    }

    //Get all result set of metadata  - SELECT * FROM [FARS2020].[meta].[TableFieldElements]
    private GetTableFieldElements(): void {
        this._utilService.GetTableFieldElements('', '').subscribe((result) => {
            this.metaDataSet = result.filter(x => x.Field !== null);
        });
    }

    private GetDrpDownOptions(): void {
        this._utilService.formDrpDownOptionShare.subscribe((data) => {
            if (data == null || data == undefined) {
                this._utilService.GetFormDrpDownListOptions('').subscribe((result) => {

                    //console.log('Drpdown options all', result);
                    this.isDrpDownSetLoaded = result.length > 0;
                    this.objDrpDwnOptions = result;
                });
            }
        });
    };

    CheckCookie(): boolean {
        // Quick test if browser has cookieEnabled host property
        if (navigator.cookieEnabled)
            return true;

        // Create cookie
        document.cookie = "cookietest=1";
        let ret: boolean = document.cookie.indexOf("cookietest=") != -1;

        // Clean up (delete) cookie. Expired cookies are removed from document.cookie
        document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
        return ret;
    }

    ngOnDestroy() {
        //Cleaning cookies after we close application; 
        this._sharedDataService.deleteCookie("cookieENFilters");
        this._sharedDataService.deleteCookie("cookieENPageState");

        this._sharedDataService.deleteCookie("cookieCaseSearchScreenerFilters");
        this._sharedDataService.deleteCookie("cookieCaseSearchScreenerPageState");
    }
}

