import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
//services
import { CaseService } from '../../../services/case.service';
import { UtilService } from '../../../services/util.service';
import { SharedDataService } from '../../../services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service'

//models
import { Acc } from '../../../models/acc';
import { PII } from '../../../models/pii';
import { Non_Occupants } from '../../../models/non-occupants';
import { NonOccRF } from '../../../models/non-occ-rf';
import { NonOcc_CondAtCrashTime } from '../../../models/non-occ-cond-at-crash-time';
import { DrpDownOptions } from '../../../models/drp-down-options';
import { Injury, FormID, KeyCode, RBISDataValue, Preference, DBMode } from '../../../models/enums/app.enums';
//components
import { BaseComponent } from 'src/app/helper/basecomponent';
import { LabelComponent } from 'src/app/components/label/label.component';
import { MultiselectComponent } from 'src/app/components/multiselect/multiselect.component';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { ModalsAgeCalculatorComponent } from 'src/app/components/modals/modals-age-calculator/modals-age-calculator.component';


import { NgbDate, NgbDateAdapter, NgbDateNativeAdapter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtil } from 'src/app/helper/objectUtil';

import { NonOcc_ActPriorCrash } from 'src/app/models/non-occ-act-prior-crash';
import { NonOcc_Bike } from 'src/app/models/non-occ-bike';
import { NonOcc_Ped } from 'src/app/models/non-occ-ped';
import { Subscription } from 'rxjs';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { Element_Specify } from 'src/app/models/element-specify';

@Component({
    selector: 'app-non-occupant-non-occupant',
    templateUrl: './non-occupant-non-occupant.component.html',
    styleUrls: ['./non-occupant-non-occupant.component.css'],
    providers: [
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
    ]
})

export class NonOccupantNonOccupantComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_NonOccupantNonOccupantComponent: string = null;
    private sbsNonOccId: Subscription;
    sbsClearForm: Subscription;

    accid: number;
    nonOccid: number;

    public intNumberOfVehicle: number;

    public acc: Acc;
    public nonOccupant: Non_Occupants;
    NonOccupantClone: Non_Occupants;

    /**
     * PII data structure is shared by Occupants and Non_Occupants. As such, there is no foreign key constraint from PII to Occupants or Non_Occupants.
     * Unless saving the entire case from Acc down, some manual data model manipulation is needed to send PII along with a Occupants or Non_Occupants object.
     * This pointer is a shortcut into the Acc.PII array to help with that manipulation.
     **/
    public pii: PII;
    public blnPIIVisible: boolean;

    //focuseOut: boolean = false;
    ageCalculatorPupupOn: boolean = true;

    //TO DO get this arrays from Defs - "dropdownoptions"
    arrNonOccupant: Array<number> = [-1, 4, 9, 10, 19]
    arrBicyclist: Array<number> = [6, 7]
    arrPedestrian: Array<number> = [5, 11, 12, 13]

    objNonOccCondAtCrashTimeTemplate: NonOcc_CondAtCrashTime;
    objNonOccPRFTemplate: NonOccRF;

    accDate: Date;
    nonOccupantDateOfBirth: Date;

    getMinDate: NgbDate;
    getMaxDate: NgbDate;

    intMode: number
    public isNonOccupantInfoVisible: boolean;
    inputElements: NodeListOf<any>;

    public strMinValidation: string;
    public streMaxValidation: string;

    public blnShowValidationMessageNonOcc: boolean = false;
    public blnRequiredNonOcc: boolean = false;
    public blnInvalidNonOcc: boolean = false;
    public blnIsDateInRangeNonOcc: boolean = false;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChildren(MultiselectComponent) multiselects: QueryList<MultiselectComponent>;
    @ViewChildren(LabelComponent) labelComp: QueryList<LabelComponent>;
    @ViewChild('ageField') ageField: TypeaheadComponent;
    @ViewChild('ageCalculator') ageCalculator: ModalsAgeCalculatorComponent;

    @ViewChild('dpDOB') public dpDOB: NgModel;

    objInstanceElementSpecify: Element_Specify;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _preferencesService: SecUserPerferenceService,
        private _autofillService: AutofillService,
        private calendar: NgbCalendar,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    async ngOnInit() {
        super.ngOnInit();
        this.intMode = this._sharedDataService.getMode();

        if (this.printNonOccupantNum == 0) {
            this._route.parent.parent.params.subscribe((params => {
                this.accid = + params['caseid'];
            }).bind(this));
        }
        else {
            this.accid = this.acc.AccID;
        }

        if (this.sbsNonOccId) {
            this.sbsNonOccId.unsubscribe();
        }
        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(async params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
        }
    }

    public FormLoad() {
        if (this.printNonOccupantNum != 0) {
            this.nonOccid = this.printNonOccupantNum;
        }

        this.accDate = new Date(this.acc.CaseYear, this.acc.AccMon - 1, this.acc.AccDay, this.acc.AccHr, this.acc.AccMin, 0, 0);

        if (this.accDate) {
            this.getMinDate = new NgbDate(this.accDate.getFullYear() - 120, this.accDate.getMonth() + 1, this.accDate.getDate());
            this.getMaxDate = new NgbDate(this.accDate.getFullYear(), this.accDate.getMonth() + 1, this.accDate.getDate());
        }
        else {
            let today = new Date();
            this.getMinDate = new NgbDate(today.getFullYear() - 120, today.getMonth() + 1, today.getDate());
            this.getMaxDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
        }

        this.strMinValidation = (this.getMinDate.month).toString() + "/" + this.getMinDate.day.toString() + "/" + this.getMinDate.year.toString();
        this.streMaxValidation = (this.getMaxDate.month).toString() + "/" + this.getMaxDate.day.toString() + "/" + this.getMaxDate.year.toString();


        this.isNonOccupantInfoVisible = false;

        this.blnPIIVisible = false;
        this.nonOccupantDateOfBirth = null;

        this._sharedDataService.setNonOccupantid(this.nonOccid);

        this.intNumberOfVehicle = this.acc.Veh.length;

        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);

        if (this.nonOccupant) {
            //this.focuseOut = false;
            //setTimeout(() => { 
            //    this.ageField.focus$.subscribe(async () => {
            //        await this._preferencesService.isThisPreferenceOn(Preference.AgeCalculaterPopUp).then(item => {
            //            this.ageCalculatorPupupOn = item;
            //        });
            //        if (this.ageCalculatorPupupOn && this.nonOccupant.Age == -1 && !this.focuseOut) {
            //            this.focuseOut = true;
            //            this.ageCalculator.btnOpen.nativeElement.click();
            //        }
            //    });
            //}, 1000);

            this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, 0, this.nonOccupant.PNumber);

            this.objNonOccPRFTemplate = ObjectUtil.InstantiateNonOccPrf(this.nonOccupant);
            this.objNonOccCondAtCrashTimeTemplate = ObjectUtil.InstantiateNonOccCondAtCrashTime(this.nonOccupant);

            this.NonOccupantClone = ObjectUtil.CloneModelObject(this.nonOccupant); //Copy original data model in case user decides to abandon changes and revert to the original

            this.pii = this.acc.PII.find(p => p.AccID == this.nonOccupant.AccID && p.VNumber == 0 && p.PNumber == this.nonOccupant.PNumber);

            if (this.pii !== null && this.pii !== undefined) {
                //DO NOT create complex crosslinks in data model as RemoveBidirectionalLinks does not distinguish between normal bidirectional links and
                //complex cross links, i.e. cloning of comlex crosslinks will produce unpredictable results. So, DON'T DO THIS: this.nonOccupant.PII = this.pii;
                //and try to clone it afterwards.
                this.NonOccupantClone.PII = ObjectUtil.CloneModelObject(this.pii);

                if (this.isValidDate(this.pii.DoB)) {
                    this.nonOccupantDateOfBirth = new Date(this.pii.DoB);
                }
                if (typeof this.pii.DoB === "string") {
                    this.nonOccupantDateOfBirth = new Date(this.pii.DoB);
                }
                if (this.pii.DoB === null) {
                    this.nonOccupantDateOfBirth = this.pii.DoB;
                }
            }

            if (this.nonOccupant.Injury === Injury.Fatal) {
                if (!this.pii || this.pii === undefined) {
                    this.pii = this._autofillService.AddNonOccupantsPII(this.nonOccupant); //Add PII object to Non_Occupants.Acc.PII and returns it.
                }
                if (this.isValidDate(this.pii.DoB)) {
                    this.nonOccupantDateOfBirth = new Date(this.pii.DoB);
                }
                if (typeof this.pii.DoB === "string") {
                    this.nonOccupantDateOfBirth = new Date(this.pii.DoB);
                }
                if (this.pii.DoB === null) {
                    this.nonOccupantDateOfBirth = this.pii.DoB;
                }

                this.blnPIIVisible = true;
            }

            if (this.nonOccupant.PType == 5) {
                this._utilService.getWizardOptions('ped');
            }
            if (this.nonOccupant.PType == 6) {
                this._utilService.getWizardOptions('bike');
            }
        }

        //Listen subscription for Clear Form btn event click;
        this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe(async item => {
            console.log('this.nonOccupant.Injury: ', this.nonOccupant.Injury);
            setTimeout(() => {//setting Timeout for model to get updated before subjectClearForm subscription occure!
                this.blnPIIVisible = false;
                this.nonOccupantDateOfBirth = null;
                this.blnPIIVisible = false;
                this.acc.PII.splice(this.acc.PII.findIndex(x => x == this.pii));
                this.pii = null;
                this.NonOccupantClone.PII = null;
                this.NonOccupantClone = ObjectUtil.CloneModelObject(this.nonOccupant); //Copy original data model in case user decides to abandon changes and revert to the original
                console.log('this.nonOccupant.Injury: ', this.nonOccupant.Injury);
            }, 1);
        });
    }

    async OnModelChange($event) { // Using emiter for Injury to set PII obj
        if (this.NonOccupantClone.Injury != this.nonOccupant.Injury && this.nonOccupant.Injury !== RBISDataValue.Blank) {
            if (this.intMode == DBMode.MOSS || this.intMode == DBMode.PPSS || this.intMode == DBMode.MTSS) {
                this.NonOccupantClone.Injury = this.nonOccupant.Injury;
                this.nonOccupantDateOfBirth = null;
                this.blnPIIVisible = false;
                this.acc.PII.splice(this.acc.PII.findIndex(x => x == this.pii));
                this.pii = null;
                this.NonOccupantClone.PII = null;
            }
            else if (this.NonOccupantClone.Injury !== Injury.Fatal && this.nonOccupant.Injury === Injury.Fatal) { //initializing empty PII obj if it is not exist          
                this.pii = this._autofillService.AddNonOccupantsPII(this.nonOccupant);  //Add PII object to Non_Occupants.Acc.PII and returns it.
                this.NonOccupantClone.Injury = this.nonOccupant.Injury;
                this.blnPIIVisible = true;
            }
            else if (this.NonOccupantClone.Injury === Injury.Fatal && this.nonOccupant.Injury !== Injury.Fatal) { //dialog
                let allowSaveForm = await this._modalService.dialogPromise("You have changed the Injury Severity of the Non-Occupant. This would delete the Person Identification Information form and all ata in it. Are you sure you want to proceed with this?", "Are you sure?");

                if (!allowSaveForm) { // No
                    this.blnPIIVisible = true;
                    this.nonOccupant.Injury = this.NonOccupantClone.Injury;
                    let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == FieldName.Injury) as TypeaheadComponent;
                    let item = typeahead.options.find(x => x.intValue == this.nonOccupant.Injury);
                    typeahead.bindItemAndFocusNextElement(item, false);
                }
                else {//Yes
                    this.NonOccupantClone.Injury = this.nonOccupant.Injury;
                    this.nonOccupantDateOfBirth = null;
                    this.blnPIIVisible = false;
                    this.acc.PII.splice(this.acc.PII.findIndex(x => x == this.pii));
                    this.pii = null;
                    this.NonOccupantClone.PII = null;
                }
            }
        }
    }

    renderBackPType(intNum: number) {
        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == FieldName.PType) as TypeaheadComponent;
        let item = typeahead.options.find(x => x.intValue == intNum);

        typeahead.bindItemAndFocusNextElement(item);
    }

    public async onBeforeSave() {
        let allowSaveForm: boolean;

        if (this.dpDOB) {
            if (this.dpDOB.invalid) {
                //if (this.dpDOB.errors.required) {
                //    this._modalService.setMessage('Date of Birth is required.', 'danger');
                //    this.blnAlloweSave = false;
                //    return;
                //}
                if (this.dpDOB.errors.ngbDate.invalid) {
                    this._modalService.setMessage('Date of Birth must be a valid date (Ex: mm/dd/yyyy).', 'danger');
                    this.blnAlloweSave = false;
                    return;
                }
                if (this.dpDOB.errors.ngbDate.requiredBefore || this.dpDOB.errors.ngbDate.requiredAfter) {
                    this._modalService.setMessage("Date of Birth  must be between " + this.strMinValidation + " and " + this.streMaxValidation + ".", 'danger');
                    this.blnAlloweSave = false;
                    return;
                }
            }
        }

        if ((this.NonOccupantClone.PType != this.nonOccupant.PType) && this.intMode != DBMode.MOSS) {
            if (this.arrBicyclist.includes(this.NonOccupantClone.PType)
                && (this.arrNonOccupant.includes(this.nonOccupant.PType) || this.arrPedestrian.includes(this.nonOccupant.PType))) { //Remove Bicyclist and if PType is in  [5, 11, 12, 13] add Pedestrian

                allowSaveForm = await this._modalService.dialogPromise("You have changed the Person type of the Non-Occupant from " + this.NonOccupantClone.PType + " to " + (this.nonOccupant.PType !== -1 ? this.nonOccupant.PType : ' ') + ". This would delete the Bicyclist form and all data in it. Are you sure you want to proceed with this?", "Are you sure?");

                if (!allowSaveForm) { // No                  
                    this.nonOccupant = ObjectUtil.CloneModelObject(this.NonOccupantClone);

                    let item = this.acc.Non_Occupants.findIndex(p => p.PNumber == this.nonOccupant.PNumber);
                    this.acc.Non_Occupants.splice(item, 1, this.nonOccupant);

                    this.nonOccupant.Acc = this.acc;
                    this.renderBackPType(this.nonOccupant.PType);
                }
                else {//Yes                  
                    this.nonOccupant.NonOcc_Bike = null;
                    let index = this.acc.RBISWizardResults.findIndex(x => x.ACCID == this.accid && x.PNUMBER == this.nonOccupant.PNumber)
                    if (index >= 0) {
                        this.acc.RBISWizardResults.splice(index, 1);
                    }
                    if (this.arrPedestrian.includes(this.nonOccupant.PType)) {// if PType == ped, add ped
                        this._autofillService.AddPedestrian(this.nonOccupant);
                        let item = this.acc.Non_Occupants.findIndex(p => p.PNumber == this.nonOccupant.PNumber);
                        this.acc.Non_Occupants.splice(item, 1, this.nonOccupant);
                    }
                    this.NonOccupantClone = ObjectUtil.CloneModelObject(this.nonOccupant);
                }
            }

            else if (this.arrPedestrian.includes(this.NonOccupantClone.PType) &&
                (this.arrNonOccupant.includes(this.nonOccupant.PType) || this.arrBicyclist.includes(this.nonOccupant.PType))) { // Remove Pedestrian if PType is in [6, 7] add Bicyclist

                allowSaveForm = await this._modalService.dialogPromise("You have changed the Person type of the Non-Occupant from " + this.NonOccupantClone.PType + " to " + (this.nonOccupant.PType !== -1 ? this.nonOccupant.PType : ' ') + ". This would delete the Pedestrian form and all data in it. Are you sure you want to proceed with this?", "Are you sure?");

                if (!allowSaveForm) { // No
                    this.nonOccupant = ObjectUtil.CloneModelObject(this.NonOccupantClone);

                    let item = this.acc.Non_Occupants.findIndex(p => p.PNumber == this.nonOccupant.PNumber);
                    this.acc.Non_Occupants.splice(item, 1, this.nonOccupant);

                    this.nonOccupant.Acc = this.acc;
                    this.renderBackPType(this.nonOccupant.PType);
                }
                else {//Yes                    
                    this.nonOccupant.NonOcc_Ped = null;
                    let index = this.acc.RBISWizardResults.findIndex(x => x.ACCID == this.accid && x.PNUMBER == this.nonOccupant.PNumber)
                    if (index >= 0) {
                        this.acc.RBISWizardResults.splice(index, 1);
                    }
                    if (this.arrBicyclist.includes(this.nonOccupant.PType)) { // if PType == bike, add bike
                        this._autofillService.AddBicyclist(this.nonOccupant);
                        let item = this.acc.Non_Occupants.findIndex(p => p.PNumber == this.nonOccupant.PNumber);
                        this.acc.Non_Occupants.splice(item, 1, this.nonOccupant);
                    }
                    this.NonOccupantClone = ObjectUtil.CloneModelObject(this.nonOccupant);
                }
            }
            else if (this.arrNonOccupant.includes(this.NonOccupantClone.PType) &&
                (this.arrPedestrian.includes(this.nonOccupant.PType) || this.arrBicyclist.includes(this.nonOccupant.PType))) { // if PType from NonOcc to bike or ped, add bike/ped based on NEW PTYPE

                if (this.arrPedestrian.includes(this.nonOccupant.PType)) {
                    this._autofillService.AddPedestrian(this.nonOccupant);
                    let item = this.acc.Non_Occupants.findIndex(p => p.PNumber == this.nonOccupant.PNumber);
                    this.acc.Non_Occupants.splice(item, 1, this.nonOccupant);
                }
                else {
                    this._autofillService.AddBicyclist(this.nonOccupant);
                    let item = this.acc.Non_Occupants.findIndex(p => p.PNumber == this.nonOccupant.PNumber);
                    this.acc.Non_Occupants.splice(item, 1, this.nonOccupant);
                }
                this.NonOccupantClone = ObjectUtil.CloneModelObject(this.nonOccupant);

                allowSaveForm = true;
            }

            else {
                allowSaveForm = true;
            }
        }
        else {
            allowSaveForm = true;
        }

        this.blnAlloweSave = allowSaveForm;
    }

    isValidDate(d): boolean {
        return d instanceof Date && !isNaN(d.getMonth());
    }

    setDateOfBirth(event: Date) {
        this.MarkFormAsDirty();
        if (this.blnPIIVisible) {
            this.pii.DoB = event;
        }
        this.nonOccupantDateOfBirth = event;
    }

    async onPIINameChange($event) {
        this.acc.PII.find(p => p.AccID == this.nonOccupant.AccID && p.VNumber == 0 && p.PNumber == this.nonOccupant.PNumber).PName = $event;
    }

    async onPIIComment1Change($event) {
        this.acc.PII.find(p => p.AccID == this.nonOccupant.AccID && p.VNumber == 0 && p.PNumber == this.nonOccupant.PNumber).Comment1 = $event;
    }

    async onPIIComment2Change($event) {
        this.acc.PII.find(p => p.AccID == this.nonOccupant.AccID && p.VNumber == 0 && p.PNumber == this.nonOccupant.PNumber).Comment2 = $event;
    }

    onDateSelect(dpDOB: NgModel) {
        ObjectUtil.DateMask(event);
        this._modalService.resetMessage();
        this.blnShowValidationMessageNonOcc = false;
        this.blnInvalidNonOcc = false;
        this.blnIsDateInRangeNonOcc = false;
        this.blnRequiredNonOcc = false;
        if (!dpDOB.invalid) {
            this.pii.DoB = new Date(dpDOB.value);
            this.nonOccupantDateOfBirth = dpDOB.value;
            this.acc.PII.find(p => p.AccID == this.nonOccupant.AccID && p.VNumber == 0 && p.PNumber == this.nonOccupant.PNumber).DoB = dpDOB.value;
        }
        else if (dpDOB.value === null) {
            this.pii.DoB = dpDOB.value;
            this.acc.PII.find(p => p.AccID == this.nonOccupant.AccID && p.VNumber == 0 && p.PNumber == this.nonOccupant.PNumber).DoB = dpDOB.value;
        }
    }

    calculateAge(): number {
        //this.accDate = new Date(this.accDate);
        //let acccYear = this.accDate.getFullYear();
        //let dateOfBirth = new Date(this.pii.DoB);
        //let birthYear = dateOfBirth.getFullYear();
        //let age = acccYear - birthYear;
        //if (this.accDate <= new Date(dateOfBirth.setFullYear(acccYear))) {
        //    age = age - 1;
        //}
        //return age;

        let PickedDate = new Date(this.pii.DoB);

        let accYear = this.accDate.getFullYear();
        let accmonth = this.accDate.getMonth();
        let accday = this.accDate.getDate();

        let birthyear = PickedDate.getFullYear();
        let birthmonth = PickedDate.getMonth();
        let birthday = PickedDate.getDate();

        let age = accYear - birthyear;
        let age_month = accmonth - birthmonth;
        let age_day = accday - birthday;

        if (age_month < 0 || (age_month == 0 && age_day < 0)) {
            age = age - 1;
        }

        return age;




    }

    onBlur(dpDOB) {
        this.blnShowValidationMessageNonOcc = false;
        this.blnRequiredNonOcc = false;
        this.blnInvalidNonOcc = false;
        this.blnIsDateInRangeNonOcc = false;
        if (dpDOB.value) {
            if (dpDOB.invalid) {
                this.blnShowValidationMessageNonOcc = true;
                if (dpDOB.errors.required) {
                    this._modalService.setMessage('Date of Birth is required.', 'danger');
                }
                if (dpDOB.errors.ngbDate.invalid) {
                    this._modalService.setMessage('Date of Birth must be a valid date (Ex: mm/dd/yyyy).', 'danger');
                }
                if (dpDOB.errors.ngbDate.requiredBefore || dpDOB.errors.ngbDate.requiredAfter) {
                    this._modalService.setMessage("Date of Birth  must be between " + this.strMinValidation + " and " + this.streMaxValidation + ".", 'danger');
                }
            }
            else {
                this._modalService.resetMessage();
                this.pii.DoB = new Date(dpDOB.value);
                this.nonOccupantDateOfBirth = dpDOB.value;

                let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "Age") as TypeaheadComponent;
                let item = typeahead.options.find(x => x.intValue == this.calculateAge());
                typeahead.bindItemAndFocusNextElement(item, false);
                let txtComment1: HTMLElement = document.getElementById('Comment1');

                if (txtComment1)
                    txtComment1.focus();
            }
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    handleKeyup(event: KeyboardEvent) {
        if (event.code == KeyCode.F9 || event.code == KeyCode.Minus || event.code == KeyCode.NumpadSubtract) {
            this.ageCalculator.btnOpen.nativeElement.click();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsClearForm) {
            this.sbsClearForm.unsubscribe();
        }
    }
}
