import { Router, ActivatedRoute, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
import { OnInit, Injectable } from '@angular/core';

@Injectable()
export class UrlTreeHelper {

    public static CrashTabs: Array<string> = ['crash', 'events', 'trafficway', 'other', 'special', 'crashnotes','nonmotorist'];
    public static NonOccupantTabs: Array<string> = ['nonOccupant', 'nonOther', 'actions', 'alcohol', 'drug', 'race', 'supplemental', 'pedestrian', 'bike', 'nonMotoristActions', 'bicyclist','injury','nonOccNotes'];
    public static VehicleTabs: Array<string> = ['vehicle', 'damagedAreas', 'vin', 'vehicleDetails', 'cmv', 'vehicleOther', 'vehicleTrailer', 'rollover', 'view', 'vehicleNotes', 'vehnonmotorist'];
    public static DriverTabs: Array<string> = ['driver', 'license', 'counters', 'driverNotes'];    
    public static PrecrashTabs: Array<string> = ['roadway', 'precrash', 'precrashOther', 'strikingprecrash', 'strikingprecrashother'];
    public static PersonTabs: Array<string> = ['person', 'safetyDevices', 'personAlcohol', 'personDrug', 'personRace', 'personSupplemental', 'personNotes'];
    public static AvoidanceEquipment: Array<string> = ['avoidanceEquipment'];

    public static MossVehicleTabs: Array<string> = ['vehicle', 'vin', 'control', 'other', 'notes', 'strikingother', 'sfrprecrashother', 'vehicleother'];
    public static MossPersonTabs: Array<string> = ['person', 'safety', 'personalcohol', 'personnotes'];

    public static StrikeVehicleTabs: Array<string> = ['strikingvehicle', 'strikingvin', 'strikingother', 'strikingnotes'];
    //public static StrikingDriverTabs: Array<string> = ['strikingdriver', 'strikingdrivernotes'];
    public static StrikePersonTabs: Array<string> = ['strikingperson', 'strikingsafety', 'strikingpersonalcohol', 'strikingdriver', 'strikingdrivernotes','strikingpersonnotes'];

    public static SFRVehicleTabs: Array<string> = ['sfrvehicle', 'sfrvin', 'sfrcontrol', 'sfrother', 'sfrnotes'];
    public static SFRPersonTabs: Array<string> = ['sfrperson', 'sfrsafety', 'sfrpersonalcohol', 'sfrpersonnotes'];
    

    constructor(protected _activatedRoute: ActivatedRoute,
        protected _router: Router) { }

    public get arrUrlSegment(): UrlSegment[] {
        const urlC: UrlTree = this._router.parseUrl(this._router.url);
        const segmentGroup: UrlSegmentGroup = urlC.root.children[PRIMARY_OUTLET];
        if (segmentGroup) {
            return segmentGroup.segments;
        }
        return null;
    }

    public static FindAndSetPathId(strPath: string, urlSegments: UrlSegment[]): number {
        try {
            if (urlSegments)
                return parseInt(urlSegments[urlSegments.findIndex(x => x.path === strPath) + 1].path);
            return null;
        } catch (error) {
            return null;
        }

    }

    public static FindAndSetStateNum(strPath: string, urlSegments: UrlSegment[]): number {
        try {
            if (urlSegments)
                return parseInt(urlSegments[urlSegments.findIndex(x => x.path === strPath) - 1].path);
            return null;
        } catch (error) {
            return null;
        }
    }

    public static FindAndSetCurrentTab(urlSegments: UrlSegment[]): string {
        try {
            if (urlSegments)
                return urlSegments[urlSegments.length - 1].path;
            return null;
        } catch (error) {
            return null;
        }
    }


}
