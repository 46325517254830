<div *ngIf="veh" class="border-left border-bottom border-right bg-light">
    <ul class="nav nav-tabs border-dark border-left">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./strikingvehicle"]'
               [ngClass]="{'bg-light': linkstrikingvehicle.isActive !== true }"
               [routerLinkActive]="['active']" #linkstrikingvehicle="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkstrikingvehicle.isActive === true, 'fw-bold' : linkstrikingvehicle.isActive === true}">
                        <!--Striking_Vehicle_V{{vehicleid}}-->
                        <!--Striking_Vehicle_V{{veh.Veh_SS.SSVNumber}}-->
                        Striking_Vehicle
                    </span>
                </span>
            </a>
        </li>

        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingvin"]'
               [ngClass]="{'bg-light': linkstrikingVehVIN.isActive === true }"
               [routerLinkActive]="['active']" #linkstrikingVehVIN="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkstrikingVehVIN.isActive === true, 'fw-bold' : linkstrikingVehVIN.isActive === true}">
                        Striking_VIN
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingnotes"]'
               [ngClass]="{'bg-light': linkstrikingVehNotes.isActive === true }"
               [routerLinkActive]="['active']" #linkstrikingVehNotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkstrikingVehNotes.isActive === true, 'fw-bold' : linkstrikingVehNotes.isActive === true}">
                        Striking_Notes
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./strikingvehicleVINDecode"]'
               [ngClass]="{'bg-light': linkVehVINDecode.isActive === true }"
               [routerLinkActive]="['active']" #linkVehVINDecode="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehVINDecode.isActive === true, 'fw-bold' : linkVehVINDecode.isActive === true}">
                        Striking_VIN Decode
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <router-outlet></router-outlet>
</div>
