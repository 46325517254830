<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Alcohol Test Result Calculator</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="form-group">
            <p class="border-bottom border-light aliceblue">This window allows you to convert a BAC value from the report to the corresponding Alcohol Test Result code.</p>
        </div>

        <div class="form-group">
            <p>Please enter the BAC value and select the unit as seen in the report.</p>
        </div>

        <div class="form-inline">
            <div class="row text-center">
                <div class="col-12">
                    <p *ngIf="warning!=''" class="alert-danger">
                        {{warning}}
                    </p>
                </div>

                <div class="mb-3 row">
                    <label for="inputBAC" class="col-auto col-form-label">BAC : </label>
                    <div class="col-auto">
                        <input type="text" name="inputBAC" class="form-control" [(ngModel)]="bac" (change)="unitChange()" id="convertorValue" />
                    </div>
                    <div class="col-auto">
                        <select class="form-select" (change)="unitChange()" id="convertorOption" [(ngModel)]="alcUnit">
                            <option selected value="-1">Choose...</option>
                            <option value="1">g/mL</option>
                            <option value="2">mg/dL</option>
                            <option value="3">g/100mL</option>
                            <option value="4">g/dL</option>
                            <option value="5">g/100cc</option>
                            <option value="6">%</option>
                        </select>
                    </div>
                    <div class="col-auto">
                        <button type="button" class="btn btn-light border-secondary" (click)="convert()">Convert</button>
                    </div>
                </div>
                <div class="col-12">
                    <p *ngIf="fontColor=='red'" style="height:35px; color:red" class="p-1 m-1">{{resultText}} </p>
                    <p *ngIf="fontColor!='red'" style="height:35px; color:red" class="p-1 m-1">{{resultText}} </p>
                </div>
                <div class="mb-3 row">
                    <label for="inputResult" class="col-auto col-form-label">Calculated Alcohol Test Result : </label>
                    <div class="col-auto">
                        <input type="text" name="inputResult" class="form-control" [value]="result" disabled />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex">
        <p *ngIf="strForm !== 'Home'" class="mr-auto font-italic">Please click Submit to accept the value</p>
        <button *ngIf="strForm !== 'Home'" type="button" class="btn btn-primary" (click)="modal.close('Submit'); onSubmit()">Submit</button>
        <button type="button" class="btn btn-light border-secondary" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-light border-secondary" (click)="onClear()">Clear</button>
    </div>
</ng-template>

<span>
    <button #btnOpen *ngIf="strForm === 'Home'" class="btn btn-link px-0" (click)="open(content, null)">Alcohol Calculator</button>

    <span class="">
        <button #btnOpen *ngIf="strForm !== 'Home'" class="btn btn-sm btn-light border-secondary" (click)="open(content, null)">Alcohol Calculator</button>
    </span>
</span>


<!--<pre>{{closeResult}}</pre>-->
