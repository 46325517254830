<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous">
<app-loading-form></app-loading-form>
<body class="bg-dark-grey">
    <app-nav-menu *ngIf="!isPrintMode"></app-nav-menu>
    <div *ngIf="isDrpDownSetLoaded" class="container-fluid">
        <div class="row pr-4" *ngIf="!isPrintMode">
            <div class="offset-0 offset-sm-0 offset-md-4 offset-lg-0 offset-xl-4 col-12 col-sm-12 col-md-8 col-lg-4 col-xl-3" *ngIf="isSearchVisible">
                <app-search-case></app-search-case>
            </div>
            <div class="offset-0 offset-sm-0 offset-md-4 offset-lg-0 offset-xl-4 col-12 col-sm-12 col-md-8 col-lg-4 col-xl-3" *ngIf="!isSearchVisible">
                <p></p>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-5">
                <app-user></app-user>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <!--<app-footer></app-footer>-->
</body>
