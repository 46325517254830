<div *ngIf="veh" class="border-dark border-start border-bottom border-end px-2">
    <ul class="nav nav-tabs border-dark bg-white">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./vehicle"]'
               [ngClass]="{'bg-light': linkVehVehicle.isActive === true }"
               [routerLinkActive]="['active']" #linkVehVehicle="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehVehicle.isActive === true, 'fw-bold' : linkVehVehicle.isActive === true}">
                        Vehicle
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1"  *ngIf="intMode ==128">
            <a class="nav-link" [routerLink]='["./damagedAreas"]'
               [ngClass]="{'bg-light': linkVehDamagedAreas.isActive === true }"
               [routerLinkActive]="['active']" #linkVehDamagedAreas="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehDamagedAreas.isActive === true, 'fw-bold' : linkVehDamagedAreas.isActive === true}">Damaged Areas</span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./vin"]'
               [ngClass]="{'bg-light': linkVehVIN.isActive === true }"
               [routerLinkActive]="['active']" #linkVehVIN="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehVIN.isActive === true, 'fw-bold' : linkVehVIN.isActive === true}">
                        VIN
                    </span>
                </span>
            </a>
        </li>

        <li class="nav-item mt-1" *ngIf="intMode !=128 && (_caseService.isShowTab$ | async) && intMode != 256">
            <a class="nav-link" [routerLink]='["./vehicleDetails"]'
               [ngClass]="{'bg-light': linkVehDetails.isActive === true }"
               [routerLinkActive]="['active']" #linkVehDetails="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehDetails.isActive === true, 'fw-bold' : linkVehDetails.isActive === true}">
                        Details
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="intMode != 256">
            <a class="nav-link" [routerLink]='["./vehicleOther"]'
               [ngClass]="{'bg-light': linkVehOther.isActive === true }"
               [routerLinkActive]="['active']" #linkVehOther="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehOther.isActive === true, 'fw-bold' : linkVehOther.isActive === true}">
                        Other
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="intMode !=128 && veh.Trailer != -1 && (_caseService.isShowTab$ | async) && intMode != 256">
            <a class="nav-link" [routerLink]='["./vehicleTrailer"]'
               [ngClass]="{'bg-light': linkVehTrailer.isActive === true }"
               [routerLinkActive]="['active']" #linkVehTrailer="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehTrailer.isActive === true, 'fw-bold' : linkVehTrailer.isActive === true}">
                        Trailer
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item ml-1 mt-1" *ngIf="intMode !=128  && (_caseService.isShowTab$ | async) && intMode != 256">
            <a class="nav-link" [routerLink]='["./rollover"]'
               [ngClass]="{'bg-light': linkVehRollover.isActive === true }"
               [routerLinkActive]="['active']" #linkVehRollover="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehRollover.isActive === true, 'fw-bold' : linkVehRollover.isActive === true}">
                        Rollover
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item ml-1 mt-1" *ngIf=" intMode !=128 && (_caseService.isShowTab$ | async) && intMode != 256">
            <a class="nav-link" [routerLink]='["./view"]'
               [ngClass]="{'bg-light': linkVehView.isActive === true }"
               [routerLinkActive]="['active']" #linkVehView="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehView.isActive === true, 'fw-bold' : linkVehView.isActive === true}">
                        View
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item ml-1 mt-1" *ngIf="intMode==128 && intMode != 256">
            <a class="nav-link" [routerLink]='["./vehnonmotorist"]'
               [ngClass]="{'bg-light': linkVehNotes.isActive === true }"
               [routerLinkActive]="['active']" #linkVehNotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehNotes.isActive === true, 'fw-bold' : linkVehNotes.isActive === true}">
                        Non-Motorist
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./vehicleNotes"]'
               [ngClass]="{'bg-light': linkVehNotes.isActive === true }"
               [routerLinkActive]="['active']" #linkVehNotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehNotes.isActive === true, 'fw-bold' : linkVehNotes.isActive === true}">
                        Notes
                    </span>
                </span>
            </a>
        </li>
        <!--<li class="nav-item ml-1 mt-1" *ngIf="intMode !=128 && (_caseService.isShowTab$ | async)">
        <a class="nav-link" [routerLink]='["./truck"]'
           [ngClass]="{'bg-light': linkVehTruck.isActive === true }"
           [routerLinkActive]="['active']" #linkVehTruck="routerLinkActive">
            <span class="text-dark">
                <span [ngClass]="{'text-decoration-none': linkVehTruck.isActive === true, 'fw-bold' : linkVehTruck.isActive === true}">
                    Truck
                </span>
            </span>
        </a>
    </li>-->
        <!--<li class="nav-item ml-1 mt-1" *ngIf="intMode !=128">
        <a class="nav-link" [routerLink]='["./nontruck"]'
           [ngClass]="{'bg-light': linkVehNonTruck.isActive === true }"
           [routerLinkActive]="['active']" #linkVehNonTruck="routerLinkActive">
            <span class="text-dark">
                <span [ngClass]="{'text-decoration-none': linkVehNonTruck.isActive === true, 'fw-bold' : linkVehNonTruck.isActive === true}">
                    Non-Truck
                </span>
            </span>
        </a>
    </li>-->
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./vehicleVINDecode"]'
               [ngClass]="{'bg-light': linkVehVINDecode.isActive === true }"
               [routerLinkActive]="['active']" #linkVehVINDecode="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkVehVINDecode.isActive === true, 'fw-bold' : linkVehVINDecode.isActive === true}">
                        VIN Decode
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <!--here we generate and show Vehicle Vehicle's-children html's components-->
    <router-outlet></router-outlet>
</div>
