import { Component, OnInit, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { Acc } from 'src/app/models/acc';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { KeyCode, DBMode } from 'src/app/models/enums/app.enums';
import { ModalService } from 'src/app/services/modal.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { EditCheckHelper } from 'src/app/helper/EditCheckHelper';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';

@Component({
    selector: 'app-search-case',
    templateUrl: './search-case.component.html',
    styleUrls: ['./search-case.component.css']
})
export class SearchCaseComponent implements OnInit {
    public blnVisible: boolean = true;
    selectedYear: number;
    selectedMode: number;

    public intCaseNumber: number = null;
    public strMossCaseNumber: string = null;

    public intTypedCharacters: number = 0;

    maxlength: number = 4;
    public _editCheckHelper: EditCheckHelper;

    blnSearchBoxEnable: boolean = true;

    @ViewChild('searchCase') public objTextbox: ElementRef;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        private _modalService: ModalService,
        protected _urlTreeHelper: UrlTreeHelper,
        public earlyNotificationService: EarlyNotificationService
    ) {
        //super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper);
        this._editCheckHelper = new EditCheckHelper(_sharedDataService, _modalService, _urlTreeHelper);
    }

    async ngOnInit() {
        this._route.params.subscribe((() => { //This fires only the first time the page loads (because subsequent navigation is for child active routes, lower in ther active route hierarchy, and don't bubble up)
            this.blnVisible = SearchCaseComponent.FindRouteSegment(this._route, '/caseEdit/') == null;
        }).bind(this));
        this._router.events.subscribe((() => { //This fires on navigation actions after the first page load (but not on first page load)
            this.blnVisible = SearchCaseComponent.FindRouteSegment(this._route, '/caseEdit/') == null;
        }).bind(this));

        let appSettings = await this._sharedDataService.GetAppSettings();
        this.selectedYear = appSettings.intYear;
        this.selectedMode = appSettings.intMode;
        this.blnSearchBoxEnable = (-1 & this.selectedMode) == this.selectedMode;
        
        if (this.selectedMode == DBMode.MOSS) {
            this._caseService.getStrObsCaseNumber.subscribe((item => {
                if (this.strMossCaseNumber != item)
                    this.strMossCaseNumber = item;
            }).bind(this));
        }
        else {
            this._caseService.getIntObsCaseNumber.subscribe((item => {
                if (this.intCaseNumber != item)
                    this.intCaseNumber = item;
            }).bind(this));
        }
    }

    //Took logic from textfield component,it behave slightly different when we input digits but it works...
    OnModelChange(strValue: string) {
        this.intTypedCharacters = this.objTextbox.nativeElement.value.length;
        if (strValue === "" || strValue === null) {
            this.intCaseNumber = null;
            return;
        }

        if (this.intCaseNumber) {
            if (this.intTypedCharacters >= this.maxlength) { //DecimalPipe fires after OnChange, so leading zeros may temporarily make the value length greater than maxlength
                if (strValue.replace(/0/g, '') == '') //Must manually handle all zero input string as DecimalPipe will ignore an all 000 input string and moreover prevent other pipes from processing it.
                    this.objTextbox.nativeElement.value = strValue.substr(0, this.maxlength);
            }
        }
    }

    onPaste(event: ClipboardEvent) {
        let strText: string = event.clipboardData.getData('text').trim();
        this.objTextbox.nativeElement.value = strText;
    }

    //Took logic from textfield component,it behave slightly different when we input digits but it works...
    public handleKeydown(event: KeyboardEvent) {
        let strReplaceTarget: string = this.getSelectionText(); //If backspace targeted a selection of multiple characters, reduce by more than one

        if (strReplaceTarget === null)
            strReplaceTarget = '';

        if (event.key == KeyCode.Backspace) {
            if (this.objTextbox.nativeElement.value.length == 0) { //At this point this.objTextbox.nativeElement.value is not updated yet.
                this.intTypedCharacters = 0;
                return;
            }
            else
                this.intTypedCharacters = Math.max(0, strReplaceTarget.length > 0 ? this.intTypedCharacters - strReplaceTarget.length : this.intTypedCharacters - 1);
        }
        else if (event.key.match(/^[a-zA-Z0-9]$/) != null)
            this.intTypedCharacters = Math.max(0, this.intTypedCharacters + 1 - strReplaceTarget.length);
    }

    //Took logic from textfield component,it behave slightly different when we input digits but it works...
    private getSelectionText(): string {
        let strSelectedText: string = window.getSelection().toString();
        return strSelectedText;
    }

    public onSearchMossNum(): void {
        let mossCaseNum = this.objTextbox.nativeElement.value;

        if (mossCaseNum == '' || mossCaseNum.length == 0) {
            this._modalService.setMessage('MOSS number search cannot perform without MOSS Number. Enter MOSS Number', 'warning', 20);
        }
        else {
            this._editCheckHelper.ClearViolatedElement();
            this._caseService.clearAccMemoryForCloseCase(); //The case could have been opened by somebody else. We want to make sure we're bypassing the cache and obtaining a proper case lock again.

            this._caseService.GetCaseByMossCaseNumberPromise(mossCaseNum, this.selectedYear).then(data => {
                if (this._caseService.acc) {
                    this._router.navigate([this._caseService.acc.StateNum, 'case', this._caseService.acc.AccID, 'crash', this._caseService.acc.AccID]);
                }
                else {
                    let strMessage: string = 'There is no case with case number ' + mossCaseNum.toString() + ' in year ' + this.selectedYear + ' for Moss';

                    this._modalService.setMessage(strMessage, 'danger', 20);
                }
            });

        }
    }

    public onSearch(): void {
        const CaseNum = this.objTextbox.nativeElement.value;

        if (CaseNum == '' || CaseNum.length == 0) {
            this._modalService.setMessage('FARS Case # search cannot perform without Case Number. Enter Case Number and Click on Go. ', 'warning', 20);
        } else {
            this._editCheckHelper.ClearViolatedElement();

            if (CaseNum) {
                this._caseService.intCasenum = CaseNum;
                this._caseService.clearAccMemoryForCloseCase();
                this._caseService.GetCaseByCaseNumberPromise(parseInt(CaseNum), this._sharedDataService.getSelectedState(), this.selectedYear).then(data => {
                    if (this._caseService.acc) {
                        this._router.navigate([this._caseService.acc.StateNum, 'case', this._caseService.acc.AccID, 'crash', this._caseService.acc.AccID]);
                    }
                });
            }
        }
    }

    public onGetIncreased() {
        this._editCheckHelper.ClearViolatedElement();
        let intIncreasedCaseNumber = (this._caseService.acc?.Casenum ?? this.intCaseNumber ?? 0) + 1;

        if (intIncreasedCaseNumber) {
            if (this._caseService.acc != null) {  //in case of not CRSS
                this._caseService.GetCaseByCaseIncreasedNumberPromise(intIncreasedCaseNumber, this._caseService.acc.StateNum, this._caseService.acc.CaseYear).then(
                    data => {
                        this.intCaseNumber = this._caseService.acc.Casenum;

                        if (this._caseService.acc) {
                            this._router.navigate([this._caseService.acc.StateNum, 'case', this._caseService.acc.AccID, 'crash', this._caseService.acc.AccID]);
                        }
                    },
                    ex => {
                        this._modalService.setMessage(ex, 'danger', 20);
                    }
                );
            }
        }
    }

    public onGetDecreased() {
        this._editCheckHelper.ClearViolatedElement();
        let intDecreasedCaseNumber = (this._caseService.acc?.Casenum ?? this.intCaseNumber ?? 0) - 1;

        if (this._caseService.acc != null) { // in case of this is not CRSS Mode.
            this._caseService.GetCaseByCaseDecreasedNumberPromise(intDecreasedCaseNumber, this._caseService.acc.StateNum, this._caseService.acc.CaseYear).then(
                data => {
                    this.intCaseNumber = this._caseService.acc.Casenum;

                    if (this._caseService.acc) {
                        this._router.navigate([this._caseService.acc.StateNum, 'case', this._caseService.acc.AccID, 'crash', this._caseService.acc.AccID]);
                    }
                },
                ex => {
                    this._modalService.setMessage(ex, 'danger', 20);
                }
            );
        }
    }

    /**
     * Recursively searches ActivatedRoute URL segment hierarchy for given text.
     */
    public static FindRouteSegment(objRoute: ActivatedRoute, strPattern: string): ActivatedRoute {
        if (objRoute.routeConfig !== null) {
            if (objRoute.routeConfig.path.indexOf(strPattern) != -1) {
                return objRoute;
            }
        }

        let objFound: ActivatedRoute = null;
        for (let objChild of objRoute.children) {
            objFound = SearchCaseComponent.FindRouteSegment(objChild, strPattern);

            if (objFound)
                return objFound;
        }

        return null;
    }
}
