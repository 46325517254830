<div class="row pt-4">
    <div class="col-xl">

        <div class="row">
            <div class="col-12 col-sm-12 col-lg-12 col-xl-8">

                <div class="">
                    <div class="table-responsive">
                        <table class="table table-hover table-sm mb-0">
                            <thead class="bg-tb-header">
                                <tr>
                                    <th class="border-bottom border-dark">Errors</th>
                                    <th class="border-bottom border-dark">Fatal</th>
                                    <th class="border-bottom border-dark">Severe</th>
                                    <th class="border-bottom border-dark">Pending</th>
                                    <th class="border-bottom border-dark">Totals</th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom border-dark">
                                <tr>
                                    <td>Non-Overridable</td>
                                    <td class="fw-bold">{{fatalNonOverridableCount}}</td>
                                    <td class="fw-bold">{{severeNonOverridableCount}}</td>
                                    <td class="fw-bold">{{pendingNonOverridableCount}}</td>
                                    <td class="fw-bold">{{totalNonOverridableCount}}</td>
                                </tr>
                                <tr>
                                    <td>Overridable</td>
                                    <td class="fw-bold">{{fatalOverridableCount}}</td>
                                    <td class="fw-bold">{{severeOverridableCount}}</td>
                                    <td class="fw-bold">{{pendingOverridableCount}}</td>
                                    <td class="fw-bold">{{totalOverridableCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <div class="row pt-4">
            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 pt-2 pt-sm-2 pt-lg-2 pt-xl-2">

                <div class="pb-2 form-inline" *ngIf="acc">
                    <h5 *ngIf="intMode == 1 || intMode == 64">Violated Rules for Case - {{ acc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : '' }}</h5>
                    <h5 *ngIf="intMode == 4">Violated Rules for PAR ID - {{ acc.EarlyNotify[0]?.PARID | number : '8.0-0' | replaceTextPipe : ',' : '' | slice : 4 : 12 }}</h5>
                    <h5 *ngIf="intMode == 256">Violated Rules for Case - {{ acc.Acc_SS.MOSSCasenum }}</h5>

                    <span class="ml-3" *ngIf="loading$ | async"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></span>
                </div>

                <div class="">
                    <div class="table-responsive">

                        <table class="table table-hover table-sm mb-0">
                            <thead class="bg-tb-header">
                                <tr class="">
                                    <th scope="col" sortable="RuleID" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span class="">Rule</span>
                                        <span *ngIf="ColummForSorting == 'RuleID'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="Definition" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span class="">Definition</span>
                                        <span *ngIf="ColummForSorting == 'Definition'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="VNumber" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span class="">Vehicle #</span>
                                        <span *ngIf="ColummForSorting == 'VNumber'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="PNumber" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span class="">Person #</span>
                                        <span *ngIf="ColummForSorting == 'PNumber'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="Severity" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span class="">Severity</span>
                                        <span *ngIf="ColummForSorting == 'Severity'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="ORideFlag" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span class="">Override</span>
                                        <span *ngIf="ColummForSorting == 'ORideFlag'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="border-bottom border-dark">
                                <tr class="bg-white">
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchRuleTerm" placeholder="Search" [(ngModel)]="searchRuleTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchDefinitionTerm" placeholder="Search" [(ngModel)]="searchDefinitionTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchVehTerm" placeholder="Search" [(ngModel)]="searchVehicleTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchPersonTerm" placeholder="Search" [(ngModel)]="searchPersonTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                        <input class="form-control form-control-sm" type="text" name="searchSeverityTerm" placeholder="Search" [(ngModel)]="searchSeverityTerm" />
                                    </td>
                                    <td class="form-group border-bottom border-dark">
                                    </td>
                                </tr>

                                <tr *ngFor="let violatedRule of violatedRules$ | async">
                                    <td class="my-auto p-0">
                                        <button type="button" class="btn btn-link text-truncate" (click)="onGoTo(violatedRule)">
                                            <ngb-highlight [result]="violatedRule?.RuleID" [term]="searchRuleTerm"></ngb-highlight>
                                        </button>
                                    </td>
                                    <td class="">
                                        <ngb-highlight [result]="violatedRule?.Definition" [term]="searchDefinitionTerm"></ngb-highlight>
                                    </td>
                                    <td class="text-center text-nowrap">
                                        <ngb-highlight [result]="violatedRule?.VNumber"></ngb-highlight>
                                    </td>
                                    <td class="text-center text-nowrap">
                                        <ngb-highlight [result]="violatedRule?.PNumber"></ngb-highlight>
                                    </td>
                                    <td class="text-center text-nowrap">
                                        <ngb-highlight [result]="violatedRule?.Severity"></ngb-highlight>
                                    </td>
                                    <td class="my-auto p-0">
                                        <button *ngIf="violatedRule?.ORideFlag =='T'" type="button" [disabled]="blnReadOnly" class="btn btn-link text-truncate" (click)="OnOverride(violatedRule)">
                                            Override
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="d-flex justify-content-between p-2">
                            <div class="align-middle">
                                <ngb-pagination class="align-middle" [collectionSize]="total$ | async" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                            </div>
                            <div class="custom-control-inline">
                                <span class="pr-2 my-auto">Total: {{total$ | async }}</span>
                                <select class="custom-select pl-2 my-auto w-auto" name="pageSize" [(ngModel)]="pageSize">
                                    <option [ngValue]="5">5 items per page</option>
                                    <option [ngValue]="10">10 items per page</option>
                                    <option [ngValue]="25">25 items per page</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>











