import { Injectable } from '@angular/core';
import {  BehaviorSubject } from "rxjs";
import { shareReplay } from 'rxjs/operators';
import {LocalStorageSaveOptions } from '../models/LocalStorageSaveOptions';    

@Injectable({
    providedIn: 'root'
})
export class CacheLocalStorageService {

    private _isCaching: boolean = false;
    caching$ = new BehaviorSubject(true);

    constructor() { }

    get isCaching(): boolean { return this._isCaching; }

    set isCaching(value) {
        this._isCaching = value;
        this.caching$.next(value);
    }

    get cachingStatus$() { return this.caching$.asObservable().pipe(shareReplay(1)); }

    startcaching() { this.isCaching = true; }

    stopcaching() { this.isCaching = false; }
    
    save(options: LocalStorageSaveOptions) {
        // Set default values for optionals
        options.expirationMins = options.expirationMins || 0

        // Set expiration date in miliseconds
        const expirationMS = options.expirationMins !== 0 ? options.expirationMins * 60 * 1000 : 0

        const record = {
            value: typeof options.data === 'string' ? options.data : JSON.stringify(options.data),
            expiration: expirationMS !== 0 ? new Date().getTime() + expirationMS : null,
            hasExpiration: expirationMS !== 0 ? true : false
        }

        //if (this.checkAvailable(options.key, JSON.stringify(record)) === true) {
        //    // available
        //} else {
        //    // unavailable
        //}

        try {
            // Recreate RBIS local storage daily (if missing)
            const Mainitem = localStorage.getItem("RBIS")
            if (Mainitem == null) {                
                const mainRecord = {
                    value: 'RBIS',
                    expiration: new Date().getTime() + (8 * 60 * 60 * 1000), // 8 Hour
                    hasExpiration: true 
                }
                localStorage.setItem("RBIS", JSON.stringify(mainRecord))
            }

            localStorage.setItem(options.key, JSON.stringify(record))

        } catch (e) {
            if (e.code === 22) {
                // we've hit our local storage limit! lets remove 1/3rd of the entries (hopefully chronologically)
                // and try again... If we fail to remove entries, lets silently give up
                console.log('Local storage capacity reached.')

                var maxLength = localStorage.length, reduceBy = ~~(maxLength / 3);

                for (var i = 0; i < reduceBy; i++) {
                    if (localStorage.key(0)) {
                        localStorage.removeItem(localStorage.key(0));
                    }
                    else break;
                }

                if (localStorage.length == maxLength) {

                }
                else if (localStorage.length < maxLength) {
                    console.log('Cache data reduced to fit new entries. (' + maxLength + ' => ' + localStorage.length + ')');
                    localStorage.setItem(options.key, JSON.stringify(record));
                }
                else {
                    console.log('Could not reduce cache size.');
                }
            }
        }
    }


    isExpire(key: string, currentTime: number) {
        this.cleanLocalStorage()
        const item = localStorage.getItem(key)
        if (item !== null) {
            const record = JSON.parse(item)
            //const now = new Date().getTime()
            // Expired data will return null
            if (!record || (record.hasExpiration && record.expiration <= currentTime)) {
                this.remove(key)
                return null
            }
            else {
                return JSON.parse(record.value)
            }
        }
        this.remove(key)
        return null
    }

    load(key: string) {
        // Get cached data from localstorage
        const item = localStorage.getItem(key)
        if (item !== null) {
            const record = JSON.parse(item)
            const now = new Date().getTime()
            // Expired data will return null
            if (!record || (record.hasExpiration && record.expiration <= now)) {
                return null
            } else {
                return JSON.parse(record.value)
            }
        }
        return null
    }

    remove(key: string) {
        localStorage.removeItem(key)
    }

    cleanLocalStorage() {
        //clean local storage apx daily
        const Mainitem = localStorage.getItem("RBIS")
        if (Mainitem !== null) {
            const record = JSON.parse(Mainitem)
            if (!record || (record.hasExpiration && record.expiration <= new Date().getTime())) {
                localStorage.clear()
            }
        }        
    }

    checkAvailable(key, data) {
        try {
            localStorage.setItem(key, data);
            localStorage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    }

}
