
<div class="" *ngIf="acc">
    <ng-template #tipContent>{{objItem?.SpecifyValue}}</ng-template>

    <ng-template #rtOtherSpecify let-r="result" let-t="term">
        <!--Angular Bootstrap Typeahead Highlight helper, highlights the portion of the option that matches the search term.-->
        <ngb-highlight [result]="r.SpecifyValue" [term]="t"></ngb-highlight>
    </ng-template>


    <input #txtOtherSpecify type="text"
           id="txtOthSpecify{{strTableName}}{{strFieldName}}{{intSeqNum}}{{intElementValue}}"
           name="txtOthSpecify{{strTableName}}{{strFieldName}}{{intSeqNum}}{{intElementValue}}"
           [ngModel]="objItem"
           (ngModelChange)="OnModelChange($event)"
           [required]="true"
           class="form-control w-100"
           *ngIf="blnIsEnabled"
           [ngbTypeahead]="searchOtherSpecify"
           [resultTemplate]="rtOtherSpecify"
           [inputFormatter]="formatterOtherSpecify"
           (focus)="focusOtherSpecify$.next($event.target.value)"
           (click)="clickOtherSpecify$.next($event.target.value)"
           (blur)="blurOtherSpecify($event)"
           (keydown)="handleKeydown($event)"
           [ngbTooltip]="tipContent"
           #instanceToolTip="ngbTooltip"
           #instanceOtherSpecify="ngbTypeahead" />
</div>


