import { Component, OnInit, Output, OnDestroy, EventEmitter, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UtilService } from 'src/app/services/util.service';
import { AppSettings, SharedDataService } from 'src/app/services/shared-data.service';

import { CaseFilesListsService } from 'src/app/services/case-files-lists.service';
import { ModalService } from 'src/app/services/modal.service';
import { CaseService } from 'src/app/services/case.service';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';

import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { CaseFilesLists } from 'src/app/models/case-files-lists.model';

import { BaseComponent } from 'src/app/helper/basecomponent';

import { Acc } from 'src/app/models/acc';
import { JsonFileWrapper } from 'src/app/models/JsonFileWrapper';
import { CaseLockedViewModel } from 'src/app/interface/CaseLockedViewModel';
import { EarlyNotify } from 'src/app/models/early-notify';

import { CaseStatus } from 'src/app/models/enums/app.enums';
import { RbisUser } from 'src/app/models/rbis-user';
import { AutofillService } from 'src/app/services/autofill.service';
import { CaseFile } from 'src/app/models/case-file';
import { CaseFileDTO } from '../../../models/CaseFileDTO';

@Component({
    selector: 'app-case-documents',
    templateUrl: './case-documents.component.html',
    styleUrls: ['./case-documents.component.css']
})

export class CaseDocumentsComponent extends BaseComponent implements OnInit, OnDestroy {
    private _TypeScript_TypeGuard_CaseDocumentsComponent: string = null;
    intYear: number;
    intMode: number;

    stateNum: number;
    accid: number;

    blnStateCaseFlag: boolean = false;
    blnIsCaseDeleted: boolean = false;


    acc: Acc;
    earlyNotification: EarlyNotify;
    CaseFiles: CaseFilesLists[] = [];



    public progress: number;
    public message: string;
    blnIsReadOnly: boolean = false;

    /**
     * Disable "Download All" button if there is no any files to download for a case.
     **/
    blnEnableDownloadAll: boolean = true;

    @ViewChild('fileInput') public fileInput: ElementRef;


    userList: RbisUser[];

    /**
     * Initial list of allowed file types may be overridden by the strAllowedFileTypes setting in the appsettings.json file
     **/
    fileExtensionTypes = [
        "bmp",
        "gif",
        "png",
        "PNG",
        "jpg",
        "jpeg",
        "tiff",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "pdf",
        "txt",
        "csv",
        "jiff",
    ];

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilService: UtilService,
        private _sharedDataService: SharedDataService,
        protected _caseService: CaseService,
        public earlyNotificationService: EarlyNotificationService,
        protected _modalService: ModalService,
        protected _urlTreeHelper: UrlTreeHelper,
        public uploadDocumentService: CaseFilesListsService,
        public _changeDetector: ChangeDetectorRef
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);

    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intYear = appSettings.intYear;
        this.intMode = appSettings.intMode;

        if (appSettings.strAllowedFileTypes && appSettings.strAllowedFileTypes != '')
            this.fileExtensionTypes = appSettings.strAllowedFileTypes.split(',');

        this._route.params.subscribe(async params => {
            this.accid = + params['caseid'];
            this.stateNum = + params['stateNum'];

            this.setReadOnly();
            this.setIsCaseDeleted();
            this.fetchFiles();
        });
    }

    private setIsCaseDeleted() {
        this.blnIsCaseDeleted = this.acc.Deleted == true ? true : false;
    }

    public fetchFiles() {

        this.uploadDocumentService.blnPARorEDTFile = this.acc.StateCaseID != null && this.acc.EarlyNotify[0].StateCaseNum != null;

        if (this.uploadDocumentService.blnUploadActive) {
            this.blnEnableDownloadAll = this.uploadDocumentService.arrUploadingCaseFiles.length > 0 || this.uploadDocumentService.blnPARorEDTFile;
        }
        else {
            this.uploadDocumentService.getCaseFilesById(this.accid)
                .then(objFiles => {
                    this.uploadDocumentService.blnUploadActive = false;
                    this.CaseFiles = objFiles;
                    this.uploadDocumentService.arrUploadingCaseFiles = [];
                    this.blnEnableDownloadAll = this.CaseFiles.length > 0 || this.uploadDocumentService.blnPARorEDTFile;
                });
        }
    }

    private async setReadOnly() {
        let objUser: RbisUser = await this._utilBaseService.GetUserPromise();
        this.blnIsReadOnly = objUser.blnReadOnly
            || (this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review || this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review_Complete);
    }

    public async uploadFile(event) {
        try {
            if (this.uploadDocumentService.blnAllDownloadActive || this.uploadDocumentService.blnUploadActive)
                return;

            this._modalService.resetMessage()
            if (this.blnIsCaseDeleted || this.blnIsReadOnly) {
                return;
            }

            if (event.length === 0) {
                return;
            }
            let filesToUpload: File[] = event;

            for (let item of filesToUpload) {
                if (!this.validateFileType(item.name)) {

                    let txtmessage = "File " + item.name.trim() + " was ignored - wrong file type";
                    this._modalService.setMessage(txtmessage, "warning");
                    this.uploadDocumentService.arrUploadingCaseFiles = [];
                    return;
                }

                if (this.validateFileNameExist(item.name)) {

                    let txtmessage = "File " + item.name.trim() + " already exist";
                    this._modalService.setMessage(txtmessage, "danger");
                    this.uploadDocumentService.arrUploadingCaseFiles = [];
                    return;
                }
            }

            const formData = new FormData();

            Array.from(filesToUpload).map((file, index) => {
                return formData.append('file' + index, file, file.name);
            });

            this.fileInput.nativeElement.value = []; //Reset element state if file was added via file dialog. If the user tries to upload the same file again, we want onChange to fire.
            this.uploadDocumentService.blnUploadActive = true;
            this.CaseFiles = [];
            let objCaseFile: CaseFileDTO = await this.uploadDocumentService.uploadCaseFile(this.accid, formData); //Includes previously uploaded files as well as the ones uploaded just now.
            this.uploadDocumentService.arrUploadingCaseFiles = objCaseFile.arrCaseFiles;
            let objFiles: CaseFilesLists[] = await this.uploadDocumentService.uploadCaseFileToObjectStore(this.accid, objCaseFile.arrObjectStoreCaseFiles);

            for (let objHelper of objFiles) {
                if (this.acc.CaseFile.find(x => x.ID == objHelper.ID) == null) {
                    let objCaseFile: CaseFile = AutofillService.AddModelObject(this.acc, 'Acc', 'CaseFile');
                    objCaseFile.AccID             = objHelper.AccID; //CaseFile does not follow PK convention, so AccID is not set by AddModelObject() and has to be set manually
                    objCaseFile.ID                = objHelper.ID;
                    objCaseFile.FileName          = objHelper.FileName;
                    objCaseFile.UserID            = objHelper.UserID;
                    objCaseFile.UploadDate        = objHelper.UploadDate;
                    objCaseFile.ContentType       = objHelper.ContentType;
                    objCaseFile.ObjectStoreID     = objHelper.ObjectStoreID;
                    objCaseFile.AdlsObjectStoreID = objHelper.AdlsObjectStoreID;

                    for (let strProperty in objHelper) {
                        if (typeof (objHelper[strProperty]) != 'object') //This is a safety sanity check. We only want to copy over primitive values.
                            if (objCaseFile[strProperty] !== undefined)  //Must use strict comparison with undefined, otherwise class members with null values will be skipped.
                                objCaseFile[strProperty] = objHelper[strProperty];
                    }
                }
            }

            setTimeout(() => {
                this._modalService.setMessage('Files uploaded successfully.', 'info');
            }, 5);
        }
        catch (ex) {
            console.log(ex);
        }
        finally {
            this.uploadDocumentService.UnlockCaseFile(this.accid);
            this._router.onSameUrlNavigation = 'reload';
            this._router.navigate(['CaseDocuments', this.stateNum, this.accid], { queryParams: { index: 1 } });
        }
    }

    public async DownloadCaseFile(file: CaseFilesLists) {
        try {
            if (file.ID) {
                console.log(file);

                let strBufferBase64: JsonFileWrapper;
                if (file.AdlsObjectStoreID == null) {
                    strBufferBase64 = await this.uploadDocumentService.downloadCaseFile(file.ID, this.accid, this.acc.CaseYear);
                }
                else {
                    strBufferBase64 = await this.uploadDocumentService.downloadDocument(file.ID);
                }

                let strBuffer: string = atob(strBufferBase64.file);
                let arrBytes: Uint8Array = new Uint8Array(strBuffer.length);

                for (var i = 0; i < strBuffer.length; i++) {
                    arrBytes[i] = strBuffer.charCodeAt(i);
                }

                let objBlob: Blob = new Blob([arrBytes], { type: strBufferBase64.mime.trim() });
                let strUrl = URL.createObjectURL(objBlob);

                var anchor = document.createElement("a");
                anchor.download = strBufferBase64.fileName.trim();
                anchor.href = strUrl;
                anchor.click();

                // TO DO  - Sergei Should we open new window?
                //window.open(strUrl);
            }
        } catch (ex) {
            console.log(ex);
            this._modalService.setMessage('Failed to Download', 'danger');
        }

    }

    public DeleteCaseFile(documentID: number) {
        if (this.blnIsCaseDeleted || this.blnIsReadOnly)
            return;

        if (documentID) {
            if (confirm("Are you sure to delete this document?")) {
                this.uploadDocumentService.deleteCaseFile(documentID, this.accid).then(
                    (result: CaseFilesLists[]) => {
                        this.CaseFiles = result;
                        this.acc.CaseFile.splice(this.acc.CaseFile.findIndex(x => x.ID == documentID), 1);
                        this.blnEnableDownloadAll = (this.CaseFiles.length > 0) || this.uploadDocumentService.blnPARorEDTFile;
                    },
                    err => {
                        console.log('Delete err', err);
                    }
                )
            }
        }
    }

    public OnBackToCase() {
        let lastUrl: string = this._sharedDataService.getLastCaseUrl();

        if (lastUrl) {
            this._router.navigateByUrl(lastUrl);
        }
        else {
            this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid, 'crash']);
        }
    }

    public OnCloseCase() {
        let lockedCase: Array<CaseLockedViewModel> = [];
        let releaseCase = {} as CaseLockedViewModel
        releaseCase.AccID = this.acc.AccID;
        lockedCase.push(releaseCase);
        this._caseService.ReleaseLockedCases(lockedCase).then(result => {
            if (result.IsValid) {
                this._caseService.hideNavForCloseCase.next(true);
                this.acc.Cases_Locked = null;

                this._router.navigate([this.stateNum, 'case', releaseCase.AccID, 'closed'])
            } else {
                this._modalService.setMessage(result.Message, 'warning')
            }
        });
    }

    public async OnGetFromStateCaseViewer(EarlyNotify: EarlyNotify): Promise<void> {
        try {
            let item: string = await this.earlyNotificationService.GetCRSSFromStateCaseViewer(EarlyNotify);

            if (!item || item.length == 0) {
                this._modalService.setMessage('File not found', 'danger');
            }
            else {
                let strBufferStateCaseViewer: string = atob(item);
                let arrBytes: Uint8Array = new Uint8Array(strBufferStateCaseViewer.length);

                for (var i = 0; i < strBufferStateCaseViewer.length; i++) {
                    arrBytes[i] = strBufferStateCaseViewer.charCodeAt(i);
                }

                let objBlob: Blob = new Blob([arrBytes], { type: 'application/pdf' });
                let strUrl = URL.createObjectURL(objBlob);
                window.open(strUrl);
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }

    public async OnGetParFromParse(EarlyNotify: EarlyNotify): Promise<void> {
        try {
            let item: string = await this.earlyNotificationService.GetParFromParse(EarlyNotify);
            if (!item || item.length == 0) {
                this._modalService.setMessage('File not found', 'danger');
            }
            else {
                let strBufferStateCaseViewer: string = atob(item);
                let arrBytes: Uint8Array = new Uint8Array(strBufferStateCaseViewer.length);

                for (var i = 0; i < strBufferStateCaseViewer.length; i++) {
                    arrBytes[i] = strBufferStateCaseViewer.charCodeAt(i);
                }

                let objBlob: Blob = new Blob([arrBytes], { type: 'application/pdf' });
                let strUrl = URL.createObjectURL(objBlob);
                window.open(strUrl);
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }

    validateFileType(name: String): boolean {
        let ext = name.substring(name.lastIndexOf('.') + 1);
        if (this.fileExtensionTypes.indexOf(ext.toLowerCase()) != -1) {
            return true;
        }

        else {
            return false;
        }
    }

    validateFileNameExist(name: String): boolean {
        return this.CaseFiles.some(i => i.FileName.trim() == name.trim());
    }

    ngOnDestroy() {
        this._modalService.resetMessage();
    }

    async onDownloadAll(): Promise<void> {
        try {
            await this.uploadDocumentService.downloadAll(this.acc);
        } catch (ex) {
            console.log(ex);
            this._modalService.setMessage('Failed to Download All', 'danger');
        }
        finally {
            this.uploadDocumentService.UnlockCaseFile(this.accid);
            this.fetchFiles();
        }

    }

}
