import { Component, OnInit } from '@angular/core';
import { AfterViewInit, OnDestroy } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';
import { TreeNode } from 'src/app/components/tree-view/tree-node';
import { Acc } from 'src/app/models/acc';
import { Occupants } from 'src/app/models/occupants';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { Veh } from 'src/app/models/veh';
import { Injury, DBMode, OccupantType, KeyCode, RBISDataValue, EDTStatus, CrssCaseStatus, VehType} from 'src/app/models/enums/app.enums';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { EarlyNotify } from 'src/app/models/early-notify';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { Dri } from 'src/app/models/dri';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UtilService } from 'src/app/services/util.service';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { TreeViewComponent } from 'src/app/components/tree-view/tree-view.component';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { DeclareFunctionStmt } from '@angular/compiler';
import { PreCrash } from 'src/app/models/pre-crash';
import { PreCrash_MTSS } from 'src/app/models/pre-crash-mtss';

/**
 * This component is used when creating a new case and when editing an existing case.
 * When creating a new case this component relies on CaseService having a [non-committed] dbo.Acc cached - this means if F5 is used to reload the app while on this page,
 * the case lookup WILL FAIL.
 **/
@Component({
    selector: 'app-case-edit',
    templateUrl: './case-edit.component.html',
    styleUrls: ['./case-edit.component.css']
})
export class CaseEditComponent implements OnInit, AfterViewInit, OnDestroy {

    private stateNum: number;
    private arrNewVehicles: Veh[]; //Only newly added vehicles are eligible for driverless vehicle validation message
    private blnDirty: boolean = false;
    private intAccId: number;
    private objAccClone: Acc;
    private objEarlyNotifyClone: EarlyNotify;
    private objTreeData: TreeNode;
    private objTreeDataClone: TreeNode;
    private sbsCaseId: Subscription;
    private sbsInjurySelected: Subscription;
    private blnIsEDTcase: boolean = false;
    public blnCreatingNewCase: boolean; //Creating new as opposed to restructuring existing case
    public intPersonsToAdd: number = 1;
    public objPerson: Occupants | Non_Occupants;

    public objAcc: Acc; //Needs to be public as it's accessed from HTML template (not enforced unless doing release build)

    intMode: number;

    @ViewChild('f') objForm: NgForm;
    @ViewChild('treeViewNew') treeViewNew: TreeViewComponent;
    @ViewChild('btnBackToSearch') btnBackToSearch: ElementRef;
    @ViewChild('btnBackToCase') btnBackToCase: ElementRef;
    @ViewChild('btnSaveCase') btnSaveCase: ElementRef;

    @ViewChild('btnAddNonOcc') btnAddNonOcc: ElementRef;
    @ViewChild('btnDelNonOcc') btnDelNonOcc: ElementRef;

    @ViewChild('btnAddVehicle') btnAddVehicle: ElementRef;
    @ViewChild('btnStrikeAddVehicle') btnStrikeAddVehicle: ElementRef;
    @ViewChild('btnSFRAddVehicle') btnSFRAddVehicle: ElementRef;    
    @ViewChild('btnOtherAddVehicle') btnOtherAddVehicle: ElementRef;

    @ViewChild('btnDelVehicle') btnDelVehicle: ElementRef;
    @ViewChild('btnStrikeDelVehicle') btnStrikeDelVehicle: ElementRef;
    @ViewChild('btnSFRDelVehicle') btnSFRDelVehicle: ElementRef;    
    @ViewChild('btnOtherDelVehicle') btnOtherDelVehicle: ElementRef;

    @ViewChild('btnAddOccupant') btnAddOccupant: ElementRef;
    @ViewChild('btnDelOccupant') btnDelOccupant: ElementRef;

    @ViewChild('divMarkFatal') divMarkFatal: ElementRef;
    @ViewChild('selMarkFatal') selMarkFatal: TypeaheadComponent;
    @ViewChild('txtPersonsToAdd') txtPersonsToAdd: ElementRef;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _autofillService: AutofillService,
        private _caseService: CaseService,
        private _earlyNotifyService: EarlyNotificationService,
        private _modalService: ModalService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _sharedDataService: SharedDataService,
        private _utilService: UtilService
    ) {
        this.arrNewVehicles = new Array<Veh>();
    }

    ngOnInit() {
        this.sbsCaseId = this._route.params.subscribe((objParams: Params) => { //If the parameter in the route changes, we load the case for the new parameter value
            let intChangedAccId: number = +objParams.caseid;
            this.stateNum = +objParams.stateNum;

            if (!this._sharedDataService.selectedState) {
                this._sharedDataService.setSelectedState(this.stateNum);
            }
            if (intChangedAccId == 0 && this._earlyNotifyService.earlyNotification!=null && this._earlyNotifyService.earlyNotification.Mode == DBMode.CRSS) {
                this._caseService.setIntObsCaseNumber(this._earlyNotifyService.earlyNotification.PARID);
            };

            if (this._caseService.acc != null) this.intMode = this._caseService.acc.Mode

            if (this._caseService.acc != null && this._caseService.acc.Mode == DBMode.MOSS) {
                this._caseService.setIntObsCaseNumber(this._caseService.acc.EarlyNotify[0].ENCaseNum);
            }

            //TODO: evaluate if this logic belongs in toolbar
            this._utilService.GetDrpDownListOptions("StateNum", '').subscribe(objResult => {
                if (objResult) {
                    let arrStates: Array<number> = [];
                    
					if (objResult) {
                        objResult.forEach(item => {
                            arrStates.push(item.intValue);
                        });
                        this._sharedDataService.setListofValidStates(arrStates);
                    }
                    
					let ddoSelectedState = objResult.find(x => x.intValue === this.stateNum);
                    
					if (ddoSelectedState) {
                        this._sharedDataService.setDDOSelectedState(ddoSelectedState);
                        this._sharedDataService.subjectSelectedState.next(ddoSelectedState);
                    }
                }
            });

            this.blnIsEDTcase = this._sharedDataService.getIsEDTCase();

            if (this.intAccId != intChangedAccId) {
                this._caseService.GetCasePromise(intChangedAccId).then((async (objAcc: Acc) => {
                    this.intAccId = objAcc.AccID;
                    this.objAcc = objAcc;
                    this.blnCreatingNewCase = (this.objAcc.Casenum == null || this.objAcc.Casenum == undefined || this.objAcc.Casenum == 0);

                    await this.DisplayCase(objAcc);

                    if (this.blnCreatingNewCase && this.objAccClone.Veh.length == 1)
                        this.objAccClone.Veh[0].Added = true; //DisplayCase resets Added flags to false, when creating a case the default case structure has one placeholder vehicle

                    if (this.blnCreatingNewCase && this.objAcc.Mode == DBMode.FARS)
                        this.selMarkFatal.hideOrShowComponent(true);
                    else
                        this.selMarkFatal.hideOrShowComponent(false);
                }).bind(this)); //Promise callback loses "this" context unless explicitly bound.
            }
        });
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        if (this.sbsCaseId)
            this.sbsCaseId.unsubscribe();
    }

    private async DisplayCase(objAcc: Acc) {
        this.blnDirty = false;

        for (let objVeh of objAcc.Veh) {
            objVeh.Added = false; //After a save, the Added flag is preserved from before the save

            for (let objOcc of objVeh.Occupants)
                objOcc.Added = false;
        }

        for (let objNonOcc of objAcc.Non_Occupants)
            objNonOcc.Added = false;

        if (objAcc.EarlyNotify) { //ObjectUtil.CloneModelObject() travels downwards only, we want to clone the data model completely, from EarlyNotify down, if we have the full data model
            if (objAcc.EarlyNotify.length > 0) {
                this.objEarlyNotifyClone = ObjectUtil.CloneModelObject(objAcc.EarlyNotify[0]);
                this.objAccClone = this.objEarlyNotifyClone.Acc; //Clone has one-directional navigation properties only, so EarlyNotify has a link to Acc, but Acc does not have link to EarlyNotify
            }
        }

        if (!this.objAccClone)
            this.objAccClone = ObjectUtil.CloneModelObject(objAcc);

        if (this.objAccClone.Casenum == 0) { //When structuring new case, all vehicles are new
            for (let i = 0; i < this.objAccClone.Veh.length; i++) {
                this.arrNewVehicles.push(this.objAccClone.Veh[i]);
            }
        }

        //TreeNode.resetMossTreeCaption();
        this.objTreeData = await TreeNode.CreateTree(objAcc.EarlyNotify[0], !this.blnCreatingNewCase, false, this._valueToDescriptionPipe);
        //TreeNode.resetMossTreeCaption();
        this.objTreeDataClone = await TreeNode.CreateTree(this.objEarlyNotifyClone, !this.blnCreatingNewCase, true, this._valueToDescriptionPipe);

        setTimeout((() => {
            this.treeViewNew.toggleSelect(this.treeViewNew); //Will raise TreeViewComponent.selectionChanged event that triggers this.OnTreeSelectionChanged()
        }).bind(this), 1); //Delay needed to let ngIf let tree be rendered and treeViewNew ViewChild be populated
        
        //this.objTreeDataClone.Select(true);
        //this.objTreeDataClone.objSelected = this.objTreeDataClone;
        //this.OnTreeSelectionChanged(this.objTreeDataClone);
    }

    public OnTreeSelectionChanged(nodeNewSelection: TreeNode): void {
        if (this.sbsInjurySelected) {
            this.sbsInjurySelected.unsubscribe();
            this.sbsInjurySelected = null;
        }

        if (nodeNewSelection == null || ObjectUtil.isAcc(nodeNewSelection.objModel)) {
            if (this.intMode == DBMode.MOSS) {
                this.btnStrikeAddVehicle.nativeElement.disabled = false;
                this.btnStrikeDelVehicle.nativeElement.disabled = true;
                this.btnSFRAddVehicle.nativeElement.disabled = false; 
                this.btnSFRDelVehicle.nativeElement.disabled = true;
                this.btnOtherAddVehicle.nativeElement.disabled = false;
                this.btnOtherDelVehicle.nativeElement.disabled = true;
            }
            else {
                this.btnAddVehicle.nativeElement.disabled = false; //Adding a Vehicle is allowed
                this.btnDelVehicle.nativeElement.disabled = true;
            }

            this.btnAddOccupant.nativeElement.disabled = true;
            this.btnDelOccupant.nativeElement.disabled = true;
            this.btnAddNonOcc.nativeElement.disabled = false; //Adding a Non Occupant is allowed
            this.btnDelNonOcc.nativeElement.disabled = true;

            this.objPerson = null;
            this.divMarkFatal.nativeElement.style.visibility = 'hidden';

            setTimeout((() => {
                this.selMarkFatal.clearComponent();
            }).bind(this), 1); //Need a delay for the cleared data model to trickle through to TypeaheadComponent.objModel 
        }
        else if (ObjectUtil.isVeh(nodeNewSelection.objModel)) {
            if (this.intMode == DBMode.MOSS) {

                this.btnStrikeAddVehicle.nativeElement.disabled = true;
                this.btnStrikeDelVehicle.nativeElement.disabled = true;
                this.btnSFRAddVehicle.nativeElement.disabled = true;
                this.btnSFRDelVehicle.nativeElement.disabled = true;
                this.btnOtherAddVehicle.nativeElement.disabled = true;
                this.btnOtherDelVehicle.nativeElement.disabled = true;

                this.btnAddOccupant.nativeElement.disabled = false; //Add Occupant allowed

                if (nodeNewSelection.strVType == VehType.StrikingVehicle) {
                    this.btnStrikeAddVehicle.nativeElement.disabled = true;
                    this.btnStrikeDelVehicle.nativeElement.disabled = nodeNewSelection.objModel.Deleted === true;
                }
                if (nodeNewSelection.strVType == VehType.SFRVehicle) {
                    this.btnSFRAddVehicle.nativeElement.disabled = true;
                    this.btnSFRDelVehicle.nativeElement.disabled = nodeNewSelection.objModel.Deleted === true;
                }
                if (nodeNewSelection.strVType == VehType.OtherVehicle) {
                    this.btnOtherAddVehicle.nativeElement.disabled = true;
                    this.btnOtherDelVehicle.nativeElement.disabled = nodeNewSelection.objModel.Deleted === true;
                    this.btnAddOccupant.nativeElement.disabled = true; //Add Occupant not allowed
                }
            }
            else {
                this.btnAddVehicle.nativeElement.disabled = true;
                this.btnDelVehicle.nativeElement.disabled = nodeNewSelection.objModel.Deleted === true; //Delete Vehicle allowed

                this.btnAddOccupant.nativeElement.disabled = false; //Add Occupant allowed
            }

            this.btnDelOccupant.nativeElement.disabled = true;
            this.btnAddNonOcc.nativeElement.disabled = true;
            this.btnDelNonOcc.nativeElement.disabled = true;

            this.objPerson = null;
            this.divMarkFatal.nativeElement.style.visibility = 'hidden';

            setTimeout((() => {
                this.selMarkFatal.clearComponent();
            }).bind(this), 1); //Need a delay for the cleared data model to trickle through to TypeaheadComponent.objModel 
        }
        else if (ObjectUtil.isOcc(nodeNewSelection.objModel) || ObjectUtil.isNonOcc(nodeNewSelection.objModel)) {
            let strModelDataType = ObjectUtil.GetTypeName(nodeNewSelection.objModel);

            if (this.selMarkFatal.strTableName != strModelDataType) {
                this.selMarkFatal.strTableName = strModelDataType;
                this.selMarkFatal.blnInitialized = false;
                AutofillService.InitializeAutofillForControl(this.selMarkFatal);
            }

            if (this.intMode == DBMode.MOSS) {
                this.btnStrikeAddVehicle.nativeElement.disabled = true;
                this.btnStrikeDelVehicle.nativeElement.disabled = true;
                this.btnSFRAddVehicle.nativeElement.disabled = true;
                this.btnSFRDelVehicle.nativeElement.disabled = true;
                this.btnOtherAddVehicle.nativeElement.disabled = true;
                this.btnOtherDelVehicle.nativeElement.disabled = true;
            }
            else {
                this.btnAddVehicle.nativeElement.disabled = true;
                this.btnDelVehicle.nativeElement.disabled = true;
            }

            this.btnAddOccupant.nativeElement.disabled = true;
            this.btnAddNonOcc.nativeElement.disabled = true;

            if (ObjectUtil.isOcc(nodeNewSelection.objModel)) {
                this.btnDelOccupant.nativeElement.disabled = nodeNewSelection.objModel.Deleted === true; //Delete Occupant allowed
                this.btnDelNonOcc.nativeElement.disabled = true;
            }
            else {
                this.btnDelNonOcc.nativeElement.disabled = nodeNewSelection.objModel.Deleted === true; //Delete Non Occupant Allowed
                this.btnDelOccupant.nativeElement.disabled = true;
            }

            this.objPerson = nodeNewSelection.objModel;
            this.divMarkFatal.nativeElement.style.visibility = 'visible';

            //Need a delay for the newly set TypeaheadComponent data model to trickle through to TypeaheadComponent.objModel
            //Need a delay also for HTML DOM to be updated to make field visible
            setTimeout((() => {
                let lbl: HTMLElement = document.getElementById(nodeNewSelection.strId);

                if (lbl) {
                    this.divMarkFatal.nativeElement.style.top = (lbl.offsetTop + 110).toString() + 'px';
                    this.divMarkFatal.nativeElement.style.left = (lbl.offsetLeft + lbl.offsetWidth + 50).toString() + 'px';
                }

                if (this.objPerson.Injury != null && this.objPerson.Injury != -1)
                    this.selMarkFatal.setCurrentValue(this.objPerson.Injury.toString()); //Caution: This will fire Typeahead.bindModel(), if any logic is attached to the event, it should not react to this as a change
                else
                    this.selMarkFatal.clearComponent();

                if (!this.sbsInjurySelected) //Attach change handler only after TypeaheadComponent's initial value has been bound, so that it does not see the initialization as a change
                    this.sbsInjurySelected = this.selMarkFatal.bindModel.subscribe(this.renderSelectedInjury.bind(this));

                setTimeout((() => { //Delay is needed to let DOM be updated to make field visible
                    this.selMarkFatal.objTextbox.nativeElement.click();
                    this.selMarkFatal.objTextbox.nativeElement.focus();
                }).bind(this), 1);
            }).bind(this), 1);
        }
    }

    public backToCaseOrCancel(objEvent: Event): void {
        let prmConfirm: Promise<boolean> = this.blnDirty ?
            this._modalService.dialog('Discard unsaved changes?', 'Please confirm').toPromise() :
            new Promise<boolean>((resolve) => { resolve(true); });

        prmConfirm.then((blnConfirm => {
            if (blnConfirm) {
                if (this.blnCreatingNewCase) {
                    if(this.objAcc.Mode == DBMode.FARS)
                        this._router.navigate(['earlyNotification', this.objAcc.StateNum, 'fatalCaseScreener']);
                    else
                        this._router.navigate(['crss', 'CaseSearchScreener']);

                    this.objAcc.EarlyNotify[0].EarlyNotify_Locked = null;
                    this._earlyNotifyService.ReleaseLock(this.objAcc.EarlyNotify[0].ENID, false);
                }
                else if (window.history.length > 0) {
                    window.history.back();
                    this.objAcc.EarlyNotify[0].EarlyNotify_Locked = null;
                    this._earlyNotifyService.ReleaseLock(this.objAcc.EarlyNotify[0].ENID, false);
                }
                else
                    this._router.navigate([this.objAcc.StateNum, 'case', this.objAcc.AccID, 'crash', this.objAcc.AccID]);
            }
        }).bind(this));
    }

    public async saveCaseStructure(objEvent: Event): Promise<void> {
        let blnConfirm: boolean = true;
        let intOccCount: number = 0;
        let intFatalCount: number = 0;
        let arrDriverless: Veh[] = new Array<Veh>();

        let intMossSFRVehTot: number = 0;
        intMossSFRVehTot = this.objEarlyNotifyClone.NoofSFRv; //MOSS struck subject vehicle total

        for (let i = 0; i < this.objAccClone.Veh.length; i++) {
            if (this.objAccClone.Veh[i].Added && this.objAccClone.Veh[i].Occupants.length == 0) {
                if (this.objAcc.Mode == DBMode.MOSS && this.objAccClone.Veh[i].VehType == VehType.OtherVehicle) {
                    //Other Vehicle is a driverless vehicle for MOSS
                }
                else 
                    arrDriverless.push(this.objAccClone.Veh[i]);                
            }                

            if (!this.objAccClone.Veh[i].Deleted) {
                for (let j = this.objAccClone.Veh[i].Occupants.length - 1; j >= 0; j--) {
                    if (!this.objAccClone.Veh[i].Occupants[j].Deleted) {
                        intOccCount++;

                        if (this.objAccClone.Veh[i].Occupants[j].Injury == Injury.Fatal)
                            intFatalCount++;
                    }
                }
            }
        }

        for (let i = 0; i < this.objAccClone.Non_Occupants.length; i++) {
            if (!this.objAccClone.Non_Occupants[i].Deleted && this.objAccClone.Non_Occupants[i].Injury == Injury.Fatal)
                intFatalCount++;
        }

        if (this.objAccClone.Veh.length == 0) {
            this._modalService.setMessage('Case must have at least one vehicle.', 'error');
            return; 
        }
        else if (this.objAcc.Mode == DBMode.FARS && this.blnCreatingNewCase && this.objAcc.EarlyNotify[0].NoOfFatals != intFatalCount) {
            this._modalService.setMessage('Number of Persons entered (' + intFatalCount.toString() + ') with Injury Severity 4 (Fatal) does not equal the number of early notification Fatals (' + this.objAcc.EarlyNotify[0].NoOfFatals.toString() + '). If number of fatals has changed since early notification, please edit early notification data before proceeding.', 'error');
            return;
        }
        else if (this.objAcc.Mode == DBMode.FARS && this.blnCreatingNewCase && intFatalCount == 0) {
            this._modalService.setMessage('FARS case must have at least one fatality.', 'error');
            return;
        }

        if (arrDriverless.length > 0) {
            let strConfirmMessage: string = 'Vehicle' + (arrDriverless.length > 1 ? 's' : '');

            let strMossVehTypeName: string = '';

            for (let i = 0; i < arrDriverless.length; i++)
                
                if (this.objAcc.Mode == DBMode.MOSS) {
                    switch (arrDriverless[i].VehType) {
                        case VehType.StrikingVehicle:
                            strMossVehTypeName = 'Striking_Vehicle_'
                            break;
                        case VehType.SFRVehicle:
                            strMossVehTypeName = 'Struck_Subject_Vehicle_'
                            break;
                        case VehType.OtherVehicle:
                            strMossVehTypeName = 'Other_Vehicle_'
                            break;
                        default:
                    }
                    strConfirmMessage += (i == 0 ? ' ' : ', ') + strMossVehTypeName +  arrDriverless[i].Veh_SS.SSVNumber;
                }
                else
                    strConfirmMessage += (i == 0 ? ' ' : ', ') + arrDriverless[i].VNumber;
            

            strConfirmMessage += (arrDriverless.length > 1 ? ' are ' : ' is a ') + 'driverless vehicle' + (arrDriverless.length > 1 ? 's' : '');

            blnConfirm = await this._modalService.dialogPromise(strConfirmMessage, 'Please confirm');
        }

        if (blnConfirm) {
            this.objAccClone.VForms = this.objAccClone.Veh.length;
            this.objAccClone.PForms = intOccCount;

            let isVehDeleted: boolean = false;

            for (let i = this.objAccClone.Veh.length - 1; i >= 0; i--) {
                if (this.objAccClone.Veh[i].Deleted) {
                    this.objAccClone.Veh.splice(i, 1);
                    isVehDeleted = true;
                }
                else {
                    for (let j = this.objAccClone.Veh[i].Occupants.length - 1; j >= 0; j--) {
                        if (this.objAccClone.Veh[i].Occupants[j].Deleted) {
                            this.objAccClone.Veh[i].Occupants.splice(j, 1);
                            AutofillService.OnChange(null, this.objAccClone.Veh[i], 'Occupants', true, true, false);
                        }
                    }
                    if (this.objAccClone.Veh[i].VehType != VehType.OtherVehicle)
                        this.objAccClone.Veh[i].NumOccs = this.objAccClone.Veh[i].Occupants.length;

                    //Checking PreCrash for MOSS
                    if (this.objAccClone.Mode == DBMode.MOSS) {
                        if (this.objAccClone.Veh[i].VehType == VehType.StrikingVehicle) {

                            if (this.objAccClone.Veh[i].PreCrash == undefined || this.objAccClone.Veh[i].PreCrash == null) {
                                AutofillService.AddModelObject(this.objAccClone.Veh[i], 'Veh', 'PreCrash');
                                this._autofillService.AddPreCrash(this.objAccClone.Veh[i]);
                            }

                            if (this.objAccClone.Veh[i].PreCrash.PreCrash_MTSS == undefined || this.objAccClone.Veh[i].PreCrash.PreCrash_MTSS == null) {
                                AutofillService.AddModelObject(this.objAccClone.Veh[i].PreCrash, 'PreCrash', 'PreCrash_MTSS');;
                                this._autofillService.AddPreCrashMTSS(this.objAccClone.Veh[i]);
                            }
                        }
                    }

                }
            }

            if (this.objAccClone.Mode == DBMode.MOSS && isVehDeleted) { // Reset MOSS Veh number
                let intCountStrikingVeh: number = 0;
                let intCountSFRVeh: number = 0;
                let intCountOtherVeh: number = 0;

                for (let i = 0; i <= this.objAccClone.Veh.length - 1; i++) {
                    switch (this.objAccClone.Veh[i].VehType) {
                        case VehType.StrikingVehicle:
                            intCountStrikingVeh = intCountStrikingVeh + 1;
                            if (this.objAccClone.Veh[i].Veh_SS.SSVNumber > intCountStrikingVeh)
                                this.objAccClone.Veh[i].Veh_SS.SSVNumber = intCountStrikingVeh;
                            break;
                        case VehType.SFRVehicle:
                            intCountSFRVeh = intCountSFRVeh + 1
                            if (this.objAccClone.Veh[i].Veh_SS.SSVNumber > intCountSFRVeh) {
                                this.objAccClone.Veh[i].Veh_SS.SSVNumber = intCountSFRVeh;
                            }
                            intMossSFRVehTot = intCountSFRVeh // Struck Subject vehicle total
                            break;
                        case VehType.OtherVehicle:
                            intCountOtherVeh = intCountOtherVeh + 1;
                            if (this.objAccClone.Veh[i].Veh_SS.SSVNumber > intCountOtherVeh)
                                this.objAccClone.Veh[i].Veh_SS.SSVNumber = intCountOtherVeh;
                            break;
                        default:
                    }
                }
            }
            else if (this.objAccClone.Mode == DBMode.MOSS && !isVehDeleted) { //count Struck Subject vehicle total 
                let intCount: number = 0;
                for (let i = 0; i <= this.objAccClone.Veh.length - 1; i++) {
                    switch (this.objAccClone.Veh[i].VehType) {
                        case VehType.SFRVehicle:
                            intCount++;
                            break;
                    }
                }
                if (intMossSFRVehTot < intCount)
                    intMossSFRVehTot = intCount;
            }

            if (this.objAcc.Mode == DBMode.MOSS) { //reset Struck Subject vehicle total
                if (this.objEarlyNotifyClone.NoofSFRv != intMossSFRVehTot) {
                    this.objEarlyNotifyClone.NoofSFRv = intMossSFRVehTot

                    //TODO: Include EarlyNotify into SaveCase data model

                    if (this.objEarlyNotifyClone != null) {
                        await this._caseService.SaveEarlyNotification(this.objEarlyNotifyClone).toPromise();
                    }
                }
            }

            //if (this.objAcc.Mode == DBMode.MOSS) {                
            //    this.objEarlyNotifyClone.NoofSv = this.objAccClone.Veh.filter(v => v.VehType == VehType.StrikingVehicle).length //Strike Vehicle
            //    this.objEarlyNotifyClone.NoofOv  = this.objAccClone.Veh.filter(v => v.VehType == VehType.OtherVehicle).length  //Other Vehicle
            //    this.objEarlyNotifyClone.NoofFirstResponderSFRv = this.objAccClone.Non_Occupants.length //Non-Occupuant-FR
            //}

            for (let i = this.objAccClone.Non_Occupants.length - 1; i >= 0; i--) {
                if (this.objAccClone.Non_Occupants[i].Deleted)
                    this.objAccClone.Non_Occupants.splice(i, 1);
            }

            if (this._sharedDataService.IsEDTCase(this.objAccClone) && this.objAccClone.Mode == 1) {
                this.objAccClone.Status = EDTStatus.Locked;
            }

            if (this.objAccClone.Mode == DBMode.CRSS && this.objEarlyNotifyClone.Status == CrssCaseStatus.ToBeCoded)
                this.objEarlyNotifyClone.Status = CrssCaseStatus.Open; //EarlyNotify is not part of save payload, but we keep the client-side and server-side data model consistent whenever possible.

            this._caseService.SaveCase(this.objAccClone, true).then(
                async (objSavedAcc: Acc) => {
                    this.arrNewVehicles.length = 0;
                    //New object had AccID = 0 for dbo.Acc row and all it's children. Taking object returned by server with assigned AccID and CaseNum.
                    this.objAcc = objSavedAcc; //A new clone will be created by DisplayCase()
                    this._caseService.acc = objSavedAcc; //If service had case cached, the cache is now stale and needs to be updated.
                    this._caseService.subCase.next(objSavedAcc);
                    this.objForm.form.markAsPristine();

                    if (this.blnCreatingNewCase) {
                        this._modalService.setMessage('Case created successfully', 'info');
                        this._caseService.acc = this.objAcc;
                        this._caseService.subCase.next(this.objAcc);
                        this.blnDirty = false;
                        this._router.navigate([this.objAcc.StateNum, 'case', this.objAcc.AccID, 'crash', this.objAcc.AccID]);
                    }
                    else {
                        this._modalService.setMessage('Restructured case saved successfully', 'info');
                        await this.DisplayCase(objSavedAcc); //If restructuring an existing case, we are intentionally staying on the page after saving
                    }
                },
                (objError: any) => {
                    this._modalService.setMessage('Failed to save restructured case: ' + objError.message, 'error');
                }
            );
        }
    }

    public async addNonOccupant(objEvent: Event): Promise<void> {
        if (this.objTreeDataClone.objSelected)
            if (!ObjectUtil.isAcc(this.objTreeDataClone.objSelected.objModel))
                this.objTreeDataClone.objSelected.Select(false); //If current selection is not Acc, unselect it before trying to add Non Occupant

        this.objTreeDataClone.Select(true);
        this.objTreeDataClone.objSelected = this.objTreeDataClone;

        let objAcc: Acc = <Acc>this.objTreeDataClone.objSelected.objModel;
        let objNonOcc: Non_Occupants = this._autofillService.AddNonOccupant(objAcc);
        objNonOcc.Added = true;
        let nodeNonOcc: TreeNode = await TreeNode.CreateTree_NonOccupant(this.objTreeDataClone, objNonOcc, false, true, this._valueToDescriptionPipe);

        if (!this.blnCreatingNewCase || this.objAcc.Mode != DBMode.FARS) //Keep case node selected
            nodeNonOcc.strTitle += ' [Added]';
        else {
            this.objTreeDataClone.Select(false);
            nodeNonOcc.Select(true);
            this.objTreeDataClone.objSelected = nodeNonOcc;
            this.OnTreeSelectionChanged(nodeNonOcc);
        }

        this.blnDirty = true;
    }

    public deleteNonOccupant(objEvent: Event): void {
        let intVehCount: number;
        let isAllowDelete: boolean = false;
        let strVehTypeMsg: string = '';

        if ((this.objTreeDataClone.objSelected) && (ObjectUtil.isNonOcc(this.objTreeDataClone.objSelected.objModel))) {
            let objNonOcc: Non_Occupants = <Non_Occupants>this.objTreeDataClone.objSelected.objModel;
            let nodeNextSelection: TreeNode = this.findNextNonDeletedNonOccupantNode(this.objTreeDataClone.objSelected);

            if (this.intMode == DBMode.MOSS) {
                strVehTypeMsg = 'There must be at least one Struck Non-Occupant Subject or one Struck Subject Vehicle involved in a MOSS case';
                intVehCount = objNonOcc.Acc.Veh.filter(x => !x.Deleted && x.VehType == VehType.SFRVehicle).length
                if (intVehCount > 0) isAllowDelete = true;
                else {
                    if ((objNonOcc.Acc.Non_Occupants.filter(x => !x.Deleted).length) > 1) isAllowDelete = true;
                }
            }
            else {
                isAllowDelete = true;
            }

            if (isAllowDelete) {
                if (objNonOcc.Added) { //Not committed yet, so remove immediately. Renumber remaining non occupants
                    let intTargetIndex: number = objNonOcc.Acc.Non_Occupants.indexOf(objNonOcc);
                    objNonOcc.Acc.Non_Occupants.splice(intTargetIndex, 1);
                    this.objTreeDataClone.objSelected.objParent.arrChildren.splice(this.objTreeDataClone.objSelected.objParent.arrChildren.indexOf(this.objTreeDataClone.objSelected), 1);

                    //Alternatively call TreeNode.CreateTree() again so that string operations are not necessary
                    for (let i = intTargetIndex; i < objNonOcc.Acc.Non_Occupants.length; i++) {
                        let strOldNumber: string = '_' + (objNonOcc.Acc.Non_Occupants[i].PNumber).toString() + '_';
                        objNonOcc.Acc.Non_Occupants[i].PNumber = i + 1;
                        let strNewNumber: string = '_' + (objNonOcc.Acc.Non_Occupants[i].PNumber).toString() + '_';
                        let strRenumbered: string = this.objTreeDataClone.objSelected.objParent.arrChildren[i].strTitle.replace(strOldNumber, strNewNumber);
                        this.objTreeDataClone.objSelected.objParent.arrChildren[i].strTitle = strRenumbered;
                    }
                }
                else {
                    objNonOcc.Deleted = true;
                    this.objTreeDataClone.objSelected.strTitle += ' [Deleted]';
                }

                this.objTreeDataClone.objSelected.Select(false);
                nodeNextSelection.Select(true);
                this.objTreeDataClone.objSelected = nodeNextSelection;
                this.OnTreeSelectionChanged(nodeNextSelection);
                this.blnDirty = true;
            }
            else
                this._modalService.setMessage(strVehTypeMsg, 'warning');
        }
        else
            this._modalService.setMessage('Please select a non-occupant', 'warning');
    }

    /**
     * Find the next non-deleted sibling Non_Occupants node, or fall back on parent case node.
     */
    private findNextNonDeletedNonOccupantNode(objNode: TreeNode): TreeNode {
        let nodeCase: TreeNode = objNode.objParent;
        let nodeNext: TreeNode = nodeCase.arrChildren.find(x => x.objModel.hasOwnProperty('PNumber') && x.objModel.PNumber > objNode.objModel.PNumber && !x.objModel.Deleted);

        if (!nodeNext) {
            for (let i = nodeCase.arrChildren.length - 1; i >= 0; i--) { //If ascending order did not yield result, traverse in descending order
                if (nodeCase.arrChildren[i].objModel.hasOwnProperty('PNumber') &&
                    nodeCase.arrChildren[i].objModel.PNumber < objNode.objModel.PNumber &&
                    !nodeCase.arrChildren[i].objModel.Deleted
                ) {
                    nodeNext = nodeCase.arrChildren[i];
                    break;
                }
            }
        }

        if (!nodeNext)
            nodeNext = nodeCase;

        return nodeNext;
    }

    public async addVehicle(objEvent: Event, strVehType: string = ''): Promise<void> {
        if (this.objTreeDataClone.objSelected)
            if (!ObjectUtil.isAcc(this.objTreeDataClone.objSelected.objModel))
                this.objTreeDataClone.objSelected.Select(false); //If current selection is not a vehicle, unselect it

        this.objTreeDataClone.Select(true);
        this.objTreeDataClone.objSelected = this.objTreeDataClone;

        let objAcc: Acc = <Acc>this.objTreeDataClone.objSelected.objModel;
        let SSVehNum: number = objAcc.Veh.filter(v => v.VehType == strVehType).length + 1
        let objVeh = this._autofillService.AddVehicle(objAcc, -1, -1, SSVehNum, strVehType);
        objVeh.Added = true;
        this.arrNewVehicles.push(objVeh);
        let nodeVeh: TreeNode = await TreeNode.CreateTree_Vehicle(this.objTreeDataClone, objVeh, false, true, this._valueToDescriptionPipe);

        if (!this.blnCreatingNewCase)
            nodeVeh.strTitle += ' [Added]';

        if (this.objTreeDataClone.objSelected)
            this.objTreeDataClone.objSelected.Select(false);

        nodeVeh.Select(true);
        this.objTreeDataClone.objSelected = nodeVeh;
        this.OnTreeSelectionChanged(nodeVeh);
        this.blnDirty = true;
    }

    public async deleteVehicle(objEvent: Event, strVehType: string = ''): Promise<void> {
        let intVehCount: number;
        let strVehTypeMsg: string = 'There must be at least one vehicle involved in an accident';
        let isAllowDelete: boolean = false;

        if ((this.objTreeDataClone.objSelected) && (ObjectUtil.isVeh(this.objTreeDataClone.objSelected.objModel))) {
            let objVeh: Veh = <Veh>this.objTreeDataClone.objSelected.objModel;

            if (this.intMode == DBMode.MOSS) {
                if (objVeh.VehType == VehType.StrikingVehicle) {
                    intVehCount = objVeh.Acc.Veh.filter(x => !x.Deleted && x.VehType == VehType.StrikingVehicle).length
                    strVehTypeMsg = 'There must be at least one striking vehicle involved in an accident'
                    if (intVehCount > 1) isAllowDelete = true;
                }
                else if (objVeh.VehType == VehType.SFRVehicle) {
                    intVehCount = objVeh.Acc.Veh.filter(x => !x.Deleted && x.VehType == VehType.SFRVehicle).length
                    strVehTypeMsg = 'There must be at least one Struck Non-Occupant Subject or one Struck Subject Vehicle involved in a MOSS case'
                    if (intVehCount > 1) isAllowDelete = true;                    
                    else {
                        if ((objVeh.Acc.Non_Occupants.filter(x => !x.Deleted).length) > 0) isAllowDelete = true;
                    }
                }
                else if (objVeh.VehType == VehType.OtherVehicle) {                    
                    isAllowDelete = true;
                }
            }
            else {
                intVehCount = objVeh.Acc.Veh.filter(x => !x.Deleted).length
                if (intVehCount > 1) isAllowDelete = true;
            }

            if (isAllowDelete) {
                if (objVeh.Added) { //Not committed yet, so remove immediately. Renumber remaining Vehicles
                    let intTargetIndex: number = objVeh.Acc.Veh.indexOf(objVeh);
                    objVeh.Acc.Veh.splice(intTargetIndex, 1);

                    for (let i = intTargetIndex; i < objVeh.Acc.Veh.length; i++)
                        objVeh.Acc.Veh[i].VNumber = i + 1;

                    //Updated VNumber needs to be propagated down to all child nodes
                    this.objTreeDataClone = await TreeNode.CreateTree(this.objEarlyNotifyClone, !this.blnCreatingNewCase, true, this._valueToDescriptionPipe);
                }
                else {
                    objVeh.Deleted = true;
                    this.objTreeDataClone.objSelected.strTitle += ' [Deleted]';
                }

                this.objTreeDataClone.objSelected.Select(false);
                this.objTreeDataClone.objSelected = null;
                this.blnDirty = true;

                if (this.arrNewVehicles.indexOf(objVeh) != -1)
                    this.arrNewVehicles.splice(this.arrNewVehicles.indexOf(objVeh), 1);
            }
            else
                this._modalService.setMessage(strVehTypeMsg, 'warning');
        }
        else
            this._modalService.setMessage('Please select a vehicle', 'warning');
    }

    public async addOccupant(objEvent: Event): Promise<void> {
        if ((this.objTreeDataClone.objSelected) && (ObjectUtil.isVeh(this.objTreeDataClone.objSelected.objModel))) {
            let objVeh: Veh = <Veh>this.objTreeDataClone.objSelected.objModel;
            let objFirstAddition: TreeNode;

            if (!objVeh.Dri)
                this._autofillService.AddDriver(objVeh); //Ticket #6349 -Add a Dri row when there is an occupant in the vehicle. It does not matter if the PType of the Occupant is a Driver or not

            for (let i = 0; i < this.intPersonsToAdd; i++) {
                let objOcc: Occupants = this._autofillService.AddOccupant(objVeh);
                objOcc.Added = true;
                let nodeOcc: TreeNode = await TreeNode.CreateTree_Occupant(this.objTreeDataClone, this.objTreeDataClone.objSelected, objOcc, false, true, this._valueToDescriptionPipe);

                if (i == 0)
                    objFirstAddition = nodeOcc;

                if (!this.blnCreatingNewCase)
                    nodeOcc.strTitle += ' [Added]';
            }

            if (this.blnCreatingNewCase && this.objAcc.Mode == DBMode.FARS) {
                this.objTreeDataClone.objSelected.Select(false);
                objFirstAddition.Select(true);
                this.objTreeDataClone.objSelected = objFirstAddition;
                this.OnTreeSelectionChanged(objFirstAddition);
            }
            //else keep vehicle node selected

            this.intPersonsToAdd = 1;
            this.blnDirty = true;
        }
        else
            this._modalService.setMessage('Please select a vehicle', 'warning');
    }

    public deleteOccupant(objEvent: Event): void {
        if ((this.objTreeDataClone.objSelected) && (ObjectUtil.isOcc(this.objTreeDataClone.objSelected.objModel))) {
            let objOcc: Occupants = <Occupants>this.objTreeDataClone.objSelected.objModel;
            let intNodeOcc: number = this.objTreeDataClone.objSelected.objParent.arrChildren.indexOf(this.objTreeDataClone.objSelected);

            let nodeNextSelection: TreeNode = this.findNextNonDeletedOccupantNode(this.objTreeDataClone.objSelected);

            if (objOcc.Added) { //Not committed yet, so remove immediately. Renumber remaining occupants
                let intTargetIndex: number = objOcc.Veh.Occupants.indexOf(objOcc);
                objOcc.Veh.Occupants.splice(intTargetIndex, 1);
                this.objTreeDataClone.objSelected.objParent.arrChildren.splice(intNodeOcc, 1);

                //Alternatively call TreeNode.CreateTree() again so that string operations are not necessary
                for (let i = intTargetIndex; i < objOcc.Veh.Occupants.length; i++) {
                    let strOldNumber = '_' + (objOcc.Veh.Occupants[i].PNumber).toString() + '_';
                    objOcc.Veh.Occupants[i].PNumber = i + 1;
                    let strNewNumber = '_' + (objOcc.Veh.Occupants[i].PNumber).toString() + '_';
                    let strRenumbered = this.objTreeDataClone.objSelected.objParent.arrChildren[i].strTitle.replace(strOldNumber, strNewNumber);
                    this.objTreeDataClone.objSelected.objParent.arrChildren[i].strTitle = strRenumbered;
                }
            }
            else { //Flag for deletion
                objOcc.Deleted = true;
                this.objTreeDataClone.objSelected.strTitle += ' [Deleted]';
            }

            this.objTreeDataClone.objSelected.Select(false);
            nodeNextSelection.Select(true);
            this.objTreeDataClone.objSelected = nodeNextSelection;
            this.OnTreeSelectionChanged(nodeNextSelection);
            this.blnDirty = true;

            if (this.arrNewVehicles.indexOf(objOcc.Veh) == -1)
                this.arrNewVehicles.push(objOcc.Veh);
        }
        else
            this._modalService.setMessage('Please select an occupant', 'warning');
    }

    /**
     * Find the next non-deleted sibling Occupants node, or fall back on parent vehicle node.
     */
    private findNextNonDeletedOccupantNode(objNode: TreeNode): TreeNode {
        let nodeVeh: TreeNode = objNode.objParent;
        let nodeNext: TreeNode = nodeVeh.arrChildren.find(x => x.objModel.PNumber > objNode.objModel.PNumber && !x.objModel.Deleted);

        if (!nodeNext) {
            for (let i = nodeVeh.arrChildren.length - 1; i >= 0; i--) { //If ascending order did not yield result, traverse in descending order
                if (nodeVeh.arrChildren[i].objModel.PNumber < objNode.objModel.PNumber && !nodeVeh.arrChildren[i].objModel.Deleted) {
                    nodeNext = nodeVeh.arrChildren[i];
                    break;
                }
            }
        }

        if (!nodeNext)
            nodeNext = nodeVeh;

        return nodeNext;
    }

    /**
     * Applies the "Injury Severity" selection to the currently selected Occ/NonOcc TreeNode.
     * When structuring a new case and adding multiple Occupants to a Veh, the next selection becomes the next sibling 
     * Occupant without a coded Injury Severity, otherwise, the next selection becomes the parent vehicle.
     */
    public async renderSelectedInjury(objInjury: DrpDownOptions): Promise<void> {
        if (this.objTreeDataClone.objSelected) {
            if (ObjectUtil.isOcc(this.objTreeDataClone.objSelected.objModel) ||
                ObjectUtil.isNonOcc(this.objTreeDataClone.objSelected.objModel)) {

                let blnIsOcc: boolean = ObjectUtil.isOcc(this.objTreeDataClone.objSelected.objModel);
                let objVeh: Veh = blnIsOcc ? (<Occupants>this.objTreeDataClone.objSelected.objModel).Veh : null;

                this.blnDirty = true;
                this.objTreeDataClone = await TreeNode.CreateTree(this.objEarlyNotifyClone, !this.blnCreatingNewCase, true, this._valueToDescriptionPipe); //Rather than editing the label string to show the injury at the proper index, just recreate the tree.


                if (blnIsOcc) {
                    //Since we just recreated the tree, this.objTreeDataClone.objSelected is no longer populated, look up by model object instead.
                    let nodeVeh: TreeNode = this.objTreeDataClone.arrChildren.find(x => x.objModel == objVeh);
                    let nodeOcc: TreeNode = nodeVeh.arrChildren.find(x => x.objModel == this.objPerson);
                    let nodeOccNext: TreeNode = nodeOcc != null ? nodeVeh.arrChildren.find(x => x.objModel.PNumber > nodeOcc.objModel.PNumber && x.objModel.Injury == RBISDataValue.Blank) : null;

                    if (objInjury.intValue == RBISDataValue.Blank) {
                        nodeOcc.Select(true);
                        this.objTreeDataClone.objSelected = nodeOcc;
                        this.OnTreeSelectionChanged(nodeOcc);
                    }
                    else if (this.blnCreatingNewCase && this.objAcc.Mode == DBMode.FARS && nodeOccNext) {
                        nodeOccNext.Select(true);
                        this.objTreeDataClone.objSelected = nodeOccNext;
                        this.OnTreeSelectionChanged(nodeOccNext);
                    }
                    else {
                        nodeVeh.Select(true);
                        this.objTreeDataClone.objSelected = nodeVeh;
                        this.OnTreeSelectionChanged(nodeVeh);
                    }
                }
                else { //Non-Occupant
                    if (objInjury.intValue == RBISDataValue.Blank) {
                        let nodeNonOcc: TreeNode = this.objTreeDataClone.arrChildren.find(x => x.objModel == this.objPerson);
                        nodeNonOcc.Select(true);
                        this.objTreeDataClone.objSelected = nodeNonOcc;
                        this.OnTreeSelectionChanged(nodeNonOcc);
                    }
                    else { //Return to tree root
                        this.objTreeDataClone.Select(true);
                        this.objTreeDataClone.objSelected = this.objTreeDataClone;
                        this.OnTreeSelectionChanged(this.objTreeDataClone);
                    }
                }
            }
        }
    }

    /**
     * Don't use keydown event for hotkeys as Injury field get's focus immediately and will react to keyup event when key is released.
     */
    public OnKeyUp(objEvent: KeyboardEvent): void {
        if (objEvent.key == '/') //Intentionally not checking if buttons are enabled to not interrupt keyboard-driven flow
            this.addNonOccupant(objEvent);
        else if (objEvent.key == '*')
            this.addVehicle(objEvent);
        else if (objEvent.key == '+')
            this.addOccupant(objEvent);
    }

    /**
     * For reacting to TAB if (none of the TreeView nodes had focus and) TreeViewComponent did not react to TAB key press
     */
    public OnKeyDown(objEvent: KeyboardEvent): void {
        if (objEvent.key == KeyCode.Tab && !objEvent.defaultPrevented) { //Entered only if TreeViewComponent did not call preventDefault()
            if (this.objTreeDataClone.objSelected &&
                (<any>objEvent.target).nodeName != 'BUTTON'
                //&& (<any>objEvent.target).nodeName != 'INPUT'
                ) {
                    let arrDepthFirstFlat: Array<TreeNode> = this.objTreeDataClone.FlattenDepthFirst();
                    let intIndexCurrent: number = arrDepthFirstFlat.indexOf(this.objTreeDataClone.objSelected)
                    let intIndexNext: number = intIndexCurrent + (objEvent.shiftKey ? -1 : 1);

                    if (intIndexNext >= 0 && intIndexNext < arrDepthFirstFlat.length) {
                        this.SelectTreeNode(arrDepthFirstFlat[intIndexNext]);
                        objEvent.preventDefault();
                    }
            }
        }
    }

    public txtPersonsToAdd_OnKeyDown(objEvent: KeyboardEvent): void {
        if (objEvent.key == '+' || objEvent.key == '*' || objEvent.key == '/') {
            objEvent.preventDefault(); //Intercept typed char before it is added to the textbox. If we allow the nonnumeric symbol to be added to the textbox, the value of this.intPersonsToAdd is unavailable for the duration of this handler as the databinding cannot interpret the number

            if (objEvent.key == '+')
                this.addOccupant(objEvent);
        }
    }

    private SelectTreeNode(objNode: TreeNode) {
        if (objNode != this.objTreeDataClone.objSelected) {
            this.objTreeDataClone.objSelected.Select(false);
            objNode.Select(true);
            this.objTreeDataClone.objSelected = objNode;
            this.OnTreeSelectionChanged(objNode);
        }
    }
}
