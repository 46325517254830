import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-un-authorized',
  templateUrl: './un-authorized.component.html',
  styleUrls: ['./un-authorized.component.css']
})
export class UnAuthorizedComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    isAuthorized: boolean = true;;
    multipleSiteAccess: boolean = false;
    mossSCISiteList: any[];

    //authorized: boolean = true;
    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper,
        private authService: AuthService) {

        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
  }

    async ngOnInit() {
        this.isAuthorized = await this.authService._isAuthorized;
        this.mossSCISiteList = await this.authService.mossSCIsites;
        this.multipleSiteAccess = this._sharedDataService.IsMultipleSiteAccess(this.mossSCISiteList);
        console.log(this.isAuthorized);
        //if (this.mossSCISiteList.length > 1 && this.mossSCISiteList.find(x => x == 0) != undefined) {
        //    this.multipleSiteAccess = true;
        //}
        //else if (this.mossSCISiteList.length > 0 && this.mossSCISiteList.find(x => x == 1 || x == 2 || x == 3) == undefined) {
        //    this.multipleSiteAccess = true;
        //}
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public onBeforeSave(strParam: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
}


