import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { NgModel } from '@angular/forms';

import { Subscription } from 'rxjs';

import { NgbDateAdapter, NgbDateNativeAdapter, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UtilService } from 'src/app/services/util.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service'

import { Acc } from 'src/app/models/acc';
import { Occupants } from 'src/app/models/occupants';
import { OccRF } from 'src/app/models/occ-rf';
import { PII } from 'src/app/models/pii';
import { Veh } from 'src/app/models/veh';
import { Injury, Preference, KeyCode } from 'src/app/models/enums/app.enums';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { LabelComponent } from 'src/app/components/label/label.component';
import { MultiselectComponent } from 'src/app/components/multiselect/multiselect.component';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';

//emit this.pickedDate for binding pii.DoB field on person-person
import { ModalsAgeCalculatorComponent } from 'src/app/components/modals/modals-age-calculator/modals-age-calculator.component';

import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Key } from 'protractor';
import { FieldName } from 'src/app/models/enums/Generated/LookupTable';

@Component({
    selector: 'app-vehicle-person-person',
    templateUrl: './vehicle-person-person.component.html',
    styleUrls: ['./vehicle-person-person.component.css'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})

export class VehiclePersonPersonComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehiclePersonPersonComponent: string = null;
    private sbsVehicleId: Subscription;
    private sbsOccupantId: Subscription;
    sbsClearForm: Subscription;

    accid: number;
    caseid: number;
    vehicleid: number;
    personid: number;
    //ageCalculatorPupupPrefID: number = 307;

    acc: Acc;
    vehicle: Veh;
    public person: Occupants;
    personClone: Occupants;

    pii: PII;
    PIIClone: PII;
    public blnPIIVisible: boolean;

    accDate: Date;
    personDateOfBirth: Date;

    getMinDate: NgbDate;
    getMaxDate: NgbDate;

    mode: number
    //isPersonInfoVisible: boolean = false;
    initSelectedOptionsOccArf: Array<number> = [];

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChildren(MultiselectComponent) multiselects: QueryList<MultiselectComponent>;
    @ViewChildren(LabelComponent) labelComp: QueryList<LabelComponent>;

    //emit this.pickedDate for binding pii.DoB field on person-person
    @ViewChild(ModalsAgeCalculatorComponent) private ageCalculatorComponent: ModalsAgeCalculatorComponent;
    @ViewChild('ageField') ageField: TypeaheadComponent;
    @ViewChild('ageCalculator') ageCalculator: ModalsAgeCalculatorComponent;

    @ViewChild('dpDOB') public dpDOB: NgModel;

    objOccRFTemplate: OccRF;

    strMinValidation: string;
    streMaxValidation: string;

    blnShowValidationMessageOcc: boolean = false;
    blnRequiredOcc: boolean = false;
    blnInvalidOcc: boolean = false;
    blnIsDateInRangeOcc: boolean = false;
    //focuseOut: boolean = false;
    ageCalculatorPupupOn: boolean = true;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _preferencesService: SecUserPerferenceService,
        private _autofillService: AutofillService,
        private calendar: NgbCalendar,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
        this.blnFocusOnFirstField = false;
    }

    public async onBeforeSave() {
        if (this.dpDOB) {
            if (this.dpDOB.invalid) {
                //if (this.dpDOB.errors.required) {
                //    this._modalService.setMessage('Date of Birth is required.', 'danger');
                //    this.blnAlloweSave = false;
                //    return;
                //}
                if (this.dpDOB.errors.ngbDate.invalid) {
                    this._modalService.setMessage('Date of Birth must be a valid date (Ex: mm/dd/yyyy).', 'danger');
                    this.blnAlloweSave = false;
                    return;
                }
                if (this.dpDOB.errors.ngbDate.requiredBefore || this.dpDOB.errors.ngbDate.requiredAfter) {
                    this._modalService.setMessage("Date of Birth  must be between " + this.strMinValidation + " and " + this.streMaxValidation + ".", 'danger');
                    this.blnAlloweSave = false;
                    return;
                }
            }
        }
        this.blnAlloweSave = true;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    async ngOnInit() {
        super.ngOnInit();
        this.initBaseComponent();
        this.blnFocusOnFirstField = false;

        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(async params => { //When we navigate to same Route with different PARAMS we need to wrap logic with subscription for getting "IDs" in our case 'personid'  to fatch and manipalate new data in ngOnInit so that page get refrashed...
                this.accid = + params['caseid'];
                if (this.sbsVehicleId)
                    this.sbsVehicleId.unsubscribe();
                this.sbsVehicleId = this._route.parent.parent.params.subscribe(async params => {
                    this.vehicleid = + params['vehicleid'];
                    this._sharedDataService.setVehicleID(this.vehicleid);

                    if (this.sbsOccupantId)
                        this.sbsOccupantId.unsubscribe();

                    this.sbsOccupantId = this._route.parent.params.subscribe(async params => {
                        this.personid = + params['personid'];
                        await this.initData();
                    });
                });
            });
        } else {
            this.vehicleid = this.printVehNum;
            this.personid = this.personNum;
            this.accid = this.acc.AccID;
            //  this._sharedDataService.setVehicleID(this.vehicleid);
            await this.initData();
        }

    }

    async initData() {
        this.blnPIIVisible = false;
        this.personDateOfBirth = null;

        this.mode = this._sharedDataService.getMode();

        this.accDate = new Date(this.acc.CaseYear, this.acc.AccMon - 1, this.acc.AccDay, this.acc.AccHr, this.acc.AccMin, 0, 0);

        if (this.accDate) {
            this.getMinDate = new NgbDate(this.accDate.getFullYear() - 120, this.accDate.getMonth() + 1, this.accDate.getDate());
            this.getMaxDate = new NgbDate(this.accDate.getFullYear(), this.accDate.getMonth() + 1, this.accDate.getDate());
        }
        else {
            let today = new Date();
            this.getMinDate = new NgbDate(today.getFullYear() - 120, today.getMonth() + 1, today.getDate());
            this.getMaxDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
        }

        this.strMinValidation = (this.getMinDate.month - 1).toString() + "/" + this.getMinDate.day.toString() + "/" + this.getMinDate.year.toString();
        this.streMaxValidation = (this.getMaxDate.month - 1).toString() + "/" + this.getMaxDate.day.toString() + "/" + this.getMaxDate.year.toString();

        this._sharedDataService.setPersonId(this.personid);
        this._sharedDataService.setVehicleID(this.vehicleid);

        this.acc = await this._caseService.GetCasePromise(this.accid);
        this.vehicle = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        this.person = this.vehicle.Occupants.find(o => o.PNumber == this.personid);

        if (this.person) {
            this.personClone = ObjectUtil.CloneModelObject(this.person);
            this.objOccRFTemplate = ObjectUtil.InstantiateOccRF(this.person);

            // Begin ticket 6006 scenarios #4
            //this.focuseOut = false;
            //if (!this.printOnly) {
            //    setTimeout(() => {
            //        this.ageField.focus$.subscribe(async () => {
            //            //await this._preferencesService.isThisPreferenceOn(this.ageCalculatorPupupPrefID).then(item => {
            //            //    this.ageCalculatorPupupOn = item;
            //            //});
            //            if (this.ageCalculatorPupupOn && this.person.Age == -1 && !this.focuseOut) {
            //                this.focuseOut = true;
            //                this.ageCalculator.btnOpen.nativeElement.click();
            //            }
            //        });
            //    }, 1); // End ticket 6006 scenarios #4
            //}

            this.pii = this.acc.PII.find(p => p.AccID == this.person.AccID && p.VNumber == this.person.VNumber && p.PNumber == this.person.PNumber);

            if (this.pii !== null && this.pii !== undefined) {
                //DO NOT create complex crosslinks in data model as RemoveBidirectionalLinks does not distinguish between normal bidirectional links and
                //complex cross links, i.e. cloning of comlex crosslinks will produce unpredictable results. So, DON'T DO THIS: this.person.PII = this.pii;
                //and try to clone it afterwards.
                this.PIIClone = ObjectUtil.CloneModelObject(this.pii);

                if (this.isValidDate(this.pii.DoB)) {
                    this.personDateOfBirth = new Date(this.pii.DoB);
                }
                if (typeof this.pii.DoB === "string") {
                    this.personDateOfBirth = new Date(this.pii.DoB);
                }
                if (this.pii.DoB === null) {
                    this.personDateOfBirth = this.pii.DoB;
                }
            }

            if (this.person.Injury == Injury.Fatal || this.person.PType === 1) {

                if (!this.pii || this.pii === undefined) {
                    this.pii = this._autofillService.AddPersonsPII(this.person); //Adds PII object to Occupants.Veh.Acc.PII and returns it.
                }
                if (this.isValidDate(this.pii.DoB)) {
                    this.personDateOfBirth = new Date(this.pii.DoB);
                }
                if (typeof this.pii.DoB === "string") {
                    this.personDateOfBirth = new Date(this.pii.DoB);
                }
                if (this.pii.DoB === null) {
                    this.personDateOfBirth = this.pii.DoB;
                }

                this.blnPIIVisible = true;
            }
        }

        //Listen subscription for Clear Form btn event click;
        this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe(async item => {
            setTimeout(() => {//setting Timeout for model to get updated before subjectClearForm subscription occure!
                this.blnPIIVisible = false;
                this.personDateOfBirth = null;
                this.acc.PII.splice(this.acc.PII.findIndex(x => x == this.pii));
                this.pii = null;
                this.PIIClone = null;
                console.log('this.person.Injury: ', this.person.Injury);
                this.personClone = ObjectUtil.CloneModelObject(this.person); //Copy original data model in case user decides to abandon changes and revert to the original
            }, 1);
        });

    }
    isValidDate(d): boolean {
        return d instanceof Date && !isNaN(d.getMonth());
    }

    async OnModelPTypeChange($event) {// Using emiter for person.PType to set PII obj
        if (this.personClone.PType != this.person.PType) {
            if (this.personClone.PType !== 1 && this.person.PType === 1) { //initializing empty PII obj if it is not exist          
                if (this.person.Injury !== 4) {
                    this.pii = this._autofillService.AddPersonsPII(this.person);  //Adds PII object to Occupants.Veh.Acc.PII and returns it.
                    this.blnPIIVisible = true;
                }
                this.personClone.PType = this.person.PType;
            }
            else if (this.personClone.PType === 1 && this.person.PType !== 1) {
                let allowPTSaveForm: boolean = false;

                if (this.person.Injury !== 4) {
                    allowPTSaveForm = await this._modalService.dialogPromise("You have changed the Person Type of the Person. This would delete the Person Identification Information form and all data in it. Are you sure you want to proceed with this?", "Are you sure?");
                    if (!allowPTSaveForm) {// No
                        this.blnPIIVisible = true;
                        this.person.PType = this.personClone.PType;
                        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == FieldName.PType) as TypeaheadComponent;
                        let item = typeahead.options.find(x => x.intValue == this.person.Injury);
                        typeahead.bindItemAndFocusNextElement(item, false);
                    }
                    else {//Yes  
                        this.personDateOfBirth = null;
                        this.blnPIIVisible = false;
                        this.acc.PII.splice(this.acc.PII.findIndex(x => x == this.pii));
                        this.pii = null;
                        this.PIIClone = null;
                    }
                }
                this.personClone.PType = this.person.PType;
            }
        }
    }

    async OnModelChange($event) { // Using emiter for person.Injury to set PII obj
        if (this.personClone.Injury != this.person.Injury) {
            if (this.personClone.Injury !== Injury.Fatal && this.person.Injury === Injury.Fatal) { //initializing empty PII obj if it is not exist
                if (this.person.PType !== 1) {
                    this.pii = this._autofillService.AddPersonsPII(this.person); //Adds PII object to Occupants.Veh.Acc.PII and returns it.
                    this.blnPIIVisible = true;
                }
                this.personClone.Injury = this.person.Injury;
            }
            else if (this.personClone.Injury === Injury.Fatal && this.person.Injury !== Injury.Fatal) { //dialog
                let allowISaveForm: boolean = false;

                if (this.person.PType !== 1) {
                    allowISaveForm = await this._modalService.dialogPromise("You have changed the Injury Severity of the Person. This would delete the Person Identification Information form and all data in it. Are you sure you want to proceed with this?", "Are you sure?");
                    if (!allowISaveForm) { // No
                        this.blnPIIVisible = true;
                        this.person.Injury = this.personClone.Injury;
                        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == FieldName.Injury) as TypeaheadComponent;
                        let item = typeahead.options.find(x => x.intValue == this.person.Injury);
                        typeahead.bindItemAndFocusNextElement(item, false);
                    }
                    else {//Yes                     
                        this.personDateOfBirth = null;
                        this.blnPIIVisible = false;
                        this.acc.PII.splice(this.acc.PII.findIndex(x => x == this.pii));
                        this.pii = null;
                        this.PIIClone = null;
                    }
                }
                this.personClone.Injury = this.person.Injury;
            }
        }
    }

    async initBaseComponent() {
        await this._preferencesService.isThisPreferenceOn(Preference.AgeCalculaterPopUp)
            .then(item => {
                this.ageCalculatorPupupOn = item;
            });
        if (this.ageCalculatorPupupOn) {
            this.blnFocusOnFirstField = false;
        }
    }

    setDateOfBirth(event: Date) { //blnPIIVisible        
        this.MarkFormAsDirty();
        if (this.blnPIIVisible) {
            this.pii.DoB = event;
        }
        this.personDateOfBirth = event;
    }

    async onPIINameChange($event) {
        this.acc.PII.find(p => p.AccID == this.person.AccID && p.VNumber == this.person.VNumber && p.PNumber == this.person.PNumber).PName = $event;
        console.log(this.acc.PII);
    }

    async onPIIComment1Change($event) {
        this.acc.PII.find(p => p.AccID == this.person.AccID && p.VNumber == this.person.VNumber && p.PNumber == this.person.PNumber).Comment1 = $event;
        console.log(this.acc.PII);
    }

    async onPIIComment2Change($event) {
        this.acc.PII.find(p => p.AccID == this.person.AccID && p.VNumber == this.person.VNumber && p.PNumber == this.person.PNumber).Comment2 = $event;
        console.log(this.acc.PII);
    }

    onDateSelect(dpDOB: NgModel) {
        ObjectUtil.DateMask(event);
        this._modalService.resetMessage();
        this.blnShowValidationMessageOcc = false;
        this.blnRequiredOcc = false;
        this.blnInvalidOcc = false;
        this.blnIsDateInRangeOcc = false;

        if (!dpDOB.invalid) {
            this.pii.DoB = new Date(dpDOB.value);
            this.personDateOfBirth = dpDOB.value;
            this.acc.PII.find(p => p.AccID == this.person.AccID && p.VNumber == this.person.VNumber && p.PNumber == this.person.PNumber).DoB = dpDOB.value;
        }

        else if (dpDOB.value === null) {
            this.pii.DoB = dpDOB.value;
            this.acc.PII.find(p => p.AccID == this.person.AccID && p.VNumber == this.person.VNumber && p.PNumber == this.person.PNumber).DoB = dpDOB.value;
        }
    }

    calculateAge(): number {
        //this.accDate = new Date(this.accDate);
        //let acccYear = this.accDate.getFullYear();

        //let dateOfBirth = new Date(this.pii.DoB);
        //let birthYear = dateOfBirth.getFullYear();
        //let age = acccYear - birthYear;

        //if (this.accDate <= new Date(dateOfBirth.setFullYear(acccYear))) {
        //    age = age - 1;
        //}

        //return age;
        let PickedDate = new Date(this.pii.DoB);

        let accYear = this.accDate.getFullYear();
        let accmonth = this.accDate.getMonth();
        let accday = this.accDate.getDate();

        let birthyear = PickedDate.getFullYear();
        let birthmonth = PickedDate.getMonth();
        let birthday = PickedDate.getDate();

        let age = accYear - birthyear;
        let age_month = accmonth - birthmonth;
        let age_day = accday - birthday;

        if (age_month < 0 || (age_month == 0 && age_day < 0)) {
            age = age - 1;
        }

        return age;

    }

    onBlur(dpDOB) {
        this.blnShowValidationMessageOcc = false;
        this.blnRequiredOcc = false;
        this.blnInvalidOcc = false;
        this.blnIsDateInRangeOcc = false;
        if (dpDOB.value) {
            if (dpDOB.invalid) {
                this.blnShowValidationMessageOcc = true;
                if (dpDOB.errors.required) {
                    this._modalService.setMessage('Date of Birth is required.', 'danger');
                }
                if (dpDOB.errors.ngbDate.invalid) {
                    this._modalService.setMessage('Date of Birth must be a valid date (Ex: mm/dd/yyyy).', 'danger');
                }
                if (dpDOB.errors.ngbDate.requiredBefore || dpDOB.errors.ngbDate.requiredAfter) {
                    this._modalService.setMessage("Date of Birth  must be between " + this.strMinValidation + " and " + this.streMaxValidation + ".", 'danger');
                }
            }
            else {
                this._modalService.resetMessage();
                this.pii.DoB = new Date(dpDOB.value);
                this.personDateOfBirth = dpDOB.value;

                let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "Age") as TypeaheadComponent;
                let item = typeahead.options.find(x => x.intValue == this.calculateAge());
                typeahead.bindItemAndFocusNextElement(item, false);
                let txtComment1: HTMLElement = document.getElementById('Comment1');

                if (txtComment1)
                    txtComment1.focus();
            }
        }
    }

    handleKeyup(event: KeyboardEvent) {
        if (event.code == KeyCode.F9 || event.code == KeyCode.Minus || event.code == KeyCode.NumpadSubtract) {
            this.ageCalculator.btnOpen.nativeElement.click();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsClearForm) {
            this.sbsClearForm.unsubscribe();
        }
    }

}
