import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

//import { SharedDataService } from './../services/shared-data.service';
import { Acc } from '../../models/acc';
import { Occupants } from '../../models/occupants';
import { Veh } from '../../models/veh';

import { CaseService } from '../../services/case.service';
import { SharedDataService, AppSettings } from '../../services/shared-data.service';
import { UtilService } from 'src/app/services/util.service';
import { FormName, DBMode } from 'src/app/models/enums/app.enums';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

    private sbsVehicleId: Subscription;
    stateNum: number;
    vehicleid: number;
    vehid_SS: number;
    accid: number;    
    case: Acc;
    vehicle: Veh;
    intMode: number;
  
    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        private _utilService: UtilService
    ) { }

    ngOnInit() {
        this._route.parent.params.subscribe((params => {
            this.stateNum = + params['stateNum'];
            this.accid = +params['caseid'];
            
            if (!this._sharedDataService.selectedState) {
                this._sharedDataService.setSelectedState(this.stateNum);
            }

            if (this.sbsVehicleId)
                this.sbsVehicleId.unsubscribe();

            this.sbsVehicleId = this._route.params.subscribe((params => {
                this.vehicleid = + params['vehicleid'];
                this._sharedDataService.setVehicleID(this.vehicleid);

                this._caseService.GetCasePromise(this.accid).then((objAcc => {               
                    this.vehicle = objAcc.Veh.find(x => x.VNumber == this.vehicleid);

                    if (!this.vehicle) //Url specifies a non-existent vehicle (or recently deleted vehicle), fall back on top-level case URL
                        this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid]);
                }).bind(this));
            }).bind(this));
        }).bind(this));

    }

    async ngAfterViewInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = appSettings.intMode;

        if (this.intMode == DBMode.MOSS)
            this.vehid_SS = this.vehicle.Veh_SS.SSVNumber;

        else
            this.vehid_SS = this.vehicleid;
        console.log(this.vehid_SS, this.vehicle, this.intMode);
    }
}
