import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from '../../../services/case.service';
import { UtilService } from '../../../services/util.service';
import { Acc } from '../../../models/acc';
import { Non_Occupants } from '../../../models/non-occupants';
import { Element_Specify } from '../../../models/element-specify';
import { TableFieldElements } from '../../../models/table-field-elements';
import { DrpDownOptions } from '../../../models/drp-down-options';
import { FormName, TableId, KeyCode, DBMode, Preference, RBISDataValue } from '../../../models/enums/app.enums';
import { TypeaheadComponent } from '../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service'
import { ModalsAlcoholCalculatorComponent } from 'src/app/components/modals/modals-alcohol-calculator/modals-alcohol-calculator.component';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-non-occupant-alcohol',
    templateUrl: './non-occupant-alcohol.component.html',
    styleUrls: ['./non-occupant-alcohol.component.css']
})
export class NonOccupantAlcoholComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_NonOccupantAlcoholComponent: string = null;
    private sbsNonOccId: Subscription;

    intMode: number;

    accid: number;
    nonOccid: number;

    acc: Acc;
    nonOccupant: Non_Occupants;


    alcConvPupupOn: boolean = true;
    focuseOut: boolean = false
    hideDrugInvolvement: boolean = true;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('alcTestResult') alcTestResult: TypeaheadComponent;
    @ViewChild('alcElement') alcElement: ModalsAlcoholCalculatorComponent;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _preferencesService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        super.ngOnInit();

        let appSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = await appSettings.intMode;

        if (this.printNonOccupantNum == 0) {
            this._route.parent.parent.params.subscribe((params => {
                this.accid = + params['caseid'];
            }).bind(this));
        }
        else {
            this.accid = this.acc.AccID;
        }

        if (this.sbsNonOccId) {
            this.sbsNonOccId.unsubscribe();
        }

        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(async params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();

        }

        this.hideDrugInvolvement = ((this.intMode == DBMode.FARS || this.intMode == DBMode.RECODE));
    }

    FormLoad() {
        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);

        this.focuseOut = false;

        setTimeout(() => {
            this.alcTestResult.focus$.subscribe(async () => {
                await this._preferencesService.isThisPreferenceOn(Preference.AlcoholConvPopup)
                    .then(item => {
                        this.alcConvPupupOn = item;
                    });
                if (this.alcConvPupupOn && this.nonOccupant.AlcRes == RBISDataValue.Blank && !this.focuseOut) {
                    this.focuseOut = true;
                    this.alcElement.btnOpen.nativeElement.click();
                }
            })
        }, 1000);
    }

    setAlcoholValue(event) {
        this.nonOccupant.AlcRes = event;
        let component: TypeaheadComponent = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.AlcRes);
        if (component) {
            component.setCurrentValue(event);
        }
    }

    handleKeyup(event: KeyboardEvent) {
        if (event.code == KeyCode.F9 || event.code == KeyCode.Minus || event.code == KeyCode.NumpadSubtract) {
            this.alcElement.btnOpen.nativeElement.click();
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
