<div *ngIf="acc && intMode" class="border-dark border-start border-bottom border-end px-2">
    <div class="w-auto">
        <ul class="nav nav-tabs border-dark border-left bg-white">
            <li class="nav-item mt-1 ml-1">
                <a class="nav-link" [routerLink]='["./crash"]'
                   [ngClass]="{'bg-light': linkcrash.isActive === true}"
                   [routerLinkActive]="['active']" #linkcrash="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': linkcrash.isActive === true, 'fw-bold' : linkcrash.isActive === true}">
                            Crash
                        </span>
                    </span>
                </a>
            </li>
            <li class="nav-item mt-1 ml-1">
                <a class="nav-link" [routerLink]='["./events"]'
                   [ngClass]="{ 'bg-light': linkevents.isActive === true }"
                   [routerLinkActive]="['active']" #linkevents="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': linkevents.isActive === true, 'fw-bold' : linkevents.isActive === true}">
                            Crash Event
                        </span>
                    </span>
                </a>
            </li>
            <li class="nav-item mt-1 ml-1">
                <a class="nav-link" [routerLink]='["./trafficway"]'
                   [ngClass]="{'bg-light': linktrafficway.isActive === true}"
                   [routerLinkActive]="['active']" #linktrafficway="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': linktrafficway.isActive === true, 'fw-bold' : linktrafficway.isActive === true}">
                            Trafficway
                        </span>
                    </span>
                </a>
            </li>
            <!--<li class="nav-item mt-1 ml-1" *ngIf="intMode == 128">
                <a class="nav-link" [routerLink]='["./other"]'
                   [ngClass]="{'bg-light': linkother.isActive === true}"
                   [routerLinkActive]="['active']" #linkother="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': linkother.isActive === true, 'fw-bold' : linkother.isActive === true}">Other</span>
                    </span>
                </a>
            </li>-->
            <li class="nav-item mt-1 ml-1" *ngIf="intMode == 128">
                <a class="nav-link" [routerLink]='["./nonmotorist"]'
                   [ngClass]="{ 'bg-light': linkcrashnotes.isActive === true }"
                   [routerLinkActive]="['active']" #linkcrashnotes="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': linkcrashnotes.isActive === true, 'fw-bold' : linkcrashnotes.isActive === true}">
                            Non-Motorist
                        </span>
                    </span>
                </a>
            </li>
            <li class="nav-item mt-1 ml-1">
                <a class="nav-link" [routerLink]='["./crashnotes"]'
                   [ngClass]="{ 'bg-light': linkcrashnotes.isActive === true }"
                   [routerLinkActive]="['active']" #linkcrashnotes="routerLinkActive">
                    <span class="text-dark">
                        <span [ngClass]="{'text-decoration-none': linkcrashnotes.isActive === true, 'fw-bold' : linkcrashnotes.isActive === true}">
                            Crash Notes
                        </span>
                    </span>
                </a>
            </li>
        </ul>
        <!--here we generate and show Crash-children html's components-->
        <div class="tab-content border-dark">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

