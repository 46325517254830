import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from '../../../services/case.service';

import { Acc } from '../../../models/acc';
import { Veh } from '../../../models/veh';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { VehType } from 'src/app/models/enums/app.enums';

@Component({
  selector: 'app-sfr-vehicle-vehicle',
  templateUrl: './sfr-vehicle-vehicle.component.html',
  styleUrls: ['./sfr-vehicle-vehicle.component.css']
})
export class SfrVehicleVehicleComponent implements OnInit, OnDestroy, AfterViewInit {

    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    showTab: boolean = true;
    intMode: number;

    constructor(
        private _route: ActivatedRoute,
        private _caseService: CaseService,
        private _sharedDataService: SharedDataService
    ) {
    }

    async ngOnInit() {
        //let appSettings = await this._sharedDataService.GetAppSettings();
        //this.intMode = appSettings.intMode;

        this._route.parent.parent.params.subscribe(params => {
            this.accid = + params['caseid'];
        });
        this._route.parent.params.subscribe(params => {
            this.vehicleid = + params['vehicleid'];
            this._caseService.GetCasePromise(this.accid).then(data => {

                this.veh = data.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.SFRVehicle);

                if (this.veh != undefined && this.veh != null)
                    //this.showTab = this._caseService.CheckTabDisplay(this.veh);
                    this._caseService.GetTabDisplayStatus(this.veh);
            });
        });
    }
    async ngAfterViewInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = appSettings.intMode;
    }

    ngOnDestroy() {
    }
}

