import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, from, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DialogComponent } from 'src/app/components/dialog/dialog.component'
import { UtilService } from './util.service';
import { CrashTypeComponent } from '../ui/crash/crash-type/crash-type.component';
import { Acc } from '../models/acc';
import { GeolocatorComponent } from '../components/geolocator/geolocator.component';
import { MessageBoxComponent } from '../components/message-box/message-box.component';
import { AirbagchildsafetyComponent } from '../components/airbagchildsafety/airbagchildsafety.component';
import { AvoindaceNotificationComponent } from '../components/avoindace-notification/avoindace-notification.component';
import { VinrequestComponent } from '../components/vinrequest/vinrequest.component';
import { OOSRequest } from '../models/oos-request';
import { PrintcaseComponent } from '../ui/cases/printcase/printcase.component';
import { PedcopydataComponent } from '../components/pedcopydata/pedcopydata.component';
import { BikecopydataComponent } from '../components/bikecopydata/bikecopydata.component';
import { DeleteCrssCaseComponent } from '../components/delete-crss-case/delete-crss-case.component';

/**
 * Wraps certain components in ngbModal, like Crash Type Wizard
 **/
@Injectable({
    providedIn: 'root'
})
export class ModalService_CannedPopups {
    public wizardType: string = '';

    constructor(public ngModal: NgbModal,
        private _utilService: UtilService
    ) {
    }

    wizard(type: any, selectedOldList: Array<any> = []) {
        this._utilService.getWizardOptions(type);
        const modal = this.ngModal.open(DialogComponent, { backdrop: 'static', size: 'pedbike' as 'lg', centered: true });
        modal.componentInstance.module = 'W';
        modal.componentInstance.modalFor = type;
        modal.componentInstance.selectedOldOptions = selectedOldList;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }

    crashTypeWizard(accData: Acc, selectedOldOptions: Array<any> = []) {
        this._utilService.getCrashTypeWizard();
        const modal = this.ngModal.open(CrashTypeComponent, {
            size: 'crashtype' as 'lg', windowClass: 'modalWindowSize', centered: true
        });
        modal.componentInstance.currentOptions = [];
        modal.componentInstance.acc = accData;
        modal.componentInstance.selectedOldOptions = selectedOldOptions;

        return from(modal.result).pipe(

            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }

    copyExistingData(content, modalSize: string = 'roadway') {
        // this._utilService.getWizardOptions('bike');
        const modal = this.ngModal.open(content, { backdrop: 'static', size: modalSize as 'lg', centered: true });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }


    copyExistingPedestrianData(allExisitingPedList: any, filteredPedLookupData: any) {
        // this._utilService.getWizardOptions('bike');
        const modal = this.ngModal.open(PedcopydataComponent, {
            backdrop: 'static', size: 'pedestrian' as 'lg', centered: true
        });
        modal.componentInstance.allExisitingPedList = allExisitingPedList;
        modal.componentInstance.filteredPedLookupData = filteredPedLookupData;

        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }

    copyExistingBikeData(allExisitingBikeList: any, filteredBikeLookupData: any) {
        // this._utilService.getWizardOptions('bike');
        const modal = this.ngModal.open(BikecopydataComponent, {
            backdrop: 'static', size: 'bike' as 'lg', centered: true
        });
        modal.componentInstance.allExisitingBikeList = allExisitingBikeList;
        modal.componentInstance.filteredBikeLookupData = filteredBikeLookupData;

        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }
    //geo
    geoLocator(road1: string, road2: string, lat: string, lng: string, county: string, state: string) {
        const modal = this.ngModal.open(GeolocatorComponent, { size: 'geo' as 'lg', backdrop: 'static', centered: true });

        modal.componentInstance.lat = lat;
        modal.componentInstance.lng = lng;

        modal.componentInstance.road1 = road1;
        modal.componentInstance.road2 = road2;

        modal.componentInstance.county = county;
        modal.componentInstance.state = state;


        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }
    //end of geo

    //open drug test conformation modal -- revisit in the future;
    openDrugTest(content, obj: any) {
        const modal = this.ngModal.open(content, obj);
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }

    airbagFatality(accData: Acc): Observable<boolean> {

        const modal = this.ngModal.open(AirbagchildsafetyComponent, { backdrop: 'static', size: 'sm', centered: true });

        modal.componentInstance.acc = accData;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )

    }

    //avoindance notifications:

    avoindanceNotification(caseNumber, isFirstPopup, intMode: number, acc: Acc): Observable<boolean> {

        const modal = this.ngModal.open(AvoindaceNotificationComponent, { backdrop: 'static', size: 'sm', centered: true });

        modal.componentInstance.caseNumber = caseNumber;
        modal.componentInstance.isFirstPopup = isFirstPopup;
        modal.componentInstance.intMode = intMode;
        modal.componentInstance.acc = acc;
        
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )

    }

    //vin request
    vinRequest(accid: number, vehNumber: number, trailerNumber: number, caseNumber: number, isReadOnly: boolean = false, vin: string = ""): Observable<boolean> {

        const modal = this.ngModal.open(VinrequestComponent, { backdrop: 'static', size: 'lg', centered: true });
        modal.componentInstance.accid = accid;
        modal.componentInstance.vehNumber = vehNumber;
        modal.componentInstance.seqNum = trailerNumber;
        modal.componentInstance.caseNumber = caseNumber;
        modal.componentInstance.isReadOnly = isReadOnly;
        modal.componentInstance.vin = vin;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )

    }

    delayReason(DelayReason) {
        const modal = this.ngModal.open(DelayReason, { backdrop: 'static', size: 'earlynotify' as 'lg', centered: true });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }


    changeCaseStatus(componentToWrapInModalDiv: any, acc: Acc) {

        const modal = this.ngModal.open(componentToWrapInModalDiv, { backdrop: 'static', size: 'lg', centered: true });
        modal.componentInstance.acc = acc;


        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )

    }

    printCase(accId: number) {
        const modal = this.ngModal.open(PrintcaseComponent, { backdrop: 'static', size: 'lg', centered: true });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }

    deleteCrssCase(acc, isUnDelete: boolean = false) {

        const modal = this.ngModal.open(DeleteCrssCaseComponent, { backdrop: 'static', size: 'lg', centered: true });
        modal.componentInstance.acc = acc;
        modal.componentInstance.isUnDeleteMode = isUnDelete;
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    caseStructureOption(caseStructure) {
        const modal = this.ngModal.open(caseStructure, { backdrop: 'static', size: 'lg', centered: true });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }
}


