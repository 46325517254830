import { Injectable, PipeTransform } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, of, Subject, empty } from 'rxjs';
import { GenericService } from './generic.service';
import { SecUserPreference } from './../models/SecUserPreference';
import { GetSecUserPreferencesResult } from 'src/app/models/GetSecUserPreferencesResult'

@Injectable({
    providedIn: 'root'
})

export class SecUserPerferenceService {

    private secUserPreferences: BehaviorSubject<GetSecUserPreferencesResult[]>  = new BehaviorSubject<GetSecUserPreferencesResult[]>(null);
    private userPreferences: Array<GetSecUserPreferencesResult> = [];

    constructor(private _baseService: GenericService<any>) { }

    /**
     * Get the set of permissions for the current user. The result is not available immediately - this function returns an observable that must be subscribed to.
     **/
    public getSecUserPreferences(): Observable<GetSecUserPreferencesResult[]> {
        this._baseService.actionURL = "api/SecUserPreference/GetSecUserPreferences/";
        let obsPrefs: Observable<GetSecUserPreferencesResult[]> = this._baseService.getData();

        obsPrefs.subscribe(((arrPrefs: GetSecUserPreferencesResult[]) => {
            this.userPreferences = arrPrefs;
            this.secUserPreferences.next(arrPrefs)
        }).bind(this));

        return obsPrefs;
    }

    /**
     * Call this method only after you are sure that getSecUserPreferences() has been called at least once.
     **/
    public get getCachedListOfSecUserPerferences(): Observable<any> {
        return this.secUserPreferences.asObservable();
    }

    public clearObservable() {
        this.secUserPreferences.next([]);
        this.userPreferences = [];
    }

    public saveSecUserPreferences(items: SecUserPreference[]) {
        let userPref: Array<SecUserPreference> = new Array<SecUserPreference>();

        items.forEach((item) => {
            let per: SecUserPreference = {
                _TypeScript_TypeGuard_SecUserPreference: null,
                PreferenceID: item.PreferenceID,
                PreferenceValue: item.PreferenceValue,
                SecApplicationID: item.SecApplicationID,
                PreferenceKey: item.PreferenceKey
            } as SecUserPreference;

            userPref.push(per);
        });

        this._baseService.actionURL = "api/SecUserPreference/SaveSecUserPreference/";
        this._baseService.updateEntity(userPref).subscribe(x => this.getSecUserPreferences()); //On Save returning, read back saved data and update BehaviorSubject
    }

    public async isThisPreferenceOn(preferenceKey: string): Promise<boolean> {
        if (this.userPreferences) {
            if (this.userPreferences.length) {
                //let intPreferenceId = this.userPreferences.find(i => i.PreferenceKey == preferenceKey).PreferenceID;
                let objPreference = this.userPreferences.find(x => x.PreferenceKey == preferenceKey);
                if (objPreference)
                    return objPreference.PreferenceValue;
                else
                    return false;
            }
        }

        //If we got to this point, then this preference data has not yet been retrieved
        let myPreferences: Array<GetSecUserPreferencesResult> = await this.getSecUserPreferences().toPromise();

        if (myPreferences) {
            let objPreference = myPreferences.find(x => x.PreferenceKey == preferenceKey);

            if (objPreference)
                return objPreference.PreferenceValue;
            else
                return false;
        }
        else
            return false;
    }
}
