<form novalidate #fnonOccbicyclist="ngForm">
    <div class="pt-2" *ngIf="nonOccupant">

        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="" [ngClass]="{'row' : printOnly !== true }">

                        <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto' : printOnly !== true }"  *ngIf="!printOnly">
                            <div [ngClass]="{'pl-2' : printOnly !== true }">
                                <span *ngIf="!printOnly" class="my-auto">
                                    <button type="button" id="btnBicyclist_Copy" [disabled]="filteredBikeLookupData.length==0" class="btn btn-sm btn-light border-secondary"
                                            (click)="onCopyBike()">
                                        <span class="text-dark fw-bold" >Copy Bicyclist data</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                            <div [ngClass]="{'pl-2' : printOnly !== true }" class="">
                                <app-label class="mr-2" inputName="SchoolZone" [printOnly]="printOnly"></app-label>
                            </div>
                        </div>

                        <div [ngClass]="{'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : printOnly !== true }">
                            <div class="pl-2">
                                <app-typeahead #schoolZone id="schoolZone" name="schoolZone" typeAheadPosition="right-top"
                                               disableLabel="true"
                                               [objModel]="nonOccupant.NonOcc_Bike" (keyup)="handleKeyup($event)" strTableName="NonOcc_Bike" strFieldName="SchoolZone"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #crosswalk id="crosswalk" name="crosswalk" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Bike" strTableName="NonOcc_Bike" strFieldName="Crosswalk"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #sidewalk id="sidewalk" name="sidewalk" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Bike" strTableName="NonOcc_Bike" strFieldName="Sidewalk"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div *ngIf="!printOnly" class="form-group">
                    <span class="pl-2">
                        <button type="button" id="btnBicyclist_BikeWizard" class="btn btn-sm btn-light border-secondary pl-2 for-focus"
                                (click)="onBikeWizardClick()">
                            <span class="text-dark fw-bold">Please click here to start the Bike Crash Type wizard.</span>
                        </button>
                    </span>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #CrashType id="crashType" name="crashType" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Bike" strTableName="NonOcc_Bike" strFieldName="CrashType"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #CrashGroup id="crashGroupBike" name="crashGroupBike" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Bike" strTableName="NonOcc_Bike" strFieldName="CrashGroup"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #CrashLocation id="crashLocation" name="crashLocation" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Bike" strTableName="NonOcc_Bike" strFieldName="CrashLocation"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #BikePosition id="bikePosition" name="bikePosition" typeAheadPosition="right-top" [disabled]="true"
                                   [objModel]="nonOccupant.NonOcc_Bike" strTableName="NonOcc_Bike" strFieldName="BikePosition"
                                   [printOnly]="printOnly"
                                   (bindModel)="fieldValueChange('BikePosition', $event, NonOcc_Bike)"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #BikeDirection id="bikeDirection" name="bikeDirection" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Bike" strTableName="NonOcc_Bike" strFieldName="BikeDirection"
                                   [printOnly]="printOnly"
                                    (bindModel)="fieldValueChange('BikeDirection', $event, NonOcc_Bike)"

                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <div class="row my-auto" [ngClass]="{'pl-3' : printOnly === true }">
                        <div for="CrashTypeExpanded" [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 fw-bold' : printOnly !== true }" *ngIf="!printOnly">Crash Type Expanded</div>
                        <div [ngClass]="[!printOnly ? 'col-xl-3' : '']">
                            <div class="input-group" *ngIf="!printOnly">
                                <div class="input-group-append">
                                    <input id="CrashTypeExpanded" type="text" class="form-control w-50" name="crashTypeExpanded" [ngModel]="crashTypeExpanded" disabled="disabled">
                                </div>
                            </div>
                            <div *ngIf="printOnly">
                                <app-label [label]="'Crash Type Expanded'"
                                           [isStaticLabel]="true"
                                           [printOnly]="printOnly" style="font-size:14px"></app-label>
                            </div>
                            <span *ngIf="printOnly">
                                <input type="text" *ngIf="!clearFieldData" style="font-size: 14px; resize: none; overflow: hidden; height: 29px;" class="form-control bg-white w-100" disabled="disabled" name="crashTypeExpanded" [ngModel]="crashTypeExpanded">
                                <input type="text" *ngIf="clearFieldData" style="font-size: 14px; resize: none; overflow: hidden; height: 29px;" class="form-control bg-white w-100" disabled="disabled" name="crashTypeExpanded">
                            </span>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>

    </div>
</form>

<!--<ng-container *ngIf="hasWizardFilled">
    <div>
        <strong>Selected Crash Types </strong>
    </div>
    <h5 class="inline-h5" *ngFor="let item of selectedWizardList, index as index">
        <span class="badge badge-primary">
            {{((item.Value!=null && item.Value!='') ? item.Value +' - ': '') }} {{item.Label}}
            <i class="fas fa-angle-right" *ngIf="(index!=selectedWizardList.length-1)"></i>
        </span>
    </h5>
</ng-container>-->


<ng-container *ngIf="!printOnly">
    <ng-container *ngIf="hasWizardFilled">
        <div class="form-group">
            <div class="">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="inputPedestrian_selectedWizardList" class="col-form-label fw-bold pl-2">                          
                            Step by Step selection of Crash Type
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="ml-2">
                            <h3 class="inline-h5" *ngFor="let item of selectedWizardList, index as index">
                                <span class="badge bg-light border border-secondary mb-1">
                                    {{getSelectedLabel(index)}}
                                </span>
                                <i class="fas fa-angle-right mx-1" *ngIf="(index!=selectedWizardList.length-1)"></i>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
