
<div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">VIN Request Details</h5>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-xl">
            <div class="card">
                <div class="card-body">
                 
                    <div class="row">
                        <div class="col-10">
                            <p *ngIf="hasEmailSent">
                                <ngb-alert [type]="success" (close)="close(alert)" class="text-center">An email has been sent successfully.</ngb-alert>
                            </p>
                            <div class="row">
                                <div class="col-sm-3" *ngIf="selectedMode == 1 || selectedMode == 64">
                                    Case Number: {{model.CaseNumber | number : '4.0-0' | replaceTextPipe : ',' : ''}}
                                </div>
                                <div class="col-sm-3" *ngIf="selectedMode == 4">
                                    PAR ID: {{acc.EarlyNotify[0].PARID | number: '8.0-0' | replaceTextPipe : ',' : '' | maxLengthPipe : 12 : '' | slice: 4:12}}
                                </div>
                                <div class="col-sm-3">
                                    Vehicle Number:	{{model.VNumber}}
                                </div>
                                <div class="col-sm-2" *ngIf="model.TNumber>0">
                                    Trailer #: {{model.TNumber}}
                                </div>
                                <div class="col-sm-4">
                                    VIN: {{ (model.OriginalVIN!=''? model.OriginalVIN: vin) }}
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <div class="card">
                                        <div class="card-header">
                                            Vehicle Information:
                                        </div>
                                        <div class="card-body">
                                            <div class="row mt-1">
                                                <div class="col-sm-2">
                                                    vPIC:
                                                </div>
                                                <div class="col-sm-8">
                                                    {{model.VPic}}
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-sm-2">
                                                    NCSA:
                                                </div>
                                                <div class="col-sm-8">
                                                    {{model.NCSA}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3" *ngIf="!isReadOnly">
                                <div class="col-sm-12">
                                    <div class="card">
                                        <div class="card-header">
                                            Comments:
                                        </div>
                                        <div class="card-body">
                                            <div class="row mt-1">
                                                <div class="col-sm-12">
                                                    <div class="form-group remove-bm">
                                                        <textarea id="inputVINRequestDetails_Comments" class="form-control" [(ngModel)]="comment" placeholder="Fix VIN" rows="3"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="mode !='new'">
                                <div class="col-sm-12">
                                    <div class="card">
                                        <div class="card-header">
                                            VIN Request:
                                        </div>
                                        <div class="card-body">
                                            <div class="row mt-1">
                                                <div class="col-sm-2">
                                                    Status:
                                                </div>
                                                <div class="col-sm-8">
                                                    {{model.Status}}
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-sm-2">
                                                    Resolution:
                                                </div>
                                                <div class="col-sm-8">
                                                    <ng-container *ngIf="isAdmin && !isReadOnly">
                                                        <select [(ngModel)]="model.ResolutionId" class="form-control">
                                                            <option *ngFor="let item of model.ReqResolutionList" [value]="item.Id">{{item.Def}}</option>
                                                        </select>
                                                    </ng-container>
                                                    <ng-container *ngIf="!isAdmin || isReadOnly">
                                                        {{model.Resolution}}
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-sm-2">
                                                    Fixed VIN:
                                                </div>
                                                <div class="col-sm-8">
                                                    <ng-container *ngIf="isAdmin && !isReadOnly">
                                                        <input type="text" [(ngModel)]="model.FixVin" maxlength="17" class="form-control" />
                                                    </ng-container>
                                                    <ng-container *ngIf="!isAdmin || isReadOnly">
                                                        {{model.FixVin}}
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" class=" text-center">Date</th>
                                                            <th scope="col" class=" text-center">User</th>
                                                            <th scope="col" class=" text-center">Comment</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of model.VinReqCommentList, index as i">
                                                            <td scope="col" class="text-center">
                                                                {{item.EventDate | date:'M/d/yyyy, h:mm:ss a'}}
                                                            </td>
                                                            <td scope="col" class="text-center"> {{item.CreatedBy}}</td>
                                                            <td scope="col" class="text-center"> {{item.Comment}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <div class="col-2" >
                                <div class="border-left border-secondary pl-2">
                                    <div class="form-group">
                                        <button type="button" id="btnVinRequestDetails_back" class="btn btn-sm btn-light border-secondary btn-block" (click)="GotBack()">&nbsp; Back &nbsp; </button>
                                    </div>
                                    <div class="form-group" *ngIf="!isReadOnly">
                                        <button type="button" id="btnVinRequestDetails_gotoVinRequest" class="btn btn-sm btn-light border-secondary btn-block" (click)="gotoVinRequest()">Go to VIN Requests</button>
                                    </div>
                                    <div class="form-group" *ngIf="mode =='new' && !isReadOnly">
                                        <button type="button" id="btnVinRequestDetails_saveVinRequest" class="btn btn-sm btn-primary btn-block" (click)="saveVinRequest()">Send VIN</button>
                                    </div>
                                    <div class="form-group" *ngIf="mode !='new' && !isReadOnly">
                                        <button type="button" id="btnVinRequestDetails_saveVinRequest" class="btn btn-sm btn-primary btn-block" (click)="saveVinRequest()">Update Request</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal-footer">
</div>

<ng-template #alertMessage let-modal>
    <div class="modal-body text-center">
        <p>{{message}}</p>
    </div>

    <div class="modal-footer">
        <button  type="button" class="btn btn-outline-dark" (click)="innerPopup.close('ok')">
            Ok
        </button>
        <button  type="button" class="btn btn-outline-dark" (click)="innerPopup.dismiss('cancel')">
            Cancel
        </button>
    </div>
</ng-template>

