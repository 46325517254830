<span>
    <button #btnOpen *ngIf="strForm === 'Home'" class="btn btn-link px-0" (click)="open(content, null)">Distance Calculator</button>

    <span class="">
        <button #btnOpen *ngIf="strForm !== 'Home'" class="btn btn-sm btn-light border-secondary" (click)="open(content, null)">Distance Calculator</button>
    </span>
</span>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Distance Calculator</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!--<form #macontrol="ngForm">-->
        <div class="form-group">
            <p class="border-bottom border-light aliceblue">This window allows you to enter Feet/inches and returns the corresponding distance to Meters (tenth of Meters).</p>
        </div>
        <div class="d-flex justify-content-center">
            <label class="align-self-center">Please enter value and select the unit.</label>
        </div>
        <div class="form-inline">
            <div class="row text-center">
                <div class="col-12">
                    <p *ngIf="warning!=''" class="alert-danger">
                        {{warning}}
                    </p>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="input-group mb-3">
                                    <input type="text" [(ngModel)]="distance" class="form-control"
                                           (change)="unitChange()"
                                           id="convertorValue"
                                           name="DistanceCalculator" placeholder="Please enter value" />
                                    <div class="input-group-append">
                                        <select class="form-select"
                                                (change)="unitChange()"
                                                id="convertorOption" [(ngModel)]="distanceUnit">
                                            <option selected value="-1">Select a Unit</option>
                                            <option value="1">Inch</option>
                                            <option value="2">Foot</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" type="text" disabled [value]="result" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex">
        <p *ngIf="strForm !== 'Home'" class="mr-auto font-italic">Please click Submit to accept the value</p>
        <button *ngIf="strForm !== 'Home' && !hideSubmitButton" type="button" class="btn btn-primary" (click)="modal.close('Submit'); onSubmit()">Submit</button>
        <button type="button" class="btn btn-light border-secondary" (click)="modal.close('Cancel click')">Cancel</button>
        <button type="button" class="btn btn-light border-secondary" (click)="onClear()">Clear</button>
    </div>
</ng-template>

