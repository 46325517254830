<form novalidate #fvvvin="ngForm">
    <div id="vehicle-vin" class="pt-2" *ngIf="veh">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <!-- We are intentionally removing Model Year and VIN from form dirty/pristine state so that user does not get prompt about unsaved changes when decoding VIN. -->
                    <app-typeahead id="modelYr" name="modelYr" typeAheadPosition="right-top" [disabled]="disabledVinControl"                                    
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData"                                   
                                   [blnHideSelectedOptionText]="false"
                                   [objModel]="veh" strTableName="Veh" strFieldName="ModelYr"
                                   [printOnly]="printOnly"
                                   [blnShowSpecialValuesOnly]="true">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="pt-1 pb-1">
                        <div class="row">
                            <div [ngClass]="[!printOnly ? 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="pl-2">
                                    <app-label inputName="VIN" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col-xl' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="input-group pl-2">

                                    <app-typeahead id="VIN" name="VIN" #txtVIN typeAheadPosition="right-top"
                                                   disableLabel="true" typeAheadClass="form-control w200"
                                                   blnAllowFreeText="true"
                                                   blnHideSelectedOptionText="true"
                                                   (paste)="OnPasteVIN($event)"
                                                   [objModel]="veh" strTableName="Veh" strFieldName="VIN"
                                                   [maxlength]="17" [(objCurrentValue)]="veh.VIN"
                                                   (emitObjectOnChange)="onBeforeModelCange($event)"
                                                   [printOnly]="printOnly"
                                                   [clearFieldData]="clearFieldData"
                                                   [blnListenEmitter]="true"
                                                   (bindModel)="onVINModelChange($event)"
                                                   [blnFormatUpperCase]="true">
                                    </app-typeahead>

                                    <div class="input-group-append" *ngIf="!printOnly">
                                        <button class="btn btn-primary mr-2" (click)="decodeVin('vehicle')" [disabled]="blnReadOnly || blnDisableDecodeVIN"><span>Decode</span></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <!-- strDefsLookupTableName is specified explicitly because it is used as a lookup key to get a reference to this TypeaheadComponent -->
                    <app-typeahead id="make" name="make"
                                   typeAheadPosition="right-top"
                                   [disabled]="disabledVinControl"
                                   [objModel]="veh"
                                   strTableName="Veh"
                                   strFieldName="Make"
                                   strDefsLookupTableName="Make"
                                   strRefreshedLookupTableName="Model"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #txtNcsaModel id="model" name="model" typeAheadPosition="right-top"
                                   [disabled]="disabledVinControl"
                                   [objModel]="veh"
                                   strTableName="Veh"
                                   strFieldName="Model"
                                   strDependentFieldName="Make"
                                   strFilterCondition=" AND MAKE= "
                                   strRefreshedLookupTableName="Body"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   (bindModel)="FilterNcsaBodyType($event)"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #txtNcsaBodyType id="body"
                                   [disabled]="disabledVinControl"
                                   name="body"
                                   typeAheadPosition="right-top"
                                   searchAll="true"
                                   [objModel]="veh"
                                   strTableName="Veh"
                                   strFieldName="Body"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="bodyCat" name="bodyCat"
                                   typeAheadPosition="right-top" searchAll="true"
                                   [objModel]="veh.Veh_SS"
                                   strTableName="Veh_SS"
                                   strFieldName="VehBodyCat"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="bodyCat" name="VehicleClass" typeAheadPosition="right-top" searchAll="true"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS"
                                   strFieldName="VehicleClass"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>
