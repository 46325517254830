import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { GenericService } from './generic.service';
import { Veh } from '../models/veh';
import { Dri } from '../models/dri';
import { Occupants } from '../models/occupants';
import { PreCrash } from '../models/pre-crash';
import { DriRF } from '../models/dri-rf';
import { HttpClient } from '@angular/common/http';
import { SharedDataService, AppSettings } from './shared-data.service';
import { ModalService } from './modal.service';
import { ModalService_CannedPopups } from './modal-canned-popups.service';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { UtilService } from './util.service';
import { LookupTable } from '../models/enums/Generated/LookupTable';
import { Acc } from '../models/acc';
import { ObjectUtil } from '../helper/objectUtil';
import { RBISDataValue, Preference, MailRequestType, DBMode } from '../models/enums/app.enums';
import { vpicGVWRMapping } from '../models/vpicGVWRMapping';
import { Trailer } from '../models/trailer';
import { CaseService } from './case.service';
import { TrailerVPICDECODE } from '../models/vpic-trailer-decode';
import { VPICDECODE } from '../models/vpic-decode';
import { OOSRequest } from '../models/oos-request';
import { SecUserPerferenceService } from './secUserPerference.service';


@Injectable({
    providedIn: 'root'
})
export class VehicleService {
    public sbjModalOpenAfterSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    arrMonthExludeValues: Array<number> = [0, 99];
    arrYearExludeValues: Array<number> = [0, 9999];

    arrVpicGVWRMapping: vpicGVWRMapping[];
    intVpicGVWR: number = RBISDataValue.Blank;
    intConvertRBISGVWR: number = RBISDataValue.Blank;
    intVpicGVWRTo: number = RBISDataValue.Blank;
    intConvertRBISGVWRTo: number = RBISDataValue.Blank;

    @Output() vehicleSaved = new EventEmitter<Veh>();

    //public driverInfoFilledStatusInitial = new BehaviorSubject(false);
    //public driverInfoFilledStatusAfterSave = new BehaviorSubject(false);
    @Output() driverSaved = new EventEmitter<Acc>();

    public finalStageBodyClassVisibleAfterSave = new BehaviorSubject(false);
    public vinDecodedSubscription: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public static arrVinDecodeErrorList: Array<string> = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '11', '12', '14', '400'];
    public static arrVinDecodesCleanList: Array<string> = ['0', '10'];
    public static arrNotDecodedVin: Array<string> = ['00000000000000000', '88888888888888888', '99999999999999999'];
    public static arrNotDecodedYear: Array<string> = ['9999', '9998'];
    public static arrOnlyRBISGVWR: Array<number> = [-1, 99, 98, 77];

    constructor(private _baseService: GenericService<any>,
        private _http: HttpClient,
        private _sharedService: SharedDataService,
        private _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private intValTostrVal: ValueToDescriptionPipe,
        private _utilService: UtilService,
        private _caseService: CaseService,
        private _userPreferenceService: SecUserPerferenceService) {
    }

    private setErrorMessage(strMessage: string, strTabName: string) {
        this._modalService.setMessage(strTabName + " - " + strMessage, "error");
    }

    public ValidateOccObj(objOccupant: Occupants): boolean {
        let blnIsValid: boolean = true;
        //region : Race Validation - Ticket# 4802

        //When analyst enter code 201 and try to save the page, system should give an error message saying "Please make sure to select more than one race."
        //When analyst enter 219 or 268 or 298 alone.System should allow analyst to save the page without any error message.
        if (objOccupant.Occ_Race)
            objOccupant.Occ_Race.sort(ObjectUtil.sortSelectedItem('SeqNum'));

        if (!objOccupant.IsMultiRace)
            if (objOccupant.Occ_Race.some(i => i.ElementValue == null))
                objOccupant.Occ_Race.forEach(item => { item.ElementValue = RBISDataValue.Blank });

        if (objOccupant.IsMultiRace) {

            if (objOccupant.Occ_Race) {

                if (objOccupant.Occ_Race.length == 0) {
                    this.setErrorMessage("Please make sure to select more than one race.", "Person# " + objOccupant.PNumber + " - Race");
                    blnIsValid = false;
                }

                else if (objOccupant.Occ_Race.length == 1 && objOccupant.Occ_Race.filter(i => i.AllowOneMultiple == false).length > 0) {
                    this.setErrorMessage("Please make sure to select more than one race.", "Person# " + objOccupant.PNumber + " - Race");
                    blnIsValid = false;
                }
                else if (objOccupant.Occ_Race.length === 1 && Number(objOccupant.Occ_Race[0].SeqNum) !== 1) {
                    this.setErrorMessage("Please assign Order# sequentially", "Person# " + objOccupant.PNumber + " - Race");
                    blnIsValid = false;
                }
                else if (objOccupant.Occ_Race.length > 0) {

                    let blnNotSequential: boolean = false;
                    let count = Array<number>();
                    objOccupant.Occ_Race.forEach(function (i) { count[Number(i.SeqNum)] = ((count[Number(i.SeqNum)]) || 0) + 1; });

                    if (objOccupant.Occ_Race.filter(x => Number(x['SeqNum']) == 1).length != 1) {
                        this.setErrorMessage("Please assign Order# sequentially", "Person# " + objOccupant.PNumber + " - Race");
                        blnIsValid = false;
                    }
                    else if (objOccupant.Occ_Race.sort(ObjectUtil.sortSelectedItem('SeqNum')).some((obj, index) => {
                        if (index < objOccupant.Occ_Race.length - 1) {
                            if ((Number(objOccupant.Occ_Race[index + 1].SeqNum) - Number(obj.SeqNum)) > 1) {
                                blnNotSequential = true;
                            }
                        }

                        return blnNotSequential;
                    })) {
                        this.setErrorMessage("Please assign Order# sequentially", "Person# " + objOccupant.PNumber + " - Race");
                        blnIsValid = false;
                    }

                    else if (objOccupant.Occ_Race.filter(i => i.SeqNum === null || i.SeqNum === 0).length > 0) {
                        this.setErrorMessage("Please assign Order# for a selected code.", "Person# " + objOccupant.PNumber + " - Race");
                        blnIsValid = false;
                    }
                    else if (count.filter(i => i > 1).length > 0) {
                        this.setErrorMessage("Please assign different Order# for a selected codes.", "Person# " + objOccupant.PNumber + " - Race");
                        blnIsValid = false;
                    }


                }
            }
        }

        //end region

        return blnIsValid;
    }

    public ValidateCounterFirstLastDate(objDriver: Dri): boolean {

        let blnIsValid = true;
        let hasExludeValues: boolean = this.arrMonthExludeValues.includes(Number(objDriver.FAccMon)) && this.arrYearExludeValues.includes(Number(objDriver.FAccYr))
            && this.arrMonthExludeValues.includes(Number(objDriver.LAccMon)) && this.arrYearExludeValues.includes(Number(objDriver.LAccYr));

        //if (!hasExludeValues) {
        //let dt: Date = new Date();
        //TODO:Betul-It will be changed later.Quick fix.!!!!!
        //let intCurrentYear: number = objDriver.Veh.Acc.CaseYear;           
        //let intSumOfPrev: number = objDriver.PrevAcc + objDriver.PrevDwi + objDriver.PrevSpd + objDriver.PrevOth + (objDriver.DriPrevSus.reduce((x, y) => x + y.PrevSusRevoc, 0));

        let dtAccDate = new Date(objDriver.Veh.Acc.CaseYear, objDriver.Veh.Acc.AccMon - 1);
        let dtFirstCrash: Date = new Date(objDriver.FAccYr, objDriver.FAccMon - 1);
        let dtLastCrash: Date = new Date(objDriver.LAccYr, objDriver.LAccMon - 1);

        let diff_msFAcc = dtAccDate.getTime() - dtFirstCrash.getTime();
        let year_dtFAcc = new Date(diff_msFAcc);

        let differenceFAcc = Math.abs(year_dtFAcc.getFullYear() - 1970);


        let diff_msLAcc = dtAccDate.getTime() - dtLastCrash.getTime();
        let year_dtLAcc = new Date(diff_msLAcc);

        let differenceLAcc = Math.abs(year_dtLAcc.getFullYear() - 1970);



        //let blnValidFrstCrash: boolean = (intCurrentYear - dtFirstCrash.getFullYear() <= 5) && (objDriver.FAccYr != 9999);
        //let blnValidLastCrash: boolean = (intCurrentYear - dtLastCrash.getFullYear() <= 5) && (objDriver.LAccYr != 9999);

        //let accYear = objDriver.Veh.Acc.CaseYear;
        //let accmonth = objDriver.Veh.Acc.AccMon;




        //let differenceFAcc = accYear - objDriver.FAccYr;
        //let FAcc_month = accmonth - objDriver.FAccMon;

        //if (FAcc_month < 0) {
        //    differenceFAcc = differenceFAcc - 1;
        //}

        //let differenceLAcc = accYear - objDriver.LAccYr;
        //let LAcc_month = accmonth - objDriver.LAccMon;

        //if (LAcc_month < 0) {
        //    differenceLAcc = differenceLAcc - 1;
        //}

        if ((objDriver.LAccYr == -1 && objDriver.LAccMon != -1) ||
            (objDriver.LAccMon == -1 && objDriver.LAccYr != -1)  // checking if one of the pair MM/YYYY value is missing
            ||
            (objDriver.LAccYr == 0 && objDriver.LAccMon == 99) ||
            (objDriver.LAccMon == 0 && objDriver.LAccYr == 9999)  // checking if one of the pairs has 0's and other has 9's 
        ) {
            this.setErrorMessage("Either both Month and Year are blank or filled in. If both are filled in, it must be Month: (00-12,99) Year(0000, 9999 or within last 5 years before crash date)", "Most Recent Crash ");
            blnIsValid = false;
            return;
        }

        if ((objDriver.FAccYr == -1 && objDriver.FAccMon != -1) ||
            (objDriver.FAccMon == -1 && objDriver.FAccYr != -1)  // checking if one of the pair MM/YYYY value is missing
            ||
            (objDriver.FAccYr == 0 && objDriver.FAccMon == 99) ||
            (objDriver.FAccMon == 0 && objDriver.FAccYr == 9999) // checking if one of the pairs has 0's and other has 9's 

        ) {
            this.setErrorMessage("Either both Month and Year are blank or filled in. If both are filled in, it must be Month: (00-12,99) Year(0000, 9999 or within last 5 years before crash date)", "Oldest Crash ");
            blnIsValid = false;
            return;
        }

        if ((!this.arrMonthExludeValues.includes(objDriver.FAccMon) && (objDriver.FAccYr == 0))) {
            this.setErrorMessage("Please select a valid year", "Oldest Crash ");
            blnIsValid = false;
            return;
        }

        if ((!this.arrMonthExludeValues.includes(objDriver.LAccMon) && (objDriver.LAccYr == 0))) {
            this.setErrorMessage("Please select a valid year", "Most Recent Crash ");
            blnIsValid = false;
            return;
        }

        if ((objDriver.FAccMon == 0) && (!this.arrYearExludeValues.includes(objDriver.FAccYr))) {
            this.setErrorMessage("Please select a valid month", "Oldest Crash ");
            blnIsValid = false;
            return;
        }

        if ((objDriver.LAccMon == 0) && (!this.arrYearExludeValues.includes(objDriver.LAccYr))) {
            this.setErrorMessage("Please select a valid month", "Most Recent Crash ");
            blnIsValid = false;
            return;
        }

        if ((!this.arrYearExludeValues.includes(Number(objDriver.FAccYr)) && objDriver.FAccYr != -1) &&
            (!this.arrMonthExludeValues.includes(Number(objDriver.FAccMon)) && objDriver.FAccMon != -1) &&
            (differenceFAcc >= 5)) {
            this.setErrorMessage("Year must be within last 5 years before crash date", "Oldest Crash ");
            blnIsValid = false;
            return;
        }

        if ((!this.arrYearExludeValues.includes(Number(objDriver.LAccYr)) && objDriver.LAccYr != -1) &&
            (!this.arrMonthExludeValues.includes(Number(objDriver.LAccMon)) && objDriver.LAccMon != -1) &&
            (differenceLAcc >= 5)) {
            this.setErrorMessage("Year must be within last 5 years before crash date", "Most Recent Crash ");
            blnIsValid = false;
            return;
        }


        //else if ((intSumOfPrev != 1) && (blnValidFrstCrash && blnValidLastCrash) && (dtLastCrash.getTime() < dtFirstCrash.getTime())) {

        //    this.setErrorMessage("Date of Most Recent Crash, Suspension, or Conviction must be greater or equal than Date of Oldest Crash, Suspension or Conviction", "Driver -Counter ");
        //    blnIsValid = false;
        //}
        //}

        //if (hasExludeValues) {
        //    if ((objDriver.LAccYr == 0 && objDriver.LAccMon == 99) ||
        //        (objDriver.LAccMon == 0 && objDriver.LAccYr == 9999) ||
        //        (objDriver.FAccYr == 0 && objDriver.FAccMon == 99) ||
        //        (objDriver.FAccMon == 0 && objDriver.FAccYr == 9999)
        //    ) {
        //        this.setErrorMessage("Either both Month and Year are blank or filled in. If both are filled in, it must be Month: (00-12,99) Year(0000, 9999 or within last 5 years before crash date)", "Driver -Counter ");
        //        blnIsValid = false;
        //        return;
        //    }
        //}

        return blnIsValid;

    }

    public IsVehicleOOS(objAcc: Acc): void {
        //Ticket# 5971 - A pop up is displayed with a message "Out of State Message Request" with options to select "Vehicle", "Driver" and "Vehicle & Driver".
        let intCaseStateNum: number = objAcc.StateNum;

        //Check every vehicle to have out of state data

        objAcc.Veh.forEach((objVeh: Veh) => {
            this._utilService.formDrpDownOptionShare.subscribe(result => {
                let objState = result.filter(i => i.tblName === LookupTable.StateNum).find(i => i.intValue === intCaseStateNum);
                if (objState) {
                    return;
                }
            });

            if (objVeh.RegState === -1) {
                return;
            }
            if (objVeh.RegState === intCaseStateNum) {
                return;
            }
        })
    }

    public SaveVeh(objVeh: Veh, blnRunEditChecksSynchronously: boolean = false): Promise<Veh> {
        this._baseService.actionURL = "api/vehicle/SaveVehicle/" + blnRunEditChecksSynchronously.toString();
        let prmSave: Promise<Veh> = this._baseService.updateEntity(objVeh).toPromise();

        //Warning: using the observable pattern here is not appropriate: the internal subscription here and the 2nd subscription of the caller of this method will cause Http to issue the web twice, once for each subscriber.
        prmSave.then(((objWrappedVeh: Acc) => {
            if (!blnRunEditChecksSynchronously)
                this._caseService.RunChecksGetStats(objVeh.AccID, 'Veh');
            else if (objWrappedVeh.CaseStats)
                this._caseService.subEditCheckStats.next(objWrappedVeh.CaseStats);
        }).bind(this));

        return prmSave;
    }

    public SaveDriver(objDriver: Dri, blnRunEditChecksSynchronously: boolean = false): Promise<Dri> {
        //ObjectUtil.AttachDriRFToDriObject(objDriver.Veh, objDriver);
        //objDriver.DrivPres = objDriver.Veh.DrivPres;
        this._baseService.actionURL = "api/vehicle/SaveDriver/" + blnRunEditChecksSynchronously.toString();
        let prmSave: Promise<Dri> = this._baseService.updateEntity(objDriver).toPromise();

        //Warning: using the observable pattern here is not appropriate: the internal subscription here and the 2nd subscription of the caller of this method will cause Http to issue the web twice, once for each subscriber.
        prmSave.then(((objWrappedDriver: Acc) => {
            if (!blnRunEditChecksSynchronously)
                this._caseService.RunChecksGetStats(objDriver.AccID, 'Dri');
            else if (objWrappedDriver.CaseStats)
                this._caseService.subEditCheckStats.next(objWrappedDriver.CaseStats);
        }).bind(this));

        //objDriver.DriRF = null;
        //delete objDriver['DrivPres'];
        return prmSave;
    }

    public SavePreCrash(item: PreCrash, blnRunEditChecksSynchronously: boolean = false): Promise<PreCrash> {
        this._baseService.actionURL = "api/vehicle/SavePreCrash/" + blnRunEditChecksSynchronously.toString();
        let prmSave: Promise<PreCrash> = this._baseService.updateEntity(item).toPromise();

        //Warning: using the observable pattern here is not appropriate: the internal subscription here and the 2nd subscription of the caller of this method will cause Http to issue the web twice, once for each subscriber.
        prmSave.then(((objWrappedPreCrash: Acc) => {
            if (!blnRunEditChecksSynchronously)
                this._caseService.RunChecksGetStats(item.AccID, 'PreCrash');
            else if (objWrappedPreCrash.CaseStats)
                this._caseService.subEditCheckStats.next(objWrappedPreCrash.CaseStats);
        }).bind(this));

        return prmSave;
    }

    public SavePerson(objPerson: Occupants, blnRunEditChecksSynchronously: boolean = false): Promise<Occupants> {
        this._baseService.actionURL = "api/vehicle/SavePerson/" + blnRunEditChecksSynchronously.toString();

        //PII, if present at all, is attached to the Acc object. To save a Occupant with PII, we temporarily move
        //(not just add a second link) it from Acc to Occupants to send it up to the server.
        let intPiiIndex: number = objPerson.Veh.Acc.PII.findIndex(p => p.PNumber == objPerson.PNumber && p.VNumber == objPerson.VNumber);

        if (intPiiIndex != -1) {
            objPerson.PII = objPerson.Veh.Acc.PII[intPiiIndex];
            objPerson.PII.Acc = null;
            objPerson.Veh.Acc.PII.splice(intPiiIndex, 1);
        }

        let obsSave: Promise<Occupants> = this._baseService.updateEntity(objPerson).toPromise();

        if (intPiiIndex != -1) { //Now that the async save request is dispatched, restore the PII object to Acc
            //RestoreBidirectionalLinks() assumes that the nesting structure of the data model represents FK relationships,
            //it does not know that we moved the PII object from Acc.PII[] to Occupants.PII. We have to clean up the erroneous
            //link it created.
            if (objPerson.PII['Occupants'])
                objPerson.PII['Occupants'] = undefined;

            objPerson.Veh.Acc.PII.splice(intPiiIndex, 0, objPerson.PII);
            objPerson.Veh.Acc.PII[intPiiIndex].Acc = objPerson.Veh.Acc;
            objPerson.PII = null;
        }

        //Warning: using the observable pattern here is not appropriate: the internal subscription here and the 2nd subscription of the caller of this method will cause Http to issue the web twice, once for each subscriber.
        obsSave.then(((objWrappedOcc: Acc) => {
            if (!blnRunEditChecksSynchronously)
                this._caseService.RunChecksGetStats(objPerson.AccID, 'Occupants');
            else if (objWrappedOcc.CaseStats)
                this._caseService.subEditCheckStats.next(objWrappedOcc.CaseStats);
        }).bind(this));

        return obsSave;
    }

    public async VehicleVINDecoder(vinNumber: string, modelYear: number): Promise<any> {
        if (VehicleService.arrNotDecodedVin.includes(vinNumber)) {
            this._modalService.setMessage("No VIN Checking is performed if VIN is blank, all 9s or 0s or 8s.", "info");
            return;
        }

        if (VehicleService.arrNotDecodedYear.includes(modelYear.toString()))
            modelYear = null;
        let objAppSetting: AppSettings = await this._sharedService.GetAppSettings();
        let baseURL: string = objAppSetting.vpicDecoderUrl + vinNumber.trim() + "?format=json";

        if (modelYear) {
            let regexExpYear: string = '[0-9]{4}';
            let isValidYear: boolean = new RegExp(regexExpYear).test(modelYear.toString());
            if (isValidYear) {
                baseURL += "&modelyear=" + modelYear;
            }
        }

        return this._http.get<any>(baseURL).toPromise();
    }

    public vehicleSavedNotify(vehicle) {
        this.vehicleSaved.emit(vehicle);
    }

    /**
     * Final Stage Body Class is applicable only to "incomplete" vehicles (Body Type has "incomplete" keyword in it)
     */
    public async visibilityFinalStageBodyClass(vehArr): Promise<Array<{ VNumber: number, isVisibleFSBC: boolean }>> {
        let fsbcVisibilityArr = new Array<{ VNumber: number, isVisibleFSBC: boolean }>();
        let isVisibleFinalStageBodyClass = false;

        for (let i = 0; i < vehArr.length; i++) {
            if (vehArr[i].vPICBodyClass > -1) {
                let finalStageValue = await this.intValTostrVal.transform(vehArr[i].vPICBodyClass, 'vPICBodyClass');
                isVisibleFinalStageBodyClass = (finalStageValue) && (finalStageValue.toLowerCase().includes('incomplete')) ? true : false;
                fsbcVisibilityArr.push({ VNumber: vehArr[i].VNumber, isVisibleFSBC: isVisibleFinalStageBodyClass });
            }
        }

        return fsbcVisibilityArr;
    }

    public setFinalStageBodyClassVisibleAfterSave(filledStatus: boolean) {
        this.finalStageBodyClassVisibleAfterSave.next(filledStatus);
    }

    public vehicleInfoFilledCheck(vehs, fsbcVisibilityArr) {
        let vehInfoFilled = true;
        vehs.forEach(function (veh) {
            if (veh.Body < 0 ||
                veh.Make < 0 ||
                veh.Model < 0 ||
                veh.ModelYr < 0 ||
                veh.RegOwner < 0 ||
                veh.RegState < 0 ||
                veh.UnitType < 0
            ) { vehInfoFilled = false; }

            fsbcVisibilityArr.forEach((fsbcVisibility) => {
                if (veh.VNumber == fsbcVisibility.VNumber) {
                    if (fsbcVisibility.isVisibleFSBC) {
                        if (veh.FinalStageBodyClass < 0) {
                            vehInfoFilled = false;
                        }
                    }
                }
            });
        });

        return vehInfoFilled;
    }

    public driverSavedNotify(objAcc: Acc) {
        this.driverSaved.emit(objAcc);
    }

    public driverInfoFilledCheck(accObj: Acc): boolean {

        let driverInfoFilled = true;

        if (accObj.Veh) {
            accObj.Veh.forEach(function (veh) {
                if (veh.Dri && veh.Occupants.length > 0) {// veh.Occupants.length added to check if vehicle Driverless;
                    if (veh.Dri.CommLic < 0 || veh.Dri.LicComp < 0 || veh.Dri.LicEndor < 0 || veh.Dri.LicRestrict < 0 ||
                        veh.Dri.LicState < 0 || veh.Dri.NonCDLType < 0 || veh.Dri.PrevAcc < 0 || veh.Dri.PrevDwi < 0 ||
                        veh.Dri.PrevOth < 0 || veh.Dri.PrevSpd < 0 || veh.Dri.SpdRel < 0) {
                        driverInfoFilled = false;
                    }
                    if (veh.Dri.DriViol) {
                        veh.Dri.DriViol.forEach((driViol) => {
                            if (driViol.Viol < 0) { driverInfoFilled = false; }
                        })
                    }
                }
            });
        }

        return driverInfoFilled;
    }

    public UpdateTrailerVpicDecode(objTrailer: Trailer): Promise<TrailerVPICDECODE> {
        this._baseService.actionURL = "api/vehicle/UpdateTrailerVPICDecode/";
        let prmSave: Promise<TrailerVPICDECODE> = this._baseService.updateEntity(objTrailer).toPromise();
        return prmSave;
    }

    public UpdateVehicleVpicDecode(objVeh: Veh): Promise<VPICDECODE> {
        this._baseService.actionURL = "api/vehicle/UpdateVehicleVPICDecode/";
        let prmSave: Promise<VPICDECODE> = this._baseService.updateEntity(objVeh).toPromise();
        return prmSave;
    }
}
