import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-genericmessage',
    templateUrl: './genericmessage.component.html',
    styleUrls: ['./genericmessage.component.css']
})
export class GenericmessageComponent implements OnInit {

    public messageItem: any //Release build (--prod build) requires this member to be public as it's being accessed from HTML template
    messageType: 'T'

    constructor(private messageService: ModalService) {
        this.messageService.getCurrentMessage.subscribe((y => {
            this.messageItem = y;
        }).bind(this));
    }

    reset() {
        this.messageService.setMessage('', '');
        // this.messageService.resetMessage();
    }

    ngOnInit() {
    }

}
