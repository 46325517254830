<form novalidate #fnodrug="ngForm">
    <div class="pt-2" *ngIf="nonOccupant">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="NonOccupantDrugInv" name="DrugInv" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="DrugInv"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <!-- ID should be unique in entire HTML document. If this component is instantiated multiple types, then expose an input property for the ID and let parent pass in a unique ID suffix and append it to Typeahead ID-->
                    <app-typeahead id="NonOccupantMethDrug" name="methDrug" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="MethDrug"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="NonOccupantDrugSts" name="drugSts" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="DrugSts" (bindModel)="OnModelChange($event)"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                <div [ngClass]="{'border-top border-dark' : printOnly !== true}">
                    <div class="d-flex justify-content-between bg-light">
                        <div class="my-auto pl-2">
                            <div [ngClass]="{'col-form-label fw-bold' : printOnly !== true}"><span>Drug Toxicology Results</span></div>
                        </div>
                        <div class="pr-2" *ngIf="!printOnly">
                            <div class="input-group pt-2 pb-2">
                                <div class="my-auto">
                                    <span class="pr-1">Add</span>
                                </div>
                                <span class="w62">
                                    <input type="number" id="inputNonOccupant_onAddResults" name="addResult" [(ngModel)]="addResult" class="form-control form-control-sm for-focus" #addResults (keyup.enter)="onAddResults(addResults.value)" numeric numericType="number" [disabled]="blnDisabled || blnReadOnly">
                                </span>
                                <div class="my-auto">
                                    <span class="px-1">Row(s)</span>
                                </div>
                                <div class="">
                                    <button id="btnNonOccupant_onAddResults" class="btn btn-sm btn-light border-secondary" type="button" (click)="onAddResults(addResults.value)" [disabled]="blnDisabled || blnReadOnly">Go</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>

                        <div class="row mt-2">
                            <div class="col-4">
                                <div class="pl-2 my-auto">
                                    <app-label inputName="DrugTst" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="my-auto">
                                    <app-label inputName="DrugRes" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div class="col my-auto" *ngIf="!printOnly">
                                <div class="d-flex justify-content-center">
                                    <div class="fw-bold">Action</div>
                                </div>
                            </div>
                        </div>

                        <div [ngClass]="{'px-2 py-1' : printOnly !== true}" *ngFor="let item of nonOccupant.NonOccDrug; let i = index;">
                            <div class="row">

                                <div class="col-4 my-auto">
                                    <app-typeahead [id]="'DrugTst_' + i" typeAheadPosition="right-top" [disabled]="blnDisabled" (bindModel)="OnNonOccDrugModelChange(item.SeqNum, 'DrugTst')"
                                                   [objModel]="item" strTableName="NonOccDrug" strFieldName="DrugTst"
                                                   [intSeqNum]="item.SeqNum" maxlength="2"
                                                   disableLabel="true"
                                                   [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData">
                                    </app-typeahead>
                                </div>

                                <div class="col-5 my-auto" [ngClass]="{'py-1' : printOnly !== true}">
                                    <app-typeahead [id]="'DrugRes_' + i" typeAheadPosition="right-top" [disabled]="blnDisabled"
                                                   [objModel]="item" strTableName="NonOccDrug" strFieldName="DrugRes" (bindModel)="OnNonOccDrugModelChange(item.SeqNum, 'DrugRes')"
                                                   [intSeqNum]="item.SeqNum" maxlength="3"
                                                   disableLabel="true"
                                                   [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData">
                                    </app-typeahead>
                                </div>

                                <div class="col my-auto" *ngIf="!printOnly">
                                    <div class="d-flex justify-content-center">
                                        <span class="pl-2">
                                            <button type="button" id="inputNonOccupant_onDeleteRow_{{item.SeqNum}}" class="btn btn-sm btn-light border-secondary my-auto" (click)="onDeleteRow(item.SeqNum)" [disabled]="blnDisabled || blnReadOnly"><span class="fw-bold">Delete</span></button>
                                        </span>
                                        <span class="pl-2">
                                            <button type="button" id="inputNonOccupant_onAddRow_{{item.SeqNum}}" class="btn btn-sm btn-light border-secondary my-auto" (click)="onAddRow(i + 1)" [disabled]="blnDisabled || blnReadOnly"><span class="fw-bold">Add</span></button>
                                        </span>
                                        <span class="pl-2">
                                            <button type="button" id="inputNonOccupant_onClearRow_{{item.SeqNum}}" class="btn btn-sm btn-light border-secondary my-auto" (click)="onClearRow(i)" [disabled]="blnDisabled || blnReadOnly"><span class="fw-bold">Clear</span></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</form>

