import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { Veh } from 'src/app/models/veh';
import { Acc } from 'src/app/models/acc';
import { Subscription } from 'rxjs';
import { VehType, UnitType, RBISDataValue } from 'src/app/models/enums/app.enums';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { CrashEvents } from 'src/app/models/crash-events';
import { CrashEventsList } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-vehicle/vehicle-vehicle-vehicle.component';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { GetSecUserPreferencesResult } from 'src/app/models/GetSecUserPreferencesResult';
import { NgForm } from '@angular/forms';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { VehRF } from 'src/app/models/veh-rf';
import { Element_Specify } from 'src/app/models/element-specify';

@Component({
  selector: 'app-striking-vehicle-vehicle-vehicle',
  templateUrl: './striking-vehicle-vehicle-vehicle.component.html',
  styleUrls: ['./striking-vehicle-vehicle-vehicle.component.css']
})
export class StrikingVehicleVehicleVehicleComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    public veh: Veh;
    acc: Acc;
    accid: number;
    vehicleid: number;
    private sbsVehicleId: Subscription;
    public VehClone: Veh;

    crashEvent: CrashEvents;
    crashEvents: CrashEvents[];

    crashEventForList: CrashEventsList;
    arrCrashEventsForList: CrashEventsList[];

    inputElements: NodeListOf<any>;
    fieldMetaData: TableFieldElements[];

    objMetaData: TableFieldElements;
    dropDownOption: DrpDownOptions[];

    dropDownOptionHarmfulEvents: DrpDownOptions[];
    arrIntDropDownHarmEvent: number[];

    public secUserPerferenceResult: GetSecUserPreferencesResult;
    public strTypeAheadClass: string;

    subsriptionSeqEvent = Subscription;
    blnOOSPreference: boolean = false;
    @ViewChild('fstrvv') objForm: NgForm;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    sbsClearForm: Subscription;

    public objVehRFTemplate: VehRF;

    objInstanceElementSpecify: Element_Specify;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        public _autofillService: AutofillService,
        private _secUserPerferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper

    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.accid = this.acc.AccID;
        // console.log(this.acc);
        if (this.sbsVehicleId) {
            this.sbsVehicleId.unsubscribe();
        }
        else {
            this.vehicleid = this._sharedDataService.getVehicleId();

            // this.sbsVehicleId = this.vehicleid;
            //    let vehicleids = this._route.snapshot.paramMap.get('vehicleid');
            //    console.log(this._route.parent.parent.params);
            //    console.log(vehicleids);
            this.formLoad();
        }
    }

    formLoad() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.StrikingVehicle);
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);

        console.log(this.veh);
        this.VehClone = ObjectUtil.CloneModelObject(this.veh);
        this.objVehRFTemplate = ObjectUtil.InstantiateVehRF(this.veh);

        this._utilService.formDrpDownOptionShare.subscribe(async result => {

            this.dropDownOption = await result.filter(i => i.tblName == LookupTable.SeqEvent);

            console.log("this.dropDownOption", this.dropDownOption);
            this.dropDownOptionHarmfulEvents = this.dropDownOption.filter(x => x.HARMFUL === true);
            this.crashEvents = this.acc.CrashEvents.filter(v => v.AccID == this.accid && (v.VNumber1 == this.vehicleid || v.VNumber2 == this.vehicleid));
            this.arrIntDropDownHarmEvent = [];
            this.dropDownOptionHarmfulEvents.forEach(item => {
                if (item.HARMFUL === true) {
                    this.arrIntDropDownHarmEvent.push(item.intValue);
                }
            });

            this.arrCrashEventsForList = [];

            if (this.crashEvents && (this.veh.UnitType == UnitType.Blank || this.veh.UnitType == UnitType.InTransport)) {
                this.crashEvents.forEach(item => {
                    if (item.SOE != RBISDataValue.Blank) {
                        let option = this.dropDownOption.find(x => x.intValue == item.SOE);
                        if (option) {
                            let itemFOrList = {} as CrashEventsList;
                            itemFOrList.intValue = option.intValue;
                            itemFOrList.strValue = option.strValue;
                            itemFOrList.desc = option.displayText;
                            this.addCrashEventTheList(itemFOrList);
                        }
                    }
                });
            }

            this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe((() => {
                const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment;
                if (UrlTreeHelper.FindAndSetCurrentTab(urlSegments) === "vehicle") {
                    if (this.acc) {
                        this.veh.UnitType = -1;
                        let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == "UnitType") as TypeaheadComponent;
                        let item: DrpDownOptions = uiControl.options.find(x => x.intValue == this.veh.UnitType);
                        uiControl.bindItemAndFocusNextElement(item);
                    }
                }

            }).bind(this));
        });

    }
    addCrashEventTheList(itemFOrList: CrashEventsList): any {
        this.arrCrashEventsForList.push(itemFOrList);
    }

    setTravelSpeed($event) {
        let result = $event;

        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "TravSpd") as TypeaheadComponent;
        if (result > 151) result = '997';        
        typeahead.setCurrentValue(result);

        this.MarkFormAsDirty();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        //if (this.sbsClearForm) {
        //    this.sbsClearForm.unsubscribe();
        //}
    }

    public async onBeforeSave() {

        this.blnAlloweSave = true;
    }

}
