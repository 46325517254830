import { Injectable, isDevMode } from '@angular/core';

import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { AppSettings } from './shared-data.service';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { GetColumnDefaults_Result } from 'src/app/models/GetColumnDefaults_Result';
import { Rule } from 'src/app/models/Rule';
import { RuleAction } from 'src/app/models/RuleAction';
import { RuleCondition } from 'src/app/models/RuleCondition';
import { RuleConditionGroup } from 'src/app/models/RuleConditionGroup';

import { EarlyNotify } from 'src/app/models/early-notify';
import { Acc } from 'src/app/models/acc';
import { AccTrafID } from 'src/app/models/acc-traf-id';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { NonOcc_Race } from 'src/app/models/non-occ-race';
import { Veh } from 'src/app/models/veh';
import { Dri } from 'src/app/models/dri';
import { PreCrash } from 'src/app/models/pre-crash';
import { Occupants } from 'src/app/models/occupants';
import { Occ_Race } from 'src/app/models/occ-race';
import { OccupantType, UnitType, DriverPresence, AutoFillOperator, ColumnDataType, PropertyType, BodyClass_VPIC, CreatedRecord_Occupant_Type, HitAndRun, PersonRace, RBISDataValue, NumberOfOccupants, PersonType, PersonRelatedFactors, EjectionPath, AlcoholTestResult, DrugTestSubstance, DrugTestResult, Preference, DBMode } from '../models/enums/app.enums';

import { UIElementBase } from 'src/app/helper/UIElementBase';
import { ArgumentOutOfRangeError, BehaviorSubject, Observable, from, of, timer } from 'rxjs';
import { MultiselectComponent } from '../components/multiselect/multiselect.component';
import { Cases_Locked } from '../models/cases-locked';
import { RbisUser } from '../models/rbis-user';
import { DamagedAreas } from '../models/damaged-areas';
import { PreCrash_ContribCirc } from '../models/pre-crash-contrib-circ';
import { OccDrug } from 'src/app/models/occ-drug';
import { NonOcc_Bike } from '../models/non-occ-bike';
import { NonOcc_Ped } from '../models/non-occ-ped';
import { NonOccDrug } from '../models/non-occ-drug';
import { PII } from '../models/pii';
import { Element_Specify } from '../models/element-specify';
import { CrashEvents } from '../models/crash-events';
import { RBISWizardResults } from '../models/RBISWizardResults';
import { CaseReview } from '../models/case-review';
import { CaseFile } from '../models/case-file';
import { AlcoholCalculatorDetails } from '../models/alcohol-calculator-details';
import { Acc_AtmCond } from '../models/acc-atm-cond';
import { AccRF } from '../models/acc-rf';
import { PopupNotification } from '../models/popupnotification';
import { OrideCase } from '../models/oride-case';
import { DrpDownOptions } from '../models/drp-down-options';
import { Trailer } from '../models/trailer';
import { OccRF } from '../models/occ-rf';
import { NonOcc_Safety } from '../models/non-occ-safety';
import { DriPrevSus } from '../models/dri-prev-sus';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageBoxComponent } from '../components/message-box/message-box.component';
import { catchError } from 'rxjs/operators';
import { LookupTable } from '../models/enums/Generated/LookupTable';
import { CaseService } from './case.service';
import { environment } from 'src/environments/environment';
import { CacheLocalStorageService } from './cache-local-storage.service';
import { HttpLocalStorageService } from './http-local-storage.service';
import { PreCrash_MTSS } from '../models/pre-crash-mtss';
import { NonOcc_Other } from '../models/non-occ-other';
import { NonOcc_Injury } from '../models/non-occ-Injury';
import { Acc_SS } from '../models/acc-ss';



enum RuleOperandType {
    Constant = 1,
    Context = 2
}

@Injectable({
    providedIn: 'root',
})
export class AutofillService {
    /**
     * The set of vehicle body types for which occupant "Created Records" should NOT be created.
     **/
    public static readonly arrOccupantCrExclusionBodyTypes = [
        BodyClass_VPIC.Blank,
        BodyClass_VPIC.Bus,
        BodyClass_VPIC.SchoolBus,
        BodyClass_VPIC.NotApplicable,
        BodyClass_VPIC.NotReported,
        BodyClass_VPIC.Otherspecify,
        BodyClass_VPIC.Unknown
    ];
    /**
     * The set of vehicle body types for which occupant "Created Records" should NOT be created.
     **/
    public static arrOccupantCrIncompleteBodyTypes = [];

    /**
     * Output verbose debugging information to the console
     */
    private static _blnVerbose: boolean = true;
    private static _arrAutofills: Rule[];
    private static _arrAutofillConditions: RuleCondition[];
    private static _arrColumnDefaults: GetColumnDefaults_Result[];
    private static _arrControls: UIElementBase[];
    private static _base: GenericService<any>;
    private _objUser: RbisUser;
    private _objAppSettings: AppSettings;

    public subInitialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public get arrControls(): UIElementBase[] {
        return (new Array<UIElementBase>()).concat(AutofillService._arrControls); //Using concat() as a way to clone the array
    }
    public static get arrControls(): UIElementBase[] {
        return (new Array<UIElementBase>()).concat(AutofillService._arrControls); //Using concat() as a way to clone the array
    }


    constructor(
        private _authService: AuthService,
        private _baseService: GenericService<any>,
        private ngModal: NgbModal,
        private _userPreferenceService: SecUserPerferenceService,
        private _cacheLocal: CacheLocalStorageService,
        private _httpLocal: HttpLocalStorageService,
    ) {
        //Initialize client-side autofill rule cache
        this.GetAutofillRules().then(x => {
            if (AutofillService._arrAutofills && AutofillService._arrColumnDefaults && this._objUser)
                this.subInitialized.next(true);
        });

        this.GetColumnDefaults().then(x => {
            if (AutofillService._arrAutofills && AutofillService._arrColumnDefaults && this._objUser)
                this.subInitialized.next(true);
        });
        AutofillService._base = _baseService;
        AutofillService._arrControls = new Array<UIElementBase>();

        this._authService.GetUserPromise().then((x => {
            this._objUser = x;

            if (AutofillService._arrAutofills && AutofillService._arrColumnDefaults && this._objUser)
                this.subInitialized.next(true);
        }).bind(this));

        //Moh: 5-4-2020 Testing local storage caching
        const cacheData = this.GetLocalStorageData(LookupTable.vPICBodyClass) //Check local storage data
        if (cacheData !== null) {
            cacheData.forEach((objBodyType: DrpDownOptions) => {
                if (objBodyType.strText.toLowerCase().indexOf('incomplete') != -1)
                    AutofillService.arrOccupantCrIncompleteBodyTypes.push(objBodyType.intValue);
            });
        }
        else {
            this._baseService.GetDrpDownListOptions(LookupTable.vPICBodyClass, '').toPromise().then(
                (arrBodyTypes: DrpDownOptions[]) => {
                    this._httpLocal.postLocalStorage({ url: LookupTable.vPICBodyClass, body: arrBodyTypes, cacheMins: 10 }); //Save in local storage, expire in 10 minutes
                    if (arrBodyTypes) {
                        arrBodyTypes.forEach((objBodyType: DrpDownOptions) => {
                            if (objBodyType.strText.toLowerCase().indexOf('incomplete') != -1)
                                AutofillService.arrOccupantCrIncompleteBodyTypes.push(objBodyType.intValue);
                        });
                    }
                });
        }

        document.addEventListener('paste', ((objEvent: ClipboardEvent) => {
            if (objEvent.target) {
                let objTarget: UIElementBase = this.arrControls.find(x => x.objTextbox.nativeElement == objEvent.target);

                if (objTarget != null) {
                    let strPastedText: string = objEvent.clipboardData.getData('Text');

                    if (objTarget.GetDbDataType != PropertyType.String && (strPastedText.startsWith(' ') || strPastedText.endsWith(' '))) {
                        objEvent.stopPropagation();
                        objEvent.preventDefault();
                        objTarget.setCurrentValue(strPastedText.trim()); //TODO: Do we want to let paste continue and only trim leading/training spaces from final value instead of assuming that paste is a replacement instead of an append?
                    }
                }
            }
        }).bind(this));
    }

    /**
     * Shows a modal dialog box. Refer to NgbModalOptions
     * @param boxType: C = Confirm | M = Message | A = Alert | W = Warning | YesNoCancel
     * @param size: sm | lg
     * @param centered: true | false
     * @param scrollable: true | false
     **/
    private dialog(body: string = 'Are you sure?', title: string = 'Friendly Message',
        boxType: string = 'C', size: 'sm' | 'lg' = 'sm',
        centered: boolean = true, scrollable: boolean = false,
        strYesText = 'OK', strNoText = 'Cancel', strDismissText = 'Dismiss', blnMergeSuccessAndError: boolean = true): Observable<boolean> {

        const modal = this.ngModal.open(MessageBoxComponent, {
            //Refer to NgbModalOptions
            backdrop: 'static',
            size: 'popup' as 'lg',
            centered: centered
        });

        modal.componentInstance.body = body;
        modal.componentInstance.title = title;
        modal.componentInstance.boxType = boxType;
        modal.componentInstance.module = 'D';
        modal.componentInstance.strYesText = strYesText;
        modal.componentInstance.strNoText = strNoText;
        modal.componentInstance.strDismissText = strDismissText;

        if (blnMergeSuccessAndError)
            return from(modal.result).pipe(
                catchError(error => {
                    console.warn(error);
                    return of(undefined);
                })
            );
        else
            return from(modal.result);
    }

    private async GetAppSettings(): Promise<AppSettings> {
        if (document.cookie.split(';').find(x => x.startsWith('.AspNetCore.Cookies') || x.startsWith(' .AspNetCore.Cookies'))) // Else do nothing as application is about to redirect to MAX.GOV
        {
            if (!this._objAppSettings) {
                try {
                    this._baseService.actionURL = "api/util/GetAppSettings";
                    this._objAppSettings = await this._baseService.getDataPromise();
                }
                catch (objError) {
                    //this._modalService.setMessage('Failed to get app settings: ' + objError.message, 'error');
                    console.warn('Failed to get app settings.', objError);
                }
            }

            return this._objAppSettings;
        }
        else {
            console.log('AutofillService.GetAppSettings(): Retrieval of application settings bypassed. User is not authenticated. Redirect to MAX.GOV imminent.');
            return null;
        }
    }

    //Moh test: 04-08-2020, 04-15-2020, 04-23-2020, 05-12-2020
    public async GetCachingIndex() {
        let objAppSettings: AppSettings = await this.GetAppSettings();
        try {
            this._baseService.actionURL = "api/Caching/GetCachingIndex/" + objAppSettings.intMode + '/' + objAppSettings.intYear;

            await this._baseService.getListPromise();
        }
        catch (objError) {
            console.error('Failed to run Caching Index: ' + objError.message);
        }
    }

    public GetLocalStorageData(storagekey: string) {
        return this._cacheLocal.isExpire(storagekey, new Date().getTime())
    }
       
    public async GetAutofillRules(): Promise<Rule[]> {
        let objAppSettings: AppSettings = await this.GetAppSettings();

        if (!AutofillService._arrAutofills) {
            try {
                this._baseService.actionURL = "api/Autofill/GetAutofills/" + objAppSettings.intMode + '/' + objAppSettings.intYear;
                AutofillService._arrAutofills = await this._baseService.getListPromise();
                AutofillService._arrAutofillConditions = new Array<RuleCondition>();

                for (let i = 0; i < AutofillService._arrAutofills.length; i++) {
                    ObjectUtil.RestoreBidirectionalLinks(AutofillService._arrAutofills[i]);
                    AutofillService.TraverseRuleConditionGroup(AutofillService._arrAutofills[i], AutofillService._arrAutofills[i].RuleConditionGroup);
                }
            }
            catch (objError) {
                console.error('Failed to get Auto Fill rules: ' + objError.message);
            }
        }

        return AutofillService._arrAutofills;
    }

    public async GetColumnDefaults(): Promise<GetColumnDefaults_Result[]> {
        this._baseService.actionURL = "api/Autofill/GetColumnDefaults";

        if (!AutofillService._arrColumnDefaults) {
            try {
                AutofillService._arrColumnDefaults = await this._baseService.getListPromise();
            }
            catch (objError) {
                console.error('Failed to get column default values: ' + objError.message, 'error');
            }
        }

        return AutofillService._arrColumnDefaults;
    }

    public AddAcc(objEarlyNotify: EarlyNotify): Acc {
        let objAcc = {} as Acc;
        objAcc._TypeScript_TypeGuard_Acc = null;
        objAcc.AccID = 0;
        AutofillService.SetDefaultValues(objAcc);
        objAcc.EarlyNotify = new Array<EarlyNotify>();
        objAcc.Non_Occupants = new Array<Non_Occupants>();
        objAcc.Element_Specify = new Array<Element_Specify>();
        objAcc.PII = new Array<PII>();
        objAcc.CrashEvents = new Array<CrashEvents>();
        objAcc.RBISWizardResults = new Array<RBISWizardResults>();
        objAcc.CaseReview = new Array<CaseReview>();
        objAcc.CaseFile = new Array<CaseFile>();
        objAcc.AlcoholCalculatorDetails = new Array<AlcoholCalculatorDetails>();
        objAcc.Acc_AtmCond = new Array<Acc_AtmCond>();
        objAcc.AccRF = new Array<AccRF>();
        objAcc.AccTrafID = new Array<AccTrafID>();
        let objTrafID: AccTrafID;
        for (let i = 0; i < 2; i++) { //Two trafficway Identifier rows should be auto-inserted.
            objTrafID = AutofillService.AddModelObject(objAcc, ObjectUtil.GetTypeName(objAcc), 'AccTrafID');
            objTrafID.SeqNum = i + 1;
        }
        objAcc.Veh = new Array<Veh>();
        objAcc.PopupNotification = new Array<PopupNotification>();
        objAcc.OrideCase = new Array<OrideCase>();

        objAcc.Acc_SS = AutofillService.AddModelObject(objAcc,'Acc', 'Acc_SS');
      
        objAcc.CaseStats = AutofillService.AddModelObject(objAcc, 'Acc', 'CaseStats');

        objAcc.EarlyNotify.push(objEarlyNotify);
        objAcc.ENID = objEarlyNotify.ENID;
        objAcc.AccDay = objEarlyNotify.AccDay;
        objAcc.AccHr = objEarlyNotify.AccHr;
        objAcc.AccMin = objEarlyNotify.AccMin;
        objAcc.AccMon = objEarlyNotify.AccMonth;
        objAcc.CaseYear = objEarlyNotify.CaseYear;
        objAcc.Mode = objEarlyNotify.Mode;
        objAcc.StudyID = objEarlyNotify.Mode;
        objAcc.StateNum = objEarlyNotify.StateNum;
        //objAcc.StateCaseID = objEarlyNotify.StateCaseNum; //DO NOT write Acc.StateCaseID, this field is reserved exclusively for the EDT process
        objAcc.Casenum = 0; //Tells case structure screen that this is a new case

        objAcc.Cases_Locked = {} as Cases_Locked;
        objAcc.Cases_Locked._TypeScript_TypeGuard_Cases_Locked = null;
        objAcc.Cases_Locked.Acc = objAcc;
        objAcc.Cases_Locked.AccID = objAcc.AccID;
        objAcc.Cases_Locked.Locked = true;
        objAcc.Cases_Locked.LockedDate = new Date();
        objAcc.Cases_Locked.UserID = this._objUser.UserId;

        this.AddVehicle(objAcc); //Modifies objAcc.Veh
        objEarlyNotify.Acc = objAcc;
        return objAcc;
    }

    public AddTrafficWayIdentifier(objAcc: Acc, seqNum: number): AccTrafID {
        if (!objAcc.AccTrafID) {
            objAcc.AccTrafID = new Array<AccTrafID>();
        }

        let objTrafficWay: AccTrafID = {} as AccTrafID
        objTrafficWay._TypeScript_TypeGuard_AccTrafID = null;

        objTrafficWay.AccID = objAcc.AccID;
        objTrafficWay.SeqNum = seqNum;
        objTrafficWay.TrafID = '';
        objTrafficWay.Acc = objAcc;

        objAcc.AccTrafID.push(objTrafficWay);

        return objTrafficWay;
    }

    public AddVehicle(objAcc: Acc, intUnitType: number = -1, intDriverPresent: number = -1, intSSVnumber: number = -1, strVehType:string = ''): Veh {
        let objVeh: Veh = {} as Veh;

        if (!objAcc.Veh)
            objAcc.Veh = new Array<Veh>();

        objVeh._TypeScript_TypeGuard_Veh = null;
        AutofillService.SetDefaultValues(objVeh);

        if (objAcc.Mode == DBMode.MTSS || objAcc.Mode == DBMode.PPSS) {
            objVeh.UnitType = UnitType.InTransport;
            AutofillService.AddModelObject(objVeh, ObjectUtil.GetTypeName(objVeh), 'Veh_MTSS');

            objVeh.Veh_MTSS.AccID = objAcc.AccID;
            objVeh.Veh_MTSS.VNumber = objAcc.Veh.length + 1;
        }
        if (objAcc.Mode == DBMode.PPSS || objAcc.Mode == DBMode.MOSS) {
            console.log(ObjectUtil.GetTypeName(objVeh), objVeh);
            AutofillService.AddModelObject(objVeh, ObjectUtil.GetTypeName(objVeh), 'Veh_SS');
        }

        objVeh.AccID = objAcc.AccID;
        objVeh.VNumber = objAcc.Veh.length + 1;
        objVeh.Occupants = new Array<Occupants>();
        objVeh.UnitType = intUnitType;        
        objVeh.Acc = objAcc

        if (objAcc.Mode == DBMode.MOSS) {
            if (intSSVnumber == -1) intSSVnumber = 1;  //use for create new Case

            objVeh.Veh_SS.SSVNumber = intSSVnumber; 
            objVeh.Veh_SS.AccID = objAcc.AccID; //use for Restructure case
            objVeh.Veh_SS.VNumber = objVeh.VNumber; //use for Restructure case
            objVeh.VehType = strVehType; //use for Restructure case            
        }

        if (intDriverPresent == DriverPresence.DriverPresent) {
            objVeh.Dri = {} as Dri;
            objVeh.Dri._TypeScript_TypeGuard_Dri = null;
            AutofillService.SetDefaultValues(objVeh.Dri);
            objVeh.Dri.AccID = objVeh.AccID;
            objVeh.Dri.VNumber = objVeh.VNumber;
            objVeh.Dri.Veh = objVeh;
        }

        if (objVeh.UnitType == UnitType.InTransport) {
            objVeh.PreCrash = {} as PreCrash;
            objVeh.PreCrash._TypeScript_TypeGuard_PreCrash = null;
            AutofillService.SetDefaultValues(objVeh.PreCrash);
            objVeh.PreCrash.AccID = objVeh.AccID;
            objVeh.PreCrash.VNumber = objVeh.VNumber;
            objVeh.PreCrash.Veh = objVeh;
            if (objAcc.Mode == DBMode.MTSS || objAcc.Mode == DBMode.PPSS ) {
                AutofillService.AddModelObject(objVeh, ObjectUtil.GetTypeName(objVeh), 'PreCrash_MTSS');
            }
        }

        //let objOccDri: Occupants = this.AddOccupant(objVeh);
        //objOccDri.PType = OccupantType.Driver;

        objVeh.Trailer1 = new Array<Trailer>();
        let objTrailer: Trailer;
        for (let i = 0; i < 3; i++) { //Three trailer rows are always coded regardless of how how many trailers were actually present
            objTrailer = AutofillService.AddModelObject(objVeh, ObjectUtil.GetTypeName(objVeh), 'Trailer');
            objTrailer.SeqNum = i + 1;
        }

        objAcc.Veh.push(objVeh);
        return objVeh;
    }

    public AddOccupant(objVeh: Veh): Occupants {
        if (!objVeh.Occupants)
            objVeh.Occupants = new Array<Occupants>();

        let objOcc: Occupants = {} as Occupants;
        objOcc._TypeScript_TypeGuard_Occupants = null;
        AutofillService.SetDefaultValues(objOcc);
        objOcc.AccID = objVeh.AccID;
        objOcc.VNumber = objVeh.VNumber;
        objOcc.PNumber = objVeh.Occupants.length + 1;
        objOcc.Veh = objVeh;

        objVeh.Occupants.push(objOcc);
        return objOcc;
    }

    public AddDriver(objVeh: Veh): void {

        let objDri: Dri = {} as Dri;
        objDri._TypeScript_TypeGuard_Dri = null;
        AutofillService.SetDefaultValues(objDri);
        objDri.AccID = objVeh.AccID;
        objDri.VNumber = objVeh.VNumber;
        objDri.Veh = objVeh;
        objVeh.Dri = objDri

        objDri.DriPrevSus = new Array<DriPrevSus>();
        let objDrPrevSus: DriPrevSus;
        for (let i = 0; i < 3; i++) { //Three DriPrevSusb rows should be auto-inserted.
            objDrPrevSus = AutofillService.AddModelObject(objDri, ObjectUtil.GetTypeName(objDri), 'DriPrevSus');
            objDrPrevSus.SeqNum = i + 1;
        }
    }

    public AddNonOccupant(objAcc: Acc): Non_Occupants {
        if (!objAcc.Non_Occupants)
            objAcc.Non_Occupants = new Array<Non_Occupants>();

        let objNonOcc: Non_Occupants = {} as Non_Occupants;
        objNonOcc._TypeScript_TypeGuard_Non_Occupants = null;
        AutofillService.SetDefaultValues(objNonOcc);
        objNonOcc.AccID = objAcc.AccID;
        objNonOcc.PNumber = objAcc.Non_Occupants.length + 1;
        objNonOcc.Acc = objAcc;


        objNonOcc.NonOcc_Safety = new Array<NonOcc_Safety>();
        let objNonOccSafety: NonOcc_Safety;
        for (let i = 0; i < 6; i++) { //Six Non Occupant Safety rows should be auto-inserted.
            objNonOccSafety = AutofillService.AddModelObject(objNonOcc, ObjectUtil.GetTypeName(objNonOcc), 'NonOcc_Safety');
            objNonOccSafety.SeqNum = i + 1;
        }

        objAcc.Non_Occupants.push(objNonOcc);

        return objNonOcc;
    }

    public AddBicyclist(objNon_Occupants: Non_Occupants): void {
        if (!objNon_Occupants.NonOcc_Bike) {
            objNon_Occupants.NonOcc_Bike = {} as NonOcc_Bike;
        }

        let objNonOcc_Bike = {} as NonOcc_Bike;

        objNon_Occupants.NonOcc_Bike._TypeScript_TypeGuard_NonOcc_Bike = null;
        //AutofillService.SetDefaultValues(objNonOcc_Bike);

        objNonOcc_Bike.AccID = objNon_Occupants.AccID;
        objNonOcc_Bike.PNumber = objNon_Occupants.PNumber;

        objNonOcc_Bike.BikeDirection = -1;
        objNonOcc_Bike.BikePosition = -1;
        objNonOcc_Bike.CrashGroup = -1;
        objNonOcc_Bike.CrashLocation = -1;
        objNonOcc_Bike.CrashType = -1;
        objNonOcc_Bike.Crosswalk = -1;
        objNonOcc_Bike.Deleted = false;
        objNonOcc_Bike.SchoolZone = -1;
        objNonOcc_Bike.Sidewalk = -1;

        objNonOcc_Bike.Non_Occupants = objNon_Occupants;

        objNon_Occupants.NonOcc_Bike = objNonOcc_Bike;

    }

    public AddPedestrian(objNon_Occupants: Non_Occupants): void {
        if (!objNon_Occupants.NonOcc_Ped) {
            objNon_Occupants.NonOcc_Ped = {} as NonOcc_Ped;
        }

        let objNonOcc_Ped = {} as NonOcc_Ped;

        objNon_Occupants.NonOcc_Ped._TypeScript_TypeGuard_NonOcc_Ped = null;
        //AutofillService.SetDefaultValues(objNonOcc_Ped);

        objNonOcc_Ped.AccID = objNon_Occupants.AccID;
        objNonOcc_Ped.PNumber = objNon_Occupants.PNumber;

        objNonOcc_Ped.CrashGroup = -1;
        objNonOcc_Ped.CrashLocation = -1;
        objNonOcc_Ped.CrashType = -1;
        objNonOcc_Ped.Crosswalk = -1;
        objNonOcc_Ped.Deleted = false;
        objNonOcc_Ped.LegIntersection = -1;
        objNonOcc_Ped.MotDirection = -1;
        objNonOcc_Ped.MotManeuver = -1;
        objNonOcc_Ped.PedDirection = -1;
        objNonOcc_Ped.PedPosition = -1;
        objNonOcc_Ped.Scenario = null;
        objNonOcc_Ped.SchoolZone = -1;
        objNonOcc_Ped.Sidewalk = -1;

        objNonOcc_Ped.Non_Occupants = objNon_Occupants;

        objNon_Occupants.NonOcc_Ped = objNonOcc_Ped;

    }

    public AddDamagedArea(objVeh: Veh, intElementValue: number = null): DamagedAreas {
        if (!objVeh.DamagedAreas)
            objVeh.DamagedAreas = new Array<DamagedAreas>();

        let objDamagedArea = {} as DamagedAreas;
        objDamagedArea._TypeScript_TypeGuard_DamagedAreas = null;
        AutofillService.SetDefaultValues(objDamagedArea);
        objDamagedArea.ACCID = objVeh.AccID;
        objDamagedArea.VNumber = objVeh.VNumber;
        objDamagedArea.Veh = objVeh
        objVeh.DamagedAreas.push(objDamagedArea);

        if (intElementValue != null)
            objDamagedArea.ElementValue = intElementValue;

        return objDamagedArea;
    }

    public AddPreCrash(objVeh: Veh): void {
        if (!objVeh.PreCrash) {
            objVeh.PreCrash = {} as PreCrash;           
        }

        objVeh.PreCrash._TypeScript_TypeGuard_PreCrash = null;       
        AutofillService.SetDefaultValues(objVeh.PreCrash);             

        objVeh.PreCrash.AccID = objVeh.AccID;
        objVeh.PreCrash.VNumber = objVeh.VNumber;

        objVeh.PreCrash.Veh = objVeh;
    }

    public AddPreCrashMTSS(objVeh: Veh) {        
        if (!objVeh.PreCrash.PreCrash_MTSS) {           
            objVeh.PreCrash.PreCrash_MTSS = {} as PreCrash_MTSS;
        }
        objVeh.PreCrash.PreCrash_MTSS._TypeScript_TypeGuard_PreCrash_MTSS = null;
        AutofillService.SetDefaultValues(objVeh.PreCrash.PreCrash_MTSS);

        objVeh.PreCrash.PreCrash_MTSS.AccID = objVeh.AccID;
        objVeh.PreCrash.PreCrash_MTSS.VNumber = objVeh.VNumber;

        objVeh.PreCrash.PreCrash_MTSS.PreCrash = objVeh.PreCrash;
    }

    public AddOccDrug(objPerson: Occupants): OccDrug {
        if (!objPerson.OccDrug) {
            objPerson.OccDrug = new Array<OccDrug>();
        }
        let objOccDrug = {} as OccDrug;

        objOccDrug._TypeScript_TypeGuard_OccDrug = null;
        //AutofillService.SetDefaultValues(objOccDrug);

        objOccDrug.AccID = objPerson.AccID;
        objOccDrug.VNumber = objPerson.VNumber;
        objOccDrug.PNumber = objPerson.PNumber;
        objOccDrug.SeqNum = 1;
        objOccDrug.DrugTst = -1;
        objOccDrug.DrugRes = -1;

        objOccDrug.Occupants = objPerson;
        objPerson.OccDrug.push(objOccDrug);

        return objOccDrug;
    }

    public AddNonOccDrug(objNon_Occupants: Non_Occupants): NonOccDrug {
        if (!objNon_Occupants.NonOccDrug) {
            objNon_Occupants.NonOccDrug = new Array<NonOccDrug>();
        }
        let objNonOccDrug = {} as NonOccDrug;

        objNonOccDrug._TypeScript_TypeGuard_NonOccDrug = null;
        //AutofillService.SetDefaultValues(objOccDrug);

        objNonOccDrug.AccID = objNon_Occupants.AccID;
        objNonOccDrug.PNumber = objNon_Occupants.PNumber;
        objNonOccDrug.SeqNum = 1;
        objNonOccDrug.DrugTst = -1;
        objNonOccDrug.DrugRes = -1;

        objNonOccDrug.Non_Occupants = objNon_Occupants;
        objNon_Occupants.NonOccDrug.push(objNonOccDrug);

        return objNonOccDrug;
    }

    public AddNonOccOther(objNon_Occupants: Non_Occupants): NonOcc_Other {
        if (!objNon_Occupants.NonOcc_Other) {
            objNon_Occupants.NonOcc_Other = {} as NonOcc_Other;
        }
        let objNonOcc_Other = {} as NonOcc_Other;

        objNonOcc_Other._TypeScript_TypeGuard_NonOcc_Other = null;
      
        objNonOcc_Other = AutofillService.AddModelObject(objNon_Occupants, ObjectUtil.GetTypeName(objNon_Occupants), 'NonOcc_Other');

        return objNonOcc_Other;
    }

    public AddNonOccInjury(objNon_Occupants: Non_Occupants): NonOcc_Injury {
        if (!objNon_Occupants.NonOcc_Injury) {
            objNon_Occupants.NonOcc_Injury = {} as NonOcc_Injury;
        }
        let objNonOcc_Injury = {} as NonOcc_Injury;

        objNonOcc_Injury._TypeScript_TypeGuard_NonOcc_Injury = null;
              
        objNonOcc_Injury = AutofillService.AddModelObject(objNon_Occupants, ObjectUtil.GetTypeName(objNon_Occupants), 'NonOcc_Injury');

        return objNonOcc_Injury;
    }

    public AddPreCrashContribCirc(objPreCrash: PreCrash): PreCrash_ContribCirc {
        if (!objPreCrash.PreCrash_ContribCirc) {
            objPreCrash.PreCrash_ContribCirc = new Array<PreCrash_ContribCirc>();
        }
        let objPreCrash_ContribCirc = {} as PreCrash_ContribCirc;

        objPreCrash_ContribCirc._TypeScript_TypeGuard_PreCrash_ContribCirc = null;
        //AutofillService.SetDefaultValues(objPreCrash_ContribCirc);

        objPreCrash_ContribCirc.AccID = objPreCrash.AccID;
        objPreCrash_ContribCirc.VNumber = objPreCrash.VNumber;

        objPreCrash_ContribCirc.ElementValue = -1;

        return objPreCrash_ContribCirc;
    }

    /**
     * PII object should be attached to ACC please DO NOT attach to the Person/Non-Occupant object itself
     **/
    public AddPersonsPII(objOccupant: Occupants): PII {
        //REMINDER: DO NOT set Occupants.PII. This helper property is used ONLY during JSON serialization.
        let objPII = {} as PII
        objPII._TypeScript_TypeGuard_PII = null;

        objPII.AccID = objOccupant.AccID;
        objPII.VNumber = objOccupant.VNumber;
        objPII.PNumber = objOccupant.PNumber;

        objPII.PName = '';
        objPII.DoB = null;
        objPII.Comment1 = "";
        objPII.Comment2 = "";

        //REMINDER: DO NOT set Occupants.PII. This helper property is used ONLY during JSON serialization.
        objPII.Acc = objOccupant.Veh.Acc;
        objOccupant.Veh.Acc.PII.push(objPII);

        return objPII;
    }

    /**
     * PII object should be attached to ACC please DO NOT attach to the Person/Non-Occupant object itself
     **/
    public AddNonOccupantsPII(objNonOccupant: Non_Occupants): PII {
        //REMINDER: DO NOT set Non_Occupants.PII. This helper property is used ONLY during JSON serialization.
        let objPII = {} as PII
        objPII._TypeScript_TypeGuard_PII = null;

        objPII.AccID = objNonOccupant.AccID;
        objPII.PNumber = objNonOccupant.PNumber;
        objPII.VNumber = 0;

        objPII.PName = '';
        objPII.DoB = null;
        objPII.Comment1 = "";
        objPII.Comment2 = "";

        //REMINDER: DO NOT set Non_Occupants.PII. This helper property is used ONLY during JSON serialization.
        objPII.Acc = objNonOccupant.Acc;
        objNonOccupant.Acc.PII.push(objPII);

        return objPII;
    }

    /**
     * Creates, drops, or alters Occupant "Created Record" placeholder rows based on the fields that drive "Created Record" existence:
     * vPIC Body Class, NumOccs (relative to dbo.Occupants row count), and Hit and Run
     * @param objModel Vehicle being edited
     * @param strFieldName Name of field being edited
     * @param objNewSelection New value for field
     * @param objModelOriginal Pristine clone of the data model
     */
    public async OnCreatedRecordPrerequisitesChange(objModel: Veh, strFieldName: string, objNewSelection: DrpDownOptions, objModelOriginal: Veh): Promise<boolean> {
        let blnChangeRolledBack: boolean = false;
        let blnConfirm: boolean = false;
        let objControl: UIElementBase = AutofillService._arrControls.find(x => x.strTableName == 'Veh' && x.strFieldName == strFieldName);
        let numCreatedRecords: number = objModel.Occupants.filter(x => x.CreatedRecord != CreatedRecord_Occupant_Type.NotCreatedRecord).length;
        let intCrToBeCreated: number = 0, intCrToBeDeleted: number = 0;
        let intCrType: number = CreatedRecord_Occupant_Type.EditableHitRun; //CRs are editable unconditionally as of 2020, regardless of "Hit and Run" field value.

        if(objModel.Acc.Mode == DBMode.MOSS)
            return false;

        if (strFieldName == 'HitRun') {
            if (objNewSelection.intValue != objModelOriginal.HitRun) {
                if (numCreatedRecords > 0) {
                    if (objModelOriginal.HitRun != HitAndRun.Yes && objNewSelection.intValue == HitAndRun.Yes) {
                        if (await this.dialog(`You have changed \"Hit and Run\" to \"1 - Yes\". This will cause the system to automatically change Person Related Factors for ${numCreatedRecords} Person Created Record(s) to 999 - Unknown, which can be edited afterwards if needed. Are you sure you want to proceed with this change?`, "Created Records", "C").toPromise()) {
                            objModelOriginal.HitRun = objNewSelection.intValue;

                            for (let objOcc of objModel.Occupants) {
                                if (objOcc.OccRF && objOcc.OccRF.length == 1 && objOcc.OccRF[0].PRF == PersonRelatedFactors.None)
                                    objOcc.OccRF[0].PRF = PersonRelatedFactors.Unknown;
                            }
                        }
                        else {
                            blnChangeRolledBack = true;
                            objControl.setCurrentValue(objModelOriginal.HitRun.toString());
                        }
                    }
                    else if (objModelOriginal.HitRun == HitAndRun.Yes && objNewSelection.intValue != HitAndRun.Yes) {
                        if (await this.dialog(`You have changed \"Hit and Run\" from \"1 - Yes\". This will cause the system to automatically change ${numCreatedRecords} Person Created Record(s) to default values, which can be edited afterwards if needed. Are you sure you want to proceed with this change?`, "Created Records", "C").toPromise()) {
                            blnConfirm = true;
                            objModelOriginal.HitRun = objNewSelection.intValue;
                            intCrToBeDeleted = numCreatedRecords;
                            intCrToBeCreated = numCreatedRecords;
                        }
                        else {
                            blnChangeRolledBack = true;
                            objControl.setCurrentValue(objModelOriginal.HitRun.toString());
                        }
                    }
                }
            }
        }
        else if (strFieldName == 'NumOccs') {
            if ((AutofillService.arrOccupantCrIncompleteBodyTypes.indexOf(objModel.vPICBodyClass) == -1 &&
                AutofillService.arrOccupantCrExclusionBodyTypes.indexOf(objModel.vPICBodyClass) == -1)
                ||
                (AutofillService.arrOccupantCrIncompleteBodyTypes.indexOf(objModel.vPICBodyClass) != -1 &&
                    AutofillService.arrOccupantCrExclusionBodyTypes.indexOf(objModel.FinalStageBodyClass) == -1)
            ) {
                let PromptForRecCreateDelete: boolean = await this._userPreferenceService.isThisPreferenceOn(Preference.PromptForRecCreateDelete);

                if (objModelOriginal.NumOccs != objModel.NumOccs &&
                    objNewSelection.intValue > objModel.Occupants.length &&
                    objNewSelection.intValue != NumberOfOccupants.NotReported &&
                    objNewSelection.intValue != NumberOfOccupants.Unknown
                ) {
                    intCrToBeCreated = objNewSelection.intValue - objModel.Occupants.length;

                    if (PromptForRecCreateDelete) {
                        if (await this.dialog("The Number of Occupants (" + objNewSelection.intValue + ") you have entered is more than the number of Person records created (" + objModel.Occupants.length + ") for this vehicle.  This will automatically generate (" + intCrToBeCreated + ") Person record(s) called \"Created Records\" and will fill them up with data. Are you sure you want to proceed with this change?", "Created Records", "C").toPromise()) {
                            blnConfirm = true;
                            objModelOriginal.NumOccs = objModel.NumOccs;
                        }
                        else {
                            blnChangeRolledBack = true;
                            objControl.setCurrentValue(objModelOriginal.NumOccs.toString());
                        }
                    }
                    else {
                        blnConfirm = true;
                        objModelOriginal.NumOccs = objModel.NumOccs;
                    }
                }
                else if (numCreatedRecords > 0 && ( //check if CRRecords need to be removed
                    objNewSelection.intValue < objModel.Occupants.length ||
                    objNewSelection.intValue == NumberOfOccupants.NotReported ||
                    objNewSelection.intValue == NumberOfOccupants.Unknown)
                ) {
                    if (objNewSelection.intValue == NumberOfOccupants.NotReported ||
                        objNewSelection.intValue == NumberOfOccupants.Unknown)
                        intCrToBeDeleted = numCreatedRecords;
                    else
                        intCrToBeDeleted = (objModel.Occupants.length - objNewSelection.intValue);

                    if (PromptForRecCreateDelete) {
                        if (await this.dialog("You have changed the Number of Occupants from (" + objModel.Occupants.length + ") to (" + objNewSelection.intValue + "), this will cause the system to automatically delete (" + numCreatedRecords + ") Person Created Record(s). Are you sure you want to proceed with this change ?", "Created Records", "C").toPromise()) {
                            blnConfirm = true;
                            objModelOriginal.NumOccs = objModel.NumOccs;

                            if (numCreatedRecords < intCrToBeDeleted) {
                                alert(`There were only ${numCreatedRecords} \"Created Record\" entries to remove. Further changes to the number of occupants in this vehicle should be done from the \"Case Restructure\" page.`);
                                intCrToBeDeleted = numCreatedRecords;
                            }
                        }
                        else {
                            blnChangeRolledBack = true;
                            objControl.setCurrentValue(objModelOriginal.NumOccs.toString());
                        }
                    }
                    else {
                        blnConfirm = true;
                        objModelOriginal.NumOccs = objModel.NumOccs;

                        if (numCreatedRecords < intCrToBeDeleted) {
                            alert(`There were only ${numCreatedRecords} \"Created Record\" entries to remove. Further changes to the number of occupants in this vehicle should be done from the \"Case Restructure\" page.`);
                            intCrToBeDeleted = numCreatedRecords;
                        }
                    }
                }
            }
        }
        else if (strFieldName == 'vPICBodyClass') { //Final Stage Body Class may have been set immediately by an autofill rule (ex Incomplete Schoolbus Chassis)
            if (objNewSelection.intValue != objModelOriginal.vPICBodyClass
                && !this.ngModal.hasOpenModals()
                && objModel.NumOccs != NumberOfOccupants.Unknown && objModel.NumOccs != NumberOfOccupants.NotReported) { //Final Stage Body Class may be changed concurrently with VPIC Body Class and a CR confirm prompt may already be open
                if ((AutofillService.arrOccupantCrIncompleteBodyTypes.indexOf(objModel.vPICBodyClass) == -1 &&
                    AutofillService.arrOccupantCrExclusionBodyTypes.indexOf(objModel.vPICBodyClass) == -1)
                    ||
                    (AutofillService.arrOccupantCrIncompleteBodyTypes.indexOf(objModel.vPICBodyClass) != -1 &&
                        AutofillService.arrOccupantCrExclusionBodyTypes.indexOf(objModel.FinalStageBodyClass) == -1)) {
                    if (objModel.NumOccs > objModel.Occupants.length) {
                        intCrToBeCreated = objModel.NumOccs - objModel.Occupants.length;

                        if (await this.dialog("You have coded the Vehicle Body Class as not a Bus and the Number of Occupants as (" + objModel.NumOccs + "), which is more than the number of Person records created (" + objModel.Occupants.length + ") for this vehicle.  This will automatically generate (" + intCrToBeCreated + ") Person record(s) called \"Created Records\" and will fill them up with data. Are you sure you want to proceed with this change?", "Created Records", "C").toPromise()) {
                            blnConfirm = true;
                            objModelOriginal.vPICBodyClass = objNewSelection.intValue;
                        }
                        else {
                            blnChangeRolledBack = true;

                            if (objControl)
                                objControl.setCurrentValue(objModelOriginal.vPICBodyClass.toString());
                            else
                                objModel.vPICBodyClass = objModelOriginal.vPICBodyClass;

                            //Final Stage Body Class may have been set immediately by an autofill rule (ex Incomplete Schoolbus Chassis)
                            let objControlFSBC: UIElementBase = AutofillService.arrControls.find(x => x.strFieldName == 'FinalStageBodyClass');

                            if (objControlFSBC)
                                objControlFSBC.setCurrentValue(objModelOriginal.FinalStageBodyClass.toString());
                            else
                                objModel.FinalStageBodyClass = objModelOriginal.FinalStageBodyClass;
                        }
                    }
                }
                else {
                    if (numCreatedRecords > 0) {
                        if (await this.dialog(`You have coded the Vehicle Body Class as a Bus. This will cause the system to automatically delete ${numCreatedRecords} Person Created Record(s). Are you sure you want to proceed with this change?`, "Created Records", "C").toPromise()) {
                            blnConfirm = true;
                            intCrToBeDeleted = numCreatedRecords;
                            objModelOriginal.vPICBodyClass = objNewSelection.intValue;
                        }
                        else {
                            blnChangeRolledBack = true;

                            if (objControl)
                                objControl.setCurrentValue(objModelOriginal.vPICBodyClass.toString());
                            else
                                objModel.vPICBodyClass = objModelOriginal.vPICBodyClass;
                        }
                    }
                }
            }
        }
        else if (strFieldName == "FinalStageBodyClass") {
            if (objNewSelection.intValue != objModelOriginal.FinalStageBodyClass //May be changing from -1 to 0 (by autofill rule)
                && !this.ngModal.hasOpenModals()
                && objModel.NumOccs != NumberOfOccupants.Unknown && objModel.NumOccs != NumberOfOccupants.NotReported
            ) { //Final Stage Body Class may be changed concurrently with VPIC Body Class and a CR confirm prompt may already be open
                if ((AutofillService.arrOccupantCrIncompleteBodyTypes.indexOf(objModel.vPICBodyClass) == -1 &&
                    AutofillService.arrOccupantCrExclusionBodyTypes.indexOf(objModel.vPICBodyClass) == -1)
                    ||
                    (AutofillService.arrOccupantCrIncompleteBodyTypes.indexOf(objModel.vPICBodyClass) != -1 &&
                        AutofillService.arrOccupantCrExclusionBodyTypes.indexOf(objModel.FinalStageBodyClass) == -1)) {
                    if (objModel.NumOccs > objModel.Occupants.length) {
                        intCrToBeCreated = objModel.NumOccs - objModel.Occupants.length;

                        if (await this.dialog("You have coded the Final Stage Body Class as not a Bus and previously coded the The Number of Occupants as (" + objModel.NumOccs + "), which is more than the number of Person records created (" + objModel.Occupants.length + ") for this vehicle.  This will automatically generate (" + intCrToBeCreated + ") Person record(s) called \"Created Records\" and will fill them up with data. Are you sure you want to proceed with this change?", "Created Records", "C").toPromise()) {
                            blnConfirm = true;
                            objModelOriginal.FinalStageBodyClass = objNewSelection.intValue;
                        }
                        else {
                            blnChangeRolledBack = true;
                            objControl.setCurrentValue(objModelOriginal.FinalStageBodyClass.toString());
                        }
                    }
                }
                else {
                    if (numCreatedRecords > 0) {
                        if (await this.dialog(`You have coded the Final Stage Body Class as a Bus. This will cause the system to automatically delete ${numCreatedRecords} Person Created Record(s). Are you sure you want to proceed with this change?`, "Created Records", "C").toPromise()) {
                            blnConfirm = true;
                            intCrToBeDeleted = numCreatedRecords;
                            objModelOriginal.FinalStageBodyClass = objNewSelection.intValue;
                        }
                        else {
                            blnChangeRolledBack = true;
                            objControl.setCurrentValue(objModelOriginal.FinalStageBodyClass.toString());
                        }
                    }
                }
            }
        }

        if (blnConfirm) {
            //We may be reinstantiating Created Records to reset them to default values
            if (intCrToBeDeleted > 0) {
                for (let i = 0; i < intCrToBeDeleted; i++)
                    AutofillService.DeleteCROccupant(objModel);
            }

            if (intCrToBeCreated > 0) {
                for (let i = 0; i < intCrToBeCreated; i++)
                    AutofillService.AddCROccupant(objModel, intCrType);
            }

            if (objModel.NumOccs != objModel.Occupants.length) //After deletion, we may have fewer person records. To restore mistakenly removed Created Records, user must go to Vehicle tab and set Veh.NumOccs again. This is intended behavior.
                objModel.NumOccs = objModel.Occupants.length;

            let intPForms: number = 0;

            for (let v of objModel.Acc.Veh)
                intPForms += v.NumOccs;

            objModel.Acc.PForms = intPForms; //Propagate updated occupant count to Acc level
        }

        return blnChangeRolledBack;
    }

    /**
     * This is a workaround for the behavior where ngFor is clearing either the row in front or the row behind a newly inserted row.
     * Reapplies data model value to INPUT.value when INPUT.value is blank.
     **/
    public RerenderModelValues(blnDisabled: boolean = null): void {
        AutofillService._arrControls.forEach(x => x.RerenderModelValue(blnDisabled));
    }



    /**
     * Creates an Entity Framework model object of the specified type with default values and with bidirectional link to parent.
     * Relies on Entity Framework naming convention: navigation properties are named the same as the underlying type.
     */
    public static AddModelObject(objParent: any, strParentTypeName: string, strTypeName: string) {
        let objModel: any = {};
        AutofillService.InitPrimaryKeysFromParent(objParent, objModel, strTypeName);
        AutofillService.SetDefaultValues(objModel, strTypeName); //Also sets type guard
        objModel[strParentTypeName] = objParent;

        let strPropertyNameInParent = strTypeName; //Entity framework appends a numerical suffix to make repeated identifiers unique, ex: Veh.Trailer (column holding trailer count) and Veh.Trailer1 (navigation property to Trailer child rows)
        if (objParent.hasOwnProperty(strTypeName + 1))
            strPropertyNameInParent += '1';

        if (Array.isArray(objParent[strPropertyNameInParent]))
            objParent[strPropertyNameInParent].push(objModel);
        else
            objParent[strPropertyNameInParent] = objModel;

        return objModel;
    }

    /**
     * Creates a placeholder Occupant row when the number of occupants is edited to be
     * above the number of Occupant records and the vehicle body type is not a bus.
     */
    public static AddCROccupant(objVeh: Veh, crType: number): Occupants {
        if (!objVeh.Occupants)
            objVeh.Occupants = new Array<Occupants>();

        let objOcc: Occupants = {} as Occupants;
        objOcc._TypeScript_TypeGuard_Occupants = null;
        objOcc.AccID = objVeh.AccID;
        objOcc.VNumber = objVeh.VNumber;
        objOcc.PNumber = objVeh.Occupants.length + 1;
        objOcc.CreatedRecord = crType;
        objOcc.PType = PersonType.Passenger;
        objOcc.Age = 998
        objOcc.Sex = 8;
        objOcc.SeatPos = 98;
        objOcc.Restraint = 98;
        objOcc.RestraintMisuse = 7;
        objOcc.AirBag = 98;
        objOcc.Ejection = 7;
        objOcc.EjectPath = EjectionPath.NotApplicable;
        objOcc.Extricat = 0;
        objOcc.AlcInvol = 8;
        objOcc.MethAlc = 9;
        objOcc.AlcTst = 0;
        objOcc.AlcRes = AlcoholTestResult.TestNotGiven;
        objOcc.DrugInv = 8;
        objOcc.MethDrug = 8;
        objOcc.Injury = 0;
        objOcc.TakeHosp = 0;
        objOcc.DiedScene = 0;
        objOcc.DthMon = 88;
        objOcc.DthDay = 88;
        objOcc.DthYr = 8888;
        objOcc.DthHr = 88;
        objOcc.DthMin = 88;
        objOcc.DeathCity = 0;
        objOcc.DeathState = 0;
        objOcc.DeathCertNumber = 0; //Leading zero formatting will be applied by UI
        objOcc.DeathCer = '000000000000';
        objOcc.InjurWrk = 8;
        objOcc.Deleted = false;
        objOcc.AlcSts = 0;
        objOcc.DrugSts = 0;
        objOcc.Helmet = 98;
        objOcc.HelmetMisUse = 7;

        objOcc.Occ_Race = new Array<Occ_Race>();
        objOcc.Occ_Race.push({} as Occ_Race);
        objOcc.Occ_Race[0]._TypeScript_TypeGuard_Occ_Race = null;
        objOcc.Occ_Race[0].AccID = objVeh.AccID;
        objOcc.Occ_Race[0].AllowOneMultiple = false;
        objOcc.Occ_Race[0].ElementValue = PersonRace.NotApplicable;
        objOcc.Occ_Race[0].Occupants = objOcc;
        objOcc.Occ_Race[0].PNumber = objOcc.PNumber;
        objOcc.Occ_Race[0].SeqNum = 1;
        objOcc.Occ_Race[0].VNumber = objOcc.VNumber;
        objOcc.IsMultiRace = false;
        objOcc.Hispanic = 0;

        objOcc.OccRF = new Array<OccRF>();
        objOcc.OccRF.push({
            _TypeScript_TypeGuard_OccRF: null,
            AccID: objOcc.AccID,
            Occupants: objOcc,
            PNumber: objOcc.PNumber,
            PRF: objVeh.HitRun == HitAndRun.Yes ? PersonRelatedFactors.Unknown : PersonRelatedFactors.None,
            VNumber: objVeh.VNumber
        } as OccRF);

        objOcc.OccDrug = new Array<OccDrug>();
        objOcc.OccDrug.push({
            _TypeScript_TypeGuard_OccDrug: null,
            AccID: objOcc.AccID,
            DrugTst: DrugTestSubstance.TestNotGiven,
            DrugRes: DrugTestResult.TestNotGiven,
            Occupants: objOcc,
            PNumber: objOcc.PNumber,
            SeqNum: 1,
            VNumber: objVeh.VNumber
        } as OccDrug);

        objOcc.Veh = objVeh;

        objVeh.Occupants.push(objOcc);
        return objOcc;
    }

    /**
     * Removes the first encountered Occupant "Created Record" from the vehicle
     */
    public static DeleteCROccupant(objVeh: Veh): Veh {

        let objOcc: Occupants = objVeh.Occupants.find(o => o.VNumber == objVeh.VNumber && o.CreatedRecord > 0);
        if (objOcc) {
            objVeh.Occupants.splice(objVeh.Occupants.indexOf(objOcc), 1);
        }

        return objVeh;
    }

    /**
     * VIN Decoding is a programmatic action that has no UI hooks to trigger autofill rules. This method should be called after VIN Decode to ensure that autofill
     * rules that depend on VIN-derived field have fired.
     * @param objVeh
     */
    public static EvalRulesForVinDerivedFields(objVeh: Veh): void {
        //TODO: If there are read-only UIElementBase deriving controls on screen that are being set programmatically, then
        //add logic to look them up in AutofillService._arrControls and feed to OnChange() method
        AutofillService.OnChange(null, objVeh, 'ErrorCode', true, true, false);
        AutofillService.OnChange(null, objVeh, 'VIN', true, true, false);
        AutofillService.OnChange(null, objVeh, 'vPICMake', true, true, false);
        AutofillService.OnChange(null, objVeh, 'vPICModel', true, true, false);
        AutofillService.OnChange(null, objVeh, 'vPICBodyClass', true, true, false);
        AutofillService.OnChange(null, objVeh, 'Make', true, true, false);
        AutofillService.OnChange(null, objVeh, 'Model', true, true, false);
        AutofillService.OnChange(null, objVeh, 'Body', true, true, false);
        AutofillService.OnChange(null, objVeh, 'ModelYr', true, true, false);
        AutofillService.OnChange(null, objVeh, 'vPICGVWR', true, true, false);
        AutofillService.OnChange(null, objVeh, 'vPICGVWRTo', true, true, false);
    }

    /**
     * Relies on naming convention: PK columns in child have the same [case sensitive] names in referenced parent
     */
    public static InitPrimaryKeysFromParent(objParent: any, objChild: any, strChildTypeName: string) {
        for (let objDefault of AutofillService._arrColumnDefaults) {
            if (objDefault.strTableName == strChildTypeName) {
                if (objDefault.blnPK) {
                    if (objParent[objDefault.strColumnName] !== undefined) {
                        objChild[objDefault.strColumnName] = objParent[objDefault.strColumnName];
                    }
                    else { //Fall back on case insensitive column name match
                        let strColumnNameLower = objDefault.strColumnName.toLowerCase();

                        for (let strProperty in objParent) {
                            if (strColumnNameLower == strProperty.toLowerCase())
                                objChild[objDefault.strColumnName] = objParent[strProperty];
                        }
                    }
                }
            }
        }
    }

    /**
     * Returns the JavaScript data type of a field on a model object based on metadata retrieved from the database.
     * Useful because at runtime JavaScript is not a type strong language and will allow the data type of a field 
     * to be changed and because null contains no data type information.
     * @param objModel Model object on which a field is to be inspected
     * @param strFieldName Field for which the data type is to be resolved
     * @param strTypeName (Optional) If not specified, the type guard on the objModel parameter will be inspected instead.
     */
    public static GetDataType(objModel: any, strFieldName: string, strTypeName: string = null): string {
        if (objModel == undefined || objModel == null)
            throw new Error('ArgumentNullException: objModel parameter is required.');
        if (strFieldName == undefined || strFieldName == null || strFieldName.trim() == '')
            throw new Error('ArgumentNullException: strFieldName parameter is required.');

        if (strFieldName == 'ddLatitude' || strFieldName == 'ddLongitude' || strFieldName == 'ddmLongitudeMinutes' || strFieldName == 'ddmLatitudeMinutes' || strFieldName == 'DeathCertNumber')
            return PropertyType.Number;

        let strDbTypeName: string;

        if (!strTypeName)
            strTypeName = ObjectUtil.GetTypeName(objModel);

        for (let objDefault of AutofillService._arrColumnDefaults) {
            if (objDefault.strTableName == strTypeName && objDefault.strColumnName == strFieldName) {
                strDbTypeName = objDefault.strDataType.toLowerCase();

                if (strDbTypeName == ColumnDataType.Integer || strDbTypeName == ColumnDataType.SmallInt ||
                    strDbTypeName == ColumnDataType.TinyInt || strDbTypeName == ColumnDataType.BigInt ||
                    strDbTypeName == ColumnDataType.Decimal || strDbTypeName == ColumnDataType.Float)
                    return PropertyType.Number;
                else if (strDbTypeName.indexOf(ColumnDataType.Date) != -1)
                    return 'Date';
                else if (strDbTypeName == ColumnDataType.Bit)
                    return PropertyType.Boolean;
                else if (strDbTypeName == ColumnDataType.Array)
                    return 'Array';
                else if (strDbTypeName == ColumnDataType.Varchar || strDbTypeName == ColumnDataType.Char)
                    return PropertyType.String;
            }
        }

        console.log(`AutofillService.GetDataType(): There is no metadata for ${strTypeName}.${strFieldName}. Falling back on "typeof" operator.`);
        return typeof (objModel[strFieldName]);
    }

    public static SetDefaultValues(objModel: any, strTypeName: string = null) {
        let dtNow: Date = new Date();
        let strDbTypeName: string;
        let objDefaultValue: any;

        if (!strTypeName)
            strTypeName = ObjectUtil.GetTypeName(objModel);

        objModel['_TypeScript_TypeGuard_' + strTypeName] = null;

        for (let objDefault of AutofillService._arrColumnDefaults) {
            if (objDefault.strTableName == strTypeName) {
                strDbTypeName = objDefault.strDataType.toLowerCase();

                if (objDefault.strDefault !== null || strDbTypeName == ColumnDataType.Array) {
                    if (strDbTypeName == ColumnDataType.Integer || strDbTypeName == ColumnDataType.SmallInt || strDbTypeName == ColumnDataType.TinyInt || strDbTypeName == ColumnDataType.Decimal || strDbTypeName == ColumnDataType.Float) {
                        if (objDefault.strDefault.toLowerCase().indexOf('year') != -1)
                            objDefaultValue = dtNow.getFullYear();
                        else
                            objDefaultValue = parseFloat(objDefault.strDefault);
                    }
                    else if (strDbTypeName.indexOf(ColumnDataType.Date) != -1)
                        objDefaultValue = dtNow;
                    else if (strDbTypeName == ColumnDataType.Bit)
                        objDefaultValue = objDefault.strDefault == '1' ? true : false;
                    else if (strDbTypeName == ColumnDataType.Array)
                        objDefaultValue = [];
                    else //strDbTypeName == 'varchar'
                        objDefaultValue = objDefault.strDefault.replace(/'/g, '');

                    objModel[objDefault.strColumnName] = objDefaultValue;
                }
                else if (objModel[objDefault.strColumnName] === undefined)
                    objModel[objDefault.strColumnName] = null;
            }
        }
    }


    /**
      * Looks up all rules where at least one condition or at least one action refers to the data model
      * field that the passed in data model reference (objModel[strField]) is bound to and [re-]evaluates those rules.
      * @param objControl The UI Control (UI Element) that was updated. May be null if an offscreen field was updated.
      * @param objModel The data model object on which a field was updated.
      * @param strField The name of the updated field on the data model object.
      * @param blnIncludeModelActions Flag that indicates whether model-affecting actions like "set", "clear", "insert", "delete" should be skipped and only presentation actions like "enable/disable" and "show/hide" should be executed.
      * @param blnUserInitiated Flag that indicates whether rules are being reevaluated after a user action and auto advancement of the field focus is applicable.
      * @param blnFocusNext Flag is for skip the focus action if the control is edited as blank (-1) .
      */
    public static OnChange(objControl: UIElementBase, objModel: any, strField: string, blnIncludeModelActions: boolean, blnUserInitiated: boolean, blnFocusNext: boolean = true): number {
        let intRulesWhereConditionsMetTotal: number = 0;
        let intRuleWhereConditionsMet: number = 0;
        //console.log(objModel);
        try {
            if (AutofillService._arrAutofillConditions) {
                let objFirstUninitialized: UIElementBase = AutofillService._arrControls.find(x => !x.blnInitialized);

                if (!objFirstUninitialized) { //Evaluating autofills will occur once UIElementBase.evtSiblingsInitialized has fired
                    if (objModel == null)
                        objModel = objControl.objModel;

                    let strModelObjectTypeName: string = ObjectUtil.GetTypeName(objModel);
                    let strOperandEnd: string = strModelObjectTypeName + '.' + strField;
                    let arrRulesToEval: Rule[] = new Array<Rule>();
                    let blnSetActionEncountered: boolean = false;

                    //Rules are matched by fields based on the prefix (the model object) and suffix (table and field name at end of model path). Since
                    //onscreen fields may be bound to model objects different from the objModel input parameter to OnChange(), we need to check all onscreen
                    //fields for whether the change is relevant to them.
                    AutofillService._arrControls.forEach((x: UIElementBase) => {
                        let arrMatchingConditions: RuleCondition[] = x.arrAutofillConditions.filter(objCondition => objCondition.LEFTOPERAND_END == strOperandEnd);

                        if (arrMatchingConditions) {
                            for (let i = 0; i < arrMatchingConditions.length; i++) {
                                if (arrRulesToEval.indexOf(arrMatchingConditions[i].Rule) == -1) { //Rule not already executed
                                    arrRulesToEval.push(arrMatchingConditions[i].Rule);
                                    intRuleWhereConditionsMet = AutofillService.EvalRule(objControl, arrMatchingConditions[i].Rule, objModel, blnIncludeModelActions, blnUserInitiated);

                                    if (!blnSetActionEncountered)
                                        blnSetActionEncountered = intRuleWhereConditionsMet > 0 && arrMatchingConditions[i].Rule.RuleAction.find(x => x.OPERATOR == AutoFillOperator.Set) != null;

                                    intRulesWhereConditionsMetTotal += intRuleWhereConditionsMet;
                                }
                            }
                        }
                    });

                    //Also need to re-evaluate rules where action right side matches changed field. Ex: this is needed for Driver- Counters rule.
                    AutofillService._arrControls.forEach((x: UIElementBase) => {
                        if (x.arrAutofillRules) {
                            let arrRules: Rule[] = x.arrAutofillRules.filter(i => i.RuleAction.find(objAction => objAction.RIGHTOPERAND == strOperandEnd));

                            for (let i = 0; i < arrRules.length; i++) {
                                if (arrRulesToEval.indexOf(arrRules[i]) == -1) { //Rule not already executed
                                    arrRulesToEval.push(arrRules[i]);
                                    intRuleWhereConditionsMet = AutofillService.EvalRule(objControl, arrRulesToEval[i], objModel, blnIncludeModelActions, blnUserInitiated);

                                    if (!blnSetActionEncountered)
                                        blnSetActionEncountered = intRuleWhereConditionsMet > 0 && arrRulesToEval[i].RuleAction.find(x => x.OPERATOR == AutoFillOperator.Set) != null;

                                    intRulesWhereConditionsMetTotal += intRuleWhereConditionsMet;
                                }
                            }
                        }
                    });

                    //There are two types of rule cascades possible: rule action triggers another rule that updates another onscreen field, and rule action triggers another rules that updates an offscreen field. Next block deals with only the latter cascade
                    //When autofill rules are cascading (user action updates an offscreen field, which triggers another rule that must update another offscreen field)
                    //we have to inspect the entire set of autofill rules for matching rule conditions.
                    if (!objControl) {
                        //Dealing with cascade triggered by offscreen field
                        let arrCascade: RuleCondition[] = AutofillService._arrAutofillConditions.filter(objCondition =>
                            objCondition.LEFTOPERAND.startsWith(strModelObjectTypeName) && //Cannot evaluate rule conditions when they have model path beginning that differs from available model object
                            objCondition.LEFTOPERAND_END == strOperandEnd);

                        for (let i = 0; i < arrCascade.length; i++) {
                            if (arrRulesToEval.indexOf(arrCascade[i].Rule) == -1) { //Rule not evaluated yet
                                arrRulesToEval.push(arrCascade[i].Rule);
                                intRuleWhereConditionsMet = AutofillService.EvalRule(objControl, arrCascade[i].Rule, objModel, blnIncludeModelActions, blnUserInitiated);

                                if (!blnSetActionEncountered)
                                    blnSetActionEncountered = intRuleWhereConditionsMet > 0 && arrCascade[i].Rule.RuleAction.find(x => x.OPERATOR == AutoFillOperator.Set) != null;

                                intRulesWhereConditionsMetTotal += intRuleWhereConditionsMet;
                            }
                        }
                    }

                    if (blnUserInitiated && blnFocusNext && blnSetActionEncountered)
                        objControl.focusNextInput(true);

                    if (AutofillService._blnVerbose && (arrRulesToEval.length > 0)) {
                        console.debug('====================================================================================================');
                        console.debug(`AutofillService: Change to ${strOperandEnd} resulted in evaluation of  ${arrRulesToEval.length} rules.`);
                        console.debug('====================================================================================================');
                    }
                }
            }
            else
                console.warn('AutofillService.OnChange called before AutofillService was initialized. Check that InitRouteGuard is being used properly.');
        }
        catch (ex) {
            console.error('AutofillService.OnChange(): ' + ex.toString());
        }

        return intRulesWhereConditionsMetTotal;
    }

    /**
     * //USE IUIElementBase.focusNextInput(true) INSTEAD
     * It finds the next empty control which is from the index of current active element.
     * @param objControl
    
    private static FocusNextEmptyAutoFillControl(objControl: UIElementBase): void {
        let arrUIElements: any[] = UIElementBase GetArrFocusCandidates();
        if (arrUIElements) {
            let currentElement = arrUIElements.find(i => i.id == objControl.inputName);
            if (currentElement) {
                let index: number = arrUIElements.indexOf(currentElement);

                if (index < arrUIElements.length - 1) {
                    let intNextIndex = UIElementBase.FindNextEmptyControlIndex(arrUIElements, index);

                    if (!intNextIndex)
                        intNextIndex = index + 1;

                    let nextElement = arrUIElements[intNextIndex];
                    //added this condition since <select> HTML element does not support .select(); so this way we are skipping selects
                    // found out at  early-notification-create.component
                    if (nextElement.value.length > 0 && nextElement.localName != "select") {
                        nextElement.select();
                    }
                    nextElement.focus();
                }
            }
        }
    }
     */
    /**
     * Executes autofill actions for a single UI control bound to a data model field.
     * @param objControl UI control on which to evaluate autofill actions
     * @param blnIncludeModelActions Execute model-affecting acions like set, clear, insert, delete (generally these are user triggered only)
     * @param blnUserInitiated Is this autofill evaluation cycle triggered by a human action like a click vs. a non-human action like a page load finished event.
     */
    public static EvalRulesAffectingField(objControl: UIElementBase, blnIncludeModelActions: boolean = false, blnUserInitiated: boolean = false) {
        let arrRulesToEval: Rule[] = new Array<Rule>();
        if (objControl.arrAutofillRules) {
            for (let i = 0; i < objControl.arrAutofillRules.length; i++) {
                if (arrRulesToEval.indexOf(objControl.arrAutofillRules[i]) == -1) {
                    arrRulesToEval.push(objControl.arrAutofillRules[i])
                    AutofillService.EvalRule(objControl, objControl.arrAutofillRules[i], objControl.objModel, blnIncludeModelActions, blnUserInitiated);
                }
            }
        }
    }

    private static EvalAction(blnConditionsMet: boolean, objControl: UIElementBase, objModel: any, objAction: RuleAction, blnIncludeModelActions: boolean, blnUserInitiated: boolean): number {
        let arrAutoFillDebugBreakAt: number[] = [58];
        if (arrAutoFillDebugBreakAt.indexOf(objAction.ACTIONID) != -1)
            console.debug('AutofillService.EvalAction(): Debug helper break point at rule action ' + objAction.ACTIONID.toString()); //Put breakpoint here to stop


        if (AutofillService._blnVerbose)
            console.debug(
                (blnConditionsMet && !blnIncludeModelActions &&
                    (objAction.OPERATOR == AutoFillOperator.Set || objAction.OPERATOR == AutoFillOperator.Clear ||
                        objAction.OPERATOR == AutoFillOperator.Insert || objAction.OPERATOR == AutoFillOperator.Delete) ? '[Skipped on page load] ' : '')
                +
                `AutofillService:   Action ${objAction.ACTIONID}: ${objAction.LEFTOPERAND} `
                +
                (objAction.OPERATOR == AutoFillOperator.Enable ? (blnConditionsMet ? 'enabled' : 'disabled') :
                    (objAction.OPERATOR == AutoFillOperator.Disable ? (blnConditionsMet ? 'disabled' : 'enabled') :
                        (objAction.OPERATOR == AutoFillOperator.Show ? (blnConditionsMet ? 'displayed' : 'hidden') :
                            (objAction.OPERATOR == AutoFillOperator.Hide ? (blnConditionsMet ? 'hidden' : 'displayed') :
                                objAction.OPERATOR))))
                + ' ' +
                (objAction.OPERATOR != AutoFillOperator.Enable && objAction.OPERATOR != AutoFillOperator.Disable &&
                    objAction.OPERATOR != AutoFillOperator.Show && objAction.OPERATOR != AutoFillOperator.Hide &&
                    objAction.OPERATOR != AutoFillOperator.Clear && objAction.OPERATOR != AutoFillOperator.Delete ? objAction.RIGHTOPERAND : ''));

        let intRuleWhereConditionsMet: number = 0;

        try {
            if (objAction.RIGHTOPERAND !== undefined && objAction.RIGHTOPERAND !== null) //Allow for 0
                if (objAction.RIGHTOPERAND.toLowerCase() == 'null')
                    objAction.RIGHTOPERAND = null;

            if (objAction.LEFTOPERANDTYPE == RuleOperandType.Context) {
                let objTarget: UIElementBase;

                if (objAction.OPERATOR == AutoFillOperator.Set) {
                    if (blnConditionsMet && blnIncludeModelActions) {
                        let objOutModelTail: { arrModel: any[] } = { arrModel: [] };
                        let objOutLeft: { strValue: string } = { strValue: null };
                        let objOutRight: { strValue: string } = { strValue: null };
                        let blnDataModelUpdated: boolean = false;

                        if (!AutofillService.ResolveContextProperty(objModel, objAction.LEFTOPERAND, objOutLeft)) {
                            console.warn('AutofillService.EvalAction(): Action model path ' + objAction.LEFTOPERAND + ' could not be resolved.');
                            return 0;
                        }
                        if (!AutofillService.ResolveOperand(objModel, objAction.RIGHTOPERANDTYPE, objAction.RIGHTOPERAND, objOutRight)) {
                            console.warn('AutofillService.EvalAction(): Action operand ' + objAction.RIGHTOPERAND + ' could not be resolved.');
                            return 0;
                        }

                        //Skip "set" action unless the incoming value is different from the current value.
                        if (objOutLeft.strValue != objOutRight.strValue) {
                            let strTable = objAction.LEFTOPERAND_END.substr(0, objAction.LEFTOPERAND_END.indexOf('.'));
                            let strField = objAction.LEFTOPERAND_END.substr(1 + objAction.LEFTOPERAND_END.indexOf('.'));

                            if (strTable.indexOf('[') != -1)
                                strTable = strTable.substring(0, strTable.indexOf('['));

                            let arrLeft = objAction.LEFTOPERAND.split('.');
                            let strTablePath = arrLeft.slice(0, arrLeft.length - 1).join('.'); //Slice goes up to, but not including the 2nd index parameter
                            let objTable: any, objTableOut: { objValue: object } = { objValue: null };


                            if (AutofillService.ResolveContextPath(objModel, strTablePath, objTableOut))
                                objTable = objTableOut.objValue;
                            else
                                console.warn(`AutofillService.EvalAction(): could not evaluate model path fragment "${strTablePath}" in insert action ${objAction.ACTIONID}`);

                            objTarget = AutofillService.FindSibling(objControl, strTable, strField, objTable, true, true); //If target field is generic, like "ElementValue", then need table name match to ensure correct target is searched for (with potential fallback on modifying data model directly when target field is not on screen)

                            //Updating the data model and updating the UI are two separate things: the data model field being updated may not be onscreen
                            if (objTarget) { //TODO: optionally, to avoid backward move of focus, check if index of objTarget in Autofill.arrControls is behind index of objControl
                                if (objTarget.setCurrentValue(objOutRight.strValue)) { //Updates UI control and data model, returns false if new value is not set of options available for the field and freetext input is disabled
                                    blnDataModelUpdated = true;
                                    objOutModelTail.arrModel.push(objTarget.objModel);

                                    if (blnUserInitiated && objOutRight.strValue != RBISDataValue.Minus1) { //Do not advance focus if "set" action is executing on page load instead of on user value selection
                                        console.log(`AutofillService.EvalAction(): objAction.ACTIONID (${objAction.ACTIONID}):  "set" action targeted onscreen element, advancing focus from ${objTarget.id}`);
                                        console.log('arrAutoFillCondition =>', objControl.arrAutofillConditions);
                                        //These logic moved to OnChange to trigger the method once to find the next empty element from the current active element. Otherwise these method was called multiple times for all conditions and failed to find the empty control.
                                        //Ticket# 7062
                                        // objTarget.focusNextInput(true);
                                    }
                                }
                            }

                            if (!blnDataModelUpdated) //Update data model only as target field is not on page currently being viewed, or still has its set of options filtered to exclude the new value
                                blnDataModelUpdated = AutofillService.SetContextProperty(objModel, objAction.LEFTOPERAND, objOutRight.strValue, objOutModelTail);
                        }

                        if (blnDataModelUpdated) { //If model was updated, raise new event to let business rules cascade
                            let arrPropertyPathParts: string[] = objAction.LEFTOPERAND.split('.');
                            let strTable: string = arrPropertyPathParts[arrPropertyPathParts.length - 2];
                            let strField: string = arrPropertyPathParts[arrPropertyPathParts.length - 1];
                            let objSibling: UIElementBase;

                            for (let objModelUpdated of objOutModelTail.arrModel) {
                                objSibling = AutofillService.FindSibling(objControl, strTable, strField, objModelUpdated); //May return null if rule updated offscreen field.
                                intRuleWhereConditionsMet += AutofillService.OnChange(objSibling, objModelUpdated, strField, blnIncludeModelActions, blnUserInitiated);
                            }
                        }
                    }
                }
                else if (objAction.OPERATOR == AutoFillOperator.Insert) {
                    if (blnConditionsMet && blnIncludeModelActions) {
                        let arrLeft = objAction.LEFTOPERAND.split('.');
                        let strField = arrLeft[arrLeft.length - 1];
                        let strTable = arrLeft[arrLeft.length - 2];
                        let strParent = arrLeft[arrLeft.length - 3];
                        let strTablePath = arrLeft.slice(0, arrLeft.length - 1).join('.'); //Slice goes up to, but not including the 2nd index parameter
                        let strParentPath = arrLeft.slice(0, arrLeft.length - 2).join('.');
                        let objTable: any, objTableOut: { objValue: object } = { objValue: null };
                        let objParent: any, objParentOut: { objValue: object } = { objValue: null };
                        let strRightOperand: string, objRightOperandOut: { strValue: string } = { strValue: null };

                        if (AutofillService.ResolveContextPath(objModel, strParentPath, objParentOut))
                            objParent = objParentOut.objValue;
                        else
                            console.warn(`AutofillService.EvalAction(): could not evaluate model path fragment "${strParentPath}" in insert action ${objAction.ACTIONID}`);

                        //Entity Framework appends 1 when duplicate identifiers are encountered. So, when a suffix of 1 is encountered and the parent 
                        //object also has a property without the suffix, we assume that the navigation property's type name is without the suffix.
                        //Ex: Veh.Trailer (Number of Trailing Units) and Veh.Trailer1 (navigation property to Trailer table)
                        if (strTable.endsWith('1') && objParent[strTable.substr(0, strTable.length - 1)] != undefined)
                            strTable = strTable.substr(0, strTable.length - 1);

                        if (AutofillService.ResolveContextPath(objModel, strTablePath, objTableOut))
                            objTable = objTableOut.objValue;
                        else
                            console.warn(`AutofillService.EvalAction(): could not evaluate model path fragment "${strTablePath}" in insert action ${objAction.ACTIONID}`);


                        if (AutofillService.ResolveOperand(objModel, objAction.RIGHTOPERANDTYPE, objAction.RIGHTOPERAND, objRightOperandOut))
                            strRightOperand = objRightOperandOut.strValue;
                        else
                            console.warn(`AutofillService.EvalAction(): could not evaluate right operand  "${objAction.RIGHTOPERAND}" in insert action ${objAction.ACTIONID}`);

                        let objDuplicateCheck: { objValue: object } = { objValue: null };
                        let strIndex = strField + ' == ' + strRightOperand;

                        //Check if object to be inserted already exists
                        if (objTable != null && Array.isArray(objTable))
                            AutofillService.ResolveCollectionElement(objTable, strIndex, objDuplicateCheck);
                        else
                            objDuplicateCheck.objValue = objTable;

                        if (objDuplicateCheck.objValue == null) {
                            let objChild = AutofillService.AddModelObject(objParent, strParent, strTable); //Added child is bidirectionally linked to parent by AddModelObject()
                            objChild[strField] = strRightOperand;

                            objTarget = AutofillService.FindSibling(objControl, strTable, strField, objParent);

                            if (objTarget)
                                objTarget.setCurrentValue(strRightOperand); //Will raise OnChange 
                        }
                    }
                }
                else {
                    let strT = objAction.LEFTOPERAND_END.substr(0, objAction.LEFTOPERAND_END.indexOf('.'));
                    let strF = objAction.LEFTOPERAND_END.substr(1 + objAction.LEFTOPERAND_END.indexOf('.'));

                    if (strT.indexOf('[') != -1)
                        strT = strT.substring(0, strT.indexOf('['));

                    objTarget = AutofillService.FindSibling(objControl, strT, strF, objModel);

                    switch (objAction.OPERATOR) {
                        case AutoFillOperator.Clear:
                        case AutoFillOperator.Delete:
                            if (blnConditionsMet && blnIncludeModelActions) {
                                if (objTarget) {
                                    objTarget.clearComponent();//The DOM element(inner text and label needs to be cleared) and object value should set -1
                                }
                                else { //Target is not currently on screen, modifying the data model directly
                                    let objLeftOut: { objValue: any } = { objValue: undefined };
                                    //Clear or Delete action may target a field, object or array, i.e. tail of model path may resolve to a field, an object, or an array of objects
                                    let strTableOrParentName: string = objAction.LEFTOPERAND.lastIndexOf('.') != -1 ? objAction.LEFTOPERAND.substring(0, objAction.LEFTOPERAND.lastIndexOf('.')) : objAction.LEFTOPERAND;
                                    let strFieldOrTableName: string = objAction.LEFTOPERAND.substring(objAction.LEFTOPERAND.lastIndexOf('.') + 1);
                                    let blnResolveLeft: boolean = AutofillService.ResolveContextPath(objModel, objAction.LEFTOPERAND, objLeftOut);

                                    if (!blnResolveLeft)   //Leftoperand resolution may be based on rightoperand type (IVALUE/ATTRID)
                                        console.warn(`Could not resolve left side of action: ${objAction.LEFTOPERANDTYPE} ${objAction.LEFTOPERAND} (Action ID: ${objAction.ACTIONID})`);
                                    else {
                                        if (objLeftOut.objValue !== undefined && objLeftOut.objValue !== null) {
                                            if (Array.isArray(objLeftOut.objValue))
                                                (<Array<any>>objLeftOut.objValue).length = 0;
                                            else if (typeof (objLeftOut.objValue) == 'object') {
                                                if (objAction.OPERATOR == AutoFillOperator.Clear)
                                                    this.SetDefaultValues(objLeftOut.objValue, strFieldOrTableName);
                                                else if (objAction.OPERATOR == AutoFillOperator.Delete) {
                                                    let objOut: { arrModel: any[] } = { arrModel: [] };
                                                    let blnSuccess: boolean = AutofillService.SetContextProperty(objModel, objAction.LEFTOPERAND, null, objOut);
                                                }
                                            }
                                            else {
                                                AutofillService.ResolveContextPath(objModel, strTableOrParentName, objLeftOut); //At this point we know the model path pointed at a field, we need to backtrack one segment

                                                if (!this.SetDefaultValue(objLeftOut.objValue, strTableOrParentName, strFieldOrTableName))
                                                    console.warn(`Could not execute clear action on model path ${objAction.LEFTOPERAND} (Action ID: ${objAction.ACTIONID})`);
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case AutoFillOperator.Hide:
                            if (objTarget) {
                                objTarget.hideOrShowComponent(!blnConditionsMet); //Setting objTarget.blnVisible directly will not fire NgOnChange

                                if (document.activeElement == objTarget.objTextbox.nativeElement && blnConditionsMet)
                                    objTarget.focusNextInput();
                            }
                            break;
                        case AutoFillOperator.Show:
                            if (objTarget) {
                                objTarget.hideOrShowComponent(blnConditionsMet);
                                if (document.activeElement == objTarget.objTextbox.nativeElement && !blnConditionsMet)
                                    objTarget.focusNextInput();
                            }
                            break;
                        case AutoFillOperator.Disable:
                            if (objTarget) {
                                objTarget.disableOrEnableComponent(blnConditionsMet);
                            }
                            break;
                        case AutoFillOperator.Enable:
                            if (objTarget) {
                                objTarget.disableOrEnableComponent(!blnConditionsMet);
                            }
                            break;
                        default:
                            console.warn('AutofillService.EvalAction(): Unexpected action operator: ' + objAction.OPERATOR);
                            break;
                    }
                }
            }
        }
        catch (ex) {
            console.error('Error while evaluating rule action ' + objAction.ACTIONID + ': ' + ex.toString());
        }

        return intRuleWhereConditionsMet;
    }

    private static GetTableName(strPath: string): string {

        if (strPath && strPath != "") {
            if (strPath.indexOf('.') > -1) {
                strPath = this.GetTableName(strPath.substring(strPath.indexOf('.') + 1));
            }
            else if (strPath.indexOf('[') > -1) {
                strPath = this.GetTableName(strPath.substring(0, strPath.indexOf('[')));
            }
        }

        return strPath;
    }

    /**
     * Returns false if no default value metadata was found
     **/
    public static SetDefaultValue(objModel: any, strTableName: string, strFieldName): boolean {
        strTableName = AutofillService.GetTableName(strTableName);
        let dtNow: Date = new Date();
        let strDbTypeName: string;
        let objDefaultValue: any;
        let objDefault = AutofillService._arrColumnDefaults.find(i =>
            i.strTableName == strTableName &&
            i.strColumnName == strFieldName);

        if (objDefault && objDefault.strDefault != null) {
            let strDbTypeName = objDefault.strDataType;

            if (strDbTypeName == ColumnDataType.Integer || strDbTypeName == ColumnDataType.SmallInt || strDbTypeName == ColumnDataType.TinyInt || strDbTypeName == ColumnDataType.Decimal || strDbTypeName == ColumnDataType.Float) {
                if (objDefault.strDefault.toLowerCase().indexOf('year') != -1)
                    objDefaultValue = dtNow.getFullYear();
                else
                    objDefaultValue = parseFloat(objDefault.strDefault);
            }
            else if (strDbTypeName.indexOf('date') != -1)
                objDefaultValue = dtNow;
            else if (strDbTypeName == ColumnDataType.Bit)
                objDefaultValue = objDefault.strDefault == '1' ? true : false;
            else //strDbTypeName == 'varchar'
                objDefaultValue = objDefault.strDefault.replace(/'/g, '');

            objModel[objDefault.strColumnName] = objDefaultValue;
            return true;
        }

        return false;
    }

    public static GetMaxLength(strTableName: string, strFieldName): number {
        let objMeta: GetColumnDefaults_Result = AutofillService._arrColumnDefaults.find(i =>
            i.strTableName == strTableName &&
            i.strColumnName == strFieldName);

        if (objMeta)
            return objMeta.intMaxLength;
        else
            return null;
    }

    private static EvalRule(objControl: UIElementBase, objRule: Rule, objModel: any, blnIncludeModelActions: boolean, blnUserInitiated: boolean): number {
        if (AutofillService._blnVerbose) {
            console.debug('----------------------------------------------------------------------------------------------------');
            console.debug(`AutofillService: Evaluating rule ${objRule.RULEID}: ${objRule.DESCRIPTION}\r\n${objRule.FRIENDLY}`);
        }

        let blnConditionsMet: boolean;
        let intRuleWhereConditionsMet: number = 0;

        let arrAutoFillDebugBreakAt: number[] = [385];
        if (arrAutoFillDebugBreakAt.indexOf(objRule.RULEID) != -1)
            console.debug('AutofillService.EvalRule(): Debug helper break point at rule ' + objRule.RULEID.toString()); //Put breakpoint here to stop

        try {
            let arrModelContext: any[] = Array.isArray(objModel) ? objModel : [objModel]; //Most of the time Model Context will be a single object.

            for (let objModel of arrModelContext) {
                blnConditionsMet = AutofillService.EvaluateRuleConditionGroup(objModel, objRule.RuleConditionGroup);

                if (blnConditionsMet)
                    intRuleWhereConditionsMet++;

                for (let objAction of objRule.RuleAction)
                    intRuleWhereConditionsMet += AutofillService.EvalAction(blnConditionsMet, objControl, objModel, objAction, blnIncludeModelActions, blnUserInitiated); //We evaluate the action regardless of whether the conditions are met because when the conditions are not met we may still be doing something like enabling a previously disabled field.

                if (AutofillService._blnVerbose)
                    console.debug(`AutofillService: Condition Group ID ${objRule.RuleConditionGroup.CONDITIONGROUPID} ${objRule.RuleConditionGroup.OPERATOR}-ed conditions met: ${blnConditionsMet}`);
            }
        }
        catch (objError) {
            console.error(`Error while evaluating rule ${objRule.RULEID} (${objRule.FRIENDLY}): \r\n ${objError.message}`);
        }

        return intRuleWhereConditionsMet;
    }

    private static EvaluateRuleConditionGroup(objModel: any, objConditionGroup: RuleConditionGroup): boolean {
        let blnChildGroups: boolean = false;
        let blnChildConditions: boolean = false;
        let objChildGroup: RuleConditionGroup;
        let objChildCondition: RuleCondition;

        for (let i = 0; i < objConditionGroup.RuleConditionGrpChd.length; i++) {
            objChildGroup = objConditionGroup.RuleConditionGrpChd[i];
            blnChildGroups = AutofillService.EvaluateRuleConditionGroup(objModel, objChildGroup);

            if (objConditionGroup.OPERATOR == AutoFillOperator.And && !blnChildGroups)
                return false;

            if (objConditionGroup.OPERATOR == AutoFillOperator.Or && blnChildGroups)
                return true;
        }

        for (let i = 0; i < objConditionGroup.RuleCondition.length; i++) {
            objChildCondition = objConditionGroup.RuleCondition[i];
            blnChildConditions = AutofillService.EvaluateRuleCondition(objModel, objChildCondition);

            if (objConditionGroup.OPERATOR == AutoFillOperator.And && !blnChildConditions)
                return false;

            if (objConditionGroup.OPERATOR == AutoFillOperator.Or && blnChildConditions)
                return true;
        }

        let blnConditionsMet: boolean = ((objConditionGroup.RuleConditionGrpChd.length == 0) || (blnChildGroups)) && ((objConditionGroup.RuleCondition.length == 0) || (blnChildConditions));

        return blnConditionsMet;
    }

    private static EvaluateRuleCondition(objModel: any, objCondition: RuleCondition): boolean {
        let blnResult: boolean = false

        try {

            let arrAutoFillDebugBreakAt: number[] = [];
            if (arrAutoFillDebugBreakAt.indexOf(objCondition.CONDITIONID) != -1)
                console.debug('AutofillService.EvaluateRuleCondition(): Debug helper break point at rule condition ' + objCondition.CONDITIONID.toString()); //Put breakpoint here to stop

            if (objCondition.CONDITIONID == 967)
                console.log(objCondition);

            let strModelTypeName: string = ObjectUtil.GetTypeName(objModel);
            let objLeftOut: { strValue: string } = { strValue: undefined };
            let objRightOut: { strValue: string } = { strValue: undefined };
            let blnResolveLeft: boolean = AutofillService.ResolveOperand(objModel, objCondition.LEFTOPERANDTYPE, objCondition.LEFTOPERAND, objLeftOut);
            let blnResolveRight: boolean = AutofillService.ResolveOperand(objModel, objCondition.RIGHTOPERANDTYPE, objCondition.RIGHTOPERAND, objRightOut);

            if (!blnResolveLeft)
                console.warn(`Could not resolve left side of condition: ${objCondition.LEFTOPERANDTYPE} - ${objCondition.LEFTOPERAND} against model object ${strModelTypeName} (Rule Condition ID: ${objCondition.CONDITIONID})`);
            else if (!blnResolveRight)
                console.warn(`Could not resolve right side of condition: ${objCondition.RIGHTOPERANDTYPE} - ${objCondition.RIGHTOPERAND} against model object ${strModelTypeName} (Rule Condition ID: ${objCondition.CONDITIONID})`);
            else {
                let arrValues: string[];
                let blnOperandsNumeric: boolean = false
                let blnOperandsDate: boolean = false;
                let intLeft: number = 0, intRight: number = 0;
                let dtLeft: Date = new Date(0), dtRight: Date = new Date(0);
                let strLeft: string = objLeftOut.strValue;
                let strRight: string = objRightOut.strValue;

                if ((strLeft != null) && (strLeft.toLowerCase().trim() == 'null'))
                    strLeft = null;
                if ((strRight != null) && (strRight.toLowerCase().trim() == 'null'))
                    strRight = null;

                //We are treating null, 'null' and '' (the empty string) as equal
                //if ((strLeft == null) || (strLeft.toLowerCase().trim() == "null")) //Typescript has short-circuit evaluation
                //    strLeft = '';
                //if ((strRight == null) || (strRight.toLowerCase().trim() == "null"))
                //    strRight = '';

                intLeft = parseInt(strLeft);
                intRight = parseInt(strRight);
                blnOperandsNumeric = !isNaN(intLeft) && !isNaN(intRight);

                if (!blnOperandsNumeric) {
                    intLeft = Date.parse(strLeft);
                    intRight = Date.parse(strRight);
                    blnOperandsDate = !isNaN(intLeft) && !isNaN(intRight);

                    if (blnOperandsDate) {
                        dtLeft = new Date(intLeft);
                        dtRight = new Date(intRight);
                    }
                }

                switch (objCondition.OPERATOR) {
                    case "==":
                        if (strRight == '')
                            blnResult = (strLeft == '' || strLeft == null);
                        else
                            blnResult = strLeft == strRight;
                        break;
                    case "!=":
                        if (strRight == '')
                            blnResult = (strLeft != null && strLeft != '');
                        else
                            blnResult = strLeft != strRight;
                        break;
                    case ">=":
                        if (strLeft == null || strRight == null) //GTE, GT, LTE, LT comparison is false if either side is null
                            blnResult = false;
                        else if (blnOperandsNumeric)
                            blnResult = intLeft >= intRight;
                        else if (blnOperandsDate)
                            blnResult = dtLeft >= dtRight;
                        else
                            blnResult = strLeft >= strRight;
                        break;
                    case "<=":
                        if (strLeft == null || strRight == null)
                            blnResult = false;
                        else if (blnOperandsNumeric)
                            blnResult = intLeft <= intRight;
                        else if (blnOperandsDate)
                            blnResult = dtLeft <= dtRight;
                        else
                            blnResult = strLeft <= strRight;
                        break;
                    case ">":
                        if (strLeft == null || strRight == null)
                            blnResult = false;
                        else if (blnOperandsNumeric)
                            blnResult = intLeft > intRight;
                        else if (blnOperandsDate)
                            blnResult = dtLeft > dtRight;
                        else
                            blnResult = strLeft > strRight;
                        break;
                    case "<":
                        if (strLeft == null || strRight == null)
                            blnResult = false;
                        else if (blnOperandsNumeric)
                            blnResult = intLeft < intRight;
                        else if (blnOperandsDate)
                            blnResult = dtLeft < dtRight;
                        else
                            blnResult = strLeft < strRight;
                        break;
                    case "contains": blnResult = strLeft ? strLeft.indexOf(strRight) != -1 : false; //TODO: do we want this to be case sensitive?
                        break;
                    case "!contains": blnResult = strLeft ? strLeft.indexOf(strRight) == -1 : false;
                        break;
                    case "in":
                        arrValues = strRight.split(',');
                        blnResult = arrValues.indexOf(strLeft) != -1 || (!strLeft && arrValues.indexOf('null') != -1);
                        break;
                    case "!in":
                        arrValues = strRight.split(',');
                        blnResult = arrValues.indexOf(strLeft) == -1 || (!strLeft && arrValues.indexOf('null') == -1);
                        break;
                    default:
                        console.warn(`Rule condition has unknown operator ${objCondition.OPERATOR} (Rule Condition ID: ${objCondition.CONDITIONID})`);
                        break;
                }
            }


            if (AutofillService._blnVerbose)
                console.debug(`AutofillService:   (${blnResult}) Condition Group ID ${objCondition.CONDITIONGROUPID} Condition ${objCondition.CONDITIONID}: ${objCondition.LEFTOPERAND} ${objCondition.OPERATOR} ${objCondition.RIGHTOPERAND} => (${objLeftOut.strValue}) ${objCondition.OPERATOR} (${objRightOut.strValue})`);
        }
        catch (ex) {
            console.error('Error while evaluating rule condition ' + objCondition.CONDITIONID + ': ' + ex.toString());
            throw ex;
        }

        return blnResult;
    }

    private static FindSibling(objControl: UIElementBase, strTblName: string, strFldOrTbl: string, objModel: any,
        blnMustMatchTableName: boolean = false, blnMustMatchModelObject: boolean = false): UIElementBase {
        //TODO: Look for sibling in same visual container.
        let objSibling: UIElementBase;

        if (objModel != null)
            objSibling = AutofillService._arrControls.find(x =>
                //x != objControl && //Don't match self
                x.strTableName == strTblName && x.strFieldName == strFldOrTbl &&
                x.objModel == objModel);

        if (!blnMustMatchTableName && !objSibling && objModel != null)
            objSibling = AutofillService._arrControls.find(x =>
                x != objControl && //Don't match self
                x.strFieldName == strFldOrTbl &&
                x.objModel == objModel);

        if (!blnMustMatchModelObject) {
            if (!objSibling)
                objSibling = AutofillService._arrControls.find(
                    //x => x != objControl &&
                    x => x.strFieldName == strFldOrTbl && x.strTableName == strTblName);

            if (!blnMustMatchTableName && !objSibling)
                objSibling = AutofillService._arrControls.find(x => x != objControl && x.strFieldName == strFldOrTbl);

            if (!objSibling)
                objSibling = AutofillService._arrControls.find(x =>
                    x != objControl &&              //Don't match self
                    AutofillService.isMultiSelect(x) &&
                    x.strTableName == strFldOrTbl); //Clear actions may have model path operators that target an array, not a property
        }

        return objSibling;
    }

    /**
     * This method should not be called from async callbacks so that controls are registered in the order they appear in the HTML template
     * and not in the order they finish initialization.
     */
    public static RegisterControl(objControl: UIElementBase): void {
        let intIndex: number = AutofillService._arrControls.indexOf(objControl);

        if (intIndex == -1)
            AutofillService._arrControls.push(objControl);
        else
            console.warn(`AutofillService.RegisterControl(): Trying to register a control that is already registered: ${objControl.strTableName}.${objControl.strFieldName}`);
    }

    public static InitializeAutofillForControl(objControl: UIElementBase): void {
        if (!objControl.blnInitialized) {
            objControl.blnInitialized = true;

            if (AutofillService._arrAutofillConditions) {
                let strModelObjectTypeName: string = ObjectUtil.GetTypeName(objControl.objModel);

                if (strModelObjectTypeName == null)
                    strModelObjectTypeName = objControl.strTableName;

                let strOperandEnd: string = strModelObjectTypeName + '.' + objControl.strFieldName;

                objControl.arrAutofillConditions = AutofillService._arrAutofillConditions
                    .filter(objCondition =>
                        objCondition.LEFTOPERAND.startsWith(strModelObjectTypeName + '.') && //Cannot evaluate rule conditions when they have model path beginning that differs from current model object
                        objCondition.LEFTOPERAND_END == strOperandEnd);

                objControl.arrAutofillRules = AutofillService._arrAutofills
                    .filter(objRule => objRule.RuleAction
                        .filter(objAction =>
                            (objAction.LEFTOPERAND && objAction.LEFTOPERAND.startsWith(strModelObjectTypeName + '.') &&
                                objAction.LEFTOPERAND_END == strOperandEnd)
                            ||
                            (objAction.RIGHTOPERAND && objAction.RIGHTOPERAND.startsWith(strModelObjectTypeName + '.') &&
                                objAction.RIGHTOPERAND == strOperandEnd)
                        ).length > 0);

                if (AutofillService._blnVerbose)
                    console.debug(`AutofillService.InitializeAutofillForControl(): Field ${strOperandEnd} matched ${objControl.arrAutofillConditions.length} rule conditions and ${objControl.arrAutofillRules.length} rule actions.`);
            }
            else
                console.warn('AutofillService.InitializeAutofillForControl() called before AutofillService was initialized. Check that InitRouteGuard is being used properly.');
        }
        else
            console.warn(`AutofillService.InitializeAutofillForControl(): Trying to initialize a control that is already initialized: ${objControl.strTableName}.${objControl.strFieldName}`);
    }

    public static UnregisterControl(objControl: UIElementBase): void {
        let intIndex: number = AutofillService._arrControls.indexOf(objControl);

        if (intIndex != -1)
            AutofillService._arrControls.splice(intIndex, 1);
        else
            console.warn(`AutofillService.UnregisterControl(): Trying to unregister a control that was never registered: ${objControl.strTableName}.${objControl.strFieldName}`);
    }

    private static ResolveOperand(objModelContext: any, intOperandType: number, strOperand: string, objOut: { strValue: string }): boolean {
        objOut.strValue = null;

        if (intOperandType == RuleOperandType.Context)
            return AutofillService.ResolveContextProperty(objModelContext, strOperand, objOut);
        else if (intOperandType == RuleOperandType.Constant) {
            objOut.strValue = strOperand;
            return true;
        }
        else
            throw new Error('AutofillService.ResolveContextProperty(): Parameter "intOperandType" is out of range.');
    }

    public static ResolveClassMemProp(objModel: any, strMemProp: string, objMemberPropValue: { objValue: any }): boolean {
        let blnResolvedSuccessfully: boolean = false;
        let blnTargetCollectionElement: boolean = false;
        let strMemPropArrayIndex: string = null;
        objMemberPropValue.objValue = null;

        let strTypeName: string = ObjectUtil.GetTypeName(objModel);

        if (strMemProp.endsWith(']')) {
            blnTargetCollectionElement = true;
            strMemPropArrayIndex = strMemProp.substring(strMemProp.indexOf('[') + 1, strMemProp.length - 1);
            strMemProp = strMemProp.substring(0, strMemProp.indexOf('['));
        }

        if (strTypeName == strMemProp && !objModel.hasOwnProperty(strMemProp) && !blnTargetCollectionElement) { //If requested member is the same as own class name, then return self
            objMemberPropValue.objValue = objModel;
            blnResolvedSuccessfully = true;
        }
        else {
            if (strTypeName == strMemProp && !objModel.hasOwnProperty(strMemProp))
                objMemberPropValue.objValue = objModel;
            else
                objMemberPropValue.objValue = objModel[strMemProp];

            if (objMemberPropValue.objValue !== undefined)
                blnResolvedSuccessfully = true;
            else
                blnResolvedSuccessfully = false;

            if (blnResolvedSuccessfully && blnTargetCollectionElement) {
                let objCollection: Array<any> = objMemberPropValue.objValue as Array<any>;
                blnResolvedSuccessfully = AutofillService.ResolveCollectionElement(objCollection, strMemPropArrayIndex, objMemberPropValue);
            }
        }

        return blnResolvedSuccessfully;
    }

    public static ResolveCollectionElement(objCollection: Array<any>, strIndex: string, objTargetElement: { objValue: any }): boolean {
        let intMemPropArrayIndex: number = -1;
        objTargetElement.objValue = null;

        if (objCollection == null)
            throw new Error('objCollection');
        if (!strIndex && strIndex == '')
            throw new Error('strIndex');

        intMemPropArrayIndex = parseInt(strIndex);
        if (intMemPropArrayIndex > -1) {
            try {
                objTargetElement.objValue = objCollection[intMemPropArrayIndex];
                return true;
            }
            catch (Exception) {
                console.warn(`Could not resolve ${objCollection}[${strIndex}]: index out of range`);
                objTargetElement.objValue = null;
                return false;
            }
        }
        else //Dealing with lambda-like expression instead of numerical index
        {
            //TODO: add support for flat Boolean combined expression?
            let arrExpressionParts: string[] = strIndex.split(' ');
            let strLeft: string = arrExpressionParts[0];
            let strOprnd: string = arrExpressionParts[1];
            let strRight: string = arrExpressionParts[2];
            let objLeft: { objValue: any } = { objValue: undefined };

            for (let objElement of objCollection) {
                if (AutofillService.ResolveClassMemProp(objElement, strLeft, objLeft)) {
                    if (objLeft.objValue.toString() == strRight) {
                        objTargetElement.objValue = objElement;
                        return true;
                    }
                }
                else {
                    console.warn(`Invalid lookup expression: ${strIndex}. Class ${objElement} does not have member ${strLeft}`);
                    objTargetElement.objValue = null;
                    return false;
                }
            }
        }

        return true; //Lookup expression could be resolved successfully but no elements matched the expression's condition
    }

    public static ResolveContextProperty(objModel: any, strPath: string, objOut: { strValue: string }): boolean {
        objOut.strValue = null; //Implementing output parameter by wrapping value in object that will be passed by reference
        let objCursor: any = objModel;
        let objCursorNext: any;
        let objPropValue: { objValue: any } = { objValue: undefined };

        if (!strPath)
            throw new Error('AutofillService.ResolveContextProperty(): Parameter "strPath" must not be blank.');
        if (!objModel)
            throw new Error('AutofillService.ResolveContextProperty(): Parameter "objModel" must not be blank.');

        let arrPathParts: string[] = strPath.split('.');

        for (let strProp of arrPathParts) {
            if (Array.isArray(objCursor)) { //TODO: check for index into array
                let objFirst: any = objCursor.length > 0 ? objCursor[0] : null;

                if (objFirst != null) {
                    if (AutofillService.ResolveClassMemProp(objFirst, strProp, objPropValue))
                        objCursor = objPropValue.objValue;
                    else
                        return false;

                    if (!objCursor)
                        return false;
                }

                return false;
            }
            else {
                if (AutofillService.ResolveClassMemProp(objCursor, strProp, objPropValue))
                    objCursor = objPropValue.objValue;
                else
                    return false; //Path is incorrect, no such class member exists
            }

            if (objCursor == null) //Path evaluation was successful, but let to a null value so further evaluation is not possible
                break;
        }

        if (objCursor != null)
            objOut.strValue = objCursor.toString();

        return true;
    }

    /**
     * Starting from objModel, follows the passed in period-delimited and bracket-delimited model path
     * @param objModel Data model object to start from
     * @param strPath Data model path to follow
     * @param objOut Data model object or property that the path resolved to. That object or property may be null.
     * @returns Boolean indicator of whether the data model path could be followed successfully (even if it led to a null)
     */
    public static ResolveContextPath(objModel: any, strPath: string, objOut: { objValue: object }): boolean {
        objOut.objValue = null; //Implementing output parameter by wrapping value in object that will be passed by reference
        let objCursor: any = objModel;
        let objCursorNext: any;
        let objPropValue: { objValue: any } = { objValue: undefined };

        if (!strPath)
            throw new Error('AutofillService.ResolveContextProperty(): Parameter "strPath" must not be blank.');
        if (!objModel)
            throw new Error('AutofillService.ResolveContextProperty(): Parameter "objModel" must not be blank.');

        let arrPathParts: string[] = strPath.split('.');

        for (let strProp of arrPathParts) {
            if (Array.isArray(objCursor)) { //TODO: check for index into array
                let objFirst: any = objCursor.length > 0 ? objCursor[0] : null;

                if (objFirst != null) {
                    if (AutofillService.ResolveClassMemProp(objFirst, strProp, objPropValue))
                        objCursor = objPropValue.objValue;
                    else
                        return false;

                    if (!objCursor)
                        return false;
                }

                return false;
            }
            else {
                if (AutofillService.ResolveClassMemProp(objCursor, strProp, objPropValue))
                    objCursor = objPropValue.objValue;
                else
                    return false; //Path is incorrect, no such class member exists
            }

            if (objCursor == null) //Path evaluation was successful, but let to a null value so further evaluation is not possible
                break;
        }

        if (objCursor != null)
            objOut.objValue = objCursor;

        return true;
    }

    //Follows the given path into the given model object and assigns the given value to whatever the tail of the model path resolves to.
    //Returns true if the path into the model could be resolved, and false otherwise. Returns the modified fringe of the model as an output parameter.
    public static SetContextProperty(objModel: any, strPath: string, objValue: any, objOutModelTail: { arrModel: any[] }): boolean {
        if (objModel == null || !strPath || strPath == '')
            return false;

        let objCursor: any = objModel;
        let objOut: { objValue: any } = { objValue: null };
        let strPathParts: string[] = strPath.split('.');

        for (let i = 0; i < strPathParts.length - 1; i++) //Travel down passed in path
        {
            if (!AutofillService.ResolveClassMemProp(objCursor, strPathParts[i], objOut))
                return false;
            else
                objCursor = objOut.objValue;

            if (Array.isArray(objCursor)) //Iterate over enumerable portion of path
            {
                strPathParts.shift();
                let strNewPath: string = strPathParts.join(".");

                for (let objElement of objCursor)
                    AutofillService.SetContextProperty(objElement, strNewPath, objValue, objOutModelTail);

                objCursor = null;
            }
        }

        if (objCursor != null) {
            let strPropName: string = strPathParts.length > 0 ? strPathParts[strPathParts.length - 1] : null;

            if (strPropName) {
                objOutModelTail.arrModel.push(objCursor);

                let strDataType: string = AutofillService.GetDataType(objCursor, strPropName); //typeof operator cannot reflect on null values

                if (strDataType === PropertyType.Number)
                    objCursor[strPropName] = Number(objValue);
                else if (strDataType === PropertyType.Boolean)
                    objCursor[strPropName] = Boolean(Number(objValue));
                else if (AutofillService.IsDate(String(objValue)))
                    objCursor[strPropName] = new Date(Date.parse(objValue));
                else //string
                    objCursor[strPropName] = objValue;

                return true;
            }
        }

        return false;
    }

    private static IsNumeric(strValue: string): boolean {
        return !isNaN(parseInt(strValue));
    }

    /**
     * String must have some kind of delimiter between year, month and date, otherwise any numerical string will successfully be parsed by Date
     */
    private static IsDate(strValue: string): boolean {
        if (strValue.match(/[. /-]/))
            return !isNaN(Date.parse(strValue));
        else
            return false;
    }

    private static TraverseRuleConditionGroup(objRule: Rule, objConditionGroup: RuleConditionGroup): void {
        if (objConditionGroup.RuleCondition) {
            for (let objCondition of objConditionGroup.RuleCondition) {
                AutofillService._arrAutofillConditions.push(objCondition);
                objCondition.Rule = objRule; //ObjectUtil.RestoreBidirectionalLinks() handles bidirectional links between adjacent levels only, here we restore a grandparent-to-grandchild link (across potentially recursively nested condition groups)
            }
        }

        if (objConditionGroup.RuleConditionGrpChd)
            for (let objConditionGroupChild of objConditionGroup.RuleConditionGrpChd)
                AutofillService.TraverseRuleConditionGroup(objRule, objConditionGroupChild);
    }

    public static isMultiSelect(objCandidate: any): objCandidate is MultiselectComponent {
        if ('_TypeScript_TypeGuard_MultiselectComponent' in objCandidate)
            return true;

        return false;
    }
}
