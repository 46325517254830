import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
//rxjs

//services
import { CaseService } from '../../../../services/case.service';
import { UtilService } from '../../../../services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { AutofillService } from 'src/app/services/autofill.service';
//models
import { Acc } from '../../../../models/acc';
import { Veh } from '../../../../models/veh';
import { Occupants } from '../../../../models/occupants';
import { OccDrug } from '../../../../models/occ-drug';
//components
import { TypeaheadComponent } from '../../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
//helpers
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { DrugTestStatus, RBISDataValue } from 'src/app/models/enums/app.enums';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { Subscription } from 'rxjs';
import { DrpDownOptions } from 'src/app/models/drp-down-options';

@Component({
    selector: 'app-vehicle-person-drug',
    templateUrl: './vehicle-person-drug.component.html',
    styleUrls: ['./vehicle-person-drug.component.css']
})

export class VehiclePersonDrugComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_VehiclePersonDrugComponent: string = null;
    sbsClearForm: Subscription;

    accid: number;
    vehicleid: number;
    personid: number;

    acc: Acc;
    veh: Veh;
    person: Occupants;

    OccDrug: OccDrug;
    objVehClone: Veh;
    objPersonClone: Occupants;

    addResult: number;
    blnDisabled: boolean = false;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    UIControls: UIElementBase[];

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
                this._route.parent.parent.params.subscribe(params => {
                    this.vehicleid = + params['vehicleid'];
                    this._route.parent.params.subscribe(params => {
                        this.personid = + params['personid'];
                        this.initData();
                    });
                });
            });
        }
        else {
            this.vehicleid = this.printVehNum;
            this.personid = this.personNum;
            this.accid = this.acc.AccID;
            this.initData();
        }
    }

    initData() {
        this.addResult = null;

        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.person = this.veh.Occupants.find(o => o.VNumber == this.vehicleid && o.PNumber == this.personid);

        this.objPersonClone = ObjectUtil.CloneModelObject(this.veh.Occupants.find(o => o.VNumber == this.vehicleid && o.PNumber == this.personid));

        if ([DrugTestStatus.Blank, DrugTestStatus.TestNotGiven, DrugTestStatus.NotReported, DrugTestStatus.ReportedAsUnknownIfTested].includes(this.person.DrugSts)) {
            this.blnDisabled = true;
        }
        else {
            this.blnDisabled = false;
        }

        if ((!this.person.OccDrug) || (this.person.OccDrug.length == 0)) {
            this._autofillService.AddOccDrug(this.person);
        }
        console.log(this.person.DrugSts);

        this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe((() => {
            const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment;
            if (UrlTreeHelper.FindAndSetCurrentTab(urlSegments) === "personDrug") {
                if (this.acc) {
                    console.log(this.person.DrugSts);
                    this.person.DrugSts = -1;
                    let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == "DrugSts") as TypeaheadComponent;
                    let item: DrpDownOptions = uiControl.options.find(x => x.intValue == this.person.DrugSts);
                    uiControl.bindItemAndFocusNextElement(item);

                    this.removeEmptyOccDrug();
                }
            }

        }).bind(this));
    }

    // Using emiter for DrugSts to set grid elements result items enabled/disabled 
    async OnModelChange($event) {
        if (this.objPersonClone.DrugSts != this.person.DrugSts) {
            this.UIControls = this._autofillService.arrControls;
            let uiControls = this.UIControls.filter(i => i.strFieldName !== FieldName.MethDrug && i.strFieldName !== FieldName.DrugSts && i.strFieldName !== FieldName.DrugInv);

            if ([DrugTestStatus.Blank, DrugTestStatus.TestNotGiven, DrugTestStatus.NotReported, DrugTestStatus.ReportedAsUnknownIfTested].includes($event.intValue)) {
                this.blnDisabled = true;
                setTimeout((() => {
                    uiControls.forEach(x => x.RerenderModelValue(this.blnDisabled));
                }).bind(this), 1);
            }
            else {
                this.blnDisabled = false;
                setTimeout((() => {
                    uiControls.forEach(x => x.RerenderModelValue(this.blnDisabled));
                    if (this.person.DrugSts === 2) {
                        let item = this.UIControls.find(x => x.strFieldName == "DrugTst" && x.intSeqNum == 1);
                        if (item) {
                            item.objTextbox.nativeElement.click();
                            item.objTextbox.nativeElement.focus();
                        }
                    }
                }).bind(this), 1);
            }

            this.addResult = null;

            let blnPrompt: boolean;

            if (this.person.DrugSts === -1) {
                if (this.objPersonClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Clearing the Drug Test Status will clear all Drug Specimen/Drug Test Result for this person. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
                }
            }
            if (this.person.DrugSts === 0) {
                if (this.objPersonClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Changing the Drug Test Status to " + this.person.DrugSts + " will clear all Drug Specimen/Drug Test Result for this person and create only one row with TEST NOT GIVEN values. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
                }
            }
            if (this.person.DrugSts === 2) {
                if (this.objPersonClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Changing the Drug Test Status to " + this.person.DrugSts + " will clear all Drug Specimen/Drug Test Result for this person. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
                }
            }
            if (this.person.DrugSts === 8) {
                if (this.objPersonClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Changing the Drug Test Status to " + this.person.DrugSts + " will clear all Drug Specimen/Drug Test Result for this person and create only one row with NOT REPORTED values. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
                }
            }
            if (this.person.DrugSts === 9) {
                if (this.objPersonClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Changing the Drug Test Status to " + this.person.DrugSts + " will clear all Drug Specimen/Drug Test Result for this person and create only one row with REPORTED AS UNKNOWN IF TESTED values. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
                }
            }
        }
    }

    OnConformationPrompt(blnPrompt) {
        setTimeout((() => { // using timeout so that autofill can kick in
            if (!blnPrompt) { // dialogPromise No
                // when we want to revert back person we have to clone it from the parent node which is Veh and we have to specify what is parent in this case it is "Veh"
                this.person = ObjectUtil.CloneModelObject(this.objPersonClone, "Occupants");
                this.person.Veh = this.veh;

                //We are reattaching reverted clone to the main data model;
                let item = this.veh.Occupants.findIndex(p => p.VNumber == this.vehicleid && p.PNumber == this.personid);
                this.veh.Occupants.splice(item, 1, this.person);

                this.renderBackDrugSts(this.person.DrugSts);

                if (this.person.OccDrug.length > 1) {
                    setTimeout((() => {
                        this.RenumberArrayObjects();

                        setTimeout((() => {
                            this._autofillService.RerenderModelValues();
                        }).bind(this), 1);

                    }).bind(this), 1);
                }
            }
            else {// dialogPromise Yes             
                this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
            }

            this.removeEmptyOccDrug();
        }).bind(this), 1);
    }

    OnOccDrugModelChange(seqNum: number, strFieldName: string) {
        let UIControl = this._autofillService.arrControls.find(i => i.strFieldName === strFieldName && i.intSeqNum === seqNum);
        if (UIControl && UIControl.blnClearHighlighting) {
            UIControl.setIsInvalidClass(false);
        }
        setTimeout((() => { //We are cloning Clone from the original in order to pick up values of NonOccDrug array after autofil kick in;
            if (this.objPersonClone.DrugSts == this.person.DrugSts) {// Since OnOccDrugModelChange occure after autofill is triggered we need to ensure that clone is not updated. 
                this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
            }
        }).bind(this), 1);
    }

    renderBackDrugSts(intNum: number) {
        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == FieldName.DrugSts) as TypeaheadComponent;
        let item = typeahead.options.find(x => x.intValue == intNum);
        typeahead.bindItemAndFocusNextElement(item);
    }

    public async onBeforeSave() {
        if (this.person.DrugSts === 2) {
            if (!this.validateOccDrug()) {
                this.blnAlloweSave = false;
                return;
            }
            else {
                this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
                this.blnAlloweSave = true;
            }
        }
        else {
            this.objPersonClone = ObjectUtil.CloneModelObject(this.person);
            this.removeEmptyOccDrug();
            this.blnAlloweSave = true;
        }
    }

    validateOccDrug(): boolean {
        let blnIsValid: boolean = true;
        let UIControls = this._autofillService.arrControls.filter(i => i.strFieldName === FieldName.DrugTst || i.strFieldName === FieldName.DrugRes);
        if (UIControls) {
            UIControls.forEach(item => {
                if (item.objModel[item.strFieldName] === RBISDataValue.Blank && this.person.DrugSts !== RBISDataValue.Blank) {
                    item.setIsInvalidClass(true);
                    blnIsValid = false;
                    this._modalService.setMessage('Range Error: Invalid Entry', 'danger');
                }
            });
            this._autofillService.RerenderModelValues();
        }
        else {
            blnIsValid = true;
        }
        return blnIsValid
    }

    /**     
    * Removing NonOccDrug objects from an array where DrugTst AND DrugRes are blank(-1) and length > 1;
    **/
    removeEmptyOccDrug() {
        //let arrOccDrug = this.acc.Veh.find(v => v.AccID === this.person.AccID && v.VNumber === this.person.VNumber).Occupants.find(x => x.AccID === this.person.AccID && x.VNumber === this.person.VNumber && x.PNumber === this.person.PNumber).OccDrug;
        let arrOccDrug = this.veh.Occupants.find(x => x.AccID === this.person.AccID && x.VNumber === this.person.VNumber && x.PNumber === this.person.PNumber).OccDrug;
        if (arrOccDrug.length > 1) {
            arrOccDrug.forEach(item => {
                if (item.DrugTst === -1 && item.DrugRes === -1 && item.SeqNum !== 1) {
                    this.onDeleteRow(item.SeqNum);
                }
            });
        }
    }

    onAddResults(rowsNumber) {
        if (rowsNumber) {
            for (let i = 0; i < rowsNumber; i++) {
                this.OccDrug = {
                    AccID: this.person.AccID,
                    VNumber: this.person.VNumber,
                    PNumber: this.person.PNumber,
                    SeqNum: this.person.OccDrug.length + 1,
                    DrugTst: -1,
                    DrugRes: -1
                } as OccDrug;
                this.person.OccDrug.push(this.OccDrug);
            }

            let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                subInit.unsubscribe();
                let uiControl: TypeaheadComponent = AutofillService.arrControls.find(i => i.strFieldName !== FieldName.MethDrug
                    && i.strFieldName !== FieldName.DrugSts
                    && i.strFieldName !== FieldName.DrugInv
                    && i.objTextbox.nativeElement.value == "") as TypeaheadComponent;

                uiControl.objTextbox.nativeElement.click();
                uiControl.objTextbox.nativeElement.focus();
            }).bind(this));

            this.MarkFormAsDirty();
        }
        this.addResult = null;
    }

    onAddRow(intIndex: number): void {

        let objOccDrug = {} as OccDrug;
        objOccDrug._TypeScript_TypeGuard_OccDrug = null;
        objOccDrug.AccID = this.person.AccID;
        objOccDrug.VNumber = this.person.VNumber;
        objOccDrug.PNumber = this.person.PNumber;
        objOccDrug.SeqNum = intIndex + 1;

        objOccDrug.DrugTst = -1;
        objOccDrug.DrugRes = -1;

        for (let i = intIndex; i < this.person.OccDrug.length; i++)
            this.person.OccDrug[i].SeqNum++;

        this.person.OccDrug.push(objOccDrug);

        if (this.person.OccDrug.length > 1) {
            setTimeout((() => {
                //ngFor gets confused when an insertion happens in the middle of the data source and recreates the DOM
                //for the next row but does not reevaluate data bindings
                //Workaround is to insert at the end and then move into position after a trivial delay.
                this.MoveRow(this.person.OccDrug.length - 1, intIndex);
                setTimeout((() => {
                    this._autofillService.RerenderModelValues();
                    let objFirstCell: TypeaheadComponent = this._autofillService.arrControls.filter(i => i.strFieldName !== FieldName.MethDrug && i.strFieldName !== FieldName.DrugSts && i.intSeqNum === intIndex + 1)[0] as TypeaheadComponent;
                    objFirstCell.objTextbox.nativeElement.click();
                    objFirstCell.objTextbox.nativeElement.focus();
                }).bind(this), 1);

            }).bind(this), 1);
        }
        this.MarkFormAsDirty();
    }

    onDeleteRow(SeqNum: number) {
        this.person.OccDrug = this.person.OccDrug.filter(x => x.SeqNum !== SeqNum);
        this.RenumberArrayObjects(); //Removal could be from the end of the collection or from the middle of the
        //collection
        this.MarkFormAsDirty();
    }

    onClearRow(intIndex: number) {

        intIndex = intIndex + 1;
        let typeAheads = this._autofillService.arrControls.filter(i => i.intSeqNum == intIndex);

        if (typeAheads) {
            typeAheads.forEach(typ => {
                typ.clearComponent();
            })
        }
        this.MarkFormAsDirty();
    }

    private MoveRow(i: number, j: number): void {
        // ngFor is listening for inserts and deletions, the default tracker
        // will not see an inplace index swap as two operations
        let objRowToMove: OccDrug = this.person.OccDrug[i];
        this.person.OccDrug.splice(i, 1);
        this.person.OccDrug.splice(j, 0, objRowToMove);
        this.RenumberArrayObjects();
    }

    private RenumberArrayObjects() {
        for (let i = 0; i < this.person.OccDrug.length; i++) {
            this.person.OccDrug[i].SeqNum = i + 1;
        }
    }

    //ngAfterViewInit(): void {
    //    super.ngAfterViewInit();
    //}

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsClearForm) {
            this.sbsClearForm.unsubscribe();
        }
    }
}
