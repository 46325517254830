import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';

import { BaseComponent } from 'src/app/helper/basecomponent';

import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { Element_Specify } from 'src/app/models/element-specify';

@Component({
    selector: 'app-vehicle-vehicle-rollover',
    templateUrl: './vehicle-vehicle-rollover.component.html',
    styleUrls: ['./vehicle-vehicle-rollover.component.css']
})

export class VehicleVehicleRolloverComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit  {

    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;   

    objInstanceElementSpecify: Element_Specify;
    //public objRITypeemplate: VCF_MTSS;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _autofillService: AutofillService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    )
    {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        //if (this.veh.Veh_MTSS.EstDistFromTripToFR > 500) {
        //    this.blnAlloweSave = false;
        //    this._modalService.setMessage("500+ includes any measured distance above 500 meters", 'danger');
        //    let UIControl = this._autofillService.arrControls.find(i => i.strFieldName === 'EstDistFromTripToFR');
        //    if (UIControl) {
        //        UIControl.setIsInvalidClass(true);
        //        UIControl.objTextbox.nativeElement.select();
        //        UIControl.objTextbox.nativeElement.click();
        //        UIControl.objTextbox.nativeElement.focus();
        //    }
        //    return;
        //}
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) {
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);             
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
