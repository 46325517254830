import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, QueryList, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { AutofillService } from 'src/app/services/autofill.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { NgForm } from '@angular/forms';
import { ModalsDistanceCalculatorComponent } from 'src/app/components/modals/modals-distance-calculator/modals-distance-calculator.component';
import { Element_Specify } from 'src/app/models/element-specify';
import { ObjectUtil } from 'src/app/helper/objectUtil';

@Component({
    selector: 'app-vehicle-non-motorist',
    templateUrl: './vehicle-non-motorist.component.html',
    styleUrls: ['./vehicle-non-motorist.component.scss']
})
/** vehicleNonMotorist component*/
export class VehicleVehicleNonMotoristComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    stateNum: number;
    accid: number;
    vehicleid: number;
    intMode: number;

    acc: Acc;
    veh: Veh;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('#fvno') objForm: NgForm;
    objInstanceElementSpecify: Element_Specify;

    private sbsVehId: Subscription = null;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngOnInit() {
        if (this.printVehNum == 0) {
            this._route.parent.params.subscribe((params => {
                this.accid = + params['crashid'];

                if (this.sbsVehId)
                    this.sbsVehId.unsubscribe();

                this._route.parent.parent.params.subscribe((params => {
                    this.vehicleid = + params['vehicleid'];
                    this.initData();
                }).bind(this));
            }).bind(this));
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }

        //this._route.parent.params.subscribe((params => {
        //    this.accid = + params['crashid'];


        //}).bind(this));

        //this._route.parent.parent.params.subscribe((params => {
        //    this.vehicleid = + params['vehicleid'];
        //    this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        //}).bind(this));
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);    

    }

    async ngAfterViewInit(): Promise<void> {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    setDistanceValue(event) {
        let component;

        if (event.objName =='DtectnDistFrPed') {
            this.veh.Veh_SS.DtectnDistFrPed = event.result;
            component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.DtectnDistFrPed);
        }
        else if (event.objName == 'AccrcyDtectnDistFrPedEst') {
            this.veh.Veh_SS.AccrcyDtectnDistFrPedEst = event.result;
            component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.AccrcyDtectnDistFrPedEst);
        }

        if (component) {
            component.setCurrentValue(event.result);
            component.onBlur(event.result);
            this.MarkFormAsDirty();
        }
    }
}
