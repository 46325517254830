import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ViewEncapsulation, QueryList, ViewChildren, Renderer2, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DecimalPipe, DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';


import { EarlyNotify } from 'src/app/models/early-notify';
import { Acc } from 'src/app/models/acc';
import { SysRunNo } from 'src/app/models/sys-run-no';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { EarlyNotifyType, DBMode, States, ExcludeTimeValues, CustomNvpFilter, SFRVehicleSubTab, ActionCommand, VehType, OccupantType, NonOccupantType, UnitType, CaseStatus } from 'src/app/models/enums/app.enums';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';

import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

import { EnumAsStringPipe } from 'src/app/pipes/enum-as-string.pipe';
import { ReplaceTextPipe } from 'src/app/pipes/replaceText.pipe';
import { take } from 'rxjs/operators';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { CaseLockedViewModel } from 'src/app/interface/CaseLockedViewModel';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { Veh } from 'src/app/models/veh';
import { ActionButtonService } from 'src/app/services/action-button.service';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { Occupants } from 'src/app/models/occupants';
import { RbisUser } from 'src/app/models/rbis-user';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrashTypeComponent } from '../../crash/crash-type/crash-type.component';


@Component({
    selector: 'app-case-create',
    templateUrl: './case-create.component.html',
    styleUrls: ['./case-create.component.css'],
    providers: [
        DecimalPipe, DatePipe, EnumAsStringPipe, ReplaceTextPipe
    ],
    encapsulation: ViewEncapsulation.None
})

export class CaseCreateComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    //sysRunNum: SysRunNo;

    selectedYear: number;
    toYear: number;

    selectedMode: number;
    public strModeName: string = 'MOSS ';

    public selectedState: number;
    arrStates: DrpDownOptions[];
    ddoSelectedState: DrpDownOptions;
    stateString: string;
    stateNum: number;

    earlyNotification: EarlyNotify = {} as EarlyNotify;

    public objAcc: Acc

    dmySFRv: Array<number> = [];
    SFRv: Array<number> = [];

    dmySv: Array<number> = [];
    Sv: Array<number> = [];

    @ViewChild('createCase') f: NgForm;

    @ViewChild('txtNoOfStruckFirstResponderVeh') txtNoOfStruckFirstResponderVeh: TypeaheadComponent;
    @ViewChild('txtNoOfNonStruckFirstResponderVeh') txtNoOfNonStruckFirstResponderVeh: TypeaheadComponent;
    @ViewChild('txtNoOfStrikingVeh') txtNoOfStrikingVeh: TypeaheadComponent;
    @ViewChild('txtNoOfOtherVeh') txtNoOfOtherVeh: TypeaheadComponent;   

    //@ViewChildren('txtsfrv') txtsfrv: QueryList<ElementRef>;

    private subsSaveEralyNotify: Subscription;

    acc: Acc;
    enid: number;
    private objUser: RbisUser;

    isCaseInvalid: boolean = false;
    isInvalidSFRv: boolean = false
    isInvalidSv: boolean = false

    private modal: NgbModalRef;
    mossSCISiteList: any[];

    dmystrCommand: string
    dmymodalContent: any
    sciSiteID: any;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _autofillService: AutofillService,
        protected _caseService: CaseService,
        private _enService: EarlyNotificationService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        public _sharedDataService: SharedDataService,
        private _utilService: UtilService,
        private statesToStringPipe: EnumAsStringPipe,
        private replaceTextPipe: ReplaceTextPipe,
        private numberPipe: DecimalPipe,
        private datePipe: DatePipe,
        protected _urlTreeHelper: UrlTreeHelper,
        private renderer: Renderer2,
        private _authService: AuthService,
        private _actionButtonService: ActionButtonService,
        private _ngModalService: NgbModal
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
        this.blnFocusOnFirstField = false;
    }

    onSelectState(event) {
        if (event !== "0") {
            this.selectedState = event;

            if (this.selectedMode == DBMode.MOSS) {
                let objEarlyNotify: EarlyNotify = {} as EarlyNotify;
                objEarlyNotify.StateNum = this.selectedState;
            }

            this.ddoSelectedState = this.arrStates.find(i => i.strValue == event);

            this._sharedDataService.setDDOSelectedState(this.ddoSelectedState);
            this._sharedDataService.subjectSelectedState.next(this.ddoSelectedState);

            if (!this._sharedDataService.selectedState) {
                this._sharedDataService.setSelectedState(event);
            }

            this.stateString = States[this._sharedDataService.selectedState];

            //this.sysRunNum = await this._enService.GetSysRunNo(this.selectedState, this.selectedYear, this.selectedMode);

            //console.log(this.sysRunNum)
        }
    }

    async ngOnInit(): Promise<void> {
        let appSettings = await this._sharedDataService.GetAppSettings();

        this.selectedYear = appSettings.intYear;
        this.selectedMode = appSettings.intMode;
        this.toYear = appSettings.toYear;

        if (DBMode[appSettings.intMode])
            this.strModeName = DBMode[appSettings.intMode];

        this.objUser = await this._authService.GetUserPromise();

        this.mossSCISiteList = await this._authService.mossSCIsites;
        const index = this.mossSCISiteList.indexOf(0, 0);
        if (index > -1) {
            this.mossSCISiteList.splice(index, 1);
        }
        this.sciSiteID = this.mossSCISiteList[0];

        //if (this._sharedDataService.selectedState == undefined || this._sharedDataService.selectedState == 0) {
        this.clearFilterCooker()
        //}

        this.selectedState= this._sharedDataService.getSelectedState();
        // this one is added because sometime window:beforeunload wasn't firing. So everytime then
        this._utilService.GetDrpDownListOptions(LookupTable.StateNum, null)
            .subscribe((result: DrpDownOptions[]) => {
                this.arrStates = result.filter(x => x.intValue != 98 && x.intValue != 99);

                if (this.arrStates.length == 1) {
                    this.onSelectState(this.arrStates[0].intValue);
                }
            });


        if (this.selectedState) {
            this.ddoSelectedState = this._sharedDataService.getDDOSelectedState();
        }

        this.stateString = States[this._sharedDataService.selectedState];


        //Create new EarlyNotify
        //this.earlyNotification.StateNum = this.stateNum;
        //this.earlyNotification.CaseYear = this.selectedYear;
        //this.earlyNotification.NoofSFRv = 0;
        //this.earlyNotification.NoofNSFRv = 0;
        //this.earlyNotification.NoofOv = 0;
        //this.earlyNotification.DateReported = new Date(new Date().setFullYear(this.selectedYear));

        this.earlyNotification = {
                _TypeScript_TypeGuard_EarlyNotify: null,
                StateNum: this.stateNum,
                AccMonth: null,
                AccDay: null,
                CaseYear: this.selectedYear,
                AccMin: null,
                AccHr: null,
                NoOfFatals: 0,
                RptJur: null,
                StateCaseNum: null,
                PARNumber: null,
                Remarks: null,
                CrashType: null,
                IsStateOfficialCrash: false,
                DateReported: new Date(new Date().setFullYear(this.selectedYear)),
                MOSSCasenum: null,
                NoofSFRv : null,
                NoofNSFRv : null,
                NoofOv: null,
                SCISiteID: this.sciSiteID
            } as EarlyNotify;

    }

    ngAfterViewInit(): void {
        //this.txtsfrv.changes
        //    .subscribe(() => console.log(this.txtsfrv));

        //this.txtsfrv.forEach((skill: ElementRef) => {
        //    this.renderer.setElementStyle(skill.nativeElement, "background", "yellow");
        //});

        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this._enService.earlyNotification = null;

        this.clearFilterCooker();
    }
    
    clearFilterCooker() {
        this._sharedDataService.deleteCookie("cookieENFilters");
        this._sharedDataService.deleteCookie("cookieENPageState");
        this._sharedDataService.deleteCookie("cookieCaseSearchScreenerFilters");
        this._sharedDataService.deleteCookie("cookieCaseSearchScreenerPageState");

        this._sharedDataService.setSelectedState(undefined);
    }

    public async onBeforeSave(): Promise<void> {
        this.blnAlloweSave = true;
    }

    onchangeSFRV(event, i): void {
        this.SFRv[i] = event;
    }

    onchangeSv(event, i): void {
        this.Sv[i] = event;
    }

    MOSSCasenumPress(event) {
        //// Only Integer Numbers
        //var charCode = (event.which) ? event.which : event.keyCode;        
        //if ((charCode < 48 || charCode > 57)) {
        //    event.preventDefault();
        //    return false;
        //} else {
        //    return true;
        //}

        // Only AlphaNumeric
        var inp = String.fromCharCode(event.keyCode);
        if (/[a-zA-Z0-9]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    //KM
    dateTimeValidations(): string {
        let cDate = "";

        if (this.earlyNotification.AccMonth != null && this.earlyNotification.AccDay != null)
            cDate = this.earlyNotification.AccMonth.toString().padStart(2, '0') + "/" + this.earlyNotification.AccDay.toString().padStart(2, '0') + "/" + this.earlyNotification.CaseYear.toString();

        let error: string;
        let isValid: boolean = false;
        let isFuture: boolean = false;
        if (!ObjectUtil.usDate(cDate)) {
            isValid = true;
        }

        if (isValid && this.blnIsDateOfCrashInTheFuture()) {
            error = "Date of Crash cannot be in the future";
        }
        else if (isValid && this.blnIsTimeofCrashInTheFuture()) {
            error = "The Accident DateTime cannot be greater than or equal to the Reported Date Time";
        }

        if (this.earlyNotification.AccMonth == null || this.earlyNotification.AccDay == null || this.earlyNotification.AccMonth == -1 || this.earlyNotification.AccDay == -1)
            error = "Crash Date must have valid values";
        else if (this.earlyNotification.AccMonth <= 12 && (this.earlyNotification.AccDay > 31 && this.earlyNotification.AccDay != 99))
            error = "Crash Date must have valid values";
        else if ((this.earlyNotification.AccMonth == 99 && this.earlyNotification.AccDay == 99) || (this.earlyNotification.AccMonth <= 12 && this.earlyNotification.AccDay == 99) || (this.earlyNotification.AccMonth == 99 && this.earlyNotification.AccDay < 31))
            error = "";
        else if (this.earlyNotification.AccMonth > 0 && this.earlyNotification.AccDay > 0 && (!isValid))
            error = "Crash Date is not valid.";
        else if (((this.earlyNotification.AccHr < 0 || this.earlyNotification.AccHr == null) && this.earlyNotification.AccMin > 0) || (this.earlyNotification.AccHr > 0 && (this.earlyNotification.AccMin < 0 || this.earlyNotification.AccMin == null)))
            error = "Crash Time must have valid values.";

        return error;
    }

    blnIsDateOfCrashInTheFuture(): boolean {

        let blnHourUnknown: boolean = this.earlyNotification.AccHr == ExcludeTimeValues.Hour;
        let blnMinUnknown: boolean = this.earlyNotification.AccMin == ExcludeTimeValues.Minute;

        let dateIsFutureCrashDate = new Date(
            parseInt(this.selectedYear.toString()),
            parseInt(this.earlyNotification.AccMonth.toString()) - 1,
            parseInt(this.earlyNotification.AccDay.toString()),
            (this.earlyNotification.AccHr && !blnHourUnknown) ? parseInt(this.earlyNotification.AccHr.toString()) : 0,
            (this.earlyNotification.AccMin && !blnMinUnknown) ? parseInt(this.earlyNotification.AccMin.toString()) : 0,
            0, 0);

        let tDay = new Date(new Date().setFullYear(this.selectedYear));

        dateIsFutureCrashDate.setHours(0, 0, 0, 0);
        tDay.setHours(0, 0, 0, 0);


        return dateIsFutureCrashDate > tDay ? true : false;
    }

    blnIsTimeofCrashInTheFuture(): boolean {

        let blnHourUnknown: boolean = this.earlyNotification.AccHr == ExcludeTimeValues.Hour;
        let blnMinUnknown: boolean = this.earlyNotification.AccMin == ExcludeTimeValues.Minute;

        let dateIsFutureCrashTime = new Date(
            parseInt(this.selectedYear.toString()),
            parseInt(this.earlyNotification.AccMonth.toString()) - 1,
            parseInt(this.earlyNotification.AccDay.toString()),
            (this.earlyNotification.AccHr && !blnHourUnknown) ? parseInt(this.earlyNotification.AccHr.toString()) : 0,
            (this.earlyNotification.AccMin && !blnMinUnknown) ? parseInt(this.earlyNotification.AccMin.toString()) : 0,
            0, 0);

        const intFutureCrashTime = dateIsFutureCrashTime.getTime();
        const intDateReportedTime = new Date(this.earlyNotification.DateReported).getTime();

        return intFutureCrashTime >= intDateReportedTime ? true : false;
    }

    isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

    async onModalSubmit() {
        this.isCaseInvalid = false;
        this.isInvalidSFRv = false;
        this.isInvalidSv = false;

        //Check is there any blank

        let xValue = this.earlyNotification.NoofFirstResponderSFRv

        if (typeof xValue === 'undefined') {
            this.isCaseInvalid = true;
            let inputctl = document.getElementById('NoofFSFRv')
            inputctl.focus();
            return;
        }

        if (String(xValue) === '') {
            this.isCaseInvalid = true;
            let inputctl = document.getElementById('NoofFSFRv')
            inputctl.focus();
            return;
        }

        if (this.earlyNotification.NoofSFRv > 0) {
            //Clear border color
            for (let v = 0; v < this.earlyNotification.NoofSFRv; v++) {
                let inputctl = document.getElementById('SFRV' + v)
                inputctl.style.borderColor = ""
            }

            for (let v = 0; v < this.earlyNotification.NoofSFRv; v++) {               
                if (this.SFRv[v] == undefined) {                    

                    this.isCaseInvalid = true;
                    this.isInvalidSFRv = true;

                    let inputctl = document.getElementById('SFRV' + v)
                    inputctl.style.borderColor = "red"
                    
                    inputctl.blur();
                    inputctl.click();
                    inputctl.focus();

                    return;
                }
            }
        }
        if (this.earlyNotification.NoofSv > 0) {
            //Clear border color
            for (let v = 0; v < this.earlyNotification.NoofSv; v++) {
                let inputctl = document.getElementById('Sv' + v)
                inputctl.style.borderColor = ""
            }
            for (let v = 0; v < this.earlyNotification.NoofSv; v++) {
                if (this.Sv[v] == undefined) {

                    this.isCaseInvalid = true;
                    this.isInvalidSv = true;

                    let inputctl = document.getElementById('Sv' + v)
                    inputctl.style.borderColor = "red"

                    inputctl.blur();
                    inputctl.click();
                    inputctl.focus();

                    return;
                }
            }
        }

        this.modal.close();

        await this.processSubmit(this.dmystrCommand,this.dmymodalContent)               
    }

    async processSubmit(strCommand: string, modalContent: any): Promise<EarlyNotify> {

        return new Promise<EarlyNotify>(((resolve, reject) => {
            try {

                this.earlyNotification.Mode = this.selectedMode;
                this.earlyNotification.StateNum = this.selectedState //States[this.stateString];
                this.earlyNotification.NoofSFRv = this.txtNoOfStruckFirstResponderVeh.objCurrentValue;
                this.earlyNotification.NoofNSFRv = this.txtNoOfNonStruckFirstResponderVeh.objCurrentValue;
                this.earlyNotification.NoofSv = this.txtNoOfStrikingVeh.objCurrentValue;
                this.earlyNotification.NoofOv = this.txtNoOfOtherVeh.objCurrentValue;

                this.earlyNotification.CrashType = 1; // Make case status open
                this.earlyNotification.Status = CaseStatus.Open;

                this.dmySFRv = [];
                this.dmySv = [];
                for (let i = 0; i < this.txtNoOfStruckFirstResponderVeh.objCurrentValue; i++) {
                    this.dmySFRv.push(i + 1)
                }
                for (let i = 0; i < this.txtNoOfStrikingVeh.objCurrentValue; i++) {
                    this.dmySv.push(i + 1)
                }                
                
                //debugger

                //this._modalService_CannedPopups.caseStructureOption(modalContent).pipe(
                //    take(1)
                //).subscribe(result => {

                //    if (result == true) {
                        this.earlyNotification.NoofFirstResponderSFRv = this.earlyNotification.NoofFirstResponderSFRv || 0; //if null NoofFirstResponderSFRv is now 0
                        //this.earlyNotification.NoofFirstResponderNSFRv = this.earlyNotification.NoofFirstResponderNSFRv || 0; //if null NoofFirstResponderNSFRv is now 0

                        this.subsSaveEralyNotify = this._caseService.SaveEarlyNotification(this.earlyNotification).subscribe(
                            async objEarlyNotify => {

                                resolve(objEarlyNotify);

                                if (strCommand == 'save') {
                                    if (!this.earlyNotification.Acc) {

                                        let objAcc = this._autofillService.AddAcc(objEarlyNotify);

                                        //objAcc.AccMon = this.earlyNotification.AccMonth;
                                        //objAcc.AccDay = this.earlyNotification.AccDay;
                                        //objAcc.CaseYear = this.earlyNotification.CaseYear;
                                        //objAcc.AccHr = this.earlyNotification.AccHr;
                                        //objAcc.AccMin = this.earlyNotification.AccMin;

                                        let date: Date = new Date();
                                        objAcc.AccMon = UnitType.Blank;
                                        objAcc.AccDay = UnitType.Blank;
                                        objAcc.CaseYear = date.getFullYear();
                                        objAcc.AccHr = UnitType.Blank;
                                        objAcc.AccMin = UnitType.Blank;

                                        objAcc.CaseStats.CrUserID = this.objUser.UserId;
                                        objAcc.CaseStats.CrDateTime = new Date();

                                        if (!objAcc.Acc_SS) {
                                            AutofillService.AddModelObject(objAcc, 'Acc', 'Acc_SS');
                                        }
                                        objAcc.Acc_SS.MOSSCasenum = this.earlyNotification.MOSSCasenum;

                                        let nonOccupantTotal = 0;

                                        //Non-Occupant - Number of Struck First Responder Vehicles
                                        for (let n = 0; n < this.earlyNotification.NoofFirstResponderSFRv; n++) {
                                            let objNonOcc: Non_Occupants = this._autofillService.AddNonOccupant(objAcc);

                                            //objAcc.Non_Occupants[nonOccupantTotal].PType = NonOccupantType.FirstResponderStruck

                                            nonOccupantTotal++;
                                            objNonOcc.Added = true;
                                        }

                                        //Non-Occupant - Number of Non-Struck First Responder Vehicles
                                        //for (let n = 0; n < this.earlyNotification.NoofFirstResponderNSFRv; n++) {
                                        //    let objNonOcc: Non_Occupants = this._autofillService.AddNonOccupant(objAcc);

                                        //    objAcc.Non_Occupants[nonOccupantTotal].PType = NonOccupantType.FirstResponderNonStruck

                                        //    nonOccupantTotal++;
                                        //    objNonOcc.Added = true;
                                        //}

                                        let IgnoreDefaultVehicle: boolean = false

                                        let vehicleTotal = 0;
                                        let SSVehNumber;

                                        //Vehicle - Number of Struck First Responder Vehicles
                                        SSVehNumber = 0;
                                        if (this.earlyNotification.NoofSFRv == 0) {
                                            AutofillService.OnChange(null, objAcc.Veh[vehicleTotal], 'Occupants', true, true, false);
                                        }
                                        else {
                                            for (let v = 0; v < this.earlyNotification.NoofSFRv; v++) {
                                                SSVehNumber++;
                                                if (IgnoreDefaultVehicle) {
                                                    let objVeh: Veh = this._autofillService.AddVehicle(objAcc, -1, -1, SSVehNumber);
                                                    objVeh.Added = true;
                                                }

                                                objAcc.Veh[vehicleTotal].VehType = VehType.SFRVehicle
                                                //objAcc.Veh[vehicleTotal].UnitType = UnitType.NotInTransport_OffTrafficway
                                                objAcc.Veh[vehicleTotal].NumOccs = this.SFRv[v]

                                                //Occupants
                                                if (objAcc.Veh[vehicleTotal].NumOccs == 0) {
                                                     AutofillService.OnChange(null, objAcc.Veh[vehicleTotal], 'Occupants', true, true, false);
                                                }
                                                else {
                                                    for (let o = 0; o < this.SFRv[v]; o++) {
                                                        if (!objAcc.Veh[v].Dri)
                                                            this._autofillService.AddDriver(objAcc.Veh[v])

                                                        let objOcc: Occupants = this._autofillService.AddOccupant(objAcc.Veh[v]);

                                                        //objAcc.Veh[vehicleTotal].Occupants[o].PType = OccupantType.Struck

                                                        objOcc.Added = true;
                                                    }
                                                }

                                                vehicleTotal++;
                                                IgnoreDefaultVehicle = true
                                            }
                                        }

                                        //Vehicle - Number of Striking Vehicles
                                        SSVehNumber = 0;
                                        if (this.earlyNotification.NoofSv == 0) {
                                            AutofillService.OnChange(null, objAcc.Veh[vehicleTotal], 'Occupants', true, true, false);
                                        }
                                        else {
                                            for (let v = 0; v < this.earlyNotification.NoofSv; v++) {
                                                SSVehNumber++;
                                                if (IgnoreDefaultVehicle) {
                                                    let objVeh: Veh = this._autofillService.AddVehicle(objAcc, -1, -1, SSVehNumber);
                                                    objVeh.Added = true;
                                                }

                                                objAcc.Veh[vehicleTotal].VehType = VehType.StrikingVehicle
                                                //objAcc.Veh[vehicleTotal].UnitType = UnitType.InTransport
                                                objAcc.Veh[vehicleTotal].NumOccs = this.Sv[v]

                                                console.log(objAcc.Veh[vehicleTotal].NumOccs);

                                                //Precrash
                                                if (objAcc.Veh[vehicleTotal].PreCrash == null) {
                                                    this._autofillService.AddPreCrash(objAcc.Veh[vehicleTotal]);
                                                }
                                                if (!objAcc.Veh[vehicleTotal].PreCrash.PreCrash_MTSS) {
                                                    AutofillService.AddModelObject(objAcc, 'PreCrash', 'PreCrash_MTSS');
                                                }
                                                if (objAcc.Veh[vehicleTotal].PreCrash.PreCrash_MTSS == null) {
                                                    this._autofillService.AddPreCrashMTSS(objAcc.Veh[vehicleTotal]);
                                                }

                                                //Occupants
                                                if (objAcc.Veh[vehicleTotal].NumOccs == 0) {
                                                    AutofillService.OnChange(null, objAcc.Veh[vehicleTotal], 'Occupants', true, true, false);
                                                }
                                                else {
                                                    for (let o = 0; o < this.Sv[v]; o++) {
                                                        if (!objAcc.Veh[vehicleTotal].Dri)
                                                            this._autofillService.AddDriver(objAcc.Veh[vehicleTotal])

                                                        let objOcc: Occupants = this._autofillService.AddOccupant(objAcc.Veh[vehicleTotal]);

                                                        //objAcc.Veh[vehicleTotal].Occupants[o].PType = OccupantType.Striking

                                                        objOcc.Added = true;
                                                    }
                                                }

                                                vehicleTotal++;
                                                IgnoreDefaultVehicle = true
                                            }
                                        }


                                        //Vehicle - Number of Other Vehicles
                                        SSVehNumber = 0;
                                        for (let v = 0; v < this.earlyNotification.NoofOv; v++) {
                                            SSVehNumber++;
                                            if (IgnoreDefaultVehicle) {
                                                let objVeh: Veh = this._autofillService.AddVehicle(objAcc, -1, -1, SSVehNumber);
                                                objVeh.Added = true;
                                            }

                                            //objAcc.Veh[vehicleTotal].Veh_SS[vehicleTotal].SSVNumber = SSVehNumber++;
                                            objAcc.Veh[vehicleTotal].VehType = VehType.OtherVehicle
                                            //objAcc.Veh[vehicleTotal].UnitType = UnitType.NotInTransport_OffTrafficway

                                            vehicleTotal++;
                                            IgnoreDefaultVehicle = true
                                        }

                                        console.log(objAcc);

                                        this._caseService.SaveCase(objAcc, false)
                                            .then(async (objSavedAcc: Acc) => {

                                                this.objAcc = objSavedAcc;

                                                this._caseService.acc = objAcc;

                                                this._modalService.setMessage('Case created successfully', 'info');
                                                this._router.navigate([this.objAcc.StateNum, 'case', this.objAcc.AccID, 'crash', this.objAcc.AccID]);

                                                this.clearFilterCooker();
                                            },
                                                (objError: any) => {
                                                    this._modalService.setMessage('Failed to save case: ' + objError.message, 'error');
                                                }
                                            );
                                    }
                                }
                            }
                        );

                    //}
                    //else if (result == false) {
                    //    //this.earlyNotification.NoofSFRv = 0;
                    //    console.log('user clicked button Cancel');
                    //    return;
                    //}
                //})
            } catch (ex) {
                reject(ex);
            }
        }).bind(this));
    }

    public async onSubmit(event: any, strCommand: string, modalContent: any): Promise<EarlyNotify> {

        let errMsg: string = '';

        if (this.sciSiteID == 0 || this.sciSiteID == undefined || this.sciSiteID == null) {
            this._modalService.setMessage('You do not have permission to create cases in any MOSS SCI sites. Please contact Help Desk to request the necessary access level.', 'danger');
            return;
        }

        if (States[this.stateString] == undefined) {
            this._modalService.setMessage('Please select State', 'danger');
            return;
        }
        
        if (this.earlyNotification.MOSSCasenum == undefined || this.isEmptyOrSpaces(this.earlyNotification.MOSSCasenum)) {
            this._modalService.setMessage('Please enter MOSS Case Number', 'danger');
            return;
        }

        this.earlyNotification.MOSSCasenum = this.earlyNotification.MOSSCasenum.toUpperCase();
        let numberPrefix = [];
        for (let startYear = this.selectedYear; startYear <= this.toYear; startYear++) {
            numberPrefix.push(startYear.toString().substr(2, 4));
        }
        errMsg = await this._caseService.IsValidateMOSSCaseNumber(this.earlyNotification.MOSSCasenum.toString(), true, this.sciSiteID, numberPrefix.join('|'));
        if (errMsg != "") {
            this._modalService.setMessage(errMsg, 'error');
            return;
        }

        if (this.earlyNotification.NoofSFRv == undefined) {
            this._modalService.setMessage('Please enter Number of Struck Subject Vehicles', 'danger');
            return;
        }

        if (this.earlyNotification.NoofNSFRv == undefined) {
            this._modalService.setMessage('Please enter Number of Non-Struck Subject Vehicles', 'danger');
            return;
        }

        if (this.txtNoOfStrikingVeh.objCurrentValue == undefined || this.txtNoOfStrikingVeh.objCurrentValue < 1) {
            this._modalService.setMessage('Please enter number of Striking Vehicle', 'danger');
            return;
        }

        if (this.earlyNotification.NoofOv == undefined) {
            this._modalService.setMessage('Please enter Number of Other Contacted Vehicles', 'danger');
            return;
        }

        //

        this.earlyNotification.Mode = this.selectedMode;
        this.earlyNotification.StateNum = States[this.stateString];
        this.earlyNotification.NoofSFRv = this.txtNoOfStruckFirstResponderVeh.objCurrentValue;
        this.earlyNotification.NoofNSFRv = this.txtNoOfNonStruckFirstResponderVeh.objCurrentValue;
        this.earlyNotification.NoofSv = this.txtNoOfStrikingVeh.objCurrentValue;
        this.earlyNotification.NoofOv = this.txtNoOfOtherVeh.objCurrentValue;
        this.earlyNotification.SCISiteID = this.sciSiteID;

        this.dmySFRv = [];
        this.dmySv = [];
        for (let i = 0; i < this.txtNoOfStruckFirstResponderVeh.objCurrentValue; i++) {
            this.dmySFRv.push(i + 1)
        }
        for (let i = 0; i < this.txtNoOfStrikingVeh.objCurrentValue; i++) {
            this.dmySv.push(i + 1)
        }

        this.modal = this._ngModalService.open(modalContent, { backdrop: 'static', size: 'lg', centered: true });

        let inputctl = document.getElementById('NoofFSFRv')
        inputctl.click();
        inputctl.focus();

        this.dmystrCommand = strCommand
        this.dmymodalContent = modalContent       

        ////Need to change and comment out code due to requirement of the task 11600
        //return new Promise<EarlyNotify>(((resolve, reject) => {
        //    try {

        //        //if (States[this.stateString] == undefined) {
        //        //    this._modalService.setMessage('Please select State', 'danger');
        //        //    return;
        //        //}

        //        ////KM: Date validations                
        //        ////let error = "";
        //        ////error = this.dateTimeValidations();
        //        ////if (error != "" && error != undefined) {
        //        ////    this._modalService.setMessage(error, 'danger');
        //        ////    return;
        //        ////}

        //        //if (this.earlyNotification.MOSSCasenum == undefined || this.isEmptyOrSpaces(this.earlyNotification.MOSSCasenum)) {
        //        //    this._modalService.setMessage('Please enter MOSS Case Number', 'danger');
        //        //    return;
        //        //}


        //        //this.earlyNotification.MOSSCasenum = this.earlyNotification.MOSSCasenum.toUpperCase();

        //        ////let blnIsValidMossNum: boolean
        //        ////blnIsValidMossNum = new RegExp(/^(MCR|MDS)(20|21)[0-9]{3}/).test(this.earlyNotification.MOSSCasenum);
        //        ////console.log(blnIsValidMossNum)

        //        ////if (!blnIsValidMossNum) {
        //        ////    this._modalService.setMessage('Please enter valid MOSS Case Number. Ex: MCR20001, MDS21001', 'danger');
        //        ////    return;
        //        ////}

        //        //if (this.earlyNotification.NoofSFRv == undefined) {
        //        //    this._modalService.setMessage('Please enter Number of Struck Subject Vehicles', 'danger');
        //        //    return;
        //        //}

        //        //if (this.earlyNotification.NoofNSFRv == undefined) {
        //        //    this._modalService.setMessage('Please enter Number of Non-Struck Subject Vehicles', 'danger');
        //        //    return;
        //        //}

        //        //if (this.txtNoOfStrikingVeh.objCurrentValue == undefined || this.txtNoOfStrikingVeh.objCurrentValue < 1) {
        //        //    this._modalService.setMessage('Please enter number of Striking Vehicle', 'danger');
        //        //    return;
        //        //}

        //        //if (this.earlyNotification.NoofOv == undefined) {
        //        //    this._modalService.setMessage('Please enter Number of Other Contacted Vehicles', 'danger');
        //        //    return;
        //        //}

        //        ////if (this.earlyNotification.NoOfFatals < 1) {
        //        ////    this._modalService.setMessage('Please enter at least one Number of Fatalities', 'danger');
        //        ////    return;
        //        ////}

        //        this.earlyNotification.Mode = this.selectedMode;
        //        this.earlyNotification.StateNum = States[this.stateString];
        //        this.earlyNotification.NoofSFRv = this.txtNoOfStruckFirstResponderVeh.objCurrentValue;
        //        this.earlyNotification.NoofNSFRv = this.txtNoOfNonStruckFirstResponderVeh.objCurrentValue;
        //        this.earlyNotification.NoofSv = this.txtNoOfStrikingVeh.objCurrentValue;
        //        this.earlyNotification.NoofOv = this.txtNoOfOtherVeh.objCurrentValue;

        //        this.dmySFRv = [];
        //        this.dmySv = [];
        //        for (let i = 0; i < this.txtNoOfStruckFirstResponderVeh.objCurrentValue; i++) {
        //            this.dmySFRv.push(i + 1)
        //        }
        //        for (let i = 0; i < this.txtNoOfStrikingVeh.objCurrentValue; i++) {
        //            this.dmySv.push(i + 1)
        //        }

        //        //{ ariaLabelledBy: 'modal-basic-title', backdropClass: 'light-blue-backdrop', backdrop: 'static', keyboard: false }
        //        this.modal = this._ngModalService.open(modalContent, {backdrop: 'static', size: 'lg', centered: true});

        //        console.log(this.modal)

        //        return //test

        //        this._modalService_CannedPopups.caseStructureOption(modalContent).pipe(
        //            take(1)
        //        ).subscribe(result => {
                    
        //            return

        //            if (result == true) {
        //                this.earlyNotification.NoofFirstResponderSFRv = this.earlyNotification.NoofFirstResponderSFRv || 0; //if null NoofFirstResponderSFRv is now 0
        //                this.earlyNotification.NoofFirstResponderNSFRv = this.earlyNotification.NoofFirstResponderNSFRv || 0; //if null NoofFirstResponderNSFRv is now 0

        //                this.subsSaveEralyNotify = this._caseService.SaveEarlyNotification(this.earlyNotification).subscribe(
        //                    async objEarlyNotify => {

        //                        resolve(objEarlyNotify);

        //                        if (strCommand == 'save') {
        //                            if (!this.earlyNotification.Acc) {

        //                                let objAcc = this._autofillService.AddAcc(objEarlyNotify);

        //                                //objAcc.AccMon = this.earlyNotification.AccMonth;
        //                                //objAcc.AccDay = this.earlyNotification.AccDay;
        //                                //objAcc.CaseYear = this.earlyNotification.CaseYear;
        //                                //objAcc.AccHr = this.earlyNotification.AccHr;
        //                                //objAcc.AccMin = this.earlyNotification.AccMin;

        //                                let date: Date = new Date();
        //                                objAcc.AccMon = -1;
        //                                objAcc.AccDay = -1;
        //                                objAcc.CaseYear = date.getFullYear();
        //                                objAcc.AccHr = -1;
        //                                objAcc.AccMin = -1;
        //                                objAcc.CaseStats.CrUserID = this.objUser.UserId;
        //                                objAcc.CaseStats.CrDateTime = new Date();

        //                                if (!objAcc.Acc_SS) {
        //                                    AutofillService.AddModelObject(objAcc, 'Acc', 'Acc_SS');
        //                                }
        //                                objAcc.Acc_SS.MOSSCasenum = this.earlyNotification.MOSSCasenum;

        //                                let nonOccupantTotal = 0;

        //                                //Non-Occupant - Number of Struck Subject Vehicles
        //                                for (let n = 0; n < this.earlyNotification.NoofFirstResponderSFRv; n++) {
        //                                    let objNonOcc: Non_Occupants = this._autofillService.AddNonOccupant(objAcc);

        //                                    objAcc.Non_Occupants[nonOccupantTotal].PType = NonOccupantType.FirstResponderStruck

        //                                    nonOccupantTotal++;
        //                                    objNonOcc.Added = true;
        //                                }

        //                                //Non-Occupant - Number of Non-Struck Subject Vehicles
        //                                //for (let n = 0; n < this.earlyNotification.NoofFirstResponderNSFRv; n++) {
        //                                //    let objNonOcc: Non_Occupants = this._autofillService.AddNonOccupant(objAcc);

        //                                //    objAcc.Non_Occupants[nonOccupantTotal].PType = NonOccupantType.FirstResponderNonStruck

        //                                //    nonOccupantTotal++;
        //                                //    objNonOcc.Added = true;
        //                                //}

        //                                let IgnoreDefaultVehicle: boolean = false

        //                                let vehicleTotal = 0;
        //                                let SSVehNumber;

        //                                //Vehicle - Number of Struck Subject Vehicles
        //                                SSVehNumber = 0;
        //                                for (let v = 0; v < this.earlyNotification.NoofSFRv; v++) {
        //                                    SSVehNumber++;
        //                                    if (IgnoreDefaultVehicle) {
        //                                        let objVeh: Veh = this._autofillService.AddVehicle(objAcc, -1, -1, SSVehNumber);
        //                                        objVeh.Added = true;
        //                                    }

        //                                    objAcc.Veh[vehicleTotal].VehType = VehType.SFRVehicle
        //                                    //objAcc.Veh[vehicleTotal].UnitType = UnitType.NotInTransport_OffTrafficway
        //                                    objAcc.Veh[vehicleTotal].NumOccs = this.SFRv[v]

        //                                    //Occupants
        //                                    for (let o = 0; o < this.SFRv[v]; o++) {
        //                                        if (!objAcc.Veh[v].Dri)
        //                                            this._autofillService.AddDriver(objAcc.Veh[v])

        //                                        let objOcc: Occupants = this._autofillService.AddOccupant(objAcc.Veh[v]);

        //                                        //objAcc.Veh[vehicleTotal].Occupants[o].PType = OccupantType.Struck

        //                                        objOcc.Added = true;
        //                                    }

        //                                    vehicleTotal++;
        //                                    IgnoreDefaultVehicle = true
        //                                }

        //                                //Vehicle - Number of Striking Vehicles
        //                                SSVehNumber = 0;
        //                                for (let v = 0; v < this.earlyNotification.NoofSv; v++) {
        //                                    SSVehNumber++;
        //                                    if (IgnoreDefaultVehicle) {
        //                                        let objVeh: Veh = this._autofillService.AddVehicle(objAcc, -1, -1, SSVehNumber);
        //                                        objVeh.Added = true;
        //                                    }

        //                                    objAcc.Veh[vehicleTotal].VehType = VehType.StrikingVehicle
        //                                    //objAcc.Veh[vehicleTotal].UnitType = UnitType.InTransport
        //                                    objAcc.Veh[vehicleTotal].NumOccs = this.Sv[v]

        //                                    //Precrash
        //                                    if (objAcc.Veh[vehicleTotal].PreCrash == null) {
        //                                        this._autofillService.AddPreCrash(objAcc.Veh[vehicleTotal]);
        //                                    }
        //                                    if (!objAcc.Veh[vehicleTotal].PreCrash.PreCrash_MTSS) {
        //                                        AutofillService.AddModelObject(objAcc, 'PreCrash', 'PreCrash_MTSS');
        //                                    }
        //                                    if (objAcc.Veh[vehicleTotal].PreCrash.PreCrash_MTSS == null) {
        //                                        this._autofillService.AddPreCrashMTSS(objAcc.Veh[vehicleTotal]);
        //                                    }

        //                                    //Occupants
        //                                    for (let o = 0; o < this.Sv[v]; o++) {
        //                                        if (!objAcc.Veh[vehicleTotal].Dri)
        //                                            this._autofillService.AddDriver(objAcc.Veh[vehicleTotal])

        //                                        let objOcc: Occupants = this._autofillService.AddOccupant(objAcc.Veh[vehicleTotal]);

        //                                        //objAcc.Veh[vehicleTotal].Occupants[o].PType = OccupantType.Striking

        //                                        objOcc.Added = true;
        //                                    }

        //                                    vehicleTotal++;
        //                                    IgnoreDefaultVehicle = true
        //                                }

        //                                //Vehicle - Number of Other Vehicles
        //                                SSVehNumber = 0;
        //                                for (let v = 0; v < this.earlyNotification.NoofOv; v++) {
        //                                    SSVehNumber++;
        //                                    if (IgnoreDefaultVehicle) {
        //                                        let objVeh: Veh = this._autofillService.AddVehicle(objAcc, -1, -1, SSVehNumber);
        //                                        objVeh.Added = true;
        //                                    }

        //                                    //objAcc.Veh[vehicleTotal].Veh_SS[vehicleTotal].SSVNumber = SSVehNumber++;
        //                                    objAcc.Veh[vehicleTotal].VehType = VehType.OtherVehicle
        //                                    //objAcc.Veh[vehicleTotal].UnitType = UnitType.NotInTransport_OffTrafficway

        //                                    vehicleTotal++;
        //                                    IgnoreDefaultVehicle = true
        //                                }

        //                                console.log(objAcc);

        //                                this._caseService.SaveCase(objAcc, false)
        //                                    .then(async (objSavedAcc: Acc) => {

        //                                        this.objAcc = objSavedAcc;

        //                                        this._caseService.acc = objAcc;

        //                                        this._modalService.setMessage('Case created successfully', 'info');
        //                                        this._router.navigate([this.objAcc.StateNum, 'case', this.objAcc.AccID, 'crash', this.objAcc.AccID]);

        //                                        this.clearFilterCooker();
        //                                    },
        //                                        (objError: any) => {
        //                                            this._modalService.setMessage('Failed to save case: ' + objError.message, 'error');
        //                                        }
        //                                    );
        //                            }
        //                        }
        //                    }
        //                );

        //            }
        //            else if (result == false) {
        //                //this.earlyNotification.NoofSFRv = 0;
        //                console.log('user clicked button Cancel');
        //                return;
        //            }
        //        })
        //    } catch (ex) {
        //        reject(ex);
        //    }
        //}).bind(this));
    }

    onCancel() {
        this.isCaseInvalid = false;
        this.isInvalidSFRv = false;
        this.isInvalidSv = false;

        this.earlyNotification.NoofFirstResponderSFRv = null;
        //this.earlyNotification.NoofFirstResponderNSFRv = null;

        this.dmySFRv=[];
        this.SFRv =[];

        this.dmySv=[];
        this.Sv=[];
    }

    onCancelENCase(): void {
        if (this.earlyNotification.EarlyNotify_Locked != null) {
            this.earlyNotification.EarlyNotify_Locked = null;
            this._enService.ReleaseLock(this.earlyNotification.ENID, false);
        }

        let lastUrl = this._sharedDataService.getLastCaseUrl();

        if (lastUrl) {
            this._router.navigateByUrl(lastUrl);
        }
        else {
            this._router.navigate(['/']);
        }
    }

    // TODO: need to check following code

    public checkUnsavedChangesEarlyNotify(objEvent: BeforeUnloadEvent): void {
        if (this.f.dirty) {
            objEvent.preventDefault(); // Preventing close event will cause some browsers, including Chrome, to show alert-like modal popup.
            objEvent.returnValue = 'Unsaved Changes'; // Chrome requires returnValue to be set
            this._modalService.dialog('Please specify how to proceed.', 'Warning: Unsaved changes', 'YesNo', 'lg', true, false, "Save Changes", "Discard Changes and Close Case", null, false).subscribe(
                ((blnSaveUnsavedChanges: boolean) => {
                    if (blnSaveUnsavedChanges) {
                        this._enService.SaveEarlyNotification(this.earlyNotification).toPromise().then(() => {
                            //Chrome will not let window be closed from a callback
                            this._modalService.setMessage('Changes saved. Browser window or tab may be closed or reloaded now.', 'success');
                            this.f.form.markAsPristine();
                        })
                    }
                    else {
                        this._sharedDataService.setEarlyNotify(null);
                        this.releaseEarlyNotification();
                    }
                }).bind(this)
            );
        } else {
            objEvent.preventDefault(); // Preventing close event will cause some browsers, including Chrome, to show alert-like modal popup.
            objEvent.returnValue = 'Unsaved Changes'; // Chrome requires returnValue to be set
            this.releaseEarlyNotification();
        }
        console.log('event', objEvent)
    }

    releaseEarlyNotification() {
        const earlyNotification = this._sharedDataService.getEarlyNotify();
        if (earlyNotification != null && earlyNotification != undefined) {
            this._caseService.ReleaseLockedCases([{
                AccID: -1,
                EnID: earlyNotification.ENID,
                AppID: "35"
            }] as CaseLockedViewModel[]).then(result => {
                this._sharedDataService.setEarlyNotify(null);
            })
        }
    }


}
