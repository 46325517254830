import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
//services
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { CaseService } from 'src/app/services/case.service';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
//models
import { Acc } from 'src/app/models/acc';
import { EarlyNotify } from 'src/app/models/early-notify';
import { DeletedCases } from 'src/app/models/deleted-cases';
import { __await } from 'tslib';
import { AutofillService } from 'src/app/services/autofill.service';
import { DBMode, CaseStatus } from 'src/app/models/enums/app.enums';

export class ENTypeHelper {
    public enTypeId: number;
    public enType: string;
    public selected: boolean;
}

@Component({
    selector: 'app-case-delete',
    templateUrl: './case-delete.component.html',
    styleUrls: ['./case-delete.component.css']
})
export class CaseDeleteComponent implements OnInit {

    stateNum: number;

    enTypeList: Array<ENTypeHelper> = new Array<ENTypeHelper>();
    selectedEnTypeId: number = 33;
    officialStateReportedCrash: boolean = true;
    /**
     * This page will set Acc.Deleted and may or may not set EarlyNotify.Deleted as well.
     * If Early Notify Crash Type is being set to a delete reason, then we're deleting the EarlyNotify row too.
     **/
    public blnDeleteEN: boolean = false;
    deletionReason: string = '';
    private sbsCaseId: Subscription;
    acc: Acc = null;
    earlyNotification: EarlyNotify;
    caseID: number;
    caseNum: number;
    ENID: Number;
    intMode: number;
    caseNumber: string;

    constructor(private _utilService: UtilService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _modalService: ModalService,
        private _caseService: CaseService,
        private _enService: EarlyNotificationService,
        public _sharedDataService: SharedDataService,
    ) {
    }


    public ngOnInit(): void {

        this._route.params.subscribe((params => {
            this.stateNum = +params['stateNum'];
           
        }).bind(this));       

        this.sbsCaseId = this._route.params.subscribe(((objParams: Params) => { //If the parameter in the route changes, we load the case for the new parameter value
            this.caseID = +objParams.caseid;

            this._caseService.GetCasePromise(this.caseID).then((objAcc => {
                this.acc = objAcc;
                this.earlyNotification = objAcc.EarlyNotify[0];
                this.caseNum = this.earlyNotification.MDECaseNum;
            }).bind(this));
        }).bind(this));
    }

    addReasonForDeletion(event) {
        this.deletionReason = event.target.value.trim();
    }

  

    async deleteCase() {
        if (this.intMode == DBMode.MOSS)
            this.caseNumber = this.acc.Acc_SS.MOSSCasenum;
        else
            this.caseNumber = this.acc.Casenum.toString();

        if (this.deletionReason == '') {
            this._modalService.dialog('Please enter a reason to delete the case' , 'Warning', 'M').pipe(take(1)).subscribe(okCliked => { });
        }
         else {
            this._modalService.dialog('Are you sure you want to delete the case ' + this.caseNumber + '?', 'Warning').pipe(take(1)).subscribe((async okCliked => {
                if (okCliked) {
                    this.acc.Deleted = true;
                    this.acc.DeleteDate = new Date();
                    this.acc.DeleteReason = this.deletionReason;
                    this.acc.DeletedCases = AutofillService.AddModelObject(this.acc, 'Acc', 'DeletedCases');
                    
                    this.earlyNotification.Deleted = true; //Keeping link between dbo.EarlyNotify and dbo.Acc
                    this.earlyNotification.Status = CaseStatus.Deleted;
                                        
                    await this._caseService.SaveCase(this.acc); //Does not save parent EarlyNotify row
                    await this._enService.SaveEarlyNotification(this.earlyNotification).toPromise(); //Does not drill down into EarlyNotify row
                    this._caseService.hideENNum.next(true); 
                    this.backToPreviousPage();
                }
            }).bind(this));
        }
    }    

    backToPreviousPage() {
       window.history.back();
    }

    async ngAfterViewInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = appSettings.intMode;
    }
}
