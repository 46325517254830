import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from 'src/app/services/case.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { UtilService } from 'src/app/services/util.service';
import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { PreCrash } from 'src/app/models/pre-crash';
import { PreCrash_DriVisObs } from 'src/app/models/pre-crash-dri-vis-obs';
import { PreCrash_DriAvoidManvr } from 'src/app/models/pre-crash-dri-avoid-manvr';
import { PreCrash_DriDistract } from 'src/app/models/pre-crash-dri-distract';
import { Element_Specify } from 'src/app/models/element-specify';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { FormName, TableId } from 'src/app/models/enums/app.enums';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { PreCrash_ContribCirc } from 'src/app/models/pre-crash-contrib-circ';

import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { AutofillService } from 'src/app/services/autofill.service';
import { RRF_MTSS } from 'src/app/models/rrf-mtss';
import { OEF_MTSS } from 'src/app/models/oef-mtss';
import { TFIF_MTSS } from 'src/app/models/tfif-mtss';
import { WRF_MTSS } from 'src/app/models/wrf-mtss';
import { NgForm } from '@angular/forms';
import { PreFHEMnvr_SS } from 'src/app/models/PreFHEMnvr_SS';
import { SuplTrfcCtrl_SS } from 'src/app/models/SuplTrfcCtrl_SS';


@Component({
    selector: 'app-striking-vehicle-precrash-other',
    templateUrl: './striking-vehicle-precrash-other.component.html',
    styleUrls: ['./striking-vehicle-precrash-other.component.css']
})

export class StrikingVehiclePrecrashOtherComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    preCrash: PreCrash;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('#fvpo') objForm: NgForm;

    public objRoadwayRFTemplate: RRF_MTSS;
    public objTrafficFlowIFTemplate: TFIF_MTSS;
    public objSupplementalTrafficControlTemplate: SuplTrfcCtrl_SS;

    objInstanceElementSpecify: Element_Specify;

    public objPreCrashPreFHEMnvrTemplate: PreFHEMnvr_SS;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _vehService: VehicleService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngOnInit() {
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });

            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        }
        else {
            this.vehicleid = this.printVehNum;
            this.accid = this.acc.AccID;
            this.initData();
        }
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        if (this.printVehNum > 0 && this.veh.PreCrash == undefined || this.veh.PreCrash == null) {
            AutofillService.AddModelObject(this.veh, 'Veh', 'PreCrash');
        }

        if (this.veh && this.veh.PreCrash) {
            this.preCrash = this.veh.PreCrash;
        }
        
        if (this.printVehNum > 0 && this.veh.PreCrash.PreCrash_MTSS == undefined || this.veh.PreCrash.PreCrash_MTSS == null) {
            AutofillService.AddModelObject(this.preCrash, 'PreCrash', 'PreCrash_MTSS');
        }

        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.preCrash.VNumber, 0);

        this.objRoadwayRFTemplate = ObjectUtil.InstantiateRoadwayRF(this.veh);
        if (!this.veh.RRF_MTSS)
            this.veh.RRF_MTSS = new Array<RRF_MTSS>();

        this.objTrafficFlowIFTemplate = ObjectUtil.InstantiateTrafficFlowIF(this.veh);
        if (!this.veh.TFIF_MTSS)
            this.veh.TFIF_MTSS = new Array<TFIF_MTSS>();

        this.objPreCrashPreFHEMnvrTemplate = ObjectUtil.InstantiatePreFHEMnvr_SS(this.veh);
        if (!this.veh.PreFHEMnvr_SS)
            this.veh.PreFHEMnvr_SS = new Array<PreFHEMnvr_SS>();
                
        this.objSupplementalTrafficControlTemplate = ObjectUtil.InstantiateSuplTrfcCtrl_SS(this.veh);
        if (!this.veh.TFIF_MTSS)
            this.veh.SuplTrfcCtrl_SS = new Array<SuplTrfcCtrl_SS>();

        this.intMode = this.acc.Mode;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    setDistanceValue(event) {

        event = String(event);
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.ShoulderWidth);
        //if (component) {
        //    component.setCurrentValue(String(event));
        //    component.onBlur(event);
        //    console.log(this.objForm);
        //    this.objForm.form.markAsDirty();
        //}
    }
}
