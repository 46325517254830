import { CommonModule } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateMDEParserFormatter } from './helper/ngb-date-mdeparser-formatter'
import './helper/extensionmethods';
import { AppRoutingModule } from './app-routing.module'; //Wraps @angular/router
import { AppComponent } from './app.component';
import { ApiInterceptor } from 'src/app/interceptor/api-interceptor';

//Apps services
import { AuthService } from 'src/app/services/auth.service';
import { GenericService } from 'src/app/services/generic.service';
import { LoadingFormService } from 'src/app/services/loading-form.service';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';

//Apps modules
import { CaseModule } from './ui/cases/case.module';

//Layout components
import { NavMenuComponent } from './ui/nav-menu/nav-menu.component';
import { FooterComponent } from './ui/footer/footer.component';
import { UserComponent } from './ui/user/user.component';

//Navigation components
import { HomeComponent } from './ui/toolbar/home/home.component';

import { BatchProcessingComponent } from './ui/toolbar/batch-processing/batch-processing.component';
import { ParRequestsComponent } from './ui/toolbar/par-requests/par-requests.component';
import { HelpComponent } from './ui/toolbar/help/help.component';
import { PreferencesComponent } from './ui/toolbar/preferences/preferences.component';

import { LoadingFormComponent } from './components/loading-form/loading-form.component';

import { SearchCaseComponent } from './ui/search-case/search-case.component';
import { KeysPipe } from './pipes/keys-pipe.pipe';

import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { GenericmessageComponent } from 'src/app/components/genericmessage/genericmessage.component';
import { GeolocatorComponent } from 'src/app/components/geolocator/geolocator.component';
import { CrashTypeComponent } from 'src/app/ui/crash/crash-type/crash-type.component';

import { CrasheventServices } from './ui/crash/crash-events/services/crashevents.service';
import { GeolocatorService } from './services/geolocator.service';
import { ReleaserecordComponent } from './ui/releaserecord/releaserecord.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { AirbagchildsafetyComponent } from './components/airbagchildsafety/airbagchildsafety.component';
import { AvoindaceNotificationComponent } from './components/avoindace-notification/avoindace-notification.component';
import { VinrequestComponent } from './components/vinrequest/vinrequest.component';

import { VinrequestService } from './services/vinrequest.service';
import { EditcheckMessageComponent } from './components/editcheck-message/editcheck-message.component';
import { PedcopydataComponent } from './components/pedcopydata/pedcopydata.component';
import { BikecopydataComponent } from './components/bikecopydata/bikecopydata.component';
import { CaseService } from './services/case.service';
import { DragDropDirective } from './directives/drag-drop.directive';
import { CaseDocumentsComponent } from './ui/crss/case-documents/case-documents.component';
import { DeleteCrssCaseComponent } from './components/delete-crss-case/delete-crss-case.component';
import { SlowRequestLoggingInterceptor } from './interceptor/slow-request-logging-interceptor';
import { BadRequestLoggingInterceptor } from './interceptor/bad-request-logging-interceptor';
import { CacheLocalStorageService } from './services/cache-local-storage.service';
import { HttpLocalStorageService } from './services/http-local-storage.service';
import { rbisStore } from './rbis-store';
import { UnAuthorizedComponent } from './ui/un-authorized/un-authorized.component';
import { AttributeInfoModalComponent } from './components/attribute-info-modal/attribute-info-modal.component';
@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        UserComponent,
        FooterComponent,
        HomeComponent,
        BatchProcessingComponent,
        ParRequestsComponent,
        HelpComponent,
        PreferencesComponent,
        LoadingFormComponent,
        SearchCaseComponent,
        KeysPipe,
        DialogComponent,
        GenericmessageComponent,
        CrashTypeComponent,
        GeolocatorComponent,
        ReleaserecordComponent,
        MessageBoxComponent,
        AirbagchildsafetyComponent,
        AvoindaceNotificationComponent,
        VinrequestComponent,
        EditcheckMessageComponent,
        PedcopydataComponent,
        BikecopydataComponent,
        DragDropDirective,
        CaseDocumentsComponent,
        DeleteCrssCaseComponent,
        UnAuthorizedComponent,
        AttributeInfoModalComponent
    ],
    imports: [
        BrowserModule, //.withServerTransition({ appId: 'ng-cli-universal' }),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        CaseModule,
        CommonModule,
        BrowserAnimationsModule,
        ReactiveFormsModule
    ],
    providers: [
        AuthService,
        CaseService,
        GenericService,
        UtilService,
        ModalService,
        ModalService_CannedPopups,
        CrasheventServices,
        GeolocatorService,
        LoadingFormService,
        VinrequestService,
        { provide: HTTP_INTERCEPTORS, useClass: SlowRequestLoggingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BadRequestLoggingInterceptor, multi: true }, //Interceptor order matters: we want the error logging interceptor to see the error first as it does not modify the error reponsnse and only logs it.
        // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        SharedDataService,
        DecimalPipe,
        { provide: NgbDateParserFormatter, useClass: NgbDateMDEParserFormatter },
        CacheLocalStorageService,
        HttpLocalStorageService,
        rbisStore
    ],
    entryComponents: [
        DialogComponent,
        GenericmessageComponent,
        CrashTypeComponent,
        GeolocatorComponent,
        MessageBoxComponent,
        AirbagchildsafetyComponent,
        AvoindaceNotificationComponent,
        VinrequestComponent,
        PedcopydataComponent,
        BikecopydataComponent,
        DeleteCrssCaseComponent,
        AttributeInfoModalComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
