import { Component, EventEmitter, OnInit, Output, OnDestroy, ComponentFactoryResolver, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Event, Params, UrlSegmentGroup, UrlSegment, UrlTree, PRIMARY_OUTLET, ActivatedRouteSnapshot } from '@angular/router';
import { DecimalPipe } from '@angular/common';

import { Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { CaseService } from '../../services/case.service';
import { NonOccupantService } from '../../services/non-occupant.service';
import { VehicleService } from '../../services/vehicle.service';
import { UtilService } from '../../services/util.service';
import { SharedDataService, AppSettings } from '../../services/shared-data.service';
import { CaseFilesListsService } from 'src/app/services/case-files-lists.service';

import { Acc } from '../../models/acc';
import { Non_Occupants } from '../../models/non-occupants';
import { Veh } from '../../models/veh';
import { Dri } from '../../models/dri';
import { PreCrash } from '../../models/pre-crash';
import { Occupants } from '../../models/occupants';
import { PII } from '../../models/pii';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { VehiclePrecrashPrecrashComponent } from '../vehicle/vehicle-precrash/vehicle-precrash-precrash/vehicle-precrash-precrash.component';
import { AutofillService } from 'src/app/services/autofill.service';
import { LongLatInfo } from 'src/app/models/longlatinfo';

import { VehicleVehicleDamagedAreasService } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-damaged-areas/service/vehicle-vehicle-damaged-areas.service';
import { CrasheventServices } from 'src/app/ui/crash/crash-events/services/crashevents.service';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { CaseLockedViewModel } from 'src/app/interface/CaseLockedViewModel';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { Preference, ExcludeFormClearFields, EDTStatus, RBISDataValue, ActionCommand, DBMode, CaseStatus } from 'src/app/models/enums/app.enums';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { PopupNotification } from 'src/app/models/popupnotification';
import { RBISWizardResults } from 'src/app/models/RBISWizardResults';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

import { ReplaceTextPipe } from 'src/app/pipes/replaceText.pipe';
import { Cases_Locked } from 'src/app/models/cases-locked';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { EditCheckHelper } from 'src/app/helper/EditCheckHelper';
import { ViolatedRules } from 'src/app/models/violated-rules';
import { GetCaseStatDetails_Result } from 'src/app/models/GetCaseStatDetails_Result';
import { ActionButtonService } from 'src/app/services/action-button.service';
import { RbisUser } from 'src/app/models/rbis-user';
import { CrashTrafficwayComponent } from '../crash/crash-trafficway/crash-trafficway.component';
import { EarlyNotify } from 'src/app/models/early-notify';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';



@Component({
    selector: 'app-action-buttons',
    templateUrl: './action-buttons.component.html',
    styleUrls: ['./action-buttons.component.css'],
    providers: [
        DecimalPipe, ReplaceTextPipe
    ],
})
export class ActionButtonsComponent extends BaseComponent implements OnInit, OnDestroy {
    private _TypeScript_TypeGuard_ActionButtonsComponent: string = null;
    private _objActiveComponent: Array<BaseComponent>;
    private _arrSbsFormDirty: Array<Subscription> = new Array<Subscription>();

    private sbsCaseId: Subscription;

    private _success = new Subject<string>();

    staticAlertClosed = false;
    successMessage: string;
    entityToSave: string;
    saveFunctionName: string;

    public strCaseStatus: string = '';
    public codingStatus: number;
    public strCodingStatus: string = '';
    public strHQReviewStatus: string = '';
    public strHQReviewCompleteStatus: string = '';

    intYear: number;
    mode: number;
    stateNum: number;
    accid: number;
    nonOccupantid: number;
    vehicleid: number;
    personid: number;
    intBeforeSaveStateNum: number;

    acc: Acc;
    pii: PII;
    nonOccupant: Non_Occupants;
    veh: Veh;
    dri: Dri;
    preCrash: PreCrash;
    person: Occupants;
    earlyNotification: EarlyNotify;

    ViolatedRuleOR: ViolatedRules;
    validationPass: boolean = false;

    longLatStatus: LongLatInfo = {} as LongLatInfo;
    selectedOldCrashTypeOptions: Array<any> = [];
    objUser: RbisUser = {} as RbisUser;
    nonHarmfulEventsIds: Array<number> = [];

    blnIsEDTstate: boolean = false;
    blnIsEDTCase: boolean = false;
    blnIsEDTConflictStatus: boolean = false;
    blnIsCodingComplete: boolean = false;

    isHQStaff: boolean = false;
    adminRights: boolean;
    blnOOSPreference: boolean = false;

    disabledCrashType: boolean = true;
    disabledEnterEarlyNotification: boolean = false;
    disabledSearchCase: boolean = false;
    disabledSaveAndCheckCase: boolean = false;
    disabledSaveCase: boolean = false;
    disabledCloseCase: boolean = false;
    disabledRestructureCase: boolean = false;
    disabledRequestOutofStateData: boolean = true;
    disabledDeleteCase: boolean = false;
    disabledOverrideEditCheck: boolean = true;
    disabledClearForm: boolean = false;
    disabledAskCodingQuestion: boolean = true;
    disabledChangeEDTStatus: boolean = false;
    disabledChangeStateCase: boolean = false;
    disabledPrintCase: boolean = false;
    disabledCrashTypeWizard: boolean = false;
    disabledCrashEvents: boolean = false;
    disabledCodingComplete: boolean = false;
    disabledHQReview: boolean = false;
    disabledHQReviewComplete: boolean = false;



    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _nonOccupantService: NonOccupantService,
        private _vehService: VehicleService,
        private _utilService: UtilService,
        private sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _autofillService: AutofillService,
        private messageService: VehicleVehicleDamagedAreasService,
        private messageServiceEvent: CrasheventServices,
        private _caseFilesListsService: CaseFilesListsService,
        private _userPreferenceService: SecUserPerferenceService,
        private _authService: AuthService,
        private replaceTextPipe: ReplaceTextPipe,
        private numberPipe: DecimalPipe,
        protected _urlTreeHelper: UrlTreeHelper,
        private _actionButtonService: ActionButtonService,
        private _earlyNotificationService: EarlyNotificationService
    ) {
        super(_route, sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);

        this.sharedDataService.subReEvalCaseActionButtons.subscribe(((objNewState: { objComponent: Array<BaseComponent>, objCase: Acc }) => {
            if (objNewState && objNewState.objComponent && ObjectUtil.GetTypeName(objNewState.objComponent[0]) != 'ActionButtonsComponent') {

                this._objActiveComponent = objNewState.objComponent;

                this._arrSbsFormDirty.forEach(x => x.unsubscribe());
                this._arrSbsFormDirty.length = 0; //Clear array
                let strActiveComponentName: string;
                let strActiveComponentNames: string = '';

                if (this._objActiveComponent) {
                    for (let objActiveComponent of this._objActiveComponent) {
                        strActiveComponentName = ObjectUtil.GetTypeName(objActiveComponent);

                        if (!strActiveComponentName || strActiveComponentName == '')
                            strActiveComponentName = 'UnknownComponent';

                        strActiveComponentNames += (strActiveComponentNames != '' ? ',' : '') + strActiveComponentName;

                        if (objActiveComponent.arrInputForms) {
                            for (let objform of objActiveComponent.arrInputForms.toArray()) {
                                if (objform.dirty && this.strCaseStatus != 'Modified' && strActiveComponentName == 'CrashTrafficwayComponent') { //added because manually dirt form wasn't reaching to next condition.
                                    this.strCaseStatus = 'Modified';
                                }
                                this._arrSbsFormDirty.push(objform.valueChanges.subscribe(// on screen init every field form is populated and set status as pristine every field form fires ngform.valueschanges $event twice  
                                    ((objformchange: any) => {
                                        if (objform.dirty && this.strCaseStatus != 'Modified') {
                                            this.strCaseStatus = 'Modified';
                                            this.ReEvalActionButtons();
                                        }
                                        else if (objform.pristine && this.strCaseStatus != 'Saved') { //if there are multiple forms and only partial pristine reset is possible, then this needs to be reworked to traverse all forms.
                                            if (this.acc && this.acc.Deleted)
                                                return;

                                            this.strCaseStatus = 'Saved';
                                            this.ReEvalActionButtons();
                                        }
                                    }).bind(this),
                                    (error) => {
                                        alert('ngform.valuechanges error: ' + error);
                                    }
                                ));
                            }
                        }
                    }

                    document.cookie = 'RbisActiveComponents=' + strActiveComponentNames; //Assignment to cookie is actually an append or replace. This cookie value is used for logging when an error is thrown.
                }

                if (objNewState.objCase) {
                    this.acc = objNewState.objCase;
                    this.strCaseStatus = this.acc.Deleted ? 'Deleted' : 'Saved';
                }

                this.ReEvalActionButtons();
            } else {
                //if the saved is called from inside the forms and saved data should hava case status to save 
                this.strCaseStatus = 'Saved';
            }
        }).bind(this));

        this._editCheckHelper = new EditCheckHelper(sharedDataService, _modalService, _urlTreeHelper);
    }


    private setURLsegments(urlSegments: UrlSegment[]): void {
        this.stateNum = UrlTreeHelper.FindAndSetStateNum("case", urlSegments);
        this.accid = UrlTreeHelper.FindAndSetPathId("case", urlSegments);//parseInt(urlSegments[urlSegments.findIndex(x => x.path === "case") + 1].path);
        this.nonOccupantid = UrlTreeHelper.FindAndSetPathId("nonOccupant", urlSegments);  //parseInt(urlSegments[urlSegments.findIndex(x => x.path === "nonOccupant") + 1].path);
        this.vehicleid = UrlTreeHelper.FindAndSetPathId("vehicle", urlSegments); //parseInt(urlSegments[urlSegments.findIndex(x => x.path === "vehicle") + 1].path);
        this.personid = UrlTreeHelper.FindAndSetPathId("person", urlSegments);  //parseInt(urlSegments[urlSegments.findIndex(x => x.path === "person") + 1].path);
        this.entityToSave = UrlTreeHelper.FindAndSetCurrentTab(urlSegments); //urlSegments[urlSegments.length - 1].path;
    }

    private setCrashTypeOldValue(): void {
        let findOldWizard = this.acc.RBISWizardResults.find(x => x.ACCID == this.acc.AccID && x.WizardType == 'CrashType');
        if (findOldWizard) {
            const _oldJson = JSON.parse(findOldWizard.JSONOBJ);
            if (_oldJson.lenght = 2) {
                this.selectedOldCrashTypeOptions = _oldJson[1];
            } else {
                this.selectedOldCrashTypeOptions = _oldJson;
            }
        }
    }

    public async onBeforeSave() {

        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        let appSettings = await this.sharedDataService.GetAppSettings();
        let objUser = await this._authService.GetUserPromise();
        this.blnOOSPreference = await this._userPreferenceService.isThisPreferenceOn(Preference.AutomaticOutOfStateMessageRequests);
        this.longLatStatus.UserID = objUser.UserId;
        this.adminRights = objUser.hasAdminRights;
        this.isHQStaff = objUser.blnHQStaff;

        this.blnIsEDTstate = this.sharedDataService.getIsEDTState();
        this.blnIsEDTCase = this.sharedDataService.getIsEDTCase();

        this.intYear = appSettings.intYear;
        this.mode = appSettings.intMode;

        this.setNonHarmfulEvents();

        this.codingStatus = this.acc.EarlyNotify[0].Status;

        this.strCodingStatus = (this.codingStatus == CaseStatus.Coding_Complete
            || this.codingStatus == CaseStatus.HQ_Review
            || this.codingStatus == CaseStatus.HQ_Review_Complete) ? 'Set Status to Open' : 'Coding Complete';
        this.strHQReviewStatus = 'HQ Review';
        this.strHQReviewCompleteStatus = 'HQ Review Complete';

        this.sharedDataService.getViolatedRule().then((item => {
            this.ViolatedRuleOR = item;
        }).bind(this));

        this._caseService.hideNavForCloseCase.subscribe(result => {
            if (!result) {
                this.ReEvalActionButtons();
                if (this.acc.AccID > 0) {
                    this.enableDisableCrashType();
                }
            }
            else if (result == true) {
                this.enableDisableCloseCase();
            }
        });

        this.stateNum = await this.sharedDataService.getSelectedState();

        this.subscription.add(this._caseFilesListsService.getMessage().subscribe((message => {
            if (message) {
                if (message.text == 'CaseFile_CaseClose')
                    this.closeCase();
            }
        }).bind(this)));

        this.sbsCaseId = this._route.params.subscribe(async (objParams: Params) => { //If the parameter in the route changes, we load the case for the new parameter value

            let intAccId: number = parseInt(this._route.snapshot.params.caseid);
            this.accid = intAccId;

            let url: string = window.location.href;
            this._caseService.hideNavForCloseCase.next(url.includes('/closed'));

            this.acc = await this._caseService.GetCasePromise(this.accid);

            if (this.acc.AccID != this.accid) this.accid = this.acc.AccID;
            this.setCrashTypeOldValue()

            //this.sharedDataService.subReEvalCaseActionButtons.next({ objComponent: null, objCase: this.acc });

            setTimeout(() => this.staticAlertClosed = true, 20000);

            this._success.subscribe((message) => this.successMessage = message);
            this._success.pipe(
                debounceTime(5000)
            ).subscribe(() => this.successMessage = null);

            //this.longLatValtionRetrive();


            if (this.blnIsEDTCase && this.acc.Status == 3) {
                this.blnIsEDTConflictStatus = true;
            } else {
                this.blnIsEDTConflictStatus = false;
            }

            this.subscription.add(this.sharedDataService.observeSave.subscribe((blnSaveSuccessful => {
                if (blnSaveSuccessful) {
                    try {
                        this.validateAirbagFatality();
                        this.validateAvoidanceNotification();
                        this._modalService.resetMessage();
                        this.clearViolatedElement();
                    }
                    catch (ex) {
                        console.error(ex);
                    }

                    if (this._objActiveComponent) {
                        for (let objActiveComponent of this._objActiveComponent) {
                            if (objActiveComponent.arrInputForms)
                                for (let objForm of objActiveComponent.arrInputForms.toArray()) {
                                    objActiveComponent.MarkFormAsDirty(); //Call to FormGroup.markAsPristine() does not raise ngForm.valueChanges unless at least one child form field is touched.
                                    objForm.form.markAsPristine();
                                }
                        }

                    }

                    this.strCaseStatus = this.acc.Deleted ? 'Deleted' : 'Saved'; //DO NOT move this outside of subscription
                    this.ReEvalActionButtons();
                }
            }).bind(this)));
        });

        //Moved from button click to here...

        this.strCaseStatus = this.acc.Deleted ? 'Deleted' : 'Saved';

        let subViolatedRule = this._sharedService.observeblnViolatedRule.subscribe((blnItem) => {
            subViolatedRule.unsubscribe();
            if (blnItem) {
                this._editCheckHelper.blnViolatedRuleForDisabled = false;
                this.ReEvalActionButtons();
            }
        })

        this.objUser = await this._authService.GetUserPromise();



    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsCaseId)
            this.sbsCaseId.unsubscribe();
    }

    /**
     * Returns true when crash has no harmful events coded
     **/
    public hasNoCrashEvents(): boolean {
        if (this.acc.CrashEvents.length == 0) {
            return true;
        }

        let countNonHarmCrashEvents = 0;
        let numberOfCrashEvents = this.acc.CrashEvents.length;

        this.acc.CrashEvents.forEach((item) => {
            if (this.nonHarmfulEventsIds.includes(item.SOE)) {
                countNonHarmCrashEvents++;
            }
        });

        if (countNonHarmCrashEvents == numberOfCrashEvents)
            return true;

        return false;
    }

    private setCrashTypeJSON(result: any): void {
        let findOldWizard = this.acc.RBISWizardResults.find(x => x.ACCID == this.acc.AccID && x.WizardType == 'CrashType');
        if (!findOldWizard) {
            let rBISWizardResults: RBISWizardResults = {} as RBISWizardResults;
            rBISWizardResults.ACCID = this.acc.AccID;
            rBISWizardResults.JSONOBJ = JSON.stringify(result);
            rBISWizardResults.WizardType = "CrashType"
            this.acc.RBISWizardResults.push(rBISWizardResults)
        } else {
            let index = this.acc.RBISWizardResults.findIndex(x => x.ACCID == this.acc.AccID && x.WizardType == 'CrashType');
            findOldWizard.JSONOBJ = JSON.stringify(result);

            this.acc.RBISWizardResults[index] = findOldWizard;
        }
    }

    sendMessage(): void {
        this.messageService.sendMessage('DamagedAreas');
    }

    clearMessage(): void {
        this.messageService.clearMessage();
    }

    enableDisableCrashType(): boolean {
        let disableCrashType = false;

        if (this.acc) {
            if (this.acc.Deleted == true || !this.acc.Cases_Locked || this.strCaseStatus == 'Modified') {
                disableCrashType = true; //Case is deleted or closed (unlocked) or has uncommitted changes
            }
            else {
                this.acc.Veh.forEach(item => { //TODO: ??? Shouldn't this be any vehicle is Unit Type 1, instead of any vehicle unit type not set???
                    if (item.UnitType == 0 || item.UnitType == -1) {
                        disableCrashType = true;
                    }
                });
            }

        }
        else
            disableCrashType = true; //No case loaded

        if (this.disabledCrashType != disableCrashType)
            this.disabledCrashType = disableCrashType;
        return disableCrashType
    }

    enableDisableCloseCase() {
        let disabledCloseCase = false;
        let disabledCrashEvents = false;

        if (this.acc) { //Case is deleted or closed (unlocked) or has uncommitted changes
            if (this.acc.Deleted == true || !this.acc.Cases_Locked || this.strCaseStatus == 'Modified') {
                disabledCloseCase = true;
                disabledCrashEvents = true;
            }
        }
        else {//No case loaded
            disabledCloseCase = true;
            disabledCrashEvents = true;
        }
        if (this.disabledCloseCase != disabledCloseCase) {
            this.disabledCloseCase = disabledCloseCase;
        }
        if (this.disabledCrashEvents != disabledCrashEvents) {
            this.disabledCrashEvents = disabledCrashEvents;
        }
    }

    checkCrashTypeStatus(): boolean {
        let isValidCrashType = true;
        if (this.acc) {
            if (this.acc.Deleted == true || !this.acc.Cases_Locked) {
                isValidCrashType = false; //Case is deleted or closed (unlocked) or has uncommitted changes
            }
            else {
                this.acc.Veh.forEach(item => {
                    if (item.UnitType == -1) {
                        isValidCrashType = false;
                    }
                });
            }
        } else {
            isValidCrashType = false;
        }
        return isValidCrashType;
    }

    /**
     * Re-evaluate which buttons should be enabled based on this.strCaseStatus, this.acc.Deleted, this.acc.Cases_Locked and this.acc.CrashType
     **/
    ReEvalActionButtons(): void {
        if (this.acc !== undefined) {
            if (this.acc !== null) {
                if (this.acc.Deleted || this.blnReadOnly) {
                    this.disabledEnterEarlyNotification = false;
                    this.disabledSaveAndCheckCase = true;
                    this.disabledSaveCase = true;
                    this.disabledCloseCase = false;
                    this.disabledRestructureCase = true;
                    this.disabledRequestOutofStateData = true;
                    this.disabledDeleteCase = true;
                    this.disabledOverrideEditCheck = true;
                    this.disabledClearForm = true;
                    this.disabledAskCodingQuestion = true;
                    this.disabledChangeEDTStatus = true;
                    this.disabledChangeStateCase = true;
                    this.disabledPrintCase = false;
                    this.disabledCrashTypeWizard = false;
                    this.disabledCrashEvents = false;
                    this.disabledCodingComplete = (this.isHQStaff
                        && (this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review || this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review_Complete)) ? false : true;
                }
                else if (!this.acc.Cases_Locked) { //Case closed (a.k.a checked in, a.k.a. unlocked) and not deleted
                    this.disabledEnterEarlyNotification = false;
                    this.disabledSaveAndCheckCase = true;
                    this.disabledSaveCase = true;
                    this.disabledCloseCase = true;
                    this.disabledRestructureCase = true;
                    this.disabledRequestOutofStateData = true;
                    this.disabledDeleteCase = true;
                    this.disabledOverrideEditCheck = true;
                    this.disabledClearForm = true;
                    this.disabledAskCodingQuestion = true;
                    this.disabledPrintCase = true;
                    this.disabledChangeEDTStatus = true;
                    this.disabledChangeStateCase = true;
                    this.disabledCrashTypeWizard = true;
                    this.disabledCrashEvents = false;
                    this.disabledCodingComplete = true;
                }
                else if (this.strCaseStatus == 'Modified') {
                    this.disabledEnterEarlyNotification = true;
                    this.disabledSaveAndCheckCase = false;
                    this.disabledSaveCase = false;
                    this.disabledCloseCase = true;
                    this.disabledRestructureCase = true;
                    this.disabledRequestOutofStateData = true;
                    this.disabledDeleteCase = true;
                    this.disabledOverrideEditCheck = true;
                    this.disabledClearForm = false;
                    this.disabledAskCodingQuestion = false;
                    this.disabledChangeStateCase = true;
                    this.disabledChangeEDTStatus = false;
                    this.disabledPrintCase = false;
                    this.disabledCrashTypeWizard = false;
                    this.disabledCrashEvents = false;
                    this.disabledCodingComplete = false;
                }
                else { //Case editable (not deleted and not closed) and without uncommitted changes
                    this.disabledEnterEarlyNotification = false;
                    this.disabledSaveAndCheckCase = false;
                    this.disabledSaveCase = false;
                    this.disabledCloseCase = false;
                    this.mode == DBMode.MOSS ? this.disabledRestructureCase = false : this.disabledRestructureCase = true;
                    this.disabledRequestOutofStateData = false;
                    this.disabledDeleteCase = false;
                    this.disabledOverrideEditCheck = this._editCheckHelper.blnViolatedRuleForDisabled; // we are enabling Override Edit Check Btn only if affected screen has field realted to the EDIT CHECK RULE!
                    this.disabledClearForm = false;
                    this.disabledAskCodingQuestion = false;
                    this.disabledChangeEDTStatus = false;
                    this.disabledChangeStateCase = false;
                    this.disabledPrintCase = false;
                    this.disabledCrashTypeWizard = false;
                    this.disabledCodingComplete = false;
                }
            }
            else { //No case loaded
                this.disabledEnterEarlyNotification = false;
                this.disabledSaveAndCheckCase = true;
                this.disabledSaveCase = true;
                this.disabledCloseCase = true;
                this.disabledRestructureCase = true;
                this.disabledRequestOutofStateData = true;
                this.disabledDeleteCase = true;
                this.disabledOverrideEditCheck = true;
                this.disabledClearForm = true;
                this.disabledAskCodingQuestion = true;
                this.disabledChangeEDTStatus = true;
                this.disabledChangeStateCase = true;
                this.disabledPrintCase = true;
                this.disabledCrashTypeWizard = true;
                this.disabledCrashEvents = true;
                this.disabledCodingComplete = true;
            }

            //console.log("objViolatedRule", this._editCheckHelper.objViolatedRule);

            //if (this._editCheckHelper.objViolatedRule == null || this._editCheckHelper.objViolatedRule.ORideFlag != 'T')
            //    this.disabledOverrideEditCheck = true;
            //else
            //    this.disabledOverrideEditCheck = false;
        }
    }

    validateAvoidanceNotification(): void {

        let IsAvoidanceEmailSent = false;

        const findOldCrashAvoidance = this.acc.PopupNotification.find(x => x.ACCID == this.acc.AccID && x.RECIPIENT == 'CRASHAVOIDANCE');
        if (findOldCrashAvoidance) {
            IsAvoidanceEmailSent = true;
        } else {
            IsAvoidanceEmailSent = false;
        }
        if (!IsAvoidanceEmailSent) {
            let matched = false;
            let isFirstPopup = false;
            let selectedVehicle: Veh = {} as Veh;
            let vehId = 0;
            //this._caseService.validationSeedData();
            if (this.acc.Veh) {
                this.acc.Veh.forEach((vehItem, index) => {
                    if (!matched) {
                        let make = vehItem.Make;
                        let model = vehItem.Model;
                        let modelYear = vehItem.ModelYr;
                        let bodyType = vehItem.Body;
                        if (!make && !model && !bodyType && !modelYear) {
                            return;
                        }
                        selectedVehicle = vehItem;
                        //console.log(selectedVehicle, 'checking')
                        vehId = vehItem.VNumber;
                        if (make == 54 && model == 40 && bodyType == 5 && modelYear == 2013) {
                            isFirstPopup = true;
                            matched = true;
                        } else {
                            matched = this._caseService.validateRules(this.acc, vehItem)
                        };
                    }
                })
            }

            if (matched) {
                let popupNotification: PopupNotification = {} as PopupNotification;
                popupNotification.ACCID = this.acc.AccID,
                    popupNotification.RECIPIENT = 'CRASHAVOIDANCE'
                this.acc.PopupNotification.push(popupNotification);
                const veh = this.acc.Veh.find(x => x.VNumber == vehId)
                console.log('selectedVehicle', selectedVehicle)
                this._modalService_CannedPopups.avoindanceNotification(this.acc.Casenum, isFirstPopup, this.acc.Mode, this.acc).toPromise().then(result => {
                    if (result) {
                        this._caseService.sendMessage(selectedVehicle);
                    }
                });
            }
        }
    }

    validateAirbagFatality(): void {
        let IsAirbagFatalityEmailSent = false;
        console.log(this.acc.PopupNotification);
        const findOldSCI = (this.acc.PopupNotification.length == 0 ? false : this.acc.PopupNotification.find(x => x.ACCID == this.acc.AccID && x.RECIPIENT == "SCI"));
        if (findOldSCI) {
            IsAirbagFatalityEmailSent = true;
        } else {
            IsAirbagFatalityEmailSent = false;
        }

        if (!IsAirbagFatalityEmailSent) {
            let matched = false;
            let selectedVehicle: Veh = {} as Veh;
            this.acc.Veh.forEach((vehItem, index) => {
                if (!matched) {
                    matched = this._caseService.validateAirbagFatality(this.acc, vehItem)
                    if (matched) {
                        selectedVehicle = vehItem;
                    }
                }
            })
            if (matched) {
                let popupNotification: PopupNotification = {} as PopupNotification;
                popupNotification.ACCID = this.acc.AccID,
                    popupNotification.RECIPIENT = 'SCI'
                this.acc.PopupNotification.push(popupNotification);

                this._modalService_CannedPopups.airbagFatality(this.acc).toPromise().then(result => {
                    if (result) {
                        this._caseService.sendAirbagFatalityMessage(selectedVehicle);
                    }
                });
            }
        }
    }

    setNonHarmfulEvents(): void {
        this._utilService.nonHarmfulEvents.subscribe(result => {
            this.nonHarmfulEventsIds = [];
            if (result) {
                result.forEach(item => {
                    this.nonHarmfulEventsIds.push(item.Id);
                })
            }
        });
    }

    checkCrashEvents(): any[] {
        //we may need to add this in future for if needed 
        /// && (item.id != "crashType" && item.objCurrentValue >= 1 && !this.checkCrashEvents().includes(this.veh.VNumber))
        let vehicleList = [];
        this.acc.CrashEvents.forEach((item) => {
            if (!vehicleList.includes(item.VNumber1)) {
                vehicleList.push(item.VNumber1);
            }
            if (!vehicleList.includes(item.VNumber2)) {
                vehicleList.push(item.VNumber2);
            }
        })
        return vehicleList;
    }

    //#region Click Events

    async onSave(event: any, command: string) {
        this._sharedService.bhvSubjectClearForm.next(false);
        if (command == 'checkCase' && this.strCaseStatus == 'Saved' && (!this.acc.CaseStats || this.acc.CaseStats.CrUserID == null || this.acc.CaseStats.CrUserID == -1 || this.acc.CaseStats.CrUserID == 4444)) { //No data model updates to commit and case has not been previously edited by a user (this avoids supervisors like Melissa Sarro from "claiming" a case when just nosing in an uncoded case without making changes)
            this._actionButtonService.navigateToCheckCase(this.acc.AccID);
            return;
        }

        if (this.blnReadOnly) {
            console.warn('Skipping save attempt in read only mode.');
            return;
        }

        let objActveComponent: BaseComponent = this._objActiveComponent[0];

        if (this.acc.Mode == DBMode.CRSS) {
            if (this._objActiveComponent.length == 2) {
                // we may need to created loop if there are more
                //then two components are there because we might need to call before save for all child components as well
                //added by Khem : 05/04
                let obj = this._objActiveComponent[1];
                if (obj != null) {
                    await obj.onBeforeSave(null);
                    if (obj.blnAlloweSave) {
                        await this.saveCaseGroup(objActveComponent, command);
                    }
                }
            } else {
                await this.saveCaseGroup(objActveComponent, command);
            }
        }
        else {
            await this.saveCaseGroup(objActveComponent, command);
        }
    }

    async saveCaseGroup(objActveComponent: any, command: string) {
        objActveComponent.ClearHighligtedBlankElement();
        await this._actionButtonService.SaveCase(objActveComponent, command);

        this._modalService.resetMessage();
        this._editCheckHelper.ClearViolatedElement();
        //this._editCheckHelper.objViolatedRule = null;
        this.ReEvalActionButtons();
    }


    public onOverride(): void {
        const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment; //urlTreeHelper.GetURLSegments(); //this.getURLSegments();
        this.setURLsegments(urlSegments);
        super.clearViolatedElement();

        this._router.navigate([this.stateNum, 'checkCase', this.accid, 'overrideRule', this.ViolatedRuleOR.RuleID], { queryParams: { From: 'override', VNumber: this.ViolatedRuleOR.VNumber, PNumber: this.ViolatedRuleOR.PNumber } });
    }

    public printCase(): void {
        const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment; //urlTreeHelper.GetURLSegments(); //this.getURLSegments();
        this.setURLsegments(urlSegments);

        //this._caseService.hideNavForCloseCase.next(true);
        this._router.navigate([this.stateNum, 'case', this.acc.AccID, 'printcase']);
        //  this._router.navigateByUrl('case/' + this.accid + '/printcase');
        // this._modalService.printCase(this.accid);
    }

    public caseEDTConflicts(): void {
        this._sharedService.setLastCaseUrl(this._router.url);
        this._router.navigate([this.stateNum, 'EDTConflicts', this.acc.AccID]);
    }

    public deleteCase(): void {
        //It removes the red highlight on the ui control and removes the warning message on top of the page for violated rule.
        super.clearViolatedElement();
        let intAccId: number = parseInt(this._route.snapshot.params.caseid);
        this._router.navigate(['cases', this.stateNum, 'caseDelete', intAccId]);
        //this._router.navigate([this.stateNum, 'cases/caseDelete/', intAccId])
    }

    public closeCase(): void {
        //It removes the red highlight on the ui control and removes the warning message on top of the page for violated rule.
        super.clearViolatedElement();
        let lockedCase: Array<CaseLockedViewModel> = [];
        let releaseCase = {} as CaseLockedViewModel
        releaseCase.AccID = this.acc.AccID;
        lockedCase.push(releaseCase);
        this._caseService.ReleaseLockedCases(lockedCase).then(result => {
            if (result.IsValid) {
                this._caseService.hideNavForCloseCase.next(true);
                this.acc.Cases_Locked = null;
                //this.ReEvalActionButtons();
                //this._router.navigateByUrl(this.stateNum + '/case/' + releaseCase.AccID + '/closed');
                this._router.navigate([this.stateNum, 'case', releaseCase.AccID, 'closed'])
            } else {
                this._modalService.setMessage(result.Message, 'warning')
            }
        });
    }

    //Task:9019 Remove the following for MTSS
    //public onEnterEarlyNotification(): void {
    //    //It removes the red highlight on the ui control and removes the warning message on top of the page for violated rule.
    //    super.clearViolatedElement();
    //    // We have to navigate users to EN Screener page if they come to  EN page from Case Page. It is by Requierment #6162;
    //    this._sharedService.setLastCaseUrl('earlyNotification/' + this.acc.StateNum.toString() + '/fatalCaseScreener');
    //    this._router.navigate(['earlyNotification', this.acc.StateNum.toString(), 'newFatalCase']);
    //}

    public onSearchCases(): void {
        //It removes the red highlight on the ui control and removes the warning message on top of the page for violated rule.
        super.clearViolatedElement();
        this._router.navigate(['crss', 'CaseSearchScreener']);
    }

    public restructureCase(objEvent: Event): void {
        //It removes the red highlight on the ui control and removes the warning message on top of the page for violated rule.
        this._modalService.resetEditCheckMessage();
        this.sharedDataService.setLastCaseUrl(this._router.url);
        this._router.navigate(['cases', this.stateNum, 'caseEdit', this.acc.AccID]);
    }

    //TO DO - Revrite condition so it looks more readable.
    public onClearForm(event): void {
        super.clearViolatedElement();
        this._sharedService.bhvSubjectClearForm.next(true);
        this._sharedService.subjectClearForm.next(null);

        let arrFormFields: UIElementBase[] = this._autofillService.arrControls;

        for (let item of arrFormFields) {
            if (//Skip all the componentes from the below condition-list;
                //crash
                (item.id == ExcludeFormClearFields.AccDateMonth) || (item.id == ExcludeFormClearFields.AccDateDay) || (item.id == ExcludeFormClearFields.AccTimeHr) || (item.id == ExcludeFormClearFields.AccTimeMin) || (item.id == ExcludeFormClearFields.AccDateYear) ||
                //bike
                (item.id == ExcludeFormClearFields.crashType) || (item.id == ExcludeFormClearFields.crashLocation) || (item.id == ExcludeFormClearFields.bikePosition) || (item.id == ExcludeFormClearFields.bikeDirection) || (item.id == ExcludeFormClearFields.crashGroupBike) ||
                //pedestrian
                (item.id == ExcludeFormClearFields.crashTypePed) || (item.id == ExcludeFormClearFields.crashLocationPed) || (item.id == ExcludeFormClearFields.pedestrianPosition) || (item.id == ExcludeFormClearFields.pedestrianDirection) ||
                (item.id == ExcludeFormClearFields.pedestrianMotDirection) || (item.id == ExcludeFormClearFields.pedestrianMotManeuver) || (item.id == ExcludeFormClearFields.pedestrianLegIntersection) || (item.id == ExcludeFormClearFields.pedestrianScenario) ||
                (item.id == ExcludeFormClearFields.crashGroupPed) || (item.id == ExcludeFormClearFields.crashGroupExpended) || (item.id == ExcludeFormClearFields.crashTypeExpended) ||
                //veh
                (item.id == ExcludeFormClearFields.NumOccs) || (item.id == ExcludeFormClearFields.InImpact) ||
                //Trailer
                //(item.disabled)
                ((item.id == ExcludeFormClearFields.Trailer1Vin1 && item.disabled)) || (item.id == ExcludeFormClearFields.Trailer1Vin2 && item.disabled) || (item.id == ExcludeFormClearFields.Trailer1Vin3 && item.disabled) ||
                (item.id == ExcludeFormClearFields.Trailer1GVWRating && item.disabled) || (item.id == ExcludeFormClearFields.Trailer2GVWRating && item.disabled) || (item.id == ExcludeFormClearFields.Trailer3GVWRating && item.disabled)
            ) {

            }
            else {
                item.clearComponent();
                if (item.strDependentFieldName != "" && item.strDependentFieldName != "AccID") {
                    //Clearing options from the dependeds Typeaheads after clear form btn click  added by Khem  => item.strDependentFieldName != "AccID" only exists on crashevents for them we don't want to clear dropDown on clear. 
                    item.options.length = 0; //Clears the array
                }
            }
        }

        setTimeout(() => this._objActiveComponent.forEach(x => x.MarkFormAsDirty()), 100); //we doing this timeout so that we ensure that the DIRTY  status is the last( there is logic of clear origine that fires immediatly after clear...)               

        //this._objActiveComponent.arrInputForms.forEach(x => x.form.markAsDirty());


        //Logic for utilizing ActionButtonsComponent Clear Form click for form element different then TypeAhead, MultiSelect or TextField
        //this._sharedService.subjectClearForm.next('TEXTAREA');
        this._sharedService.subClearLngLatFields.next('LongLat');
        //clear all for _Vehicle Vehicle Damaged Areas
        //pathname
        //href
        if (event.view.location.href.includes("damagedAreas")) {
            this.sendMessage();
        }

        if (event.view.location.href.includes("events")) {
            this._sharedService.subjectClearForm.next('events');
        }
        this._modalService.resetMessage();
    }

    OnCrashEvents($event: any) {
        this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid, 'events']);
    }

    async crashTypeWizard(event: any) {
        if (this.strCaseStatus == 'Modified') {
            let saved = await this.onSave(event, 'save')
        }
        if (!this.checkCrashTypeStatus()) {
            let displayMessage = this._modalService.dialog('This case is either deleted/locked or there are no vehicles set with unit type. Please check all the vehicles', '', 'M').toPromise();
        }
        else if (this.hasNoCrashEvents()) {
            let displayMessage = this._modalService.dialog('There are no vehicles identified with the First Harmful Event. Please make sure that the Crash Events Table is filled.', '', 'M').toPromise();

        } else {
            // this.enableDisableCrashType();

            // if (this.disabledCrashType) return;
            //this.selectedOldCrashTypeOptions = [];
            this._modalService_CannedPopups.crashTypeWizard(this.acc, this.selectedOldCrashTypeOptions).pipe(take(1)).subscribe(result => {
                if (result == null || result.length == 0) return;
                this.selectedOldCrashTypeOptions = result[1];
                console.log('selectedOldCrashTypeOptions', this.selectedOldCrashTypeOptions);
                //checking number of vechicle and selection side for vechicle.
                let vechicleInfo = result[0];

                this.selectedOldCrashTypeOptions.push(vechicleInfo);

                this.acc.Veh.forEach((item, index) => {
                    if (this.acc.Veh[index].PreCrash == null) return;

                    if (this.acc.Veh[index].PreCrash != null && item.VNumber == vechicleInfo.vecNum1) {
                        this.acc.Veh[index].PreCrash.CrashType = parseInt(vechicleInfo.vechicle1Value);
                        return
                    }

                    if (this.acc.Veh[index].PreCrash != null && item.VNumber == vechicleInfo.vecNum2) {
                        this.acc.Veh[index].PreCrash.CrashType = parseInt(vechicleInfo.vechicle2Value);
                        return
                    }

                    if (this.acc.Veh[index].PreCrash != null && item.VNumber != vechicleInfo.vecNum1 && item.VNumber != vechicleInfo.vecNum2) {
                        this.acc.Veh[index].PreCrash.CrashType = 98;
                        return
                    }
                })

                if (this._router.url.includes('precrash')) {
                    this._caseService.setRefreshPreCrash(true);
                }
                this.setCrashTypeJSON(result);

                //NOTE: This was added in previus check in. Since I forgot to attach the bug with the prev check in I am checking in again same file with this NOTE-MSG; 
                //On Crash Type Wizard selected options we have to save Acc. 
                const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment; //calling urlTreeHelper.GetURLSegments(); so that we can Save whole Acc event we are at for example at "vehicle/2/person/3/personSupplemental"
                this.setURLsegments(urlSegments);
                this._actionButtonService.saveAcc(this.acc, ActionCommand.Save, this.entityToSave);
                //if (this._objActiveComponent) {
                //    this._objActiveComponent.arrInputForms.forEach(form => {
                //        form.form.markAsDirty();
                //    })
                //}
            });
        }
    }

    public changeStateCase(): void {
        let ENID = this.acc.EarlyNotify.find(x => x.MDEAccID === this.acc.AccID).ENID;
        let ENStateNum = this.acc.EarlyNotify.find(x => x.MDEAccID === this.acc.AccID).StateNum;
        // let enURL: string = '/earlyNotification/newFatalCase/' + ENID + '/1';
        let enURL: string = '/earlyNotification/' + ENStateNum + '/newFatalCase/' + ENID + '/1';
        this._router.navigateByUrl(enURL);
    }

    public askCodingQuestion(): void {
        this._modalService.resetEditCheckMessage();
        let intAccId: number = parseInt(this._route.snapshot.params.caseid);
        this._router.navigate(['cases', this.stateNum, 'askCodingQuestion', this.acc.Casenum]);
    }
    //#end region 

    /**
     * Complementary to Unsaved Changes Routeguard, this function is attached to window beforeunload event
     */
    public checkUnsavedChanges(objEvent: BeforeUnloadEvent): void {
        if (this.strCaseStatus == 'Modified') {
            objEvent.preventDefault(); // Preventing close event will cause some browsers, including Chrome, to show alert-like modal popup.
            objEvent.returnValue = 'Unsaved Changes'; // Chrome requires returnValue to be set

            this._modalService.dialog('Please specify how to proceed.', 'Warning: Unsaved changes', 'YesNo', 'lg', true, false, "Save Changes", "Discard Changes and Close Case", null, false).subscribe(
                ((blnSaveUnsavedChanges: boolean) => {
                    if (blnSaveUnsavedChanges) {
                        let objActveComponent: BaseComponent = this._objActiveComponent.find(x => x.arrInputForms && x.arrInputForms.some(x => x.dirty == true));
                        this._actionButtonService.SaveCase(objActveComponent, 'Save').then(
                            (blnSuccess: boolean) => {
                                if (blnSuccess) //Chrome will not let window be closed from a callback
                                    this._modalService.setMessage('Changes saved. Browser window or tab may be closed or reloaded now.', 'success');
                            });
                    }
                    else {
                        if (this._objActiveComponent)
                            for (let objActiveComponent of this._objActiveComponent) {
                                if (objActiveComponent.arrInputForms)
                                    for (let objForm of objActiveComponent.arrInputForms.toArray())
                                        objForm.form.markAsPristine(); //Mark as pristine so that Unsaved Changes Routeguard allows navigation to case closed page
                            }

                        this._caseService.acc = null;
                        this.closeCase(); //Chrome will not let window be closed from a callback
                    }
                }).bind(this)
            );
        }
    }

    public deletePar(): void {
        this._modalService_CannedPopups.deleteCrssCase(this.acc).subscribe(result => {
            if (typeof (result) === 'object') {
                this.acc = result;
                this._caseService.acc = result;
                UIElementBase.blnReadOnly = true;
                this._router.onSameUrlNavigation = "reload";
                this._router.navigateByUrl(this._router.url);
                this._sharedService.subReEvalCaseActionButtons.next({ objCase: result, objComponent: null });
            }
        });
    }

    public undeletePar(): void {
        this._modalService_CannedPopups.deleteCrssCase(this.acc, true).subscribe(((objAccResult: Acc) => {
            if (typeof (objAccResult) === 'object') {
                this.acc = objAccResult;
                this._caseService.acc = objAccResult;
                UIElementBase.blnReadOnly = false; //Make case editable again
                this._router.onSameUrlNavigation = "reload";
                this._router.navigateByUrl(this._router.url);
                this._sharedService.subReEvalCaseActionButtons.next({ objCase: objAccResult, objComponent: null });
            }
        }).bind(this));
    }

    public async codingComplete() {
        let statuscode = 0;
        if (this.acc.EarlyNotify[0].Status == CaseStatus.Coding_Complete
            || this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review
            || this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review_Complete) {
            this.strCodingStatus = 'Set Status to Open';
            statuscode = 1;
            this.acc.EarlyNotify[0].Status = 1;
        }
        else {
            this.strCodingStatus = 'Coding Complete'
            statuscode = 4;
            this.acc.EarlyNotify[0].Status = 4;
        }

        this.setCodingStatus(statuscode);
    }

    public async hqReview() {
        let statuscode = 0;

        this.strCodingStatus = 'Set Status to Open';
        this.strHQReviewStatus = 'HQ Review';
        statuscode = 5;
        this.acc.EarlyNotify[0].Status = 5;

        this.setCodingStatus(statuscode);
    }

    public async hqReviewComplete() {
        let statuscode = 0;

        this.strHQReviewCompleteStatus = 'HQ Review Complete';
        statuscode = 6;
        this.acc.EarlyNotify[0].Status = 6;

        this.setCodingStatus(statuscode);
    }

    public async setCodingStatus(statuscode) {
        let caseStatus = CaseStatus[this.acc.EarlyNotify[0].Status];
        caseStatus = caseStatus.replace('_', ' ');

        await this._earlyNotificationService.UpdateENStatus(this.acc.AccID, statuscode);

        if (this.intMode == DBMode.MOSS
            && (this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review || this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review_Complete))
            UIElementBase.blnReadOnly = true;

        this._modalService.dialog('Case Status successfully updated to ' + caseStatus.toString() + '.', 'success', 'M')
            .pipe(take(1))
            .subscribe(okCliked => { });

        this._router.onSameUrlNavigation = "reload";
        this._router.navigateByUrl(this._router.url);
    }

    async reload(url: string): Promise<boolean> {
        // this._router.navigate(['cases', this.stateNum, 'caseDelete', intAccId]);

        await this._router.navigateByUrl('/', { skipLocationChange: true });
        return this._router.navigateByUrl(url);
    }
}
