import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-striking-vehicle-precrash',
    templateUrl: './striking-vehicle-precrash.component.html',
    styleUrls: ['./striking-vehicle-precrash.component.css']
})

export class StrikingVehiclePrecrashComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
