import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { VinRequestModel } from 'src/app/models/VinRequestModel';
import { VehicleService } from 'src/app/services/vehicle.service';
import { VinrequestService } from 'src/app/services/vinrequest.service';
import { Trailer } from 'src/app/models/trailer';
import { ModalService } from 'src/app/services/modal.service';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AutofillService } from 'src/app/services/autofill.service';
import { VinRequest } from 'src/app/models/vin-request';
import { VinReqComment } from 'src/app/models/VinReqComment';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { AuthService } from 'src/app/services/auth.service';
import { RbisUser } from 'src/app/models/rbis-user';
import { CaseService } from 'src/app/services/case.service';
import { DBMode } from 'src/app/models/enums/app.enums';

@Component({
    selector: 'app-vinrequest',
    templateUrl: './vinrequest.component.html',
    styleUrls: ['./vinrequest.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class VinrequestComponent implements OnInit {
    acc: Acc;
    veh: Veh;
    model: VinRequestModel = {} as VinRequestModel;
    private objUser: RbisUser;
    private objVinRequest: VinRequest;
    trailer: Trailer;
    comment: string;
    mode: string = 'new';
    isAdmin: boolean = false;
    message: string = '';
    innerPopup: NgbModalRef;
    hasEmailSent: boolean = false;
    vin: string = "";
    success: string = 'success';

    accid: number = 0;
    vehNumber: number = 0;
    seqNum: number = 0;
    caseNumber: number = 0;
    isReadOnly: boolean = false;
    selectedMode: number = -1;
    @ViewChild('alertMessage') alertMessage: ViewContainerRef;

    constructor(public activeModal: NgbActiveModal,
        private _authService: AuthService,
        private _autofillService: AutofillService,
        private _caseService: CaseService,
        private _vinRequestService: VinrequestService,
        private router: Router,
        private localModal: NgbModal) {
    } //Class members accessed from the HTML template must be public for Release build

    async ngOnInit() {
        this.hasEmailSent = false;
        this.isAdmin = false;

        if (this.trailer != null)
            this.seqNum = this.trailer.SeqNum;

        this.getInfo();

        this.acc = await this._caseService.GetCasePromise(this.accid, false, true);
        this.veh = this.acc.Veh.find(x => x.VNumber == this.vehNumber);

        this.selectedMode = this.acc.Mode;
        this.model.StateId = this.acc.StateNum;
        if(this.veh)
            this.objVinRequest = this.veh.VinRequest.find(x => x.SeqNum == this.seqNum);

        this.objUser = await this._authService.GetUserPromise();

        this._caseService.acc = null;
    }


    sendVinRequest() {
        if (this.model.RequestId == 0) {
            this.model.AccID = this.accid;
            this.model.VNumber = this.vehNumber;
            this.model.SeqNum = this.seqNum;
            this.model.OriginalVIN = this.vin;

            this.objVinRequest = AutofillService.AddModelObject(this.veh, 'Veh', 'VinRequest');
            //VIN Request does NOT follow PK composite key convention and does NOT follow PK naming convention found in the rest of the application,
            //so, PK is NOT initialized by AutofillService.
            this.objVinRequest.ID      = 0;
            this.objVinRequest.AccID   = this.veh.AccID;
            this.objVinRequest.VNumber = this.veh.VNumber;
            this.objVinRequest.SeqNum  = this.seqNum;
            this.objVinRequest.CreatedDate = new Date();
            this.objVinRequest.CreatedBy   = this.objUser.UserId;
            this.objVinRequest.StateNum    = this.acc.StateNum;
            this.objVinRequest.OriginalVIN = this.vin;
        }

        if (this.comment && this.comment.trim() != '') {
            let objComment: VinReqComment = AutofillService.AddModelObject(this.objVinRequest, 'VinRequest', 'VinReqComment');
            //VIN Request Comment does NOT follow PK composite key convention and does NOT follow PK naming convention found in the rest of the application,
            //so, PK is NOT initialized by AutofillService.
            objComment.ID           = 0;
            objComment.VinRequestID = this.objVinRequest.ID;
            objComment.Comment      = this.comment;
            objComment.CreatedBy    = this.objUser.UserId;;
            objComment.CreatedDate  = new Date();
        }

        this.model.Comment = this.comment;
        this.comment = '';

        this._vinRequestService.saveVinRequest(this.model).then(((result: VinRequestModel) => {
            this.model = result;

            if (result.RequestId != 0) {
                this.mode = 'update';
                this.hasEmailSent = true;

                if (this.objVinRequest.ID == 0) {
                    this.objVinRequest.ID = result.RequestId;
                    this.objVinRequest.VinReqComment[0].VinRequestID = result.RequestId;
                }
            }

            if (result.ReqResolutionList.length > 0 && result.ReqStatusList.length > 0) {
                this.isAdmin = true;
            }
        }).bind(this));
    }

    saveVinRequest() {
        if (this.validateFixVin()) {
            this.message = 'Are you sure you want to send this VIN?'
            this.openMessage().toPromise().then(result => {
                console.log('result', result);
                if (result == 'ok') {
                    this.sendVinRequest()
                }
            });
        } else {
            this.message = 'Please provide Fixed VIN (17 alphanumeric characters)!';
            this.openMessage().toPromise().then(result => {
                this.message = '';
            });
        }
    }

    openMessage() {
        this.innerPopup = this.localModal.open(this.alertMessage, { size: 'sm', centered:true });
        return from(this.innerPopup.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }

    gotoVinRequest() {
       
        let urlFragment = '';
        let times = (Math.floor(Math.random()*9));
        if (this.comment != '') {
            this.message = 'Are you sure you want to move away from this page without sending the information?'
            this.openMessage().toPromise().then(result => {
                if (result == 'ok') {
                    this.activeModal.dismiss(true);
                    if (location.href.includes('vin-requests')) {
                        urlFragment = 'refresh' + times 
                    }
                    if (this.selectedMode == DBMode.CRSS) {
                        this.router.navigate(['vin-requests'], { fragment: urlFragment });
                    } else {
                        this.router.navigate([this.model.StateId, 'vin-requests'], { fragment: urlFragment });
                    }
                }
            });
        } else {
            this.activeModal.dismiss(true);
            if (location.href.includes('vin-requests')) {
                urlFragment = 'refresh' + times
            }

            if (this.selectedMode == DBMode.CRSS) {
                this.router.navigate(['vin-requests'], { fragment: urlFragment });
            } else {
                this.router.navigate([this.model.StateId, 'vin-requests'], { fragment: urlFragment });
            }
           
        }
    }

    GotBack() {
        if (location.href.includes('vin-requests')) {
            let urlFragment = '';
            let times = (Math.floor(Math.random() * 9));
            urlFragment = 'refresh' + times
            this.activeModal.close(true);

            if (this.selectedMode == DBMode.CRSS) {
                this.router.navigate(['vin-requests'], { fragment: urlFragment });
            } else {
                this.router.navigate([this.model.StateId, 'vin-requests'], { fragment: urlFragment });
            }
        }
        else {
            this.activeModal.close(true);
        }
    }

    validateFixVin() {
        if (this.isAdmin) {
            if (this.model.ResolutionId == 1 || this.model.ResolutionId == 2) {
                const regexp = new RegExp((/^(?=.*\d)(?=.*[a-zA-Z]).{17}$/));
                return regexp.test(this.model.FixVin);
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    getInfo() {
        if (this.trailer != null)
            this.seqNum = this.trailer.SeqNum;

        this._vinRequestService.getVINRequest(this.accid, this.vehNumber, this.seqNum).toPromise().then(((result: VinRequestModel) => {
            this.model = result;

            if (result.RequestId != 0) {
                this.mode = 'update';
            }

            if (result.ReqResolutionList.length > 0 && result.ReqStatusList.length > 0) {
                this.isAdmin = true;
            }
        }).bind(this));
    }
}
