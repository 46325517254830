import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CrashRoutingModule }       from 'src/app/ui/crash/crash-routing.module';
import { CaseRoutingModule }        from 'src/app/ui/cases/case-routing.module';

//Layout components
import { HomeComponent }            from 'src/app/ui/toolbar/home/home.component';
import { PreferencesComponent }     from 'src/app/ui/toolbar/preferences/preferences.component';
import { ReleaserecordComponent }   from './ui/releaserecord/releaserecord.component';
import { VINRequestsDetailsComponent } from './ui/vin-request/vin-requests-details/vin-requests-details.component';
import { IsRBISGuard } from './ui/is-rbis.guard';
import { UnAuthorizedComponent } from './ui/un-authorized/un-authorized.component'


const routes: Routes = [
    //Nav links
    { path: 'unauthorized', component: UnAuthorizedComponent, pathMatch: 'full' },// Redirects to unauthorized page if the user soes not have MTSS access rights
    { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [IsRBISGuard] }, //Redirects to crssHome from ngOnInit() handler if mode is CRSS
    //{ path: 'crssHome', component: CRSSHomePageComponent, pathMatch: 'full' },
    //  { path: 'crssHome', component: CRSSHomePageComponent, pathMatch: 'full', canActivate: [!IsRBISGuard] },

    //{ path: ':stateNum/monthlyCounts',               component: MonthlyCountsComponent },
    //{ path: ':stateNum/monthlycounts/print/:typeId', component: MonthlyCountsPrintComponent },

    // Commented out since we did not implemented logic for: "batchProcessing, reports, parRequests" as per Lavanay request on the meeting 2 / 26 / 2020
    //  { path: ':stateNum/batchProcessing',        component: BatchProcessingComponent },  
    //  { path: ':stateNum/reports',                component: ReportsComponent },
    //  { path: ':stateNum/parRequests',            component: ParRequestsComponent },    
    //  { path: ':stateNum/help',                   component: HelpComponent },
    //  { path: 'help',                             component: HelpComponent },

    { path: ':stateNum/preferences',            component: PreferencesComponent },
    { path: 'releaserecords', component: ReleaserecordComponent },
    { path: ':stateNum/vin-requests', component: VINRequestsDetailsComponent },
    { path: 'vin-requests', component: VINRequestsDetailsComponent },
    { path: 'releaserecords', component: ReleaserecordComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {           
        }),
        CrashRoutingModule,
        CaseRoutingModule
    ],

    exports: [RouterModule]
})
export class AppRoutingModule { }
