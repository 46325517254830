<div>
    <!--<span class="case-header-status" [class.edt-case]="true"></span>-->
    <!-- CASE HEADER -->
    <div class="border-bottom border-secondary pb-2" *ngIf="!isPrintMode">
        <div class="row">
            <div class="col my-auto">
                <ul class="nav nav-fill">
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">State: </span>
                        <span class="align-middle fw-bold">{{stateString}}</span>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Case: </span>
                        <span class="align-middle fw-bold">{{acc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : ''}}</span>
                    </li>
                    <li class="nav-item my-auto">
                        <span class="align-middle no-pointer">EN Case: </span>
                        <button *ngIf="acc.EarlyNotify[0]" id="btnCaseComponent_onEarlyNotifyEdit" class="btn btn-link" hidden="{{invisibleENCaseNum? 'hidden':''}}" disabled="{{hideNavForCloseCase? 'disabled':''}}" (click)="onEarlyNotifyEdit(acc)"><u>{{acc.EarlyNotify[0]?.ENCaseNum| number : '4.0-0' | replaceTextPipe : ',' : '' }}</u></button>
                    </li>
                    <li class="nav-item my-auto">
                        <span class="align-middle no-pointer">State Case: </span>
                        <ng-container *ngIf="acc.EarlyNotify[0]">
                            <button *ngIf="acc.EarlyNotify[0].StateCaseNum" id="btnCaseComponent_onEarlyNotifyEdit" class="btn btn-link" disabled="{{hideNavForCloseCase? 'disabled':''}}" (click)="onStateCasePDF(acc.EarlyNotify[0])"><u>{{acc.EarlyNotify[0]?.StateCaseNum}}</u></button>
                        </ng-container>
                    </li>

                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Case Level: </span>
                        <!--<span class="align-middle fw-bold" *ngIf="acc.CaseStats && acc.CaseStats.Fatal > 0">                                                                     S</span>-->
                        <span class="align-middle fw-bold" *ngIf="acc.CaseStats && acc.CaseStats.CaseIDX  > 89">                                    1</span>
                        <span class="align-middle fw-bold" *ngIf="acc.CaseStats && (acc.CaseStats.CaseIDX > 79 && acc.CaseStats.CaseIDX <  90)">    2</span>
                        <span class="align-middle fw-bold" *ngIf="acc.CaseStats && (acc.CaseStats.CaseIDX > 69 && acc.CaseStats.CaseIDX <  80)">    3</span>
                        <span class="align-middle fw-bold" *ngIf="acc.CaseStats && (acc.CaseStats.CaseIDX > 59 && acc.CaseStats.CaseIDX <  70)">    4</span>
                        <span class="align-middle fw-bold" *ngIf="acc.CaseStats && (acc.CaseStats.CaseIDX <= 59)">                                  Unusable</span>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Non-Occ Forms: </span>
                        <span class="align-middle fw-bold" *ngIf="acc.NMForms !== -1; else ZeroNMForms">{{acc?.NMForms}}</span>
                        <ng-template #ZeroNMForms>
                            <span class="align-middle fw-bold">0</span>
                        </ng-template>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Veh Forms: </span>
                        <span class="align-middle fw-bold" *ngIf="acc.VForms !== -1; else ZeroVForms">{{acc?.VForms}}</span>
                        <ng-template #ZeroVForms>
                            <span class="align-middle fw-bold">0</span>
                        </ng-template>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Occ Forms: </span>
                        <span class="align-middle fw-bold" *ngIf="acc.PForms !== -1; else ZeroVForms">{{acc?.PForms}}</span>
                        <ng-template #ZeroPForms>
                            <span class="align-middle fw-bold">0</span>
                        </ng-template>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Last Updated By: </span>
                        <span class="align-middle fw-bold">{{acc.strLastUpdatedBy}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!--EDT Header-->
    <!-- CASE HEADER -->
    <div class="border-bottom border-secondary pb-2" *ngIf="blnIsEDTcase && acc">
        <div class="row">
            <div class="col my-auto">
                <ul class="nav nav-fill">
                    <li class="nav-item my-auto">
                        <span class="align-middle">EDT Case Status: </span>
                        <span class="align-middle fw-bold">{{ acc.Status | valueToDescription: 'EDT_CaseStatus' | async}}</span>
                        <span class="align-content-start">
                            <button class="btn btn-link fw-bold" disabled="{{hideNavForCloseCase? 'disabled':''}}" (click)="changeCaseStatus()"><u>Change</u></button>
                        </span>
                    </li>
                    <li>
                        <span class="align-content-start" *ngIf="caseStatusReviewFlag">
                            <button class="btn btn-link fw-bold" (click)="RedirectViewCaseStatusUpdates(accid)">View Updates</button>
                        </span>
                    </li>
                    <li class="nav-item my-auto">
                        <span class="align-middle">Last State Update: </span>
                        <span class="align-middle fw-bold" *ngIf="edtLastUpdateDates">{{ edtLastUpdateDates?.LastRecvdDate | date:'M/d/yyyy, h:mm:ss a' }}</span>
                    </li>
                    <li class="nav-item my-auto">
                        <span class="align-middle">Last EDT Update: </span>
                        <span class="align-middle fw-bold" *ngIf="edtLastUpdateDates">{{ edtLastUpdateDates?.LastProcessedDate | date:'M/d/yyyy, h:mm:ss a' }}</span>
                    </li>
                    <li class="nav-item my-auto">
                        <span class="align-middle">Last Analyst Update: </span>
                        <span class="align-middle fw-bold">{{ acc?.LastModifiedOn | date:'M/d/yyyy, h:mm:ss a' }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
