import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { retry, window } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GeolocatorService } from 'src/app/services/geolocator.service';
import { DBMode } from 'src/app/models/enums/app.enums';

@Component({
    selector: 'app-geolocator',
    templateUrl: './geolocator.component.html',
    styleUrls: ['./geolocator.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class GeolocatorComponent implements OnInit, OnDestroy {

    constructor(public activeModal: NgbActiveModal,
        private _utilService: UtilService,
        private _sanitizer: DomSanitizer,
        private _geoLocatorService: GeolocatorService) {
    }

    /**
     * Bypasses Angular's internal cross-domain restrictions.
     * Does not affect browser-level cross-domain security restrictions.
     */
    public iframeSrc: SafeResourceUrl;

    public road1: string = '';
    public road2: string = '';
    public lat: string = '';
    public lng: string = '';
    public county: string = '';
    public state: string = '';

    ngOnInit() {
        let plainUrl: string = this.buildUrl();
        this.iframeSrc = this._sanitizer.bypassSecurityTrustResourceUrl(plainUrl);
        //console.log('GeolocatorComponent: converted raw URL ', plainUrl, 'to registered safe URL ', this.iframeSrc);
    }

    private buildUrl(): string {
        if (this.lng.includes != undefined && !this.lng.includes('-') && this.lng != '')
            this.lng = "-" + this.lng;

        return this._geoLocatorService.rootUrl + (this._geoLocatorService.rootUrl.endsWith('?') ? '' : '?')
            + 'state=' + this.state
            + (this._geoLocatorService.Mode != DBMode.MOSS ? '&county=' + this.county : '') //County is not applicable in MOSS
            + '&road1=' + encodeURI(this.road1)
            + '&road2=' + encodeURI(this.road2)
            + '&latitude=' + this.lat
            + '&longitude=' + this.lng
            + '&userId=' + this._geoLocatorService.userId
            + '&returnUrl=' + encodeURIComponent(this._geoLocatorService.returnUrl)
            + '&callingApp=' + (this._geoLocatorService.Mode != DBMode.MOSS ? 'rbis' : 'rbisMOSS');
    }

    /**
     * This function is how Geolocator communicates with RBIS: Geolocator reloads the IFRAME with the RBIS URL appended with the selected coordinates and address
     * which fires the load event handler we attached in the HTML template and since the URL is RBIS, the IFRAME is no longer cross-domain.
     * This function is entered two additional times: (1) when the IFRAME loads with a blank URL and (2) when IFRAME.src is set to Geolocator - note that the second
     * time that window.location.href cannot be read back due to cross-domain security restrictions.
     * @param iFrame
     */
    public onLoadIframe(iFrame: HTMLIFrameElement): void {
        try {
            let returnUrl: string = iFrame.contentWindow.location.href;
            //console.log('GeolocatorComponent: onLoadIframe', returnUrl);

            if (returnUrl.includes('address')) {
                this.activeModal.close(returnUrl);
            }
        }
        catch (ex) {
            if (ex.message.indexOf('Blocked a frame with origin') == -1) //Ignore cross-origin exception when load event fires for Geolocator
                console.error('GeolocatorComponent: Error in IFRAME load event handler: ', ex);
        }
    }

    ngOnDestroy() {

    }
}
