import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import { decode, encode } from 'punycode';
import { PerBikeWizard } from 'src/app/models/pedbikewizard';
import { RbisUser } from 'src/app/models/rbis-user';


interface PedWizardScreen {
    shortDesc: string
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal,
        private _utilService: UtilService) {
    }

    body: string;
    title: string ='';
    boxType: string = 'M';
    module: string = 'D';
    showBack: boolean = false;
    hint: any;
    currentOptions: Array<PerBikeWizard> = [];
    wizardOptions: Array<PerBikeWizard> = [];
    selectedOptions: Array<PerBikeWizard> = [];
    selectedOldOptions: Array<PerBikeWizard> = [];
    currentOption = {} as PerBikeWizard;
    showFinalSelection: boolean = false;
    templateType: string = 'image';
    parentId: string = '';
    parentItem: any;
    singleImage: string = '';
    optionCount: number = 0;
    shortDesc: string = '';
    previousParentItem: any;
    showHintArea: boolean = false;
    isFirst: boolean = true;
    isExit: boolean = false;
    columnSize: number = 6;
    isOddsRows: boolean = false;
    backSelected: string = '';
    modalFor: string = 'ped';
    customCss: string = '';
    buttonSize: string = 'btn-lg';
    showNext: boolean = false;
    currentIndex: number = 0;
    showExtraStripInTop: boolean = false;
    showDivider: boolean = false;
    columnSizeCss: string = 'col-sm-6';
    showDividerWithoutText: boolean = false;
    isReadOnly: boolean = false;

    async ngOnInit() {
        this.getReadOnly(); 
        if (this.module == 'W') {
            this.wizardOptions = await this._utilService.getWizardOptions(this.modalFor)
            this.wizardOptions.forEach((item, i) => {
                if (item.Parent == '' && i == 0) {
                    this.parentId = item.Id.toString();
                    this.parentItem = item;
                    // this.previousParentItem = this.parentItem;
                    this.setTitleAndShortDesc();
                    this.setCurrentStep(this.parentId);
                    this.showNextButton();
                }
            })
        }
    }

    showNextButton() {
        if (this.selectedOldOptions!=null && this.selectedOldOptions.length > 1) {
            this.showNext = true;
        } else {
            this.showNext = false;
        }
    }

    setSelectedValue() {
        if (this.selectedOldOptions != null && this.selectedOldOptions.length > 0) {
            let hasAnyMatched = false;
            console.log('this.selectedOldOptions', this.selectedOldOptions);
            console.log('currentOption', this.currentOption);
            this.selectedOldOptions.forEach((item, index) => {
                let matchValue = this.currentOptions.find(x => x.Id == item.Id);
                if (matchValue != null) {
                    hasAnyMatched = true;
                    this.backSelected = matchValue.Id.toString();
                } 
            })
            if (hasAnyMatched) {
                this.showNext = true;
            } else {
                this.selectedOldOptions = [];
                this.showNext = false;
            }
        }
    }
    setCurrentStep(parent: string = '') {

        
        const allWizard = this.wizardOptions;
        let excludeChild: Array<string> = [];
        if (this.parentItem != null && (this.parentItem.ExcludeChild != null && this.parentItem.ExcludeChild != '')) {

            if (this.parentItem.ExcludeChild.includes(',')) {
                const excludeItems = this.parentItem.ExcludeChild.split(',');
                excludeItems.forEach(item => {
                    excludeChild.push(item);
                })
            }

            if (this.parentItem.ExcludeChild.includes('-')) {
                const excludeItems = this.parentItem.ExcludeChild.split('-');
                if (excludeItems.length > 0) {
                    let start = parseInt(excludeItems[0]);
                    let stop = parseInt(excludeItems[1])
                    for (let i = start; i <= stop; i++) {
                        if (excludeItems[0].includes('0') && excludeItems[1].includes('0')) excludeChild.push('0' + i);
                        else excludeChild.push(i.toString())
                        
                    }
                }
            }

            if (!this.parentItem.ExcludeChild.includes('-') && !this.parentItem.ExcludeChild.includes(',')) {
                excludeChild.push(this.parentItem.ExcludeChild);
            }
        }
        let wizardList: any;
        let searchByCondition = '';
        if (this.parentItem.SectionTitle!=null && this.parentItem.SectionTitle.toLowerCase().includes('crossing path crash')) {
            if (this.modalFor == 'bike' && this.getFirstId == 151) {
                searchByCondition = 'firstParentId=151';
            }
        }
        else if (this.getFirstId == 4 && this.parentItem.Condition == 'firstParentId=4')
        {
            searchByCondition = '';
        }
        else if (this.getFirstId == 5 && this.parentItem.Condition == 'firstParentId=5') {
            searchByCondition = '';
        }
        else {

            if (this.parentItem != null && (this.parentItem.Condition != null && this.parentItem.Condition != '')) {

                const parentValue = "parentValue==" + this.previousParentItem.Value;
                //parse condition
                let conditionList = this.parentItem.Condition.split('|');

                console.log('conditionList', conditionList, 'parent value', this.previousParentItem.Value);
                if (conditionList.length > 0) {
                    conditionList.forEach((itemCondition, index) => {
                        if (itemCondition != '') {
                            if (conditionList[index] == parentValue) {
                                console.log('value one ', conditionList[index], 'value two ', itemCondition);
                                searchByCondition = conditionList[conditionList.length - 1].toLowerCase();
                            }
                        }
                    })

                }

            }
        }
        if (searchByCondition != '') wizardList = allWizard.filter(x => x.Condition == searchByCondition);
        else wizardList = allWizard.filter(x => x.Parent == parent && x.Condition != 'firstParentId=151');
        
        this.currentOptions = [];

       
        let hasCondition  = wizardList.find(x => x.Condition == 'firstParentId='+this.getFirstId);
        wizardList.forEach(item => {
            if (this.parentItem != null && this.parentItem.ChildImageUrl != null) item.ImageUrl = this.parentItem.ChildImageUrl;
            if (excludeChild.length > 0 && excludeChild.includes(item.Value)) return;
            if (hasCondition && this.getFirstId == 4 && item.OptionType == 'button' && (item.Condition != null && item.Condition != 'firstParentId=4')) return;
            if (hasCondition && this.getFirstId == 5 && item.OptionType == 'button' && (item.Condition != 'firstParentId=5')) return;

            if (hasCondition && this.getFirstId == 6 && item.OptionType == 'button' && (item.Condition == null && item.Condition != 'firstParentId=6')) return;
            if (this.getFirstId != 6 && item.OptionType == 'button' && item.Condition == 'firstParentId=6') return;

            if (this.getFirstId != 4 && item.OptionType == 'button' && item.Condition == 'firstParentId=4') return;
            if (this.getFirstId != 5 && item.OptionType == 'button' && item.Condition == 'firstParentId=5') return;
            if (this.getFirstId == 4 && item.OptionType == 'image' && item.Condition == 'firstParentId!=4') return;
            if (this.getFirstId != 4 && item.OptionType == 'image' && item.Condition == 'firstParentId=4') return;
            if (this.getFirstId == 4 && item.OptionType == 'common' && item.Condition == 'firstParentId!=4') return;
            if (this.getFirstId != 4 && item.OptionType == 'common' && item.Condition == 'firstParentId=4') return;
            this.currentOptions.push(item);
            if (item.OptionType == 'directional') this.singleImage = item.ImageUrl;
        })


        // if (this.currentOptions.length > 0 && this.currentOptions[0].ImageUrl != '') this.templateType = 'image'; else this.templateType = 'radio';
       // if (this.currentOptions.length > 0) this.title = this.currentOptions[0].sectionTitle;

        if (wizardList.filter(y => y.OptionType == 'radio').length > 0) {
            this.templateType = 'radio';
            
        } else if (wizardList.filter(y => y.OptionType == 'image').length > 0) {
            this.templateType = 'image'; 
        }
        else if (wizardList.filter(y => y.OptionType == 'direction').length > 0) {
            this.templateType = 'direction';
        }
        else if (wizardList.filter(y => y.OptionType == 'imageButton').length > 0) {
            this.templateType = 'imageButton';
        }
        else if (wizardList.filter(y => y.OptionType == 'common').length > 0) {
            this.templateType = 'common';
        }
        else if (wizardList.filter(y => y.OptionType == 'button').length > 0) {
            this.templateType = 'button';
        }
        this.currentOptions.sort((a, b) => { return a.Order-b.Order});

        this.optionCount = this.currentOptions.length;

        if (searchByCondition != '' && this.currentOptions.length > 0 && this.getFirstId != 151) {

            let checkHasShortInfo = this.currentOptions[0].SectionTitle.split('>')
            if (checkHasShortInfo.length == 2) {
                this.title = checkHasShortInfo[0];
                this.shortDesc = checkHasShortInfo[1];
            } else {
                this.shortDesc = this.currentOptions[0].ShortDesc;
                this.title = this.currentOptions[0].SectionTitle;
            }

        } else {
            if (this.getFirstId == 151 && (this.parentItem.Id == 173 || this.parentItem.Id == 174 || this.parentItem.Id == 175)) {
                this.shortDesc = 'Which of the following describes the circumstances of the bicyclist/motorist collision at a residential or commercial driveway or other midblock location not at an intersection?'
            }
        }


        this.checkShowHintArea();
        this.setColumnSize();
        this.setSelectedValue();
    }
    get getFirstId() {

        if (this.selectedOptions.length == 0) return 0;
        return this.selectedOptions[0].Id;
    }
    setColumnSize() {
        this.customCss = '';
        this.buttonSize = 'btn-lg';
        this.showExtraStripInTop = false;
        this.showDivider = false;
        this.showDividerWithoutText = false;
        this.columnSize = this.currentOptions.length;
        if (this.columnSize < 4) this.isFirst = true;
        let parentTitle: string = '';

        if (this.parentItem != null && this.parentItem.SectionTitle != null) {
            parentTitle = this.parentItem.SectionTitle.toLowerCase();
        }

        if (parentTitle.includes('unusual pedestrian')) {
            this.columnSize = 3;
            this.customCss = 'unsual-button-center';
        }
        if (parentTitle.includes('initial approach')) {
            this.columnSize = 3;
            this.customCss = 'initial-button-center';
        }
        if (parentTitle.includes('parallel path crash')) {
            this.columnSize = 3;
            this.customCss = 'parallel-button-center col-sm-12 mt-3';
        }
        if (parentTitle.includes('crossing path crash')) {

            if (this.getFirstId != 151) {
                this.customCss = 'crossing-button-center col-sm-12 mt-3';
            } else {
                this.buttonSize = 'btn-sm';
                this.showExtraStripInTop = true;
            }
        }
        if (parentTitle.includes('motorist turned or merged')) {
            this.customCss = 'parallel-button-center col-sm-12 mt-3';
        }

        if (parentTitle.includes('motorist overtaking')) {
            this.customCss = 'parallel-button-center col-sm-12 mt-3';
        }
        if (parentTitle.includes('specific circumstances')) {
            this.customCss = 'bike-specific-circumstances-button-center col-sm-12 mt-3';
        }
        

        if (parentTitle.includes('controlled intersection crash')) {
            this.showDivider = true;
        }

        if (parentTitle.includes('typical pedestrian crash') && this.getFirstId == 4) {
            this.showExtraStripInTop = true;
        }

        if (parentTitle.includes('crossing/in')) {
            this.showExtraStripInTop = true;
            this.showDividerWithoutText = true;
        }
        if (parentTitle.includes('lying in roadway')) this.isFirst = !this.isFirst;
        if (parentTitle.includes('turning error')) {
            this.buttonSize = 'btn-sm';
        }
        if (parentTitle.includes('non-trafficway crash')) {
            this.columnSize = 3;
            this.isFirst = false;
            this.customCss = 'mt-3';
        }
        
     
       
        this.columnSizeCss = '';
        if (this.modalFor == 'bike') this.columnSize = 3;
        if (this.columnSize == 3) {
            //this.columnSizeCss + ' bikeImageSize';
            //this.columnSizeCss = 'col'
        }
    }

    checkShowHintArea() {
        this.showHintArea = false;
        this.currentOptions.forEach((item, index) => {
            if (item.HintText != null && item.HintText != '') {
                this.showHintArea = true;
            }
        })
    }

    setSelectedHintText() {
        
        this.currentOptions.forEach((item, index) => {
            if (item.Id.toString() == this.backSelected) {
                this.hint = (item.Value != null ? item.Value + ' ' : '') + item.HintText;
            }
        })
    }


    onOptionSelect(item: PerBikeWizard,showNext: boolean = false) {

        if (item.Value == '00') return;
        let selectedItem = item;
        this.currentOption = selectedItem;
        const indexExists = this.selectedOptions.lastIndexOf(selectedItem);

        //   const findItem = this.selectedOptions.filter(x => x.Type == item.Type)
        if (indexExists > -1) {
            // this.selectedOptions.s
            this.selectedOptions.splice(indexExists)
        }

        if (this.backSelected != selectedItem.Id.toString() && this.selectedOldOptions.length > 0) {
            this.selectedOldOptions = [];
            this.showNext = false;
        }
        this.selectedOptions.push(selectedItem);
        this.clearTitleAndShortDesc();
       // if (this.selectedOptions.length > 0) this.showBack = true; else this.showBack = false;
        this.showGoBack();
        this.isFirstChild();
        this.isLast(item)
        this.checkExit(item); //this logic is specially for Bike. 

        if (!showNext && this.selectedOldOptions.length > 0) {
            this.currentIndex = this.currentIndex + 1;
        }

        if (!this.isExit) {
            this.hint = '';
            let parentId = (item.ShareWith ? item.ShareWith : item.Id);
            this.previousParentItem = this.parentItem;
            this.parentItem = item;
            this.setTitleAndShortDesc();
            this.setCurrentStep(parentId.toString());
            //console.log(item.Type);
            this.showFinalSelection = false;
        } else {
            this.title = 'Final Reviews';
            this.shortDesc = 'Review your selections below. Submit or click the Back button to correct your final selections.';
            this.showFinalSelection = true;
            this.showNext = false;
        }
        
    }

    isLast(item: any) {
      
        let hasMoreSteps = this.wizardOptions;

        if (hasMoreSteps.filter(x => x.Parent == item.Id).length > 0
            || hasMoreSteps.filter(x => x.Parent == item.ShareWith).length > 0 ||
            (item.Condition != null && item.Condition != 'skip')) {
            this.isExit = false;
        } else {
            this.isExit = true;
        }

        if (item.Condition != null && (item.Condition == 'group_a' || item.Condition == 'group_b')) this.isExit = true;

        if (item.Condition != null && (item.Value == '357' || item.Value == '380' || item.Value == '791' || item.Value == '795' || item.Value == '680' || item.Value == '690' || item.Value == '900')) {
            this.isExit = true;
        }
      
    }

    checkExit(itemRow: any) {

        if (itemRow != null && (itemRow.Condition != null && itemRow.Condition != '')) {

            const parentValue = "parentValue==" + this.parentItem.Value;
            let options = itemRow.Condition.split('|');
            const condition = options[1];
            const parentItemValue = options[0];
            if (condition == 'end' && parentItemValue == parentValue) {
                this.isExit = true;
            }
        }
    }

    setTitleAndShortDesc() {
        let parentTitle = this.parentItem.SectionTitle;
        this.title = (parentTitle != null ? parentTitle : '');
        if (this.getFirstId == 151 && parentTitle.toLowerCase().includes('crossing path crash')) {
            this.title = this.title.split('-')[0] + " - " + this.selectedOptions[0].Label;
        }
        if (this.getFirstId == 4) {
            if (parentTitle.toLowerCase().includes('failure to yield') || parentTitle.toLowerCase().includes('other action')) {
                let firstPartTitle = parentTitle.split('-')[0];
                let secondPartTitle = "Non-intersection"
                this.title = firstPartTitle + ' - ' + secondPartTitle;
            }
        }
        this.shortDesc = this.parentItem.ShortDesc;
    }

    clearTitleAndShortDesc() {
        this.title = ''
        this.shortDesc = '';
        this.showHintArea = false;
    }

    showGoBack() {
        if (this.selectedOptions.length > 0) this.showBack = true; else this.showBack = false;
    }

    onGoBack(item: any) {

       // const indexExists = this.selectedOptions.lastIndexOf(x => x.Type == item.Type);
        //   const findItem = this.selectedOptions.filter(x => x.Type == item.Type)

        const lastItem = this.selectedOptions.pop();
        this.backSelected = lastItem.Id.toString();
        if (this.selectedOptions.length > 0) {
            this.parentItem = this.selectedOptions[this.selectedOptions.length - 1];
            if (this.selectedOptions.length > 1) this.previousParentItem = this.selectedOptions[this.selectedOptions.length - 2];
        } else {
            this.parentItem = this.wizardOptions[0];
        }
        console.log('aaa',this.selectedOptions[this.selectedOptions.length - 1]);
        let parent = (lastItem.ShareWith ? lastItem.ShareWith : lastItem.Parent);
        if (item != 'undefined' || item != null) parent = lastItem.Parent

        this.setTitleAndShortDesc();
        this.setCurrentStep(parent.toString());
        this.showFinalSelection = false;
        this.showGoBack();
        this.isFirstChild();
        this.setSelectedHintText();
        this.reserveIndex();
        this.showNextButton();
    }

    reserveIndex() {
            if (this.currentIndex < 0) this.currentIndex = 0
            else
            this.currentIndex = this.currentIndex - 1;
    }

    onGoNext(parentItem: any) {
  
        let item = this.selectedOldOptions[this.currentIndex];
        this.currentIndex = this.currentIndex + 1;

        this.checkCurrentVsOldOptions();
       // this.selectedOptions.push(item);
        this.onOptionSelect(item, true);
        this.setSelectedHintText();
     
    }

    checkCurrentVsOldOptions() {
      
        console.log('old', this.selectedOldOptions, 'Current Set: ', this.currentOptions, 'selected list', this.selectedOptions)

    }
    isFirstChild() {
        console.log('length test', this.selectedOptions.length);
        if (this.selectedOptions.length == 0) this.isFirst = true; else this.isFirst = false;
    }

    onMouseHover(item: any, $event: any) {
        if (item.HintText == null || item.HintText == '') return false;
      
        if ($event) {
           $event.toElement.classList.add('border-img');;
        }

       // if (item.hintText != null && item.hintText != '') {
        this.hint = (item.Value!=null ? item.Value +' ': '') +  item.HintText;
         //   this.showHintArea = true;
      //  } else {
      //      this.showHintArea = false;
     //   }
        
    }

    getScreenName(index: number) : string {
        let screenName = '';
        let item: any;
        if (index == 0) {
            screenName = this.wizardOptions[index].SectionTitle.toUpperCase();
        } else {
            item = this.selectedOptions[index];
            if (item.OptionType == 'common') {
                item = this.wizardOptions.find(x => x.OptionType == 'common' && x.Parent == "0")
            } else {
                item = this.selectedOptions[index - 1];
            } 
            if (item.SectionTitle == null || item.SectionTitle == '') {
                item = this.selectedOptions[index];
            }
           
            screenName = (item.sectionTitle != null ? item.SectionTitle.split('-')[0].Trim().toUpperCase() : item.SectionTitle.toUpperCase());

        }
        return screenName;
    }

    onMouseOut($event: MouseEvent) {
   
        if ($event) {
            let target = $event.target  as HTMLElement
            target.classList.remove('border-img');
        }

    }

    private async getReadOnly() {
        let objUser: RbisUser = await this._utilService.GetUserPromise();
        this.isReadOnly = objUser.blnReadOnly
    }
}
