import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewEncapsulation, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { AutofillService } from 'src/app/services/autofill.service';

import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { PreCrash } from 'src/app/models/pre-crash';
import { PreCrash_ContribCirc } from 'src/app/models/pre-crash-contrib-circ';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { FormName, KeyCode } from 'src/app/models/enums/app.enums';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { take } from 'rxjs/operators';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Element_Specify } from 'src/app/models/element-specify';

@Component({
    selector: 'app-striking-vehicle-precrash-roadway',
    templateUrl: './striking-vehicle-precrash-roadway.component.html',
    styleUrls: ['./striking-vehicle-precrash-roadway.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class StrikingVehiclePrecrashRoadwayComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehiclePrecrashRoadwayComponent: string = null;
    accid: number;
    vehicleid: number;
    acc: Acc;
    veh: Veh;
    preCrash: PreCrash;
    allExisitingPrecrashList: Array<PreCrash> = [];
    noCopyAvailable: boolean = true;
    objInstanceElementSpecify: Element_Specify;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('trafflow') _trafflow: TypeaheadComponent;
    @ViewChild('traflane') _traflane: TypeaheadComponent;
    @ViewChild('spdlim') _spdlim: TypeaheadComponent;
    @ViewChild('roadalgn') _roadalgn: TypeaheadComponent;
    @ViewChild('roadprof') _roadprof: TypeaheadComponent;
    @ViewChild('surftype') _surftype: TypeaheadComponent;
    @ViewChild('surfcond') _surfcond: TypeaheadComponent;
    @ViewChild('contdev') _contdev: TypeaheadComponent;
    @ViewChild('devfunc') _devfunc: TypeaheadComponent;

    fieldMetaData: TableFieldElements[];
    @ViewChild('copyModal') copyModal: ViewContainerRef;
    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _sharedDataService: SharedDataService,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid)
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);

        if (this.printVehNum > 0 && this.veh.PreCrash == undefined || this.veh.PreCrash == null) {
            AutofillService.AddModelObject(this.veh, 'Veh', 'PreCrash');
        }
        if (this.veh && this.veh.PreCrash) {
            this.preCrash = this.veh.PreCrash;
        } else {

        }
        this.listAvailableExistingPrecrashInfo(this.acc);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    //TO DO - Uncomment it out
    onCopyRoadway(content) {
        this._modalService_CannedPopups.copyExistingData(content).pipe(
            take(1)
        ).subscribe(result => {
            if (result != 'undefined' && result != null) {
                this.copyFromExistingData(result);
            }
        });
    }

    //TO DO - Uncomment it out
    listAvailableExistingPrecrashInfo(data: any) {
        this.allExisitingPrecrashList = [];
        let countPrecrash = 0;
        data.Veh.forEach((item) => {
            console.log('item.PreCrash', item.PreCrash);
            if (item.PreCrash != null && item.PreCrash.VNumber != this.vehicleid) {
                this.allExisitingPrecrashList.push(item.PreCrash);
                countPrecrash = countPrecrash + 1;
            }
        })
        this.noCopyAvailable = this.allExisitingPrecrashList.length == 0;
    }


    copyFromExistingData(item: PreCrash) {
        if (this.preCrash == null) { // copying whole exisitng item in case if the precrash isn't exisitng in current vechicle otherwise it will give null error. 
            this.preCrash = item;
            this.preCrash.Veh = null;
            this.preCrash.VNumber = this.vehicleid;
            console.log(this.veh);
        } else {
            // this.preCrash.ContDev = (item.ContDev == null ? 0 : item.ContDev);
            // this.preCrash.CrashType = item.CrashType;
            //  this.preCrash.CriticalEvent = item.CriticalEvent;
            //   this.preCrash.CriticalEventCat = item.CriticalEventCat;
            // this.preCrash.DevFunc = item.DevFunc;
            // this.preCrash.VehManvr = item.VehManvr;

            //  this.preCrash.PreImpLoc = item.PreImpLoc;
            //  this.preCrash.PreImpStab = item.PreImpStab;
            this.preCrash.RoadAlgn = item.RoadAlgn;
            this.preCrash.RoadProf = item.RoadProf;
            this.preCrash.SpdLim = item.SpdLim;
            this.preCrash.SurfCond = item.SurfCond;
            this.preCrash.SurfType = item.SurfType;
            this.preCrash.TrafFlow = item.TrafFlow;
            this.preCrash.TrafLane = item.TrafLane;

            this._trafflow.setCurrentValue(this.preCrash.TrafFlow.toString())
            this._traflane.setCurrentValue(this.preCrash.TrafLane.toString())
            this._spdlim.setCurrentValue(this.preCrash.SpdLim.toString())
            this._roadalgn.setCurrentValue(this.preCrash.RoadAlgn.toString())
            this._roadprof.setCurrentValue(this.preCrash.RoadProf.toString())
            this._surftype.setCurrentValue(this.preCrash.SurfType.toString())
            this._surfcond.setCurrentValue(this.preCrash.SurfCond.toString())

        }
    }

    handleKeyup(event: KeyboardEvent) {
        if (event.code == KeyCode.F9 || event.code == KeyCode.Minus || event.code == KeyCode.NumpadSubtract) {
            this.onCopyRoadway(this.copyModal);
        }
    }
}
