import { BehaviorSubject, Observable } from 'rxjs';
import { State } from './state';
import { pluck,distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';

const state: State = {}

@Injectable({
    providedIn: 'root'
})

export class rbisStore {
    private subject = new BehaviorSubject<State>(state);
    private store = this.subject.asObservable()
        .pipe(distinctUntilChanged());

    /* example: store.value */
    get value() {
        return this.subject.value;
    }

    /* example: store.select<veh[]>('veh') */
    select<T>(name: string): Observable<T> {
        return this.store
            .pipe(pluck(name));
    }

    /* example: store.set('Veh',[{},{}]) */
    set(name: string, state: any) {
        this.subject.next({
            ...this.value, [name]: state
        });
    }
}
