import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AutofillService } from 'src/app/services/autofill.service';
import { ModalService } from 'src/app/services/modal.service';

import { Dri } from 'src/app/models/dri';
import { MeassureUnitType } from 'src/app/models/enums/app.enums';
import { TypeaheadComponent } from '../../typeahead/typeahead.component';

export const HEIGHTCALCUNITYPE = [
    { value: 1, text: 'Meters' },
    { value: 2, text: 'Inches' },

];

export interface HeightUnitTypeDDL {
    value: number;
    text: string;
};


@Component({
    selector: 'app-height-calculator',
    templateUrl: './height-calculator.component.html',
    styleUrls: ['./height-calculator.component.css']
})
export class HeightCalculatorComponent implements OnInit {

    private modal: NgbModalRef;

    public intHeight: number;

    public intFeet: number;
    public intInches: number;

    arrUnitTypes: Array<HeightUnitTypeDDL>;

    intUnitType: number;
    strUnitType: string;


    blnRequired: boolean;

    blnMetersLessThen: boolean;
    blnMetersGreaterThen: boolean;


    blnInchesLessThen: boolean;
    blnInchesGreaterThen: boolean;

    @Input() public objDri: Dri;
    @Output() setFeetInchesEvent = new EventEmitter<boolean>();
    @Input() public blnIsPedastrian: boolean = false;
    @Output() submitHeightInCms = new EventEmitter();

    constructor(
        private _ngModalService: NgbModal,
        private _autofillService: AutofillService,
        protected _modalService: ModalService
    ) { }

    ngOnInit() {

    }

    OnHeightChange($event) {
        this.resetValidations();

        let strFieldType: string = typeof ($event);

        if (strFieldType == 'number') {
            this.intHeight = $event;
        }
        else if (strFieldType == 'string') {
            let intValue = $event != '' ? parseFloat($event) : null;
            this.intHeight = intValue;
        }

        if (this.intUnitType == MeassureUnitType.Meter) {
            let tempStrFeetInches = (this.intHeight / 0.3048).toFixed(2);
            let tempIntFeet = tempStrFeetInches != '' ? parseFloat(tempStrFeetInches) : null;
            let tempintInches = tempStrFeetInches != '' ? parseFloat(tempStrFeetInches) : null;

            this.intFeet = Math.floor(tempIntFeet);
            this.intInches = Math.round(parseFloat((tempintInches % 1).toFixed(2)) * 12);
        }

        if (this.intUnitType == MeassureUnitType.Inches) {
            let tempStrFeetInches = (this.intHeight / 12).toFixed(2);
            let tempIntFeet = tempStrFeetInches != '' ? parseFloat(tempStrFeetInches) : null;
            let tempintInches = tempStrFeetInches != '' ? parseFloat(tempStrFeetInches) : null;

            this.intFeet = Math.floor(tempIntFeet);
            this.intInches = Math.round(parseFloat((tempintInches % 1).toFixed(2)) * 12);
        }
    }

    OnUnitTypeChange($event, inputHeight) {

        this.intUnitType = $event;

        this.strUnitType = this.arrUnitTypes.find(x => x.value == $event).text;

        this.intHeight = null;
        //this.OnHeightChange(0);

        this.intFeet = this.objDri.Feet && this.objDri.Feet != -1 ? this.objDri.Feet : null;
        this.intInches = (this.objDri.Inches || this.objDri.Inches === 0) && this.objDri.Inches != -1 ? this.objDri.Inches : null;

        inputHeight.select();
        inputHeight.focus();
    }

    async OnSubmit(inputHeight) {
        if (this.blnIsPedastrian) {
            if (this.intHeight && this.intHeight < MeassureUnitType["InchesMin"] && this.intUnitType == MeassureUnitType.Inches) {
                this.blnInchesLessThen = true;
                inputHeight.select();
                inputHeight.focus();
                return;
            }
            if (this.intHeight && this.intHeight > MeassureUnitType["InchesMax"] && this.intUnitType == MeassureUnitType.Inches) {
                this.blnInchesGreaterThen = true;
                inputHeight.select();
                inputHeight.focus();
                return;
            }

            else {
                this.submitHeightInCms.emit(Math.round(this.intHeight * 2.4));
                this.modal.close();
            }
        }
        else {
            if (!this.intHeight && this.intHeight != 0) {
                this.blnRequired = true;
                inputHeight.select();
                inputHeight.focus();
                return;
            }

            if (this.intHeight && this.intHeight < MeassureUnitType["MeterMin"] && this.intUnitType == MeassureUnitType.Meter) {
                this.blnMetersLessThen = true;
                inputHeight.select();
                inputHeight.focus();
                return;
            }
            if (this.intHeight && this.intHeight > MeassureUnitType["MeterMax"] && this.intUnitType == MeassureUnitType.Meter) {
                this.blnMetersGreaterThen = true;
                inputHeight.select();
                inputHeight.focus();
                return;
            }
            if (this.intHeight && this.intHeight < MeassureUnitType["InchesMin"] && this.intUnitType == MeassureUnitType.Inches) {
                this.blnInchesLessThen = true;
                inputHeight.select();
                inputHeight.focus();
                return;
            }
            if (this.intHeight && this.intHeight > MeassureUnitType["InchesMax"] && this.intUnitType == MeassureUnitType.Inches) {
                this.blnInchesGreaterThen = true;
                inputHeight.select();
                inputHeight.focus();
                return;
            }
            if (this.objDri.Feet && this.objDri.Feet != -1 || this.objDri.Inches && this.objDri.Inches != -1) {
                let prmConfirm: boolean = await this._modalService.dialog('You are about to overwrite existing Driver Feet and Inches. Are you sure?', 'Please confirm').toPromise();
                if (prmConfirm) {
                    this.setFeenAndInches();
                    this.resetValidations();
                    this.modal.close();
                }
                else {
                    this.intHeight = null;
                    inputHeight.select();
                    inputHeight.focus();
                    this.intFeet = this.objDri.Feet;
                    this.intInches = this.objDri.Inches;
                }
            }
            else {
                this.setFeenAndInches();
                this.resetValidations();
                this.modal.close();
            }
        }
    }

    setFeenAndInches() {
        this.objDri.Feet = this.intFeet;
        this.objDri.Inches = this.intInches;
        let UICtrlFeet: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "Feet") as TypeaheadComponent;
        UICtrlFeet.setCurrentValue(this.objDri.Feet.toString());
        let UICtrlInches: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "Inches") as TypeaheadComponent;
        UICtrlInches.setCurrentValue(this.objDri.Inches.toString());
        this.setFeetInchesEvent.emit(true);
    }

    onCancel() {
        this.resetValidations();
        this.intHeight = null;
    }

    onBlur() {

    }

    resetValidations() {
        this.blnRequired = false;

        this.blnMetersLessThen = false;
        this.blnMetersGreaterThen = false;

        this.blnInchesLessThen = false;
        this.blnInchesGreaterThen = false;
    }

    open(content, options: NgbModalOptions) {
        this.intHeight = null;
        console.log(this.blnIsPedastrian);

        if (this.objDri == null) {
            this.intFeet = null;
            this.intInches = null;
        }
        else {
            if (this.objDri.Feet) {
                this.intFeet = this.objDri.Feet != -1 ? this.objDri.Feet : null;
            }

            else {
                this.intFeet = null;
            }

            if (this.objDri.Inches || this.objDri.Inches === 0) {
                this.intInches = this.objDri.Inches != -1 ? this.objDri.Inches : null;
            }
            else {
                this.intInches = null;
            }
        }

        this.arrUnitTypes = HEIGHTCALCUNITYPE;
        if (this.blnIsPedastrian) {
            this.arrUnitTypes = this.arrUnitTypes.filter(obj => obj.text !== "Meters");
            this.intUnitType = MeassureUnitType.Inches;
            this.strUnitType = this.arrUnitTypes.find(x => x.value == this.intUnitType).text;
        }
        else {
            this.intUnitType = MeassureUnitType.Meter;
            this.strUnitType = this.arrUnitTypes.find(x => x.value == this.intUnitType).text;
        }

        this.modal = this._ngModalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'light-blue-backdrop', backdrop: 'static', keyboard: false });
    }
}
