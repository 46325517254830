import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, AfterViewInit, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { Acc } from '../../../models/acc';
import { AccTrafID } from 'src/app/models/acc-traf-id';
import { DrpDownOptions } from '../../../models/drp-down-options';
import { TableFieldElements } from '../../../models/table-field-elements';
import { TableId, FormName, Preference, MilePointWhole, RBISDataValue, DBMode, CaseStatus } from '../../../models/enums/app.enums';
import { LabelComponent } from '../../../components/label/label.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { take } from 'rxjs/operators';
import { GeolocatorService } from 'src/app/services/geolocator.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { LongLatInfo } from 'src/app/models/longlatinfo';
import { OldLongLatInfo } from 'src/app/interface/OldLongLatInfo';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { NgForm } from '@angular/forms';
import { EncryptedData } from 'src/app/models/EncryptedData';
import { RbisUser } from 'src/app/models/rbis-user';
import { AuthService } from 'src/app/services/auth.service';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { Subscription, Subject } from 'rxjs';
import { Coordinates_DMS } from 'src/app/models/Coordinates_DMS';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { TextFieldComponent } from 'src/app/components/textfield/textfield.component';
import { usp_DEFS_GetDropDownOptions_Result } from 'src/app/models/usp_DEFS_GetDropDownOptions_Result';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { parse } from 'path';
import { DecimalPipe } from '@angular/common';
import { format } from 'util';

enum CoordinateSpecial {
    LatNotApplicable = 77, LonNotApplicable = 777, ddNotApplicable = "77.77777777",
    LatNotReported = 88, LonNotReported = 888, ddNotReported = "88.88888888",
    LatUnknown = 99, LonUnknown = 999, ddUnknown = "99.99999999",
    LonDecNotApplicable = "777.77777777",
    LonDecNotReported = "888.88888888",
    LonDecUnknown = "999.99999999",
    ddmMinutesNotApplicable = "77.7777",
    ddmMinutesNotReported = "88.8888",
    ddmMinutesUnknown = "99.9999"
}

@Component({
    selector: 'app-crash-traffic',
    templateUrl: './crash-trafficway.component.html',
    styleUrls: ['./crash-trafficway.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CrashTrafficwayComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_CrashTrafficwayComponent: string = null;
    private objUser: RbisUser;
    public myThis: CrashTrafficwayComponent = this;

    private evtSiblingsInitializedTrafficway: Subscription;
    private strMilePointClone: string;

    accid: number;
    crashid: number;

    acc: Acc;
    accTrafID1: AccTrafID;
    accTrafID2: AccTrafID;

    viewType: string = 'dd';
    lastViewType: string = 'dd';

    manualHyperLinkIdentifier1: string;
    manualHyperLinkIdentifier2: string;
    manualHyperLinkMilePoint: string;
    manualHyperLinkGlobalPosition: string;

    resultString: string = '';
    geoValidationMessage: string = '';

    isFirstLoad: boolean = true;
    loadCounter: number = 0;
    emptyUrl = 'about:blank';
    disableSubmit: boolean = true;
    countyId: number = 0;
    /**
     * a.k.a. StateNum. The ID of the state that the case is from. In CRSS, this value is derived from the PSU that the case is from.
     * Notice: In CRSS, dbo.Acc.StateNum holds the PSU ID, since we don't have bounding boxes for PSUs, we can at best only do a partial sanity check based on the PSU's state.
     *         Additionally, although currently CRSS PSUs do not span across state borders, theoretically this is possible - if CRSS PSUs start spanning acorss state borders,
     *         then this logic will need to be revised to user proper coordinates or, at a minimum, check against the boundaries of all applicable states.
     **/
    private intStateId: number = 0;
    private strStateName: string;
    countyValidationRules: Array<string> = [];
    validateSuccessMessage: string = '';
    @ViewChild('fact') objForm: NgForm;
    @ViewChild('AccMilePointWhole', {static: false}) txtAccMilePointWhole: UIElementBase;
    @ViewChild('AccMilePointDecimal', { static: false }) txtAccMilePointDecimal: TypeaheadComponent;

    @ViewChild('txtAcclatitude', { static: false }) txtLatitude: TypeaheadComponent;
    @ViewChild('txtAccLongitude', { static: false }) txtLongitude: TypeaheadComponent;


    @ViewChild('txtAccLatDeg', { static: false }) txtAccLatDeg: TypeaheadComponent;
    @ViewChild('txtAccLatMin', { static: false }) txtLatMin: TypeaheadComponent;
    @ViewChild('txtAccLatSec', { static: false }) txtLatSec: TypeaheadComponent;
    
    @ViewChild('txtAccLonDeg', { static: false }) txtLonDeg: TypeaheadComponent;
    @ViewChild('txtAccLonMin', { static: false }) txtLonMin: TypeaheadComponent;
    @ViewChild('txtAccLonSec', { static: false }) txtLonSec: TypeaheadComponent;

    @ViewChild('txtddmAccLatDeg', { static: false }) txtddmAccLatDeg: TypeaheadComponent;
    @ViewChild('txtlatddmMinutes', { static: false }) txtlatddmMinutes: TypeaheadComponent;
    @ViewChild('txtddmLonDec', { static: false }) txtddmLonDec: TypeaheadComponent;
    @ViewChild('txtddmLonMin', { static: false }) txtddmLonMin: TypeaheadComponent;

    longLatStatus: LongLatInfo = {} as LongLatInfo;
    oldLlongLatInfo: OldLongLatInfo = {} as OldLongLatInfo
    firstOldValue: OldLongLatInfo = {} as OldLongLatInfo
    geoAddress: string = '';
    hasMinues: boolean = false;
    preferenceGeoLocatorPopup: boolean = true;
    focuseOut: boolean = false;
    subsClearLngLat: Subscription;
    DMSFormatPrefered: boolean = true;
    hasGeoValueChanged: boolean = false;
    isLatLngIsValidated: boolean = false;
    numberOfFieldUpdate: number = 0;
    firstTimeInDDM: boolean = false;
    wasVerifyMessageShown: boolean = false;

    //validation data for Lat/long
    LatDegValidationRange: Array<DrpDownOptions> = [];
    LatMinValidationRange: Array<DrpDownOptions> = [];
    LatSecValidationRange: Array<DrpDownOptions> = [];


    LonDegValidationRange: Array<DrpDownOptions> = [];
    LonMinValidationRange: Array<DrpDownOptions> = [];
    LonSecValidationRange: Array<DrpDownOptions> = [];
    hasOutOfRangeError = false;
    className = "crash_trafficway"
    @ViewChild("geolocatorButton") geolocatorButton: ElementRef;

    hasMOSSSpecialValueError = false;
    intMOSSGlobalPositionSeciton: number = 2; //1:DD, 2:DMS, 3:DDM

    constructor(
        private _route: ActivatedRoute,
        private _autofillService: AutofillService,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _geoLocatorService: GeolocatorService,
        private _sanitizer: DomSanitizer,
        private _secUserPerferenceService: SecUserPerferenceService,
        private _authService: AuthService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _router: Router
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);

        let sbsTypeaheadsInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((async () => {
            sbsTypeaheadsInit.unsubscribe();
            let arrStates: Array<DrpDownOptions> = await this._utilService.GetDrpDownListOptions(LookupTable.StateNum, '').toPromise();

            if (this.txtLatSec && this.txtLatSec.objCurrentValue != RBISDataValue.Blank) {
                let secWithDecimal = this._utilService.sizeOfNumber(this.acc.LatSec, 2, 2);
                this.txtLatSec.objTextbox.nativeElement.value = secWithDecimal;
            }

            if (this.txtLonSec && this.txtLonSec.objCurrentValue != RBISDataValue.Blank) {
                let secWithDecimal = this._utilService.sizeOfNumber(this.acc.LonSec, 2, 2);
                this.txtLonSec.objTextbox.nativeElement.value = secWithDecimal;
            }

            if (this.intMode == DBMode.MTSS || this.intMode == DBMode.PPSS ) { //Mode property is inherited from and initialized by base class
                this.txtAccMilePointWhole.intMaxLengthCode = 4; //TODO: this is a hack, we need to altter the DEFS values for the whole portion of Mile Point so that 9999.8 and 9999.9 can be distinguished by the whole portion.
                this.txtAccMilePointWhole.setMaxLengthOfCode();
                this.txtAccMilePointWhole.objTextbox.nativeElement.value = ('0'.repeat(4 - this.acc.MilePointWhole.toString().length) + this.acc.MilePointWhole);
            }

            this.intStateId = this.acc.StateNum;
            let objState: DrpDownOptions = arrStates.find(x => x.intValue == this.intStateId);

            if (objState)
                this.strStateName = objState.strText;

            this.latLonRangeValidationData();
        }).bind(this));

        this._sharedDataService.GetAppSettings().then(((appSetting: AppSettings) => {
            this._geoLocatorService.rootUrl = appSetting.geoLocatorUrl;
        }).bind(this));
    }

    clearForm() {
    }

    //MOH
    private hasMOSSSpecialLatLng(intCoordValue: number = null): boolean {
        intCoordValue = this.acc.LatDeg;
        if ((intCoordValue == CoordinateSpecial.LatNotApplicable) ||
            (intCoordValue == CoordinateSpecial.LatNotReported) ||
            (intCoordValue == CoordinateSpecial.LatUnknown))
            return true;

        return false;
    }

    //Trafficway tab is hidden and is a child component in CRSS mode.
    ////////*****Please be ensure about to put validation inside related mode inside code ****************//////
    /// Latitude and Longitute are only applicable fields in both FARS and CRSS mode in this component.
    public async onBeforeSave() {
        this.blnAlloweSave = false;

        this.clearOnlySpeficicRow();                
        this.deleteAddress();               

        if (this.intMode == DBMode.MOSS) {
            this.hasMOSSSpecialValueError = false;
            if (this.hasMOSSSpecialLatLng()) {
                this.hasMOSSSpecialValueError = true;
                this.validateLatLonRange();
            }
            else {
                if (!this.isGeoInfoIsEmpty() && !this.isSpecialLatLngNoBnl())
                    this.validateLatLonRange()                
                else
                    this.hasOutOfRangeError = false;                
            }               
        }
        else {
            if (!this.isGeoInfoIsEmpty() && !this.isSpecialLatLngNoBnl()) {
                this.validateLatLonRange()
            }
            else {
                this.hasOutOfRangeError = false;
            }
        }

        if (this.hasOutOfRangeError) {
            this.blnAlloweSave = false
            return;
        }

        if (!this.ValidateTrafficIdentifier(1)) {// validation for Traffic Identifiers is applicable only for FARS MODE
            this.blnAlloweSave = false;
            this._modalService.setMessage("Please check Traffic Identifier (1)", "danger");
            let UIControl = this._autofillService.arrControls.find(i => i.strFieldName === 'TrafID' && i.intSeqNum.toString() === "1");

            if (UIControl) {
                UIControl.setIsInvalidClass(true);
                UIControl.objTextbox.nativeElement.select();
                UIControl.objTextbox.nativeElement.click();
                UIControl.objTextbox.nativeElement.focus();
            }

            return;
        }

        if (!this.ValidateTrafficIdentifier(2)) {
            this.blnAlloweSave = false;
            this._modalService.setMessage("Please check Traffic Identifier (2)", "danger");
            let UIControl = this._autofillService.arrControls.find(i => i.strFieldName === 'TrafID' && i.intSeqNum.toString() === "2");

            if (UIControl) {
                UIControl.setIsInvalidClass(true);
                UIControl.objTextbox.nativeElement.select();
                UIControl.objTextbox.nativeElement.click();
                UIControl.objTextbox.nativeElement.focus();
            }

            return;
        }

        if (!this.isValueIsSameAsOld()) {
            this.updateLongLatValidation(false);
        }

        if (this.invalidLatLng() && !this.isGeoInfoIsEmpty()) {
            let response = this._modalService.dialog('Coordinates are blank, they could not have been validated', 'Information', 'M').toPromise();
            this.blnAlloweSave = false;
        } else {
            if (!this.isSpecialLatLngNoBnl() && !this.isGeoInfoIsEmpty()) {
                this._caseService.longLatStatus.subscribe((result => {
                    // if ((this._sharedService.blnIsEDTCase && this.isValueIsSameAsOld()) || (!this.isValueIsSameAsOld())) {
                    if ((result == null) || (result != null && !result.Verified)) {
                        if (this.intMode == DBMode.MOSS) { //Ignore Geolocator varification
                            this.blnAlloweSave = true;
                        }
                        else {
                            this.blnAlloweSave = false;
                            this._modalService.dialogPromise('Longitude/Latitude values are not verified. Do you want to verify them?', '  Warning  ', 'C').then(blnConfirmed => {
                                if (blnConfirmed) {
                                    this.onOpenGeoLocator();
                                }
                            });
                        }
                    }
                    else {
                        this.blnAlloweSave = true;
                    }
                    /*} else {
                        this.blnAlloweSave = true;
                    }*/
                }).bind(this)).unsubscribe();
            } else {
                this.blnAlloweSave = true;
            }
        }
        
        if (this.intMode == DBMode.CRSS) {
            if (!this.isSpecialLatLngNoBnl()) {
                this.validateLatLonRange()
            } else {
                this.hasOutOfRangeError = false;
            }

            if (this.hasOutOfRangeError) {
                this.blnAlloweSave = false
                return;
            } else {
                this.blnAlloweSave = true;
            }
        }

        if (this.blnAlloweSave) {
            this.setFirstOldValue();
        }
    }

    ValidateTrafficIdentifier(seqNum: number): boolean {
        //let strRegEXP = /^[a-zA-Z0-9\/-\s]{1,30}$/;
        let AccTrafID = this.acc.AccTrafID.find(x => x.SeqNum == seqNum).TrafID;
        let blnAccTrafID: boolean = true;

        if (AccTrafID) {
            blnAccTrafID = new RegExp(/^[a-zA-Z0-9\/-\s]{1,30}$/).test(AccTrafID);
        }
        return blnAccTrafID;
    }


    isGeoInfoIsEmpty() {
        return (
            this.acc.LatDeg == RBISDataValue.Blank &&
            this.acc.LatMin == RBISDataValue.Blank &&
            this.acc.LatSec == RBISDataValue.Blank &&
            this.acc.LonDeg == RBISDataValue.Blank &&
            this.acc.LonMin == RBISDataValue.Blank &&
            this.acc.LonSec == RBISDataValue.Blank &&
            (this.acc.ddmLongitudeMinutes == RBISDataValue.Minus1 || this.acc.ddmLongitudeMinutes == null || this.acc.ddmLongitudeMinutes == "") &&
            (this.acc.ddmLatitudeMinutes == RBISDataValue.Minus1 || this.acc.ddmLatitudeMinutes == null || this.acc.ddmLatitudeMinutes == "")
        )
    }

    private invalidLatLng(): boolean {
        if (
            (this.acc.LatDeg > 0 && (this.acc.LatMin == RBISDataValue.Blank || this.acc.LatMin == null || this.acc.LatSec == RBISDataValue.Blank || this.acc.LatSec == null))
            || (this.acc.LatMin > 0 && (this.acc.LatDeg == RBISDataValue.Blank || this.acc.LatDeg == null || this.acc.LatSec == RBISDataValue.Blank || this.acc.LatSec == null))
            || (this.acc.LatSec > 0 && (this.acc.LatDeg == RBISDataValue.Blank || this.acc.LatDeg == null || this.acc.LatMin == RBISDataValue.Blank || this.acc.LatMin == null))
        ) {
            return true;
        }
        else if (parseFloat(this.acc.ddmLatitudeMinutes) > 0 && this.acc.LatDeg == RBISDataValue.Blank) {
            return true;
        }

        if (
            (this.acc.LonDeg > 0 && (this.acc.LonMin == RBISDataValue.Blank || this.acc.LonMin == null || this.acc.LonSec == RBISDataValue.Blank || this.acc.LonSec == null))
            || (this.acc.LonMin > 0 && (this.acc.LonDeg == RBISDataValue.Blank || this.acc.LonDeg == null || this.acc.LonSec == RBISDataValue.Blank || this.acc.LonSec == null))
            || (this.acc.LonSec > 0 && (this.acc.LonDeg == RBISDataValue.Blank || this.acc.LonDeg == null || this.acc.LonMin == RBISDataValue.Blank || this.acc.LonMin == null))
        ) {
            return true;
        }
        else if (parseFloat(this.acc.ddmLongitudeMinutes) > 0 && this.acc.LonDeg == RBISDataValue.Blank) {
            return true;
        }
        return false;
    }

    setLatLngValidateStatus() {
        if (!this.isLatLngIsValidated && !this.isValueIsSameAsOld()) {
            this.updateLongLatValidation(false);
        }
    }

    deleteAddress() {
        if ((this.acc.LonMin == RBISDataValue.Blank && this.acc.LonDeg == RBISDataValue.Blank && this.acc.LonSec == RBISDataValue.Blank) && (this.acc.LatMin == RBISDataValue.Blank && this.acc.LatDeg == RBISDataValue.Blank && this.acc.LatSec == RBISDataValue.Blank)) {
            this.acc.GeolocatorAddress = '';
        }
    }

    calculateValue() {
        this._utilService.convertLatLngToDms(this.acc.ddLongitude, 'lat')
        this._utilService.convertLatLngToDms(this.acc.ddLongitude, 'lng');
        this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
        this.setDegDecimalMinutes(this._utilService.dmsConverted)
    }

    async ngOnInit() {

        super.ngOnInit();
        this.objUser = await this._authService.GetUserPromise();
        this.focuseOut = false;

        this.strMilePointClone = ('0'.repeat(4 - this.acc.MilePointWhole.toString().length) + this.acc.MilePointWhole) + '.' + this.acc.MilePointDecimal;

        // Removed Preference Logic - 
        //this.preferenceGeoLocatorPopup = await this._secUserPerferenceService.isThisPreferenceOn(Preference.GeoLocatorPopUp);
        //this.DMSFormatPrefered = await this._secUserPerferenceService.isThisPreferenceOn(Preference.LatLongDMSFormat);

        this.DMSFormatPrefered = true;
        //if (this.DMSFormatPrefered) {
            this.viewType = 'dms';
            this.lastViewType = 'dms';
        //}

        this.crashid = this.acc.AccID;

        this.accTrafID1 = this.acc.AccTrafID.find(x => x.SeqNum === 1) != null ? this.acc.AccTrafID.find(x => x.SeqNum === 1) : this._autofillService.AddTrafficWayIdentifier(this.acc, 1);
        this.accTrafID2 = this.acc.AccTrafID.find(x => x.SeqNum === 2) != null ? this.acc.AccTrafID.find(x => x.SeqNum === 2) : this._autofillService.AddTrafficWayIdentifier(this.acc, 2);

        this.countyId = this.acc.County;
        this.initDecimalDegreesFormat();
        this.setOldLongLatInfo();
        this.setFirstOldValue();


        this.longLatRetrive();
        this.setCountValidationRules();

        if (!this.printOnly && this.viewType == 'dd') {
            setTimeout(() => {
                if (this.txtLatitude) {
                    this.txtLatitude.focus$.subscribe(async () => {
                        await this._secUserPerferenceService.isThisPreferenceOn(Preference.GeoLocatorPopUp).then(item => {
                            this.preferenceGeoLocatorPopup = item;
                        });
                        if (this.preferenceGeoLocatorPopup && this.acc.LatDeg == RBISDataValue.Blank && !this.focuseOut) {
                            this.focuseOut = true;
                            this.onOpenGeoLocator();
                        }
                    })
                }
            }, 1000);
        }

        if (!this.printOnly && this.viewType == 'dms') {
            setTimeout(() => {
                if (this.txtAccLatDeg) {
                    this.txtAccLatDeg.focus$.subscribe(async () => {
                        await this._secUserPerferenceService.isThisPreferenceOn(Preference.GeoLocatorPopUp).then(item => {
                            this.preferenceGeoLocatorPopup = item;
                        });
                        if (this.preferenceGeoLocatorPopup && this.acc.LatDeg == RBISDataValue.Blank && !this.focuseOut) {
                            this.focuseOut = true;
                            this.onOpenGeoLocator();
                        }
                    })
                }
            }, 1000);
        }

        //this.evtSiblingsInitializedTrafficway = UIElementBase.evtSiblingsInitialized.subscribe((() => {
        //    this.evtSiblingsInitializedTrafficway.unsubscribe();
        //    console.log("Hello there!");        
        //}).bind(this));


        //Logic for utilizing ActionButtonsComponent Clear Form click for form element different then TypeAhead, MultiSelect or TextField
        this.subsClearLngLat = this._sharedDataService.subClearLngLatFields.subscribe(item => {
            if (item === "LongLat") {
                this.clearLatOnly();
                this.clearLngOnly();
                this.acc.ddLatitude = RBISDataValue.Minus1;
                this.acc.ddLongitude = RBISDataValue.Minus1;
                if (this.txtLongitude && this.txtLongitude.disabled) {
                    this.txtLongitude.disableOrEnableComponent(false);
                }
            }
        });

    };

    onLatitudeFocus($event) {
        if ($event == "" && this.preferenceGeoLocatorPopup && !this.focuseOut) {
            this.focuseOut = true;
            this.onOpenGeoLocator();
        }
    }

    clearOnlySpeficicRow() {
        if (this.isSpecialLatLngNoBnl()) return;

        if (DBMode.MOSS) {
            if ((this.acc.LatDeg == RBISDataValue.Blank && this.acc.LatMin == RBISDataValue.Blank && this.acc.LatSec == RBISDataValue.Blank)) {
                this.emptyLatLng();
                return;
            }
        }

        if ((this.acc.LatDeg == RBISDataValue.Blank && this.acc.LatMin == RBISDataValue.Blank && this.acc.LatSec == RBISDataValue.Blank)) {
            if (this.acc.ddmLatitudeMinutes == null ||
                this.acc.ddmLatitudeMinutes == RBISDataValue.Empty ||
                this.acc.ddmLatitudeMinutes == RBISDataValue.Minus1) {
                this.clearLatOnly();
            }
            else if (this.acc.ddmLatitudeMinutes != RBISDataValue.Minus1 && this.firstTimeInDDM) {
                this.clearLatOnly();
                this.firstTimeInDDM = false;
            }
        }
        else if ((this.acc.LatDeg == null && this.acc.ddmLatitudeMinutes == null) || ((this.acc.LatDeg == RBISDataValue.Blank) && (this.acc.ddmLatitudeMinutes == RBISDataValue.Empty || this.acc.ddmLatitudeMinutes == RBISDataValue.Minus1))) {
            this.clearLatOnly();
        }
        else if ((this.acc.ddLatitude == null || this.acc.ddLatitude == RBISDataValue.Empty || this.acc.ddLatitude == RBISDataValue.Minus1)) {
            if (this.viewType == 'dd') {
                this.clearLatOnly();
            } else {
                if ((this.acc.LatDeg == RBISDataValue.Blank && this.acc.LatMin == RBISDataValue.Blank && this.acc.LatSec == RBISDataValue.Blank)) {
                    this.clearLatOnly();
                }
            }

        }
        else if (this.acc.LatDeg == RBISDataValue.Blank && (this.acc.ddmLatitudeMinutes == RBISDataValue.Empty || this.acc.ddmLatitudeMinutes == RBISDataValue.Minus1)) {
            this.clearLatOnly();
        }
        else if (this.acc.LatDeg != RBISDataValue.Blank && (this.acc.ddmLatitudeMinutes == RBISDataValue.Empty || this.acc.ddmLatitudeMinutes == RBISDataValue.Minus1)) {
            if (this.lastViewType == 'ddm' || this.viewType == 'ddm') {
                this.acc.LatMin = RBISDataValue.Blank
                this.acc.LatSec = RBISDataValue.Blank
            }

        }
        else if ((this.acc.LatDeg != RBISDataValue.Blank && this.acc.LatMin == RBISDataValue.Blank && this.acc.LatSec == RBISDataValue.Blank)) {
            this.acc.ddmLatitudeMinutes = RBISDataValue.Empty
        }


        if ((this.acc.LonDeg == RBISDataValue.Blank && this.acc.LonMin == RBISDataValue.Blank && this.acc.LonSec == RBISDataValue.Blank)) {
            if (this.acc.ddmLongitudeMinutes == null ||
                this.acc.ddmLongitudeMinutes == RBISDataValue.Empty ||
                this.acc.ddmLongitudeMinutes === RBISDataValue.Minus1) {
                this.clearLngOnly();
            }
            else if (this.acc.ddmLongitudeMinutes !== RBISDataValue.Minus1 && this.firstTimeInDDM) {
                this.clearLngOnly();
            }
        } else if ((this.acc.LonDeg == null && this.acc.ddmLongitudeMinutes == null) || ((this.acc.LonDeg == RBISDataValue.Blank) && (this.acc.ddmLongitudeMinutes == RBISDataValue.Empty || this.acc.ddmLongitudeMinutes == RBISDataValue.Minus1))) {
            this.clearLngOnly();
        }
        else if ((this.acc.LonDeg == null || this.acc.LonDeg == RBISDataValue.Blank) && (this.acc.ddmLongitudeMinutes == RBISDataValue.Empty || this.acc.ddmLongitudeMinutes == RBISDataValue.Minus1)) {
            this.clearLngOnly();
        }
        else if ((this.acc.ddLongitude == null || this.acc.ddLongitude == RBISDataValue.Empty || this.acc.ddLongitude == RBISDataValue.Minus1)) {
            if (this.viewType == 'ddm' && this.acc.LonDeg == RBISDataValue.Blank && (this.acc.ddmLongitudeMinutes == RBISDataValue.Empty || this.acc.ddmLongitudeMinutes == RBISDataValue.Minus1)) {
                this.clearLngOnly();
            } else {

                if (this.acc.LonDeg != RBISDataValue.Blank && (this.acc.ddmLongitudeMinutes == RBISDataValue.Empty || this.acc.ddmLongitudeMinutes == RBISDataValue.Minus1)) {
                    if (this.viewType == 'ddm' || this.lastViewType == 'ddm') {
                        this.acc.LonMin = RBISDataValue.Blank
                        this.acc.LonSec = RBISDataValue.Blank
                    } else {
                        this.clearLngOnly();
                    }
                } else {
                    if (this.viewType == 'ddm' && this.acc.LonDeg != RBISDataValue.Blank && this.acc.ddmLongitudeMinutes != RBISDataValue.Empty) {
                        this.setValuesFromDDM();
                    } else {
                        if (this.intMode === DBMode.MOSS) {
                            //On Moss if user click Save case button before click validate geo location button
                            if (this.viewType == 'ddm' &&
                                ((this.acc.LonDeg == RBISDataValue.Blank || this.acc.LonDeg == null || this.acc.LonDeg == RBISDataValue.Blank) ||
                                (this.acc.LonMin == RBISDataValue.Blank || this.acc.LonMin == null || this.acc.LonMin == RBISDataValue.Blank) ||
                                (this.acc.LonSec == RBISDataValue.Blank || this.acc.LonSec == null || this.acc.LonSec == RBISDataValue.Blank))) {
                                this.clearLngOnly();
                            }
                        }
                        else {
                            this.clearLngOnly();
                        }
                    }
                }
                //if incase last control isn't fire force here to calculate
            }
        }
        else if (this.acc.LonDeg != RBISDataValue.Blank && (this.acc.ddmLongitudeMinutes == RBISDataValue.Empty || this.acc.ddmLongitudeMinutes == RBISDataValue.Minus1)) {
            if (this.viewType == 'ddm' || this.lastViewType == 'ddm') {
                this.acc.LonMin = RBISDataValue.Blank
                this.acc.LonSec = RBISDataValue.Blank
            }
        }
        else if ((this.acc.LonDeg != RBISDataValue.Blank && this.acc.LonMin == RBISDataValue.Blank && this.acc.LonSec == RBISDataValue.Blank)) {
            this.acc.ddmLongitudeMinutes = RBISDataValue.Empty
        }
        this.firstTimeInDDM = false;
    }

    setOldLongLatInfo() {
        this.oldLlongLatInfo.LatDeg = this.acc.LatDeg;
        this.oldLlongLatInfo.LatMin = this.acc.LatMin;
        this.oldLlongLatInfo.LatSec = this.acc.LatSec;
        this.oldLlongLatInfo.LonDeg = this.acc.LonDeg;
        this.oldLlongLatInfo.LonMin = this.acc.LonMin;
        this.oldLlongLatInfo.LonSec = this.acc.LonSec;

        this.oldLlongLatInfo.ddLatitude = this.acc.ddLatitude
        this.oldLlongLatInfo.ddLongitude = this.acc.ddLongitude;
    }

    setFirstOldValue() {
        this.firstOldValue.LatDeg = this.acc.LatDeg;
        this.firstOldValue.LatMin = this.acc.LatMin;
        this.firstOldValue.LatSec = this.acc.LatSec;
        this.firstOldValue.LonDeg = this.acc.LonDeg;
        this.firstOldValue.LonMin = this.acc.LonMin;
        this.firstOldValue.LonSec = this.acc.LonSec;
        this.firstOldValue.ddLatitude = this.acc.ddLatitude
        this.firstOldValue.ddLongitude = this.acc.ddLongitude

    }

    longLatRetrive() {
        this._caseService.longLatStatus.subscribe(result => {
            if (result == null) {
                this.longLatStatus.Verified == false;
            } else {
                this.longLatStatus = result;
                //this.isLatLngIsValidated = this.longLatStatus.Verified; 
            }
        }).unsubscribe()

    }

    showVerifyLongLatMessage() {

        if (this.oldLlongLatInfo.LatDeg == this.acc.LatDeg
            && this.oldLlongLatInfo.LatMin == this.acc.LatMin
            && this.oldLlongLatInfo.LatSec == this.acc.LatSec
            && this.oldLlongLatInfo.LonDeg == this.acc.LonDeg
            && this.oldLlongLatInfo.LonMin == this.acc.LonMin
            && this.oldLlongLatInfo.LonSec == this.acc.LonSec) {
            return false;
        }

        if (this.longLatStatus.Verified == false) {
            this._modalService.dialog('Long Lat has been changed, Please verify proceed.', 'Info', 'M').subscribe(result => {
                console.log('result: ', result);
                if (result != null) {
                    this.onOpenGeoLocator();
                }
            });
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    initBaseComponent() {
        setTimeout(() => {
            super.focusFirstOrBlankOrViolatedElement();
        }, 50);
    }

    setObjectValue($event: DrpDownOptions, fieldName: string) {
        super.setObjectValue($event, fieldName, this.acc);
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.subsClearLngLat)
            this.subsClearLngLat.unsubscribe();
    }

    setCountValidationRules() {
        this.countyValidationRules.push("000");
        this.countyValidationRules.push("997");
        this.countyValidationRules.push("999");
        this.countyValidationRules.push("998");
        this.countyValidationRules.push("0");
        this.countyValidationRules.push("null");
        this.countyValidationRules.push("-1");
    }

    setDegDecimalMinutes(result: any) {
        result.forEach((item) => {
            if (item.type == 'lat') {
                this.acc.ddmLatitudeMinutes = item.dMinutes;
                this.acc.LatDeg = item.degree;
                if (this.txtlatddmMinutes) {
                    this.txtlatddmMinutes.setCurrentValue(parseFloat(this.acc.ddmLatitudeMinutes).toFixed(4));
                    this.txtddmAccLatDeg.setCurrentValue(this.acc.LatDeg.toString());
                }
            } else {
                this.acc.LonDeg = item.degree;
                this.acc.ddmLongitudeMinutes = item.dMinutes;
                if (this.txtddmLonMin) {
                    this.txtddmLonMin.setCurrentValue(parseFloat(this.acc.ddmLongitudeMinutes).toFixed(4));
                    this.txtddmLonDec.setCurrentValue(this.acc.LonDeg.toString());
                }
            }
        });

        //  this.acc.ddLatitude = parseFloat(this._utilService.convertDmstoLatLng(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec, '')).toFixed(8).toString();
        //  this.acc.ddLongitude = parseFloat(this._utilService.convertDmstoLatLng(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec, '')).toFixed(8).toString();
    }

    /**
     * Clear all DD, DMS, DDM fields
     **/
    emptyLatLng() {
        this.acc.ddLatitude = RBISDataValue.Minus1;
        this.acc.ddLongitude = RBISDataValue.Minus1;
        this.acc.ddmLatitudeMinutes = RBISDataValue.Minus1;
        this.acc.ddmLongitudeMinutes = RBISDataValue.Minus1;

        this.acc.LatMin = RBISDataValue.Blank;
        this.acc.LatDeg = RBISDataValue.Blank;
        this.acc.LatSec = RBISDataValue.Blank;

        this.acc.LonMin = RBISDataValue.Blank;
        this.acc.LonDeg = RBISDataValue.Blank;
        this.acc.LonSec = RBISDataValue.Blank;

        if (this.txtLatSec)
            this.txtLatSec.objTextbox.nativeElement.value = "";
        if (this.txtLonSec)
            this.txtLonSec.objTextbox.nativeElement.value = "";
    }

    /**
     * Returns true when all cooridinate field groups, DD and DMS format, have at least one blank per field group
     **/
    private isEmptyLatLng(): boolean {
        if (this.acc.ddLatitude == RBISDataValue.Minus1 ||
            this.acc.ddLongitude == RBISDataValue.Minus1 ||
            this.acc.ddLatitude == null ||
            this.acc.ddLongitude == null
        ) {
            if (this.acc.LatDeg == RBISDataValue.Blank || this.acc.LatDeg == 0 ||
                this.acc.LatMin == RBISDataValue.Blank ||
                this.acc.LatSec == RBISDataValue.Blank || //Minutes and seconds could be zero
                this.acc.LatDeg == null || this.acc.LatMin == null || this.acc.LatSec == null)
                return true;

            if ((this.acc.LonDeg == RBISDataValue.Blank || this.acc.LonDeg == 0 ||
                this.acc.LonMin == RBISDataValue.Blank ||
                this.acc.LonSec == RBISDataValue.Blank) || //Minutes and seconds could be zero
                (this.acc.LonDeg == null || this.acc.LonDeg == null || this.acc.LonDeg == null))
                return true;
        }

        return false;
    }

    /**
     * Checks if the corrdinate value is one of the reserved values. If it is, it means that format conversion is not applicable.
     * For example 99 means "unknown" and should not be translated into minutes or seconds.
     */
    private isSpecialLatLng(intCoordValue: number = null, blnAllowBlank: boolean = false): boolean {
        if (intCoordValue == undefined || intCoordValue == null)
            intCoordValue = this.acc.LatDeg;

        return (intCoordValue == undefined && !blnAllowBlank) ||
            (intCoordValue == null && !blnAllowBlank) ||
            (intCoordValue == RBISDataValue.Blank && !blnAllowBlank) ||
            intCoordValue == CoordinateSpecial.LatNotApplicable ||
            intCoordValue == CoordinateSpecial.LatNotReported ||
            intCoordValue == CoordinateSpecial.LatUnknown;
    }

    private isSpecialLatLngNoBnl(intCoordValue: number = null, blnAllowBlank: boolean = false): boolean {
        if (intCoordValue == undefined || intCoordValue == null)
            intCoordValue = this.acc.LatDeg;
        return (
            intCoordValue == CoordinateSpecial.LatNotApplicable ||
            intCoordValue == CoordinateSpecial.LatNotReported ||
            intCoordValue == CoordinateSpecial.LatUnknown)
    }

    /**
     * Updates the DMS format, which are the values actually committed to the database.
     */
    private setDegreeMinuteSeconds(result: any, isOkay: boolean = true): void {

        result.forEach(((item) => {
            if (item.type == 'lat') {
                this.acc.LatDeg = item.degree;
                if (this.txtAccLatDeg) {
                    this.txtAccLatDeg.setCurrentValue(this.acc.LatDeg.toString())
                }
                this.acc.LatMin = item.minutes;
                this.acc.LatSec = parseFloat((item.seconds).toFixed(2))

                if (isOkay) {
                    this.acc.ddLatitude = parseFloat(this._utilService.dmsTodd(item.degree, item.minutes, item.seconds, '')).toFixed(8);
                }
            } else {
                this.acc.LonDeg = item.degree;
                this.acc.LonMin = item.minutes;
                this.acc.LonSec = parseFloat((item.seconds).toFixed(2))

                if (isOkay) {
                    this.acc.ddLongitude = parseFloat(this._utilService.dmsTodd(item.degree, item.minutes, item.seconds, '')).toFixed(8);
                }
            }
        }).bind(this));

        if (this.hasMinues) {
            this.acc.LonDeg = -this.acc.LonDeg;
        }
    }

    public async onOpenGeoLocator(blnGeolocatorSessionExists: boolean = false): Promise<void> {
        if (!blnGeolocatorSessionExists && !this._sharedDataService.blnGeolocatorSessionExists) {
            //When accessed for the first time, the GeoLocator app may issue a 302 redirect to MAX.GOV. MAX.GOV includes a header to not allow access via an IFRAME, which the browser will respect.
            //To get around this, we open GeoLocator in a full window (vs in an IFRAME) to let it establish a session. From then onward, GeoLocator can be nested in IFRAME as it will not longer redirect to MAX.GOV
            let winGeoLocatorSessionHelper: Window = window.open(this._geoLocatorService.rootUrl, '_blank');
            setTimeout((() => {
                if (!winGeoLocatorSessionHelper.closed)
                    winGeoLocatorSessionHelper.close();

                this._sharedDataService.blnGeolocatorSessionExists = true; //App Component will keep the Geolocator session cookie alive from this point by periodically issuing requests to Geolocator in the background
                this.onOpenGeoLocator(true);
            }).bind(this), 5000);
        }
        else {
            this.countyId = this.acc.County;

            if (this.intMode != DBMode.MOSS && !this.validateCounty()) {
                let response: boolean = await this._modalService.dialog('Invalid/Blank County code. Please enter a valid county and try again', 'Information', 'M').toPromise();
            }
            else {
                let TrafID1 = this.acc.AccTrafID[0] ? this.acc.AccTrafID[0].TrafID : '';
                let TrafID2 = this.acc.AccTrafID[1] ? this.acc.AccTrafID[1].TrafID : '';
                let tmpLat: string = '';
                let tmpLng: string = '';

                if (this.acc.ddLatitude != null && this.acc.ddLatitude != undefined && this.acc.ddLatitude != RBISDataValue.Minus1 && !this.isSpecialLatLng()) {
                    tmpLat = this.acc.ddLatitude;
                }

                if (this.acc.ddLongitude != null && this.acc.ddLongitude != undefined && this.acc.ddLongitude != RBISDataValue.Minus1 && !this.isSpecialLatLng()) {
                    tmpLng = '-' + this.acc.ddLongitude;
                }

                //Testing: Allow to open geolocator window without click Save case button if value is missing for MOSS - Moh
                if (this.intMode == DBMode.MOSS) { 
                    if (this.intMOSSGlobalPositionSeciton == 2 && tmpLat == "" && tmpLng =="" && !this.isSpecialLatLng()) {
                        tmpLat = this.acc.ddLatitude;
                        tmpLng = this.acc.ddLongitude;
                    }
                }                
                
                this._modalService_CannedPopups.geoLocator(
                    this.afterSlashRemover(TrafID1),
                    this.afterSlashRemover(TrafID2),
                    tmpLat,
                    tmpLng,
                    //null,
                    this.countyId.toString(),
                    this.intStateId.toString()
                ).pipe(take(1)).subscribe(((result: string) => {
                    this.resultString = result;

                    if (result == null || this.blnReadOnly || this.acc.Deleted
                        || (this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review || this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review_Complete)) {
                        this.isLatLngIsValidated = false;
                        this.wasVerifyMessageShown = false;
                        //this.updateLongLatValidation(false);
                    }
                    else {
                        this.wasVerifyMessageShown = false;
                        this.isLatLngIsValidated = true;
                        this.jsonManipulation(this._utilService.urlToJson(result));
                        setTimeout(() => {
                            this.isLatLngIsValidated = false; //waiting finish to render bindmodel
                        }, 50)

                    }
                }).bind(this));
            }
        }
    }

    validateCounty() {
        let validCounty = true;
        let countyValue = this.countyId.toString();

        this.countyValidationRules.forEach(item => {
            if (item == countyValue) {
                validCounty = false;
            }
        });

        return validCounty;
    }

    private afterSlashRemover(str: string): string {
        if (str == null) return '';
        if (!str || str == '')
            return '';

        let checkSlash = str.split('/');
        return checkSlash[0];
    }

    jsonManipulation(jsonResult: any) {
        this.geolocatorButton.nativeElement.focus();

        if (jsonResult.q != null) { //Geolocator result is encrypted
            this._utilService.decrypt(this.resultString).subscribe(((value: EncryptedData) => {
                let valueReceived: string = value.code;

                if (valueReceived != null && valueReceived != '') {
                    valueReceived = decodeURIComponent(valueReceived);

                    let fromEncryptedResult: any = this._utilService.urlToJson(valueReceived);

                    let lat = fromEncryptedResult.latitude;
                    let long = fromEncryptedResult.longitude;
                    let address = fromEncryptedResult.address;

                    this.acc.GeolocatorAddress = address; // adding address in the db

                    this._utilService.convertLatLngToDms(lat, 'lat')
                    this._utilService.convertLatLngToDms(long, 'lng');

                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
                    this.setDegDecimalMinutes(this._utilService.dmsConverted)
                    this.updateLongLatValidation(true);
                    this.setOldLongLatInfo();
                    this.setForceValue();
                    this.setFirstOldValue();
                    this.objForm.form.markAsDirty();
                    this.ngAfterViewInit();
                }

                console.log('valueReceived', valueReceived);
            }).bind(this));
        }
        else { //Geolocator result is plaintext JSON

            if (jsonResult.latitude == '' || jsonResult.longitude == '') return;

            if (jsonResult.latitude == CoordinateSpecial.ddNotApplicable ||
                jsonResult.latitude == CoordinateSpecial.ddNotReported ||
                jsonResult.latitude == CoordinateSpecial.ddUnknown) {
                return;
            }

            let tmpLongitude = jsonResult.longitude;

            if (jsonResult.longitude.includes('-')) {
                tmpLongitude = jsonResult.longitude.substring(1, jsonResult.longitude.length);
            }

            if (tmpLongitude == CoordinateSpecial.LonDecNotApplicable ||
                tmpLongitude == CoordinateSpecial.LonDecNotReported ||
                tmpLongitude == CoordinateSpecial.LonDecUnknown) {
                return;
            }
            this.acc.ddLatitude = jsonResult.latitude;
            this.acc.ddLongitude = tmpLongitude;
            let address = jsonResult.address;

            if (this.txtLatitude) {
                this.txtLatitude.setCurrentValue(this.acc.ddLatitude);
                this.txtLongitude.setCurrentValue(this.acc.ddLongitude);
                this.txtLongitude.disableOrEnableComponent(false);
            }

            if (address != '')
                this.acc.GeolocatorAddress = address; // adding address in the db

            if (this.acc.ddLatitude == RBISDataValue.Empty) {
                this.acc.ddLatitude = RBISDataValue.Empty;
                this.acc.ddLongitude = RBISDataValue.Empty;
            } else {
                this._utilService.dmsConverted = []
                this._utilService.ddToDms(this.acc.ddLatitude, 'lat')
                this._utilService.ddToDms(this.acc.ddLongitude, 'lng')
                //this._utilService.convertLatLngToDms(this.acc.ddLatitude, 'lat');
                //this._utilService.convertLatLngToDms(this.acc.ddLongitude, 'lng');

                this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
                this.setDegDecimalMinutes(this._utilService.dmsConverted);
                this.updateLongLatValidation(true);
                //this.isLatLngIsValidated = true;
                this.setForceValue();
                this.setFirstOldValue();
                this.objForm.form.markAsDirty();
                this.ngAfterViewInit();

            }
        }
    }

    bindModelValue($event, field) {
        if ($event != this[field]) {
            this[field] = $event;
            //this.changeType(this.viewType, null);
        }
    }

    specialValue(type: string, strNewValue: DrpDownOptions, objModel: any, strFieldName: string) {
        if (this.isLatLngIsValidated) return;
        if (type == 'dd' && strFieldName == 'ddLatitude' && (strNewValue != null && strNewValue.strValue == '-1') &&
            (this.oldLlongLatInfo.LatDeg == CoordinateSpecial.LatNotApplicable ||
                this.oldLlongLatInfo.LatDeg == CoordinateSpecial.LatNotReported ||
                this.oldLlongLatInfo.LatDeg == CoordinateSpecial.LatUnknown)
        ) {
            this.emptyLatLng();
            this.txtLongitude.disableOrEnableComponent(false);
        }
        else if (this.viewType == 'dd' && this.acc.ddLatitude != "" &&
            (this.acc.ddLatitude == CoordinateSpecial.ddNotApplicable ||
                this.acc.ddLatitude == CoordinateSpecial.ddNotReported ||
                this.acc.ddLatitude == CoordinateSpecial.ddUnknown)
        ) {
            this.setDefaultValueOnChange();
            this.txtLongitude.disableOrEnableComponent(true);
        }
        else {
            if (strNewValue == null && this.acc.ddLatitude != "") {
                // this.hasGeoValueChanged = true;
                this.updateFieldOnChange(strFieldName)
            }
            else {
                if (strNewValue != null && strNewValue.strValue == '-1') {
                    this.clearLatOnly();
                } else {
                    if (this.acc.ddLatitude != null && this.acc.ddLatitude != RBISDataValue.Empty && this.acc.ddLatitude != RBISDataValue.Minus1) {
                        /*let objCoordsLat: Coordinates_DMS = this._utilService.convertLatLngToDms(this.acc.ddLatitude, 'lat');
                        this.acc.LatDeg = objCoordsLat.degrees;
                        this.acc.LatMin = objCoordsLat.minutes;
                        this.acc.LatSec = objCoordsLat.seconds;*/
                        this._utilService.ddToDms(this.acc.ddLatitude, 'lat');
                        this.setDegreeMinuteSeconds(this._utilService.dmsConverted, false);
                        this.setDegDecimalMinutes(this._utilService.dmsConverted);
                    }
                    this.txtLongitude.disableOrEnableComponent(false)
                    /*
                    let objCoordsLon: Coordinates_DMS = this._utilService.convertLatLngToDms(this.acc.ddLongitude, 'lng');
                    this.acc.LonDeg = objCoordsLon.degrees;
                    this.acc.LonMin = objCoordsLon.minutes;
                    */
                }
            }
        }

        this.setOldLongLatInfo();
    }

    public changeType(type: string, strNewValue: string, objModel: any, strFieldName: string): void {
        this.lastViewType = this.viewType;
        this.viewType = type; //Changes which coordinate fields are visible
        this.isLatLngIsValidated = true;

        if (this.viewType == this.lastViewType && //View type is not changing
            this.viewType == 'dd' &&             //View type is not Degree Minute Seconds (DMS)
            <string>objModel[strFieldName] != strNewValue //Incoming value is user typing something, not the initial page load
        ) {
            if (this.acc.ddLatitude != CoordinateSpecial.ddUnknown &&
                this.acc.ddLatitude != CoordinateSpecial.ddNotReported &&
                this.acc.ddLatitude != CoordinateSpecial.ddUnknown) {
                let objCoordsLat: Coordinates_DMS = this._utilService.convertLatLngToDms(this.acc.ddLatitude, 'lat');
                this.acc.LatDeg = objCoordsLat.degrees;
                this.acc.LatMin = objCoordsLat.minutes;
                this.acc.LatSec = objCoordsLat.seconds;

                let objCoordsLon: Coordinates_DMS = this._utilService.convertLatLngToDms(this.acc.ddLongitude, 'lng');
                this.acc.LonDeg = objCoordsLon.degrees;
                this.acc.LonMin = objCoordsLon.minutes;
            }
        }
        else if (this.isEmptyLatLng() && this.isSpecialLatLng()) {// Do not attempt to convert empty, partially filled or special cases like Not Applicable, Not Reported, Unknown

        }
        else {
            this._utilService.dmsConverted = [];
        }
    }

    onClickDd(type: string, strNewValue: string) {
        this.lastViewType = this.viewType;
        this.viewType = type
        if (this.lastViewType == this.viewType) return;
        this._utilService.dmsConverted = [];
        this.isLatLngIsValidated = true;
        this.clearOnlySpeficicRow();
        if (this.isEmptyLatLng() && this.isSpecialLatLng()) {// Do not attempt to convert empty, partially filled or special cases like Not Applicable, Not Reported, Unknown

        }
        else if (this.lastViewType == 'dms') {

            if (this.acc.LatDeg != CoordinateSpecial.LatNotApplicable &&
                this.acc.LatDeg != CoordinateSpecial.LatNotReported &&
                this.acc.LatDeg != CoordinateSpecial.LatUnknown) {
                if (this.hasGeoValueChanged) {
                    this._utilService.dmsConverted = [];

                    if (this.acc.LatDeg != RBISDataValue.Blank) {
                        this.acc.ddLatitude = parseFloat(this._utilService.dmsTodd(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec, '')).toFixed(8).toString();
                    }
                    if (this.acc.LonDeg != RBISDataValue.Blank) {
                        this.acc.ddLongitude = parseFloat(this._utilService.dmsTodd(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec, '')).toFixed(8).toString();
                    }
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
                    this.hasGeoValueChanged = false;
                }
            }
        }
        else if (this.lastViewType == 'ddm') {
            if (this.acc.LatDeg != CoordinateSpecial.LatNotApplicable &&
                this.acc.LatDeg != CoordinateSpecial.LatNotReported &&
                this.acc.LatDeg != CoordinateSpecial.LatUnknown) {
               
                if (this.hasGeoValueChanged) {
                    if (this.acc.ddmLatitudeMinutes != RBISDataValue.Minus1 && this.acc.ddmLatitudeMinutes != RBISDataValue.Empty) {
                        this.acc.ddLatitude = this._utilService.ddmTodd(this.acc.LatDeg.toString(), this.acc.ddmLatitudeMinutes);
                    }
                    if (this.acc.ddmLongitudeMinutes != RBISDataValue.Minus1 && this.acc.ddmLongitudeMinutes != RBISDataValue.Empty) {
                        this.acc.ddLongitude = this._utilService.ddmTodd(this.acc.LonDeg.toString(), this.acc.ddmLongitudeMinutes)
                    }
                  
                }
            }
        }

        setTimeout(() => {
            this.isLatLngIsValidated = false;
        }, 10)
    }

    onDdmClick(type: string, strNewValue: string) {
        this.lastViewType = this.viewType;
        this.viewType = type
        if (this.lastViewType == this.viewType) return;
        this._utilService.dmsConverted = [];
        this.isLatLngIsValidated = true;
        this.firstTimeInDDM = true;
        this.clearOnlySpeficicRow();

        if (this.isEmptyLatLng() && this.isSpecialLatLng()) {// Do not attempt to convert empty, partially filled or special cases like Not Applicable, Not Reported, Unknown

        }
        else if (this.lastViewType == 'dd') {
            if (this.acc.ddLatitude != CoordinateSpecial.ddNotApplicable &&
                this.acc.ddLatitude != CoordinateSpecial.ddNotReported &&
                this.acc.ddLatitude != CoordinateSpecial.ddUnknown) {

                if (this.hasGeoValueChanged) {
                    this._utilService.dmsConverted = [];

                    if (this.acc.ddLatitude != RBISDataValue.Minus1 && this.acc.ddLatitude != RBISDataValue.Empty)
                        this._utilService.ddToDms(this.acc.ddLatitude, 'lat');

                    if (this.acc.ddLongitude != RBISDataValue.Minus1 && this.acc.ddLongitude != RBISDataValue.Empty)
                        this._utilService.ddToDms(this.acc.ddLongitude, 'lng');

                    this.setDegDecimalMinutes(this._utilService.dmsConverted);
                    this.hasGeoValueChanged = false;
                }
            }
        }
        else if (this.lastViewType == 'dms') {

            if (this.acc.LatDeg != CoordinateSpecial.LatNotApplicable &&
                this.acc.LatDeg != CoordinateSpecial.LatNotReported &&
                this.acc.LatDeg != CoordinateSpecial.LatUnknown) {
                if (this.hasGeoValueChanged) {
                    this._utilService.dmsConverted = [];

                    if (this.acc.LatDeg != RBISDataValue.Blank) {
                        this.acc.ddmLatitudeMinutes = this._utilService.dmsToDdm(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec).toFixed(4);
                    }

                    if (this.acc.LonDeg != RBISDataValue.Blank) {
                        this.acc.ddmLongitudeMinutes = this._utilService.dmsToDdm(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec).toFixed(4);
                    }

                    this.hasGeoValueChanged = false;
                }
            }
        }

        setTimeout(() => { //TODO: What is the purpose of this delay?
            this.isLatLngIsValidated = false;
        }, 10);
    }

    onDmsClick(type: string, strNewValue: string) {
        this.lastViewType = this.viewType;
        this.viewType = type
        if (this.lastViewType == this.viewType) return;
        this.clearOnlySpeficicRow();
        this._utilService.dmsConverted = [];
        this.isLatLngIsValidated = true;

        if (this.isEmptyLatLng() && this.isSpecialLatLng()) {// Do not attempt to convert empty, partially filled or special cases like Not Applicable, Not Reported, Unknown

        }
        else if (this.lastViewType == 'ddm') {
            if (this.acc.LatDeg != CoordinateSpecial.LatNotApplicable &&
                this.acc.LatDeg != CoordinateSpecial.LatNotReported &&
                this.acc.LatDeg != CoordinateSpecial.LatUnknown) {
                if (this.hasGeoValueChanged) {
                    this._utilService.dmsConverted = [];

                    if (this.acc.LatDeg != RBISDataValue.Blank && this.acc.LatDeg != RBISDataValue.Blank) {
                        this._utilService.ddmToDms(this.acc.LatDeg.toString(), this.acc.ddmLatitudeMinutes, 'lat')
                    }
                    if (this.acc.LonDeg != RBISDataValue.Blank && this.acc.LonDeg != RBISDataValue.Blank) {
                        this._utilService.ddmToDms(this.acc.LonDeg.toString(), this.acc.ddmLongitudeMinutes, 'lng')
                    }
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
                    this.hasGeoValueChanged = false
                }
            }
        }
        else if (this.lastViewType == 'dd') {
            this._utilService.dmsConverted = [];
            if (this.acc.ddLatitude != CoordinateSpecial.ddNotApplicable &&
                this.acc.ddLatitude != CoordinateSpecial.ddNotReported &&
                this.acc.ddLatitude != CoordinateSpecial.ddUnknown) {
                if (this.hasGeoValueChanged) {
                    if (this.acc.ddLatitude != RBISDataValue.Minus1 && this.acc.ddLatitude != RBISDataValue.Empty) {
                        this._utilService.ddToDms(this.acc.ddLatitude, 'lat');
                    }
                    if (this.acc.ddLongitude != RBISDataValue.Minus1 && this.acc.ddLongitude != RBISDataValue.Empty) {
                        this._utilService.ddToDms(this.acc.ddLongitude, 'lng');
                    }
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted, false);
                    this.hasGeoValueChanged = false
                }
            }
        }
        setTimeout(() => { this.isLatLngIsValidated = false; }, 10)
    }

    //Per confirmation with Khem on 04/22/2020 - The code commented out because of duplicates autofill rule and updating only objmodel not update value of dom element. 
    setDefaultValueOnChange(isFromLoad: boolean = false) {

        if (!isFromLoad) {
            if (this.acc.ddLatitude == '') {
                this.acc.ddLongitude = '';
                if (this.txtLongitude)
                    this.txtLongitude.setCurrentValue(this.acc.ddLongitude)
            }
            //else if (this.acc.LatDeg == CoordinateSpecial.LatNotApplicable) {

            //    this.acc.LatDeg = CoordinateSpecial.LatNotApplicable;
            //    this.acc.LatMin = CoordinateSpecial.LatNotApplicable;
            //    this.acc.LatSec = parseFloat(CoordinateSpecial.LatNotApplicable + '.' + CoordinateSpecial.LatNotApplicable);

            //    this.acc.LonDeg = CoordinateSpecial.LonNotApplicable;
            //    this.acc.LonMin = CoordinateSpecial.LatNotApplicable
            //    this.acc.LonSec = parseFloat(CoordinateSpecial.LatNotApplicable + '.' + CoordinateSpecial.LatNotApplicable);

            //    this.acc.ddLongitude = CoordinateSpecial.LonDecNotApplicable;

            //    this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmSecNotApplicable;

            //    this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmSecNotApplicable;
            //    this.acc.ddLatitude = CoordinateSpecial.ddNotApplicable
            //}
            //else if (this.acc.LatDeg == CoordinateSpecial.LatNotReported) {
            //    this.acc.ddLatitude = CoordinateSpecial.ddNotReported
            //    this.acc.LatDeg = CoordinateSpecial.LatNotReported;
            //    this.acc.LatMin = CoordinateSpecial.LatNotReported;
            //    this.acc.LatSec = parseFloat(CoordinateSpecial.LatNotReported + '.' + CoordinateSpecial.LatNotReported);

            //    this.acc.LonDeg = CoordinateSpecial.LonNotReported;
            //    this.acc.LonMin = CoordinateSpecial.LatNotReported
            //    this.acc.LonSec = parseFloat(CoordinateSpecial.LatNotReported + '.' + CoordinateSpecial.LatNotReported);

            //    this.acc.ddLongitude = CoordinateSpecial.LonDecNotReported;

            //    this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmSecNotReported;;

            //    this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmSecNotReported;

            //}
            //else if (this.acc.LatDeg == CoordinateSpecial.LatUnknown) {
            //    this.acc.LatDeg = CoordinateSpecial.LatUnknown;
            //    this.acc.LatMin = CoordinateSpecial.LatUnknown;
            //    this.acc.LatSec = parseFloat(CoordinateSpecial.LatUnknown + '.' + CoordinateSpecial.LatUnknown);

            //    this.acc.LonDeg = CoordinateSpecial.LonUnknown;
            //    this.acc.LonMin = CoordinateSpecial.LatUnknown
            //    this.acc.LonSec = parseFloat(CoordinateSpecial.LatUnknown + '.' + CoordinateSpecial.LatUnknown);

            //    this.acc.ddLongitude = CoordinateSpecial.LonDecUnknown;

            //    this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmSecUnknown
            //    this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmSecUnknown;
            //    this.acc.ddLatitude = CoordinateSpecial.ddUnknown
            //}
            else if (this.acc.ddLatitude == CoordinateSpecial.ddNotApplicable) {

                this.acc.LatDeg = CoordinateSpecial.LatNotApplicable;
                this.acc.LatMin = CoordinateSpecial.LatNotApplicable;
                this.acc.LatSec = parseFloat(CoordinateSpecial.LatNotApplicable + '.' + CoordinateSpecial.LatNotApplicable);

                this.acc.LonDeg = CoordinateSpecial.LonNotApplicable;
                this.acc.LonMin = CoordinateSpecial.LatNotApplicable
                this.acc.LonSec = parseFloat(CoordinateSpecial.LatNotApplicable + '.' + CoordinateSpecial.LatNotApplicable);

                this.acc.ddLongitude = CoordinateSpecial.LonDecNotApplicable;

                this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesNotApplicable;

                this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesNotApplicable;
                this.acc.ddLatitude = CoordinateSpecial.ddNotApplicable
            }
            else if (this.acc.ddLatitude == CoordinateSpecial.ddNotReported) {
                this.acc.ddLatitude = CoordinateSpecial.ddNotReported
                this.acc.LatDeg = CoordinateSpecial.LatNotReported;
                this.acc.LatMin = CoordinateSpecial.LatNotReported;
                this.acc.LatSec = parseFloat(CoordinateSpecial.LatNotReported + '.' + CoordinateSpecial.LatNotReported);

                this.acc.LonDeg = CoordinateSpecial.LonNotReported;
                this.acc.LonMin = CoordinateSpecial.LatNotReported
                this.acc.LonSec = parseFloat(CoordinateSpecial.LatNotReported + '.' + CoordinateSpecial.LatNotReported);

                this.acc.ddLongitude = CoordinateSpecial.LonDecNotReported;

                this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesNotReported;;

                this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesNotReported;

            }
            else if (this.acc.ddLatitude == CoordinateSpecial.ddUnknown) {
                this.acc.LatDeg = CoordinateSpecial.LatUnknown;
                this.acc.LatMin = CoordinateSpecial.LatUnknown;
                this.acc.LatSec = parseFloat(CoordinateSpecial.LatUnknown + '.' + CoordinateSpecial.LatUnknown);

                this.acc.LonDeg = CoordinateSpecial.LonUnknown;
                this.acc.LonMin = CoordinateSpecial.LatUnknown
                this.acc.LonSec = parseFloat(CoordinateSpecial.LatUnknown + '.' + CoordinateSpecial.LatUnknown);

                this.acc.ddLongitude = CoordinateSpecial.LonDecUnknown;

                this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesUnknown
                this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesUnknown;
                this.acc.ddLatitude = CoordinateSpecial.ddUnknown
            } else {
                if (this.txtLongitude)
                    this.txtLongitude.disabled = false;
            }
        }
        else {
            // if trying to reset value from existing save.
            if (this.acc.LatDeg == CoordinateSpecial.LatNotApplicable) {
                this.acc.ddLatitude = CoordinateSpecial.ddNotApplicable
                this.acc.ddLongitude = CoordinateSpecial.LonDecNotApplicable;
            }

            if (this.acc.LatDeg == CoordinateSpecial.LatNotReported) {
                this.acc.ddLatitude = CoordinateSpecial.ddNotReported;
                this.acc.ddLongitude = CoordinateSpecial.LonDecNotReported;
            }

            if (this.acc.LatDeg == CoordinateSpecial.LatUnknown) {
                this.acc.ddLatitude = CoordinateSpecial.ddUnknown;
                this.acc.ddLongitude = CoordinateSpecial.LonDecUnknown;
            }
        }
    }

    setLatLngValue() {
        this._utilService.dmsConverted = [];

        this._utilService.convertLatLngToDdm(this.acc.ddLatitude, 'lat')
        this._utilService.convertLatLngToDdm(this.acc.ddLongitude, 'lng');

        this.setDegDecimalMinutes(this._utilService.dmsConverted);

        this.setDegreeMinuteSeconds(this._utilService.dmsConverted, false);
    }

    openLatLongConvertor(content) {
        //resenting initial default data
        this.geoValidationMessage = '';
        this.disableSubmit = false;
        this.viewType = 'dd';
        //end of reseting initial data

        this.initDecimalDegreesFormat();

        this._modalService.openCommonSmallModal(content, 'window-size').pipe(
            take(1)
        ).subscribe(result => {
            if (result == 'ok') {
                this._utilService.dmsConverted = [];
                this._utilService.convertLatLngToDms(this.acc.ddLatitude, 'lat')
                this._utilService.convertLatLngToDms(this.acc.ddLongitude, 'lng');
                this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
            }
        });
    }

    private initDecimalDegreesFormat(): void {
        if (this.acc.LatDeg == undefined || this.acc.LatDeg == null || this.acc.LatDeg == RBISDataValue.Blank)
            this.acc.ddLatitude = RBISDataValue.Minus1;
        else if (this.acc.LatDeg == CoordinateSpecial.LatNotApplicable) {

            this.acc.ddLatitude = CoordinateSpecial.ddNotApplicable;
            this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesNotApplicable;
            this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesNotApplicable;
        }
        else if (this.acc.LatDeg == CoordinateSpecial.LatNotReported) {
            this.acc.ddLatitude = CoordinateSpecial.ddNotReported;
            this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesNotReported;
            this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesNotReported;
        }
        else if (this.acc.LatDeg == CoordinateSpecial.LatUnknown) {
            this.acc.ddLatitude = CoordinateSpecial.ddUnknown;
            this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesUnknown;
            this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesUnknown;
        }

        else {
            this.acc.ddmLatitudeMinutes = this._utilService.dmsToDdm(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec).toFixed(4);
            this.acc.ddLatitude = parseFloat(this._utilService.dmsTodd(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec, '')).toFixed(8);
        }


        if (this.acc.LonDeg == undefined || this.acc.LonDeg == null || this.acc.LonDeg == RBISDataValue.Blank)
            this.acc.ddLongitude = RBISDataValue.Minus1;
        else if (this.acc.LonDeg == CoordinateSpecial.LonNotApplicable) {
            this.acc.ddLongitude = CoordinateSpecial.LonDecNotApplicable;
            this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesNotApplicable;
            this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesNotApplicable;
        }
        else if (this.acc.LonDeg == CoordinateSpecial.LonNotReported) {
            this.acc.ddLongitude = CoordinateSpecial.LonDecNotReported;
            this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesNotReported;
            this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesNotReported;
        }

        else if (this.acc.LonDeg == CoordinateSpecial.LonUnknown) {
            this.acc.ddLongitude = CoordinateSpecial.LonDecUnknown;
            this.acc.ddmLatitudeMinutes = CoordinateSpecial.ddmMinutesUnknown;
            this.acc.ddmLongitudeMinutes = CoordinateSpecial.ddmMinutesUnknown;
        }
        else {
            this.acc.ddmLongitudeMinutes = this._utilService.dmsToDdm(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec).toFixed(4);
            this.acc.ddLongitude = parseFloat(this._utilService.dmsTodd(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec, '')).toFixed(8);
        }
    }

    /**
     * Initializes DDM and DMS coordinate formats from the DD coordinate value
     **/
    private setDefaultDDAndDDM(): void {
        this._utilService.convertLatLngToDdm(this.acc.ddLatitude, 'lat')
        this._utilService.convertLatLngToDdm(this.acc.ddLongitude, 'lng');

        this.setDegDecimalMinutes(this._utilService.dmsConverted);

        this.setDegreeMinuteSeconds(this._utilService.dmsConverted, true);
    }

    setForceDMS(num) {
        this.acc.LatMin = num;
        this.acc.LatSec = num;

        this.acc.LonDeg = num;
        this.acc.LonMin = num;
        this.acc.LonSec = num;
    }

    focusNextInput() {
        setTimeout(() => {
            let inputElements = window.document.querySelectorAll('input');
            var focusInputElements = Array.prototype.filter.call(inputElements,
                function (element) {
                    return (element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement)
                        && (element.hidden == false && element.disabled == false);
                });

            let index = focusInputElements.indexOf(document.activeElement);
            let nextElement = focusInputElements[index + 1];

            if (index < focusInputElements.length - 1) {
                if (nextElement.value.length > 0) {
                    nextElement.select();
                }

                nextElement.focus();
            }
        }, 30);
    }

    onKeyDown(event) {
        if (this.isEmptyLatLng())
            return;

        if (event.key == 'Enter') {
            this.focusNextInput()
            this.updateLongLatValidation(false);
            this.showVerifyLongLatMessage();
            event.preventDefault();
        } else {
            if (event.key != 'Tab') {
                this.disableSubmit = false;
                this.validateSuccessMessage = '';
                let currentValue = event.target.value;
                let newValue = currentValue.concat(event.key);

                if (newValue && !String(newValue).match(this.regex[newValue])) {
                    event.preventDefault();
                }
            } else {
                this.updateLongLatValidation(false);
                this.showVerifyLongLatMessage();
                // event.preventDefault();
            }
        }
    }

    /**
     * Milepoint is a special field in that the placeholder values for "unknown" and "not reported" both begin with 9999
     * and cannot be coded in the metadata table for the MilePointWhole field. Thus, requiring a workaround like this method.
     */
    public OnChange_MilePointWhole(objSelection: DrpDownOptions): void {
        setTimeout((() => { //Time delay needed because we're modifying the field that raised the event and it's not yet done binding the current selection
            if (objSelection && objSelection.strValue) { // we added condition by checking if curent value is same as clone one, so we will skip OnChange_MilePointWhole() at page initiate;
                //Value 0 requires care to avoid infinite loop
                if (objSelection.strValue == MilePointWhole.None) {
                    this.txtAccMilePointWhole.setCurrentValue('0000');
                    this.txtAccMilePointWhole.objTextbox.nativeElement.value = '0000'; //DEFS value is a special case, it is 5 digits long, but actual maxlength is 4. Leading zero formatting logic needs to be overruled.
                    this.txtAccMilePointDecimal.bindItemAndFocusNextElement(this.txtAccMilePointDecimal.options.find(x => x.intValue == 0), true);
                    this.strMilePointClone = objSelection.strValue;
                }
                else if (objSelection.strValue == MilePointWhole.NotReported) {
                    this.txtAccMilePointWhole.setCurrentValue('9999');
                    this.txtAccMilePointWhole.objTextbox.nativeElement.value = '9999'; //DEFS value is a special case, it is 5 digits long, but actual maxlength is 4. Leading zero formatting logic needs to be overruled.
                    this.txtAccMilePointDecimal.bindItemAndFocusNextElement(this.txtAccMilePointDecimal.options.find(x => x.intValue == 8), true);
                    this.strMilePointClone = objSelection.strValue;
                }
                else if (objSelection.strValue == MilePointWhole.Unknown) {
                    this.txtAccMilePointWhole.setCurrentValue('9999');
                    this.txtAccMilePointWhole.objTextbox.nativeElement.value = '9999'; //DEFS value is a special case, it is 5 digits long, but actual maxlength is 4. Leading zero formatting logic needs to be overruled.
                    this.txtAccMilePointDecimal.bindItemAndFocusNextElement(this.txtAccMilePointDecimal.options.find(x => x.intValue == 9), true);
                    this.strMilePointClone = objSelection.strValue;
                }
            }
            else
                this.strMilePointClone = ('0'.repeat(4 - this.acc.MilePointWhole.toString().length) + this.acc.MilePointWhole) + '.' + this.acc.MilePointDecimal;
        }).bind(this), 1);
    }

    public OnChange_MilePointDecimal(objSelection: DrpDownOptions): void {
        this.strMilePointClone = ('0'.repeat(4 - this.acc.MilePointWhole.toString().length) + this.acc.MilePointWhole) + '.' + this.acc.MilePointDecimal;
    }

    private regex = {
        number: new RegExp(/^\d+$/),
        decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
    };

    updateLongLatValidation(status: boolean = false) {
        this.longLatStatus = AutofillService.AddModelObject(this.acc, 'Acc', 'LongLatInfo');
        this.longLatStatus.UserID = this.objUser.UserId;
        this.longLatStatus.VerifyDate = new Date();
        this.longLatStatus.Verified = status;
        this._caseService.UpdateLongLatStatus(this.longLatStatus);
    }

    public changeValue(field: string, value: string, event: any, objModel: any): void {
         //Before we plug in the user input into the arithmetic logic for converting DD, DMS, and DDM formats, we want to do a basic sanity check
        if (field == 'ddLatitude' && Math.abs(parseInt(this.acc.ddLatitude)) >= 90 && this.acc.ddLatitude != CoordinateSpecial.ddNotApplicable && this.acc.ddLatitude != CoordinateSpecial.ddNotReported && this.acc.ddLatitude != CoordinateSpecial.ddUnknown)
            this._modalService.setMessage('Latitude degrees cannot be greater than 90. Please check the entered value.', 'danger');

        if (field == 'ddLongitude' && Math.abs(parseInt(this.acc.ddLongitude)) >= 180 && this.acc.ddLongitude != CoordinateSpecial.LonDecNotApplicable && this.acc.ddLongitude != CoordinateSpecial.LonDecNotReported && this.acc.ddLongitude != CoordinateSpecial.LonDecUnknown)
            this._modalService.setMessage('Longitude degrees cannot be greater than 180. Please check the entered value.', 'danger');

        if ((field == 'ddmLatitudeMinutes'  && Math.abs(parseInt(this.acc.ddmLatitudeMinutes))  >= 60 && this.acc.ddmLatitudeMinutes  != CoordinateSpecial.ddmMinutesNotApplicable && this.acc.ddmLatitudeMinutes  != CoordinateSpecial.ddmMinutesNotReported && this.acc.ddmLatitudeMinutes  != CoordinateSpecial.ddmMinutesUnknown) ||
            (field == 'ddmLongitudeMinutes' && Math.abs(parseInt(this.acc.ddmLongitudeMinutes)) >= 60 && this.acc.ddmLongitudeMinutes != CoordinateSpecial.ddmMinutesNotApplicable && this.acc.ddmLongitudeMinutes != CoordinateSpecial.ddmMinutesNotReported && this.acc.ddmLongitudeMinutes != CoordinateSpecial.ddmMinutesUnknown))
            this._modalService.setMessage('Minute portion cannot be greater than 60. Please check the entered value.', 'danger');

        if (this.isLatLngIsValidated)
            return;

       

        if (value == null) {
            this.acc[field] = -1;
        }
        else if ((field == 'LatDeg' || field == 'ddmLatDeg') && (event != null && event.strValue == '-1') &&
            (this.oldLlongLatInfo.LatDeg == CoordinateSpecial.LatNotApplicable ||
             this.oldLlongLatInfo.LatDeg == CoordinateSpecial.LatNotReported ||
             this.oldLlongLatInfo.LatDeg == CoordinateSpecial.LatUnknown)
        ) {
            if (field == 'ddmLatDeg')
                this.setForceValueForDdm();

            this.emptyLatLng();
               }
        else if ((field == 'LatDeg' || field == 'ddmLatDeg') &&
            (this.acc.LatDeg == CoordinateSpecial.LatNotApplicable ||
             this.acc.LatDeg == CoordinateSpecial.LatNotReported ||
             this.acc.LatDeg == CoordinateSpecial.LatUnknown)
        ) {
            if (event.strValue == '-1') {
                if (field == 'ddmLatDeg')
                    this.setForceValueForDdm();

                this.emptyLatLng();
            } else {
                this.setDefaultValueOnChange()
            }
                 }
        else if ((field == 'LatDeg' || field == 'ddmLatDeg') && event != null && event == '-1') {
            this.clearLatOnly();
            }
        else if (field == 'LonDeg' && this.acc.LonDeg == -1 && (this.acc.ddmLongitudeMinutes == '-1' || this.acc.ddmLongitudeMinutes == '')) {
            this.clearLngOnly();
        }
        else if (field == 'ddLongitude' && this.acc.LonDeg == -1 && this.acc.ddmLongitudeMinutes == '') {
            this.clearLngOnly();
        }
        else if (field == 'ddmLongitudeMinutes' && this.acc.LonDeg == -1 && event != null && event == '-1') {
            this.clearLngOnly();
        }
        else if (field == 'LatSec')
        {
            if (this.acc.LatSec != RBISDataValue.Blank) {
                let secWithDecimal = this._utilService.sizeOfNumber(this.acc.LatSec, 2, 2);
                this.txtLatSec.objTextbox.nativeElement.value = secWithDecimal;

                if (this.intMode == DBMode.MOSS) //Moh
                    this.updateFieldOnChange(field);
            }
        }
        else if (field == 'LonSec') {
            if (this.acc.LonSec != RBISDataValue.Blank) {
                let secWithDecimal = this._utilService.sizeOfNumber(this.acc.LonSec, 2, 2);
                this.txtLonSec.objTextbox.nativeElement.value = secWithDecimal;

                if (this.intMode == DBMode.MOSS) //Moh
                    this.updateFieldOnChange(field); 
            }
        }
        else if (value != '' && value != '-1') {
            // this.hasGeoValueChanged = true;
            this.updateFieldOnChange(field);
        }

        this.setOldLongLatInfo();
    }


    /**
     * Clears Degrees Decimal Minutes (DDM) fields
     **/
    setForceValueForDdm() {
        this.txtddmAccLatDeg.setCurrentValue('-1');
        this.txtlatddmMinutes.setCurrentValue('-1');
        this.txtddmLonDec.setCurrentValue('-1');
        this.txtddmLonMin.setCurrentValue('-1');
    }

    clearLatOnly() {
        this.acc.ddLatitude = RBISDataValue.Minus1;
        this.acc.LatMin = RBISDataValue.Blank;
        this.acc.LatDeg = RBISDataValue.Blank;
        this.acc.LatSec = RBISDataValue.Blank;
        this.acc.ddmLatitudeMinutes = RBISDataValue.Minus1;

        if (this.txtlatddmMinutes)
            this.txtlatddmMinutes.setCurrentValue(this.acc.ddmLatitudeMinutes);

        // if (this.txtLatitude) this.txtLatitude.setCurrentValue(this.acc.ddLatitude);
    }

    clearLngOnly() {
        this.acc.ddLongitude = RBISDataValue.Minus1;
        this.acc.ddmLongitudeMinutes = RBISDataValue.Minus1;
        this.acc.LonMin = RBISDataValue.Blank;
        this.acc.LonDeg = RBISDataValue.Blank;
        this.acc.LonSec = RBISDataValue.Blank;
        if (this.txtddmLonMin) this.txtddmLonMin.setCurrentValue(this.acc.ddmLongitudeMinutes);
        if (this.txtLongitude) this.txtLongitude.setCurrentValue(this.acc.ddLongitude);
    }

    updateFieldOnChange(field: string) {        
        if (this.isLatLngIsValidated) {
            this.numberOfFieldUpdate = this.numberOfFieldUpdate + 1;
        }
        this._utilService.dmsConverted = []
        if (field == 'ddLatitude') {
            if (this.acc.ddLatitude != RBISDataValue.Minus1 && this.acc.ddLatitude != RBISDataValue.Empty) {
                if (this.acc.ddLatitude != CoordinateSpecial.ddNotApplicable &&
                    this.acc.ddLatitude != CoordinateSpecial.ddNotReported &&
                    this.acc.ddLatitude != CoordinateSpecial.ddUnknown) {
                    this._utilService.ddToDms(this.acc.ddLatitude, 'lat');
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted, false);
                    this.setDegDecimalMinutes(this._utilService.dmsConverted);
                }
            }
        }
        if (field == 'ddLongitude') {
            if (this.acc.ddLongitude != RBISDataValue.Minus1 && this.acc.ddLongitude != RBISDataValue.Empty) {
                if (this.acc.ddLongitude != CoordinateSpecial.LonDecNotApplicable &&
                    this.acc.ddLongitude != CoordinateSpecial.LonDecNotReported &&
                    this.acc.ddLongitude != CoordinateSpecial.LonDecUnknown) {
                    this._utilService.ddToDms(this.acc.ddLongitude, 'lng');
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted, false);
                    this.setDegDecimalMinutes(this._utilService.dmsConverted);
                }
            }
        }

        if (field == 'LatDeg' || field == 'LatMin' || field == 'LatSec') {
            this.intMOSSGlobalPositionSeciton = 2;
            //set to ddm and dd latitute only
            if (this.acc.LatDeg != CoordinateSpecial.LatNotApplicable &&
                this.acc.LatDeg != CoordinateSpecial.LatNotReported &&
                this.acc.LatDeg != CoordinateSpecial.LatUnknown) {
                if (this.acc.LatDeg != RBISDataValue.Blank && this.acc.LatMin != RBISDataValue.Blank && this.acc.LatSec != RBISDataValue.Blank) {
                    this.acc.ddmLatitudeMinutes = this._utilService.dmsToDdm(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec).toFixed(4);
                    this.acc.ddLatitude = parseFloat(this._utilService.dmsTodd(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec, '')).toFixed(8).toString();
                }
            }
        }

        if (field == 'ddmLatDeg') {

            //set to ddm and dd latitute only
            if (this.acc.LatDeg != CoordinateSpecial.LatNotApplicable &&
                this.acc.LatDeg != CoordinateSpecial.LatNotReported &&
                this.acc.LatDeg != CoordinateSpecial.LatUnknown) {
                if (this.acc.LatDeg != RBISDataValue.Blank && this.acc.ddmLatitudeMinutes != RBISDataValue.Minus1 && this.acc.ddmLatitudeMinutes != RBISDataValue.Empty) {
                    //  this.acc.ddmLatitudeMinutes = this._utilService.dmsToDdm(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec).toFixed(4);
                    // this.acc.ddLatitude = parseFloat(this._utilService.dmsTodd(this.acc.LatDeg, this.acc.LatMin, this.acc.LatSec, '')).toFixed(8).toString();
                    this.acc.ddLatitude = this._utilService.ddmTodd(this.acc.LatDeg.toString(), this.acc.ddmLatitudeMinutes);
                    this._utilService.ddmToDms(this.acc.LatDeg.toString(), this.acc.ddmLatitudeMinutes, 'lat')
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
                }
            }
        }

        if (field == 'LonDeg' || field == 'LonMin' || field == 'LonSec') {
            this.intMOSSGlobalPositionSeciton = 2;
            //set to ddm and dd longitude only
            if (this.acc.LonDeg != CoordinateSpecial.LonNotApplicable &&
                this.acc.LonDeg != CoordinateSpecial.LonNotReported &&
                this.acc.LonDeg != CoordinateSpecial.LonUnknown) {
                if (this.acc.LonDeg != RBISDataValue.Blank && this.acc.LonMin != RBISDataValue.Blank && this.acc.LonSec != RBISDataValue.Blank) {
                    this.acc.ddmLongitudeMinutes = this._utilService.dmsToDdm(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec).toFixed(4);
                    this.acc.ddLongitude = parseFloat(this._utilService.dmsTodd(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec, '')).toFixed(8).toString();
                }
            }
        }

        if (field == 'ddmLonDeg') {
            //set to ddm and dd longitude only
            if (this.acc.LonDeg != CoordinateSpecial.LonNotApplicable &&
                this.acc.LonDeg != CoordinateSpecial.LonNotReported &&
                this.acc.LonDeg != CoordinateSpecial.LonUnknown) {
                if (this.acc.LonDeg != RBISDataValue.Blank && this.acc.ddmLongitudeMinutes != RBISDataValue.Minus1 && this.acc.ddmLongitudeMinutes != RBISDataValue.Empty) {
                    // this.acc.ddmLongitudeMinutes = this._utilService.dmsToDdm(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec).toFixed(4);
                    // this.acc.ddLongitude = parseFloat(this._utilService.dmsTodd(this.acc.LonDeg, this.acc.LonMin, this.acc.LonSec, '')).toFixed(8).toString();

                    this.acc.ddLatitude = this._utilService.ddmTodd(this.acc.LatDeg.toString(), this.acc.ddmLatitudeMinutes);
                    this._utilService.ddmToDms(this.acc.LatDeg.toString(), this.acc.ddmLatitudeMinutes, 'lat')
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
                }
            }
        }
        if (field == 'ddmLatitudeMinutes') {
            // update on dms Latitude and dd
            if (this.acc.LatDeg != CoordinateSpecial.LatNotApplicable &&
                this.acc.LatDeg != CoordinateSpecial.LatNotReported &&
                this.acc.LatDeg != CoordinateSpecial.LatUnknown && this.acc.LatDeg != RBISDataValue.Blank) {
                if (this.acc.ddmLatitudeMinutes != RBISDataValue.Minus1 && this.acc.ddmLatitudeMinutes != RBISDataValue.Empty) {
                    this.acc.ddLatitude = this._utilService.ddmTodd(this.acc.LatDeg.toString(), this.acc.ddmLatitudeMinutes);
                    this._utilService.ddmToDms(this.acc.LatDeg.toString(), this.acc.ddmLatitudeMinutes, 'lat')
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
                } else {
                    if (this.acc.LatDeg == RBISDataValue.Blank && this.acc.ddmLatitudeMinutes == RBISDataValue.Empty) {
                        this.clearLngOnly();
                    }
                }
            }
        }

        if (field == 'ddmLongitudeMinutes') {
            // update on dms Longitude and dd
            if (this.acc.LonDeg != CoordinateSpecial.LonNotApplicable &&
                this.acc.LonDeg != CoordinateSpecial.LonNotReported &&
                this.acc.LonDeg != CoordinateSpecial.LonUnknown && this.acc.LonDeg != RBISDataValue.Blank) {
                if (this.acc.ddmLongitudeMinutes != RBISDataValue.Minus1 && this.acc.ddmLongitudeMinutes != RBISDataValue.Empty) {
                    this.acc.ddLongitude = this._utilService.ddmTodd(this.acc.LonDeg.toString(), this.acc.ddmLongitudeMinutes);
                    this._utilService.ddmToDms(this.acc.LonDeg.toString(), this.acc.ddmLongitudeMinutes, 'lng')
                    this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
                } else {
                    if (this.acc.LonDeg == RBISDataValue.Blank && this.acc.ddmLongitudeMinutes == RBISDataValue.Empty) {
                        this.clearLngOnly();
                    }
                }
            }
        }
        this.wasVerifyMessageShown = false;
        // this.resetValidFlag();
    }

    isValueIsSameAsOld() {
        return (
            this.firstOldValue.LatDeg == this.acc.LatDeg
            && this.firstOldValue.LatMin == this.acc.LatMin
            && this.firstOldValue.LatSec == this.acc.LatSec
            && this.firstOldValue.LonDeg == this.acc.LonDeg
            && this.firstOldValue.LonMin == this.acc.LonMin
            && this.firstOldValue.LonSec == this.acc.LonSec
            && this.firstOldValue.ddLatitude == this.acc.ddLatitude
            && this.firstOldValue.ddLongitude == this.acc.ddLongitude

        )
    }

    checkEmptyValue(event) {
        if (this.acc.LonDeg == RBISDataValue.Blank && this.acc.ddmLongitudeMinutes == RBISDataValue.Empty) {
            this.clearLngOnly();
        }
    }

    pasteData(event, field) {
        let pasteValue = event.clipboardData.getData('text/plain');
        if (pasteValue != '' && !isNaN(pasteValue)) {
            this.acc[field] = pasteValue;
            this.updateFieldOnChange(field)
        }
    }

    onMyBlur(field, event) {
         this.updateFieldOnChange(field)
    }



    resetValidateFlagOnKeyUp(event, field) {        
        this.isLatLngIsValidated = false
    }


    setValuesFromDDM() {
        this._utilService.dmsConverted = [];
        this.acc.ddLongitude = this._utilService.ddmTodd(this.acc.LonDeg.toString(), this.acc.ddmLongitudeMinutes);
        this._utilService.ddmToDms(this.acc.LonDeg.toString(), this.acc.ddmLongitudeMinutes, 'lng')
        this.setDegreeMinuteSeconds(this._utilService.dmsConverted);
    }

    /**
     * Initializes bounding boxes for geographical states for the purpose of coordinate range-of-value validation. Can be called only after the state the the case belongs to is resolved
     **/
    latLonRangeValidationData() {
        if (!this.intStateId || this.intStateId <= 0)
            throw new Error('InvalidOperationException: Cannot initialize GPS coordinate bounding box validation before the state that the case belongs to is resolved.');

        let Lat: Array<any> = [];

        this.LatDegValidationRange = [];
        this.LatMinValidationRange = [];
        this.LatSecValidationRange = [];

        this.LonDegValidationRange = [];
        this.LonMinValidationRange = [];
        this.LonSecValidationRange = [];

        /* Lat */
        this._utilService.GetDrpDownListOptions("LatDeg", ' AND State_ID =' + this.intStateId).subscribe(result => {
            result.forEach(item => {
                if (item.strValue != CoordinateSpecial.LatNotApplicable.toString() && item.strValue != CoordinateSpecial.LatNotReported.toString() && item.strValue != CoordinateSpecial.LatUnknown.toString()) {
                    this.LatDegValidationRange.push(item);
                }
            });
        });

        this._utilService.GetDrpDownListOptions("LatMin", '').subscribe(result => {
            result.forEach(item => {
                if (item.strValue != CoordinateSpecial.LatNotApplicable.toString() && item.strValue != CoordinateSpecial.LatNotReported.toString() && item.strValue != CoordinateSpecial.LatUnknown.toString()) {
                    this.LatMinValidationRange.push(item);
                }
            });
        });

        const latLonSecNa = CoordinateSpecial.LatNotApplicable + '.' + CoordinateSpecial.LatNotApplicable;
        const latLonSecNr = CoordinateSpecial.LatNotReported + '.' + CoordinateSpecial.LatNotReported;
        const latLonSecUnknown = CoordinateSpecial.LatUnknown + '.' + CoordinateSpecial.LatUnknown;
        const defaultValue = '-1.00';

        //this._utilService.GetDrpDownListOptions("LatSec", '').subscribe(result => {
        //    result.forEach(item => {
        //        if (item.strValue != latLonSecNa && item.strValue != latLonSecNr && item.strValue != latLonSecUnknown && item.strValue != defaultValue) {
        //            this.LatSecValidationRange.push(item);
        //        }

        //    });
        //});

        /* Lon */

        this._utilService.GetDrpDownListOptions("LonDeg", ' AND State_ID =' + this.intStateId).subscribe(result => {
            result.forEach(item => {
                if (item.strValue != CoordinateSpecial.LonNotApplicable.toString() && item.strValue != CoordinateSpecial.LonNotReported.toString() && item.strValue != CoordinateSpecial.LonUnknown.toString()) {
                    this.LonDegValidationRange.push(item);
                }
            });
        });

        this._utilService.GetDrpDownListOptions("LonMin", '').subscribe(result => {
            result.forEach(item => {
                // this condin using Lat value becasue in the minues we do have only two digits which is same as Lat values
                if (item.strValue != CoordinateSpecial.LatNotApplicable.toString() && item.strValue != CoordinateSpecial.LatNotReported.toString() && item.strValue != CoordinateSpecial.LatUnknown.toString()) {
                    this.LonMinValidationRange.push(item);
                }

            });
        });

        //this._utilService.GetDrpDownListOptions("LonSec", '').subscribe(result => {
        //    result.forEach(item => {
        //        if (item.strValue != latLonSecNa && item.strValue != latLonSecNr && item.strValue != latLonSecUnknown && item.strValue != defaultValue) {
        //            this.LonSecValidationRange.push(item);
        //        }
        //    });
        //});

    }

    showRangeError(options: Array<DrpDownOptions>, type: string = 'Latitude ', forField: string = 'Degree'): string {
        let startRange = options[0].strValue;
        let endRange = options[options.length - 1].strValue;
        let message = 'Your Input is Out of Range for ' + forField + ' in ' + type + '. ' + forField + ' must be in in between ' + startRange + ' and ' + endRange + ' to be within the state of ' + this.strStateName + '. <br>';
        return message
    }

    validateLatLonRange() {
        try {
            let message = '';
            this.hasOutOfRangeError = false;

            if (this.intStateId <= 0) {
                console.error('The state that this case belongs to could not be resolved. Coordinate boundary validation cannot occur.');
                return;
            }

            if (this.viewType == 'dd') {
                if (this.acc.LatDeg != RBISDataValue.Blank) {
                    if (!this.LatDegValidationRange.find(x => x.intValue == this.acc.LatDeg) || this.hasMOSSSpecialValueError) {
                        this.hasOutOfRangeError = true;
                        message = this.showRangeError(this.LatDegValidationRange);
                        this.txtLatitude.setIsInvalidClass(true);
                    } else {
                        this.txtLatitude.setIsInvalidClass(false);
                    }
                }

                if (this.acc.LonDeg != RBISDataValue.Blank) {
                    if (!this.LonDegValidationRange.find(x => x.intValue == this.acc.LonDeg)) {
                        this.hasOutOfRangeError = true;
                        message = message + this.showRangeError(this.LonDegValidationRange, 'Longitude', 'Degree');
                        this.txtLongitude.setIsInvalidClass(true);
                    } else {
                        this.txtLongitude.setIsInvalidClass(false);
                    }
                }
            }
            else if (this.viewType == 'dms') {
                if (this.acc.LatDeg != RBISDataValue.Blank) {
                    if (!this.LatDegValidationRange.find(x => x.intValue == this.acc.LatDeg) || this.hasMOSSSpecialValueError) {
                        this.hasOutOfRangeError = true;
                        message = this.showRangeError(this.LatDegValidationRange);
                        this.txtAccLatDeg.setIsInvalidClass(true);
                    } else {
                        this.txtAccLatDeg.setIsInvalidClass(false);
                    }
                }

                if (this.acc.LatMin != RBISDataValue.Blank) {
                    if (!this.LatMinValidationRange.find(x => x.intValue == this.acc.LatMin)) {
                        this.hasOutOfRangeError = true;
                        message = message + this.showRangeError(this.LatMinValidationRange, 'Latitude', 'Minutes');
                        this.txtLatMin.setIsInvalidClass(true);
                    } else {
                        this.txtLatMin.setIsInvalidClass(false);
                    }
                }

                //if (this.acc.LatSec != RBISDataValue.Blank) {
                //    let secWithDecimal = this._utilService.sizeOfNumber(this.acc.LatSec, 2, 2);
                //    if (!this.LatSecValidationRange.find(x => x.strValue == secWithDecimal.toString())) {
                //        this.hasOutOfRangeError = true;
                //        message = message + this.showRangeError(this.LatSecValidationRange, 'Latitude', 'Seconds');
                //        this.txtLatSec.setIsInvalidClass(true);
                //    } else {
                //        this.txtLatSec.setIsInvalidClass(false);
                //    }
                //}

                if (this.acc.LonDeg != RBISDataValue.Blank) {
                    if (!this.LonDegValidationRange.find(x => x.intValue == this.acc.LonDeg) || this.hasMOSSSpecialValueError) {
                        this.hasOutOfRangeError = true;
                        message = message + this.showRangeError(this.LonDegValidationRange, 'Longitude', 'Degree');
                        this.txtLonDeg.setIsInvalidClass(true);
                    } else {
                        this.txtLonDeg.setIsInvalidClass(false);
                    }
                }

                if (this.acc.LonMin != RBISDataValue.Blank) {
                    if (!this.LonMinValidationRange.find(x => x.intValue == this.acc.LonMin) || this.hasMOSSSpecialValueError) {
                        this.hasOutOfRangeError = true;
                        message = message + this.showRangeError(this.LonMinValidationRange, 'Longitude', 'Minutes');
                        this.txtLonMin.setIsInvalidClass(true);
                    } else {
                        this.txtLonMin.setIsInvalidClass(false);
                    }
                }

                if (this.acc.LonMin != RBISDataValue.Blank) {
                    let secWithDecimal = this._utilService.sizeOfNumber(this.acc.LonSec, 2, 2);

                    //if (!this.LonSecValidationRange.find(x => x.strValue == secWithDecimal.toString())) {
                    //    this.hasOutOfRangeError = true;
                    //    message = message + this.showRangeError(this.LonSecValidationRange, 'Longitude', 'Seconds');
                    //    this.txtLonSec.setIsInvalidClass(true);
                    //} else {
                    //    this.txtLonSec.setIsInvalidClass(false);
                    //}
                }
            }
            else if (this.viewType == 'ddm') {
                if (this.acc.LatDeg != RBISDataValue.Blank) {
                    if (!this.LatDegValidationRange.find(x => x.intValue == this.acc.LatDeg) || this.hasMOSSSpecialValueError) {
                        this.hasOutOfRangeError = true;
                        message = this.showRangeError(this.LatDegValidationRange);
                        this.txtddmAccLatDeg.setIsInvalidClass(true);
                    } else {
                        this.txtddmAccLatDeg.setIsInvalidClass(false);
                    }
                }

                if (this.acc.LatMin != RBISDataValue.Blank) {
                    if (!this.LatMinValidationRange.find(x => x.intValue == this.acc.LatMin)) {
                        this.hasOutOfRangeError = true;
                        message = message + this.showRangeError(this.LatMinValidationRange, 'Latitude', 'Minutes');
                        this.txtlatddmMinutes.setIsInvalidClass(true);
                    } else {
                        this.txtlatddmMinutes.setIsInvalidClass(false);
                    }
                }

                if (this.acc.LonDeg != RBISDataValue.Blank) {
                    if (!this.LonDegValidationRange.find(x => x.intValue == this.acc.LonDeg)) {
                        this.hasOutOfRangeError = true;
                        message = message + this.showRangeError(this.LonDegValidationRange, 'Longitude', 'Degree');
                        this.txtddmLonDec.setIsInvalidClass(true);
                    } else {
                        this.txtddmLonDec.setIsInvalidClass(false);
                    }
                }

                if (this.acc.LonMin != RBISDataValue.Blank) {
                    if (!this.LonMinValidationRange.find(x => x.intValue == this.acc.LonMin)) {
                        this.hasOutOfRangeError = true;
                        message = message + this.showRangeError(this.LonMinValidationRange, 'Longitude', 'Minutes');
                        this.txtddmLonMin.setIsInvalidClass(true);
                    } else {
                        this.txtddmLonMin.setIsInvalidClass(false);
                    }
                }
            }

            if (this.hasOutOfRangeError) {
                this._modalService.dialog(message, 'Out of Range', 'W').toPromise();
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }

    setForceValue() {

        if (this.txtAccLatDeg) this.txtAccLatDeg.setCurrentValue(this.acc.LatDeg.toString());
        if (this.txtLatMin) this.txtLatMin.setCurrentValue(this.acc.LatMin.toString());
        if (this.txtLatSec) this.txtLatSec.setCurrentValue(this.acc.LatSec.toString());
        if (this.txtLatSec) this.txtLatSec.setCurrentValue('0'.repeat(5 - this.acc.LatSec.toString().length) + this.acc.LatSec);
         if (this.txtlatddmMinutes) this.txtlatddmMinutes.setCurrentValue(this.acc.ddmLatitudeMinutes.toString());


        if (this.txtLonDeg) this.txtLonDeg.setCurrentValue(this.acc.LonDeg.toString());
        if (this.txtLonMin) this.txtLonMin.setCurrentValue(this.acc.LonMin.toString());
        if (this.txtLonSec) this.txtLonSec.setCurrentValue(this.acc.LonSec.toString());

        if (this.txtddmLonMin) this.txtddmLonMin.setCurrentValue(this.acc.ddmLongitudeMinutes.toString());
    }
}
