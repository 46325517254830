<span class="pl-2">
    <button id="btnWeightCalculator_Open" class="btn btn-sm btn-light border-secondary" (click)="open(content, null)" #btnOpen>Weight Calculator</button>
</span>

<ng-template #content let-modal>

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Weight Calculator</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form #macontrol="ngForm">
            <div class="form-group">
                <p class="border-bottom border-light aliceblue">This window allows you to enter weight in lbs and returns the corresponding weight in kgs</p>
            </div>

            <div class="d-flex justify-content-center">
                <label class="align-self-center" for="dateOfBirth">Enter the weight in lbs<br /></label>
            </div>

            <div class="form-group">
                <div *ngIf="blnShowValidationMessage==true">
                    <div class="alert alert-danger mt-1" role="alert">
                        Weight is required.
                    </div>
                </div>
            </div>
           
            <div class="d-flex justify-content-center">
                   <div class="form-group">

                        <div class="input-group">
                            <input type="number" name="value" [(ngModel)]="value" placeholder="Pounds" (change)="unitChange()" id="convertorValue">

                        </div>

                        <!--<div>
                <p>Kilograms:</p>
                <input id="outputKilograms" name="result" />
            </div>-->


                    </div>
               <!--<div class="form-group">
                    Kilograms: {{this.result}}
                </div>-->
                <div class="modal-footer d-flex justify-content-end">
                    <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
                    <button type="button" *ngIf="strForm !== 'Home'" class="btn btn-light border-secondary" (click)="modal.close('Save click');cancel()">Cancel</button>

                </div>
            </div>

        </form>
    </div>
</ng-template>
