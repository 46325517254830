<div *ngIf="nonOccupant && intMode" class="border-dark border-start border-bottom border-end px-2">
    <ul class="nav nav-tabs border-dark border-left bg-white">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./nonOccupant"]'
               [ngClass]="{'bg-light': linknonOccupant.isActive === true}"
               [routerLinkActive]="['active']" #linknonOccupant="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linknonOccupant.isActive === true, 'fw-bold' : linknonOccupant.isActive === true}">
                        Non-Occupant
                    </span>
                </span>
            </a>
        </li>

        <li class="nav-item mt-1 ml-1" *ngIf="intMode == 128 || intMode == 256">
            <a class="nav-link" [routerLink]='["./nonOther"]'
               [ngClass]="{'bg-light': linkother.isActive === true}"
               [routerLinkActive]="['active']" #linkother="routerLinkActive">
                <span class="text-dark">
                    <span *ngIf="intMode == 128" [ngClass]="{'text-decoration-none': linkother.isActive === true, 'fw-bold' : linkother.isActive === true}">
                        Other
                    </span>
                    <span *ngIf="intMode == 256" [ngClass]="{'text-decoration-none': linkother.isActive === true, 'fw-bold' : linkother.isActive === true}">
                        Details
                    </span>
                </span>
            </a>
        </li>

        <li class="nav-item mt-1" *ngIf="intMode == 128 && (nonOccupant.PType === 6 || nonOccupant.PType === 7)">
            <a class="nav-link" [routerLink]='["./bicyclist"]'
               [ngClass]="{'bg-light': linkbicyclist.isActive === true}"
               [routerLinkActive]="['active']" #linkbicyclist="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkbicyclist.isActive === true, 'fw-bold' : linkbicyclist.isActive === true}">
                        Bicyclist
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="(intMode == 128) && (nonOccupant.PType === 5 || nonOccupant.PType === 11 || nonOccupant.PType === 12 || nonOccupant.PType === 13)">
            <a class="nav-link" [routerLink]='["./pedestrian"]'
               [ngClass]="{'bg-light': linkpedestrian.isActive === true}"
               [routerLinkActive]="['active']" #linkpedestrian="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpedestrian.isActive === true, 'fw-bold' : linkpedestrian.isActive === true}">
                        Pedestrian
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="intMode == 128 || intMode == 256">
            <a class="nav-link" [routerLink]='["./nonMotoristActions"]'
               [ngClass]="{'bg-light': nonMotoristActions.isActive === true}"
               [routerLinkActive]="['active']" #nonMotoristActions="routerLinkActive">
                <span class="text-dark" *ngIf="intMode == 256">
                    <span [ngClass]="{'text-decoration-none': nonMotoristActions.isActive === true, 'fw-bold' : nonMotoristActions.isActive === true}">
                        Non-Occupant Actions
                    </span>
                </span>
                <span class="text-dark" *ngIf="intMode == 128">
                    <span [ngClass]="{'text-decoration-none': nonMotoristActions.isActive === true, 'fw-bold' : nonMotoristActions.isActive === true}">
                        Non-Motorist Actions
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="intMode == 128">
            <a class="nav-link" [routerLink]='["./alcohol"]'
               [ngClass]="{'bg-light': linkalcohol.isActive === true}"
               [routerLinkActive]="['active']" #linkalcohol="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkalcohol.isActive === true, 'fw-bold' : linkalcohol.isActive === true}">
                        Alcohol
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="intMode == 128">
            <a class="nav-link" [routerLink]='["./drug"]'
               [ngClass]="{'bg-light': linkdrug.isActive === true}"
               [routerLinkActive]="['active']" #linkdrug="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkdrug.isActive === true, 'fw-bold' : linkdrug.isActive === true}">
                        Drug
                    </span>
                </span>
            </a>
        </li>
        <!--<li class="nav-item mt-1" *ngIf="intMode == 1">
        <a class="nav-link" [routerLink]='["./race"]'
           [ngClass]="{'bg-light': linkrace.isActive === true}"
           [routerLinkActive]="['active']" #linkrace="routerLinkActive">
            <span class="text-dark">
                <span [ngClass]="{'text-decoration-none': linkrace.isActive === true, 'fw-bold' : linkrace.isActive === true}">
                Race</span>
            </span>
        </a>
    </li>-->
        <li class="nav-item mt-1" *ngIf="intMode == 128  || intMode == 256">
            <a class="nav-link" [routerLink]='["./supplemental"]'
               [ngClass]="{'bg-light': linksupplemental.isActive === true}"
               [routerLinkActive]="['active']" #linksupplemental="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksupplemental.isActive === true, 'fw-bold' : linksupplemental.isActive === true}">
                        Supplemental
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="intMode == 128">
            <a class="nav-link" [routerLink]='["./injury"]'
               [ngClass]="{'bg-light': linksupplemental.isActive === true}"
               [routerLinkActive]="['active']" #linksupplemental="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksupplemental.isActive === true, 'fw-bold' : linksupplemental.isActive === true}">
                        Injury
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1 ml-1" *ngIf="intMode == 128 || intMode == 256">
            <a class="nav-link" [routerLink]='["./nonOccNotes"]'
               [ngClass]="{ 'bg-light': linkcrashnotes.isActive === true }"
               [routerLinkActive]="['active']" #linkcrashnotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkcrashnotes.isActive === true, 'fw-bold' : linkcrashnotes.isActive === true}">
                        Notes
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <!--here we generates and show Non-Occupants-children html's components-->
    <router-outlet></router-outlet>

</div>
