
<div class="row {{printOnly ? 'd-none' : ''}}" #divContainer>
    <ng-template #rt let-r="result" let-t="term">
        <!-- Angular Bootstrap Typeahead Highlight helper, highlights the portion of the option that matches the search term. -->
        <ngb-highlight [result]="blnFormatListOnlyShowText ? r.strText : r.displayText" [term]="t"></ngb-highlight>
    </ng-template>
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto" *ngIf="!disableLabel && objMetaData">
        <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo()" style="cursor:pointer;display:inline;"></i>&nbsp;
        <app-label [inputName]="inputName" [inputMetaData]="objMetaData" [disableLabel]="disableLabel" #currentLabel></app-label>
    </div>

    <div class="col col-xs-12 col-sm-12 col-md-12 col-xl my-auto">
        <!-- Using input-group to keep intput with hardcoded width and following label flowing on a single line without wrapping -->
        <div class="{{isFromBatchProcessing ? '' : 'input-group flex-nowrap'}}">
            <div class="{{isFromBatchProcessing ? '' : 'input-group-append'}}">
                <!--
                Bootstrap Typeahead https://ng-bootstrap.github.io/#/components/typeahead/examples input parameters:
                    ngbTypeahead:   Selector for Bootstrap Typeahead and also the search function that converts the entered search term to an array of completion suggestions. If the array of completions suggestions is empty, the Typeahead popup is not shown.
                    inputFormatter: The function that converts an item from the result list to a string to display in the <input> field. It is called when the user selects something in the popup or the model value changes, so the input needs to be updated.
                    placement:      The preferred placement of the typeahead
                    resultTemplate: The template to override the way resulting items are displayed in the popup.
                    selectItem:     @Output Event handler to communicate selection back to parent

                w74 is a custom CSS class defined in this component's CSS file
                click$, focus$: Subject (A special type of Observable that allows values to be multicasted to many Observables. Subjects are like EventEmitters.)
                #instance Gives TypeScript class a reference to the Bootstrap Typeahead control

                Here we use "th" to mean "Typeadhead", not to be confused with the table header HTML tag.

                We are intentionally not binding NGMODEL directly to objModel[strFieldName], objModel[objMetadata.FieldID], instead, we are processing the click and keyup events separately,
                however, we do want NGMODEL to be present without bindings to receive model updates as NGMODEL makes the field participate in NGFORM pristine/dirty infrastructure.
                -->
                <input #txt
                       [id]="inputName"
                       type="text"
                       [class]="typeAheadClass"
                       [name]="inputName"
                       ngModel
                       [ngModelOptions]="objNgModelOptions"
                       [ngbTypeahead]="search"
                       [placement]="typeAheadPosition"
                       [resultTemplate]="rt"
                       [inputFormatter]="formatter"
                       placeholder="{{strPlaceholder}}"
                       (click)="click$.next($event.target.value);handleClick($event)"
                       (focus)="handleFocus($event)"
                       (blur)="onBlur($event.target.value)"
                       (keydown)="handleKeydown($event)"
                       (keyup)="handleKeyup($event)"
                       (selectItem)="selected($event)"
                       #instance="ngbTypeahead" />
                </div>
            <div class="input-group-append">
                <div class="input-group">
                    <div class="input-group-append w-100">
                        <div class="{{ blnHideSelectedOptionText === false ? 'container-fluid' : '' }}">
                            <div class="{{ blnHideSelectedOptionText === false ? 'row' : '' }}">
                                <div #lbl class="col-form-label mx-3 col-12 col-sm-12 col-md-auto col-lg-auto col-xl-auto"
                                     [hidden]="blnHideSelectedOptionText"></div>
                                <app-edt-confidence-color #EDTConfidenceColor [strTableName]="strTableName"
                                                          [strFieldName]="strFieldName"
                                                          [selectedValue]="objCurrentValue"
                                                          [arrOptions]="_arrOptionsPristine"
                                                          [objModel]="objModel"
                                                          [blnEnableEDTColor]="blnCheckStrengthCode">
                                </app-edt-confidence-color>
                                <div class="{{ blnEnableOtherSpecify ? 'col-12 col-sm-12 col-md col-lg col-xl' : '' }}">
                                    <app-other-specify #otherSpecify [strTableName]="strTableName"
                                                       [strFieldName]="strFieldName"
                                                       [intSeqNum]="intSeqNum"
                                                       strCssClass=""
                                                       class="w-100"
                                                       [objOtherSpecifyTemplate]="objOtherSpecifyTemplate"
                                                       [intElementValue]="objCurrentValue"
                                                       [blnIsEnabled]="blnEnableOtherSpecify">
                                    </app-other-specify>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="printOnly && intDisabledMode != intMode">
    <div class="print-only">
        <!--Label of avaiable options-->
        <app-label *ngIf="!disableLabel && objMetaData" [inputName]="inputName" [printOnly]="true" [inputMetaData]="objMetaData" [disableLabel]="disableLabel" style="font-size:14px">

        </app-label>
        <textarea *ngIf="!showTextboxInSamePositionForPrintOnly && !otherOption && !clearFieldData" style="font-size:14px"
                  type="text" class="form-control form-control-sm bg-white print-only-input"
                  [ngModel]="selecteditem ? replaceInCondition(selecteditem.strValue) : replaceInCondition(txt.value) | replaceTextPipe : '-1' : '' " [ngModelOptions]="{standalone: true}"
                  readonly disabled="disabled"
                  #printTextArea></textarea>
        <!--Empty box based on -->
        <!--<label class="border field-size-in-sameposition ml-2" *ngIf="clearFieldData">&nbsp;</label>-->
        <textarea *ngIf="clearFieldData && !otherOption" style="font-size:14px"
                  type="text" class="form-control form-control-sm bg-white print-only-input"
                  readonly disabled="disabled"
                  #printTextArea></textarea>
        <!--list of avaiable options-->
        <ng-container *ngIf="!clearFieldData && !otherOption">
            <!--<label *ngIf="showTextboxInSamePositionForPrintOnly" class="border field-size-in-sameposition ml-2 {{(selecteditem && showDescriptionForPrintOnly && selecteditem.displayText.length>50) ? 'field-with-long-text' : ''}}">{{selecteditem && showDescriptionForPrintOnly ? selecteditem.displayText : selecteditem ? selecteditem.strValue : '' }} </label>-->
            <textarea *ngIf="showTextboxInSamePositionForPrintOnly" style="font-size:14px"
                      type="text" class="form-control form-control-sm bg-white print-only-input"
                      [ngModel]="selecteditem && showDescriptionForPrintOnly ? selecteditem.displayText : selecteditem ? replaceInCondition(selecteditem.strValue) : replaceInCondition(txt.value) | replaceTextPipe : '-1' : '' " [ngModelOptions]="{standalone: true}"
                      readonly disabled="disabled"
                      #printTextArea></textarea>
        </ng-container>
        <!--list of avaiable options-->
        <ng-container *ngIf="showAllDataListForPrintOnly">
            <div class="row">
                <div class="col-sm-12">
                    <ng-container class="container-fluid" *ngFor="let item of options">
                        <div class="row border-light border-bottom">
                            <!--<div class="col-2 pr-0" *ngIf="item.intValue!=-1" style="word-break: break-all;">
                                <span class="float-right">{{ item.intValue }} - </span>
                            </div>
                            <div class="col-10 pl-0" *ngIf="item.intValue!=-1" style="word-break: break-all;">
                                <span>{{item.strText}}</span>
                            </div>-->
                            <div class="col">
                                <ul class="list-group list-group-flush" *ngIf="item.intValue!=-1">
                                    <li class="list-group-item p-0" style="font-size:14px">
                                        <span>{{ item.strValue }}</span><span> - </span><span>{{item.strText}}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>


