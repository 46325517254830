
<div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Standard Avoidance Equipment Notification</h5>
</div>
<div class="modal-body">
    <div class="row col-sm-12 text-center">
        <div class="col-sm-12">
            <h6 *ngIf="intMode != 4"><strong>Case Number: {{caseNumber | number : '4.0-0' | replaceTextPipe : ',' : ''}} </strong> </h6>
            <h6 *ngIf="intMode == 4"><strong>PAR ID: {{ParId }} </strong> </h6>
            <h6><strong>Standard Avoidance Equipment Notification</strong></h6>
        </div>
    </div>

    <div class="row col-sm-12 mt-2 mb-2" *ngIf="!isFirstPopup">
        <div class="col-sm-12">
            <h6 class="text-justify text-primary">
                This Vehicle is equipped with standard crash avoidance equipment with data available on the EDR Report.
            </h6>
        </div>
    </div>
    <div class="row col-sm-12">
        <div class="col-sm-12">
            <p>
                Please upload the PAR using {{strAppName}} Case Documents Link. If you are unable to upload the file, please fax it to the fax number given below.
            </p>
        </div>
    </div>
    <div class="row col-sm-12">
        <div class=" {{isFirstPopup == false ? 'col-sm-6 border-right':'col-sm-12'}}">
<pre>
Mark Mynatt
U.S. Dept. of Transportation/NHTSA
Rm W53-485
1200 New Jersey Ave SE
Washington, DC 20590
Fax: (202) 366-3189
Email: Mark.Mynatt@dot.gov
</pre>
        </div>
        <div class="col-sm-6" *ngIf="!isFirstPopup">
<pre>
SCI Team Leader
U.S. Dept. of Transportation/NHTSA
Rm W53-305
1200 New Jersey Ave SE
Washington, DC 20590
Fax: (202) 366-4328
Email: Harold.Herrera@dot.gov
</pre>
        </div>
    </div>
    <div class="row col-sm-12 text-center">
        <div class="col-sm-12">
            <h6><strong>Thank You!</strong></h6>
            <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(true)">OK</button>
        </div>
    </div>
</div>
<div class="modal-footer">
</div>
