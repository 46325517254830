import { Component, OnInit, OnDestroy }      from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params, ChildActivationEnd, NavigationCancel } from '@angular/router';

import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { CaseService }       from 'src/app/services/case.service'
import { DBMode, States }    from 'src/app/models/enums/app.enums';
import { RbisUser }          from 'src/app/models/rbis-user';

import { AuthService } from 'src/app/services/auth.service';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { Subscription } from 'rxjs';
import { DrpDownOptions } from 'src/app/models/drp-down-options';

import { CaseModule } from '../cases/case.module';
import { CaseLockedViewModel } from 'src/app/interface/CaseLockedViewModel';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit, OnDestroy {
    private objAppSettings: AppSettings;
    private sbsNavigation: Subscription;
    intYear: number;
    toYear: number;
    mode: number; 
    modeString: string;
    user: string;
    selectedState: number;
    selectedStateString: string;
    hasOnlyOneState: boolean = false;
    public objUser: RbisUser;
   
    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        public sharedDataService: SharedDataService,
        public caseService: CaseService,
        private _authService: AuthService,
        private _utilService: UtilService,
        private _modalService: ModalService
    ) { }

    async ngOnInit() {
        this.sbsNavigation = this._router.events.subscribe((async (event: Event) => {
            if (event instanceof NavigationEnd) {
                if (this._router.url.indexOf('/?unique') != -1) //This action is optional, it has cosmetic effect only: The home page has two states: logged into a geographical state or not, when logging out of a geographical state, we use a query string parameter to force a fresh navigation action. This just cleans up that query string parameter.
                    this._router.navigate(['/']);
            }
        }).bind(this));

        this.objAppSettings = await this.sharedDataService.GetAppSettings();

        if (DBMode[this.objAppSettings.intMode])
            this.modeString = DBMode[this.objAppSettings.intMode];      

        this.intYear = this.objAppSettings.intYear;
        this.mode = this.objAppSettings.intMode;
        this.toYear = this.objAppSettings.toYear;

        let sbsRouteParam: Subscription = this._router.events.subscribe(((event: any) => {
            //Used when page is being reloaded (ex. F5). 
            if (event instanceof ChildActivationEnd) {
                //"stateNum" parameter may be found in different places in the URL depending on what page we're on, so we search the entire URL
                for (let objActivatedRouteSnapshot of event.snapshot.pathFromRoot) {
                    if (objActivatedRouteSnapshot.params['stateNum']) {
                        this.selectedState = objActivatedRouteSnapshot.params['stateNum'];
                        if (this.mode == DBMode.CRSS) {
                           // this.selectedStateString = this._utilService.findPSU(this.selectedState)
                            this.selectedStateString = '';
                        }
                        else {
                            this.selectedStateString = States[this.selectedState];
                        }

                        sbsRouteParam.unsubscribe();
                    }
                }
            }
        }).bind(this));

        this.sharedDataService.subjectSelectedState.subscribe(((objSelection: DrpDownOptions) => {
            if (objSelection && objSelection != null && objSelection != undefined) {
                this.selectedState = objSelection.intValue;
                if (this.mode == DBMode.CRSS) {
                    this.selectedStateString = '';
                  //  this.selectedStateString = this._utilService.findPSU(this.selectedState)
                } else {
                    this.selectedStateString = States[this.selectedState];
                }
            }
            else {
                this.selectedState = null;
                this.selectedStateString = null;
            }

            if (sbsRouteParam)
                sbsRouteParam.unsubscribe();
        }).bind(this));

        //this.selectedState = this.sharedDataService.getSelectedState();
        //this.selectedStateString = States[this.selectedState];

        this.objUser = await this._authService.GetUserPromise();
        this.isUserHasOnlyOneState();
    }

    private isUserHasOnlyOneState(): void {
        this._utilService.GetDrpDownListOptions('StateNum', 'ByPermissions').subscribe(result => {
            const arrStates = result.filter(x => x.intValue != 98 && x.intValue != 99);
            if (arrStates.length == 1) {
                this.hasOnlyOneState = true;
            }
        });
    }

    /**
     * The meaning of "Logout" is different depending on if has access to multiple states and is logged into a state.
     * The home page for a user with access to multiple states has two modes of operation: a state is selected (or "logged into")
     * or no state is selected (or "logged into") yet.
     **/
    public onLogout(): void {
        if ((this.objAppSettings.intMode == DBMode.CRSS) || (this.objAppSettings.intMode == DBMode.MTSS) || (this.objAppSettings.intMode == DBMode.PPSS) || (this.objAppSettings.intMode == DBMode.MOSS) || 
            (this.objAppSettings.intMode == DBMode.FARS && (!this.sharedDataService.selectedState || this.hasOnlyOneState))) {
            try { window.close(); } catch { } //window.close() will not work as Chrome no longer allows scripts to close windows that were not created via a script.
            this._modalService.setMessage('Please close the browser window by clicking X in the top right of the browser window.', 'warning', 15, false); // Upon closing window, if user has unsaved changes, they will get confirm prompt attached to window.onBeforeClose event
        }
        else {
            //At this point we know the user is logged into a state and has access to more than one state
            let sbsLogoutNavigation: Subscription = this._router.events.subscribe((async (event: Event) => {
                //If navigation is not canceled by route guard, clear top header and, if case is open, releases case lock and clears cache.
                if (event instanceof NavigationEnd) {
                    sbsLogoutNavigation.unsubscribe();
                    this.sharedDataService.subjectSelectedState.next(null);
                    this.sharedDataService.setSelectedState(0);
                    this.sharedDataService.setUserName(""); //TODO: Do we really want to clear user name? User is logging out of state, but not necessarily out of application

                    //On Logout when the the navigation is END we clear Cookies from the browser!
                    this.sharedDataService.deleteCookie("cookieENFilters");
                    this.sharedDataService.deleteCookie("cookieENPageState");
                    this.sharedDataService.deleteCookie("cookieCaseSearchScreenerFilters");
                    this.sharedDataService.deleteCookie("cookieCaseSearchScreenerPageState");

                    if (this.caseService.acc != null) {
                        await this.caseService.ReleaseLockedCases([{
                            AccID: this.caseService.acc.AccID,
                            EnID: this.caseService.acc.ENID,
                            AppID: this.objAppSettings.intApplicationId.toString()
                        }] as CaseLockedViewModel[]);

                        this.caseService.acc = null;
                    }
                    else {
                        const earlyNotification = this.sharedDataService.getEarlyNotify();

                        if (earlyNotification != null && earlyNotification != undefined) {
                            await this.caseService.ReleaseLockedCases([{
                                AccID: -1,
                                EnID: earlyNotification.ENID,
                                AppID: this.objAppSettings.intApplicationId.toString()
                            }] as CaseLockedViewModel[]);

                            this.sharedDataService.setEarlyNotify(null);
                        }
                    }
                }
                else if (event instanceof NavigationCancel) {
                    sbsLogoutNavigation.unsubscribe();
                }
            }).bind(this));

            this._router.navigate(['/'],                                              //This action is not immediate and flow of control goes to route guard, which may cancel navigation if there are unsaved changes.
                { replaceUrl: true, queryParams: { unique: (new Date()).getTime() } } //Make URL unique to force navigation action
            );  
        }
    }

    public ngOnDestroy(): void {
        if (this.sbsNavigation) {
            this.sbsNavigation.unsubscribe();
            this.sbsNavigation = null;
        }
    }
}
