import { Injectable } from '@angular/core';
import { UrlTreeHelper } from '../helper/UrlTreeHelper';
import { BaseComponent } from '../helper/basecomponent';
import { CaseService } from './case.service';
import { Acc } from '../models/acc';
import { UrlSegment, Router } from '@angular/router';
import { NonOccupantService } from './non-occupant.service';
import { VehicleService } from './vehicle.service';
import { SharedDataService } from './shared-data.service';
import { ModalService } from './modal.service';
import { VehicleVehicleDamagedAreasService } from '../ui/vehicle/vehicle-vehicle/vehicle-vehicle-damaged-areas/service/vehicle-vehicle-damaged-areas.service';
//import { NonOccupantSubTab, RBISDataValue, PersonSubTab, DriverSubTab, VehicleSubTab, MailRequestType, ActionCommand, CrashSubTab, PrecrashSubTab, MessageType, Preference, DBMode, AvoidanceEquipmentSubTab, SFRVehicleSubTab, VehType, StrikingVehicleSubTab, SFRPersonSubTab, StrikingPersonSubTab, StrikingDriverSubTab, StrikingPrecrashSubTab } from '../models/enums/app.enums';
import { NonOccupantSubTab, RBISDataValue, PersonSubTab, DriverSubTab, VehicleSubTab, ActionCommand, CrashSubTab, PrecrashSubTab, MessageType, Preference, DBMode, AvoidanceEquipmentSubTab, SFRVehicleSubTab, VehType, StrikingVehicleSubTab, SFRPersonSubTab, StrikingPersonSubTab,  StrikingPrecrashSubTab } from '../models/enums/app.enums';
import { ObjectUtil } from '../helper/objectUtil';
import { PII } from '../models/pii';
import { Non_Occupants } from '../models/non-occupants';
import { Veh } from '../models/veh';
import { Dri } from '../models/dri';
import { PreCrash } from '../models/pre-crash';
import { Occupants } from '../models/occupants';
import { DecimalPipe } from '@angular/common';
import { ReplaceTextPipe } from '../pipes/replaceText.pipe';
import { SecUserPerferenceService } from './secUserPerference.service';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ActionButtonService {

    //public static sbjModalOpenAfterSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    intYear: number;
    stateNum: number;
    accid: number;
    nonOccupantid: number;
    vehicleid: number;
    personid: number;
    intBeforeSaveStateNum: number;
    blnOOSPreference: boolean = false;

    //acc: Acc;
    pii: PII;
    nonOccupant: Non_Occupants;
    veh: Veh;
    dri: Dri;
    preCrash: PreCrash;
    person: Occupants


    strSaveMessage: string = "";

    constructor(
        private _router: Router,
        protected _caseService: CaseService,
        private _nonOccupantService: NonOccupantService,
        private _vehService: VehicleService,
        private sharedDataService: SharedDataService,
        private _modalService: ModalService,
        private messageService: VehicleVehicleDamagedAreasService,
        private _userPreferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {




    }


    //#region [SAVE OPERATIONS]

    public saveAcc(objAcc: Acc, command, tab): Promise<Acc> {

        //KM: 11/5/2019 Validations for DateTime
        let dateTimeError = this._caseService.dateTimeValidations(objAcc, tab, this.intYear);

        if (dateTimeError != "") {
            this._modalService.setMessage(dateTimeError, 'error');
            return;
        }

        return this._caseService.SaveCase(objAcc, false, command === ActionCommand.CheckCase)
            .then((async (objSavedCase: Acc) => {
                this._vehService.sbjModalOpenAfterSave.next(false);

                if (objAcc.EarlyNotify != null && objAcc.EarlyNotify.length > 0) {
                    // We are saving EarlyNotify in case Acc Crash Date/Time were updated, to keep Crash Date/Time synched between EarlyNotify and Acc
                    await this._caseService.SaveEarlyNotification(objAcc.EarlyNotify[0]).toPromise();
                }

                this.sharedDataService.observeSave.next(true);

                if (command === ActionCommand.CheckCase) {
                    this.navigateToCheckCase(this.accid); //Execution continues past this point to reach call to SharedDataService.observeSave.next()
                }
                else if (command === ActionCommand.Save && tab === CrashSubTab.Other) {
                    this._caseService.emsSavedNotify(objAcc);
                }
                else if (command === ActionCommand.Save && tab === CrashSubTab.Trafficway) {
                    this._caseService.trafficwaySaved.emit(objAcc);
                }
            }).bind(this));
    }

    private saveNonOccupant(data: Acc, command, tab): Promise<any> {

        this.nonOccupant = data.Non_Occupants.find(n => n.PNumber == this.nonOccupantid);
        
        //Kakuli M: 11/5/2019 Validations for DateTime
        if (tab === NonOccupantSubTab.Supplemental) {
            let dateTimeError = "";
            dateTimeError = ObjectUtil.dateTimeValidations(this.nonOccupant.DthYr, this.nonOccupant.DthMon, this.nonOccupant.DthDay, this.nonOccupant.DthHr, this.nonOccupant.DthMin, this.intYear);
            if (dateTimeError != "") {
                this._modalService.setMessage(dateTimeError, 'error');
                return;
            }
        }
        return this._nonOccupantService.SaveNonOccupant(this.nonOccupant, command === ActionCommand.CheckCase)
            .then(result => {
                this.sharedDataService.observeSave.next(true);
                this._vehService.sbjModalOpenAfterSave.next(false);
                if (command === ActionCommand.CheckCase) {
                    this.navigateToCheckCase(this.accid);
                }
                else if (command === ActionCommand.Save && tab === NonOccupantSubTab.Supplemental) {
                    this._caseService.deathRecordsSavedNotify(data);
                }
                else if (command === ActionCommand.Save && (tab === NonOccupantSubTab.Alcohol || tab === NonOccupantSubTab.Drug)) {
                    this._caseService.alcDrugSavedNotify(data);
                }

                //this._modalService.setMessage(this.strSaveMessage, MessageType.Success);
            });
    }

    private async saveVehicle(data: Acc, command, tab): Promise<any> {

        if (tab == 'avoidanceEquipment') {
        }
        this.veh = data.Veh.find(v => v.VNumber == this.vehicleid);

        return this._vehService.SaveVeh(this.veh, command === ActionCommand.CheckCase)
            .then((result => {
                this._vehService.sbjModalOpenAfterSave.next(false);
                if (command === ActionCommand.CheckCase) {
                    this.navigateToCheckCase(this.accid);
                }
                else if (command === ActionCommand.Save) {
                    this._vehService.vehicleSavedNotify(this.veh);
                }
                this.sharedDataService.observeSave.next(true);
            }).bind(this));
    }

    private async saveDriver(data: Acc, command, tab): Promise<any> {

        this.dri = data.Veh.find(v => v.VNumber == this.vehicleid).Dri;

        if (tab === DriverSubTab.Counters) {
            //if (!this._vehService.ValidateCounterFirstLastDate(this.dri)) {
            //    return;
            //}
        }
        this.veh = data.Veh.find(v => v.VNumber == this.vehicleid);
        

        if (this.veh.Occupants.length == 0) {
            return this._vehService.SaveVeh(this.veh, command === ActionCommand.CheckCase).then((result => {
                this.sharedDataService.observeSave.next(true);
                this._vehService.sbjModalOpenAfterSave.next(false);
                if (command === ActionCommand.CheckCase) {
                    this.navigateToCheckCase(this.accid);
                };

                return;
            }).bind(this));
        } else {
            return this._vehService.SaveDriver(this.dri, command === ActionCommand.CheckCase).then((async result => {
                this.sharedDataService.observeSave.next(true);
                this._vehService.sbjModalOpenAfterSave.next(false);
                if (command === ActionCommand.CheckCase) {
                    this.navigateToCheckCase(this.accid);
                }
                else if (command === ActionCommand.Save) {
                    this._vehService.driverSavedNotify(data);
                }

            }).bind(this));
        }  }

    private async savePrecrash(data: Acc, command, tab):Promise<any> {
        this.preCrash = data.Veh.find(v => v.VNumber == this.vehicleid).PreCrash;
        if (tab == PrecrashSubTab.PrecrashOther || tab == StrikingPrecrashSubTab.PrecrashOther) {//saving Whole Acc obj since wee need to save also siblinings of PreCrash(RRF_MTSS, WRF_MTSS, OEF_MTSS, TFIF_MTSS)
            return this._caseService.SaveCase(data, false, command === ActionCommand.CheckCase)
                .then((async (objSavedCase: Acc) => {
                    this._vehService.sbjModalOpenAfterSave.next(false);                  

                    this.sharedDataService.observeSave.next(true);

                    if (command === ActionCommand.CheckCase) {
                        this.navigateToCheckCase(this.accid); //Execution continues past this point to reach call to SharedDataService.observeSave.next()
                    }
                   
                   
                }).bind(this));
        }
        else {
            return this._vehService.SavePreCrash(this.preCrash, command === ActionCommand.CheckCase)
                .then((result => {
                    this.sharedDataService.observeSave.next(true);
                    this._vehService.sbjModalOpenAfterSave.next(false);
                    if (command === ActionCommand.CheckCase) {
                        this.navigateToCheckCase(this.accid);
                    }
                    this._modalService.setMessage(this.strSaveMessage, MessageType.Success);
                }).bind(this));
        }
    }

    private savePerson(data: Acc, command, tab): Promise<any> {
           //if (tab === PersonSubTab.PersonRace)
        //    if (!this._vehService.ValidateOccObj(this.person))
        //        return;

        //if (tab == SFRPersonSubTab.PersonAlcohol || tab == SFRPersonSubTab.Person || tab == SFRPersonSubTab.PersonNotes || tab == SFRPersonSubTab.Safety)
        //    this.person = data.Veh.find(v => v.VNumber == this.sfrvehid).Occupants.find(v => v.PNumber == this.sfrpersonid);          

        //else if (tab == StrikingPersonSubTab.PersonAlcohol || tab == StrikingPersonSubTab.Person || tab == StrikingPersonSubTab.PersonNotes)
        //    this.person = data.Veh.find(v => v.VNumber == this.strikevehid).Occupants.find(v => v.PNumber == this.strikingpersonid);
        
        //else
            this.person = data.Veh.find(v => v.VNumber == this.vehicleid).Occupants.find(v => v.PNumber == this.personid);

        console.log(this.person, tab);
        //Kakuli M: 11/5/2019 Validations for DateTime
        if (tab === PersonSubTab.PersonSupplemental) {
            let dateTimeError = "";
            dateTimeError = ObjectUtil.dateTimeValidations(this.person.DthYr, this.person.DthMon, this.person.DthDay, this.person.DthHr, this.person.DthMin, this.intYear);
            if (dateTimeError != "") {
                this._modalService.setMessage(dateTimeError, 'error');
                return;
            }
        }
         return this._vehService.SavePerson(this.person, command === ActionCommand.CheckCase).then(((occ: Occupants) => {
            this.sharedDataService.observeSave.next(true);
            this._vehService.sbjModalOpenAfterSave.next(false);

            if (command === ActionCommand.CheckCase) {
                this.navigateToCheckCase(this.accid);
            }
            else if (command === ActionCommand.Save && tab === PersonSubTab.PersonSupplemental) {
                this._caseService.deathRecordsSavedNotify(data);
            }
            else if (command === ActionCommand.Save && (tab === PersonSubTab.PersonAlcohol || tab === PersonSubTab.PersonDrug)) {
                this._caseService.alcDrugSavedNotify(data);
            }

            this._modalService.setMessage(this.strSaveMessage, MessageType.Success);
        }).bind(this));
    }
    //#end region [SAVE OPERATIONS]

    private setURLsegments(urlSegments: UrlSegment[]): void {

        this.stateNum = UrlTreeHelper.FindAndSetStateNum("case", urlSegments);
        this.accid = UrlTreeHelper.FindAndSetPathId("case", urlSegments);
        this.nonOccupantid = UrlTreeHelper.FindAndSetPathId("nonOccupant", urlSegments);

        let blnavoidanceEquipTab = urlSegments.some(seg => seg['path'] === 'vehicleAvoidEquip');      
        let blnstrikingTab = urlSegments.some(seg => seg['path'] === 'strikingvehicle');
        let blnsfrTab = urlSegments.some(seg => seg['path'] === 'sfrvehicle');

        let blnsfrPersonTab = urlSegments.some(seg => seg['path'] === 'sfrperson');
        let blnstrikePersonTab = urlSegments.some(seg => seg['path'] === 'strikingperson');

        this.vehicleid =
            blnavoidanceEquipTab ?
                UrlTreeHelper.FindAndSetPathId("vehicleAvoidEquip", urlSegments) :
            blnstrikingTab ?
                UrlTreeHelper.FindAndSetPathId("strikingvehicle", urlSegments) :
            blnsfrTab ?
                    UrlTreeHelper.FindAndSetPathId("sfrvehicle", urlSegments)
            : UrlTreeHelper.FindAndSetPathId("vehicle", urlSegments);

        this.personid =
            blnsfrPersonTab ?
                UrlTreeHelper.FindAndSetPathId("sfrperson", urlSegments) :
                blnstrikePersonTab ?
                    UrlTreeHelper.FindAndSetPathId("strikingperson", urlSegments) :
                UrlTreeHelper.FindAndSetPathId("person", urlSegments);
    }

    public navigateToCheckCase(accid: number): void {
        const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment;
        this.setURLsegments(urlSegments);

        this.sharedDataService.setLastCaseUrl(this._router.url);
        this._router.navigate([this.stateNum, 'checkCase', accid, 'violatedRules']);

        this.sharedDataService.setLastCaseUrl(this._router.url);

    }

    public async SaveCase(objActiveComponent: BaseComponent, command: string): Promise<boolean> {
        return new Promise<boolean>(((resolve, reject) => {
            try {
                //this._modalService.resetMessage();
                //objActiveComponent.clearViolatedElement();
                let objAcc = this._caseService.acc;

                if (objAcc.Mode == DBMode.MOSS) {
                    this._caseService.CheckMOSSCaseNum(objAcc, true); //Moh: 11-27-2020
                }

                this._caseService.CheckFinalStramtum(objAcc, true); //Moh: 04-20-2020

                objActiveComponent.onBeforeSave(command)
                    .then(async () => {
                        this._caseService.CheckAndSetEDTCaseStatus(objAcc);

                        if (objAcc.Mode == 4) {
                            const eightDigitParOnly = objAcc.EarlyNotify[0].PARID.toString().slice(4, 12);
                            this.strSaveMessage = "PAR ID  " + eightDigitParOnly + " saved successfully.";
                        }
                        else if (objAcc.Mode == DBMode.MOSS) {
                            this.strSaveMessage = "Case# " + this._caseService.acc.Acc_SS.MOSSCasenum + " saved successfully.";
                        }
                        else {
                            this.strSaveMessage = "Case# " +
                                new ReplaceTextPipe().transform(new DecimalPipe('en-US').transform(this._caseService.acc.Casenum, '4.0-0') ?
                                    new DecimalPipe('en-US').transform(this._caseService.acc.Casenum, '4.0-0').toString() : '', ',', '') +
                                " saved successfully.";
                        }

                        if (objActiveComponent.blnAlloweSave) {
                            let p;
                            const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment;
                            this.setURLsegments(urlSegments);
                            let strCurrentTab: string = UrlTreeHelper.FindAndSetCurrentTab(urlSegments);


                           
                            switch (strCurrentTab) {

                                //Saving Crash tabs
                                case CrashSubTab.Crash: //"crash":
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;
                                case CrashSubTab.Events://"events": //Crash Event
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;
                                case CrashSubTab.Trafficway://"trafficway":
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;
                                case CrashSubTab.Other://"other":
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;
                                case CrashSubTab.Special://"special":
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;
                                case CrashSubTab.Crashnotes://"crashnotes":
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;
                                case CrashSubTab.NonMotorist://"nonmotorist":
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;

                                //Saving NonOccupant tabs
                                case NonOccupantSubTab.NonOccupant: //"nonOccupant":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.NonOther://"nonother":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.Bicyclist: //"bicyclist":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.Pedestrian: //"pedestrian":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.NonMotoristActions: //"nonMotoristActions":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.Alcohol: //"alcohol":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.Drug: //"drug":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.Race: //"race":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.Supplemental: //"supplemental":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.Injury://"injury":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                case NonOccupantSubTab.NonOccNotes://"nonOccNotes":
                                    p = this.saveNonOccupant(objAcc, command, strCurrentTab);
                                    break;
                                
                                //Saving Vehicle tabs
                                case VehicleSubTab.Vehicle: //"vehicle":
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;
                                case VehicleSubTab.DamagedAreas: //"damagedAreas":
                                    this.messageService.sendMessage('damagedAreasSave');
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case VehicleSubTab.VIN: //"vin":
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;
                                case VehicleSubTab.VehicleDetails: //"vehicleDetails":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case VehicleSubTab.VehicleOther: //"vehicleOther":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case VehicleSubTab.VehNonMotorist: //"vehnonmotorist":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case VehicleSubTab.VehicleTrailer: //"vehicleTrailer":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case VehicleSubTab.VehicleVINDecode: //"vehicleVINDecode":
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;
                                case VehicleSubTab.Rollover: //"rollover":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case VehicleSubTab.View: //"view":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case VehicleSubTab.VehicleNotes: //"vehicleNotes":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                //case VehicleSubTab.Truck: //"truck":
                                //    this.saveVehicle(objAcc, command, strCurrentTab);
                                //    break;
                                //case VehicleSubTab.NonTruck: //"nontruck":
                                //    this.saveVehicle(objAcc, command, strCurrentTab);
                                //    break;

                                //Save SFR Vehicle
                                case SFRVehicleSubTab.Vehicle:
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case SFRVehicleSubTab.VIN:
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;
                                case SFRVehicleSubTab.Notes:
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;
                                case SFRVehicleSubTab.Other:
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;
                                case SFRVehicleSubTab.Control:
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;
                                case SFRVehicleSubTab.VehicleVINDecode: //"vehicleVINDecode":
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;

                                //Save Strike Vehicle 
                                case StrikingVehicleSubTab.Vehicle:
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case StrikingVehicleSubTab.VIN:
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;
                                case StrikingVehicleSubTab.Notes:
                                    p = this.saveAcc(objAcc, command, strCurrentTab);
                                    break;
                                case StrikingVehicleSubTab.VehicleVINDecode: //"vehicleVINDecode":
                                    p = this.saveAcc(objAcc, command, strCurrentTab); //Created Records logic in this tab may have modified PForms on Acc model object, so we save entire case
                                    break;

                                //Saving Driver tabs
                                case DriverSubTab.Driver: //"driver":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab); //Save Records logic in this tab may have modified DriRF on Veh model object
                                    break;
                                case DriverSubTab.License: //"license":
                                    p = this.saveDriver(objAcc, command, strCurrentTab);
                                    break;
                                case DriverSubTab.Counters: //"counters":
                                    p = this.saveDriver(objAcc, command, strCurrentTab);
                                    break;
                                case DriverSubTab.DriverNotes: //"driverNotes":
                                    p = this.saveDriver(objAcc, command, strCurrentTab);
                                    break;

                                //Save Strike Driver                                
                                //case StrikingDriverSubTab.PersonNotes: //"personNotes":
                                //    p = this.saveDriver(objAcc, command, strCurrentTab);
                                //    break;
                                //case StrikingDriverSubTab.Driver: //"personNotes":
                                //    p = this.saveDriver(objAcc, command, strCurrentTab);
                                //    break;
                               
                                //Saving Precrash tabs
                                case PrecrashSubTab.Precrash: //"precrash":
                                    p = this.savePrecrash(objAcc, command, strCurrentTab);
                                    break;
                                case PrecrashSubTab.Roadway: //"roadway":
                                    p = this.savePrecrash(objAcc, command, strCurrentTab);
                                    break;
                                case PrecrashSubTab.PrecrashOther: //"precrashOther":
                                    p = this.savePrecrash(objAcc, command, strCurrentTab);
                                    break;

                                //Save Strike precrash
                                case StrikingPrecrashSubTab.Precrash: //"precrash":
                                    p = this.savePrecrash(objAcc, command, strCurrentTab);
                                    break;
                                case StrikingPrecrashSubTab.Roadway: //"roadway":
                                    p = this.savePrecrash(objAcc, command, strCurrentTab);
                                    break;
                                case StrikingPrecrashSubTab.PrecrashOther: //"precrashOther":
                                    p = this.savePrecrash(objAcc, command, strCurrentTab);
                                    break;

                                //Saving Person tabs
                                case PersonSubTab.Person: //"person":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case PersonSubTab.SafetyDevices: //"safetyDevices":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case PersonSubTab.PersonAlcohol: //"personAlcohol":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case PersonSubTab.PersonDrug: //"personDrug":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case PersonSubTab.PersonRace: //"personRace":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case PersonSubTab.PersonSupplemental: //"personSupplemental":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case PersonSubTab.PersonNotes: //"personNotes":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;

                                //Save SFR Person
                                case SFRPersonSubTab.Person: //"person":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;
                                case SFRPersonSubTab.Safety: //"safety":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case SFRPersonSubTab.PersonAlcohol: //"personAlcohol":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;                              
                                case SFRPersonSubTab.PersonNotes: //"personNotes":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;

                                //Save Strinking Person
                                case StrikingPersonSubTab.Person: //"person":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case StrikingPersonSubTab.Safety: //"safety":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case StrikingPersonSubTab.PersonAlcohol: //"personAlcohol":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;
                                case StrikingPersonSubTab.Driver: //"Driver":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab); //Save Records logic in this tab may have modified Dri_DriDistract,Dri_DriInattention on Veh model object
                                    break;
                                case StrikingPersonSubTab.DriverNotes: //"DriverNotes":
                                    p = this.saveDriver(objAcc, command, strCurrentTab);
                                    break;
                                case StrikingPersonSubTab.PersonNotes: //"personNotes":
                                    p = this.savePerson(objAcc, command, strCurrentTab);
                                    break;

                                case AvoidanceEquipmentSubTab.AvoidanceEquipment: //"avoidanceEquipment":
                                    p = this.saveVehicle(objAcc, command, strCurrentTab);
                                    break;

                                default:
                            }

                            //Whatever section of the case is being saved, all Veh/Occupant/Driver/etc saves fire SharedDataService.observeSave
                            let sbsSave: Subscription = this.sharedDataService.observeSave.subscribe((async blnSaveSuccessful => {
                                sbsSave.unsubscribe();

                                if (objAcc.Element_Specify) //We may be adding or removing "other specify" entries
                                    await this._caseService.SaveListElementSpecify(objAcc.AccID, objAcc.Element_Specify);

                                this._modalService.setMessage(this.strSaveMessage, MessageType.Success);
                                resolve(blnSaveSuccessful);
                            }).bind(this));
                        }
                    });
            } catch (ex) {
                reject(ex);
            }
        }).bind(this));
    }
}
