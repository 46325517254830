import { Component, OnInit, AfterViewInit, QueryList, ViewChildren, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Acc } from 'src/app/models/acc';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { AutofillService } from 'src/app/services/autofill.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { NgForm } from '@angular/forms';
import { Element_Specify } from 'src/app/models/element-specify';

@Component({
    selector: 'app-non-motorist',
    templateUrl: './non-motorist.component.html',
    styleUrls: ['./non-motorist.component.scss']
})

export class NonMotoristComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit{
    accid: number;
    acc: Acc;
    objInstanceElementSpecify: Element_Specify;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('fcnm') objForm: NgForm;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    ngOnInit() {
        this._route.parent.params.subscribe((params => {
            this.accid = + params['crashid'];
        }).bind(this));
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, 0, 0);

    }

    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    setDistanceValue(event) {
       // this.acc.Acc_SS.DistFromCurb = event.result;
        console.log(event);
        event = String(event);
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.DistFromCurb);
        if (component) {
            component.setCurrentValue(String(event));
            component.onBlur(event);
            console.log(this.objForm);
            this.objForm.form.markAsDirty();
        }
    }
}
