<div>
    <!-- CASE HEADER -->
    <div class="border-bottom border-secondary pb-2" *ngIf="!isPrintMode">
        <div class="row">
            <div class="col my-auto">
                <ul class="nav nav-fill">
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">State: </span>
                        <span class="align-middle fw-bold">{{CRSSstateString}}</span>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">PAR ID: </span>
                        <span class="align-middle fw-bold">{{acc.EarlyNotify[0].PARID| number : '8.0-0' | replaceTextPipe : ',' : ''| slice : -8 }}</span>
                    </li>

                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle no-pointer">PSU : </span>
                        <span class="align-middle fw-bold text-truncate" [ngbTooltip]="acc.EarlyNotify[0]?.StateNum | valueToDescription: 'VR_PSU' | async"
                              *ngIf="acc.EarlyNotify[0]?.StateNum !== -1; else ZeroPForms"
                              style="display: inline-block; max-width: 200px;">
                            {{acc.EarlyNotify[0]?.StateNum | valueToDescription: 'VR_PSU' | async}}
                        </span>
                        <ng-template #ZeroPForms>
                            <span class="align-middle fw-bold">0</span>
                        </ng-template>
                    </li>

                    <li class="nav-item my-auto">
                        <span class="align-middle no-pointer">PAR # </span>
                        <ng-container *ngIf="acc.EarlyNotify[0]">
                            <button *ngIf="acc.EarlyNotify[0].PARNumber" id="btnCaseComponent_onEarlyNotifyEdit"
                                    class="btn btn-link"
                                    disabled="{{hideNavForCloseCase? 'disabled':''}}" (click)="onStateCasePDF(acc.EarlyNotify[0])">
                                <u>{{acc.EarlyNotify[0]?.PARNumber}}</u>
                            </button>
                        </ng-container>
                    </li>

                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">CRSS Strata : </span>
                        <span class="align-middle">Original: </span>
                        <span class="align-middle fw-bold">{{acc.EarlyNotify[0].Category| number : '2.0-0' | replaceTextPipe : ',' : ''}}</span>
                        <span class="align-middle"> Final: </span>
                        <span class="align-middle fw-bold" *ngIf="acc.EarlyNotify[0].CategoryFinal !== null; else ZeroFForms">{{acc.EarlyNotify[0].CategoryFinal| number : '2.0-0' | replaceTextPipe : ',' : ''}}</span>
                        <ng-template #ZeroFForms>
                            <span class="align-middle fw-bold">0</span>
                        </ng-template>
                    </li>

                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Non-Occ Forms: </span>
                        <span class="align-middle fw-bold" *ngIf="acc.NMForms !== -1; else ZeroNMForms">{{acc?.NMForms | number : '3.0-0'}}</span>
                        <ng-template #ZeroNMForms>
                            <span class="align-middle fw-bold">0</span>
                        </ng-template>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Veh Forms: </span>
                        <span class="align-middle fw-bold" *ngIf="acc.VForms !== -1; else ZeroVForms">{{acc?.VForms | number : '3.0-0'}}</span>
                        <ng-template #ZeroVForms>
                            <span class="align-middle fw-bold">0</span>
                        </ng-template>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Occ Forms: </span>
                        <span class="align-middle fw-bold" *ngIf="acc.PForms !== -1; else ZeroOForms">{{acc?.PForms | number : '3.0-0' }}</span>
                        <ng-template #ZeroOForms>
                            <span class="align-middle fw-bold">0</span>
                        </ng-template>
                    </li>
                    <li class="nav-item my-auto no-pointer" *ngIf="!blnIsEDTcase">
                        <span class="align-middle">Last Updated By: </span>
                        <span class="align-middle fw-bold">{{acc.strLastUpdatedBy}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!--EDT Header-->
    <!-- CASE HEADER -->
    <div class="border-bottom border-secondary pb-2" *ngIf="blnIsEDTcase && acc">
        <div class="row">
            <div class="col my-auto">
                <ul class="nav nav-fill">
                    <li class="nav-item my-auto">
                        <span class="align-middle">Case Status: </span>
                        <span class="align-middle fw-bold">
                            {{acc.Status | valueToDescription: 'EDT_CaseStatus' | async}}
                        </span>
                        <span class="align-content-start">
                            <button class="btn btn-link fw-bold" disabled="{{hideNavForCloseCase? 'disabled':''}}" (click)="changeCaseStatus()"><u>Change</u></button>
                        </span>
                    </li>
                    <li>
                        <span class="align-content-start" *ngIf="caseStatusReviewFlag">
                            <button class="btn btn-link fw-bold" (click)="RedirectViewCaseStatusUpdates(accid)">View Updates</button>
                        </span>
                    </li>
                    <li class="nav-item my-auto">
                        <span class="align-middle">Last State Update: </span>
                        <span class="align-middle fw-bold" *ngIf="edtLastUpdateDates">{{ edtLastUpdateDates?.LastRecvdDate | date:'M/d/yyyy, h:mm:ss a' }}</span>
                    </li>
                    <li class="nav-item my-auto">
                        <span class="align-middle">EDT : </span>
                        <span class="align-middle fw-bold" *ngIf="edtLastUpdateDates">{{ edtLastUpdateDates?.LastProcessedDate | date:'M/d/yyyy, h:mm:ss a' }}</span>
                    </li>
                    <li class="nav-item my-auto">
                        <span class="align-middle">Analyst: </span>
                        <span class="align-middle fw-bold">{{ acc?.LastModifiedOn | date:'M/d/yyyy, h:mm:ss a' }}</span>
                    </li>
                    <li class="nav-item my-auto no-pointer">
                        <span class="align-middle">Last Updated By: </span>
                        <span class="align-middle fw-bold">{{acc.strLastUpdatedBy}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>
