import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ViewChildren, QueryList, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Subscription, BehaviorSubject, Subject, Observable, of, ObjectUnsubscribedError } from 'rxjs';
import { debounceTime, delay, switchMap, tap, finalize } from 'rxjs/operators';

import { UtilService } from 'src/app/services/util.service';
import { CaseService } from 'src/app/services/case.service';

import { AutofillService } from 'src/app/services/autofill.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { VehicleService } from 'src/app/services/vehicle.service';

import { Veh } from 'src/app/models/veh';
import { Trailer } from './../../../../models/trailer';
import { TrailerVINDecode } from './../../../../models/trailer-vindecode';
import { vinDecode } from 'src/app/models/vin-decode';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { FormName, VehicleElement, RBISDataValue, BodyClass_VPIC, VehType } from 'src/app/models/enums/app.enums';

import { BaseComponent } from 'src/app/helper/basecomponent';
//import { DecodeVinComponent } from 'src/app/components/decode-vin/decode-vin.component';

import { NgbdSortableHeader, SortEvent, SortDirection } from 'src/app/directives/sortable-header.directive';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { VINDecode } from 'src/app/models/v-indecode';
import { vpicGVWRMapping } from 'src/app/models/vpicGVWRMapping';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { VPICDECODE } from 'src/app/models/vpic-decode';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { Acc } from 'src/app/models/acc';
import { TrailerVPICDECODE } from 'src/app/models/vpic-trailer-decode';

//Copised from Decode-vin.ts --- BEGIN
interface VINDecoded {
    Value: string;
    ValueId: string;
    Variable: string;
    VariableId: number;
}

interface SearchResult {
    VINDecoded: VINDecoded[];
    total: number;
}

interface VINDecodedState {
    page: number;
    pageSize: number;

    searchVariableTerm: string;
    searchValueTerm: string;

    sortColumn: string;
    sortDirection: SortDirection;
}

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(vinDecodeds: VINDecoded[], column: string, direction: string): VINDecoded[] {
    if (direction === '') {
        return vinDecodeds;
    } else {
        return [...vinDecodeds].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}
//function matcheVariable(vinDecoded: VINDecoded, pipe: PipeTransform, searchTerm: string) {
function matcheVariable(vinDecoded: VINDecoded, searchTerm: string) {
    if (vinDecoded.Variable) {
        return vinDecoded.Variable.toLowerCase().includes(searchTerm.toLowerCase());
    }
}

function matcheValue(vinDecoded: VINDecoded, searchTerm: string) {
    if (vinDecoded.Value) {
        return vinDecoded.Value.toLowerCase().includes(searchTerm.toLowerCase());
    }
}


@Component({
  selector: 'app-sfr-vehicle-vehicle-vin-decode',
  templateUrl: './sfr-vehicle-vehicle-vin-decode.component.html',
  styleUrls: ['./sfr-vehicle-vehicle-vin-decode.component.css']
})
export class SfrVehicleVehicleVinDecodeComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehicleVehicleVinDecodeComponent: string = null;
    private subVehicleId: Subscription

    public myThis: SfrVehicleVehicleVinDecodeComponent = this;
    public objStandalone: { standalone: boolean } = { standalone: true };
    accid: number;
    vehicleid: number;
    modelYear: number;
    VinNumber: string;
    pageTitle: string;
    /**
     * Indicates whether this component is operating on a vehicle data model object or a trailer data model object.
     **/
    URLfragment: string;
    trailerIndex: number;
    isYearVisible: boolean = false;
    blnDecoderVisable: boolean = false;
    blnDecodeVinClicked: boolean = false;
    blnShowVINDecoder: boolean = true;


    //from decode-vin.ts BEGIN
    vinData: any;
    vinResult: any;
    blnVINIsClean: boolean = true;
    stateNum: number;

    veh: Veh;
    public acc: Acc;
    private vehOriginal: Veh;
    trailers: Trailer[];
    trailer: Trailer;

    fieldMetadata: TableFieldElements[];
    vinMetaData: TableFieldElements;

    vINDecoded: VINDecoded[];

    //error alert card
    errorCodeValue: string;
    errorTextVariable: string;
    errorTextValue: string;
    suggestedVIN: string
    possibleValues: string
    alertType: string;

    //Basic Info
    manufacturer: string;
    vehicleType: string;
    steModelYear: string;
    make: string;
    model: string;
    bodyClass: string;
    NCSAMake: string;
    NCSAModel: string;
    NCSABodyType: string;


    //Other Information
    series: string;
    grossVehicleWeightRating: string;
    grossVehicleWeightRatingTo: string;
    cylinders: string;
    engineModel: string;
    engineManufacturer: string;
    transmissionSpeed: string;

    trim: string;
    driveType: string;
    primaryFuelType: string;
    engineBrake: string;
    engineDisplacement: string;
    transmissionStyle: string;

    airbagsFront: string;
    airbagsKnee: string;
    airbagsSide: string;
    airbagsCurtain: string;
    airbagsSeatCushion: string;
    airbagsOtherRestraintInfo: string;

    arrVpicGVWRMapping: vpicGVWRMapping[];

    plantInformation: string;
    vehicleBodyImgUrl: string;
    imgBodyClassPath: string = "../../../assets/images/vinDecoder/BodyClass/";

    isTrailer: boolean = false;
    intMdlYear: number = RBISDataValue.Blank;

    vinDecodeErrorList: { '2', '3', '4', '5', '6', '7', '8', '9', '11', '12' };
    vinDecodesCleanList: { '0', '1', '10', '400' };

    @Input() vinModelYear: number;
    //@Input() isYearVisiable: boolean;
    @Input() Vehicle: Veh; //TODO: Is this input property used for "Print Form" functionality?
    @Input() TRAILERINDEX: number;
    @Input() AccID: number;
    @Input() VehicleID: number;
    //@ViewChild('bodyImg') imgVehicleBody: ElementRef;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;



    //region for en grid variables
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _vinDecodeds$ = new BehaviorSubject<VINDecoded[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    public ColummForSorting: string = '';
    public DirectionForSorting: string = '';

    private _state: VINDecodedState = {
        page: 1,
        pageSize: 10,

        searchVariableTerm: '',
        searchValueTerm: '',

        sortColumn: '',
        sortDirection: ''
    };

    //end of region
    //from decode-vin.ts END

    //@ViewChild(DecodeVinComponent) vinDecoderComponent: DecodeVinComponent;
    @ViewChild('fvvvind') objForm: NgForm;
    @ViewChild('txtVinDecodeVIN') txtVinDecodeVIN: UIElementBase;
    @ViewChild('txtModelYear') txtModelYear: UIElementBase;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _vehicleService: VehicleService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _sharedDataService: SharedDataService,
        private _router: Router,
        private _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _autofillService: AutofillService
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
        this.blnFocusOnFirstField = false; //We don't immediately know if first field (year) will be visible
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngOnInit() {
        super.ngOnInit();

        this._route.parent.parent.parent.params.subscribe((async params => {
            this.stateNum = await + params['stateNum'];
            this.accid = + await params['caseid'];

            this._route.fragment.subscribe(((fragment: string) => {
                this.URLfragment = fragment;
            }).bind(this));

            if (this.subVehicleId)
                this.subVehicleId.unsubscribe();

            this._utilService.GetVpicGVWRMappings().subscribe(result => {
                this.arrVpicGVWRMapping = result;
            });


            this.subVehicleId = this._route.parent.parent.params.subscribe((params => {
                this.vehicleid = + params['vehicleid'];
                //this._caseService.GetCasePromise(this.accid).then((data => {
                this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.SFRVehicle);
                this.vehOriginal = ObjectUtil.CloneModelObject(this.veh);

                if (this._sharedDataService.getVinNumber() != '') {
                    this.VinNumber = this._sharedDataService.getVinNumber();
                    this._sharedDataService.setVinNumber('');
                    this.getVINNumber();
                } else {
                    this.VinNumber = this.getVINNumber();
                }


                if (this.VinNumber || this.VinNumber !== null || this.VinNumber !== undefined) {
                    this.decodeVin(this.VinNumber);
                }
                //}).bind(this));
            }).bind(this));
        }).bind(this));

        this._utilService.metaDataToShare.subscribe(result => {
            this.vinMetaData = result.find(x => x.Form == FormName.Vehicle && x.Field == "VIN");
        });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    keyupYear($event) {
        let term: string = $event.target.value;
        if ((term && term !== "") && (this.VinNumber && this.VinNumber !== "")) {

            let regexExpVin: string = this.vinMetaData.FieldRegexPattern;
            let isValidVIN: boolean = new RegExp(regexExpVin).test(this.VinNumber);

            let regexExpYear: string = '[0-9]{4}';
            let isValidYear: boolean = new RegExp(regexExpYear).test(term);

            if ((isValidYear) && (isValidVIN)) {
                this.decodeVin(this.VinNumber);
            }
        }
    }

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.sortColumn = column;
        this.sortDirection = direction;

        this.ColummForSorting = column;
        this.DirectionForSorting = direction;
    }

    //region for en grid
    get vinDecodeds$() { return this._vinDecodeds$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }

    get searchVariableTerm() { return this._state.searchVariableTerm; }
    get searchValueTerm() { return this._state.searchValueTerm; }


    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }

    set searchVariableTerm(searchVariableTerm: string) { this._set({ searchVariableTerm }); }
    set searchValueTerm(searchValueTerm: string) { this._set({ searchValueTerm }); }


    set sortColumn(sortColumn: string) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    private _set(patch: Partial<VINDecodedState>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {

        const { sortColumn, sortDirection, pageSize, page, searchVariableTerm, searchValueTerm } = this._state;

        // 1. sort
        let VINDecoded = sort(this.vinData, sortColumn, sortDirection);
        //console.log('earlyNotifications:', earlyNotifications);

        this.ColummForSorting = sortColumn;
        this.DirectionForSorting = sortDirection;

        // 2. filter ENCaseNum
        if (searchVariableTerm !== '') {
            VINDecoded = VINDecoded.filter(vinDecoded => matcheVariable(vinDecoded, searchVariableTerm));
        }

        // 3. filter MDECaseNum
        if (searchValueTerm !== '') {
            VINDecoded = VINDecoded.filter(vinDecoded => matcheValue(vinDecoded, searchValueTerm));
        }
        // 4. total number of results
        const total = VINDecoded.length;

        // 5. paginate
        VINDecoded = VINDecoded.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

        return of({ VINDecoded, total });
    }
    //end of region

    private getVINNumber(): string {
        switch (this.URLfragment) {
            case "vehicle": {
                this.pageTitle = "Vehicle VIN";
                this.isYearVisible = true
                setTimeout((() => { //Time delay needed to let control become visible
                    this.txtModelYear.objTextbox.nativeElement.focus();
                }).bind(this), 1);
                return this.veh.VIN;

            }
            case "trailerVIN0": {
                this.pageTitle = "Trailer VIN #1";
                this.trailerIndex = 0;
                this.trailer = this.veh.Trailer1[0];
                //this.txtVinDecodeVIN.objTextbox.nativeElement.focus();
                return this.veh.Trailer1[0].TrailerVIN;
            }
            case "trailerVIN1": {
                this.pageTitle = "Trailer VIN #2";
                this.trailerIndex = 1;
                this.trailer = this.veh.Trailer1[1];
                // this.txtVinDecodeVIN.objTextbox.nativeElement.focus();
                return this.veh.Trailer1[1].TrailerVIN;
            }
            case "trailerVIN2": {
                this.pageTitle = "Trailer VIN #3";
                this.trailerIndex = 2;
                this.trailer = this.veh.Trailer1[2];
                // this.txtVinDecodeVIN.objTextbox.nativeElement.focus();
                return this.veh.Trailer1[2].TrailerVIN;
            }
            default: {
                this.pageTitle = "Vehicle VIN";
                this.isYearVisible = true;
                setTimeout((() => { //Time delay needed to let control become visible
                    this.txtModelYear.objTextbox.nativeElement.focus();
                }).bind(this), 1);
                return this.veh.VIN;
            }
        }
    }

    private getVINDecoderResults(): void {
        if (this.VinNumber) {
            if (!this.isYearVisible) {
                this.vinModelYear = null;
            }
            let intMdlYear: number = this.isTrailer ? RBISDataValue.Blank : this.veh.ModelYr;
            this._vehicleService.VehicleVINDecoder(this.VinNumber, intMdlYear).then(result => {
                if (result && result.Results) {
                    this.vinData = result.Results;
                    this.setObjVinDecodeData(result);

                    this.vehicleBodyImgUrl = this.vinData.find(x => x.VariableId == VehicleElement.BodyClass).ValueId != null
                        ? this.imgBodyClassPath + this.vinData.find(x => x.VariableId == VehicleElement.BodyClass).ValueId + ".png"
                        : this.imgBodyClassPath + "0.gif";

                    //error alert card
                    this.errorCodeValue = this.vinData.find(x => x.VariableId == VehicleElement.ErrorCode).Value;

                    if (this.errorCodeValue != null)
                        this.blnVINIsClean = this.errorCodeValue.split(',').every(strCode => VehicleService.arrVinDecodesCleanList.includes(strCode));
                    else
                        this.blnVINIsClean = false;

                    if (this.errorCodeValue === "0") {
                        this.alertType = "success";
                    }
                    //else if (this.errorCodeValue.includes("1")) {
                    else if (this.errorCodeValue !== "0") {
                        this.alertType = "warning";
                    }
                    this.errorTextVariable = this.vinData.find(x => x.VariableId == VehicleElement.ErrorText).Variable;
                    this.errorTextValue = this.vinData.find(x => x.VariableId == VehicleElement.ErrorText).Value;
                    this.suggestedVIN = this.vinData.find(x => x.VariableId == VehicleElement.SuggestedVIN).Value;
                    this.possibleValues = this.vinData.find(x => x.VariableId == VehicleElement.PossibleValues).Value;
                    console.log('errorCodeValue: ', this.errorCodeValue);
                    console.log('suggestedVIN: ', this.suggestedVIN);
                    console.log('possibleValues: ', this.possibleValues);

                    this.manufacturer = this.vinData.find(x => x.VariableId == VehicleElement.Manufacturer).Value;
                    this.modelYear = this.vinData.find(x => x.VariableId == VehicleElement.ModelYear).Value != null
                        ? this.vinData.find(x => x.VariableId == VehicleElement.ModelYear).Value : "";
                    this.make = this.vinData.find(x => x.VariableId == VehicleElement.Make).Value != null
                        ? this.make = this.vinData.find(x => x.VariableId == VehicleElement.Make).Value : "";
                    this.model = this.vinData.find(x => x.VariableId == VehicleElement.Model).Value;
                    this.bodyClass = this.vinData.find(x => x.VariableId == VehicleElement.BodyClass).Value;
                    this.vehicleType = this.vinData.find(x => x.VariableId == VehicleElement.VehicleType).Value != null
                        ? this.vehicleType = this.vinData.find(x => x.VariableId == VehicleElement.VehicleType).Value : "";

                    this.NCSAMake = this.vinData.find(x => x.VariableId == VehicleElement.NCSAMake).Value;
                    this.NCSAModel = this.vinData.find(x => x.VariableId == VehicleElement.NCSAModel).Value;
                    this.NCSABodyType = this.vinData.find(x => x.VariableId == VehicleElement.NCSABodyType).Value;

                    this.isTrailer = this.vinData.find(x => x.VariableId == VehicleElement.VehicleType).ValueId === "6";
                    //this.imgVehicleBody.nativeElement.src = this.vehicleBodyImgUrl;

                    console.log(this.vinData.find(x => x.VariableId == VehicleElement.VehicleType).ValueId);
                    console.log(this.isTrailer);
                    this.series = this.vinData.find(x => x.VariableId == VehicleElement.Series).Value;
                    this.grossVehicleWeightRating = this.vinData.find(x => x.VariableId == VehicleElement.GVWR).Value;
                    this.grossVehicleWeightRatingTo = this.vinData.find(x => x.VariableId == VehicleElement.GVWR_to).Value;
                    this.cylinders = this.vinData.find(x => x.VariableId == VehicleElement.EngineCylinders).Value;
                    this.engineModel = this.vinData.find(x => x.VariableId == VehicleElement.EngineModel).Value;
                    this.engineManufacturer = this.vinData.find(x => x.VariableId == VehicleElement.EngineManufacturer).Value;
                    this.transmissionSpeed = this.vinData.find(x => x.VariableId == VehicleElement.TransmissionSpeeds).Value;

                    this.trim = this.vinData.find(x => x.VariableId == VehicleElement.Trim).Value;
                    this.driveType = this.vinData.find(x => x.VariableId == VehicleElement.DriveType).Value;
                    this.primaryFuelType = this.vinData.find(x => x.VariableId == VehicleElement.FuelTypePrimary).Value;
                    this.engineBrake = this.vinData.find(x => x.VariableId == VehicleElement.EngineHP).Value;
                    this.engineDisplacement = this.vinData.find(x => x.VariableId == VehicleElement.DisplacementCC).Value;
                    this.transmissionStyle = this.vinData.find(x => x.VariableId == VehicleElement.TransmissionStyle).Value;

                    this.airbagsFront = this.vinData.find(x => x.VariableId == VehicleElement.AirBagLocFront).Value;
                    this.airbagsKnee = this.vinData.find(x => x.VariableId == VehicleElement.AirBagLocKnee).Value;
                    this.airbagsSide = this.vinData.find(x => x.VariableId == VehicleElement.AirBagLocSide).Value;
                    this.airbagsCurtain = this.vinData.find(x => x.VariableId == VehicleElement.AirBagLocCurtain).Value;
                    this.airbagsSeatCushion = this.vinData.find(x => x.VariableId == VehicleElement.AirBagLocSeatCushion).Value;
                    this.airbagsOtherRestraintInfo = this.vinData.find(x => x.VariableId == VehicleElement.OtherRestraintSystemInfo).Value;

                    this.plantInformation = this.nullPlantInformationToEmpty(this.vinData.find(x => x.VariableId == VehicleElement.PlantCity).Value) + ", " + this.nullPlantInformationToEmpty(this.vinData.find(x => x.VariableId == VehicleElement.PlantCountry).Value) + ", " + this.nullPlantInformationToEmpty(this.vinData.find(x => x.VariableId == VehicleElement.PlantState).Value);
                    this.plantInformation = this.checkComma(this.plantInformation);

                    this._state.page = 1;
                    this._state.pageSize = 10;

                    this._search$.pipe(
                        tap(() => this._loading$.next(true)),
                        debounceTime(300),
                        switchMap(() => this._search()),
                        delay(200),
                        finalize(() => this._loading$.next(false)),
                        tap(() => this._loading$.next(false))
                    ).subscribe(result => {
                        this._vinDecodeds$.next(result.VINDecoded);
                        this._total$.next(result.total);
                    });

                    this._search$.next();
                }

                //this.vinDecodedSubscription.unsubscribe();
            });
        }
    }

    private nullPlantInformationToEmpty(value: any): any {
        return (value == null || value == undefined) ? '' : value;
    }

    private checkComma(value: any): any {
        return (value.trim() == ', ,') ? '' : value;
    }

    private decodeVin(strVinNumber: string): void {
        this.blnShowVINDecoder = true;

        if (strVinNumber == "" || VehicleService.arrNotDecodedVin.includes(strVinNumber)) {
            //this._modalService.setMessage("No VIN Checking is performed if VIN is blank, all 9s or 0s or 8s.", "info");
            this._modalService.resetMessage();
            this.blnShowVINDecoder = false;
            return;
        }

        if (!strVinNumber)
            strVinNumber = this.VinNumber;

        if (strVinNumber != "" && strVinNumber != undefined) {
            this._route.fragment.subscribe(async (fragment: string) => {
                if (fragment) {
                    if (fragment.includes("trailer")) {
                        this.isTrailer = true;
                    }
                }
            });

            this.getVINDecoderResults();
        }
    }

    private setObjVinDecodeData(vindata: any): void {
        setTimeout(() => {
            let objVinDecode = {} as vinDecode;
            objVinDecode.searchCriteria = vindata.SearchCriteria.substring(4);

            objVinDecode.ncsaMake = vindata.Results.find(x => x.VariableId == VehicleElement.NCSAMake).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.NCSAMake).ValueId) : RBISDataValue.Blank;

            objVinDecode.ncsaModel = vindata.Results.find(x => x.VariableId == VehicleElement.NCSAModel).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.NCSAModel).ValueId) : RBISDataValue.Blank;
            objVinDecode.ncsaBodyType = vindata.Results.find(x => x.VariableId == VehicleElement.NCSABodyType).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.NCSABodyType).ValueId) : RBISDataValue.Blank;
            objVinDecode.vpicMake = vindata.Results.find(x => x.VariableId == VehicleElement.Make).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.Make).ValueId) : RBISDataValue.Blank;
            objVinDecode.vpicModel = vindata.Results.find(x => x.VariableId == VehicleElement.Model).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.Model).ValueId) : RBISDataValue.Blank;
            objVinDecode.vpicBodyClass = vindata.Results.find(x => x.VariableId == VehicleElement.BodyClass).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.BodyClass).ValueId) : RBISDataValue.Blank;
            objVinDecode.vPICGVWR = vindata.Results.find(x => x.VariableId == VehicleElement.GVWR).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.GVWR).ValueId) : RBISDataValue.Blank;
            objVinDecode.vPICGVWRTo = vindata.Results.find(x => x.VariableId == VehicleElement.GVWR_to).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.GVWR_to).ValueId) : RBISDataValue.Blank;
            objVinDecode.suggestedVIN = vindata.Results.find(x => x.VariableId == VehicleElement.SuggestedVIN).Value !== ""
                ? vindata.Results.find(x => x.VariableId == VehicleElement.SuggestedVIN).Value
                : this.VinNumber;

            objVinDecode.year = vindata.Results.find(x => x.VariableId == VehicleElement.ModelYear) ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.ModelYear).Value) : RBISDataValue.Blank;

            //objVinDecode.trailerVpicManufacture = vindata.Results.find(x => x.VariableId == VehicleElement.Manufacturer).ValueId;

            objVinDecode.intTrailerVpicMake = vindata.Results.find(x => x.VariableId == VehicleElement.Make).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.Make).ValueId) : RBISDataValue.Blank;
            objVinDecode.strTrailerVpicMake = vindata.Results.find(x => x.VariableId == VehicleElement.Make).Value;

            objVinDecode.intTrailerVpicBodyClass = vindata.Results.find(x => x.VariableId == VehicleElement.BodyClass).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.BodyClass).ValueId) : RBISDataValue.Blank;
            objVinDecode.strTrailerVpicBodyClass = vindata.Results.find(x => x.VariableId == VehicleElement.BodyClass).Value;

            objVinDecode.intTrailerVpicBodyType = vindata.Results.find(x => x.VariableId == VehicleElement.TrailerBodyType).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.TrailerBodyType).ValueId) : RBISDataValue.Blank;
            objVinDecode.strTrailerVpicBodyType = vindata.Results.find(x => x.VariableId == VehicleElement.TrailerBodyType).Value;

            objVinDecode.intTrailerVpicGVWR = vindata.Results.find(x => x.VariableId == VehicleElement.GVWR).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.GVWR).ValueId) : RBISDataValue.Blank;
            objVinDecode.strTrailerVpicGVWR = vindata.Results.find(x => x.VariableId == VehicleElement.GVWR).Value;

            objVinDecode.intTrailerVpicModel = vindata.Results.find(x => x.VariableId == VehicleElement.Model).ValueId ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.Model).ValueId) : RBISDataValue.Blank;
            objVinDecode.strTrailerVpicModel = vindata.Results.find(x => x.VariableId == VehicleElement.Model).Value;

            objVinDecode.intTrailerVpicYear = vindata.Results.find(x => x.VariableId == VehicleElement.ModelYear).Value ? parseInt(vindata.Results.find(x => x.VariableId == VehicleElement.ModelYear).Value) : RBISDataValue.Blank;
            objVinDecode.strTrailerVpicYear = vindata.Results.find(x => x.VariableId == VehicleElement.ModelYear).Value;

            objVinDecode.errorCode = vindata.Results.find(x => x.VariableId == VehicleElement.ErrorCode).Value;
            this._sharedDataService.setVinDecode(objVinDecode); //TODO: why save this object at the service level and not at component level?
        }, 50); //TODO: what is the purpose of this delay?

    }

    public BackToVINTab(): void {
        this.objForm.form.markAsPristine();
        this._route.fragment.subscribe(async (fragment: string) => {
            if (fragment) {
                if (fragment.includes("trailer")) {
                    await this.setTrailerValue();
                    this._router.navigate([this.stateNum, 'case', this.accid, 'sfrvehicle', this.vehicleid, 'sfrvehicle', 'vehicleTrailer'], { fragment: this.URLfragment });
                }
                else if (fragment == 'sfrvehicle' || fragment == 'vehicle') {
                    if (this.isTrailer) {// are giving an errror msg if User are trying to accept Trailer VIN decoded for a vehicle 
                        this._modalService.setMessage("This is a Trailer VIN. Please enter the VIN for the vehicle Power Unit to proceed further.", "danger");
                        return;
                    }

                    await this.setVehicleValue();
                    this._vehicleService.vinDecodedSubscription.next(true);
                    this._router.navigate([this.stateNum, 'case', this.accid, 'sfrvehicle', this.vehicleid, 'sfrvehicle', 'sfrvin']);
                }
            }
            else {
                if (this.isTrailer) {// are giving an errror msg if User are trying to accept Trailer VIN decoded for a vehicle 
                    this._modalService.setMessage("This is a Trailer VIN. Please enter the VIN for the vehicle Power Unit to proceed further.", "danger");
                    return;
                }

                await this.setVehicleValue();
                this._router.navigate([this.stateNum, 'case', this.accid, 'sfrvehicle', this.vehicleid, 'sfrvehicle', 'sfrvin']);
            }
        });
    }

    public acceptVIN(): void {
        this.objForm.form.markAsPristine();
        if (this.VinNumber) {
           
            this.objForm.form.markAsPristine();
            this._route.fragment.subscribe(async (fragment: string) => {
                console.log(fragment);
                if (fragment) {
                    if (fragment.includes("trailer")) {
                        await this.setTrailerValue();
                        this._router.navigate([this.stateNum, 'case', this.accid, 'sfrvehicle', this.vehicleid, 'sfrvehicle', 'sfrvin']);
                }
                    else if (fragment == 'sfrvehicle' || fragment == 'vehicle') {
                        if (this.isTrailer) {// are giving an errror msg if User are trying to accept Trailer VIN decoded for a vehicle 
                            this._modalService.setMessage("This is a Trailer VIN. Please enter the VIN for the vehicle Power Unit to proceed further.", "danger");
                            return;
                        }

                        await this.setVehicleValue();
                        this._vehicleService.vinDecodedSubscription.next(true);
                        this._router.navigate([this.stateNum, 'case', this.accid, 'sfrvehicle', this.vehicleid, 'sfrvehicle', 'sfrvin']);
               }
                }
                else {
                    if (this.isTrailer) {// are giving an errror msg if User are trying to accept Trailer VIN decoded for a vehicle 
                        this._modalService.setMessage("This is a Trailer VIN. Please enter the VIN for the vehicle Power Unit to proceed further.", "danger");
                        return;
                    }

                    await this.setVehicleValue();
                    this._vehicleService.vinDecodedSubscription.next(true);
                    this._router.navigate([this.stateNum, 'case', this.accid, 'sfrvehicle', this.vehicleid, 'sfrvehicle', 'sfrvin']);
                  }
            });
        }
    }

    public async onVINRequest(content): Promise<void> {
        if (this._router.url.includes('trailer')) {
            this.setTrailerValue(true);
        } else {
            await this.setVehicleValue(true);
        }
    }

    public TranslateVPICGVWRtoRBIS(intVpicGvWR: number): number {
        if (VehicleService.arrOnlyRBISGVWR.includes(intVpicGvWR))
            return intVpicGvWR;

        let objvpicGVWRMapping: vpicGVWRMapping = this.arrVpicGVWRMapping.find(i => i.vPICGVWRID == intVpicGvWR);
        if (!objvpicGVWRMapping)
            return RBISDataValue.Blank;
        return objvpicGVWRMapping.RBISGVWRID;
    }

    /**
     * Updates client-side data model (including Created Records) and dispatches a save operation to commit the data model
     * on the server. Does not refresh Edit Check case stats.
     */
    public async setVehicleValue(isVinRequest: boolean = false) {
        let objVinData: vinDecode = await this._sharedDataService.getVinDecode();
        if (objVinData) {
            if (!this.veh.VPICDECODE) {
                this.veh.VPICDECODE = AutofillService.AddModelObject(this.veh, 'Veh', 'VPICDECODE');
            }
            this.veh.ErrorCode = objVinData.errorCode;
            this.veh.VIN = objVinData.searchCriteria;
            this.veh.VPICDECODE.VIN = this.veh.VIN;
            this.veh.vPICMake = objVinData.vpicMake;
            this.veh.vPICModel = objVinData.vpicModel;

            this.veh.vPICBodyClass = objVinData.vpicBodyClass;
            AutofillService.OnChange(null, this.veh, 'vPICBodyClass', true, true, false); //There's at least one autofill rule conditioned on vPICBodyClass

            this.veh.Make = objVinData.ncsaMake;
            // It is a workaround solution to convert the NCSAModelID what RBIS has. 
            //NCSAModel returns (NSCAMakeId * 1, 000, 000 + NCSAModelID) from internal API and modulo arithmetic has been implemented to convert it to actual VPIC value 
            this.veh.Model = (objVinData.ncsaModel % 1000000);
            this.veh.Body = objVinData.ncsaBodyType;

            if (!this.veh.ModelYr || this.veh.ModelYr == RBISDataValue.Blank) {
                this.veh.ModelYr = isNaN(objVinData.year) ? RBISDataValue.Blank : objVinData.year;
            }

            if (objVinData.vPICGVWR > RBISDataValue.Blank) {
                this.veh.vPICGVWR = this.TranslateVPICGVWRtoRBIS(objVinData.vPICGVWR);
                this.veh.vPICGVWRTo = objVinData.vPICGVWRTo == RBISDataValue.Blank ? this.veh.vPICGVWR : this.TranslateVPICGVWRtoRBIS(objVinData.vPICGVWRTo);
            }
            this._vehicleService.intVpicGVWR = this.veh.vPICGVWR;

            let blnChangeRolledBack: boolean = false;

            if (!isVinRequest && this.veh.vPICBodyClass != this.vehOriginal.vPICBodyClass) {
                let objNewSelection: DrpDownOptions = { intValue: this.veh.vPICBodyClass } as DrpDownOptions;
                blnChangeRolledBack = await this._autofillService.OnCreatedRecordPrerequisitesChange(this.veh, 'vPICBodyClass', objNewSelection, this.vehOriginal);

                if (blnChangeRolledBack) {
                    this.veh.ErrorCode = this.vehOriginal.ErrorCode
                    this.veh.VIN = this.vehOriginal.VIN;
                    this.veh.VPICDECODE.VIN = this.vehOriginal.VIN;
                    this.veh.vPICMake = this.vehOriginal.vPICMake;
                    this.veh.vPICModel = this.vehOriginal.vPICModel;
                    this.veh.vPICBodyClass = this.vehOriginal.vPICBodyClass;
                    this.veh.Make = this.vehOriginal.Make;
                    // It is a workaround solution to convert the NCSAModelID what RBIS has. 
                    //NCSAModel returns (NSCAMakeId * 1, 000, 000 + NCSAModelID) from internal API and modulo arithmetic has been implemented to convert it to actual VPIC value 
                    this.veh.Model = (this.vehOriginal.Model % 1000000);
                    this.veh.Body = this.vehOriginal.Body;
                    this.veh.ModelYr = this.vehOriginal.ModelYr;
                    this.veh.vPICGVWR = this.vehOriginal.vPICGVWR;
                    this.veh.vPICGVWRTo = this.vehOriginal.vPICGVWRTo;
                }
            }

            if (!blnChangeRolledBack) {
                let objVpicDecode: VPICDECODE = await this._vehicleService.UpdateVehicleVpicDecode(this.veh);
                objVpicDecode.Veh = this.veh;
                this.veh.VPICDECODE = objVpicDecode;

                if (isVinRequest) {
                    this._modalService_CannedPopups.vinRequest(this.accid, this.veh.VNumber, 0, 0, false, this.veh.VIN).toPromise();
                }

                this._sharedDataService.setVinDecode(null);
            }
        }
        else {
            if (isVinRequest) {
                await this._modalService_CannedPopups.vinRequest(this.accid, this.veh.VNumber, 0, 0, false, this.veh.VIN).toPromise();
            }
        }
    }

    public async setTrailerValue(isVinRequest: boolean = false) {

        let data: vinDecode = await this._sharedDataService.getVinDecode();
        let n = this.trailerIndex;
        if (data) {
            if (!this.veh.Trailer1[n].TrailerVPICDECODE) {
                this.veh.Trailer1[n].TrailerVPICDECODE = AutofillService.AddModelObject(this.veh.Trailer1[n], 'Trailer', 'TrailerVPICDECODE');
            }
            this.veh.Trailer1[n].TrailerVIN = data.searchCriteria;
            this.veh.Trailer1[n].TrailerVPICDECODE.VIN = this.veh.Trailer1[n].TrailerVIN;
            if (data.intTrailerVpicGVWR > RBISDataValue.Blank)
                this.veh.Trailer1[n].TrailerGVWR = this.TranslateVPICGVWRtoRBIS(data.intTrailerVpicGVWR);

            let objTrailerDecode: TrailerVPICDECODE = await this._vehicleService.UpdateTrailerVpicDecode(this.veh.Trailer1[n]);
            this.veh.Trailer1[n].TrailerVPICDECODE = objTrailerDecode;
            objTrailerDecode.Trailer.Veh = this.veh.Trailer1[n].Veh;


            this._sharedDataService.setVinDecode(null);
            //});
            if (isVinRequest) {
                this._modalService_CannedPopups.vinRequest(this.accid, this.veh.VNumber, this.veh.Trailer1[n].SeqNum, 0, false, this.veh.Trailer1[n].TrailerVIN).toPromise();
            }
        } else {
            if (isVinRequest) {
                this._modalService_CannedPopups.vinRequest(this.accid, this.veh.VNumber, this.veh.Trailer1[n].SeqNum, 0, false, this.veh.Trailer1[n].TrailerVIN).toPromise();
            }
        }

    }

    public vinChanged(): void {
        ////Get the regular expression for VIN element from metadata.
        let regexExp: string = this.vinMetaData.FieldRegexPattern;
        let isValidVIN: boolean = new RegExp(regexExp).test(this.VinNumber);

        if (isValidVIN) {
            this.decodeVin(this.VinNumber);
        }
    }

}
