import { Injectable, QueryList } from '@angular/core';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';

import { Acc } from 'src/app/models/acc';
import { CheckBlanks } from 'src/app/models/check-blanks';
import { EarlyNotify } from 'src/app/models/early-notify';
import { FormName, RBISUser, DBMode } from 'src/app/models/enums/app.enums';
import { ViolatedRules } from 'src/app/models/violated-rules';
import { vinDecode } from 'src/app/models/vin-decode';
import { VINDecode } from 'src/app/models/v-indecode';
import { EarlyNotificationFilters } from 'src/app/models/early-notification-filters';
import { EarlyNotificationState } from 'src/app/models/early-notification-state'
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { CaseOverRiddenRules } from 'src/app/models/case-over-ridden-rules';

//components
import { ActionButtonsComponent } from 'src/app/ui/action-buttons/action-buttons.component';
import { BaseComponent } from 'src/app/helper/basecomponent';

import { GenericService } from 'src/app/services/generic.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { usp_EDT_GetStylesByStateNum_Result } from '../models/usp_EDT_GetStylesByStateNum_Result';
import { GetCaseBlanks_Result } from '../models/GetCaseBlanks_Result';
import { NgForm } from '@angular/forms';
import { CaseService } from './case.service';

export class AppSettings {
    public intMode: number;
    public intYear: number;
    public intPreviousYear: number;
    public intApplicationId: number;
    public intSessionIdleMaxMinutes: number;
    public geoLocatorUrl: string;
    public vpicDecoderUrl: string;
    public strCurrentSSCodingManual: string;
    public strCurrentFarsCrssCodingManual: string;
    public strCurrentFarsCrssPedBikeManual: string;
    public intClientSideSlowRequestThresholdMillisecond: number;
    public strPrivilege: string;
    public strAllowedFileTypes: string;
    public toYear: number;
}

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {
    /**
     * Geolocator cannot do a handshake with MAX.GOV in order to issue an ASP.NET Core session cookie while embedded in an IFRAME, so the first
     * time that Geolocator is accessed it must be in a separate tab/window. Once we have an ASP.NET Core session cookie, we can keep it alive by
     * periodically issuing a request to it in a hidden IFRAME as Geolocator does not disallow embedding in an IFRAME.
     * This flag is essentially static and keeps track of whether there is a Geolocator ASP.NET Core session cookie to keep alive.
     **/
    public blnGeolocatorSessionExists: boolean = false;

    IsMultipleSiteAccess(mossSCISiteList: any[]): boolean {
        if (this.mode != DBMode.MOSS)
            return false;

        //if no individual site access is given
        if (mossSCISiteList.filter(x => x == 1 || x == 2 || x == 3).length == 0)
            return true;

        else if (mossSCISiteList.filter(x => x == 1 || x == 2 || x == 3) != undefined) {
            
            if (mossSCISiteList.filter(x => x == 1 || x == 2 || x == 3).length > 1) {
                if (mossSCISiteList.filter(x => x == 0).length == 0) {
                    console.log(mossSCISiteList)
                    return true;
                }
            }
        }

        ////If multiple rights are assigned, readonly is must
        //if (mossSCISiteList.length > 1 && mossSCISiteList.filter(x => x == 0) != undefined
        //    && mossSCISiteList.filter(x => x == 1 || x == 2 || x == 3) == undefined) {
        //    return true;
        //}
        ////mulltiple rights assigned and admin role
        //else if (mossSCISiteList.length > 1 && mossSCISiteList.filter(x => x == 4) != undefined
        //    && mossSCISiteList.filter(x => x == 1 || x == 2 || x == 3) == undefined) {
        //    return true;
        //}
        //else if (mossSCISiteList.length > 0 && mossSCISiteList.filter(x => x == 1 || x == 2 || x == 3) == undefined) {
        //    return true;
        //}
        return false;
    }

    /**
    * This observable subject acts as a messaging bus to let components emit events without having to be aware of whether someone is listening.
    **/
    public subGeneralMessage: Subject<{ strMessageType: string, objData: any }> = new Subject<{ strMessageType: string, objData: any }>();
    /**
     * Fired whenever something happens that affects whether the appearance of ActionButtonComponent changes.
     * For example: case is opened, case is closed, case is deleted, case is modified, case is saved, case type changes
     * Feed in null values if you want ActionButtonsComponent to refresh with it's existing internal state.
     **/
    public subReEvalCaseActionButtons: BehaviorSubject<{ objComponent: Array<BaseComponent>, objCase: Acc }>
        = new BehaviorSubject<{ objComponent: Array<BaseComponent>, objCase: Acc }>({ objComponent: null, objCase: this._caseService.acc });

    //public subReEvalCaseActionButtons: Subject<{ objComponent: BaseComponent, objCase: Acc }> = new Subject<{ objComponent: BaseComponent, objCase: Acc }>();

    /**
     * Fired after every commit of the data model to the database.
     **/
    public observeSave = new Subject<boolean>();

    /**
    * Fired from EditCheckHelper.HighlightViolatedElement();
    * Used to enable/disable Override Edit Check btn
    **/
    public observeblnViolatedRule = new Subject<boolean>();

    sbsRefreshAcc: Subscription;

    private _objAppSettings: AppSettings;

    //MODE KEY VALUE
    // fars = 1
    // crss = 4
    // recode = 16
    // nits = 32    
    private mode: number = 1;
    blnIsEDTState: boolean = false;
    blnIsEDTCase: boolean = false;
    edtLastProcessedDate: Date;
    edtLastRecvdDate: Date;
    public user: string;
    public selectedState: number;
    public ddoselectedState: DrpDownOptions;

    public accid: number;
    private nonOcupantid: number;
    private vehicleid: number;
    private personid: number;
    private vinNumber: string;
    private violatedRule: ViolatedRules;
    private violatedRuleOR: ViolatedRules;
    private caseOverRiddenRules: CaseOverRiddenRules;
    private vinDecodeResult: vinDecode;
    private caseBlanks: GetCaseBlanks_Result;
    private intCurrentCaseNumber: number;
    private ruleDetails: string;
    private arrEDTStyle: usp_EDT_GetStylesByStateNum_Result[];
    private _arrValidStates: Array<number>;
    private _intBeforeSaveState: number;

    private earlyNotify: EarlyNotify;
    earlyNotificationFilters: EarlyNotificationFilters;
    earlyNotificationState: EarlyNotificationState;

    blanksList: CheckBlanks[];
    //lastCaseUrl: string = 'case/' + this.accid + '/crash/' + this.accid + '/crash';

    lastCaseUrl: string;

    //Logic for utilizing ActionButtonsComponent Clear Form click for form element different then TypeAhead, MultiSelect or TextField
    public subjectClearForm = new Subject<any>();
    public bhvSubjectClearForm = new BehaviorSubject<boolean>(false);
    public subClearLngLatFields = new Subject<string>();
    //having Subject<DrpDownOptions> to store Selected State after user login the system.
    public subjectSelectedState = new Subject<DrpDownOptions>();

    //public subjectRefreshDataObj = new BehaviorSubject(false);

    public blnCanGetCaseDetails: boolean = true;

    public subjectBackToCase = new Subject<any>();
    public subjectBackToCheckCase = new Subject<any>();
    public subjectSaveOverrideReason = new Subject<any>();

    constructor(
        private _baseService: GenericService<any>,
        private _modalService: ModalService,
        private _utilService: UtilService,
        private _caseService: CaseService
    ) {
        this.bhvSubjectClearForm.next(false);
        this.GetAppSettings();
    }

    public async GetAppSettings(): Promise<AppSettings> {
        if (document.cookie.split(';').find(x => x.startsWith('.AspNetCore.Cookies') || x.startsWith(' .AspNetCore.Cookies'))) // Else do nothing as application is about to redirect to MAX.GOV
        {
            if (!this._objAppSettings) {
                try {
                    this._baseService.actionURL = "api/util/GetAppSettings";
                    this._objAppSettings = await this._baseService.getDataPromise();
                    this.setMode(this._objAppSettings.intMode);
                }
                catch (objError) {
                    //this._modalService.setMessage('Failed to get app settings: ' + objError.message, 'error');
                    console.warn('Failed to get app settings.', objError);
                }
            }

            return this._objAppSettings;
        }
        else {
            console.log('SharedDataService.GetAppSettings(): Retrieval of application settings bypassed. User is not authenticated. Redirect to MAX.GOV imminent.');
            return null;
        }
    }

    public async GetConfigKeyValue(key: string): Promise<string> {

        this._baseService.actionURL = "api/util/GetConfigKeyValue/" + key;
        return await this._baseService.getDataPromise();
    }

    public getCanGetCaseDetails() {
        return this.blnCanGetCaseDetails;
    }

    public setCanGetCaseDetails(blnCanGetCaseDetails: boolean) {
        this.blnCanGetCaseDetails = blnCanGetCaseDetails;
    }


    setListofValidStates(arrValidStates: Array<number>): void {
        this._arrValidStates = arrValidStates;
    }
    getListOfValidStates(): Array<number> {
        return this._arrValidStates;
    }

    getIsEDTState() {
        return this.blnIsEDTState;
    }

    setIsEDTState(blnIsEdT: boolean) {
        this.blnIsEDTState = blnIsEdT;
    }


    getIsEDTCase() {
        return this.blnIsEDTCase;
    }

    setIsEDTCase(blnIsEdTCase: boolean) {
        this.blnIsEDTCase = blnIsEdTCase;
    }

    getEDTLastProcessedDate() {
        return this.edtLastProcessedDate;
    }

    setEDTLastProcessedDate(edtLastProsDate: Date) {
        this.edtLastProcessedDate = edtLastProsDate;
    }

    getEDTLastRecvdDate() {
        return this.edtLastRecvdDate;
    }

    setEDTLastRecvdDate(edtLastRecvDate: Date) {
        this.edtLastRecvdDate = edtLastRecvDate;
    }

    getMode() {
        return this.mode;
    }

    setMode(mode) {
        this.mode = mode;
    }

    getUser() {
        return this.user;
    }

    public setUserName(strUserName: string): void {
        this.user = strUserName;
    }

    getBeforeSaveState() {
        return this._intBeforeSaveState;
    }

    setBeforeSaveState(selectedState) {
        this._intBeforeSaveState = selectedState;
    }

    getSelectedState() {
        return this.selectedState;
    }

    setSelectedState(selectedState) {
        this.selectedState = selectedState;
    }
    //ddoselectedState
    getDDOSelectedState() {
        return this.ddoselectedState;
    }

    setDDOSelectedState(ddoselectedState) {
        this.ddoselectedState = ddoselectedState;
    }

    getBlanksList() {
        return this.blanksList;
    }

    setBlanksList(blanksList) {
        this.blanksList = blanksList;
    }

    getLastCaseUrl() {
        return this.lastCaseUrl;
    }

    setLastCaseUrl(lastCaseUrl) {
        this.lastCaseUrl = lastCaseUrl;
    }


    getAccId() {
        return this.accid;
    }
    setAccId(accid) {
        this.accid = accid;
    }

    getCurrentCaseNumber() {
        return this.intCurrentCaseNumber;
    }

    setCurrentCaseNumber(intCurrentCaseNumber) {
        this.intCurrentCaseNumber = intCurrentCaseNumber;
    }

    getCrashId() { }
    setCrashId() { }

    getNonOccupantid() {
        return this.nonOcupantid;
    }
    setNonOccupantid(nonOcupantid) {
        this.nonOcupantid = nonOcupantid;
    }

    getVehicleId() {
        return this.vehicleid;
    }

    setVehicleID(vehicleid) {
        this.vehicleid = vehicleid;
    }

    getPersonId() {
        return this.personid;
    }
    setPersonId(personid) {
        this.personid = personid;
    }

    getViolatedRule(): Promise<ViolatedRules> {
        return new Promise((resolve) => {
            resolve(this.violatedRule)
        });
    }

    getStateEDTStyle(): Promise<usp_EDT_GetStylesByStateNum_Result[]> {
        return new Promise((resolve) => {
            resolve(this.arrEDTStyle)
        });
    }

    setStateEDTStyle(edtStyle) {
        this.arrEDTStyle = edtStyle;
    }

    setViolatedRuleOR(rule) {
        this.violatedRuleOR = rule;
    }

    getViolatedRuleOR(): Promise<ViolatedRules> {
        return new Promise((resolve) => {
            resolve(this.violatedRuleOR)
        });
    }


    setCaseBlank(objBlank: GetCaseBlanks_Result): void {
        this.caseBlanks = objBlank;
    }

    getCaseBlank(): GetCaseBlanks_Result {
        return this.caseBlanks;
    }

    setViolatedRuleOREdit(rule) {
        this.caseOverRiddenRules = rule;
    }

    getViolatedRuleOREdit(): Promise<CaseOverRiddenRules> {
        return new Promise((resolve) => {
            resolve(this.caseOverRiddenRules)
        });
    }


    setViolatedRule(rule) {
        this.violatedRule = rule;
    }



    getVinDecode(): Promise<vinDecode> {
        return new Promise((resolve) => {
            resolve(this.vinDecodeResult)
        });
    }

    setVinDecode(vinDecode) {
        this.vinDecodeResult = vinDecode;
    }

    getVinNumber(): string {
        return this.vinNumber;
    }

    setVinNumber(vinNumber: string) {
        this.vinNumber = vinNumber;
    }

    getEarlyNotify() {
        return this.earlyNotify;
    }

    setEarlyNotify(earlyNotify) {
        this.earlyNotify = earlyNotify;
    }

    //getEarlyNotificationPageState() {
    //    return this.earlyNotificationState;
    //}

    //setEarlyNotificationPageState(earlyNotificationState) {
    //    this.earlyNotificationState = earlyNotificationState;
    //} 

    getCookie(name: string): string {
        let cookie = funcGetCookie(name);
        return cookie;
    }

    setNewCookie(name: string, val: string, expires: Date) {
        funcSetCookie(name, val, expires);
    }

    deleteCookie(name: string) {
        funcDeleteCookie(name);
    }

    public IsEDTCase(objAcc: Acc): boolean {
        return (objAcc.StateCaseID != "" || objAcc.StateCaseID != null) && (objAcc.CreatedBy == RBISUser.EDT);
    }
}

export function funcSetCookie(name: string, val: string, expires: Date = new Date((new Date()).getTime() + (7 * 24 * 60 * 60 * 1000))) {
    const value = val;

    // By defult it will expire in 7 days unless caller passes in null value, which will make cookie transient
    let extExpires: string = '';

    if (expires) {
        extExpires = "; expires=" + expires.toUTCString() + "; path=/";
    }

    if (funcGetCookie(name)) {
        funcDeleteCookie(name);
    }

    // Set it
    document.cookie = name + "=" + value + extExpires;
}

export function funcGetCookie(name: string): string {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
        return parts.pop().split(";").shift();
    }

}

export function funcDeleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
}


