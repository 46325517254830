
<div class="row">    
    <div class="col">
        <ul class="nav nav-fill">
            <li class="nav-item">
                <a class="nav-item nav-link fw-bold px-0">{{modeString}}</a>
            </li>
            <li class="nav-item" *ngIf="objUser">
                <a class="nav-item nav-link px-0"><span>User: </span><span class="fw-bold">{{objUser.strEmail}} </span></a>
            </li>
            <!--<!--<li class="nav-item" *ngIf="mode == 1">
                <a class="nav-item nav-link px-0"><span>State: </span><span class="fw-bold">{{selectedState | enumAsString : 'States' }}</span></a>
            </li>-->
                    <!--<li class="nav-item" *ngIf="mode == 4">
                <a class="nav-item nav-link px-0"><span>PSU: </span><span class="fw-bold">{{selectedStateString}}</span></a>
            </li>-->
            <li class="nav-item" *ngIf="mode != 256">
                <a class="nav-item nav-link px-0"><span>Year: </span><span class="fw-bold">{{intYear}}</span></a>
            </li>
            <li class="nav-item" *ngIf="mode == 256">
                <a class="nav-item nav-link px-0"><span>Year: </span><span class="fw-bold">{{intYear}} - {{toYear}}</span></a>
            </li>
            <li class="nav-item" *ngIf="selectedState || mode === 4 || mode === 64 || mode === 128 || mode === 256">
                <a class="nav-item nav-link px-0" (click)="onLogout()"><i class="fas fa-sign-out-alt"> Logout </i></a>
            </li>
        </ul>
    </div>
</div>
