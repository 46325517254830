import { Directive, ElementRef, HostListener, Input, OnInit, AfterContentInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
    selector: '[numeric]'
})
export class NumericDirective {

    @Input('numeric') inputValue: string;
    @Input('numericType') numericType: string; // number | decimal    

    private regex = {
        number: new RegExp(/^\d+$/),
        decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
    };

    private specialKeys = {
        number: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Shift', 'Control'],
        decimal: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Shift', 'Control'],
    };

    constructor(private el: ElementRef) {
    } 

    /**
     * Intercepts and discards non-numeric (and non-delete/selection keys). Copy/Paste hotkeys are allowed.
     */
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys[this.numericType].indexOf(event.key) !== -1 ||
            (event.ctrlKey && (event.key == 'c' || event.key == 'v' || event.key == 'C' || event.key == 'V'))) {
            return;
        }

        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);

        if (next && !String(next).match(this.regex[this.numericType])) {
            event.preventDefault();
        }
    }

}
