import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { GenericService } from './generic.service';
import { SharedDataService } from './shared-data.service';
import { ViolatedRules } from './../models/violated-rules';

@Injectable({
    providedIn: 'root'
})

export class ViolatedRulesService {

    constructor(
        private _baseService: GenericService<any>,
        public _sharedDataService: SharedDataService
    ) { }

    // New way of fatching data
    public GetViolatedRules(accID: number): Observable<ViolatedRules[]> {
        this._baseService.actionURL = "api/case/GetRulesErrorList/" + accID;

        let item = this._baseService.getList().pipe(
            share()
        );

        return item;
    }
}
