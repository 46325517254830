import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from './../../../services/case.service';
import { UtilService } from './../../../services/util.service';

import { Acc } from './../../../models/acc';
import { Non_Occupants } from './../../../models/non-occupants';

import { NonOcc_ActPriorCrash } from './../../../models/non-occ-act-prior-crash';
import { NonOcc_ActAtCrash } from './../../../models/non-occ-act-at-crash';
import { NonOcc_Distract } from './../../../models/non-occ-distract';
import { NonOcc_Safety } from './../../../models/non-occ-safety';

import { Element_Specify } from '../../../models/element-specify';
import { TableFieldElements } from '../../../models/table-field-elements';
import { DrpDownOptions } from '../../../models/drp-down-options';
import { FormName, TableId } from '../../../models/enums/app.enums';

import { TypeaheadComponent } from './../../../components/typeahead/typeahead.component';
import { BaseComponent } from './../../../helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-non-occupant-non-motorist-actions',
    templateUrl: './non-occupant-non-motorist-actions.component.html',
    styleUrls: ['./non-occupant-non-motorist-actions.component.css']
})

export class NonOccupantNonMotoristActionsComponent extends BaseComponent implements OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_NonOccupantNonMotoristActionsComponent: string = null;
    private sbsNonOccId: Subscription;
    accid: number;
    nonOccid: number;
    intMode: number;

    acc: Acc;
    nonOccupant: Non_Occupants;

    objNonOccActPriorCrashTemplate: NonOcc_ActPriorCrash;
    objNonNonOccActAtCrashTemplate: NonOcc_ActAtCrash;
    objNonOccDistractTemplate: NonOcc_Distract;
    objInstanceElementSpecify: Element_Specify;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnInit() {
        super.ngOnInit();

        this._sharedService.GetAppSettings().then((appSetting: AppSettings) => {
            this.intMode = appSetting.intMode;            
        })

        if (this.printNonOccupantNum == 0) {
            this._route.parent.parent.params.subscribe((params => {
                this.accid = + params['caseid'];
            }).bind(this));
        }
        else {
            this.accid = this.acc.AccID;
        }

        //this._route.parent.parent.params.subscribe(params => {
        //    this.accid = + params['caseid'];
        //});

        //this._route.parent.params.subscribe(params => {
        //    this.nonOccid = + params['nonOccid'];
        //});

        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
        }

    }

    FormLoad() {
        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);

        if (this.nonOccupant.NonOcc_Safety === null) {
            this.nonOccupant.NonOcc_Safety = [] as NonOcc_Safety[];
        }

        this.objNonOccActPriorCrashTemplate = ObjectUtil.InstantiateNonOccActPriorCrash(this.nonOccupant);
        this.objNonNonOccActAtCrashTemplate = ObjectUtil.InstantiateNonOccActAtCrash(this.nonOccupant);
        this.objNonOccDistractTemplate = ObjectUtil.InstantiateNonOccDistract(this.nonOccupant);
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, 0, this.nonOccid);

        ObjectUtil.BindNonOccupantSafetyToParent(this.nonOccupant);
    }


    ngOnDestroy() {

    }

}
