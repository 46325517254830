import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from '../../../../services/case.service';
import { VehicleService } from '../../../../services/vehicle.service';
import { UtilService } from '../../../../services/util.service';
import { Acc } from '../../../../models/acc';
import { Veh } from '../../../../models/veh';
import { PreCrash } from '../../../../models/pre-crash';
import { PreCrash_DriVisObs } from '../../../../models/pre-crash-dri-vis-obs';
import { PreCrash_DriAvoidManvr } from '../../../../models/pre-crash-dri-avoid-manvr';
import { PreCrash_DriDistract } from '../../../../models/pre-crash-dri-distract';
import { Element_Specify } from '../../../../models/element-specify';
import { TableFieldElements } from '../../../../models/table-field-elements';
import { DrpDownOptions } from '../../../../models/drp-down-options';
import { FormName, TableId, RBISDataValue, KeyCode, DBMode } from '../../../../models/enums/app.enums';
import { TypeaheadComponent } from '../../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { PreCrash_ContribCirc } from 'src/app/models/pre-crash-contrib-circ';

import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { AutofillService } from 'src/app/services/autofill.service';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { Subscription } from 'rxjs';
import { concat } from 'rxjs/operators';
@Component({
    selector: 'app-striking-vehicle-precrash-precrash',
    templateUrl: './striking-vehicle-precrash-precrash.component.html',
    styleUrls: ['./striking-vehicle-precrash-precrash.component.css']
})
export class StrikingVehiclePrecrashPrecrashComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehiclePrecrashPrecrashComponent: string = null;
    accid: number;
    vehicleid: number;
    acc: Acc;
    veh: Veh;
    preCrash: PreCrash;
    objElementSpecify: Element_Specify[];
    fieldMetaData: TableFieldElements[];
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    objInstanceElementSpecify: Element_Specify;
    @ViewChild('txtCrashTypeCat', { static: false }) private objTypeaheadCrashTypeCat: TypeaheadComponent;
    @ViewChild('txtCrashTypeConf', { static: false }) private objTypeaheadCrashTypeConf: TypeaheadComponent;
    @ViewChild('txtCrashType', { static: false }) private objTypeaheadCrashType: TypeaheadComponent;

    blnIsUpdateCategoryEmpty: boolean;
    private sbsClearForm: Subscription;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _vehService: VehicleService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _autofillService: AutofillService,
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.vehicleid = this.printVehNum;
            this.accid = this.acc.AccID;
            this.initData();
        }
        this.refreshCrashType();
    }

    initData() {
        this.intMode = this.acc.Mode;

        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid)
        if (this.printVehNum > 0 && this.veh.PreCrash == undefined || this.veh.PreCrash == null) {
            AutofillService.AddModelObject(this.veh, 'Veh', 'PreCrash');
        }

        if (this.veh && this.veh.PreCrash) {
            this.preCrash = this.veh.PreCrash;
        }

        if (this.printVehNum > 0 && this.veh.PreCrash.PreCrash_MTSS == undefined || this.veh.PreCrash.PreCrash_MTSS == null) {
            AutofillService.AddModelObject(this.preCrash, 'PreCrash', 'PreCrash_MTSS');
        }

        if (this.preCrash) {
            //this.objPreCrashDriInattentionTemplate = ObjectUtil.InstantiatePreCrashDriInattention(this.preCrash);
            //this.objPreCrashDriDistractTemplate = ObjectUtil.InstantiatePreCrashDriDistract(this.preCrash);
            this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.preCrash.VNumber, 0);
        }        

        this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe(async () => {

            setTimeout(() => {//setting Timeout for model to get updated before subjectClearForm subscription occure!
                if (this.acc) {
                    this.reloadCrashType();
                }
            }, 1);

        });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.reloadCrashType();

        ////if user click Clear form, user want to see whole crash list when click on crash type
 
        //this.objTypeaheadCrashType.focus$.subscribe(async () => {
        //    if (this.veh.PreCrash.PreCrash_MTSS.CrashTypeSS == RBISDataValue.Blank) {
        //        this.blnIsUpdateCategoryEmpty = false;

        //        if (this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat == RBISDataValue.Blank &&
        //            (this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == '' || this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == RBISDataValue.Blank)) {

        //            let field: TypeaheadComponent;

        //            field = this.typeaheads.find(x => x.strFieldName == 'CrashTypeConf');
        //            if (field != null) {
        //                field.FilterFieldOptions();
        //                this._caseService.setRefreshPreCrash(false);
        //            }

        //            field = this.typeaheads.find(x => x.strFieldName == 'CrashTypeSS');
        //            if (field != null) {
        //                field.blnEvaluateConditionValue = false;
        //                field.FilterFieldOptions();
        //                this._caseService.setRefreshPreCrash(false);
        //            }
        //        }
        //    }
        //})
    }

    public async reloadCrashType(): Promise<void> {
        //if user click Clear form, user want to see whole crash list when click on crash type

            if (this.veh.PreCrash.PreCrash_MTSS.CrashTypeSS == RBISDataValue.Blank) {
                this.blnIsUpdateCategoryEmpty = false;

                //if (this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat != RBISDataValue.Blank) {

                //    let field: TypeaheadComponent;

                //    field = this.typeaheads.find(x => x.strFieldName == 'CrashTypeSS');
                //    if (field != null) {
                //        field.blnEvaluateConditionValue = false;
                //        field.FilterFieldOptions();
                //        this._caseService.setRefreshPreCrash(false);
                //    }
                //}
                if (this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat == RBISDataValue.Blank &&
                    (this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == '' ||
                        this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == RBISDataValue.Minus1)) {

                    let field: TypeaheadComponent;

                    if (this.typeaheads != undefined) { // Try to fixed bug , error when user do restructrue case.
                        field = this.typeaheads.find(x => x.strFieldName == 'CrashTypeConf');
                        if (field != null) {
                            field.FilterFieldOptions();
                            this._caseService.setRefreshPreCrash(false);
                        }

                        field = this.typeaheads.find(x => x.strFieldName == 'CrashTypeSS');
                        if (field != null) {
                            this.objTypeaheadCrashType.strFilterCondition = ' AND Configuration = '
                            field.blnEvaluateConditionValue = false;
                            field.FilterFieldOptions();
                            this._caseService.setRefreshPreCrash(false);
                        }
                    }
                }
            }
    }

    public setCrashConf(objModelSelection: DrpDownOptions): void {
        let field: TypeaheadComponent;
        if (this.typeaheads != undefined) { // Try to fixed bug , error when user do restructrue case.
            field = this.typeaheads.find(x => x.strFieldName == 'CrashTypeConf');
            if (field != null) {
                field.FilterFieldOptions();
                this._caseService.setRefreshPreCrash(false);
            }
        }
    }

    public setCrashTypeCatAndConf(objModelSelection: DrpDownOptions): void {
        if (this.blnIsUpdateCategoryEmpty) {
            objModelSelection = null
            this.veh.PreCrash.PreCrash_MTSS.CrashTypeSS == RBISDataValue.Blank
            this.objTypeaheadCrashType.setCurrentValue('')
        }

        if (objModelSelection &&
            this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat == RBISDataValue.Blank &&
            (this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == '' || this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == RBISDataValue.Minus1)) {
            if (objModelSelection.intValue != RBISDataValue.Blank) {

                let CrashTypeCatTypeahead = this.typeaheads.find(i => i.strFieldName == 'CrashTypeCat');
                if (CrashTypeCatTypeahead) {
                    this.objTypeaheadCrashTypeCat.setCurrentValue(objModelSelection.CrashTypeCat.toString());
                }

                let crashTypeConfTypeahead = this.typeaheads.find(i => i.strFieldName == 'CrashTypeConf');
                if (crashTypeConfTypeahead) {
                    this.objTypeaheadCrashTypeConf.setCurrentValue(objModelSelection.CrashTypeConf.toString());
                }

                ////let typeaheads: UIElementBase[] = this._autofillService.arrControls;
                //let crashTypeConfTypeahead1 = this.typeaheads.find(i => i.strFieldName == 'CrashTypeConf');
                //if (crashTypeConfTypeahead1) {
                //    ////crashTypeConfTypeahead1.strDependentFieldName = "CrashTypeCat";
                //    //crashTypeConfTypeahead1.strFilterCondition = " AND ID=" + objModelSelection.CrashTypeConf;
                //    //crashTypeConfTypeahead1.FilterFieldOptions();
                //}
            }
        }
    }

    public FilterCrashTypeConf(objNewModelSelection: DrpDownOptions): void {
        if (objNewModelSelection && this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat && this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat != RBISDataValue.Blank)
            this.objTypeaheadCrashTypeConf.strFilterCondition = ' AND CrashTypeConf.Category = ' + this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat.toString();
        else
            this.objTypeaheadCrashTypeConf.strFilterCondition = '';
        this.objTypeaheadCrashTypeConf.FilterFieldOptions();
    }

    handleKeyupCat(event: KeyboardEvent) {
        this.blnIsUpdateCategoryEmpty = false;
        if (this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat.toString() == '' ||
            this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == RBISDataValue.Minus1) {
            this.blnIsUpdateCategoryEmpty = true;
        }
    }

    handleKeyup(event: KeyboardEvent) {
        let strCrashTypeConf: string = (<any>(event.target)).value

        //if (event.code == KeyCode.Backspace || event.code == KeyCode.Delete) {
        if ((this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == '' ||
            this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == "" ||
            this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf == RBISDataValue.Minus1) &&
            strCrashTypeConf !== "") {
            //this.veh.PreCrash.PreCrash_MTSS.CrashTypeSS = RBISDataValue.Blank
            //this.objTypeaheadCrashType.strFilterCondition = ' AND CrashType.Configuration = '

            this.refreshCrashType();

            this.objTypeaheadCrashType.FilterFieldOptions();
        }
        else {
            if (this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat && this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat != RBISDataValue.Blank) {
                this.objTypeaheadCrashType.strFilterCondition = ' AND Configuration = ' + strCrashTypeConf ;
                this.objTypeaheadCrashType.blnEvaluateConditionValue = true;
            }
            else
                this.objTypeaheadCrashType.strFilterCondition = '';
            this.objTypeaheadCrashType.FilterFieldOptions();
        }
    }

    filterCrashType(objSelection: DrpDownOptions) {
        console.log(objSelection.strValue)

        if (objSelection && objSelection.strValue) {
            if (this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat && this.veh.PreCrash.PreCrash_MTSS.CrashTypeCat != RBISDataValue.Blank) {
                this.objTypeaheadCrashType.strFilterCondition = ' AND Configuration = ' + objSelection.strValue;
                this.objTypeaheadCrashType.blnEvaluateConditionValue = true;
            }
            else
                this.objTypeaheadCrashType.strFilterCondition = '';            
        }
        else {
            this.objTypeaheadCrashType.strFilterCondition = ' AND Configuration = '
            this.objTypeaheadCrashType.blnEvaluateConditionValue = true;
            this.objTypeaheadCrashType.FilterFieldOptions();
        }

        //work aorund the issue, on debug moode looks ok but on UI sometimes list generate some times not
        setTimeout(() => {
            this.objTypeaheadCrashType.FilterFieldOptions();
        }, 250);
        
    }


    //public FilterCrashType(objNewModelSelection: DrpDownOptions): void {
    //    if (objNewModelSelection && this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf && this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf != RBISDataValue.Blank)
    //        this.objTypeaheadCrashType.strFilterCondition = ' AND CrashType.Configuration = ' + this.veh.PreCrash.PreCrash_MTSS.CrashTypeConf.toString();
    //    else {
    //        this.objTypeaheadCrashType.strFilterCondition = '';
    //        this.refreshCrashType();
    //    }
    //    this.objTypeaheadCrashType.FilterFieldOptions();
    //}

    //private RefreshOtherControls() {
    //    for (let objTypeahead of this._autofillService.arrControls) {
    //        if (objTypeahead.strFieldName === 'CrashTypeSS') {
    //            if (objTypeahead.options) {
    //                let objSelection: DrpDownOptions = objTypeahead.options.find(x => x.intValue == objTypeahead.objModel[objTypeahead.strFieldName]);
    //                if (objSelection != null)
    //                    this.FilterCrashType(objSelection);
    //                //this.setObjectValue(objSelection, objTypeahead.strFieldName, objTypeahead.intSeqNum - 1);
    //            }
    //        }
    //    }
    //}

    refreshCrashType() {
        this._caseService.getRefreshPreCrash.subscribe(result => {
            if (result) {
                let field: TypeaheadComponent;
                field = this.typeaheads.find(x => x.strFieldName == 'CrashTypeSS');
                if (field != null) {
                    field.FilterFieldOptions();
                    this._caseService.setRefreshPreCrash(false);
                }
            }
        });
    }

    checkField() {
        console.log('event', this.typeaheads.find(c => c.strFieldName == 'CriticalEvent'));
        console.log('category', this.typeaheads.find(c => c.strFieldName == 'CriticalEventCat'));
    }

    setObjectValue(event) {
        if (event.intValue != -1) {
            let criticalEvent = this.typeaheads.find(c => c.strFieldName == 'CriticalEvent')
            if (criticalEvent) {
                criticalEvent.strDependentFieldName = "CriticalEventCat";
                criticalEvent.strFilterCondition = " AND Category=" + event.intValue;
                criticalEvent.FilterFieldOptions();
            }
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
