import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

//services
import { CaseService } from '../../services/case.service';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';
import { SharedDataService } from '../../services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { UtilService } from 'src/app/services/util.service';

//models
import { Acc } from 'src/app/models/acc';
import { EarlyNotify } from 'src/app/models/early-notify';

//components
import { BaseComponent } from 'src/app/helper/basecomponent';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { CaseLockedViewModel } from 'src/app/interface/CaseLockedViewModel';
import { DBMode, States, CaseStatus } from 'src/app/models/enums/app.enums';
import { CaseStats } from 'src/app/models/case-stats';

@Component({
    selector: 'app-check-case',
    templateUrl: './check-case.component.html',
    styleUrls: ['./check-case.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CheckCaseComponent extends BaseComponent implements OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_CheckCaseComponent: string = null;
    stateNum: number;
    accid: number;
    blnIsoverrideRule: boolean;

    edtLastProcessedDate: Date;
    edtLastRecvdDate: Date;
    blnIsEDTstate: boolean;
    blnIsEDTcase: boolean;

    currentTab: string;
    navigateToTab: string;

    strModeName: string;
    intYear: number;
    intMode: number;
    stateString: string;
    caseStatus: string;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private sharedDataService: SharedDataService,
        protected _caseService: CaseService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        let appSettings = await this.sharedDataService.GetAppSettings();
        if (DBMode[appSettings.intMode]) {
            this.strModeName = DBMode[appSettings.intMode];
        }
        

        this.intYear = appSettings.intYear;
        this.intMode = appSettings.intMode;

        this._route.params.subscribe(params => {
            this.accid = + params['caseid'];
            this.stateNum = + params['stateNum'];
            this.stateString = States[this.stateNum];
            this.caseStatus = CaseStatus[this.acc.EarlyNotify[0].Status];

            this._utilService.GetDrpDownListOptions("StateNum", '').subscribe(result => {
                if (result) {
                    let arrStates: Array<number> = [];
                    //result = result.filter(i => i.tblName == LookupTable.StateNum && i.intValue != 98 && i.intValue != 99);
                    if (result) {
                        result.forEach(item => {
                            arrStates.push(item.intValue);
                        });
                        this.sharedDataService.setListofValidStates(arrStates);
                    }
                    let ddoSelectedState = result.find(x => x.intValue === this.stateNum);
                    if (ddoSelectedState) {
                        this.sharedDataService.setDDOSelectedState(ddoSelectedState);
                        this.sharedDataService.subjectSelectedState.next(ddoSelectedState);
                    }
                }
            });

            this._route.firstChild.params.subscribe(item => { //Checking if URL has RuleID param so we can show or hide btns
                this.blnIsoverrideRule = item['RuleID'] ? true : false;
            });

            this.blnIsEDTstate = this.sharedDataService.getIsEDTState();
            this.blnIsEDTcase = this.sharedDataService.getIsEDTCase();

            this.edtLastProcessedDate = this.sharedDataService.getEDTLastProcessedDate();
            this.edtLastRecvdDate = this.sharedDataService.getEDTLastRecvdDate();
        });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    tabNavigation(event: KeyboardEvent) {

        var pieces = event.view.location.pathname.split("/").filter(s => s != "");
        this.currentTab = pieces[pieces.length - 1];

        if (event.key === 'PageUp' || event.key === 'PageDown') {
            event.preventDefault();
            switch (this.currentTab) {
                case "violatedRules": {
                    if (event.key === 'PageDown') {
                        this.navigateNextCheckCaseTab("blanks");
                    }
                    break;
                }
                case "blanks": {
                    if (event.key === 'PageDown') {
                        this.navigateNextCheckCaseTab("overriddenRules");
                    }
                    if (event.key === 'PageUp') {
                        this.navigateNextCheckCaseTab("violatedRules");
                    }
                    break;
                }
                case "overriddenRules": {
                    if (event.key === 'PageUp') {
                        this.navigateNextCheckCaseTab("violatedRules");
                    }
                    break;
                }
            }
        }
    }

    navigateNextCheckCaseTab(navigateToTab) {
        this._router.navigate([this.stateNum, 'checkCase', this.accid, navigateToTab]);
    }

    async onBackToCase(event, accid) {
        //It removes the red highlight on the ui control and removes the warning message on top of the page for violated rule.
        setTimeout(() => {
            super.clearViolatedElement();
        }, 1000)

        let pmsCaseStats: CaseStats = await this._caseService.GetAccCaseStats(this.accid);
        if (pmsCaseStats) { //Refresh client model for acc.CaseStats when we want to go back to Case tabs;
            this.acc.CaseStats = pmsCaseStats;
        }

        if (this.blnIsoverrideRule) {
            this._sharedService.subjectBackToCase.next(undefined);
        }
        else {
            let lastUrl = this.sharedDataService.getLastCaseUrl();
            if (lastUrl) {
                let intIndex: number = lastUrl.indexOf('#');
                if (intIndex > 0)
                    lastUrl = lastUrl.substring(0, intIndex);

                this._router.navigateByUrl(lastUrl);
            }
            else {
                this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid, 'crash']);
            }
        }
    }

    OnCloseCase() {
        //It removes the red highlight on the ui control and removes the warning message on top of the page for violated rule.
        super.clearViolatedElement();
        let lockedCase: Array<CaseLockedViewModel> = [];
        let releaseCase = {} as CaseLockedViewModel
        releaseCase.AccID = this.acc.AccID;
        lockedCase.push(releaseCase);
        this._caseService.ReleaseLockedCases(lockedCase).then(result => {
            if (result.IsValid) {
                this._caseService.hideNavForCloseCase.next(true);
                this.acc.Cases_Locked = null;
                //this._router.navigateByUrl(this.stateNum + '/case/' + releaseCase.AccID + '/closed');
                this._router.navigate([this.stateNum, 'case', releaseCase.AccID, 'closed'])
            } else {
                this._modalService.setMessage(result.Message, 'warning')
            }
        });
    }

    onGoToTheCase() {
        if (this.acc) {
            this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid]);
        }
    }

    onGoToTheEN() {
        if (this.acc) {
            this._router.navigate(['earlyNotification', this.stateNum, 'newFatalCase', this.acc.EarlyNotify[0].ENID]);
        }
    }

    onBackToCheckCase(event): void {
        this._sharedService.subjectBackToCheckCase.next(1);
    }

    saveOverrideReason(): void {
        this._sharedService.subjectSaveOverrideReason.next(1);
    }
}



