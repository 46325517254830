<form novalidate #crashEvent="ngForm">
    <div class="container-fluid p-0 m-0" *ngIf="acc && intMode">
        <div class=" col-12 align-top p-0 m-0">
            <div class="input-group pt-2 pb-2 pl-n1" *ngIf="!printOnly">
                <div class="my-auto">
                    <span class="pr-1">Add</span>
                </div>
                <span class="w62">
                    <input type="number" name="addResult" [(ngModel)]="intRowsToAdd" [ngModelOptions]="{standalone: true}" [disabled]="blnReadOnly"
                           class="form-control form-control-sm border-primary" #addResults (keydown.enter)="onAddNewRows($event)">
                </span>
                <div class="my-auto">
                    <span class="px-1">Row(s)</span>
                </div>
                <div class="">
                    <button class="btn btn-sm btn-outline-primary" type="button" (click)="onAddNewRows()" [disabled]="blnReadOnly">Go</button>
                </div>

                <div class="ml-2" *ngIf="intMode == 64 || intMode == 128">
                    <button name="previewform" class="btn btn-primary btn-sm btn-block" (click)="onPreviewData(longContent)" [disabled]="blnReadOnly">
                        <i class="far fa-file-alt"></i> Save & Preview
                    </button>
                </div>
            </div>

            <div *ngIf="intMode == 64 || intMode == 128" class="row pt-4 pb-2">
                <div class="col-md-4 ml-2">
                    <app-label inputName="FHEvent" [printOnly]="printOnly"></app-label>
                </div>

                <div #typAhdFHEvent class="col-md-7 align-top">
                    <app-typeahead id="FHEvent" name="FHEvent" disableLabel="true" typeAheadPosition="right-top" disabled="true"
                                   #FHEvent [objModel]="acc" strTableName="Acc" strFieldName="FHEvent" [intSeqNum]="-1" [printOnly]="printOnly"
                                   [showTextboxInSamePositionForPrintOnly]="true"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">

                    </app-typeahead>
                </div>
            </div>
            <div *ngIf="intMode == 64 || intMode == 128" class="row pb-4">
                <div class="col-md-4 ml-2">
                    <app-label inputName="MannCol" [printOnly]="printOnly"></app-label>
                </div>
                <div #typAhdMannCol class="col-md-7 align-top">
                    <app-typeahead id="MannCol" name="MannCol" disableLabel="true" typeAheadPosition="right-top"
                                   #MannCol [objModel]="acc" strTableName="Acc" strFieldName="MannCol" [intSeqNum]="-1"
                                   (bindModel)="setObjectValue($event,'MannCol',i)" [printOnly]="printOnly"
                                   [showTextboxInSamePositionForPrintOnly]="true"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData"></app-typeahead>
                </div>
            </div>

            <div *ngIf="intMode==128" class="card border border-secondary bg-light m-2">
                <div class="card-body border border-secondary bg-light p-1 margin-border">
                    <div class="card-title fw-bold bg-light">Relation To Junction</div>

                    <div #typAhdRelJuncInter [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                        <div class="form-group pl-2">
                            <app-typeahead id="relJuncInter" name="relJuncInter"
                                           [objModel]="acc" strTableName="Acc" strFieldName="RelJuncInter" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                            </app-typeahead>
                        </div>
                    </div>

                    <div #typAhddrpJunc [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                        <div class="form-group pl-2">
                            <app-typeahead id="drpJunc" name="drpJunc"
                                           [objModel]="acc" strTableName="Acc" strFieldName="RelJunc" [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                            </app-typeahead>
                        </div>
                    </div>

                </div>
            </div>
            <div class="table" *ngIf="intMode == 256">
                <table class="table table-sm">
                    <thead>
                        <tr class="d-flex">
                            <!--<th class="col-1" scope="col">Event<br />(#)</th>-->
                            <th class="col-1" scope="col">Veh No.<br />(#)</th>
                            <!--<th class="col-2" scope="col">Class of Vehicle</th>-->
                            <th class="col-2" scope="col">
                                <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo(areaOfDamageStrikingVeh, 'Area of Damage (Striking Veh)')" style="cursor:pointer;"></i>&nbsp;
                                Area of Damage<br />(Striking Veh)
                            </th>
                            <th class="col-2" scope="col">
                                <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo(objectContactedClassList, 'Object Contacted Class')" style="cursor:pointer;"></i>&nbsp;
                                Object Contacted Class
                            </th>
                            <th class="col-2" scope="col">
                                <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo(objContactGroupEventList, 'Object Contacted')" style="cursor:pointer;"></i>&nbsp;
                                Object<br />Contacted
                            </th>
                            <th class="col-1" scope="col">Veh No.<br />(Contacted)</th>
                            <th class="col-2" scope="col">
                                <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo(areaOfDamageStrikingVeh, 'Area of Damage (Contacted Veh)')" style="cursor:pointer;"></i>&nbsp;
                                Area of Damage<br />(Contacted Veh)
                            </th>
                            <th class="col-sm-4" scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="d-flex" *ngFor="let item of acc.CrashEvents; let i = index">
                            <!--<th class="col-1" scope="row">
                        <div>
                            <span>
                                {{item.EventNum}}
                            </span>
                        </div>
                    </th>-->
                            <td class="col-1">
                                <div *ngIf="i == 0">
                                    <app-typeahead [id]="'VNumber1_' + i" disableLabel="true" typeAheadPosition="right-top" [intSeqNum]="item.EventNum"
                                                   [objModel]="item" strTableName="CrashEvents" strFieldName="VNumber1"
                                                   strDependentFieldName="AccID"
                                                   [strFilterCondition]="' AND v.ID BETWEEN 1 AND ' + totalStrikingVehicleCount + ' AND v.VehType = ' + StrikingVehicle "
                                                   [blnHideSelectedOptionText]="true"
                                                   (bindModel)="setObjectValue($event,'VNumber1', i)" [printOnly]="printOnly"
                                                   [showTextboxInSamePositionForPrintOnly]="true"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [showDescriptionForPrintOnly]="true"
                                                   [clearFieldData]="clearFieldData"
                                                   (emitObjectOnChange)="onEventModelChange($event,'VNumber1', i)">
                                    </app-typeahead>
                                    <div class="table-condensed-font">
                                        {{item.VehTypeDes1}}
                                    </div>
                                </div>
                                <div *ngIf="i > 0">
                                    <app-typeahead [id]="'VNumber1_' + i" disableLabel="true" typeAheadPosition="right-top" [intSeqNum]="item.EventNum"
                                                   [objModel]="item" strTableName="CrashEvents" strFieldName="VNumber1" strDependentFieldName="AccID"
                                                   [strFilterCondition]="' AND ((v.ID BETWEEN 1 AND ' + totalStrikingVehicleCount + ' AND v.VEHTYPE = '+ StrikingVehicle +') OR (v.ID BETWEEN 1 AND ' + totalSFRVehicleCount + ' AND v.VEHTYPE = '+ SFRVehicle +') OR (v.ID BETWEEN 1 AND ' + totalOtherVehicleCount + ' AND v.VEHTYPE = '+ OtherVehicle +'))'"
                                                   [blnHideSelectedOptionText]="true"
                                                   (bindModel)="setObjectValue($event,'VNumber1', i)" [printOnly]="printOnly"
                                                   [showTextboxInSamePositionForPrintOnly]="true" [showAllDataListForPrintOnly]="false"
                                                   [showDescriptionForPrintOnly]="true"
                                                   [clearFieldData]="clearFieldData"
                                                   (emitObjectOnChange)="onEventModelChange($event,'VNumber1', i)">
                                    </app-typeahead>
                                    <div class="table-condensed-font">
                                        {{item.VehTypeDes1}}
                                    </div>
                                </div>
                            </td>
                            <!--<td class="col-2">
                        <div>
                            <span>
                                {{item.VehicleClass1}}
                            </span>
                        </div>
                    </td>-->
                            <td class="col-2">
                                <div>
                                    <app-typeahead [id]="'AOD1_' + i"
                                                   typeAheadPosition="right-top"
                                                   [objModel]="item"
                                                   strTableName="CrashEvents"
                                                   strFieldName="AOD1"
                                                   [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData"
                                                   strDependentFieldName="AccID"
                                                   strFilterCondition=" AND DAMAGEGRPID <=2 "
                                                   (bindModel)="setObjectValue($event,'AOD1',i)"
                                                   [blnHideSelectedOptionText]="false"
                                                   [showTextboxInSamePositionForPrintOnly]="true"
                                                   [showDescriptionForPrintOnly]="true"
                                                   [intSeqNum]="item.EventNum"
                                                   disableLabel="true">
                                    </app-typeahead>
                                </div>
                            </td>
                            <td class="col-2">
                                <div>
                                    <app-typeahead #txtCrashEventOCC [id]="'OCC_' + i"
                                                   typeAheadPosition="right-top"
                                                   [objModel]="item"
                                                   strTableName="CrashEvents"
                                                   strFieldName="OCC"
                                                   [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData"
                                                   searchAll="true"
                                                   strDefsLookupTableName="SS.ObjContactGroup"
                                                   strRefreshedLookupTableName="SS.ObjContactGroupEvent"
                                                   (bindModel)="setObjectValue($event,'OCC',i)"
                                                   [showTextboxInSamePositionForPrintOnly]="true"
                                                   [showDescriptionForPrintOnly]="true"
                                                   (emitObjectOnChange)="onEventModelChange($event,'OCC', i)"
                                                   [intSeqNum]="item.EventNum"
                                                   disableLabel="true"
                                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                                    </app-typeahead>
                                </div>
                                <!--<div>
                            <span>
                                {{item.ObjectContactedClass}}
                            </span>
                        </div>-->
                            </td>
                            <td class="col-2">
                                <div>
                                    <app-typeahead #txtCrashEventOC [id]="'SOE_' + i"
                                                   typeAheadPosition="right-top"
                                                   [objModel]="item"
                                                   strTableName="CrashEvents"
                                                   strFieldName="SOE"
                                                   [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData"
                                                   searchAll="true"
                                                   strDefsLookupTableName="SS.ObjContactGroupEvent"
                                                   strFilterCondition=" AND ISOBJCONTGRPID= "
                                                   strDependentFieldName="OCC"
                                                   (bindModel)="setObjectValue($event,'SOE',i)"
                                                   [showTextboxInSamePositionForPrintOnly]="true"
                                                   [showDescriptionForPrintOnly]="true"
                                                   (emitObjectOnChange)="onEventModelChange($event,'SOE', i)"
                                                   [intSeqNum]="item.EventNum"
                                                   disableLabel="true"
                                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                                    </app-typeahead>
                                </div>
                            </td>
                            <td class="col-1">
                                <div>
                                    <app-typeahead #txtVNumber2 [id]="'VNumber2_' + i" disableLabel="true" typeAheadPosition="right-top" [intSeqNum]="item.EventNum"
                                                   [objModel]="item" strTableName="CrashEvents" strFieldName="VNumber2"
                                                   strDependentFieldName="AccID"
                                                   [strFilterCondition]="' AND ((v.ID BETWEEN 1 AND ' + totalStrikingVehicleCount + ' AND v.VEHTYPE = '+ StrikingVehicle +') OR (v.ID BETWEEN 1 AND ' + totalSFRVehicleCount + ' AND v.VEHTYPE = '+ SFRVehicle +') OR (v.ID BETWEEN 1 AND ' + totalOtherVehicleCount + ' AND v.VEHTYPE = '+ OtherVehicle +'))'"
                                                   [blnHideSelectedOptionText]="true"
                                                   (bindModel)="setObjectValue($event,'VNumber2', i)" [printOnly]="printOnly"
                                                   [showTextboxInSamePositionForPrintOnly]="true" [showAllDataListForPrintOnly]="false"
                                                   [showDescriptionForPrintOnly]="true"
                                                   [clearFieldData]="clearFieldData"
                                                   (emitObjectOnChange)="onEventModelChange($event,'VNumber2', i)">
                                    </app-typeahead>
                                    <div class="table-condensed-font">
                                        {{item.VehTypeDes2}}
                                    </div>
                                </div>
                                <!--<div>
                            <span>
                                {{item.VehicleClass2}}
                            </span>
                        </div>-->
                            </td>
                            <td class="col-2">
                                <div>
                                    <app-typeahead [id]="'AOD2_' + i"
                                                   typeAheadPosition="right-top"
                                                   [objModel]="item"
                                                   strTableName="CrashEvents"
                                                   strFieldName="AOD2"
                                                   [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData"
                                                   strDependentFieldName="AccID"
                                                   strFilterCondition=" AND DAMAGEGRPID <=2 "
                                                   (bindModel)="setObjectValue($event,'AOD2', i)"
                                                   [showTextboxInSamePositionForPrintOnly]="true"
                                                   [showDescriptionForPrintOnly]="true"
                                                   [intSeqNum]="item.EventNum"
                                                   disableLabel="true">
                                    </app-typeahead>
                                </div>
                            </td>
                            <td class="col-2">
                                <button color="warn" (click)="addCrashItem(i+1, true)" title="Add a line" [disabled]="acc.Deleted || blnBtnReadOnly">
                                    <i class="fas fa-plus fa-xs" title="Add a line"></i>
                                </button>
                                <button type="button" color="warn" (click)="deleteCrashRow(i)" title="Delete current line" [disabled]="acc.Deleted || blnBtnReadOnly">
                                    <i class="fas fa-minus fa-xs" title="Delete current line"></i>
                                </button>
                                <button color="warn" (click)="arrowupCrashRow(i)" title="Move line up" [disabled]="acc.Deleted || blnBtnReadOnly">
                                    <i class="fas fa-arrow-up fa-xs" title="Move line up"></i>
                                </button>
                                <button color="warn" (click)="arrowdownCrashRow(i)" title="Move line down" [disabled]="acc.Deleted || blnBtnReadOnly">
                                    <i class="fas fa-arrow-down fa-xs" title="Move line down"></i>
                                </button>
                                <button color="warn" (click)="clearCrashRow(i,item)" title="Clear the line" [disabled]="acc.Deleted || blnBtnReadOnly">
                                    <i class="far fa-trash-alt" title="Clear the line"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card  bg-light border-right-0 border-left-0" *ngIf="intMode == 64 || intMode == 128">
                <div class="d-flex flex-row">
                    <!-- Veh 1 -->
                    <div class="{{printOnly ? 'col-2 print-font-size' : 'col-1'}}">
                        Vehicle No.
                        <br />
                        (This)
                    </div>
                    <!-- AOI 1 / Damage Area-->
                    <div class="col-2 {{printOnly ? 'print-font-size' : ''}}">
                        Area of Impact (AOI)
                        <br />
                        (This)
                    </div>
                    <!-- SOE / Object Contacted -->
                    <div class="col-4 {{printOnly ? 'print-font-size' : ''}}">
                        Sequence of Events (SOE)
                    </div>
                    <!-- Veh 2 / Contacted Class of Vehicle -->
                    <div class="{{printOnly ? 'col-2 print-font-size' : 'col-1'}}">
                        Vehicle No.
                        <br />
                        (Other)
                    </div>
                    <!-- AOI 2 / Contacted Damage Area-->
                    <div class="col-2 {{printOnly ? 'print-font-size' : ''}}">
                        Areas of Impact (AOI)
                        <br /> (Other)
                    </div>
                    <div class="col-md-2" *ngIf="!printOnly">
                        Action
                    </div>
                </div>
            </div>
            <!--
        NOTICE: The Angular DOM and the HTML DOM are not the same thing: to bind an expression to an Angular DOM element attribute, square brackets must be used.
        If square brackets are not used, the attribute binding becomes not an evaluated expression, but a passthrough constant string assignment to the HTML DOM
        attribute, resulting in repeated element "id" values (which will confuse the browser's HTML DOM) and repeated "name" values
        (which will complicate Angular's ngForm)

        NOTE: intSeqNum is not needed for metadata lookup, but is used in this component's logic to distinguish between data model rows when manipulating typeahead instances.
    -->
            <div *ngIf="intMode == 64 || intMode == 128">
                <ul class="list-group" *ngFor="let item of acc.CrashEvents; let i = index">
                    <li class="list-group-item pl-0 pr-0">
                        <div class="d-flex flex-row">
                            <!-- Veh 1 -->
                            <div class="{{printOnly ? 'col-2 align-top' : 'col-1 align-top'}} ">
                                <!-- Using a dummy value for strDependentFieldName to force filter condition to be applied immediately -->
                                <app-typeahead [id]="'VNumber1_' + i" disableLabel="true" typeAheadPosition="right-top" [intSeqNum]="item.EventNum"
                                               [objModel]="item" strTableName="CrashEvents" strFieldName="VNumber1" strDependentFieldName="AccID"
                                               [strFilterCondition]="'AND ID BETWEEN 1 AND ' + acc.Veh.length" [blnHideSelectedOptionText]="true"
                                               (bindModel)="setObjectValue($event,'VNumber1', i)" [printOnly]="printOnly"
                                               [showTextboxInSamePositionForPrintOnly]="true" [showAllDataListForPrintOnly]="false"
                                               [showDescriptionForPrintOnly]="true"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <!-- AOI 1 / Damage Area-->
                            <div class="col-2 align-top ">
                                <app-typeahead [id]="'AOI1_' + i" disableLabel="true" typeAheadPosition="right-top"
                                               [objModel]="item" strTableName="CrashEvents" strFieldName="AOI1" [intSeqNum]="item.EventNum"
                                               (bindModel)="setObjectValue($event,'AOI1',i)" [printOnly]="printOnly"
                                               [showTextboxInSamePositionForPrintOnly]="true"
                                               [showAllDataListForPrintOnly]="false"
                                               [showDescriptionForPrintOnly]="true"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <!-- SOE / Object Contacted-->
                            <div class="col-4 align-top">
                                <app-typeahead [id]="'SOE_' + i" disableLabel="true" typeAheadPosition="right-top"
                                               [objModel]="item" strTableName="CrashEvents" strFieldName="SOE" [intSeqNum]="item.EventNum"
                                               (bindModel)="setObjectValue($event,'SOE',i)" [printOnly]="printOnly"
                                               [showTextboxInSamePositionForPrintOnly]="true" [showAllDataListForPrintOnly]="false"
                                               [showDescriptionForPrintOnly]="true"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <!-- Veh 2 / Contacted Class of Vehicle-->
                            <div class="{{printOnly ? 'col-2 align-top' : 'col-1 align-top'}} ">
                                <app-typeahead [id]="'VNumber2_' + i" disableLabel="true" typeAheadPosition="right-top" [intSeqNum]="item.EventNum"
                                               [objModel]="item" strTableName="CrashEvents" strFieldName="VNumber2" strDependentFieldName="AccID"
                                               [strFilterCondition]="'AND ID BETWEEN 1 AND ' + acc.Veh.length" [blnHideSelectedOptionText]="true"
                                               (bindModel)="setObjectValue($event,'VNumber2', i)" [printOnly]="printOnly"
                                               [showTextboxInSamePositionForPrintOnly]="true" [showAllDataListForPrintOnly]="false"
                                               [showDescriptionForPrintOnly]="true"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <!-- AOI 2-->
                            <div class="col-2 align-top">
                                <app-typeahead [id]="'AOI2_' + i" disableLabel="true" typeAheadPosition="right-top"
                                               (bindModel)="setObjectValue($event,'AOI2', i)"
                                               [objModel]="item" strTableName="CrashEvents" strFieldName="AOI2" [intSeqNum]="item.EventNum"
                                               [printOnly]="printOnly" [showTextboxInSamePositionForPrintOnly]="true" [showAllDataListForPrintOnly]="false"
                                               [showDescriptionForPrintOnly]="true" [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <div class="col-md-2 pt-3" *ngIf="!printOnly">
                                <span class="">
                                    <button color="warn" (click)="addCrashItem(i+1, true)" title="Add a line" [disabled]="acc.Deleted|| blnBtnReadOnly">
                                        <i class="fas fa-plus fa-xs" title="Add a line"></i>
                                    </button>
                                </span>
                                <span class="">
                                    <button type="button" color="warn" (click)="deleteCrashRow(i)" title="Delete current line" [disabled]="acc.Deleted|| blnBtnReadOnly">
                                        <i class="fas fa-minus fa-xs" title="Delete current line"></i>
                                    </button>
                                </span>

                                <span class="">
                                    <button color="warn" (click)="arrowupCrashRow(i)" title="Move line up" [disabled]="acc.Deleted|| blnBtnReadOnly">
                                        <i class="fas fa-arrow-up fa-xs" title="Move line up"></i>
                                    </button>
                                </span>
                                <span class="">
                                    <button color="warn" (click)="arrowdownCrashRow(i)" title="Move line down" [disabled]="acc.Deleted || blnBtnReadOnly">
                                        <i class="fas fa-arrow-down fa-xs" title="Move line down"></i>
                                    </button>
                                </span>
                                <span class="">
                                    <button color="warn" (click)="clearCrashRow(i,item)" title="Clear the line" [disabled]="acc.Deleted || blnBtnReadOnly">
                                        <i class="far fa-trash-alt" title="Clear the line"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="justify-content-center">
                <input disabled name='lblmessage' type="text" style="color:red; text-align:center;" class="form-control" [(ngModel)]="lblmessage">
            </div>

        </div>
            <div  *ngIf="!printOnly && (intMode == 64 || intMode == 128)" class="d-flex flex-row justify-content-center">
                <img style="width:50%;" src="../../../../../assets/images/crashevents/AOIClockPoints.jpg" />
            </div>
        </div>
</form>

<ng-template #longContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Crash Events Preview</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card border-dark p-3 m-3">
            <div class="row">
                <span *ngIf="intMode == 64 || intMode == 128" class="col-12 fw-bold">Details for Case #{{acc.Casenum}}</span>
                <span *ngIf="intMode == 256" class="col-12 fw-bold">Details for Case #{{acc.Acc_SS.MOSSCasenum}}</span>
            </div>

            <div *ngIf="intMode == 64 || intMode == 128" class="row">
                <app-label class="col-10" inputName="FHEvent"></app-label>
                <div class="col-2 text-right" *ngIf="acc.FHEvent != -1">{{acc.FHEvent}}</div>
            </div>
        </div>

        <div class="card border-dark p-3 m-3" *ngFor="let objVeh of acc.Veh ; let i = index">
            <div class="row">
                <span class="col-12 fw-bold">Details for Vehicle #{{objVeh.VNumber}}</span>
            </div>

            <div class="row">
                <app-label class="col-10" inputName="UnitType"></app-label>
                <div class="col-2 text-right" *ngIf="objVeh.UnitType != -1">{{objVeh.UnitType}}</div>
            </div>

            <div *ngIf="intMode == 64 || intMode == 128" class="row grayBGC">
                <app-label class="col-10" inputName="InImpact"></app-label>
                <div class="col-2 text-right" *ngIf="objVeh.InImpact != -1">{{objVeh.InImpact}}</div>
            </div>

            <div class="row">
                <app-label class="col-10" inputName="SOE"></app-label>
                <div class="col-2 text-right">
                    <span *ngFor="let objCrashEvent of GetCrashEventsInvolvingVehicle(objVeh.VNumber) ; let j = index">
                        <span *ngIf="j > 0">, </span>{{objCrashEvent.SOE}}
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="intMode == 64 || intMode == 128" class="col-12">*The values shown for the "Sequence of Events" are applicable only when the vehicle's Unit Type is coded as 01</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
