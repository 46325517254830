<div *ngIf="blnVisible">
    <div class="col">
        <div class="input-group">
            <div class="px-1 input-group-append">
                <input type="text" id="searchMossCase" class="form-control border-secondary excludeInput w100" style="max-width:120px;" maxlength="8"
                       [ngModel]="strMossCaseNumber | replaceTextPipe : ' ' : '' | maxLengthPipe : 12 : ''" (ngModelChange)="OnModelChange($event)"
                       (keydown)="handleKeydown($event)"
                       (keyup.enter)="onSearchMossNum()"
                       (paste)="onPaste($event)"
                       #searchCase *ngIf="selectedMode == 256" />

                <input type="text" id="SearcBox" class="form-control border-secondary excludeInput w74 px-3" style="max-width:50px;" maxlength="4"
                       [ngModel]="intCaseNumber | number: '4.0-0' | replaceTextPipe : ',' : '' | maxLengthPipe : 4 : ''" (ngModelChange)="OnModelChange($event)"
                       (keydown)="handleKeydown($event)"
                       (keyup.enter)="onSearch()"
                       (paste)="onPaste($event)"
                       #searchCase *ngIf="selectedMode == 64 || selectedMode == 128" />
            </div>
            <div class="input-group-append">
                <button *ngIf="blnSearchBoxEnable && selectedMode != 256" id="SearchBoxGo" class="btn btn-light border-secondary ms-1" type="button" (click)="onSearch()"><span class="text-dark">Go</span></button>
                <button *ngIf="blnSearchBoxEnable && selectedMode == 256" id="SearchBoxGo" class="btn btn-light border-secondary ms-1" type="button" (click)="onSearchMossNum()"><span class="text-dark">Go</span></button>
                <button *ngIf="blnSearchBoxEnable" class="btn btn-light border-secondary ms-1" type="button" (click)="onGetIncreased()"><i class="fas fa-arrow-up text-dark"></i></button>
                <button *ngIf="blnSearchBoxEnable" class="btn btn-light border-secondary ms-1" type="button" (click)="onGetDecreased()"><i class="fas fa-arrow-down text-dark"></i></button>
            </div>
        </div>
    </div>
</div>
