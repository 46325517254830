<form novalidate #fvvview="ngForm">
    <div *ngIf="veh" id="vehicle-view" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="ExteriorMirrorLocation" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="ExteriorMirrorLocation"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="FieldofViewRBSR"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="FieldofViewRBSR"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="TruckSightLineOVC"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="TruckSightLineOVC"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="TruckViewOVO"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="TruckViewOVO"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="CabPassComparSeparate"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="CabPassComparSeparate"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="CabPassCompIntrusion"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="CabPassCompIntrusion"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RetroflectiveTapePUCB"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="RetroflectiveTapePUCB"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RetroflectiveTapeTrailer"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="RetroflectiveTapeTrailer"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RearUnderrideGuardPUCB"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="RearUnderrideGuardPUCB"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RearUnderrideGuardTrailer"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="RearUnderrideGuardTrailer"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="SideUnderrideGuardPUCB"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="SideUnderrideGuardPUCB"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="SideUnderrideGuardTrailer"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="SideUnderrideGuardTrailer"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="TruckInspectionConducted"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="TruckInspectionConducted"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="BrakeInspConducted"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="BrakeInspConducted"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>           

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="VehicleCF"
                                     strTableName="VCF_MTSS"
                                     strFieldName="VCF"                                    
                                     multiSelectPosition="right-top"                                     
                                     [objTemplateModel]="objVCFTemplate"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [objModel]="veh.VCF_MTSS" [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PresenceOfESC"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="PresenceOfESC"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PresenceOfRSC"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="PresenceOfRSC"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

        </div>
    </div>
</form>
