<form novalidate #fvpsafetyd="ngForm">
    <div class="pt-2" *ngIf="person && intMode">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">               

                <div [ngClass]="{'card border border-secondary bg-light m-2' : !printOnly }">
                    <div [ngClass]="{'card-body border border-secondary bg-light p-1 margin-border' : !printOnly }">
                        <div [ngClass]="[!printOnly ? 'card-title fw-bold bg-light' : 'text-center']"> <span [ngClass]="{'h6' : printOnly }">Restraint System Use </span></div>

                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="restraint" name="restraint" typeAheadPosition="right-top"
                                               [objModel]="person" strTableName="Occupants" strFieldName="Restraint" [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>

                        <!--<div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="restraintMisuse" name="restraintMisuse" typeAheadPosition="right-top"
                                               [objModel]="person" strTableName="Occupants" strFieldName="RestraintMisuse" [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>-->

                    </div>
                </div>
            </div>
           
            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div [ngClass]="{'card border border-secondary bg-light m-2' : !printOnly }">
                    <div [ngClass]="{'card-body border border-secondary bg-light p-1 margin-border' : !printOnly }">
                        <div [ngClass]="[!printOnly ? 'card-title fw-bold bg-light' : 'text-center']"> <span [ngClass]="{'h6' : printOnly }">Helmet Use</span></div>
                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="helmet" name="helmet" typeAheadPosition="right-top"
                                               [objModel]="person" strTableName="Occupants" strFieldName="Helmet" [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="helmetMisUse" name="helmetMisUse" typeAheadPosition="right-top"
                                               [objModel]="person" strTableName="Occupants" strFieldName="HelmetMisUse" [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>

                    </div>
                </div>
            </div>-->

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2 mt-2">
                    <app-typeahead id="airBag" name="airBag" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="AirBag" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="ejection" name="ejection" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="Ejection" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="ejectPath" name="ejectPath" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="EjectPath" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="extricat" name="extricat" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="Extricat"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>
