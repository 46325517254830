
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


//import { CrashComponent } from './crash.component';
//import { CrashCrashComponent } from './crash-crash/crash-crash.component'
//import { CrasheventsComponent } from './crash-events/crash-events.component';
//import { CrashRoadwayComponent } from './crash-roadway/crash-roadway.component';
//import { CrashOtherComponent } from './crash-other/crash-other.component';
//import { CrashSpecialComponent } from './crash-special/crash-special.component';

const crashRoutes: Routes = [
    //{ path: 'crash/:crashid', component: CrashComponent},
    //{ path: 'crash/:crashid/crashCrashTab', component: CrashComponent, data:{name:'crashCrashTab'}},
    //{ path: 'crash/:crashid/crashEventTab', component: CrashComponent, data:{name:'crashEventTab'}},
    //{ path: 'crash/:crashid/crashRoadwayTab', component: CrashComponent, data: { name:'crashRoadwayTab'}},
    //{ path: 'crash/:crashid/crashOtherTab', component: CrashComponent, data: { name:'crashOtherTab'}},
    //{ path: 'crash/:crashid/crashSpecialTab', component: CrashComponent, data: { name:'crashSpecialTab'}}
];


@NgModule({
    imports: [RouterModule.forChild(crashRoutes)
    ],

    exports: [RouterModule],
  
})
export class CrashRoutingModule { }
