<form novalidate #fsfrvv="ngForm">
    <div id="sfrvehicle-vehiclecontrol" class="pt-2">
       
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="SuplTrfcCtrl"
                                     multiSelectPosition="right-top"
                                     strTableName="SuplTrfcCtrl_SS"
                                     strFieldName="SuplTrfcCtrl"
                                     [objModel]="veh.SuplTrfcCtrl_SS"
                                     [objTemplateModel]="objSuplTrfcCtrlTemplate"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div [ngClass]="{'row' : printOnly === true}">
                <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                    <div class="form-group pl-2">
                        <app-typeahead id="LocSuplWarnDev" name="LocSuplWarnDev" typeAheadPosition="right-top"
                                       [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="LocSuplWarnDev"
                                       [printOnly]="printOnly"
                                       [clearFieldData]="clearFieldData">
                        </app-typeahead>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'row' : printOnly === true}">
                <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                    <div class="form-group pl-2">
                        <app-typeahead id="FrstResDirSgnlinUse" name="FrstResDirSgnlinUse" typeAheadPosition="right-top"
                                       [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="FrstResDirSgnlinUse"
                                       [printOnly]="printOnly"
                                       [clearFieldData]="clearFieldData">
                        </app-typeahead>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'row' : printOnly === true}">
                <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                    <div class="form-group pl-2">
                        <app-typeahead id="RetroflectiveTapePUCB" name="RetroflectiveTapePUCB" typeAheadPosition="right-top"
                                       [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="RetroflectiveTapePUCB"
                                       [printOnly]="printOnly"
                                       [clearFieldData]="clearFieldData">
                        </app-typeahead>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'row' : printOnly === true}">
                <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                    <div class="form-group pl-2">
                        <app-typeahead id="SecColFirstHrmEvt" name="SecColFirstHrmEvt" typeAheadPosition="right-top"
                                       [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="SecColFirstHrmEvt"
                                       [printOnly]="printOnly"
                                       [clearFieldData]="clearFieldData">
                        </app-typeahead>
                    </div>
                </div>
            </div>


            <!--<div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
        <div [ngClass]="{'border-top border-dark' : printOnly !== true}">-->

            <div [ngClass]="{'col-12 border border-dark mt-2' : printOnly === true}">
                <div [ngClass]="{'border-top border-dark' : printOnly === true}">
                    <div class="d-flex justify-content-between bg-light">
                        <div class="my-auto pl-2">
                            <label class="col-form-label fw-bold">Lighting Results</label>
                        </div>
                        <div class="pr-2">
                            <div class="input-group pt-2 pb-2" *ngIf="!printOnly">
                                <div class="my-auto">
                                    <span class="pr-1">Add</span>
                                </div>
                                <span class="w62">
                                    <input type="number" id="onAddRows" name="addResults" [(ngModel)]="intRowsToAdd" class="form-control form-control-sm for-focus" [ngModelOptions]="{standalone: true}"
                                           #addResults (keyup.enter)="onAddResults($event)" (keydown.enter)="onAddResults($event)" numeric numericType="number">
                                </span>
                                <!--<span class="w62">
                                    <input type="number" name="addResult" [(ngModel)]="intRowsToAdd" [ngModelOptions]="{standalone: true}" [disabled]="blnReadOnly"
                                           class="form-control form-control-sm border-primary" #addResults (keydown.enter)="onAddNewRows($event)">
                                </span>-->
                                <!--<span class="w62">
        <input type="number" id="onAddRows" name="addResult" [(ngModel)]="intRowsToAdd" [ngModelOptions]="{standalone: true}" [disabled]="blnReadOnly"
               class="form-control form-control-sm border-primary" #addResults (keydown.enter)="onAddNewRows($event)">
    </span>-->
                                <div class="my-auto">
                                    <span class="px-1">Row(s)</span>
                                </div>
                                <div class="">
                                    <button id="btnPerson_onAddResults" class="btn btn-sm btn-light border-secondary" type="button" (click)="onAddResults(addResults.value)">Go</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <div class="pl-2 my-auto">
                                <div class="fw-bold">
                                    <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo(locationList, 'Location')" style="cursor:pointer;"></i>&nbsp;
                                    Location
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="pl-2 my-auto">
                                <div class="fw-bold">
                                  <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo(typeList, 'Type')" style="cursor:pointer;"></i>&nbsp;
                                   Type
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="pl-2 my-auto">
                                <div class="fw-bold">
                                    <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo(colorList, 'Color')" style="cursor:pointer;"></i>&nbsp;
                                    Color
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="pl-2 my-auto">
                                <div class="fw-bold">
                                    <i class="fas fa-info-circle 2x" *ngIf="!printOnly" (click)="showAttributeInfo(statusList, 'Status')" style="cursor:pointer;"></i>&nbsp;
                                    Status
                                </div>
                            </div>
                        </div>
                        <div class="col-1" *ngIf="!printOnly">
                            <label class="fw-bold">Action</label>
                        </div>
                    </div>


                    <div [ngClass]="{'px-2 py-1' : printOnly !== true}" *ngFor="let item of veh.LightingType_SS; let i = index;">
                        <div class="row">
                            <div class="col-3">
                                <app-typeahead [id]="'LightingType' + i" name="LightingType"
                                               typeAheadPosition="right-top"
                                               [objModel]="item" strTableName="LightingType_SS" strFieldName="LightingType"
                                               [printOnly]="printOnly" disableLabel="true" [intSeqNum]="item.SeqNum"
                                               [showAllDataListForPrintOnly]="false" [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <div class="col-2">
                                <app-typeahead [id]="'LightingSrc' + i" name="LightingSrc"
                                               typeAheadPosition="right-top"
                                               [objModel]="item" strTableName="LightingType_SS" strFieldName="LightingSrc"
                                               [printOnly]="printOnly" disableLabel="true" [intSeqNum]="item.SeqNum"
                                               [showAllDataListForPrintOnly]="false" [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <div class="col-2">
                                <app-typeahead [id]="'LightingClr' + i" name="LightingClr"
                                               typeAheadPosition="right-top" disableLabel="true"
                                               [objModel]="item" strTableName="LightingType_SS" strFieldName="LightingClr"
                                               [printOnly]="printOnly" [intSeqNum]="item.SeqNum"
                                               [showAllDataListForPrintOnly]="false" [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <div class="col-3">
                                <app-typeahead [id]="'LightingSts' + i" name="LightingSts"
                                               typeAheadPosition="right-top" disableLabel="true"
                                               [objModel]="item" strTableName="LightingType_SS" strFieldName="LightingSts"
                                               [printOnly]="printOnly" [intSeqNum]="item.SeqNum"
                                               [showAllDataListForPrintOnly]="false" [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                            <div class="col-sm-1" *ngIf="!printOnly">
                                <div class="d-flex justify-content-center">
                                    <span class="pl-2">
                                        <button type="button" id="inputPerson_onDeleteRow_{{item.SeqNum}}" class="btn btn-sm btn-light border-secondary my-auto" (click)="onDeleteRow(item.SeqNum)"><span class="fw-bold">Delete</span></button>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--</div>-->
    <!--</div>-->
</form>
