import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, OnDestroy, PipeTransform, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';

import { Subscription } from 'rxjs';

import { SharedDataService } from 'src/app/services/shared-data.service';
import { ViolatedRules } from 'src/app/models/violated-rules';

import { ModalService } from 'src/app/services/modal.service';
import { OverriddenRulesService } from 'src/app/services/overridden-rules.service';
import { ViolatedRulesService } from 'src/app/services/violated-rules.service';
import { CaseService } from 'src/app/services/case.service';
import { AuthService } from 'src/app/services/auth.service';

import { CaseOverRiddenRules } from 'src/app/models/case-over-ridden-rules';
import { CaseOverRiddenRulesReasons } from 'src/app/models/case-overridden-rules-reasons';

import { Acc } from 'src/app/models/acc';
import { OrideCase } from 'src/app/models/oride-case';
import { ObjectUtil } from 'src/app/helper/objectUtil';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { UtilService } from 'src/app/services/util.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { DBMode } from 'src/app/models/enums/app.enums';

@Component({
    selector: 'app-override-rule',
    templateUrl: './override-rule.component.html',
    styleUrls: ['./override-rule.component.css'],
    providers: [
        OverriddenRulesService,
        DatePipe
    ]
})

export class OverrideRuleComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_OverrideRuleComponent: string = null;
    public CaseOverRiddenRule: CaseOverRiddenRules;
    public CaseOverRiddenRulesReasons: CaseOverRiddenRulesReasons[];
    //VIOLATEDRULESOR: ViolatedRules[];

    public ViolatedRule: ViolatedRules
    public ViolatedRules: ViolatedRules[];

    public accid: number;
    //public ViolatedRuleOR: ViolatedRules

    ruleDetails: string;

    RuleID: string;
    VNumber: number;
    PNumber: number;
    From: string
    reason: string;
    definition: string;
    versionNumber: number;

    //URLfragment: string;

    stateNum: number;
    caseYear: number;
    overrideReason: string = '';
    selectedYear: number;

    acc: Acc;
    OrideCase: OrideCase;
    hidden: number;

    todaysDate: Date;
    userId: number;
    overrideRuleSubscription: Subscription;
    violatedRulesSubscription: Subscription;
    overriddenRulesSubscription: Subscription;
    overriddenRulesReasonsSubscription: Subscription;

    subGetCaseOverRiddenRules: Subscription;
    subGetViolatedRulesRules: Subscription;

    subSaveOverrideReason: Subscription;
    subBackToCheckCase: Subscription;

    strModeName: string;
    public intMode: number;

    blnPvtReadOnly: boolean = false;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _sharedDataService: SharedDataService,
        private _overriddenRulesService: OverriddenRulesService,
        private _violatedRulesService: ViolatedRulesService,
        protected _modalService: ModalService,
        private _authService: AuthService,
        private pipe: DecimalPipe,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper

    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        let appSettings = await this._sharedDataService.GetAppSettings();
        if (DBMode[appSettings.intMode]) {
            this.strModeName = DBMode[appSettings.intMode];
        }
        this.caseYear = appSettings.intYear;
        this.selectedYear = appSettings.intYear;
        this.intMode = appSettings.intMode;


        this.todaysDate = new Date(new Date().setFullYear(this.caseYear));

        this._route.parent.params.subscribe((params => {
            this.stateNum = + params['stateNum'];
            this.accid = + params['caseid'];

            this._authService.GetUserPromise().then((result => {
                this.userId = result.UserId; //We need the user ID before instantiating OrideCase
                this.blnPvtReadOnly = result.blnReadOnly;

                this._route.params.subscribe((params => { //calling function to fetch data only when RuleID is part of URL; 
                    this.RuleID = params['RuleID'];
                    console.log("Acc from OverrideRuleComponent: ", this.acc.Casenum);
                    this._route.queryParams.subscribe(((params) => {// we have to know from where we are coming and based on that we will get rules from Rules_Error || OrideCase 
                        this.From = params["From"];
                        this.VNumber = +params["VNumber"];
                        this.PNumber = +params["PNumber"];

                        if (!this.acc.OrideCase || this.From == "override") { //we have to cover if  this.acc.OrideCase does not exist(request coming from edit) or if request coming from override(We know it does not exist)
                            this.OrideCase = ObjectUtil.InstantiateOrideCase(this.acc, this.RuleID, this.VNumber, this.PNumber);
                            this.OrideCase.USERID = this.userId;
                            this.acc.OrideCase.push(this.OrideCase);
                        }
                        else {
                            this.OrideCase = this.acc.OrideCase.find(x => x.ACCID == this.acc.AccID && x.RULEID == this.RuleID && x.VNumber == this.VNumber && x.PNumber == this.PNumber);
                        }

                        if (this.From == "override") {//lending to page from Violated Rules page;
                            this.subGetViolatedRulesRules = this._violatedRulesService.GetViolatedRules(this.accid).subscribe(
                                result => {
                                    this.ViolatedRule = result.find(x => x.RuleID == this.RuleID && x.VNumber == this.VNumber && x.PNumber == this.PNumber);
                                    if (this.ViolatedRule) {
                                        this.OrideCase.ACCID = this.accid;
                                        this.OrideCase.RULEID = this.RuleID;
                                        this.OrideCase.VNumber = this.VNumber;
                                        this.OrideCase.PNumber = this.PNumber;
                                        this.OrideCase.IsHide = false;
                                        this.OrideCase.VersionNumber = this.ViolatedRule.CurrentVersionNumber;
                                        this.OrideCase.ORIDEDATE = new Date();

                                        this.OrideCase.REASON = "";
                                        this.definition = this.ViolatedRule.Definition;
                                    }
                                },
                                error => {
                                    console.log("GetViolatedRules Error", error);
                                });
                        }
                        if (this.From == "edit") {//lending to page from Overridden Rules page;
                            this.subGetCaseOverRiddenRules = this._overriddenRulesService.GetCaseOverRiddenRules(this.stateNum, this.accid, this.selectedYear, this.RuleID).subscribe(
                                result => {
                                    this.CaseOverRiddenRule = result.find(x => x.RULEID == this.RuleID && x.VNumber == this.VNumber && x.PNumber == this.PNumber);
                                    if (this.CaseOverRiddenRule) {
                                        this.OrideCase.ACCID = this.accid;
                                        this.OrideCase.RULEID = this.RuleID;
                                        this.OrideCase.VNumber = this.VNumber;
                                        this.OrideCase.PNumber = this.PNumber;
                                        this.OrideCase.IsHide = false;
                                        this.OrideCase.VersionNumber = this.CaseOverRiddenRule.CurrentVersionNumber;
                                        this.OrideCase.ORIDEDATE = new Date();

                                        this.OrideCase.REASON = this.CaseOverRiddenRule.REASON;
                                        this.definition = this.CaseOverRiddenRule.RuleDef;                                    
                                    }
                                },
                                error => {
                                    console.log("GetCaseOverRiddenRules Error", error);
                                });
                        }
                    }).bind(this));

                    this.getOverRiddenRulesReasons();
                }).bind(this));
            }).bind(this));

            this.subBackToCheckCase = this._sharedDataService.subjectBackToCase.subscribe(() => { //Listen subsctiptin for BackToCase btn-click               
                this.OnBackToCase();
            });

            this.subBackToCheckCase = this._sharedDataService.subjectBackToCheckCase.subscribe(() => { //Listen subsctiptin for BackToCheckCase btn-click               
                this.OnBackToCheckCase();
            });

            this.subSaveOverrideReason = this._sharedDataService.subjectSaveOverrideReason.subscribe(() => { //Listen subsctiptin for SaveOverrideReason btn-click                
                this.saveOverrideReason();
            });
        }).bind(this));
    }

    getOverRiddenRulesReasons() {
        this.overriddenRulesReasonsSubscription = this._overriddenRulesService.GetCaseOverRiddenRulesReasons(this.stateNum, this.caseYear, this.RuleID).subscribe(result => {
            this.CaseOverRiddenRulesReasons = result;
        }, error => {
            console.log("GetCaseOverRiddenRulesReasons Error", error);
        }, () => { // unsubscribe on complete!
            this.overriddenRulesReasonsSubscription.unsubscribe();
        });
    };

    saveOverrideReason() {
        if (this.OrideCase.REASON.trimLeft() === "") {
            this._modalService.setMessage('Please enter an override reason', 'danger');
            return;
        }

        else {
            this._overriddenRulesService.SaveOrideCase(this.OrideCase).then((() => {
                this._modalService.setMessage('The override reason was saved successfully', 'success');
                this._router.navigate([this.stateNum, 'checkCase', this.accid, 'overriddenRules']);
            }).bind(this)), error => {
                console.log("Error: ", error);
            };
        }
    }

    onSelect(value) {
        this.OrideCase.REASON = value;
    }

    onHide(overriddenRule: CaseOverRiddenRulesReasons) {
        this._modalService.dialog('Are you sure you want to hide this override reason for this rule?', 'Warning').pipe().subscribe(okCliked => {
            if (okCliked) {
                //let OverRiddenReasons: CaseOverRiddenRulesReasons;
                //OverRiddenReasons = this.CaseOverRiddenRulesReasons.find(r => r.RULEID == overriddenRule.RULEID &&
                //    r.VNumber == overriddenRule.VNumber &&
                //    r.PNumber == overriddenRule.PNumber &&
                //    r.REASON == overriddenRule.REASON);
                overriddenRule.StateNumber = this.stateNum;
               

                this._overriddenRulesService.SaveOrideCaseIsHideFlag(overriddenRule).then(item => {
                    this._modalService.setMessage('The override reason has been hidden successfully', 'success');
                    this.getOverRiddenRulesReasons();
                });

            }
        });
    }

    OnBackToCase() {
        if (this.From == 'override') {

            console.log(this.acc.OrideCase.find(x => x.RULEID == this.RuleID));
            this.acc.OrideCase.splice(this.acc.OrideCase.findIndex(x => x == this.OrideCase));//Removing  OrideCase from objModel(this.acc) before we navigate back to violatedRules page;     
            console.log(this.acc.OrideCase.find(x => x.RULEID == this.RuleID));
        }

        let lastUrl = this._sharedDataService.getLastCaseUrl();
        if (lastUrl) {
            this._router.navigateByUrl(lastUrl);
        }
        else {
            this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid, 'crash']);
        }
    }

    OnBackToCheckCase() {
        if (this.From == 'override') {
            this.acc.OrideCase.splice(this.acc.OrideCase.findIndex(x => x == this.OrideCase)); //Removing  OrideCase from objModel(this.acc) before we navigate back to violatedRules page;     

            this._router.navigate([this.stateNum, 'checkCase', this.accid, 'violatedRules']);
        }
        if (this.From == 'edit') {
            this._router.navigate([this.stateNum, 'checkCase', this.accid, 'violatedRules']);
           // this._router.navigate([this.stateNum, 'checkCase', this.accid, 'overriddenRules']);
        }
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.subSaveOverrideReason) {
            this.subSaveOverrideReason.unsubscribe();
        }

        if (this.subBackToCheckCase) {
            this.subBackToCheckCase.unsubscribe();
        }
    }
}
