import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from 'src/app/services/case.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { UtilService } from 'src/app/services/util.service';
import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { Dri } from 'src/app/models/dri';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { FormName } from 'src/app/models/enums/app.enums';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

@Component({
    selector: 'app-vehicle-driver-license',
    templateUrl: './vehicle-driver-license.component.html',
    styleUrls: ['./vehicle-driver-license.component.css']
})

export class VehicleDriverLicenseComponent extends BaseComponent implements OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_VehicleDriverLicenseComponent: string = null;
    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    dri: Dri;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngOnInit() {
        super.ngOnInit();
        
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        if (!this.veh.Dri) {
            this.dri = {} as Dri;
        }
        else {
            this.dri = this.acc.Veh.find(v => v.VNumber == this.vehicleid).Dri;
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    setObjectValue($event: DrpDownOptions, fieldName: string) {
        super.setObjectValue($event, fieldName, this.dri);
    }

    setFeetAndInches($event) {
        this.MarkFormAsDirty();
    }

    ngOnDestroy() {
    }
}
