import { Injectable, PipeTransform } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, of, Subject, empty } from 'rxjs';
import { GenericService } from './generic.service';
import { MonthlyCount } from './../models/MonthlyCount';
import { GetMonthlyCountValuesResult } from 'src/app/models/get-monthly-count-values-result'

@Injectable({
  providedIn: 'root'
})
export class MonthlyCountService {

    private monthlyCounts: BehaviorSubject<any[]>;

    constructor(private _baseService: GenericService<any>) {
        this.monthlyCounts = new BehaviorSubject<any[]>([]);
    }

    public getMonthlyCount(state: number, year: number, typeId: number): Observable<GetMonthlyCountValuesResult[]> {
        this._baseService.actionURL = "api/monthlycount/monthlycount/" + state +'/'+ year +'/'+ typeId;
        let monthlyCounts = this._baseService.getData();
        monthlyCounts.subscribe(options => {
            this.monthlyCounts.next(options)
        })
        return monthlyCounts;
    }

    public get getListOfMonthlyCounts(): Observable<any> {
        return this.monthlyCounts.asObservable();
    }

    public clearObservable() {
        this.monthlyCounts.next([]);
    }
    
    public saveMonthlyCount(item: MonthlyCount): Observable<MonthlyCount> {
        this._baseService.actionURL = "api/monthlycount/SaveMonthlyCount/";
        return this._baseService.updateEntity(item);
    }
}
