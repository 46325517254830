<form novalidate #fnonOccNonMotorist="ngForm">
    <div class="pt-2" *ngIf="nonOccupant && intMode">

        <div class="{{ printOnly === true ? 'row' : '' }}">
            <div class="{{ printOnly === true ? 'col-6 border border-dark' : ''}}">
                <div class="form-group {{ printOnly !== true ? 'pl-2' : ''}}">
                    <app-typeahead id="NMLocat" name="NMLocat" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant"
                                   strTableName="Non_Occupants"
                                   strFieldName="NMLocat"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-multiselect id="actPriorCrash"

                                     strTableName="NonOcc_ActPriorCrash"
                                     strFieldName="ElementValue"
                                     [objTemplateModel]="objNonNonOccActAtCrashTemplate"
                                     [objModel]="nonOccupant.NonOcc_ActPriorCrash"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-multiselect id="actAtCrash"
                                     strTableName="NonOcc_ActAtCrash"
                                     strFieldName="ElementValue"
                                     [objTemplateModel]="objNonOccActPriorCrashTemplate"
                                     [objModel]="nonOccupant.NonOcc_ActAtCrash"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div #typAhdNonOccDistract *ngIf="intMode == 64 || intMode == 128" class="{{ printOnly === true ? 'col-6 border border-dark' : ''}}">
                <div class="form-group {{ printOnly !== true ? 'pl-2' : ''}}">
                    <app-multiselect id="nMDistract"
                                     [objModel]="nonOccupant.NonOcc_Distract"
                                     [objTemplateModel]="objNonOccDistractTemplate"
                                     strTableName="NonOcc_Distract"
                                     strFieldName="ElementValue"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'pl-0 text-center' : printOnly === true}" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label [ngClass]="{'col-form-label fw-bold pl-2' : printOnly === false}">
                    <span *ngIf="intMode == 64 || intMode == 128">Non-Motorist Safety Equipment</span>
                    <span *ngIf="intMode == 256">Preventive Non-Occupant Safety Equipment</span>

                </label>
            </div>
            <ng-container class="" *ngIf="nonOccupant.NonOcc_Safety">
                <!--Application-->
                <div *ngIf="!printOnly" [ngClass]="{'card border border-secondary bg-light m-2' : printOnly === false}">
                    <div *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'card-body border border-secondary bg-light p-1 margin-border' : printOnly === false}">
                        <div [ngClass]="{'card-title fw-bold bg-light' : printOnly === false}"><span>Protective</span></div>
                        <div class="form-group">
                            <div #typAhdproHelmet class="pl-2">
                                <app-typeahead id="proHelmet" name="proHelmet"
                                               [objModel]="nonOccupant.NonOcc_Safety[0]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="1"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                        <div class="form-group">
                            <div #typAhdproPads class="pl-2">
                                <app-typeahead id="proPads" name="proPads"
                                               [objModel]="nonOccupant.NonOcc_Safety[1]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="2"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="proOther" name="proOther"
                                               [objModel]="nonOccupant.NonOcc_Safety[2]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="3"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Print-->
                <ng-container *ngIf="printOnly && (intMode == 64 || intMode == 128)">
                    <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                        <div class="card-title fw-bold bg-light"><span>Protective</span></div>
                    </div>
                        <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                            <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                                <app-typeahead id="proHelmet" name="proHelmet"
                                               [objModel]="nonOccupant.NonOcc_Safety[0]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="1"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                        <div *ngIf="printOnly" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                            <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                                <app-typeahead id="proPads" name="proPads"
                                               [objModel]="nonOccupant.NonOcc_Safety[1]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="2"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                        <div *ngIf="printOnly" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                            <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                                <app-typeahead id="proOther" name="proOther"
                                               [objModel]="nonOccupant.NonOcc_Safety[2]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="3"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                </ng-container>

                <!--Application-->
                <div *ngIf="!printOnly" [ngClass]="{'card border border-secondary bg-light m-2' : printOnly === false}">
                    <div [ngClass]="{'card-body border border-secondary bg-light p-1 margin-border' : printOnly === false}">
                        <div [ngClass]="{'card-title fw-bold bg-light' : printOnly === false}" *ngIf="intMode == 64 || intMode == 128"><span>Preventive</span></div>
                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="preReflectiveClothing" name="preReflectiveClothing"
                                               [objModel]="nonOccupant.NonOcc_Safety[3]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="4"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="preLightingUse" name="preLightingUse"
                                               [objModel]="nonOccupant.NonOcc_Safety[4]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="5"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="pl-2">
                                <app-typeahead id="preOther" name="preOther"
                                               [objModel]="nonOccupant.NonOcc_Safety[5]"
                                               strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="6"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Print-->
                <ng-container *ngIf="printOnly">
                    <div [ngClass]="{'col-12 border border-dark mt-2' : printOnly === true}">
                        <div class="card-title fw-bold bg-light"  *ngIf="intMode == 64 || intMode == 128"><span>Preventive</span></div>
                    </div>
                    <div *ngIf="printOnly" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                        <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                            <app-typeahead id="preReflectiveClothing" name="preReflectiveClothing"
                                           [objModel]="nonOccupant.NonOcc_Safety[3]"
                                           strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="4"
                                           [printOnly]="printOnly"
                                           [clearFieldData]="clearFieldData">
                            </app-typeahead>
                        </div>
                    </div>
                    <div *ngIf="printOnly" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                        <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                            <app-typeahead id="preLightingUse" name="preLightingUse"
                                           [objModel]="nonOccupant.NonOcc_Safety[4]"
                                           strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="5"
                                           [printOnly]="printOnly"
                                           [clearFieldData]="clearFieldData">
                            </app-typeahead>
                        </div>
                    </div>
                    <div *ngIf="printOnly" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                        <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                            <app-typeahead id="preOther" name="preOther"
                                           [objModel]="nonOccupant.NonOcc_Safety[5]"
                                           strTableName="NonOcc_Safety" strFieldName="ElementValue" [intSeqNum]="6"
                                           [printOnly]="printOnly"
                                           [clearFieldData]="clearFieldData">
                            </app-typeahead>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        </div>
</form>
