<div class="row pt-4">
    <div class="col-xl">

        <div class="row">
            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 pt-2 pt-sm-2 pt-lg-2 pt-xl-0">

                <div class="pb-2 form-inline">
                    <h5 *ngIf="selectedMode == 1 || selectedMode == 64">Overridden Rules for Case - {{ acc?.Casenum | number : '4.0-0' | replaceTextPipe : ',' : '' }}</h5>
                    <h5 *ngIf="selectedMode == 4">Overridden Rules for PAR ID - {{ acc.EarlyNotify[0]?.PARID | number : '8.0-0' | replaceTextPipe : ',' : '' | slice : 4 : 12 }}</h5>
                    <h5 *ngIf="selectedMode == 256">Overridden Rules for PAR ID - {{ acc.Acc_SS.MOSSCasenum }}</h5>
                    <span class="ml-3" *ngIf="loading$ | async"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></span>
                </div>

                <div class="">

                    <div class="table-responsive">
                        <table class="table table-hover table-sm mb-0">
                            <thead class="bg-tb-header">
                                <tr class="">
                                    <th scope="col" class="border-bottom border-dark">Cancel</th>
                                    <th scope="col" class="border-bottom border-dark">Edit</th>
                                    <th scope="col" sortable="RULEID" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span> Rule</span>
                                        <span *ngIf="ColummForSorting == 'RULEID'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="REASON" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span>Reason</span>
                                        <span *ngIf="ColummForSorting == 'REASON'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="VNumber" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span>Vehicle #</span>
                                        <span *ngIf="ColummForSorting == 'VNumber'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                    <th scope="col" sortable="PNumber" (sort)="onSort($event)" class="border-bottom border-dark text-nowrap text-center">
                                        <span>Person #</span>
                                        <span *ngIf="ColummForSorting == 'PNumber'" class="pl-2">
                                            <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up sorting-icon-size"></i>
                                            <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down sorting-icon-size"></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom border-dark">

                                <tr class="bg-white">
                                    <td class="border-bottom border-dark">
                                        <div class="form-group pt-2 pl-2 mb-2">
                                        </div>
                                    </td>
                                    <td class="border-bottom border-dark">
                                        <div class="form-group pt-2 pl-2 mb-2">
                                        </div>
                                    </td>
                                    <td class="border-bottom border-dark">
                                        <div class="form-group  pt-2 pl-2 mb-2">
                                            <input class="form-control form-control-sm" type="text" name="searchVehTerm" placeholder="Search" [(ngModel)]="searchRuleTerm" />
                                        </div>
                                    </td>
                                    <td class="border-bottom border-dark">
                                        <div class="form-group  pt-2 pl-2 mb-2">
                                            <input class="form-control form-control-sm" type="text" name="searchPersonTerm" placeholder="Search" [(ngModel)]="searchReasonTerm" />
                                        </div>
                                    </td>
                                    <td class="border-bottom border-dark">
                                        <div class="form-group  pt-2 pl-2 mb-2">
                                            <input class="form-control form-control-sm" type="text" name="searchSeverityTerm" placeholder="Search" [(ngModel)]="searchVehicleTerm" />
                                        </div>
                                    </td>
                                    <td class="border-bottom border-dark">
                                        <div class="form-group  pt-2 pl-2 mb-2">
                                            <input class="form-control form-control-sm" type="text" name="searchOverrideTerm" placeholder="Search" [(ngModel)]="searchPersonTerm" />
                                        </div>
                                    </td>
                                </tr>

                                <tr *ngFor="let overriddenRule of overriddenRules$ | async">
                                    <td class="my-auto p-0">
                                        <button type="button" class="btn btn-link text-truncate" (click)="onCancel(overriddenRule)">
                                            Cancel
                                        </button>
                                    </td>
                                    <td class="my-auto p-0">
                                        <button type="button" class="btn btn-link text-truncate" (click)="onEdit(overriddenRule)">
                                            Edit
                                        </button>
                                    </td>
                                    <td class="text-center text-nowrap">
                                        <ngb-highlight [result]="overriddenRule?.RULEID" [term]="searchRuleTerm"></ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight [result]="overriddenRule?.REASON" [term]="searchReasonTerm"></ngb-highlight>
                                    </td>
                                    <td class="text-center text-nowrap">
                                        <ngb-highlight [result]="overriddenRule?.VNumber"></ngb-highlight>
                                    </td>
                                    <td class="text-center text-nowrap">
                                        <ngb-highlight [result]="overriddenRule?.PNumber"></ngb-highlight>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                        <div class="d-flex justify-content-between p-2">
                            <div class="align-middle">
                                <ngb-pagination class="align-middle" [collectionSize]="total$ | async" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                            </div>
                            <div class="custom-control-inline">
                                <select class="custom-select pl-2 my-auto w-auto" name="pageSize" [(ngModel)]="pageSize">
                                    <option [ngValue]="5">5 items per page</option>
                                    <option [ngValue]="10">10 items per page</option>
                                    <option [ngValue]="25">25 items per page</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



