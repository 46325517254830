<form novalidate #fvvavoidequip="ngForm">
    <div *ngIf="veh" id="avoidanceEquipment" class="border-dark border-start border-bottom border-end px-2">
        <div [ngClass]="{'row' : printOnly === true}">

            <div #typAhdAvoidEquipAssmnt [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-typeahead id="vehAvoidEquipAssmnt" name="vehAvoidEquipAssmnt" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_MTSS" strTableName="Veh_MTSS" strFieldName="AvoidEquipAssmnt"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdFwdCollisionWarningHOV [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-typeahead id="FwdCollisionWarningHOV" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="FwdCollisionWarningHOV"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdAutomaticBrakingHOV [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-typeahead id="AutomaticBrakingHOV"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="AutomaticBrakingHOV"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdLaneDepartureWarningHOV [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-typeahead id="LaneDepartureWarningHOV"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="LaneDepartureWarningHOV"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdLaneKeepingHOV [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-typeahead id="LaneKeepingHOV"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="LaneKeepingHOV"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdBlindSpotHOV [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-typeahead id="BlindSpotHOV"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="BlindSpotHOV"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}" *ngIf="intMode == 64">
                <div class="form-group pl-2">
                    <app-textfield id="VehAvoidAssmntNotes" [disableLabel]="false"
                                   [blnIsNumber]="false" [intTextAreaRows]="10"
                                   [objModel]="veh.Veh_MTSS" [(objCurrentValue)]="veh.Veh_MTSS.AvoidAssmntNotes"
                                   [blnFormatWithLeading]="false"
                                   strTableName="Veh_MTSS" strFieldName="AvoidAssmntNotes"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-textfield>
                </div>
            </div>
            <div #typAhdAvoidAssmnt [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="AvoidAssmnt" name="AvoidAssmnt" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AvoidAssmnt"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
        <div class="form-group pl-2">
            <app-typeahead id="travelspeed" name="travelspeed" typeAheadPosition="right-top"
                           [objModel]="veh" strTableName="Veh" strFieldName="TravSpd" [printOnly]="printOnly"
                           [showAllDataListForPrintOnly]="false"
                           [blnShowSpecialValuesOnly]="true"
                           [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="TravSpd" [printOnly]="printOnly" inputName="TravSpd"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-speed-calculator *ngIf="!blnReadOnly"
                                                      [objVeh]="veh"
                                                      objToUpdate="TravSpd"
                                                      destUnits="Mph"
                                                      (setSpeedEvent)="setTravelSpeed($event)">
                                </app-speed-calculator>
                            </span>
                        </div>
                        <div #typAhdTravSpd [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="travelspeed" name="travelspeed" typeAheadPosition="right-top"
                                               [objModel]="veh" strTableName="Veh" strFieldName="TravSpd"
                                               [printOnly]="printOnly" [disableLabel]="true" [blnAllowFreeText]="true"
                                               [clearFieldData]="clearFieldData" [showAllDataListForPrintOnly]="false"
                                               [blnShowSpecialValuesOnly]="true">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="AcurcyVehTrvlSpdEst" [printOnly]="printOnly" inputName="AcurcyVehTrvlSpdEst"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-speed-calculator *ngIf="!blnReadOnly"
                                                      [objVeh]="veh.Veh_SS"
                                                      objToUpdate="AcurcyVehTrvlSpdEst"
                                                      (setSpeedEvent)="setAcurcyVehTrvlSpdEst($event)">
                                </app-speed-calculator>
                            </span>
                        </div>
                        <div #typAhdAcurcyVehTrvlSpdEst [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="AcurcyVehTrvlSpdEst" name="AcurcyVehTrvlSpdEst" typeAheadPosition="right-top"
                                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AcurcyVehTrvlSpdEst"
                                               [printOnly]="printOnly" [disableLabel]="true" [blnAllowFreeText]="true"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label class="my-auto" inputName="DtectnDistFrPed" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-distance-calculator #DtectnDistFrPedElement [objName]="'DtectnDistFrPed'" (submitDistanceValue)="setDistanceForPedValue($event)" *ngIf="!blnReadOnly"></app-modals-distance-calculator>
                                </div>
                            </div>
                        </div>
                        <div #typAhdDtectnDistFrPed [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-typeahead id="DtectnDistFrPed" name="DtectnDistFrPed" typeAheadPosition="right-top"
                                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="DtectnDistFrPed"
                                               [printOnly]="printOnly" 
                                               [clearFieldData]="clearFieldData"
                                               disableLabel="true" [showAllDataListForPrintOnly]="false"
                                                [blnAllowFreeText]="true"
                                                [maxlength]="4"
                                                [blnFormatWithLeading]="false"                                                
                                               >
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label class="my-auto" inputName="AccrcyDtectnDistFrPedEst" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-distance-calculator #AccrcyDtectnDistFrPedEstElement [objName]="'AccrcyDtectnDistFrPedEst'" (submitDistanceValue)="setAccDistanceValue($event)" *ngIf="!blnReadOnly" [hideSubmitButton]="true"></app-modals-distance-calculator>
                                </div>
                            </div>
                        </div>
                        <div #typAhdAccrcyDtectnDistFrPedEst [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-typeahead id="AccrcyDtectnDistFrPedEst" name="AccrcyDtectnDistFrPedEst" typeAheadPosition="right-top"
                                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AccrcyDtectnDistFrPedEst"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData"
                                               disableLabel="true" [showAllDataListForPrintOnly]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #typAhdCalcRevlTime [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="CalcRevlTime" name="CalcRevlTime" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="CalcRevlTime"
                                   [printOnly]="printOnly" [blnAllowFreeText]="true"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdAccrcyCalcRevlTmEst [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="AccrcyCalcRevlTmEst" name="AccrcyCalcRevlTmEst" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AccrcyCalcRevlTmEst"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdPedCrshWrnEff [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="PedCrshWrnEff" name="PedCrshWrnEff" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="PedCrshWrnEff"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdPedEmrgcyBrkEff [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="PedEmrgcyBrkEff" name="PedEmrgcyBrkEff" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="PedEmrgcyBrkEff"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div #typAhdPedEmrgcySterEff [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group pl-2">
                    <app-typeahead id="PedEmrgcySterEff" name="PedEmrgcySterEff" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="PedEmrgcySterEff"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
        <div class="form-group">
            <div class="row">
                <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                    <div class="pl-2">
                        <app-label label="AcurcyVehTrvlSpdEst" [printOnly]="printOnly" inputName="AcurcyVehTrvlSpdEst"></app-label>
                    </div>
                    <span class="my-auto" *ngIf="!printOnly">
                        <app-speed-calculator *ngIf="!blnReadOnly"
                                              [objVeh]="veh.Veh_SS"
                                              objToUpdate="AcurcyVehTrvlSpdEst"
                                              (setSpeedEvent)="setAcurcyVehTrvlSpdEst($event)">
                        </app-speed-calculator>
                    </span>
                </div>
                <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                    <div class="pl-2">
                        <app-typeahead id="AcurcyVehTrvlSpdEst" name="AcurcyVehTrvlSpdEst" typeAheadPosition="right-top"
                                       [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AcurcyVehTrvlSpdEst"
                                       [printOnly]="printOnly" [disableLabel]="true" [blnAllowFreeText]="true"
                                       [clearFieldData]="clearFieldData">
                        </app-typeahead>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="ImpactSpeed" [printOnly]="printOnly" inputName="ImpactSpeed"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-speed-calculator *ngIf="!blnReadOnly"
                                                      [objVeh]="veh"
                                                      objToUpdate="ImpactSpeed"
                                                      (setSpeedEvent)="setImpactSpeed($event)">
                                </app-speed-calculator>
                            </span>
                        </div>
                        <div #typAhdImpactSpeed [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="vehImpactSpeed" name="vehImpactSpeed" typeAheadPosition="right-top"
                                               [disableLabel]="true"
                                               [objModel]="veh.Veh_SS"
                                               strTableName="Veh_SS" strFieldName="ImpactSpeed"
                                               [blnAllowFreeText]="true"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode == 128">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="AcurcyImpSpdEst" [printOnly]="printOnly" inputName="AcurcyImpSpdEst"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-speed-calculator *ngIf="!blnReadOnly"
                                                      [objVeh]="veh.Veh_SS"
                                                      objToUpdate="AcurcyImpSpdEst"
                                                      (setSpeedEvent)="setAcurcyImpSpdEst($event)">
                                </app-speed-calculator>
                            </span>
                        </div>
                        <div #typAhdAcurcyImpSpdEst [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="AcurcyImpSpdEst" name="AcurcyImpSpdEst" typeAheadPosition="right-top"
                                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AcurcyImpSpdEst"
                                               [printOnly]="printOnly" [blnAllowFreeText]="true" [disableLabel]="true"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode ==128">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label class="my-auto" inputName="PedFinalRestDist" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-distance-calculator #PedFinalRestDistElement [objName]="'PedFinalRestDist'" (submitDistanceValue)="setPedFnlRstDistanceValue($event)" *ngIf="!blnReadOnly"></app-modals-distance-calculator>
                                </div>
                            </div>
                        </div>
                        <div #typAhdPedFinalRestDist [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-typeahead id="PedFinalRestDist" name="PedFinalRestDist" typeAheadPosition="right-top"
                                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="PedFinalRestDist"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData"
                                               disableLabel="true" [showAllDataListForPrintOnly]="false"
                                                [blnAllowFreeText]="true"
                                                [maxlength]="4"
                                                [blnFormatWithLeading]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}" *ngIf="intMode ==128">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label class="my-auto" inputName="VehFinalRestDist" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-distance-calculator #VehFinalRestDistElement [objName]="'VehFinalRestDist'" (submitDistanceValue)="setFnlRstDistanceValue($event)" *ngIf="!blnReadOnly"></app-modals-distance-calculator>
                                </div>
                            </div>
                        </div>
                        <div #typAhdVehFinalRestDist [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-typeahead id="VehFinalRestDist" name="VehFinalRestDist" typeAheadPosition="right-top"
                                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="VehFinalRestDist"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData"
                                               disableLabel="true" [showAllDataListForPrintOnly]="false"
                                                [blnAllowFreeText]="true"
                                                [maxlength]="4"
                                                [blnFormatWithLeading]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #typAhdSpdDistDataSrc [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode ==128">
                <div class="form-group pl-2">
                    <app-typeahead id="SpdDistDataSrc" name="SpdDistDataSrc" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="SpdDistDataSrc"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}" *ngIf="intMode ==128">
                <div class="form-group pl-2">
                    <app-textfield id="AvoidAssessmentNotes" [disableLabel]="false"
                                   [blnIsNumber]="false" [intTextAreaRows]="10"
                                   [objModel]="veh.Veh_SS" [(objCurrentValue)]="veh.Veh_SS.AvoidAssessmentNotes"
                                   [blnFormatWithLeading]="false"
                                   strTableName="Veh_SS" strFieldName="AvoidAssessmentNotes"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-textfield>
                </div>
            </div>
        </div>
    </div>
</form>
