import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { NonOcc_Ped } from 'src/app/models/non-occ-ped';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pedcopydata',
  templateUrl: './pedcopydata.component.html',
    styleUrls: ['./pedcopydata.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PedcopydataComponent implements OnInit {

    filteredPedLookupData: Array<DrpDownOptions> = [];
    allExisitingPedList: Array<NonOcc_Ped> = [];
    tmpGrid: Array<TmpGridData> = [];
    noCopyAvailable: boolean = true;
    
    constructor(public modal: NgbActiveModal) { }

    ngOnInit() {
        this.loadData();
    }

    getTitleFromLookup(fieldValue: any, tableName: any) {
        if (fieldValue == undefined || fieldValue == null) return '-';
        const field = this.filteredPedLookupData.find(x => x.strValue == fieldValue && x.tblName == tableName)
        if (field != null && field != undefined) {
            if (tableName == 'PBCAT_PedScenario') return field.strValue
            return field.displayText;
        } else {
            return '-';
        }
    }

    loadData() {
        if (this.allExisitingPedList.length > 0) {
            this.tmpGrid = [];
            this.allExisitingPedList.forEach(item => {
                this.fillTmpGridData(item);
            })
        }
        this.noCopyAvailable = this.allExisitingPedList.length == 0;
    }
    fillTmpGridData(item: any) {
        const tmpGrid = {} as TmpGridData;
        tmpGrid.PNumber = item.PNumber;
        tmpGrid.CrashLocation = this.getTitleFromLookup(item.CrashLocation, 'PBCAT_PedCrashLocation')
        tmpGrid.PedPosition = this.getTitleFromLookup(item.PedPosition, 'PBCAT_PedPosition')
        tmpGrid.MotDirection = this.getTitleFromLookup(item.MotDirection, 'PBCAT_PedMotDirection')
        tmpGrid.MotManeuver = this.getTitleFromLookup(item.MotManeuver, 'PBCAT_PedMotManeuver')
        tmpGrid.LegIntersection = this.getTitleFromLookup(item.LegIntersection, 'PBCAT_PedLegIntersection')
        tmpGrid.Scenario = this.getTitleFromLookup(item.Scenario, 'PBCAT_PedScenario')
        tmpGrid.PedDirection = this.getTitleFromLookup(item.PedDirection, 'PBCAT_PedDirection')
        tmpGrid.CrashType = this.getTitleFromLookup(item.CrashType, 'PBCAT_PedCrashType')
        tmpGrid.SchoolZone = this.getTitleFromLookup(item.SchoolZone, 'PBCAT_SchoolZone')
        tmpGrid.Crosswalk = this.getTitleFromLookup(item.Crosswalk, 'PBCAT_CrossWalk')
        tmpGrid.Sidewalk = this.getTitleFromLookup(item.Sidewalk, 'PBCAT_SideWalk')
        tmpGrid.RowItem = item;
        this.tmpGrid.push(tmpGrid);
    }
}

export interface TmpGridData {
    PNumber: number
    CrashLocation: string;
    PedPosition: string;
    MotDirection: string;
    MotManeuver: string;
    LegIntersection: string;
    Scenario: string;
    PedDirection: string;
    CrashType: string;
    SchoolZone: string;
    Crosswalk: string;
    Sidewalk: string;
    RowItem: any;
}
