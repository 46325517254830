import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Acc } from 'src/app/models/acc';
import { ModalService } from 'src/app/services/modal.service';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { ParErrorCodeList } from 'src/app/models/ParErrorCodeList';

@Component({
  selector: 'app-delete-crss-case',
  templateUrl: './delete-crss-case.component.html',
  styleUrls: ['./delete-crss-case.component.css']
})
export class DeleteCrssCaseComponent implements OnInit {

    acc: Acc
    errorId = -1;
    existingErrorId = -1;
    public intParId_Duplicate: number = null;
    innerPopup: NgbModalRef;
    success: string = 'success';
    title: string = 'Set PAR Error/Delete Case';
    textLabel: string = 'Delete';
    isUnDeleteMode: boolean = false;
    message: string = '';
    currentStatus: string = '';
    parErrorCodeList: Array<ParErrorCodeList> = [];

    deleteErrorCodeList: Array<ParErrorCodeList> = [];
    errorCodeList: Array<ParErrorCodeList> = [];
    jurisdiction: string = '';

    @ViewChild('alertMessage') alertMessage: ViewContainerRef;
    constructor(
        public activeModal: NgbActiveModal,
        private localModal: NgbModal,
        private _utilService: UtilService,
        private _modalService: ModalService
    ) { }

    ngOnInit() {
        this._utilService.getParErrorCodeLists().then(result => {
            this.parErrorCodeList = result;
            this.parErrorCodeList.splice(0, 0, { ID: -1, DEF: 'Select a option', IsAllowInMDE: false } as ParErrorCodeList);
            this.parErrorCodeList.forEach((item) => {
                if (item.IsAllowInMDE == false && item.ID != -1) this.deleteErrorCodeList.push(item);
                if (item.IsAllowInMDE == true) this.errorCodeList.push(item);
            });
            this.currentStatus = this.getDescription;
        });

        if (this.acc.EarlyNotify[0].ParError != null) {
            this.errorId = this.acc.EarlyNotify[0].ParError;
            this.intParId_Duplicate = this.acc.EarlyNotify[0].PARID_Duplicate;
            this.existingErrorId = this.errorId;
        }

        this.getCurrentJurisdiction();

        if (this.isUnDeleteMode) {
            this.title = 'Set PAR Error/Undelete Case';
        } else {
            this.title = 'Set PAR Error/Delete Case';
        }
    }

    getCurrentJurisdiction() {
        this._utilService.GetDrpDownListOptions("VR_Jurisdiction",'').toPromise().then(result => {
            if (result) {
                this.jurisdiction = result.find(x => x.intValue == this.acc.EarlyNotify[0].RptJur).strText;
            }
        });
    }

    setParError() {
        if (this.errorId == undefined || this.errorId == null || this.errorId == -1) { //PAR Error 0 is a valid value
            this.message = 'Please Select PAR Error.'
            this.openMessage().toPromise();
        }
        else if (this.isDeletedCode && !this.isUnDeleteMode) {
            this.message = 'You can set the PAR Error to \"' + this.getDescription +'\" only when deleting the case. Please click on \"Delete\" button to delete the case.'
            this.openMessage().toPromise();
        }
        else if (this.isErrorCode && this.isUnDeleteMode) {
            this.message = 'You can set the PAR Error to \"' + this.getDescription + '\" only when undeleting  the case. Please click on \"Undelete\" button to delete the case.'
            this.openMessage().toPromise();
        }
        else {
            this._utilService.UpdateCrssCase(this.acc.AccID, this.errorId, this.isUnDeleteMode, this.intParId_Duplicate).toPromise().then(result => {
                this.acc = result;
                this.activeModal.close(this.acc);
            });
        }
    }

    get isDeletedCode(): boolean {
        return (this.deleteErrorCodeList.find(x => x.ID == this.errorId) != null ? true : false);
    }

    get isOldDeletedCode(): boolean {
        return (this.deleteErrorCodeList.find(x => x.ID == this.existingErrorId) != null ? true : false);
    }

    get isErrorCode(): boolean {
        return (this.errorCodeList.find(x => x.ID == this.errorId) != null ? true : false);
    }

    get isOldErrorCode(): boolean {
        return (this.errorCodeList.find(x => x.ID == this.existingErrorId) != null ? true : false);
    }

    deleteCase() {
        if (this.errorId == -1) {
            this.message = 'Select Par Error.'
            this.openMessage().toPromise();
        }
        else if (this.isErrorCode) {
            this.message = 'Please note that setting the PAR Error to \"'+ this.getDescription +'\" will not delete the case.  Please click on \"Set PAR Error\" button to update the PAR';
            this.openMessage().toPromise();
        } else {
            this._utilService.UpdateCrssCase(this.acc.AccID, this.errorId, this.isUnDeleteMode, this.intParId_Duplicate).toPromise().then(
                ((result: Acc) => {
                    this.acc = result;
                    this.activeModal.close(this.acc);
                }).bind(this),
                ((objError: any) => {
                    this._modalService.setMessage(objError, 'danger');
                }).bind(this));
        }
    }

    undeleteCase() {
        if (this.errorId == -1) {
            this.message = 'Select Par Error.'
            this.openMessage().toPromise();
        }
        else if (this.isDeletedCode) {
            this.message = 'Please note that setting the PAR Error to \"' + this.getDescription + '\" will not undelete the case.  Please click on \"Set PAR Error\" button to update the PAR';
            this.openMessage().toPromise();
        } else {
            this._utilService.UpdateCrssCase(this.acc.AccID, this.errorId, this.isUnDeleteMode, this.intParId_Duplicate).toPromise().then(result => {
                this.acc = result;
                this.activeModal.close(this.acc);
            });
        }
    }

    openMessage() {
        this.innerPopup = this.localModal.open(this.alertMessage, { size: 'sm', centered: true });
        return from(this.innerPopup.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }

    get getDescription(): string {
        const def = this.parErrorCodeList.find(x => x.ID == this.errorId).DEF
        if (def == null || def == undefined) return '';
        return def;
    }
}
