import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//services
import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';

//helper
import { BaseComponent } from 'src/app/helper/basecomponent';

//pipes
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';

//models
import { Acc } from 'src/app/models/acc';

//basecomponents
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

@Component({
    selector: 'app-crash-notes',
    templateUrl: './crash-notes.component.html',
    styleUrls: ['./crash-notes.component.css']
})

export class CrashNotesComponent extends BaseComponent implements OnInit, AfterViewInit {

    accid: number;
    stateNum: number;

    acc: Acc;
  
    isBtnAvaiable: boolean;
    isShowSCCWarning: boolean = false;
    isShowSCCSuccess: boolean = false;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    )    {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngOnInit() {
        this._route.parent.params.subscribe((params => {
            this.accid = + params['crashid'];

          

        }).bind(this));       
    }

    ChangeCircumstances($event) {
        if ($event) {
            this.isBtnAvaiable = true;
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
}
