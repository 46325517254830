import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from '../../../services/case.service';
import { UtilService } from '../../../services/util.service';

import { Acc } from '../../../models/acc';
import { Non_Occupants } from '../../../models/non-occupants';
import { NonOcc_Ped } from './../../../models/non-occ-ped';


import { Element_Specify } from '../../../models/element-specify';
import { TableFieldElements } from '../../../models/table-field-elements';
import { DrpDownOptions } from '../../../models/drp-down-options';
import { FormName, TableId } from '../../../models/enums/app.enums';

import { TypeaheadComponent } from '../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { take } from 'rxjs/operators';
import { ConcatSource } from 'webpack-sources';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { RBISWizardResults } from 'src/app/models/RBISWizardResults';
import { NgForm } from '@angular/forms';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { PerBikeWizard } from 'src/app/models/pedbikewizard';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Subscription } from 'rxjs';



@Component({
    selector: 'app-non-occupant-pedestrian',
    templateUrl: './non-occupant-pedestrian.component.html',
    styleUrls: ['./non-occupant-pedestrian.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NonOccupantPedestrianComponent extends BaseComponent implements OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_NonOccupantPedestrianComponent: string = null;
    private sbsNonOccId: Subscription;

    accid: number;
    nonOccid: number;
    intMode: number = 1;
    acc: Acc;
    nonOccupant: Non_Occupants;
    pedestrian: NonOcc_Ped = { _TypeScript_TypeGuard_NonOcc_Ped: null } as NonOcc_Ped;

    crashGroupPed: number;
    crashGroupExpended: string = '';
    crashTypeExpended: string = '';

    noCopyAvailable: boolean = true;
    allExisitingPedList: Array<NonOcc_Ped> = [];
    wizardOptions: Array<PerBikeWizard> = [];
    selectedWizardList: Array<PerBikeWizard> = [];
    @ViewChild('fnonOccPedestrian') objForm: NgForm;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;

    @ViewChild('CrashType') _crashType: TypeaheadComponent;
    @ViewChild('CrashGroup') _crashGroup: TypeaheadComponent;
    @ViewChild('CrashLocation') _crashLocation: TypeaheadComponent;
    @ViewChild('PedPosition') _pedPosition: TypeaheadComponent;
    @ViewChild('PedDirection') _pedDirection: TypeaheadComponent;
    @ViewChild('MotDirection') _motDirection: TypeaheadComponent;
    @ViewChild('MotManeuver') _motManeuver: TypeaheadComponent;
    @ViewChild('LegIntersection') _legIntersection: TypeaheadComponent;
    @ViewChild('Scenario') _scenario: TypeaheadComponent;

    @ViewChild('schoolZone') _schoolZone: TypeaheadComponent;
    @ViewChild('crosswalk') _crosswalk: TypeaheadComponent;
    @ViewChild('sidewalk') _sidewalk: TypeaheadComponent;

    data: Acc;
    hasWizardFilled: boolean = false;
    fireOnlyAfterLoad: boolean = true;
    filteredPedLookupData: Array<DrpDownOptions> = [];
    tableToFilter = [
        'PBCAT_PedCrashLocation',
        'PBCAT_PedPosition',
        'PBCAT_PedMotDirection',
        'PBCAT_PedMotManeuver',
        'PBCAT_PedLegIntersection',
        'PBCAT_PedScenario',
        'PBCAT_PedDirection',
        'PBCAT_PedCrashType',
        'PBCAT_SchoolZone',
        'PBCAT_CrossWalk',
        'PBCAT_SideWalk'];

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        let hasInvalidaEntry = false;
        this.blnAlloweSave = false;

        if (!this._pedPosition.disabled && this.nonOccupant.NonOcc_Ped.PedPosition == -1) {
            this._pedPosition.setIsInvalidClass(true);
            hasInvalidaEntry = true;
        } else {
            this._pedPosition.setIsInvalidClass(false);
        }

        if (!this._motDirection.disabled && this.nonOccupant.NonOcc_Ped.MotDirection == -1) {
            this._motDirection.setIsInvalidClass(true);
            hasInvalidaEntry = true;
        } else {
            this._motDirection.setIsInvalidClass(false);
        }


        if (!this._motManeuver.disabled && this.nonOccupant.NonOcc_Ped.MotManeuver == -1) {
            this._motManeuver.setIsInvalidClass(true);
            hasInvalidaEntry = true;
        } else {
            this._motManeuver.setIsInvalidClass(false);
        }


        if (!this._legIntersection.disabled && this.nonOccupant.NonOcc_Ped.LegIntersection == -1) {
            this._legIntersection.setIsInvalidClass(true);
            hasInvalidaEntry = true;
        } else {
            this._legIntersection.setIsInvalidClass(false);
        }

        if (!this._scenario.disabled && (this.nonOccupant.NonOcc_Ped.Scenario == '' || this.nonOccupant.NonOcc_Ped.Scenario == '-1')) {
            this._scenario.setIsInvalidClass(true);
            hasInvalidaEntry = true;
        } else {
            this._scenario.setIsInvalidClass(false);
        }

        if (hasInvalidaEntry) {
            this._modalService.setMessage('The highlighted fields cannot be left blank.', 'M');
        } else {
            this._modalService.resetMessage();
            this.blnAlloweSave = true;
        } 
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    clearInvalidCss() {
        this._scenario.setIsInvalidClass(false);
        this._legIntersection.setIsInvalidClass(false);
        this._motManeuver.setIsInvalidClass(false);
        this._motDirection.setIsInvalidClass(false);
        this._pedPosition.setIsInvalidClass(false);
    }
    async ngOnInit() {
        super.ngOnInit();

        this.wizardOptions = await this._utilService.getWizardOptions('ped');
        this.loadFormData();

        if (this.printNonOccupantNum == 0) {
            this._route.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
        }
        else {
            this.accid = this.acc.AccID;
            
        }

        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();

            });
        }
        else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
           
           // this.loadCrashGroup();
        }
    }

    FormLoad() {
        this.data = this.acc;

        this.nonOccupant = this.data.Non_Occupants.find(v => v.PNumber == this.nonOccid);
        // this.crashGroupPed = -1;
        this.getPedWizardJSON();
       // if(this.printNonOccupantNum == 0) this.loadCrashGroup();
        
        if (isNaN(this.nonOccupant.NonOcc_Ped.CrashGroup)) {
            this.nonOccupant.NonOcc_Ped.CrashGroup = null;
            //  this._crashGroup.setCurrentValue("");
        }
        //Setting empty Peddestrian after landing to the page
        this.setEmptyPedestrian(this.nonOccupant);

        this.enableDisableFiledAfterLoad();

        setTimeout(() => {
            this.assignCrashGroupAndCrashTypeExpended()
        }, 50);

        this.listAvailableExistingPedInfo();
        this.intMode = this.acc.Mode;
    }

    loadCrashGroup() {

        if (this.nonOccupant.NonOcc_Ped == null) {
            this.nonOccupant.NonOcc_Ped = this.pedestrian;
            this.nonOccupant.NonOcc_Ped.AccID = this.accid;
            this.nonOccupant.NonOcc_Ped.PNumber = this.nonOccid;
        } else {
            if (this.nonOccupant.NonOcc_Ped.CrashType) {
                const _crashGroupNumber = this.wizardOptions.find(x => x.Value == this.nonOccupant.NonOcc_Ped.CrashType.toString())
                if (_crashGroupNumber != null) {
                    console.log('this.nonOccupant.NonOcc_Ped.CrashGroup', this.nonOccupant.NonOcc_Ped.CrashGroup)
                    this.nonOccupant.NonOcc_Ped.CrashGroup = parseInt(_crashGroupNumber.GroupValue);
                }
            } else {
                this.nonOccupant.NonOcc_Ped.CrashGroup = null;
            }
        }
    }

    setPedWizardJSON(result: any, fromCopy: boolean = false) {
        let findOldWizard = this.data.RBISWizardResults.find(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
        if (findOldWizard == undefined || findOldWizard == null) {
            let rBISWizardResults: RBISWizardResults = {} as RBISWizardResults;
            rBISWizardResults._TypeScript_TypeGuard_RBISWizardResults = null;
            rBISWizardResults.ACCID = this.accid;
            rBISWizardResults.PNUMBER = this.nonOccid;
            rBISWizardResults.JSONOBJ = fromCopy ? result : JSON.stringify(result);
            rBISWizardResults.WizardType = "Ped"
            this.data.RBISWizardResults.push(rBISWizardResults)
        } else {
            let index = this.data.RBISWizardResults.findIndex(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
            findOldWizard.JSONOBJ = fromCopy ? result : JSON.stringify(result);
            this.data.RBISWizardResults[index] = findOldWizard;
        }
        this.getPedWizardJSON();
    }

    getPedWizardJSON() {
        let findOldWizard = this.data.RBISWizardResults.find(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
        if (findOldWizard && findOldWizard.JSONOBJ) {
            if (this.nonOccupant.NonOcc_Ped && this.nonOccupant.NonOcc_Ped.CrashType > 0) {
                this.hasWizardFilled = true;
                this.selectedWizardList = JSON.parse(findOldWizard.JSONOBJ);
            } else {
                const index = this.data.RBISWizardResults.findIndex(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
                this.data.RBISWizardResults.splice(index, 1);
            }
        } else {
            this.selectedWizardList = [];
            this.hasWizardFilled = false;
        }
    }

    enableDisableFiledAfterLoad() {
        if (this.nonOccupant == undefined || this.nonOccupant == null) return;
        if (this.nonOccupant.NonOcc_Ped == undefined || this.nonOccupant.NonOcc_Ped == null || this.nonOccupant.NonOcc_Ped.CrashLocation == -1) return;

        setTimeout(() => {
            this.checkEnableCondition(this.nonOccupant.NonOcc_Ped.CrashLocation, this.nonOccupant.NonOcc_Ped.MotDirection, this.nonOccupant.NonOcc_Ped.MotManeuver, this.nonOccupant.NonOcc_Ped.LegIntersection);
        }, 50)

    }

    setEmptyPedestrian(nonOccupant) {
        if ((nonOccupant.PType === 5 || nonOccupant.PType === 8) && !nonOccupant.NonOcc_Ped) {
            this.nonOccupant.NonOcc_Ped = this.pedestrian;
        }
        console.log("Pedestrian Empty Object: ", this.nonOccupant.NonOcc_Ped);
    }

    ngOnDestroy() {
        if (this.sbsNonOccId)
            this.sbsNonOccId.unsubscribe();
    }

    onPedWizardClick($event: any) {

        this._modalService_CannedPopups.wizard('ped', this.selectedWizardList).pipe(
            take(1)
        ).subscribe(result => {
            if (result != null) this.selectedWizardList = result;
            this.fireOnlyAfterLoad = false;
            this.setValueInPedTextField(result);
            this.clearInvalidCss();
            this.fireOnlyAfterLoad = true;

        })
    }

    async onCopyData(content) {
        const result = await this._modalService_CannedPopups.copyExistingPedestrianData(this.allExisitingPedList, this.filteredPedLookupData).toPromise();
        if (result != 'undefined' && result != null) {
            this.clearOldValue();
            this.copyExistingItem(result);
            this.objForm.form.markAsDirty();
        }
    }

    listAvailableExistingPedInfo() {

        if (this.data == null) return;
        this.allExisitingPedList = [];
        this.data.Non_Occupants.forEach((item) => {
            if (item.NonOcc_Ped != null && Object.keys(item.NonOcc_Ped).length != 0 && item.NonOcc_Ped.PNumber != undefined && item.NonOcc_Ped.PNumber != this.nonOccid) {
                this.allExisitingPedList.push(item.NonOcc_Ped);
            }
        })

    }

    async copyExistingItem(item: NonOcc_Ped) {
        this.nonOccupant.NonOcc_Ped.PNumber = this.nonOccid;
        this.nonOccupant.NonOcc_Ped.CrashLocation = item.CrashLocation;
        this.nonOccupant.NonOcc_Ped.PedPosition = item.PedPosition;
        this.nonOccupant.NonOcc_Ped.MotDirection = item.MotDirection;
        this.nonOccupant.NonOcc_Ped.MotManeuver = item.MotManeuver;
        this.nonOccupant.NonOcc_Ped.LegIntersection = item.LegIntersection;
        this.nonOccupant.NonOcc_Ped.CrashType = item.CrashType;
        this.nonOccupant.NonOcc_Ped.Scenario = item.Scenario;

        this.nonOccupant.NonOcc_Ped.CrashGroup = item.CrashGroup;

        this.nonOccupant.NonOcc_Ped.Crosswalk = item.Crosswalk;
        this.nonOccupant.NonOcc_Ped.SchoolZone = item.SchoolZone;
        this.nonOccupant.NonOcc_Ped.Sidewalk = item.Sidewalk;

        this._crosswalk.setCurrentValue(this.nonOccupant.NonOcc_Ped.Crosswalk.toString());
        this._schoolZone.setCurrentValue(this.nonOccupant.NonOcc_Ped.SchoolZone.toString());
        this._sidewalk.setCurrentValue(this.nonOccupant.NonOcc_Ped.Sidewalk.toString());

        this.loadCrashGroup();
        this.assignCrashGroupAndCrashTypeExpended();

        //this.nonOccupant.NonOcc_Ped.PedDirection = this.CalculatePedDirection(this.nonOccupant.NonOcc_Ped.Scenario, this.nonOccupant.NonOcc_Ped.MotDirection,
          //  this.nonOccupant.NonOcc_Ped.MotManeuver, this.nonOccupant.NonOcc_Ped.LegIntersection);

        await this.refreshScenario();

        this.setData();
        let _oldWizard = this.data.RBISWizardResults.find(x => x.ACCID == this.accid && x.PNUMBER == item.PNumber);
        if (_oldWizard) {
            this.setPedWizardJSON(_oldWizard.JSONOBJ, true);
        }
        this.enableDisableFiledAfterLoad();
    }

    clearOldValue() {

        this.nonOccupant.NonOcc_Ped.CrashLocation = -1;
        this.nonOccupant.NonOcc_Ped.PedPosition = -1;
        this.nonOccupant.NonOcc_Ped.MotDirection = -1;
        this.nonOccupant.NonOcc_Ped.MotManeuver = -1;
        this.nonOccupant.NonOcc_Ped.LegIntersection = -1;
        this.nonOccupant.NonOcc_Ped.CrashType = -1;
        this.nonOccupant.NonOcc_Ped.Scenario = "";
        this.nonOccupant.NonOcc_Ped.CrashGroup = -1;
        this.nonOccupant.NonOcc_Ped.PedDirection = -1
        this.crashTypeExpended = "";

        this.setData();
        this.enabledField(this._crashLocation, true);
        this.enabledField(this._pedPosition, true);
        this.enabledField(this._motManeuver, true);
        this.enabledField(this._scenario, true);
        this.enabledField(this._legIntersection, true);
        this.enabledField(this._motDirection, true);
        this.enabledField(this._pedDirection, true);
        this._scenario.setCurrentValue(this.nonOccupant.NonOcc_Ped.Scenario.toString());
    }

    async setValueInPedTextField(result: any) {

        if (result == null) return;
        this.clearOldValue();
        let backupItems: Array<any> = [];
        result.forEach(((item) => {

            backupItems.push({ label: item.Label, value: item.Value, imageUrl: item.ImageUrl })
            //   console.log(item.ScreenTitle +' ' + item.value);
            switch (item.ScreenTitle) {
                case "crash_location":
                    this.nonOccupant.NonOcc_Ped.CrashLocation = parseInt(item.Value);
                    break;
                case "ped_position":
                    this.nonOccupant.NonOcc_Ped.PedPosition = parseInt(item.Value);
                    break;
                case "motorist_initial":
                    console.log('motorist_initial', item.Value)
                    this.nonOccupant.NonOcc_Ped.MotDirection = parseInt(item.Value);
                    break;
                case "motorist_maneuver":
                    console.log('Maneuver ', item.Value);
                    this.nonOccupant.NonOcc_Ped.MotManeuver = parseInt(item.Value);
                    break;
                case "leg_intersection":
                    this.nonOccupant.NonOcc_Ped.LegIntersection = parseInt(item.Value);

                    break;
                case "crash_type":
                    console.log('crash type', item.Value);
                    this.nonOccupant.NonOcc_Ped.CrashType = item.Value;

                    if (item.GroupValue != null && item.GroupValue != '') {
                        this.nonOccupant.NonOcc_Ped.CrashGroup = parseInt(item.GroupValue);
                        this.crashGroupPed = item.GroupValue
                    } //setting up group associated that crash type
                    break;
                case "scenario":
                    console.log(item.Value + ' scenario');

                    this.nonOccupant.NonOcc_Ped.Scenario = item.Value.toString().trim();
                    break;
            }
        }).bind(this));

       // if (this.nonOccupant.NonOcc_Ped.LegIntersection == 9 || this.nonOccupant.NonOcc_Ped.MotManeuver == 9) {
            // this._scenario.setCurrentValue("99");
         //   this.nonOccupant.NonOcc_Ped.Scenario = "99";
       // }
        await this.refreshScenario();

        //crash group and group type expended

        this.assignCrashGroupAndCrashTypeExpended();


        //finding the right Ped Direction based on the various values this logic is written based on the old


        this.nonOccupant.NonOcc_Ped.PedDirection = this.CalculatePedDirection(this.nonOccupant.NonOcc_Ped.Scenario, this.nonOccupant.NonOcc_Ped.MotDirection,
            this.nonOccupant.NonOcc_Ped.MotManeuver, this.nonOccupant.NonOcc_Ped.LegIntersection);
        this.setData();
        this.checkEnableCondition(this.nonOccupant.NonOcc_Ped.CrashLocation, this.nonOccupant.NonOcc_Ped.MotDirection, this.nonOccupant.NonOcc_Ped.MotManeuver, this.nonOccupant.NonOcc_Ped.LegIntersection);
        this.setPedWizardJSON(result)

        this.objForm.form.markAsDirty();
    }

    setData() {
        this._crashType.setCurrentValue(this.nonOccupant.NonOcc_Ped.CrashType.toString());
        this._crashGroup.setCurrentValue(this.nonOccupant.NonOcc_Ped.CrashGroup.toString());
        this._crashLocation.setCurrentValue(this.nonOccupant.NonOcc_Ped.CrashLocation.toString());
        this._pedPosition.setCurrentValue(this.nonOccupant.NonOcc_Ped.PedPosition.toString());
        this._pedDirection.setCurrentValue(this.nonOccupant.NonOcc_Ped.PedDirection.toString());
        this._motDirection.setCurrentValue(this.nonOccupant.NonOcc_Ped.MotDirection.toString());
        this._motManeuver.setCurrentValue(this.nonOccupant.NonOcc_Ped.MotManeuver.toString());
        this._legIntersection.setCurrentValue(this.nonOccupant.NonOcc_Ped.LegIntersection.toString());
        if (this.nonOccupant.NonOcc_Ped.Scenario) this._scenario.setCurrentValue(this.nonOccupant.NonOcc_Ped.Scenario.toString());
    }

    assignCrashGroupAndCrashTypeExpended() {
        if (this.nonOccupant.NonOcc_Ped == null) return;
        if (this.nonOccupant.NonOcc_Ped.PedPosition == null && this.nonOccupant.NonOcc_Ped.CrashLocation == null) return;
        let pedPostion: number = parseInt(this.nonOccupant.NonOcc_Ped.PedPosition.toString());
        this.crashGroupExpended = this.nonOccupant.NonOcc_Ped.CrashLocation + '' + pedPostion + '' + this.nonOccupant.NonOcc_Ped.CrashGroup;
        this.crashTypeExpended = this.nonOccupant.NonOcc_Ped.CrashLocation + '' + pedPostion + '' + this.nonOccupant.NonOcc_Ped.CrashType;

        if (this.crashGroupExpended.includes('-')) this.crashGroupExpended = '';
        if (this.crashTypeExpended.includes('-')) this.crashTypeExpended = '';
    }

    CalculatePedDirection(scenerio: any, motDirection: number, motManeuver: number, legIntersection: number): number {
        let pedDirection: number = 0;
        let appYear: number = 2020;
        this._sharedDataService.GetAppSettings().then(c => {
            appYear = c.intYear
        }); //need to changed based on the application year setting.
        if (scenerio == undefined) scenerio = '';
        console.log('mot position: ' + motDirection + ' leg Inserction ' + legIntersection + ' Scenerio :' + scenerio + ' Mot Maneuever : ' + motManeuver);
        if (scenerio.charAt(scenerio.length - 1) == 'c' || scenerio.charAt(scenerio.length - 1) == 'd' || scenerio == '99') return pedDirection = 9;

        if (appYear >= 2018 && motDirection == 9) return pedDirection = 9;

        if (motManeuver == 1 && legIntersection == 2) {
            if (scenerio == '11a' || scenerio == '12a') {
                return pedDirection = motDirection
            }
            else if (scenerio == '11b' || scenerio == '12b') {
                if (motDirection == 1) {
                    pedDirection = 3;
                }
                else if (motDirection == 2) {
                    pedDirection = 4;
                }
                else if (motDirection == 3) {
                    pedDirection = 1;
                }
                else if (motDirection == 4) {
                    pedDirection = 2;
                }
            }
        }
        else if (motManeuver == 1 && legIntersection == 1) {

            if (scenerio == '9a' || scenerio == '10a') {
                if (motDirection == 1) {
                    pedDirection = 2;
                }
                else if (motDirection == 2) {
                    pedDirection = 3;
                }
                else if (motDirection == 3) {
                    pedDirection = 4;
                }
                else if (motDirection == 4) {
                    pedDirection = 1;
                }
            }
            else if (scenerio == '9b' || scenerio == '10b') {
                if (motDirection == 1) {
                    pedDirection = 4;
                }
                else if (motDirection == 2) {
                    pedDirection = 1;
                }
                else if (motDirection == 3) {
                    pedDirection = 2;
                }
                else if (motDirection == 4) {
                    pedDirection = 3;
                }

            }
        }
        else if (motManeuver == 2 && legIntersection == 2) {
            if (scenerio == '7a' || scenerio == '8a') {
                pedDirection = motDirection;
            }
            else if (scenerio == '7b' || scenerio == '8b') {
                if (motDirection == 1) {
                    pedDirection = 3;
                }
                else if (motDirection == 2) {
                    pedDirection = 4;
                }
                else if (motDirection == 3) {
                    pedDirection = 1;
                }
                else if (motDirection == 4) {
                    pedDirection = 2;
                }
            }
        }
        else if (motManeuver == 2 && legIntersection == 1) {

            if (scenerio == '5a' || scenerio == '6a') {
                if (motDirection == 1) {
                    pedDirection = 2;
                }
                else if (motDirection == 2) {
                    pedDirection = 3;
                }
                else if (motDirection == 3) {
                    pedDirection = 4;
                }
                else if (motDirection == 4) {
                    pedDirection = 1;
                }
            }
            else if (scenerio == '5b' || scenerio == '6b') {
                if (motDirection == 1) {
                    pedDirection = 4;
                }
                else if (motDirection == 2) {
                    pedDirection = 1;
                }
                else if (motDirection == 3) {
                    pedDirection = 2;
                }
                else if (motDirection == 4) {
                    pedDirection = 3;
                }
            }
        }
        else if (motManeuver == 3 && legIntersection == 2) {

            if (scenerio == '3a' || scenerio == '4a') {
                if (motDirection == 1) {
                    pedDirection = 2;
                }
                else if (motDirection == 2) {
                    pedDirection = 3;
                }
                else if (motDirection == 3) {
                    pedDirection = 4;
                }
                else if (motDirection == 4) {
                    pedDirection = 1;
                }
            }
            else if (scenerio == '3b' || scenerio == '4b') {
                if (motDirection == 1) {
                    pedDirection = 4;
                }
                else if (motDirection == 2) {
                    pedDirection = 1;
                }
                else if (motDirection == 3) {
                    pedDirection = 2;
                }
                else if (motDirection == 4) {
                    pedDirection = 3;
                }

            }
        }
        else if (motManeuver == 3 && legIntersection == 1) {

            if (scenerio == '1a' || scenerio == '2a') {
                if (motDirection == 1) {
                    pedDirection = 2;
                }
                else if (motDirection == 2) {
                    pedDirection = 3;
                }
                else if (motDirection == 3) {
                    pedDirection = 4;
                }
                else if (motDirection == 4) {
                    pedDirection = 1;
                }
            }
            else if (scenerio == '1b' || scenerio == '2b') {
                if (motDirection == 1) {
                    pedDirection = 4;
                }
                else if (motDirection == 2) {
                    pedDirection = 1;
                }
                else if (motDirection == 3) {
                    pedDirection = 2;
                }
                else if (motDirection == 4) {
                    pedDirection = 3;
                }

            }
        }
        this.nonOccupant.NonOcc_Ped.PedDirection = pedDirection;
        console.log(pedDirection + ' ped postion selected');
        return pedDirection;
    }

    checkEnableCondition(crashLocation: number, motDirection: any, motManeuver: any, legIntersection: any) {

        console.log('crashLocation', crashLocation)
        if (crashLocation == undefined || motDirection == undefined || motManeuver == undefined || legIntersection == undefined) return false;
        // if (crashLocation == -1 || motDirection == -1 || motManeuver == -1 || legIntersection == -1) return false;
        this.enabledField(this._pedPosition);

        if (crashLocation != 3 && crashLocation != 4 && crashLocation != 9) {
            this.enabledField(this._motManeuver);
            this.enabledField(this._legIntersection);
            this.enabledField(this._motDirection);
        }

        if ((crashLocation == 3) || (crashLocation == 4) || (crashLocation == 9) || (motDirection == 9) || (motManeuver == 9) || (legIntersection == 9)) {

            if ((motManeuver == 9) || (legIntersection == 9)) {

                this.enabledField(this._legIntersection);
            } else {
                if (crashLocation == 1 || crashLocation == 2) {
                    this.enabledField(this._legIntersection);
                    this.enabledField(this._scenario);
                } else {
                    this.enabledField(this._legIntersection, true);
                    this.enabledField(this._scenario, true);
                }
            }
        } else {
            this.enabledField(this._motManeuver);
            this.enabledField(this._legIntersection);
            this.enabledField(this._scenario);
        }
    }

    enabledField(fieldName: TypeaheadComponent, disable: boolean = false) {
        fieldName.disableOrEnableComponent(disable)
    }

    handleKeyup(event: KeyboardEvent) {
        if (event.code == "F9" || event.code == "Minus") {
            document.getElementById("btnPedestrian_Copy").click();
        }
    }

    /**
     * Creates subscription for DEFS data and when DEFS data is available, populates this.filteredBikeLookupData
     **/
    loadFormData() {
        if (this.filteredPedLookupData.length > 0) //TODO: remove this, if we don't need to refresh DEFS data, just don't call this function
            return;

          this._utilService.formDrpDownOptionShare.subscribe(((arrOptionsAll: DrpDownOptions[]) => {
            this.filteredPedLookupData = [];
            if (arrOptionsAll) {
                arrOptionsAll = arrOptionsAll.filter(x => this.tableToFilter.includes(x.tblName));
                arrOptionsAll.forEach(item => {
                    this.filteredPedLookupData.push(item);
                });
            }
        }).bind(this)).unsubscribe();
    }

    fieldValueChange(field, event, obj) {
        if (!this.fireOnlyAfterLoad) return;
        if (event.strValue != '-1' && this.wizardOptions.length > 0 && this.data.RBISWizardResults.length > 0) {
            this.findArraAndSetValue(field, event)
            this.assignCrashGroupAndCrashTypeExpended();
            this.nonOccupant.NonOcc_Ped.PedDirection = this.CalculatePedDirection(this.nonOccupant.NonOcc_Ped.Scenario, this.nonOccupant.NonOcc_Ped.MotDirection,
                this.nonOccupant.NonOcc_Ped.MotManeuver, this.nonOccupant.NonOcc_Ped.LegIntersection);

            if (field == 'MotManeuver' || field == 'LegIntersection') {
                this.bindMotoristManeuverOrLegIntersection(event);
            }

            this.checkEnableCondition(this.nonOccupant.NonOcc_Ped.CrashLocation, this.nonOccupant.NonOcc_Ped.MotDirection, this.nonOccupant.NonOcc_Ped.MotManeuver, this.nonOccupant.NonOcc_Ped.LegIntersection);

        }
    }

    findArraAndSetValue(field, event) {
        let selectedOption: any = null;
        let value = event.strValue;
        let wizardIndex = this.data.RBISWizardResults.findIndex(x => x.ACCID == this.accid && x.PNUMBER == this.nonOccid);
        if (field == 'MotManeuver') {
            selectedOption = this.wizardOptions.find(x => x.Value == value && x.ScreenTitle == 'motorist_maneuver')
            const postionIndex = this.selectedWizardList.findIndex(x => x.ScreenTitle == 'motorist_maneuver');
            this.selectedWizardList[postionIndex] = selectedOption;
            this.data.RBISWizardResults[wizardIndex].JSONOBJ = JSON.stringify(this.selectedWizardList);
        }
        if (field == 'Scenario') {
            selectedOption = this.wizardOptions.find(x => x.Value == value && x.ScreenTitle == 'scenario')
            const postionIndex = this.selectedWizardList.findIndex(x => x.ScreenTitle == 'scenario');
            this.selectedWizardList[postionIndex] = selectedOption;
            this.data.RBISWizardResults[wizardIndex].JSONOBJ = JSON.stringify(this.selectedWizardList);
        }
        if (field == 'LegIntersection') {
            selectedOption = this.wizardOptions.find(x => x.Value == value && x.ScreenTitle == 'leg_intersection')
            const postionIndex = this.selectedWizardList.findIndex(x => x.ScreenTitle == 'leg_intersection');
            this.selectedWizardList[postionIndex] = selectedOption;
            this.data.RBISWizardResults[wizardIndex].JSONOBJ = JSON.stringify(this.selectedWizardList);
        }

        if (field == 'PedPosition') {
            value = '0' + value
            selectedOption = this.wizardOptions.find(x => x.Value == value && x.ScreenTitle == 'ped_position')
            const postionIndex = this.selectedWizardList.findIndex(x => x.ScreenTitle == 'ped_position');
            this.selectedWizardList[postionIndex] = selectedOption;
            this.data.RBISWizardResults[wizardIndex].JSONOBJ = JSON.stringify(this.selectedWizardList);
        }

        if (field == 'MotDirection') {
            selectedOption = this.wizardOptions.find(x => x.Value == value && x.ScreenTitle == 'motorist_initial')
            const postionIndex = this.selectedWizardList.findIndex(x => x.ScreenTitle == 'motorist_initial');
            this.selectedWizardList[postionIndex] = selectedOption;
            this.data.RBISWizardResults[wizardIndex].JSONOBJ = JSON.stringify(this.selectedWizardList);
        }
    }

    async bindMotoristManeuverOrLegIntersection(event) {

        if (this.nonOccupant.NonOcc_Ped.MotManeuver == 9 || this.nonOccupant.NonOcc_Ped.LegIntersection == 9) {
            await this.refreshScenario();
            this.nonOccupant.NonOcc_Ped.Scenario = "99";
            this.enabledField(this._scenario, true);
            this._scenario.setCurrentValue(this.nonOccupant.NonOcc_Ped.Scenario);

        } else {

            if (this._scenario._arrOptionsPristine == undefined) {
                await this.refreshScenario();
            } else {
                let checkScenario = this._scenario._arrOptionsPristine.find(x => x.strValue == this.nonOccupant.NonOcc_Ped.Scenario);
                if (!checkScenario) {
                    await this.setEmptyScenarioMode();
                }
            }
        }
    }

    async setEmptyScenarioMode() {
        this.nonOccupant.NonOcc_Ped.Scenario = "-1";
        await this.refreshScenario();
        this.enabledField(this._scenario);
        if (this._scenario._arrOptionsPristine) this._scenario.setCurrentValue(this.nonOccupant.NonOcc_Ped.Scenario);
    }

    async refreshScenario() {
        if (this._scenario && this._legIntersection) {
            this._scenario.strDependentFieldName = 'LegIntersection';
            this._scenario.strFilterCondition = ' AND MotManID = ' + this.nonOccupant.NonOcc_Ped.MotManeuver + ' AND LegInterID = '; //it has depadent fields so value will be of dependends fields.

            if (this.nonOccupant.NonOcc_Ped.LegIntersection == 9 || this.nonOccupant.NonOcc_Ped.MotManeuver == 9) {
                if (this.nonOccupant.NonOcc_Ped.MotManeuver == 9) {
                    this._scenario.strDependentFieldName = 'MotManeuver';
                    this._scenario.strFilterCondition = ' AND LegInterID = 9 AND MotManID = '; //it has depadent fields so value will be of dependends fields.
                } else {
                    this._scenario.strFilterCondition = ' AND MotManID = 9 AND LegInterID = '; //it has depadent fields so value will be of dependends fields.
                }
            }
            await this._scenario.FilterFieldOptions(); //Refresh the typeahead for the new options
            //   this._scenario.setCurrentValue(this.nonOccupant.NonOcc_Ped.Scenario);
        }
    }

}
