import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CaseService } from 'src/app/services/case.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { Cases_Locked } from 'src/app/models/cases-locked';
import { ModalService } from 'src/app/services/modal.service';
import { HomeService } from 'src/app/services/home.service';
import { UtilService } from 'src/app/services/util.service';

import { CaseLockedViewModel } from 'src/app/interface/CaseLockedViewModel';
import { concat } from 'rxjs';
import { CustomNvpFilter, States, DBMode } from 'src/app/models/enums/app.enums';
import { DrpDownOptions } from 'src/app/models/drp-down-options';

@Component({
    selector: 'app-releaserecord',
    templateUrl: './releaserecord.component.html',
    styleUrls: ['./releaserecord.component.css']
})
export class ReleaserecordComponent implements OnInit {

    locked_case: Array<CaseLockedViewModel> = [];
    tmp_case: Array<CaseLockedViewModel> = [];
    hasReleaseRights: boolean = false;

    stateNum: number;

    //Moh: 04-22-2020 Task: 8681
    seletedStatus: number = 2;
    hasAdminRights: false;
    selectedMode: number;
    public arrStates: DrpDownOptions[];
    public blnStateDropDownVisible: boolean = false;
    ddoSelectedState: DrpDownOptions;
    SelectedStateNum: number;
    BlankOption: DrpDownOptions = {} as DrpDownOptions;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilService: UtilService,
        public _sharedDataService: SharedDataService,
        private _caseService: CaseService,
        private _modalService: ModalService,
        private _homeService: HomeService,
        public sharedDataService: SharedDataService
    ) {
    }

    public async ngOnInit() {
        let appSettings: AppSettings = await this.sharedDataService.GetAppSettings();
        this.selectedMode = appSettings.intMode;

        this._homeService.hasAdminRigths.subscribe(result => {
            if (result) {
                this.hasAdminRights = result;
                this.seletedStatus = 1;
            }
        });        

        if (this.selectedMode != DBMode.MTSS && this.selectedMode != DBMode.PPSS && this.selectedMode != DBMode.MOSS) {
            this._route.params.subscribe((params => {
                this.stateNum = +params['stateNum'];

                if (this.stateNum) {
                    this.ListofLockedCase(this.stateNum);
                }
            }).bind(this));
        } else {
            this.ListofLockedCase(this.stateNum);
        }
        
    }

    OnStateNumChange($event) {
        this.SelectedStateNum = $event;

        if (this.SelectedStateNum && this.SelectedStateNum != -1 ) {
            this.ListofLockedCase(this.SelectedStateNum );
            this._router.navigate([this.SelectedStateNum, 'releaserecords']);
        }
    }

    ListofLockedCase(stateNum: number) {
        if (this.selectedMode == DBMode.MTSS || this.selectedMode == DBMode.PPSS || this.selectedMode == DBMode.MOSS ) this.stateNum = -1;

        if (this._router.url.includes('releaserecords')) {
            this._caseService.GetAllLockedCase(this.stateNum).subscribe(result => {
                this.locked_case = result;
                console.log(result);
            });
        }     
    }
       
    releaseRecord(item: CaseLockedViewModel, index, selectedMode) {

        this.tmp_case.push(item)
        this._caseService.ReleaseLockedCases(this.tmp_case).then(result => {
            console.log(result);
            if (result.IsValid) {
                if (selectedMode == DBMode.MTSS || this.selectedMode == DBMode.PPSS) {
                    this._modalService.setMessage('Case # ' + this.function_pad(item.CaseNum.toString()) + ' has been released', 'success');
                }
                else if (selectedMode == DBMode.CRSS) {
                    this._modalService.setMessage('PAR ID ' + item.PARID.toString().slice(4, 12) + ' has been released', 'success');
                }
                else if (selectedMode == DBMode.MOSS) {
                    this._modalService.setMessage('Moss case ' + item.MossCaseNum.toString() + ' has been released', 'success');

                }
                this.locked_case.splice(index, 1);
            } else {
                this._modalService.setMessage(result.Message, 'warning')
            }
        });
        this.tmp_case = [];
    }

    function_pad(num: string) {
        let s: string = num;
        let size = 4;
        while (s.length < (size || 2)) { s = "0" + s; }
        return s;
    }
    releaseAllRecord() {

        if (this.locked_case.length == 0) return;

        this._caseService.ReleaseLockedCases(this.locked_case).then(result => {
            if (result.IsValid) {
                if (this.selectedMode == DBMode.CRSS) {
                    this._modalService.setMessage('All locked CRSS Records are released.', 'success');
                } else {
                    this._modalService.setMessage('All locked Cases are released.', 'success');
                }
                this.locked_case = [];
            } else {
                this._modalService.setMessage(result.Message, 'warning')
            }
        });
        this.tmp_case = [];
    }

    releaseOwnRecord() {

    }
}
