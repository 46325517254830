<div class="container-fluid">
    <div class="card border-secondary">
        <div class="card-body bg-light margin-border">
            <div class="row">
                <div class="col-10" *ngIf="earlyNotification">
                    <div class="row">
                        <div class="col-12">
                            <div class="card border-dark">
                                <div class="card-header bg-tb-header">
                                    <!--<h5>Case Information</h5>-->
                                    <h5>Delete Case</h5>
                                </div>
                                <div class="card-body bg-light margin-border pb-1">
                                    <div class="form-group">
                                        <p class="mt-1">
                                            Case #:
                                            <span *ngIf="intMode != 256" class="fw-bold">{{acc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : '' }}</span>
                                            <span *ngIf="intMode == 256" class="fw-bold">{{acc.Acc_SS.MOSSCasenum.toString() | replaceTextPipe : ' ' : '' | maxLengthPipe : 12 : ''}}</span>

                                        </p>
                                    </div>
                                    <hr />
                                    <div class="form-group mb-0">
                                        <label for="deletionReason">Please enter the Reason for deletion below:</label>
                                        <textarea class="form-control" id="deletionReason" placeholder="" rows="6"
                                                  (change)="addReasonForDeletion($event)"></textarea>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="card border-dark mt-2">
                                <div class="card-header bg-tb-header">
                                    <h5>Delete Case Confimation</h5>
                                </div>
                                <div class="card-body bg-light margin-border">
                                    <div class="form-group mb-0">
                                        <label for="deletionReason">Please enter the Reason for deletion below:</label>
                                        <textarea class="form-control" id="deletionReason" placeholder="" rows="6"
                                                  (change)="addReasonForDeletion($event)"></textarea>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>

                <div class="col-2">
                    <button class="btn btn-primary border-primary btn-block" id="btncaseDelete_delete" (click)="deleteCase()">
                        Delete
                    </button>
                    <button class="btn btn-light border-secondary btn-block" id="btncaseDelete_backToCase" (click)="backToPreviousPage()">
                        <span class="glyphicon glyphicon-print"></span>Back to Case
                    </button>
                </div>

            </div>
        </div>

    </div>
</div>

