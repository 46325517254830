import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { Response } from 'selenium-webdriver/http';
import { ObjectUtil } from '../helper/objectUtil';
import { EarlyNotify } from '../models/early-notify';
import { Acc } from '../models/acc';
import { CaseOverRiddenRulesReasons } from '../models/case-overridden-rules-reasons';
import { RbisUser } from '../models/rbis-user';
import { CacheLocalStorageService } from './cache-local-storage.service';
import { AttributeMatchLevel } from '../models/attr-match-level';
import { UserClient } from '../models/user-client';

/**
 * This service works in conjunction with src/app/interceptor/api-interceptor.ts, which appends authentication cookie info to the HTTP requests constructed by this service
 **/
@Injectable({
    providedIn: 'root'
})
export class GenericService<T> {

    public actionURL: string;
    private baseURL: string;
    private objParams: HttpParams;
    userlist: Observable<UserClient[]>;

    constructor(
        private _http: HttpClient,
        private _cacheLocal: CacheLocalStorageService,
        @Inject('BASE_URL') _baseURL: string
    ) {
        this.baseURL = _baseURL;
    }

    //#region Observable calls - Cold Observable -- It has to be subscribed to it to start its execution

    /**
     * Expects JSON response type. If passing back raw text, adjust the response type accordingly
     **/
    public getData(): Observable<any> {
        return this._http.get<any>(this.baseURL + this.actionURL);
    }

    public getList(): Observable<T[]> {
        try {
            return this._http.get<T[]>(this.baseURL + this.actionURL);
        } catch (ex) {
            console.error(ex);
            return throwError(ex)
        }
    }

    /**
     * This is actually a PUT request where the uploaded payload is a set of lookup/filtering parameters.
     **/
    public getListByCriteria(item: T): Observable<T[]> {
        try {
            return this._http.put<T[]>(this.baseURL + this.actionURL, item);
        } catch (ex) {
            console.error(ex);
            return throwError(ex)
        }
    }

    /**
     * Alias for updateEntity()
     **/
    public saveEntity(item: T): Observable<T> {
        try {
            return this.updateEntity(item);
        } catch (ex) {
            console.error(ex);
            return throwError(ex)
        }
    }

    public updateEntity(item: T): Observable<T> {

        try {
            let objEN: EarlyNotify = ObjectUtil.FindRoot(item);

            if (objEN)
                ObjectUtil.RemoveBidirectionalLinks(objEN); //Before the HttpClient calls JSON.stringify(), must remove bidirectional links from object
            else
                ObjectUtil.RemoveBidirectionalLinks(item);

            let objClone: T = JSON.parse(JSON.stringify(item)); //Must create clone, otherwise we can't restore bidirectional links until HttpClient has serialized the payload

            if (objEN) //Do not wait for result to restore bidirectional links to allow website to remain responsive while request is pending
                ObjectUtil.RestoreBidirectionalLinks(objEN);
            else
                ObjectUtil.RestoreBidirectionalLinks(item);

            let obsRequestResult = this._http.put<T>(this.baseURL + this.actionURL, objClone);
            return obsRequestResult;
        }
        catch (ex) {
            console.log(ex);
            return throwError(ex)
        }
    }

    //#endregion


    //#region Promise calls

    public getDataPromise(): Promise<T> {
        try {
            return this._http.get<T>(this.baseURL + this.actionURL).toPromise();
        } catch (ex) {
            console.error(ex);
        }

    }
    public getListPromise(): Promise<T[]> {
        try {
            return this._http.get<T[]>(this.baseURL + this.actionURL).toPromise();
        } catch (ex) {
            console.error(ex);
        }

    }

    //#region Util calls

    public DeleteElementSpecify(accID: number, vNumber: number, pNumber: number, tableID: string, fieldID: string, elementValue: number): Observable<T> {
        this.objParams = new HttpParams()
            .set('accID', accID.toString())
            .set('vNumber', vNumber.toString())
            .set('pNumber', pNumber.toString())
            .set('tableID', tableID)
            .set('fieldID', fieldID)
            .set('elementValue', elementValue.toString());
        return this._http.get<T>(this.baseURL + "api/case/DeleteElementSpecify/", { params: this.objParams });
    }

    public GetDrpDownListOptions(strTableName: string, strFilterConditions: string): Observable<T[]> {
        this.objParams = new HttpParams()
            .set('strTableName', strTableName)
            .set('strFilterConditions', strFilterConditions);
        return this._http.get<T[]>(this.baseURL + "api/util/GetDropDownOptions/", { params: this.objParams });
    }

    public GetFormDrpDownListOptions(formName: string): Observable<T[]> {
        this.objParams = new HttpParams()
            .set('formName', formName);
        return this._http.get<T[]>(this.baseURL + "api/util/GetFormDrpDownListOptions/", { params: this.objParams });
    }

    public GetTableFieldElements(tableId: string, form: string): Observable<T[]> {
        this.objParams = new HttpParams()
            .set('tableId', tableId)
            .set('form', form);
        return this._http.get<T[]>(this.baseURL + "api/util/GetTableFieldElement", { params: this.objParams });
    }


    public GetStateEDTStyle(stateNum: number): Observable<T[]> {
        this.objParams = new HttpParams()
            .set('stateNum', stateNum.toString());

        return this._http.get<T[]>(this.baseURL + "api/util/GetStateEDTStyle", { params: this.objParams });
    }

    //public GetStateAllElementsAttributeMatchLevels(stateNum: number, strForeCaching: string = 'false'): Observable<T[]> {
    //    this.objParams = new HttpParams()
    //        .set('stateNum', stateNum.toString())
    //        .set('blnForceRegenerate', strForeCaching);


    public GetUserList(): Observable<UserClient[]> {
        console.log(this.userlist);
        if (!this.userlist) {
            this.userlist = this._http.get<UserClient[]>(this.baseURL + "api/util/GetUserList").pipe(
                map(response => response),
                publishReplay(1), // this tells Rx to cache the latest emitted

                refCount(), // and this tells Rx to keep the Observable alive as long as there are any Subscribers

            );
        }
        console.log(this.userlist)
        return this.userlist;
    }

    public GetUserListByPriv(priv: string): Observable<UserClient[]> {
        const apiUrl = this.baseURL + "api/util/GetUserListByPriv/" + priv;

        return new Observable(observer => {
            const controller = new AbortController();
            const signal = controller.signal;

            fetch(
                `${apiUrl}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    signal
                }
            )
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    observer.error(apiUrl + ' request failed with status code: ' + response.status);
                }
            })
            .then(data => {
                observer.next(data);
                observer.complete();
            })
            .catch(err => {
                console.error('Unable to get data.', err)
                observer.error(err);
            })

            return () => controller.abort()

        });
    }

    public GetAttributeMatchLevelsByForm(stateNum: number, strSubFormName: string, strByPassCaching: string = 'false', strForeCaching: string = 'false'): Observable<AttributeMatchLevel[]> {
        this.objParams = new HttpParams()
            .set('stateNum', stateNum.toString())
            .set('subFormName', strSubFormName)
            .set('blnByPassCaching', strByPassCaching)
            .set('blnForeCaching', strForeCaching);


        return this._http.get<AttributeMatchLevel[]>(this.baseURL + "api/util/GetAttributeMatchLevelsByForm", { params: this.objParams }).pipe(
            map(response => response)
        );
    }

    public GetCalenderReminder(): Observable<T> {
        return this._http.get<T>(this.baseURL + "api/home/GetCalenderReminder", { params: this.objParams });
    }

    public GetCaseSummaryData(caseYear: number, mdeMode: number): Observable<T> {
        this.objParams = new HttpParams()           
            .set('caseYear', caseYear.toString())
            .set('mdeMode', mdeMode.toString());
        return this._http.get<T>(this.baseURL + "api/home/GetCaseSummaryData", { params: this.objParams });
    }

    // GES
    public GetGESSummaryData(stateNum: number, caseYear: number, mdeMode: number): Observable<T> {
        if (stateNum == undefined || stateNum == null) stateNum = -1;
        this.objParams = new HttpParams()
            .set('stateNum', stateNum.toString())
            .set('caseYear', caseYear.toString())
            .set('mdeMode', mdeMode.toString());
        return this._http.get<T>(this.baseURL + "api/home/GetGESSummaryData", { params: this.objParams });
    }

    //END of GES
    public GetFarsMetricsDetails(stateNum: number, caseYear: number, monthlyCountTypeID: number): Observable<T> {
        this.objParams = new HttpParams()
            .set('stateNum', stateNum.toString())
            .set('caseYear', caseYear.toString())
            .set('monthlyCountTypeID', monthlyCountTypeID.toString());
        return this._http.get<T>(this.baseURL + "api/home/GetFarsMetricsDetails", { params: this.objParams });
    }

    public GetQcBenchmarkComparison(stateNum: number): Observable<T> {
        this.objParams = new HttpParams()
            .set('stateNum', stateNum.toString());
        return this._http.get<T>(this.baseURL + "api/home/GetQcBenchmarkComparison", { params: this.objParams });
    }

    public GetDelayReason(year: number, stateNum: number): Observable<T> {
        this.objParams = new HttpParams()
            .set('year', year.toString())
            .set('StateNum', stateNum.toString());
        return this._http.get<T>(this.baseURL + "api/earlyNotification/GetDelayReason", { params: this.objParams });
    }
    //#endregion

    //#region Check Case
    public GetCaseOverRiddenRules(stateNum: number, accId: number, year: number, ruleId: string): Observable<T> {
        this.objParams = new HttpParams()
            .set('stateNum', stateNum.toString())
            .set('accId', accId.toString())
            .set('year', year.toString())
            .set('ruleId', ruleId);
        return this._http.get<T>(this.baseURL + "api/case/GetCaseOverRiddenRules", { params: this.objParams });
    }


    public GetCaseOverRiddenRulesReasons(stateNum: number, year: number, ruleId: string): Observable<T> {
        this.objParams = new HttpParams()
            .set('stateNum', stateNum.toString())
            .set('year', year.toString())
            .set('ruleId', ruleId.toString());
        return this._http.get<T>(this.baseURL + "api/case/GetCaseOverRiddenRulesReasons", { params: this.objParams });
    }

    public DeleteOverRideRule(accid: number, ruleId: string, vNumber: number, pNumber: number): Promise<any> {
        this.objParams = new HttpParams()
            .set('accid', accid.toString())
            .set('ruleId', ruleId.toString())
            .set('vNumber', vNumber.toString())
            .set('pNumber', pNumber.toString());
        return this._http.delete(this.baseURL + "api/case/DeleteOverRideRule", { params: this.objParams }).toPromise();
    }

    public SaveOrideCaseIsHideFlag(CaseOverRiddenRulesReasons: CaseOverRiddenRulesReasons): Promise<any> {

        const url = "api/case/SaveOrideCaseIsHideFlag/";
        return this._http.put(this.baseURL + url, CaseOverRiddenRulesReasons).toPromise();
    }

    //public SaveOrideCase(acc: Acc): Promise<any> {
    //    this.objParams = new HttpParams()
    //        .set('accid', acc.AccID.toString());
    //    //return this._http.put(this.baseURL + "api/case/SaveORideCase", { params: this.objParams }).toPromise();
    //    const url = "api/case/SaveORideCase/" + acc;
    //    return this._http.put(this.baseURL + url, { params: this.objParams }).toPromise();
    //}
    //#endregion

    public SaveEDTCaseStatus(accid: number, status: number): Promise<any> {
        this.objParams = new HttpParams()
            .set('accid', accid.toString())
            .set('status', status.toString());
        const url = "api/edt/SaveEDTCaseStatus/" + accid + '/' + status;
        return this._http.put(this.baseURL + url, { params: this.objParams }).toPromise();
    }
}
