import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from '../../../../services/case.service';
import { VehicleService } from '../../../../services/vehicle.service';
import { UtilService } from '../../../../services/util.service';
import { Acc } from '../../../../models/acc';
import { Veh } from '../../../../models/veh';
import { Occupants } from '../../../../models/occupants';
import { Element_Specify } from '../../../../models/element-specify';
import { TableFieldElements } from '../../../../models/table-field-elements';
import { DrpDownOptions } from '../../../../models/drp-down-options';
import { FormName, TableId, DBMode, KeyCode, Preference } from '../../../../models/enums/app.enums';
import { TypeaheadComponent } from '../../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';


import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service'
import { ModalsAlcoholCalculatorComponent } from 'src/app/components/modals/modals-alcohol-calculator/modals-alcohol-calculator.component';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';


@Component({
    selector: 'app-vehicle-person-alcohol',
    templateUrl: './vehicle-person-alcohol.component.html',
    styleUrls: ['./vehicle-person-alcohol.component.css']
})

export class VehiclePersonAlcoholComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehiclePersonAlcoholComponent: string = null;
    mode: number;

    accid: number;
    vehicleid: number;
    personid: number;
    alcConvPupupPrefID: number = 312;
    alcConvPupupOn: boolean = true;
    hideDrugInvolvement: boolean = true;

    acc: Acc;
    venicle: Veh;
    person: Occupants;
    focuseOut: boolean = false;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('alcTestResult') alcTestResult: TypeaheadComponent;
    @ViewChild('alcElement') alcElement: ModalsAlcoholCalculatorComponent;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _preferencesService: SecUserPerferenceService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {

        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
        this.focuseOut = false;
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    async ngOnInit() {
        super.ngOnInit();

        let appSettings = await this._sharedDataService.GetAppSettings();
        this.mode = await appSettings.intMode;

        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });

            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
            });
            this._route.parent.params.subscribe(params => {
                this.personid = + params['personid'];
                this.initData();
            });
        } else {
            this.vehicleid = this.printVehNum;
            this.personid = this.personNum;
            this.accid = this.acc.AccID;
            this.initData();
        }
        //Hide Police reported drug involvement on Alcohol tab for FARS and Recode(except CRSS)
        this.hideDrugInvolvement = ((this.mode == DBMode.FARS || this.mode == DBMode.RECODE));
    }

    initData() {
        this.venicle = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.person = this.venicle.Occupants.find(o => o.PNumber == this.personid);
        this.focuseOut = false;

        setTimeout(() => {
            this.alcTestResult.focus$.subscribe(async () => {
                await this._preferencesService.isThisPreferenceOn(Preference.AlcoholConvPopup)
                    .then(item => {
                        this.alcConvPupupOn = item;
                    });
                if (this.alcConvPupupOn && this.person.AlcRes == -1 && !this.focuseOut) {
                    this.focuseOut = true;
                    this.alcElement.btnOpen.nativeElement.click();
                }
            })
            //this.focusNextInput();
        }, 1000);
    }

    setObjectValue($event: DrpDownOptions, fieldName: string) {
        super.setObjectValue($event, fieldName, this.person);
    }

    setAlcoholValue(event) {
        this.person.AlcRes = event;
        this.focuseOut = true;
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.AlcRes);

        if (component) {
            component.setCurrentValue(event);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    handleKeyup(event: KeyboardEvent) {
        if (event.code == KeyCode.F9 || event.code == KeyCode.Minus || event.code == KeyCode.NumpadSubtract) {
            this.alcElement.btnOpen.nativeElement.click();
        }
    }

    focusNextInput() {
        setTimeout(() => {
            let inputElements = window.document.querySelectorAll('input');
            var focusInputElements = Array.prototype.filter.call(inputElements,
                function (element) {
                    return (element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement)
                        && (element.hidden == false && element.disabled == false);
                });

            let index = focusInputElements.indexOf(document.activeElement);
            let nextElement = focusInputElements[index + 1];

            if (index < focusInputElements.length - 1) {
                if (nextElement.value.length > 0) {
                    nextElement.select();
                }
                nextElement.focus();
            }
        }, 30);
    }
}
