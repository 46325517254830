import { Injectable, PipeTransform } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, of, Subject, empty } from 'rxjs';
import { GenericService } from './generic.service';
import { HelpDeskTicket } from './../models/HelpDeskTicket';
import { HttpClient, HttpHeaders, HttpHandler, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HelpDeskTicketService {

    private ticket: BehaviorSubject<any[]>;

    constructor(private http: HttpClient, private _baseService: GenericService<any>) {
    }

    public CreateTicket(formData: FormData): Promise<any> {

        let filesAndText = [];
        filesAndText.push(formData)
        const headers = new HttpHeaders({
            'Content-Disposition': 'multipart/form-data'
        })
        return this.http.post("api/HelpDeskTicket/CreateTicket/", formData).toPromise();
    }

}
