<form novalidate #fvpsafetyd="ngForm">
    <div class="pt-2" *ngIf="person && intMode">
        <div [ngClass]="{'row' : printOnly === true}">

            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="AirBagDeplmnt" name="AirBagDeplmnt" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="AirBagDeplmnt" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PlcRprtdAlcPrnce" name="PlcRprtdDrgPrnce" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="PlcRprtdDrgPrnce" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="ContrBehvr" name="ContrBehvr" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="ContrBehvr" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                     ID should be unique in entire HTML document. If this component is instantiated multiple types, then expose an input property for the ID and let parent pass in a unique ID suffix and append it to Typeahead ID
                    <app-typeahead id="methDrug" name="methDrug" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="MethDrug"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="BeltUsed" name="BeltUsed" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="BeltUsed" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData" [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="AirBagDeplmnt" name="AirBagDeplmnt" typeAheadPosition="right-top"
                                   [objModel]="person" strTableName="Occupants" strFieldName="AirBagDeplmnt" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>
