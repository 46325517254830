<div *ngIf="blnHideLight == true" class="badge badge-primary badge-pill badge-secondary ml-3 col-12 col-sm-auto col-md-12 col-lg-auto col-xl-auto">{{matchLevel}}%</div>
<div *ngIf="blnHideDanger == true" class="badge badge-primary badge-pill badge-danger ml-3 col-12 col-sm-auto col-md-12 col-lg-auto col-xl-auto">{{matchLevel}}</div>
<div *ngIf="blnHideWarning == true" class="badge badge-primary badge-pill badge-warning ml-3 col-12 col-sm-auto col-md-12 col-lg-auto col-xl-auto">{{matchLevel}}</div>
<div *ngIf="blnHidePrimary == true" class="badge badge-primary badge-pill badge-primary ml-3 col-12 col-sm-auto col-md-12 col-lg-auto col-xl-auto">{{matchLevel}}</div>
<div *ngIf="blnHideInfo == true" class="badge badge-primary badge-pill badge-info ml-3 col-12 col-sm-auto col-md-12 col-lg-auto col-xl-auto">{{matchLevel}}</div>
<div *ngIf="blnHideSuccess == true" class="badge badge-primary badge-pill badge-success ml-3 col-12 col-sm-auto col-md-12 col-lg-auto col-xl-auto">{{matchLevel}}%</div>




