<div class="container-fluid">
    <p>&nbsp;</p>
    <div class="row">
        <div class="col-md-10">
            <h5 class="alert-info">&nbsp;Ask a Coding Question</h5>
            <strong>&nbsp;CC (Please enter email addresses separated by comma)</strong>
            <textarea class="col-sm-12" id="textareaAskCodingQuestion_EmailCC" name="ccMail" rows="2" maxlength="1000" (onkeydown)="moveCursorToEnd($event)" (change)="emailCC($event)"></textarea>
            <div class="col-sm-1">
            </div>
            <strong>&nbsp;Request / Issue Description</strong>
            <textarea class="col-sm-12" id="textareaAskCodingQuestion_Question" name="question" rows="10" maxlength="5000" (onkeydown)="moveCursorToEnd($event)" (change)="askQuestion($event)">{{issueDesc}}</textarea>           
            <div class="col-sm-12">
                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <h6>
                            Click on Browse Files and pick the file you would like to upload. When you have selected the file, click Open in the browser window.<br>
                            To drag and drop files from your computer, select files, drag them to into this location, and drop them.<br>
                            Only files with the following extensions are allowed:
                            bmp, csv, doc, docx, gif, jpg, pdf, png, tiff, txt, xls, xlsx.
                        </h6>
                        <button type="button" (click)="openFileSelector()" >Browse Files</button>
                    </ng-template>
                </ngx-file-drop>
            </div>
            <div class="col-sm-12" *ngIf="!noAttachment">
                <Label class="col-form-label" id="txtAttachedFiles"><strong>Attached Files: </strong>{{attachedFileNames}}&nbsp;&nbsp;</Label>
                <i class="far fa-trash-alt fa-lg text-danger" (click)="clearFiles()" title="Delete all attached files"></i>
            </div>
        </div>
        <div class="col-md-2">
            <div class="col-sm-1">
                <p>&nbsp;&nbsp;</p>
            </div>
            <div class="col-sm-1">
            </div>
            <div class="col-sm-11">
                <button class="btn btn-sm btn-primary btn-block" id="btnAskCodingQuestion_Submit" (click)="submit()">
                    Submit
                </button>
            </div>
            &nbsp;
            <div class="col-sm-11">
                <button class="btn btn-sm btn-light border-secondary btn-block" id="btnAskCodingQuestion_Cancel" (click)="cancel()">
                    <span class="glyphicon glyphicon-print"></span>Cancel
                </button>
            </div>
        </div>
    </div>
</div>
