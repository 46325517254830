import { formatNumber } from '@angular/common';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { Acc } from 'src/app/models/acc';
import { EarlyNotify } from 'src/app/models/early-notify';
import { DBMode, Injury, NonOccupantType, RBISDataValue, VehType } from 'src/app/models/enums/app.enums';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { Occupants } from 'src/app/models/occupants';
import { Veh } from 'src/app/models/veh';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';

export enum TreeNodeSelectability {
    Disabled = 0,
    Enabled = 1,
    SelectParent = 2
}

//let setNonOccCaption: boolean = false;
//let setStrikingVehCaption: boolean = false;
//let setSFRVehCaption: boolean = false;
//let setOtherVehCaption: boolean = false ;

export class TreeNode {
    public arrChildren: TreeNode[];
    public blnExpanded: boolean; //Show child nodes [flagged as visible]
    private _blnSelected: boolean;
    public get blnSelected(): boolean { return this._blnSelected; }
    public blnVerbose: boolean;  //Write out basic summary information for node
    public blnVisible: boolean;
    public eSelectability: TreeNodeSelectability = TreeNodeSelectability.Enabled;
    public intCompletenessPercent: number;
    public objModel: any;
    public objParent: TreeNode;
    public objRoot: TreeNode;
    public objSelected: TreeNode;
    public strId: string;
    public strTitle: string;
    public strUrl: string;
    public strVType: string;

    public intMode: number;

    constructor(pRoot: TreeNode, pParent: TreeNode, pTitle: string, pModel: any, pUrl: string, pVisible: boolean = true, pSelectable: TreeNodeSelectability = TreeNodeSelectability.Enabled, vType:string ="") {
        this.blnVisible = pVisible;
        this.eSelectability = pSelectable;
        this.objModel = pModel;
        this.objParent = pParent;
        this.objRoot = pRoot;
        this.strTitle = pTitle;
        this.strId = pTitle.indexOf('|') != -1 ? pTitle.substr(0, pTitle.indexOf('|') - 1) : pTitle;
        this.strId = this.strId.replace(/[^a-zA-Z0-9]/g, '_');
        this.strUrl = pUrl;

        this.arrChildren = new Array<TreeNode>();
        this.blnExpanded = true;
        this.intCompletenessPercent = 0;

        this.strVType = vType;
    }

    //Based on seletability behavior, selects either the current node or its ancestor and returns the selected node.
    public Select(blnNewValue: boolean = undefined): TreeNode {
        if (this.eSelectability == TreeNodeSelectability.Disabled)
            return null;
        else if (this.eSelectability == TreeNodeSelectability.SelectParent) {
            this.objParent._blnSelected = blnNewValue !== undefined ? blnNewValue : !this.objParent._blnSelected;
            return this.objParent._blnSelected ? this.objParent : null;
        }
        else {
            this._blnSelected = blnNewValue !== undefined ? blnNewValue : !this._blnSelected;
            return this._blnSelected ? this : null;
        }
    }

    /**
     * Traverses the tree in depth-first order and returns tree nodes in an array.
     */
    public FlattenDepthFirst(arrFlat: Array<TreeNode> = null): Array<TreeNode> {
        if (arrFlat == null)
            arrFlat = new Array<TreeNode>();

        if (this.eSelectability == TreeNodeSelectability.Enabled)
            arrFlat.push(this); //Push self on

        for (let objChild of this.arrChildren) {
            if (objChild.arrChildren.length == 0) {
                if (objChild.eSelectability == TreeNodeSelectability.Enabled)
                    arrFlat.push(objChild);
            }
            else
                objChild.FlattenDepthFirst(arrFlat); //Recursive call will push child on if there are grandchildren
        }

        return arrFlat;
    }

    public static async CreateTree(objEarlyNotify: EarlyNotify, blnVerbose: boolean = false, blnEnabled: boolean = true, objNameValuePipe: ValueToDescriptionPipe): Promise<TreeNode> {
        try {
            let objAcc: Acc = objEarlyNotify.Acc;
            let strCaseNumFormatted: string = '';

            if (objAcc.Casenum) {
                if (objAcc.Mode == DBMode.MOSS)
                    strCaseNumFormatted = objAcc.Acc_SS.MOSSCasenum;
                else if (objAcc.Mode != DBMode.CRSS)
                    strCaseNumFormatted = formatNumber(objAcc.Casenum, 'en-US', '4.0-0').replace(/,/g, '');
                else
                    strCaseNumFormatted = formatNumber(objEarlyNotify.PARID % 10000000, 'en-US', '7.0-0').replace(/,/g, '');
            }

            //URL routes generated here should match what is registered with Router service
            let nodeRoot: TreeNode = new TreeNode(null, null, 'Case ' + strCaseNumFormatted, objAcc,
                `/case/${objAcc.AccID}/crash/${objAcc.AccID}`, true, TreeNodeSelectability.Enabled);
            let nodeVeh: TreeNode;

            for (let objNonOcc of objAcc.Non_Occupants)
                await TreeNode.CreateTree_NonOccupant(nodeRoot, objNonOcc, blnVerbose, blnEnabled, objNameValuePipe);

            if (objAcc.Mode == DBMode.MOSS) {
                //Set vehicle sorting order by Strike, SFR and Other Vehicle
                let sortedVehByType = objAcc.Veh.sort((a, b) => {
                    
                    let vehA = a.VehType == VehType.StrikingVehicle ? 0 : a.VehType == VehType.SFRVehicle ? 1 : a.VehType == VehType.OtherVehicle ? 2 : null;
                    let vehB = b.VehType == VehType.StrikingVehicle ? 0 : b.VehType == VehType.SFRVehicle ? 1 : b.VehType == VehType.OtherVehicle ? 2 : null;

                    //Sort by veh number
                    if (!vehA || vehA < vehB) { 
                        return -1;
                    } else if (!vehB || vehA > vehB) {
                        return 1;
                    }
                    return 0;
                })

                //Set sorted vehicle sort by veh number
                let sortedVehByNumber = sortedVehByType.sort ((a, b) => {

                    let vehA = a.VehType == VehType.StrikingVehicle ? 0 : a.VehType == VehType.SFRVehicle ? 1 : a.VehType == VehType.OtherVehicle ? 2 : null;
                    let vehB = b.VehType == VehType.StrikingVehicle ? 0 : b.VehType == VehType.SFRVehicle ? 1 : b.VehType == VehType.OtherVehicle ? 2 : null;
                    
                    if (!vehA && !vehB && vehA == vehB ) 
                        return (a.Veh_SS.SSVNumber > b.Veh_SS.SSVNumber) ? 1 : -1
                    else
                        return 0;
                })

                for (let objVeh of sortedVehByNumber)
                    nodeVeh = await TreeNode.CreateTree_Vehicle(nodeRoot, objVeh, blnVerbose, blnEnabled, objNameValuePipe);

                //Not working
                //(async () => {
                //    objAcc.Veh.filter(item => item.VehType == VehType.StrikingVehicle)
                //        .sort((x, y) => (x.Veh_SS.SSVNumber > y.Veh_SS.SSVNumber && x.VehType== VehType.StrikingVehicle && y.VehType== VehType.StrikingVehicle) ? 1 : -1)
                //        .forEach(async (veh)  => {
                //            nodeVeh = await TreeNode.CreateTree_Vehicle(nodeRoot, veh, blnVerbose, blnEnabled, objNameValuePipe);
                //        });
                //})().then
                //(async () => {
                //    objAcc.Veh.filter(item => item.VehType == VehType.SFRVehicle)
                //        .sort((x, y) => (x.Veh_SS.SSVNumber > y.Veh_SS.SSVNumber && x.VehType == VehType.SFRVehicle && y.VehType == VehType.SFRVehicle) ? 1 : -1)
                //        .forEach(async (veh)  => {
                //            nodeVeh = await TreeNode.CreateTree_Vehicle(nodeRoot, veh, blnVerbose, blnEnabled, objNameValuePipe);
                //        });
                //}).then
                //(async () => {
                //    objAcc.Veh.filter(item => item.VehType == VehType.OtherVehicle)
                //        .sort((x, y) => (x.Veh_SS.SSVNumber > y.Veh_SS.SSVNumber && x.VehType == VehType.OtherVehicle && y.VehType == VehType.OtherVehicle) ? 1 : -1)
                //        .forEach( async (veh) => {
                //            nodeVeh = await TreeNode.CreateTree_Vehicle(nodeRoot, veh, blnVerbose, blnEnabled, objNameValuePipe);
                //        });
                //});

            }
            else {
                for (let objVeh of objAcc.Veh)
                    nodeVeh = await TreeNode.CreateTree_Vehicle(nodeRoot, objVeh, blnVerbose, blnEnabled, objNameValuePipe);
            }

            return nodeRoot;
        }
        catch (ex) {
            console.log(ex);
        }
    }

    public static vehMossTreeTitle(strVehType: string = ''): string {
        let strTitle: string = '';

        strTitle = strVehType == VehType.SFRVehicle ? 'Struck_Subject_' :
            strVehType == VehType.StrikingVehicle ? 'Striking_' :
                strVehType == VehType.OtherVehicle ? 'Other_' : '';

        return strTitle;
    }

    public static vehMossUrlTitle(strVehType: string = ''): string {
        let strUrl: string = '';

        strUrl = strVehType == VehType.SFRVehicle ? 'sfr' :
            strVehType == VehType.StrikingVehicle ? 'striking' :
                strVehType == VehType.OtherVehicle ? '' : '';

        return strUrl;
    }

    //public static resetMossTreeCaption() {
    //    setNonOccCaption = false;
    //    setStrikingVehCaption = false;
    //    setSFRVehCaption = false;
    //    setOtherVehCaption = false;
    //}

    public static async delayTreeCreateProcess(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public static async CreateTree_Vehicle(nodeRoot: TreeNode, objVeh: Veh, blnVerbose: boolean, blnEnabled: boolean = true, objNameValuePipe: ValueToDescriptionPipe): Promise<TreeNode> {
        let strTitle: string ='';
        let intVNumber: number;
        let strUrl: string = '';

        let strVpicMake: string = '', strVpicModel: string = '';

        let strPrecrashType: string = '';

        if (objVeh.Acc.Mode == DBMode.MOSS) {
            strTitle = this.vehMossTreeTitle(objVeh.VehType);

            intVNumber = objVeh.Veh_SS.SSVNumber == RBISDataValue.Blank ? 1 : objVeh.Veh_SS.SSVNumber;
            
            strUrl = this.vehMossUrlTitle(objVeh.VehType);

            if (blnVerbose) {
                strVpicMake = await objNameValuePipe.transform(objVeh.Make, 'Make');

                if (objVeh.Make > 0)
                    strVpicModel = await objNameValuePipe.transform(objVeh.Model, 'Model', ' AND MAKE = ', objVeh.Make.toString())
            }

            if (objVeh.PreCrash && objVeh.PreCrash.PreCrash_MTSS)
                strPrecrashType = objVeh.PreCrash.PreCrash_MTSS.CrashTypeSS ? objVeh.PreCrash.PreCrash_MTSS.CrashTypeSS.toString(): '';
        }
        else {
            intVNumber = objVeh.VNumber;

            if (blnVerbose) {
                strVpicMake = await objNameValuePipe.transform(objVeh.vPICMake, 'vPICMake');

                if (objVeh.vPICMake > 0)
                    strVpicModel = await objNameValuePipe.transform(objVeh.vPICModel, 'vPICModel', ' AND MAKE = ', objVeh.vPICMake.toString())

                if (objVeh.PreCrash)
                    strPrecrashType = objVeh.PreCrash.CrashType ? objVeh.PreCrash.CrashType.toString(): '';
            }
        }     

        ////Create a Veh group Caption for MOSS
        //let nodeVehGroup: TreeNode
        //if (objVeh.Acc.Mode == DBMode.MOSS) {
        //    let vehGroupName: string = '';
        //    vehGroupName = objVeh.VehType == VehType.StrikingVehicle ? 'Striking ' : objVeh.VehType == VehType.SFRVehicle ? 'SFR' : objVeh.VehType == VehType.OtherVehicle ? 'Other ' : '';

        //    if (!setStrikingVehCaption && objVeh.VehType == VehType.StrikingVehicle && objVeh.Acc.Veh.filter(x => x.VehType == VehType.StrikingVehicle).length > 0) {
        //        nodeVehGroup = new TreeNode(nodeRoot, nodeRoot, vehGroupName, objVeh,
        //            `/case /${objVeh.AccID}/${strUrl}vehicle / ${objVeh.VNumber} / vehicle`, true, TreeNodeSelectability.Disabled, objVeh.VehType);
        //        nodeRoot.arrChildren.push(nodeVehGroup);
        //        setStrikingVehCaption = true
        //    }
        //    else if (!setSFRVehCaption && objVeh.VehType == VehType.SFRVehicle && objVeh.Acc.Veh.filter(x => x.VehType == VehType.SFRVehicle).length > 0) {
        //        nodeVehGroup = new TreeNode(nodeRoot, nodeRoot, vehGroupName, objVeh,
        //            `/case /${objVeh.AccID}/${strUrl}vehicle / ${objVeh.VNumber} / vehicle`, true, TreeNodeSelectability.Disabled, objVeh.VehType);
        //        nodeRoot.arrChildren.push(nodeVehGroup);
        //        setSFRVehCaption = true
        //    }
        //    else if (!setOtherVehCaption && objVeh.VehType == VehType.OtherVehicle && objVeh.Acc.Veh.filter(x => x.VehType == VehType.OtherVehicle).length > 0) {
        //        nodeVehGroup = new TreeNode(nodeRoot, nodeRoot, vehGroupName, objVeh,
        //            `/case /${objVeh.AccID}/${strUrl}vehicle / ${objVeh.VNumber} / vehicle`, true, TreeNodeSelectability.Disabled, objVeh.VehType);
        //        nodeRoot.arrChildren.push(nodeVehGroup);
        //        setOtherVehCaption = true
        //    }
        //}

        let nodeVeh: TreeNode = new TreeNode(nodeRoot, nodeRoot,
            strTitle + 'Vehicle_' + intVNumber +
            (blnVerbose ? ' | Make: ' + strVpicMake + ' | Model: ' + strVpicModel : ''),
            objVeh,
            `/case/${objVeh.AccID}/${strUrl}vehicle/${objVeh.VNumber}/vehicle`,
            true,
            blnEnabled ? TreeNodeSelectability.Enabled : TreeNodeSelectability.Disabled, objVeh.VehType);

        //Find tree position to insert record
        if (objVeh.Acc.Mode == DBMode.MOSS) {
            (async () => {
                let intInsertIndex: number;

                if (objVeh.VehType == VehType.StrikingVehicle) {
                    nodeRoot.arrChildren.filter((vehType, index) => {
                        if (vehType.strVType == VehType.StrikingVehicle)
                            intInsertIndex = index
                    });
                }
                else if (objVeh.VehType == VehType.SFRVehicle) {
                    nodeRoot.arrChildren.filter((vehType, index) => {
                        if (vehType.strVType == VehType.SFRVehicle)
                            intInsertIndex = index
                    });
                }
                else if (objVeh.VehType == VehType.OtherVehicle) {
                    nodeRoot.arrChildren.filter((vehType, index) => {
                        if (vehType.strVType == VehType.OtherVehicle)
                            intInsertIndex = index
                    });
                }
                    
                await this.delayTreeCreateProcess(1); //try to avoid random runtime error
                                    
                if (intInsertIndex >= 0)
                    nodeRoot.arrChildren.splice(intInsertIndex + 1 , 0, nodeVeh);
                else 
                    nodeRoot.arrChildren.push(nodeVeh);                    
            })();
        }
        else {
            nodeRoot.arrChildren.push(nodeVeh);
        }

        let showDriverNode = objVeh.Acc.Mode == DBMode.MOSS && (objVeh.VehType == VehType.SFRVehicle || objVeh.VehType == VehType.OtherVehicle )? false : true
        let showPersonNode = objVeh.Acc.Mode == DBMode.MOSS && objVeh.VehType == VehType.OtherVehicle ? false : true

        if (blnVerbose) {
            if (objVeh.Dri && showDriverNode)
                    nodeVeh.arrChildren.push(new TreeNode(nodeRoot, nodeVeh,
                        strTitle + 'Driver_V' + intVNumber +
                        (blnVerbose && objVeh.Acc.Mode == DBMode.FARS ? ' | License State: ' + await objNameValuePipe.transform(objVeh.Dri.LicState, 'StateNum') : ''),
                        objVeh.Dri,
                        `/case/${objVeh.AccID}/${strUrl}vehicle/${objVeh.VNumber}/driver`,
                        true,
                        blnEnabled ? TreeNodeSelectability.SelectParent : TreeNodeSelectability.Disabled
                    ));

            if (objVeh.Acc.Mode == DBMode.MOSS && objVeh.VehType == VehType.StrikingVehicle) {
                nodeVeh.arrChildren.push(new TreeNode(nodeRoot, nodeVeh,
                    strTitle + 'Precrash_V' + intVNumber + (blnVerbose ? ' | Crash Type: ' + strPrecrashType : ''),
                    objVeh.PreCrash,
                    `/case/${objVeh.AccID}/${strUrl}vehicle/${objVeh.VNumber}/precrash`,
                    true,
                    blnEnabled ? TreeNodeSelectability.SelectParent : TreeNodeSelectability.Disabled
                ));
            }
            else {
                if (objVeh.PreCrash)
                    nodeVeh.arrChildren.push(new TreeNode(nodeRoot, nodeVeh,
                        'Precrash_V' + intVNumber + (blnVerbose ? ' | Crash Type: ' + strPrecrashType : ''),
                        objVeh.PreCrash,
                        `/case/${objVeh.AccID}/${strUrl}vehicle/${objVeh.VNumber}/precrash`,
                        true,
                        blnEnabled ? TreeNodeSelectability.SelectParent : TreeNodeSelectability.Disabled
                    ));
            }
        }

        if (objVeh.Occupants && showPersonNode)
            for (let objOcc of objVeh.Occupants)
                await TreeNode.CreateTree_Occupant(nodeRoot, nodeVeh, objOcc, blnVerbose, blnEnabled, objNameValuePipe);

        return nodeVeh;
    }

    public static async CreateTree_Occupant(nodeRoot: TreeNode, nodeVeh: TreeNode, objOcc: Occupants, blnVerbose: boolean, blnEnabled: boolean = true, objNameValuePipe: ValueToDescriptionPipe): Promise<TreeNode> {
        let strTitle: string ='';
        let intVNumber: number;
        let strUrl: string = '';
        let strPerson: string = 'Person'; 
        
        if (objOcc.Veh.Acc.Mode == DBMode.MOSS) {
            strTitle = this.vehMossTreeTitle(objOcc.Veh.VehType);

            intVNumber = objOcc.Veh.Veh_SS.SSVNumber == RBISDataValue.Blank ? 1 : objOcc.Veh.Veh_SS.SSVNumber;
            
            strUrl = this.vehMossUrlTitle(objOcc.Veh.VehType);

            strPerson = 'Occupant';
        }
        else 
            intVNumber = objOcc.Veh.VNumber;       

        let nodePsn: TreeNode = new TreeNode(nodeRoot, nodeVeh,
            strTitle + strPerson +'_' + objOcc.PNumber.toString() + '_V' + intVNumber +
            (objOcc.PType === 1 ? ' - Driver' : '') +
            (blnVerbose ?
                ' | Age: ' + objOcc.Age +
                ' | Sex: ' + await objNameValuePipe.transform(objOcc.Sex, 'Sex') +
                ' | ' + strPerson + ' Type : ' + await objNameValuePipe.transform(objOcc.PType, 'PType')
                : '') +
            (objOcc.Veh.Acc.Mode == DBMode.MOSS  ? 
                (objOcc.PolInjSevr != -1 ? ' | Injury: ' + await objNameValuePipe.transform(objOcc.PolInjSevr, 'Injury') : '') :
                (objOcc.Injury != -1 ? ' | Injury: ' + await objNameValuePipe.transform(objOcc.Injury, 'Injury') : ''))
            +
            (objOcc.Veh.Acc.Mode == DBMode.MOSS ?
                (objOcc.PolInjSevr === Injury.MOSSFatal ? ' *' : '') :
                (objOcc.Injury === Injury.Fatal ? ' *' : '')),
            objOcc,
            `/case/${objOcc.AccID}/${strUrl}vehicle/${objOcc.VNumber}/person/${objOcc.PNumber}`,
            true,
            blnEnabled ? TreeNodeSelectability.Enabled : TreeNodeSelectability.Disabled
        );

        nodeVeh.arrChildren.push(nodePsn);
        return nodePsn;
    }

    public static async CreateTree_NonOccupant(nodeRoot: TreeNode, objNonOcc: Non_Occupants, blnVerbose: boolean, blnEnabled: boolean = true, objNameValuePipe: ValueToDescriptionPipe ): Promise<TreeNode> {
        let strTitle: string = '';        
        let strPerson: string = 'Person';

        if (objNonOcc.Acc.Mode == DBMode.MOSS) {
            strTitle = 'Subject_';
            strPerson = 'Non Occupant';
        }

        let strNonOccTypeAbbreviated: string = objNonOcc.PType == NonOccupantType.Pedestrian ? ' (Pedestrian)' : (objNonOcc.PType == NonOccupantType.Bike ? ' (Bike)' : '');

        ////Create a Non Occ group Caption for MOSS
        //if (objNonOcc.Acc.Mode == DBMode.MOSS) {
        //    let vehGroupName: string = 'Non Occupant';
        //    if (!setNonOccCaption ) {
        //        let nodeNonOccGroup = new TreeNode(nodeRoot, nodeRoot, vehGroupName, objNonOcc,
        //            `/case/${objNonOcc.AccID}/nonOccupant/${objNonOcc.PNumber}`, true, TreeNodeSelectability.Disabled);
        //        nodeRoot.arrChildren.push(nodeNonOccGroup);
        //        setNonOccCaption = true
        //    }
        //}

        let nodePsn: TreeNode = new TreeNode(nodeRoot, nodeRoot,
            'Non_Occupant_'+ strTitle + objNonOcc.PNumber +
            (blnVerbose ?
                ' | Age: ' + objNonOcc.Age +
                ' | Sex: ' + await objNameValuePipe.transform(objNonOcc.Sex, 'Sex') +
                ' | ' + strPerson + ' Type : ' + await objNameValuePipe.transform(objNonOcc.PType, 'PType') +
                ' | Injury: ' + await objNameValuePipe.transform(objNonOcc.Injury, 'Injury')
                :
                (objNonOcc.Injury != -1 ? ' | Injury: ' + await objNameValuePipe.transform(objNonOcc.Injury, 'Injury') : '') +
                strNonOccTypeAbbreviated) +
            (objNonOcc.Acc.Mode == DBMode.MOSS ?
                (objNonOcc.Injury === Injury.MOSSFatal ? ' *' : '') :
                (objNonOcc.Injury === Injury.Fatal ? ' *' : '')),
            objNonOcc,
            `/case/${objNonOcc.AccID}/nonOccupant/${objNonOcc.PNumber}`,
            true,
            blnEnabled ? TreeNodeSelectability.Enabled : TreeNodeSelectability.Disabled
        );

        let intInsertIndex = nodeRoot.arrChildren.filter(x => ObjectUtil.isNonOcc(x.objModel)).length;
        nodeRoot.arrChildren.splice(intInsertIndex, 0, nodePsn);

        nodePsn.arrChildren.push(new TreeNode(nodeRoot, nodePsn,
            (objNonOcc.PType == NonOccupantType.Pedestrian ? 'Pedestrian' : (objNonOcc.PType == NonOccupantType.Bike ? 'Bike' : 'Ped/Bike')) + '_' + objNonOcc.PNumber.toString(),
            objNonOcc,
            `/case/${objNonOcc.AccID}/nonOccupant/${objNonOcc.PNumber}`,
            true,
            blnEnabled ? TreeNodeSelectability.SelectParent : TreeNodeSelectability.Disabled
        ));

        return nodePsn;
    }
}
