import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CaseService } from 'src/app/services/case.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { Acc } from 'src/app/models/acc';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { DrpDownOptions } from 'src/app/models/drp-down-options';

@Component({
    selector: 'app-non-occupant',
    templateUrl: './non-occupant.component.html',
    styleUrls: ['./non-occupant.component.css']
})
export class NonOccupantComponent implements OnInit, AfterViewInit {

    private sbsNonOccId: Subscription;
    accid: number;
    stateNum: number;
    nonOccid: number;
    intMode: number;

    case: Acc;
    nonOccupant: Non_Occupants;
    objDrpDownOptions: DrpDownOptions[];

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _caseService: CaseService,
        private _sharedDataService: SharedDataService,
    ) { }

    async ngOnInit() {


        this._route.parent.params.subscribe((params => {
            this.accid = + params['caseid'];
            this.stateNum = + params['stateNum'];
            if (!this._sharedDataService.selectedState) {
                this._sharedDataService.setSelectedState(this.stateNum);
            }

            if (this.sbsNonOccId)
                this.sbsNonOccId.unsubscribe();

            this.sbsNonOccId = this._route.params.subscribe((params => {
                this.nonOccid = + params['nonOccid'];
                this._sharedDataService.setNonOccupantid(this.nonOccid);

                //Listen subscription for Refreshing an object from UnsavedChangesRouteGuard btn event click;
                //this._sharedDataService.subjectRefreshDataObj.subscribe(async item => {
                //    if (item === true) {
                //        this.case = await this._caseService.GetCasePromise(this.accid);
                //    }
                //});


                this._caseService.GetCasePromise(this.accid).then((data => {
                    this.nonOccupant = data.Non_Occupants.find(v => v.PNumber == this.nonOccid);

                    if (!this.nonOccupant) //Url specifies a non-existent non-occupant (or recently deleted non-occupant), fall back on top-level case URL
                        this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid]);
                }).bind(this));
            }).bind(this));
        }).bind(this));
    }

    async ngAfterViewInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = appSettings.intMode;
    }
}
