import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'leadingZero'

})
//TO DO Revisit this pipe later and correct if any issue or possiable improvments;
export class LeadingZeroPipe implements PipeTransform {

    item: string;

    targetLength: number;

    field: string = "";

    result: any;
    isValid: any;
    //arrNum: Array<string> = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    transform(value: any, ...args: any[]): any {

        //if there is no value exit pipe
        //if () {
        //    return;
        //}

        //since we store properties as integer in DB first that pipe is called value will come as number; 
        let isNumber = typeof (value) === "number" ? true : false;
        //since we use pipe multiple times at same page we need some kind of logic to distingvish difference between fields;
        let isSameField = this.field === args[1] ? true : false;
        //after we assign string value to property, property become string;
        let strValue;

        //max Lenght for field
        this.targetLength = args[0];

        //Field id(name);
        this.field = args[1];

        //value as number
        if (value && typeof (value) === "number") {
            strValue = value.toString();
            // value.lenght == 2 //(targetLength = 2 )
            if (strValue.length == this.targetLength) {
                this.item = value.toString();
            }
            // value.lenght == 1 //(targetLength = 2 )               
            if (strValue.length + 1 == this.targetLength) {
                this.item = this.leftFillNum(strValue, this.targetLength);
            }
            // value.lenght == random //(targetLength = 4 )
            if (this.targetLength == 4) {
                this.item = this.leftFillNum(strValue, this.targetLength);
            }

        }

        //value as string
        else {
            if (value) {
                strValue = value;
                //if (value.lenght == 2) - //(targetLength = 2 )
                if (value.length == this.targetLength) {
                    this.item = value.toString();
                }
                //if value.lenght == 1 - (targetLength = 2 )
                if (value.length + 1 == this.targetLength) {
                    this.item = this.leftFillNum(value, this.targetLength);
                }
                // value.lenght == random //(targetLength = 4 )
                if (this.targetLength == 4) {
                    this.item = this.leftFillNum(value, this.targetLength);
                }
            }
        }

        //if item is empty return nothing;
        if (!value || value == undefined || !this.item) {
            return;
        }

        //logic for fields with 2 digits;

        if (this.targetLength = 2) {
            if (isSameField) {
                if (!value) {
                    return;
                }
                if (value) {
                    this.result = value;
                }
            }

            if (!isSameField) {
                if (strValue.length == 0) {
                    return;
                }
                if (strValue.length == 1) {
                    if (strValue == "0") {
                        this.result = strValue;
                    }
                    if (strValue != "0" && isNumber) {
                        this.result = this.item;
                    }
                    if (strValue != "0" && !isNumber) {
                        this.result = strValue
                    }
                }
                if (strValue.length >= 2) {
                    this.result = this.item;
                }
            }
        }
        //TO DO - Revrite logic for fields which allow more then 2 digits
        //logic for EN Case# as label at case page;
        if (this.targetLength == 4) {
            this.result = this.leftFillNum(value, this.targetLength);
        }

        return this.result;
    }

    leftFillNum(item, targetLength) {
        return item.padStart(targetLength, "0");
    }

}


