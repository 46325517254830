<form novalidate #fvno="ngForm">
    <div *ngIf="veh" id="vehicle-other" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="HeadLights" name="HeadLights" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="VehHdLght"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="HeadLightBeamSetting" name="HeadLightBeamSetting" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="HdlghtBeamSetting"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="FrontEndProfType" name="FrontEndProfType" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="FrntEndProfType"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>         
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="EvidncBmprGrilLvlCnt" name="EvidncBmprGrilLvlCnt" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="EvidncBmprGrilLvlCnt"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="EvidncHoodCnt" name="EvidncHoodCnt" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="EvidncHoodCnt"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="EvidncWndShlCnt" name="EvidncWndShlCnt" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="EvidncWndShlCnt"
                                   [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="AvoidAssmnt" name="AvoidAssmnt" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AvoidAssmnt"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->
            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="DtectnDistFrPed" name="DtectnDistFrPed" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="DtectnDistFrPed"
                                   [printOnly]="printOnly" [blnAllowFreeText]="true"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="AccrcyDtectnDistFrPedEst" name="AccrcyDtectnDistFrPedEst" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AccrcyDtectnDistFrPedEst"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->

            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label class="my-auto" inputName="DtectnDistFrPed" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-distance-calculator #DtectnDistFrPedElement [objName]="'DtectnDistFrPed'" (submitDistanceValue)="setDistanceValue($event)" *ngIf="!blnReadOnly"></app-modals-distance-calculator>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-typeahead id="DtectnDistFrPed" name="DtectnDistFrPed" typeAheadPosition="right-top"
                                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="DtectnDistFrPed"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData"
                                               disableLabel="true" (keyup)="handleKeyup($event)" [showAllDataListForPrintOnly]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->

            <!--<div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label class="my-auto" inputName="AccrcyDtectnDistFrPedEst" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-distance-calculator #AccrcyDtectnDistFrPedEstElement [objName]="'AccrcyDtectnDistFrPedEst'" (submitDistanceValue)="setDistanceValue($event)" *ngIf="!blnReadOnly"></app-modals-distance-calculator>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-typeahead id="AccrcyDtectnDistFrPedEst" name="AccrcyDtectnDistFrPedEst" typeAheadPosition="right-top"
                                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AccrcyDtectnDistFrPedEst"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData"
                                               disableLabel="true" (keyup)="handleKeyup($event)" [showAllDataListForPrintOnly]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->

            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="CalcRevlTime" name="CalcRevlTime" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="CalcRevlTime"
                                   [printOnly]="printOnly" [blnAllowFreeText]="true"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="AccrcyCalcRevlTmEst" name="AccrcyCalcRevlTmEst" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="AccrcyCalcRevlTmEst"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->
            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PedCrshWrnEff" name="PedCrshWrnEff" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="PedCrshWrnEff"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PedEmrgcyBrkEff" name="PedEmrgcyBrkEff" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="PedEmrgcyBrkEff"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PedEmrgcySterEff" name="PedEmrgcySterEff" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="PedEmrgcySterEff"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>-->
        </div>
        </div>
</form>
