import { QueryList, ViewChildren, AfterViewInit, OnInit, Input, ViewChild, ElementRef, OnDestroy, Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, UrlTree, Router, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { TableFieldElements } from 'src/app/models/table-field-elements';
import { TableId, FormName, RBISDataValue, CheckCaseFragment, KeyCode, DBMode } from 'src/app/models/enums/app.enums';
import { ViolatedRules } from 'src/app/models/violated-rules';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';

import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { UtilService } from 'src/app/services/util.service';
import { TextFieldComponent } from 'src/app/components/textfield/textfield.component';
import { AutofillService } from '../services/autofill.service';
import { UIElementBase } from './UIElementBase';
import { RuleFieldMetaData } from '../models/rulefield-metadata';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { UrlTreeHelper } from './UrlTreeHelper';
import { EditCheckHelper } from './EditCheckHelper';
import { Acc } from 'src/app/models/acc';
import { CaseService } from '../services/case.service';
import { ReplaceTextPipe } from '../pipes/replaceText.pipe';
import { DecimalPipe } from '@angular/common';
import { usp_EDT_GetAttributeMatchLevels_Result } from '../models/usp_EDT_GetAttributeMatchLevels_Result';
import { LookupTable } from '../models/enums/Generated/LookupTable';
import { usp_EDT_GetStylesByStateNum_Result } from '../models/usp_EDT_GetStylesByStateNum_Result';
import { EdtConfidenceColorComponent } from '../components/edt-confidence-color/edt-confidence-color.component';
import { AttributeMatchLevel } from '../models/attr-match-level';
import { ObjectUtil } from './objectUtil';
import { GetCaseBlanks_Result } from '../models/GetCaseBlanks_Result';

/**
 * Provides data model to all deriving components via BaseComponent.acc, which is populated by resolver that fires CaseService.subCase.next()
 **/
@Component({
    selector: 'basecomp',
    template: '<div></div>'
})
export abstract class BaseComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChildren(NgForm) public arrInputForms: QueryList<NgForm>;
    @ViewChildren(TextFieldComponent) protected textFieldComponentList: QueryList<TextFieldComponent>;


    public intMode: number;
    public intStateNum: number;
    private sbsViewInit: Subscription;
    private sbsEvtMarkFormDirty: Subscription;
    public sbsFocusBlanksOrViolatedField: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public acc: Acc;
    violatedRule: ViolatedRules;

    //public get blnReadOnly() { return UIElementBase.blnReadOnly; }
    public get blnReadOnly() {
        return UIElementBase.blnReadOnly || this.isReadOnly;
    }
    public set blnReadOnly(isReadOnly: boolean) {
        this.isReadOnly = isReadOnly;
    }
    public blnAlloweSave: boolean = true;
    protected blnFocusOnFirstField: boolean = true;
    public abstract onBeforeSave(strParam: string): Promise<void>;

    public _editCheckHelper: EditCheckHelper;
    arrUrlSegments: UrlSegment[];
    _arrAttributeLevel: usp_EDT_GetAttributeMatchLevels_Result[];
    subscription: Subscription = new Subscription();
    private sbsEdtSubscription: Subscription = new Subscription();
    objState: DrpDownOptions;
    _arrStateStyles: usp_EDT_GetStylesByStateNum_Result[];

    public strCurrentTab: string = '';

    //for print only
    @Input() printOnly: boolean = false;
    @Input() clearFieldData: boolean = false;
    @Input() printVehNum: number = 0;
    @Input() blnMultipleRacePrintForm: boolean = false;
    @Input() public printNonOccupantNum: number = 0;
    @Input() public personNum: number = 0;

    //for reaonly
    private isReadOnly: boolean = false;

    constructor(protected _activatedRoute: ActivatedRoute,
        protected _sharedService: SharedDataService,
        protected _modalService: ModalService,
        protected _utilBaseService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper,
        protected _caseService: CaseService
    ) {
        this.sbsEvtMarkFormDirty = UIElementBase.evtMarkFormDirty.subscribe((() => {
            this.MarkFormAsDirty();
        }).bind(this));

        this._caseService.subCase.subscribe(((objAcc: Acc) => { //Activated route resolver will call CaseService.GetCasePromise(), which in turn will fire CaseService.subCase.next()
            if (objAcc != null) //Initial value of CaseService.subCase is null
                this.acc = objAcc;
        }).bind(this));

        this.sbsViewInit = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            this.sbsViewInit.unsubscribe();
            this.sbsViewInit = null;
            let arrFields: Array<UIElementBase> = AutofillService.arrControls;

            console.debug(`BaseComponent.ctor.sbsViewInit: this.blnFocusOnFirstField (${this.blnFocusOnFirstField}) AutofillService.arrControls.length(${AutofillService.arrControls.length})`);

            if (this.blnFocusOnFirstField && arrFields.length > 0) {
                let objFirstEnabled: UIElementBase = arrFields.find(x => !x.blnDisabled);

                if (objFirstEnabled && objFirstEnabled.objTextbox) {
                    console.debug(`BaseComponent.ctor.sbsViewInit: giving focus to first enabled control: (${objFirstEnabled.id})`);
                    objFirstEnabled.objTextbox.nativeElement.focus();
                }
            }
        }).bind(this));

        if (!this.printOnly)
            this._editCheckHelper = new EditCheckHelper(_sharedService, _modalService, _urlTreeHelper);
    }

    public ngAfterViewInit() {
        this.FindBlankOrViolatedUIElement();
        if (ObjectUtil.GetTypeName(this) != 'ActionButtonsComponent') { 
            this._sharedService.subReEvalCaseActionButtons.next({ objComponent: [this], objCase: null });
        }
    }

    ngOnInit(): void {

        this.sbsFocusBlanksOrViolatedField.next(false);
        this.arrUrlSegments = this._urlTreeHelper.arrUrlSegment;
        this.strCurrentTab = this.arrUrlSegments[this.arrUrlSegments.length - 1].path;

        this._sharedService.GetAppSettings().then((appSetting: AppSettings) => {

            this.intMode = appSetting ? appSetting.intMode : DBMode.All;

            this._utilBaseService.formDrpDownOptionShare.subscribe(objResult => {
                if (objResult) {
                    let intStateNum: number = UrlTreeHelper.FindAndSetStateNum('case', this.arrUrlSegments);

                    let objState: DrpDownOptions;
                    if (this.intMode == DBMode.FARS || this.intMode == DBMode.MTSS || this.intMode == DBMode.PPSS || this.intMode == DBMode.MOSS ) {
                        objState = objResult.filter(x => x.tblName == LookupTable.StateNum).find(i => i.intValue == intStateNum);
                    }
                    else if (this.intMode == DBMode.CRSS) {
                        let arrPSUs: DrpDownOptions = objResult.find(i => i.tblName == LookupTable.VR_PSU && i.intValue == intStateNum);
                        if (arrPSUs) {
                            objState = objResult.find(i => i.PSUStateAbbr == arrPSUs.PSUStateAbbr);
                        }
                    }

                    if (objState != undefined)
                        this.intStateNum = objState.intValue;
                    //let blnEnableEDT: boolean = objState && this.intMode == DBMode.CRSS ? objState.ENABLE_CRSS_EDT : objState.ENABLE_EDT;

                    if (this._sharedService.IsEDTCase(this.acc)) {
                        this._utilBaseService.GetStateEDTStyle(objState.intValue).toPromise()
                            .then((arrStyle: usp_EDT_GetStylesByStateNum_Result[]) => {
                                this._arrStateStyles = arrStyle;
                                this.BindElementEDTColor(objState.intValue);
                            });


                        //Moh: 04-21-2020 : 8
                        //Moh: 04-22-2020 : 8
                        //this.RefreshCache(objState.intValue, this.strCurrentTab);
                    }
                }
            });

        });
    }


    /**
     * DEPRECATED
     * It is a common event binding method to bind the selected values from typeahed component to parent component
     * $event : DrpDownOptions -> Selected item on typeahead component
     * fieldName : string -> it should be passed through the event binding on parent html -- > (bindModel)="setObjectValue($event, 'County')"
     *  It is the query to find the metadata of an element.
     *   SELECT  *
     *   FROM    FARS2020.meta.TableFieldElements
     *   WHERE   Field = <fieldName>
     * objMetadata : TableFieldElements[] -> Metadata of the form
     **/
    public setObjectValue(objSelection: DrpDownOptions, fieldName: string, objModel: any, formId: number = null) {
        this._utilBaseService.metaDataToShare.subscribe(result => {
            let objmetaData: TableFieldElements = formId !== null
                ? result.find(x => x.FormID == formId && x.Field.toUpperCase() == fieldName.toUpperCase())
                : result.find(x => x.Field.toUpperCase() == fieldName.toUpperCase());

            if (objmetaData != undefined) {
                if (objModel !== null && objModel !== undefined) {
                    let tblfieldId: string = objmetaData.FieldID;
                    let fieldId: string = tblfieldId;//.toInterfacePropertyName();
                    if (objModel.hasOwnProperty(fieldId)) {
                        objModel[fieldId] = objSelection.intValue;
                    }
                    else {
                        console.log("Object doesn't have property: " + fieldId, objModel);
                    }
                }
            }
            else {
                console.log("Object is null or undefined: ");
            }
        });
    }

    /**
     * DEPRECATED: ngInit() is fired by the Angular runtime, there is no need to call it manually. If prerequisite initialization must occur, then it should be awaited so that 2nd call to ngInit() is not needed.
     */
    public initParentChildComponents(objectModel: any = null) { }

    /**
     * DEPRECATED: It is triggered ngInit cycle of the component now usage changed with focusFirstOrBlankOrViolatedUIElement and is being called in OnInit.
     */
    public focusFirstOrBlankOrViolatedElement() { }

    /**
     * DEPRECATED: If the active element is an INPUT, then select the INPUT contents so that when user starts typing they replace, rather than append to the previous INPUT contents.
     */
    public selectCurrentInput(inputElements: NodeListOf<any>) {
        if ((<any>document.activeElement).select)
            (<any>document.activeElement).select();
        /*
        let focusInputElements = this.getFormInputElements(inputElements);

        let index = focusInputElements.indexOf(document.activeElement);
        let currentElement = focusInputElements[index];

        if (index < focusInputElements.length - 1) {
            if (currentElement.value.length > 0) {
                currentElement.select();
            }
        }
        */
    }

    /**
     * DEPRECATED:
     */
    public getFormInputElements(elementList: NodeListOf<any> = null, blnRemoveInvalidClass: boolean = false): any {

        let inputElements = elementList == null ? window.document.querySelectorAll('input, select, textarea, .for-focus') : elementList;
        //It is for ticket# 5451 - Input fields are showing -1.
        inputElements.forEach(elm => { //TODO: Rework this, the INPUT element encapsulated in TypeaheadComponent belongs to UIElementBase, this component should not be manipulating what is essentially a private property
            if (elm.value === RBISDataValue.Blank) {
                elm.value = null;

            }
        });
        var focusInputElements = Array.prototype.filter.call(inputElements,
            function (element) {
                if (!blnRemoveInvalidClass)
                    element.classList.remove("is-invalid");
                //console.log('Input Element name', element.name);
                return (element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement)
                    && (element.hidden == false && element.disabled == false && element.id != '' && !element.classList.contains('excludeInput'));

            });

        return focusInputElements;
    }

    public focusNextInput(inputElements: NodeListOf<any> = null) {
        let focusInputElements = this.getFormInputElements(inputElements);
        let index = focusInputElements.indexOf(document.activeElement);
        let nextElement = focusInputElements[index + 1];

        if (index < focusInputElements.length - 1) {
            if (nextElement.value.length > 0 && nextElement.localName !== "select") {
                nextElement.select();
            }
            nextElement.focus();
        }
    }

    public focusPrevInput(inputElements: NodeListOf<any> = null) {
        let focusInputElements = this.getFormInputElements(inputElements);

        let index = focusInputElements.indexOf(document.activeElement);
        let prevElement;

        if (index == 0) {
            prevElement = focusInputElements[index];
        }

        if (index > 0) {
            prevElement = focusInputElements[index - 1];
        }

        if (index < focusInputElements.length - 1) {
            if (prevElement.value.length > 0) {
                prevElement.select();
            }
            prevElement.focus();
        }
    }



    /**
     *Helper method to focus the first element in a form.
     * */
    public focusAndSelectFirstElement() {
        let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            subInit.unsubscribe();
            let uiControl: UIElementBase = AutofillService.arrControls[0];
            uiControl.focusUIElement();

        }).bind(this));
    }

    /**
     * The metheod is being called in OnInit cyle of every form to find an highlight the blank or violated field(s) which is navigated through the check case tabs. 
     **/
    public FindBlankOrViolatedUIElement() {
        this._activatedRoute.fragment.subscribe((fragment: string) => {
            if (fragment !== undefined && fragment !== null && fragment !== '') {
                this.sbsFocusBlanksOrViolatedField.next(true);
                let objCheckBlank: GetCaseBlanks_Result = this._sharedService.getCaseBlank();

                if (objCheckBlank) {
                    this.blnFocusOnFirstField = false;
                    EditCheckHelper.FocusBlankElement(objCheckBlank);
                }
            }
            else {
                this._sharedService.getViolatedRule().then(objViolatedRule => {
                    this.violatedRule = objViolatedRule;

                    if (this.violatedRule) {
                        this.blnFocusOnFirstField = false;
                        this.sbsFocusBlanksOrViolatedField.next(true);
                        this._editCheckHelper.FindAndHighlightEditCheckElement(this.violatedRule);
                    }
                });
            }
        });
    }

    public ClearHighligtedBlankElement(): void {
        this._activatedRoute.fragment.subscribe((fragment: string) => {
            if (fragment) {
                if (fragment) {
                    let arrUiControl: UIElementBase[] = AutofillService.arrControls.filter(i => i.objMetaData.Field.toLowerCase() == fragment.toLowerCase());
                    if (arrUiControl)
                        arrUiControl.forEach((objControl: UIElementBase) => {
                            objControl.blnClearHighlighting = true;
                            objControl.setIsInvalidClass(false);
                        });
                }
            }
        });
    }

    //Moh: 04-21-2020
    //private cache$: Observable<usp_EDT_GetAttributeMatchLevels_Result[]>;

    //public RefreshCache(intStateNum: number, strCurrentTab: string) {
    //    const interval$ = interval(60 * 60 * 1000).pipe(
    //        tap(i => console.log('Refresh Cache', i))
    //    );

    //    const multicastedInterval$ = interval$.pipe(
    //        shareReplay(1)
    //    );

    //    const subOne = multicastedInterval$
    //        .subscribe(() => {
    //            subOne.unsubscribe();
    //            this.cache$ = null;
    //            this.GetStateAttributeMatchLevelsByFormName(intStateNum, strCurrentTab);
    //        });
    //}

    //public GetStateAttributeMatchLevelsByFormName(intStateNum: number, strCurrentTab: string) {

    //    if (!this.cache$) {
    //        this.cache$ = this._utilBaseService.GetStateAttributeMatchLevelsByFormName(intStateNum, this.strCurrentTab, 'false', 'false');
    //    }
    //    return this.cache$;
    //}

    public BindElementEDTColor(intStateNum: number) {
        if (this._sharedService.IsEDTCase(this.acc)) {

            let sbsViewInit = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                sbsViewInit.unsubscribe();
                let arrUIControls: Array<UIElementBase> = AutofillService.arrControls;
                this._activatedRoute.queryParams.subscribe((params: Params) => {
                    let strByPassCaching: string = params && params.ByPassCaching ? params.ByPassCaching : 'false';
                    this._utilBaseService.GetStateAttributeMatchLevelsByFormNamePromise(intStateNum, this.strCurrentTab, strByPassCaching)
                        .then((arrAttrMatchLevel: AttributeMatchLevel[]) => {
                            if (arrUIControls) {
                                arrUIControls.forEach((control: UIElementBase) => {
                                    control.BindElementEDTColor(intStateNum, this._arrStateStyles, arrAttrMatchLevel);
                                });
                            }
                        });
                });
            }).bind(this));
        }
    }

    /**
     * Helper method to remove the red highlighting in a violated UI element and to remove the violation description on top of the form.
    */
    public clearViolatedElement() {
        this._editCheckHelper.ClearViolatedElement();
    }

    /**
     * NgForm will ignore calls to FormGroup.markAsDirty() if none of the child form fields are touched, unless we force it to raise the "valueChanges" event.
     * Since some operations, like adding or deleting grid rows are not setting any child form fields, we need to force ngForm to raise the "valueChanges" event.
     * Similarly NgForm will ignore FormGroup.markAsPristine() if child fields are not touched.
     **/
    public MarkFormAsDirty(): void {
        for (let objForm of this.arrInputForms.toArray()) {
            objForm.form.markAsDirty();
            objForm.form.updateValueAndValidity({ onlySelf: true, emitEvent: true }); //Force raise "valueChanges" event
        }
    }

    public ngOnDestroy(): void {
        if (this.sbsViewInit)
            this.sbsViewInit.unsubscribe();
        if (this.sbsEvtMarkFormDirty)
            this.sbsEvtMarkFormDirty.unsubscribe();
        if (this.subscription)
            this.subscription.unsubscribe();
        if (this.sbsEdtSubscription)
            this.sbsEdtSubscription.unsubscribe();
    }

    showAttributeInfo(options: any, displayText:string = ''): void {
        console.log(options);
        this._modalService.openAttributeInfoModal(options, undefined, displayText);
    }
}
