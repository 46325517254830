import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from '../../../../services/case.service';
import { UtilService } from '../../../../services/util.service';
import { Veh } from '../../../../models/veh';
import { DrpDownOptions } from '../../../../models/drp-down-options';
import { RBISDataValue } from '../../../../models/enums/app.enums';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { Subscription } from 'rxjs';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { AutofillService } from 'src/app/services/autofill.service';
import { Element_Specify } from 'src/app/models/element-specify';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { ModalsWeightCalculatorComponent } from 'src/app/components/modals/modals-weight-calculator/modals-weight-calculator.component';
import { LookupTable} from 'src/app/models/enums/Generated/LookupTable';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'app-vehicle-vehicle-details',
    templateUrl: './vehicle-vehicle-details.component.html',
    styleUrls: ['./vehicle-vehicle-details.component.css']
})

export class VehicleVehicleDetailsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_VehicleVehicleDetailsComponent: string = null;
    private sbsAfterSave: Subscription = null;
    private sbsVehId: Subscription = null;
    private intHazmat3Original: number;
    accid: number;
    vehicleid: number;
    veh: Veh;
    @ViewChild('txtHazMat3') private txtHazMat3: TypeaheadComponent;
    @ViewChild('fvvc') objForm: NgForm;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;

    objInstanceElementSpecify: Element_Specify;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.sbsAfterSave = this._sharedDataService.observeSave.subscribe(((blnSaveSuccess: boolean) => {
            if(blnSaveSuccess)
                this.intHazmat3Original = this.veh.HazMat3;
        }).bind(this));

        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe((params => {
                this.accid = + params['caseid'];

                if (this.sbsVehId)
                    this.sbsVehId.unsubscribe();

                this.sbsVehId = this._route.parent.parent.params.subscribe((params => {
                    this.vehicleid = + params['vehicleid'];
                    this.initData();
                }).bind(this));
            }).bind(this));
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
             
        this.intHazmat3Original = this.veh.HazMat3;
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);

    }

    public ValidateHazMat3Range(objNewSelection: DrpDownOptions): void {
        if (objNewSelection.intValue != RBISDataValue.Blank &&
            objNewSelection.intValue != 0 &&
            objNewSelection.intValue != 8888 &&
            !(objNewSelection.intValue >= 4    && objNewSelection.intValue <= 3600) &&
            !(objNewSelection.intValue >= 9000 && objNewSelection.intValue <= 9500)
            ) {
            this.txtHazMat3.setCurrentValue(this.intHazmat3Original.toString());
            this._modalService.setMessage('"HM3: Hazardous Material ID Number" allowed range of values is 0000, 0004-3600, 8888, 9000-9500', 'warning');
            this.txtHazMat3.focusUIElement();
        }
    }

    setWeightValue(event) {
         this.veh.Veh_MTSS.CargoWeight = event;
        let result = event;
        result = String(result);
        // this.focuseOut = true;
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.CargoWeight);
        console.log(this.typeaheads);

        if (component) {
            console.log(event);
            component.setCurrentValue(result);
            component.onBlur(result);
            this.MarkFormAsDirty();
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.sbsAfterSave)
            this.sbsAfterSave.unsubscribe();
    }
}
