<form novalidate #fnonOccNonOcc="ngForm">
    <div class="pt-2" *ngIf="nonOccupant && intMode">
        <div [ngClass]="{'row' : printOnly === true}">

            <!--<div [ngClass]="{'col-8 border border-dark' : printOnly === true}">
        <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
            <app-typeahead id="StrikVeh" name="StrikVeh" typeAheadPosition="right-top" strFilterCondition=' AND ID <= {{intNumberOfVehicle}}'
                           strDefsLookupTableName="StrikVeh"
                           [objModel]="nonOccupant"
                           strTableName="Non_Occupants" strFieldName="StrikVeh"
                           [printOnly]="printOnly"
                           [showAllDataListForPrintOnly]="false"
                           [clearFieldData]="clearFieldData">
            </app-typeahead>
        </div>
    </div>-->
            <div #typAhdStrikVeh *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="StrikVeh" name="StrikVeh" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="StrikVeh"
                                   [printOnly]="printOnly" strFilterCondition=' AND ID <= {{intNumberOfVehicle}}'
                                   strDefsLookupTableName="StrikVeh"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-4 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div [ngClass]="{'row' : printOnly !== true}">

                        <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                            <div [ngClass]="{'px-2' : printOnly !== true }">
                                <app-label class="my-auto" inputName="Age" [printOnly]="printOnly"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-modals-age-calculator #ageCalculator *ngIf="!blnReadOnly"
                                                           name="nonOccupantAge"
                                                           [nonOccObj]="nonOccupant"
                                                           [accDate]="accDate"
                                                           [occupantDateOfBirth]="nonOccupantDateOfBirth"
                                                           (newDOBEvent)="setDateOfBirth($event)">
                                </app-modals-age-calculator>
                            </span>
                        </div>

                        <div [ngClass]="{'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : printOnly !== true }">
                            <div [ngClass]="{'pl-2' : printOnly !== true}">
                                <app-typeahead id="NonOccAge" name="NonOccAge" [blnShowSpecialValuesOnly]="true"
                                               typeAheadPosition="right-top" [disableLabel]="true"
                                               [objModel]="nonOccupant" #ageField (keyup)="handleKeyup($event)"
                                               strTableName="Non_Occupants" strFieldName="Age"
                                               [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="nonsex" name="nonsex" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="Sex"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="nonpType" name="nonpType" typeAheadPosition="right-top" strFilterCondition=" AND Type = 2" strDefsLookupTableName="PType"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="PType"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdNonOccRF *ngIf="intMode == 256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group my-auto">
                    <div [ngClass]="{'mb-2 pl-2' : !printOnly}">
                        <app-multiselect id="nonOccRF"
                                         name="nonOccRF"
                                         multiSelectPosition="right-top"
                                         strTableName="NonOccRF"
                                         strFieldName="PRF"
                                         [objTemplateModel]="objNonOccPRFTemplate"
                                         [objModel]="nonOccupant.NonOccRF"
                                         [showAllDataListForPrintOnly]="false"
                                         [clearFieldData]="clearFieldData"
                                         [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                         strDefsLookupTableName="PRF"
                                         strFilterCondition=" AND PRFTYPE in (0,2)"
                                         intSeqNum="1"
                                         [printOnly]="printOnly">
                        </app-multiselect>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="noninjury" name="noninjury" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="Injury" (bindModel)="OnModelChange($event)"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdTakeHosp *ngIf="intMode == 128 || intMode == 256" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="takeHosp" name="takeHosp" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="TakeHosp"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdPRF *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group my-auto">
                    <div [ngClass]="{'mb-2 pl-2' : !printOnly}">
                        <app-multiselect id="nonOccRF" name="nonOccRF" strFilterCondition=" AND PRFTYPE in (0,2)" strDefsLookupTableName="PRF"
                                         multiSelectPosition="right-top"
                                         [objModel]="nonOccupant.NonOccRF"
                                         strTableName="NonOccRF"
                                         strFieldName="PRF"
                                         [objTemplateModel]="objNonOccPRFTemplate"
                                         intSeqNum="1"
                                         [printOnly]="printOnly"
                                         [showAllDataListForPrintOnly]="false"
                                         [clearFieldData]="clearFieldData">
                        </app-multiselect>
                    </div>
                </div>
            </div>

            <div #typAhdnonOccCondAtCrashTime *ngIf="intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group my-auto">
                    <div [ngClass]="{'mt-2 mb-2 pl-2' : !printOnly}">
                        <app-multiselect id="nonOccCondAtCrashTime"
                                         name="nonOccCondAtCrashTime"
                                         [objModel]="nonOccupant.NonOcc_CondAtCrashTime"
                                         strTableName="NonOcc_CondAtCrashTime"
                                         [objTemplateModel]="objNonOccCondAtCrashTimeTemplate"
                                         strFieldName="ElementValue"
                                         [printOnly]="printOnly"
                                         [clearFieldData]="clearFieldData">
                        </app-multiselect>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!printOnly">
                <div *ngIf="blnPIIVisible && mode === 1">
                    <div class="border-top border-secondary mt-2">

                        <div class="form-group">
                            <div class="row">
                                <span class="pl-2">
                                    <label class="col-12 col-form-label fw-bold">Person Identification Information</label>
                                </span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <div class="pl-2">
                                        <app-label class="my-auto" inputName="PersonName"></app-label>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="pii">
                                    <input id="PName" type="text" class="form-control" name="PName" [ngModel]="pii.PName" [disabled]="blnReadOnly"
                                           (ngModelChange)="pii.PName = $event; onPIINameChange($event)" maxlength="50" />
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <div class="pl-2">
                                        <app-label class="my-auto" inputName="DoB"></app-label>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="mm/dd/yyyy" id="nonOccupantDateOfBirth" name="nonOccupantDateOfBirth"
                                                   [ngClass]="{'is-invalid': blnShowValidationMessageNonOcc}"
                                                   [ngModel]="nonOccupantDateOfBirth" (ngModelChange)="onDateSelect(dpDOB, $event)" (blur)="onBlur(dpDOB)"
                                                   [minDate]="getMinDate" [maxDate]="getMaxDate" maxlength="10"
                                                   [autoClose]="inside" (dateSelect)="oage.close()"
                                                   ngbDatepicker
                                                   #dpDOB="ngModel" #oage="ngbDatepicker"
                                                   [disabled]="blnReadOnly" />
                                            <div class="input-group-append">
                                                <button class="btn btn-light border-secondary" (click)="oage.toggle()" type="button" [disabled]="blnReadOnly">
                                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <div class="pl-2">
                                        <app-label class="my-auto" inputName="Comment1"></app-label>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="pii">
                                    <textarea id="Comment1" type="text" rows="2" class="form-control" name="Comment1" [ngModel]="pii.Comment1" [disabled]="blnReadOnly"
                                              (ngModelChange)="pii.Comment1 = $event; onPIIComment1Change($event)" maxlength="50">
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <div class="pl-2">
                                        <app-label class="my-auto" inputName="Comment2"></app-label>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="pii">
                                    <textarea id="Comment2" type="text" rows="2" class="form-control" name="Comment2" [ngModel]="pii.Comment2" [disabled]="blnReadOnly"
                                              (ngModelChange)="pii.Comment2 = $event; onPIIComment2Change($event)" maxlength="50">
                                    </textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
        </div>
        </div>
</form>
