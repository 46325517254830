import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { SharedDataService, AppSettings } from './shared-data.service';
import { RbisUser } from '../models/rbis-user';

@Injectable({
    providedIn: 'root'
})
export class GeolocatorService {
    public Mode: number;
    public userId: number;
    public rootUrl: string;
    public returnUrl: string;

    constructor(
        private _authService: AuthService,
        private _sharedDataService: SharedDataService
        )
    {
        this.returnUrl = location.href + '/';

        this._authService.GetUserPromise().then(((result: RbisUser) => {
            this.userId = result.UserId;
        }).bind(this));

        this._sharedDataService.GetAppSettings().then(((appSetting: AppSettings) => {
            this.Mode = appSetting.intMode;
        }).bind(this));
    }
}
