<ng-template #CaseStructure let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Case Structure</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form #mcscontrol="ngForm">
            <div class="form-group">
                <div *ngIf="isCaseInvalid==true">
                    <div class="alert alert-danger mt-1" *ngIf="isInvalidSFRv" role="alert">
                        Please enter valid Struck Subject Vehicles Occupant.
                    </div>
                    <div class="alert alert-danger mt-1" *ngIf="isInvalidSv" role="alert">
                        Please enter valid Striking Vehicles Occupant.
                    </div>
                </div>
            </div>

            <div class="form-group mb-0">
                <div class="pt-1 pb-1">
                    <div class="row">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-6 my-auto">
                            <span class="pl-2">
                                <label class="">Number of Struck Subject Non-Occupants</label>
                            </span>
                        </div>
                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-6 my-auto">
                            <div class="form-inline">
                                <input type="text"
                                       id="NoofFSFRv"
                                       name="NoofFSFRv"
                                       class="form-control for-focus w74"
                                       [(ngModel)]="earlyNotification.NoofFirstResponderSFRv"
                                       #txtsfrvNonOcc="ngModel"
                                       [ngClass]="{ 'is-invalid': txtsfrvNonOcc.invalid  && (txtsfrvNonOcc.dirty || txtsfrvNonOcc.touched) }"
                                       [numeric]
                                       numericType="number"
                                       maxlength="2"
                                       required>
                                <div class="my-auto" style="width: 15px"></div>
                                <div *ngIf="txtsfrvNonOcc.invalid  && (txtsfrvNonOcc.dirty || txtsfrvNonOcc.touched)">
                                    <div class="alert alert-danger" role="alert" *ngIf="txtsfrvNonOcc.errors.required">
                                        Please enter valid number
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="form-group mb-0">
                <div class="pt-1 pb-1">
                    <div class="row">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-6 my-auto">
                            <span class="pl-2">
                                <label class="">Number of Subject Non-Occupants Non-Struck</label>
                            </span>
                        </div>
                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-6 my-auto">
                            <div class="form-inline">
                                <div class="my-auto" style="width: 15px"></div>
                                <input #txtnsfrvNonOcc type="text" class="form-control for-focus w74" id="NoofFNSFRv" name="NoofFNSFRv" [(ngModel)]="earlyNotification.NoofFirstResponderNSFRv">
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->

            <div class="form-group mb-0" *ngIf="dmySFRv.length>0">
                <div class="pt-1 pb-1">
                    <label class="">Struck Subject Vehicles:</label>
                    <div class="row" *ngFor="let item of dmySFRv; let i = index">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-6 my-auto">
                            <span class="pl-2">
                                <label class="">Number of Occupants for Struck Subject Vehicle {{ i + 1 }} </label>
                            </span>
                        </div>
                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-6 my-auto">
                            <div class="form-inline">
                                <input type="text"
                                       id="SFRV{{i}}"
                                       name="SFRV"
                                       class="form-control for-focus w74"
                                       #txtsfrv="ngModel"
                                       [ngModel]="SFRV"
                                       (ngModelChange)="onchangeSFRV($event,i)"
                                       (change)="true"
                                       [numeric]
                                       numericType="number"
                                       maxlength="2"
                                       required />
                                <div class="my-auto" style="width: 15px"></div>
                                <!--<div *ngIf="txtsfrv.invalid  && (txtsfrv.dirty || txtsfrv.touched)">
                                    <div class="alert alert-danger" role="alert" *ngIf="txtsfrv.errors.required">
                                        Please enter valid number
                                    </div>
                                </div>-->
                                                            <!--[ngClass]="{ 'is-invalid': txtsfrv.invalid  && (txtsfrv.dirty || txtsfrv.touched) }" >-->
                                                            <!--<div *ngIf="txtsfrv.invalid  && (txtsfrv.dirty || txtsfrv.touched)" class="alert alert-danger mt-1">
                                    <div *ngIf="txtsfrv.errors.required">Number of Occupants inside each Struck Subject Vehicle is required</div>
                                </div>-->
                            </div>
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>

            <div class="form-group mb-0" *ngIf="dmySv.length>0">
                <div class="pt-1 pb-1">
                    <label class="">Striking Vehicles:</label>
                    <div class="row" *ngFor="let item of dmySv; let i = index">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-6 my-auto">
                            <span class="pl-2">
                                <label class="">Number of Occupants for Striking Vehicle {{ i + 1 }} </label>
                            </span>
                        </div>
                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-6 my-auto">
                            <div class="form-inline">
                                <input type="text"
                                       class="form-control for-focus w74"
                                       id="Sv{{i}}"
                                       name="Sv"
                                       #txtsv="ngModel"
                                       [ngModel]="Sv"
                                       (ngModelChange)="onchangeSv($event,i)"
                                       (change)="true"
                                       [numeric]
                                       numericType="number"
                                       maxlength="2"
                                       required />
                                <div class="my-auto" style="width: 15px"></div>
                                <!--<div *ngIf="txtsv.invalid  && (txtsv.dirty || txtsv.touched)">
                                    <div class="alert alert-danger" role="alert" *ngIf="txtsv.errors.required">
                                        Please enter valid number
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <!--<button [disabled]="!txtsfrvNonOcc.valid" type="button" class="btn btn-sm btn-primary" (click)="modal.close(true)">Save</button>onSubmit(dplvar)-->
                <button type="button" class="btn btn-sm btn-primary" (click)="onModalSubmit();">Save</button>
                <button type="button" class="btn btn-sm btn-light border-secondary" (click)="modal.close(false);onCancel()">Cancel</button>
            </div>            

        </form>
    </div>
</ng-template>

<form name="form" #createCase="ngForm" novalidate>
    <div class="row" *ngIf="earlyNotification">
        <div class="col-xl">
            <div class="card border-dark">
                <!--(window:beforeunload)="checkUnsavedChangesEarlyNotify($event)"-->
                <div class="card-header text-dark">
                    <div class="my-auto">
                        <div class="card-title my-auto pl-2">
                            <h3 class="showhinline">Create Case<span *ngIf="enid">: {{earlyNotification?.ENCaseNum | number : '4.0-0' | replaceTextPipe : ',' : '' }}</span></h3>
                        </div>
                    </div>
                </div>

                <div class="card-body bg-light" style="margin: 0.998px">
                    <div class="row">
                        <div class="col-10">

                            <!--<div class="form-inline" *ngIf="_sharedDataService.selectedState === 0 || !_sharedDataService.selectedState">
        <div class="form-group">
            <label for="selectState" class="">State</label>
            <select id="selectState" name="selectState" class="form-control w100 ml-3" [ngModel]="_sharedDataService.selectedState" (ngModelChange)="onSelectState($event)">
                <option *ngFor="let state of arrStates" [ngValue]="state.intValue">
                    {{state.intValue | enumAsString : 'States' }}
                </option>
            </select>
        </div>
    </div>

    <div class="form-group mb-0">
        <div class="pt-1 pb-1">
            <div class="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                    <span class="pl-2">
                        <app-label inputName="ENAccDate"></app-label>
                    </span>
                </div>
                <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                    <div class="form-inline">
                        <div style="width: 15px">
                            <p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>
                        </div>
                        <div class="input-group ml-1">
                            <div class="input-group-append">
                                <div class="mr-1">
                                    <app-typeahead id="CrashDateMonth" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="earlyNotification.AccMonth" [blnHideSelectedOptionText]="true"
                                                   [objModel]="earlyNotification" strTableName="EarlyNotify" strFieldName="AccMonth">

                                    </app-typeahead>
                                </div>
                                <p class="pt-1 mr-2 ml-1">/</p>
                                <div class="p-0 mr-1">
                                    <div class="p-0 mr-1">
                                        <app-typeahead id="CrashDateDay" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="earlyNotification.AccDay" [blnHideSelectedOptionText]="true"
                                                       [objModel]="earlyNotification" strTableName="EarlyNotify" strFieldName="AccDay"
                                                       [disabled]="earlyNotification.Deleted">
                                        </app-typeahead>
                                    </div>
                                </div>
                                <p class="pt-1 mr-2 ml-1">/</p>
                                <div class="p-0 mr-1">
                                    <input type="text" class="form-control w74" id="CaseYear" name="CaseYear" [(ngModel)]="earlyNotification.CaseYear" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group mb-0">
        <div class="pt-1 pb-1">
            <div class="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                    <span class="pl-2">
                        <app-label inputName="ENAccTime"></app-label>
                    </span>
                </div>

                <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                    <div class="form-inline">
                        <div class="text-danger my-auto" style="width: 15px"></div>

                        <div class="input-group">
                            <div class="input-group-append">
                                <div class="p-0">
                                    <div class="p-0 mr-1">
                                        <app-typeahead id="CrashTimeHr" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="earlyNotification.AccHr" [blnHideSelectedOptionText]="true"
                                                       [objModel]="earlyNotification" strTableName="EarlyNotify" strFieldName="AccHr"
                                                       [disabled]="earlyNotification.Deleted">
                                        </app-typeahead>
                                    </div>
                                </div>
                                <p class="pt-1 mr-2 ml-1">:</p>
                                <div class="p-0">
                                    <div class="p-0 mr-1">
                                        <app-typeahead id="CrashTimeMin" typeAheadPosition="right-top" [disableLabel]="true" [(objCurrentValue)]="earlyNotification.AccMin" [blnHideSelectedOptionText]="true"
                                                       [objModel]="earlyNotification" strTableName="EarlyNotify" strFieldName="AccMin"
                                                       [disabled]="earlyNotification.Deleted">
                                        </app-typeahead>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group mb-0">
        <div class="pt-1 pb-1">
            <div class="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                    <span class="pl-2">
                        <app-label inputName="ENNoOfFatals"></app-label>
                    </span>
                </div>
                <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10">
                    <div class="form-inline">
                        <div class="" style="width: 15px">
                            <p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>
                        </div>

                        <app-typeahead id="NoOfFatals" typeAheadPosition="right-top" [disableLabel]="true"
                                       [(objCurrentValue)]="earlyNotification.NoOfFatals" [blnHideSelectedOptionText]="true"
                                       [objModel]="earlyNotification" strTableName="EarlyNotify" strFieldName="NoOfFatals"
                                       [disabled]="isDeleting || isCaseOpen">
                        </app-typeahead>
                    </div>
                    <div *ngIf="f.controls.txtNoOfFatals">
                        <div *ngIf="f.controls.txtNoOfFatals.invalid && (f.controls.txtNoOfFatals.dirty || f.controls.txtNoOfFatals.touched)" class="alert alert-danger mt-1">
                            <div *ngIf="f.controls.txtNoOfFatals.errors?.required">Number of Fatalities is required</div>
                            <div *ngIf="f.controls.txtNoOfFatals.errors?.customMin">Please enter atleast one Fatality.</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group mb-0">
        <div class="pt-1 pb-1">
            <div class="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                    <span class="pl-2">
                        <app-label inputName="ENDateReported"></app-label>
                    </span>
                </div>
                <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10">
                    <div class="form-inline">
                        <div class="" style="width: 15px">
                            <p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>
                        </div>

                        <input type="text" class="form-control for-focus w-auto" id="DateReported" name="DateReported"
                               [ngModel]="earlyNotification.DateReported  | date:'MM/dd/yyyy, h:mm a'" (ngModelChange)="earlyNotification.DateReported = $event"
                               disabled />
                    </div>
                </div>
            </div>
        </div>
    </div>-->

                            <div class="form-group mb-0">
                                <div class="pt-1 pb-1">
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                                            <span class="pl-2">
                                                <app-label inputName="ENState1"></app-label>
                                            </span>
                                        </div>
                                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                                            <div class="form-inline">
                                                <div class="" style="width: 15px">
                                                    <!--<p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>-->
                                                </div>
                                                <!--<input type="text" class="form-control w74" id="stateString" name="stateString"
                           [(ngModel)]="stateString"
                           #stateStringModel="ngModel"
                           required disabled>-->
                                                <select id="selectState" name="selectState" class="form-control w100" [ngModel]="_sharedDataService.selectedState" (ngModelChange)="onSelectState($event)">
                                                    <option *ngFor="let state of arrStates" [ngValue]="state.intValue">
                                                        {{state.intValue | enumAsString : 'States' }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <div class="pt-1 pb-1">
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                                            <span class="pl-2">
                                                <app-label inputName="MOSSCasenum"></app-label>
                                            </span>
                                        </div>
                                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                                            <div class="form-inline">
                                                <div class="" style="width: 15px">
                                                    <!--<p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>-->
                                                </div>
                                                <app-textfield id="MOSSCasenum" name="MOSSCasenum" class="" cssFieldCLass="w200 for-focus"
                                                               [disableLabel]="true"
                                                               [maxlength]="8"
                                                               [blnFormatWithLeading]="false"
                                                               [blnIsNumber]="false"
                                                               [objModel]="earlyNotification"
                                                               [(objCurrentValue)]="earlyNotification.MOSSCasenum"
                                                               strTableName="EarlyNotify" strFieldName="MOSSCasenum"
                                                               #txtStateCaseNumber
                                                               (keypress)="MOSSCasenumPress($event)">
                                                </app-textfield>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class="pt-1 pb-1">
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                                            <span class="pl-2">
                                                <app-label inputName="NoofSv"></app-label>
                                                <label for="NoOfStrikingVeh" class=""></label>
                                            </span>
                                        </div>
                                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                                            <div class="form-inline">
                                                <!--<div class="my-auto" style="width: 15px"></div>-->
                                                <div class="" style="width: 15px">
                                                    <!--<p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>-->
                                                </div>
                                                <app-textfield id="NoOfStrikingVeh" class="" cssFieldCLass="w74 for-focus" numType="number"
                                                               [disableLabel]="true"
                                                               [intCustomMin]="1" [intCustomMax]="20" [maxlength]="2"
                                                               [blnIsNumber]="true"
                                                               [objModel]="earlyNotification" [(objCurrentValue)]="earlyNotification.NoofSv"
                                                               strTableName="EarlyNotify" strFieldName="NoofSv"
                                                               [disabled]="isDeleting"
                                                               #txtNoOfStrikingVeh>
                                                </app-textfield>
                                                <div *ngIf="createCase.controls.txtNoOfStrikingVeh">
                                                    <div *ngIf="createCase.controls.txtNoOfStrikingVeh.invalid && (f.controls.txtNoOfStrikingVeh.dirty || f.controls.txtNoOfStrikingVeh.touched)" class="alert alert-danger mt-1">
                                                        <div *ngIf="createCase.controls.txtNoOfStrikingVeh.errors?.customMin">Please enter number of Striking Vehicle.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class="pt-1 pb-1">
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                                            <span class="pl-2">
                                                <app-label inputName="NoofSFRv"></app-label>
                                                <label for="NoOfStruckFirstResponderVeh" class=""></label>
                                            </span>
                                        </div>
                                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                                            <div class="form-inline">
                                                <div class="" style="width: 15px">
                                                    <!--<p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>-->
                                                </div>
                                                <app-textfield id="NoOfStruckFirstResponderVeh" class="" cssFieldCLass="w74 for-focus" numType="number"
                                                               [disableLabel]="true"
                                                               [intCustomMin]="1" [intCustomMax]="20" [maxlength]="2"
                                                               [blnIsNumber]="true"
                                                               [objModel]="earlyNotification" [(objCurrentValue)]="earlyNotification.NoofSFRv"
                                                               strTableName="EarlyNotify" strFieldName="NoofSFRv"
                                                               [disabled]="isDeleting"
                                                               #txtNoOfStruckFirstResponderVeh>
                                                </app-textfield>
                                                <div *ngIf="createCase.controls.txtNoOfStruckFirstResponderVeh">
                                                    <div *ngIf="createCase.controls.txtNoOfStruckFirstResponderVeh.invalid && (f.controls.txtNoOfStruckFirstResponderVeh.dirty || f.controls.txtNoOfStruckFirstResponderVeh.touched)" class="alert alert-danger mt-1">
                                                        <div *ngIf="createCase.controls.txtNoOfStruckFirstResponderVeh.errors?.customMin">Please enter number of Struck Subject Vehicle.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <div class="pt-1 pb-1">
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                                            <span class="pl-2">
                                                <app-label inputName="NoofNSFRv"></app-label>
                                                <label for="NoOfNonStruckFirstResponderVeh" class=""></label>
                                            </span>
                                        </div>
                                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                                            <div class="form-inline">
                                                <div class="" style="width: 15px">
                                                    <!--<p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>-->
                                                </div>
                                                <app-textfield id="NoOfNonStruckFirstResponderVeh" class="" cssFieldCLass="w74 for-focus" numType="number"
                                                               [disableLabel]="true"
                                                               [intCustomMin]="1" [intCustomMax]="20" [maxlength]="2"
                                                               [blnIsNumber]="true"
                                                               [objModel]="earlyNotification" [(objCurrentValue)]="earlyNotification.NoofNSFRv"
                                                               strTableName="EarlyNotify" strFieldName="NoofNSFRv"
                                                               [disabled]="isDeleting"
                                                               #txtNoOfNonStruckFirstResponderVeh>
                                                </app-textfield>
                                                <div *ngIf="createCase.controls.txtNoOfNonStruckFirstResponderVeh">
                                                    <div *ngIf="createCase.controls.txtNoOfNonStruckFirstResponderVeh.invalid && (f.controls.txtNoOfNonStruckFirstResponderVeh.dirty || f.controls.txtNoOfNonStruckFirstResponderVeh.touched)" class="alert alert-danger mt-1">
                                                        <div *ngIf="createCase.controls.txtNoOfNonStruckFirstResponderVeh.errors?.customMin">Please enter number of Non-Struck Subject Vehicle.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <div class="pt-1 pb-1">
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                                            <span class="pl-2">
                                                <app-label inputName="NoofOv"></app-label>
                                                <label for="NoOfOtherVeh" class=""></label>
                                            </span>
                                        </div>
                                        <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                                            <div class="form-inline">
                                                <div class="" style="width: 15px">
                                                    <!--<p class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </p>-->
                                                </div>
                                                <app-textfield id="NoOfOtherVeh" class="" cssFieldCLass="w74 for-focus" numType="number"
                                                               [disableLabel]="true"
                                                               [intCustomMin]="1" [intCustomMax]="20" [maxlength]="2"
                                                               [blnIsNumber]="true"
                                                               [objModel]="earlyNotification" [(objCurrentValue)]="earlyNotification.NoofOv"
                                                               strTableName="EarlyNotify" strFieldName="NoofOv"
                                                               [disabled]="isDeleting"
                                                               #txtNoOfOtherVeh>
                                                </app-textfield>
                                                <div *ngIf="createCase.controls.txtNoOfOtherVeh">
                                                    <div *ngIf="createCase.controls.txtNoOfOtherVeh.invalid && (f.controls.txtNoOfOtherVeh.dirty || f.controls.txtNoOfOtherVeh.touched)" class="alert alert-danger mt-1">
                                                        <div *ngIf="createCase.controls.txtNoOfOtherVeh.errors?.customMin">Please enter number of Other Contacted Vehicle.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="form-group mb-0">
        <div class="pt-1 pb-1">
            <div class="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                    <span class="pl-2">
                        <app-label inputName="ENRemarks"></app-label>
                    </span>
                </div>
                <div class="col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto">
                    <div class="form-inline">
                        <div class="my-auto" style="width: 15px"></div>
                        <textarea type="text" rows="1" class="form-control for-focus w475" id="Remarks" name="Remarks"
                                  [(ngModel)]="earlyNotification.Remarks"
                                  [ngClass]="{ 'is-invalid': remarks.invalid  && (remarks.dirty || remarks.touched) }"
                                  maxlength="1000"
                                  #remarks="ngModel"
                                  [disabled]="earlyNotification.Deleted">
                        </textarea>
                        <div *ngIf="remarks.invalid  && (remarks.dirty || remarks.touched)" class="alert alert-danger mt-1">
                            <div *ngIf="remarks.errors.maxlength">Comments can be at most 1000 characters long.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
                            <!--<div class="form-group mb-0">
        <div class="text-center">
            <p><span class="text-danger fw-bold col-form-label" style="font-size: x-large"> ~ </span><span class="font-italic">Denotes a Required Field</span></p>
        </div>
    </div>-->
                        </div>

                        <div class="col-2">
                            <div class="border-left border-secondary pl-2">
                                <div class="form-group">
                                    <button type="button" id="btnSaveCaseHeader_MOSS"
                                            class="btn btn-primary btn-block" (click)="onSubmit($event,'save', CaseStructure)"
                                            [disabled]="!createCase.form.valid">Save</button>
                                </div>
                                <div class="form-group">
                                    <button type="button" id="btnCancelEarlyNotify_MOSS" class="btn btn-light border-secondary btn-block" (click)="onCancelENCase()">Cancel</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</form>
