<div *ngIf="intMode" class="border-dark border-start border-bottom border-end px-2">
    <ul class="nav nav-tabs border-dark bg-white">
        <li class="nav-item ml-1 mt-1">
            <a class="nav-link" [routerLink]='["./sfrperson"]'
               [ngClass]="{'bg-light': sfrlinkperson.isActive === true }"
               [routerLinkActive]="['active']" #sfrlinkperson="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': sfrlinkperson.isActive === true, 'fw-bold' : sfrlinkperson.isActive === true}">
                        SS_Occupant
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1 mt-1">
            <a class="nav-link" [routerLink]='["./sfrsafety"]'
               [ngClass]="{'bg-light': linksafetyDevices.isActive === true }"
               [routerLinkActive]="['active']" #linksafetyDevices="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linksafetyDevices.isActive === true, 'fw-bold' : linksafetyDevices.isActive === true}">
                        SS_Safety
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./sfrpersonalcohol"]'
               [ngClass]="{'bg-light': linkpersonAlcohol.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonAlcohol="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonAlcohol.isActive === true, 'fw-bold' : linkpersonAlcohol.isActive === true}">
                        SS_Alcohol
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./sfrpersonnotes"]'
               [ngClass]="{'bg-light': linkpersonNotes.isActive === true }"
               [routerLinkActive]="['active']" #linkpersonNotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkpersonNotes.isActive === true, 'fw-bold' : linkpersonNotes.isActive === true}">
                        SS_Notes
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <!-- Router outlet hosts Vehicle Occupant child route components' HTML output -->
    <router-outlet></router-outlet>
</div>
