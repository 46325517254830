import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, from, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AttributeInfoModalComponent } from '../components/attribute-info-modal/attribute-info-modal.component';

import { MessageBoxComponent } from '../components/message-box/message-box.component';
import { DrpDownOptions } from '../models/drp-down-options';

/**
 * Provides simple modal dialogs, prompts and banner pop-ups that float above the calling page.
 **/
@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private currentMessageSubject: BehaviorSubject<any>;
    private editCheckMessageSubject: BehaviorSubject<any>;
    private static readonly arrAlertTypes: string[] = ['success', 'info', 'warning', 'danger', 'primary', 'secondary', 'light', 'dark'];

    private currentMessage: Observable<any>;
    public wizardType: string = '';

    constructor(public ngModal: NgbModal) {
        this.currentMessageSubject = new BehaviorSubject<any>({ message: '', type: '' });
        this.editCheckMessageSubject = new BehaviorSubject<any>({ message: '', type: '' });
    }

    public get getCurrentMessage(): Observable<any> {
        return this.currentMessageSubject.asObservable();
    }

    public get getCurrentEditCheckMessage(): Observable<any> {
        return this.editCheckMessageSubject.asObservable();
    }


    setMessage(message: string, type: string, intervalSecond: number = 15, blnShowDismissMsg: boolean = true) {
        if (ModalService.arrAlertTypes.indexOf(type.toLowerCase()) == -1) {
            console.info('ngbAlert: There is no alert type "' + type + '". Falling back on type "danger".');
            type = 'danger';
        }

        const newMessage = { message: message, type: type, blnShowDismissMsg: blnShowDismissMsg };
        this.currentMessageSubject.next(newMessage);

        if (intervalSecond > 0) {
            setTimeout(() => { this.resetMessage() }, intervalSecond * 1000);
        }
    }

    setEditCheckMessage(message: string) {
        const newMessage = { message: message, type: 'danger' };
        this.editCheckMessageSubject.next(newMessage);

    }

    resetEditCheckMessage() {
        this.editCheckMessageSubject.next({ message: '', type: '' });
    }

    resetMessage() {
        this.currentMessageSubject.next({ message: '', type: '' });
    }

    /**
     * Shows a modal dialog box. Refer to NgbModalOptions
     * @param boxType: C = Confirm | M = Message | A = Alert | W = Warning | YesNoCancel
     * @param size: sm | lg
     * @param centered: true | false
     * @param scrollable: true | false
     **/
    dialog(body: string = 'Are you sure?', title: string = 'Friendly Message',
        boxType: string = 'C', size: 'sm' | 'lg' = 'sm',
        centered: boolean = true, scrollable: boolean = false,
        strYesText = 'OK', strNoText = 'Cancel', strDismissText = 'Dismiss', blnMergeSuccessAndError: boolean = true): Observable<boolean> {

        const modal = this.ngModal.open(MessageBoxComponent, {
            //Refer to NgbModalOptions
            backdrop: 'static',
            size: 'popup' as 'lg',
            centered: centered
        });

        modal.componentInstance.body = body;
        modal.componentInstance.title = title;
        modal.componentInstance.boxType = boxType;
        modal.componentInstance.module = 'D';
        modal.componentInstance.strYesText = strYesText;
        modal.componentInstance.strNoText = strNoText;
        modal.componentInstance.strDismissText = strDismissText;

        if (blnMergeSuccessAndError)
            return from(modal.result).pipe(
                catchError(error => {
                    console.warn(error);
                    return of(undefined);
                })
            );
        else
            return from(modal.result);
    }

    public dialogPromise(body: string = 'Are you sure?', title: string = 'Friendly Message',
        boxType: string = 'C', size: string = 'sm',
        centered: boolean = true, scrollable: boolean = false): Promise<any> {

        const modal = this.ngModal.open(MessageBoxComponent, { backdrop: 'static', size: 'popup' as 'sm', centered: centered });

        modal.componentInstance.body = body;
        modal.componentInstance.title = title;
        modal.componentInstance.boxType = boxType;
        modal.componentInstance.module = 'D';
        modal.componentInstance.strYesText = 'Yes';
        modal.componentInstance.strNoText = 'No';
        //Dependencies issue comment
        return modal.result;
    }

    public openModal(content): Observable<any> {
        const modal = this.ngModal.open(content, { backdrop: 'static', size: 'lg', centered: true });
        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }

    public openCommonSmallModal(content, className): Observable<any> {
        const modal = this.ngModal.open(content, {
            windowClass: className, backdrop: 'static', size: 'sm', centered: true
        });

        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }
    public alertPromise(body: string = 'Something is missing please check?'): Promise<any> {

        const modal = this.ngModal.open(MessageBoxComponent, { backdrop: 'static', size: 'popup' as 'sm', centered: true });

        modal.componentInstance.body = body;
        modal.componentInstance.isAlert = true;
        //Dependencies issue comment
        return modal.result;
    }

    public openAttributeInfoModal(options: DrpDownOptions[], label: any, displayText:string = ''): Promise<any> {
        const modal = this.ngModal.open(AttributeInfoModalComponent, { backdrop: 'static', size: 'lg', centered: true });
        modal.componentInstance.options = options;
        modal.componentInstance.currentLabel = label;
        modal.componentInstance.displayText = displayText;
        

        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        ).toPromise();
    }
}
