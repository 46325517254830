<form novalidate #fvvtrailer="ngForm">
    <div id="vehicle-trailer" class="pt-2" *ngIf="veh">
        <div class="form-group border-dark">
            <div class="" *ngIf="!printOnly">
                <div class="row pl-2">
                    <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 col-form-label fw-bold">Trailer Information</label>
                </div>
            </div>
            <div id="trailer1">
                <div [ngClass]="[!printOnly ? 'form-group border-top border-dark': '']">
                    <div [ngClass]="[!printOnly ? 'pt-1 pb-1' : '']">
                        <div [ngClass]="[!printOnly ? 'row pl-2' : 'row']">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="!printOnly">
                                <app-label inputName="TrailerVIN1"></app-label>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col-xl-3 my-auto': 'col-6  border border-dark']">
                                <app-label inputName="TrailerVIN1" *ngIf="printOnly"></app-label>
                                <div class="input-group">
                                    <app-typeahead id="Trailer1Vin1" name="Trailer1Vin1" #Trailer1Vin1 typeAheadPosition="right-top"
                                                   disableLabel="true" typeAheadClass="form-control w200"
                                                   blnAllowFreeText="true" blnHideSelectedOptionText="true"
                                                   (paste)="OnPasteTrailerVIN($event, 0)"
                                                   [objModel]="veh.Trailer1[0]" strTableName="Trailer" strFieldName="TrailerVIN" [maxlength]="17"
                                                   [(objCurrentValue)]="veh.Trailer1[0].TrailerVIN"
                                                   [intSeqNum]="1"
                                                   (emitObjectOnChange)="onTrailerVINModelChange($event)"
                                                   [objNgModelOptions]="objStandalone"
                                                   [printOnly]="printOnly"
                                                   [clearFieldData]="clearFieldData"
                                                   [blnFormatUpperCase]="true">
                                    </app-typeahead>

                                    <div class="input-group-append" *ngIf="!printOnly">
                                        <button type="button" class="btn btn-primary" (click)="decodeVin('trailerVIN0',veh.Trailer1[0])" [disabled]="!blnTrailerOneDisabled"><span>Decode</span></button>
                                    </div>
                                </div>
                                <div class="input-group mt-2" *ngIf="!printOnly">
                                    <!--<button class="btn btn-sm btn-light border-secondary mr-2" (click)="openVinRequest(veh.Trailer1[0])" [disabled]="!blnTrailerOneDisabled"><span>VIN Request</span></button>-->
                                </div>
                            </div>

                            <div [ngClass]="[!printOnly ? 'col-xl my-auto': 'col-6 border border-dark border-left-0']">
                                <ul class="list-group list-group w-100 p-sm-2 p-lg-2">
                                    <li class="list-group-item p-0 border-0">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Make
                                                <span class="fw-bold">{{ veh.Trailer1[0].TrailerVPICDECODE?.Make }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Body Class
                                                <span class="fw-bold">{{ veh.Trailer1[0].TrailerVPICDECODE?.BodyClass }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Trailer Body Type
                                                <span class="fw-bold">{{ veh.Trailer1[0].TrailerVPICDECODE?.TrailerBodyType }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Model
                                                <span class="fw-bold">{{ veh.Trailer1[0].TrailerVPICDECODE?.Model }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Model Year
                                                <span class="fw-bold">{{ veh.Trailer1[0].TrailerVPICDECODE?.ModelYear !== -1 ? veh.Trailer1[0].TrailerVPICDECODE?.ModelYear : "" }}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                    <div class="form-group">
                        <div class="row">
                            <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                                <div class="pl-2">
                                    <app-label label="Trailer Empty Weight(kgs)" [printOnly]="printOnly" inputName="TrailerEmptyWeight"></app-label>
                                </div>
                                <div [hidden]="!blnTrailerOneDisabled">
                                    <div class="my-auto" *ngIf="!printOnly">
                                        <app-modals-weight-calculator #weightCalculator *ngIf="!blnReadOnly"
                                                                      (submitWeightValue)="setWeightValue($event, 'Trailer1EmptyWeight')" name="weight">
                                        </app-modals-weight-calculator>
                                        </div>
                                    </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="pl-2">
                                    <app-typeahead id="Trailer1EmptyWeight" name="Trailer1EmptyWeight" typeAheadPosition="right-top" searchAll="true"
                                                   disableLabel="true" [objModel]="veh.Trailer1[0]" strTableName="Trailer" strFieldName="TrailerEmptyWeight" [intSeqNum]="1"
                                                   [blnAllowFreeText]="true"
                                                   [printOnly]="printOnly"
                                                   [clearFieldData]="clearFieldData"
                                                   [showAllDataListForPrintOnly]="false">
                                    </app-typeahead>                                
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

            <div id="trailer2">
                <div [ngClass]="[!printOnly ? 'form-group border-top ': 'mt-3']">
                    <div [ngClass]="[!printOnly ? 'pt-1 pb-1' : '']">
                        <div [ngClass]="[!printOnly ? 'row pl-2' : 'row']">

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="!printOnly">
                                <app-label inputName="TrailerVIN2">Trailer VIN #2</app-label>
                            </div>

                            <div [ngClass]="[!printOnly ? 'col-xl-3 my-auto': 'col-6  border border-dark']">
                                <app-label inputName="TrailerVIN2" *ngIf="printOnly"></app-label>
                                <div class="input-group">
                                    <app-typeahead id="Trailer1Vin2" name="Trailer1Vin2" #Trailer1Vin2 typeAheadPosition="right-top"
                                                   disableLabel="true" typeAheadClass="form-control w200"
                                                   blnAllowFreeText="true" blnHideSelectedOptionText="true"
                                                   (paste)="OnPasteTrailerVIN($event, 1)"
                                                   [objModel]="veh.Trailer1[1]" strTableName="Trailer" strFieldName="TrailerVIN" [maxlength]="17"
                                                   [(objCurrentValue)]="veh.Trailer1[1].TrailerVIN"
                                                   [intSeqNum]="2"
                                                   (emitObjectOnChange)="onTrailerVINModelChange($event)"
                                                   [objNgModelOptions]="objStandalone"
                                                   [blnFormatUpperCase]="true"
                                                   [printOnly]="printOnly"
                                                   [clearFieldData]="clearFieldData">
                                    </app-typeahead>

                                    <div class="input-group-append" *ngIf="!printOnly">
                                        <button type="button" class="btn btn-primary" (click)="decodeVin('trailerVIN1',veh.Trailer1[1])" [disabled]="!blnTrailerTwoDisabled"><span>Decode</span></button>
                                    </div>
                                </div>
                                <div class="input-group mt-2" *ngIf="!printOnly">
                                    <!--<button class="btn btn-sm btn-light border-secondary mr-2" (click)="openVinRequest(veh.Trailer1[1])" [disabled]="!blnTrailerTwoDisabled"><span>VIN Request</span></button>-->
                                </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col-xl': 'col-6 border border-dark border-left-0']">
                                <ul class="list-group w-100 p-sm-2 p-lg-2">
                                    <li class="list-group-item p-0 border-0">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Make
                                                <span class="fw-bold">{{ veh.Trailer1[1].TrailerVPICDECODE?.Make }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Body Class
                                                <span class="fw-bold">{{ veh.Trailer1[1].TrailerVPICDECODE?.BodyClass }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Body Type
                                                <span class="fw-bold">{{ veh.Trailer1[1].TrailerVPICDECODE?.TrailerBodyType }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Model
                                                <span class="fw-bold">{{ veh.Trailer1[1].TrailerVPICDECODE?.Model }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Year
                                                <span class="fw-bold">{{ veh.Trailer1[1].TrailerVPICDECODE?.ModelYear !== -1 ? veh.Trailer1[1].TrailerVPICDECODE?.ModelYear : ""}}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                    <div class="form-group">
                        <div class="row">
                            <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                                <div class="pl-2">
                                    <app-label label="Trailer Empty Weight(kgs)" [printOnly]="printOnly" inputName="TrailerEmptyWeight"></app-label>
                                </div>
                                <div [hidden]="!blnTrailerTwoDisabled">
                                    <div class="my-auto" *ngIf="!printOnly">
                                        <app-modals-weight-calculator #weightCalculator *ngIf="!blnReadOnly"
                                                                      (submitWeightValue)="setWeightValue($event, 'Trailer2EmptyWeight')" name="weight">
                                        </app-modals-weight-calculator>
                                    </div>
                                </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="pl-2">
                                    <app-typeahead id="Trailer2EmptyWeight" name="Trailer2EmptyWeight" typeAheadPosition="right-top" searchAll="true"
                                                   [objModel]="veh.Trailer1[1]" strTableName="Trailer" strFieldName="TrailerEmptyWeight" [intSeqNum]="2"
                                                   [blnAllowFreeText]="true" disableLabel="true"
                                                   [printOnly]="printOnly"
                                                   [clearFieldData]="clearFieldData"
                                                   [showAllDataListForPrintOnly]="false">
                                    </app-typeahead>                                
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>

            <div id="trailer3">
                <div [ngClass]="[!printOnly ? 'form-group border-top ': 'mt-3']">
                    <div [ngClass]="[!printOnly ? 'pt-1 pb-1' : '']">
                        <div [ngClass]="[!printOnly ? 'row pl-2' : 'row']">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="!printOnly">
                                <app-label inputName="TrailerVIN3">Trailer VIN #3</app-label>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col-xl-3 my-auto': 'col-6  border border-dark']">
                                <app-label inputName="TrailerVIN3" *ngIf="printOnly">Trailer VIN #3</app-label>
                                <div class="input-group">
                                    <app-typeahead id="Trailer1Vin3" name="Trailer1Vin3" #Trailer1Vin3 typeAheadPosition="right-top"
                                                   disableLabel="true" typeAheadClass="form-control w200"
                                                   blnAllowFreeText="true" blnHideSelectedOptionText="true"
                                                   (paste)="OnPasteTrailerVIN($event, 2)"
                                                   [objModel]="veh.Trailer1[2]" strTableName="Trailer" strFieldName="TrailerVIN" [maxlength]="17"
                                                   [(objCurrentValue)]="veh.Trailer1[2].TrailerVIN"
                                                   [intSeqNum]="3"
                                                   (emitObjectOnChange)="onTrailerVINModelChange($event)"
                                                   [objNgModelOptions]="objStandalone"
                                                   [printOnly]="printOnly"
                                                   [clearFieldData]="clearFieldData"
                                                   [blnFormatUpperCase]="true">
                                    </app-typeahead>
                                    <div class="input-group-append" *ngIf="!printOnly">
                                        <button type="button" class="btn btn-primary" (click)="decodeVin('trailerVIN2',veh.Trailer1[2])" [disabled]="!blnTrailerThreeDisabled"><span>Decode</span></button>
                                    </div>
                                </div>
                                <div class="input-group mt-2" *ngIf="!printOnly">
                                    <!--<button class="btn btn-sm btn-light border-secondary mr-2" (click)="openVinRequest(veh.Trailer1[2])" [disabled]="!blnTrailerThreeDisabled"><span>VIN Request</span></button>-->
                                </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col-xl': 'col-6 border border-dark border-left-0']">
                                <ul [ngClass]="[!printOnly ? 'list-group w-100 p-sm-2 p-lg-2' : 'list-group']">
                                    <li class="list-group-item p-0 border-0">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Make
                                                <span class="fw-bold">{{ veh.Trailer1[2].TrailerVPICDECODE?.Make }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Body Class
                                                <span class="fw-bold">{{ veh.Trailer1[2].TrailerVPICDECODE?.BodyClass }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Trailer Body Type
                                                <span class="fw-bold">{{ veh.Trailer1[2].TrailerVPICDECODE?.TrailerBodyType }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Model
                                                <span class="fw-bold">{{ veh.Trailer1[2].TrailerVPICDECODE?.Model }}</span>
                                            </li>
                                            <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">
                                                Year
                                                <span class="fw-bold">{{ veh.Trailer1[2].TrailerVPICDECODE?.ModelYear !== -1 ? veh.Trailer1[2].TrailerVPICDECODE?.ModelYear : ""  }}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                    <div class="form-group">
                        <div class="row">
                            <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                                <div class="pl-2">
                                    <app-label label="Trailer Empty Weight(kgs)" [printOnly]="printOnly" inputName="TrailerEmptyWeight"></app-label>
                                </div>
                                <div [hidden]="!blnTrailerThreeDisabled">
                                    <div class="my-auto" *ngIf="!printOnly">
                                        <app-modals-weight-calculator #weightCalculator *ngIf="!blnReadOnly"
                                                                      (submitWeightValue)="setWeightValue($event, 'Trailer3EmptyWeight')" name="weight">
                                        </app-modals-weight-calculator>
                                        </div>
                                    </div>
                            </div>
                            <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                                <div class="pl-2">
                                    <app-typeahead id="Trailer3EmptyWeight" name="Trailer3EmptyWeight" disableLabel="true" typeAheadPosition="right-top" searchAll="true"
                                                   [objModel]="veh.Trailer1[2]" strTableName="Trailer" strFieldName="TrailerEmptyWeight" [intSeqNum]="3"
                                                   [blnAllowFreeText]="true"
                                                   [printOnly]="printOnly"
                                                   [showAllDataListForPrintOnly]="false"
                                                   [clearFieldData]="clearFieldData">
                                    </app-typeahead>
                                </div>
                            </div>                        
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
</form>

