<div *ngIf="veh && intMode" class="border-dark border-start border-bottom border-end px-2">
    <ul class="nav nav-tabs px-1 border-dark bg-white">
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./driver"]'
               [ngClass]="{'bg-light': linkdriver.isActive === true }"
               [routerLinkActive]="['active']" #linkdriver="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkdriver.isActive === true, 'fw-bold' : linkdriver.isActive === true}">
                        Driver
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="veh.DrivPres != 0 && veh.DrivPres != 9 && veh.Occupants.length > 0 && (intMode == 1 || intMode == 64|| intMode == 128)">
            <a class="nav-link" [routerLink]='["./license"]'
               [ngClass]="{'bg-light': linklicense.isActive === true }"
               [routerLinkActive]="['active']" #linklicense="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linklicense.isActive === true, 'fw-bold' : linklicense.isActive === true}">
                        License
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item mt-1" *ngIf="veh.DrivPres != 0 && veh.DrivPres != 9 && veh.Occupants.length > 0 && (intMode == 1 || intMode == 64|| intMode == 128)">
            <a class="nav-link" [routerLink]='["./counters"]'
               [ngClass]="{'bg-light': linkcounters.isActive === true }"
               [routerLinkActive]="['active']" #linkcounters="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkcounters.isActive === true, 'fw-bold' : linkcounters.isActive === true}">
                        Counters
                    </span>
                </span>
            </a>
        </li>
        <!-- driverNotes -->
        <li class="nav-item mt-1">
            <a class="nav-link" [routerLink]='["./driverNotes"]'
               [ngClass]="{'bg-light': linkdriverNotes.isActive === true }"
               [routerLinkActive]="['active']" #linkdriverNotes="routerLinkActive">
                <span class="text-dark">
                    <span [ngClass]="{'text-decoration-none': linkdriverNotes.isActive === true, 'fw-bold' : linkdriverNotes.isActive === true}">
                        Notes
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <!--here we generate and show Vehicle Driver's-children html's components-->
    <router-outlet></router-outlet>
</div>
