<div class="modal-header">
    <h4 class="modal-title">Bike Data</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-warning text-center m-2" role="alert" style="height:9rem;font-size:medium;margin-top:-20px" *ngIf="noCopyAvailable">
        There are no other Bikes to copy data. <br />Click Close to return to the main form. <br />
        <button type="button" value="Close" (click)="modal.close(null)">Close </button>
    </div>
    <div class="border rounded p-2 text-center" *ngIf="!noCopyAvailable">
        <p>This window allows you to copy data from previously entered Bikes.</p>

        <div class="p-3 mb-2 bg-secondary text-white"><strong>Select the appropriate Bike </strong></div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col"> </th>
                    <th scope="col">Bike #</th>
                    <th scope="col">Crash Location</th>
                    <th scope="col">Bicyclist Position</th>
                    <th scope="col">Bicyclist Direction</th>
                    <th scope="col">Crash Type</th>
                    <th scope="col">School Zone</th>
                    <th scope="col">Marked Crosswalk</th>
                    <th scope="col">Sidewalk Presence</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of tmpGrid; index as i">
                    <td>
                        <button type="button" value="Select" (click)="modal.close(item.RowItem)">Select </button>
                    </td>
                    <th scope="row">{{ item.PNumber }}</th>
                    <td>
                        {{item.CrashLocation}}
                    </td>
                    <td>
                        {{item.BikePosition}}
                    </td>
                    <td>
                        {{ item.BikeDirection }}
                    </td>
                    <td>
                        {{ item.CrashType }}
                    </td>
                    <td>
                        {{ item.SchoolZone}}
                    </td>
                    <td>
                        {{ item.Crosswalk}}
                    </td>
                    <td>
                        {{ item.Sidewalk}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light" *ngIf="!noCopyAvailable" (click)="modal.close(null)">Close</button>
</div>
