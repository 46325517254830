import { Component, OnInit, AfterViewInit, OnChanges, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

//services
import { AutofillService } from 'src/app/services/autofill.service'; //Instantiate service to prefetch autofill rules in the background
import { GenericService } from 'src/app/services/generic.service';
import { UtilService } from 'src/app/services/util.service';
import { HomeService } from 'src/app/services/home.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';

//models
import { Acc } from 'src/app/models/acc';
import { AttributeMatchLevel } from 'src/app/models/attr-match-level';
import { GetCalendarDetails_Result } from 'src/app/models/get-calendar-details-result';
import { CaseSummaryData } from 'src/app/models/case-summary-data';
import { FarsMetricsDetails } from 'src/app/models/fars-metrics-details';
import { QcBenchmarkComparison } from 'src/app/models/get-qc-benchmark-comparison';
import { Months, CustomNvpFilter, DBMode, KeyCode } from 'src/app/models/enums/app.enums';
import { Element_Specify } from 'src/app/models/element-specify';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { QC_BenchmarkConfig } from 'src/app/models/qc-benchmark-config';

//components
import { BaseComponent } from 'src/app/helper/basecomponent';

import { extend } from 'webdriver-js-extender';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { EditCheckHelper } from 'src/app/helper/EditCheckHelper';
import { Subscription, Observable, pipe } from 'rxjs';
import { usp_EDT_GetAttributeMatchLevels_Result } from 'src/app/models/usp_EDT_GetAttributeMatchLevels_Result';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { CaseLockedViewModel } from 'src/app/interface/CaseLockedViewModel';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { RbisUser } from 'src/app/models/rbis-user';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
    selectedYear: number;
    selectedMode: number;
    strMode: string;

    calendarId: number;
    calenderReminder: GetCalendarDetails_Result[];
    caseSummaryData: CaseSummaryData;
    farsMetricsDetails: FarsMetricsDetails;
    qcBenchmarkComparison: QcBenchmarkComparison;
    objStateElementOtherSpecify: Element_Specify[];

    QC_BenchmarkConfig: QC_BenchmarkConfig;
    arrQC_BenchmarkConfig: Promise<QC_BenchmarkConfig[]>

    arrStates: DrpDownOptions[];
    ddoSelectedState: DrpDownOptions;

    selectedState: number;

    isEDT: boolean;

    fatalsEarlyPercent: number;
    casesEarlyPercent: number;

    today: Date = new Date();
    yestrdayDate: Date;
    nextBenchmark: Date;
    benchmarkPeriod: string;
    benchmarkPeriodMonthFrom: string;
    benchmarkPeriodMonthTo: string;

    inputElements: NodeListOf<any>;
    showRelease: boolean = false;
    searchTextLen: number = 4;
    blnIsEDTstate: boolean;
    blnIsCRSSEDTstate: boolean = false;
    _arrAttributeLevel: AttributeMatchLevel[];
    subscription: Subscription = new Subscription();
    isAuthorized: boolean;
    modeString: string;
    mossSCISiteList: any[];

    strSeachText: string;
    @ViewChild('searchCase') inputSearchCase: ElementRef;
    @ViewChild('searchMossCase') inputSearchMossCase: ElementRef;
    sciSiteID: number;
    isReadOnly: boolean = false;

    constructor(
        private _autofillService: AutofillService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _baseService: GenericService<any>,
        private _utilService: UtilService,
        private _homeService: HomeService,
        public sharedDataService: SharedDataService,
        private _caseService: CaseService,
        private _modalService: ModalService,
        private _secUserPreferenceService: SecUserPerferenceService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _enService: EarlyNotificationService,
        private authService: AuthService,
        private renderer: Renderer2
    ) {
    }   


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    async ngOnInit() {      
       
        let appSettings = await this.sharedDataService.GetAppSettings();
        this.mossSCISiteList = await this.authService.mossSCIsites;
       // this.sciSiteID = this.mossSCISiteList[0];
        //this.LoadSites();
        //if (this.mossSCISiteList.length > 0 && this.mossSCISiteList.find(x=>x == 0).length > 0) {
           console.log(this.mossSCISiteList);
        //    this._router.navigate(['unauthorized']);
        //}

        if (DBMode[appSettings.intMode])
            this.modeString = DBMode[appSettings.intMode];

        this.selectedYear = appSettings.intYear;
        this.selectedMode = appSettings.intMode;
        this.strMode = DBMode[appSettings.intMode];  

        this.strSeachText = '0000';

        let objUser: RbisUser = await this.authService.GetUserPromise();
        this.isReadOnly = objUser.blnReadOnly;

        this._homeService.setAdminRights();
        this._utilService.setSeqEventsList();
        this._utilService.setCrashAvoidCriteria();
        this._secUserPreferenceService.getSecUserPreferences();

        this._homeService.hasAdminRigths.subscribe(result => {
            this.showRelease = result;
        });

        setTimeout(() => {
            let inputElements = window.document.querySelectorAll('input');
            if (inputElements.length > 0 && inputElements !== undefined) {
                inputElements[0].focus();
                inputElements[0].select();
            }
        }, 10);

        this.getCalenderReminder();
        
        this.getSummaryData();

        this.unlockPreviousCase();        
    }
  

    OnModelChange($event) {
        //console.log($event);
    }

    public unlockPreviousCase(): void {
        if (this._router.url == '/') {

            if (this._caseService.acc != null && this._caseService.acc != undefined) {
                this._modalService.setEditCheckMessage('');
                this._caseService.ReleaseLockedCases([{
                    AccID: this._caseService.acc.AccID,
                    EnID: this._caseService.acc.ENID,
                    AppID: "30"
                }] as CaseLockedViewModel[]).then(result => {
                    this._caseService.acc = null;
                })
                this._caseService.acc = null;
            } else {
                // removing current early notificaion
                const earlyNotification = this.sharedDataService.getEarlyNotify();
                if (earlyNotification != null && earlyNotification != undefined) {
                    this._caseService.ReleaseLockedCases([{
                        AccID: -1,
                        EnID: earlyNotification.ENID,
                        AppID: "30"
                    }] as CaseLockedViewModel[]).then(result => {
                        this.sharedDataService.setEarlyNotify(null);
                    })
                }
            }
        }
    }

    onSearch() {
        let CaseNum = this.inputSearchCase.nativeElement.value;
        if (CaseNum) {
            this._caseService.clearAccMemoryForCloseCase();
            this._caseService.intCasenum = CaseNum;
            this._caseService.GetCaseByCaseNumberPromise(parseInt(CaseNum), this.sharedDataService.getSelectedState(), this.selectedYear).then(
                ((objAcc: Acc) => {
                    console.log(objAcc);
                    if (objAcc) {
                        this._router.navigate([objAcc.StateNum, 'case', objAcc.AccID, 'crash', objAcc.AccID]);
                    }
                    if (objAcc == null) {
                        this._modalService.setMessage('There is no case with the Moss case number ' + CaseNum, 'danger', 20);
                    }
                }).bind(this) //If an error is thrown, CaseService.OnGetCaseError() handles displaying of an error message to the user.
            );
        }
    }

    public onSearchMossNum(): void {
        let mossCaseNum = this.inputSearchMossCase.nativeElement.value;
        this._caseService.clearAccMemoryForCloseCase();
        this._caseService.mossCasenum = mossCaseNum;

        if (mossCaseNum == '' || mossCaseNum.length == 0) {
            this._modalService.setMessage('MOSS number search cannot perform without MOSS Number. Enter MOSS Number', 'warning', 20);
        }
        else {
            this._caseService.GetCaseByMossCaseNumberPromise(mossCaseNum).then(
                ((objAcc: Acc) => {
                    if (objAcc) {
                        this._router.navigate([objAcc.StateNum, 'case', objAcc.AccID, 'crash', objAcc.AccID]);
                    }
                }).bind(this) //If an error is thrown, CaseService.OnGetCaseError() handles displaying of an error message to the user.
            );
        }
    }

    onCreateMoSS(): void {
        this.sharedDataService.setLastCaseUrl(this._router.url);
        //this._router.navigate(['cases', this.sharedDataService.getSelectedState(), 'caseCreate']);
        this._router.navigate(['cases',  'caseCreate']);
    }

    onEnterEN(): void {
        this.sharedDataService.setLastCaseUrl(this._router.url);
        this._router.navigate(['earlyNotification', this.sharedDataService.getSelectedState(), 'newFatalCase']);
    }

    getSummaryData(): void {
        this._homeService.GetCaseSummaryData(this.selectedYear, this.selectedMode).subscribe((result) => {
            this.caseSummaryData = result[0];
        });
    }


    getCalenderReminder(): void {
        this._homeService.GetCalenderReminder().subscribe((result: GetCalendarDetails_Result[]) => {
            this.calenderReminder = result;
        });
    }

    OnGoToReleaseRecords() {
        this._router.navigate(['releaserecords']);
    }

    onGoNotificationScreener(): void {
        this._router.navigate(['earlyNotification', 'fatalCaseScreener']);
    }

    onPaste(event: ClipboardEvent) {
        this._modalService.resetMessage();

        let strText: string = event.clipboardData.getData('text').trim();
        let newValue = strText.replace(/\s/g, '');
        let blnIsNaN: boolean = /^\d+$/.test(newValue);        

        if (this.selectedMode != 256) {
            if (newValue.length > 4) {
                event.preventDefault();
                this._modalService.setMessage('"' + strText + '"' + " is not valid for " + this.strMode + " Case #, it should be up to 4 digit", "danger");
            }
            else if (newValue.length <= 4 && !blnIsNaN) {
                event.preventDefault();
                this._modalService.setMessage('"' + strText + '"' + " is not valid for" + this.strMode + " Case #, it should be number only", "danger");
            }
            else {
                //Do notthing....            
            }
        }
        else {
            if (newValue.length > 8) {
                event.preventDefault();
                this._modalService.setMessage('"' + strText + '"' + " is not valid for " + this.strMode + " Case #, it should be up to 8 digit", "danger");
            }
            //else if (newValue.length <= 4 && !blnIsNaN) {
            //    event.preventDefault();
            //    this._modalService.setMessage('"' + strText + '"' + " is not valid for" + this.strMode + " Case #, it should be number only", "danger");
            //}
            else {
                //Do notthing....            
            }

        }
    }

    handleKeydown(event: KeyboardEvent) {
        if (event.code == KeyCode.F5) {
            document.location.reload();           
        }
    }
}
