import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';

import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';

import { BaseComponent } from 'src/app/helper/basecomponent';

import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Element_Specify } from 'src/app/models/element-specify';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { NgForm } from '@angular/forms';
import { DBMode } from 'src/app/models/enums/app.enums';

@Component({
    selector: 'app-avoidance-equipment',
    templateUrl: './avoidance-equipment.component.html',
    styleUrls: ['./avoidance-equipment.component.css']
})

export class AvoidanceEquipmentComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_AvoidanceEquipmentComponent: string = null;

    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    objInstanceElementSpecify: Element_Specify;
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('fvvavoidequip') objForm: NgForm;

    //Replaced *ngIf Structural directives
    //@ViewChild('typAhdAvoidEquipAssmnt') typAhdAvoidEquipAssmnt: TypeaheadComponent;
    //@ViewChild('typAhdFwdCollisionWarningHOV') typAhdFwdCollisionWarningHOV: TypeaheadComponent;
    //@ViewChild('typAhdAutomaticBrakingHOV') typAhdAutomaticBrakingHOV: TypeaheadComponent;
    //@ViewChild('typAhdLaneDepartureWarningHOV') typAhdLaneDepartureWarningHOV: TypeaheadComponent;
    //@ViewChild('typAhdLaneKeepingHOV') typAhdLaneKeepingHOV: TypeaheadComponent;
    //@ViewChild('typAhdBlindSpotHOV') typAhdBlindSpotHOV: TypeaheadComponent;
    //@ViewChild('typAhdAvoidAssmnt') typAhdAvoidAssmnt: TypeaheadComponent;
    //@ViewChild('typAhdTravSpd') typAhdTravSpd: TypeaheadComponent;
    //@ViewChild('typAhdAcurcyVehTrvlSpdEst') typAhdAcurcyVehTrvlSpdEst: TypeaheadComponent;
    //@ViewChild('typAhdDtectnDistFrPed') typAhdDtectnDistFrPed: TypeaheadComponent;
    //@ViewChild('typAhdAccrcyDtectnDistFrPedEst') typAhdAccrcyDtectnDistFrPedEst: TypeaheadComponent;
    //@ViewChild('typAhdCalcRevlTime') typAhdCalcRevlTime: TypeaheadComponent;
    //@ViewChild('typAhdAccrcyCalcRevlTmEst') typAhdAccrcyCalcRevlTmEst: TypeaheadComponent;
    //@ViewChild('typAhdPedCrshWrnEff') typAhdPedCrshWrnEff: TypeaheadComponent;
    //@ViewChild('typAhdPedEmrgcyBrkEff') typAhdPedEmrgcyBrkEff: TypeaheadComponent;
    //@ViewChild('typAhdPedEmrgcySterEff') typAhdPedEmrgcySterEff: TypeaheadComponent;
    //@ViewChild('typAhdImpactSpeed') typAhdImpactSpeed: TypeaheadComponent;
    //@ViewChild('typAhdAcurcyImpSpdEst') typAhdAcurcyImpSpdEst: TypeaheadComponent;
    //@ViewChild('typAhdPedFinalRestDist') typAhdPedFinalRestDist: TypeaheadComponent;
    //@ViewChild('typAhdVehFinalRestDist') typAhdVehFinalRestDist: TypeaheadComponent;
    //@ViewChild('typAhdSpdDistDataSrc') typAhdSpdDistDataSrc: TypeaheadComponent;
    //

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _autofillService: AutofillService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        console.log(this.acc);
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngOnInit() {
        super.ngOnInit();

        //hideOrShowComponent true is for show element , false is for hide element
        //this._sharedService.GetAppSettings().then((appSetting: AppSettings) => {
        //    if (appSetting.intMode == DBMode.MTSS) {
        //        this.typAhdAvoidEquipAssmnt.hideOrShowComponent(true);
        //        this.typAhdFwdCollisionWarningHOV.hideOrShowComponent(true);
        //        this.typAhdAutomaticBrakingHOV.hideOrShowComponent(true);
        //        this.typAhdLaneDepartureWarningHOV.hideOrShowComponent(true);
        //        this.typAhdLaneKeepingHOV.hideOrShowComponent(true);
        //        this.typAhdBlindSpotHOV.hideOrShowComponent(true);
        //    }
        //    if (appSetting.intMode == DBMode.PPSS) {
        //        //this.typAhdAvoidAssmnt.hideOrShowComponent(true);
        //        this.typAhdTravSpd.hideOrShowComponent(true);
        //        this.typAhdAcurcyVehTrvlSpdEst.hideOrShowComponent(true);
        //        this.typAhdDtectnDistFrPed.hideOrShowComponent(true);
        //        this.typAhdAccrcyDtectnDistFrPedEst.hideOrShowComponent(true);
        //        this.typAhdCalcRevlTime.hideOrShowComponent(true);
        //        this.typAhdAccrcyCalcRevlTmEst.hideOrShowComponent(true);
        //        this.typAhdPedCrshWrnEff.hideOrShowComponent(true);
        //        this.typAhdPedEmrgcyBrkEff.hideOrShowComponent(true);
        //        this.typAhdPedEmrgcySterEff.hideOrShowComponent(true);
        //        this.typAhdImpactSpeed.hideOrShowComponent(true);
        //        this.typAhdAcurcyImpSpdEst.hideOrShowComponent(true);
        //        this.typAhdPedFinalRestDist.hideOrShowComponent(true);
        //        this.typAhdVehFinalRestDist.hideOrShowComponent(true);
        //        this.typAhdSpdDistDataSrc.hideOrShowComponent(true);
        //    }

        //});

        if (this.printVehNum == 0) {
            this._route.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);

        console.log(this.objInstanceElementSpecify);

    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    setImpactSpeed($event, ) {
       
       // this.MarkFormAsDirty();
    }

    setAcurcyImpSpdEst($event) {
        let result = $event;
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.AcurcyImpSpdEst);
        //console.log(this.typeaheads);

        //if (component) {
        //    console.log(event);
        //    component.setCurrentValue(result);
        //    component.onBlur(result);
        //    this.objForm.form.markAsDirty();
        //}
    }

    setAcurcyVehTrvlSpdEst($event) {
        let result = $event;
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.AcurcyVehTrvlSpdEst);
        //console.log(this.typeaheads);
        //if (component) {
        ////    if (result > '15') {
        ////        result = '3';
        ////    }
        //    console.log(result);
        //    component.setCurrentValue(result);
        //    component.onBlur(result);
        //    this.objForm.form.markAsDirty();
        //}
    }

    setTravelSpeed($event) {
        let result = $event;
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.TravSpd);
        console.log(this.typeaheads);

        if (component) {
            component.setCurrentValue(result);
            component.onBlur(result);
            this.MarkFormAsDirty();
        }
    }

    setDistanceForPedValue(event) {
        // this.acc.Acc_SS.DistFromCurb = event.result;
        event = String(event);
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.DtectnDistFrPed);
        if (component) {
            component.setCurrentValue(String(event));
            component.onBlur(event);
            console.log(this.objForm);
            this.MarkFormAsDirty();
        }
    }

    setAccDistanceValue(event) {
        // this.acc.Acc_SS.DistFromCurb = event.result;
        event = String(event);
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.AccrcyDtectnDistFrPedEst);
        //if (component) {
        //    component.setCurrentValue(String(event));
        //    component.onBlur(event);
        //    console.log(this.objForm);
        //    this.objForm.form.markAsDirty();
        //}
    }

    setFnlRstDistanceValue(event) {
        // this.acc.Acc_SS.DistFromCurb = event.result;
        event = String(event);
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.VehFinalRestDist);
        if (component) {
            component.setCurrentValue(String(event));
            component.onBlur(event);
            console.log(this.objForm);
            this.MarkFormAsDirty();
        }
    }

    setPedFnlRstDistanceValue(event) {
        // this.acc.Acc_SS.DistFromCurb = event.result;
        event = String(event);
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.PedFinalRestDist);
        if (component) {
            component.setCurrentValue(String(event));
            component.onBlur(event);
            console.log(this.objForm);
            this.MarkFormAsDirty();
        }
    }

    
}
