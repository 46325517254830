import { Component, OnInit, ViewChildren, QueryList, PipeTransform, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';

//rxjs && rxjs/operators
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, switchMap, tap, finalize } from 'rxjs/operators';

//services
import { CaseService } from 'src/app/services/case.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

import { BlanksService } from 'src/app/services/blanks.service';

//models
import { CheckBlanks } from 'src/app/models/check-blanks';
import { BlanksState } from 'src/app/models/blanks-state';
import { SearchBlanksResult } from 'src/app/models/search-blanks-result';

//import { CrashSubTab, NonOccupantSubTab, VehicleSubTab, DriverSubTab, PrecrashSubTab, PersonSubTab, DBMode, AvoidanceEquipmentSubTab, VehType, StrikingDriverSubTab } from 'src/app/models/enums/app.enums';
import { CrashSubTab, NonOccupantSubTab, VehicleSubTab, DriverSubTab, PrecrashSubTab, PersonSubTab, DBMode, AvoidanceEquipmentSubTab, VehType } from 'src/app/models/enums/app.enums';

//directives
import { NgbdSortableHeader, SortEvent, SortDirection } from 'src/app/directives/sortable-header.directive';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { GetCaseBlanks_Result } from 'src/app/models/GetCaseBlanks_Result';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { Veh } from 'src/app/models/veh';

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(blanks: CheckBlanks[], column: string, direction: string): CheckBlanks[] {
    if (direction === '') {
        return blanks;
    } else {
        return [...blanks].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matcheBlankElementTerms(blanks: CheckBlanks, term: string) {
    return blanks.FldDef.toLowerCase().includes(term.toLowerCase());
}

function matcheVehicleTerms(blanks: CheckBlanks, pipe: PipeTransform, term: string) {    
    return pipe.transform(blanks.VehNum).includes(term);
}

function matcheSSVehicleTerms(blanks: CheckBlanks, pipe: PipeTransform, term: string) {  
        return pipe.transform(blanks.SSVNumber).includes(term);
} 

function matchePersonTerms(blanks: CheckBlanks, pipe: PipeTransform, term: string) {
    return pipe.transform(blanks.PerNum).includes(term);
}

function matcheLevelTerms(blanks: CheckBlanks, pipe: PipeTransform, term: string) {
    return pipe.transform(blanks.Rank).includes(term);
}

function matcheFieldTerms(blanks: CheckBlanks, term: string) {
    return blanks.FldName.toLowerCase().includes(term.toLowerCase());
}

function matcheDataFileTerms(blanks: CheckBlanks, term: string) {
    return blanks.SubFormName.toLowerCase().includes(term.toLowerCase());
}

@Component({
    selector: 'app-blanks',
    templateUrl: './blanks.component.html',
    styleUrls: ['./blanks.component.css'],
    providers: [
        BlanksService,
        DecimalPipe
    ]
})

export class BlanksComponent extends BaseComponent implements OnInit, OnDestroy {
    private _TypeScript_TypeGuard_BlanksComponent: string = null;
    stateNum: number;
    accid: number;
    vehicleid: number;
    personid: number;
    nonOccupantid: number;

    mandatoryBlanksCount: number;
    keyBlanksCount: number;
    commonBlanksCount: number;
    supplementalBlanksCount: number;
    totalBlanksCount: number;
    Veh: Veh;
    //region for CheckBlanks grid variables
    public BLANK: CheckBlanks[]; //CheckBlanks is a replica of GetCaseBlanks_Results

    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _blanks$ = new BehaviorSubject<CheckBlanks[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    public ColummForSorting: string = '';
    public DirectionForSorting: string = '';

    private _state: BlanksState = {
        page: 1,
        pageSize: 10,

        searchBlankElementTerm: '',
        searchVehicleTerm: '',
        searchSSVVehicleTerm: '',
        searchPersonTerm: '',
        searchLevelTerm: '',
        searchFieldTerm: '',
        searchDataFileTerm: '',

        sortColumn: '',
        sortDirection: ''
    };
    //end of CheckBlanks grid variables region    

    crashSubTab = CrashSubTab;
    NonOccupantSubTab = NonOccupantSubTab;
    vehicleSubTab = VehicleSubTab;
    driverSubTab = DriverSubTab;
    precrashSubTab = PrecrashSubTab;
    personSubTab = PersonSubTab;
    AvoidanceEquipmentSubTab = AvoidanceEquipmentSubTab;
    //StrikingDriverSubTab = StrikingDriverSubTab;

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    blanksSubscription: Subscription;

    strModeName: string;
    intYear: number;
    intMode: number;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private sharedDataService: SharedDataService,
        protected _caseService: CaseService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        protected _urlTreeHelper: UrlTreeHelper,
        public blanksService: BlanksService,
        private pipe: DecimalPipe,
    ) {
        super(_route, sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {// we are not using this method at this page, it is only because of BaseComponent extension
        this.blnAlloweSave = true;
    }

    async ngOnInit() {
        let appSettings = await this.sharedDataService.GetAppSettings();
        if (DBMode[appSettings.intMode]) {
            this.strModeName = DBMode[appSettings.intMode];
        }
        this.intYear = appSettings.intYear;
        this.intMode = appSettings.intMode;

        this._route.parent.params.subscribe(params => {
            this.stateNum = + params['stateNum'];
            this.accid = + params['caseid'];
            this.getBlanksRules();
        });
    }

    private getBlanksRules(): void {
        this.blanksSubscription = this.blanksService.GetCaseBlanks(this.accid).subscribe(result => {
            this.BLANK = result;

            if (this.intMode != DBMode.MOSS) {
                for (let i = 0; i < UrlTreeHelper.CrashTabs.length;          i++) UrlTreeHelper.CrashTabs[i]          = UrlTreeHelper.CrashTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.NonOccupantTabs.length;    i++) UrlTreeHelper.NonOccupantTabs[i]    = UrlTreeHelper.NonOccupantTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.VehicleTabs.length;        i++) UrlTreeHelper.VehicleTabs[i]        = UrlTreeHelper.VehicleTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.DriverTabs.length;         i++) UrlTreeHelper.DriverTabs[i]         = UrlTreeHelper.DriverTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.PrecrashTabs.length;       i++) UrlTreeHelper.PrecrashTabs[i]       = UrlTreeHelper.PrecrashTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.PersonTabs.length;         i++) UrlTreeHelper.PersonTabs[i]         = UrlTreeHelper.PersonTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.AvoidanceEquipment.length; i++) UrlTreeHelper.AvoidanceEquipment[i] = UrlTreeHelper.AvoidanceEquipment[i].toLowerCase();
            }
            else if (this.intMode == DBMode.MOSS) {
                for (let i = 0; i < UrlTreeHelper.CrashTabs.length;          i++) UrlTreeHelper.CrashTabs[i]          = UrlTreeHelper.CrashTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.NonOccupantTabs.length;    i++) UrlTreeHelper.NonOccupantTabs[i]    = UrlTreeHelper.NonOccupantTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.SFRVehicleTabs.length;     i++) UrlTreeHelper.SFRVehicleTabs[i]     = UrlTreeHelper.SFRVehicleTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.SFRPersonTabs.length;      i++) UrlTreeHelper.SFRPersonTabs[i]      = UrlTreeHelper.SFRPersonTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.StrikeVehicleTabs.length;  i++) UrlTreeHelper.StrikeVehicleTabs[i]  = UrlTreeHelper.StrikeVehicleTabs[i].toLowerCase();
                //for (let i = 0; i < UrlTreeHelper.StrikingDriverTabs.length; i++) UrlTreeHelper.StrikingDriverTabs[i] = UrlTreeHelper.StrikingDriverTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.StrikePersonTabs.length;   i++) UrlTreeHelper.StrikePersonTabs[i]   = UrlTreeHelper.StrikePersonTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.MossPersonTabs.length;     i++) UrlTreeHelper.MossPersonTabs[i]     = UrlTreeHelper.MossPersonTabs[i].toLowerCase();
                for (let i = 0; i < UrlTreeHelper.PrecrashTabs.length;       i++) UrlTreeHelper.PrecrashTabs[i]       = UrlTreeHelper.PrecrashTabs[i].toLowerCase();
                
                this.BLANK.forEach(((item: CheckBlanks) => {
                    //MOSS requires a custom approach because there are three different vehicle types (struck first responder, striking, struckother), each with their own pages/routes.
                    //Moreover, a given field that is applicable to multiple vehicle types may appear on differently named tabs. So, when multiple routes are needed, we use a comma-
                    //delimited subTab value that follows the following ordering convention (sfr, striking, other).
                    if (item.VehNum > 0 && item.SubFormName.indexOf(',') != -1) {
                        let v: Veh = this.acc.Veh.find(x => x.VNumber == item.VehNum);

                        if (item.SubFormName.split(',').length != 3)
                            this._modalService.setMessage(`Multi-form metadata for ${item.TableName}.${item.FldName} in RBSDB.meta.TableFieldElements.SubForm contains a comma, but does not follow the (SFR, Striking, Other) ordering convention.`, 'danger');
                        else if (v.VehType == VehType.SFRVehicle)
                            item.SubFormName = item.SubFormName.split(',')[0];
                        else if (v.VehType == VehType.StrikingVehicle)
                            item.SubFormName = item.SubFormName.split(',')[1];
                        else if (v.VehType == VehType.OtherVehicle)
                            item.SubFormName = item.SubFormName.split(',')[2];
                    }
                }).bind(this));
            }
            
            this.mandatoryBlanksCount = result.filter(m => m.Rank == 1).length;
            this.keyBlanksCount = result.filter(m => m.Rank == 2).length;
            this.commonBlanksCount = result.filter(m => m.Rank == 3).length;
            this.supplementalBlanksCount = result.filter(m => m.Rank == 4).length;
            this.totalBlanksCount = result.length;

            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                finalize(() => this._loading$.next(false)),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._blanks$.next(result.blanks);
                this._total$.next(result.total);
            });

            this._search$.next();
        });
    }

    //region for BLANK grid
    get blanks$() { return this._blanks$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }

    get searchBlankElementTerm() { return this._state.searchBlankElementTerm; }
    get searchVehicleTerm() { return this._state.searchVehicleTerm; }
    get searchSSVVehicleTerm() { return this._state.searchSSVVehicleTerm; }
    get searchPersonTerm() { return this._state.searchPersonTerm; }
    get searchLevelTerm() { return this._state.searchLevelTerm; }
    get searchFieldTerm() { return this._state.searchFieldTerm; }
    get searchDataFileTerm() { return this._state.searchDataFileTerm; }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }

    set searchBlankElementTerm(searchBlankElementTerm: string) { this._set({ searchBlankElementTerm }); }
    set searchVehicleTerm(searchVehicleTerm: string) { this._set({ searchVehicleTerm }); }
    set searchSSVVehicleTerm(searchSSVVehicleTerm: string) { this._set({ searchSSVVehicleTerm }); }
    set searchPersonTerm(searchPersonTerm: string) { this._set({ searchPersonTerm }); }
    set searchLevelTerm(searchLevelTerm: string) { this._set({ searchLevelTerm }); }
    set searchFieldTerm(searchFieldTerm: string) { this._set({ searchFieldTerm }); }
    set searchDataFileTerm(searchDataFileTerm: string) { this._set({ searchDataFileTerm }); }

    set sortColumn(sortColumn: string) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    private _set(patch: Partial<BlanksState>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchBlanksResult> {
        const { sortColumn, sortDirection, pageSize, page, searchBlankElementTerm, searchVehicleTerm, searchSSVVehicleTerm, searchPersonTerm, searchLevelTerm, searchFieldTerm, searchDataFileTerm, } = this._state;

        let blanks = sort(this.BLANK, sortColumn, sortDirection);

        this.ColummForSorting = sortColumn;
        this.DirectionForSorting = sortDirection;

        if (searchBlankElementTerm !== '') {
            blanks = blanks.filter(blank => matcheBlankElementTerms(blank, searchBlankElementTerm));
        }

        if (searchVehicleTerm !== '') {
            blanks = blanks.filter(blank => matcheVehicleTerms(blank, this.pipe, searchVehicleTerm));
        }

        if (searchSSVVehicleTerm !== '') {
            blanks = blanks.filter(blank => matcheSSVehicleTerms(blank, this.pipe, searchSSVVehicleTerm));
        }

        if (searchPersonTerm !== '') {
            blanks = blanks.filter(blank => matchePersonTerms(blank, this.pipe, searchPersonTerm));
        }

        if (searchLevelTerm !== '') {
            blanks = blanks.filter(blank => matcheLevelTerms(blank, this.pipe, searchLevelTerm));
        }

        if (searchFieldTerm !== '') {
            blanks = blanks.filter(blank => matcheFieldTerms(blank, searchFieldTerm));
        }

        if (searchDataFileTerm !== '') {
            blanks = blanks.filter(blank => matcheDataFileTerms(blank, searchDataFileTerm));
        }

        const total = blanks.length;

        blanks = blanks.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ blanks, total });
    }
    //end of BLANK grid region

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.sortColumn = column;
        this.sortDirection = direction;

        this.ColummForSorting = column;
        this.DirectionForSorting = direction;
    }

    onGoTo(blank: GetCaseBlanks_Result)    {

        if (blank) {
            this.sharedDataService.setCaseBlank(blank);

            let controlToFocus: string = blank.FldName;
            let subTab: string = blank.SubFormName.charAt(0).toLowerCase() + blank.SubFormName.slice(1); //TODO: Why are we making the subtab name camelCase?

            if (this.intMode != DBMode.MOSS) { //MTSS, PPSS
                if (UrlTreeHelper.CrashTabs.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid, subTab], { fragment: controlToFocus });
                }
                else if (UrlTreeHelper.NonOccupantTabs.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'nonOccupant', blank.PerNum, subTab], { fragment: controlToFocus });   // /case/7/nonOccupant/1/nonOccupant
                }
                else if (UrlTreeHelper.VehicleTabs.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', blank.VehNum, 'vehicle', subTab], { fragment: controlToFocus }); // /case/7/vehicle/1/vehicle/vehicle
                }
                else if (UrlTreeHelper.DriverTabs.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', blank.VehNum, 'driver', subTab], { fragment: controlToFocus });  // /case/7/vehicle/1/driver/driver
                }
                else if (UrlTreeHelper.PrecrashTabs.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', blank.VehNum, 'precrash', subTab], { fragment: controlToFocus });  // /case/7/vehicle/1/precrash/roadway
                }
                else if (UrlTreeHelper.PersonTabs.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', blank.VehNum, 'person', blank.PerNum, subTab], { fragment: controlToFocus });   // /case/7/vehicle/1/person/1/person
                }
                else if (UrlTreeHelper.AvoidanceEquipment.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'vehicleAvoidEquip', blank.VehNum, 'ae', subTab], { fragment: controlToFocus });   // /case/7/vehicle/1/person/1/person
                }
            }
            else if (this.intMode == DBMode.MOSS) {
                if (UrlTreeHelper.CrashTabs.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'crash', this.accid, subTab], { fragment: controlToFocus });
                }
                else if (UrlTreeHelper.NonOccupantTabs.includes(subTab.toLowerCase())) {
                    this._router.navigate([this.stateNum, 'case', this.accid, 'nonOccupant', blank.PerNum, subTab], { fragment: controlToFocus });   // /case/7/nonOccupant/1/nonOccupant
                }
                else {
                    this.Veh = this.acc.Veh.filter(item => item.VNumber == blank.VehNum)[0];

                    if (this.Veh.VehType == VehType.SFRVehicle) {
                        if (UrlTreeHelper.SFRVehicleTabs.includes(subTab.toLowerCase())) {
                            this._router.navigate([this.stateNum, 'case', this.accid, 'sfrvehicle', blank.VehNum, 'sfrvehicle', subTab], { fragment: controlToFocus }); // /case/7/vehicle/1/vehicle/vehicle
                        }
                        else if (UrlTreeHelper.SFRPersonTabs.includes(subTab.toLowerCase()) || UrlTreeHelper.MossPersonTabs.includes(subTab.toLowerCase())) {
                            this._router.navigate([this.stateNum, 'case', this.accid, 'sfrvehicle', blank.VehNum, 'sfrperson', blank.PerNum, subTab], { fragment: controlToFocus }); // /case/7/vehicle/1/vehicle/vehicle
                        }
                        else
                            this._modalService.setMessage(`Tab ${subTab} not mapped for Struck Subject Vehicle`, 'danger');
                    }
                    else if (this.Veh.VehType == VehType.StrikingVehicle) {
                        if (UrlTreeHelper.StrikeVehicleTabs.includes(subTab.toLowerCase())) {
                            this._router.navigate([this.stateNum, 'case', this.accid, 'strikingvehicle', blank.VehNum, 'strikingvehicle', subTab], { fragment: controlToFocus }); // /case/7/vehicle/1/vehicle/vehicle
                        }
                        //else if (UrlTreeHelper.StrikingDriverTabs.includes(subTab.toLowerCase())) {
                        //    this._router.navigate([this.stateNum, 'case', this.accid, 'strikingvehicle', blank.VehNum, 'strikingdriver', subTab], { fragment: controlToFocus });  // /case/7/vehicle/1/driver/driver
                        //}
                        else if (UrlTreeHelper.StrikePersonTabs.includes(subTab.toLowerCase())) {
                            this._router.navigate([this.stateNum, 'case', this.accid, 'strikingvehicle', blank.VehNum, 'strikingperson', blank.PerNum, subTab], { fragment: controlToFocus });   // /case/7/vehicle/1/person/1/person
                        }
                        else if (UrlTreeHelper.PrecrashTabs.includes(subTab.toLowerCase())) {
                            this._router.navigate([this.stateNum, 'case', this.accid, 'strikingvehicle', blank.VehNum, 'strikingprecrash', subTab], { fragment: controlToFocus });  // /case/7/vehicle/1/precrash/roadway
                        }
                        else
                            this._modalService.setMessage(`Tab ${subTab} not mapped for Striking Vehicle`, 'danger');
                    }
                    else if (this.Veh.VehType == VehType.OtherVehicle) {
                        this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', blank.VehNum, 'vehicle', subTab], { fragment: controlToFocus }); // /case/7/vehicle/1/vehicle/vehicle
                    }
                }
            }
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.blanksSubscription) {
            this.blanksSubscription.unsubscribe();
        }
    }
}






