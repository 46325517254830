import { Injectable } from '@angular/core';
//rxjs
import { Observable } from 'rxjs';
//services
import { GenericService } from './generic.service';
import { ModalService } from './modal.service';
//models
import { Acc } from 'src/app/models/acc';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { NonOcc_Race } from '../models/non-occ-race';
import { RBISDataValue } from '../models/enums/app.enums';
import { PII } from '../models/pii';
//helpers
import { ObjectUtil } from '../helper/objectUtil';
import { RBISWizardResults } from '../models/RBISWizardResults';
import { CaseService } from './case.service';

@Injectable({
    providedIn: 'root'
})
export class NonOccupantService {

    constructor(
        private _baseService: GenericService<any>,
        private _modalService: ModalService,
        private _caseService: CaseService
    ) { }


    private setWarningMessage(strMessage: string, strTabName: string) {
        this._modalService.setMessage(strTabName + " - " + strMessage, "error");
    }


    public ValidateNonOccObj(objNonOccupant: Non_Occupants): boolean {
        let blnIsValid: boolean = true;
        //region : Race Validation - Ticket# 4802

        //When analyst enter code 201 and try to save the page, system should give an error message saying "Please make sure to select more than one race."
        //When analyst enter 219 or 268 or 298 alone.System should allow analyst to save the page without any error message.

        if (objNonOccupant.NonOcc_Race) {
            if (objNonOccupant.NonOcc_Race.some(i => i.ElementValue == null))
                objNonOccupant.NonOcc_Race.forEach(item => { item.ElementValue = RBISDataValue.Blank });
            objNonOccupant.NonOcc_Race.sort(ObjectUtil.sortSelectedItem('SeqNum'));
        }

        if (objNonOccupant.IsMultiRace) {
            if (objNonOccupant.NonOcc_Race) {

                if (objNonOccupant.NonOcc_Race.length == 0) {
                    this.setWarningMessage("Please make sure to select more than one race.", "Non Occupant# " + objNonOccupant.PNumber + " - Race")
                    blnIsValid = false;
                }

                else if (objNonOccupant.NonOcc_Race.length == 1 && objNonOccupant.NonOcc_Race.filter(i => i.AllowOneMultiple == false).length > 0) {
                    this.setWarningMessage("Please make sure to select more than one race.", "Non Occupant# " + objNonOccupant.PNumber + " - Race");
                    blnIsValid = false;
                }
                else if (objNonOccupant.NonOcc_Race.length === 1 && Number(objNonOccupant.NonOcc_Race[0].SeqNum) !== 1) {
                    this.setWarningMessage("Please assign Order# sequentially", "Non Occupant# " + objNonOccupant.PNumber + " - Race");
                    blnIsValid = false;
                }
                else if (objNonOccupant.NonOcc_Race.length > 0) {

                    let count = Array<number>();
                    objNonOccupant.NonOcc_Race.forEach(function (i) { count[Number(i.SeqNum)] = ((count[Number(i.SeqNum)]) || 0) + 1; });
                    let blnNotSequential: boolean = false;

                    if (objNonOccupant.NonOcc_Race.filter(x => Number(x['SeqNum']) == 1).length != 1) {
                        this.setWarningMessage("Please assign Order# sequentially", "Non Occupant# " + objNonOccupant.PNumber + " - Race");
                        blnIsValid = false;
                    }

                    else if (objNonOccupant.NonOcc_Race.sort(ObjectUtil.sortSelectedItem('SeqNum')).some((obj, index) => {
                        if (index < objNonOccupant.NonOcc_Race.length - 1) {
                            if ((Number(objNonOccupant.NonOcc_Race[index + 1].SeqNum) - Number(obj.SeqNum)) > 1) {
                                blnNotSequential = true;
                            }
                        }
                        return blnNotSequential;
                    })) {
                        this.setWarningMessage("Please assign Order# sequentially", "Non Occupant# " + objNonOccupant.PNumber + " - Race");
                        blnIsValid = false;
                    }
                    else if (objNonOccupant.NonOcc_Race.filter(i => i.SeqNum === null || i.SeqNum === 0).length > 0) {
                        this.setWarningMessage("Please assign Order# for a selected code.", "Non Occupant# " + objNonOccupant.PNumber + " - Race");
                        blnIsValid = false;
                    }
                    else if (count.filter(i => i > 1).length > 0) {
                        this.setWarningMessage("Please assign different Order# for a selected codes.", "Non Occupant# " + objNonOccupant.PNumber + " - Race");
                        blnIsValid = false;
                    }


                }
            }
        }

        //end region

        return blnIsValid;
    }

    public SaveNonOccupant(objNonOccupant: Non_Occupants, blnRunEditChecksSynchronously: boolean = false): Promise<Non_Occupants> {
        this._baseService.actionURL = "api/nonOccupant/SaveNonOccupant/" + blnRunEditChecksSynchronously.toString();

        //PII, if present at all, is attached to the Acc object. To save a Non_Occupant with PII, we temporarily move
        //(not just add a second link) it from Acc to Non_Occupants to send it up to the server.
        let intPiiIndex: number = objNonOccupant.Acc.PII.findIndex(p => p.PNumber == objNonOccupant.PNumber && p.VNumber == 0);

        if (intPiiIndex != -1) {
            objNonOccupant.PII = objNonOccupant.Acc.PII[intPiiIndex];
            objNonOccupant.PII.Acc = null;
            objNonOccupant.Acc.PII.splice(intPiiIndex, 1);
        }

        //RBISWizardResults needs to attach the non-occupant level.

        let intRBISWizardIndex: number = objNonOccupant.Acc.RBISWizardResults.findIndex(p => p.PNUMBER == objNonOccupant.PNumber);

        if (intRBISWizardIndex != -1) {
            objNonOccupant.RBISWizardResult = objNonOccupant.Acc.RBISWizardResults[intRBISWizardIndex];
            objNonOccupant.RBISWizardResult.Acc = null;
            objNonOccupant.Acc.RBISWizardResults.splice(intRBISWizardIndex, 1);
        }
         //Warning: using the observable pattern here is not appropriate: the internal subscription here and the 2nd subscription of the caller of this method will cause Http to issue the web twice, once for each subscriber.
        let obsSave = this._baseService.updateEntity(objNonOccupant).toPromise();

        if (intPiiIndex != -1) { //Now that the async save request is dispatched, restore the PII object to Acc
            //RestoreBidirectionalLinks() assumes that the nesting structure of the data model represents FK relationships,
            //it does not know that we moved the PII object from Acc.PII[] to Non_Occupants.PII. We have to clean up the erroneous
            //link it created.
            if (objNonOccupant.PII['Non_Occupants'])
                objNonOccupant.PII['Non_Occupants'] = undefined;

            objNonOccupant.Acc.PII.splice(intPiiIndex, 0, objNonOccupant.PII);
            objNonOccupant.Acc.PII[intPiiIndex].Acc = objNonOccupant.Acc;
            objNonOccupant.PII = null;
        }

        if (intRBISWizardIndex != -1) {
            if (objNonOccupant.RBISWizardResult['Non_Occupants']) {
                objNonOccupant.RBISWizardResult['Non_Occupants'] = undefined;
            }

            objNonOccupant.Acc.RBISWizardResults.splice(intRBISWizardIndex, 0, objNonOccupant.RBISWizardResult);
            objNonOccupant.Acc.RBISWizardResults[intRBISWizardIndex].Acc = objNonOccupant.Acc;
            objNonOccupant.RBISWizardResult = null;
        }

        obsSave.then(((objWrappedNocOcc: Acc) => {
            if (!blnRunEditChecksSynchronously)
                this._caseService.RunChecksGetStats(objNonOccupant.AccID, 'Non_Occupants');
            else if (objWrappedNocOcc.CaseStats)
                this._caseService.subEditCheckStats.next(objWrappedNocOcc.CaseStats);
        }).bind(this));

        return obsSave;
    }
}
