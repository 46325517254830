import { Component, OnInit, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DrpDownOptions } from '../../../models/drp-down-options';
import { CaseService } from '../../../services/case.service';
import { UtilService } from '../../../services/util.service';
import { Acc } from '../../../models/acc';
import { AccRF } from '../../../models/acc-rf';
import { TableFieldElements } from '../../../models/table-field-elements';
import { TableId, FormName, States, KeyCode } from '../../../models/enums/app.enums';
import { TypeaheadComponent } from '../../../components/typeahead/typeahead.component';
import { LabelComponent } from '../../../components/label/label.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { LookupTable, FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { AutofillService } from 'src/app/services/autofill.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

@Component({
    selector: 'app-crash-other',
    templateUrl: './crash-other.component.html',
    styleUrls: ['./crash-other.component.css']
})

export class CrashOtherComponent extends BaseComponent implements OnInit, OnDestroy {
    private _TypeScript_TypeGuard_CrashOtherComponent: string = null;
    accid: number;
    crashid: number;
    stateNum: number;

    acc: Acc;

    initSelectedOptionsArf: Array<number> = [];

    manualHyperLinkNotificationTimeEMS: string;
    manualHyperLinkArrivalTimeEMS: string;
    manualHyperLinkEMSTimeAtHospital: string;
    manualHyperLinkASI: string;

    //@ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild(TypeaheadComponent) typeaheads1: TypeaheadComponent;
    @ViewChild(LabelComponent) labelComp: LabelComponent;
    objAccRFTemplate: AccRF;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        let blnCheckXRing: boolean = new RegExp("^\s|^([0-9]{6})+([a-zA-Z]{1})$").test(this.acc.RXing);       
        let dateTimeError = this._caseService.dateTimeValidations(this.acc, "other", 2020);

        if (blnCheckXRing || (this.acc.RXing === '9999999' || this.acc.RXing === '0000000' || this.acc.RXing === '' || this.acc.RXing === '-1')) {
            this.blnAlloweSave = true;
        }
        else {
            this.blnAlloweSave = false;
            this._modalService.setMessage("The format of the value entered must be six numeric followed by one alphabet", "danger");
            let UIControl = this._autofillService.arrControls.find(i => i.strFieldName === 'RXing');
            if (UIControl) {
                UIControl.setIsInvalidClass(true);
                UIControl.objTextbox.nativeElement.select();
                UIControl.objTextbox.nativeElement.click();
                UIControl.objTextbox.nativeElement.focus();
            }
            return;
        }

        if (dateTimeError != "") {
            this._modalService.setMessage(dateTimeError, 'error');
            this.blnAlloweSave = false;


            return;
        }
        else {
            this.blnAlloweSave = true;
        }
    }

    ngOnInit() {

        super.ngOnInit();
        if (this.acc) {
            this.accid = this.acc.AccID;
            this.crashid = this.acc.AccID
            this.initData();
        } else {
            this._route.parent.params.subscribe(params => {
                this.crashid = + params['crashid'];
                this.initData();
            });
        }
        
    }

    initData() {
        this._caseService.GetCasePromise(this.crashid).then(data => {
            this.acc = data;
            if (this.acc) {
                this.objAccRFTemplate = ObjectUtil.InstantiateAccRF(this.acc);
            }
        });
      
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    OnModelChange() {

    }

    bindModelValue($event: DrpDownOptions, strFieldName: string): void {
        let arrControls = this._autofillService.arrControls;

        switch (strFieldName) {
            case LookupTable.NotHr:
                let typeheadNotMinInstanceNotMin = arrControls.find(i => i.strTableName == "Acc" && i.strFieldName == "NotMin");

                if (typeheadNotMinInstanceNotMin) {
                    if ($event.intValue == 99) {
                        if (typeheadNotMinInstanceNotMin.options)
                            typeheadNotMinInstanceNotMin.options = typeheadNotMinInstanceNotMin.options.filter(i => i.intValue === 98 || i.intValue === 99);
                    }
                    else {
                        typeheadNotMinInstanceNotMin.FilterFieldOptions();
                    }
                }
                break;

            case LookupTable.ArrHr:
                let typeheadNotMinInstanceArrMin = arrControls.find(i => i.strTableName == "Acc" && i.strFieldName == "ArrMin");

                if (typeheadNotMinInstanceArrMin) {
                    if ($event.intValue == 99) {
                        if (typeheadNotMinInstanceArrMin.options)
                            typeheadNotMinInstanceArrMin.options = typeheadNotMinInstanceArrMin.options.filter(i => i.intValue === 97 || i.intValue === 98 || i.intValue === 99);
                    }
                    else {
                        typeheadNotMinInstanceArrMin.FilterFieldOptions();
                    }
                }
                break;

            case LookupTable.HospHr:
                let typeheadNotMinInstanceHospMin = arrControls.find(i => i.strTableName == "Acc" && i.strFieldName == "HospMin");

                if (typeheadNotMinInstanceHospMin) {
                    if ($event.intValue == 99) {
                        if (typeheadNotMinInstanceHospMin.options)
                            typeheadNotMinInstanceHospMin.options.filter(i => i.intValue === 96 || i.intValue === 97 || i.intValue === 98 || i.intValue === 99);
                    }
                    else {
                        typeheadNotMinInstanceHospMin.FilterFieldOptions();
                    }
                }
                break;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
