<form novalidate #fvvro="ngForm">
    <div *ngIf="veh" id="vehicle-rollover" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="rollover" name="rollover"
                                   [objModel]="veh" strTableName="Veh" strFieldName="RollOver" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="rolloverloc" name="rolloverloc"
                                   [objModel]="veh" strTableName="Veh" strFieldName="RollOverLoc" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="NumberOfQuarterTurns"
                                   typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="NumberOfQuarterTurns"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="InterruptedRoll"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="InterruptedRoll"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PreRollManeuver"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="PreRollManeuver"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RIType"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="RIType"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RIObjectContactedClass"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="RIObjectContactedClass"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RIObjectContacted"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="RIObjectContacted"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="DirectionOfInitialRoll"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="DirectionOfInitialRoll"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <!--  [blnAllowFreeText]="true" -->
                    <app-typeahead id="EstDistFromTripToFR"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="EstDistFromTripToFR"
                                   [blnShowSpecialValuesOnly]="true"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PCtWithGroundAtFR"
                                   [objModel]="veh.Veh_MTSS"
                                   strTableName="Veh_MTSS" strFieldName="PCtWithGroundAtFR"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>
