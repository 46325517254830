<div> <!-- Causes releas build error: *ngIf="module!='W'" -->
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(null)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-dark" role="alert" style="font-size:small;margin-top:-12px">
            {{header}}
        </div>
        <div class="border rounded p-2 content-size" >
            <ng-template [ngIf]="optionType=='radio'">
                <div class="border-0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let item of currentOptions; index as index">
                            <input *ngIf="item.OptionType =='radio'" disabled="{{(isFirst && numberOfVechicle==1 && index!=0 && currentOptions.length-1!=index) ? 'disabled':(isFirst && numberOfVechicle==2 && index==0) ? 'disable':''}}" checked="{{(backSelected!='' && backSelected == item.Value) ? 'checked':''}}" type="radio" name="option" (click)="onOptionSelected(item)" />
                            <button type="button" (click)="onOptionSelected(item)" disabled="{{(isFirst && numberOfVechicle==1 && index!=0 && currentOptions.length-1!=index) ? 'disabled':(isFirst && numberOfVechicle==2 && index==0) ? 'disable':''}}" class="btn btn-link-crashType">{{item.Label}}</button>
                            <span *ngIf="(isFirst && numberOfVechicle==1 && index!=0 && currentOptions.length-1!=index)" class="note-text">
                                <br />Note:  Please select a Crash Type Category that involves only one vehicle.
                            </span>
                            <span *ngIf="(isFirst && numberOfVechicle==2 && index==0 && currentOptions.length-1!=index)" class="note-text">
                                <br />Note: Please select a Crash Type Category that involves two vehicles.
                            </span>
                        </li>
                    </ul>
                </div>
            </ng-template>

            <ng-template [ngIf]="optionType=='image'">
                <div class="container" style="width:100%">
                    <div class="row text-center">
                        <div *ngFor="let item of currentOptions; index as i" class="h-100 mb-5 {{optionCount>9? 'col-sm-3':'col-sm-4'}}" style="cursor:pointer">
                            <img class="{{(backSelected!='' && backSelected == item.Value) ? 'border-selected':''}}" src="../../assets/images/wizard/PCCrashType/{{item.ImageUrl}}" (click)="onOptionSelected(item)" (mouseout)="onMouseOut($event)" (mouseover)="onMouseHover(item, $event)" />
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template [ngIf]="optionType=='final'">
                <div class="container" style="width:100%">
                        <div class="row  mt-2">
                            <div class="h-100 col-sm-12 pl-0 ">
                                Please pick the first Vehicle from the list here:
                                <select (change)="onPositionSwitch($event,true)" [(ngModel)]="vecNum1">
                                    <ng-container *ngFor="let item of dropDownOptions">
                                        <option  *ngIf="item.isFirst" [value]="item.value">{{item.value}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="h-100 col-sm-4 " *ngFor="let item of firstRowList, index as index">
                                <input type="radio"  (change)="onChangeRadio1(item.Value)" name="option1" [checked]="vec1Value==valueParse(item.Value)" id="option1_{{item.Value}}"   [value]="item.Value" /> {{item.Value}}
                                <img src="../../assets/images/wizard/PCCrashType/{{item.ImageUrl}}"  (mouseout)="onMouseOut($event)" (mouseover)="onMouseHover(item, $event)" />
                            </div>
                        </div>
                        <ng-container *ngIf="numberOfVechicle>1">
                            <div class="row">
                                <div class="h-100 col-sm-12 mt-2 pl-0">
                                    Please pick the second Vehicle from the list here:
                                    <select (change)="onPositionSwitch($event,false)" [(ngModel)]="vecNum2">
                                        <ng-container *ngFor="let item of dropDownOptions">
                                            <option *ngIf="!item.isFirst"  [value]="item.value">{{item.value}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="row  mt-2">
                                <div *ngFor="let item1 of multipleImagesInLastWindow; index as index" class="h-100 col-sm-4">
                                    <input type="radio" (change)="onChangeRadio2(item1.Value)" name="option2" [checked]="vec2Value==valueParse(item1.Value)" id="option2_{{item1.Value}}" [value]="item1.Value" /> {{item1.Value}}
                                    <img src="../../assets/images/wizard/PCCrashType/{{item1.ImageUrl}}" (mouseout)="onMouseOut($event)" (mouseover)="onMouseHover(item1, $event)" />
                                </div>
                            </div>
                        </ng-container>
                    </div>
            </ng-template>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-sm-2">
                <button type="button"
                        class="btn btn-outline-dark float-left"
                        (click)="onGoBack()" *ngIf="showBack">
                    Back
                </button>
            </div>
            <div class="col-sm-8 text-center">
                    <button type="button"
                            class="btn btn-outline-dark"
                            (click)="finishWizard()"   *ngIf="optionType=='final'">
                        Finish
                    </button>
                    <button ngbAutofocus type="button"
                            class="btn btn-outline-dark ml-2"
                            (click)="activeModal.close(null)">
                        Cancel
                    </button>
                </div>
                <div class="col-sm-2">
                    <button type="button"
                            class="btn btn-outline-dark float-right"
                            (click)="onGoNext()" *ngIf="showNext">
                        Next
                    </button>
                </div>
            </div>
        </div>
</div>

<ng-template #alertMessage let-modal>
    <div class="modal-body text-center">
        <p>{{message}}</p>
    </div>

    <div class="modal-footer modal-sub-message-footer ">
        <button type="button" class="btn btn-outline-dark float-right" (click)="innerPopup.close('ok')">
            Ok
        </button>
    </div>
</ng-template>
