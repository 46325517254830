<form novalidate #fvvvind="ngForm">
    <div *ngIf="veh" id="vehicle-vin-decode" class="pt-2">
        <div class="row">
            <div class="col-12">
                <label class="col-form-label fw-bold pl-2">Showing the VIN Decode results for "{{pageTitle}}"</label>
            </div>
        </div>
        <div class="form-group" *ngIf="isYearVisible">
            <div class="pl-2">
                <app-typeahead id="modelYr" name="modelYr" typeAheadPosition="right-top" [blnHideSelectedOptionText]="true"
                               [objModel]="veh" strTableName="Veh" strFieldName="ModelYr" [blnCheckStrengthCode]="false"
                               (keyup)="keyupYear($event)" #txtModelYear>
                </app-typeahead>
            </div>
        </div>
        <div class="form-group">
            <div class="pt-1 pb-1">
                <div class="row">
                    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto">
                        <div class="pl-2">
                            <app-label inputName="VIN"></app-label>
                        </div>
                    </div>
                    <div class="col-xl">
                        <div class="input-group pl-2">
                            <!-- This control is bound not to the data model, but to a property of this component because we may be operating on the vehicle VIN or on a trailer VIN.
                            The table and field names are still provided for metadata lookup.
                            (bindModel) requiers extra care because we want to use MyThis.VinNumber and not Veh.VinNumbe -->
                            <app-textfield id="VinDecodeVIN" name="VinDecodeVIN" cssFieldCLass="form-control w200"
                                           disableLabel="true"
                                           [maxlength]="17"
                                           [blnIsNumber]="false"
                                           [blnFormatWithLeading]="false"
                                           [objModel]="myThis"
                                           strTableName="Veh" strFieldName="VinNumber"
                                           (bindModel)="vinChanged()"
                                           [(objCurrentValue)]="myThis.VinNumber"
                                           [objNgModelOptions]="objStandalone"
                                           [blnCheckStrengthCode]="false"
                                           #txtVinDecodeVIN>
                            </app-textfield>
                            <div class="input-group-append">
                                <button class="btn btn-primary mr-2" (click)="decodeVin(myThis.VinNumber)" [disabled]="blnReadOnly"><span>Decode</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="m-3">
            <!--<app-decode-vin [VIN]="VinNumber" [vinModelYear]="veh?.ModelYr" [Vehicle]="veh" [TRAILERINDEX]="trailerIndex" [URLfragment]="URLfragment" [AccID]="accid"
                            [VehicleID]="vehicleid"
                            [isYearVisiable]="isYearVisible">
            </app-decode-vin>-->
            <div *ngIf="VinNumber != null && VinNumber != undefined && VinNumber !='' && blnShowVINDecoder">
                <div *ngIf="vinData; else loadingVINResult">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="card border mb-3">
                                <div class="card-header text-dark">
                                    <div class="row">
                                        <div class="col-10">
                                            <h4>{{modelYear + " " + make + " - " + vehicleType}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body text-dark">
                                    <div class="row justify-content-between">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                            <span class="fw-bold">Please make sure that VIN is accurately entered and if the PCR does not match with make, model and body type, click on VIN Request button</span>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                            <button class="btn btn-sm btn-primary mr-2" *ngIf="!blnVINIsClean" (click)="acceptVIN(content)">Accept VIN</button>
                                            <button class="btn btn-sm btn-primary mr-2" *ngIf="blnVINIsClean" (click)="BackToVINTab()">Back</button>
                                            <!--<button class="btn btn-sm btn-light border-secondary" (click)="onVINRequest(content)">VIN Request</button>-->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <div class="card"
                                                 [class.border-0]="!suggestedVIN || !possibleValues"
                                                 [class.border-success]="errorCodeValue === '0'"
                                                 [class.border-warning]="errorCodeValue !== '0'">
                                                <div class="card-header bg-white p-0"
                                                     [class.border-0]="!suggestedVIN || !possibleValues"
                                                     [class.border-success]="errorCodeValue === '0'"
                                                     [class.border-warning]="errorCodeValue !== '0'">
                                                    <ngb-alert [type]="alertType" [dismissible]="false" class="mb-0">
                                                        <span class="fw-bold">{{ errorTextVariable }}</span> - {{errorTextValue}}
                                                    </ngb-alert>
                                                </div>
                                                <div *ngIf="suggestedVIN || possibleValues" class="card-body"
                                                     [class.border-0]="!suggestedVIN || !possibleValues"
                                                     [class.border-success]="errorCodeValue === '0'"
                                                     [class.border-warning]="errorCodeValue !== '0'">
                                                    <p *ngIf="suggestedVIN">
                                                        <span class="fw-bold">Suggested VIN:   </span>{{suggestedVIN}}
                                                    </p>
                                                    <p *ngIf="possibleValues">
                                                        <span class="fw-bold">Possible Values:  </span>{{possibleValues}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--List of common items-->
                                    <div class="row mt-2">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                            <ul class="list-group ">
                                                <li class="list-group-item p-0 border-0">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Model Year                    <span class="fw-bold">{{ modelYear }}</span></li>
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">vPIC Make                          <span class="fw-bold">{{ make }}</span></li>
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">vPIC Model                         <span class="fw-bold">{{ model }}</span></li>
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">vPIC Body Class                    <span class="fw-bold">{{ bodyClass }}</span></li>
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">vPIC GVWR From                     <span class="fw-bold">{{ grossVehicleWeightRating }}</span></li>
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">vPIC GVWR To                       <span class="fw-bold">{{ grossVehicleWeightRatingTo }}</span></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                            <ul class="list-group ">
                                                <li class="list-group-item p-0 border-0">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item border border-white"></li>
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">NCSA Make                          <span class="fw-bold">{{ NCSAMake }}</span></li>
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">NCSA Model                         <span class="fw-bold">{{ NCSAModel }}</span></li>
                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">NCSA Body Type                     <span class="fw-bold">{{ NCSABodyType }}</span></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="card">
                                <div class="bg-light">
                                    <nav ngbNav #nav="ngbNav" class="nav-tabs">
                                        <ng-container ngbNavItem id="tab-otherInformation">
                                            <a ngbNavLink>
                                                <span class="text-dark fw-bold">Other Information</span>
                                            </a>
                                            <ng-template ngbNavContent>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                        <div class="text-dark">
                                                            <ul class="list-group p-2">
                                                                <li class="list-group-item p-0 border-0">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Series                        <span class="fw-bold">{{ series  }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Cylinders                     <span class="fw-bold">{{ cylinders     }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Engine Model                  <span class="fw-bold">{{ engineModel          }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Engine Manufacturer           <span class="fw-bold">{{ engineManufacturer         }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Transmission Speed            <span class="fw-bold">{{ transmissionSpeed     }}</span></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                        <div class="text-dark">
                                                            <ul class="list-group p-2">
                                                                <li class="list-group-item p-0 border-0">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Trim                          <span class="fw-bold">{{ trim  }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Drive Type                    <span class="fw-bold">{{ driveType   }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Primary Fuel Type             <span class="fw-bold">{{ primaryFuelType     }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Engine Brake (HP)             <span class="fw-bold">{{ engineBrake          }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Engine Displacement (L)       <span class="fw-bold">{{ engineDisplacement         }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Transmission Style            <span class="fw-bold">{{ transmissionStyle     }}</span></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                        <div class="text-dark">
                                                            <ul class="list-group p-2">
                                                                <li class="list-group-item p-0 border-0">
                                                                    <div class="d-flex w-100">
                                                                        <h5 class="mb-1">Airbags</h5>
                                                                    </div>
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Front                     <span class="fw-bold">{{ airbagsFront  }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Knee                      <span class="fw-bold">{{ airbagsKnee   }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Side                      <span class="fw-bold">{{ airbagsSide     }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Curtain                   <span class="fw-bold">{{ airbagsCurtain          }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Seat Cushion              <span class="fw-bold">{{ airbagsSeatCushion         }}</span></li>
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Other Restraint Info      <span class="fw-bold">{{ airbagsOtherRestraintInfo     }}</span></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                        <div class="text-dark">
                                                            <ul class="list-group p-2">
                                                                <li class="list-group-item p-0 border-0">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center p-0">Plant Information         <span class="fw-bold">{{ plantInformation  }}</span></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                        <ng-container ngbNavItem id="tab-all">
                                            <a ngbNavLink>
                                                <span class="text-dark fw-bold">All Vehicle Details</span>
                                                <span class="mx-3" *ngIf="loading$ | async"><i class="fa fa-spinner fa-spin" style="font-size:inherit"></i></span>
                                            </a>

                                            <ng-template ngbNavContent>
                                                <div class="row mt-2">
                                                    <div class="col-xl">

                                                        <div class="table-responsive">
                                                            <table class="table table-hover table-sm table-striped mb-0">
                                                                <thead class="bg-tb-header">
                                                                    <tr class="">
                                                                        <th scope="col" sortable="Variable" (sort)="onSort($event)" class="border-bottom border-dark text-center pointer w-50">
                                                                            Variable
                                                                            <span *ngIf="ColummForSorting == 'Variable'">
                                                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up"></i>
                                                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                                                            </span>
                                                                        </th>
                                                                        <th scope="col" sortable="Value" (sort)="onSort($event)" class="border-bottom border-dark text-center pointer w-50">
                                                                            Value
                                                                            <span *ngIf="ColummForSorting == 'Value'">
                                                                                <i *ngIf="DirectionForSorting =='asc'" class="fas fa-sort-up"></i>
                                                                                <i *ngIf="DirectionForSorting =='desc'" class="fas fa-sort-down"></i>
                                                                            </span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody class="border-bottom border-dark">
                                                                    <tr class="bg-white">
                                                                        <td class="border-bottom border-dark w-50">
                                                                            <div class="form-group pt-2 pl-2 mb-2">
                                                                                <input class="form-control" type="text" name="searchVariableTerm" [(ngModel)]="searchVariableTerm" />
                                                                            </div>
                                                                        </td>
                                                                        <td class="border-bottom border-dark w-50">
                                                                            <div class="form-group pt-2 pl-2 mb-2">
                                                                                <input class="form-control" type="text" name="searchValueTerm" [(ngModel)]="searchValueTerm" />
                                                                            </div>

                                                                        </td>
                                                                    </tr>

                                                                    <tr *ngFor="let vinDecoded of vinDecodeds$ | async">
                                                                        <td class="text-nowrap w-50">
                                                                            <ngb-highlight [result]="vinDecoded.Variable" [term]="searchVariableTerm"></ngb-highlight>
                                                                        </td>
                                                                        <td class="w-50">
                                                                            <ngb-highlight [result]="vinDecoded.Value" [term]="searchValueTerm"></ngb-highlight>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>

                                                        <div class="d-flex justify-content-between p-2">
                                                            <div class="align-middle">
                                                                <ngb-pagination class="align-middle" [collectionSize]="total$ | async" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                                            </div>
                                                            <div class="custom-control-inline">
                                                                <p class="pr-2 my-auto">Total: {{total$ | async }}</p>
                                                                <select class="custom-select pl-2 my-auto w-auto" name="pageSize" [(ngModel)]="pageSize">
                                                                    <option [ngValue]="5">5 items per page</option>
                                                                    <option [ngValue]="10">10 items per page</option>
                                                                    <option [ngValue]="25">25 items per page</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </nav>

                                    <div [ngbNavOutlet]="nav" class="mt-2"></div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ng-template #loadingVINResult>
                <div class="pl-3 py-3"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></div>
            </ng-template>
        </div>
    </div>
    <div class="alert alert-info" role="alert" *ngIf="!blnShowVINDecoder">
        No VIN Checking is performed if VIN is blank, all 9s or 0s or 8s.
    </div>
</form>
