import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { UtilService } from './../../services/util.service';
import { TableFieldElements } from './../../models/table-field-elements';

import { } from './../../helper/extensionmethods';

@Component({
    selector: 'app-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.css']
})

export class LabelComponent implements OnInit {

    @ViewChild('divContainer', { static: false }) divContainer: ElementRef;
    static objectCodingManualWindow: Window;

    link: string = null;
    objMetaData: TableFieldElements;
    strVisibleDisplayStyle: string;

    @Input() label: string;
    @Input() inputName: string;
    @Input() TableID: string;
    @Input() inputMetaData: TableFieldElements = null;
    @Input() disableLabel: boolean = false;
    @Input() printOnly: boolean = false;
    @Input() isStaticLabel: boolean = false;
    constructor(
        private _utilService: UtilService
    ) { }

    public ngOnInit(): void {
        if (!this.isStaticLabel && !this.disableLabel) {
            if (this.inputMetaData) {
                this.label = this.inputMetaData.Desc;
                this.link = this.inputMetaData.ManualHyperLink;
            }
            else if (this.inputName) {
                this._utilService.metaDataToShare.subscribe(((arrMeta: TableFieldElements[]) => {
                    {
                        if (this.TableID) {
                            this.objMetaData = arrMeta.find(x => x.TableID.toLowerCase() === this.TableID.toLowerCase() && x.Field.toLowerCase() === this.inputName.toLowerCase()); //Try field name with SeqNum first (Field is the concatenation of FieldID and SeqNum)

                            if (!this.objMetaData)
                                this.objMetaData = arrMeta.find(x => x.TableID.toLowerCase() === this.TableID.toLowerCase() && x.FieldID.toLowerCase() === this.inputName.toLowerCase()); //Try field name with SeqNum first (Field is the concatenation of FieldID and SeqNum)
                        }

                        if (!this.objMetaData)
                            this.objMetaData = arrMeta.find(x => x.Field.toLowerCase() === this.inputName.toLowerCase()); //Try field name with SeqNum first (Field is the concatenation of FieldID and SeqNum)

                        if (!this.objMetaData)
                            this.objMetaData = arrMeta.find(x => x.FieldID.toLowerCase() === this.inputName.toLowerCase()); //Fall back on field name without SeqNum

                        if (this.objMetaData) {
                            this.label = this.objMetaData.Desc;
                            this.link = this.objMetaData.ManualHyperLink;
                        }
                        else
                            console.warn(`Label component failed to resolve metadata using lookup keys TableID ('${this.TableID}') and inputName ('${this.inputName}')`)
                    }
                }).bind(this));
            }
            else {
                this.hideOrShowComponent(false);
            }
        }
    }

    public hideOrShowComponent(blnVisible: boolean): void {
        if (!blnVisible && !this.strVisibleDisplayStyle) {//If about to change from visible to invisible, remember CSS display attribute value.
            this.strVisibleDisplayStyle = this.divContainer.nativeElement.style.display;
        }

        this.divContainer.nativeElement.style.display = blnVisible ? this.strVisibleDisplayStyle : 'none';
    }

    public onPDFOpen(): void {
        if (LabelComponent.objectCodingManualWindow) {
            if (LabelComponent.objectCodingManualWindow.closed === false) {
                console.debug('Closing Coding Manual window (closed status ', LabelComponent.objectCodingManualWindow.closed, ')');
                LabelComponent.objectCodingManualWindow.close();
                console.debug('Closed Coding Manual window (closed status ', LabelComponent.objectCodingManualWindow.closed, ')');
                LabelComponent.objectCodingManualWindow = null;
            }
        }

        if (this.link)
            LabelComponent.objectCodingManualWindow = window.open("assets/" + this.link, 'manualLinkWindow1');
    }
}
