import { Component, OnInit, ChangeDetectorRef, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { LoadingFormService } from "src/app/services/loading-form.service";

@Component({
    selector: 'app-loading-form',
    templateUrl: './loading-form.component.html',
    styleUrls: ['./loading-form.component.css']
})
export class LoadingFormComponent implements OnDestroy {

  
    loading: boolean = false;
    loadingSubscription: Subscription;

    constructor(
        private _loadingService: LoadingFormService,
        private _elmRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef
    ) {// the sub needs to be inside constructor to be rdy for metadata call which is starting from service constructor;
        this.loadingSubscription = this._loadingService.loadingStatus.pipe(
            debounceTime(50)
        ).subscribe((value) => {
            //console.log('Loading from component Subscription', value);
            this.loading = value;
        });
    }

    ngOnDestroy() {
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
        }
    }

}
