import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CaseService } from '../../../services/case.service';
import { SharedDataService, AppSettings } from '../../../services/shared-data.service';
import { Acc } from '../../../models/acc';
import { Veh } from '../../../models/veh';
import { Occupants } from '../../../models/occupants';
import { VehType } from 'src/app/models/enums/app.enums';

@Component({
  selector: 'app-striking-vehicle-person',
  templateUrl: './striking-vehicle-person.component.html',
  styleUrls: ['./striking-vehicle-person.component.css']
})
export class StrikingVehiclePersonComponent implements OnInit {
    private sbsVehId: Subscription;
    private sbsOccId: Subscription;
    intStateNum: number;
    intAccId: number;
    intOccId: number;
    intVehId: number;
    intMode: number;    

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _caseService: CaseService,
        private _sharedDataService: SharedDataService,
    ) { }

    async ngOnInit() {

        this._route.parent.parent.params.subscribe((params => {
            this.intStateNum = + params['stateNum'];
            this.intAccId = + params['caseid'];

            if (this.sbsVehId)
                this.sbsVehId.unsubscribe();

            this.sbsVehId = this._route.parent.params.subscribe((params => {
                this.intVehId = + params['vehicleid'];

                if (this.sbsOccId)
                    this.sbsOccId.unsubscribe();

                this.sbsOccId = this._route.params.subscribe((params => {
                    this.intOccId = + params['personid'];

                    this._caseService.GetCasePromise(this.intAccId).then((objAcc => {
                        let objVeh: Veh = objAcc.Veh.find(v => v.VNumber == this.intVehId && v.VehType == VehType.StrikingVehicle);

                        //If URL specifies a non-existent vehicle or occupant (or recently deleted vehicle or occupant), then fall back on top-level case URL
                        if (objVeh) {
                            let objOcc: Occupants = objVeh.Occupants.find(x => x.PNumber == this.intOccId);

                            if (!objOcc)
                                this._router.navigate([this.intStateNum, 'case', this.intAccId, 'crash', this.intAccId]);
                        }
                        else
                            this._router.navigate([this.intStateNum, 'case', this.intAccId, 'crash', this.intAccId]);
                    }).bind(this));
                }).bind(this));
            }).bind(this));
        }).bind(this));
    }

    async ngAfterViewInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = appSettings.intMode;
    }
}
