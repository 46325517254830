<form novalidate #fnonOccPedestrian="ngForm">
    <div class="pt-2" *ngIf="nonOccupant && intMode">

        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="" [ngClass]="{'row' : printOnly !== true }">
                        <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto' : printOnly !== true }" *ngIf="!printOnly && intMode == 128">
                            <div class="pl-2">
                                <span class="my-auto">
                                    <button type="button" id="btnPedestrian_Copy" [disabled]="filteredPedLookupData.length == 0" class="btn btn-sm btn-light border-secondary"
                                            (click)="onCopyData()">
                                        <span class="text-dark fw-bold">Copy Pedestrian data</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }" *ngIf="!printOnly">
                            <div class="pl-2">
                                <app-label class="mr-2" inputName="SchoolZone" [printOnly]="printOnly"></app-label>
                            </div>
                        </div>
                        <div [ngClass]="{'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : printOnly !== true }" class="">
                            <div class="pl-2">
                                <app-label inputName="SchoolZone" *ngIf="printOnly" [printOnly]="printOnly"></app-label>
                                <app-typeahead #schoolZone id="schoolZonePed" name="schoolZonePed" typeAheadPosition="right-top"
                                               disableLabel="true"
                                               [objModel]="nonOccupant.NonOcc_Ped" (keyup)="handleKeyup($event)" strTableName="NonOcc_Ped" strFieldName="SchoolZone"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div #typAhdRdCrosswalk *ngIf="(intMode == 128)" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #crosswalk id="crosswalkPed" name="crosswalkPed" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="Crosswalk"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #sidewalk id="sidewalkPed" name="sidewalkPed" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="Sidewalk"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <ng-container *ngIf="!printOnly && intMode == 128">
                <div class="form-group">
                    <span class="pl-2">
                        <!-- for-focus CSS class makes the button participate in UIElementBase tab order -->
                        <button type="button" id="btnBicyclist_BikeWizard" class="btn btn-sm btn-light border-secondary pl-2 for-focus"
                                (click)="onPedWizardClick($event)">
                            <span class="text-dark fw-bold">Please click here to start the Pedestrian Crash Type wizard.</span>
                        </button>
                    </span>
                </div>
            </ng-container>

            <div  #typAhdRdCrashType *ngIf="(intMode == 128)" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #CrashType id="crashTypePed" name="crashTypePed" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="CrashType"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <!--TO DO Crash Group - Pedestrian-->

            <div #typAhdRdCrashGroup *ngIf="(intMode == 128)" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #CrashGroup id="crashGroupPed" name="crashGroupPed" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="CrashGroup"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>

                </div>
            </div>

            <div #typAhdRdCrashLocation *ngIf="(intMode == 128)" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #CrashLocation id="crashLocationPed" name="crashLocationPed" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="CrashLocation"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdRdPedPosition *ngIf="(intMode == 128)" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #PedPosition id="pedestrianPosition" name="pedestrianPosition" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="PedPosition"
                                   [printOnly]="printOnly"
                                   (bindModel)="fieldValueChange('PedPosition',$event,nonOccupant.NonOcc_Ped)"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdRdPedDirection *ngIf="(intMode == 128)" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #PedDirection id="pedestrianDirection" name="pedestrianDirection" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="PedDirection"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div *ngIf="printOnly && intMode == 1" class="col-md-12 mt-4" style="margin-top:150px!important"></div>
            <div *ngIf="printOnly && intMode == 4" class="col-md-12" style="margin-top:150px!important"></div>
            <div #typAhdRdMotDirection *ngIf="(intMode == 128)" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #MotDirection id="pedestrianMotDirection" name="pedestrianMotDirection" typeAheadPosition="right-top" disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="MotDirection"
                                   [printOnly]="printOnly"
                                   (bindModel)="fieldValueChange('MotDirection',$event,nonOccupant.NonOcc_Ped)"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdRdMotManeuver *ngIf="(intMode == 128)"  [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #MotManeuver id="pedestrianMotManeuver"
                                   name="pedestrianMotManeuver"
                                   typeAheadPosition="right-top"
                                   disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Ped"
                                   strTableName="NonOcc_Ped"
                                   strFieldName="MotManeuver"
                                   [printOnly]="printOnly"
                                   (bindModel)="fieldValueChange('MotManeuver',$event,nonOccupant.NonOcc_Ped)"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdRdLegIntersection *ngIf="(intMode == 128)"  [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #LegIntersection id="pedestrianLegIntersection"
                                   name="pedestrianLegIntersection"
                                   typeAheadPosition="right-top"
                                   disabled="true"
                                   [objModel]="nonOccupant.NonOcc_Ped"
                                   strTableName="NonOcc_Ped"
                                   strFieldName="LegIntersection"
                                   [printOnly]="printOnly"
                                   (bindModel)="fieldValueChange('LegIntersection',$event,nonOccupant.NonOcc_Ped)"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdRdScenario *ngIf="(intMode == 128)"  [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead #Scenario id="pedestrianScenario" name="pedestrianScenario" typeAheadPosition="right-top" [disabled]="true"
                                   [objModel]="nonOccupant.NonOcc_Ped" strTableName="NonOcc_Ped" strFieldName="Scenario"
                                   [printOnly]="printOnly"
                                   (bindModel)="fieldValueChange('Scenario',$event,nonOccupant.NonOcc_Ped)"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>

<ng-container *ngIf="!printOnly">
    <ng-container *ngIf="hasWizardFilled">
        <div class="form-group">
            <div class="">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="inputPedestrian_selectedWizardList" class="col-form-label fw-bold pl-2">
                            Step by Step selection of Crash Type
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="ml-2">
                            <h3 class="inline-h5" *ngFor="let item of selectedWizardList, index as index">
                                <span class="badge bg-light border border-secondary mb-1">
                                    {{((item.Value!=null && item.Value!='') ? item.Value +' - ': '') }} {{item.Label}}
                                </span>
                                <i class="fas fa-angle-right mx-1" *ngIf="(index!=selectedWizardList.length-1)"></i>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
