import { Component, NgModule, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params, PRIMARY_OUTLET, UrlTree, UrlSegmentGroup, UrlSegment } from '@angular/router';

//rxjs and rxjs/operators
import { Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

//services
import { CaseService } from '../../services/case.service';
import { EarlyNotificationService } from 'src/app/services/early-notification.service';
import { ModalService } from '../../services/modal.service';
import { SharedDataService } from '../../services/shared-data.service';
import { AppSettings } from 'src/app/services/shared-data.service';
import { UtilService } from '../../services/util.service';
import { VehicleService } from '../../services/vehicle.service';

//models
import { EarlyNotify } from '../../models/early-notify';
import { Acc } from '../../models/acc';
import { Veh } from '../../models/veh';
import { PreCrash } from '../../models/pre-crash';
import { Dri } from '../../models/dri';
import { Occupants } from 'src/app/models/occupants';
import { Non_Occupants } from 'src/app/models/non-occupants';

import { States, KeyCode, CrashSubTab, NonOccupantSubTab, VehicleSubTab, PersonSubTab, PrecrashSubTab, DriverSubTab, URLstrings, ActionCommand, UnitType, DBMode, AvoidanceEquipmentSubTab, SFRVehicleSubTab, VehType, NonOccupantType, CaseStatus, SFRPersonSubTab } from '../../models/enums/app.enums';
import { TableFieldElements } from '../../models/table-field-elements';
import { AttributeMatchLevel } from '../../models/attr-match-level';
import { DrpDownOptions } from 'src/app/models/drp-down-options';

import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { EDT_GetCaseLastUpdateDates_Result } from 'src/app/models/EDT_GetCaseLastUpdateDates_Result';
import { usp_EDT_GetStylesByStateNum_Result } from 'src/app/models/usp_EDT_GetStylesByStateNum_Result';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { RbisUser } from 'src/app/models/rbis-user';
import { AuthService } from 'src/app/services/auth.service';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { ActionButtonsComponent } from 'src/app/ui/action-buttons/action-buttons.component';
import { CaseStats } from 'src/app/models/case-stats';
import { ActionButtonService } from 'src/app/services/action-button.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { HttpLocalStorageService } from 'src/app/services/http-local-storage.service';
import { rbisStore } from 'src/app/rbis-store';
import { Veh_SS } from 'src/app/models/veh-ss';
import { JsonFileWrapper } from 'src/app/models/JsonFileWrapper';
import { CaseFilesListsService } from 'src/app/services/case-files-lists.service';
import { CaseFilesLists } from '../../models/case-files-lists.model';


@Component({
    selector: 'app-case',
    templateUrl: './case.component.html',
    styleUrls: ['./case.component.css']
})

export class CaseComponent implements OnInit, OnDestroy {
    sbsRefreshAcc: Subscription;
    private _objActiveComponent: BaseComponent;
    private _arrObjActiveComponent: Array<BaseComponent>;
    //Used to @ViewChild to make a call to the method OnSave(this.acc, 'checkCase');
    @ViewChild(ActionButtonsComponent) _actionButtonsComponent: ActionButtonsComponent;

    stateNum: number;
    accid: number;
    crashid: number;
    vehicleid: number = this._sharedDataService.getVehicleId();
    personid: number = this._sharedDataService.getPersonId();
    nonOccupantid: number = this._sharedDataService.getNonOccupantid();

    personsCount: number;
    vehiclesCount: number;
    nonOccupantsCount: number;
    stateString: string;
    currentTab: string;
    navigateToTab: string;
    blnIsEDTstate: boolean = false;
    blnIsEDTcase: boolean = false;
    caseStatusforReview: string = "Updated";
    caseStatusReviewFlag: Boolean = false;
    caseStatus: string = '';


    acc: Acc;
    earlyNotification: EarlyNotify;


    objstateEDTStyle: usp_EDT_GetStylesByStateNum_Result[];
    objDrpDwnOptions: DrpDownOptions[];
    caseSubscription: Subscription;
    edtLastUpdateDates: EDT_GetCaseLastUpdateDates_Result;
    _arrAttributeLevel: Array<AttributeMatchLevel>;

    //Horizontal
    horizontalLinksGroupForCrash: Array<{ crashLinkHObj: Acc }> = [];
    horizontalLinksGroupForNonOccupant: Array<{ nonOccupantLinkHObj: Non_Occupants }> = [];
    horizontalLinksGroupForVehicle: Array<{ vehLinkHObj: Veh }> = [];
    horizontalLinksGroupForSFRVehicle: Array<{ vehLinkSFRObj: Veh }> = [];
    horizontalLinksGroupForStrikingVehicle: Array<{ vehLinkStrikingRObj: Veh }> = [];


    //Vertical
    verticalLinksGroupForCrash: Array<{ crashLinkVObj: Acc }> = [];
    verticalLinksGroupForNonOccupant: Array<{ nonOccupantLinkVObj: Non_Occupants }> = [];
    verticalLinksGroupForVehicle: Array<{ vehLinkVObj: Veh, vehsOccupantsLinkVObj: Occupants[], vehPrecrashLinkVObj: PreCrash, vehDriverLinkVObj: Dri, isCollapsed: boolean }> = [];
    verticalLinksGroupForSFRVehicle: Array<{ vehLinkSFRVObj: Veh, vehsOccupantsLinkSFRObj: Occupants[], vehPrecrashLinkSFRObj: PreCrash, vehDriverLinkSFRObj: Dri, isCollapsed: boolean }> = [];
    verticalLinksGroupForStrikingVehicle: Array<{ vehLinkStrikingRVObj: Veh, vehsOccupantsLinkStrikingObj: Occupants[], vehPrecrashLinkStrikingObj: PreCrash, vehDriverLinkStrikingObj: Dri, isCollapsed: boolean }> = [];

    arrNonOccupantLinkOptions: DrpDownOptions[];
    arrAllLinkIntValue: Array<number>;
    arrNonOccupantLinkIntValue: Array<number>;
    arrBicyclistIntValue: Array<number>;
    arrPedestrianIntValue: Array<number>;

    isRoadwayInfo: boolean = false;
    isAlcoholNDrugs: boolean = true;
    isEMSInfo: boolean = false;
    isDeathRecords: boolean = false;
    isDriverInfo: boolean = false;
    isVehicleInfo: boolean = true;

    public errorStatusFatal: number = 0;
    public errorStatusSevere: number = 0;
    public errorStatusPending: number = 0;

    isCrashCollapsed = false;
    isErrorStatusCollapsed = false;
    //isInformationFilledCollapsed = false;
    isPrintCollapsed = false;
    isCaseDocumentsCollapsed = false;
    isReferenceDocumentsCollapsed = false;
    hideNavForCloseCase: boolean = false;
    invisibleENCaseNum: boolean = false;
    public selectedMode: number;
    isPrintMode: boolean = false;
    //counter: number;   

    blnRefrashObj: boolean = false;
    blnInformationFilledVis: boolean = true;

    arrSubscription: Subscription[] = new Array<Subscription>();

    public strCurrentSSCodingManual: string;
    public strCurrentFarsCrssCodingManual: string;
    public strCurrentFarsCrssPedBikeManual: string;
    blnEnableDownloadAll: boolean;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _authService: AuthService,
        private _caseService: CaseService,
        private _enService: EarlyNotificationService,
        private _utilService: UtilService,
        private _vehService: VehicleService,
        private _sharedDataService: SharedDataService,
        private _modalService: ModalService,
        protected _urlTreeHelper: UrlTreeHelper,
        private _actionButtonService: ActionButtonService,
        private _autofillService: AutofillService,
        private _httpLocal: HttpLocalStorageService,
        private rbisstore: rbisStore,
        private caseFileService: CaseFilesListsService

    ) {

        this._sharedDataService.subReEvalCaseActionButtons.subscribe(((objNewState: { objComponent: Array<BaseComponent>, objCase: Acc }) => {
            if (objNewState && objNewState.objComponent) {
                if (objNewState && objNewState.objComponent.length > 0) {
                    this._arrObjActiveComponent = objNewState.objComponent;
                }
            }
        }).bind(this));
    }

    public async ngOnInit(): Promise<void> {

        this.blnIsEDTstate = await this._sharedDataService.getIsEDTState();
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.strCurrentSSCodingManual = appSettings.strCurrentSSCodingManual ? appSettings.strCurrentSSCodingManual : '2020_MTSS_Coding_Manual.pdf';
        this.strCurrentFarsCrssCodingManual = appSettings.strCurrentFarsCrssCodingManual ? appSettings.strCurrentFarsCrssCodingManual : '2018_FARS_CRSS_Coding_Manual.pdf';

        //this.strCurrentFarsCrssPedBikeManual = appSettings.strCurrentFarsCrssPedBikeManual ? appSettings.strCurrentFarsCrssPedBikeManual : '2020_FARS_CRSS_Pedestrian_Bicyclist_Manual.pdf';

        let objUser: RbisUser = await this._authService.GetUserPromise();
        this.selectedMode = appSettings.intMode;

        this.blnInformationFilledVis = this.selectedMode == 1 ? true : false; //hide Information Filled card if mode is not 1

        this._route.params.subscribe((async params => {

            this.accid = await +params['caseid'];
            this.stateNum = await +params['stateNum'];

            this.acc = await this._caseService.GetCasePromise(this.accid);
            //CAUTION: UIElementBase.blnReadOnly is used in UIElementBase.ngOnInit(). It SHOULD NOT be behind any await calls that would cause it to resolve after UIElementBase.ngOnInit() has already completed.
            //If necessary, refactor the code, ex: notify UIElementBase when Acc.Deleted becomes available.
            UIElementBase.blnReadOnly = this.acc.Deleted
                || objUser.blnReadOnly
                || (this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review || this.acc.EarlyNotify[0].Status == CaseStatus.HQ_Review_Complete);

            if (!this.caseFileService.blnUploadActive)
                await this.caseFileService.GetCaseFileLock(this.accid);
            let arrCaseFiles: CaseFilesLists[] = await this.caseFileService.getCaseFilesById(this.acc.AccID);
            this.caseFileService.blnPARorEDTFile = this.acc.StateCaseID != null && this.acc.EarlyNotify[0].StateCaseNum != null;
            this.blnEnableDownloadAll = arrCaseFiles.length > 0 || this.caseFileService.blnPARorEDTFile;

            if (this._router.url.includes('print')) {
                this.isPrintMode = true;
            }

            if (this.acc.CaseStats) {
                this.errorStatusFatal = this.acc.CaseStats.Fatal;
                this.errorStatusSevere = this.acc.CaseStats.Severe;
                this.errorStatusPending = this.acc.CaseStats.Pending;
            }

            this._caseService.subEditCheckStats.subscribe(((objEditCheckStats: CaseStats) => {
                if (objEditCheckStats) {
                    if (objEditCheckStats.AccID == this.accid) { //If user is jumping quickly between cases, the Edit Check results from the last save may come back after the user has already switched to a different case, in which case we simply ignore them.
                        this.errorStatusFatal = objEditCheckStats.Fatal;
                        this.errorStatusSevere = objEditCheckStats.Severe;
                        this.errorStatusPending = objEditCheckStats.Pending;
                    }
                }
                else {
                    this.errorStatusFatal = 0;
                    this.errorStatusSevere = 0;
                    this.errorStatusPending = 0;
                }
            }).bind(this));

            if (!this.acc.CaseStats || !this.acc.CaseStats.CrUserID)                   //If Edit Checks have never been run yet for this case
                this._caseService.RunChecksGetStats(this.acc.AccID, 'AllChecks', -1); //Run Edit Checks, but without setting CaseStats.CrUserID

            this.earlyNotification = this.acc.EarlyNotify[0];

            try {
                this._caseService.hideENNum.next(this.acc.Deleted);
            }
            catch (ex) {
                console.error(ex);
            }

            this._sharedDataService.setAccId(this.acc.AccID);
            this._sharedDataService.setCurrentCaseNumber(this.acc.Casenum);

            this._sharedDataService.setIsEDTCase(null)
            if (this._sharedDataService.IsEDTCase(this.acc))
                this._sharedDataService.setIsEDTCase(true);

            //Horizontal
            this.horizontalLinksGroupForCrash = [];
            this.horizontalLinksGroupForNonOccupant = [];
            this.horizontalLinksGroupForVehicle = [];
            this.horizontalLinksGroupForSFRVehicle = [];
            this.horizontalLinksGroupForStrikingVehicle = [];

            //Vertical
            this.verticalLinksGroupForCrash = [];
            this.verticalLinksGroupForNonOccupant = [];
            this.verticalLinksGroupForVehicle = [];
            this.verticalLinksGroupForSFRVehicle = [];

            this.stateString = States[this.acc.StateNum];
            let statusValue = this.acc.EarlyNotify[0].Status;
            if (statusValue != undefined) {
                this.caseStatus = CaseStatus[statusValue]
            }

            this.caseStatus = this.caseStatus.replace('_', ' ');

            this.addLinksGroupForCrash(this.acc);

            //const arrNonMotoristPTypes = [NonOccupantType.FirstResponderStruck,NonOccupantType.FirstResponderNonStruck];

            //this.acc.Non_Occupants.filter(x => arrNonMotoristPTypes.includes(x.PType))
            //    .forEach((item) => {
            //        console.log(item)
            //        this.addLinksGroupForNonOccupant(item);
            //    });

            this.acc.Non_Occupants.forEach(item => {
                this.addLinksGroupForNonOccupant(item);

                //if (this.selectedMode = DBMode.MOSS) {
                //    item.PTypeDesc = 'FR' + '_' + item.PNumber.toString()

                //    //if (this.acc.Non_Occupants.find(x => arrNonMotoristPTypes.includes(x.PType))) {
                //    //    item.PTypeDesc = (item.PType == NonOccupantType.FirstResponderNonStruck ? 'Non-SFR' : (item.PType == NonOccupantType.FirstResponderStruck ? 'SFR' : '')) + '_' + item.PNumber.toString()
                //    //}

                //    //if (item.PType != -1) {
                //    //    item.PTypeDesc = (item.PType == NonOccupantType.FirstResponderNonStruck ? 'Non-SFR' : (item.PType == NonOccupantType.FirstResponderStruck ? 'SFR' : '')) + '_' + item.PNumber.toString()
                //    //    //item.PTypeDesc = item.PNumber.toString() + '-'  + (item.PType == NonOccupantType.FirstResponderNonStruck ? 'Non-SFR' : (item.PType == NonOccupantType.FirstResponderStruck ? 'SFR' : ''))
                //    //}
                //    //else {
                //    //    item.PTypeDesc = item.PNumber.toString()
                //    //}
                //}
            });

            if (this.selectedMode == DBMode.MOSS) {
                this.acc.Veh.filter(item => item.VehType == VehType.OtherVehicle)
                    .sort((x, y) => (x.Veh_SS.SSVNumber > y.Veh_SS.SSVNumber) ? 1 : -1)
                    .forEach((item) => {
                        this.addLinksGroupForVehicle(item, item.Occupants, item.PreCrash, item.Dri, false);

                    });
            }
            else {
                this.acc.Veh.forEach((item) => {
                    this.addLinksGroupForVehicle(item, item.Occupants, item.PreCrash, item.Dri, false);

                });
            }
            this.acc.Veh.filter(item => item.VehType == VehType.SFRVehicle)
                .sort((x, y) => (x.Veh_SS.SSVNumber > y.Veh_SS.SSVNumber) ? 1 : -1)
                .forEach(item => {
                    this.addLinksGroupForSFRVehicle(item, item.Occupants, item.PreCrash, item.Dri, false)
                });
            this.acc.Veh.filter(item => item.VehType == VehType.StrikingVehicle)
                .sort((x, y) => (x.Veh_SS.SSVNumber > y.Veh_SS.SSVNumber) ? 1 : -1)
                .forEach(item => {
                    this.addLinksGroupForStrikingVehicle(item, item.Occupants, item.PreCrash, item.Dri, false)
                });

            this.nonOccupantsCount = this.getNonOccupantsCount();
            this.vehiclesCount = this.getVehiclesCount();
            this.personsCount = this.getPersonsCount();
            //Commented out since it is taking TOO MUCH TIME in between changing the tabs.
            // Do We need it in here.?
            this._caseService.GetLongLatValidationStatus(this.accid);
            //});
            this.blnIsEDTcase = false;
            this.blnIsEDTcase = this._sharedDataService.getIsEDTCase();


            this._utilService.formDrpDownOptionShare.subscribe(async objResult => {
                if (objResult) {
                    let arrStates: Array<number> = [];
                    let arrDefStates: DrpDownOptions[] = objResult.filter(i => i.tblName == LookupTable.StateNum && i.intValue != 98 && i.intValue != 99);
                    if (arrDefStates) {
                        arrDefStates.forEach(item => {
                            arrStates.push(item.intValue);
                        });

                        this._sharedDataService.setListofValidStates(arrStates);
                    }

                    let ddoSelectedState: DrpDownOptions;

                    if (this.selectedMode == DBMode.FARS)
                        ddoSelectedState = objResult.find(x => x.intValue === this.stateNum);

                    if (this.selectedMode == DBMode.CRSS) {
                        let arrPSUs: DrpDownOptions = objResult.find(i => i.tblName == LookupTable.VR_PSU && i.intValue == this.stateNum);

                        if (arrPSUs) {
                            ddoSelectedState = arrDefStates.find(i => i.PSUStateAbbr == arrPSUs.PSUStateAbbr);
                        }
                    }

                    if (ddoSelectedState) {
                        this._sharedDataService.setDDOSelectedState(ddoSelectedState);
                        this._sharedDataService.subjectSelectedState.next(ddoSelectedState);

                        if (this._sharedDataService.IsEDTCase(this.acc))//KM:EDT related
                        {
                            //Moh: 5-12-2020
                            let objEDTLastUpdateDate: EDT_GetCaseLastUpdateDates_Result[];
                            const cacheData = this._autofillService.GetLocalStorageData("LastUpdateDate_" + this.accid) //Check local storage data
                            if (cacheData !== null) objEDTLastUpdateDate = cacheData
                            else {
                                this._httpLocal.postLocalStorage({ url: "LastUpdateDate_" + this.accid, body: objEDTLastUpdateDate, cacheMins: 10 }); //Save in local storage, expire in 10 minutes

                                /* get data from store*/
                                //console.log(this.rbisstore.select("api/edt/GetEDTLastUpdateDates/" + this.accid + "/" + this.selectedMode))
                            }

                            if (objEDTLastUpdateDate) {
                                if (objEDTLastUpdateDate && objEDTLastUpdateDate.length > 0) {
                                    this.edtLastUpdateDates = objEDTLastUpdateDate[0];

                                    this._sharedDataService.setEDTLastProcessedDate(this.edtLastUpdateDates.LastProcessedDate);
                                    this._sharedDataService.setEDTLastRecvdDate(this.edtLastUpdateDates.LastRecvdDate);

                                    if (this.edtLastUpdateDates.EDTComments && this.edtLastUpdateDates.EDTComments != '')
                                        this._modalService.setMessage(this.edtLastUpdateDates.EDTComments, 'warning');
                                }
                            }
                        }
                    }
                }
            });

            if (this.selectedMode == DBMode.FARS) { //Do not get data for Information Filled card if mode is not 1;
                this.FldFieldSubscribe();
            }

            //Removing initial and onAfterSave BehaviorSubjects and triggering AfterSave EventEmitter on page init directly            
            this._caseService.emsSaved.emit(this.acc); //Initial value for Emergency Management Services (EMS) Info Filled flag.
            this._caseService.trafficwaySaved.emit(this.acc); //Initial value for Trafficway Info Filled flag.           
            this._vehService.visibilityFinalStageBodyClass(this.acc.Veh).then((fsbcVisibilityArr) => {
                //Initial value for Vehicle Info Filled flag.
                this.isVehicleInfo = this._vehService.vehicleInfoFilledCheck(this.acc.Veh, fsbcVisibilityArr);
            });
            this._caseService.deathRecordsSaved.emit(this.acc); //Initial value for Death Record Filled flag.
            this._caseService.alcDrugSaved.emit(this.acc); //Initial value for Alcohol & Drugs Filled flag.
            this._vehService.driverSaved.emit(this.acc);

            this.caseStatusReviewFlag = (this.acc.Status == 4);

        }).bind(this));

        this._caseService.hideNavForCloseCase.subscribe(result => {
            this.hideNavForCloseCase = result;
        });
        this._caseService.hideENNum.subscribe(result => {
            this.invisibleENCaseNum = result;
        });

    }


    private FldFieldSubscribe() {

        this.arrSubscription.push(this._vehService.vehicleSaved.subscribe((vehicle) => {
            let vehSavedToArray: Veh[] = new Array<Veh>();
            vehSavedToArray.push(vehicle);
            this._vehService.visibilityFinalStageBodyClass(vehSavedToArray).then((fsbcVisibilityArr) => {
                this._caseService.GetCasePromise(this.accid).then((accObj) => {
                    if (accObj.Veh) {
                        this.isVehicleInfo = this._vehService.vehicleInfoFilledCheck(accObj.Veh, fsbcVisibilityArr);
                    }
                });
            });
        }));

        this.arrSubscription.push(this._caseService.emsSaved.subscribe((accObj: Acc) => {
            this.isEMSInfo = this._caseService.emsInfoFilledCheck(accObj);
        }));

        this.arrSubscription.push(this._caseService.trafficwaySaved.subscribe((accObj: Acc) => {
            this.isRoadwayInfo = this._caseService.trafficwayInfoFilledCheck(accObj);
        }));

        this.arrSubscription.push(this._caseService.deathRecordsSaved.subscribe((accObj: Acc) => {
            this.isDeathRecords = this._caseService.deathRecordsFilledCheck(accObj);
        }));

        this.arrSubscription.push(this._vehService.driverSaved.subscribe((accObj: Acc) => {
            this.isDriverInfo = this._vehService.driverInfoFilledCheck(accObj);
        }));

        this.arrSubscription.push(this._caseService.alcDrugSaved.subscribe((accObj) => {
            this.isAlcoholNDrugs = this._caseService.alcDrugFilledCheck(accObj);
        }));

        this.arrSubscription.push(this._caseService.hideNavForCloseCase.subscribe(result => {
            this.hideNavForCloseCase = result;
        }));

        this.arrSubscription.push(this._caseService.hideENNum.subscribe(result => {
            this.invisibleENCaseNum = result;
        }));
    }
    public addLinksGroupForCrash(crashLinkObj) {
        this.horizontalLinksGroupForCrash.push({ crashLinkHObj: crashLinkObj });
        this.verticalLinksGroupForCrash.push({ crashLinkVObj: crashLinkObj });
    }
    public addLinksGroupForNonOccupant(nonOccupantLinkObj) {
        this.horizontalLinksGroupForNonOccupant.push({ nonOccupantLinkHObj: nonOccupantLinkObj });
        this.verticalLinksGroupForNonOccupant.push({ nonOccupantLinkVObj: nonOccupantLinkObj });
    }
    public addLinksGroupForVehicle(vehLinkObj, vehsOccupantsLinkVObj, vehPrecrashLinkVObj, vehDriverLinkVObj, isCollapsed) {
        this.horizontalLinksGroupForVehicle.push({ vehLinkHObj: vehLinkObj });
        console.log(this.horizontalLinksGroupForVehicle);
        this.verticalLinksGroupForVehicle.push({ vehLinkVObj: vehLinkObj, vehsOccupantsLinkVObj: vehsOccupantsLinkVObj, vehPrecrashLinkVObj: vehPrecrashLinkVObj, vehDriverLinkVObj: vehDriverLinkVObj, isCollapsed: isCollapsed });
        console.log(this.verticalLinksGroupForVehicle);
    }
    public addLinksGroupForSFRVehicle(vehLinkSFRObj, vehsOccupantsLinkSFRObj, vehPrecrashLinkSFRObj, vehDriverLinkSFRObj, isCollapsed) {
        this.horizontalLinksGroupForSFRVehicle.push({ vehLinkSFRObj: vehLinkSFRObj });
        this.verticalLinksGroupForSFRVehicle.push({ vehLinkSFRVObj: vehLinkSFRObj, vehsOccupantsLinkSFRObj: vehsOccupantsLinkSFRObj, vehPrecrashLinkSFRObj: vehPrecrashLinkSFRObj, vehDriverLinkSFRObj: vehDriverLinkSFRObj, isCollapsed: isCollapsed });

    }
    public addLinksGroupForStrikingVehicle(vehLinkStrikingObj, vehsOccupantsLinkStrikingObj, vehPrecrashLinkStrikingObj, vehDriverLinkStrikingObj, isCollapsed) {
        this.horizontalLinksGroupForStrikingVehicle.push({ vehLinkStrikingRObj: vehLinkStrikingObj });
        this.verticalLinksGroupForStrikingVehicle.push({ vehLinkStrikingRVObj: vehLinkStrikingObj, vehsOccupantsLinkStrikingObj: vehsOccupantsLinkStrikingObj, vehPrecrashLinkStrikingObj: vehPrecrashLinkStrikingObj, vehDriverLinkStrikingObj: vehDriverLinkStrikingObj, isCollapsed: isCollapsed });

    }

    onSearch(caseid) {
        if (caseid) {
            this._router.navigate([this.stateNum, URLstrings.Case, caseid, URLstrings.Crash, caseid]);
        }
    }

    getPersonsCount(intVehicleNumber?: number) {
        if (!intVehicleNumber)
            intVehicleNumber = this._sharedDataService.getVehicleId();

        if (!intVehicleNumber)
            return 0;
        let objVeh = this.acc.Veh.find(v => v.VNumber == intVehicleNumber);
        if (!objVeh)
            return 0;
        if (!objVeh.Occupants)
            return 0;
        return objVeh.Occupants.length;
    }

    getVehiclesCount() {
        var vehiclesNumber = this.acc.Veh.length;
        return vehiclesNumber;
    }

    //getVehiclesCountByType() {
    //    var vehiclesNumber = this.acc.Veh.length;
    //    return vehiclesNumber;
    //}

    getNonOccupantsCount() {
        var nonOccupantsNumber = this.acc.Non_Occupants.length;
        return nonOccupantsNumber;
    }

    getIsBicyclist() {
        let isBicyclist = false;
        let personType = this.acc.Non_Occupants.find(x => x.PNumber === this._sharedDataService.getNonOccupantid()).PType;
        if (personType === 6 || personType === 7) {
            isBicyclist = true;
        }
        return isBicyclist;
    }

    getIsPedestrian() {
        let isPedestrian = false;
        let personType = this.acc.Non_Occupants.find(x => x.PNumber === this._sharedDataService.getNonOccupantid()).PType;
        if (personType === 5 || personType === 11 || personType === 12 || personType === 13) {
            isPedestrian = true;
        }
        return isPedestrian;
    }

    getIsPrecrash(vehicleID: number) {
        let isPrecrash = false;
        let unitType = this.acc.Veh.find(x => x.VNumber === vehicleID).UnitType;
        if (unitType === 1) {
            isPrecrash = true;
        }
        return isPrecrash;
    }

    getIsTrailer() {
        let isTrailer = false;
        let Veh = this.acc.Veh.find(x => x.AccID === this.accid && x.VNumber === this.vehicleid);
        isTrailer = Veh.Trailer === -1 ? false : true;
        return isTrailer;
    }

    getIsDriverPresence(): boolean {
        let Veh = this.acc.Veh.find(x => x.AccID === this.accid && x.VNumber === this.vehicleid);
        let blnIsVisabel = (Veh.DrivPres != 0 && Veh.DrivPres != 9 && Veh.Occupants.length > 0) ? true : false;
        return blnIsVisabel;
    }

    getIsVehicleDriverless(): boolean {
        let vehID = this.currentTab == AvoidanceEquipmentSubTab.AvoidanceEquipment ? this.vehiclesCount : this.vehicleid;//added condition for MTSS(AvoidanceEquipment Tab)
        let Veh = this.acc.Veh.find(x => x.AccID === this.accid && x.VNumber === vehID);
        let blnIsVehicleDriverless = (Veh.Occupants.length === 0) ? true : false;
        return blnIsVehicleDriverless
    }

    getIsPreviousVehicleDriverless(): boolean {
        let PreviousVehicleID = this.vehicleid - 1;
        if (PreviousVehicleID > 0) {
            let Veh = this.acc.Veh.find(x => x.AccID === this.accid && x.VNumber === this.vehicleid - 1);
            let blnIsVehicleDriverless = (Veh.Occupants.length === 0) ? true : false;
            return blnIsVehicleDriverless;
        }
        else {
            return false;
        }
    }
    getIsBodyType(): boolean {
        let Veh = this.acc.Veh.find(x => x.AccID === this.accid && x.VNumber === this.vehicleid);
        let tabsVisible = ([60, 61, 62, 64, 65, 66, 67].includes(Veh.Body)) ? true : false;
        return tabsVisible;
    }


    setURLsegments(urlSegments: UrlSegment[]) {
        this.currentTab = urlSegments[urlSegments.length - 1].path;
        this.accid = parseInt(urlSegments[urlSegments.findIndex(x => x.path === URLstrings.Case) + 1].path);
        this.nonOccupantid = parseInt(urlSegments[urlSegments.findIndex(x => x.path === NonOccupantSubTab.NonOccupant) + 1].path);
        if (this.currentTab == AvoidanceEquipmentSubTab.AvoidanceEquipment) {
            this.vehicleid = parseInt(urlSegments[urlSegments.findIndex(x => x.path === URLstrings.VehicleAvoidEquip) + 1].path);
        }
        else {
            this.vehicleid = parseInt(urlSegments[urlSegments.findIndex(x => x.path === VehicleSubTab.Vehicle) + 1].path);
        }

        if (this.currentTab == SFRPersonSubTab.Person) {
            this.personid = parseInt(urlSegments[urlSegments.findIndex(x => x.path === SFRPersonSubTab.Person) + 1].path);
        }
        else {
            this.personid = parseInt(urlSegments[urlSegments.findIndex(x => x.path === PersonSubTab.Person) + 1].path);
        }
    }

    public async tabNavigation(event: KeyboardEvent) {

        //If the popup opens, it shouldn't execute the save and navigation logic.
        if (!this._modalService.ngModal.hasOpenModals()) {
            if (event.key === KeyCode.PageUp || event.key === KeyCode.PageDown || event.key === KeyCode.Enter) {
                event.preventDefault();

                if (this.acc.Deleted)
                    return;

                const urlC: UrlTree = this._router.parseUrl(this._router.url);
                const segmentGroup: UrlSegmentGroup = urlC.root.children[PRIMARY_OUTLET];
                const urlSegments: UrlSegment[] = segmentGroup.segments;
                this.setURLsegments(urlSegments);
                let blnModalOpen: boolean = false;
                let isTrafficwayPage: boolean = false;
                //check if the current tab is traffic, If the current tab is trafficway let validate the values.
                //Specilly this case happen only if the USER come and without make any change if the user hit above button

                isTrafficwayPage = this._router.url.toLocaleLowerCase().includes('trafficway');

                this._objActiveComponent = this._arrObjActiveComponent.find(x => x.arrInputForms.toArray().some(y => y.dirty));

                if (this._objActiveComponent) {

                    let blnModelDirty: boolean = this._objActiveComponent.arrInputForms.toArray().some(x => x.dirty);

                    if (blnModelDirty || isTrafficwayPage) { //Intentionally not prompting user about unsaved changes. PageUp/PageDown are defined as "Save and navigate"
                        await this._objActiveComponent.onBeforeSave(null);
                        if (this._objActiveComponent.blnAlloweSave) {
                            let blnSuccess: boolean = await this._actionButtonService.SaveCase(this._objActiveComponent, ActionCommand.Save)
                            if (!blnSuccess)
                                return;

                            for (let objComponent of this._arrObjActiveComponent) {
                                objComponent.arrInputForms.forEach(objForm => objForm.form.markAsPristine());
                            }

                            //The popup can be opened  after save is done and navigation to next tab shouldn't be executed to complete the action in the pop-up (Scenerio -> Out of State Request)
                            let sbsModalOpen: Subscription = this._vehService.sbjModalOpenAfterSave
                                .subscribe((blnModalOpen: boolean) => {
                                    this.acc.Mode == DBMode.CRSS ? this.NavigateToCRSSNextTab(event, blnModalOpen) : this.NavigateToNextTab(event, blnModalOpen);
                                });
                            sbsModalOpen.unsubscribe();
                            return;
                        }
                    }
                    else {
                        // Out Of State Requst moved to VIN tab and the status set to saved if the VIN is decoded and fields are populated automatically. 
                        if (this.currentTab == VehicleSubTab.VIN) {
                            let sbsModalOpen: Subscription = this._vehService.sbjModalOpenAfterSave
                                .subscribe((blnModalOpen: boolean) => {
                                    this.acc.Mode == DBMode.CRSS ? this.NavigateToCRSSNextTab(event, blnModalOpen) : this.NavigateToNextTab(event, blnModalOpen);
                                });

                            sbsModalOpen.unsubscribe();
                        }
                        else {
                            this.acc.Mode == DBMode.CRSS ? this.NavigateToCRSSNextTab(event, blnModalOpen) : this.NavigateToNextTab(event, blnModalOpen);
                        }
                    }
                }
                else {
                    this.acc.Mode == DBMode.CRSS ? this.NavigateToCRSSNextTab(event, blnModalOpen) : this.NavigateToNextTab(event, blnModalOpen);
                }

            }
        }
    }

    //original tab navigation from RBIS - FARS/CRSS

    //private NavigateToNextTab(event: KeyboardEvent, blnModalOpen: boolean = false): void {
    //    if (blnModalOpen)
    //        return;

    //    switch (this.currentTab) {
    //        //crash tabs
    //        case CrashSubTab.Crash: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextCrashTab(CrashSubTab.Events);
    //            }
    //            break;
    //        }
    //        case CrashSubTab.Events: {
    //            if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.FARS) {
    //                this.navigateNextCrashTab(CrashSubTab.Trafficway);
    //            }
    //            if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.CRSS)
    //                this.navigateNextCrashTab(CrashSubTab.Special);
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextCrashTab(CrashSubTab.Crash);
    //            }
    //            break;
    //        }
    //        case CrashSubTab.Trafficway: {

    //            if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.FARS) {
    //                this.navigateNextCrashTab(CrashSubTab.Other);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextCrashTab(CrashSubTab.Events);
    //            }
    //            break;
    //        }
    //        case CrashSubTab.Other: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextCrashTab(CrashSubTab.Special);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextCrashTab(CrashSubTab.Trafficway);
    //            }
    //            break;
    //        }
    //        case CrashSubTab.Special: {
    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getNonOccupantsCount() > 0) {
    //                    this.nonOccupantid = 1;
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
    //                }
    //                else if (this.getVehiclesCount() > 0) {
    //                    this.vehicleid = 1;
    //                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
    //                }
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextCrashTab(CrashSubTab.Other);
    //            }
    //            break;
    //        }
    //        //non occupant tabs
    //        case NonOccupantSubTab.NonOccupant: {
    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getIsBicyclist()) {
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
    //                    break;
    //                }

    //                if (this.getIsPedestrian()) {
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
    //                    break;
    //                }
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
    //                break;
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                if (this.getNonOccupantsCount() > -1 && this.nonOccupantid - 1 > 0) {
    //                    this.nonOccupantid = this.nonOccupantid - 1;
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Supplemental);
    //                    break;
    //                }
    //                this.navigateNextCrashTab(CrashSubTab.Special);
    //            }
    //            break;
    //        }
    //        case NonOccupantSubTab.Bicyclist: {
    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getIsPedestrian()) {
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
    //                    break;
    //                }
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
    //            }
    //            break;
    //        }
    //        case NonOccupantSubTab.Pedestrian: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                if (this.getIsBicyclist()) {
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
    //                    break;
    //                }
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
    //            }
    //            break;
    //        }
    //        case NonOccupantSubTab.NonMotoristActions: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.Alcohol);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                if (this.getIsBicyclist()) {
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
    //                    break;
    //                }

    //                if (this.getIsPedestrian()) {
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
    //                    break;
    //                }
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
    //            }
    //            break;
    //        }
    //        case NonOccupantSubTab.Alcohol: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.Drug);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
    //            }
    //            break;
    //        }
    //        case NonOccupantSubTab.Drug: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.Race);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.Alcohol);
    //            }
    //            break;
    //        }
    //        case NonOccupantSubTab.Race: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.Supplemental);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.Drug);
    //            }
    //            break;
    //        }
    //        case NonOccupantSubTab.Supplemental: {
    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getNonOccupantsCount() > -1 && this.nonOccupantid + 1 <= this.getNonOccupantsCount()) {
    //                    this.nonOccupantid = this.nonOccupantid + 1;
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
    //                    break;
    //                }
    //                if (this.getVehiclesCount() >= 1) {
    //                    this.vehicleid = 1;
    //                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
    //                }
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextNonOccupantTab(NonOccupantSubTab.Race);
    //            }
    //            break;
    //        }

    //        //vehicle tabs
    //        case VehicleSubTab.Vehicle: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextVehicleTab(VehicleSubTab.DamagedAreas);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && !this.getIsPreviousVehicleDriverless()) {
    //                    this.vehicleid = this.vehicleid - 1
    //                    this.personid = this.getPersonsCount(this.vehicleid);
    //                    this.navigateNextPersonTab(PersonSubTab.PersonSupplemental);
    //                }
    //                else if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && this.getIsPrecrash(this.vehicleid - 1)) {
    //                    this.vehicleid = this.vehicleid - 1
    //                    this.personid = this.getPersonsCount(this.vehicleid);
    //                    this.navigateNextPrecrashTab(PrecrashSubTab.Precrash);
    //                }
    //                else if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && this.getIsPreviousVehicleDriverless()) {
    //                    this.vehicleid = this.vehicleid - 1
    //                    this.personid = this.getPersonsCount(this.vehicleid);
    //                    this.navigateNextDriverTab(DriverSubTab.Driver);
    //                }
    //                else if (this.nonOccupantsCount > 0 && this.vehicleid == 1) {
    //                    this.nonOccupantid = this.nonOccupantsCount;
    //                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Supplemental);
    //                    break;
    //                }
    //                else {
    //                    this.navigateNextCrashTab(CrashSubTab.Special);
    //                }
    //            }
    //            break;
    //        }
    //        case VehicleSubTab.DamagedAreas: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextVehicleTab(VehicleSubTab.VIN);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
    //            }
    //            break;
    //        }
    //        case VehicleSubTab.VIN: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextVehicleTab(VehicleSubTab.VehicleDetails);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextVehicleTab(VehicleSubTab.DamagedAreas);
    //            }
    //            break;
    //        }
    //        case VehicleSubTab.VehicleDetails: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextVehicleTab(VehicleSubTab.VIN);
    //            }
    //            break;
    //        }
    //        case VehicleSubTab.VehicleOther: {
    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getIsTrailer()) {
    //                    this.navigateNextVehicleTab(VehicleSubTab.VehicleTrailer);
    //                }
    //                else {
    //                    this.navigateNextVehicleTab(VehicleSubTab.VehicleVINDecode);
    //                }
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextVehicleTab(VehicleSubTab.VehicleDetails);
    //            }
    //            break;
    //        }
    //        case VehicleSubTab.VehicleTrailer: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextVehicleTab(VehicleSubTab.VehicleVINDecode);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
    //            }
    //            break;
    //        }
    //        case VehicleSubTab.VehicleVINDecode: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextDriverTab(DriverSubTab.Driver);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                if (this.getIsTrailer()) {
    //                    this.navigateNextVehicleTab(VehicleSubTab.VehicleTrailer);
    //                }
    //                else {
    //                    this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
    //                }
    //            }
    //            break;
    //        }

    //        //driver tabs
    //        case DriverSubTab.Driver: {
    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getIsDriverPresence()) {
    //                    this.navigateNextDriverTab(DriverSubTab.License);
    //                }

    //                else if (this.acc.Veh.find(i => i.VNumber == this.vehicleid && i.UnitType == UnitType.InTransport)) {
    //                    this.navigateNextPrecrashTab(PrecrashSubTab.Roadway);
    //                }

    //                else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount())) {
    //                    this.vehicleid = this.vehicleid + 1;
    //                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
    //                    break;
    //                }

    //                else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 > this.getVehiclesCount())) {
    //                    this.navigateCheckCaseTab();
    //                    break;
    //                }

    //                else {
    //                    this.personid = 1
    //                    this.navigateNextPersonTab(PersonSubTab.Person);
    //                    break;
    //                }
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextVehicleTab(VehicleSubTab.VehicleVINDecode);
    //            }
    //            break;
    //        }
    //        case DriverSubTab.License: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextDriverTab(DriverSubTab.Counters);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextDriverTab(DriverSubTab.Driver);
    //            }
    //            break;
    //        }
    //        case DriverSubTab.Counters: {
    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getIsPrecrash(this.vehicleid)) {
    //                    this.navigateNextPrecrashTab(PrecrashSubTab.Roadway);
    //                    break;
    //                }
    //                else if (this.getPersonsCount(this.vehicleid)) {
    //                    this.personid = 1
    //                    this.navigateNextPersonTab(PersonSubTab.Person);
    //                    break;
    //                }
    //                else if (this.getVehiclesCount() > -1) {
    //                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
    //                    break;
    //                }
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextDriverTab(DriverSubTab.License);
    //            }
    //            break;
    //        }

    //        //precrash tabs
    //        case PrecrashSubTab.Roadway: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextPrecrashTab(PrecrashSubTab.Precrash);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                if (this.getIsDriverPresence()) {
    //                    this.navigateNextDriverTab(DriverSubTab.Counters);
    //                }
    //                else {
    //                    this.navigateNextDriverTab(DriverSubTab.Driver);
    //                }
    //            }
    //            break;
    //        }
    //        case PrecrashSubTab.Precrash: {

    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getPersonsCount(this.vehicleid)) {
    //                    this.personid = 1
    //                    this.navigateNextPersonTab(PersonSubTab.Person);
    //                    break;
    //                }
    //                else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount())) {
    //                    this.vehicleid = this.vehicleid + 1;
    //                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
    //                    break;
    //                }
    //                else {
    //                    this.navigateCheckCaseTab();
    //                }
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextPrecrashTab(PrecrashSubTab.Roadway);
    //                break;
    //            }
    //            break;
    //        }

    //        //person tabs
    //        case PersonSubTab.Person: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextPersonTab(PersonSubTab.SafetyDevices);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                if (this.getPersonsCount() > -1 && this.personid > 1) {
    //                    this.personid = this.personid - 1;
    //                    this.navigateNextPersonTab(PersonSubTab.PersonSupplemental);
    //                    break;
    //                }
    //                else if (this.getIsPrecrash(this.vehicleid)) {
    //                    this.navigateNextPrecrashTab(PrecrashSubTab.Precrash);
    //                    break;
    //                }
    //                else if (!this.getIsPrecrash(this.vehicleid) && this.getIsDriverPresence()) {
    //                    this.navigateNextDriverTab(DriverSubTab.Counters);
    //                    break;
    //                }
    //                else if (!this.getIsPrecrash(this.vehicleid) && !this.getIsDriverPresence()) {
    //                    this.navigateNextDriverTab(DriverSubTab.Driver);
    //                    break;
    //                }
    //            }
    //            break;
    //        }
    //        case PersonSubTab.SafetyDevices: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextPersonTab(PersonSubTab.PersonAlcohol);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextPersonTab(PersonSubTab.Person);
    //            }
    //            break;
    //        }
    //        case PersonSubTab.PersonAlcohol: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextPersonTab(PersonSubTab.PersonDrug);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextPersonTab(PersonSubTab.SafetyDevices);
    //            }
    //            break;
    //        }
    //        case PersonSubTab.PersonDrug: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextPersonTab(PersonSubTab.PersonRace);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextPersonTab(PersonSubTab.PersonAlcohol);
    //            }
    //            break;
    //        }
    //        case PersonSubTab.PersonRace: {
    //            if (event.key === KeyCode.PageDown) {
    //                this.navigateNextPersonTab(PersonSubTab.PersonSupplemental);
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextPersonTab(PersonSubTab.PersonDrug);
    //            }
    //            break;
    //        }
    //        case PersonSubTab.PersonSupplemental: {
    //            if (event.key === KeyCode.PageDown) {
    //                if (this.getPersonsCount() > -1 && this.personid + 1 <= this.getPersonsCount()) {
    //                    this.personid = this.personid + 1;
    //                    this.navigateNextPersonTab(PersonSubTab.Person);
    //                    break;
    //                }
    //                else if (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount()) {
    //                    this.vehicleid = this.vehicleid + 1;
    //                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
    //                    break;
    //                }
    //                else {
    //                    this.navigateCheckCaseTab();
    //                }
    //            }
    //            if (event.key === KeyCode.PageUp) {
    //                this.navigateNextPersonTab(PersonSubTab.PersonRace);
    //            }
    //            break;
    //        }
    //    }
    //}

    private NavigateToNextTab(event: KeyboardEvent, blnModalOpen: boolean = false): void {
        if (blnModalOpen)
            return;

        switch (this.currentTab) {
            //crash tabs
            case CrashSubTab.Crash: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextCrashTab(CrashSubTab.Events);
                }
                break;
            }
            case CrashSubTab.Events: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextCrashTab(CrashSubTab.Trafficway);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextCrashTab(CrashSubTab.Crash);
                }
                break;
            }
            case CrashSubTab.Trafficway: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextCrashTab(CrashSubTab.Crashnotes);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextCrashTab(CrashSubTab.Events);
                }
                break;
            }
            case CrashSubTab.Crashnotes: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.MTSS) {
                    if (this.getNonOccupantsCount() > 0) {
                        this.nonOccupantid = 1;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                    }
                    else if (this.getVehiclesCount() > 0) {
                        this.vehicleid = 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                    }
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.MTSS) {
                    this.navigateNextCrashTab(CrashSubTab.Trafficway);
                }
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextCrashTab(CrashSubTab.NonMotorist);
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextCrashTab(CrashSubTab.Trafficway);
                }
                break;
            }

            //case CrashSubTab.Crashnotes: {
            //    if (event.key === KeyCode.PageDown) {
            //        this.navigateNextCrashTab(CrashSubTab.NonMotorist);
            //    }
            //    if (event.key === KeyCode.PageUp) {
            //        this.navigateNextCrashTab(CrashSubTab.Trafficway);
            //    }
            //    break;
            //}

            //case CrashSubTab.Crashnotes: {
            //    if (event.key === KeyCode.PageDown) {
            //        if (this.getNonOccupantsCount() > 0) {
            //            this.nonOccupantid = 1;
            //            this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
            //        }
            //        else if (this.getVehiclesCount() > 0) {
            //            this.vehicleid = 1;
            //            this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
            //        }
            //    }
            //    if (event.key === KeyCode.PageUp) {
            //        this.navigateNextCrashTab(CrashSubTab.Trafficway);
            //    }
            //    break;
            //}
            case CrashSubTab.NonMotorist: {
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextCrashTab(CrashSubTab.Crashnotes);
                }
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    if (this.getNonOccupantsCount() > 0) {
                        this.nonOccupantid = 1;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                    }
                    else if (this.getVehiclesCount() > 0) {
                        this.vehicleid = 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Rollover);
                    }
                }
                break;
            }
            //non occupant tabs
            //case NonOccupantSubTab.NonOccupant: {
            //    if (event.key === KeyCode.PageDown) {
            //        if (this.getNonOccupantsCount() > -1 && this.nonOccupantid + 1 <= this.getNonOccupantsCount()) {
            //            this.nonOccupantid = this.nonOccupantid + 1;
            //            this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
            //            break;
            //        }
            //        if (this.getVehiclesCount() >= 1) {
            //            this.vehicleid = 1;
            //            this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
            //        }
            //        break;
            //    }
            //    if (event.key === KeyCode.PageUp) {
            //        if (this.getNonOccupantsCount() > -1 && this.nonOccupantid - 1 > 0) {
            //            this.nonOccupantid = this.nonOccupantid - 1;
            //            this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
            //            break;
            //        }
            //        this.navigateNextCrashTab(CrashSubTab.Crash);
            //    }
            //    break;
            //}
            //case NonOccupantSubTab.NonOccupant: {
            //    if (event.key === KeyCode.PageDown) {
            //        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOther);
            //        break;
            //    }
            //    if (event.key === KeyCode.PageUp) {
            //        if (this.getNonOccupantsCount() > -1 && this.nonOccupantid - 1 > 0) {
            //            this.nonOccupantid = this.nonOccupantid - 1;
            //            this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
            //            break;
            //        }
            //        this.navigateNextCrashTab(CrashSubTab.Crash);
            //    }
            //    break;
            //}

            case NonOccupantSubTab.NonOccupant: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.MTSS) {
                    if (this.getNonOccupantsCount() > -1 && this.nonOccupantid + 1 <= this.getNonOccupantsCount()) {
                        this.nonOccupantid = this.nonOccupantid + 1;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                        break;
                    }
                    if (this.getVehiclesCount() >= 1) {
                        this.vehicleid = 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                    }
                    break;
                }
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOther);
                    break;
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getNonOccupantsCount() > -1 && this.nonOccupantid - 1 > 0) {
                        this.nonOccupantid = this.nonOccupantid - 1;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                        break;
                    }
                    this.navigateNextCrashTab(CrashSubTab.Crash);
                }
                break;
            }
            case NonOccupantSubTab.NonOther: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    if (this.getIsBicyclist()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
                        break;
                    }
                    if (this.getIsPedestrian()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
                        break;
                    }
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                }
                break;
            }
            case NonOccupantSubTab.Bicyclist: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    if (this.getIsPedestrian()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
                        break;
                    }
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                }
                break;
            }
            case NonOccupantSubTab.Pedestrian: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    if (this.getIsBicyclist()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
                        break;
                    }
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOther);
                }
                break;
            }
            case NonOccupantSubTab.NonMotoristActions: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Alcohol);
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    if (this.getIsBicyclist()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
                        break;
                    }
                    if (this.getIsPedestrian()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
                        break;
                    }
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOther);
                }
                break;
            }
            case NonOccupantSubTab.Alcohol: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Drug);
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
                }
                break;
            }
            case NonOccupantSubTab.Drug: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Supplemental);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Alcohol);
                }
                break;
            }
            case NonOccupantSubTab.Supplemental: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Injury);
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Drug);
                }
                break;
            }
            case NonOccupantSubTab.Injury: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccNotes);
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Supplemental);
                }
                break;
            }
            case NonOccupantSubTab.NonOccNotes: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getNonOccupantsCount() > -1 && this.nonOccupantid + 1 <= this.getNonOccupantsCount()) {
                        this.nonOccupantid = this.nonOccupantid + 1;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                        break;
                    }
                    if (this.getVehiclesCount() >= 1) {
                        this.vehicleid = 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                    }
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Injury);
                }
                break;
            }
            //case NonOccupantSubTab.NonOther: {
            //    if (event.key === KeyCode.PageDown) {
            //        if (this.getNonOccupantsCount() > -1 && this.nonOccupantid + 1 <= this.getNonOccupantsCount()) {
            //            this.nonOccupantid = this.nonOccupantid + 1;
            //            this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
            //            break;
            //        }
            //        if (this.getVehiclesCount() >= 1) {
            //            this.vehicleid = 1;
            //            this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
            //        }
            //    }
            //    if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
            //        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
            //    }
            //    break;
            //}
            //case NonOccupantSubTab.NonOccNotes: {
            //    if (event.key === KeyCode.PageDown) {
            //        if (this.getNonOccupantsCount() > 0) {
            //            this.nonOccupantid = 1;
            //            this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
            //        }
            //        else if (this.getVehiclesCount() > 0) {
            //            this.vehicleid = 1;
            //            this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
            //        }
            //    }
            //    if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
            //        this.navigateNextCrashTab(NonOccupantSubTab.Injury);
            //    }
            //    break;
            //}
            case SFRVehicleSubTab.Vehicle: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.MOSS) {
                    this.navigateNextVehicleTab(SFRVehicleSubTab.VIN);
                    break;
                }
            }

            //vehicle tabs
            case VehicleSubTab.Vehicle: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextVehicleTab(VehicleSubTab.DamagedAreas);
                    break;
                }
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VIN);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && !this.getIsPreviousVehicleDriverless()) {
                        this.vehicleid = this.vehicleid - 1
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextPersonTab(PersonSubTab.PersonNotes);
                    }
                    else if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && this.getIsPrecrash(this.vehicleid - 1)) {
                        this.vehicleid = this.vehicleid - 1
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextPrecrashTab(PrecrashSubTab.PrecrashOther);
                    }
                    else if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && this.getIsPreviousVehicleDriverless()) {
                        this.vehicleid = this.vehicleid - 1
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextDriverTab(DriverSubTab.DriverNotes);
                    }
                    else if (this.nonOccupantsCount > 0 && this.vehicleid == 1) {
                        this.nonOccupantid = this.nonOccupantsCount;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                        break;
                    }
                    else {
                        this.navigateNextCrashTab(CrashSubTab.Crashnotes);
                    }
                }
                break;
            }
            case VehicleSubTab.DamagedAreas: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VIN);
                }
                if (event.key === KeyCode.PageUp) {

                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                }
                break;
            }
            case VehicleSubTab.VIN: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getIsBodyType()) {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleDetails);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
                    }
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    this.navigateNextVehicleTab(VehicleSubTab.DamagedAreas);
                    break;
                }
                if (event.key === KeyCode.PageUp) {

                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                }
                break;
            }
            case VehicleSubTab.VehicleDetails: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
                }
                if (event.key === KeyCode.PageUp) {

                    this.navigateNextVehicleTab(VehicleSubTab.VIN);
                }
                break;
            }
            case VehicleSubTab.VehicleOther: {
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.MTSS) {
                    if (this.getIsTrailer() && this.getIsBodyType()) {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleTrailer);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleNotes);
                    }
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.MTSS) {
                    if (this.getIsBodyType()) {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleDetails);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VIN);
                    }
                }
                if (event.key === KeyCode.PageDown && this.acc.Mode == DBMode.PPSS) {
                    if (this.getIsTrailer() && this.getIsBodyType()) {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleTrailer);
                    }
                    else if (!this.getIsTrailer() && this.getIsBodyType()) {
                        this.navigateNextVehicleTab(VehicleSubTab.Rollover);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VehNonMotorist);
                    }
                }
                if (event.key === KeyCode.PageUp && this.acc.Mode == DBMode.PPSS) {
                    if (this.getIsBodyType()) {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleDetails);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VIN);
                    }
                }
                break;
            }
            case VehicleSubTab.VehicleTrailer: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.Rollover);
                }
                if (event.key === KeyCode.PageUp) {

                    this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
                }
                break;
            }
            case VehicleSubTab.Rollover: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.View);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getIsTrailer()) {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleTrailer);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
                    }
                }
                break;
            }
            case VehicleSubTab.View: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleNotes);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.Rollover);
                }
                break;
            }
            case VehicleSubTab.VehNonMotorist: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleNotes);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getIsBodyType()) {
                        this.navigateNextVehicleTab(VehicleSubTab.View);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
                    }
                }
                break;
            }
            case VehicleSubTab.VehicleNotes: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleVINDecode);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getIsBodyType()) {
                        this.navigateNextVehicleTab(VehicleSubTab.View);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VehNonMotorist);
                    }
                }
                break;
            }
            case VehicleSubTab.VehicleVINDecode: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextDriverTab(DriverSubTab.Driver);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleNotes);
                }
                break;
            }
            //driver tabs
            case DriverSubTab.Driver: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getIsDriverPresence()) {
                        this.navigateNextDriverTab(DriverSubTab.License);
                    }
                    else {
                        this.navigateNextDriverTab(DriverSubTab.DriverNotes);
                    }
                    //else if (this.acc.Veh.find(i => i.VNumber == this.vehicleid && i.UnitType == UnitType.InTransport)) {
                    //    this.navigateNextPrecrashTab(PrecrashSubTab.Roadway);
                    //}

                    //else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount())) {
                    //    this.vehicleid = this.vehicleid + 1;
                    //    this.navigateNextVehicleTab(DriverSubTab.License);
                    //    break;
                    //}

                    //else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 > this.getVehiclesCount())) {
                    //    this.navigateCheckCaseTab();
                    //    break;
                    //}

                    //else {
                    //    this.personid = 1
                    //    this.navigateNextPersonTab(PersonSubTab.Person);
                    //    break;
                    //}
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleVINDecode);
                }
                break;
            }
            case DriverSubTab.License: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextDriverTab(DriverSubTab.Counters);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextDriverTab(DriverSubTab.Driver);
                }
                break;
            }
            case DriverSubTab.Counters: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextDriverTab(DriverSubTab.DriverNotes);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextDriverTab(DriverSubTab.License);
                }
                break;
            }
            case DriverSubTab.DriverNotes: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getIsPrecrash(this.vehicleid)) {
                        this.navigateNextPrecrashTab(PrecrashSubTab.Roadway);
                        break;
                    }
                    else if (this.getPersonsCount(this.vehicleid)) {
                        this.personid = 1
                        this.navigateNextPersonTab(PersonSubTab.Person);
                        break;
                    }
                    else if (this.vehicleid + 1 <= this.vehiclesCount) {
                        this.vehicleid++
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                        break;
                    }
                    else if (this.vehicleid + 1 > this.vehiclesCount) {
                        this.vehicleid = 1;
                        this.navigateNextAvoidanceEquipmentTab(AvoidanceEquipmentSubTab.AvoidanceEquipment);
                        break;
                    }

                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getIsDriverPresence()) {
                        this.navigateNextDriverTab(DriverSubTab.Counters);
                        break;
                    }
                    else {
                        this.navigateNextDriverTab(DriverSubTab.Driver);
                    }

                }
                break;
            }
            //precrash tabs
            case PrecrashSubTab.Roadway: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPrecrashTab(PrecrashSubTab.Precrash);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextDriverTab(DriverSubTab.DriverNotes);
                }
                break;
            }
            case PrecrashSubTab.Precrash: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPrecrashTab(PrecrashSubTab.PrecrashOther);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPrecrashTab(PrecrashSubTab.Roadway);
                    break;
                }
                break;
            }
            case PrecrashSubTab.PrecrashOther: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getPersonsCount(this.vehicleid)) {
                        this.personid = 1
                        this.navigateNextPersonTab(PersonSubTab.Person);
                        break;
                    }
                    else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount())) {
                        this.vehicleid = this.vehicleid + 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Rollover);
                        break;
                    }
                    else {
                        //navigate to AvoidanceTab
                        this.navigateCheckCaseTab();
                    }
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPrecrashTab(PrecrashSubTab.Precrash);
                    break;
                }
                break;
            }

            //person tabs
            case PersonSubTab.Person: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPersonTab(PersonSubTab.SafetyDevices);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getPersonsCount() > -1 && this.personid > 1) {
                        this.personid = this.personid - 1;
                        this.navigateNextPersonTab(PersonSubTab.PersonNotes);
                        break;
                    }
                    else if (this.getIsPrecrash(this.vehicleid)) {
                        this.navigateNextPrecrashTab(PrecrashSubTab.PrecrashOther);
                        break;
                    }
                    else if (!this.getIsPrecrash(this.vehicleid) && this.getIsDriverPresence()) {
                        this.navigateNextDriverTab(DriverSubTab.Counters);
                        break;
                    }
                    else if (!this.getIsPrecrash(this.vehicleid) && !this.getIsDriverPresence()) {
                        this.navigateNextDriverTab(DriverSubTab.Driver);
                        break;
                    }
                }
                break;
            }
            case PersonSubTab.SafetyDevices: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPersonTab(PersonSubTab.PersonAlcohol);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPersonTab(PersonSubTab.Person);
                }
                break;
            }
            case PersonSubTab.PersonAlcohol: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPersonTab(PersonSubTab.PersonDrug);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPersonTab(PersonSubTab.SafetyDevices);
                }
                break;
            }
            case PersonSubTab.PersonDrug: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPersonTab(PersonSubTab.PersonNotes);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPersonTab(PersonSubTab.PersonAlcohol);
                }
                break;
            }
            case PersonSubTab.PersonNotes: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getPersonsCount() > -1 && this.personid + 1 <= this.getPersonsCount()) {
                        this.personid = this.personid + 1;
                        this.navigateNextPersonTab(PersonSubTab.Person);
                        break;
                    }
                    else if (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount()) {
                        this.vehicleid = this.vehicleid + 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                        break;
                    }
                    else {
                        //Navigate to Avoidance Tab
                        this.vehicleid = 1;
                        this.navigateNextAvoidanceEquipmentTab(AvoidanceEquipmentSubTab.AvoidanceEquipment);
                        //this.navigateCheckCaseTab();
                    }
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPersonTab(PersonSubTab.PersonDrug);
                }
                break;
            }
            case AvoidanceEquipmentSubTab.AvoidanceEquipment: {
                if (event.key === KeyCode.PageDown) {
                    if (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount()) {
                        this.vehicleid = this.vehicleid + 1;
                        this.navigateNextAvoidanceEquipmentTab(AvoidanceEquipmentSubTab.AvoidanceEquipment);
                        break;
                    }
                    else {
                        this.navigateCheckCaseTab();
                    }

                }
                if (event.key === KeyCode.PageUp) {
                    if (this.vehicleid - 1 > 0) {
                        this.vehicleid = this.vehicleid - 1;
                        this.navigateNextAvoidanceEquipmentTab(AvoidanceEquipmentSubTab.AvoidanceEquipment);
                    }
                    else if (this.vehicleid - 1 == 0 && !this.getIsVehicleDriverless()) {
                        this.vehicleid = this.vehiclesCount
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextPersonTab(PersonSubTab.PersonNotes);
                    }

                    else if (this.vehicleid - 1 == 0 && this.getIsPrecrash(this.vehiclesCount)) {
                        this.vehicleid = this.vehiclesCount
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextPrecrashTab(PrecrashSubTab.PrecrashOther);
                    }
                    else if (this.vehicleid - 1 == 0 && this.getIsVehicleDriverless()) {
                        this.vehicleid = this.vehiclesCount
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextDriverTab(DriverSubTab.DriverNotes);
                    }
                }
                break;
            }
        }
    }

    private NavigateToCRSSNextTab(event: KeyboardEvent, blnModalOpen: boolean = false): void {
        if (blnModalOpen)
            return;

        switch (this.currentTab) {
            case CrashSubTab.Crash: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextCrashTab(CrashSubTab.Events);
                }
                break;
            }
            case CrashSubTab.Events: {
                if (event.key === KeyCode.PageDown)
                    this.navigateNextCrashTab(CrashSubTab.Special);
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextCrashTab(CrashSubTab.Crash);
                }
                break;
            }
            case CrashSubTab.Special: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getNonOccupantsCount() > 0) {
                        this.nonOccupantid = 1;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                    }
                    else if (this.getVehiclesCount() > 0) {
                        this.vehicleid = 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                    }
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextCrashTab(CrashSubTab.Events);
                }
                break;
            }

            case NonOccupantSubTab.NonOccupant: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getIsBicyclist()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
                        break;
                    }

                    if (this.getIsPedestrian()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
                        break;
                    }
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
                    break;
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getNonOccupantsCount() > -1 && this.nonOccupantid - 1 > 0) {
                        this.nonOccupantid = this.nonOccupantid - 1;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Alcohol);
                        break;
                    }
                    this.navigateNextCrashTab(CrashSubTab.Special);
                }
                break;
            }
            case NonOccupantSubTab.Bicyclist: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getIsPedestrian()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
                        break;
                    }
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                }
                break;
            }

            case NonOccupantSubTab.Pedestrian: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getIsBicyclist()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
                        break;
                    }
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                }
                break;
            }
            case NonOccupantSubTab.NonMotoristActions: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.Alcohol);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getIsBicyclist()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Bicyclist);
                        break;
                    }

                    if (this.getIsPedestrian()) {
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Pedestrian);
                        break;
                    }
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                }
                break;
            }

            case NonOccupantSubTab.Alcohol: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getNonOccupantsCount() > -1 && this.nonOccupantid + 1 <= this.getNonOccupantsCount()) {
                        this.nonOccupantid = this.nonOccupantid + 1;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.NonOccupant);
                        break;
                    }
                    if (this.getVehiclesCount() >= 1) {
                        this.vehicleid = 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                    }
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextNonOccupantTab(NonOccupantSubTab.NonMotoristActions);
                }
                break;
            }

            case VehicleSubTab.Vehicle: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.DamagedAreas);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && !this.getIsPreviousVehicleDriverless()) {
                        this.vehicleid = this.vehicleid - 1
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextPersonTab(PersonSubTab.PersonAlcohol);
                    }
                    else if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && this.getIsPrecrash(this.vehicleid - 1)) {
                        this.vehicleid = this.vehicleid - 1
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextPrecrashTab(PrecrashSubTab.Precrash);
                    }
                    else if (this.vehiclesCount > -1 && this.vehicleid - 1 > 0 && this.getIsPreviousVehicleDriverless()) {
                        this.vehicleid = this.vehicleid - 1
                        this.personid = this.getPersonsCount(this.vehicleid);
                        this.navigateNextDriverTab(DriverSubTab.Driver);
                    }
                    else if (this.nonOccupantsCount > 0 && this.vehicleid == 1) {
                        this.nonOccupantid = this.nonOccupantsCount;
                        this.navigateNextNonOccupantTab(NonOccupantSubTab.Alcohol);
                        break;
                    }
                    else {
                        this.navigateNextCrashTab(CrashSubTab.Special);
                    }
                }
                break;
            }
            case VehicleSubTab.DamagedAreas: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VIN);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                }
                break;
            }
            case VehicleSubTab.VIN: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleDetails);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.DamagedAreas);
                }
                break;
            }
            case VehicleSubTab.VehicleDetails: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.VIN);
                }
                break;
            }
            case VehicleSubTab.VehicleOther: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getIsTrailer()) {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleTrailer);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleVINDecode);
                    }
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleDetails);
                }
                break;
            }
            case VehicleSubTab.VehicleTrailer: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleVINDecode);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
                }
                break;
            }
            case VehicleSubTab.VehicleVINDecode: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextDriverTab(DriverSubTab.Driver);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getIsTrailer()) {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleTrailer);
                    }
                    else {
                        this.navigateNextVehicleTab(VehicleSubTab.VehicleOther);
                    }
                }
                break;
            }

            case DriverSubTab.Driver: {
                if (event.key === KeyCode.PageDown) {
                    if (this.acc.Veh.find(i => i.VNumber == this.vehicleid && i.UnitType == UnitType.InTransport)) {
                        this.navigateNextPrecrashTab(PrecrashSubTab.Roadway);
                    }

                    else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount())) {
                        this.vehicleid = this.vehicleid + 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                        break;
                    }

                    else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 > this.getVehiclesCount())) {
                        this.navigateCheckCaseTab();
                        break;
                    }

                    else {
                        this.personid = 1
                        this.navigateNextPersonTab(PersonSubTab.Person);
                        break;
                    }
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextVehicleTab(VehicleSubTab.VehicleVINDecode);
                }
                break;
            }
            case PrecrashSubTab.Roadway: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPrecrashTab(PrecrashSubTab.Precrash);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextDriverTab(DriverSubTab.Driver);
                }
                break;
            }
            case PrecrashSubTab.Precrash: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getPersonsCount(this.vehicleid)) {
                        this.personid = 1
                        this.navigateNextPersonTab(PersonSubTab.Person);
                        break;
                    }
                    else if (this.getIsVehicleDriverless() && (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount())) {
                        this.vehicleid = this.vehicleid + 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                        break;
                    }
                    else {
                        this.navigateCheckCaseTab();
                    }
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPrecrashTab(PrecrashSubTab.Roadway);
                    break;
                }
                break;
            }

            case PersonSubTab.Person: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPersonTab(PersonSubTab.SafetyDevices);
                }
                if (event.key === KeyCode.PageUp) {
                    if (this.getPersonsCount() > -1 && this.personid > 1) {
                        this.personid = this.personid - 1;
                        this.navigateNextPersonTab(PersonSubTab.PersonAlcohol);
                        break;
                    }
                    else if (this.getIsPrecrash(this.vehicleid)) {
                        this.navigateNextPrecrashTab(PrecrashSubTab.Precrash);
                        break;
                    }

                    else if (!this.getIsPrecrash(this.vehicleid)) {
                        this.navigateNextDriverTab(DriverSubTab.Driver);
                        break;
                    }
                }
                break;
            }
            case PersonSubTab.SafetyDevices: {
                if (event.key === KeyCode.PageDown) {
                    this.navigateNextPersonTab(PersonSubTab.PersonAlcohol);
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPersonTab(PersonSubTab.Person);
                }
                break;
            }
            case PersonSubTab.PersonAlcohol: {
                if (event.key === KeyCode.PageDown) {
                    if (this.getPersonsCount() > -1 && this.personid + 1 <= this.getPersonsCount()) {
                        this.personid = this.personid + 1;
                        this.navigateNextPersonTab(PersonSubTab.Person);
                        break;
                    }
                    else if (this.acc.Veh.length > -1 && this.vehicleid + 1 <= this.getVehiclesCount()) {
                        this.vehicleid = this.vehicleid + 1;
                        this.navigateNextVehicleTab(VehicleSubTab.Vehicle);
                        break;
                    }
                    else {
                        this.navigateCheckCaseTab();
                    }
                }
                if (event.key === KeyCode.PageUp) {
                    this.navigateNextPersonTab(PersonSubTab.SafetyDevices);
                }
                break;
            }
        }
    }

    navigateNextCrashTab(navigateToTab) {
        this._router.navigate([this.stateNum, URLstrings.Case, this.accid, URLstrings.Crash, this.accid, navigateToTab]);
    }

    navigateNextNonOccupantTab(navigateToTab) {
        this._router.navigate([this.stateNum, URLstrings.Case, this.accid, URLstrings.NonOccupant, this.nonOccupantid, navigateToTab]);
    }

    navigateNextVehicleTab(navigateToTab) {
        this._router.navigate([this.stateNum, URLstrings.Case, this.accid, URLstrings.Vehicle, this.vehicleid, URLstrings.Vehicle, navigateToTab]);
    }

    navigateNextDriverTab(navigateToTab) {
        this._router.navigate([this.stateNum, URLstrings.Case, this.accid, URLstrings.Vehicle, this.vehicleid, URLstrings.Driver, navigateToTab]);
    }

    navigateNextPrecrashTab(navigateToTab) {
        this._router.navigate([this.stateNum, URLstrings.Case, this.accid, URLstrings.Vehicle, this.vehicleid, URLstrings.Precrash, navigateToTab]);
    }

    navigateNextPersonTab(navigateToTab) {
        this._router.navigate([this.stateNum, URLstrings.Case, this.accid, URLstrings.Vehicle, this.vehicleid, URLstrings.Person, this.personid, navigateToTab]);
    }

    navigateNextAvoidanceEquipmentTab(navigateToTab) {
        this._router.navigate([this.stateNum, URLstrings.Case, this.accid, URLstrings.VehicleAvoidEquip, this.vehicleid, URLstrings.Ae, navigateToTab]);
    }

    navigateCheckCaseTab() {
        if (!this.acc.Deleted)
            this._actionButtonsComponent.onSave(this.acc, 'checkCase');
    }

    OnGoToCaseFiles(AccId) {
        this._sharedDataService.setLastCaseUrl(this._router.url);
        this._router.navigate([this.stateNum, "caseFiles", AccId]);
    }

    OnGoToSSCaseFiles(accid) {
        this._sharedDataService.setLastCaseUrl(this._router.url);
        this._router.navigate(["CaseDocuments", this.stateNum, accid]);
    }

    RedirecttoCaseDocument() {
        var accNo = this.accid.toString();
        this._router.navigate([this.stateNum, '/case-files/' + accNo]);
    }

    ngOnDestroy() { //We are setting flag blnReadOnly from UIElementBase as false once we are leaving the page to reset so it does not efect other pages like EN Create...
        UIElementBase.blnReadOnly = false;
        this.arrSubscription.forEach((sbs: Subscription) => {
            sbs.unsubscribe();
        })
    }

    gotoPrint(type: string = 'case') {

        let Id: string = "0";
        let personId = "0";
        let isVehicle = false;
        if (type == 'form') {
            const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment; //urlTreeHelper.GetURLSegments(); //this.getURLSegments();
            //this.setURLsegments(urlSegments);
            const currentTab = UrlTreeHelper.FindAndSetCurrentTab(urlSegments);
            type = currentTab; //opening current selected tab
            if (urlSegments.length > 4) {
                Id = urlSegments[4].path; //taking Id for that module 
            }

            if (window.location.href.includes('person')) {
                personId = urlSegments[6].path
            }
        }

        //we may simply used Url Part only
        const parsedUrl = new URL(window.location.href);
        const baseUrl = parsedUrl.origin;
        //above code may removed to be safe side taking origin base Url

        const url = baseUrl + '/' + this.stateNum + '/case/' + this.accid + '/print/' + type + '/' + Id + '/' + personId;
        window.open(url, '_blank');

        //  this._router.navigate([this.stateNum, 'case', this.accid, 'print', type, Id])
    }

    get appMode() {
        return DBMode;
    }


    async onDownloadAll(): Promise<void> {
        try {
            await this.caseFileService.downloadAll(this.acc);
        } catch (ex) {
            console.log(ex);
            this.caseFileService.UnlockCaseFile(this.accid);
            this._modalService.setMessage('Failed to Download All', 'danger');
        }
    }
}
