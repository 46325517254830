import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { CaseService } from 'src/app/services/case.service';

import { LabelComponent } from 'src/app/components/label/label.component';
import { ModalsModule } from 'src/app/components/modals/modals.module'
import { MultiselectComponent } from 'src/app/components/multiselect/multiselect.component';
import { OtherSpecifyComponent } from 'src/app/components/other-specify/other-specify.component';
import { TreeViewComponent } from 'src/app/components/tree-view/tree-view.component';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { TextFieldComponent } from 'src/app/components/textfield/textfield.component';

import { CustomMinDirective } from 'src/app/directives/custom-min.directive';
import { CustomMaxDirective } from 'src/app/directives/custom-max.directive';
import { NgbdSortableHeader } from 'src/app/directives/sortable-header.directive';

import { ActionButtonsComponent } from 'src/app/ui/action-buttons/action-buttons.component';


/**
 * Deprecated
 * //import { DrugToxicologyResultsComponent } from 'src/app/ui/drug-toxicology-results/drug-toxicology-results.component';
 **/

import { CaseComponent } from './case.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CaseCreateComponent } from './case-create/case-create.component';
import { CaseEditComponent } from './case-edit/case-edit.component';
import { CaseDeleteComponent } from './case-delete/case-delete.component';
import { CaseClosedComponent } from './case-close/case-close.component';

import { EarlyNotificationCreateComponent } from './../early-notification/early-notification-create/early-notification-create.component';
import { EarlyNotificationDetailsComponent } from './../early-notification/early-notification-details/early-notification-details.component';

import { CheckCaseComponent } from './../check-case/check-case.component';
import { ViolatedRulesComponent } from './../check-case/violated-rules/violated-rules.component';

import { OverriddenRulesComponent } from './../check-case/overridden-rules/overridden-rules.component';
import { BlanksComponent } from './../check-case/blanks/blanks.component';
import { OverrideRuleComponent } from './../check-case/override-rule/override-rule.component';

import { CrashComponent } from './../crash/crash.component';
import { CrashCrashComponent } from './../crash/crash-crash/crash-crash.component';
import { CrasheventlistComponent } from './../crash/crash-events/crasheventlist/crasheventlist.component';
import { CrashTrafficwayComponent } from './../crash/crash-trafficway/crash-trafficway.component';
import { CrashOtherComponent } from './../crash/crash-other/crash-other.component';
import { CrashSpecialComponent } from './../crash/crash-special/crash-special.component';
import { CrashNotesComponent } from 'src/app/ui/crash/crash-notes/crash-notes.component';
import { NonMotoristComponent } from '../crash/non-motorist/non-motorist.component';

import { NonOccupantComponent } from './../non-occupant/non-occupant.component';
import { NonOccupantNonOccupantComponent } from './../non-occupant/non-occupant-non-occupant/non-occupant-non-occupant.component';
import { NonOccupantNonMotoristActionsComponent } from './../non-occupant/non-occupant-non-motorist-actions/non-occupant-non-motorist-actions.component';
import { NonOccupantAlcoholComponent } from './../non-occupant/non-occupant-alcohol/non-occupant-alcohol.component';
import { NonOccupantDrugComponent } from './../non-occupant/non-occupant-drug/non-occupant-drug.component';
import { NonOccupantRaceComponent } from './../non-occupant/non-occupant-race/non-occupant-race.component';
import { NonOccupantSupplementalComponent } from './../non-occupant/non-occupant-supplemental/non-occupant-supplemental.component';
import { NonOccupantPedestrianComponent } from './../non-occupant/non-occupant-pedestrian/non-occupant-pedestrian.component';
import { NonOccupantBicyclistComponent } from './../non-occupant/non-occupant-bicyclist/non-occupant-bicyclist.component';
import { NonOccupantOtherComponent } from '../non-occupant/non-occupant-other/non-occupant-other.component';
import { NonOccupantInjuryComponent } from '../non-occupant/non-occupant-injury/non-occupant-injury.component';

import { VehicleComponent } from './../vehicle/vehicle.component';
import { VehicleVehicleComponent } from './../vehicle/vehicle-vehicle/vehicle-vehicle.component';
import { VehicleVehicleVehicleComponent } from './../vehicle/vehicle-vehicle/vehicle-vehicle-vehicle/vehicle-vehicle-vehicle.component';
import { VehicleVehicleDamagedAreasComponent } from './../vehicle/vehicle-vehicle/vehicle-vehicle-damaged-areas/vehicle-vehicle-damaged-areas.component';
import { VehicleVehicleVinComponent } from './../vehicle/vehicle-vehicle/vehicle-vehicle-vin/vehicle-vehicle-vin.component';
import { VehicleVehicleDetailsComponent } from './../vehicle/vehicle-vehicle/vehicle-vehicle-details/vehicle-vehicle-details.component';
import { VehicleVehicleOtherComponent } from './../vehicle/vehicle-vehicle/vehicle-vehicle-other/vehicle-vehicle-other.component';
import { VehicleVehicleNonMotoristComponent } from '../vehicle/vehicle-vehicle/vehicle-non-motorist/vehicle-non-motorist.component';

import { VehicleDriverComponent } from './../vehicle/vehicle-driver/vehicle-driver.component';
import { VehicleDriverDriverComponent } from './../vehicle/vehicle-driver/vehicle-driver-driver/vehicle-driver-driver.component';
import { VehicleDriverLicenseComponent } from './../vehicle/vehicle-driver/vehicle-driver-license/vehicle-driver-license.component';
import { VehicleDriverCountersComponent } from './../vehicle/vehicle-driver/vehicle-driver-counters/vehicle-driver-counters.component';
import { VehicleDriverNotesComponent } from 'src/app/ui/vehicle/vehicle-driver/vehicle-driver-notes/vehicle-driver-notes.component';

import { VehiclePrecrashComponent } from './../vehicle/vehicle-precrash/vehicle-precrash.component';
import { VehiclePrecrashRoadwayComponent } from './../vehicle/vehicle-precrash/vehicle-precrash-roadway/vehicle-precrash-roadway.component';
import { VehiclePrecrashPrecrashComponent } from './../vehicle/vehicle-precrash/vehicle-precrash-precrash/vehicle-precrash-precrash.component';
import { VehiclePrecrashOtherComponent } from 'src/app/ui/vehicle/vehicle-precrash/vehicle-precrash-other/vehicle-precrash-other.component';

import { VehiclePersonComponent } from './../vehicle/vehicle-person/vehicle-person.component';
import { VehiclePersonPersonComponent } from './../vehicle/vehicle-person/vehicle-person-person/vehicle-person-person.component';
import { VehiclePersonSafetyDevicesComponent } from './../vehicle/vehicle-person/vehicle-person-safety-devices/vehicle-person-safety-devices.component';
import { VehiclePersonAlcoholComponent } from './../vehicle/vehicle-person/vehicle-person-alcohol/vehicle-person-alcohol.component';
import { VehiclePersonDrugComponent } from './../vehicle/vehicle-person/vehicle-person-drug/vehicle-person-drug.component';
import { VehiclePersonRaceComponent } from './../vehicle/vehicle-person/vehicle-person-race/vehicle-person-race.component';
import { VehiclePersonSuplementalComponent } from './../vehicle/vehicle-person/vehicle-person-suplemental/vehicle-person-suplemental.component';
import { VehiclePersonNotesComponent } from 'src/app/ui/vehicle/vehicle-person/vehicle-person-notes/vehicle-person-notes.component';

import { VehicleVehicleTrailerComponent } from './../vehicle/vehicle-vehicle/vehicle-vehicle-trailer/vehicle-vehicle-trailer.component';
import { VehicleVehicleVinDecodeComponent } from './../vehicle/vehicle-vehicle/vehicle-vehicle-vin-decode/vehicle-vehicle-vin-decode.component';
import { VehicleVehicleCmvComponent } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-cmv/vehicle-vehicle-cmv.component';

import { VehicleVehicleRolloverComponent } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-rollover/vehicle-vehicle-rollover.component';
import { VehicleVehicleViewComponent } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-view/vehicle-vehicle-view.component';
import { VehicleVehicleNotesComponent } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-notes/vehicle-vehicle-notes.component';
//import { VehicleVehicleTruckComponent } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-truck/vehicle-vehicle-truck.component';
//import { VehicleVehicleNontruckComponent } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-nontruck/vehicle-vehicle-nontruck.component';

import { AvoidanceEquipmentComponent } from 'src/app/ui/avoidance-equipment/avoidance-equipment.component';

import { RegexValidatorDirective } from 'src/app/directives/validators/regex-validator.directive';

import { EnumAsStringPipe } from './../../pipes/enum-as-string.pipe';
import { LeadingZeroPipe } from './../../pipes/leading-zero.pipe';
import { MaxLengthPipe } from 'src/app/pipes/maxlength.pipe';

// import { NgxFileDropModule } from 'ngx-file-drop'; -- will check it later. 
import { ReplaceTextPipe } from 'src/app/pipes/replaceText.pipe';
import { UsernamePipe } from 'src/app/pipes/username.pipe';
import { TextNewLinePipe } from 'src/app/pipes/text-new-line.pipe';
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { AutofillService } from 'src/app/services/autofill.service';
import { NumericDirective } from 'src/app/directives/numeric.directive';

import { CaseFilesListsService } from 'src/app/services/case-files-lists.service';
// containers
import { CaseHeaderComponent } from 'src/app/ui/cases/case-header/case-header.component';
// components 
import { CaseHeaderFarsComponent } from './case-header/case-header-fars/case-header-fars.component';
import { CaseHeaderCrssComponent } from './case-header/case-header-crss/case-header-crss.component';

//CRSS
import { CaseScreenerComponent } from 'src/app/ui/crss/case-screener/case-screener.component';

//VINRequests
import { VINRequestsDetailsComponent } from 'src/app/ui/vin-request/vin-requests-details/vin-requests-details.component';
import { AskCodingQuestionComponent } from './ask-coding-question/ask-coding-question.component';

import { NoReuseRouteReuseStrategy } from './noreuse.routereusestrategy';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { PrintcaseComponent } from './printcase/printcase.component';
import { CaseResolver } from 'src/app/services/routerresolving.service';

import { TextNewLineDirective } from 'src/app/directives/text-new-line.directive';
import { TextUppercaseDirective } from 'src/app/directives/text-uppercase.directive';
import { EdtConfidenceColorComponent } from 'src/app/components/edt-confidence-color/edt-confidence-color.component';
import { CaseHeaderMtsComponent } from './case-header/case-header-mts/case-header-mts.component';

import { SpeedCalculatorComponent } from 'src/app/components/modals/speed-calculator/speed-calculator.component';

import { HeightCalculatorComponent } from 'src/app/components/modals/height-calculator/height-calculator.component';
import { NonOccupantNotesComponent } from '../non-occupant/non-occupant-notes/non-occupant-notes.component';

import { StrikingVehicleComponent } from 'src/app/ui/striking-vehicle/striking-vehicle.component';
import { StrikingVehicleVehicleComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle.component';
import { StrikingVehicleVinComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-vin/striking-vehicle-vin.component';
import { StrikingVehicleNotesComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-notes/striking-vehicle-notes.component';
import { StrikingVehicleVehicleVehicleComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle-vehicle/striking-vehicle-vehicle-vehicle.component';
import { StrikingVehicleVehicleNotesComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle-notes/striking-vehicle-vehicle-notes.component';
import { StrikingVehicleVehicleVinComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle-vin/striking-vehicle-vehicle-vin.component';
import { StrikingVehicleVehicleVinDecodeComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle-vin-decode/striking-vehicle-vehicle-vin-decode.component';

import { SfrVehicleComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle.component';
import { SfrVehicleVehicleComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle.component';
import { SfrVehicleVehicleVinComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-vin/sfr-vehicle-vehicle-vin.component';
import { SfrVehicleVehicleVinDecodeComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-vin-decode/sfr-vehicle-vehicle-vin-decode.component';

import { SfrVehicleVehicleControlComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-control/sfr-vehicle-vehicle-control.component';
import { SfrVehicleVehicleNotesComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-notes/sfr-vehicle-vehicle-notes.component';
import { SfrVehicleVehicleOtherComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-other/sfr-vehicle-vehicle-other.component';
import { SfrVehicleVehicleVehicleComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-vehicle/sfr-vehicle-vehicle-vehicle.component';


import { StrikingVehiclePrecrashComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-precrash/striking-vehicle-precrash.component';
import { StrikingVehiclePrecrashPrecrashComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-precrash/striking-vehicle-precrash-precrash/striking-vehicle-precrash-precrash.component';
import { StrikingVehiclePrecrashRoadwayComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-precrash/striking-vehicle-precrash-roadway/striking-vehicle-precrash-roadway.component';
import { StrikingVehiclePrecrashOtherComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-precrash/striking-vehicle-precrash-other/striking-vehicle-precrash-other.component';

import { SfrVehiclePersonComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person.component';
import { SfrVehiclePersonPersonComponent } from 'src/app/ui/sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person-person/sfr-vehicle-person-person.component';
import { SfrVehiclePersonSafetyComponent } from '../sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person-safety/sfr-vehicle-person-safety.component';
import { SfrVehiclePersonAlcoholComponent } from '../sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person-alcohol/sfr-vehicle-person-alcohol.component';
import { SfrVehiclePersonNotesComponent } from '../sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person-notes/sfr-vehicle-person-notes.component';

import { StrikingVehiclePersonComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person.component';
import { StrikingVehiclePersonPersonComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person-person/striking-vehicle-person-person.component';
import { StrikingVehiclePersonSafetyComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person-safety/striking-vehicle-person-safety.component';
import { StrikingVehiclePersonAlcoholComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person-alcohol/striking-vehicle-person-alcohol.component';
import { StrikingVehicleDriverDriverComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-driver-driver/striking-vehicle-driver-driver.component';
import { StrikingVehicleDriverNotesComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-driver-notes/striking-vehicle-driver-notes.component';
import { StrikingVehiclePersonNotesComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person-notes/striking-vehicle-person-notes.component';

import { StrikingVehicleDriverComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver.component';
//import { StrikingVehicleDriverDriverComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-driver/striking-vehicle-driver-driver.component';
import { StrikingVehicleDriverPersonComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-person/striking-vehicle-driver-person.component';
import { StrikingVehicleDriverSafetyComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-safety/striking-vehicle-driver-safety.component';
import { StrikingVehicleDriverAlcoholComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-alcohol/striking-vehicle-driver-alcohol.component';
import { NgxFileDropModule } from 'ngx-file-drop';
//import { StrikingVehicleDriverNotesComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-notes/striking-vehicle-driver-notes.component';


@NgModule({
    declarations: [
        CaseComponent,
        CaseListComponent,
        CaseCreateComponent,
        CaseEditComponent,
        CaseDeleteComponent,
        CaseClosedComponent,
        CaseHeaderComponent,
        CaseHeaderFarsComponent,
        CaseHeaderCrssComponent,

        EarlyNotificationCreateComponent,
        EarlyNotificationDetailsComponent,

        CheckCaseComponent,
        ViolatedRulesComponent,
        OverriddenRulesComponent,
        BlanksComponent,
        OverrideRuleComponent,

        CrashComponent,
        CrashCrashComponent,
        CustomMinDirective,
        CustomMaxDirective,
        CrasheventlistComponent,

        CrashTrafficwayComponent,
        CrashOtherComponent,
        CrashSpecialComponent,
        CrashNotesComponent,

        NonMotoristComponent,

        NonOccupantComponent,

        NonOccupantNonOccupantComponent,
        NonOccupantNonMotoristActionsComponent,
        NonOccupantAlcoholComponent,
        NonOccupantDrugComponent,
        NonOccupantRaceComponent,
        NonOccupantSupplementalComponent,
        NonOccupantPedestrianComponent,
        NonOccupantBicyclistComponent,

        NonOccupantOtherComponent,
        NonOccupantInjuryComponent,
        NonOccupantNotesComponent,

        VehicleComponent,
        VehicleVehicleCmvComponent,
        VehicleVehicleComponent,
        VehicleVehicleVehicleComponent,
        VehicleVehicleDamagedAreasComponent,
        VehicleVehicleVinComponent,
        VehicleVehicleDetailsComponent,
        VehicleVehicleOtherComponent,
        VehicleVehicleTrailerComponent,
        VehicleVehicleVinDecodeComponent,

        VehicleVehicleRolloverComponent,
        VehicleVehicleViewComponent,
        VehicleVehicleNotesComponent,
        //VehicleVehicleTruckComponent,
        //VehicleVehicleNontruckComponent,
        VehicleVehicleNonMotoristComponent,

        VehicleDriverComponent,
        VehicleDriverDriverComponent,
        VehicleDriverLicenseComponent,
        VehicleDriverCountersComponent,
        VehicleDriverNotesComponent,

        VehiclePrecrashComponent,
        VehiclePrecrashRoadwayComponent,
        VehiclePrecrashPrecrashComponent,
        VehiclePrecrashOtherComponent,

        VehiclePersonComponent,
        VehiclePersonPersonComponent,
        VehiclePersonSafetyDevicesComponent,
        VehiclePersonAlcoholComponent,
        VehiclePersonDrugComponent,
        VehiclePersonRaceComponent,
        VehiclePersonSuplementalComponent,
        VehiclePersonNotesComponent,

        AvoidanceEquipmentComponent,

        TreeViewComponent,
        TypeaheadComponent,
        TextFieldComponent,
        EdtConfidenceColorComponent,
        MultiselectComponent,
        ActionButtonsComponent,
        OtherSpecifyComponent,
        NgbdSortableHeader,       
        LabelComponent,
        RegexValidatorDirective,
        EnumAsStringPipe,
        LeadingZeroPipe,
        MaxLengthPipe,
        ReplaceTextPipe,
        UsernamePipe,
        TextNewLinePipe,
        ValueToDescriptionPipe,
        NumericDirective,

        VINRequestsDetailsComponent,
        AskCodingQuestionComponent,

        PrintcaseComponent,
        TextNewLineDirective,
        TextUppercaseDirective,
        CaseScreenerComponent,
        CaseHeaderMtsComponent,
        SpeedCalculatorComponent,
        HeightCalculatorComponent,

        SfrVehicleComponent,
        SfrVehicleVehicleComponent,
        SfrVehicleVehicleVehicleComponent,
        SfrVehicleVehicleVinComponent,
        SfrVehicleVehicleVinDecodeComponent,
        SfrVehicleVehicleControlComponent,
        SfrVehicleVehicleNotesComponent,
        SfrVehicleVehicleOtherComponent,
        StrikingVehicleComponent,

        SfrVehiclePersonComponent,
        SfrVehiclePersonPersonComponent,
        SfrVehiclePersonAlcoholComponent,
        SfrVehiclePersonNotesComponent,
        SfrVehiclePersonSafetyComponent,

        StrikingVehicleVinComponent,
        StrikingVehicleNotesComponent,

        StrikingVehicleVehicleComponent,
        StrikingVehicleVehicleVehicleComponent,
        StrikingVehicleVehicleComponent,
        StrikingVehicleVehicleVinComponent,
        StrikingVehicleVehicleVinDecodeComponent,
        StrikingVehicleVehicleNotesComponent,

        StrikingVehiclePersonComponent,
        StrikingVehiclePersonPersonComponent,
        StrikingVehiclePersonAlcoholComponent,
        StrikingVehiclePersonNotesComponent,
        StrikingVehiclePersonSafetyComponent,

        StrikingVehiclePrecrashComponent,
        StrikingVehiclePrecrashPrecrashComponent,
        StrikingVehiclePrecrashRoadwayComponent,
        StrikingVehiclePrecrashOtherComponent,

        StrikingVehiclePersonSafetyComponent,
        StrikingVehicleDriverComponent,
        StrikingVehicleDriverDriverComponent,
        StrikingVehicleDriverPersonComponent,
        StrikingVehicleDriverSafetyComponent,
        StrikingVehicleDriverAlcoholComponent,
        StrikingVehicleDriverNotesComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        NgbModule,
        ModalsModule,
        FormsModule,
        BrowserModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxFileDropModule

    ],
    providers: [
        CaseService,
        AutofillService,
        UrlTreeHelper,
        ValueToDescriptionPipe,
        CaseFilesListsService,
        CaseResolver,
        DatePipe,
        UsernamePipe,
        { provide: RouteReuseStrategy, useClass: NoReuseRouteReuseStrategy }
    ],
    exports: [
        TypeaheadComponent,
        TextFieldComponent,
        ModalsModule,
        MultiselectComponent,
        ActionButtonsComponent,
        EnumAsStringPipe,
        LeadingZeroPipe,
        MaxLengthPipe,
        NumericDirective,
        RegexValidatorDirective,
        ReplaceTextPipe,
        UsernamePipe,
        TextNewLinePipe,
        ValueToDescriptionPipe,
        EnumAsStringPipe,
        TextNewLineDirective,
        TextUppercaseDirective,
        //EdtCaseStatusComponent,
        CaseHeaderComponent //Moh 04-10-2020
    ],
    entryComponents: [PrintcaseComponent]

})
export class CaseModule { }
