import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, Renderer2, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map, startWith, debounceTime } from 'rxjs/operators';

import { CaseService } from 'src/app/services/case.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UtilService } from 'src/app/services/util.service';

import { Veh } from 'src/app/models/veh';

import { TableFieldElements } from 'src/app/models/table-field-elements';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { FormName, RBISDataValue, CreatedRecord_Occupant_Type, Preference, MailRequestType, VehType } from 'src/app/models/enums/app.enums';

import { BaseComponent } from 'src/app/helper/basecomponent';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { vinDecode } from 'src/app/models/vin-decode';

import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';
import { LookupTable, FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { Acc } from 'src/app/models/acc';
import { Subject, Subscription } from 'rxjs';
import { Element_Specify } from 'src/app/models/element-specify';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { NgForm } from '@angular/forms';
import { AutofillService } from 'src/app/services/autofill.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
//emit this.pickedDate for binding pii.DoB field on person-person
import { ModalsWeightCalculatorComponent } from 'src/app/components/modals/modals-weight-calculator/modals-weight-calculator.component';

@Component({
    selector: 'app-vehicle-vehicle-vin',
    templateUrl: './vehicle-vehicle-vin.component.html',
    styleUrls: ['./vehicle-vehicle-vin.component.css'],
    providers: [ValueToDescriptionPipe],
    encapsulation: ViewEncapsulation.None
})

export class VehicleVehicleVinComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehicleVehicleVinComponent: string = null;
    public objStandalone: { standalone: boolean } = { standalone: true };
    sbsClearForm: Subscription;

    stateNum: number;
    accid: number;
    vehicleid: number;
    //vinNumber: string;
    vinData: any;
    acc: Acc;
    disabledVinControl: boolean = false;
    disabledvPicGVWR: boolean = false;
    isFinalStageBodyClassVisiable: boolean = false;
    blnTruck: boolean = false;

    veh: Veh;
    objVehClone: Veh;
    vpicResult: vinDecode;
    fieldMetadata: TableFieldElements[];
    vinMetaData: TableFieldElements;
    objInstanceElementSpecify: Element_Specify;

    blnDisableDecodeVIN: boolean = false;

    strVinBeforeChange: string = "";
    intVpicMakeBeforeChange: number = RBISDataValue.Blank;
    intVpicBodyBeforeChange: number = RBISDataValue.Blank;

    arrVpicElementsFields: Array<string> = ['ModelYr', 'vPICMake', 'vPICModel', 'vPICBodyClass', 'vPICGVWR', 'Make', 'Model', 'Body', 'GvwRating', 'Model_Yr', 'vPICGVWRTo', 'FinalStageBodyClass'];
    arrNonVpicElementsFields: Array<string> = ['ADSPresent', 'ADSLevel', 'ADSEngaged', 'VIN', 'VehicleEmptyWeight'];
    arrVpicMakeDependentFields: Array<string> = ['vPICMake', 'vPICModel', 'Make', 'Model', 'Body'];


    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('confirmMessage') dialogBox: ElementRef;
    @ViewChild('fvvvin') objForm: NgForm;
    @ViewChild('txtNcsaBodyType') private objTypeaheadBody: TypeaheadComponent;
    @ViewChild('txtVIN') private objVIN: TypeaheadComponent;
    @ViewChild('mwcElement') mwcElement: ModalsWeightCalculatorComponent;



    caseNumber: number = 0;
    isFirstPopup: boolean = false;
    //added by khem
    private _success = new Subject<string>();
    staticAlertClosed = false;
    successMessage: string;


    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _sharedDataService: SharedDataService,
        private _vehicleService: VehicleService,
        private intValTostrVal: ValueToDescriptionPipe,
        private _autoFillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper


    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        if (this.veh != undefined && this.veh != null)
            this._caseService.GetTabDisplayStatus(this.veh);

        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this._sharedDataService.setVinDecode(null);

        if (this.sbsClearForm) {
            this.sbsClearForm.unsubscribe();
        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.accid = this.acc.AccID;

        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.stateNum = + params['stateNum'];
                this.accid = + params['caseid'];
                this._route.parent.parent.params.subscribe(params => {

                    this.vehicleid = + params['vehicleid'];
                    this.initData();
                });
            });
        } else {
            this.vehicleid = this.printVehNum;
            this.initData()
        }

        this._utilService.metaDataToShare.subscribe(result => {
            this.vinMetaData = result.find(x => x.Form == FormName.Vehicle && x.Field == FieldName.VIN);
        })
        setTimeout(() => this.staticAlertClosed = true, 20000);
        this._success.subscribe((message) => this.successMessage = message);
        this._success.pipe(
            debounceTime(5000)
        ).subscribe(() => this.successMessage = null);

    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    onBodyTypeChange(value) {
        if (value.intValue >= 60 && value.intValue <= 67 && value.intValue != 63) {
            this.blnTruck = true;
        }
        else {
            this.blnTruck = false;
        }
    }

    initData() {
        if (this.intMode == 256)
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.OtherVehicle);
        else
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        this.objVehClone = ObjectUtil.CloneModelObject(this.veh);
        this.setVinDecoderValues();
        if (this.veh.Body >= 60 && this.veh.Body <= 67 && this.veh.Body != 63) {
            this.blnTruck = true;
        }
        else {
            this.blnTruck = false;
        }

        this._sharedDataService.setVinNumber(this.veh.VIN);
        if (this.veh.vPICBodyClass > -1) {
            setTimeout(async () => {
                let finalStageValue = await this.intValTostrVal.transform(this.veh.vPICBodyClass, 'vPICBodyClass')
                this.isFinalStageBodyClassVisiable = (finalStageValue) && (finalStageValue.toLowerCase().includes('incomplete')) ? true : false;
            }, 50);
        }


        //Listen subscription for Clear Form btn event click;
        this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe(item => {
            this.veh.ErrorCode = RBISDataValue.Blank.toString();
            this.veh.VPICDECODE = null;
            this.enableAndCleardVinDecodedFields();

            let uiControls = this._autoFillService.arrControls.filter(i => i.strFieldName == "ADSLevel" || i.strFieldName == "ADSEngaged");

            if (uiControls) {
                uiControls.forEach(control => {
                    control.clearComponent();
                    control.disableOrEnableComponent(false);
                })
            }

            this.FilterNcsaBodyType(null);
        });
        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.vehicleid, 0);
    }

    setVinDecoderValues() {
        this.disabledVinControl = false;
        this.disabledvPicGVWR = false;

        if (this.veh.ErrorCode && this.veh.ErrorCode != "") {
            if (this.veh.ErrorCode.split(',').some(strCode => VehicleService.arrVinDecodeErrorList.includes(strCode))) {
                this.disabledVinControl = false;
                this.disabledvPicGVWR = false;
            }
            else if (this.veh.ErrorCode.split(',').every(strCode => VehicleService.arrVinDecodesCleanList.includes(strCode))) {
                this.disabledVinControl = true;
                this.disabledvPicGVWR = this._vehicleService.intVpicGVWR > RBISDataValue.Blank;
            }
        }
    }

    decodeVin(inComingObject) {
        this._modalService.resetMessage();

        //Cheking if VIN # has WhiteSpace
        let blnWhiteSpace = ObjectUtil.HasWhiteSpaces(this.veh.VIN);


        if (this.veh.VIN === "" || VehicleService.arrNotDecodedVin.includes(this.veh.VIN)) {
            this._modalService.setMessage("No VIN Checking is performed if VIN is blank, all 9s or 0s or 8s.", "info");
            return;
        }

        if (blnWhiteSpace) {
            this._modalService.setMessage("Vehicle VIN number has empty space/s, please replace it with * and try again", "warning");
            return;
        }

        else {
            this.objForm.form.markAsPristine();
            this._sharedDataService.setVinNumber(this.veh.VIN);
            this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', this.vehicleid, 'vehicle', 'vehicleVINDecode'], { fragment: inComingObject });
        }
    }

    enableAndCleardVinDecodedFields() {
        this.arrVpicElementsFields.forEach(strFieldName => {
            let uiControl = this._autoFillService.arrControls.find(i => i.strFieldName == strFieldName);
            if (uiControl) {
                uiControl.clearComponent();
                uiControl.disableOrEnableComponent(false);
            }
        });
    }

    //openVinRequest() {
    //    if (this.objForm.dirty) {
    //        this._vehicleService.SaveVeh(this.veh)
    //            .then((result => {
    //                this.objForm.form.markAsPristine();
    //                this._sharedDataService.subReEvalCaseActionButtons.next({ objComponent: null, objCase: this.acc });
    //                this._modalService_CannedPopups.vinRequest(this.acc.AccID, this.veh.VNumber, 0, this.acc.Casenum, false, this.veh.VIN).toPromise();
    //            }).bind(this));
    //    } else {
    //        this._modalService_CannedPopups.vinRequest(this.acc.AccID, this.veh.VNumber, 0, this.acc.Casenum, false, this.veh.VIN).toPromise();
    //    }
    //}

    private SkipPopUpMessage(): boolean {

        //if ((this.veh.VIN == "") && (this.objVehClone.VIN == "" || this.objVehClone.VIN == null))
        //    return true;

        let arrVpicControls = this._autoFillService.arrControls.filter(control => !this.arrNonVpicElementsFields.includes(control.strFieldName));

        if (arrVpicControls)
            if (arrVpicControls.some(i => i.objModel[i.strFieldName] != RBISDataValue.Blank))
                return false;

        return true;
    }

    onBeforeModelCange(objModel: Veh) {
        this.strVinBeforeChange = objModel.VIN;
        this.intVpicMakeBeforeChange = objModel.vPICMake;
        this.intVpicBodyBeforeChange = objModel.vPICModel;
    }

    OnPasteVIN($event: ClipboardEvent) {
        this._modalService.resetMessage();
        let strVIN: string = $event.clipboardData.getData('Text').trim();

        //let newValue = strVIN.replace(/\s/g, '*');
        let newValue = strVIN.replace(/\s/g, '');
        this.veh.VIN = newValue;

        setTimeout((() => {
            let uiControl: TypeaheadComponent = this._autoFillService.arrControls.find(x => x.strFieldName == "VIN") as TypeaheadComponent;
            uiControl.setCurrentValue(newValue);
            uiControl.RerenderModelValue();
            if (this.veh.VIN.length > 17) {
                this._modalService.setMessage("Pasted Trailer VIN number has more then 17 charaters, please correct it and try again", "warning");
                return;
            }

            let objOption: DrpDownOptions = {} as DrpDownOptions;
            this.onVINModelChange(objOption);
        }).bind(this), 1);

    }

    async onVINModelChange(objOption: DrpDownOptions) {
        this._modalService.resetMessage();
        this.blnDisableDecodeVIN = VehicleService.arrNotDecodedVin.includes(this.veh.VIN);
        this.disabledVinControl = false;

        if (this.strVinBeforeChange != "" && this.veh.VIN == "") {

            if (this.SkipPopUpMessage())
                return;

            this._modalService.dialog("Clearing the VIN will clear all the decoded values. Are you sure you want to clear the VIN?", "Are you sure?")
                .subscribe(async confirm => {
                    if (confirm) {
                        this.veh.vPICBodyClass = RBISDataValue.Blank;
                        this.veh.FinalStageBodyClass = RBISDataValue.Blank;
                        let blnRolledBackChange: boolean = await this._autoFillService.OnCreatedRecordPrerequisitesChange(this.veh, 'vPICBodyClass', { intValue: this.veh.vPICBodyClass } as DrpDownOptions, this.objVehClone);

                        if (!blnRolledBackChange) {
                            this.veh.ModelYr = RBISDataValue.Blank;
                            this.veh.VIN = null;
                            this.veh.ErrorCode = null;
                            this.veh.VPICDECODE = null;
                            this.enableAndCleardVinDecodedFields();
                            this.objForm.form.markAsDirty();
                            this.objVehClone = ObjectUtil.CloneModelObject(this.veh);
                        }
                        else
                            confirm = false;

                        this.FilterNcsaBodyType(null);
                    }

                    if (!confirm) { //This is not a simple "else": the preceding "if" may reset the condition
                        this.veh.VIN = "";
                        this.veh.VIN = this.strVinBeforeChange;
                        let uiControl: TypeaheadComponent = this._autoFillService.arrControls.find(x => x.strFieldName == "VIN") as TypeaheadComponent;
                        uiControl.RerenderModelValue();
                        uiControl.instance.dismissPopup();
                    }
                },
                    cancel => {
                        this.veh.VIN = "";
                        this.veh.VIN = this.strVinBeforeChange;
                        let uiControl: TypeaheadComponent = this._autoFillService.arrControls.find(x => x.strFieldName == "VIN") as TypeaheadComponent;
                        uiControl.RerenderModelValue();
                        uiControl.instance.dismissPopup();
                    });
        }
        let regexExp: string = this.vinMetaData.FieldRegexPattern;
        let isValidVIN: boolean = new RegExp(regexExp).test(this.veh.VIN) && !VehicleService.arrNotDecodedVin.includes(this.veh.VIN);

        if (this.blnDisableDecodeVIN) {
            this.disabledvPicGVWR = false;
            this._modalService.setMessage("No VIN Checking is performed if VIN is blank, all 9s or 0s or 8s.", "info");
            return;
        }

        if (isValidVIN) {
            if (this.objVIN.blnInitialized) {
                this.objForm.form.markAsPristine();
                this._sharedDataService.setVinNumber(this.veh.VIN);
                this._router.navigate([this.stateNum, 'case', this.accid, 'vehicle', this.vehicleid, 'vehicle', 'vehicleVINDecode'], { fragment: 'vehicle' });
            }
        }
    }


    public FilterNcsaBodyType(objNewModelSelection: DrpDownOptions): void {
        if (objNewModelSelection && this.objTypeaheadBody && this.veh.Make && this.veh.Model && this.veh.Make != RBISDataValue.Blank && this.veh.Model != RBISDataValue.Blank) {
            this.objTypeaheadBody.blnEvaluateConditionValue = false;
            this.objTypeaheadBody.strDependentFieldName = 'Model';
            this.objTypeaheadBody.strFilterCondition = ' AND Model_bd.MAKE_ID = ' + this.veh.Make.toString() + ' AND Model_bd.MODEL_ID = ' + this.veh.Model.toString();
        }
        else {
            //Reset NCSA Body Type to unfiltered state
                this.objTypeaheadBody.blnEvaluateConditionValue = true;
            this.objTypeaheadBody.strDependentFieldName = '';
                this.objTypeaheadBody.strFilterCondition = '';
            this.objTypeaheadBody.setCurrentValue('-1');
        }
        this.objTypeaheadBody.FilterFieldOptions();
    }

    onADSPresentChange($event: DrpDownOptions) {
        setTimeout(() => {
            let UIControl = this._autoFillService.arrControls.find(i => i.strFieldName == "ADSPresent") as TypeaheadComponent;
            if ($event.intValue === -1) {
                UIControl.objTextbox.nativeElement.click();
                UIControl.objTextbox.nativeElement.focus();
            }
        }, 1);
    }

    public onADSLevelChange($event) {
        setTimeout(() => {
            let UIControl = this._autoFillService.arrControls.find(i => i.strFieldName == "ADSLevel") as TypeaheadComponent;
            UIControl.setIsInvalidClass(false);
            if (this.veh.ADSPresent === -1 || this.veh.ADSPresent === 1) {
                if ($event.intValue === 0 || $event.intValue === 98 || $event.intValue === 99) {
                    this._modalService.setMessage("Values 00, 98, 99 cannot be entered when ADS Present is 01 or Blank", "warning");
                    UIControl.clearComponent();
                    UIControl.setIsInvalidClass(true);
                    UIControl.objTextbox.nativeElement.click();
                    UIControl.objTextbox.nativeElement.focus();
                }
            }
        }, 5);
    }

    public onADSEngagedChange($event) {
        setTimeout(() => {
            let UIControl = this._autoFillService.arrControls.find(i => i.strFieldName == "ADSEngaged") as TypeaheadComponent;
            UIControl.setIsInvalidClass(false);
            if (this.veh.ADSPresent === -1 || this.veh.ADSPresent === 1) {
                if ($event.intValue === 0 || $event.intValue === 98 || $event.intValue === 99) {
                    this._modalService.setMessage("Values 00, 98, 99 cannot be entered when ADS Present is 01 or Blank", "warning");
                    UIControl.clearComponent();
                    UIControl.setIsInvalidClass(true);
                    UIControl.objTextbox.nativeElement.click();
                    UIControl.objTextbox.nativeElement.focus();
                }
            }
        }, 5);
    }

    ClearVpicMakeRelatedControl() {
        this.arrVpicMakeDependentFields.forEach(strFieldName => {
            let uiControl = this._autoFillService.arrControls.find(i => i.strFieldName == strFieldName);
            if (uiControl) {
                uiControl.clearComponent();
            }
        });
    }

    async handleVpicMakeChange($event: DrpDownOptions) {
        if ($event.intValue == RBISDataValue.Blank
            && this.intVpicMakeBeforeChange != RBISDataValue.Blank
            && this.arrVpicMakeDependentFields.some(i => this.veh[i] != RBISDataValue.Blank)) {
            this._modalService.dialog('Clearing the "vPIC Make" will clear values "vPIC Model", "NCSA Make", "NCSA Model" and "NCSA Body Type". Are you sure you want to clear the "vPIC Make"?', "Are you sure?")
                .subscribe(async confirm => {
                    if (confirm) {
                        this.ClearVpicMakeRelatedControl();
                        this.objForm.form.markAsDirty();
                    }
                    else {
                        this.veh.vPICMake = this.intVpicMakeBeforeChange;
                        let uiControl: TypeaheadComponent = this._autoFillService.arrControls.find(x => x.strFieldName == "vPICMake") as TypeaheadComponent;
                        uiControl.setCurrentValue(this.veh.vPICMake.toString());
                        uiControl.instance.dismissPopup();

                        this.veh.vPICModel = this.intVpicBodyBeforeChange;
                        let uiBodyControl: TypeaheadComponent = this._autoFillService.arrControls.find(x => x.strFieldName == "vPICModel") as TypeaheadComponent;
                        await uiBodyControl.FilterFieldOptions();
                        uiBodyControl.setCurrentValue(this.veh.vPICModel.toString());
                        uiBodyControl.instance.dismissPopup();
                    }
                }
                    ,
                    cancel => {
                        this.veh.VIN = "";
                        this.veh.VIN = this.strVinBeforeChange;
                        let uiControl: TypeaheadComponent = this._autoFillService.arrControls.find(x => x.strFieldName == "VIN") as TypeaheadComponent;
                        uiControl.RerenderModelValue();
                        uiControl.instance.dismissPopup();
                    });
        }
    }

    public setObjectValue(objNewSelection: DrpDownOptions, strField: string): void {
        if (strField === "vPICBodyClass" || strField === "FinalStageBodyClass") {
            if (strField === "vPICBodyClass") {
                if (objNewSelection.intValue !== -1) {
                    this.isFinalStageBodyClassVisiable = objNewSelection.strText.toLowerCase().includes('incomplete') ? true : false;
                }
            }

            if (!this.disabledVinControl)
                this._autoFillService.OnCreatedRecordPrerequisitesChange(this.veh, strField, objNewSelection, this.objVehClone);
        }
    }


    setWeightValue(event) {
        console.log(event);
        this.veh.Veh_MTSS.VehicleEmptyWeight = event;
        let result = event;
        result = String(result);
        // this.focuseOut = true;
        let component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.VehicleEmptyWeight);

        if (component) {
            console.log(event);
            component.setCurrentValue(result);
            component.onBlur(result);
            this.MarkFormAsDirty();
        }
    }
}
