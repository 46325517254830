<form novalidate #fact="ngForm">
    <div id="crash-trafficway" class="pt-2" *ngIf="acc && accTrafID1 && accTrafID2 && intMode">
        <div [ngClass]="{'row' : printOnly === true}">

            <div *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <!-- We want UI fields to be added to HTML DOM as one unit to preserve order of appearance in registration order.
                    So, do not put ngIf with different conditions on individual fields.-->
                    <div class="row">
                        <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                            <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                <app-label inputName="TrafID1" [printOnly]="printOnly"></app-label>
                            </div>
                        </div>
                        <div #typAhdAccTrafID1 [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 my-auto' : printOnly !== true }" class="">
                            <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                <app-typeahead #txtAccTrafID1 id="AccTrafID1" name="AccTrafID1" typeAheadClass="form-control w375"
                                               [objModel]="accTrafID1"
                                               [(objCurrentValue)]="accTrafID1.TrafID"
                                               [disableLabel]="true"
                                               strTableName="AccTrafID"
                                               strFieldName="TrafID"
                                               intSeqNum="1"
                                               [blnHideSelectedOptionText]="true"
                                               [blnAllowFreeText]="true"
                                               [printOnly]="printOnly"
                                               [maxlength]="30"
                                               [clearFieldData]="clearFieldData"
                                               [blnFormatUpperCase]="true"
                                               [showAllDataListForPrintOnly]="false"
                                               [blnAllowFocusNext]="true"
                                               [blnIncludeBlank]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                            <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                <app-label inputName="TrafID2" [printOnly]="printOnly"></app-label>
                            </div>
                        </div>
                        <div #typAhdAccTrafID2 [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 my-auto' : printOnly !== true }" class="">
                            <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                <app-typeahead #txtAccTrafID2 id="AccTrafID2" name="AccTrafID2" typeAheadClass="form-control w375"
                                               [objModel]="accTrafID2"
                                               [(objCurrentValue)]="accTrafID2.TrafID"
                                               [disableLabel]="true"
                                               strTableName="AccTrafID"
                                               strFieldName="TrafID"
                                               intSeqNum="2"
                                               [blnHideSelectedOptionText]="true"
                                               [blnAllowFreeText]="true"
                                               [printOnly]="printOnly"
                                               [maxlength]="30"
                                               [clearFieldData]="clearFieldData"
                                               [blnFormatUpperCase]="true"
                                               [showAllDataListForPrintOnly]="false"
                                               [blnAllowFocusNext]="true"
                                               [blnIncludeBlank]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div #typAhdRTESign *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="rteSign" typeAheadPosition="right-top"
                                   [objModel]="acc" strTableName="Acc" strFieldName="RTESign" [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdLandUse *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="landUse" typeAheadPosition="right-top"
                                   [objModel]="acc" strTableName="Acc" strFieldName="LandUse" [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdFuncSystem *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="funcSystem" typeAheadPosition="right-top"
                                   [objModel]="acc" strTableName="Acc" strFieldName="FuncSystem" [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdOwnership *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="ownership" typeAheadPosition="right-top"
                                   [objModel]="acc" strTableName="Acc" strFieldName="Ownership" [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>
            <!--<div *ngIf="printOnly" class="col-md-12 " style="margin-top:224px!important"></div>-->
            <div #typAhdNHS *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="nhs"
                                   [objModel]="acc" strTableName="Acc" strFieldName="NHS" [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdSpeJur *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group" [ngClass]="{'pl-2' : printOnly !== true}">
                    <app-typeahead id="spejur"
                                   [objModel]="acc" strTableName="Acc" strFieldName="SpeJur" [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>

            <div *ngIf="intMode == 64 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div class="">
                        <div class="row">
                            <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }" class="">
                                <div [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                    <app-label strTableName="Acc" strFieldName="MilePnt" inputName="MilePnt" [printOnly]="printOnly"></app-label>
                                </div>
                            </div>
                            <div [ngClass]="{'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 my-auto' : printOnly !== true }" class="">
                                <div class="input-group">
                                    <div class="input-group" [ngClass]="{'px-2' : printOnly !== true, 'px-3' : printOnly === true }">
                                        <div #typAhdMilePointWhole class="input-group-append">
                                            <app-typeahead id="AccMilePointWhole" #AccMilePointWhole
                                                           [disableLabel]="true" typeAheadPosition="right-bottom" [maxlength]="4"
                                                           [blnAllowFreeText]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [blnShowSpecialValuesOnly]="true" [blnMatchDropDownOptionAsText]="true"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="MilePointWhole" strComputedFieldName="MilePnt"
                                                           [(objCurrentValue)]="acc.MilePointWhole" (bindModel)="OnChange_MilePointWhole($event)"
                                                           [printOnly]="printOnly" [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           [showDescriptionForPrintOnly]="false"
                                                           strRightArrowFocusFieldName="MilePointDecimal" [intDisabledMode]="4" [blnFocusOnNextEmptyControl]="false"
                                                           [blnCheckStrengthCode]="false"
                                                           [blnRemoveDecimalFraction]="true">
                                            </app-typeahead>
                                        </div>
                                        <p class="px-2" [ngClass]="{'mb-0' : printOnly === true }">.</p>
                                        <div #typAhdMilePointDecimal class="input-group-append px-1">
                                            <app-typeahead id="AccMilePointDecimal" #AccMilePointDecimal
                                                           [disableLabel]="true" typeAheadPosition="right-bottom" [blnHideSelectedOptionText]="true" [maxlength]="1"
                                                           [objModel]="acc" strTableName="Acc" strFieldName="MilePointDecimal"
                                                           [(objCurrentValue)]="acc.MilePointDecimal" strComputedFieldName="MilePnt"
                                                           (bindModel)="OnChange_MilePointDecimal($event)"
                                                           [showTextboxInSamePositionForPrintOnly]="true"
                                                           [printOnly]="printOnly"
                                                           [showDescriptionForPrintOnly]="false"
                                                           [showAllDataListForPrintOnly]="false"
                                                           [clearFieldData]="clearFieldData"
                                                           strLeftArrowFocusFieldName="MilePointWhole" [intDisabledMode]="4"
                                                           strComplexFieldName="MilePnt">
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-8 border border-dark' : printOnly }">
                <div class="form-group mb-0">
                    <div class="row">
                        <div class="" [ngClass]="{'col-3 ml-2' : printOnly !== true, 'col-12' : printOnly === true }">
                            <span class="pl-2">
                                <app-label inputName="GlobalPostion" [printOnly]="printOnly">Global Position</app-label>
                            </span>
                        </div>
                        <span style="visibility: hidden">-</span>
                        <!--TO DO Create Meta data for Global Position-->
                        <div class="col-2" *ngIf="!printOnly">
                            <input type="radio" id="typedd" #typedd value="dd" [checked]="!DMSFormatPrefered" class="form-check-input" name="viewType" (change)="onClickDd('dd', $event)" />
                            <label class="px-1" for="typedd">Decimal Degrees</label>
                        </div>
                        <div class="col-3" *ngIf="!printOnly">
                            <input type="radio" id="typedms" #typedms value="dms" [checked]="DMSFormatPrefered" class="form-check-input" name="viewType" (change)="onDmsClick('dms',$event)" />
                            <label class="px-1" for="typedms">Degrees Minutes Seconds </label>
                        </div>
                        <div class="col-3" *ngIf="!printOnly">
                            <input type="radio" id="typeddm" #typeddm value="ddm" class="form-check-input" name="viewType" (change)="onDdmClick('ddm',$event)" />
                            <label class="px-1" for="typeddm">Degrees Decimal Minutes</label>
                        </div>
                    </div>

                    <div [ngClass]="{'row': !printOnly}">
                        <div [ngClass]="{'col-xl-10' : printOnly !== true, 'col-12' : printOnly === true }" class="">
                            <!-- DECIMAL DEGREES (DD) -->
                            <ng-container *ngIf="viewType=='dd' && !printOnly">
                                <div class="col-12 p-2">
                                    <div class="form-group row mb-sm-2 text-center">
                                        <div class="col-sm-2">
                                        </div>
                                        <div class="col-3">
                                            <label for="latitude" class="col-form-label ml-4">Latitude:</label>
                                        </div>

                                        <div [ngClass]="{'col-sm-4' : printOnly !== true, 'col-sm-3' : printOnly === true }" class="col-sm-4" *ngIf="intMode != 256">
                                            <!-- For TypeaheadComponent with options, maxlength is determined at runtime from options retrieved from DEFS database -->
                                            <app-typeahead id="Acclatitude" name="Acclatitude" typeAheadClass="form-control w300"
                                                           [blnAllowFreeText]="true"
                                                           [disableLabel]="true" [blnHideSelectedOptionText]="true"
                                                           [blnFreeTextWithoutOption]="true"
                                                           [blnMatchDropDownOptionAsText]="true"
                                                           [printOnly]="printOnly"
                                                           [clearFieldData]="clearFieldData"
                                                           [maxlength]="11" [intMaxDecimal]="8"
                                                           [blnBindDecimalAttribute]="true"
                                                           [blnFormatWithLeading]="false"
                                                           [objModel]="acc"
                                                           strComputedFieldName="LatDeg"
                                                           [disabledLeftAndRightArow]="true"
                                                           strTableName="Acc" strFieldName="ddLatitude"
                                                           (keydown.Tab)="onMyBlur('ddLatitude', $event)"
                                                           (bindModel)="specialValue('dd', $event, acc, 'ddLatitude')"
                                                           (paste)="pasteData($event, 'ddLatitude')"
                                                           (keyup)="resetValidateFlagOnKeyUp($event,'ddLatitude')"
                                                           (keydown.Enter)="onEnter('ddLatitude',$event)"
                                                           (keydown.PageUp)="onEnter('ddLatitude',$event)"
                                                           (keydown.PageDown)="onEnter('ddLatitude',$event)"
                                                           #txtAcclatitude>
                                            </app-typeahead>
                                        </div>
                                        <div [ngClass]="{'col-sm-4' : printOnly !== true, 'col-sm-3' : printOnly === true }" class="col-sm-4" *ngIf="intMode == 256">
                                            <!-- For TypeaheadComponent with options, maxlength is determined at runtime from options retrieved from DEFS database -->
                                            <app-typeahead id="Acclatitude" name="Acclatitude" typeAheadClass="form-control w300"
                                                           [blnAllowFreeText]="true"
                                                           [disableLabel]="true" [blnHideSelectedOptionText]="true"
                                                           [blnMatchDropDownOptionAsText]="true"
                                                           [printOnly]="printOnly"
                                                           [clearFieldData]="clearFieldData"
                                                           [maxlength]="11" [intMaxDecimal]="8"
                                                           [blnBindDecimalAttribute]="true"
                                                           [blnFormatWithLeading]="false"
                                                           [objModel]="acc"
                                                           strComputedFieldName="LatDeg"
                                                           [disabledLeftAndRightArow]="true"
                                                           strTableName="Acc" strFieldName="ddLatitude"
                                                           (keydown.Tab)="onMyBlur('ddLatitude', $event)"
                                                           (bindModel)="specialValue('dd', $event, acc, 'ddLatitude')"
                                                           (paste)="pasteData($event, 'ddLatitude')"
                                                           (keyup)="resetValidateFlagOnKeyUp($event,'ddLatitude')"
                                                           (keydown.Enter)="onEnter('ddLatitude',$event)"
                                                           (keydown.PageUp)="onEnter('ddLatitude',$event)"
                                                           (keydown.PageDown)="onEnter('ddLatitude',$event)"
                                                           #txtAcclatitude>
                                            </app-typeahead>
                                        </div>
                                    </div>
                                    <br />

                                    <div class="form-group row mb-sm-2 text-center">
                                        <div class="col-sm-2">
                                        </div>
                                        <div class="col-3">
                                            <label for="longitude" class="col-form-label ml-4">Longitude:</label>
                                        </div>
                                        <div [ngClass]="{'col-sm-4' : printOnly !== true, 'col-sm-3' : printOnly === true }" class="col-sm-4">
                                            <!-- For TypeaheadComponent with options, maxlength is determined at runtime from options retrieved from DEFS database -->
                                            <app-typeahead id="AccLongitude" name="AccLongitude" typeAheadClass="form-control w300"
                                                           [blnAllowFreeText]="true"
                                                           [disableLabel]="true" [blnHideSelectedOptionText]="true"
                                                           [blnFreeTextWithoutOption]="true"
                                                           [disabledLeftAndRightArow]="true"
                                                           [maxlength]="12" [intMaxDecimal]="8"
                                                           [blnFormatWithLeading]="false"
                                                           [objModel]="acc"
                                                           strComputedFieldName="LonDeg"
                                                           strTableName="Acc" strFieldName="ddLongitude"
                                                           (blur)="onMyBlur('ddLongitude', $event)"
                                                           (keydown.Tab)="onMyBlur('ddLongitude',$event)"
                                                           (bindModel)="changeValue('ddLongitude', acc.ddLongitude, $event, acc)"
                                                           (paste)="pasteData($event, 'ddLongitude')"
                                                           (keyup)="resetValidateFlagOnKeyUp($event,'ddLongitude')"
                                                           (keydown.Enter)="onEnter('ddLongitude',$event)"
                                                           (keydown.PageUp)="onEnter('ddLongitude',$event)"
                                                           (keydown.PageDown)="onEnter('ddLongitude',$event)"
                                                           #txtAccLongitude>
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- [WHOLE] DEGREES MINUTES [DECIMAL] SECONDS (DMS) -->
                            <ng-container *ngIf="viewType=='dms' || printOnly">
                                <div class="col-xl-9">
                                    <div class="form-group">
                                        <div class="row">
                                            <div [ngClass]="{'col-sm-4' : printOnly !== true, 'col-sm-3' : printOnly === true }">
                                                &nbsp;
                                            </div>

                                            <div [ngClass]="{'print-font-size' : printOnly === true }" class="col-3">
                                                Deg
                                            </div>
                                            <div [ngClass]="{'col-sm-2' : printOnly !== true, 'col-sm-3 print-font-size' : printOnly === true }">
                                                Min
                                            </div>
                                            <div [ngClass]="{'col-sm-2' : printOnly !== true, 'col-sm-3 print-font-size' : printOnly === true }">
                                                Sec
                                            </div>
                                        </div>
                                        <div class="row mb-sm-1">
                                            <div [ngClass]="{'col-sm-4' : printOnly !== true, 'col-sm-3' : printOnly === true }">
                                                <label class="col-form-label pl-1 float-right {{printOnly ? 'print-font-size' : ''}}">Latitude:</label>
                                            </div>
                                            <div class="col-3">
                                                <app-typeahead id="AccLatDeg" name="AccLatDeg" typeAheadClass="form-control w74"
                                                               [blnAllowFreeText]="true"
                                                               [disableLabel]="true" [blnHideSelectedOptionText]="true"
                                                               [blnShowSpecialValuesOnly]="true"
                                                               [printOnly]="printOnly"
                                                               [clearFieldData]="clearFieldData"
                                                               [showDescriptionForPrintOnly]="false"
                                                               [showAllDataListForPrintOnly]="false"
                                                               [maxlength]="2" [intMaxDecimal]="0"
                                                               [objModel]="acc"
                                                               strTableName="Acc" strFieldName="LatDeg"
                                                               [blnFormatWithLeading]="false"
                                                               (keyup)="resetValidateFlagOnKeyUp($event,'LatDeg')"
                                                               #txtAccLatDeg (bindModel)="changeValue('LatDeg', acc.LatDeg, $event, acc)"
                                                               (keydown.Enter)="onEnter('LatDeg',$event)"
                                                               (paste)="pasteData($event, 'LatDeg')"
                                                               (keydown.PageUp)="onEnter('LatDeg',$event)"
                                                               (keydown.PageDown)="onEnter('LatDeg',$event)">
                                                </app-typeahead>
                                            </div>
                                            <div [ngClass]="{'col-sm-2' : printOnly !== true, 'col-sm-3' : printOnly === true }">
                                                <app-typeahead id="AccLatMin" name="AccLatMin"
                                                               [blnAllowFreeText]="true"
                                                               [blnHideSelectedOptionText]="true"
                                                               [disableLabel]="true"
                                                               [printOnly]="printOnly"
                                                               [clearFieldData]="clearFieldData"
                                                               typeAheadPosition="right-top"
                                                               [showDescriptionForPrintOnly]="false"
                                                               [showAllDataListForPrintOnly]="false"
                                                               [maxlength]="2" [intMaxDecimal]="0"
                                                               [blnFormatWithLeading]="true"
                                                               [objModel]="acc"
                                                               [blnFreeTextWithoutOption]="true"
                                                               strFilterCondition=" AND ID < 60"
                                                               strTableName="Acc" strFieldName="LatMin"
                                                               (bindModel)="changeValue('LatMin', acc.LatMin, $event, acc)"
                                                               (keyup)="resetValidateFlagOnKeyUp($event,'LatMin')"
                                                               (keydown.Enter)="onEnter('LatMin',$event)"
                                                               (keydown.PageUp)="onEnter('LatMin',$event)"
                                                               (keydown.PageDown)="onEnter('LatMin',$event)"
                                                               (paste)="pasteData($event, 'LatMin')"
                                                               #txtAccLatMin>
                                                </app-typeahead>
                                            </div>
                                            <div [ngClass]="{'col-sm-2' : printOnly !== true, 'col-sm-3' : printOnly === true }" *ngIf="intMode != 256">
                                                <app-typeahead id="AccLatSec" name="AccLatSec" typeAheadClass="form-control w74"
                                                               [blnAllowFreeText]="true"
                                                               [disableLabel]="true" [blnHideSelectedOptionText]="true" [blnShowSpecialValuesOnly]="true"
                                                               [printOnly]="printOnly"
                                                               [clearFieldData]="clearFieldData"
                                                               [showDescriptionForPrintOnly]="false"
                                                               [showAllDataListForPrintOnly]="false"
                                                               [maxlength]="4" [intMaxDecimal]="2"
                                                               (keyup)="resetValidateFlagOnKeyUp($event,'LatSec')"
                                                               [objModel]="acc" [(objCurrentValue)]="acc.LatSec"
                                                               strTableName="Acc" strFieldName="LatSec"
                                                               [blnFormatWithLeading]="false"
                                                               #txtAccLatSec (bindModel)="changeValue('LatSec', acc.LatSec, $event, acc)"
                                                               strRightArrowFocusFieldName="LatMin"
                                                               (keydown.Enter)="onEnter('LatSec',$event)"
                                                               (keydown.PageUp)="onEnter('LatSec',$event)"
                                                               (keydown.PageDown)="onEnter('LatSec',$event)"
                                                               (paste)="pasteData($event, 'LatSec')">
                                                </app-typeahead>

                                            </div>
                                            <div [ngClass]="{'col-sm-2' : printOnly !== true, 'col-sm-3' : printOnly === true }" *ngIf="intMode == 256">
                                                <app-typeahead id="AccLatSec" name="AccLatSec" typeAheadClass="form-control w74"
                                                               [blnAllowFreeText]="true"
                                                               [disableLabel]="true" [blnHideSelectedOptionText]="true"
                                                               [blnShowSpecialValuesOnly]="true"
                                                               [blnFreeTextWithoutOption]="true"
                                                               [printOnly]="printOnly"
                                                               [clearFieldData]="clearFieldData"
                                                               [showDescriptionForPrintOnly]="false"
                                                               [showAllDataListForPrintOnly]="false"
                                                               [maxlength]="4" [intMaxDecimal]="2"
                                                               (keyup)="resetValidateFlagOnKeyUp($event,'LatSec')"
                                                               [objModel]="acc" [(objCurrentValue)]="acc.LatSec"
                                                               strTableName="Acc" strFieldName="LatSec"
                                                               [blnFormatWithLeading]="false"
                                                               #txtAccLatSec (bindModel)="changeValue('LatSec', acc.LatSec, $event, acc)"
                                                               strRightArrowFocusFieldName="LatMin"
                                                               (keydown.Enter)="onEnter('LatSec',$event)"
                                                               (keydown.PageUp)="onEnter('LatSec',$event)"
                                                               (keydown.PageDown)="onEnter('LatSec',$event)"
                                                               (paste)="pasteData($event, 'LatSec')">
                                                </app-typeahead>

                                            </div>

                                        </div>
                                        <div class="row mb-sm-1">
                                            <div [ngClass]="{'col-sm-4' : printOnly !== true, 'col-sm-3 ' : printOnly === true }">
                                                <label class="col-form-label pl-1 float-right {{printOnly ? 'print-font-size' : ''}}">Longitude:</label>
                                            </div>
                                            <div class="col-3">
                                                <app-typeahead id="AccLonDeg" name="AccLonDeg"
                                                               [blnAllowFreeText]="true"
                                                               [disableLabel]="true" [blnHideSelectedOptionText]="true"
                                                               [blnShowSpecialValuesOnly]="true"
                                                               [printOnly]="printOnly"
                                                               [clearFieldData]="clearFieldData"
                                                               [showDescriptionForPrintOnly]="false"
                                                               [showAllDataListForPrintOnly]="false"
                                                               [maxlength]="3" [intMaxDecimal]="0"
                                                               [objModel]="acc"
                                                               strTableName="Acc" strFieldName="LonDeg"
                                                               [blnFormatWithLeading]="true"
                                                               (keyup)="resetValidateFlagOnKeyUp($event, 'LonDeg')"
                                                               #txtAccLonDeg (bindModel)="changeValue('LonDeg', acc.LonDeg, $event, acc)"
                                                               (keydown.Enter)="onEnter('LonDeg',$event)"
                                                               (paste)="pasteData($event, 'LonDeg')"
                                                               (keydown.PageUp)="onEnter('LonDeg',$event)"
                                                               (keydown.PageDown)="onEnter('LonDeg',$event)"
                                                               [blnFreeTextWithoutOption]="true">
                                                </app-typeahead>
                                            </div>
                                            <div [ngClass]="{'col-sm-2' : printOnly !== true, 'col-sm-3' : printOnly === true }">
                                                <app-typeahead id="AccLonMin" name="AccLonMin"
                                                               [blnAllowFreeText]="true"
                                                               [blnHideSelectedOptionText]="true"
                                                               [disableLabel]="true"
                                                               [printOnly]="printOnly"
                                                               [clearFieldData]="clearFieldData"
                                                               typeAheadPosition="right-top"
                                                               [showDescriptionForPrintOnly]="false"
                                                               [showAllDataListForPrintOnly]="false"
                                                               [maxlength]="2" [intMaxDecimal]="0"
                                                               [blnFreeTextWithoutOption]="true"
                                                               [objModel]="acc"
                                                               strTableName="Acc" strFieldName="LonMin"
                                                               strFilterCondition=" AND ID < 60"
                                                               (bindModel)="changeValue('LonMin', acc.LonMin, $event, acc)"
                                                               (keyup)="resetValidateFlagOnKeyUp($event, 'LonMin')"
                                                               [blnIncludeBlank]="false"
                                                               (keydown.Enter)="onEnter('LonMin',$event)"
                                                               (keydown.PageUp)="onEnter('LonMin',$event)"
                                                               (keydown.PageDown)="onEnter('LonMin',$event)"
                                                               (paste)="pasteData($event, 'LonMin')"
                                                               #txtAccLonMin>
                                                </app-typeahead>
                                            </div>
                                            <div [ngClass]="{'col-sm-2' : printOnly !== true, 'col-sm-3' : printOnly === true }" *ngIf="intMode != 256">
                                                <app-typeahead id="AccLonSec" name="AccLonSec" typeAheadClass="form-control w74"
                                                               [blnAllowFreeText]="true"
                                                               [disableLabel]="true" [blnHideSelectedOptionText]="true" [blnShowSpecialValuesOnly]="true"
                                                               [printOnly]="printOnly"
                                                               [clearFieldData]="clearFieldData"
                                                               [showDescriptionForPrintOnly]="false"
                                                               [showAllDataListForPrintOnly]="false"
                                                               [maxlength]="4" [intMaxDecimal]="2"
                                                               [objModel]="acc" [(objCurrentValue)]="acc.LonSec"
                                                               strTableName="Acc" strFieldName="LonSec"
                                                               [blnFormatWithLeading]="false"
                                                               (keyup)="resetValidateFlagOnKeyUp($event, 'LonSec')"
                                                               #txtAccLonSec (bindModel)="changeValue('LonSec', acc.LonSec, $event, acc)"
                                                               strRightArrowFocusFieldName="LonMin"
                                                               (keydown.Enter)="onEnter('LonSec',$event)"
                                                               (keydown.PageUp)="onEnter('LonSec',$event)"
                                                               (keydown.PageDown)="onEnter('LonSec',$event)"
                                                               (paste)="pasteData($event, 'LonSec')">
                                                </app-typeahead>
                                            </div>
                                            <div [ngClass]="{'col-sm-2' : printOnly !== true, 'col-sm-3' : printOnly === true }" *ngIf="intMode == 256">
                                                <app-typeahead id="AccLonSec" name="AccLonSec" typeAheadClass="form-control w74"
                                                               [blnAllowFreeText]="true"
                                                               [disableLabel]="true" [blnHideSelectedOptionText]="true"
                                                               [blnShowSpecialValuesOnly]="true"
                                                               [blnFreeTextWithoutOption]="true"
                                                               [printOnly]="printOnly"
                                                               [clearFieldData]="clearFieldData"
                                                               [showDescriptionForPrintOnly]="false"
                                                               [showAllDataListForPrintOnly]="false"
                                                               [maxlength]="4" [intMaxDecimal]="2"
                                                               [objModel]="acc" [(objCurrentValue)]="acc.LonSec"
                                                               strTableName="Acc" strFieldName="LonSec"
                                                               [blnFormatWithLeading]="false"
                                                               (keyup)="resetValidateFlagOnKeyUp($event, 'LonSec')"
                                                               #txtAccLonSec (bindModel)="changeValue('LonSec', acc.LonSec, $event, acc)"
                                                               strRightArrowFocusFieldName="LonMin"
                                                               (keydown.Enter)="onEnter('LonSec',$event)"
                                                               (keydown.PageUp)="onEnter('LonSec',$event)"
                                                               (keydown.PageDown)="onEnter('LonSec',$event)"
                                                               (paste)="pasteData($event, 'LonSec')">
                                                </app-typeahead>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- [WHOLE] DEGREES DECIMAL MINUTES (DDM) -->
                            <ng-container *ngIf="viewType=='ddm' && !printOnly">
                                <div class="col-xl-8 p-2">
                                    <div class="row mb-sm-2">
                                        <div class="col-sm-1">
                                            &nbsp;
                                        </div>
                                        <div class="col-sm-3">
                                        </div>
                                        <div class="col-sm-2 ml-4">
                                            Deg
                                        </div>
                                        <div class="col-sm-2">
                                            Min
                                        </div>
                                    </div>
                                    <div class="form-group row mb-sm-2">
                                        <div class="col-sm-1">
                                            &nbsp;
                                        </div>
                                        <div class="col-sm-3">
                                            <label for="latDegree" class="col-form-label ml-4 float-right">Latitude:</label>
                                        </div>

                                        <div class="col-sm-2" *ngIf="intMode != 256">
                                            <app-typeahead id="dcmAccLonDeg" name="dcmAccLonDeg" typeAheadClass="form-control w74"
                                                           [blnAllowFreeText]="true"
                                                           [blnShowSpecialValuesOnly]="true"
                                                           [disableLabel]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [blnFreeTextWithoutOption]="true"
                                                           [printOnly]="printOnly"
                                                           [clearFieldData]="clearFieldData"
                                                           [maxlength]="2" [intMaxDecimal]="0"
                                                           [objModel]="acc"
                                                           [blnFormatWithLeading]="false"
                                                           (bindModel)="changeValue('ddmLatDeg', acc.LatDeg, $event, acc)"
                                                           strTableName="Acc" strFieldName="LatDeg"
                                                           (keyup)="resetValidateFlagOnKeyUp($event, 'ddmLatDeg')"
                                                           (keydown.Enter)="onEnter('ddmLatDeg',$event)"
                                                           (keydown.PageUp)="onEnter('ddmLatDeg',$event)"
                                                           (keydown.PageDown)="onEnter('ddmLatDeg',$event)"
                                                           (paste)="pasteData($event, 'ddmLatDeg')"
                                                           #txtddmAccLatDeg>
                                            </app-typeahead>
                                        </div>
                                        <div class="col-sm-2" *ngIf="intMode == 256">
                                            <app-typeahead id="dcmAccLonDeg" name="dcmAccLonDeg" typeAheadClass="form-control w74"
                                                           [blnAllowFreeText]="true"
                                                           [blnShowSpecialValuesOnly]="true"
                                                           [disableLabel]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [printOnly]="printOnly"
                                                           [clearFieldData]="clearFieldData"
                                                           [maxlength]="2" [intMaxDecimal]="0"
                                                           [objModel]="acc"
                                                           [blnFormatWithLeading]="false"
                                                           (bindModel)="changeValue('ddmLatDeg', acc.LatDeg, $event, acc)"
                                                           strTableName="Acc" strFieldName="LatDeg"
                                                           (keyup)="resetValidateFlagOnKeyUp($event, 'ddmLatDeg')"
                                                           (keydown.Enter)="onEnter('ddmLatDeg',$event)"
                                                           (keydown.PageUp)="onEnter('ddmLatDeg',$event)"
                                                           (keydown.PageDown)="onEnter('ddmLatDeg',$event)"
                                                           (paste)="pasteData($event, 'ddmLatDeg')"
                                                           #txtddmAccLatDeg>
                                            </app-typeahead>
                                        </div>
                                        <div class="col-sm-2">
                                            <!-- name="latMinutes" [(ngModel)]="latMinutes"  We do not have metadata field for this one neither field in Acc Table. it s calculated field -->

                                            <app-typeahead id="AccLatMinutes" name="AccLatMinutes" typeAheadClass="form-control w100"
                                                           [disableLabel]="true"
                                                           [blnShowSpecialValuesOnly]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [blnAllowFreeText]="true"
                                                           [maxlength]="6" [intMaxDecimal]="4"
                                                           [blnFreeTextWithoutOption]="true"
                                                           [objModel]="acc"
                                                           strTableName="Acc" strFieldName="ddmLatitudeMinutes"
                                                           (keyup)="resetValidateFlagOnKeyUp($event, 'ddmLatitudeMinutes')"
                                                           (bindModel)="changeValue('ddmLatitudeMinutes', acc.ddmLatitudeMinutes, $event, acc)"
                                                           (keydown.Enter)="onEnter('ddmLatitudeMinutes',$event)"
                                                           (paste)="pasteData($event, 'ddmLatitudeMinutes')"
                                                           (keydown.PageUp)="onEnter('ddmLatitudeMinutes',$event)"
                                                           (keydown.PageDown)="onEnter('ddmLatitudeMinutes',$event)"
                                                           #txtlatddmMinutes>
                                            </app-typeahead>
                                        </div>
                                    </div>
                                    <div class="form-group row mb-sm-2">
                                        <div class="col-sm-1">
                                            &nbsp;
                                        </div>
                                        <div class="col-sm-3">
                                            <label for="longitude" class="col-form-label ml-4 float-right">Longitude:</label>
                                        </div>
                                        <div class="col-sm-2">
                                            <!-- We do not have metadata field for this one neither field in Acc Table. It is a calculated field -->
                                            <app-typeahead id="AccLngDegree" name="AccLngDegree"
                                                           [disableLabel]="true"
                                                           [blnShowSpecialValuesOnly]="true"
                                                           [blnHideSelectedOptionText]="true"
                                                           [blnFreeTextWithoutOption]="true"
                                                           [maxlength]="3" [intMaxDecimal]="0"
                                                           [blnAllowFreeText]="true"
                                                           [objModel]="acc"
                                                           strTableName="Acc" strFieldName="LonDeg"
                                                           [blnFormatWithLeading]="true"
                                                           (bindModel)="changeValue('ddmLonDeg', acc.LonDeg, $event, acc)"
                                                           (keyup)="resetValidateFlagOnKeyUp($event, 'ddmLonDeg')"
                                                           (keydown.Enter)="onEnter('LonDeg',$event)"
                                                           (paste)="pasteData($event, 'LonDeg')"
                                                           (keydown.PageUp)="onEnter('LonDeg',$event)"
                                                           (keydown.PageDown)="onEnter('LonDeg',$event)"
                                                           #txtddmLonDec>
                                            </app-typeahead>
                                        </div>
                                        <div class="col-sm-2">
                                            <!-- We do not have metadata field for this one neither field in Acc Table. It is a calculated field -->
                                            <app-typeahead id="AccLngMinutes" name="AccLngMinutes" typeAheadClass="form-control w100"
                                                           [disableLabel]="true"
                                                           [blnShowSpecialValuesOnly]="true"
                                                           [maxlength]="6" [intMaxDecimal]="4"
                                                           [blnHideSelectedOptionText]="true"
                                                           [blnFreeTextWithoutOption]="true"
                                                           [blnAllowFreeText]="true"
                                                           [objModel]="acc"
                                                           strTableName="Acc" strFieldName="ddmLongitudeMinutes"
                                                           (keyup)="resetValidateFlagOnKeyUp($event, 'ddmLongitudeMinutes')"
                                                           (bindModel)="changeValue('ddmLongitudeMinutes', acc.ddmLongitudeMinutes, $event, acc)"
                                                           (keydown.Enter)="onEnter('ddmLongitudeMinutes',$event)"
                                                           (paste)="pasteData($event, 'ddmLongitudeMinutes')"
                                                           (keydown.PageUp)="onEnter('ddmLongitudeMinutes',$event)"
                                                           (keydown.PageDown)="onEnter('ddmLongitudeMinutes',$event)"
                                                           #txtddmLonMin>
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row" *ngIf="!printOnly">
                        <div class="col">
                            <div class="row pt-2 pb-3 pl-2">
                                <div class="pl-3">
                                    <button class="btn btn-outline-dark openGEO" #geolocatorButton (click)="onOpenGeoLocator()">Geolocator</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
