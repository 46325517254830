import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { VehType } from 'src/app/models/enums/app.enums';
import { VehRF } from 'src/app/models/veh-rf';
import { Veh } from 'src/app/models/veh';
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { Acc } from 'src/app/models/acc';
import { Element_Specify } from 'src/app/models/element-specify';

@Component({
  selector: 'app-sfr-vehicle-vehicle-other',
  templateUrl: './sfr-vehicle-vehicle-other.component.html',
  styleUrls: ['./sfr-vehicle-vehicle-other.component.css']
})
export class SfrVehicleVehicleOtherComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private _TypeScript_TypeGuard_VehicleVehicleOtherComponent: string = null;
    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    objInstanceElementSpecify: Element_Specify;
    objVehClone: Veh;

    public objVehRFTemplate: VehRF;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        private _autofillService: AutofillService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = this._caseService.ValidateOtherSpecifyObject(this.acc);
        //this.blnAlloweSave = true;
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) {
            this.vehicleid = this._sharedDataService.getVehicleId();
            this.initData();

        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
        console.log(this.acc);
    }

    initData() {
        console.log(this.printVehNum);
        console.log(this.vehicleid);
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.SFRVehicle);
        console.log(this.veh);

        this.objInstanceElementSpecify = ObjectUtil.InstantiateElementSpecify(this.acc, this.veh.VNumber, 0);
        this.objVehClone = ObjectUtil.CloneModelObject(this.veh);
        this.objVehRFTemplate = ObjectUtil.InstantiateVehRF(this.veh);

    }

    setTravelSpeed($event) {
        let result = $event;

        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "TravSpd") as TypeaheadComponent;
        console.log(result);
        if (result > 151) {
            result = '997';
        }
        typeahead.setCurrentValue(result);

        let objFirstCell: TypeaheadComponent = this._autofillService.arrControls.find(i => i.strFieldName === 'UdOvr') as TypeaheadComponent;
        objFirstCell.objTextbox.nativeElement.click();
        objFirstCell.objTextbox.nativeElement.focus();

        this.MarkFormAsDirty();
    }


    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
