import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { UserClient } from 'src/app/models/user-client';

@Pipe({
    name: 'username'
})
export class UsernamePipe implements PipeTransform {

    constructor(private _utilService: UtilService) { }

    public async transform(
        value: any,
        args?: any
    ): Promise<string>
    {
        let intValue: number = parseInt(value);
        let UserClient: UserClient;
        let returnValue: string;       

        if (intValue !== null && intValue !== undefined && intValue > -1 && intValue !== NaN) {

            let userList = await this._utilService.GetUserList();

            let user = userList.find(x => x.SECUSERID == intValue);
            if (user) {
                returnValue = user.FULLNAME ? user.FULLNAME : '';
            }
            else {
                returnValue = '';
            }
           
        }

        return returnValue;
    }

}
