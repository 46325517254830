import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';


@Component({
  selector: 'app-case-closed',
  templateUrl: './case-close.component.html',
  styleUrls: ['./case-close.component.css']
})
export class CaseClosedComponent implements OnInit, AfterViewInit {

    constructor(private caseService: CaseService) {}

    ngOnInit() {
       
    }
    ngAfterViewInit() {
      //  this.caseService.clearAccMemory();
    }

}
