import { Injectable, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';

import { Observable, BehaviorSubject, Subscription, of, Subject, empty } from 'rxjs';
import { debounceTime, delay, switchMap, tap, finalize, share, } from 'rxjs/operators';

import { GenericService } from './generic.service';
import { ModalService } from './modal.service';
import { SharedDataService } from './shared-data.service';
import { CaseService } from './case.service';

import { Acc } from '../models/acc';
import { Dri } from '../models/dri';
import { Veh } from '../models/veh';

import { EarlyNotify } from './../models/early-notify';
import { EarlyNotificationFilters } from './../models/early-notification-filters';
import { SysRunNo} from './../models/sys-run-no';
import { DrpDownOptions } from '../models/drp-down-options';
import { ENMonthlyCountFilters } from 'src/app/ui/early-notification/early-notification-details/early-notification-details.component';
//import { EarlyNotificationState } from './../models/early-notification-state';

@Injectable({
    providedIn: 'root'
})

export class EarlyNotificationService {

    acc: Acc;
    veh: Veh;
    accPms: Promise<Acc>;

    earlyNotification: EarlyNotify
    earlyNotificationPms: Promise<EarlyNotify>;

    earlyNotificationsPms: Promise<EarlyNotify[]>;

    sysRunNo: SysRunNo;
    sysRunNoPms: Promise<SysRunNo>;

    constructor(
        private _baseService: GenericService<any>,
        //private _sharedDataService: SharedDataService,
        private _modalService: ModalService,
        private _caseService: CaseService

    ) {

    }

    //gt SysRunNo by value, year, mode
    public GetSysRunNo(sysValue: number, sysYear: number, mode: number): Promise<SysRunNo> {

        let promiseSysRunNo: Promise<SysRunNo>;

        if ((this.sysRunNo) && (this.sysRunNo.SysValue == sysValue.toString())) {
            promiseSysRunNo = new Promise((resolve, reject) => {
                resolve(this.sysRunNo);
            });
        }
        else if (this.sysRunNoPms) {
            return this.sysRunNoPms;
        }
        else {
            this._baseService.actionURL = "api/earlyNotification/GetSysRunNo/" + sysValue + "/" + sysYear + "/" + mode;
            promiseSysRunNo = this._baseService.getDataPromise();
            this.sysRunNoPms = promiseSysRunNo;

            promiseSysRunNo.then((item) => {
                this.sysRunNo = item;
                this.sysRunNoPms = null;
            });
        }

        return promiseSysRunNo;
    }


    //gt Early Notification by Id
    public GetEarlyNotificationPromise(enid: number): Promise<EarlyNotify> {

        let promiseEarlyNotification: Promise<EarlyNotify>;

        if ((this.earlyNotification) && (this.earlyNotification.ENID == enid)) {
            promiseEarlyNotification = new Promise((resolve, reject) => {
                resolve(this.earlyNotification);
            });
        }
        else if (this.earlyNotificationPms) {
            return this.earlyNotificationPms;
        }
        else {
            this._baseService.actionURL = "api/earlyNotification/GetEarlyNotification/" + enid;
            promiseEarlyNotification = this._baseService.getDataPromise();
            this.earlyNotificationPms = promiseEarlyNotification;

            promiseEarlyNotification.then(
                (item) => {
                    this.earlyNotification = item;
                    this.earlyNotificationPms = null;
                },
                (strError: string) => {
                    this.earlyNotificationPms = null;

                    if (strError.includes('isCaseLocked'))
                        this._modalService.setMessage('Early Notification with ENID ' + enid.toString() + ' is in use. Please go to a different Early Notification.', 'danger');
                    else
                        this._modalService.setMessage(strError, 'danger');
                });
        }

        return promiseEarlyNotification;
    }



    //get all Early Notifications with Promise
    //public GetEarlyNotificationsPromise(): Promise<EarlyNotify[]> {

    //    let promiseEarlyNotifications: Promise<EarlyNotify[]>;

    //    if (this.earlyNotification) {
    //        promiseEarlyNotifications = new Promise((resolve, reject) => {
    //            resolve(this.earlyNotifications);
    //        });
    //    }

    //    else if (this.earlyNotificationsPms) {
    //        return this.earlyNotificationsPms;
    //    }
    //    else {
    //        this._baseService.actionURL = "api/earlyNotification/GetEarlyNotifications/";
    //        promiseEarlyNotifications = this._baseService.getListPromise();
    //        this.earlyNotificationsPms = promiseEarlyNotifications;

    //        promiseEarlyNotifications.then((item) => {
    //            this.earlyNotifications = item;
    //            this.earlyNotificationsPms = null;
    //        });
    //    }
    //    return promiseEarlyNotifications;
    //}

    //get all Early Notifications with Observable
    public GetEarlyNotifications(filters?: EarlyNotificationFilters): Observable<EarlyNotify[]> {
        this._baseService.actionURL = "api/earlyNotification/GetEarlyNotifications/";

        let ens = this._baseService.getListByCriteria(filters).pipe(
            share()
        );

        return ens;
    }

    public GetEarlyNotificationsExcel(filters?: EarlyNotificationFilters): Promise<string> {
        this._baseService.actionURL = "api/earlyNotification/GetEarlyNotificationsExcel/";
        return this._baseService.updateEntity(filters).toPromise();
    }

    //save Early Notification 
    public SaveEarlyNotification(item: EarlyNotify): Observable<EarlyNotify> {
        this._baseService.actionURL = "api/earlyNotification/SaveEarlyNotification/";
        return this._baseService.updateEntity(item);
    }

    public GetDelayReason(year: number, stateNum: number): Observable<string[]> {

        let delelayReasons = this._baseService.GetDelayReason(year, stateNum).pipe(
            share()
        );
        return delelayReasons;
    }

    public AddJurisdiction(JurisdictionName: string, StateNum: number): Observable<DrpDownOptions> {
        this._baseService.actionURL = "api/earlyNotification/AddJurisdiction/" + StateNum + "?JurisdictionName=" + JurisdictionName;
        return this._baseService.getData();
    }

    //public GetFromStateCaseViewer(EarlyNotify: EarlyNotify): Promise<string> {
    //    this._baseService.actionURL = "api/earlyNotification/GetParFromStateCaseViewer/";
    //    return this._baseService.updateEntity(EarlyNotify).toPromise();
    //}
    //used in CRSS Parse
    public GetParFromParse(EarlyNotify: EarlyNotify): Promise<string> {
        this._baseService.actionURL = "api/util/GetParFromParse/";
        let item = this._baseService.updateEntity(EarlyNotify).toPromise();
        return item;
    }
    //used in CRSS State Case Viewer
    public GetCRSSFromStateCaseViewer(EarlyNotify: EarlyNotify): Promise<string> {
        this._baseService.actionURL = "api/util/GetCRSSFromStateCaseViewer/";
        return this._baseService.updateEntity(EarlyNotify).toPromise();
    }

    public ReleaseLock(intENID: number, blnReleaseCase: boolean = false): Promise<boolean> {
        this._baseService.actionURL = `api/earlyNotification/ReleaseLock/${intENID}/${blnReleaseCase}`;
        return this._baseService.getDataPromise();
    }

    public UpdateENStatus(intAccID: number, statuscode: number): Promise<boolean> {
        this._baseService.actionURL = `api/earlyNotification/CodingComplete/${intAccID}/${statuscode}`;
        return this._baseService.getDataPromise();
    }

    public SetEDTStateCaseNumber(accid: number, earlyNotify: EarlyNotify) {

        this._caseService.GetCasePromise(accid).then(data => {
            this.acc = data;
        });

        if (earlyNotify.StateCaseNum) {
            this.acc.StateCaseID = earlyNotify.StateCaseNum;

            if (this.acc.Non_Occupants.length > 0)
                this.acc.Non_Occupants.forEach(nonOcc => nonOcc.StateCaseID = earlyNotify.StateCaseNum);

            if (this.acc.Veh.length > 0) {
                this.acc.Veh.forEach((v: Veh) => {
                    v.StateCaseID = earlyNotify.StateCaseNum;

                    if (v.Dri)
                        v.Dri.StateCaseID = earlyNotify.StateCaseNum;

                    if (v.PreCrash)
                        v.PreCrash.StateCaseID = earlyNotify.StateCaseNum;

                    if (v.Occupants.length > 0) {
                        v.Occupants.forEach((person) => {
                            person.StateCaseID = earlyNotify.StateCaseNum;
                        });
                    }

                });

            }

            this._caseService.SaveCase(this.acc);
        }    
    }
}


