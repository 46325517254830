<form novalidate #fvpn="ngForm">
    <div id="crash-notes" class="pt-2" *ngIf="person">
        <div [ngClass]="{'col-12' : printOnly === true}">
            <div class="form-group mb-0">
                <div class="px-2">
                    <app-textfield id="PersonNotes" [disableLabel]="false"
                                   [blnIsNumber]="false" [intTextAreaRows]="10"
                                   [objModel]="person" [(objCurrentValue)]="person.Notes"
                                   [blnFormatWithLeading]="false"
                                   strTableName="Occupants" strFieldName="Notes"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-textfield>
                </div>
            </div>
        </div>
    </div>
</form>
