
export enum LookupTable {
    ActAtCrash = "ActAtCrash",
    ActPriorCrash = "ActPriorCrash",
    ADSEngaged = "ADSEngaged",
    ADSLevel = "ADSLevel",
    ADSPresent = "ADSPresent",
    Age = "Age",
    AirBag = "AirBag",
    Alc_Related = "Alc_Related",
    AlcInvol = "AlcInvol",
    AlcRes = "AlcRes",
    AlcSts = "AlcSts",
    AlcTst = "AlcTst",
    ARF = "ARF",
    ArrHr = "ArrHr",
    ArrMin = "ArrMin",
    AtmCond = "AtmCond",
    AccrcyDtectnDistFrPedEst = "SS.AccrcyDtectnDistFrPedEst",
    AcurcyImpSpdEst = "SS.AcurcyImpSpdEst",
    AcurcyVehTrvlSpdEst = "SS.AcurcyVehTrvlSpdEst",
    AreaOfDamage = "SS.AreaOfDamage",
    Body = "Body",
    BusUse = "BusUse",
    Cargo = "Cargo",
    City = "City",
    CommLic = "CommLic",
    CondAtCrashTime = "CondAtCrashTime",
    ConsZone = "ConsZone",
    ContDev = "ContDev",
    ContribCirc = "ContribCirc",
    County = "County",
    CrashType = "CrashType",
    CriticalEvent = "CriticalEvent",
    CriticalEventCat = "CriticalEventCat",
    CrshAvoid = "CrshAvoid",
    CaseLevels = 'CaseLevels',
    CaseStatus = 'CaseStatus',
    CaseType = 'CaseType',
    CargoType = 'SS.CARGOTYPE',
    CargoWeight = 'SS.CARGOWGT',
    CARGOSPILL = 'SS.CARGOSPILL',
    CURBWTTRLR = 'SS.CURBWTTRLR',
    CrashTypeConf = "CrashTypeConf",
    CrashTypeCat = "CrashTypeCat",
    DeForm = "DeForm",
    DevFunc = "DevFunc",
    DiedScene = "DiedScene",
    DRF = "DRF",
    DriAvoidManvr = "DriAvoidManvr",
    DriDistract = "DriDistract",
    DrivPres = "DrivPres",
    DrugInv = "DrugInv",
    DrugRes = "DrugRes",
    Drugsts = "Drugsts",
    DrugTst = "DrugTst",
    DthDay = "DthDay",
    DthHr = "DthHr",
    DthMin = "DthMin",
    DthMon = "DthMon",
    DthYr = "DthYr",
    DZip = "DZip",
    DistFromCurb = "SS.DistFromCurb",
    DtectnDistFrPed = "SS.DtectnDistFrPed",
    EDT_CaseStatus = "EDT_CaseStatus",
    Ejection = "Ejection",
    EjectPath = "EjectPath",
    Emerg = "Emerg",
    ENDays = "ENDays",
    ENMonths = "ENMonths",
    ENType = "ENType",
    Extricat = "Extricat",
    F_L_AccMon = "F_L_AccMon",
    F_L_AccYr = "F_L_AccYr",
    FarsDays = "FarsDays",
    FarsHours = "FarsHours",
    FarsMinutes = "FarsMinutes",
    FarsMonths = "FarsMonths",
    Feet = "Feet",
    FhEvent = "FhEvent",
    FinalStageBodyClass = "FinalStageBodyClass",
    Fire = "Fire",
    FuncSystem = "FuncSystem",
    GESCaseStatus = 'GESCaseStatus',
    GvwRating = "GvwRating",
    HazCargo = "HazCargo",
    HazMat2 = "HazMat2",
    HazMat3 = "HazMat3",
    HazMat4 = "HazMat4",
    HazMat5 = "HazMat5",
    Helmet = "Helmet",
    HelmetMisuse = "HelmetMisuse",
    HisPanic = "HisPanic",
    HitRun = "HitRun",
    HospHr = "HospHr",
    HospMin = "HospMin",
    Inches = "Inches",
    InImpact = "InImpact",
    InjurWrk = "InjurWrk",
    Injury = "Injury",
    IntersectionType = "IntersectionType",
    Jacknife = "Jacknife",
    LandUse = "LandUse",
    LatDeg = "LatDeg",
    LatMin = "LatMin",
    LatSec = "LatSec",
    Leave = "Leave",
    LicComp = "LicComp",
    LicEndor = "LicEndor",
    LicRestrict = "LicRestrict",
    LicState = "LicState",
    LightCond = "LightCond",
    LonDeg = "LonDeg",
    LonMin = "LonMin",
    LonSec = "LonSec",
    Make = "Make",
    MannCol = "MannCol",
    McInAuth = "McInAuth",
    MCINNNUM = "MCINNNUM",
    MethAlc = "MethAlc",
    MethDrug = "MethDrug",
    MhEvent = "MhEvent",
    Milepoint = "Milepoint",
    MilepointDecimal = "MilepointDecimal",
    Model = "Model",
    Model_Yr = "Model_Yr",
    MonthlyCountType = "MonthlyCountType",
    MULTIPLERACE = "MULTIPLERACE",
    NHS = "NHS",
    NMDistract = "NMDistract",
    NMLocat = "NMLocat",
    NonCDL = "NonCDL",
    NonCDLType = "NonCDLType",
    NotHr = "NotHr",
    NotMin = "NotMin",
    numfatal = "numfatal",
    NumOccs = "NumOccs",
    Ownership = "Ownership",
    ObjContactGroup = "SS.ObjContactGroup",
    ObjContactGroupEvent = "SS.ObjContactGroupEvent",
    PBCAT_BikeCrashGroup = "PBCAT_BikeCrashGroup",
    PBCAT_BikeCrashLocation = "PBCAT_BikeCrashLocation",
    PBCAT_BikeCrashType = "PBCAT_BikeCrashType",
    PBCAT_BikeDirection = "PBCAT_BikeDirection",
    PBCAT_BikePosition = "PBCAT_BikePosition",
    PBCAT_CrossWalk = "PBCAT_CrossWalk",
    PBCAT_PedCrashGroup = "PBCAT_PedCrashGroup",
    PBCAT_PedCrashLocation = "PBCAT_PedCrashLocation",
    PBCAT_PedCrashType = "PBCAT_PedCrashType",
    PBCAT_PedDirection = "PBCAT_PedDirection",
    PBCAT_PedLegIntersection = "PBCAT_PedLegIntersection",
    PBCAT_PedMotDirection = "PBCAT_PedMotDirection",
    PBCAT_PedMotManeuver = "PBCAT_PedMotManeuver",
    PBCAT_PedPosition = "PBCAT_PedPosition",
    PBCAT_PedScenario = "PBCAT_PedScenario",
    PBCAT_SchoolZone = "PBCAT_SchoolZone",
    PBCAT_SideWalk = "PBCAT_SideWalk",
    PreImpLoc = "PreImpLoc",
    PreImpStab = "PreImpStab",
    PrevACC = "PrevACC",
    PrevDWI = "PrevDWI",
    PrevOth = "PrevOth",
    PrevSpd = "PrevSpd",
    PrevSus = "PrevSus",
    PRF = "PRF",
    PrImpact = "PrImpact",
    PType = "PType",
    PedFinalRestDist = "SS.PedFinalRestDist",
    PedWeight = "SS.PedWeight",
    PedHeight = "SS.PedHeight",
    Race = "Race",
    RegOwner = "RegOwner",
    RegState = "RegState",
    RelJunc = "RelJunc",
    RelJuncInter = "RelJuncInter",
    RelRdwy = "RelRdwy",
    ResTraint = "ResTraint",
    RestraintMisuse = "RestraintMisuse",
    RoadAlgn = "RoadAlgn",
    RoadProf = "RoadProf",
    RollOver = "RollOver",
    RollOverLoc = "RollOverLoc",
    RptJur = "RptJur",
    RteSign = "RteSign",
    RXIng = "RXIng",
    Safety = "Safety",
    SASPBCAT_PedCrashType = "SASPBCAT_PedCrashType",
    SASPBCAT_PedMotDirection = "SASPBCAT_PedMotDirection",
    SchlBus = "SchlBus",
    SeatPos = "SeatPos",
    SeqEvent = "SeqEvent",
    Sex = "Sex",
    SpdLim = "SpdLim",
    SpdRel = "SpdRel",
    SpeJur = "SpeJur",
    SpUse = "SpUse",
    StateNum = "StateNum",
    StrikVeh = "StrikVeh",
    SurfCond = "SurfCond",
    SurfType = "SurfType",
    ShoulderWidth = "SS.ShldrWidth",
    TakeHosp = "TakeHosp",
    TrafFlow = "TrafFlow",
    TrafLane = "TrafLane",
    Trailer = "Trailer",
    TrailerVIN = "TrailerVIN",
    TravSpd = "TravSpd",
    UdOvr = "UdOvr",
    UnitType = "UnitType",
    VConfig = "VConfig",
    VehManvr = "VehManvr",
    vin = "vin",
    VIOL = "VIOL",
    VisObs = "VisObs",
    vPICBodyClass = "vPICBodyClass",
    vPICGVWR = "vPICGVWR",
    vPICMake = "vPICMake",
    vPICModel = "vPICModel",
    vPICTrailerBodyType = "vPICTrailerBodyType",
    vPICTrailerGVWR = "vPICTrailerGVWR",
    VRf = "VRf",
    VR_PSU = 'VR_PSU',
    VR_Jurisdiction = 'VR_Jurisdiction',
    VehicleEmptyWeight = "SS.CURBWTPWRUNIT",
    VehFinalRestDist = "SS.VehFinalRestDist",
    VehicleClass = "SS.VehicleClass",
    VehNum = "SS.VehNum",
    Weight = "Weight",
    LightingType = "SS.LightType",
    LightingClr = "SS.LightClr",
    LightingSts = "SS.LightSts",
    LightingSrc = "SS.LightSrc",
    VNumber = "VNumber"
}

export enum FieldName {
    AccDate = "AccDate",
    AccMon = "AccMon",
    AccDay = "AccDay",
    AccMin = "AccMin",
    AccTime = "AccTime",
    ActAtCrash = "ActAtCrash",
    ActPriorCrash = "ActPriorCrash",
    ADSEngaged = "ADSEngaged",
    ADSLevel = "ADSLevel",
    ADSPresent = "ADSPresent",
    Age = "Age",
    AirBag = "AirBag",
    AlcInvol = "AlcInvol",
    AlcRes = "AlcRes",
    AlcSts = "AlcSts",
    AlcTst = "AlcTst",
    AOI1 = "AOI1",
    AOI2 = "AOI2",
    ARF1 = "ARF1",
    ARF2 = "ARF2",
    ARF3 = "ARF3",
    ArrTime = "ArrTime",
    BikeCrashLocation = "BikeCrashLocation",
    BikeCrashType = "BikeCrashType",
    BikeCrossWalk = "BikeCrossWalk",
    BikeDirection = "BikeDirection",
    BikePosition = "BikePosition",
    BikeSchoolZone = "BikeSchoolZone",
    BikeSideWalk = "BikeSideWalk",
    Body = "Body",
    BusUse = "BusUse",
    Cargo = "Cargo",
    City = "City",
    CommLic = "CommLic",
    CondAtCrashTime = "CondAtCrashTime",
    ConsZone = "ConsZone",
    ContDev = "ContDev",
    ContribCirc = "ContribCirc",
    County = "County",
    CrashGroup = "CrashGroup",
    crashGroupBike = "crashGroupBike",
    CrashType = "CrashType",
    CreatedRecord = "CreatedRecord",
    CriticalEvent = "CriticalEvent",
    CriticalEventCat = "CriticalEventCat",
    CrshAvoid = "CrshAvoid",
    DeathDate = "DeathDate",
    DeathTime = "DeathTime",
    DeForm = "DeForm",
    DevFunc = "DevFunc",
    DiedScene = "DiedScene",
    DRF1 = "DRF1",
    DRF2 = "DRF2",
    DRF3 = "DRF3",
    DRF4 = "DRF4",
    DriAvoidManvr = "DriAvoidManvr",
    DriCondAtCrashTime = "DriCondAtCrashTime",
    DriDistract = "DriDistract",
    DrivPres = "DrivPres",
    DrugInv = "DrugInv",
    DrugRes = "DrugRes",
    DrugSts = "DrugSts",
    DrugTst = "DrugTst",
    DZip = "DZip",
    Ejection = "Ejection",
    EjectPath = "EjectPath",
    ElementValue = "ElementValue",
    ElementValue1 = "ElementValue1",
    Emerg = "Emerg",
    EnAccDate = "EnAccDate",
    ENAccMonth = "ENAccMonth",
    ENAccTime = "ENAccTime",
    ENCrashType = "ENCrashType",
    ENNoOfFatals = "ENNoOfFatals",
    ENRptJur = "ENRptJur",
    ENState = "ENState",
    Extricat = "Extricat",
    FAccDate = "FAccDate",
    Feet = "Feet",
    FhEvent = "FhEvent",
    FinalStageBodyClass = "FinalStageBodyClass",
    Fire = "Fire",
    FuncSystem = "FuncSystem",
    GVWRating = "GVWRating",
    HazCargo = "HazCargo",
    HazMat2 = "HazMat2",
    HazMat4 = "HazMat4",
    HazMat5 = "HazMat5",
    Helmet = "Helmet",
    HelmetMisUse = "HelmetMisUse",
    HisPanic = "HisPanic",
    HitRun = "HitRun",
    HospTime = "HospTime",
    Inches = "Inches",
    InImpact = "InImpact",
    InjurWrk = "InjurWrk",
    Injury = "Injury",
    IntersectionType = "IntersectionType",
    Jacknife = "Jacknife",
    LAccDate = "LAccDate",
    LandUse = "LandUse",
    LatDeg = "LatDeg",
    LatMin = "LatMin",
    LatSec = "LatSec",
    Leave = "Leave",
    LicComp = "LicComp",
    LicEndor = "LicEndor",
    LicEndor1 = "LicEndor1",
    LicEndor2 = "LicEndor2",
    LicEndor3 = "LicEndor3",
    LicRestrict = "LicRestrict",
    LicRestrict1 = "LicRestrict1",
    LicRestrict2 = "LicRestrict2",
    LicRestrict3 = "LicRestrict3",
    LicState = "LicState",
    LightCond = "LightCond",
    LonDeg = "LonDeg",
    LonMin = "LonMin",
    LonSec = "LonSec",
    Make = "Make",
    MannCol = "MannCol",
    McInAuth = "McInAuth",
    McInAuth1 = "McInAuth1",
    McInAuth2 = "McInAuth2",
    McInAuth3 = "McInAuth3",
    McInnNum = "McInnNum",
    McInnNum1 = "McInnNum1",
    McInnNum2 = "McInnNum2",
    McInnNum3 = "McInnNum3",
    MethAlc = "MethAlc",
    MethDrug = "MethDrug",
    MhEvent = "MhEvent",
    Model = "Model",
    ModelYr = "ModelYr",
    MotDirection = "MotDirection",
    MotManeuver = "MotManeuver",
    MountlyCountTypeID = "MountlyCountTypeID",
    MultipleRace1 = "Multiple Race1",
    NHS = "NHS",
    NMDistract = "NMDistract",
    NMLocat = "NMLocat",
    NonCDL = "NonCDL",
    NonCDLType = "NonCDLType",
    NotTime = "NotTime",
    NumOccs = "NumOccs",
    Ownership = "Ownership",
    PedCrashLocation = "PedCrashLocation",
    PedCrashType = "PedCrashType",
    PedCrossWalk = "PedCrossWalk",
    PedDirection = "PedDirection",
    PedLegIntersection = "PedLegIntersection",
    PedPosition = "PedPosition",
    PedSchoolZone = "PedSchoolZone",
    PedSideWalk = "PedSideWalk",
    PreImpLoc = "PreImpLoc",
    PreImpStab = "PreImpStab",
    PrevAcc = "PrevAcc",
    PrevDwi = "PrevDwi",
    PrevOth = "PrevOth",
    PrevSpd = "PrevSpd",
    PrevSusRevoc = "PrevSusRevoc",
    PRF1 = "PRF1",
    PRF2 = "PRF2",
    PRF3 = "PRF3",
    PrImpact = "PrImpact",
    PType = "PType",
    Race = "Race",
    RegOwner = "RegOwner",
    RegState = "RegState",
    RelJunc = "RelJunc",
    RelJuncInter = "RelJuncInter",
    RelRdwy = "RelRdwy",
    Restraint = "Restraint",
    RestraintMisuse = "RestraintMisuse",
    RoadAlgn = "RoadAlgn",
    RoadProf = "RoadProf",
    RollOver = "RollOver",
    RollOverLoc = "RollOverLoc",
    RteSign = "RteSign",
    RXing = "RXing",
    Safety1 = "Safety1",
    Safety2 = "Safety2",
    Safety3 = "Safety3",
    Safety4 = "Safety4",
    Safety5 = "Safety5",
    Safety6 = "Safety6",
    Scenario = "Scenario",
    SchlBus = "SchlBus",
    SeatPos = "SeatPos",
    SeqEvent = "SeqEvent",
    Sex = "Sex",
    SOE = "SOE",
    SpdLim = "SpdLim",
    SpdRel = "SpdRel",
    SpeJur = "SpeJur",
    SpUse = "SpUse",
    StateNum = "StateNum",
    StrikVeh = "StrikVeh",
    SurfCond = "SurfCond",
    SurfType = "SurfType",
    TakeHosp = "TakeHosp",
    TrafFlow = "TrafFlow",
    TrafLane = "TrafLane",
    Trailer = "Trailer",
    TrailerBodyTypeId = "TrailerBodyTypeId",
    TrailerGVWR = "TrailerGVWR",
    TrailerVIN1 = "TrailerVIN1",
    TrailerVIN2 = "TrailerVIN2",
    TrailerVIN3 = "TrailerVIN3",
    TravSpd = "TravSpd",
    UdOvr = "UdOvr",
    UnitType = "UnitType",
    VConfig = "VConfig",
    VehManvr = "VehManvr",
    VIN = "VIN",
    Viol = "Viol",
    VisObs = "VisObs",
    VPICBodyClass = "VPICBodyClass",
    vPICGVWR = "vPICGVWR",
    VPICMake = "VPICMake",
    VPICModel = "VPICModel",
    VRF1 = "VRF1",
    VRF2 = "VRF2",
    Weight = "Weight",
    LightingType = "LightingType",
    LightingClr = "LightingClr",
    LightingSts = "LightingSts",
    LightingSrc = "LightingSrc"

}


















