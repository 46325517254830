import { Component, OnInit, AfterViewInit, ViewEncapsulation, forwardRef, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';

import { Non_Occupants } from '../../../models/non-occupants';
import { Occupants } from 'src/app/models/occupants';

import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbDateStruct, NgbDateAdapter, NgbDateNativeAdapter, NgbDate, NgbCalendar, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AutofillService } from 'src/app/services/autofill.service';
import { ModalService } from 'src/app/services/modal.service';

import { TypeaheadComponent } from '../../typeahead/typeahead.component';
import { ObjectUtil } from 'src/app/helper/objectUtil';

@Component({
    selector: 'app-modals-age-calculator',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './modals-age-calculator.component.html',
    styleUrls: ['./modals-age-calculator.component.css'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
    ]
})

export class ModalsAgeCalculatorComponent implements OnInit {//, AfterViewInit {
    closeResult: string;

    todayDate: Date;
    pickedDate: Date;

    datePicked: Date;    

    isDatePicked: boolean = false;
    calculatedAgeint: number;

    @Input() accDate: Date;

    @Input() nonOccObj: Non_Occupants;
    @Input() occupantDateOfBirth: Date;

    @Input() personObj: Occupants;

    @Output() newDOBEvent = new EventEmitter<Date>();

    getMinDate: NgbDate;
    getMaxDate: NgbDate;

    strMinValidation: string;
    streMaxValidation: string;

    blnShowValidationMessage: boolean = false;
    blnRequired: boolean = false;
    blnInvalid: boolean = false;
    blnIsDateInRange: boolean = false;

    private modal: NgbModalRef;

    @ViewChild('btnOpen') public btnOpen: ElementRef;

    constructor(
        private _modalService: NgbModal,
        private _mService: ModalService,
        private calendar: NgbCalendar,
        private _autofillService: AutofillService
    ) { }

    ngOnInit() {        
        
    }

    onDateSelect(dplvar) {
        ObjectUtil.DateMask(event);
        this.blnRequired = false;
        this.blnInvalid = false;
        this.blnIsDateInRange = false;
        this.isDatePicked = false;

        this.blnShowValidationMessage = true;


        if (!dplvar.invalid) {
            this.pickedDate = new Date(dplvar.value);
            this.datePicked =  dplvar.value;
            this.calculatedAgeint = this.calculateAge(this.pickedDate);
            this.isDatePicked = true;
            document.getElementById('AgeCalculatorDateOfBirth').focus(); //Texbox is in nested template fed to ngModal, @ViewChild cannot resolve template variable in nested template, so using getElementById(). Keep focus on textbox so that Enter key handler receives key press (if Enter is pressed to dismiss modal popup)
        }
    }

    calculateAge(PickedDate: Date): number {
        //this.accDate = new Date(this.accDate);
        //let acccYear = this.accDate.getFullYear();

        //let dateOfBirth = new Date(PickedDate);
        //let birthYear = dateOfBirth.getFullYear();
        //let age = acccYear - birthYear;

        //if (this.accDate <= new Date(dateOfBirth.setFullYear(acccYear))) {
        //    age = age - 1;
        //}

        let accYear = this.accDate.getFullYear();
        let accmonth = this.accDate.getMonth();
        let accday = this.accDate.getDate();

        let birthyear = PickedDate.getFullYear();
        let birthmonth = PickedDate.getMonth();
        let birthday = PickedDate.getDate();

        let age = accYear - birthyear;
        let age_month = accmonth - birthmonth;
        let age_day = accday - birthday;

        if (age_month < 0 || (age_month == 0 && age_day < 0)) {
            age = age - 1;
        }

        return age;
    }

    onBlur() {
        this.blnShowValidationMessage = true;
    }


    onSubmit(dplvar) {
        this.blnRequired = false;
        this.blnInvalid = false;
        this.blnIsDateInRange = false;

        if (dplvar.invalid) {
            if (dplvar.errors.required) {
                this.blnShowValidationMessage = true;
                this.blnRequired = true;
                return;
            }
            if (dplvar.errors.ngbDate.invalid) {
                this.blnShowValidationMessage = true;
                this.blnInvalid = true;
                return;
            }
            if (dplvar.errors.ngbDate.requiredBefore || dplvar.errors.ngbDate.requiredAfter) {
                this.blnShowValidationMessage = true;
                this.blnIsDateInRange = true;
                return;
            }
            return;
        }
        else {
            this.blnRequired = false;
            this.blnInvalid = false;
            this.blnIsDateInRange = false;

            if (this.nonOccObj) {
                this.nonOccObj.Age = this.calculatedAgeint;

                let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "Age") as TypeaheadComponent;
                typeahead.setCurrentValue(this.nonOccObj.Age.toString());

                this.modal.close();

                let objFirstCell: TypeaheadComponent = this._autofillService.arrControls.find(i => i.strFieldName === 'Sex') as TypeaheadComponent;
                objFirstCell.objTextbox.nativeElement.click();
                objFirstCell.objTextbox.nativeElement.focus();

            }
            if (this.personObj) {
                this.personObj.Age = this.calculatedAgeint;

                let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == "Age") as TypeaheadComponent;
                typeahead.setCurrentValue(this.personObj.Age.toString());

                this.modal.close();

                let objFirstCell: TypeaheadComponent = this._autofillService.arrControls.find(i => i.strFieldName === 'Sex') as TypeaheadComponent;
                objFirstCell.objTextbox.nativeElement.click();
                objFirstCell.objTextbox.nativeElement.focus();
            }
                        
            this.newDOBEvent.emit(this.pickedDate);         
        }
    }

    onCancel() {
        this.blnRequired = false;
        this.blnInvalid = false;
        this.blnIsDateInRange = false;
        
    }

    open(content, options: NgbModalOptions) {       
        
        if (this.accDate) {
            this.getMinDate = new NgbDate(this.accDate.getFullYear() - 120, this.accDate.getMonth() + 1, this.accDate.getDate());
            this.getMaxDate = new NgbDate(this.accDate.getFullYear(), this.accDate.getMonth() + 1, this.accDate.getDate());
        }
        else {
            let today = new Date();
            this.getMinDate = new NgbDate(today.getFullYear() - 120, today.getMonth() + 1, today.getDate());
            this.getMaxDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
        }

        this.strMinValidation = (this.getMinDate.month).toString() + "/" + this.getMinDate.day.toString() + "/" + this.getMinDate.year.toString();
        this.streMaxValidation = (this.getMaxDate.month).toString() + "/" + this.getMaxDate.day.toString() + "/" + this.getMaxDate.year.toString();

        if (this.occupantDateOfBirth) {
            this.pickedDate = this.occupantDateOfBirth;
            this.calculatedAgeint = this.calculateAge(this.pickedDate);
        }
        else {
            this.pickedDate = null;
        }

        this.modal = this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdropClass: 'light-blue-backdrop', backdrop: 'static', keyboard: false });

        this.modal.result.then((result) => {
            console.log('result: ', result);
        });
    }
}
