import { Component, OnInit, QueryList, ViewChild, ViewChildren, AfterViewInit, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from './../../../../services/case.service';
import { VehicleService } from './../../../../services/vehicle.service';
import { UtilService } from './../../../../services/util.service';

import { Acc } from './../../../../models/acc';
import { Veh } from './../../../../models/veh';
import { Dri } from './../../../../models/dri';
import { DriPrevSus } from './../../../../models/dri-prev-sus';

import { TableFieldElements } from './../../../../models/table-field-elements';
import { DrpDownOptions } from './../../../../models/drp-down-options';

import { TableId, FormName, KeyCode, Preference, RBISDataValue } from './../../../../models/enums/app.enums';

import { TypeaheadComponent } from './../../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { GetSecUserPreferencesResult } from 'src/app/models/GetSecUserPreferencesResult';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service';
import { AutofillService } from 'src/app/services/autofill.service';
import { FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { UIElementBase } from 'src/app/helper/UIElementBase';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-vehicle-driver-counters',
    templateUrl: './vehicle-driver-counters.component.html',
    styleUrls: ['./vehicle-driver-counters.component.css']
})

export class VehicleDriverCountersComponent extends BaseComponent implements OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_VehicleDriverCountersComponent: string = null;
    arrMonthExludeValues: Array<number> = [-1, 0, 99];
    arrYearExludeValues: Array<number> = [-1, 0, 9999];

    UIControls: UIElementBase[];

    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    dri: Dri;
    vehicle: Veh
    driPrevSusS: DriPrevSus[];

    stateId: number;
    driPrevSus1: number;
    driPrevSus2: number;
    driPrevSus3: number;

    //@ViewChild(TypeaheadComponent) typeaheads1: TypeaheadComponent;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _vehService: VehicleService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _secUserPerferenceService: SecUserPerferenceService,
        private _autoFillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {

        //this.blnAlloweSave = this._vehService.ValidateCounterFirstLastDate(this.dri);

        let dtAccDate = new Date(this.dri.Veh.Acc.CaseYear, this.dri.Veh.Acc.AccMon - 1, );
        let dtFirstCrash: Date = new Date(this.dri.FAccYr, this.dri.FAccMon - 1);
        let dtLastCrash: Date = new Date(this.dri.LAccYr, this.dri.LAccMon - 1);

        let diff_msFAcc = dtAccDate.getTime() - dtFirstCrash.getTime();
        let year_dtFAcc = new Date(diff_msFAcc);

        let differenceFAcc = Math.abs(year_dtFAcc.getFullYear() - 1970);

        let diff_msLAcc = dtAccDate.getTime() - dtLastCrash.getTime();
        let year_dtLAcc = new Date(diff_msLAcc);

        let differenceLAcc = Math.abs(year_dtLAcc.getFullYear() - 1970);


        if ((this.dri.LAccYr == -1 && this.dri.LAccMon != -1) ||
            (this.dri.LAccMon == -1 && this.dri.LAccYr != -1)  // checking if one of the pair MM/YYYY value is missing
            ||
            (this.dri.LAccYr == 0 && this.dri.LAccMon == 99) ||
            (this.dri.LAccMon == 0 && this.dri.LAccYr == 9999)  // checking if one of the pairs has 0's and other has 9's 
        ) {
            this._modalService.setMessage("Either both Month and Year are blank or filled in. If both are filled in, it must be Month: (00-12,99) Year(0000, 9999 or within last 5 years before crash date)", "danger"); //Most Recent Crash 
            let item = this.UIControls.filter(x => x.strFieldName == "LAccMon" || x.strFieldName == "LAccYr");
            item.forEach(x => {
                x.setIsInvalidClass(true);
            });

            this.blnAlloweSave = false;
            return;
        }

        else if ((this.dri.FAccYr == -1 && this.dri.FAccMon != -1) ||
            (this.dri.FAccMon == -1 && this.dri.FAccYr != -1)  // checking if one of the pair MM/YYYY value is missing
            ||
            (this.dri.FAccYr == 0 && this.dri.FAccMon == 99) ||
            (this.dri.FAccMon == 0 && this.dri.FAccYr == 9999) // checking if one of the pairs has 0's and other has 9's 

        ) {
            this._modalService.setMessage("Either both Month and Year are blank or filled in. If both are filled in, it must be Month: (00-12,99) Year(0000, 9999 or within last 5 years before crash date)", "danger"); //Oldest Crash 
            let item = this.UIControls.filter(x => x.strFieldName == "FAccMon" || x.strFieldName == "FAccYr");
            item.forEach(x => {
                x.setIsInvalidClass(true);
            });

            this.blnAlloweSave = false;
            return;
        }

        else if ((!this.arrMonthExludeValues.includes(this.dri.FAccMon) && (this.dri.FAccYr == 0))) {
            this._modalService.setMessage("Please select a valid year", "danger"); //Oldest Crash 
            let item = this.UIControls.find(x => x.strFieldName == "FAccYr");
            item.setIsInvalidClass(true);
            this.blnAlloweSave = false;
            return;
        }

        else if ((!this.arrMonthExludeValues.includes(this.dri.LAccMon) && (this.dri.LAccYr == 0))) {
            this._modalService.setMessage("Please select a valid year", "danger"); //Most Recent Crash 
            let item = this.UIControls.find(x => x.strFieldName == "LAccYr");
            item.setIsInvalidClass(true);
            this.blnAlloweSave = false;
            return;
        }

        else if ((this.dri.FAccMon == 0) && (!this.arrYearExludeValues.includes(this.dri.FAccYr))) {
            this._modalService.setMessage("Please select a valid month", "danger"); //Oldest Crash 
            let item = this.UIControls.find(x => x.strFieldName == "FAccMon");
            item.setIsInvalidClass(true);
            this.blnAlloweSave = false;
            return;
        }

        else if ((this.dri.LAccMon == 0) && (!this.arrYearExludeValues.includes(this.dri.LAccYr))) {
            this._modalService.setMessage("Please select a valid month", "danger"); //Most Recent Crash 
            let item = this.UIControls.find(x => x.strFieldName == "LAccMon");
            item.setIsInvalidClass(true);
            this.blnAlloweSave = false;
            return;
        }

        else if ((!this.arrYearExludeValues.includes(Number(this.dri.FAccYr)) && this.dri.FAccYr != -1) &&
            (!this.arrMonthExludeValues.includes(Number(this.dri.FAccMon)) && this.dri.FAccMon != -1) &&
            (differenceFAcc >= 5 && (this.dri.Veh.Acc.AccMon != this.dri.FAccMon))) { // Since we do not have days for Oldest Crash we have to skip this validation if difference is 5 years and months are same
            this._modalService.setMessage("Year must be within last 5 years before crash date", "danger"); //Oldest Crash
            let item = this.UIControls.filter(x => x.strFieldName == "FAccMon" || x.strFieldName == "FAccYr");
            item.forEach(x => {
                x.setIsInvalidClass(true);
            });

            this.blnAlloweSave = false;
            return;
        }

        else if ((!this.arrYearExludeValues.includes(Number(this.dri.LAccYr)) && this.dri.LAccYr != -1) &&
            (!this.arrMonthExludeValues.includes(Number(this.dri.LAccMon)) && this.dri.LAccMon != -1) &&
            (differenceLAcc >= 5 && (this.dri.Veh.Acc.AccMon != this.dri.LAccMon))) { // Since we do not have days for Most Recent Crash we have to skip this validation if difference is 5 years and months are same
            this._modalService.setMessage("Year must be within last 5 years before crash date", ""); //Most Recent Crash 
            let item = this.UIControls.filter(x => x.strFieldName == "LAccMon" || x.strFieldName == "LAccYr");
            item.forEach(x => {
                x.setIsInvalidClass(true);
            });
            this.blnAlloweSave = false;
            return;
        }
        else {
            this.blnAlloweSave = true;
        }
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        }
        else {
            this.vehicleid = this.printVehNum;
            this.accid = this.acc.AccID;
            this.initData();
        }
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        if (!this.veh.Dri) {
            this.dri = {} as Dri;
        }
        else {
            this.dri = this.acc.Veh.find(v => v.VNumber == this.vehicleid).Dri;
            ObjectUtil.BindDriPrevSusToParent(this.dri);

            //getting userPreferance
            this._secUserPerferenceService.isThisPreferenceOn(Preference.AllowprefillforPrevRecAdminPerSefields)
                .then((objPref: boolean) => {
                    if (objPref) {
                        let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                            subInit.unsubscribe();
                            let typPrevRevoc1 = this._autoFillService.arrControls.find(i => i.strFieldName == FieldName.PrevSusRevoc && i.objCurrentValue == RBISDataValue.Blank && i.intSeqNum == 1);
                            if (typPrevRevoc1)
                                typPrevRevoc1.setCurrentValue("0");
                            let typPrevRevoc2 = this._autoFillService.arrControls.find(i => i.strFieldName == FieldName.PrevSusRevoc && i.objCurrentValue == RBISDataValue.Blank && i.intSeqNum == 2);
                            if (typPrevRevoc2)
                                typPrevRevoc2.setCurrentValue("0");

                        }).bind(this));
                    }
                })
        }

        if (!this.dri.DriPrevSus) {
            this.dri.DriPrevSus = [] as DriPrevSus[];
        }
    }

    eventHandler(event) {
        //console.log(fieldname);
        if (event.code.match(KeyCode.NumpadDivide) || event.code.match(KeyCode.Slash)) {

            let arrTypeAheadControls = this._autoFillService.arrControls;
            if (arrTypeAheadControls) {
                arrTypeAheadControls.forEach(control => {
                    control.setCurrentValue("0");
                })
            }
        }
    }

    OnModelFAccMonChange($event) {
        this._modalService.resetMessage();
        if (this.UIControls) {
            let item = this.UIControls.filter(x => x.strFieldName == "FAccMon" || x.strFieldName == "FAccYr");
            item.forEach(x => {
                x.setIsInvalidClass(false);
            });
        }
    }

    OnModelFAccYrChange($event) {
        this._modalService.resetMessage();
        if (this.UIControls) {
            let item = this.UIControls.filter(x => x.strFieldName == "FAccMon" || x.strFieldName == "FAccYr");
            item.forEach(x => {
                x.setIsInvalidClass(false);
            });
        }
    }

    OnModelLAccMonChange($event) {
        this._modalService.resetMessage();
        if (this.UIControls) {
            let item = this.UIControls.filter(x => x.strFieldName == "LAccMon" || x.strFieldName == "LAccYr");
            item.forEach(x => {
                x.setIsInvalidClass(false);
            });
        }
    }

    OnModelLAccYrChange($event) {
        this._modalService.resetMessage();
        if (this.UIControls) {
            let item = this.UIControls.filter(x => x.strFieldName == "LAccMon" || x.strFieldName == "LAccYr");
            item.forEach(x => {
                x.setIsInvalidClass(false);
            });
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
            subInit.unsubscribe();
            this.UIControls = this._autoFillService.arrControls;
        }).bind(this));
    }

    ngOnDestroy() {

    }
}
