<form novalidate #fvdd="ngForm">
    <div class="pt-2" *ngIf="dri && veh && intMode">
        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <!-- We want UI fields to be added to HTML DOM as one unit to preserve order of appearance in registration order.
        So, do not put ngIf with different conditions on individual fields.-->
                <div class="form-group pl-2">
                    <app-typeahead id="drivPres" name="drivPres" typeAheadPosition="right-top"
                                   [objModel]="dri.Veh" strTableName="Veh" strFieldName="DrivPres"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>

                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PhyCond"
                                   typeAheadPosition="right-top"
                                   [objModel]="dri"
                                   strTableName="Dri"
                                   strFieldName="PhyCond"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="DriverFatigue" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="Fatigue"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="driViol"
                                     name="driViol"
                                     multiSelectPosition="right-top"
                                     [objModel]="dri.Dri_Viol"
                                     strTableName="Dri_Viol"
                                     strFieldName="Viol"
                                     [objTemplateModel]="objDriVioTemplate" [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="spdRel" name="spdRel" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="SpdRel" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="driCondAtCrashTime"
                                     name="driCondAtCrashTime"
                                     multiSelectPosition="right-top"
                                     strTableName="DriCondAtCrashTime"
                                     strFieldName="CondAtCrashTime"
                                     [objModel]="dri.DriCondAtCrashTime"
                                     [objTemplateModel]="objDriCondCrashTimeTemplate" [printOnly]="printOnly"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="DriInattention"
                                     multiSelectPosition="right-top"
                                     strTableName="Dri_DriInattention"
                                     strFieldName="DriInattention"
                                     [objTemplateModel]="objDriInattentionTemplate"
                                     [objModel]="dri.Veh.Dri_DriInattention"
                                     [printOnly]="printOnly"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="DriDistract"
                                     multiSelectPosition="right-top"
                                     strTableName="Dri_DriDistract"
                                     strFieldName="Dri_Distract"
                                     [objTemplateModel]="objDriDistractTemplate"
                                     [objModel]="dri.Veh.Dri_DriDistract"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="DriIllness"
                                     multiSelectPosition="right-top"
                                     strTableName="Dri_Illness"
                                     strFieldName="Illness"
                                     [objTemplateModel]="objDriIllnessTemplate"
                                     [objModel]="dri.Dri_Illness"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly" [maxInputs]="3"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
        <div class="form-group pl-2">
            <app-typeahead id="DriIllness" typeAheadPosition="right-top"
                           [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="DriIllness"
                           [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                           [printOnly]="printOnly"
                           [clearFieldData]="clearFieldData"
                           [showAllDataListForPrintOnly]="false">
            </app-typeahead>
        </div>
    </div>-->



            <div class="form-group pl-2">
                <app-typeahead id="nonCDLType" typeAheadPosition="right-top" class="pt-0"
                               [objModel]="dri" strTableName="Dri" strFieldName="NonCDLType"
                               [printOnly]="printOnly"
                               [clearFieldData]="clearFieldData">
                </app-typeahead>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="nonCDL" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="NonCDL" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="GdlSts" typeAheadPosition="right-top"
                                   [objModel]="dri" strTableName="Dri" strFieldName="GdlSts"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>
            <div class="form-group pl-2">
                <app-typeahead id="SightLine" typeAheadPosition="right-top"
                               [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="SightLine"
                               [printOnly]="printOnly" [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                               [clearFieldData]="clearFieldData">
                </app-typeahead>
            </div>
            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="DriverView" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="DriverView" [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData" [objOtherSpecifyTemplate]="objInstanceElementSpecify">
                    </app-typeahead>
                </div>
            </div>
            <!--<div [ngClass]="{'col-12 border border-dark ' : printOnly === true}">
        <div class="form-group pl-2">
            <app-multiselect id="driRF"
                             name="driRF"
                             multiSelectPosition="right-top"
                             strTableName="DriRF"
                             strFieldName="DRF"
                             intSeqNum="1"
                             [objModel]="dri.Veh.DriRF"
                             [objTemplateModel]="objDriRF"
                             [objOtherSpecifyTemplate]="objInstanceElementSpecify" [printOnly]="printOnly"
                             [showAllDataListForPrintOnly]="false"
                             [clearFieldData]="clearFieldData">
            </app-multiselect>
        </div>
    </div>-->
        </div>
    </div>
</form>
