import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

//services
import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';

//helper
import { BaseComponent } from 'src/app/helper/basecomponent';

//pipes
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';

//models
import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { Veh_SSAvoidEquipAvail } from 'src/app/models/Veh-SSAvoidEquipAvail';

//basecomponents
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { NgForm } from '@angular/forms';
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { VehType } from 'src/app/models/enums/app.enums';


@Component({
    selector: 'app-vehicle-vehicle-notes',
    templateUrl: './vehicle-vehicle-notes.component.html',
    styleUrls: ['./vehicle-vehicle-notes.component.css']
})

export class VehicleVehicleNotesComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    stateNum: number;
    accid: number;
    vehicleid: number;
    //intMode: number;

    acc: Acc;
    veh: Veh;

    public objVehAvoidEquipAvailTemplate: Veh_SSAvoidEquipAvail;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    @ViewChild('#fvvn') objForm: NgForm;

    private sbsVehId: Subscription = null;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    //ngOnInit() {
    //    if (this.printVehNum == 0) {
    //        this._route.parent.params.subscribe((params => {
    //            this.accid = + params['crashid'];

    //            if (this.sbsVehId)
    //                this.sbsVehId.unsubscribe();

    //            this._route.parent.parent.params.subscribe((params => {
    //                this.vehicleid = + params['vehicleid'];
    //                this.initData();
    //            }).bind(this));
    //        }).bind(this));
    //    } else {
    //        this.accid = this.acc.AccID;
    //        this.vehicleid = this.printVehNum;
    //        this.initData();
    //    }
    //}


    ngOnInit() {
        super.ngOnInit();

        if (this.printVehNum == 0) {
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
    }

    initData() {
        if (this.intMode == 256)
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.OtherVehicle);
        else
            this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);

        this.objVehAvoidEquipAvailTemplate = ObjectUtil.InstantiateVeh_SSAvoidEquipAvail(this.veh);
    }

    async ngAfterViewInit(): Promise<void> {
        super.ngAfterViewInit();
        //let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        //this.intMode = appSettings.intMode;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    setImpactSpeed($event) {
        //this.MarkFormAsDirty();
    }

    setDistanceValue(event) {
        let component;

        if (event.objName == 'PedFinalRestDist') {
            this.veh.Veh_SS.DtectnDistFrPed = event.result;
            component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.PedFinalRestDist);
        }
        else if (event.objName == 'VehFinalRestDist') {
            this.veh.Veh_SS.AccrcyDtectnDistFrPedEst = event.result;
            component = this.typeaheads.find(x => x.strDefsLookupTableName == LookupTable.VehFinalRestDist);
        }

        if (component) {
            component.setCurrentValue(event.result);
            component.onBlur(event.result);
            this.MarkFormAsDirty();
        }
    }
}
