import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CaseService } from '../../../services/case.service';

import { Acc } from '../../../models/acc';
import { Veh } from '../../../models/veh';
import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { Subscription } from 'rxjs';
import { VehType } from 'src/app/models/enums/app.enums';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-striking-vehicle-vehicle',
  templateUrl: './striking-vehicle-vehicle.component.html',
  styleUrls: ['./striking-vehicle-vehicle.component.css']
})
export class StrikingVehicleVehicleComponent implements OnInit {
    private sbsVehicleId: Subscription;
    stateNum: number;
    vehicleid: number;
    accid: number;
    case: Acc;
    veh: Veh;
    intMode: number;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        private _utilService: UtilService
    ) {
    }

    async ngOnInit() {
        //let appSettings = await this._sharedDataService.GetAppSettings();
        //this.intMode = appSettings.intMode;

        this._route.parent.parent.params.subscribe(params => {
            this.accid = + params['caseid'];
        });
        this._route.parent.params.subscribe(params => {
            this.vehicleid = + params['vehicleid'];
            this._caseService.GetCasePromise(this.accid).then(data => {

                this.veh = data.Veh.find(v => v.VNumber == this.vehicleid && v.VehType == VehType.StrikingVehicle);

                if (this.veh != undefined && this.veh != null)
                    //this.showTab = this._caseService.CheckTabDisplay(this.veh);
                    this._caseService.GetTabDisplayStatus(this.veh);
            });
        });
    }
    async ngAfterViewInit() {
        let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();
        this.intMode = appSettings.intMode;
    }

    ngOnDestroy() {
    }
}


