<form #f="ngForm" novalidate (window:keydown)="OnKeyDown($event)" (window:keyup)="OnKeyUp($event)">
    <div class="card">
        <div class="card-header" *ngIf="objAcc">
            <span *ngIf="objAcc.Casenum > 0 && objAcc.Mode == 4">Restructure Case {{ objAcc.EarlyNotify[0]?.PARID % 10000000 | number : '7.0-0' | replaceTextPipe : ',' : '' }}</span>
            <span *ngIf="objAcc.Casenum > 0 && (objAcc.Mode == 64 || objAcc.Mode == 128)">Restructure Case {{ objAcc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : '' }}</span>
            <span *ngIf="objAcc.Acc_SS.MOSSCasenum && objAcc.Mode == 256">Restructure Case {{ objAcc.Acc_SS.MOSSCasenum }}</span>
            <span *ngIf="objAcc.Casenum == 0">Structure Case</span>
        </div>
        <div class="card-body row">
            <div class="col-10" *ngIf="objAcc">
                <div class="row" *ngIf="objAcc.Mode == 64 || objAcc.Mode == 128">
                    <div class="col-6" *ngIf="objAcc.Casenum > 0 && objTreeData">
                        <h5 class="card-title">Original Case Structure</h5>
                        <app-tree-view [blnEnabled]="false" [blnVerbose]="true" [treeData]="objTreeData" strIdPrefix="treeOriginal"></app-tree-view>
                    </div>
                    <div [ngClass]="{'col-6': objAcc.Casenum > 0, 'col-12': objAcc.Casenum == 0 }" *ngIf="objTreeDataClone">
                        <h5 class="card-title">New Case Structure</h5>
                        <app-tree-view *ngIf="objAccClone" #treeViewNew [tabindex]="0"
                                       [blnEnabled]="true" [blnVerbose]="true" [treeData]="objTreeDataClone" strIdPrefix="treeNew"
                                       (selectionChanged)="OnTreeSelectionChanged($event)"></app-tree-view>
                    </div>
                </div>
                <div class="row" *ngIf="objAcc.Mode == 256">
                    <div class="col-6" *ngIf="objAcc.Acc_SS.MOSSCasenum && objTreeData">
                        <h5 class="card-title">Original Case Structure</h5>
                        <app-tree-view [blnEnabled]="false" [blnVerbose]="true" [treeData]="objTreeData" strIdPrefix="treeOriginal"></app-tree-view>
                    </div>
                    <div [ngClass]="{'col-6': objAcc.Acc_SS.MOSSCasenum, 'col-12': !objAcc.Acc_SS.MOSSCasenum }" *ngIf="objTreeDataClone">
                        <h5 class="card-title">New Case Structure</h5>
                        <app-tree-view *ngIf="objAccClone" #treeViewNew [tabindex]="0"
                                       [blnEnabled]="true" [blnVerbose]="true" [treeData]="objTreeDataClone" strIdPrefix="treeNew"
                                       (selectionChanged)="OnTreeSelectionChanged($event)"></app-tree-view>
                    </div>
                </div>
            </div>

            <!-- Not covered by *ngIf="objAcc" as we want ViewChildren to be available immediately without waiting for the data model -->
            <div #divMarkFatal style="position:absolute; width:50%">
                <!-- blnExcludeFromBusinessRules prevents attempt to evaluate autofill rules before model object is provided (via selection
                    of a tree node). After the model object is provided, changes do result in evaluation of autofill rules -->
                <app-typeahead id="injury" typeAheadPosition="right" #selMarkFatal class="col-12 p-0" [blnHideSelectedOptionText]="true"
                               [objModel]="objPerson" strTableName="Occupants" strFieldName="Injury" [blnExcludeFromBusinessRules]="true">
                </app-typeahead>
            </div>

            <div class="col-2">
                <div class="form-group">
                    <button type="button" id="btnBackToCase" #btnBackToCase class="btn btn-dark fw-bold col-12" (click)="backToCaseOrCancel($event)">
                        <span *ngIf="blnCreatingNewCase">Cancel</span><span *ngIf="!blnCreatingNewCase">Back to Case</span>
                    </button>
                </div>
                <div class="form-group">
                    <button type="button" id="btnSaveCase" #btnSaveCase class="btn btn-success fw-bold col-12" (click)="saveCaseStructure($event)">Save Changes</button>
                </div>
                <br />
                <div class="form-group">
                    <button type="button" id="btnAddNonOcc" #btnAddNonOcc class="btn col-12"
                            [ngClass]="{'btn-primary fw-bold' : !btnAddNonOcc.disabled, 'btn-outline-dark' : btnAddNonOcc.disabled}"
                            (click)="addNonOccupant($event)" title="Hotkey /">
                        Add Non-Occupant (<span style="font-weight:bolder;">/</span>)
                    </button>
                </div>
                <div class="form-group">
                    <button type="button" id="btnDelNonOcc" #btnDelNonOcc class="btn col-12"
                            [ngClass]="{'btn-primary fw-bold' : !btnDelNonOcc.disabled, 'btn-outline-dark' : btnDelNonOcc.disabled}"
                            (click)="deleteNonOccupant($event)">
                        Delete Non-Occupant
                    </button>
                </div>
                <br />

                <div *ngIf="intMode != 256">
                    <div class="form-group">
                        <button type="button" id="btnAddVehicle" #btnAddVehicle class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnAddVehicle.disabled, 'btn-outline-dark' : btnAddVehicle.disabled}"
                                (click)="addVehicle($event)" title="Hotkey *">
                            Add Vehicle (<span style="font-weight:bolder;">*</span>)
                        </button>
                    </div>
                    <div class="form-group">
                        <button type="button" id="btnDelVehicle" #btnDelVehicle class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnDelVehicle.disabled, 'btn-outline-dark' : btnDelVehicle.disabled}"
                                (click)="deleteVehicle($event)">
                            Delete Vehicle
                        </button>
                    </div>
                    <br />
                </div>

                <div *ngIf="intMode == 256">
                    <div class="form-group">
                        <button type="button" id="btnStrikeAddVehicle" #btnStrikeAddVehicle class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnStrikeAddVehicle.disabled, 'btn-outline-dark' : btnStrikeAddVehicle.disabled}"
                                (click)="addVehicle($event,'S')" title="Hotkey *">
                            Add Striking Vehicle (<span style="font-weight:bolder;">*</span>)
                        </button>
                    </div>
                    <div class="form-group">
                        <button type="button" id="btnStrikeDelVehicle" #btnStrikeDelVehicle class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnStrikeDelVehicle.disabled, 'btn-outline-dark' : btnStrikeDelVehicle.disabled}"
                                (click)="deleteVehicle($event,'S')">
                            Delete Striking Vehicle
                        </button>
                    </div>
                    <br />
                    <div class="form-group">
                        <button type="button" id="btnSFRAddVehicle" #btnSFRAddVehicle class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnSFRAddVehicle.disabled, 'btn-outline-dark' : btnSFRAddVehicle.disabled}"
                                (click)="addVehicle($event,'F')" title="Hotkey *">
                            Add Struck Subject Vehicle (<span style="font-weight:bolder;">*</span>)
                        </button>
                    </div>
                    <div class="form-group">
                        <button type="button" id="btnSFRDelVehicle" #btnSFRDelVehicle class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnSFRDelVehicle.disabled, 'btn-outline-dark' : btnSFRDelVehicle.disabled}"
                                (click)="deleteVehicle($event,'F')">
                            Delete Struck Subject Vehicle
                        </button>
                    </div>
                    <br />
                    <div class="form-group">
                        <button type="button" id="btnOtherAddVehicle" #btnOtherAddVehicle class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnOtherAddVehicle.disabled, 'btn-outline-dark' : btnOtherAddVehicle.disabled}"
                                (click)="addVehicle($event,'O')" title="Hotkey *">
                            Add Other Vehicle (<span style="font-weight:bolder;">*</span>)
                        </button>
                    </div>
                    <div class="form-group">
                        <button type="button" id="btnOtherDelVehicle" #btnOtherDelVehicle class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnOtherDelVehicle.disabled, 'btn-outline-dark' : btnOtherDelVehicle.disabled}"
                                (click)="deleteVehicle($event,'O')">
                            Delete Other Vehicle
                        </button>
                    </div>
                    <br />
                </div>


                <div *ngIf="intMode != 256">
                    <div class="form-group">
                        <div class="input-group col-12 p-0">
                            <input id="txtPersonsToAdd" name="txtPersonsToAdd" #txtPersonsToAdd type="number"
                                   class="form-control col-md-6 col-lg-3 col-xl-2 text-center p-lg-0" [ngClass]="{'fw-bold' : !btnAddOccupant.disabled}"
                                   [disabled]="btnAddOccupant.disabled" (keydown)="txtPersonsToAdd_OnKeyDown($event)"
                                   [(ngModel)]="intPersonsToAdd" ngModelOptions="{ standalone: true }" maxlength="2" max="25" />
                            <div class="input-group-append col-md-12 col-lg-9 col-xl-10 p-0">
                                <button type="button" id="btnAddOccupant" #btnAddOccupant class="btn col-12"
                                        [ngClass]="{'btn-primary fw-bold' : !btnAddOccupant.disabled, 'btn-outline-dark' : btnAddOccupant.disabled}"
                                        (click)="addOccupant($event)" title="Hotkey +">
                                    Add Person (<span style="font-weight:bolder;">+</span>)
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" id="btnDelOccupant" #btnDelOccupant class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnDelOccupant.disabled, 'btn-outline-dark' : btnDelOccupant.disabled}"
                                (click)="deleteOccupant($event)">
                            Delete Person
                        </button>
                    </div>
                </div>

                <div *ngIf="intMode == 256">
                    <div class="form-group">
                        <div class="input-group col-12 p-0">
                            <input id="txtPersonsToAdd" name="txtPersonsToAdd" #txtPersonsToAdd type="number"
                                   class="form-control col-md-6 col-lg-3 col-xl-2 text-center p-lg-0" [ngClass]="{'fw-bold' : !btnAddOccupant.disabled}"
                                   [disabled]="btnAddOccupant.disabled" (keydown)="txtPersonsToAdd_OnKeyDown($event)"
                                   [(ngModel)]="intPersonsToAdd" ngModelOptions="{ standalone: true }" maxlength="2" max="25" />
                            <div class="input-group-append col-md-12 col-lg-9 col-xl-10 p-0">
                                <button type="button" id="btnAddOccupant" #btnAddOccupant class="btn col-12"
                                        [ngClass]="{'btn-primary fw-bold' : !btnAddOccupant.disabled, 'btn-outline-dark' : btnAddOccupant.disabled}"
                                        (click)="addOccupant($event)" title="Hotkey +">
                                    Add Occupant (<span style="font-weight:bolder;">+</span>)
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" id="btnDelOccupant" #btnDelOccupant class="btn col-12"
                                [ngClass]="{'btn-primary fw-bold' : !btnDelOccupant.disabled, 'btn-outline-dark' : btnDelOccupant.disabled}"
                                (click)="deleteOccupant($event)">
                            Delete Occupant
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>
