import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CaseService } from '../../../../services/case.service';
import { UtilService } from '../../../../services/util.service';
import { Acc } from '../../../../models/acc';
import { Veh } from '../../../../models/veh';
import { Occupants } from '../../../../models/occupants';
import { Element_Specify } from '../../../../models/element-specify';
import { TableFieldElements } from '../../../../models/table-field-elements';
import { DrpDownOptions } from '../../../../models/drp-down-options';
import { FormName, TableId } from '../../../../models/enums/app.enums';
import { TypeaheadComponent } from '../../../../components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

@Component({
    selector: 'app-vehicle-person-safety-devices',
    templateUrl: './vehicle-person-safety-devices.component.html',
    styleUrls: ['./vehicle-person-safety-devices.component.css']
})
export class VehiclePersonSafetyDevicesComponent extends BaseComponent implements OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_VehiclePersonSafetyDevicesComponent: string = null;
    accid: number;
    vehicleid: number;
    personid: number;

    acc: Acc;
    venicle: Veh;
    person: Occupants;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnInit() {
        super.ngOnInit();
        
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
            });
            this._route.parent.params.subscribe(params => {
                this.personid = + params['personid'];
                this.initData();
                super.ngOnInit();
            });
        } else {
            this.vehicleid = this.printVehNum;
            this.personid = this.personNum;
            this.accid = this.acc.AccID;
          //  this._sharedDataService.setVehicleID
            this.initData();
        }
    }

    initData() {
        this.venicle = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        this.person = this.acc.Veh.find(v => v.VNumber == this.vehicleid).Occupants.find(o => o.PNumber == this.personid);
    }

    ngOnDestroy() {
        //Do we need this.person.Restraint = null;????
        //this.person.Restraint = null;      
    }
}
