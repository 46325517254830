<div class="row {{printOnly ? 'd-none' : ''}}" #divContainer>
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto" *ngIf="!disableLabel && objMetaData">
        <app-label [inputName]="inputName" [inputMetaData]="objMetaData" [disableLabel]="disableLabel"></app-label>
    </div>

    <div class="col col-xs-12 col-sm-12 col-md-12 col-xl my-auto">
        <!--
        click$, focus$: Subject (A special type of Observable that allows values to be multicasted to many Observables. Subjects are like EventEmitters.)
        strTableName and strFieldName are fed to the customMin and customMax directives
        id must be specified for test scripts
        name must be specified for the input to participate in the form control hierarchy (useful for validation)
        -->
        <input *ngIf="blnIsNumber && blnAllowLeftMostZero"
               #txt type="text"
               [customMin]="intCustomMin" [customMax]="intCustomMax" [strTableName]="strTableName"
               [strFieldName]="strFieldName"
               [required]="blnIsRequired"
               [id]="inputName" [name]="inputName"
               class="form-control" [ngClass]="cssFieldCLass"
               [ngModel]
               [ngModelOptions]="objNgModelOptions"
               (click)="click$.next($event.target.value)"
               (focus)="handleFocus($event);"
               (blur)="onBlur($event.target.value)"
               (keydown)="handleKeydown($event)"
               (keyup)="handleKeyup($event)"
               [numeric] [numericType]="numType" />
        <input *ngIf="blnIsNumber && !blnAllowLeftMostZero"
               #txt type="text"
               [customMin]="intCustomMin" [customMax]="intCustomMax" [strTableName]="strTableName"
               [strFieldName]="strFieldName"
               [required]="blnIsRequired"
               [id]="inputName" [name]="inputName"
               class="form-control" [ngClass]="cssFieldCLass"
               [ngModel]="objCurrentValue"
               [ngModelOptions]="objNgModelOptions"
               (ngModelChange)="OnModelChange($event)"
               (click)="click$.next($event.target.value)"
               (focus)="handleFocus($event);"
               (blur)="onBlur($event.target.value)"
               (keydown)="handleKeydown($event)"
               (keyup)="handleKeyup($event)"
               [numeric] [numericType]="numType" />
        <!-- No leading zero pipe (DecimalPipe) -->
        <textarea *ngIf="!blnIsNumber"
                  #txt type="text" appRegexValidator [fieldName]="fieldName"
                  [customMin]="intCustomMin" [customMax]="intCustomMax" [strTableName]="strTableName" [strFieldName]="strFieldName"
                  [id]="inputName" [name]="inputName"
                  class="form-control" [ngClass]="cssFieldCLass"
                  [ngModel]="objCurrentValue"
                  [ngModelOptions]="objNgModelOptions"
                  (ngModelChange)="OnModelChange($event)"
                  (click)="click$.next($event.target.value)"
                  (focus)="handleFocus($event);"
                  (blur)="onBlur($event.target.value)"
                  (keydown)="handleKeydown($event)"
                  (keyup)="handleKeyup($event)"
                  [rows]="intTextAreaRows"
                  style="resize: none;"></textarea>
    </div>
    <app-edt-confidence-color #EDTConfidenceColor [strTableName]="strTableName"
                              [strFieldName]="strFieldName"
                              [selectedValue]="objCurrentValue"
                              [blnEnableEDTColor]="blnCheckStrengthCode">
    </app-edt-confidence-color>
</div>
<ng-container *ngIf="printOnly && intMode != intDisabledMode">
    <div class="print-only">
        <app-label *ngIf="!disableLabel && objMetaData" [inputName]="inputName" [printOnly]="true" [inputMetaData]="objMetaData" [disableLabel]="disableLabel"></app-label>
        <!--<label *ngIf="!showTextboxInSamePositionForPrintOnly && !clearFieldData" class="border field-size-text-field">{{objCurrentValue}} </label>-->

        <ng-container *ngIf="strFieldName == 'DeathState'">
            <textarea *ngIf="showTextboxInSamePositionForPrintOnly && !clearFieldData && objCurrentValue!='-1'"
                      type="text" class="form-control form-control-sm bg-white print-only-input"
                      [ngModel]="objCurrentValue  | number : '2.0-0' | replaceTextPipe : '-1' : ''" [ngModelOptions]="{standalone: true}"
                      readonly disabled="disabled"
                      #printTextArea></textarea>

            <textarea *ngIf="clearFieldData || objCurrentValue =='-1' " style="resize: none;"
                      type="text" class="form-control form-control-sm bg-white print-only-input"
                      readonly disabled="disabled"
                      #printTextArea></textarea>
        </ng-container>
        <ng-container *ngIf="strFieldName == 'DeathCertNumber'">

            <textarea *ngIf="showTextboxInSamePositionForPrintOnly && !clearFieldData && objCurrentValue!='-1'"
                      type="text" class="form-control form-control-sm bg-white print-only-input"
                      [ngModel]="objCurrentValue  | number : '6.0-0' | replaceTextPipe : ',' : ''" [ngModelOptions]="{standalone: true}"
                      readonly disabled="disabled"
                      #printTextArea></textarea>

            <textarea *ngIf="clearFieldData || objCurrentValue =='-1' " style="resize: none;"
                      type="text" class="form-control form-control-sm bg-white print-only-input"
                      readonly disabled="disabled"
                      #printTextArea></textarea>

        </ng-container>

        <ng-container *ngIf="strFieldName != 'DeathCertNumber' && strFieldName != 'DeathState'">
            <textarea *ngIf="!showTextboxInSamePositionForPrintOnly && !clearFieldData && objCurrentValue!='-1'"
                      type="text" class="form-control form-control-sm bg-white print-only-input"
                      [ngModel]="objCurrentValue  | replaceTextPipe : '-1' : ''" [ngModelOptions]="{standalone: true}"
                      readonly disabled="disabled"
                      #printTextArea></textarea>

            <!--<label *ngIf="showTextboxInSamePositionForPrintOnly && !clearFieldData" class="border field-size-in-sameposition-text-field">{{objCurrentValue }} </label>-->

            <textarea *ngIf="showTextboxInSamePositionForPrintOnly && !clearFieldData && objCurrentValue!='-1'"
                      type="text" class="form-control form-control-sm bg-white print-only-input"
                      [ngModel]="objCurrentValue" [ngModelOptions]="{standalone: true}"
                      readonly disabled="disabled"
                      #printTextArea></textarea>
            <!--<label *ngIf="clearFieldData" class="border field-size-in-sameposition-text-field">&nbsp;</label>-->
            <textarea *ngIf="clearFieldData || objCurrentValue =='-1' " style="resize: none;"
                      type="text" class="form-control form-control-sm bg-white print-only-input"
                      readonly disabled="disabled"
                      #printTextArea></textarea>
            <ng-container *ngIf="showAllDataListForPrintOnly">

                <div class="row">
                    <div class="col-12">
                        <ng-container class="container-fluid" *ngFor="let item of options">
                            <div class="row border-light border-bottom">
                                <div class="col">
                                    <ul class="list-group list-group-flush" *ngIf="item.intValue!=-1">
                                        <li class="list-group-item p-0" style="font-size:14px">
                                            <span>{{ item.strValue }}</span><span> - </span><span>{{item.strText}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
