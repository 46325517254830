import { Directive, Input, OnInit } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, ValidatorFn, FormControl, NG_VALIDATORS } from '@angular/forms';
import { UtilService } from 'src/app/services/util.service';

import { TableFieldElements } from './../../models/table-field-elements';

@Directive({
    selector: '[appRegexValidator][formControlName],[appRegexValidator][formControl],[appRegexValidator][ngModel]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: RegexValidatorDirective,
        multi: true
    }]
})


export class RegexValidatorDirective implements Validator, OnInit {

    @Input() fieldName: string;    

    fieldMetaData: TableFieldElements;
    fieldMetaDatas: TableFieldElements[];

    constructor(
        private _utilService: UtilService
    ) {      
    }

    async ngOnInit() {
        await this._utilService.metaDataToShare.subscribe(result => {
            this.fieldMetaDatas = result;
        });
    }

    validate(control: AbstractControl): { [key: string]: any } {
        //skip validation for a fields without fieldName param
        if (!this.fieldName) {
            return null;
        }

        if (control.value) {
            this.fieldMetaData = this.fieldMetaDatas.find(x => x.Field === this.fieldName);

            if (this.fieldMetaData) {              

                let isValid = new RegExp(this.fieldMetaData.FieldRegexPattern).test(control.value);
                if (isValid) {
                    return null;
                } else {
                    return control.value < 1 || isValid ? null : { 'regexValidation': false, 'validationMessage': this.fieldMetaData.ValidationMessage };
                }
            }
            return null;

        }
        return null;

    }

}

