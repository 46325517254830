import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, AfterContentInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
//rxjs
import { take, catchError } from 'rxjs/operators';
//services
import { CaseService } from '../../../services/case.service';
import { UtilService } from '../../../services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalService_CannedPopups } from 'src/app/services/modal-canned-popups.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
//models
import { Acc } from '../../../models/acc';
import { Non_Occupants } from '../../../models/non-occupants';
import { NonOcc_Bike } from './../../../models/non-occ-bike';
import { RBISWizardResults } from 'src/app/models/RBISWizardResults';
import { DrpDownOptions } from '../../../models/drp-down-options';
//components
import { TypeaheadComponent } from '../../../components/typeahead/typeahead.component';
//helper
import { BaseComponent } from 'src/app/helper/basecomponent';
import { PerBikeWizard } from 'src/app/models/pedbikewizard';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { Subscription } from 'rxjs';
import { ObjectUtil } from 'src/app/helper/objectUtil';

@Component({
    selector: 'app-non-occupant-bicyclist',
    templateUrl: './non-occupant-bicyclist.component.html',
    styleUrls: ['./non-occupant-bicyclist.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class NonOccupantBicyclistComponent extends BaseComponent implements OnInit, AfterViewInit, AfterContentInit {
    private _TypeScript_TypeGuard_NonOccupantBicyclistComponent: string = null;
    private sbsNonOccId: Subscription;

    accid: number;
    nonOccid: number;

    acc: Acc;
    nonOccupant: Non_Occupants;
    bicyclist: NonOcc_Bike;

    crashGroupBike: number;
    crashTypeExpanded: string = '';
    allBicycList: Array<NonOcc_Bike> = [];
    filteredBikeLookupData: Array<DrpDownOptions> = [];
    wizardOptions: Array<PerBikeWizard> = [];
   
    selectedWizardList: Array<PerBikeWizard> = [];
    @ViewChild('fnonOccbicyclist') objForm: NgForm;

    fieldList: Array<string> = ['BikeCrashType', 'BikeCrashLocation', 'BikePosition', 'BikeDirection', 'PBCAT_BikeCrashGroup']
    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    data: Acc;
    hasWizardFilled: boolean = false;
    @ViewChild('CrashType') _crashType: TypeaheadComponent;
    @ViewChild('CrashGroup') _crashGroup: TypeaheadComponent;
    @ViewChild('CrashLocation') _crashLocation: TypeaheadComponent;
    @ViewChild('BikePosition') _bikePosition: TypeaheadComponent;
    @ViewChild('BikeDirection') _bikeDirection: TypeaheadComponent;

    @ViewChild('schoolZone') _schoolZone: TypeaheadComponent;
    @ViewChild('crosswalk') _crosswalk: TypeaheadComponent;
    @ViewChild('sidewalk') _sidewalk: TypeaheadComponent;
    tableToFilter = ['PBCAT_BikeCrashLocation','PBCAT_BikePosition','PBCAT_BikeDirection','PBCAT_BikeCrashType','PBCAT_SchoolZone','PBCAT_CrossWalk','PBCAT_SideWalk']
    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _modalService_CannedPopups: ModalService_CannedPopups,
        private _sharedDataService: SharedDataService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {

        let hasInvalidaEntry = false;
        this.blnAlloweSave = true;

        if (!this._bikePosition.disabled && this.bicyclist.BikePosition == -1) {
            this._bikePosition.setIsInvalidClass(true);
            hasInvalidaEntry = true;
        } else {
            this._bikePosition.setIsInvalidClass(false);
        }

        if (!this._bikeDirection.disabled && this.bicyclist.BikeDirection == -1) {
            this._bikeDirection.setIsInvalidClass(true);
            hasInvalidaEntry = true;
        } else {
            this._bikeDirection.setIsInvalidClass(false);
        }
        if (hasInvalidaEntry) {
            this.blnAlloweSave = false;
            this._modalService.setMessage('The highlighted fields cannot be left blank.', 'M');
        } 
    }

    clearInvalidCss() {
        this._bikeDirection.setIsInvalidClass(false);
        this._bikePosition.setIsInvalidClass(false);
    }

    async ngOnInit() {
        super.ngOnInit();
        this.wizardOptions = await this._utilBaseService.getWizardOptions('bike');
        setTimeout(() => {
            this.loadFormData();
        }, 100);
        if (this.printNonOccupantNum == 0) {
            this._route.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
        }
        else {
            this.accid = this.acc.AccID;
        }

        if (this.sbsNonOccId) {
            this.sbsNonOccId.unsubscribe();
        }

        if (this.printNonOccupantNum == 0) {
            this.sbsNonOccId = this._route.parent.params.subscribe(params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
            //this.getCrashGroupType();
            
        }

       
    }

    FormLoad() {
        this.data = this.acc;
        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);
        //console.log('NonOccupant from BicyclistComponent', this.nonOccupant);
       // if(this.printNonOccupantNum ==0) this.getCrashGroupType();
        this.bicyclist = this.nonOccupant.NonOcc_Bike;

        //example of binding disabled value
        //this.bicyclist.CrashType = 112;
        this.crashGroupBike = -1;

        //Setting empty Pedestrian after landing to the page
        this.setEmptyBicyclist(this.nonOccupant);

        this.getBikeWizardJSON();
        this.crashTypeExpended();

        if (!this.nonOccupant.NonOcc_Bike) {
            this.nonOccupant.NonOcc_Bike = ObjectUtil.InstantiateNonOccBike(this.nonOccupant);
        }

        this.listAvailableExistingBikeInfo();
        setTimeout(() => {
            this.enabledFieldList(this.nonOccupant.NonOcc_Bike.CrashType, this.nonOccupant.NonOcc_Bike.CrashLocation);
        }, 30);
     
        this.initBaseComponent();
    }

    getCrashGroupType() {
        if (this.nonOccupant.NonOcc_Bike == null) {
            this.nonOccupant.NonOcc_Bike = { _TypeScript_TypeGuard_NonOcc_Bike: null } as NonOcc_Bike;
            this.nonOccupant.NonOcc_Bike.AccID = this.accid;
            this.nonOccupant.NonOcc_Bike.PNumber = this.nonOccid;
        } else {
            if (this.nonOccupant.NonOcc_Bike && this.nonOccupant.NonOcc_Bike.CrashType) {
                const _groupNumber = this.wizardOptions.find(x => x.Value == this.nonOccupant.NonOcc_Bike.CrashType.toString())
                if (_groupNumber != null) {
                    this.nonOccupant.NonOcc_Bike.CrashGroup = parseInt(_groupNumber.GroupValue);
                } else {
                   
                }
            }
        }
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    initBaseComponent() {
        setTimeout(() => {
            super.focusFirstOrBlankOrViolatedElement();
        }, 50);
    }

    setObjectValue($event: DrpDownOptions, fieldName: string) {
        super.setObjectValue($event, fieldName, this.nonOccupant);
    }

    setEmptyBicyclist(nonOccupant) {
        if ((nonOccupant.PType === 6 || nonOccupant.PType === 7) && !nonOccupant.NonOcc_Bike) {
            this.nonOccupant.NonOcc_Bike = this.bicyclist;
        }
        console.log("Bicyclist Empty Object: ", this.nonOccupant.NonOcc_Bike);
    }

    ngOnDestroy() {

    }

    onBikeWizardClick() {
        this._modalService_CannedPopups.wizard('bike', this.selectedWizardList).pipe(
            take(1)
        ).subscribe(result => {
            console.log(result);

            if (result == null) {
                // this.selectedWizardList = result;
                return;
            } else {
                this.fillBoxDataFromWizard(result);
                this.clearInvalidCss();
            }
        })
    }

    listAvailableExistingBikeInfo() {
        if (this.data == null) return;
        let countBike = 0;
        this.allBicycList = [];
        this.data.Non_Occupants.forEach((item) => {
            if (item.NonOcc_Bike != null && Object.keys(item.NonOcc_Bike).length != 0 && item.NonOcc_Bike.PNumber != this.nonOccid) {
                this.allBicycList.push(item.NonOcc_Bike);
                countBike = countBike + 1;
            }
        })
    }

    async onCopyBike() {
        const result = await this._modalService_CannedPopups.copyExistingBikeData(this.allBicycList, this.filteredBikeLookupData).toPromise();
        if (result != 'undefined' && result != null) {
            this.disabledFields();
            this.copyExistingItem(result);
            this.objForm.form.markAsDirty();
        }
    }

    copyExistingItem(item: NonOcc_Bike) {
        this.nonOccupant.NonOcc_Bike.PNumber = this.nonOccid;
        this.nonOccupant.NonOcc_Bike.CrashType = item.CrashType
        this.nonOccupant.NonOcc_Bike.BikeDirection = item.BikeDirection
        this.nonOccupant.NonOcc_Bike.BikePosition = item.BikePosition
        this.nonOccupant.NonOcc_Bike.CrashLocation = item.CrashLocation
        this.nonOccupant.NonOcc_Bike.Crosswalk = item.Crosswalk
        this.nonOccupant.NonOcc_Bike.PNumber = item.PNumber
        this.nonOccupant.NonOcc_Bike.SchoolZone = item.SchoolZone
        this.nonOccupant.NonOcc_Bike.Sidewalk = item.Sidewalk
        this.nonOccupant.NonOcc_Bike.CrashGroup = item.CrashGroup;

        this.nonOccupant.NonOcc_Bike.PNumber = this.nonOccid;

        //adding additional value;

        //adding additional value;
        if (this.nonOccupant.NonOcc_Bike.SchoolZone)
            this._schoolZone.setCurrentValue(this.nonOccupant.NonOcc_Bike.SchoolZone.toString())
        if (this.nonOccupant.NonOcc_Bike.Sidewalk)
            this._sidewalk.setCurrentValue(this.nonOccupant.NonOcc_Bike.Sidewalk.toString())
        if (this.nonOccupant.NonOcc_Bike.Crosswalk)
            this._crosswalk.setCurrentValue(this.nonOccupant.NonOcc_Bike.Crosswalk.toString())


        this.crashTypeExpanded = '';
        this.getCrashGroupType();
        this.crashTypeExpended();
        this.enabledFieldList(this.nonOccupant.NonOcc_Bike.CrashType, this.nonOccupant.NonOcc_Bike.CrashLocation);
        this.setData();

        let _oldWizard = this.data.RBISWizardResults.find(x => x.ACCID == this.acc.AccID && x.PNUMBER == item.PNumber);
        if (_oldWizard) {
            this.setBikeWizardJSON(_oldWizard.JSONOBJ, true);
        } else {
            this.disabledFields();
            this.hasWizardFilled = false;
            this.selectedWizardList = [];
        }
    }

    getTitle(fieldValue: any, fieldType: any, index: number): string {
        if (fieldValue == undefined) return '-';
        let screenName = '';
        let item: any;
        let options = this.wizardOptions;
        item = options.find(x => x.Value == fieldValue && x.ScreenTitle == fieldType);
        if (item == null || item == undefined) screenName = '-'; else screenName = item.Value + ' (' + item.Label + ')';
        if (item != null && item != undefined && item.Value == item.Label) screenName = item.Value;
        return screenName;
    }

    /**
     * Creates subscription for DEFS data and when DEFS data is available, populates this.filteredBikeLookupData
     **/
    private loadFormData(): void {
        if (this.filteredBikeLookupData.length > 0) //TODO: remove this, if we don't need to refresh DEFS data, just don't call this function
            return;

        this._utilService.formDrpDownOptionShare.subscribe(((arrOptionsAll: DrpDownOptions[]) => {
            this.filteredBikeLookupData = [];

            if (arrOptionsAll) {
                arrOptionsAll = arrOptionsAll.filter(x => this.tableToFilter.includes(x.tblName));
                arrOptionsAll.forEach(item => {
                    this.filteredBikeLookupData.push(item);
                });
            }
        }).bind(this)).unsubscribe();
    }

    fillBoxDataFromWizard(result: any) {
        if (!result) return;

        if (this.nonOccupant.NonOcc_Bike == null) {
            this.nonOccupant.NonOcc_Bike = { _TypeScript_TypeGuard_NonOcc_Bike: null } as NonOcc_Bike;
            this.nonOccupant.NonOcc_Bike.AccID = this.accid;
            this.nonOccupant.NonOcc_Bike.PNumber = this.nonOccid;
        }

        result.forEach((item) => {
            switch (item.ScreenTitle) {
                case 'crash_type':
                    this.nonOccupant.NonOcc_Bike.CrashType = parseInt(item.Value);
                    if (item.GroupValue != null && item.GroupValue != '') {
                        this.crashGroupBike = parseInt(item.GroupValue);
                        this.nonOccupant.NonOcc_Bike.CrashGroup = parseInt(item.GroupValue);
                    }
                    console.log('aaa: ', item.GroupValue);
                    break;
                case 'crash_location':
                    this.nonOccupant.NonOcc_Bike.CrashLocation = parseInt(item.Value);
                    if (this.nonOccupant.NonOcc_Bike.CrashLocation == 9) {
                        this.nonOccupant.NonOcc_Bike.CrashType = 980;
                        this.nonOccupant.NonOcc_Bike.CrashGroup = 990;
                        this.nonOccupant.NonOcc_Bike.BikePosition = 9
                        this.nonOccupant.NonOcc_Bike.BikeDirection = 9
                    }
                    break;
                case 'bicyclist_position':
                    this.nonOccupant.NonOcc_Bike.BikePosition = parseInt(item.Value);
                    break;
                case 'bicyclist_direction':
                    this.nonOccupant.NonOcc_Bike.BikeDirection = parseInt(item.Value);
                    break;

            }
        })

        if (this.nonOccupant.NonOcc_Bike && this.nonOccupant.NonOcc_Bike.CrashLocation == 4) {
            this.nonOccupant.NonOcc_Bike.CrashType = 910;
            this.nonOccupant.NonOcc_Bike.CrashGroup = 910;
            this.nonOccupant.NonOcc_Bike.BikeDirection = 9
        }

        this.crashTypeExpended();
        this.enabledFieldList(this.nonOccupant.NonOcc_Bike.CrashType, this.nonOccupant.NonOcc_Bike.CrashLocation);
        console.log(this.nonOccupant.NonOcc_Bike.CrashLocation + ' ' + this.nonOccupant.NonOcc_Bike.BikePosition + ' ' + this.nonOccupant.NonOcc_Bike.BikeDirection + ' ' + this.nonOccupant.NonOcc_Bike.CrashType + ' ' + this.crashGroupBike)
        this.setData();
        this.setBikeWizardJSON(result);
        this.objForm.form.markAsDirty();
    }

    setData() {
        if (this.nonOccupant.NonOcc_Bike.CrashType) this._crashType.setCurrentValue(this.nonOccupant.NonOcc_Bike.CrashType.toString())
        if (this.nonOccupant.NonOcc_Bike.CrashGroup) this._crashGroup.setCurrentValue(this.nonOccupant.NonOcc_Bike.CrashGroup.toString())
        if (this.nonOccupant.NonOcc_Bike.CrashLocation) this._crashLocation.setCurrentValue(this.nonOccupant.NonOcc_Bike.CrashLocation.toString())
        if (this.nonOccupant.NonOcc_Bike.BikePosition) this._bikePosition.setCurrentValue(this.nonOccupant.NonOcc_Bike.BikePosition.toString())
        if (this.nonOccupant.NonOcc_Bike.BikeDirection) this._bikeDirection.setCurrentValue(this.nonOccupant.NonOcc_Bike.BikeDirection.toString())
    }
    crashTypeExpended() {

        if (this.nonOccupant.NonOcc_Bike.CrashLocation == undefined || this.nonOccupant.NonOcc_Bike.CrashLocation == -1) return;
        this.crashTypeExpanded = this.nonOccupant.NonOcc_Bike.CrashLocation + '' + this.nonOccupant.NonOcc_Bike.BikePosition + '' + this.nonOccupant.NonOcc_Bike.BikeDirection + '' + this.nonOccupant.NonOcc_Bike.CrashType;
    }

    disabledFieldList() {

        console.log('i am emptty', this.typeaheads);
        this.fieldList.forEach(fieldName => {
            //  this.enableOrDisableField(fieldName, true);
        })
    }

    enabledFieldList(crashType, crashLocation) {
        if (this.nonOccupant && this.nonOccupant.NonOcc_Bike) {
            if (crashType != undefined && crashType != -1) {
                this.enableOrDisableField(this._bikePosition, false);

                if (crashLocation != 4) {
                    this.enableOrDisableField(this._bikeDirection, false);
                } else {
                    this.enableOrDisableField(this._bikeDirection, true);
                }

            }
        }
    }
    enableOrDisableField(fieldName: TypeaheadComponent, disable: boolean = false) {

        fieldName.disableOrEnableComponent(disable);
    }

    setBikeWizardJSON(result: any, fromCopy: boolean = false) {
        let findOldWizard = this.data.RBISWizardResults.find(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
        if (!findOldWizard) {
            let rBISWizardResults: RBISWizardResults = { _TypeScript_TypeGuard_RBISWizardResults: null } as RBISWizardResults;
            rBISWizardResults.ACCID = this.accid;
            rBISWizardResults.PNUMBER = this.nonOccid;
            rBISWizardResults.JSONOBJ = fromCopy ? result : JSON.stringify(result);
            rBISWizardResults.WizardType = "Bike"
            this.data.RBISWizardResults.push(rBISWizardResults)
            this.getBikeWizardJSON();
        } else {
            let index = this.data.RBISWizardResults.findIndex(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
            findOldWizard.JSONOBJ = fromCopy ? result : JSON.stringify(result);
            findOldWizard.WizardType = "Bike"
            this.data.RBISWizardResults[index] = findOldWizard;
            this.getBikeWizardJSON();
        }

    }

    getSelectedLabel(index: number) {
        let item = this.selectedWizardList[index];
        if (item.Value != null && item.Value != '') return item.Value + ' - ' + item.Label
        else if (item.Value == null && item.Label == '') return item.SectionTitle
        else if (item.Value == null && item.Label != '') return item.Label
    }
    getBikeWizardJSON() {
        let findOldWizard = this.data.RBISWizardResults.find(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
        if (findOldWizard && findOldWizard.JSONOBJ) {
            if (this.nonOccupant.NonOcc_Bike.CrashType > 0) {
                this.selectedWizardList = JSON.parse(findOldWizard.JSONOBJ);
                this.hasWizardFilled = true;
            } else {
                const index = this.data.RBISWizardResults.findIndex(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
                this.data.RBISWizardResults.splice(index, 1);
            }
        } else {
            this.selectedWizardList = [];
            this.hasWizardFilled = false;
        }
    }

    handleKeyup(event: KeyboardEvent) {
        if (event.code == "F9" || event.code == "Minus") {
            document.getElementById("btnBicyclist_Copy").click();
        }
    }

    ngAfterContentInit() {

    }

    fieldValueChange(field, event, obj) {

        if (event.strValue != '-1' && this.wizardOptions.length > 0 && this.data.RBISWizardResults.length > 0) {
            this.findArraAndSetValue(field, event)
            this.crashTypeExpended();
        }
    }

    findArraAndSetValue(field, event) {
        let selectedOption: any = null;
        let value = '0' + event.strValue;
        let wizardIndex = this.data.RBISWizardResults.findIndex(x => x.ACCID == this.acc.AccID && x.PNUMBER == this.nonOccid);
        if (field == 'BikePosition' && wizardIndex!=-1) {
            selectedOption = this.wizardOptions.find(x => x.Value == value && x.ScreenTitle == 'bicyclist_position')
            const postionIndex = this.selectedWizardList.findIndex(x => x.ScreenTitle == 'bicyclist_position');
            this.selectedWizardList[postionIndex] = selectedOption;
            this.data.RBISWizardResults[wizardIndex].JSONOBJ = JSON.stringify(this.selectedWizardList);
        }
        if (field == 'BikeDirection' && wizardIndex!=-1) {
            selectedOption = this.wizardOptions.find(x => x.Value == value && x.ScreenTitle == 'bicyclist_direction')
            const postionIndex = this.selectedWizardList.findIndex(x => x.ScreenTitle == 'bicyclist_direction');
            this.selectedWizardList[postionIndex] = selectedOption;
            this.data.RBISWizardResults[wizardIndex].JSONOBJ = JSON.stringify(this.selectedWizardList) ;
        }
    }

    disabledFields() {
        this.enableOrDisableField(this._bikePosition, true);
        this.enableOrDisableField(this._bikeDirection, true);
    }
}
