<form novalidate #fvvn="ngForm">
    <div *ngIf="veh && intMode" id="vehicle-notes" class="pt-2">
        <div [ngClass]="{'row' : printOnly === true}">

            <div #typAhdEDREquipObtn [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode ==128">
                <div class="form-group pl-2">
                    <app-typeahead id="EDREquipObtn" name="EDREquipObtn" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_SS" strTableName="Veh_SS" strFieldName="EDREquipObtn"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdAvoidEquipAvail [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode ==128">
                <div class="form-group pl-2">
                    <app-multiselect id="AvoidEquipAvail"
                                     name="AvoidEquipAvail"
                                     strTableName="Veh_SSAvoidEquipAvail"
                                     strFieldName="AvoidEquipAvail"
                                     intSeqNum="1"
                                     multiSelectPosition="right-top"
                                     [objModel]="veh.Veh_SSAvoidEquipAvail"
                                     [objTemplateModel]="objVehAvoidEquipAvailTemplate"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="1">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}" *ngIf="intMode ==128">
                <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                    <div class="form-group pl-2">
                        <app-textfield id="AvoidEquipNotes" [disableLabel]="false"
                                       [blnIsNumber]="false" [intTextAreaRows]="10"
                                       [objModel]="veh.Veh_SS" [(objCurrentValue)]="veh.Veh_SS.AvoidEquipNotes"
                                       [blnFormatWithLeading]="false"
                                       strTableName="Veh_SS" strFieldName="AvoidEquipNotes"
                                       [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                        </app-textfield>
                    </div>
                </div>
            </div>

            <div #typAhdEDREquip [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode ==64">
                <div class="form-group pl-2">
                    <app-typeahead id="vehEDREquip" name="vehEDREquip" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_MTSS" strTableName="Veh_MTSS" strFieldName="EDREquip"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div #typAhdAvoidEquipAvail [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode ==64">
                <div class="form-group pl-2">
                    <app-multiselect id="vehAvoidEquipAvail"
                                     name="vehAvoidEquipAvail"
                                     strTableName="Veh_SSAvoidEquipAvail"
                                     strFieldName="AvoidEquipAvail"
                                     intSeqNum="1"
                                     multiSelectPosition="right-top"
                                     [objModel]="veh.Veh_SSAvoidEquipAvail"
                                     [objTemplateModel]="objVehAvoidEquipAvailTemplate"
                                     [showAllDataListForPrintOnly]="false"
                                     [printOnly]="printOnly" [clearFieldData]="clearFieldData" [intDisabledMode]="1">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}" *ngIf="intMode ==64">
                <div class="form-group pl-2">
                    <app-textfield id="vehAvoidEquipAssmntNotes" [disableLabel]="false"
                                   [blnIsNumber]="false" [intTextAreaRows]="10"
                                   [objModel]="veh.Veh_MTSS" [(objCurrentValue)]="veh.Veh_MTSS.AvoidEquipAssmntNotes"
                                   [blnFormatWithLeading]="false"
                                   strTableName="Veh_MTSS" strFieldName="AvoidEquipAssmntNotes"
                                   [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                    </app-textfield>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode ==64">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' : 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-12']">
                            <div class="pl-2">
                                <app-label label="ImpactSpeed" [printOnly]="printOnly" inputName="ImpactSpeed"></app-label>
                            </div>
                            <span class="my-auto" *ngIf="!printOnly">
                                <app-speed-calculator *ngIf="!blnReadOnly"
                                                      [objVeh]="veh"
                                                      objToUpdate="ImpactSpeed"
                                                      (setSpeedEvent)="setImpactSpeed($event)">
                                </app-speed-calculator>
                            </span>
                        </div>

                        <div #typAhdImpactSpeed [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col col-xs-12 col-sm-12 col-md-12 col-xl-12']">
                            <div class="pl-2">
                                <app-typeahead id="vehImpactSpeed" name="vehImpactSpeed" typeAheadPosition="right-top"
                                               [disableLabel]="true"
                                               [objModel]="veh.Veh_MTSS"
                                               strTableName="Veh_MTSS" strFieldName="ImpactSpeed"
                                               [blnAllowFreeText]="true"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div #typAhdSpdDistDataSrc [ngClass]="{'col-6 border border-dark' : printOnly === true}" *ngIf="intMode ==64">
                <div class="form-group pl-2">
                    <app-typeahead id="vehSpdDistDataSrc" name="vehSpdDistDataSrc" typeAheadPosition="right-top"
                                   [objModel]="veh.Veh_MTSS" strTableName="Veh_MTSS" strFieldName="SpdDistDataSrc"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}" *ngIf="intMode ==64">
                <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                    <div class="form-group pl-2">
                        <app-textfield id="VehNotes" [disableLabel]="false"
                                       [blnIsNumber]="false" [intTextAreaRows]="10"
                                       [objModel]="veh" [(objCurrentValue)]="veh.Notes"
                                       [blnFormatWithLeading]="false"
                                       strTableName="Veh" strFieldName="Notes"
                                       [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                        </app-textfield>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}" *ngIf="intMode ==128">
                    <div class="form-group pl-2">
                        <app-textfield id="VehNotes" [disableLabel]="false"
                                       [blnIsNumber]="false" [intTextAreaRows]="10"
                                       [objModel]="veh" [(objCurrentValue)]="veh.Notes"
                                       [blnFormatWithLeading]="false"
                                       strTableName="Veh" strFieldName="Notes"
                                       [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                        </app-textfield>
                </div>
            </div>

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}" *ngIf="intMode ==256">
                    <div class="form-group pl-2">
                        <app-textfield id="VehNotes" [disableLabel]="false"
                                       [blnIsNumber]="false" [intTextAreaRows]="10"
                                       [objModel]="veh.Veh_SS" [(objCurrentValue)]="veh.Veh_SS.OthVehNotes"
                                       [blnFormatWithLeading]="false"
                                       strTableName="Veh_SS" strFieldName="OthVehNotes"
                                       [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                        </app-textfield>
                </div>
            </div>
           
        </div>
    </div>
</form>
