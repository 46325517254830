<form novalidate #fvpo="ngForm">
    <div class="pt-2" *ngIf="preCrash">
        <div [ngClass]="{'row' : printOnly === true}">

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="ShoulderSurfaceT" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="ShoulderSurfaceT"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>

            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="ShoulderWidth" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="ShoulderWidth"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>-->

            <div [ngClass]="{'col-6 border border-dark ' : printOnly === true}">
                <div class="form-group">
                    <div class="row">
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-label class="my-auto" inputName="ShoulderWidth" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-distance-calculator #DtectnDistFrPedElement [objName]="'ShoulderWidth'" (submitDistanceValue)="setDistanceValue($event)" *ngIf="!blnReadOnly" [hideSubmitButton]="true"></app-modals-distance-calculator>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="[!printOnly ? 'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 my-auto']">
                            <div class="pl-2">
                                <app-typeahead id="ShoulderWidth" name="ShoulderWidth" typeAheadPosition="right-top"
                                               [objModel]="preCrash.PreCrash_MTSS" strTableName="PreCrash_MTSS" strFieldName="ShoulderWidth"
                                               [printOnly]="printOnly"
                                               [clearFieldData]="clearFieldData"
                                               disableLabel="true" [showAllDataListForPrintOnly]="false">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RSInitTravLane" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="RSInitTravLane"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="RSRoad" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="RSRoad"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="LineTypeRight" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="LineTypeRight"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="LineTypeLeft" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="LineTypeLeft"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="RoadwayRF"
                                     multiSelectPosition="right-top"
                                     strTableName="RRF_MTSS"
                                     strFieldName="RRF"
                                     [objModel]="preCrash.Veh.RRF_MTSS"
                                     [objTemplateModel]="objRoadwayRFTemplate"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="WeatherRF"
                                     multiSelectPosition="right-top"
                                     strTableName="WRF_MTSS"
                                     strFieldName="WRF"
                                     [objModel]="preCrash.Veh.WRF_MTSS"
                                     [objTemplateModel]="objWeatherRFTemplate"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="OtherEF"
                                     multiSelectPosition="right-top"
                                     strTableName="OEF_MTSS"
                                     strFieldName="OEF"
                                     [objModel]="preCrash.Veh.OEF_MTSS"
                                     [objTemplateModel]="objOtherEFTemplate"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="TrafficFlowIF"
                                     multiSelectPosition="right-top"
                                     strTableName="TFIF_MTSS"
                                     strFieldName="TFIF"
                                     [objModel]="preCrash.Veh.TFIF_MTSS"
                                     [objTemplateModel]="objTrafficFlowIFTemplate"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="DriverFatigue" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="DriverFatigue"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="DriIllness" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="DriIllness"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>

            <!--<div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="PreFHarmEventManeuver" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="PreFHarmEventManeuver"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>-->
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-multiselect id="PreFHarmEventManeuver"
                                     multiSelectPosition="right-top"
                                     strTableName="PreFHEMnvr_SS"
                                     strFieldName="PreFHarmEventManeuver"
                                     [objModel]="preCrash.Veh.PreFHEMnvr_SS"
                                     [objTemplateModel]="objPreCrashPreFHEMnvrTemplate"
                                     [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                     [printOnly]="printOnly"
                                     [showAllDataListForPrintOnly]="false"
                                     [clearFieldData]="clearFieldData">
                    </app-multiselect>
                </div>
            </div>

            <!--<div  *ngIf="intMode == 64" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="CritReasonCritEvent" typeAheadPosition="right-top"
                                   [objModel]="preCrash.PreCrash_MTSS"
                                   strTableName="PreCrash_MTSS" strFieldName="CritReasonCritEvent"
                                   [objOtherSpecifyTemplate]="objInstanceElementSpecify"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [showAllDataListForPrintOnly]="false">
                    </app-typeahead>
                </div>
            </div>-->

            <div [ngClass]="{'col-12 border border-dark' : printOnly === true}">
                <div class="form-group mb-0">
                    <div class="px-2">
                        <app-textfield id="PrecrashNotes" [disableLabel]="false"
                                       [blnIsNumber]="false" [intTextAreaRows]="10"
                                       [objModel]="preCrash.PreCrash_MTSS" [(objCurrentValue)]="preCrash.PreCrash_MTSS.PrecrashNotes"
                                       [blnFormatWithLeading]="false"
                                       strTableName="PreCrash_MTSS" strFieldName="PrecrashNotes"
                                       [printOnly]="printOnly" [clearFieldData]="clearFieldData">
                        </app-textfield>
                    </div>
                </div>
            </div>

        </div>
    </div>
</form>


