import { Component, OnInit, ViewContainerRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { NgbActiveModal, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { Acc } from 'src/app/models/acc';
import { CrashEvents } from 'src/app/models/crash-events';
import { catchError } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { CrashTypeWizardModel } from 'src/app/models/CrashTypeWizard';

function hasOwnProperty<X extends {}, Y extends PropertyKey>
    (obj: X, prop: Y): obj is X & Record<Y, unknown> {
    return obj.hasOwnProperty(prop)
}

@Component({
  selector: 'app-crash-type',
  templateUrl: './crash-type.component.html',
    styleUrls: ['./crash-type.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CrashTypeComponent implements OnInit {

    currentOptions: Array<CrashTypeWizardModel> = [];
    wizardOptions: Array<CrashTypeWizardModel> = [];
    selectedOptions: Array<CrashTypeWizardModel> = [];
    selectedOldOptions: Array<any> = [];
    parentId: string = '';
    parentItem: any;
    previousParent: any;
    title: string = '';
    header: string = '';
    optionType: string = 'radio';
    backSelected: string = '';
    showFinalScreen: boolean = false;
    lastItem: any;
    showBack: boolean = false;
    optionCount: number = 0;
    isFirst: boolean = true;
    accid: number;
    crashid: number;
    acc: Acc;
    finalSelectedOptions: Array<any> = [];
    multipleImagesInLastWindow: Array<any> = [];
    firstRowList: Array<any> = [];
    dropDownOptions: Array<any> = [
        { value: 1, selected: true, isFirst: true },
        { value: 2, selected: false, isFirst: true  },
        { value: 1, selected: false, isFirst: false  },
        { value: 2, selected: true, isFirst: false  }
    ];
    savedItems: Array<any> = [
        { screenName: 'a', value: 'aaa' }
    ];
    showNext: boolean = false;
    selectedValue: string = '';
    numberOfVechicle: number = 1;

    vec1OrgValue: any = '-1';
    vec2OrgValue: any = '-1';
    vec1Value: any='-1';
    vec2Value: any = '-1';
    vecNum1: number = 0;
    vecNum2: number = 0;
    currentIndex: number = 0;
    harmfulVec: Array<number> = [];
    crashEvents: Array<CrashEvents> = [];
    fhEvents: Array<CrashEvents> = []; //TODO: there is only one "first" harmful event in the accident, this shouldn't be an array
    message: string = '';
    innerPopup: NgbModalRef;
    @ViewChild('alertMessage', { static: false }) alertMessage: ViewContainerRef;
    harmfulEventCodes: Array<number> = [];

    constructor(
        private _utilService: UtilService,
        public activeModal: NgbActiveModal,
        private localModal: NgbModal
    ) { }

    public ngOnInit(): void {
        if (this.acc && this.acc.CrashEvents.length>0) {
            this.crashEvents = this.acc.CrashEvents;
        }

        this._utilService.setSeqEventsList();

        this.setHarmfulEventsIds();
       
        if (this.parentId == '') {

            this._utilService.getListOfCrashWizard.subscribe(options => {
               
                this.wizardOptions = [];
                 options.forEach((item, index) => {
                    if (index == 0) {
                        this.parentItem = item;
                       }
                    this.wizardOptions.push(item);
                    if (index == options.length - 1) {
                        this.GetFheInfo();
                        this.prepareDropDown();
                        this.setVechicleNumber();
                        this.prepareCurrentStep();
                    }
                });
            }, (err) => {
                console.log(err);
                })
        }
    }

    crashPathFinder(firstVehicle: number, secondVehicle: number) {

     
        let _firstVeh = this.acc.Veh.find(x => x.VNumber == firstVehicle);
        let _secondVeh = this.acc.Veh.find(x => x.VNumber == secondVehicle);

        if (_firstVeh && _firstVeh.PreCrash) {
            if (_firstVeh.PreCrash.CrashType) {
                this.vec1OrgValue= _firstVeh.PreCrash.CrashType
                this.vec1Value = _firstVeh.PreCrash.CrashType
            }
            this.vecNum1 = firstVehicle
        }

        if (_secondVeh && _secondVeh.PreCrash) {
            if (_secondVeh.PreCrash.CrashType) {
                this.vec2OrgValue = _secondVeh.PreCrash.CrashType
                this.vec2Value = _secondVeh.PreCrash.CrashType
            }
            this.vecNum2 = secondVehicle;
        }

        if (this.vec1Value == -1 && this.vec2Value==-1) {
            return;
        }
        if (this.selectedOldOptions.length != 0) return;
        this.selectedOldOptions = [];

        let lastOption = this.wizardOptions.find(x => x.Value == this.vec1Value && x.Type == 'crash_type')
        if (lastOption.Condition == 'hide') {
            let _notNullList = this.wizardOptions.filter(x => x.Condition != null && x.Condition != 'hide')
            let _notNullGroupValueList = _notNullList.filter(x => x.GroupValue != null && x.GroupValue != '')
            if (_notNullGroupValueList) {
              let  lastOption1 = _notNullGroupValueList.find(x => x.GroupValue.includes(lastOption.Value))
                if (lastOption1 == undefined)
                    lastOption = _notNullList.find(x => x.Condition.includes(lastOption.Value))
            } else {
                lastOption = _notNullList.find(x => x.Condition.includes(lastOption.Value))
            }
        }

        let secondOption = this.wizardOptions.find(x => x.Value == lastOption.ConfigCode && x.Type =='config');
        let firstOption = this.wizardOptions.find(x => x.Id == parseInt(secondOption.Parent));

        this.selectedOldOptions.push(firstOption)
        this.selectedOldOptions.push(secondOption)
        this.selectedOldOptions.push(lastOption)
        this.selectedOldOptions.push({ label: 'vechicle', vecNum1: this.vecNum1, vecNum2: this.vecNum2, vechicle1Value: this.vec1Value, vechicle2Value: this.vec2Value });
    }

    private GetFheInfo(): void {
        let totalVehicle = this.acc.Veh.length;
        this.fhEvents = [];

        this.crashEvents.forEach((objCrashEvent: CrashEvents, index) => {
            if (objCrashEvent.SOE == this.acc.FHEvent &&
                this.harmfulEventCodes.includes(objCrashEvent.SOE)) { //Sanity check: First Harmful Event (FHEvent) should be in list of harmful event codes

                if (objCrashEvent.VNumber1 > 0 &&
                    objCrashEvent.VNumber2 > 0 &&
                    totalVehicle != 1) { //Sanity check: CrashEvents.VNumber2 cannot be populated when there is only 1 vehicle involved
                    this.numberOfVechicle = 2;
                    if (this.fhEvents.length == 0) { //Sanity check: There is only one "first" harmful event
                        this.fhEvents.push(objCrashEvent);
                        this.crashPathFinder(objCrashEvent.VNumber1, objCrashEvent.VNumber2);
                    }
                }
                else if ((objCrashEvent.VNumber1 > 0 || objCrashEvent.VNumber2 > 0) && totalVehicle == 1) {  //Moh- Add code for single vehicle crash
                    this.numberOfVechicle = 1;
                    if (this.fhEvents.length == 0) { //Sanity check: There is only one "first" harmful event
                        this.fhEvents.push(objCrashEvent);
                        this.crashPathFinder(objCrashEvent.VNumber1, objCrashEvent.VNumber2);
                    }
                }
                else { //Else leave numberOfVechicle with default value of 1
                    if (this.fhEvents.length == 0) { //Sanity check: There is only one "first" harmful event
                        this.fhEvents.push(objCrashEvent);
                    }
                }
            }
        });
    }

    reserveIndex() {
        if (this.currentIndex < 0) this.currentIndex = 0
        else
            this.currentIndex = this.currentIndex - 1;
    }

    onGoNext() {
        //this.currentIndex = this.currentIndex + 1; //Moh: 7-31-2020 case 0298 
        let item = this.selectedOldOptions[this.currentIndex];
        this.currentIndex = this.currentIndex + 1;
        this.onOptionSelected(item, false);

    }

    prepareCurrentStep() {
      
        this.currentOptions = [];
        const allOptions = this.wizardOptions;
        if (this.parentItem.Value != null && isNaN(this.parentItem.Value)) {

            if (this.numberOfVechicle == 1 && this.parentItem.Value == 'M') { // this section applied only for single vehichle

                let options = allOptions.filter(f => f.ConfigCode == this.parentItem.Value && f.Condition != 'hide');
                options.forEach((item) => {
                    if (item.Value == '92') item.ImageUrl = item.ChildImageUrl; //switiching image in case of single vehichl and Child image contain image only 92
                })
                this.currentOptions = options;

            } else {
                this.currentOptions = allOptions.filter(f => f.ConfigCode == this.parentItem.Value && f.Condition != 'hide');
            }
           
            this.optionType = 'image';
        } else {
            
            this.currentOptions = allOptions.filter(f => f.Parent == this.parentItem.Id);
            this.optionType = 'radio';
        }
   
        this.currentOptions.sort((a, b) => { return a.Order - b.Order });
        this.setHeader(); //first setup header;
        this.checkShowBack();
        this.checkOptionsSize();
        this.checkIsFirst();
        this.setSelectedValue();
    }

    setHeader() {
        if (this.selectedOptions.length == 1) {
            this.title = this.parentItem.SectionTitle;
            this.header = this.parentItem.ShortDesc;
            this.header = this.header.replace('{0}', this.parentItem.Label + '(' + this.parentItem.Value + ')');
          
        } else {
            this.title = this.parentItem.SectionTitle;
            this.header = this.parentItem.ShortDesc;
        }
    }

    checkShowBack() {
        if (this.selectedOptions.length > 0) this.showBack = true; else this.showBack = false; 
    }

    checkOptionsSize() {
        this.optionCount = this.currentOptions.length;
    }
    onOptionSelected(item: any, clearOldOption: boolean = true) {
        this.optionType = 'radio';
        if (clearOldOption) {
            this.selectedOldOptions = [];
            this.showNextButton();
        }
        if (this.parentItem != null) this.previousParent = this.parentItem;
        this.parentItem = item;
        this.selectedOptions.push(item);
        if (this.selectedOptions.length == 3 || this.previousParent.Value == "M") { 
            this.showFinalScreen = true;
            this.optionType = 'final';

            //Moh: 8-3-2020 case 068 
            this.lastItem = this.selectedOldOptions.find(x => x.Value == parseInt(this.vec1OrgValue))
            if (this.lastItem == undefined) {
                this.lastItem = this.selectedOldOptions.find(x => x.Value == parseInt(this.vec2OrgValue));
            }
            if (this.lastItem == undefined) {
                this.lastItem = item;
            }
            this.prepareFinalMultipleImageArray(this.vec1OrgValue, this.vec2OrgValue);

            this.setFinalSetOfData();
            this.showNext = false;
            this.title = 'Crash Type Vehicle Assignment';
            this.header = 'Please pick the Crash Type for the vehicles involved in the First Harmful Event:';
        } else {
            this.prepareCurrentStep();
            this.showFinalScreen = false;
        }
    }

    setFinalSetOfData() {

        this.finalSelectedOptions = [];
        if (this.firstRowList.length == 1) this.vec1Value = this.firstRowList[0].Value;
        if (this.multipleImagesInLastWindow.length ==1) this.vec2Value = this.multipleImagesInLastWindow[0].Value;
        if (this.numberOfVechicle == 1) {
            this.vec2Value = 0;
            this.vecNum2 = 0;
        }

        this.finalSelectedOptions.push({ label: 'vechicle', vecNum1: this.vecNum1, vecNum2: this.vecNum2, vechicle1Value: this.vec1Value, vechicle2Value: this.vec2Value });
        this.finalSelectedOptions.push(this.selectedOptions)

        //this.selectedOptions.push()

        if (this.optionType == 'final') {
            if (this.selectedOldOptions.length == 4) {
                const _row = this.selectedOldOptions[3];
                if (_row) {
                    this.vec1Value = _row.vechicle1Value;
                    this.vec2Value = _row.vechicle2Value;

                    this.vecNum1 = _row.vecNum1;
                    this.vecNum2 = _row.vecNum2;
                }
            }
        }

    }

    setVechicleNumber() {
        this.dropDownOptions.forEach((item) => {
            if (item.selected && item.isFirst) this.vecNum1 = item.value;
            if (item.selected && !item.isFirst) this.vecNum2 = item.value;
        })
    }
    onRadioChanged() {
        this.finalSelectedOptions = [];
        this.finalSelectedOptions.push({ label: 'vechicle', vecNum1: this.vecNum1, vecNum2: this.vecNum2, vechicle1Value: this.vec1Value, vechicle2Value: this.vec2Value });
    }

    prepareFinalMultipleImageArray(v1Value, v2Value) {
        let listOfValues: [];
        let wizards:CrashTypeWizardModel[];
        this.firstRowList = [];
        this.multipleImagesInLastWindow = [];

        if (this.lastItem.Condition != null && this.lastItem.Condition != '') {
            listOfValues = this.lastItem.Condition.split(',');
            wizards = this.wizardOptions;

            //checking if the has this randon image postion.
            if (this.lastItem.GroupValue != null) {
                let groupArrays = this.lastItem.GroupValue.split(',');
                if (groupArrays[0]!=92) this.firstRowList.push({ ImageUrl: groupArrays[1], Value: groupArrays[0] });
                this.multipleImagesInLastWindow.push({ ImageUrl: groupArrays[1], Value: groupArrays[0]});
               // this.vec1Value = groupArrays[0]; //assiginng default value;
               // this.vec1Value = -1;// groupArrays[0]; //assiginng default value;
                listOfValues.forEach((value, index) => {
                    if (index == 0) {
                        let getItemByValue = wizards.find(x => x.Value == value);
                        if (getItemByValue != null) {
                            this.firstRowList.push({ ImageUrl: getItemByValue.ChildImageUrl, Value: getItemByValue.Value });
                        }
                    }
                })
               }

            listOfValues.forEach((value, index) => {
                if (index != 0) {
                    let getItemByValue = wizards.find(x => x.Value == v2Value);
                   // if (index == 1) this.vec2Value = getItemByValue.Value;
                    if (getItemByValue != null) {
                        this.multipleImagesInLastWindow.push({ ImageUrl: getItemByValue.ImageUrl, Value: getItemByValue.Value });
                    }
                }
            })
        } else {
            //this.vec2Value = this.lastItem.Value;
            this.multipleImagesInLastWindow.push(this.lastItem);
        }

        if (this.firstRowList.length == 0) {
            ////this.vec1Value = this.lastItem.Value;
            ////this.firstRowList.push({ ImageUrl: (this.lastItem.ChildImageUrl != null ? this.lastItem.ChildImageUrl : this.lastItem.ImageUrl), Value: this.lastItem.Value });
            //let getItemByValue = wizards.find(x => x.Value == v1Value);
            //if (getItemByValue != null) {
                this.firstRowList.push({ ImageUrl: (this.lastItem.ChildImageUrl != null ? this.lastItem.ChildImageUrl : this.lastItem.ImageUrl), Value: this.lastItem.Value });
            //}
        }


    }

    checkIsFirst() {
        this.isFirst = this.selectedOptions.length < 1;
    }
    onMouseHover(item: any, $event: any) {
        if ($event) {
            $event.toElement.classList.add('border-img');;
        }
    }

    onMouseOut($event: any) {
        if ($event) {
            let target = $event.target as HTMLElement
            target.classList.remove('border-img');
        }
    }

    buildImageUrl(value: any) : string {
        return '';
    }


    onGoBack() {
        const lastItem = this.selectedOptions.pop();
        this.backSelected = lastItem.Value;
        if (this.selectedOptions.length > 0) {
            this.parentItem = this.selectedOptions[this.selectedOptions.length - 1];
            if (this.selectedOptions.length > 1) this.previousParent = this.selectedOptions[this.selectedOptions.length - 2];
        } else {
            this.parentItem = this.wizardOptions[0];
        }
        this.prepareCurrentStep();
        this.reserveIndex();
        this.showNextButton();
    }

    showNextButton() {
        if (this.selectedOldOptions != null && this.selectedOldOptions.length > 1) {
            this.showNext = true;
        } else {
            this.showNext = false;
        }
    }
    onPositionSwitch(event: any, isFirst: boolean) {
        if (this.harmfulVec.length > 2) return;
        if (this.numberOfVechicle == 1) return;
        let selectedValue = (isFirst ? this.vecNum1 : this.vecNum2);

        let item = this.dropDownOptions.find(x => x.isFirst == isFirst && x.value == selectedValue);

        this.dropDownOptions.forEach((lItem, index) => {
            this.dropDownOptions[index].selected = false;

            if (lItem.isFirst != item.isFirst && lItem.value != item.value) {
                this.dropDownOptions[index].selected = true;
                this.vecNum2 = this.dropDownOptions[index].value;
            }

            if (lItem.isFirst == item.isFirst && lItem.value == item.value) {
                this.dropDownOptions[index].selected = true;
                this.vecNum1 = this.dropDownOptions[index].value;
            }

        });
        this.setVechicleNumber();
        this.setFinalSetOfData();
    }

    prepareDropDown() {

        this.harmfulVec = [];
        this.fhEvents.forEach((item, index) => {
            if (item.VNumber1 > 0 && item.VNumber2 > 0) {
                let veh = this.acc.Veh.find(x => x.VNumber == parseInt(item.VNumber1.toString()));
                if (veh && veh.UnitType != 2) {
                    if (!this.harmfulVec.includes(parseInt(item.VNumber1.toString()))) {
                        this.harmfulVec.push(parseInt(item.VNumber1.toString()));
                    }
                }
                veh = this.acc.Veh.find(x => x.VNumber == parseInt(item.VNumber2.toString()));
                if (veh && veh.UnitType != 2) {
                    if (!this.harmfulVec.includes(parseInt(item.VNumber2.toString()))) {
                        this.harmfulVec.push(parseInt(item.VNumber2.toString()));
                    };
                }
             
            }
        })
        if (this.harmfulVec.length == 1) {
            this.numberOfVechicle = 1
        } 
        this.dropDownOptions = [];
        if (this.numberOfVechicle == 1) {
            this.dropDownOptions = [{ value: 1, selected: true, isFirst: true }];
            this.vec1Value = this.harmfulVec[0];
        }
        else {
            this.harmfulVec.forEach((vechicleNumber, index) => {
                if (index == 0) {
                    this.dropDownOptions.push({ value: vechicleNumber, selected: true, isFirst: true });
                    this.vecNum1 = vechicleNumber;
                } else {
                    this.dropDownOptions.push({ value: vechicleNumber, selected: false, isFirst: true });
                }
            });

            this.harmfulVec.forEach((vechicleNumber, index) => {
                if (index == 0) {
                    this.dropDownOptions.push({ value: vechicleNumber, selected: this.harmfulVec.length != 2, isFirst: false });
                } else {
                    this.vecNum2 = vechicleNumber;
                    this.dropDownOptions.push({ value: vechicleNumber, selected: this.harmfulVec.length==2, isFirst: false });

                }
            });
            if (this.harmfulVec.length > 2) {
                this.vecNum2 = this.harmfulVec[0];
            }
        }
    }

    submitCrashType() {

        /*if (this.numberOfVechicle != 1) {
            if (this.acc && this.acc.Veh) {
                this.acc.Veh.forEach((item, index) => {
                    if (item.VNumber == 2 && item.UnitType) {

                    }
                })
            }
        }*/

        this.activeModal.close(this.finalSelectedOptions)
    }

    setSelectedValue() {
        //console.log('selectedOldOptions', this.selectedOldOptions);
        //console.log('currentOptions', this.currentOptions);

        if (this.selectedOldOptions != null && this.selectedOldOptions.length > 0) {
            let hasAnyMatched = false;
            this.selectedOldOptions.forEach((item, key) => {
                let matchValue: any;

                if (typeof item === 'object' && hasOwnProperty(item, 'Id') && typeof item.Id === 'number') { //Moh- Check Id property, because when Id property is missing browser got error
                    //console.log(item.Id)
                    matchValue = this.currentOptions.find(x => x.Id == item.Id);
                    if (matchValue != null) {
                        hasAnyMatched = true;
                        this.backSelected = matchValue.Value;
                    }
                }
            })
            if (hasAnyMatched) {
                this.showNext = true;
            } else {
                if(this.optionType != 'final') this.selectedOldOptions = [];
                this.showNext = false;
            }
        }
        
        console.log('selectedOldOptions1', this.selectedOldOptions);
    }

    finishWizard() {
        if (!this.checkMessageCondtion()) {
            this.openMessage().toPromise();
        } else {
            this.message = '';
            this.finalSelectedOptions = [];
            this.finalSelectedOptions.push({label: 'vechicle', vecNum1: this.vecNum1, vecNum2: this.vecNum2, vechicle1Value: this.vec1Value, vechicle2Value: this.vec2Value });
            this.finalSelectedOptions.push(this.selectedOptions);
            this.activeModal.close(this.finalSelectedOptions)
        }
    }
    checkMessageCondtion() {

        if (this.numberOfVechicle != 1 && this.vecNum2 == this.vecNum1) {
            this.message = 'Please select a different vehicle';
            return false;
        }
        if (this.vec1Value == '-1' && this.vec2Value == '-1' && this.numberOfVechicle!=1) {
            this.message = 'Please select Crash Type sceneario for the First and Second Vehicle';
            return false;
        }
        else if (this.vec1Value == '-1') {
            this.message = 'Please select a Crash Type scenario for the First Vehicle';
            return false;
        }
        else if (this.vec2Value == '-1') {
            this.message = 'Please select a Crash Type scenario for the Second Vehicle';
            return false;
        } else {
            return true;
        }
       
    }
    openMessage() {
        this.innerPopup = this.localModal.open(this.alertMessage, { size: 'sm', centered: true });
        return from(this.innerPopup.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        )
    }

    setHarmfulEventsIds() {
      
        this._utilService.harmfulEvents.subscribe(result => {
            this.harmfulEventCodes = [];
            if (result) {
                result.forEach(item => {
                    this.harmfulEventCodes.push(item.Id);
                })
            }
        })
    }


    onChangeRadio1(value) {
        this.vec1Value = value;
    }

    onChangeRadio2(value) {
        this.vec2Value = value;
    }
    valueParse(value) {
        if (value == null || value == undefined) return 0;
        return parseInt(value);
    }
}
