<div *ngIf="acc" (window:keydown)="tabNavigation($event)">

    <div class="row">
        <div class="col-2 col-sm-6 col-md-2" *ngIf="!isPrintMode">

            <!-- NAVIGATION TREE links (not to be confused with tab headings -->
            <div class="card border-dark pb-0 margin-border " *ngIf="!hideNavForCloseCase" style="overflow:hidden;">
                <div class="card-body bg-light margin-border">

                    <h5 class="card-title pointer" *ngIf="!isCrashCollapsed" (click)="isCrashCollapsed = !isCrashCollapsed" [attr.aria-expanded]="!isCrashCollapsed" aria-controls="collapseCrash">Form Browser <i class="fas fa-caret-down"></i></h5>
                    <h5 class="card-title pointer" *ngIf="isCrashCollapsed" (click)="isCrashCollapsed = !isCrashCollapsed" [attr.aria-expanded]="!isCrashCollapsed" aria-controls="collapseCrash">Form Browser <i class="fas fa-caret-up"></i></h5>

                    <nav class="nav flex-column" id="collapseCrash" [ngbCollapse]="isCrashCollapsed">

                        <!--Crash Vertical-->
                        <div *ngFor="let accLink of verticalLinksGroupForCrash">
                            <div *ngIf="accLink" class="bg-light">
                                <a id="tabCrash" class="nav-item nav-link bg-light"
                                   [ngClass]="{'bg-white' : verticalLinkCaseCrash.isActive === true}"
                                   [routerLink]="['./crash', accLink.crashLinkVObj.AccID ]" [routerLinkActive]="['active']"
                                   #verticalLinkCaseCrash="routerLinkActive">
                                    <span *ngIf="selectedMode == 64 || selectedMode == 128" class="text-dark" [ngClass]="{'text-decoration-none': verticalLinkCaseCrash.isActive === true, 'fw-bold' : verticalLinkCaseCrash.isActive === true}">
                                        Crash - {{acc.Casenum | number : '4.0-0' | replaceTextPipe : ',' : ''}}
                                    </span>
                                    <span *ngIf="selectedMode == 256" class="text-dark" [ngClass]="{'text-decoration-none': verticalLinkCaseCrash.isActive === true, 'fw-bold' : verticalLinkCaseCrash.isActive === true}">
                                        Crash - {{acc.Acc_SS.MOSSCasenum | replaceTextPipe : ',' : ''}}
                                    </span>
                                </a>
                            </div>
                        </div>

                        <!--Non Occupant Vertical-->
                        <div *ngIf="acc.Non_Occupants.lenght !== 0">
                            <div class="border-top border-dark" *ngFor="let nonOccLink of verticalLinksGroupForNonOccupant">
                                <div *ngIf="nonOccLink" class="bg-light margin-border">
                                    <a class="nav-item nav-link bg-light"
                                       [ngClass]="{'bg-white' : verticalLinkinkCasenonOccupant.isActive === true}"
                                       [routerLink]="['./nonOccupant', nonOccLink.nonOccupantLinkVObj.PNumber]" [routerLinkActive]="['active']"
                                       #verticalLinkinkCasenonOccupant="routerLinkActive">
                                        <span>
                                            <span *ngIf="selectedMode == 64 || selectedMode == 128" class="text-dark" [ngClass]="{'text-decoration-none': verticalLinkinkCasenonOccupant.isActive === true, 'fw-bold' : verticalLinkinkCasenonOccupant.isActive === true}">
                                                Non-Occupant_{{ nonOccLink.nonOccupantLinkVObj.PNumber }}
                                                <i class="fas fa-asterisk pl-2" *ngIf="nonOccLink.nonOccupantLinkVObj.Injury === 4"></i>
                                            </span>
                                            <span *ngIf="selectedMode == 256" class="text-dark" [ngClass]="{'text-decoration-none': verticalLinkinkCasenonOccupant.isActive === true, 'fw-bold' : verticalLinkinkCasenonOccupant.isActive === true}">
                                                Non_Occupant_Subject_{{ nonOccLink.nonOccupantLinkVObj.PNumber }}
                                                <i class="fas fa-asterisk pl-2" *ngIf="nonOccLink.nonOccupantLinkVObj.Injury === 5"></i>
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--Striking Vehicle-->
                        <div class="border-top border-dark margin-border" *ngFor="let group of verticalLinksGroupForStrikingVehicle">
                            <div *ngIf="group" class="bg-light margin-border">
                                <div class="pl-2 py-2 pointer">
                                    <span *ngIf="group.isCollapsed" (click)="group.isCollapsed = !group.isCollapsed" [attr.aria-expanded]="!group.isCollapsed" [attr.aria-controls]="'collapseVehicle' + group.vehLinkStrikingRVObj.VNumber" class=""><i class="fas fa-plus"></i> Striking_Vehicle_{{group.vehLinkStrikingRVObj.Veh_SS.SSVNumber}}</span>
                                    <span *ngIf="!group.isCollapsed" (click)="group.isCollapsed  = !group.isCollapsed" [attr.arinav-pillsa-expanded]="!group.isCollapsed" [attr.aria-controls]="'collapseVehicle' + group.vehLinkStrikingRVObj.VNumber" class=""><i class="fas fa-minus"></i> Striking_Vehicle_{{group.vehLinkStrikingRVObj.Veh_SS.SSVNumber}}</span>
                                </div>
                                <nav class="nav flex-column" [id]="'collapseVehicle' + group.vehLinkStrikingRVObj.VNumber" [ngbCollapse]="group.isCollapsed">
                                    <div>
                                        <span *ngIf="group.vehLinkStrikingRVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseVehicle.isActive === true}"
                                               [routerLink]="['./strikingvehicle', group.vehLinkStrikingRVObj.VNumber, 'strikingvehicle']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseVehicle="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseVehicle.isActive === true, 'fw-bold' : verticalLinkinkCaseVehicle.isActive === true}">
                                                    Striking_Vehicle_{{ group.vehLinkStrikingRVObj.Veh_SS.SSVNumber }}
                                                </span>
                                            </a>
                                        </span>
                                        <!--<span *ngIf="group.vehLinkStrikingRVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseDriver.isActive === true}"
                                               [routerLink]="['./strikingvehicle', group.vehLinkStrikingRVObj.VNumber, 'strikingdriver']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseDriver="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseDriver.isActive === true, 'fw-bold' : verticalLinkinkCaseDriver.isActive === true}">
                                                    Striking_Driver_V{{ group.vehLinkStrikingRVObj.Veh_SS.SSVNumber }}
                                                </span>
                                            </a>
                                        </span>-->
                                        <a class="nav-item nav-link bg-light"
                                           [ngClass]="{'bg-white' : verticalLinkinkCasePrecrash.isActive === true}"
                                           [routerLink]="['./strikingvehicle', group.vehLinkStrikingRVObj.VNumber, 'strikingprecrash']" [routerLinkActive]="['active']"
                                           #verticalLinkinkCasePrecrash="routerLinkActive">
                                            <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCasePrecrash.isActive === true, 'fw-bold' : verticalLinkinkCasePrecrash.isActive === true}">
                                                Striking_Precrash_V{{ group.vehLinkStrikingRVObj.Veh_SS.SSVNumber }}
                                            </span>
                                        </a>
                                        <div *ngFor="let link of group.vehsOccupantsLinkStrikingObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseStrikingPerson.isActive === true}"
                                               [routerLink]="['./strikingvehicle', link.VNumber, 'strikingperson', link.PNumber]" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseStrikingPerson="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseStrikingPerson.isActive === true, 'fw-bold' : verticalLinkinkCaseStrikingPerson.isActive === true}">
                                                    Striking_Occupant_{{ link.PNumber }}_V{{ link.Veh.Veh_SS.SSVNumber}} <span class="fw-bold" *ngIf="link.CreatedRecord != null && link.CreatedRecord > 0">_CR</span>
                                                    <i class="fas fa-asterisk pl-2" *ngIf="link.PolInjSevr === 5"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <!-- SFR Vehicles Vertical-->
                        <div class="border-top border-dark margin-border" *ngFor="let group of verticalLinksGroupForSFRVehicle">
                            <div *ngIf="group" class="bg-light margin-border">
                                <div class="pl-2 py-2 pointer">
                                    <span *ngIf="group.isCollapsed" (click)="group.isCollapsed = !group.isCollapsed" [attr.aria-expanded]="!group.isCollapsed" [attr.aria-controls]="'collapseVehicle' + group.vehLinkSFRVObj.VNumber" class=""><i class="fas fa-plus"></i> Struck_Subject_Vehicle _{{group.vehLinkSFRVObj.Veh_SS.SSVNumber}}</span>
                                    <span *ngIf="!group.isCollapsed" (click)="group.isCollapsed  = !group.isCollapsed" [attr.arinav-pillsa-expanded]="!group.isCollapsed" [attr.aria-controls]="'collapseVehicle' + group.vehLinkSFRVObj.VNumber" class=""><i class="fas fa-minus"></i> Struck_Subject_Vehicle_{{group.vehLinkSFRVObj.Veh_SS.SSVNumber}}</span>
                                </div>
                                <nav class="nav flex-column" [id]="'collapseVehicle' + group.vehLinkSFRVObj.VNumber" [ngbCollapse]="group.isCollapsed">
                                    <div>
                                        <span *ngIf="group.vehLinkSFRVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseVehicle.isActive === true}"
                                               [routerLink]="['./sfrvehicle', group.vehLinkSFRVObj.VNumber, 'sfrvehicle']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseVehicle="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseVehicle.isActive === true, 'fw-bold' : verticalLinkinkCaseVehicle.isActive === true}">
                                                    Struck_Subject_Vehicle_V{{ group.vehLinkSFRVObj.Veh_SS.SSVNumber }}
                                                </span>
                                            </a>
                                        </span>
                                        <!--<span *ngIf="group.vehLinkSFRVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseDriver.isActive === true}"
                                               [routerLink]="['./sfrvehicle', group.vehLinkSFRVObj.VNumber, 'driver']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseDriver="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseDriver.isActive === true, 'fw-bold' : verticalLinkinkCaseDriver.isActive === true}">
                                                    SFRDriver_V{{ group.vehLinkSFRVObj.VNumber }}
                                                </span>
                                            </a>
                                        </span>-->
                                        <!--<span *ngIf="group.vehLinkSFRVObj?.PreCrash">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCasePrecrash.isActive === true}"
                                               [routerLink]="['./vehicle', group.vehLinkSFRVObj.VNumber, 'precrash']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCasePrecrash="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCasePrecrash.isActive === true, 'fw-bold' : verticalLinkinkCasePrecrash.isActive === true}">
                                                    SFRPrecrash_V{{ group.vehLinkSFRVObj.VNumber }}
                                                </span>
                                            </a>
                                        </span>-->
                                        <div *ngFor="let link of group.vehsOccupantsLinkSFRObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseSFRPerson.isActive === true}"
                                               [routerLink]="['./sfrvehicle', group.vehLinkSFRVObj.VNumber, 'sfrperson',link.PNumber]" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseSFRPerson="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseSFRPerson.isActive === true, 'fw-bold' : verticalLinkinkCaseSFRPerson.isActive === true}">
                                                    <!--SFRPerson_{{ link.PNumber }}_V{{ link.VNumber}}-->
                                                    Struck_Subject_Occupant_{{ link.PNumber }}_V{{ group.vehLinkSFRVObj.Veh_SS.SSVNumber }}
                                                    <span class="fw-bold" *ngIf="link.CreatedRecord != null && link.CreatedRecord > 0">_CR</span>
                                                    <i class="fas fa-asterisk pl-2" *ngIf="link.PolInjSevr === 5"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <!--Other Vehicles Vertical-->
                        <div class="border-top border-dark margin-border" *ngFor="let group of verticalLinksGroupForVehicle">
                            <div *ngIf="group && selectedMode == 256" class="bg-light margin-border">
                                <div class="pl-2 py-2 pointer">
                                    <span *ngIf="group.isCollapsed" (click)="group.isCollapsed = !group.isCollapsed" [attr.aria-expanded]="!group.isCollapsed" [attr.aria-controls]="'collapseVehicle' + group.vehLinkVObj.VNumber" class=""><i class="fas fa-plus"></i> Other_Vehicle_{{group.vehLinkVObj.Veh_SS.SSVNumber}}</span>
                                    <span *ngIf="!group.isCollapsed" (click)="group.isCollapsed  = !group.isCollapsed" [attr.arinav-pillsa-expanded]="!group.isCollapsed" [attr.aria-controls]="'collapseVehicle' + group.vehLinkVObj.VNumber" class=""><i class="fas fa-minus"></i> Other_Vehicle_{{group.vehLinkVObj.Veh_SS.SSVNumber}}</span>
                                </div>
                                <nav class="nav flex-column" [id]="'collapseVehicle' + group.vehLinkVObj.VNumber" [ngbCollapse]="group.isCollapsed">
                                    <div>
                                        <span *ngIf="group.vehLinkVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseVehicle.isActive === true}"
                                               [routerLink]="['./vehicle', group.vehLinkVObj.VNumber, 'vehicle']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseVehicle="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseVehicle.isActive === true, 'fw-bold' : verticalLinkinkCaseVehicle.isActive === true}">
                                                    Vehicle_V{{ group.vehLinkVObj.Veh_SS.SSVNumber }}
                                                </span>
                                            </a>
                                        </span>
                                        <!--<span *ngIf="group.vehLinkVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseDriver.isActive === true}"
                                               [routerLink]="['./vehicle', group.vehLinkVObj.VNumber, 'driver']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseDriver="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseDriver.isActive === true, 'fw-bold' : verticalLinkinkCaseDriver.isActive === true}">
                                                    Driver_V{{ group.vehLinkVObj.VNumber }}
                                                </span>
                                            </a>
                                        </span>-->
                                        <!--<span *ngIf="group.vehLinkVObj?.PreCrash">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCasePrecrash.isActive === true}"
                                               [routerLink]="['./vehicle', group.vehLinkVObj.VNumber, 'precrash']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCasePrecrash="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCasePrecrash.isActive === true, 'fw-bold' : verticalLinkinkCasePrecrash.isActive === true}">
                                                    Precrash_V{{ group.vehLinkVObj.VNumber }}
                                                </span>
                                            </a>
                                        </span>-->
                                        <!--<div *ngFor="let link of group.vehsOccupantsLinkVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCasePerson.isActive === true}"
                                               [routerLink]="['./vehicle', link.VNumber, 'person', link.PNumber]" [routerLinkActive]="['active']"
                                               #verticalLinkinkCasePerson="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCasePerson.isActive === true, 'fw-bold' : verticalLinkinkCasePerson.isActive === true}">
                                                    Person_{{ link.PNumber }}_V{{ link.VNumber}} <span class="fw-bold" *ngIf="link.CreatedRecord != null && link.CreatedRecord > 0">_CR</span>
                                                    <i class="fas fa-asterisk pl-2" *ngIf="link.Injury === 4"></i>
                                                </span>
                                            </a>
                                        </div>-->
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <!--Vehicles Vertical-->
                        <div class="border-top border-dark margin-border" *ngFor="let group of verticalLinksGroupForVehicle">
                            <div *ngIf="group && selectedMode != 256" class="bg-light margin-border">
                                <div class="pl-2 py-2 pointer">
                                    <span *ngIf="group.isCollapsed" (click)="group.isCollapsed = !group.isCollapsed" [attr.aria-expanded]="!group.isCollapsed" [attr.aria-controls]="'collapseVehicle' + group.vehLinkVObj.VNumber" class=""><i class="fas fa-plus"></i> Vehicle_{{group.vehLinkVObj.VNumber}}</span>
                                    <span *ngIf="!group.isCollapsed" (click)="group.isCollapsed  = !group.isCollapsed" [attr.arinav-pillsa-expanded]="!group.isCollapsed" [attr.aria-controls]="'collapseVehicle' + group.vehLinkVObj.VNumber" class=""><i class="fas fa-minus"></i> Vehicle_{{group.vehLinkVObj.VNumber}}</span>
                                </div>
                                <nav class="nav flex-column" [id]="'collapseVehicle' + group.vehLinkVObj.VNumber" [ngbCollapse]="group.isCollapsed">
                                    <div>
                                        <span *ngIf="group.vehLinkVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseVehicle.isActive === true}"
                                               [routerLink]="['./vehicle', group.vehLinkVObj.VNumber, 'vehicle']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseVehicle="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseVehicle.isActive === true, 'fw-bold' : verticalLinkinkCaseVehicle.isActive === true}">
                                                    Vehicle_V{{ group.vehLinkVObj.VNumber }}
                                                </span>
                                            </a>
                                        </span>
                                        <span *ngIf="group.vehLinkVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCaseDriver.isActive === true}"
                                               [routerLink]="['./vehicle', group.vehLinkVObj.VNumber, 'driver']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCaseDriver="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCaseDriver.isActive === true, 'fw-bold' : verticalLinkinkCaseDriver.isActive === true}">
                                                    Driver_V{{ group.vehLinkVObj.VNumber }}
                                                </span>
                                            </a>
                                        </span>
                                        <span *ngIf="group.vehLinkVObj?.PreCrash">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCasePrecrash.isActive === true}"
                                               [routerLink]="['./vehicle', group.vehLinkVObj.VNumber, 'precrash']" [routerLinkActive]="['active']"
                                               #verticalLinkinkCasePrecrash="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCasePrecrash.isActive === true, 'fw-bold' : verticalLinkinkCasePrecrash.isActive === true}">
                                                    Precrash_V{{ group.vehLinkVObj.VNumber }}
                                                </span>
                                            </a>
                                        </span>
                                        <div *ngFor="let link of group.vehsOccupantsLinkVObj">
                                            <a class="nav-item nav-link bg-light"
                                               [ngClass]="{'bg-white' : verticalLinkinkCasePerson.isActive === true}"
                                               [routerLink]="['./vehicle', link.VNumber, 'person', link.PNumber]" [routerLinkActive]="['active']"
                                               #verticalLinkinkCasePerson="routerLinkActive">
                                                <span class="text-dark pl-2" [ngClass]="{'text-decoration-none': verticalLinkinkCasePerson.isActive === true, 'fw-bold' : verticalLinkinkCasePerson.isActive === true}">
                                                    Person_{{ link.PNumber }}_V{{ link.VNumber}} <span class="fw-bold" *ngIf="link.CreatedRecord != null && link.CreatedRecord > 0">_CR</span>
                                                    <i class="fas fa-asterisk pl-2" *ngIf="link.Injury === 4"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <!--Avoidance Equipment Vertical-->
                        <div *ngIf="acc.Veh.lenght !== 0 && selectedMode != 256">
                            <div class="border-top border-dark margin-border" *ngFor="let group of verticalLinksGroupForVehicle">
                                <div *ngIf="group" class="bg-light margin-border">
                                    <a class="nav-item nav-link bg-light"
                                       [ngClass]="{'bg-white' : verticalLinkinkAvoidanceEquipment.isActive === true}"
                                       [routerLink]="['./vehicleAvoidEquip', group.vehLinkVObj.VNumber, 'ae', 'avoidanceEquipment']" [routerLinkActive]="['active']"
                                       #verticalLinkinkAvoidanceEquipment="routerLinkActive">
                                        <span>
                                            <span class="text-dark" [ngClass]="{'text-decoration-none': verticalLinkinkAvoidanceEquipment.isActive === true, 'fw-bold' : verticalLinkinkAvoidanceEquipment.isActive === true}">
                                                Avoidance_V{{ group.vehLinkVObj.VNumber }}
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <div class="card border-dark margin-border my-1" *ngIf="!hideNavForCloseCase && !selectedMode == 256">
                <div class="card-body bg-light margin-border" *ngIf="acc; else loadingErrorStatus">
                    <h5 class="card-title pointer" *ngIf="!isErrorStatusCollapsed" (click)="isErrorStatusCollapsed = !isErrorStatusCollapsed" [attr.aria-expanded]="!isErrorStatusCollapsed" aria-controls="collapseErrorStatus">Error Status <i class="fas fa-caret-down"></i></h5>
                    <h5 class="card-title pointer" *ngIf="isErrorStatusCollapsed" (click)="isErrorStatusCollapsed = !isErrorStatusCollapsed" [attr.aria-expanded]="!isErrorStatusCollapsed" aria-controls="collapseErrorStatus">Error Status <i class="fas fa-caret-up"></i></h5>
                    <ul class="nav flex-column bg-light" id="collapseErrorStatus" [ngbCollapse]="isErrorStatusCollapsed">
                        <li class="nav-item d-flex justify-content-between align-items-center no-pointer">
                            <span>Fatal:</span>
                            <span class="badge badge-danger badge-pill">{{errorStatusFatal}}</span>
                        </li>
                        <li class="nav-item d-flex justify-content-between align-items-center no-pointer">
                            <span>Severe:</span>
                            <span class="badge badge-info badge-pill">{{errorStatusSevere}}</span>
                        </li>
                        <li class="nav-item d-flex justify-content-between align-items-center no-pointer">
                            <span>Pending:</span>
                            <span class="badge badge-secondary badge-pill">{{errorStatusPending}}</span>
                        </li>
                    </ul>
                </div>
                <ng-template #loadingErrorStatus>
                    <div class="pl-2 py-3"><i class="fa fa-spinner fa-spin" style="font-size:24px"></i></div>
                </ng-template>
            </div>

            <div class="card border-dark margin-border my-1" *ngIf="!hideNavForCloseCase && !selectedMode == 256">
                <div class="card-body bg-light margin-border">
                    <h5 class="card-title pointer" *ngIf="!isPrintCollapsed" (click)="isPrintCollapsed = !isPrintCollapsed" [attr.aria-expanded]="!isPrintCollapsed" aria-controls="collapsePrint">Print <i class="fas fa-caret-down"></i></h5>
                    <h5 class="card-title pointer" *ngIf="isPrintCollapsed" (click)="isPrintCollapsed = !isPrintCollapsed" [attr.aria-expanded]="!isPrintCollapsed" aria-controls="collapsePrint">Print <i class="fas fa-caret-up"></i></h5>

                    <ul class="nav flex-column bg-light" id="collapsePrint" [ngbCollapse]="isPrintCollapsed">
                        <li class="nav-item">
                            <button id="btnCase_gotoPrint" class="btn btn-link pointer p-0 nounderline" (click)="gotoPrint()">Print Case</button>
                        </li>
                        <li class="nav-item">
                            <button id="btnCase_gotoPrintForm" class="btn btn-link pointer p-0 nounderline" (click)="gotoPrint('form')">Print this Form</button>
                        </li>
                        <li class="nav-item">
                            <button id="btnCase_gotoPrintBlank" class="btn btn-link pointer p-0 nounderline" (click)="gotoPrint('blank')">Print Blank Case</button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="card border-dark margin-border" *ngIf="!hideNavForCloseCase">
                <div class="card-body bg-light margin-border">
                    <h5 class="card-title pointer" *ngIf="!isCaseDocumentsCollapsed" (click)="isCaseDocumentsCollapsed = !isCaseDocumentsCollapsed" [attr.aria-expanded]="!isCaseDocumentsCollapsed" aria-controls="collapseCaseDocuments">Case Documents <i class="fas fa-caret-down"></i></h5>
                    <h5 class="card-title pointer" *ngIf="isCaseDocumentsCollapsed" (click)="isCaseDocumentsCollapsed = !isCaseDocumentsCollapsed" [attr.aria-expanded]="!isCaseDocumentsCollapsed" aria-controls="collapseCaseDocuments">Case Documents <i class="fas fa-caret-up"></i></h5>

                    <ul class="nav flex-column bg-light" id="collapseCaseDocuments" [ngbCollapse]="isCaseDocumentsCollapsed">
                        <li *ngIf="blnEnableDownloadAll" class="nav-item">
                            <button *ngIf="!caseFileService.blnAllDownloadActive && !caseFileService.blnUploadActive" id="btnDownloadAll" class="btn btn-link pointer p-0 nounderline text-left" (click)="onDownloadAll()">Download All Files</button>
                            <button *ngIf="caseFileService.blnAllDownloadActive" class="btn btn-primary" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Downloading All Files...
                            </button>
                        </li>
                        <li *ngIf="caseFileService.blnUploadActive" class="nav-item">
                            <button class='btn btn-success' type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Uploading Files...
                            </button>
                        </li>
                        <li class="nav-item" *ngIf="selectedMode == 64">
                            <button class="btn btn-link pointer p-0 nounderline" (click)="OnGoToSSCaseFiles(accid)">MTSS Case Documents</button>
                        </li>
                        <li class="nav-item" *ngIf="selectedMode == appMode.PPSS">
                            <button class="btn btn-link pointer p-0 nounderline" (click)="OnGoToSSCaseFiles(accid)">PPSS Case Documents</button>
                        </li>
                        <li class="nav-item" *ngIf="selectedMode == appMode.MOSS">
                            <button class="btn btn-link pointer p-0 nounderline" (click)="OnGoToSSCaseFiles(accid)">MOSS Case Documents</button>
                        </li>
                    </ul>
                </div>
            </div>



            <div class="card border-dark margin-border">
                <div class="card-body bg-light margin-border">
                    <h5 class="card-title pointer" *ngIf="!isReferenceDocumentsCollapsed" (click)="isReferenceDocumentsCollapsed = !isReferenceDocumentsCollapsed" [attr.aria-expanded]="!isReferenceDocumentsCollapsed" aria-controls="collapseReferenceDocuments">Reference Documents<i class="fas fa-caret-down"></i></h5>
                    <h5 class="card-title pointer" *ngIf="isReferenceDocumentsCollapsed" (click)="isReferenceDocumentsCollapsed = !isReferenceDocumentsCollapsed" [attr.aria-expanded]="!isReferenceDocumentsCollapsed" aria-controls="collapseReferenceDocuments">Reference Documents <i class="fas fa-caret-up"></i></h5>

                    <ul class="nav flex-column bg-light" id="collapseReferenceDocuments" [ngbCollapse]="isReferenceDocumentsCollapsed">
                        <li class="nav-item" *ngIf="selectedMode==64">
                            <a href="/assets/{{strCurrentSSCodingManual}}" rel="noopener noreferrer" target="_blank" class="card-link">MTSS Coding Manual</a>
                        </li>
                        <li class="nav-item" *ngIf="selectedMode==128">
                            <a href="/assets/{{strCurrentSSCodingManual}}" rel="noopener noreferrer" target="_blank" class="card-link">PPSS Coding Manual</a>
                        </li>
                        <li class="nav-item" *ngIf="selectedMode==256">
                            <a href="/assets/{{strCurrentSSCodingManual}}" rel="noopener noreferrer" target="_blank" class="card-link">MOSS Coding Manual</a>
                        </li>
                        <li class="nav-item" *ngIf="selectedMode==64 || selectedMode==128">
                            <a href="/assets/{{strCurrentFarsCrssCodingManual}}" rel="noopener noreferrer" target="_blank" class="card-link">FARS-CRSS Coding Manual</a>
                        </li>
                        <!--<li class="nav-item">
                            <a href="/assets/{{strCurrentFarsCrssPedBikeManual}}" rel="noopener noreferrer" target="_blank" class="card-link">Ped-Bike Coding Manual</a>
                        </li>
                        <li class="nav-item">
                            <a href="https://farsintranet.cdan.dot.gov/Commonlibrary/phonebook/Phonebook.pdf" rel="noopener noreferrer" target="_blank" class="card-link">FARS Phone Book</a>
                        </li>-->
                    </ul>
                </div>
            </div>
        </div>

        <div class="col pl-2 pl-sm-3 pl-md-3 pl-lg-0 pl-sl-0">
            <div [ngClass]="{'card border-dark margin-border' : !isPrintMode }">
                <div [ngClass]="{'card-body pt-1 bg-light margin-border' : !isPrintMode }">

                    <!--FARS-->
                    <app-case-header-fars *ngIf="selectedMode ==1"
                                          [isPrintMode]="isPrintMode"
                                          [stateString]="stateString"
                                          [acc]="acc"
                                          [invisibleENCaseNum]="invisibleENCaseNum"
                                          [hideNavForCloseCase]="hideNavForCloseCase"
                                          [stateNum]="stateNum"
                                          [accid]="accid"
                                          [edtLastUpdateDates]="edtLastUpdateDates"
                                          [blnIsEDTcase]="blnIsEDTcase"
                                          [caseStatusReviewFlag]="caseStatusReviewFlag"
                                          (selectcase)="handleSelectCase($event)">
                    </app-case-header-fars>

                    <!--CRSS-->
                    <app-case-header-crss *ngIf="selectedMode ==4"
                                          [isPrintMode]="isPrintMode"
                                          [stateString]="stateString"
                                          [acc]="acc"
                                          [invisibleENCaseNum]="invisibleENCaseNum"
                                          [hideNavForCloseCase]="hideNavForCloseCase"
                                          [stateNum]="stateNum"
                                          [accid]="accid"
                                          [edtLastUpdateDates]="edtLastUpdateDates"
                                          [blnIsEDTcase]="blnIsEDTcase"
                                          [caseStatusReviewFlag]="caseStatusReviewFlag"
                                          (selectcase)="handleSelectCase($event)">
                    </app-case-header-crss>

                    <!--MTSS-->
                    <app-case-header-mts *ngIf="selectedMode == 64"
                                         [isPrintMode]="isPrintMode"
                                         [stateString]="stateString"
                                         [acc]="acc"
                                         [invisibleENCaseNum]="invisibleENCaseNum"
                                         [hideNavForCloseCase]="hideNavForCloseCase"
                                         [stateNum]="stateNum"
                                         [accid]="accid"
                                         [edtLastUpdateDates]="edtLastUpdateDates"
                                         [blnIsEDTcase]="blnIsEDTcase"
                                         [caseStatusReviewFlag]="caseStatusReviewFlag"
                                         (selectcase)="handleSelectCase($event)">
                    </app-case-header-mts>

                    <!--PCSS-->
                    <app-case-header-mts *ngIf="selectedMode == 128"
                                         [isPrintMode]="isPrintMode"
                                         [stateString]="stateString"
                                         [acc]="acc"
                                         [invisibleENCaseNum]="invisibleENCaseNum"
                                         [hideNavForCloseCase]="hideNavForCloseCase"
                                         [stateNum]="stateNum"
                                         [accid]="accid"
                                         [edtLastUpdateDates]="edtLastUpdateDates"
                                         [blnIsEDTcase]="blnIsEDTcase"
                                         [caseStatusReviewFlag]="caseStatusReviewFlag"
                                         (selectcase)="handleSelectCase($event)">
                    </app-case-header-mts>

                    <!--MOSS-->
                    <app-case-header-mts *ngIf="selectedMode == 256"
                                         [isPrintMode]="isPrintMode"
                                         [stateString]="stateString"
                                         [caseStatus]="caseStatus"
                                         [acc]="acc"
                                         [invisibleENCaseNum]="invisibleENCaseNum"
                                         [hideNavForCloseCase]="hideNavForCloseCase"
                                         [stateNum]="stateNum"
                                         [accid]="accid"
                                         [edtLastUpdateDates]="edtLastUpdateDates"
                                         [blnIsEDTcase]="blnIsEDTcase"
                                         [caseStatusReviewFlag]="caseStatusReviewFlag"
                                         (selectcase)="handleSelectCase($event)">
                    </app-case-header-mts>

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 pr-2">
                            <!-- TAB HEADINGS -->
                            <ul class="nav nav-tabs border-dark border-left border-right bg-tb-header" *ngIf="!hideNavForCloseCase && !isPrintMode">
                                <!--Crash horizontal-->
                                <li *ngFor="let accLink of horizontalLinksGroupForCrash" class="nav-item mt-1 ml-1">
                                    <div *ngIf="accLink">
                                        <a class="nav-link bg-light " [routerLink]="['./crash', accLink.crashLinkHObj.AccID ]"
                                           [ngClass]="{'bg-white': linkCaseCrash.isActive === true }"
                                           [routerLinkActive]="['active']" #linkCaseCrash="routerLinkActive">
                                            <span class="text-dark">
                                                <span [ngClass]="{'text-decoration-none': linkCaseCrash.isActive === true, 'fw-bold' : linkCaseCrash.isActive === true}">Crash</span>
                                            </span>
                                        </a>
                                    </div>
                                </li>

                                <!-- Can't put more than one *ng structural directive on the same tag -->
                                <ng-container *ngIf="acc.Non_Occupants.lenght !== 0">
                                    <!--Non Occupant horizontal-->
                                    <li *ngFor="let nonOccLink of horizontalLinksGroupForNonOccupant" class="mt-1 ml-1">
                                        <div class="nav-item" *ngIf="nonOccLink">
                                            <a class="nav-link bg-light" [routerLink]="['./nonOccupant', nonOccLink.nonOccupantLinkHObj.PNumber ]"
                                               [ngClass]="{'bg-white': linkCasenonOccupant.isActive === true }"
                                               [routerLinkActive]="['active']" #linkCasenonOccupant="routerLinkActive">
                                                <span class="text-dark">
                                                    <span *ngIf="selectedMode == 64 || selectedMode == 128" [ngClass]="{'text-decoration-none': linkCasenonOccupant.isActive === true, 'fw-bold' : linkCasenonOccupant.isActive === true}">
                                                        Non-Occupant_{{ nonOccLink.nonOccupantLinkHObj.PNumber }}
                                                        <i class="fas fa-asterisk pl-2" *ngIf="nonOccLink.nonOccupantLinkHObj.Injury === 4"></i>
                                                    </span>
                                                    <span *ngIf="selectedMode == 256" [ngClass]="{'text-decoration-none': linkCasenonOccupant.isActive === true, 'fw-bold' : linkCasenonOccupant.isActive === true}">
                                                        Non_Occupant_Subject_{{ nonOccLink.nonOccupantLinkHObj.PNumber }}
                                                        <i class="fas fa-asterisk pl-2" *ngIf="nonOccLink.nonOccupantLinkHObj.Injury === 5"></i>
                                                    </span>
                                                </span>

                                            </a>
                                        </div>
                                    </li>
                                </ng-container>
                                <!--Striking Vehicle horizontal-->
                                <li *ngFor="let vehicleLink of horizontalLinksGroupForStrikingVehicle" class="mt-1 ml-1">
                                    <div class="nav-item" *ngIf="vehicleLink && selectedMode == 256">
                                        <a class="nav-link bg-light" [routerLink]="['./strikingvehicle', vehicleLink.vehLinkStrikingRObj.VNumber]"
                                           [ngClass]="{'bg-white': linkCaseStrikingvehicle.isActive === true }"
                                           [routerLinkActive]="['active']" #linkCaseStrikingvehicle="routerLinkActive">
                                            <span class="text-dark">
                                                <span [ngClass]="{'text-decoration-none': linkCaseStrikingvehicle.isActive === true, 'fw-bold' : linkCaseStrikingvehicle.isActive === true}">
                                                    Striking_Vehicle_{{ vehicleLink.vehLinkStrikingRObj?.Veh_SS.SSVNumber }}
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </li>
                                <!--SFR Vehicle horizontal-->
                                <li *ngFor="let vehicleLink of horizontalLinksGroupForSFRVehicle" class="mt-1 ml-1">
                                    <div class="nav-item" *ngIf="vehicleLink && selectedMode == 256">
                                        <a class="nav-link bg-light" [routerLink]="['./sfrvehicle', vehicleLink.vehLinkSFRObj.VNumber]"
                                           [ngClass]="{'bg-white': linkCaseSFRvehicle.isActive === true }"
                                           [routerLinkActive]="['active']" #linkCaseSFRvehicle="routerLinkActive">
                                            <span class="text-dark">
                                                <span [ngClass]="{'text-decoration-none': linkCaseSFRvehicle.isActive === true, 'fw-bold' : linkCaseSFRvehicle.isActive === true}">
                                                    Struck_Subject_Vehicle _{{ vehicleLink.vehLinkSFRObj?.Veh_SS.SSVNumber }}
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </li>
                                <!--Other Vehicle horizontal-->
                                <li *ngFor="let vehicleLink of horizontalLinksGroupForVehicle" class="mt-1 ml-1">
                                    <div class="nav-item" *ngIf="vehicleLink && selectedMode == 256">
                                        <a class="nav-link bg-light" [routerLink]="['./vehicle', vehicleLink.vehLinkHObj.VNumber]"
                                           [ngClass]="{'bg-white': linkCasevehicle.isActive === true }"
                                           [routerLinkActive]="['active']" #linkCasevehicle="routerLinkActive">
                                            <span class="text-dark">
                                                <span [ngClass]="{'text-decoration-none': linkCasevehicle.isActive === true, 'fw-bold' : linkCasevehicle.isActive === true}">
                                                    Other_Vehicle_{{ vehicleLink.vehLinkHObj?.Veh_SS.SSVNumber }}
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </li>

                                <!--Vehicle horizontal-->
                                <li *ngFor="let vehicleLink of horizontalLinksGroupForVehicle" class="mt-1 ml-1">
                                    <div class="nav-item" *ngIf="vehicleLink && selectedMode != 256">
                                        <a class="nav-link bg-light" [routerLink]="['./vehicle', vehicleLink.vehLinkHObj.VNumber]"
                                           [ngClass]="{'bg-white': linkCasevehicle.isActive === true }"
                                           [routerLinkActive]="['active']" #linkCasevehicle="routerLinkActive">
                                            <span class="text-dark">
                                                <span [ngClass]="{'text-decoration-none': linkCasevehicle.isActive === true, 'fw-bold' : linkCasevehicle.isActive === true}">
                                                    Vehicle_{{ vehicleLink.vehLinkHObj?.VNumber }}
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </li>

                                <!--Avoidance Equipment horizontal-->
                                <li *ngFor="let avoidanceEquipmentLink of horizontalLinksGroupForVehicle" class="mt-1 ml-1">
                                    <div class="nav-item" *ngIf="avoidanceEquipmentLink && selectedMode != 256">
                                        <a class="nav-link bg-light" [routerLink]="['./vehicleAvoidEquip', avoidanceEquipmentLink.vehLinkHObj.VNumber, 'ae', 'avoidanceEquipment']"
                                           [ngClass]="{'bg-white': linkHorAvoidanceEquipment.isActive === true }"
                                           [routerLinkActive]="['active']" #linkHorAvoidanceEquipment="routerLinkActive">
                                            <span class="text-dark">
                                                <span [ngClass]="{'text-decoration-none': linkHorAvoidanceEquipment.isActive === true, 'fw-bold' : linkHorAvoidanceEquipment.isActive === true}">
                                                    Avoidance_V{{ avoidanceEquipmentLink.vehLinkHObj?.VNumber }}
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </li>
                            </ul>

                            <!--here we generate and show Case-children html's components-->
                            <router-outlet></router-outlet>

                        </div>
                        <div class="col px-0">
                            <div class="mr-3">
                                <div class="" *ngIf="!isPrintMode">
                                    <app-action-buttons class=""></app-action-buttons>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
