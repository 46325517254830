import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { NonOcc_Bike } from 'src/app/models/non-occ-bike';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bikecopydata',
  templateUrl: './bikecopydata.component.html',
    styleUrls: ['./bikecopydata.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class BikecopydataComponent implements OnInit {

    filteredBikeLookupData: Array<DrpDownOptions> = [];
    allExisitingBikeList: Array<NonOcc_Bike> = [];
    tmpGrid: Array<TmpGridData> = [];
    noCopyAvailable: boolean = true;

    constructor(public modal: NgbActiveModal) { }

    ngOnInit() {
        this.loadData();
  }

    loadData() {
        if (this.allExisitingBikeList.length > 0) {
            this.tmpGrid = [];
            this.allExisitingBikeList.forEach(item => {
                this.fillTmpGridData(item);
            })
        }
        this.noCopyAvailable = this.allExisitingBikeList.length == 0;
    }
    fillTmpGridData(item: any) {
        const tmpGrid = {} as TmpGridData;
        tmpGrid.PNumber = item.PNumber;
        tmpGrid.CrashLocation = this.getTitleFromLookup(item.CrashLocation, 'PBCAT_BikeCrashLocation')
        tmpGrid.BikePosition = this.getTitleFromLookup(item.BikePosition, 'PBCAT_BikePosition')
        tmpGrid.BikeDirection = this.getTitleFromLookup(item.BikeDirection, 'PBCAT_BikeDirection')
        tmpGrid.CrashType = this.getTitleFromLookup(item.CrashType, 'PBCAT_BikeCrashType')
        tmpGrid.SchoolZone = this.getTitleFromLookup(item.SchoolZone, 'PBCAT_SchoolZone')
        tmpGrid.Crosswalk = this.getTitleFromLookup(item.Crosswalk, 'PBCAT_CrossWalk')
        tmpGrid.Sidewalk = this.getTitleFromLookup(item.Sidewalk, 'PBCAT_SideWalk')
        tmpGrid.RowItem = item;
        this.tmpGrid.push(tmpGrid);
    }
    getTitleFromLookup(fieldValue: any, tableName: any) {
        if (fieldValue == undefined || fieldValue == null) return '-';
        const field = this.filteredBikeLookupData.find(x => x.intValue == fieldValue && x.tblName == tableName)
        if (field != null && field != undefined) {
            return field.displayText;
        } else {
            return '-';
        }
    }

}

export interface TmpGridData {
    PNumber: number
    CrashLocation: string;
    BikePosition: string;
    BikeDirection: string;
    CrashType: string;
    SchoolZone: string;
    Crosswalk: string;
    Sidewalk: string;
    RowItem: any;
}

