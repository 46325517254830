import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//services
import { AutofillService } from 'src/app/services/autofill.service';
import { CaseService } from 'src/app/services/case.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';

//helper
import { BaseComponent } from 'src/app/helper/basecomponent';

//pipes
import { ValueToDescriptionPipe } from 'src/app/pipes/value-to-description.pipe';

//models
import { Acc } from 'src/app/models/acc';
import { Veh } from 'src/app/models/veh';
import { Dri } from 'src/app/models/dri';

//basecomponents
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';

@Component({
    selector: 'app-vehicle-driver-notes',
    templateUrl: './vehicle-driver-notes.component.html',
    styleUrls: ['./vehicle-driver-notes.component.css']
})

export class VehicleDriverNotesComponent extends BaseComponent implements OnInit, AfterViewInit {
    private _TypeScript_TypeGuard_VehicleDriverNotesComponent: string = null;
    stateNum: number;
    accid: number;
    vehicleid: number;

    acc: Acc;
    veh: Veh;
    dri: Dri;

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _sharedDataService: SharedDataService,
        protected _modalService: ModalService,
        private _utilService: UtilService,
        private _valueToDescriptionPipe: ValueToDescriptionPipe,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
    }

    public async onBeforeSave() {
        this.blnAlloweSave = true;
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.printVehNum == 0) {
            this._route.parent.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
            this._route.parent.parent.params.subscribe(params => {
                this.vehicleid = + params['vehicleid'];
                this.initData();
            });
        } else {
            this.accid = this.acc.AccID;
            this.vehicleid = this.printVehNum;
            this.initData();
        }
    }

    initData() {
        this.veh = this.acc.Veh.find(v => v.VNumber == this.vehicleid);
        if (!this.veh.Dri) {
            this.dri = {} as Dri;
        }
        else {
            this.dri = this.acc.Veh.find(v => v.VNumber == this.vehicleid).Dri;
            console.log("this.dri: ", this.dri);
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
}
