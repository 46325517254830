import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, UrlSegment } from '@angular/router';

import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';
import { SecUserPerferenceService } from 'src/app/services/secUserPerference.service'
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { LookupTable } from 'src/app/models/enums/Generated/LookupTable';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { DBMode } from 'src/app/models/enums/app.enums';

@Component({
    selector: 'app-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {

    userPreferences: Array<any> = [];
    userEDTPreferences: Array<any> = [];
    isEDTstate: boolean;
    stateNum: number
    appSettings: AppSettings;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilService: UtilService,
        public _sharedDataService: SharedDataService,
        private _preferencesServices: SecUserPerferenceService,
        private _modalService: ModalService,
        private _urlTreeHelper: UrlTreeHelper

    ) { }

    async ngOnInit() {
        this._modalService.setEditCheckMessage('');
        this.appSettings = await this._sharedDataService.GetAppSettings();
        this._route.params.subscribe((params => {
            this.stateNum = +params['stateNum'];
            if (!this._sharedDataService.selectedState) {
                this._sharedDataService.setSelectedState(this.stateNum);
            }
            this._utilService.GetDrpDownListOptions("StateNum", '').subscribe(objResult => {
                if (objResult) {
                    let arrStates: Array<number> = [];
                    if (objResult) {
                        objResult.forEach(item => {
                            arrStates.push(item.intValue);
                        });
                        this._sharedDataService.setListofValidStates(arrStates);
                    }
                    let ddoSelectedState = objResult.find(x => x.intValue === this.stateNum);
                    if (ddoSelectedState) {
                        this._sharedDataService.setDDOSelectedState(ddoSelectedState);
                        this._sharedDataService.subjectSelectedState.next(ddoSelectedState);
                    }
                }
            });
        }).bind(this));

        this.userPreferences = [];
        //this.isEDTstate = this._sharedDataService.getIsEDTState();
        let arrUrlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment;
        let intStateNum: number = UrlTreeHelper.FindAndSetStateNum('preferences', arrUrlSegments);

        this._utilService.formDrpDownOptionShare.subscribe(objResult => {
            if (objResult) {

                let objState: DrpDownOptions;
                objState = objResult.filter(x => x.tblName == LookupTable.StateNum).find(i => i.intValue == intStateNum);
                if (objState)
                    this.isEDTstate = (this.appSettings && this.appSettings.intMode == DBMode.CRSS)
                        ? objState.ENABLE_CRSS_EDT
                        : objState.ENABLE_EDT;
            }
        })
        this._preferencesServices.getSecUserPreferences().subscribe(dataset => {
            dataset.forEach((item) => {
                if (item.IsEDT) {
                    this.userEDTPreferences.push(item);
                }
                else {
                    this.userPreferences.push(item);
                }
            })
        });
    }

    togglePrefOnOff(index) {
        this.userPreferences[index].PreferenceValue = !this.userPreferences[index].PreferenceValue;
    }

    toggleEDTPrefOnOff(index) {
        this.userEDTPreferences[index].PreferenceValue = !this.userEDTPreferences[index].PreferenceValue;
    }

    onSave() {
        this._preferencesServices.saveSecUserPreferences(this.userPreferences);
        if (this.isEDTstate) {
            this._preferencesServices.saveSecUserPreferences(this.userEDTPreferences);
        }
    }

    onBack() {
        window.history.back();
    }
}
