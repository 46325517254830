import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
//rxjs
import { Subscription } from 'rxjs';
//services
import { CaseService } from 'src/app/services/case.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { AutofillService } from 'src/app/services/autofill.service';

//models
import { Acc } from 'src/app/models/acc';
import { Non_Occupants } from 'src/app/models/non-occupants';
import { NonOccDrug } from 'src/app/models/non-occ-drug';
import { DrugTestStatus } from 'src/app/models/enums/app.enums';

//components
import { TypeaheadComponent } from 'src/app/components/typeahead/typeahead.component';
import { BaseComponent } from 'src/app/helper/basecomponent';

//helpers
import { ObjectUtil } from 'src/app/helper/objectUtil';
import { UrlTreeHelper } from 'src/app/helper/UrlTreeHelper';
import { FieldName } from 'src/app/models/enums/Generated/LookupTable';
import { DrpDownOptions } from 'src/app/models/drp-down-options';
import { UIElementBase } from 'src/app/helper/UIElementBase';

@Component({
    selector: 'app-non-occupant-drug',
    templateUrl: './non-occupant-drug.component.html',
    styleUrls: ['./non-occupant-drug.component.css']
})

export class NonOccupantDrugComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _TypeScript_TypeGuard_NonOccupantDrugComponent: string = null;
    private sbsNonOccId: Subscription;
    sbsClearForm: Subscription;
    blnClearBtnClicked: boolean;

    accid: number;
    nonOccid: number;

    acc: Acc;
    nonOccupant: Non_Occupants;

    NonOccDrug: NonOccDrug;

    objNon_OccupantsClone: Non_Occupants;
    addResult: number;
    blnDisabled: boolean = false;

    @ViewChildren(TypeaheadComponent) typeaheads: QueryList<TypeaheadComponent>;
    UIControls: UIElementBase[];

    constructor(
        private _route: ActivatedRoute,
        protected _caseService: CaseService,
        private _utilService: UtilService,
        protected _modalService: ModalService,
        private _sharedDataService: SharedDataService,
        private _autofillService: AutofillService,
        protected _urlTreeHelper: UrlTreeHelper
    ) {
        super(_route, _sharedDataService, _modalService, _utilService, _urlTreeHelper, _caseService);
        this.blnFocusOnFirstField = true;
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.printNonOccupantNum == 0) {
            this._route.parent.parent.params.subscribe(params => {
                this.accid = + params['caseid'];
            });
        }
        else {
            this.accid = this.acc.AccID;
        }

        if (this.sbsNonOccId) {
            this.sbsNonOccId.unsubscribe();
        }

        if (this.printNonOccupantNum == 0) {
            this._route.parent.params.subscribe(params => {
                this.nonOccid = + params['nonOccid'];
                this.FormLoad();
            });
        } else {
            this.nonOccid = this.printNonOccupantNum;
            this.FormLoad();
        }
    }

    FormLoad() {
        this.addResult = null;

        this.nonOccupant = this.acc.Non_Occupants.find(v => v.PNumber == this.nonOccid);
        if ([DrugTestStatus.Blank, DrugTestStatus.TestNotGiven, DrugTestStatus.NotReported, DrugTestStatus.ReportedAsUnknownIfTested].includes(this.nonOccupant.DrugSts)) {
            this.blnDisabled = true;
        }
        else {
            this.blnDisabled = false;
        }
        if (this.nonOccupant.NonOccDrug.length == 0) {
            this._autofillService.AddNonOccDrug(this.nonOccupant);
        }
        this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);

        this.sbsClearForm = this._sharedDataService.subjectClearForm.subscribe((() => {

            const urlSegments: UrlSegment[] = this._urlTreeHelper.arrUrlSegment;
            if (UrlTreeHelper.FindAndSetCurrentTab(urlSegments) === "drug") {
                if (this.acc) {
                    this.nonOccupant.DrugSts = -1;
                    let uiControl: TypeaheadComponent = AutofillService.arrControls.find(x => x.strFieldName == "DrugSts") as TypeaheadComponent;
                    let item: DrpDownOptions = uiControl.options.find(x => x.intValue == this.nonOccupant.DrugSts);
                    uiControl.bindItemAndFocusNextElement(item);

                    this.removeEmptyNonOccDrug();
                }
            }

        }).bind(this));

    }


    async OnModelChange($event) {
        if (this.objNon_OccupantsClone.DrugSts != this.nonOccupant.DrugSts) {
            this.UIControls = this._autofillService.arrControls;
            let uiControls = this.UIControls.filter(i => i.strFieldName !== FieldName.MethDrug && i.strFieldName !== FieldName.DrugSts && i.strFieldName !== FieldName.DrugInv);

            if ([DrugTestStatus.Blank, DrugTestStatus.TestNotGiven, DrugTestStatus.NotReported, DrugTestStatus.ReportedAsUnknownIfTested].includes($event.intValue)) {
                this.blnDisabled = true;
                setTimeout((() => {
                    uiControls.forEach(x => x.RerenderModelValue(this.blnDisabled));
                }).bind(this), 1);
            }
            else {
                this.blnDisabled = false;
                setTimeout((() => {
                    uiControls.forEach(x => x.RerenderModelValue(this.blnDisabled));
                    if (this.nonOccupant.DrugSts === 2) {
                        let item = this.UIControls.find(x => x.strFieldName == "DrugTst" && x.intSeqNum == 1);
                        item.objTextbox.nativeElement.click();
                        item.objTextbox.nativeElement.focus();
                    }

                }).bind(this), 1);
            }

            this.addResult = null;

            let blnPrompt: boolean;

            if (this.nonOccupant.DrugSts === -1) {
                if (this.objNon_OccupantsClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Clearing the Drug Test Status will clear all Drug Specimen/Drug Test Result for this person. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
                }
            }
            else if (this.nonOccupant.DrugSts === 0) {
                if (this.objNon_OccupantsClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Changing the Drug Test Status to " + this.nonOccupant.DrugSts + " will clear all Drug Specimen/Drug Test Result for this person and create only one row with TEST NOT GIVEN values. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
                }
            }
            else if (this.nonOccupant.DrugSts === 2) {
                if (this.objNon_OccupantsClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Changing the Drug Test Status to " + this.nonOccupant.DrugSts + " will clear all Drug Specimen/Drug Test Result for this person. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
                }
            }
            else if (this.nonOccupant.DrugSts === 8) {
                if (this.objNon_OccupantsClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Changing the Drug Test Status to " + this.nonOccupant.DrugSts + " will clear all Drug Specimen/Drug Test Result for this person and create only one row with NOT REPORTED values. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
                }
            }
            else if (this.nonOccupant.DrugSts === 9) {
                if (this.objNon_OccupantsClone.DrugSts !== -1) {
                    blnPrompt = await this._modalService.dialogPromise("Changing the Drug Test Status to " + this.nonOccupant.DrugSts + " will clear all Drug Specimen/Drug Test Result for this person and create only one row with REPORTED AS UNKNOWN IF TESTED values. Are you sure you want to change the Drug Test Status?", "Please confirm");
                    this.OnConformationPrompt(blnPrompt);
                }
                else {
                    this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
                }
            }
        }

    }

    OnConformationPrompt(blnPrompt) {
        setTimeout((() => { // using timeout so that autofill can kick in
            if (!blnPrompt) { // dialogPromise No
                this.nonOccupant = ObjectUtil.CloneModelObject(this.objNon_OccupantsClone, "Non_Occupants");
                //We are reattaching reverted clone to the main data model;
                let item = this.acc.Non_Occupants.findIndex(p => p.PNumber == this.nonOccupant.PNumber);
                this.acc.Non_Occupants.splice(item, 1, this.nonOccupant);

                this.nonOccupant.Acc = this.acc;

                this.renderBackDrugSts(this.nonOccupant.DrugSts);

                if (this.nonOccupant.NonOccDrug.length > 1) {
                    setTimeout((() => {
                        this.RenumberArrayObjects();

                        setTimeout((() => {
                            this._autofillService.RerenderModelValues();
                        }).bind(this), 1);

                    }).bind(this), 1);
                }
            }
            else { // dialogPromise Yes
                this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
            }

            this.removeEmptyNonOccDrug();
        }).bind(this), 1);
    }

    OnNonOccDrugModelChange(seqNum: number, strFieldName: string) {

        let UIControl = this._autofillService.arrControls.find(i => i.strFieldName === strFieldName && i.intSeqNum === seqNum);
        if (UIControl) {
            UIControl.setIsInvalidClass(false);
        }
        setTimeout((() => { //We are cloning Clone from the original in order to pick up values of NonOccDrug array after autofil kick in;
            if (this.objNon_OccupantsClone.DrugSts == this.nonOccupant.DrugSts) {
                this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
            }
        }).bind(this), 1);

    }

    renderBackDrugSts(intNum: number) {
        let typeahead: TypeaheadComponent = this._autofillService.arrControls.find(x => x.strFieldName == FieldName.DrugSts) as TypeaheadComponent;
        let item = typeahead.options.find(x => x.intValue == intNum);

        typeahead.bindItemAndFocusNextElement(item);
    }

    public async onBeforeSave() {
        if (this.nonOccupant.DrugSts === 2) {
            if (!this.validateNonOccDrug()) {
                this.blnAlloweSave = false;
                return;
            }
            else {
                this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
                this.blnAlloweSave = true;
            }
        } else {
            this.removeEmptyNonOccDrug();
            this.objNon_OccupantsClone = ObjectUtil.CloneModelObject(this.nonOccupant);
            this.blnAlloweSave = true;
        }
    }

    validateNonOccDrug(): boolean {
        let blnIsValid: boolean = true;
        let UIControls = this._autofillService.arrControls.filter(i => i.strFieldName === FieldName.DrugTst || i.strFieldName === FieldName.DrugRes);

        if (UIControls) {
            UIControls.forEach(item => {
                if (item.objModel[item.strFieldName] === -1 && this.nonOccupant.DrugSts !== -1) {
                    item.setIsInvalidClass(true);
                    blnIsValid = false;
                    this._modalService.setMessage('Range Error: Invalid Entry', 'danger')
                }
            });
            this._autofillService.RerenderModelValues();
        }
        else {
            blnIsValid = true;
        }
        return blnIsValid
    }

    /**     
     * Removing NonOccDrug objects from an array where DrugTst AND DrugRes are blank(-1) and length > 1;
     **/
    removeEmptyNonOccDrug() {
        let arrNonOccDrug = this.acc.Non_Occupants.find(x => x.AccID == this.nonOccupant.AccID && x.PNumber === this.nonOccupant.PNumber).NonOccDrug
        if (arrNonOccDrug.length > 1) {
            arrNonOccDrug.forEach(item => {
                if (item.DrugTst === -1 && item.DrugRes === -1 && item.SeqNum !== 1) {
                    this.onDeleteRow(item.SeqNum);
                }
            });
        }
    }

    onAddResults(rowsNumber) {
        if (rowsNumber) {
            for (let i = 0; i < rowsNumber; i++) {
                this.NonOccDrug = {
                    AccID: this.nonOccupant.AccID,
                    PNumber: this.nonOccupant.PNumber,
                    SeqNum: this.nonOccupant.NonOccDrug.length + 1,
                    DrugTst: -1,
                    DrugRes: -1
                } as NonOccDrug;
                this.nonOccupant.NonOccDrug.push(this.NonOccDrug);
            }

            let subInit: Subscription = UIElementBase.evtSiblingsInitialized.subscribe((() => {
                subInit.unsubscribe();
                let uiControl: TypeaheadComponent = AutofillService.arrControls.find(i => i.strFieldName !== FieldName.MethDrug
                    && i.strFieldName !== FieldName.DrugSts
                    && i.strFieldName !== FieldName.DrugInv
                    && i.objTextbox.nativeElement.value == "") as TypeaheadComponent;

                uiControl.objTextbox.nativeElement.click();
                uiControl.objTextbox.nativeElement.focus();
            }).bind(this));


            this.MarkFormAsDirty();
        }
        this.addResult = null;
    }

    onAddRow(intIndex: number): void {

        let objNonOccDrug = {} as NonOccDrug;
        objNonOccDrug._TypeScript_TypeGuard_NonOccDrug = null;
        objNonOccDrug.AccID = this.nonOccupant.AccID;

        objNonOccDrug.PNumber = this.nonOccupant.PNumber;
        objNonOccDrug.SeqNum = intIndex + 1;

        objNonOccDrug.DrugTst = -1;
        objNonOccDrug.DrugRes = -1;

        for (let i = intIndex; i < this.nonOccupant.NonOccDrug.length; i++)
            this.nonOccupant.NonOccDrug[i].SeqNum++;

        this.nonOccupant.NonOccDrug.push(objNonOccDrug);

        if (this.nonOccupant.NonOccDrug.length > 1) {
            setTimeout((() => {
                //ngFor gets confused when an insertion happens in the middle of the data source and recreates the DOM
                //for the next row but does not reevaluate data bindings
                //Workaround is to insert at the end and then move into position after a trivial delay.
                this.MoveRow(this.nonOccupant.NonOccDrug.length - 1, intIndex);
                setTimeout((() => {
                    this._autofillService.RerenderModelValues();
                    let objFirstCell: TypeaheadComponent = this._autofillService.arrControls.filter(i => i.strFieldName !== FieldName.MethDrug
                        && i.strFieldName !== FieldName.DrugSts && i.intSeqNum === intIndex + 1)[0] as TypeaheadComponent;
                    objFirstCell.objTextbox.nativeElement.click();
                    objFirstCell.objTextbox.nativeElement.focus();

                }).bind(this), 1);

            }).bind(this), 1);
        }
        this.MarkFormAsDirty();

    }

    onDeleteRow(SeqNum: number) {
        this.nonOccupant.NonOccDrug = this.nonOccupant.NonOccDrug.filter(x => x.SeqNum !== SeqNum);
        this.RenumberArrayObjects(); //Removal could be from the end of the collection or from the middle of the
        //collection
        this.MarkFormAsDirty();
    }

    onClearRow(intIndex: number) {

        intIndex = intIndex + 1;
        let typeAheads = this._autofillService.arrControls.filter(i => i.intSeqNum == intIndex);

        if (typeAheads) {
            typeAheads.forEach(typ => {
                typ.clearComponent();
            })
        }
        this.MarkFormAsDirty();
    }

    private MoveRow(i: number, j: number): void {
        // ngFor is listening for inserts and deletions, the default tracker
        // will not see an inplace index swap as two operations
        let objRowToMove: NonOccDrug = this.nonOccupant.NonOccDrug[i];
        this.nonOccupant.NonOccDrug.splice(i, 1);
        this.nonOccupant.NonOccDrug.splice(j, 0, objRowToMove);
        this.RenumberArrayObjects();
    }

    private RenumberArrayObjects() {
        for (let i = 0; i < this.nonOccupant.NonOccDrug.length; i++) {
            this.nonOccupant.NonOccDrug[i].SeqNum = i + 1;
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        if (this.sbsClearForm)
            this.sbsClearForm.unsubscribe();
    }

    /**
     * If the Drug Test Status as value 2-Test Given change to other option we will remove results from this.nonOccupant.NonOccDrug and hide grid,
     * otherwise we keep as it is.
     * @param objSelection
     * @param content
     */

    //ngAfterViewInit(): void {
    //    super.ngAfterViewInit();
    //}
}


