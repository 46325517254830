import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SharedDataService, AppSettings } from 'src/app/services/shared-data.service';

import { Subscription } from 'rxjs';
import { DrpDownOptions } from 'src/app/models/drp-down-options';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {
    isExpanded = false;

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }

    selectedYear: number; 
    selectedMode: number;

    stateNum: number;
    mailPendingRequests: number;
    blnNavbarItemsVisibleState: boolean;

    blnIsEDTstate: boolean;
    blnIsCRSSEDTstate: boolean;

    subsSubjectSelectedState: Subscription;
    
    constructor(
        private _sharedDataService: SharedDataService,
        private _router: Router,
    )
    {
    }

    public async ngOnInit(): Promise<void> {
        try {
            let appSettings: AppSettings = await this._sharedDataService.GetAppSettings();

            if (appSettings) {
                this.selectedYear = appSettings.intYear;
                this.selectedMode = appSettings.intMode;

                this.blnIsEDTstate = false;

                //having subscription on subjectSelectedState to show or hide Head Navbar links based on User selected state(logged in) 
                this.subsSubjectSelectedState = this._sharedDataService.subjectSelectedState.subscribe(((objSelection: DrpDownOptions) => {
                    if (objSelection && objSelection != null && objSelection != undefined) {

                        this.blnNavbarItemsVisibleState = true;
                        this.stateNum = objSelection.intValue;

                        if (objSelection.ENABLE_EDT === true) {
                            this.blnIsEDTstate = true;
                        }
                        else {
                            this.blnIsEDTstate = false;
                        }
                    }
                    else {
                        this.blnNavbarItemsVisibleState = false;
                        console.log('Logged out:', objSelection);
                    }
                }).bind(this));
            }
        }
        catch (ex) {
            console.log(ex); //Silently consume exception if it was Authentication challenge
        }
    }

    onClickCases() {
        this._router.navigate(['earlyNotification', 'fatalCaseScreener']);
      //  this._router.navigate(['earlyNotification', this._sharedDataService.getSelectedState(), 'fatalCaseScreener']);
    }

    OnGoToCaseSearchScreener() {
        this._router.navigate(['crss', 'CaseSearchScreener']);
    }

    OnSearchEDT() {
        this._router.navigate(['edt', this.stateNum, 'EDTSearch']);
    }

    OnGoToBatchProcessing() {
        this._router.navigate([this.stateNum, 'batchProcessing']);
    }

    //OnGoToVINRequests() {
    //    if (this.selectedMode == 4) {
    //        this._router.navigate(['vin-requests']);
    //    } else {
    //        this._router.navigate([this.stateNum, 'vin-requests']);
    //    }
    //}

    onFARS() {
        let url: string = window.location.href;
        if ((url.includes('/cases/')) || (url.includes('/caseFiles/')) || (url.includes('/closed'))) {
            document.getElementById("SearchBoxGo").click();
        }
        else if (url.includes('/case/')) {
            document.getElementById("tabCrash").click();
        }
        else {
            document.getElementById("lnkHome").click();
        }        
    }

    OnGoToReports() {
        this._router.navigate([this.stateNum, 'reports']);
    }

    //OnGoTomonthlyCounts() {
    //    this._router.navigate([this.stateNum, 'monthlyCounts']);
    //}

    //OnGoToMail() {
    //    this._router.navigate([this.stateNum, 'mail']);
    //}

    OnGoToHelp() {
        //this._router.navigate([this.stateNum, 'help']);
        this._router.navigate(['help']);
    }

    OnGoToPARRequests() {
        this._router.navigate([this.stateNum, 'parRequests']);
        //this._router.navigate(['parRequests']);
    }

    //OnGoToPreferences() {// We need to set :stateNum to 0 if there is user is not selected State(FARS) or PSU(CRSS) because of URL needs it
    //    if (this.selectedMode == 4) {
    //        this.stateNum = this.stateNum == undefined ? 0 : this.stateNum;
    //    }
    //    this._router.navigate([this.stateNum, 'preferences']);
    //}

    OnGoToPreferencesCRSS() {
        this._router.navigate(['preferences']);
    }

    ngOnDestroy() {
        this.subsSubjectSelectedState.unsubscribe();
    }
}
