<form novalidate #fnonoccalcohol="ngForm">
    <div class="pt-2" *ngIf="nonOccupant && intMode">

        <div [ngClass]="{'row' : printOnly === true}">
            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="nonalcInvol" name="nonalcInvol" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="AlcInvol"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div  #typAhdMethAlc *ngIf="intMode == 1 || intMode == 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="methAlc" name="methAlc" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="MethAlc"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData"
                                   [intDisabledMode]="4">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="nonalcSts" name="nonalcSts" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="AlcSts"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="nonalcTst" name="nonalcTst" typeAheadPosition="right-top"
                                   [objModel]="nonOccupant" strTableName="Non_Occupants" strFieldName="AlcTst"
                                   [printOnly]="printOnly"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>

            <div [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group">
                    <div [ngClass]="{'row' : printOnly !== true}">
                        <div [ngClass]="{'col-12 col-xs-12 col-sm-12 col-md-12 col-xl-2 my-auto' : printOnly !== true }">
                            <div [ngClass]="{'px-2' : printOnly !== true }">
                                <app-label class="my-auto" inputName="AlcRes" [printOnly]="printOnly"></app-label>
                                <div class="my-auto" *ngIf="!printOnly">
                                    <app-modals-alcohol-calculator #alcElement *ngIf="!blnReadOnly" strForm="NonOccpantAlcohol" (submitAlcoholValue)="setAlcoholValue($event)"></app-modals-alcohol-calculator>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col col-xs-12 col-sm-12 col-md-12 col-xl-10 my-auto' : printOnly !== true }">
                            <div [ngClass]="{'pl-2' : printOnly !== true}">
                                <app-typeahead id="nonalcRes" name="nonalcRes" typeAheadPosition="right-top" disableLabel="true"
                                               [objModel]="nonOccupant" #alcTestResult (keyup)="handleKeyup($event)"
                                               strTableName="Non_Occupants"
                                               strFieldName="AlcRes" strDefsLookupTableName="AlcRes"
                                               [printOnly]="printOnly"
                                               [showAllDataListForPrintOnly]="false"
                                               [clearFieldData]="clearFieldData">
                                </app-typeahead>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #typAhdDrugInv *ngIf="!hideDrugInvolvement && intMode != 128" [ngClass]="{'col-6 border border-dark' : printOnly === true}">
                <div class="form-group pl-2">
                    <app-typeahead id="NonOccupantDrugInv"
                                   name="DrugInv"
                                   typeAheadPosition="right-top"
                                   [objModel]="nonOccupant"
                                   strTableName="Non_Occupants"
                                   strFieldName="DrugInv"
                                   [printOnly]="printOnly"
                                   [showAllDataListForPrintOnly]="false"
                                   [clearFieldData]="clearFieldData">
                    </app-typeahead>
                </div>
            </div>
        </div>
    </div>
</form>
