import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { CaseCreateComponent }        from './case-create/case-create.component';
import { CaseEditComponent }          from './case-edit/case-edit.component';
import { CaseDeleteComponent }        from './case-delete/case-delete.component';
import { CaseClosedComponent }        from './case-close/case-close.component';
import { AskCodingQuestionComponent } from './ask-coding-question/ask-coding-question.component';

//Case components
import { CaseComponent } from './case.component';

//Check Case components
import { CheckCaseComponent }       from './../check-case/check-case.component';
import { ViolatedRulesComponent }   from './../check-case/violated-rules/violated-rules.component';
import { OverriddenRulesComponent } from './../check-case/overridden-rules/overridden-rules.component';
import { BlanksComponent }          from './../check-case/blanks/blanks.component';
import { OverrideRuleComponent }    from './../check-case/override-rule/override-rule.component';

//Early Notification
import { EarlyNotificationDetailsComponent } from './../early-notification/early-notification-details/early-notification-details.component';

//Crash components
import { CrashComponent }           from './../crash/crash.component';
import { CrashCrashComponent }      from './../crash/crash-crash/crash-crash.component';
import { CrasheventlistComponent }  from './../crash/crash-events/crasheventlist/crasheventlist.component';
import { CrashTrafficwayComponent } from './../crash/crash-trafficway/crash-trafficway.component';
import { CrashOtherComponent }      from './../crash/crash-other/crash-other.component';

//MTS Crash Components
import { CrashNotesComponent }  from 'src/app/ui/crash/crash-notes/crash-notes.component';
import { NonMotoristComponent } from 'src/app/ui/crash/non-motorist/non-motorist.component';

//NonOccupant components
import { NonOccupantComponent }                   from 'src/app/ui/non-occupant/non-occupant.component';
import { NonOccupantNonOccupantComponent }        from 'src/app/ui/non-occupant/non-occupant-non-occupant/non-occupant-non-occupant.component';
import { NonOccupantNonMotoristActionsComponent } from 'src/app/ui/non-occupant/non-occupant-non-motorist-actions/non-occupant-non-motorist-actions.component';
import { NonOccupantAlcoholComponent }            from 'src/app/ui/non-occupant/non-occupant-alcohol/non-occupant-alcohol.component';
import { NonOccupantDrugComponent }               from 'src/app/ui/non-occupant/non-occupant-drug/non-occupant-drug.component';
import { NonOccupantSupplementalComponent }       from 'src/app/ui/non-occupant/non-occupant-supplemental/non-occupant-supplemental.component';
import { NonOccupantPedestrianComponent }         from 'src/app/ui/non-occupant/non-occupant-pedestrian/non-occupant-pedestrian.component';
import { NonOccupantBicyclistComponent }          from 'src/app/ui/non-occupant/non-occupant-bicyclist/non-occupant-bicyclist.component';
import { NonOccupantInjuryComponent }             from 'src/app/ui/non-occupant/non-occupant-injury/non-occupant-injury.component';
import { NonOccupantOtherComponent }              from 'src/app/ui/non-occupant/non-occupant-other/non-occupant-other.component';
import { NonOccupantNotesComponent }              from 'src/app/ui/non-occupant/non-occupant-notes/non-occupant-notes.component';

//Vehicle components
import { VehicleComponent }                    from 'src/app/ui/vehicle/vehicle.component';
import { VehicleVehicleComponent }             from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle.component';
import { VehicleVehicleVehicleComponent }      from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-vehicle/vehicle-vehicle-vehicle.component';
import { VehicleVehicleDamagedAreasComponent } from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-damaged-areas/vehicle-vehicle-damaged-areas.component';
import { VehicleVehicleVinComponent }          from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-vin/vehicle-vehicle-vin.component';
import { VehicleVehicleDetailsComponent }      from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-details/vehicle-vehicle-details.component';
import { VehicleVehicleOtherComponent }        from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-other/vehicle-vehicle-other.component';
import { VehicleVehicleTrailerComponent }      from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-trailer/vehicle-vehicle-trailer.component';
import { VehicleVehicleVinDecodeComponent }    from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-vin-decode/vehicle-vehicle-vin-decode.component';
import { VehicleVehicleNonMotoristComponent }  from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-non-motorist/vehicle-non-motorist.component';

//MTS Vehicle Components
import { VehicleVehicleRolloverComponent }  from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-rollover/vehicle-vehicle-rollover.component';
import { VehicleVehicleViewComponent }      from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-view/vehicle-vehicle-view.component';
import { VehicleVehicleNotesComponent }     from 'src/app/ui/vehicle/vehicle-vehicle/vehicle-vehicle-notes/vehicle-vehicle-notes.component';
import { VehicleDriverComponent }           from 'src/app/ui/vehicle/vehicle-driver/vehicle-driver.component';
import { VehicleDriverDriverComponent }     from 'src/app/ui/vehicle/vehicle-driver/vehicle-driver-driver/vehicle-driver-driver.component';
import { VehicleDriverLicenseComponent }    from 'src/app/ui/vehicle/vehicle-driver/vehicle-driver-license/vehicle-driver-license.component';
import { VehicleDriverCountersComponent }   from 'src/app/ui/vehicle/vehicle-driver/vehicle-driver-counters/vehicle-driver-counters.component';
import { VehicleDriverNotesComponent }      from 'src/app/ui/vehicle/vehicle-driver/vehicle-driver-notes/vehicle-driver-notes.component';
import { VehiclePrecrashComponent }         from 'src/app/ui/vehicle/vehicle-precrash/vehicle-precrash.component';
import { VehiclePrecrashRoadwayComponent }  from 'src/app/ui/vehicle/vehicle-precrash/vehicle-precrash-roadway/vehicle-precrash-roadway.component';
import { VehiclePrecrashPrecrashComponent } from 'src/app/ui/vehicle/vehicle-precrash/vehicle-precrash-precrash/vehicle-precrash-precrash.component';

//MTS Precrash Components
import { VehiclePrecrashOtherComponent }       from 'src/app/ui/vehicle/vehicle-precrash/vehicle-precrash-other/vehicle-precrash-other.component';
import { VehiclePersonComponent }              from 'src/app/ui/vehicle/vehicle-person/vehicle-person.component';
import { VehiclePersonPersonComponent }        from 'src/app/ui/vehicle/vehicle-person/vehicle-person-person/vehicle-person-person.component';
import { VehiclePersonSafetyDevicesComponent } from 'src/app/ui/vehicle/vehicle-person/vehicle-person-safety-devices/vehicle-person-safety-devices.component';
import { VehiclePersonAlcoholComponent }       from 'src/app/ui/vehicle/vehicle-person/vehicle-person-alcohol/vehicle-person-alcohol.component';
import { VehiclePersonDrugComponent }          from 'src/app/ui/vehicle/vehicle-person/vehicle-person-drug/vehicle-person-drug.component';
import { VehiclePersonNotesComponent }         from 'src/app/ui/vehicle/vehicle-person/vehicle-person-notes/vehicle-person-notes.component';
import { AvoidanceEquipmentComponent }         from 'src/app/ui/avoidance-equipment/avoidance-equipment.component';

//VINRequests
import { VINRequestsDetailsComponent } from 'src/app/ui/vin-request/vin-requests-details/vin-requests-details.component';

import { PrintcaseComponent }          from 'src/app/ui/cases/printcase/printcase.component';
import { CaseResolver }                from 'src/app/services/routerresolving.service';
import { CaseDocumentsComponent }      from 'src/app/ui/crss/case-documents/case-documents.component';
import { IsRBISGuard }                 from 'src/app/ui/is-rbis.guard';
import { InitRouteGuard }              from 'src/app/ui/cases/init.routeguard';
import { UnsavedChangesRouteGuard }    from 'src/app/ui/cases/unsaved-changes.routeguard';

/*** MOSS ***/
import { StrikingVehicleComponent }                 from 'src/app/ui/striking-vehicle/striking-vehicle.component';
import { StrikingVehicleVehicleComponent }          from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle.component';
import { StrikingVehicleVehicleVehicleComponent }   from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle-vehicle/striking-vehicle-vehicle-vehicle.component';
import { StrikingVehicleVehicleNotesComponent }     from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle-notes/striking-vehicle-vehicle-notes.component';
import { StrikingVehicleVehicleVinComponent }       from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle-vin/striking-vehicle-vehicle-vin.component';
import { StrikingVehicleVehicleVinDecodeComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-vehicle/striking-vehicle-vehicle-vin-decode/striking-vehicle-vehicle-vin-decode.component';
import { StrikingVehiclePersonComponent }           from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person.component';
import { StrikingVehiclePersonPersonComponent }     from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person-person/striking-vehicle-person-person.component';
import { StrikingVehiclePersonSafetyComponent }     from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person-safety/striking-vehicle-person-safety.component';
import { StrikingVehiclePersonNotesComponent }      from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person-notes/striking-vehicle-person-notes.component';
import { StrikingVehiclePersonAlcoholComponent }    from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-person-alcohol/striking-vehicle-person-alcohol.component';
import { StrikingVehicleDriverDriverComponent }     from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-driver-driver/striking-vehicle-driver-driver.component';
import { StrikingVehicleDriverNotesComponent }      from 'src/app/ui/striking-vehicle/striking-vehicle-person/striking-vehicle-driver-notes/striking-vehicle-driver-notes.component';
import { StrikingVehiclePrecrashRoadwayComponent }  from 'src/app/ui/striking-vehicle/striking-vehicle-precrash/striking-vehicle-precrash-roadway/striking-vehicle-precrash-roadway.component';
import { StrikingVehiclePrecrashPrecrashComponent } from 'src/app/ui/striking-vehicle/striking-vehicle-precrash/striking-vehicle-precrash-precrash/striking-vehicle-precrash-precrash.component';
import { StrikingVehiclePrecrashOtherComponent }    from 'src/app/ui/striking-vehicle/striking-vehicle-precrash/striking-vehicle-precrash-other/striking-vehicle-precrash-other.component';
import { StrikingVehiclePrecrashComponent }         from 'src/app/ui/striking-vehicle/striking-vehicle-precrash/striking-vehicle-precrash.component';
import { StrikingVehicleDriverComponent }           from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver.component';
//import { StrikingVehicleDriverDriverComponent }     from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-driver/striking-vehicle-driver-driver.component';
import { StrikingVehicleDriverPersonComponent }     from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-person/striking-vehicle-driver-person.component';
import { StrikingVehicleDriverSafetyComponent }     from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-safety/striking-vehicle-driver-safety.component';
import { StrikingVehicleDriverAlcoholComponent }    from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-alcohol/striking-vehicle-driver-alcohol.component';
//import { StrikingVehicleDriverNotesComponent }      from 'src/app/ui/striking-vehicle/striking-vehicle-driver/striking-vehicle-driver-notes/striking-vehicle-driver-notes.component';

import { SfrVehicleComponent }                      from 'src/app/ui/sfr-vehicle/sfr-vehicle.component';
import { SfrVehicleVehicleComponent }               from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle.component';
import { SfrVehicleVehicleVinComponent }            from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-vin/sfr-vehicle-vehicle-vin.component';
import { SfrVehicleVehicleNotesComponent }          from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-notes/sfr-vehicle-vehicle-notes.component';
import { SfrVehicleVehicleControlComponent }        from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-control/sfr-vehicle-vehicle-control.component';
import { SfrVehicleVehicleOtherComponent }          from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-other/sfr-vehicle-vehicle-other.component';
import { SfrVehicleVehicleVehicleComponent }        from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-vehicle/sfr-vehicle-vehicle-vehicle.component';
import { SfrVehicleVehicleVinDecodeComponent }      from 'src/app/ui/sfr-vehicle/sfr-vehicle-vehicle/sfr-vehicle-vehicle-vin-decode/sfr-vehicle-vehicle-vin-decode.component';
import { SfrVehiclePersonComponent }                from 'src/app/ui/sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person.component';
import { SfrVehiclePersonPersonComponent }          from 'src/app/ui/sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person-person/sfr-vehicle-person-person.component';
import { SfrVehiclePersonSafetyComponent }          from 'src/app/ui/sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person-safety/sfr-vehicle-person-safety.component';
import { SfrVehiclePersonAlcoholComponent }         from 'src/app/ui/sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person-alcohol/sfr-vehicle-person-alcohol.component';
import { SfrVehiclePersonNotesComponent }           from 'src/app/ui/sfr-vehicle/sfr-vehicle-person/sfr-vehicle-person-notes/sfr-vehicle-person-notes.component';

const SSRoutes: Routes = [
    {
        path: ':stateNum/case/:caseid', component: CaseComponent, resolve: { case: CaseResolver },
        children: [
            { path: 'closed', component: CaseClosedComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard] },
            { path: 'print/:type/:id/:personId', component: PrintcaseComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard] },
            {
                path: 'crash/:crashid',
                component: CrashComponent,
                children: [
                    { path: '', redirectTo: 'crash', pathMatch: 'prefix' },
                    { path: 'crash',       component: CrashCrashComponent,      resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'events',      component: CrasheventlistComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'trafficway',  component: CrashTrafficwayComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'other',       component: CrashOtherComponent,      resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'crashnotes',  component: CrashNotesComponent,      resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'nonmotorist', component: NonMotoristComponent,     resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }
                ]
            },
            {
                path: 'nonOccupant/:nonOccid', component: NonOccupantComponent,
                children: [
                    { path: '', redirectTo: 'nonOccupant', pathMatch: 'prefix' },
                    { path: 'nonOccupant',        component: NonOccupantNonOccupantComponent,        resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'nonMotoristActions', component: NonOccupantNonMotoristActionsComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'nonOther',           component: NonOccupantOtherComponent,              resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'pedestrian',         component: NonOccupantPedestrianComponent,         resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'bicyclist',          component: NonOccupantBicyclistComponent,          resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'alcohol',            component: NonOccupantAlcoholComponent,            resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'drug',               component: NonOccupantDrugComponent,               resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'supplemental',       component: NonOccupantSupplementalComponent,       resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'injury',             component: NonOccupantInjuryComponent,             resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    { path: 'nonOccNotes',        component: NonOccupantNotesComponent,              resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }
                ]
            },
            //NOTICE: In MOSS we have three vehicle types (struck first responder (sfr), striking, other). Blank Checks may need to navigate to a field on a tab dedicated to
            //any type of vehicle. Therefore, we follow a naming convention for URL routes: we use the prefix sfr, striking or other. MTSS and PPSS routes don't follow this convention.
            {
                path: 'sfrvehicle/:vehicleid', component: SfrVehicleComponent,
                children: [
                    { path: '', redirectTo: 'sfrvehicle', pathMatch: 'prefix' },
                    {
                        path: 'sfrvehicle', component: SfrVehicleVehicleComponent,
                        children: [
                            { path: '', redirectTo: 'sfrvehicle', pathMatch: 'prefix' },
                            { path: 'sfrvehicle',          component: SfrVehicleVehicleVehicleComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'sfrvin',              component: SfrVehicleVehicleVinComponent,       resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },                           
                            { path: 'sfrother',            component: SfrVehicleVehicleOtherComponent,     resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'sfrcontrol',          component: SfrVehicleVehicleControlComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'sfrnotes',            component: SfrVehicleVehicleNotesComponent,     resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'sfrvehicleVINDecode', component: SfrVehicleVehicleVinDecodeComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                        ]
                    },
                     {
                        path: 'sfrperson/:personid', component: SfrVehiclePersonComponent,
                        children: [
                            { path: '', redirectTo: 'sfrperson', pathMatch: 'prefix' },
                            { path: 'sfrperson',        component: SfrVehiclePersonPersonComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'sfrsafety',        component: SfrVehiclePersonSafetyComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'sfrpersonalcohol', component: SfrVehiclePersonAlcoholComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'sfrpersonnotes',   component: SfrVehiclePersonNotesComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }
                        ]
                    },                  
                ]
            },
            {
                path: 'strikingvehicle/:vehicleid', component: StrikingVehicleComponent,
                children: [
                    { path: '', redirectTo: 'strikingvehicle', pathMatch: 'prefix' },
                    {
                        path: 'strikingvehicle', component: StrikingVehicleVehicleComponent,
                        children: [
                            { path: '', redirectTo: 'strikingvehicle', pathMatch: 'prefix' },
                            { path: 'strikingvehicle',          component: StrikingVehicleVehicleVehicleComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingvin',              component: StrikingVehicleVehicleVinComponent,       resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingnotes',            component: StrikingVehicleVehicleNotesComponent,     resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingvehicleVINDecode', component: StrikingVehicleVehicleVinDecodeComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },

                        ]
                    },
                    {
                        path: 'strikingperson/:personid', component: StrikingVehiclePersonComponent,
                        children: [
                            { path: '', redirectTo: 'strikingperson', pathMatch: 'prefix' },
                            { path: 'strikingperson',        component: StrikingVehiclePersonPersonComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingsafety',        component: StrikingVehiclePersonSafetyComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingpersonalcohol', component: StrikingVehiclePersonAlcoholComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingdriver',        component: StrikingVehicleDriverDriverComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingdrivernotes',   component: StrikingVehicleDriverNotesComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingpersonnotes',   component: StrikingVehiclePersonNotesComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }
                        ]
                    },
                    {
                        path: 'strikingprecrash', component: StrikingVehiclePrecrashComponent,
                        children: [
                            { path: '', redirectTo: 'strikingroadway', pathMatch: 'prefix' },
                            { path: 'strikingroadway',       component: StrikingVehiclePrecrashRoadwayComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingprecrash',      component: StrikingVehiclePrecrashPrecrashComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'strikingprecrashother', component: StrikingVehiclePrecrashOtherComponent,    resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }
                        ]
                    },
                    //{
                    //    path: 'strikingdriver', component: StrikingVehicleDriverComponent,
                    //    children: [
                    //        { path: '', redirectTo: 'strikingdriver', pathMatch: 'prefix' },
                    //        { path: 'strikingdriver',        component: StrikingVehicleDriverDriverComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    //        { path: 'strikingdriversafety',  component: StrikingVehicleDriverSafetyComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    //        { path: 'strikingdriveralcohol', component: StrikingVehicleDriverAlcoholComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    //        { path: 'strikingdrivernotes',   component: StrikingVehicleDriverNotesComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                    //        { path: 'strikingdriverperson',  component: StrikingVehicleDriverPersonComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }

                    //    ]
                    //},
                ]
            },             
            {
                path: 'vehicle/:vehicleid', component: VehicleComponent,
                children: [
                    { path: '', redirectTo: 'vehicle', pathMatch: 'prefix' },
                    {
                        path: 'vehicle', component: VehicleVehicleComponent,
                        children: [
                            { path: '', redirectTo: 'vehicle', pathMatch: 'prefix' },
                            { path: 'vehicle',          component: VehicleVehicleVehicleComponent,      resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'vin',              component: VehicleVehicleVinComponent,          resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'vehicleDetails',   component: VehicleVehicleDetailsComponent,      resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'damagedAreas',     component: VehicleVehicleDamagedAreasComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },                    
                            { path: 'vehicleOther',     component: VehicleVehicleOtherComponent,        resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },                            
                            { path: 'vehicleTrailer',   component: VehicleVehicleTrailerComponent,      resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'rollover',         component: VehicleVehicleRolloverComponent,     resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'view',             component: VehicleVehicleViewComponent,         resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'vehicleNotes',     component: VehicleVehicleNotesComponent,        resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'vehicleVINDecode', component: VehicleVehicleVinDecodeComponent,    resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'vehnonmotorist',   component: VehicleVehicleNonMotoristComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }
                        ]
                    },
                    {
                        path: 'driver', component: VehicleDriverComponent,
                        children: [
                            { path: '', redirectTo: 'driver', pathMatch: 'prefix' },
                            { path: 'driver',      component: VehicleDriverDriverComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'license',     component: VehicleDriverLicenseComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'counters',    component: VehicleDriverCountersComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'driverNotes', component: VehicleDriverNotesComponent,    resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },                           
                        ]
                    },
                    {
                        path: 'precrash', component: VehiclePrecrashComponent,
                        children: [
                            { path: '', redirectTo: 'roadway', pathMatch: 'prefix' },
                            { path: 'roadway',       component: VehiclePrecrashRoadwayComponent,  resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'precrash',      component: VehiclePrecrashPrecrashComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'precrashOther', component: VehiclePrecrashOtherComponent,    resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }                            
                        ]
                    },
                    {
                        path: 'person/:personid', component: VehiclePersonComponent,
                        children: [
                            { path: '', redirectTo: 'person', pathMatch: 'prefix' },
                            { path: 'person',        component: VehiclePersonPersonComponent,        resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'safetyDevices', component: VehiclePersonSafetyDevicesComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'personAlcohol', component: VehiclePersonAlcoholComponent,       resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'personDrug',    component: VehiclePersonDrugComponent,          resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] },
                            { path: 'personNotes',   component: VehiclePersonNotesComponent,         resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard] }                           
                        ]
                    }
                ]
            },
            {
                path: 'vehicleAvoidEquip/:vehicleid/ae/avoidanceEquipment', component: AvoidanceEquipmentComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard], canDeactivate: [UnsavedChangesRouteGuard]
            }     
        ]
    },
    {
        path: ':stateNum/checkCase/:caseid', component: CheckCaseComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard],
        children: [
            { path: '', redirectTo: 'violatedRules', pathMatch: 'prefix' },
            { path: 'violatedRules',        component: ViolatedRulesComponent,   resolve: { case: CaseResolver }, canActivate: [InitRouteGuard] },
            { path: 'overriddenRules',      component: OverriddenRulesComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard] },
            { path: 'overrideRule/:RuleID', component: OverrideRuleComponent,    resolve: { case: CaseResolver }, canActivate: [InitRouteGuard] },
            { path: 'blanks',               component: BlanksComponent,          resolve: { case: CaseResolver }, canActivate: [InitRouteGuard] }
        ]
    },

    { path: 'earlyNotification/fatalCaseScreener',        component: EarlyNotificationDetailsComponent, canActivate: [InitRouteGuard, IsRBISGuard] },
    { path: ':stateNum/vinRequests/vinRequests',          component: VINRequestsDetailsComponent },
    { path: 'cases/caseCreate',                           component: CaseCreateComponent },
    { path: 'cases/:stateNum/caseEdit/:caseid',           component: CaseEditComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard] },
    { path: 'cases/:stateNum/caseDelete/:caseid',         component: CaseDeleteComponent, resolve: { case: CaseResolver }, canActivate: [InitRouteGuard] },
    { path: 'cases/:stateNum/askCodingQuestion/:casenum', component: AskCodingQuestionComponent },
    { path: 'CaseDocuments/:stateNum/:caseid',            component: CaseDocumentsComponent, resolve: { case: CaseResolver } },
]

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forChild(SSRoutes),
        CommonModule
    ],
    exports: [RouterModule],
    providers: []
})
export class CaseRoutingModule { }
