import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SharedDataService } from '../services/shared-data.service';

@Injectable({
    providedIn: 'root'
})
export class IsRBISGuard implements CanActivate {
    multipleSiteAccess: boolean = false;
    mossSCISiteList: any[];

    constructor(private authService: AuthService, private _sharedDataService: SharedDataService) {
       
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
       
        if (document.cookie.split(';').find(x => x.startsWith('.AspNetCore.Cookies') || x.startsWith(' .AspNetCore.Cookies')))
        {
            return new Observable<boolean>(objGuardObserver => {
                this.authService.IsUserAuthorized().then(blnInitialized => {
                    
                    (async () => {
                        try {
                            let result = await this.authService.GetMossSciSites();
                            this.mossSCISiteList = result,
                            this.multipleSiteAccess = this._sharedDataService.IsMultipleSiteAccess(result);

                            if (blnInitialized && !this.multipleSiteAccess) {
                                objGuardObserver.next(true);
                            }  
                        } catch (err) {
                            console.log(err);
                        }
                         
                    })();
                });
            });   
        }
        else {
            console.log('IsRBISGuard.canActivate(): Retrieval of user permissions bypassed. User is not authenticated. Redirect to MAX.GOV imminent.');
            return false;
        }
    }
}
